import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  section: {
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sectionbody: {
    backgroundColor: "#FAFBFC",
    //   padding: "20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    width: "100px",
    height: "40px",
    color: "#8a94a6",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  col: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
}));

export default function Course() {
  const classes = useStyles();

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={classes.section}>
          <div className={classes.sectionbody}>
            <div
              className={classes.col}
              style={{
                backgroundColor: "#377dff",
              }}
            />
            <Typography variant="overline">B.Tech</Typography>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionbody}>
            <div
              className={classes.col}
              style={{
                backgroundColor: "#38cb89",
              }}
            />
            <Typography variant="overline">B.Com</Typography>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionbody}>
            <div
              className={classes.col}
              style={{
                backgroundColor: "#ffab00",
              }}
            />
            <Typography variant="overline">MCA</Typography>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionbody}>
            <div
              className={classes.col}
              style={{
                backgroundColor: "#ffc143",
              }}
            />
            <Typography variant="overline">BCA</Typography>
          </div>
        </div>
      </div>
    </>
  );
}
