import { Button, Chip, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import pSBC from "shade-blend-color";
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "10px",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
  span: {
    color: theme.palette.primary.main,
  },
  text: {
    marginBottom: "7px",
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
}));

export default function AssignedPlacementCycle({
  jobId,
  collegeId,
  placementList,
}) {
  const classes = useStyles();
  const [showMore, setShowMore] = React.useState(false);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Assigned Placement Cycles</Typography>{" "}
            {placementList?.length > 5 && (
              <Button
                color="primary"
                style={{ display: "flex" }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                {" "}
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
          </div>

          <div className={classes.chipsection}>
            {!showMore
              ? placementList
                  ?.slice(0, 5)
                  ?.map((data, index) => (
                    <Chip
                      style={{ display: "grid" }}
                      className={classes.chip}
                      color="primary"
                      variant="outlined"
                      size="small"
                      label={data?.name}
                    />
                  ))
              : placementList?.map((data, index) => (
                  <Chip
                    style={{ display: "grid" }}
                    className={classes.chip}
                    color="primary"
                    variant="outlined"
                    size="small"
                    label={data?.name}
                  />
                ))}
          </div>
        </Paper>
      </div>
    </>
  );
}
