import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F6F6F6",
    color: "#000000",
  },
  body: {
    fontSize: 14,
    border: "none",
    borderRight: "1px solid #6C757D",
    // "&:last-of-type": {
    //   borderRight: "none",
    // },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);



const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ReportTable({chartData}) {
  
  const classes = useStyles();
  const labels = chartData?.labels
  const data = chartData?.data
  let rows = [];
    rows = labels?.map((element, index) => ({
    id: index +1 ,
    x: element,
    y: data[index]
  }));


  return (
    <TableContainer
      style={{ border: "1px solid #6C757D", borderRadius: "4px" }}
    >
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              style={{ borderRight: "1px solid #6C757D", fontWeight: "600" }}
            >
              S.No
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ borderRight: "1px solid #6C757D", fontWeight: "600" }}
            >
              {chartData?.xAxis}
            </StyledTableCell>

            <StyledTableCell align="center" style={{ fontWeight: "600" }}>
            {chartData?.yAxis}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length && rows?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" align="center">
                {row?.id}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="center">
                {row?.x}
              </StyledTableCell>

              <StyledTableCell align="center" style={{ borderRight: "none" }}>
                {row?.y}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
