import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const CREDIT_DATA = "CREDIT_DATA";
export const TRANSACTION_HISTORY_DATA = "TRANSACTION_HISTORY_DATA";
export const RECHARGE_HISTORY_DATA = "RECHARGE_HISTORY_DATA";

export const CreditInfoGetAPI = (
  alert,
  history,
  location,
  setLoading,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.PRAVESH}api/saas/college/wallet/get_credit_info/?college_id=${collegeId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
    }
    setLoading(false);

    dispatch({
      type: CREDIT_DATA,
      payload: res.data.data,
    });
  };
};

export const TransactionistoryGetApi = (
  alert,
  history,
  location,
  setLoading,
  date,
  collegeId,
  page
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.PRAVESH}api/saas/college/wallet/get_transaction_history/?college_id=${collegeId}&start_date=${date.startDate}&end_date=${date.endDate}&page=${page}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
    }
    setLoading(false);

    dispatch({
      type: TRANSACTION_HISTORY_DATA,
      payload: res.data.data,
    });
  };
};

export const RechargeHistoryGetApi = (
  alert,
  history,
  location,
  setLoading,
  date,
  collegeId,
  page
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.PRAVESH}api/saas/college/wallet/get_recharge_history/?college_id=${collegeId}&start_date=${date.startDate}&end_date=${date.endDate}&page=${page}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
    }
    setLoading(false);

    dispatch({
      type: RECHARGE_HISTORY_DATA,
      payload: res.data.data,
    });
  };
};

export const UpdateCrediData = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: CREDIT_DATA,
      payload: payload,
    });
  };
};
