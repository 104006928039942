// import React, { Component } from "react";
// import Charst from "react-apexcharts";
// import { Paper, Typography } from "@material-ui/core";
// import Course from "../../Course";

// export default class HiredStudents extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       options: {
//         grid: {
//           xaxis: {
//             lines: {
//               show: false,
//             },
//           },
//           yaxis: {
//             lines: {
//               show: false,
//             },
//           },
//         },
//         chart: {
//           id: "apexchart-example",
//           toolbar: {
//             show: false,
//           },
//         },
//         xaxis: {
//           categories: ["0-3", "3-6", "6-9", "9-12", "12-15", "15-18"],
//         },

//         yaxis: {
//           title: {
//             text: "Number of Hired Students",
//             style: {
//               colors: [],
//               fontSize: "14px",
//               fontFamily: "Nunito",
//             },
//           },
//         },
//         plotOptions: {
//           bar: {
//             horizontal: false,
//             columnWidth: "75%",
//             borderRadius: 10,
//           },
//         },
//         dataLabels: {
//           enabled: false,
//         },
//       },
//       legend: {
//         show: false,
//       },

//       series: [
//         {
//           name: "series-1",
//           data: [20, 40, 35, 50, 49, 60],
//           color: "#17a2b8",
//         },
//       ],
//     };
//   }
//   render() {
//     return (
//       <Paper
//         style={{
//           boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
//           borderRadius: "20px",
//           padding: "30px",
//         }}
//       >
//         <Typography variant="h6" style={{ marginBottom: "30px" }}>
//           Number of Hired Students
//         </Typography>

//         <Charst
//           options={this.state.options}
//           series={this.state.series}
//           type="bar"
//           height={220}
//         />
//         <Typography
//           variant="body2"
//           style={{ fontWeight: "bold", textAlign: "center" }}
//         >
//           CTC in LPA
//         </Typography>
//       </Paper>
//     );
//   }
// }

import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";
import Course from "../../Course";

const HiredStudents = ({ placement }) => {
  return (
    <div
      style={{
        boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
        borderRadius: "8px",
        padding: "30px",
      }}
    >
      <Charst
        options={{
          xaxis: {
            title: {
              text: "CTC in LPA",

              style: {
                fontSize: "14px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            categories: placement?.categories ? placement?.categories : [],
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 8,
            },
          },
          yaxis: {
            title: {
              text: "Number of Hired Students",
              style: {
                fontSize: "14px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          title: {
            text: "CTC vs Hired Students",
            style: {
              fontSize: "14px",
              fontFamily: "Nunito",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        }}
        series={placement?.series_list ? placement?.series_list : []}
        type="bar"
        // height={220}
        height="100%"
        //  width="100%"
      />
    </div>
  );
};

export default HiredStudents;
