import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import React from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { loginAPI } from "../../../api/SignIn/action";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { NEXT_APP_ENDPOINT } from "../../../constants/constants";
import { eventTrack } from "../../../utility/gaTracking";
import { emailRegex, passwordRegex } from "../../../utility/regex";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(3, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "60%",
  },
  text: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    width: "90%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    borderRadius: "8px",
    margin: theme.spacing(3, 0, 2),
    boxShadow: "none",
  },
  paper2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
  },
  terms: {
    color: theme.palette.primary.main,
  },
  span: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function SignUpForm({ state, setState, errors, setErrors }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  const [values, setValues] = React.useState({
    password: "",

    showPassword: false,
  });

  const domainData = useSelector((store) => store?.domainData);

  const [loading, setLoading] = React.useState(false);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateData = () => {
    var email_error = null;
    var password_error = null;
    var is_valid = true;
    if (!state.email) {
      email_error = "Email field cannot be left blank";
      is_valid = false;
    }
    if (state.email && !emailRegex.test(state.email)) {
      email_error = "Please enter a valid email";
      is_valid = false;
    }
    if (!state.password) {
      password_error = "Password field cannot be left blank";
      is_valid = false;
    }
    if (state.password && !passwordRegex.test(state.password)) {
      password_error = "Please enter a valid Password 6 digits long";
      is_valid = false;
    }
    setErrors({ ...errors, email: email_error, password: password_error });
    return is_valid;
  };
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const submitData = () => {
    if (validateData()) {
      dispatch(
        loginAPI(
          state,
          history,
          setLoading,
          setErrors,
          errors,
          alert,
          location,
          isEducationGroup,
          isCollegeGroup
        )
      );
    }
  };

  const recipient = "support@getwork.org";
  const subject = `IMP Query | ${domainData?.collegeDetails?.college_name}`;
  const body = "Please write your query here...";

  const handleContactUs = () => {
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className={classes.paper2}>
        <div className={classes.paper}>
          {/* <Typography component="h1" variant="h5">
            Sign In
          </Typography> */}
          <div className={classes.text}>
            <Typography
              component="h1"
              variant="body1"
              style={{ color: "#6c757d" }}
            >
              Believe that there are no limitations, no barriers to your success
              – you will be empowered and you will achieve.
            </Typography>
            <br />
            <Typography variant="h5">GETWORK WELCOMES YOU!</Typography>
          </div>
          <form className={classes.form} noValidate>
            <div style={{ marginBottom: "10px" }}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={state.email}
                onChange={(e) => {
                  setState({ ...state, email: e.target.value });
                  setErrors({ ...errors, email: null });
                }}
                error={errors.email ? true : false}
                helperText={<span>{errors.email}</span>}
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                id="email"
                label="Your Email"
                name="email"
                autoComplete="email"
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    submitData();
                  }
                }}
              />
            </div>
            <FormControl
              className={clsx(classes.margin, classes.textField, classes.form)}
              variant="outlined"
              error={errors.password ? true : false}
            >
              <InputLabel className={classes.floatingLabelFocusStyle}>
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={state.password}
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                onChange={(e) => {
                  setState({ ...state, password: e.target.value });
                  setErrors({ ...errors, password: null });
                }}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    submitData();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText id="standard-weight-helper-text">
                {errors.password}
              </FormHelperText>
            </FormControl>

            <Grid container style={{ marginTop: "20px", marginBottom: "10px" }}>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox value="remember" size="small" color="primary" />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ color: "#6c757d", lineHeight: 1.5 }}
                    >
                      Remember me
                    </Typography>
                  }
                />
                <br />
                {/* <Typography variant="body2" style={{ marginTop: "10px" }}>
                  By Signing in you accepted to{" "}
                  <span className={classes.terms}> Terms & Conditions*. </span>
                </Typography> */}
              </Grid>
              <Grid item>
                <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body2"
                    style={{
                      marginTop: "10px",
                      color: "#6c757d",
                    }}
                  >
                    Forgot password?
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              color="secondary"
              style={{ marginTop: "7px" }}
            >
              By Signing in you accepted to{" "}
              <span
                onClick={() => {
                  window.open(`${NEXT_APP_ENDPOINT}/terms-and-conditions`);
                }}
                className={classes.span}
              >
                {" "}
                Terms & Conditions*.
              </span>
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                if (!loading) {
                  eventTrack("Sign Up Screen", "Sign Up Button", "Button");

                  submitData();
                }
              }}
            >
              {loading ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : (
                <>Sign In</>
              )}
            </Button>
            <Grid container>
              <Grid item xs style={{ display: "flex" }}>
                <Typography variant="overline" style={{ color: "#181818" }}>
                  If you have any query? &nbsp;
                  <a
                    variant="body2"
                    style={{ textDecoration: "none" }}
                    id="my_custom_link"
                  >
                    <span onClick={handleContactUs} className={classes.span}>
                      Contact us
                    </span>
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
}
