import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Chip } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ProfileApproveDialog from "../../EditStudent/ProfileApproveDialog/ProfileApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
}));

export default function LanguagesCard({
  data,
  userId,
  StudentDetailsAPI,
  fromProfileUpdate = false,
}) {
  const classes = useStyles();
  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <br />
      {data?.student_user_details?.languages?.length > 0 ||
      (fromProfileUpdate &&
        data?.student_profile_update &&
        Object.keys(data?.student_profile_update)?.includes("LANG")) ? (
        <div className={classes.paper}>
          <Typography
            variant="h6"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            Languages
          </Typography>
          {data?.student_user_details?.languages?.length > 0 &&
            data?.student_user_details?.languages?.map((item) => (
              <>
                <Typography variant="body2">{item.name}</Typography>
                <Typography variant="overline">
                  Proficiency: {item?.proficiency}
                </Typography>
                {item.level.length > 0 && (
                  <div className={classes.root}>
                    {item.level.map((item2) => (
                      <Chip
                        color="primary"
                        variant="outlined"
                        size="small"
                        label={item2}
                        borderRadius="8px"
                      />
                    ))}
                  </div>
                )}
              </>
            ))}
          {fromProfileUpdate &&
          data?.student_profile_update &&
          Object.keys(data?.student_profile_update)?.includes("LANG") ? (
            <div
              style={{
                backgroundColor: "green ",
                padding: "10px",
                width: "160px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                right: "10px",
                bottom: "10px",
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
              >
                {`Pending Request (${data?.student_profile_update?.LANG})`}
              </Typography>
            </div>
          ) : (
            <></>
          )}
          <ProfileApproveDialog
            title="Languages Request"
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            formType="approveLanguages"
            data={data}
            userId={userId}
            type={"LANG"}
            StudentDetailsAPI={StudentDetailsAPI}
          />
        </div>
      ) : null}
    </>
  );
}
