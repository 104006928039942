import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlacementListAPI } from "../../api/Common/action";
import { collegeIdFn, userRolesActionsFn } from "../../api/SignIn/selector";
import ReportTab from "../../components/Header/ReportTab";
import DropDownButton from "../../components/Input/DropDownButton";
import NotAuthorized from "../../components/NoDataSVG/NotAuthorized";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "space-between",
    flexDirection: "row",
  },
}));

export default function Report() {
  const classes = useStyles();
  const [placementSession, setPlacementSession] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const [loadingPlacement, setLoadingPlacement] = useState(false);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const placementSessionList = useSelector((state) => state.commonData.placementList);
  const collegeId = useSelector(collegeIdFn);

  useEffect(() => {
    if (collegeId) {
      dispatch(PlacementListAPI(alert, history, location, setLoadingPlacement, collegeId));
    }
  }, [collegeId]);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };
  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_cycle")) {
        var placement_name = checkPlacementName(location?.search.replace("?placement_cycle=", ""));
        setPlacementSession({
          id: location?.search.replace("?placement_cycle=", ""),
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  return (
    <>
      {/* <Layout> */}
      {/* <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <DropDownButton title="Placement Session 2021" />
        </div> */}
      {userRolesPermission?.some((item) => item.access_control_key === "analyse_report") ? (
        <>
          {placementSessionList?.length > 0 && (
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
              <DropDownButton title="Placement Session 2021" placementSession={placementSession} setPlacementSession={setPlacementSession} />
            </div>
          )}

          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Campus Placement Reports</Typography>
              <Typography variant="body2" color="secondary">
                Visualize all Placement Data with Bar Graphs & Pie Charts
              </Typography>
            </div>

            {/* <div
            style={{
              display: "flex",
              //      flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Button
              variant="contained"
              style={{
                borderRadius: "30px",
                marginRight: "10px",
                paddingRight: "40px",
                paddingLeft: "40px",
                //    marginTop: "4px",
                boxShadow: "none",
                display: "flex",
                height: "40px",
              }}
              color="primary"
            >
              <img
                src={Share}
                height="12px"
                width="12px"
                style={{ marginRight: "5px" }}
              />
              Share
            </Button>
            <Button
              variant="contained"
              size="small"
              style={{ marginRight: "0px" }}
              startIcon={<GetAppIcon />}
              className={classes.button}
              //   startIcon={<VerticalAlignBottomIcon />}
            >
              Download
            </Button>
          </div> */}
          </div>

          <ReportTab placementSession={placementSession} />
        </>
      ) : (
        <NotAuthorized height={"320px"} width={"320px"} title="You are not Authorized to view this section" subtitle="Please contact your admin to make this page visible to you" />
      )}
      {/* </Layout> */}
    </>
  );
}
