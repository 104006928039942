import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IndustryGetAPI } from "../../../api/Common/action";
import { AllCompanyAPI } from "../../../api/JobPost/action";
import AddNewCompanyForm from "../DialogItem/AddNewCompanyForm";
import {isEducationGroupFn, isCollegeGroupFn} from "../../../api/SignIn/selector";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "8px",
  },
}));

export default function PostJobComapny({
  collegeId,
  jobDetails = {},
  setJobDetails = () => {},
  isRecommended,
  setJobDetailsError = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const params = useParams();
  const selectedData = useSelector((state) =>
    state?.SingleJobData?.details ? state?.SingleJobData?.details : []
  );
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [makeCompanyCall, setMakeCompanyCall] = useState(false);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const initialCompanyDetails = {
    company_name: null,
    company_website: null,
    industry_id: null,
    industry_name: null,
    industry_type_id: null,
    industry_type_name: null,
    contact_person_name: isRecommended ? "Getwork" : null,
    email: isRecommended ? "support@getwork.org" : null,
    phone: isRecommended ? 8595351311 : null,
    designation: null,
  };
  const [companyDetails, setCompanyDetails] = useState(initialCompanyDetails);

  const getTypeId = () => {
    const industry =
      jobDetails?.companyDetails?.industry_type ??
      jobDetails?.companyCreation?.company_form_data?.industry_type_name;
    let nums = null;
    if (industry && industry === "Government") nums = 2;
    else if (industry && industry === "Private") nums = 1;
    else if (industry && industry === "Semi-Government") nums = 3;
    return nums;
  };

  useEffect(() => {
    if (
      jobDetails?.companyDetails &&
      jobDetails?.companyCreation &&
      jobDetails?.companyCreation?.poc_list
    ) {
      setCompanyDetails({
        ...companyDetails,
        company_name: jobDetails?.companyDetails?.company_name,
        company_website: jobDetails?.companyDetails?.company_website,
        industry_id:
          jobDetails?.companyCreation?.company_form_data?.industry_id ??
          jobDetails?.companyDetails?.industry_id,
        industry_name:
          jobDetails?.companyCreation?.company_form_data?.industry_name ??
          jobDetails?.companyDetails?.industry_name,
        industry_type_id:
          jobDetails?.companyCreation?.company_form_data?.industry_type_id ??
          getTypeId(),
        industry_type_name:
          jobDetails?.companyCreation?.company_form_data?.industry_type_name ??
          jobDetails?.companyDetails?.industry_type,
        // contact_person_name: jobDetails?.companyCreation?.poc_list[0]?.contact_person_name,
        // email: jobDetails?.companyCreation?.poc_list[0]?.email,
        // phone: jobDetails?.companyCreation?.poc_list[0]?.phone,
        designation: jobDetails?.companyCreation?.poc_list[0]?.designation,
      });
    }
  }, [jobDetails]);

  const [jobAction, setJobAction] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setMakeCompanyCall(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (params.job_id) {
      setJobAction(params.job_id.split("-")[0]);
    }
  }, [params]);

  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("inbound_job");

  // will be open for no job data from recommended job values when company details are null
  // for jobs from recommended section

  useEffect(() => {
    if (selectedData && jobAction === "recommended" && !makeCompanyCall) {
      setOpen(!selectedData?.company);
    }
  }, [selectedData]);

  useEffect(() => {
    if (makeCompanyCall) {
      dispatch(AllCompanyAPI(setLoading, alert, history, location, collegeId));
    }
  }, [makeCompanyCall]);

  const industryList = useSelector((state) => state?.commonData?.industryData);

  useEffect(() => {
    dispatch(IndustryGetAPI(alert, history, location));
  }, []);

  const getSource = () => {
    if (jobAction === "recommended") {
      if (param1) return "INBOUND_JOB";
      else return "RECOMMENDED";
    } else {
      return "";
    }
  };
  return (
    <div>
      {/* <Button
        variant="contained"
        color="primary"
        style={{
          borderRadius: "30px",
          boxShadow: "none",
          height: "48px",
          width: "100%",
          //   height: "100%",
        }}
      >
        Add Company
      </Button> */}
      { !(isEducationGroupFn || isCollegeGroup) && 
      <Button
        color="primary"
        style={{ display: "flex", marginTop: -15, marginLeft: 10 }}
        startIcon={<AddCircleIcon />}
        onClick={handleClickOpen}
      >
        Add Company
      </Button>
      }
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={!isRecommended && handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <AddNewCompanyForm
            handleClose={handleClose}
            fromPostJob={true}
            setMakeCompanyCall={setMakeCompanyCall}
            industryList={industryList}
            collegeId={collegeId}
            setJobDetails={setJobDetails}
            jobDetails={jobDetails}
            isRecommended={isRecommended}
            companyDetails={companyDetails}
            initialCompanyDetails={initialCompanyDetails}
            setCompanyDetails={setCompanyDetails}
            source={getSource()}
            // setOpen={setOpen}
            // handleOpen={handleOpen}
            // open={open}
          />
        </div>
      </Dialog>
    </div>
  );
}
