import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography,Chip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    marginBottom: "15px",
  },
   chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",

  },
  chipDisplay:{
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
  },
}));

export default function SaveWorkExperience({
  experience,
  handleDelete,
  handleClickOpen,
  index,
  setEditWorkEx,
  setIsEdit,
  setEditIndex,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            style={{
              marginBottom: "4px",
              fontWeight: "bold",
            }}
          >
            {experience.job_designation}
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton
              color="primary"
              onClick={() => {
                setEditIndex(index);
                setIsEdit(true);
                handleClickOpen();
                setEditWorkEx(experience);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                handleDelete(experience?.id, index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
            fontWeight: "bold",
          }}
        >
          {" "}
          {experience.company_name}{" "}
        </Typography>

        <Typography variant="body2">
          {experience?.start_date && experience?.start_date !== null && (
            <>
              {moment(experience?.start_date).format("MMM, YYYY")}
              {experience.end_date && experience.end_date !== null ? (
                <>
                  {" - "}
                  {moment(experience?.end_date).format("MMM, YYYY")}
                  {/* <span color="primary"> {experience?.job_type_name}</span> */}
                </>
              ) : (
                <>
                  - Present
                  {/*  <span color="primary"> ({experience?.job_type_name})</span> */}
                </>
              )}
            </>
          )}
        </Typography>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
          }}
        >
          {" "}
          <span color="primary"> {experience?.job_type_name}</span>{" "}
        </Typography>

        <Typography>{experience?.job_description}</Typography>
         <div className={classes.chipDisplay}>
                {experience?.skills &&
                  experience?.skills.map((item, index) => {
                    return (
                      <li key={item.skill_id}>
                        <Chip
                          color="primary"
                          variant="outlined"
                          size="small"
                          label={item.skill_name}
                          className={classes.chip}
                        />
                      </li>
                    );
                })}
        </div>
      </div>
    </>
  );
}
