import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  praveshAxiosDeleteReq,
  praveshAxiosGetReq,
  praveshAxiosPatchReq,
  praveshAxiosPostReq
} from "../../api/BaseApi/apiBase";
import { AllLocationsAPI } from "../../api/JobPost/action";
import {
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn
} from "../../api/SignIn/selector";
import Achievements from "../../components/Cards/EditStudent/Achievements/Achievements";
import BasicInfo from "../../components/Cards/EditStudent/BasicInfo";
import Certifications from "../../components/Cards/EditStudent/Certifications/Certifications";
import Education from "../../components/Cards/EditStudent/Educantion/Education";
import Hobbies from "../../components/Cards/EditStudent/Hobbies";
import ImportantLink from "../../components/Cards/EditStudent/ImportantLink";
import Languages from "../../components/Cards/EditStudent/Languages/Languages";
import Project from "../../components/Cards/EditStudent/Project/Project";
import Publications from "../../components/Cards/EditStudent/Publications/Publications";
import Resume from "../../components/Cards/EditStudent/Resume";
import GenerateResume from "../../components/Cards/Student/Resume/Resume";
import Skills from "../../components/Cards/EditStudent/Skills";
import WorkExperience from "../../components/Cards/EditStudent/WorkExperience/WorkExperience";
import CustomCircularProgress from "../../components/CircularProgressBar";
import { checkForToken } from "../../constants/ApiCall";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

function EditStudents() {
  const classes = useStyles();

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const collegeId = useSelector(collegeIdFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [resumeDataLoading, setResumeDataLoading] = useState(false);
  const [workExDataLoading, setWorkExDataLoading] = useState(false);
  const [eduDataLoading, setEduDataLoading] = useState(false);
  const [linkDataLoading, setLinkDataLoading] = useState(false);
  const [skillsDataLoading, setSkillsDataLoading] = useState(false);
  const [projectDataLoading, setProjectDataLoading] = useState(false);
  const [pubDataLoading, setPubDataLoading] = useState(false);
  const [langDataLoading, setLangDataLoading] = useState(false);
  const [hobbieDataLoading, setHobbieDataLoading] = useState(false);
  const [basicInfoDataLoading, setBasicInfoDataLoading] = useState(false);
  const [achievementDataLoading, setAchievementDataLoading] = useState(false);
  const [loadingExtraCurricular, setLoadingExtraCurricular] = useState(false);
  const [certiDataLoading, setCertiDataLoading] = useState(false);
  const [resume, setResume] = useState();

  const [languageData, setLanguageData] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [extraCurricularActivities, setExtraCurricularActivities] = useState(
    []
  );

  const [collegeList, setCollegeList] = useState([]);
  const [boardsList, setBoardsList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [education, setEducation] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [publications, setPublications] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [userSkills, setUserSkills] = useState([]);
  const [stuCertifications, setStuCertifications] = useState([]);
  const [studentData, setStudentData] = useState();
  const [resumeName, setResumeName] = useState(null);
  const [uploadedResume, setUploadedResume] = useState(null);
  const dispatch = useDispatch();

  const params = useParams();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (params) {
      const userId = parseInt(params?.user_id?.split("-")?.pop());
      setUserId(userId);
      dispatch(AllLocationsAPI(() => {}, alert, history, location));
    }
  }, [params]);

  const [approvalRequestCounts, setApprovalRequestCounts] = useState(null);
  const getRequestApprovalData = async () => {
    const url = `api/saas/profile/update/section_count/?student_user_id=${userId}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res?.data?.success) {
        setApprovalRequestCounts(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  useEffect(() => {
    if (userId) {
      getAllStudentDetails();
      getCollegesList();
      getCompaniesList();
      getSkillsList();
      getBoardsList();
      getRequestApprovalData();
    }
  }, [params, userId]);

  const getAllStudentDetails = async () => {
    const url = `api/shared/v2/public_profile/?user_id=${userId}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        var tempProjects = [];
        var tempPublications = [];

        res?.data?.data?.student_user_details?.project?.forEach((pub, _) => {
          if (pub.type === "Project") tempProjects.push(pub);
          else if (pub.type === "Publications") tempPublications.push(pub);
        });
        setProjects(tempProjects);
        setPublications(tempPublications);

        setStudentData(res?.data?.data);
        setSocialLinks(res?.data?.data?.profile_url);
        setAchievements(res?.data?.data?.student_user_details?.achievements);
        setExtraCurricularActivities(
          res?.data?.data?.student_user_details?.extra_curricular_activity
        );
        setLanguageData(res?.data?.data?.student_user_details?.languages);
        setWorkExperience(res?.data?.data?.student_user_details?.work_ex);
        setEducation(res?.data?.data?.student_user_details?.education);
        setHobbies(res?.data?.data?.student_user_details?.hobbies);
        setUserSkills(res?.data?.data?.student_user_details?.skill);
        setResume(res?.data?.data?.student_user_details?.resume);
        setStuCertifications(
          res?.data?.data?.student_user_details?.certification_data
        );
        setResumeName(res?.data?.data?.student_user_details?.resume_name);
        // setLoading(false);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };

  const getBoardsList = async () => {
    const url = `api/education/board`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res?.data?.success) {
        setBoardsList(res?.data?.data?.board_list);
      } else {
        alert.error(res.data.error);
      }
    } catch (error) {
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const getCollegesList = async () => {
    const url = `api/saas/college/get/`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res?.data?.success) {
        setCollegeList(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
    } catch (error) {
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const getSkillsList = async () => {
    const url = `api/education/skills`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res?.data?.success) {
        setSkillsList(res?.data?.data?.skills);
      } else {
        alert.error(res.data.error);
      }
    } catch (error) {
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const getCompaniesList = async () => {
    const url = `api/company/company_list/`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res?.data?.success) {
        setCompaniesList(res?.data?.data?.all_companies);
      } else {
        alert.error(res.data.error);
      }
    } catch (error) {
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const addWorkEx = async (workEx, handleClose) => {
    const url = `api/saas/work_experience/?user_id=${userId}`;

    const data = {
      company_id: workEx?.company_id,
      company_name: workEx?.company_name,
      company_website: workEx?.company_website ? workEx?.company_website : null,
      city_id: null,
      job_type_id: workEx?.job_type_id,
      job_type: workEx?.job_type_name,
      start_date: moment(workEx?.start_date).format("MM/yyyy"),
      end_date: workEx?.end_date
        ? moment(workEx?.end_date).format("MM/yyyy")
        : null,
      job_designation: workEx?.job_designation,
      job_description: workEx?.job_description,
      skills: workEx?.skills,
      is_current_working: workEx?.is_current_working,
    };

    setWorkExDataLoading(true);
    try {
      const res = await praveshAxiosPostReq(url, data);

      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setWorkExperience([...workExperience, res?.data?.data?.data]);
        handleClose();
      } else {
        alert.error(res?.data?.error);
      }
      setWorkExDataLoading(false);
    } catch (error) {
      setWorkExDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const patchWorkEx = async (workEx, workExId, index, handleClose) => {
    const url = `api/saas/work_experience/${workExId}/?user_id=${userId}`;
    const data = {
      company_id: workEx?.company_id,
      company_name: workEx?.company_name,
      company_website: workEx?.company_website,
      city_id: null,
      job_type_id: workEx?.job_type_id,
      job_type: workEx?.job_type_name,
      start_date: moment(workEx?.start_date).format("MM/yyyy"),
      end_date: workEx?.end_date
        ? moment(workEx?.end_date).format("MM/yyyy")
        : null,
      job_designation: workEx?.job_designation,
      job_description: workEx?.job_description,
      skills: workEx?.skills,
      is_current_working: workEx?.is_current_working,
    };
    setWorkExDataLoading(true);
    try {
      const res = await praveshAxiosPatchReq(url, data);

      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        let items = [...workExperience];
        items[index] = res.data.data.data;
        setWorkExperience(items);
        handleClose();
      } else {
        alert.error(res?.data?.error);
      }
      setWorkExDataLoading(false);
    } catch (error) {
      setWorkExDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const deleteWorkEx = async (workExId, index) => {
    const url = `api/saas/work_experience/${workExId}/?user_id=${userId}`;
    setWorkExDataLoading(true);
    try {
      const res = await praveshAxiosDeleteReq(url);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setWorkExperience((workExperiences) =>
          workExperiences.filter((_, workExIndex) => workExIndex !== index)
        );
      } else {
        alert.error(res?.data?.error);
      }
      setWorkExDataLoading(false);
    } catch (error) {
      setWorkExDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const addEducation = async (newEducation, handleClose) => {
    const url = `api/saas/user_education/?user_id=${userId}`;

    var newDegreeId = newEducation?.degree_id;
    var newSpecializationId = newEducation?.specialization_id;
    var tempEducation = {
      ...newEducation,
      education_level: newEducation.education,
      start_date: moment(newEducation.start_date).format("yyyy-MM-DD"),
      end_date: moment(newEducation.end_date).format("yyyy-MM-DD"),
      specialization: newSpecializationId,
      degree: newDegreeId,
      college_name:
        newEducation?.college_id && newEducation?.college_id !== 142
          ? newEducation?.college_name
          : newEducation?.temp_college_name,
      degree_id: null,
      specialization_id: null,
      is_current: false,
    };

    setEduDataLoading(true);
    try {
      const res = await praveshAxiosPostReq(url, tempEducation);

      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setEducation([...education, res?.data?.data?.data]);
        handleClose();
      } else {
        alert.error(res?.data?.error);
      }
      setEduDataLoading(false);
    } catch (error) {
      setEduDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const patchEducation = async (newEducation, eduId, index, handleClose) => {
    const url = `api/saas/user_education/${eduId}/?user_id=${userId}`;

    var newDegreeId = newEducation?.degree_id;
    var newSpecializationId = newEducation?.specialization_id;
    var tempEducation = {
      ...newEducation,
      education_level: newEducation.education,
      start_date: moment(newEducation.start_date).format("yyyy-MM-DD"),
      end_date: moment(newEducation.end_date).format("yyyy-MM-DD"),
      specialization: newSpecializationId,
      degree: newDegreeId,
      college_name:
        newEducation?.college_id && newEducation?.college_id !== 142
          ? newEducation?.college_name
          : newEducation?.temp_college_name,
      degree_id: null,
      specialization_id: null,
    };
    delete tempEducation.education_id;
    setEduDataLoading(true);
    try {
      const res = await praveshAxiosPatchReq(url, tempEducation);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        let items = [...education];
        items[index] = res?.data?.data?.data;
        setEducation(items);
        handleClose();
      } else {
        alert.error(res?.data?.error);
      }
      setEduDataLoading(false);
    } catch (error) {
      setEduDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const deleteEducation = async (eduId, index) => {
    const url = `api/saas/user_education/${eduId}/?user_id=${userId}`;
    setEduDataLoading(true);
    try {
      const res = await praveshAxiosDeleteReq(url);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setEducation((edu) => edu.filter((_, eduIndex) => eduIndex !== index));
      } else {
        alert.error(res?.data?.error);
      }
      setEduDataLoading(false);
    } catch (error) {
      setEduDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const addProject = async (project, handleClose, type) => {
    const url = `api/saas/user_project/?user_id=${userId}`;
    const data = {
      ...project,
      type: type,
    };
    type === "Project" ? setProjectDataLoading(true) : setPubDataLoading(true);
    try {
      const res = await praveshAxiosPostReq(url, data);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        const newProject = {
          ...data,
          id: res?.data?.data?.project_id,
        };
        type === "Project"
          ? setProjects([...projects, newProject])
          : setPublications([...publications, newProject]);

        handleClose();
      } else {
        alert.error(res?.data?.error);
      }
      type === "Project"
        ? setProjectDataLoading(false)
        : setPubDataLoading(false);
    } catch (error) {
      type === "Project"
        ? setProjectDataLoading(false)
        : setPubDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };
  const patchProject = async (project, projectId, index, handleClose, type) => {
    const url = `api/saas/user_project/${projectId}/?user_id=${userId}`;

    type === "Project" ? setProjectDataLoading(true) : setPubDataLoading(true);
    try {
      const res = await praveshAxiosPatchReq(url, project);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        let items = [...projects];
        items[index] = project;
        type === "Project" ? setProjects(items) : setPublications(items);
        handleClose();
      } else {
        alert.error(res.data.error);
      }
      type === "Project"
        ? setProjectDataLoading(false)
        : setPubDataLoading(false);
    } catch (error) {
      type === "Project"
        ? setProjectDataLoading(false)
        : setPubDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const deleteProject = async (projectId, index, type) => {
    const url = `api/saas/user_project/${projectId}/?user_id=${userId}`;
    try {
      const res = await praveshAxiosDeleteReq(url);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        type === "Project"
          ? setProjects((projects) =>
              projects.filter((_, projectIndex) => projectIndex !== index)
            )
          : setPublications((publications) =>
              publications.filter((_, projectIndex) => projectIndex !== index)
            );
      } else {
        alert.error(res.data.error);
      }
    } catch (error) {
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };
  const postResume = async (newResume) => {
    const url = `api/saas/user/resume/?user_id=${userId}`;
    setResumeDataLoading(true);
    const fd = new FormData();
    fd.append("resume", newResume);
    fd.append("user_id", userId);
    try {
      const res = await praveshAxiosPostReq(url, fd);

      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setResume(res?.data?.data?.resume_link);
        setResumeName(res?.data?.data?.resume_name);
        setUploadedResume(null);
        /* setResumeRefresh(!resumeRefresh); */
      } else {
        alert.error(res.data.error);
      }
      setResumeDataLoading(false);
    } catch (error) {
      setResumeDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const postHobbies = async (hobbies) => {
    const url = `api/saas/student_user_extras/?user_id=${userId}`;
    const data = {
      hobbies: hobbies,
      user: userId,
    };
    setHobbieDataLoading(true);
    try {
      const res = await praveshAxiosPostReq(url, data);

      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
      } else {
        alert.error(res?.data?.error);
      }
      setHobbieDataLoading(false);
    } catch (error) {
      setHobbieDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const postAchievements = async (achievements, from) => {
    const url = `api/saas/student_user_extras/?user_id=${userId}`;
    let data = {
      achievements: achievements,
      user: userId,
    };

    if (from === "extra_curricular_activites") {
      data = {
        extra_curricular_activity: achievements,
        user: userId,
      };
      setLoadingExtraCurricular(true);
    } else {
      setAchievementDataLoading(true);
    }

    try {
      const res = await praveshAxiosPostReq(url, data);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
      } else {
        alert.error(res.data.error);
      }
    } catch (error) {
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    } finally {
      setLoadingExtraCurricular(false);
      setAchievementDataLoading(false);
    }
  };
  const postSkills = async (skills) => {
    const url = `/api/saas/user_skill/?user_id=${userId}`;
    const data = {
      skill: skills,
      user: userId,
    };
    setSkillsDataLoading(true);
    try {
      const res = await praveshAxiosPostReq(url, data);

      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
      } else {
        alert.error(res?.data?.error);
      }
      setSkillsDataLoading(false);
    } catch (error) {
      setSkillsDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const postLanguages = async (languages) => {
    const url = `api/saas/student_user_extras/?user_id=${userId}`;
    const data = {
      languages: languages,
      user: userId,
    };
    setLangDataLoading(true);
    try {
      const res = await praveshAxiosPostReq(url, data);

      if (res.data.success) {
        setLanguageData(languages);
        alert.success(res?.data?.data?.message);
      } else {
        alert.error(res?.data?.error);
      }
      setLangDataLoading(false);
    } catch (error) {
      setLangDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const addSocialLinks = async (newSocialLink) => {
    const url = `api/saas/user_links/?user_id=${userId}`;
    const data = {
      profile_url: newSocialLink,
    };
    setLinkDataLoading(true);
    try {
      const res = await praveshAxiosPostReq(url, data);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
      } else {
        alert.error(res.data.error);
      }
      setLinkDataLoading(false);
    } catch (error) {
      setLinkDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const basicInfoPatchData = async (formData) => {
    const url = `api/saas/student_details_edit/?user_id=${userId}`;
    setBasicInfoDataLoading(true);
    try {
      const res = await praveshAxiosPostReq(url, formData);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setStudentData({
          ...studentData,
          first_name: res?.data?.data?.first_name,
          last_name: res?.data?.data?.last_name,
        });
      } else {
        alert.error(res?.data?.error);
      }
      setBasicInfoDataLoading(false);
    } catch (error) {
      setBasicInfoDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const addCertification = async (cerification, handleClose) => {
    const url = `api/saas/user_certifications/?user_id=${userId}`;
    setCertiDataLoading(true);
    const data = {
      ...cerification,
      start_date: cerification?.start_date
        ? moment(cerification?.start_date).format("MM/yyyy")
        : null,
      end_date: cerification?.end_date
        ? moment(cerification?.end_date).format("MM/yyyy")
        : null,
      valid_date:
        !cerification?.expiration && cerification?.valid_date
          ? moment(cerification?.valid_date).format("MM/yyyy")
          : null,
      user_type: 1,
      expiration: cerification?.expiration ? cerification?.expiration : false,
      description: cerification?.description,
    };
    try {
      const res = await praveshAxiosPostReq(url, data);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setStuCertifications([...stuCertifications, res.data.data.data]);
        handleClose();
      } else {
        alert.error(res.data.error);
      }
      setCertiDataLoading(false);
    } catch (error) {
      setCertiDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const patchCertification = async (
    cerification,
    certiId,
    index,
    handleClose
  ) => {
    const url = `api/saas/user_certifications/${certiId}/?user_id=${userId}`;
    setCertiDataLoading(true);
    const data = {
      ...cerification,
      start_date: cerification?.start_date
        ? moment(cerification?.start_date).format("MM/yyyy")
        : null,
      end_date: cerification?.end_date
        ? moment(cerification?.end_date).format("MM/yyyy")
        : null,
      valid_date:
        !cerification.expiration && cerification?.valid_date
          ? moment(cerification?.valid_date).format("MM/yyyy")
          : null,
      description: cerification?.description,
    };
    try {
      const res = await praveshAxiosPatchReq(url, data);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        let items = [...stuCertifications];
        items[index] = res.data.data.data;
        setStuCertifications(items);
        handleClose();
      } else {
        alert.error(res.data.error);
      }
      setCertiDataLoading(false);
    } catch (error) {
      setCertiDataLoading(false);
      alert.error(error);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };
  const deleteCertification = async (certiId, index) => {
    const url = `api/saas/user_certifications/${certiId}/?user_id=${userId}`;
    setCertiDataLoading(true);
    try {
      const res = await praveshAxiosDeleteReq(url);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setStuCertifications((certi) =>
          certi.filter((_, certiIndex) => certiIndex !== index)
        );
      } else {
        alert.error(res.data.error);
      }
      setCertiDataLoading(false);
    } catch (error) {
      setCertiDataLoading(false);
      alert.error(error);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  return (
    <>
      {/* <Layout> */}
      {!studentData && !loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to={checkForBreadcrumb(
                isEducationGroup,
                isCollegeGroup,
                collegeId,
                "manage-students"
              )}
              style={{ textDecoration: "none" }}
            >
              <Typography color="primary" variant="body2">
                Manage Students
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: "none", cursor: "pointer" }}
              color="inherit"
              to={`/student-profile/${studentData?.first_name?.replace(
                / /g,
                "_"
              )}_${studentData?.last_name?.replace(/ /g, "_")}-${
                studentData?.id
              }`}
            >
              <Typography color="primary" variant="body2">
                {studentData?.first_name + " " + studentData?.last_name}
              </Typography>
            </Link>

            <Typography color="secondary" variant="body2">
              Edit Info
            </Typography>
          </Breadcrumbs>
          <div>
            <br />
            <Grid container spacing={5} className={classes.grid}>
              <Grid item xs={12} sm={6}>
                <BasicInfo
                  studentData={studentData}
                  basicInfoPatchData={basicInfoPatchData}
                  basicInfoDataLoading={basicInfoDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
                <br />
                {/* <Resume
                  data={studentData}
                  userId={userId}
                  resume={resume}
                  postResume={postResume}
                  resumeDataLoading={resumeDataLoading}
                  resumeName={resumeName}
                  setUploadedResume={setUploadedResume}
                  uploadedResume={uploadedResume}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                /> */}
                <GenerateResume 
                    data={studentData}
                    userId={userId}
                    fromProfileUpdate={false}
                />
                <br />
                <ImportantLink
                  socialLinks={socialLinks}
                  postSocialLinks={addSocialLinks}
                  linksDataLoading={linkDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />

                <br />
                <Skills
                  skillsList={skillsList}
                  userSkills={userSkills}
                  postSkills={postSkills}
                  skillsDataLoading={skillsDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Education
                  collegeList={collegeList}
                  boardsList={boardsList}
                  education={education}
                  handleAddEdu={addEducation}
                  handlePatchEdu={patchEducation}
                  handleDeleteEdu={deleteEducation}
                  eduDataLoading={eduDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />

                <br />
                <WorkExperience
                  companiesList={companiesList}
                  skillsList={skillsList}
                  workExperiences={workExperience}
                  handleAddWorkEx={addWorkEx}
                  handlePatchWorkEx={patchWorkEx}
                  handleDeleteWorkEx={deleteWorkEx}
                  workExDataLoading={workExDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
                <br />
                <Project
                  projects={projects}
                  skillsList={skillsList}
                  handleAddProject={addProject}
                  handlePatchProject={patchProject}
                  handleDeleteProject={deleteProject}
                  projectDataLoading={projectDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
                <br />
                <Publications
                  publications={publications}
                  handleAddPublications={addProject}
                  handlePatchPublications={patchProject}
                  handleDeletePublications={deleteProject}
                  pubDataLoading={pubDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
                <br />
                <Certifications
                  cerifications={stuCertifications}
                  handleAddCertifications={addCertification}
                  handlePatchCertifications={patchCertification}
                  handleDeleteCertifications={deleteCertification}
                  certiDataLoading={certiDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
                <br />
                <Hobbies
                  hobbies={hobbies}
                  postHobbies={postHobbies}
                  hobbieDataLoading={hobbieDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
                <br />
                <Languages
                  languageData={languageData}
                  postLanguages={postLanguages}
                  langDataLoading={langDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
                <br />
                <Achievements
                  achievements={achievements}
                  postAchievements={postAchievements}
                  achivementDataLoading={achievementDataLoading}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
                <br />
                <Achievements
                  from={"Extra Curricular Activities"}
                  achievements={extraCurricularActivities}
                  postAchievements={(data) => {
                    postAchievements(data, "extra_curricular_activites");
                  }}
                  achivementDataLoading={loadingExtraCurricular}
                  approvalRequestCounts={approvalRequestCounts}
                  redirectionData={{
                    userId: userId,
                    name: studentData?.first_name,
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
export default EditStudents;
