import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  collegeGroupListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { EDGTeamManageAPI } from "../../../api/University/APIs/action";
import UplacementCycle from "../../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../../components/CircularProgressBar";
import { BackendBaseApi } from "../../../constants/constants";
function UniversityTeamManage() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const checkForParams = () => {
    if (isEducationGroup && collegeGroupList?.length === 0) {
      return `&college_id=${location.college_id[0]}`;
    } else if ((isEducationGroup || isCollegeGroup) && collegeGroupList?.length > 0) {
      return `&group_id=${location.group_id}`;
    } else return ``;
  };
  useEffect(() => {
    var url = `${
      BackendBaseApi.SANCHALAN
    }api/university/university_count/?query=team_member${checkForParams()}`;
    dispatch(EDGTeamManageAPI(setLoading, alert, history, location, url));
  }, []);

  useEffect(() => {
    if (!location.college_id && !location.group_id) {
      history.push(`${isEducationGroup ? `/educational-group` : `/college-group`}/manage-team`);
    }
  }, [location]);

  const collegeTeamList = useSelector((state) => state?.EDGCommon?.edgTeamList);
  return (
    <>
      {/* <UniversityLayout> */}
      <Breadcrumbs aria-label="breadcrumb">
            <Link to={`${isEducationGroup ? `/educational-group` : `/college-group`}/manage-team`} style={{ textDecoration: "none" }}>
              <Typography color="primary" variant="body2">
                Manage Team
              </Typography>
            </Link>
            {/* <Link style={{ textDecoration: "none" }} color="inherit"> */}
            <Typography color="secondary" variant="body2">
              View Teams in Group
            </Typography>
            {/* </Link> */}
          </Breadcrumbs>
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Typography variant="h2">Group Wise Teams</Typography>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={5}>
              {collegeTeamList?.length > 0 &&
                collegeTeamList?.map((item) => (
                  <Grid item xs={4}>
                    <UplacementCycle
                      item={item}
                      countTitle={"Team Members"}
                      count={item?.count}
                      link={`/manage-team`}
                      linkTitle={"View team Info"}
                      from={"Teams"}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityTeamManage;
