import { JOB_UPLOADER_FIELDS, SELECTED_JOB_UPLOADER_FIELDS } from "./action";

export const JobUploader = (state = {}, action) => {
  switch (action.type) {
    case JOB_UPLOADER_FIELDS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const JobFields = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_JOB_UPLOADER_FIELDS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};
