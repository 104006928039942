import { Breadcrumbs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { IndustryGetAPI } from "../../api/Common/action";
import { SingleCompanyGetAPI } from "../../api/Company/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  userRolesActionsFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import EditCompany from "../../components/Form/CompanyData/EditCompanyForm";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";

function handleClick(event) {
  event.preventDefault();
}

function EditCompanyProfile() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  useEffect(() => {
      dispatch(
        SingleCompanyGetAPI(
          setLoading,
          alert,
          history,
          location,
          params?.company_id,
          collegeId
        )
      );
  }, [params?.company_id, collegeId]);

  const companyData = useSelector((state) => state?.CompanyData?.singeCompany);

  const industryList = useSelector((state) => state?.commonData?.industryData);

  const userRolesPermission = useSelector(userRolesActionsFn);

  useEffect(() => {
    dispatch(IndustryGetAPI(alert, history, location));
  }, []);

  return (
    <>
      {/* <Layout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to={checkForBreadcrumb(
                isEducationGroup,
                isCollegeGroup,
                collegeId,
                "manage-companies"
              )}
              color="inherit"
              style={{ textDecoration: "none" }}
            >
              <Typography color="primary" variant="body2">
                Companies
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              color="inherit"
              to={`/company-profile/${companyData?.id}`}
            >
              <Typography color="primary" variant="body2">
                {companyData?.name}
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              href="/getting-started/installation/"
              onClick={handleClick}
            >
              <Typography color="secondary" variant="body2">
                Edit
              </Typography>
            </Link>
          </Breadcrumbs>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Typography variant="h2">Edit Company Profile</Typography>
          </div>
          {companyData && (
            <EditCompany
              companyData={companyData}
              industryList={industryList}
              collegeId={collegeId}
              userRolesPermission={userRolesPermission}
            />
          )}
        </>
      )}
      {/* </Layout> */}
    </>
  );
}

export default EditCompanyProfile;
