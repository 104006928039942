import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
// import Layout from "../../components/Layout";

export default function Testing() {
  return (
    <>
      {/* <Layout> */}
        <div>
          <Link to="/student"> Student Register for Placement</Link>
          <br />
          <Link to="/companyform"> Register your Company</Link>
          <br />
          <Link to="/student-erf"> Student Uploader</Link>
          <br />
          <Link to="/company-erf"> company uploader </Link>
          <br />
          <Link to="/college-profile">College Profile </Link>
          <br />
          <Link to="/course">Course (in progress) </Link>
          <br />
          <Link to="/account">Account setting (in progress) </Link>
          <br />
          <Link to="/thank-you">Thankyou Page</Link>
          <br />
          <Link to="/student-details">Student Details</Link>
          <br />
          <Link to="/manage-job-posting">Manage Job Posting</Link>
          <br />
          <Link to="/manage-jobs">Job Manage</Link>
          <br />
          <Link to="/track">Track</Link>
        </div>
        <br />
        <div>
          <Typography>Sign-Up all Pages</Typography>
          <br />
          <Link to="/resetlink">1.2 Password reset link send </Link>
          <br />
          <Link to="/new-password">1.3 enter new Password </Link>
          <br />
          <Link to="/reset-your-password">1.4 Update New Password </Link>
        </div>
        <br />
        <div>
          <Typography>manage job | Open all Jobs </Typography>
          <br />
          <Link to="/job-details">3.1.0-1 manage job | (in progress)</Link>
        </div>

        <br />
        <div>
          <Typography> 4.1 Company Scroll [pending] </Typography>
          <br />
          <Link to="/companyform">
            4.15 company e-rf sub heading and multiple name add [new update
            pending]
          </Link>
        </div>
        <br />
        <div>
          <Typography>
            {" "}
            5.1 team memder invite form design [pending list] <br />
            5.6 manage team drag and drop
            <br />
            5.8 team member profile
          </Typography>
          <br />
          <Link to="/manage-team">5.2 manage team dialog</Link>
          <br />
        </div>

        <br />
        <div>
          <Typography>
            6.0 Manage student [pending]
            <br /> 6.1 student detail page [complete]
          </Typography>
          <br />
          <Link to="/student-details">student -detail</Link>
          <br />
          <Link to="/track">6.8 track </Link>
          <br />
        </div>
        <br />
        <div>
          <Typography>7 create ejd</Typography>
          <br />
          <Link to="/manage">ejd</Link>
          <br />
          <Link to="/ejd-company">7.4 ejd design </Link>
          <br />
        </div>

        <br />
        <div>
          <Typography>8.0 student uploder</Typography>
          <br />
          <Link to="/student-erf">
            student uploder [ update 3 cards on top]
          </Link>
          <br />
          <Link to="/student">8.1 student form design sub heading add</Link>
          <br />
        </div>

        <br />
        <div>
          <Typography>9.0 company uploder</Typography>
          <br />
          <Link to="/company-erf">company uploder </Link>
          <br />
          <Link to="/companyform">9.1 company registration form</Link>
          <br />
        </div>
        <br />
        <div>
          <Typography>10 manage job posting</Typography>
          <br />
          <Link to="/job-posting-form">10 manage job posting </Link>
          <br />
        </div>
        <br />
        <div>
          <Typography>11 Contact us</Typography>
          <br />
          <Link to="/contact"> 11 contact Us </Link>
          <br />
          <Link to="/contact-thankyou">11.1 contact Thank You</Link>
          <br />
        </div>
        <div>
          <Typography>12 Help</Typography>
          <br />
          <Link to="/help"> Help page </Link>
          <br />
        </div>
        <div>
          <Typography>13 College Profile</Typography>
          <br />
          <Link to="/college-profile">
            13.0 college-profile [color picker section pending]{" "}
          </Link>
          <br />
          <Link to="/college-profile">
            13.0.1 company detail page [pending]
          </Link>
          <br />
          <Link to="/course">13.2 course</Link>
          <br />
          <Link to="/setting">13.4.1 setting </Link>
          <br />
          <Link to="/account">13.5 account setting [user pic missing] </Link>
        </div>
      {/* </Layout> */}
    </>
  );
}
