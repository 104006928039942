import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SaveProject from "./SaveProjcet";
import AddProjectDialog from "./AddProjectDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  newBtnStyle: {
    borderRadius: "8px",
    height: "40px",
  },
}));

export default function Project({
  projects,
  handleAddProject,
  skillsList,
  handlePatchProject,
  handleDeleteProject,
  projectDataLoading,
  approvalRequestCounts,
  redirectionData,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [tempProjects, setTempProjects] = useState([]);
  const [addProject, setAddProject] = useState({ type: "Project" });
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (projects) setTempProjects(projects);
  }, [projects]);

  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setAddProject({});
    setOpen(false);
  };

  const saveProject = () => {
    isEdit
      ? handlePatchProject(
          addProject,
          addProject?.id,
          editIndex,
          handleClose,
          "Project"
        )
      : handleAddProject(addProject, handleClose, "Project");
  };

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Projects</Typography>
        <br />
        <Grid
          item
          xs={12}
          style={
            approvalRequestCounts?.PROJ
              ? { pointerEvents: "none", opacity: 0.4 }
              : {}
          }
        >
          {tempProjects &&
            tempProjects.map((project, index) => {
              return (
                <SaveProject
                  data={project}
                  projectIndex={index}
                  setAddProject={setAddProject}
                  handleDelete={handleDeleteProject}
                  handleOpen={handleClickOpen}
                  setIsEdit={setIsEdit}
                  setEditIndex={setEditIndex}
                />
              );
            })}
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
          {approvalRequestCounts?.PROJ ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.newBtnStyle}
              onClick={() => {
                history.push({
                  pathname: `/student-profile-request/${redirectionData?.name.replace(
                    / /g,
                    "_"
                  )}-${redirectionData?.userId}`,
                });
              }}
            >
              {`Approve Request (${approvalRequestCounts?.PROJ ?? 0})`}
            </Button>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="text"
                color="primary"
                className={classes.newBtnStyle}
                onClick={handleClickOpen}
                style={{ display: "flex" }}
              >
                Add Project
              </Button>
            </div>
          )}
        </Grid>
      </div>
      <AddProjectDialog
        open={open}
        editProject={addProject}
        setEditProject={setAddProject}
        handleClose={handleClose}
        saveProject={saveProject}
        projectDataLoading={projectDataLoading}
        isEdit={isEdit}
        skillsList={skillsList}
      />
    </>
  );
}
