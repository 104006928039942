import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableFooter,
  TableHead,
  IconButton,
  TableContainer,
  Paper,
  TablePagination,
  useTheme,
  styled,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D2E3FF",
    color: theme.palette.common.black,
    border: "1px solid #CBD5E1",
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #CBD5E1",
    color: "#6C757D",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: "1px solid #CBD5E1",
    // border: 0,
  },
}));

function createData(
  credit_order,
  sgst,
  gst,
  total_tax,
  total_amount,
  status,
  date
) {
  return {
    credit_order,
    sgst,
    gst,
    total_tax,
    total_amount,
    status,
    date,
  };
}

const rows = [
  createData(
    1726,
    "30%",
    "40%",
    "3%",
    54635,
    "Payment Completed",
    "08-05-2024 at12:32 PM"
  ),
  createData(
    1726,
    "30%",
    "40%",
    "3%",
    54635,
    "Payment Cancelled",
    "08-05-2024 at12:32 PM"
  ),
  createData(
    1726,
    "30%",
    "40%",
    "3%",
    54635,
    "Payment Pending",
    "08-05-2024 at12:32 PM"
  ),
];

export default function PaymentHistoryTable({
  rechargeData,
  totalCount,
  page,
  setPage,
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    // const handleFirstPageButtonClick = (event) => {
    //   onPageChange(event, 0);
    // };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    // const handleLastPageButtonClick = (event) => {
    //   onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    // };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        {/* <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton> */}
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        {/* <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton> */}
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: "100%",
          boxShadow: "none",
        }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell rowSpan={2}>Credit Order</StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              SGST
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              GST
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Total Tax
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Total Amount
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Order Status
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Date & Time
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rechargeData.length === 0 ? (
            <StyledTableRow style={{ height: "250px" }}>
              <StyledTableCell colSpan={8} align="center">
                No data present
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            rechargeData
              .slice(
                rowsPerPage == -1 ? 0 : page * rowsPerPage,
                rowsPerPage == -1
                  ? rechargeData.length
                  : page * rowsPerPage + rowsPerPage
              )
              .map((row) => (
                <StyledTableRow key={row?.name}>
                  <StyledTableCell component="th" scope="row">
                    {row?.transaction_id}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row?.sgst}</StyledTableCell>

                  <StyledTableCell align="center">{row?.gst}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.total_tax}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.total_amount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color:
                        row?.order_status == "PAYMENT_COMPLETED"
                          ? "#029E73"
                          : row?.order_status == "PAYMENT_CANCELLED"
                          ? "#2D81F7"
                          : row?.order_status == "PAYMENT_FAILED"
                          ? "#CD393E"
                          : row?.order_status == "PAYMENT_PENDING"
                          ? "#E0A800"
                          : "#6C757D",
                    }}
                  >
                    {row?.order_status}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.order_date}
                  </StyledTableCell>
                </StyledTableRow>
              ))
          )}
        </TableBody>
        <TableFooter style={{ position: "relative", width: "100%" }}>
          <TableRow style={{}}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              // colSpan={3}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              style={{
                paddingRight: "34%",
              }}
              // className={classes.Pagination}
              // classes={{
              //   // root: classes.Pagination,
              //   caption: classes.PaginationCaption,
              //   selectIcon: classes.PaginationSelectIcon,
              //   select: classes.PaginationSelect,
              //   actions: classes.PaginationActions,
              // }}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
