import { IconButton, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DeleteJob from "../../DialogSection/DeleteJobDialog/DeleteJob";
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    color: "#fff",
    border: "1px solid #e2e6ea",
    backgroundColor: theme.palette.primary.main,
    marginBottom: "20px",
  },

  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  cardsdetails: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
}));

export default function FullTimeJob({ item,
  setCurrentItem,
  currentItemIndex,
  setJobDetails,
  setCurrentItemButtonClicked,
  setCurrentItemIndex,
  index,
  selectedCourses,
  setDataSelectedNewFormat,
  setCoursesArray,
  setPushArray,
  pushArray,
  setDataSelected,
  coursesArray,
  setSpecializationInDgegreeId,
  ejdJobsList,
  setEjdJobsList,
  companyDetails,
  currentItem,
  companyList, 
  setCompanyDetails,
  setPocList, 
  disableCompany, 
  setApplicationDates,
  setCurrentJobLocation,
  preSelectedJD,
  setPreSelectedJD,setJdPath = () => {},
 
}) {

    const companyName = ejdJobsList[0].companyDetails?.company_name ? ejdJobsList[0].companyDetails?.company_name : ejdJobsList[0]?.companyDetails?.company_object?.name;

     
     let ctc;
    //  let notDisclosed = item?.college_job_data?.ctc_max  ? false : true;
     let notDisclosed = item?.college_job_data?.salary_type ==='NOT DISCLOSED'  ? true : false;

     if (!notDisclosed){
      ctc = `CTC :${(item?.college_job_data?.ctc_min/100000).toFixed(2) } LPA-${(item?.college_job_data?.ctc_max/100000).toFixed(2)}  LPA`;
     }
     else {
       ctc = "CTC : Not Disclosed";
     };

    const data = [
      {
        icon: <BadgeOutlinedIcon style={{ color: "#fff" }} />,
        title: "Job Type : Full Time",
      },
      {
        icon: <CameraFrontIcon style={{ color: "#fff" }} />,
        title: `Company Name : ${companyName}`,
      },
      {
        icon: <CurrencyRupeeIcon style={{ color: "#fff" }} />,
                
        title: ctc,
      },
      {
        icon: <InsertInvitationOutlinedIcon style={{ color: "#fff" }} />,
        title: `Application End Date : ${new Date(item?.college_job_data?.application_end_date).toLocaleString().split(',')[0]}`,
      },
    ];

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if(!(currentItemIndex !== null && currentItemIndex >= 0))
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkForSpec = (item_n, deg_id, edu_id, spec_arr) => {
    var bool_ = false;
    var arr = [...item?.college_job_data?.degree_spec_selected];
    if (item?.college_job_data?.degree_spec_selected?.length > 0) {
      var edu_index = arr.findIndex((i) => i.education_id === edu_id);
      if (edu_index !== -1) var deg_index = arr[edu_index].degrees.findIndex((i) => i.degree_id === deg_id);

      if (edu_index !== -1 && deg_index !== -1) {
        arr[edu_index].degrees[deg_index].specialization.map((items) => {
          if (items.specialization_id === item_n.specialization_id) bool_ = true;
          // else bool_ = false;
        });
      }
    } else bool_ = false;

    return bool_;
  };

  const checkForDegree = (item_n) => {
    var bool_ = false;

    var arr = [...item?.college_job_data?.degree_spec_selected];
    if (item?.college_job_data?.degree_spec_selected?.length > 0) {
      arr.map((i) => {
        i.degrees.map((internal) => {
          if (internal.degree_id === item_n.degree_id) {
            if (internal.specialization.length > 0) bool_ = true;
            else bool_ = false;
          }
        });
      });
    } else bool_ = false;

    return bool_;
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{item?.college_job_data?.job_title}</Typography>

          <div style={{ display: "flex" }}>

           <IconButton 
            onClick={() => {
              var arr = [...selectedCourses];
                  arr.map((item) => {
                    item.degrees.forEach((item2) => {
                      item2.select_all = checkForDegree(item2);
                      item2.specialization.forEach((item3) => {
                        item3.selected = checkForSpec(item3, item2.degree_id, item.education_id, item2.specialization);
                      });
                    });
                  });
                  setCoursesArray(arr);
                  setDataSelectedNewFormat(item?.college_job_data?.degree_spec_selected);

                  var arr1 = [];
                  item?.college_job_data?.degree_spec_selected?.map((item1) => {
                    item1.degrees.map((i) => arr1.push(i));
                  });
                  setDataSelected(arr1);

                  var push_arr = [...pushArray];
                  push_arr.push(1);
                  setPushArray(push_arr);

                  var temp = {};
                  arr1.forEach((item) => {
                    temp[item.degree_id] = item.specialization;
                  });
                  coursesArray.forEach((item) => {
                    item?.degrees.forEach((item2) => {
                      if (!Object.keys(temp).includes(String(item2.degree_id))) temp[item2.degree_id] = [];
                    });
                  });
                  setSpecializationInDgegreeId(temp);
                  setJobDetails(item?.college_job_data);
                  setPreSelectedJD(item?.college_job_data?.job_description);
                  setCompanyDetails(item?.companyDetails);
                  setPocList(item?.companyDetails?.poc_list);
                  setApplicationDates({ applicationStartDate: item?.application_start_date,
                                        applicationEndDate:item?.application_end_date,
                                        joiningDate:item?.college_job_data?.joining_date,
                                        campusDriveDate:item?.college_job_data?.campus_drive_date,                                      
                                      });
                  setCurrentJobLocation(item?.college_job_data?.job_location);
                  setJdPath(item?.college_job_data?.document_for_jd)
                  // setCurrentBatch();
                }}                             
                        
            style={{ marginRight: "10px" }}>
            <FileCopyOutlinedIcon style={{ color: "#fff" }}/>  
                  
            </IconButton>

            <IconButton  
            onClick={() => {
                  setCurrentItem(item?.college_job_data);
                  setCurrentItemButtonClicked(true);
                  setCurrentItemIndex(index);
                  var arr = [...selectedCourses];
                  arr.map((item) => {
                    item.degrees.forEach((item2) => {
                      item2.select_all = checkForDegree(item2);
                      item2.specialization.forEach((item3) => {
                        item3.selected = checkForSpec(item3, item2.degree_id, item.education_id, item2.specialization);
                      });
                    });
                  });
                  setCoursesArray(arr);
                  setDataSelectedNewFormat(item?.college_job_data?.degree_spec_selected);

                  var arr1 = [];
                  item?.college_job_data?.degree_spec_selected?.map((item1) => {
                    item1.degrees.map((i) => arr1.push(i));
                  });
                  setDataSelected(arr1);

                  var push_arr = [...pushArray];
                  push_arr.push(1);
                  setPushArray(push_arr);

                  var temp = {};
                  arr1.forEach((item) => {
                    temp[item.degree_id] = item.specialization;
                  });
                  coursesArray.forEach((item) => {
                    item?.degrees.forEach((item2) => {
                      if (!Object.keys(temp).includes(String(item2.degree_id))) temp[item2.degree_id] = [];
                    });
                  });
                  setSpecializationInDgegreeId(temp);
                  setCompanyDetails(item?.companyDetails);
                  setPocList(item?.companyDetails?.poc_list);
                  setApplicationDates({ applicationStartDate: item?.application_start_date,
                                        applicationEndDate:item?.application_end_date,
                                        joiningDate:item?.college_job_data?.joining_date,
                                        campusDriveDate:item?.college_job_data?.campus_drive_date, 
                                      });
                  setPreSelectedJD(item?.college_job_data?.job_description);
                  setCurrentJobLocation(item?.college_job_data?.job_location);
                  setJdPath(item?.college_job_data?.document_for_jd);
                }}
            
            >
            <EditIcon style={{ color: "#fff" }} />
            </IconButton>
            <IconButton onClick={handleClickOpen} disabled={(currentItemIndex !== null && currentItemIndex >= 0)}>
              <DeleteIcon style={{ color: "#fff" }} />
            </IconButton>
          </div>
        </div>
        {data.map((val, ind) => (
          <div className={classes.cardsdetails}>
            <div style={{ display: "flex" }}>{val.icon}</div>
            <Typography
              variant="body2"
              color="inherit"
              style={{ marginLeft: "10px" }}
            >
              {val.title}
            </Typography>
          </div>
        ))}
      </Paper>
      <DeleteJob setEjdJobsList={setEjdJobsList} 
                ejdJobsList={ejdJobsList} 
                open={open} 
                handleClose={handleClose} 
                index={index}/>
    </>
  );
}


