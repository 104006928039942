import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import PostJob from ".";
import { niyuktiAxiosGetReq } from "../../api/BaseApi/apiBase";
import {
  SINGLE_JOB_DATA,
  DISABLE_FIELDS_POST_JOB,
} from "../../api/JobPost/action";
import {
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import { generateRandomId } from "../../utility/generateRandomId";
import { groupViewCheck } from "../../utility/groupViewCheck";
import {
  uniqueArrayWithId,
  uniqueArrayWithoutId,
} from "../../utility/uniqueArray";

const CloneRecommended = () => {
  const params = useParams();
  const companyList = useSelector((state) =>
    state?.AllCompany?.details ? state?.AllCompany?.details : []
  );
  const collegeId = useSelector(collegeIdFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const alert = useAlert();
  const dispatch = useDispatch();
  const jobRoundList = useSelector((state) =>
    state?.AllJobRounds?.details ? state?.AllJobRounds?.details : []
  );

  const [selectedJobData, setSelectedJobData] = useState(null);

  const [existingCompanyData, setExistingCompanyData] = useState(null);
  const [niyuktiJobData, setNiyuktiJobData] = useState(null);
  const industryList = useSelector((state) => state?.commonData?.industryData);

  const [dataLoading, setDataLoading] = useState(false);

  const initialDisableFields = {
    company: false,
    job_type: false,
    textfield: false,
    autocomplete: false,
    application_date: false,
    date_single: false,
    campus_drive_date: false,
    desc: false,
    ctc_range: false,
    editor: false,
    location: false,
    vacancy: false,
    duration: false,
    ppo: false,
    courses: false,
    skills: false,
    graduation_grade: false,
    school_grade: false,
    batch: false,
    selection: false,
    documentsRequired: false,
  };

  const disableFields = useRef(initialDisableFields);

  const handleNiyuktiData = () => {
    let matchFound;
    let CompanyData = null;

    const is_companyExistingInList = () => {
      let tempWebAddress = niyuktiJobData?.company?.company_website;

      if (!tempWebAddress) {
        alert.error("Website Detail Missing!");
        return;
      }
      // these lines are written for test cases clearnace if proper details are missing.
      if (tempWebAddress.includes("www")) {
        tempWebAddress = tempWebAddress.replace("www.", "");
      }
      if (
        !(tempWebAddress.includes("http") || tempWebAddress.includes("https"))
      ) {
        tempWebAddress = `https://${tempWebAddress}`;
      }
      // console.log("tempWebAddress", tempWebAddress);
      let domain = new URL(tempWebAddress);
      domain = domain?.hostname;

      if (companyList) {
        matchFound = companyList.findIndex((item) => {
          const webAdd = item?.company_website?.toLowerCase();

          return webAdd?.includes(domain);
        });
      }
      let company_details = null;

      if (matchFound === -1) {
        company_details = {
          company_name: niyuktiJobData?.company_name,
          company_website: niyuktiJobData?.company_website,
          industry_id: niyuktiJobData?.industry?.id,
          industry_name: niyuktiJobData?.industry?.name,
          industry_type_id: niyuktiJobData?.industry_type_id,
          industry_type_name:
            niyuktiJobData?.industry_type_id === 1
              ? "Private"
              : niyuktiJobData?.industry_type_id === 2
              ? "Government"
              : "Semi-Government",
        };
        // default poc for company to update data.
        CompanyData = {
          college_id: groupViewCheck(
            isEducationGroup,
            isCollegeGroup,
            collegeId
          )
            ? niyuktiJobData?.college_list.map((item) => item.id)
            : [collegeId],
          company_form_data: company_details,
          poc_list: [
            {
              contact_person_name: "GetWork",
              email: `prabha${Date.now()}@getwork.org`,
              phone: 9876543211,
              designation: "Company",
              is_validated: true,
            },
          ],
        };
      } else {
        setExistingCompanyData(companyList[matchFound]);

        disableFields.current = { ...disableFields.current, company: true };
      }
    };

    is_companyExistingInList();

    const getSkillsArray = (arrData) => {
      const newArr = arrData?.map((item) => {
        return {
          name: item?.skill_name,
          id: item?.skill_id,
          ...item,
        };
      });

      return newArr;
    };

    const getIndustry = () => {
      // let industryMatched;
      if (industryList) {
        let industryMatched = industryList?.filter((item) => {
          // console.log(item?.pravesh_id, 'match id', niyuktiJobData?.company?.industry_id);
          return item?.pravesh_id === niyuktiJobData?.company?.industry_id;
        });
        // console.log(industryMatched, "lets match");
        return industryMatched[0];
      }
    };

    let recommendedJobData = {
      company_id:
        (matchFound || matchFound === 0) && matchFound !== -1
          ? companyList[matchFound]?.id
          : null,
      company_name: niyuktiJobData?.company?.company_name,
      company:
        (matchFound || matchFound === 0) && matchFound !== -1
          ? companyList[matchFound]
          : null,
      companyCreation: CompanyData,
      job_title: niyuktiJobData?.job_title,
      job_type_id: niyuktiJobData?.job_type,
      job_type_name: niyuktiJobData?.job_type_name,
      job_role_id: niyuktiJobData?.job_role,
      job_role_name: niyuktiJobData?.job_role_name,
      application_start_date: niyuktiJobData?.apply_start_date,
      application_end_date: niyuktiJobData?.apply_end_date,
      joining_date: niyuktiJobData?.job_duration_start,
      job_description: niyuktiJobData?.job_description,
      ctc_min: niyuktiJobData?.ctc_min,
      ctc_max: niyuktiJobData?.ctc_max,
      bonus: false,
      salary_type: niyuktiJobData?.salary_type || "NOT DISCLOSED",
      salary_breakup: null,
      wfh: niyuktiJobData?.work_from_home,
      pan_india: niyuktiJobData?.job_location?.length > 0 ? false : true,
      job_location: uniqueArrayWithId(niyuktiJobData?.job_location, "city_id"),
      vacancy: niyuktiJobData?.vacancy,
      service_bond: niyuktiJobData?.service_bond,
      niyukti_job_id: niyuktiJobData?.id,
      niyukti_company_id: niyuktiJobData?.company?.company_id,
      conditions: niyuktiJobData?.bond_condition ?? "",
      ppo: niyuktiJobData?.ppo,
      ppo_ctc_min: niyuktiJobData?.ppo_ctc_min,
      // ppo_salary_type: "NOT DISCLOSED",
      ppo_ctc_max: niyuktiJobData?.ppo_ctc_max,
      // salary_breakup_ppo: null,
      // degree_spec_selected: [],
      // grad_scoring: isGradeSystem ? "CGPA" : "Percentage",
      grad_min_score: niyuktiJobData?.eligibility_criteria?.["percentage"],
      backlog: niyuktiJobData?.backlog,
      eligible_batches: uniqueArrayWithoutId(
        niyuktiJobData?.eligibility_criteria?.graduation_years?.map((item) =>
          parseInt(item)
        )
      ),
      // rounds: getRoundsSynched(niyuktiJobData?.job_rounds),
      documents: niyuktiJobData?.documents,
      skills: getSkillsArray(niyuktiJobData?.eligibility_criteria?.["skills"]),
      companyDetails: niyuktiJobData?.company,
      industry: getIndustry(),
    };
    if (niyuktiJobData?.job_role) {
      recommendedJobData = {
        ...recommendedJobData,
        job_role: {
          id: niyuktiJobData?.job_role,
          job_role_name: niyuktiJobData?.job_role_name,
          name: niyuktiJobData?.job_role_name,
        },
      };
    }
    if (niyuktiJobData?.job_segment) {
      recommendedJobData = {
        ...recommendedJobData,
        job_segment: {
          id: niyuktiJobData?.job_segment ? niyuktiJobData?.job_segment : 0,
          job_segment_id: niyuktiJobData?.job_segment
            ? niyuktiJobData?.job_segment
            : 0,
          job_segment_name: niyuktiJobData?.job_segment_name
            ? niyuktiJobData?.job_segment_name
            : "",
          name: niyuktiJobData?.job_segment_name
            ? niyuktiJobData?.job_segment_name
            : "",
        },
      };
    }

    setSelectedJobData((prevData) => ({ ...prevData, ...recommendedJobData }));
    // object for disabling fields in post job as per data we have obtained

    disableFields.current = {
      ...disableFields.current,
      job_title: !!niyuktiJobData?.job_title || false,
      job_role:
        !!(niyuktiJobData?.job_role_name && niyuktiJobData?.job_role) || false,
      application_date: !!niyuktiJobData?.apply_start_date || false,
      application_end_date: !!niyuktiJobData?.apply_end_date || false,
      joining_date: !!niyuktiJobData?.job_duration_start || false,
      desc: !!niyuktiJobData?.job_description || false,
      ctc_range:
        !!(niyuktiJobData?.ctc_min && niyuktiJobData?.ctc_max) || false,
      salary_breakup: false,
      salary_type: !!niyuktiJobData?.salary_type || false,
      location: niyuktiJobData?.job_location?.length > 0 || false,
      vacancy: !!niyuktiJobData?.vacancy || false,
      wfh: !!niyuktiJobData?.work_from_home || false,
      pan_india: niyuktiJobData?.job_location?.length === 0 || false,
      grad_min_score:
        !!niyuktiJobData?.eligibility_criteria?.["percentage"] || false,
      backlog: niyuktiJobData?.backlog || false,
      duration: false,
      ppo: false,
      skills:
        niyuktiJobData?.eligibility_criteria?.["skills"]?.length > 0 || false,
      graduation_grade: false,
      school_grade: false,
      batch:
        niyuktiJobData?.eligibility_criteria?.graduation_years?.length > 0 ||
        false,
      rounds: niyuktiJobData?.job_rounds?.length > 0 || false,
      documentsRequired: niyuktiJobData?.documents?.length > 0 || false,
      job_segment: !!niyuktiJobData?.job_segment || false,
      vacancy: !!niyuktiJobData?.vacancy || false,
      service_bond: !!niyuktiJobData?.service_bond || false,
      service_bond_conditions: !!niyuktiJobData?.bond_condition || false,
    };

    dispatch({
      type: DISABLE_FIELDS_POST_JOB,
      payload: disableFields.current,
    });
  };

  useEffect(() => {
    if (niyuktiJobData && companyList) {
      handleNiyuktiData();
    }
  }, [companyList, niyuktiJobData, industryList]);

  useEffect(() => {
    if (niyuktiJobData && jobRoundList?.length > 0) {
      const getRoundsSynched = () => {
        let matchedRoundsArr = jobRoundList.filter((item) => {
          let match = niyuktiJobData?.job_rounds?.findIndex(
            (round) => parseInt(item?.niyukti_id) === round?.round
          );
          return match === -1 ? false : true;
        });
        matchedRoundsArr = matchedRoundsArr?.map((item) => ({
          ...item,
          round_id: item?.id,
          round_code: item?.code,
          round_name: item?.name,
          // getOccurrence(niyuktiJobData?.rounds, item?.id),
          duration: 0,
          // type: '',
          // venue: '',
          random_round_id: generateRandomId(8),
        }));

        return matchedRoundsArr;
      };
      setSelectedJobData((prevData) => ({
        ...prevData,
        rounds: getRoundsSynched(niyuktiJobData?.job_rounds),
      }));
    }
  }, [jobRoundList]);

  useEffect(() => {
    dispatch({
      type: SINGLE_JOB_DATA,
      payload: selectedJobData,
    });
  }, [selectedJobData]);

  const getRecommendedJobDetails = async (jobRecommendedId) => {
    if (!collegeId) return;
    const endPoint = `job/college_jobs/job_details/?college_id=${collegeId}&job_id=${jobRecommendedId}`;

    try {
      setDataLoading(true);
      const res = await niyuktiAxiosGetReq(endPoint);

      if (res?.data?.success) {
        const data = res?.data?.data;

        setNiyuktiJobData(data);
      } else {
        alert.error(res?.data?.error);
      }
      setDataLoading(false);
    } catch (error) {
      // throw (error);
      setDataLoading(false);
      alert.error("Something went wrong!!");
    }
  };

  useEffect(() => {
    const jobId = params?.job_id?.split("-")[1];

    if (params?.job_id) {
      getRecommendedJobDetails(jobId);
    }
  }, []);

  return (
    <div>
      {dataLoading ? (
        <CustomCircularProgress />
      ) : (
        selectedJobData && (
          <PostJob selectedJobData={selectedJobData} isRecommended={true} />
        )
      )}
    </div>
  );
};

export default CloneRecommended;
