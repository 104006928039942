import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const EJD_FIELDS = "EJD_FIELDS";
export const SELECTED_EJD_FIELDS = "SELECTED_EJD_FIELDS";
export const SINGLE_EJD_FIELD = "SINGLE_EJD_FIELD";

export const EJDFieldsAPI = (
  setLoading,
  alert,
  history,
  location,
  college_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/manage_job/ejd/?college_id=" +
          college_id,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: EJD_FIELDS,
      payload: res.data.data,
    });
  };
};

export const EJDSelectedFieldsAPI = (
  setLoading,
  alert,
  history,
  location,
  college_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/manage_job/ejd/selected_fields/?college_id=" +
          college_id,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_EJD_FIELDS,
      payload: res.data.data,
    });
  };
};

export const EJDSelectedSingleAPI = (
  setLoading,
  alert,
  history,
  location,
  link
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "public/job_form/?link=" + link)
      .catch((error) => {
        setLoading(false);
        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SINGLE_EJD_FIELD,
      payload: res.data.data,
    });
  };
};

export const EJDSelectedPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  ejdList,
  college_id,
  setInviteCall,
  handleSuccessData = () => {}
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "api/manage_job/ejd/selected_fields/?college_id=" +
          college_id,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      alert.success("E-JNF Created successfully");
      var arr = [...ejdList];
      arr.push(res.data.data);
      handleSuccessData(res.data.data);
      setInviteCall(true);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_EJD_FIELDS,
      payload: arr,
    });
  };
};

export const EJDPostAPI = (
  data,
  alert,
  history,
  location,
  setStep,
  setLoading
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "public/ejd/view/", data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });

    if (res.data.success) {
      setStep(1);
      alert.success("EJD response successfully Submitted");
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};
