import { withStyles } from "@material-ui/core/styles";
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export default function TopBarLoader() {
  return (
    <>
      <div style={{ width: "100%" }}>
        <BorderLinearProgress />
      </div>
    </>
  );
}
