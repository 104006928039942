import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
  entityInfoSelectorFn
} from "../../../api/SignIn/selector";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import CompanyActionBar from "../../Input/CompanyActionBar";
import CompanyTableNew from "../../Table/CompanyTableNew";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function CompanyTab({
  page,
  setPage,
  loading,
  totalCount,
  pendingCount,
  filtersCompany,
  setFiltersCompany,
  setClickSearchButton,
  setSelectedRowsData,
  selectedRowsData,
  clickSearchButton,
  setApplyClickedCTC,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  fromGroup,
  rejectedCount,
  companyList,
  backendDrivenFilter,
  setBackendDrivenFilter,
  filterData,
  filterLoading,
  companyListTotalCount,
  pageData,
  setPageData,
  setCompanyList,
  setRefreshData,
  handleToggle = () => {},
  toggleViewCollege = false,
  setToggleViewCollege = () => {},
}) {
  const classes = useStyles();
  const [selectedID, setSelectedID] = React.useState([]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn);

  const handleChange = (event, newValue) => {
    var approval_status = "PENDING";
    if (newValue === "Rejected") {
      approval_status = "REJECTED";
    }
    setFiltersCompany({
      ...filtersCompany,
      current_tab: newValue,
      approval_status: approval_status,
    });
  };
  // const companyList = useSelector((state) => state?.CompanyData?.details);

  const columns = [
    {
      field: "name",
      width: 125,
      resizable: true,
      renderHeader: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <strong style={{ color: "#5a6268" }}>Company</strong>
        </div>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link
                to={`/company-profile/${params.row.id}`}
                target={`${
                  isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                }`}
                style={{ textDecoration: "none", display: "contents" }}
              >
                {" "}
                <Tooltip title={params.row.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px" }}
                  >
                    {params.row.name}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "industry_name",
      width: 120,
      resizable: true,

      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "20px" }}>
          Industry
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.industry_name} placement="right">
                <Typography noWrap style={{ fontSize: "14px" }}>
                  {params.row.industry_name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>

        // <span
        //   style={{
        //     textAlign: "center",
        //     display: "flex",
        //     margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   {params.row.industry_name}
        // </span>
      ),
    },
    {
      field: "industry_type_name",
      width: 150,
      renderHeader: (params) => (
        <div>
          {" "}
          <strong style={{ color: "#5a6268" }}>Industry Type </strong>
        </div>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.industry_type_name}
        </span>
      ),
    },
    {
      field: "source",
      // type: "text",
      headerName: "Source",
      sortable: false,
      width: 180,
      renderHeader: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginLeft: ".5rem",
          }}
        >
          <strong style={{ color: "#5a6268" }}>Source</strong>
        </div>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.invitation_source ? params.row.invitation_source : "NA"}
        </span>
      ),
    },
    //  {
    //   field: "job_source",
    //   width: 132,
    //   renderHeader: (params) => (
    //     <strong style={{ color: "#5a6268" }}>Source </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {params.row.job_source ?? "NA"}
    //     </span>
    //   ),
    // },
    {
      field: "visitng_date",
      width: 150,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "17px" }}>
          Visiting Date
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.visiting_date !== "NA"
            ? moment(params.row.visiting_date).format("DD-MM-YYYY")
            : "NA"}
        </span>
      ),
    },
    {
      field: "vacancies",
      width: 130,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "2px" }}>
          Vacancies{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.vacancies}
        </span>
      ),
    },
    {
      field: "total_job_count",
      width: 130,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "2px" }}>
          No. of Jobs{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.total_job_count}
        </span>
      ),
    },
    {
      field: "ctc",
      width: 140,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>CTC (Min-Max) </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.ctc}
        </span>
      ),
    },
    {
      field: "stipend",
      // type: "text",
      sortable: false,
      width: 180,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
          Stipend (Min-Max)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.stipend}
        </span>
      ),
    },
    {
      field: "Hired",
      type: "number",
      width: 120,
      renderHeader: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <strong style={{ color: "#5a6268" }}>Hired</strong>
        </div>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.hired}
        </span>
      ),
    },

    {
      field: "last_job_post",
      width: 148,
      renderHeader: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <strong style={{ color: "#5a6268" }}>Last Job Post</strong>
        </div>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.last_job_post}
        </span>
      ),
    },
    !groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && {
      field: "current_status",
      width: 157,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>Current Status </strong>
      ),
      renderCell: (params) => (
        <>
          {params.row.current_status === "ACTIVE" ? (
            <span
              style={{
                textAlign: "center",
                display: "flex",
                margin: "auto",
                color: "#38cb43",
                fontSize: "14px",
              }}
            >
              {params.row.current_status === "ACTIVE" ? "Active" : "Blocked"}
            </span>
          ) : (
            <span
              style={{
                textAlign: "center",
                display: "flex",
                margin: "auto",
                color: "red",
                fontSize: "14px",
              }}
            >
              {params.row.current_status === "ACTIVE" ? "Active" : "Blocked"}
            </span>
          )}
        </>
      ),
    },
    {
      field: "created",
      width: 125,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Action Date{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.created}
        </span>
      ),
    },

    groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && {
      field: "no_of_colleges",
      width: 170,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "15px" }}>
          {entityInfo?.college}s Count{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.college_count}
        </span>
      ),
    },
  ];

  const columns2 = [
    {
      field: "name",
      width: 305,
      resizable: true,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "105px" }}>
          Company
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link
                to={`/pending-company-profile/${params.row.id}`}
                target={`${
                  isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                }`}
                style={{ textDecoration: "none", display: "contents" }}
              >
                {" "}
                <Tooltip title={params.row.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px" }}
                  >
                    {params.row.name}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "industry_name",
      width: 250,
      resizable: true,

      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "82px" }}>
          Industry
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.industry_name} placement="right">
                <Typography noWrap style={{ fontSize: "14px" }}>
                  {params.row.industry_name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "industry_type_name",
      width: 220,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
          Industry Type{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.industry_type_name}
        </span>
      ),
    },
    //  {
    //   field: " job_source",
    //   width: 132,
    //   renderHeader: (params) => (
    //     <strong style={{ color: "#5a6268" }}>Source </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {params.row.job_source}
    //     </span>
    //   ),
    // },
    {
      field: "visitng_date",
      width: 220,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "52px" }}>
          Visiting Date
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.visiting_date !== "NA"
            ? moment(params.row.visiting_date).format("DD-MM-YYYY")
            : "NA"}
        </span>
      ),
    },
    {
      field: "created",
      width: 200,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "55px" }}>
          Action Date{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.created}
        </span>
      ),
    },

    {
      field: "source",
      // type: "text",
      headerName: "Source",
      sortable: false,
      width: 180,
      renderHeader: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginLeft: ".5rem",
          }}
        >
          <strong style={{ color: "#5a6268" }}>Source</strong>
        </div>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.invitation_source ? params.row.invitation_source : "NA"}
        </span>
      ),
    },
  ];

  const rejectedColumns = [
    {
      field: "name",
      width: 305,
      resizable: true,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "105px" }}>
          Company
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link
                to={`/rejected-company-profile/${params.row.id}`}
                target={`${
                  isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                }`}
                style={{ textDecoration: "none", display: "contents" }}
              >
                {" "}
                <Tooltip title={params.row.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px" }}
                  >
                    {params.row.name}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "industry_name",
      width: 250,
      resizable: true,

      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "82px" }}>
          Industry
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.industry_name} placement="right">
                <Typography noWrap style={{ fontSize: "14px" }}>
                  {params.row.industry_name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "industry_type_name",
      width: 220,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
          Industry Type{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.industry_type_name}
        </span>
      ),
    },
    //  {
    //   field: " job_source",
    //   width: 132,
    //   renderHeader: (params) => (
    //     <strong style={{ color: "#5a6268" }}>Source </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {params.row.job_source}
    //     </span>
    //   ),
    // },
    {
      field: "last_updated_by",
      width: 220,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "52px" }}>
          Rejected By
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row?.last_updated_by ? params.row.last_updated_by : "NA"}
        </span>
      ),
    },
    {
      field: "rejected_at",
      width: 200,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "55px" }}>
          Action Date{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.created}
        </span>
      ),
    },
    {
      field: "reson",
      width: 200,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "55px" }}>
          Reason{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.reason}
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={filtersCompany?.current_tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <NewTab
            value={"All"}
            label={`Approved Companies ${`(${totalCount})`}`}
            wrapped
            {...a11yProps("All")}
          />
          {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
            <NewTab
              value={"Verify"}
              label={`Pending Companies ${`(${pendingCount})`}`}
              wrapped
              {...a11yProps("Verify")}
            />
          )}
          {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
            <NewTab
              value={"Rejected"}
              label={`Rejected Companies ${`(${rejectedCount})`}`}
              wrapped
              {...a11yProps("Rejected")}
            />
          )}
        </NewTabs>
      </AppBar>

      <CompanyActionBar //all filters
        filtersCompany={filtersCompany}
        setFiltersCompany={setFiltersCompany}
        setClickSearchButton={setClickSearchButton}
        selectedRowsData={selectedRowsData}
        clickSearchButton={clickSearchButton}
        setSelectedRowsData={setSelectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        setApplyClickedCTC={setApplyClickedCTC}
        collegeId={collegeId}
        userRolesPermission={userRolesPermission}
        setCallNavbar={setCallNavbar}
        fromGroup={fromGroup}
        backendDrivenFilter={backendDrivenFilter}
        setBackendDrivenFilter={setBackendDrivenFilter}
        filterData={filterData}
        filterLoading={filterLoading}
        loading={loading}
        setRefreshData={setRefreshData}
        handleToggle={handleToggle}
        toggleViewCollege={toggleViewCollege}
        setToggleViewCollege={setToggleViewCollege}
      />
      <br />
      <TabPanel value={filtersCompany?.current_tab} index={"All"}>
        <CompanyTableNew
          loading={loading}
          list={companyList}
          columns={columns}
          setSelectedRowsData={setSelectedRowsData}
          selectedRowData={selectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          pageData={pageData}
          setPageData={setPageData}
          companyListTotalCount={companyListTotalCount}
          setCompanyList={setCompanyList}
        />
      </TabPanel>
      {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
        <TabPanel value={filtersCompany?.current_tab} index={"Verify"}>
          <CompanyTableNew
            loading={loading}
            list={companyList}
            columns={columns2}
            setSelectedRowsData={setSelectedRowsData}
            selectedRowData={selectedRowsData}
            selectedID={selectedID}
            setSelectedID={setSelectedID}
            pageData={pageData}
            setPageData={setPageData}
            companyListTotalCount={companyListTotalCount}
            setCompanyList={setCompanyList}
          />
        </TabPanel>
      )}
      {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
        <TabPanel value={filtersCompany?.current_tab} index={"Rejected"}>
          <CompanyTableNew
            loading={loading}
            list={companyList}
            columns={rejectedColumns}
            setSelectedRowsData={setSelectedRowsData}
            selectedRowData={selectedRowsData}
            selectedID={selectedID}
            setSelectedID={setSelectedID}
            pageData={pageData}
            setPageData={setPageData}
            companyListTotalCount={companyListTotalCount}
            setCompanyList={setCompanyList}
          />
        </TabPanel>
      )}
    </div>
  );
}
