import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import vactor from "../../../assets/img/star-performer-image.svg";
import { Tooltip } from "@mui/material";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// const useStyles = makeStyles({
//   root: {
//     // maxWidth: 345,
//     boxShadow: "none",
//     borderRadius: 8,
//   },
//   media: {
//     height: 240,
//   },
// });

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: "100%",
  },
  pera: {
    fontWeight: "600",
    marginTop: 10,
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      textAlign: "left",
      marginTop: 10,
      lineHeight: 1.3,
    },
  },
  imgdiv: {
    borderRadius: 100,
    height: 160,
    width: 160,
  },
  rightdiv: {
    marginTop: 60,
    [theme.breakpoints.only("xs")]: {
      marginTop: 60,
    },
    border:"1px solid #7C7F81",
    borderRadius:8,
    padding:"0px"
  }
}));

export default function StarPerformer({data}) {
  const classes = useStyles();
  const theme = useTheme();

  
  const NEW_DATA = [
    {
      title: "Company Name",
      info: data?.company_name,
    },
    {
      title: "Job Title",
      info: data?.job_title,
    },
    {
      title: "CTC Placed",
      info: `${data?.max_ctc} LPA`,
    },
  ];
  const image=data?.profile_picture?.length  ? data?.profile_picture : vactor;


  return (
    // <Card className={classes.root}>
    //   <CardActionArea>
    //     <CardMedia
    //       className={classes.media}
    //       image={data?.profile_picture?.length  ? data?.profile_picture : vactor}
    //       title="Contemplative Reptile"
    //     />
    //     <CardContent
    //       style={{
    //         border: "1px solid #B0B6BA",
    //         borderTop: "none",
    //         borderBottomRightRadius: 8,
    //         borderBottomLeftRadius: 8,
    //       }}
    //     >
    //       <div>
    //         <Typography
    //           style={{ color: "#6C757D", fontSize: "14px", marginBottom: 5 }}
    //         >
    //           Student Detail
    //         </Typography>
    //         { (data?.student_name?.length < 25) ? (<Typography variant="h6">
    //         {data?.student_name}
    //       </Typography>) : (<Tooltip title={`${data?.student_name}`} arrow><Typography variant="h6">
    //         {data?.student_name?.slice(0,25)}...       
    //       </Typography></Tooltip>)}
    //     { (data?.course?.length < 30) ? (<Typography variant="p">
    //         {data?.course}
    //       </Typography>) : (<Tooltip title={`${data?.course}`} arrow><Typography variant="p">
    //         {data?.course?.slice(0,30)}...       
    //       </Typography></Tooltip>)}
    //       </div>
    //       <div style={{ marginTop: 10 }}>
    //         <Typography
    //           style={{ color: "#6C757D", fontSize: "14px", marginBottom: 5 }}
    //         >
    //           Company Detail
    //         </Typography>
    //         { (data?.company_name?.length <25) ? (<Typography variant="h6">
    //         {data?.company_name}
    //       </Typography>) : (<Tooltip title={`${data?.company_name}`} arrow><Typography variant="h6">
    //         {data?.company_name?.slice(0,25)}...       
    //       </Typography></Tooltip>)}
    //       { (data?.job_title?.length <25) ? (<Typography variant="h6">
    //         {data?.job_title}
    //       </Typography>) : (<Tooltip title={`${data?.job_title}`} arrow><Typography variant="h6">
    //         {data?.job_title?.slice(0,25)}...       
    //       </Typography></Tooltip>)}
    //       </div>

    //       <div style={{ marginTop: 10 }}>
    //         <Typography
    //           style={{ color: "#6C757D", fontSize: "14px", marginBottom: 5 }}
    //         >
    //           CTC Placed
    //         </Typography>
    //         <Typography variant="h5" component="h2">
    //         {data?.max_ctc}
    //         </Typography>
    //       </div>
    //     </CardContent>
    //   </CardActionArea>
    // </Card>
    <div className={classes.rightdiv}>
      <div
        style={{
          // padding: 20,
          paddingBottom: 0,
          borderRadius: 8,
        }}
      >
        <div>
          <Box style={{height:"100px",background:theme.palette.primary.main,borderRadius:"8px 8px 0px 0px" }}  > </Box>
          <div style={{ marginTop: "-80px",marginLeft:"20px" }}>
            <div className={classes.imgdiv}>
              <img
                src={image}
                style={{ height: "100%", width: "100%",borderRadius:"100%" }}
              />
            </div>
          </div>
         
          <div>
            <div>
            <div style={{minHeight:"60px",padding:"0px 10px 0px 10px",marginTop:"10px"}}> 
            { (data?.student_name?.length < 30) ? (<Typography variant="h6">
             {data?.student_name}
           </Typography>) : (<Tooltip title={`${data?.student_name}`} arrow><Typography variant="h6">
             {data?.student_name?.slice(0,30)}...       
               </Typography></Tooltip>)}
               { (data?.course?.length < 60) ? (<Typography variant="body2" style={{ color:"#6C757D" }}>
             {data?.course}
           </Typography>) : (<Tooltip title={`${data?.course}`} arrow><Typography style={{ color:"#6C757D" }} variant="body2">
             {data?.course?.slice(0,60)}...       
         </Typography></Tooltip>)}
         </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
               
              </div>
              <div>
                {NEW_DATA?.map((item) => (
                  <div style={{ marginBottom: 20, backgroundColor: "#F1F8FF",padding:"10px",borderRadius:8,minHeight:"100px" }}>
                    <Typography
                      variant="h6"
                      style={{ marginTop: 0, marginBottom: 4 ,color:"#6C757D"}}
                 
                      className={classes.pera}
                    >
                      {item?.title}
                    </Typography>
                   
                      { (item?.info?.length < 60) ? (<Typography variant="h6" color="black">
                      {item.info}
           </Typography>) : (<Tooltip title={`${item?.info}`} arrow><Typography color="primary" variant="body2">
                       {item?.info?.slice(0,60)}...       
         </Typography></Tooltip>)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}


