import { OVERVIEW_DATA, PLACEMENT_DATA, SKILL_DATA } from "./action";

export const overview = (state = {}, action) => {
  switch (action.type) {
    case OVERVIEW_DATA:
      return { ...state, overviewdata: action.payload };
    case PLACEMENT_DATA:
      return { ...state, placementdata: action.payload };
    case SKILL_DATA:
      return { ...state, skilldata: action.payload };

    default:
      return state;
  }
};
