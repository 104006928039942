import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ProfileApproveDialog from "../../EditStudent/ProfileApproveDialog/ProfileApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
}));

export default function Education({
  data,
  userId,
  StudentDetailsAPI,
  fromProfileUpdate,
}) {
  const classes = useStyles();
  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {data?.student_user_details?.education?.length > 0 ||
      (fromProfileUpdate &&
        data?.student_profile_update &&
        Object.keys(data?.student_profile_update)?.includes("EDU")) ? (
        <div className={classes.paper}>
          <Typography variant="h6">Education</Typography>
          {data?.student_user_details?.education?.length > 0 &&
            data?.student_user_details?.education?.map((item) => (
              <>
                {item.education > 2 ? (
                  <>
                    <div>
                      <Typography variant="body1" style={{ marginTop: "10px" }}>
                        {item?.highest_qualification}
                      </Typography>
                      <Typography
                        variant="body1" /* style={{ marginTop: "10px" }} */
                      >
                        {item?.college_name}{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{ marginTop: "5px", marginBottom: "7px" }}
                      >
                        {item?.degree && <>{item.degree} </>}
                        {item?.specialization && (
                          <> in {item?.specialization}</>
                        )}
                        {item?.degree && <>,</>}{" "}
                        {item?.is_grade_system
                          ? item.cgpa && "CGPA: " + item.cgpa
                          : item.percentage && "Percentage: " + item.percentage}
                      </Typography>
                      <Typography variant="body2">
                        {item?.start_date && item?.start_date !== null && (
                          <>
                            {moment(item?.start_date).format("MMM, YYYY")}{" "}
                            {item.end_date && item.end_date !== null && (
                              <>
                                {" - "}
                                {moment(item?.end_date).format(
                                  "MMM, YYYY"
                                )}{" "}
                              </>
                            )}
                          </>
                        )}
                      </Typography>
                    </div>
                    <br />
                  </>
                ) : (
                  <>
                    <div>
                      <Typography variant="body1" style={{ marginTop: "10px" }}>
                        {item?.highest_qualification
                          ? item?.highest_qualification + " Education"
                          : ""}{" "}
                      </Typography>
                      <Typography
                        variant="body1" /* style={{ marginTop: "10px" }} */
                      >
                        {item?.college_name}{" "}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{ marginTop: "5px", marginBottom: "7px" }}
                      >
                        {item.board && <>{item?.board_name},</>}{" "}
                        {item?.is_grade_system
                          ? "CGPA:" + item.cgpa
                          : "Percentage: " + item.percentage}
                      </Typography>
                      <Typography variant="body2">
                        {item?.start_date && item?.start_date !== null && (
                          <>
                            {moment(item?.start_date).format("MMM, YYYY")}{" "}
                            {item.end_date && item.end_date !== null && (
                              <>
                                {" - "}
                                {moment(item?.end_date).format(
                                  "MMM, YYYY"
                                )}{" "}
                              </>
                            )}
                          </>
                        )}
                      </Typography>
                    </div>
                    <br />
                  </>
                )}
              </>
            ))}
          {fromProfileUpdate &&
          data?.student_profile_update &&
          Object.keys(data?.student_profile_update)?.includes("EDU") ? (
            <div
              style={{
                backgroundColor: "green ",
                padding: "10px",
                width: "160px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                right: "10px",
                bottom: "10px",
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
              >
                {`Pending Request (${data?.student_profile_update?.EDU})`}
              </Typography>
            </div>
          ) : (
            <></>
          )}
          <ProfileApproveDialog
            title="Education Request"
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            formType="approveEducation"
            data={data}
            userId={userId}
            type={"EDU"}
            StudentDetailsAPI={StudentDetailsAPI}
          />
        </div>
      ) : null}
    </>
  );
}
