import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, Grid, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import { TeamPlacementCycleSingleChangeAPI } from "../../../../api/TeamManage/action";
import { useDispatch } from "react-redux";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    width: "80%",
    marginBottom: "10px",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    display: "flex",
    paddingTop: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
  formControl: {
    marginTop: "12px",
    marginBottom: "5px",
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  circle: {
    height: "7px",
    width: "7px",
    marginRight: "10px",
    border: "1px solid black",
    borderRadius: "50%",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px",
    marginBottom: "5px",
  },
}));

export default function AssignedPlacementCycles({ data, params, collegeId, placementCycle, setPlacementCycle, placementError, setPlacementError }) {
  const classes = useStyles();
  const [degree, setDegree] = React.useState("");
  const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: 14 }} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkedIcon} />;
  const handleChange = (event) => {
    setDegree(event.target.value);
  };

  const handleDeletePlacement = (item, index) => {
    if (item.is_selected) {
      var arr = [...placementCycle];
      if (index !== -1) {
        arr[index].is_selected = false;
        setPlacementCycle(arr);
      }
    }
  };
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (validateData()) {
      var arr = [];
      var new_arr = [];
      new_arr = placementCycle.filter((i) => i.new_selected && i.is_selected);
      new_arr?.forEach((item) => {
        arr.push(item.id);
      });
      var data_ = {
        college_id: [collegeId],
        placement_id: arr,
        object_id: params?.team_member_id,
      };
      dispatch(TeamPlacementCycleSingleChangeAPI(data_, alert, history, location, data, setLoader, collegeId));
    }
  };

  const validateData = () => {
    var is_valid = true;
    var placement_error = null;
    if (!placementCycle.some((i) => i?.new_selected)) {
      is_valid = false;
      placement_error = "Please Enter Active Placement Cycle";
    }
    setPlacementError(placement_error);
    return is_valid;
  };
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Assigned Placement Cycles</Typography>
          </div>

          <Grid container spacing={3} style={{ display: "flex", alignItems: "center" }}>
            {placementCycle?.length > 0 && (
              <Grid item md={9}>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  options={placementCycle}
                  getOptionLabel={(option) => option.name}
                  // value={placementCycle}

                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => <></>);
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={option.is_selected}
                              disabled={option.is_selected && !option.new_selected}
                              onChange={(e) => {
                                setPlacementError(null);
                                var arr = [...placementCycle];
                                var obj = { ...option, new_selected: true };
                                obj["is_selected"] = e.target.checked;
                                var index = arr.findIndex((i) => i.id === option.id);
                                arr[index] = obj;
                                setPlacementCycle(arr);
                              }}
                              // onChange={(e) => {
                              //   handleSelectSingleSpecializations(e, i, item);
                              // }}
                              name="gilad"
                            />
                          }
                          label={<span style={{ fontSize: 13 }}>{option.name}</span>}
                        />
                      </FormGroup>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={"Active Placement Cycle"}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={placementError ? true : false}
                      helperText={<span>{placementError}</span>}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item md={3} style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <Button
                variant="contained"
                color="primary"
                className={classes.upload}
                onClick={() => {
                  if (!loader) handleSubmit();
                }}
              >
                {loader ? <CustomButtonCircularProgress /> : "Assign"}
              </Button>
            </Grid>
          </Grid>

          {placementCycle?.length > 0 &&
            placementCycle?.map((item, index) => (
              <>
                {item.is_selected && (
                  <div className={classes.item}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.circle} />
                      <Typography variant="body2" style={{ fontWeight: "normal" }}>
                        {item.name}
                      </Typography>
                    </div>
                    {item.is_selected && !item.new_selected ? (
                      <></>
                    ) : (
                      <Button
                        color="primary"
                        style={{ padding: "0px" }}
                        onClick={() => {
                          handleDeletePlacement(item, index);
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                )}
              </>
            ))}
        </Paper>
      </div>
    </>
  );
}
const Data = [
  {
    icon: <PhoneIcon color="primary" />,
    info: "9839302906",
  },
  {
    icon: <MailIcon color="primary" />,
    info: "roshan.kapoor34@gmail.com",
  },
  {
    icon: <LanguageIcon color="primary" />,
    info: "www.roshankapoor.in",
  },
];
