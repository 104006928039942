import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  PlacementPatchAPI,
  PlacementPostAPI,
} from "../../../api/PlacementCycles/action";
import Calendar from "../../Input/Calendar/index3";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import pSBC from "shade-blend-color";
import CompanyGroupAccordian from "../../Accordian/CollegeGroupAccordain";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import {
  collegeIdFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { use } from "echarts";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    width: "50%",
    fontSize: "16px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  btngrid: {
    // paddingRight: "25px",
    display: "flex",
    //paddingLeft: "25px",
    width: "100%",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "15px",
  },
  add: {
    //padding: "20px",
    paddingTop: "20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  chip: {
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

export default function AddCycle({
  open,
  handleClickOpen,
  handleClose,
  collegeId,
  userRolesPermission,
  multiCollege = false,
  edit = false,
  editData = {},
  selectedRowsData = [],
  setSelectedID,
  setSelectedRowsData,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [placement, setPlacement] = useState(null);
  // const [placementError, setPlacementError] = useState(null);
  const [selectedDateError, setSelectedDateError] = useState(null);

  const [placementError, setPlacementError] = useState({
    placement_cycle: null,
    college_list: null,
  });

  useEffect(() => {
    if (edit && editData && Object.keys(editData).length) {
      setPlacement(editData?.name);
      setSelectedDate(editData?.create_date);
      setGroupArray(editData?.group_array);
    }
  }, [editData, edit]);

  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const placementList = useSelector((state) => state.PlacementCycles.details);
  const dispatch = useDispatch();

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeIdd = useSelector(collegeIdFn);

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const isAtLeastOneCollegeSelected = (groupArray) => {
    for (let section of groupArray) {
      for (let group of section.groups) {
        const isAnyCollegeSelected = group.colleges.some(
          (spec) => spec.selected
        );

        if (isAnyCollegeSelected) {
          return true;
        }
      }
    }
    return false;
  };

  const validateData = () => {
    var is_valid = true;
    var name_error = null;
    var college_list_error = null;
    if (!placement) {
      name_error = "Placement Cycle field cannot be left blank";
      is_valid = false;
    }
    if (
      multiCollege &&
      groupViewCheck(isEducationGroup, isCollegeGroup, collegeIdd) &&
      !isAtLeastOneCollegeSelected(groupArray)
    ) {
      college_list_error = `${entityInfo?.college} field cannot be left blank`;
      is_valid = false;
    }

    if (!selectedDate) {
      alert.error("Please enter Start Date!!");
      is_valid = false;
    }

    setPlacementError((prev) => ({
      placement_cycle: name_error,
      college_list: college_list_error,
    }));

    return is_valid;
  };

  const [variantName, setVariantName] = React.useState([]);
  const [addStudentToPlacementCycle, setAddStudentToPlacementCycle] =
    useState(false);

  const CHARACTER_LIMIT = 65;

  const [groupArray, setGroupArray] = useState([]);
  const [groupArrayInitialState, setGroupArrayInitialState] = useState([]);
  const [loading1, setLoading1] = useState(false);

  useEffect(() => {
    const getGroupCollege = async () => {
      const res = await axios
        .get(
          `${BackendBaseApi.SANCHALAN}api/college_group/group_college_ids/`,
          {
            withCredentials: true,
          }
        )
        .catch((error) => {
          setLoading1(false);
          throw error;
        });

      if (res.data.success) {
        setGroupArray(res?.data?.data);
        setGroupArrayInitialState(res?.data?.data);
      }
      setLoading1(false);
    };
    if (multiCollege) getGroupCollege();
  }, []);

  const getSelectedCollegeIds = (data) => {
    const selectedCollegeIds = new Set();

    data.forEach((item) => {
      item.groups.forEach((group) => {
        group.colleges.forEach((college) => {
          if (college.selected) {
            selectedCollegeIds.add(college.college_id);
          }
        });
      });
    });

    return Array.from(selectedCollegeIds);
  };

  const handleSubmit = () => {
    if (validateData()) {
      var data = {
        college_id: groupArray?.length
          ? getSelectedCollegeIds(groupArray)
          : [collegeId],
        name: placement,
        start_date: new Date(selectedDate).toISOString(),
        group_array: groupArray,
      };
      if (!edit)
        dispatch(
          PlacementPostAPI(
            data,
            setLoading,
            alert,
            history,
            location,
            placementList,
            handleClose,
            setPlacement,
            setSelectedDate,
            setAddStudentToPlacementCycle,
            addStudentToPlacementCycle,
            collegeId,
            setGroupArray,
            groupArrayInitialState
          )
        );
      else
        dispatch(
          PlacementPatchAPI(
            data,
            selectedRowsData[0]?.id,
            setLoading,
            alert,
            history,
            location,
            placementList,
            handleClose,
            false,
            false,
            setSelectedID,
            setSelectedRowsData,
            setPlacement,
            setSelectedDate,
            setAddStudentToPlacementCycle,
            setGroupArray,
            groupArrayInitialState
          )
        );
    }
  };

  return (
    <div>
      {userRolesPermission?.some(
        (item) => item.access_control_key === "manage_placement_cycle"
      ) && (
        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          style={{
            borderRadius: "8px",
            boxShadow: "none",
            height: "40px",
            fontSize: "16px",
            display: "flex",
            fontWeight: "normal",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
          disabled={selectedRowsData?.length}
        >
          Add New Cycle
        </Button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={() => {
          setPlacement("");
          setSelectedDate(null);
          setAddStudentToPlacementCycle(false);
          setGroupArray(groupArrayInitialState);
          handleClose();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.add}>
          <div
            style={{
              // padding: "10px",
              //   paddingLeft: "20px",
              display: "flex",
              marginBottom: "30px",
              marginLeft: "120px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Add New Placement Cycle</Typography>
          </div>

          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{
              style: { padding: "16px" },
              //className: classes.textfiledrow,
              classes: { root: classes.inputRoot },
              maxlength: CHARACTER_LIMIT,
            }}
            /*  inputProps={{
              style: { padding: "16px" },
            }} */
            value={placement}
            onChange={(e) => {
              setPlacement(e.target.value);
              setPlacementError((prev) => ({ ...prev, placement_cycle: null }));
            }}
            error={placementError?.placement_cycle ? true : false}
            //helperText={<span>{placementError}</span>}
            // fullWidth
            /* InputProps={{ classes: { root: classes.inputRoot } }} */
            style={{ width: "100%" }}
            label="Enter Placement Cycle"
            name="Enter Placement Cycle"
            helperText={`${
              placement?.length ? placement?.length : 0
            }/${CHARACTER_LIMIT}`}
          />
          {multiCollege && (
            <div
              style={{
                display: "flex",

                width: "100%",
                flexDirection: "column",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <CompanyGroupAccordian
                groupArray={groupArray}
                setGroupArray={setGroupArray}
                errorMessage={placementError?.college_list}
                setErrorMessage={setPlacementError}
                errorMessageKey={"college_list"}
                disabled={edit}
              />

              {/* <Autocomplete
              multiple
              options={top100Films}
              limitTags={3}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </>
              )}
              fullWidth
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="default"
                    label={option?.title}
                    style={{
                      color: theme.palette.primary.main,
                      backgroundColor: pSBC(0.8, theme.palette.primary.main),
                      border: `solid 0.5px ${theme.palette.primary.main}`,
                    }}
                    deleteIcon={
                      <CloseRoundedIcon
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    }
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  label="Select College Groups"
                  placeholder="College"
                />
              )}
            /> */}
            </div>
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",

              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            {/* <Button color="primary" startIcon={<DateRangeIcon />} style={{ padding: "0px", display: "flex" }}>
              Select Start Date
            </Button> */}
            <Calendar
              title="Start Date"
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
          {!multiCollege && (
            <div style={{ marginBottom: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={addStudentToPlacementCycle ? true : false}
                    onChange={() => {
                      setAddStudentToPlacementCycle(
                        !addStudentToPlacementCycle
                      );
                    }}
                  />
                }
                label={
                  <a href="/add-students" style={{ textDecoration: "none" }}>
                    <Typography variant="body2">
                      Add Students after creating placement cycle
                    </Typography>
                  </a>
                }
              />
            </div>
          )}
          <div className={classes.btngrid}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                setPlacement("");
                setSelectedDate(null);
                setAddStudentToPlacementCycle(false);
                setGroupArray(groupArrayInitialState);
                handleClose();
              }}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              autoFocus
              className={classes.button}
              variant="contained"
              style={{ marginLeft: "15px", minWidth: "140px" }}
              color="primary"
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : edit ? (
                "Edit Cycle"
              ) : (
                "Add Cycle"
              )}{" "}
            </Button>
          </div>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
