import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Dialog, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  root: {
    padding: "20px",
    width: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    //width: "40%",
    marginBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  circle: {
    width: "90px",
    height: "90px",
    backgroundColor: "#28a745",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  btn: {
    marginTop: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px ",
    display: "flex",
    marginRight: "20px",
  },
}));

export default function SuccessfullyPostJob({ open, handleClickOpen, handleClose, jobDetails, jobAction, returnJobId, jobText }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  return (
    <div>
      <Dialog fullScreen={fullScreen} maxWidth="md" PaperProps={{ classes: { root: classes.root1 } }} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <div className={classes.root}>
          <div
            style={{
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div className={classes.circle}>
              <CheckCircleIcon style={{ color: "#fff", fontSize: "55px" }} />
            </div>
          </div>
          <Typography variant="h6">
            {returnJobId?.job_title} Job has been {jobAction === "edit" ? "Edited" : jobText} Successfully.
          </Typography>
        </div>

        <div className={classes.btngrid}>
          {returnJobId && (
            <div>
              <Link to={`/job/${returnJobId.job_title.toLowerCase().replace("", "-")}-${returnJobId.id}`} style={{ textDecoration: "none" }}>
                <Button className={classes.button} variant="outlined" onClick={handleClose} color="primary">
                  View Job Profile
                </Button>
              </Link>
            </div>
          )}
          {/* <Link to={"/post-job"}> */}
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose();
              history.push("/post-job");
            }}
          >
            Post Another Job
          </Button>
          {/* </Link> */}
        </div>
      </Dialog>
    </div>
  );
}
