import { Grid, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircleOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CompanyProfilePatch } from "../../../api/Company/action";
import { userRolesActionsFn } from "../../../api/SignIn/selector";
import { emailRegex } from "../../../utility/regex";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "30px",
    width: "90%",
    paddingRight: "30px",
    display: "flex",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function AddCompanyMember({ pocList, setPocList, fromPublic }) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [tempPocList, setTempPocList] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const params = useParams();
  const [pocErrors, setPocErrors] = useState({
    contact_person_name: null,
    email: null,
    phone: null,
    designation: null,
  });

  const [pocDetails, setPocDetails] = useState({
    contact_person_name: null,
    email: null,
    phone: null,
    designation: null,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (pocList?.length > 0) {
      setTempPocList(pocList);
    }
  }, [pocList]);

  const validateData = () => {
    var is_valid = true;
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    var designation_error = null;
    if (!pocDetails.contact_person_name) {
      name_error = "Please Enter contact person name";
      is_valid = false;
    }
    if (!pocDetails.email) {
      email_error = "Please Enter Email";
      is_valid = false;
    }
    if (pocDetails.email && !emailRegex.test(pocDetails.email)) {
      email_error = "Please Enter valid Email";
      is_valid = false;
    }
    if (!pocDetails.phone) {
      phone_error = "Please Enter Phone No.";
      is_valid = false;
    }
    if (pocDetails.phone && pocDetails.phone.length !== 10) {
      phone_error = "Please Enter valid Phone No.";
      is_valid = false;
    }
    if (!pocDetails.designation) {
      designation_error = "Please Enter Designation";
      is_valid = false;
    }

    setPocErrors({
      ...pocErrors,
      contact_person_name: name_error,
      email: email_error,
      phone: phone_error,
      designation: designation_error,
    });
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      var arr = [...pocList];
      arr.push(pocDetails);
      if (!fromPublic) {
        var data = {
          poc_list: arr,
        };
        dispatch(
          CompanyProfilePatch(
            data,
            setLoadingSubmit,
            alert,
            history,
            location,
            setPocList,
            params?.company_id,
            handleClose
          )
        );
      } else {
        setPocList(arr);
        setPocDetails({
          contact_person_name: "",
          email: "",
          phone: "",
          designation: "",
        });
        handleClose();
      }
    }
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <div>
      {userRolesPermission?.some(
        (item) => item.access_control_key === "manage_company"
      ) && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {" "}
          <Button
            color="primary"
            startIcon={<AddCircleOutlined />}
            style={{ display: "flex" }}
            onClick={handleClickOpen}
          >
            {pocList?.length > 0
              ? "Add Alternate POC details"
              : "Add POC Details"}
          </Button>
          <span style={{ color: "#181818", fontSize: "14px" }}>
            {" "}
            (Name, Email Id, Phone Number)
          </span>
        </div>
      )}
      {fromPublic && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {" "}
          <Button
            color="primary"
            startIcon={<AddCircleOutlined />}
            style={{ display: "flex" }}
            onClick={handleClickOpen}
          >
            {pocList?.length > 0
              ? "Add Alternate POC details"
              : "Add POC Details"}
          </Button>
          <span style={{ color: "#181818", fontSize: "14px" }}>
            {" "}
            (Name, Email Id, Phone Number)
          </span>
        </div>
      )}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <Typography variant="h6">Update Details</Typography>
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Contact Person Name"
            value={pocDetails.contact_person_name}
            onChange={(e) => {
              setPocDetails({
                ...pocDetails,
                contact_person_name: e.target.value,
              });
              setPocErrors({ ...pocErrors, contact_person_name: null });
            }}
            error={pocErrors?.contact_person_name ? true : false}
            helperText={<span>{pocErrors?.contact_person_name}</span>}
          />
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Phone"
            type="number"
            value={pocDetails.phone}
            onChange={(e) => {
              setPocDetails({ ...pocDetails, phone: e.target.value });
              setPocErrors({ ...pocErrors, phone: null });
            }}
            error={pocErrors?.phone ? true : false}
            helperText={<span>{pocErrors?.phone}</span>}
          />
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Email"
            value={pocDetails.email}
            onChange={(e) => {
              setPocDetails({ ...pocDetails, email: e.target.value });
              setPocErrors({ ...pocErrors, email: null });
            }}
            error={pocErrors?.email ? true : false}
            helperText={<span>{pocErrors?.email}</span>}
          />
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Designation"
            value={pocDetails.designation}
            onChange={(e) => {
              setPocDetails({ ...pocDetails, designation: e.target.value });
              setPocErrors({ ...pocErrors, designation: null });
            }}
            error={pocErrors?.designation ? true : false}
            helperText={<span>{pocErrors?.designation}</span>}
          />
          <Grid container spacing={3} style={{ marginTop: "0px" }}>
            <Grid item xs={12} sm={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{
                  height: "48px",
                  borderRadius: "8px",
                  boxShadow: "none",
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: "grid" }}>
              {" "}
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "48px",
                  borderRadius: "8px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  if (!loadingSubmit) handleSubmit();
                }}
              >
                {loadingSubmit ? (
                  <CustomButtonCircularProgress />
                ) : fromPublic ? (
                  "Add"
                ) : (
                  "Update"
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
