import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import { Button } from "@material-ui/core";
import {
  praveshAxiosGetReq,
  praveshAxiosPostReq,
} from "../../../../api/BaseApi/apiBase";
import { useAlert } from "react-alert";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
}));

const Resume = ({ data, userId }) => {
  const classes = useStyles();
  const [loadingResumeDownload, setLoadingResumeDownload] = useState(false);
  const resumeReference = useRef("");

  const downloadResume = async (url) => {
    setLoadingResumeDownload(true);

    try {
      const res = await praveshAxiosGetReq(url);
      if (res?.data?.success) {
        resumeReference.current = res?.data?.data?.link;
        if (res?.data?.data?.link) {
          window.open(res?.data?.data?.link, "blank").focus();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingResumeDownload(false);
    }
  };

  const alert = useAlert();

  const [resumeUploadLoader, setResumeUploadLoader] = useState(false);

  const uploadResume = async () => {
    var formData = new FormData();
    if (resumeUpload) {
      formData.append("college_resume", resumeUpload);

      const url = `api/college/college-saas/upload-resume/?student_id=${userId}`;

      setResumeUploadLoader(true);
      try {
        const res = await praveshAxiosPostReq(url, formData);

        if (res?.data?.success) {
          alert.success(res?.data?.data?.message);
        } else {
          alert.error(res?.data?.error);
          setResumeUpload(null);
        }
        setResumeUploadLoader(false);
      } catch (error) {
        setResumeUploadLoader(false);
        alert.error("Something went wrong");
        setResumeUpload(null);
        throw error;
      }
    }
  };

  const inputRef = useRef(null);

  const handleButtonClick = (e) => {
    if (!resumeUpload) {
      inputRef.current.click(); // Open file picker only if no resume is present
      document.activeElement.blur();
    } else {
      uploadResume(); // Call upload function when resume is already selected
    }
  };

  const [resumeUpload, setResumeUpload] = useState(null);

  return (
    <>
      <br />
      <div className={classes.paper}>
        <Typography variant="h6">Resume</Typography>
        {/* {data?.student_user_details?.resume && (
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          Student Resume
        </Typography>
      )} */}
        {/* <div
        style={{
          display: "flex",
          // marginLeft: "10px",
          marginTop: "10px",
          justifyContent: "flex-start",
        }}
      >
        {data?.student_user_details?.resume && (
          <a target="blank" href={data?.student_user_details?.resume}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              style={{ width: "10rem", marginRight: ".5rem" }}
            >
              {"View Resume"}
            </Button>
          </a>
        )}
      </div> */}
        {/* <Typography variant="body1" style={{ marginTop: "10px" }}>
        College Student Resume
      </Typography> */}
        {/* <div
          style={{
            display: "flex",
            marginTop: "10px",

            flexDirection: "column",
          }}
        >
         
        </div> */}
        <div
          style={{
            display: "flex",
            // marginLeft: "10px",
            marginTop: "10px",
            justifyContent: "flex-start",
            alignContent: "center",
          }}
        >
          {(resumeUpload ||
            resumeReference.current ||
            data?.student_user_details?.college_resume) && (
            <a
              target="blank"
              href={
                resumeUpload
                  ? URL.createObjectURL(resumeUpload)
                  : resumeReference.current ||
                    data?.student_user_details?.college_resume
              }
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                style={{ width: "160px", marginRight: ".5rem", height: "45px" }}
              >
                {" "}
                Open
              </Button>
            </a>
          )}

          {data?.is_generate_resume ? (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              style={{ width: "160px", height: "45px" }}
              onClick={() => {
                const url = `api/college/college-saas/update-download-student-resume/?student_id=${data?.id}`;
                let resumeName = `${data?.first_name}:${data?.id}`;
                downloadResume(url, resumeName);
              }}
            >
              {loadingResumeDownload ? (
                <CustomButtonCircularProgress />
              ) : (
                "Generate"
              )}
            </Button>
          ) : (
            <div
              style={{
                display: "flex",

                flexDirection: "row",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                component="label"
                style={{ width: "160px", marginRight: ".5rem", height: "45px" }}
                onMouseDown={handleButtonClick}
              >
                {resumeUploadLoader ? (
                  <CustomButtonCircularProgress />
                ) : resumeUpload ? (
                  "Upload Resume"
                ) : (
                  "Select Resume"
                )}
                {!resumeUpload && (
                  <input
                    ref={inputRef}
                    type="file"
                    hidden
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setResumeUpload(file);
                      }
                    }}
                  />
                )}
              </Button>
              <span style={{ width: "245px" }}>
                {resumeUpload ? resumeUpload?.name : ""}
                {resumeUpload && (
                  <IconButton onClick={() => setResumeUpload(null)}>
                    <CloseIcon />
                  </IconButton>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Resume;
