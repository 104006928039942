import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const STUDENT_DATA = "STUDENT_DATA";
export const STUDENT_DATA_ELIGIBLE = "STUDENT_DATA_ELIGIBLE";
export const STUDENT_DATA_ELIGIBLE_ALL = "STUDENT_DATA_ELIGIBLE_ALL";

export const STUDENT_NAVBAR = "STUDENT_NAVBAR";
export const STUDENT_FILTER_DATA = "STUDENT_FILTER_DATA";
export const STUDENT_FILTER_ELIGIBLE_DATA = "STUDENT_FILTER_ELIGIBLE_DATA";

export const StudentsGetAPI = (
  setLoading,
  alert,
  history,
  location,
  url,
  setClickSearchButton,
  setFirstApiCall,
  setApplyClickedScore,
  source
) => {
  return async (dispatch) => {
    let cancelTokenObj = {};

    if (source && source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }

    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      setClickSearchButton(false);
      setFirstApiCall(true);
      setApplyClickedScore(false);
      dispatch({
        type: STUDENT_DATA,
        payload: res.data.data.data,
      });

      dispatch({
        type: STUDENT_FILTER_DATA,
        payload: res.data.data.filter_data,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const StudentsGetAPI2 = (
  setLoading,
  alert,
  history,
  location,
  url,
  setClickSearchButton,
  setFirstApiCall,
  setApplyClickedScore,
  setStudentListTotalCount,
  setAddStudentClicked,
  source
) => {
  return async (dispatch) => {
    let cancelTokenObj = {};

    if (source && source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }

    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        if (error.message != "Cancelled due to stale request")
          setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      setClickSearchButton(false);
      setFirstApiCall(true);
      setApplyClickedScore(false);
      setAddStudentClicked(false);
      setLoading(false);
      dispatch({
        type: STUDENT_DATA,
        payload: res.data.data.results,
      });
      setStudentListTotalCount(res.data.data.count);
    } else {
      setLoading(false);
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const StudentsEligibleGetAPI = (
  setLoading,
  alert,
  history,
  location,
  url,
  setClickSearchButton,
  setFirstApiCall,
  setApplyClickedScore
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    setClickSearchButton(false);
    setFirstApiCall(true);
    setApplyClickedScore(false);
    dispatch({
      type: STUDENT_DATA_ELIGIBLE,
      payload: res.data.data.data,
    });
    dispatch({
      type: STUDENT_DATA_ELIGIBLE_ALL,
      payload: res.data.data,
    });
    dispatch({
      type: STUDENT_FILTER_ELIGIBLE_DATA,
      payload: res.data.data.filter_data,
    });
  };
};

export const ExcelStudentPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  handleClose,
  setProgress,
  setDataLoaded,
  setTotalLoad,
  setresData,
  setImported
) => {
  return async (dispatch) => {
    setLoading(true);
    setImported(false);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN + "api/manage_student/upload_excel/",
        data,
        {
          withCredentials: true,
          onUploadProgress: (data) => {
            setDataLoaded(Math.round(data.loaded / 1000000));
            setTotalLoad(Math.round(data.total / 1000000));

            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      )
      .catch((error) => {
        setLoading(false);
        alert.error("Upload Failed. Enter Correct File!!!");
        handleClose();

        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      // alert.success(res.data.data.message);
      // window.location.reload();
      const resdata = res?.data?.data;
      setresData({
        total_entries: resdata?.total_entries,
        user_created_count: resdata?.user_created_count,
        email_already_exist:
          resdata?.email_already_exist + resdata?.enrollment_id_exist,
        failed_entries: resdata?.error_list_count,
        flag: res?.data?.success,
        error_list: [
          ...res?.data?.data?.error_list,
          ...res?.data?.data?.already_exist_data,
        ],
        // already_exist_list: res?.data?.data?.already_exist_data,
      });
      setImported(true);
    } else {
      // alert.error("Some Error Occurred!!!");
      alert.error(res.data.data.message);
      setresData((resData) => ({ ...resData, flagCloseImport: true }));
    }
    handleClose();

    setLoading(false);
  };
};

export const StudentsPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  college_id,
  fromPublicPost,
  studentList,
  handleClose,
  currentTab,
  setStep
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "api/manage_student/view/", data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      });

    if (res.data.success) {
      alert.success("Student Added Successfully!!");
      if (!fromPublicPost) {
        if (
          currentTab === "All" ||
          currentTab === "ALL" ||
          currentTab === "all" ||
          currentTab === "UNPLACED"
        ) {
          var arr = [...studentList];

          arr.unshift(res.data.data);
          dispatch({
            type: STUDENT_DATA,
            payload: arr,
          });
        }

        handleClose();
      } else {
        setStep(1);
      }
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const StudentsPublicPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  college_id,
  fromPublicPost,
  studentList,
  handleClose,
  currentTab,
  setStep
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "public/add_student/?college_id=" +
          college_id,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      });

    if (res.data.success) {
      alert.success("Student Added Successfully!!");
      if (!fromPublicPost) {
        if (
          currentTab === "All" ||
          currentTab === "ALL" ||
          currentTab === "all" ||
          currentTab === "UNPLACED"
        ) {
          var arr = [...studentList];

          arr.push(res.data.data);
          dispatch({
            type: STUDENT_DATA,
            payload: arr,
          });
        }

        handleClose();
      } else {
        setStep(1);
      }
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const StudentNavbarAPI = (url, source) => {
  return async (dispatch) => {
    let cancelTokenObj = {};

    if (source && source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }

    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: STUDENT_NAVBAR,
        payload: res.data.data,
      });
      //   history.push("/manage-jobs");
    }
  };
};

export const StudentNavbarAPII = (url, source) => {
  return async (dispatch) => {
    let cancelTokenObj = {
      withCredentials: true,
    };

    // if (source && source?.token) {
    //   cancelTokenObj = {
    //     withCredentials: true,
    //     cancelToken: source?.token,
    //   };
    // } else {
    //   cancelTokenObj = {
    //     withCredentials: true,
    //   };
    // }

    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: STUDENT_NAVBAR,
        payload: res.data.data,
      });
      //   history.push("/manage-jobs");
    }
  };
};

export const BlockStudentAPI = (
  data,
  alert,
  history,
  location,
  studentList,
  handleClose,
  setLoading,
  setSelectedRowsData
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_student/block_student/`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...studentList];
      data.object_id.map((item) => {
        arr.forEach((item2) => {
          if (item2.id === item) item2["status"] = data.status;
        });
      });
      dispatch({
        type: STUDENT_DATA,
        payload: arr,
      });
      setSelectedRowsData([]);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();
    setLoading(false);
  };
};

export const StudentPlacementCycleChangeAPI = (
  data,
  alert,
  history,
  location,
  jobList,
  handleClose,
  setLoader,
  collegeId,
  setSelectedRowsData
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_student/add_student_placement/?college_id=${collegeId}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        if (error.response.status !== 401) {
          alert.error("Some Error Occurred! Cant add to placement Cycle");
        }
        handleClose();
        setLoader(false);

        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      setSelectedRowsData([]);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();

    setLoader(false);
  };
};

export const StudentJobApplyAPI = (
  data,
  alert,
  history,
  location,
  studentList,
  handleClose,
  setLoader,
  fromEligibility,
  setSelectedRowsData = () => {},
  collegeId
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/manage_student/apply_job/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      setSelectedRowsData([]);
      history.push(`/track/?job_id=${data?.job_id}`);
      // if (fromEligibility) {
      //   if (location?.search?.includes("track"))
      //     history.push(`/track/?job_id=${data?.job_id}`);
      //   else history.push("/manage-students");
      // }
    } else {
      alert.error(res.data.data.error ?? "Some Error Occurred!!!");
    }
    handleClose();

    setLoader(false);
  };
};

export const ApproveStudentsAPI = (
  data,
  alert,
  history,
  location,
  companyList,
  handleClose,
  setCallNavbar = () => {},
  setLoading = () => {},
  changeStatus,
  fromStudentDetail,
  callback
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_student/verify_student/${
          fromStudentDetail ? `?user_id=true` : ``
        }`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        // handleClose();
        setLoading(false);
        throw error;
      });

    if (res?.data?.success) {
      alert.success(res?.data?.data?.message);
      if (fromStudentDetail) {
        callback();
      } else {
        var arr = [...companyList];
        if (!changeStatus) {
          data?.student_id?.map((item) => {
            var index = arr.findIndex((item2) => item2.id === item);
            arr.splice(index, 1);
          });
        } else {
          data.student_id.map((item) => {
            var index = arr.findIndex((item2) => item2.id === item);
            const newItem = {
              ...arr[index],
              invite_status: "CHANGE_REQUESTED",
              reason_message: data?.message,
            };
            arr.splice(index, 1, newItem);
          });
        }
        setCallNavbar(true);
        dispatch({
          type: STUDENT_DATA,
          payload: arr,
        });
      }
      handleClose();
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};
