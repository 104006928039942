import React, { useEffect, useMemo, useState } from "react";
import { TextField, Typography, useTheme, withStyles } from "@material-ui/core";
import CampusBarGraph from "./CampusBarGraph";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import ReportTable from "./ReportTable";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import CampusPieGraph from "./CampusPieGraph";
import ReportTableSecond from "./ReportTableSecond";
import PieTab from "./PieTab";
import CampusBarhorizontalGraph from "./CampusBarhorizontalGraph";
import PieSkeleton from "./PieSkeleton";
import { Autocomplete } from "@material-ui/lab";
import { sanchalanAxiosGetReq } from "../../../api/BaseApi/apiBase";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";
import GraphSkeleton from "./GraphSkeleton";
import SkeletonCourses from "./SkeletonCourses";

const SearchTextField = withStyles({
  root: {
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F5F5F5",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F5F5F5",
      },
      "&:hover fieldset": {
        borderColor: "#F5F5F5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F5F5F5",
      },
    },
  },
})(TextField);

function BarTableTabSection({
  selectedPlacement,
  collegeId,
  from="",
  heading = "",
  yAxis = "",
  xAxis = "",
}) {
  const theme = useTheme();
  const [value, setValue] = useState(1);
  const alert = useAlert();
  function handleChange(id) {
    setValue(id);
  }
  const [graphData, setGraphData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [tabvalue, setTabValue] = useState(0);
  function handleChangeTab(id) {
    setTabValue(id);
  }
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = React.useState(courseData);
  const [inputValue, setInputValue] = React.useState("");
  const [loadingCourses, setLoadingCourses] = useState(false);

  useEffect(() => {
    if (selectedPlacement) {
      setLoadingCourses(true);
      let url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_data/get_courses/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      if(from === "job_data" || from === "job_placed_data") url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_data/get_courses/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;

      axios
        .get(
         url,
          { withCredentials: true }
        )
        .then((res) => {
          if (res?.data?.success) {
            setCourseData(res?.data?.data);
            // setTabValue(res?.data?.data[0]);
            setLoadingCourses(false);
          }
        })
        .catch((err) => {
          alert.error(err?.response?.data?.error);
          setLoadingCourses(false);
        });
    }
  }, [selectedPlacement, collegeId]);

  useEffect(() => {
    if (
      tabvalue &&
      tabvalue?.course_id &&
      tabvalue?.degree_id &&
      heading &&
      selectedPlacement
    ) {
      let url;
      if (heading === "Marks vs Student")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_marks_data/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}&degree_id=${tabvalue?.degree_id}&course_id=${tabvalue?.course_id}`;
      else if (heading === "Job Salary Scale" && from === "job_data")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_data/job_salary_scale/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}&degree_id=${tabvalue?.degree_id}&course_id=${tabvalue?.course_id}`;
      else if (heading === "Mark vs Student applicant Placed(%)")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_placed/marks_data/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}&degree_id=${tabvalue?.degree_id}&course_id=${tabvalue?.course_id}`;
      else if (heading === "Job Salary Scale")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_salary_scale/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}&degree_id=${tabvalue?.degree_id}&course_id=${tabvalue?.course_id}`;
      else if (heading === "Month vs Placed Jobs")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_month_wise/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}&degree_id=${tabvalue?.degree_id}&course_id=${tabvalue?.course_id}`;
      else if (heading === "Job Placed vs Number of Students")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_number_wise/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}&degree_id=${tabvalue?.degree_id}&course_id=${tabvalue?.course_id}`;

      setLoading(true);
      url &&
        axios
          .get(url, { withCredentials: true })
          .then((res) => {
            if (res?.data?.success) {
              setGraphData(res?.data?.data);
              setLoading(false);
            }
          })
          .catch((err) => {
            alert.error(err?.response?.data?.error);
            setLoading(false);
          });
    } else if (selectedPlacement) {
      let url;
      if (heading === "Marks vs Student")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_marks_data/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Job Salary Scale" && from === "job_data")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_data/job_salary_scale/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Mark vs Student applicant Placed(%)")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_placed/marks_data/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Job Salary Scale")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_salary_scale/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Month vs Placed Jobs")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_month_wise/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Job Placed vs Number of Students")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_number_wise/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;

      setLoading(true);
      url &&
        axios
          .get(url, { withCredentials: true })
          .then((res) => {
            if (res?.data?.success) {
              setGraphData(res?.data?.data);
              setLoading(false);
            }
          })
          .catch((err) => {
            alert.error(err?.response?.data?.error);
            setLoading(false);
          });
    }
  }, [selectedPlacement, collegeId, tabvalue]);

  const chartHelper = () => {
    if (graphData?.length) {
      const labels = [];
      const data = [];
      const color = [];
      for (let key of graphData) {
        labels.push(key.name);
        data.push(key.value);
        color.push(key.color);
      }

      return {
        labels: labels,
        data: data,
        color: color[0],
        yAxis: yAxis,
        xAxis: xAxis,
        heading:heading
      };
    }
  };

  const data = [
    {
      id: 1,
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <BarChartOutlinedIcon /> Graph
        </div>
      ),
      tab: (
        <div>
          {loading ? (
            <GraphSkeleton />
          ) : (
            <CampusBarGraph DATA={chartHelper()} />
          )}
        </div>
      ),
    },
    {
      id: 2,
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TableChartOutlinedIcon style={{ marginRight: 5 }} /> Table
        </div>
      ),
      tab: (
        <div style={{ maxHeight: "300px", overflow: "scroll" }}>
          {" "}
          <ReportTable chartData={chartHelper()} />{" "}
        </div>
      ),
    },
  ];

  const memoizedData = useMemo(() => {
    return data;
  }, [loading]);

  return (
    <>
      <div
        style={{
          border: "1px solid #b0b6ba",
          borderRadius: "8px",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
        >
          <div style={{ padding: "20px", width: "100%", position: "relative" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection:"row-reverse"
                }}
              >
                {/* <div>
                  <Typography variant="h6">{heading}</Typography>
                </div> */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* {value === 2 && (
                    <div style={{ marginRight: "10px" }}>
                      {" "}
                      <Autocomplete
                        id="custom-input-demo"
                        options={courseData}
                        getOptionLabel={(courseData) => courseData?.name}
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{
                              padding: 10,
                              borderRadius: 8,
                              backgroundColor: "#F6F6F6",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <SearchRoundedIcon style={{ color: "#6C757D" }} />
                            <input
                              style={{
                                width: 250,
                                backgroundColor: "#F6F6F6",
                                border: "none",
                                color: "#6C757D",
                              }}
                              type="text"
                              placeholder="Search course"
                              {...params.inputProps}
                            />
                          </div>
                        )}
                      />
                    </div>
                  )} */}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#fff",
                        //   padding: "10px",
                        borderRadius: "8px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {memoizedData.map((item, id) => (
                          <ul
                            style={{
                              padding: 0,
                              display: "flex",
                              listStyleType: "none",
                              flexWrap: "wrap",
                              textAlign: "center",
                            }}
                            id="myTab"
                            data-tabs-toggle="#myTabContent"
                            role="tablist"
                          >
                            <li
                              className="mr-2"
                              role="presentation"
                              onClick={() => handleChange(item.id)}
                            >
                              <div
                                style={{
                                  padding: "6px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                  color:
                                    value === item.id
                                      ? "#fff"
                                      : theme.palette.primary.main,
                                  backgroundColor:
                                    value === item.id
                                      ? theme.palette.primary.main
                                      : "#fff",
                                }}
                              >
                                {item.title}
                              </div>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {memoizedData?.map((item, id) => (
                <div key={id} id="myTabContent">
                  <div
                    style={{
                      display: value === item.id ? "block " : "none",
                      marginTop: "20px",
                    }}
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    {item.tab}
                  </div>
                </div>
              ))}
            </div>
            {/* {tabdata.map((item, id) => (
              <div id="myTabContent">
                <div
                  style={{
                    display: tabvalue === item.id ? "block " : "none",
                  }}
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {item?.tab}
                </div>
              </div>
            ))} */}
          </div>

          <div
            style={{
              width: "500px",
              height: "400px",
              backgroundColor: "#FAFAFA",
              overflow: "scroll",
              borderBottomRightRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // padding: "20px",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "sticky",
                    top: "0px",
                    padding: 20,
                    backgroundColor: "#FAFAFA",
                  }}
                >
                  <Typography style={{ color: "#1C1C1C", fontSize: "16px" }}>
                    Select Course
                  </Typography>
                  <Autocomplete
                    id="combo-box-demo"
                    value={tabvalue}
                    freeSolo
                    onChange={(event, newValue) => {
                      setTabValue(newValue);
                    }}
                    // defaultValue={[tabdata[1]]}
                    // onChange={() => handleChangeTab(tabdata.id)}
                    // inputValue={tabvalue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(courseData?.course_id, newInputValue);
                    }}
                    options={courseData}
                    style={{ marginTop: "10px" }}
                    //onChange={() => handleChangeTab(tabdata.id)}
                    getOptionLabel={(courseData) => courseData?.name}
                    renderInput={(params) => (
                      <SearchTextField
                        {...params}
                        variant="outlined"
                        placeholder="Search"
                        style={{
                          backgroundColor: "#F5F5F5",
                          borderRadius: 8,
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 20,
                    paddingTop: "0px",
                  }}
                >
                  {loadingCourses ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <SkeletonCourses />
                      <SkeletonCourses />
                      <SkeletonCourses />
                      <SkeletonCourses />
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: 0,
                        display: "flex",
                        listStyleType: "none",
                        flexWrap: "wrap",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      {courseData?.map((item, id) => (
                        <ul
                          style={{
                            padding: 0,
                            display: "flex",
                            listStyleType: "none",
                            flexWrap: "wrap",
                            textAlign: "left",
                            width: "100%",
                          }}
                          id="myTab"
                          data-tabs-toggle="#myTabContent"
                          role="tablist"
                        >
                          <li
                            style={{ width: "100%", textAlign: "left" }}
                            role="presentation"
                            onChange={(event, newValue) => {
                              setTabValue(newValue);
                            }}
                            onClick={() => handleChangeTab(item)}
                          >
                            <div
                              style={{
                                padding: "10px",
                                cursor: "pointer",
                                borderRadius: "8px",
                                color:
                                  tabvalue?.course_id === item?.course_id
                                    ? "#fff"
                                    : "#000",
                                backgroundColor:
                                  tabvalue?.course_id === item?.course_id
                                    ? theme.palette.primary.main
                                    : "#FAFAFA",
                              }}
                            >
                              {item?.name}
                            </div>
                          </li>
                        </ul>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BarTableTabSection;
