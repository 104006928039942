import { Breadcrumbs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { collegeDetailSelectorFn } from "../../api/SignIn/selector";
import { EDGUniversitySkillsTableAPI } from "../../api/University/APIs/action";
import CustomCircularProgress from "../../components/CircularProgressBar";
import HighlighedSkillTable from "../../components/Table/UniversityTable/HighlighedSkillTable";
const useStyles = makeStyles((theme) => ({
  Chip: {
    backgroundColor: "#FAFBFC",
    //   padding: "20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    //  width: "150px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "40px",
    color: "#8a94a6",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dot: {
    backgroundColor: theme.palette.primary.main,
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
  },
  paper: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    padding: "30px",
  },
  btn: {
    boxShadow: "none",
    height: "30px",
    borderRadius: "8px",
    display: "flex",
    backgroundColor: "#e0a800",
    color: "#fff",
  },
  item: {
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dotback: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "20px",
    width: "120px",
    height: "40px",
    color: "#8a94a6",
    backgroundColor: "#FAFBFC",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dot: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function HighlightedSkillsStudents() {

  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (collegeDetail?.education_group_id) dispatch(EDGUniversitySkillsTableAPI(setLoading, alert, history, location, collegeDetail?.education_group_id));
  }, [collegeDetail?.education_group_id]);

  const skills = useSelector((state) => state?.EDGCommon?.edgDashboardSkillsTable);

  return (
    <>
      {/* <UniversityLayout> */}
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: "10px", marginBottom: "20px" }}>
        <Link
          to="/dashboard"
          //  onClick={handleClick}
          style={{ textDecoration: "none" }}
        >
          <Typography color="secondary" variant="body2">
            Overview
          </Typography>
        </Link>
        <Link style={{ textDecoration: "none" }} color="inherit" href="/getting-started/installation/" onClick={handleClick}>
          <Typography color="primary" variant="body2">
            Highlighted Skills of Students
          </Typography>
        </Link>
      </Breadcrumbs>
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <div style={{ width: "60%" }}>
          <Typography variant="h6"> Highlighted Skills of Students</Typography>
          <br />
          <HighlighedSkillTable data={skills} />
        </div>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default HighlightedSkillsStudents;
