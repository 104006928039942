import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Chip, Tooltip, IconButton } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
}));

function SavedSkills({ data }) {
  const classes = useStyles();
  return (
    <>
      <div style={{ position: "relative" }}>
        <div className={classes.root}>
          {(data?.change_data?.skill
            ? data?.change_data?.skill
            : data?.change_data
          ).map((item) => (
            <Chip
              color="primary"
              variant="outlined"
              size="small"
              label={item?.skill_name}
              borderRadius="8px"
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default SavedSkills;
