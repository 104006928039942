import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  AccessControlsAPI,
  UserRolesPostAPI,
} from "../../../api/AccessControls/action";
import CheckList from "./List";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "15px",
    height: "48px",
    paddingLeft: "30px",
    marginRight: "20px",
    paddingRight: "30px",
    display: "flex",
  },

  root1: {
    borderRadius: "8px",
    overflowX: "hidden",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function CreateCustomRole({
  open,
  handleClickOpen,
  handleClose,
  userRoleList,
  collegeId,
}) {
  const classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [localAccessControlList, setLocalAccessControlList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const accessControlList = useSelector(
    (state) => state?.AccessControls?.details
  );
  const [userRoleName, setUserRoleName] = useState(null);
  const [userRoleError, setUserRoleError] = useState(null);
  useEffect(() => {
    dispatch(AccessControlsAPI(setLoading, alert, history, location));
  }, []);

  const handleSubmit = () => {
    if (validateData()) {
      var arr = [];
      localAccessControlList.map((item) => {
        if (item.is_selected) arr.push(item.id);
      });
      var data = {
        college_id: [collegeId],
        user_role_name: userRoleName,
        access_controls: arr,
      };
      if (arr.length > 0)
        dispatch(
          UserRolesPostAPI(
            data,
            setLoading,
            alert,
            history,
            location,
            userRoleList,
            collegeId,
            handleClose
          )
        );
      else
        alert.error(
          "Please Select at least one Access Control for the user role!!"
        );
    }
  };

  const validateData = () => {
    var is_valid = true;
    var role_error = null;

    if (!userRoleName) {
      role_error = "User Role Name field cannot be left blank";
      is_valid = false;
    }

    setUserRoleError(role_error);
    return is_valid;
  };

  const [groupName, setGroupName] = useState([]);

  useEffect(() => {
    if (accessControlList?.length > 0) {
      var result = accessControlList.reduce(function (r, a) {
        if (a.access_control_group_name) {
          r[a.access_control_group_name] = r[a.access_control_group_name] || [];
          r[a.access_control_group_name].push(a);
        }

        return r;
      }, Object.create(null));

      setGroupName(result);
    }
  }, [accessControlList]);

  return (
    <div>
      {/* <Button color="primary" size="small" className={classes.button} onClick={handleClickOpen}>
        Create Custom Role
      </Button> */}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <Grid container spacing={3} style={{ padding: "40px" }}>
            <Grid
              item
              xs={6}
              style={{
                //left: "18%",
                position: "fixed",
              }}
            >
              <div className={classes.paper}>
                <Typography variant="h6">Create Custom Role</Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  value={userRoleName}
                  onChange={(e) => {
                    setUserRoleName(e.target.value);
                    setUserRoleError(null);
                  }}
                  error={userRoleError ? true : false}
                  helperText={<span>{userRoleError}</span>}
                  fullWidth
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  label="Role Name"
                />
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleClose}
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "140px" }}
                  className={classes.button}
                  onClick={() => {
                    if (!loading) handleSubmit();
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.circularProgress}
                      size={14}
                    />
                  ) : (
                    <>Create Role</>
                  )}{" "}
                </Button>
              </div>
            </Grid>
            <div
              className={clsx(classes.column, classes.helper)}
              style={{ marginLeft: "400px" }}
            >
              <Grid item xs={12}>
                <div className={classes.paper}>
                  {" "}
                  <Typography variant="h6">User Access Control</Typography>
                  <br />
                  <CheckList
                    localAccessControlList={localAccessControlList}
                    setLocalAccessControlList={setLocalAccessControlList}
                    groupName={groupName}
                    setGroupName={setGroupName}
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
