import { FEED_POST } from "./action";

const initialState = {
  feedList: [],
};
export const feed = (state = initialState, action) => {
  switch (action.type) {
    case FEED_POST:
      return { ...state, feed: action.payload };
    default:
      return state;
  }
};
