import { EJD_FIELDS, SELECTED_EJD_FIELDS, SINGLE_EJD_FIELD } from "./action";

export const EJDFields = (state = {}, action) => {
  switch (action.type) {
    case EJD_FIELDS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const EJDSelectedFields = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_EJD_FIELDS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const SingleEJDField = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_EJD_FIELD:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};
