import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Tooltip,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CompanyTable from "../../Table/CompanyTable";
import { Link } from "react-router-dom";
import { truncate } from "../../../utility/ellipsify";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import {
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import OffCampusActionBar from "../../Input/OffCampusActionBar";
import { useAlert } from "react-alert";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    color: "red",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    color: "red",
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function OffCampusTab(props) {
  const classes = useStyles();
  const [selectedID, setSelectedID] = React.useState([]);
  const isGradeSystem = useSelector(isGradeSystemFn);
  const studentList = useSelector((state) => state?.StudentData?.details);

  const collegeListSelector = useSelector(collegeListSelectorFn);

  const [tempStudentList, setTempStudentList] = useState([]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const {
    approveRejectOffCampus,
    deleteResponse,
    setDeleteReasonError,
    deleteReasonError,
    setDeleteReason,
    deleteReason,
    navbarDataOffCampus,
    deleteOffCampusStudentData,
    studentListOffCampus,
    fetchStudentList,
    dataLoading,
    refreshData,
    setRefreshData,
    studentDataOffCampus,
    filterListOffCampusData,
    loading,
    filters,
    setFilters,
    setClickSearchButton,
    setSelectedRowsData,
    selectedRowsData,
    setApplyClickedScore,
    filterDataText,
    setFilterDataText,
    collegeId,
    userRolesPermission,
  } = props;
  const alert = useAlert();
  useEffect(() => {
    if (
      studentList?.length > 0 &&
      groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
    ) {
      var arr = [...studentList];
      arr.forEach((item) => {
        item["college_name"] = checkCollegeName(item?.college_id[0]);
      });
      setTempStudentList(arr);
    } else setTempStudentList([]);
  }, [studentList, studentList?.length]);

  const checkCollegeName = (id) => {
    var name = "";
    collegeListSelector.map((item) => {
      if (item?.id === id) name = item?.name;
    });
    return name;
  };

  const columns = [
    {
      field: "enrollment_no",
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            marginLeft: "10px",
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "8px",
              marginRight: "10px",
              width: "8px",
              backgroundColor:
                params.row.status === "ACTIVE" ? "#51c03e" : "#f64a3f",
              borderRadius: "50%",
            }}
          />
          {params.row.enrollment_no}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
              marginLeft: "45px",
            }}
          >
            {" "}
            {params?.row?.user_id ? (
              <Grid item xs zeroMinWidth>
                <Link
                  to={`/student-profile/${params?.row?.name.replace(
                    / /g,
                    "_"
                  )}-${params?.row?.user_id}`}
                  style={{ textDecoration: "none", display: "contents" }}
                >
                  {" "}
                  <Tooltip title={params?.row?.name} placement="right">
                    <Typography
                      color="primary"
                      noWrap
                      style={{ fontSize: "14px" }}
                    >
                      {params?.row?.name}
                    </Typography>
                  </Tooltip>
                </Link>
              </Grid>
            ) : (
              <Grid item xs zeroMinWidth>
                {/*   <Link
    to={`/student-profile/${params?.row?.name.replace(/ /g, "_")}-${
      params?.row?.user_id
    }`}
    style={{ textDecoration: "none", display: "contents" }}
  > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      alert.error(
                        "Something wrong with this data, kindly connect with GetWork"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/*  </Link> */}
              </Grid>
            )}
          </Grid>
        </>
      ),
    },

    {
      field: "degree",
      type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            //margin: "auto",
            fontSize: "14px",
            marginLeft: "15px",
          }}
        >
          <Tooltip title={params?.row?.degree} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.degree)}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "course",
      type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Course
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            //  margin: "auto",
            cursor: "pointer",
            fontSize: "14px",
            marginLeft: "15px",
          }}
        >
          <Tooltip title={params?.row?.course} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.course)}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "batch",
      headerName: "Batch",
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.batch}
        </span>
      ),
    },
    {
      field: "gpa",
      width: 120,
      type: "text",

      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          GPA{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.cgpa}
        </span>
      ),
    },

    {
      field: "job_title",
      width: 170,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Job Title
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //margin: "auto",
              fontSize: "14px",
              marginLeft: "45px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.job_title} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.job_title}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "job_type_id",
      headerName: "Job Type",
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Job Type
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params?.row?.job_type_name}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "company_name",
      width: 140,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Placed at
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            //margin: "auto",
            fontSize: "14px",
            marginLeft: "25px",
          }}
        >
          <Tooltip title={params?.row?.company_name} placement="right">
            <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
              {params?.row?.company_name}{" "}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "ctc",
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          CTC/Stipend
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.formatted_ctc}
        </span>
      ),
    },

    {
      field: "placed_date",
      type: "text",
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "10px",
          }}
        >
          Placed Date
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.placed_date}
        </span>
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    setFilters({ ...filters, status: newValue });
    setFilterDataText("No Data Found");
  };

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);

  const checkBoxSelection =
    filters?.current_tab?.params === "Rejected" ? false : true;
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={filters?.status}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="ant example"
        >
          {navbarDataOffCampus?.length > 0 &&
            navbarDataOffCampus?.map((item) => (
              <NewTab
                value={String(item.params)}
                label={`${item.heading}(${item.count})`}
                onClick={() => {
                  setFilters({
                    ...filters,
                    current_tab: item,
                    status: String(item.params),
                  });
                }}
                wrapped
                {...a11yProps(String(item.params))}
              />
            ))}
        </NewTabs>
      </AppBar>
      <OffCampusActionBar
        filters={filters}
        setFilters={setFilters}
        setClickSearchButton={setClickSearchButton}
        selectedRowsData={selectedRowsData}
        setSelectedRowsData={setSelectedRowsData}
        setSelectedID={setSelectedID}
        filterList={filterListOffCampusData}
        studentList={studentList}
        setApplyClickedScore={setApplyClickedScore}
        collegeId={collegeId}
        userRolesPermission={userRolesPermission}
        filterListOffCampusData={filterListOffCampusData}
        studentListOffCampus={studentListOffCampus}
        dataLoading={dataLoading}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        fetchStudentList={fetchStudentList}
        deleteOffCampusStudentData={deleteOffCampusStudentData}
        setDeleteReason={setDeleteReason}
        deleteReason={deleteReason}
        setDeleteReasonError={setDeleteReasonError}
        deleteReasonError={deleteReasonError}
        deleteResponse={deleteResponse}
        approveRejectOffCampus={approveRejectOffCampus}
      />
      <br />
      <TabPanel>
        <CompanyTable
          loading={loading}
          list={studentDataOffCampus}
          columns={columns}
          setSelectedRowsData={setSelectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          filterDataText={filterDataText}
          filters={filters}
          checkBoxSelection={checkBoxSelection}
        />{" "}
      </TabPanel>
    </div>
  );
}
