import React, { useState } from "react";
import { TextField, Typography, useTheme, withStyles } from "@material-ui/core";
// import CampusBarGraph from "./CampusBarGraph";
// import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
// import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
// import ReportTable from "./ReportTable";
// import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
// import CampusPieGraph from "./CampusPieGraph";
// import ReportTableSecond from "./ReportTableSecond";
import PieTab from "./PieTab";
import CampusBarhorizontalGraph from "./CampusBarhorizontalGraph";
import PieSkeleton from "./PieSkeleton";
import { Autocomplete } from "@material-ui/lab";

const SearchTextField = withStyles({
  root: {
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F5F5F5",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F5F5F5",
      },
      "&:hover fieldset": {
        borderColor: "#F5F5F5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F5F5F5",
      },
    },
  },
})(TextField);

function ReportHeadingSecond() {
  const theme = useTheme();
  const [value, setValue] = useState(1);
  function handleChange(id) {
    setValue(id);
  }

  const [tabvalue, setTabValue] = useState(1);
  function handleChangeTab(id) {
    setTabValue(id);
  }

  const [search, setSearch] = React.useState(tabdata);
  const [inputValue, setInputValue] = React.useState("");

  // console.log(search.id, "testing");

  return (
    <>
      <div
        style={{
          border: "1px solid #b0b6ba",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
        >
          <div style={{ padding: "20px", width: "100%" }}>
            <div>{tabvalue?.tab}</div>
            {tabdata.map((item, id) => (
              <div id="myTabContent">
                <div
                  style={{
                    display: tabvalue === item.id ? "block " : "none",
                  }}
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {item?.tab}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              width: "500px",
              height: "400px",

              backgroundColor: "#FAFAFA",
              overflow: "scroll",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // padding: "20px",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "sticky",
                    top: "0px",
                    padding: 20,
                    backgroundColor: "#FAFAFA",
                  }}
                >
                  <Typography style={{ color: "#1C1C1C", fontSize: "16px" }}>
                    Select Course
                  </Typography>
                  <Autocomplete
                    id="combo-box-demo"
                    value={tabvalue}
                    freeSolo
                    onChange={(event, newValue) => {
                      setTabValue(newValue);
                    }}
                    defaultValue={[tabdata[1]]}
                    // onChange={() => handleChangeTab(tabdata.id)}
                    // inputValue={tabvalue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(tabdata.id, newInputValue);
                    }}
                    options={tabdata}
                    style={{ marginTop: "10px" }}
                    //onChange={() => handleChangeTab(tabdata.id)}
                    getOptionLabel={(tabdata) => tabdata.title}
                    renderInput={(params) => (
                      <SearchTextField
                        {...params}
                        variant="outlined"
                        placeholder="Search"
                        style={{
                          backgroundColor: "#F5F5F5",
                          borderRadius: 8,
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 20,
                    paddingTop: "0px",
                  }}
                >
                  {tabdata.map((item, id) => (
                    <ul
                      style={{
                        padding: 0,
                        display: "flex",
                        listStyleType: "none",
                        flexWrap: "wrap",
                        textAlign: "center",
                        width: "100%",
                      }}
                      id="myTab"
                      data-tabs-toggle="#myTabContent"
                      role="tablist"
                    >
                      <li
                        style={{ width: "100%" }}
                        role="presentation"
                        onChange={(event, newValue) => {
                          setTabValue(newValue);
                        }}
                        onClick={() => handleChangeTab(item.id)}
                      >
                        <div
                          style={{
                            padding: "10px",
                            cursor: "pointer",
                            borderRadius: "8px",
                            color: tabvalue === item.id ? "#fff" : "#000",
                            backgroundColor:
                              tabvalue === item.id
                                ? theme.palette.primary.main
                                : "#FAFAFA",
                          }}
                        >
                          {item.title}
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportHeadingSecond;

// const data = [
//   {
//     id: 1,
//     title: (
//       <div style={{ display: "flex", alignItems: "center" }}>
//         <TableChartOutlinedIcon style={{ marginRight: 5 }} /> Table
//       </div>
//     ),
//     tab: <ReportTableSecond />,
//   },
//   {
//     id: 2,
//     title: (
//       <div style={{ display: "flex", alignItems: "center" }}>
//         <BarChartOutlinedIcon /> Graph
//       </div>
//     ),
//     tab: <CampusPieGraph />,
//   },
// ];

const tabdata = [
  {
    id: 1,
    title: "Student Data",
    tab: <PieTab />,
  },
  {
    id: 2,
    title: "Job Data",
    tab: <PieTab />,
  },
  {
    id: 3,
    title: "Bar horizontal Graph",
    tab: <CampusBarhorizontalGraph />,
  },
  {
    id: 4,
    title: "Job Placed Data",
    tab: <PieTab />,
  },
  {
    id: 5,
    title: "Pie Skeleton",
    tab: <PieSkeleton />,
  },
  {
    id: 6,
    title: "Student Data",
    tab: <PieTab />,
  },
  {
    id: 7,
    title: "Job Data",
    tab: <PieTab />,
  },
  {
    id: 8,
    title: "Student Placed Data",
    tab: <PieTab />,
  },
  {
    id: 9,
    title: "Job Placed Data",
    tab: <PieTab />,
  },
  {
    id: 10,
    title: "Student Placed Data",
    tab: <PieTab />,
  },
  {
    id: 11,
    title: "Job Placed Data",
    tab: <PieTab />,
  },
];
