import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import pSBC from "shade-blend-color";
import {
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn
} from "../../../api/SignIn/selector";
import PlacementCyclesDialog from "../../DialogSection/UniManageJob";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    marginBottom: "10px",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
  span: {
    color: theme.palette.primary.main,
  },
  text: {
    marginBottom: "7px",
  },
  chipsection: {
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
}));

export default function CollegePostedJob({ list }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">{`${entityInfo?.college} and Placement Cycles`}</Typography>{" "}
            <Button
              color="primary"
              variant="text"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: 0,
                marginTop: 6,
              }}
              onClick={handleClickOpen}
            >
              {`View (${list.length})`}
            </Button>
          </div>
        </Paper>
      </div>
      <PlacementCyclesDialog
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        collegeToPlacementCycle={list}
      />
    </>
  );
}
