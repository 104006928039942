import React, { useState } from "react";
import { Typography, Checkbox, Grid, Box } from "@material-ui/core";
import default_logo from "../../../assets/png/default_college.png";
import { ReactComponent as CollegeIcon } from "../../../assets/svg/postjob1.svg";
import { ReactComponent as LocationIcon } from "../../../assets/svg/postjob2.svg";
import { ReactComponent as DocIcon } from "../../../assets/svg/postjob3.svg";

function AllColleges({
  id,
  setCollegesList,
  details,
  checked,
  setSearchState,
  disable = false,
  setSelectedCollegeIds,
}) {
  const handleChange = (event) => {
    setSelectedCollegeIds((prevSelected) => {
      if (event.target.checked) {
        return prevSelected.includes(id) ? prevSelected : [...prevSelected, id];
      } else {
        return prevSelected.filter((collegeId) => collegeId !== id);
      }
    });

    setCollegesList((prev) => {
      const newCollegesList = [...prev];
      const index = newCollegesList.findIndex((college) => college.id === id);

      if (index !== -1) {
        newCollegesList[index] = {
          ...newCollegesList[index],
          checked: event.target.checked,
          placementCycles: newCollegesList[index].placementCycles.map(
            (cycle) => ({
              ...cycle,
              checked: event.target.checked,
            })
          ),
        };
      }

      return newCollegesList;
    });

    setSearchState((prevSearchState) => {
      const newSearchState = [...prevSearchState];
      const index = newSearchState.findIndex((college) => college.id === id);

      if (index !== -1) {
        newSearchState[index] = {
          ...newSearchState[index],
          checked: event.target.checked,
          placementCycles: newSearchState[index].placementCycles.map(
            (cycle) => ({
              ...cycle,
              checked: event.target.checked,
            })
          ),
        };
      }

      return newSearchState;
    });
  };

  const data = [
    {
      icon: <CollegeIcon height="18px" width="18px" fill="#5B5B5B" />,
      text: details?.name,
    },
    {
      icon: <LocationIcon height="18px" width="18px" fill="#5B5B5B" />,
      text: details?.location,
    },
    {
      icon: <DocIcon height="18px" width="18px" fill="#5B5B5B" />,
      text: `Open Jobs: ${details?.openJobs}`,
    },
  ];

  return (
    <div>
      {" "}
      <Grid
        container
        spacing={2}
        style={{ margin: 0, width: "100%" }}
        alignItems="center"
      >
        <Grid item>
          <Checkbox
            style={{ padding: 0 }}
            color="primary"
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
            disabled={disable}
          />
        </Grid>
        <Grid item md={2} style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={details?.logo ? details?.logo : default_logo}
            style={{
              aspectRatio: "3/2",
              border: "1px solid #EEEEEE",
              width: "80px",
              height: "80px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </Grid>
        <Grid item md={8}>
          <Box style={{ display: "flex" }}>
            <Box>
              {data.map((item, id) => (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: id === 2 ? 0 : 6,
                  }}
                >
                  <Box style={{ display: "flex", marginTop: 4 }}>
                    {item.icon}
                  </Box>
                  <Typography
                    style={{
                      color: "#000",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default AllColleges;
