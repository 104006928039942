import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEducationGroupFn, userLoggedInSelectorFn } from "../../api/Domain/selector";
import Form from "../../components/Form";

function SignIn() {
  const history = useHistory();
  const isLoggedIn = useSelector(userLoggedInSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);

  //login states
  const [loginDetails, setLoginDetails] = useState({
    email: null,
    password: null,
  });

  //error states
  const [errorDetails, setErrorDetails] = useState({
    email: null,
    password: null,
  });

  //Login API Call
  useEffect(() => {
    if (isLoggedIn) {
      if (isEducationGroup) history.push("/dashboard");
      else history.push("/overview");
    }
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      <Form state={loginDetails} setState={setLoginDetails} errors={errorDetails} setErrors={setErrorDetails} />
    </>
  );
}

export default SignIn;
