import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  DayView,
  MonthView,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { createStyles, Theme, withStyles } from "@material-ui/core";
import { blue, indigo, teal } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import { fade } from "@material-ui/core/styles/colorManipulator";
import classNames from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { interviewGetGroup } from "../../api/Assignment/action";
import {
  collegeDetailSelectorFn,
  collegeGroupListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";

const styles = ({ palette }) =>
  createStyles({
    appointment: {
      borderRadius: 0,
      borderBottom: 0,
      backgroundColor: palette.primary.main,
      "&:hover": {
        backgroundColor: palette.primary.main,
      },
    },
    highPriorityAppointment: {
      borderLeft: `4px solid ${teal[500]}`,
    },
    mediumPriorityAppointment: {
      borderLeft: `4px solid ${blue[500]}`,
    },
    lowPriorityAppointment: {
      borderLeft: `4px solid ${indigo[500]}`,
    },
    weekEndCell: {
      backgroundColor: fade(palette.action.disabledBackground, 0.04),
      "&:hover": {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
      },
      "&:focus": {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
      },
    },
    weekEndDayScaleCell: {
      backgroundColor: fade(palette.action.disabledBackground, 0.06),
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    content: {
      opacity: 0.7,
    },
    container: {
      width: "100%",
      lineHeight: 1.2,
      height: "100%",
    },
    btn: {
      color: palette.primary.main,
    },
  });

const InterviewCalendarGroup = ({ selectedGroup, setSelectedGroup }) => {
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const data = useSelector((state) => state?.assignment?.allinterview);
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `group_id=${selectedGroup.id}`;
      else
        return `group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  useEffect(() => {
    if (date) {
      dispatch(
        interviewGetGroup(
          setLoading,
          alert,
          history,
          location,

          checkSelectedGroup,
          date
        )
      );
    }
  }, [date]);

  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  // useEffect(() => {
  //   if (collegeGroupList?.length > 0) {
  //     setSelectedGroup({ id: "All", name: "All College Groups" });
  //   }
  // }, [collegeGroupList?.length]);

  const DayScaleCell = withStyles(styles)(
    ({ startDate, classes, ...restProps }) => (
      <MonthView.DayScaleCell
        className={classNames({
          [classes.weekEndDayScaleCell]: isWeekEnd(startDate),
        })}
        startDate={startDate}
        {...restProps}
      />
    )
  );

  const TimeTableCell = withStyles(styles)(
    ({ startDate, classes, ...restProps }) => (
      <MonthView.TimeTableCell
        className={classNames({
          [classes.weekEndCell]: isWeekEnd(startDate),
        })}
        startDate={startDate}
        {...restProps}
      />
    )
  );

  const isWeekEnd = (date) => date.getDay() === 0 || date.getDay() === 6;

  const Appointment = withStyles(styles)(({ classes, data, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      className={classNames({
        [classes.highPriorityAppointment]: data.priority === 1,
        [classes.mediumPriorityAppointment]: data.priority === 2,
        [classes.lowPriorityAppointment]: data.priority === 3,
        [classes.appointment]: true,
      })}
      data={data}
      onClick={(item) => {
        // const formattedDate = moment(item.data.startDate).format("YYYY-MM-DD");
        // const url = `/interview?date=${formattedDate}`;
        // window.open(url, "_blank");
        history.push({
          pathname: "/interview",
          search: `?date=${moment(item.data.startDate).format("YYYY-MM-DD")}`,
        });
      }}
    />
  ));

  const AppointmentContent = withStyles(styles, { name: "AppointmentContent" })(
    ({ classes, data, ...restProps }) => {
      return (
        <Appointments.AppointmentContent {...restProps} data={data}>
          <div className={classes.container}>
            <div className={classes.text}>{data.title}</div>
          </div>
        </Appointments.AppointmentContent>
      );
    }
  );

  const DateNavigatorse = withStyles(styles, { name: "Date" })(
    ({ classes, ...restProps }) => (
      <DateNavigator.OpenButton {...restProps} className={classes.btn} />
    )
  );

  return (
    <div>
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <Paper>
          <Scheduler data={data} height={500}>
            <ViewState
              defaultCurrentViewName="Month"
              defaultCurrentDate={date}
              onCurrentDateChange={(e) =>
                setDate(moment(e).format("YYYY-MM-DD"))
              }
            />
            <DayView />
            <WeekView />
            <MonthView
              dayScaleCellComponent={DayScaleCell}
              timeTableCellComponent={TimeTableCell}
            />

            <Appointments
              appointmentComponent={Appointment}
              appointmentContentComponent={AppointmentContent}
            />
            <AppointmentTooltip showCloseButton />
            <Toolbar />
            <DateNavigator openButtonComponent={DateNavigatorse} />
            <ViewSwitcher />
            <TodayButton />
          </Scheduler>
        </Paper>
      )}
    </div>
  );
};

export default InterviewCalendarGroup;
