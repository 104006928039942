import {
  Box, Button, Grid,
  IconButton, InputBase, Paper, TextField, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { niyuktiAxiosGetReq } from "../../api/BaseApi/apiBase";
import { getJobsAPI } from "../../api/JobPost/action";
import { collegeIdFn } from "../../api/SignIn/selector";
import JobList from "../../components/Cards/RecommendedJobs/JobList";
import CustomCircularProgress from "../../components/CircularProgressBar";
import FilterDrawer from "../../components/DialogSection/FilterDrawer/FilterDrawer";
import JobDetail from "../../components/DialogSection/JobDescription/JobDetail";
import NoDataFunc from "../../components/NoDataSVG/index1";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    // justifyContent: "space-between",
    // alignItems: "center",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  filterSection: {
    display: "flex",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  actionBar: {
    marginBottom: "1.25rem",
    marginTop: "20px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  iconButton: {
    padding: 10,
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "12rem",
    boxShadow: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  buttonClear: {
    borderRadius: 8,
    width: 108,
    height: 50,
    color: "#6C757D",
    backgroundColor: "white",
    border: ".5px solid #6C757D",
    cursor: "pointer",
  },
  filtersCount: {
    // color: theme.palette.primary.main,
    color: "white",
    // backgroundColor: pSBC(0.8, theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    position: "fixed",
    height: "9%",
    width: "auto",
    top: "41%",
    // left: '80%',
    right: "58%",
  },
  pageLoader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    // alignItems: 'center',
  },
  noDataAvailableMessage: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  floatingLabelFocusStyle: {
    color: "#E9E9E9",
    alignSelf: "centre",
    fontSize: 14,
  },
}));

const RecommendedJobs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();

  const collegeId = useSelector(collegeIdFn);

  const [allJobs, setAllJobs] = useState([]);
  const [pageloading, setPageloading] = useState(true);
  const initialJobsCount = {
    totalCounts: "",
    filteredJobCounts: "",
  };
  const [totalJobsCount, setTotalJobsCount] = useState(initialJobsCount);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [pageNo, setPageNo] = useState(1);

  const [filterData, setFilterData] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterDataError, setFilterDataError] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [clickFilterApplyButton, setClickFilterApplyButton] = useState(false);
  const [showSkeletonCards, setShowSkeletonCards] = useState(false);
  const [filterPayLoad, setFilterPayLoad] = useState("");
  const [searchFilter, setSearchFilter] = useState(null);

  const [jobDetails, setJobDetails] = useState(null);
  const [currentJobDetailIndex, setCurrentJobDetailIndex] = useState(null);
  const [clickSearchButton, setClickSearchButton] = useState(false);

  const initialFilters = {
    jobType: null,
    sortBy: null,
    searchByJobTiltle: null,
  };
  const [filterValues, setFilterValues] = useState(initialFilters);

  const [filterObject, setFilterObject] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onPressingEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setFilterPayLoad(
        (prevFilterString) => prevFilterString + `&search=${searchFilter}`
      );
      setClickSearchButton(true);
    }
  };

  const handleClearFilters = () => {
    setSelectedFilters(null);
    setFilterPayLoad("");
    setFilterValues({ ...initialFilters });
    setClearFilter(true);
  };

  const handleJobDetails = (data, jobIndex) => {
    setJobDetails(data);
    setCurrentJobDetailIndex(jobIndex);
  };

  const handleNotInterestedCard = {
    undo: (jobIndex) => {
      let tempArr = allJobs[jobIndex];
      tempArr = { ...allJobs[jobIndex], removed: false };

      let tempAllJobs = [...allJobs];
      tempAllJobs.splice(jobIndex, 1, tempArr);
      setAllJobs([...tempAllJobs]);
    },
    notInterested: (jobIndex) => {
      let tempArr = allJobs[jobIndex];
      tempArr = { ...allJobs[jobIndex], removed: true };
      let tempAllJobs = [...allJobs];
      tempAllJobs.splice(jobIndex, 1, tempArr);
      setAllJobs([...tempAllJobs]);
    },
  };

  const handleforwardJobDetail = () => {
    if (currentJobDetailIndex < allJobs?.length - 1) {
      setJobDetails(allJobs[currentJobDetailIndex + 1]?.["job"]);
      setCurrentJobDetailIndex((jobIndex) => jobIndex + 1);
    }
  };
  const handleBackwardJobDetail = () => {
    // disabled for first job
    if (!(currentJobDetailIndex === 0)) {
      setJobDetails(allJobs[currentJobDetailIndex - 1]?.["job"]);
      setCurrentJobDetailIndex((jobIndex) => jobIndex - 1);
    }
  };

  useEffect(() => {
    if (selectedFilters && Object.keys(selectedFilters).length > 0) {
      let payloadString = "";

      Object.keys(selectedFilters).map((item) => {
        if (selectedFilters?.[item].length > 0) {
          const ids = selectedFilters?.[item]?.map(
            (filteredField) => filteredField?.id
          );
          payloadString = payloadString + `&${item}=${ids.join(",")}`;
          // console.log('id payi hai payload bhi paye gein', payloadString);
        }
      });

      setFilterPayLoad(payloadString);
    }
  }, [selectedFilters]);

  useEffect(() => {
    getFilteredData();
  }, [filterPayLoad]);

  const getFilteredData = async () => {
    const endPoint = `/job/college_saas/job_recommendation/?filtered_response=true${filterPayLoad}`;

    setPageloading(true);

    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      if (res?.data?.success) {
        setNextPageUrl(res?.data?.data?.["next"]);

        if (totalJobsCount?.totalCounts) {
          setTotalJobsCount((prevData) => ({
            ...prevData,
            filteredJobCounts: res?.data?.data?.["count"],
          }));
        } else {
          setTotalJobsCount((prevData) => ({
            ...prevData,
            totalCounts: res?.data?.data?.["count"],
          }));
        }

        setAllJobs([...res?.data?.data?.["results"]]);
      }
      setPageloading(false);
    } catch (error) {
      setPageloading(false);
      throw error;
    }
  };

  const addNextPageJobs = async () => {
    setShowSkeletonCards(true);
    try {
      const res = await axios.get(nextPageUrl, { withCredentials: true });
      if (res?.data?.success) {
        setNextPageUrl(res?.data?.data?.["next"]);

        setAllJobs([...allJobs, ...res?.data?.data?.["results"]]);
      }
      setShowSkeletonCards(false);
    } catch (error) {
      setShowSkeletonCards(false);
      throw error;
    }
  };

  useEffect(() => {
    if (nextPageUrl) {
      addNextPageJobs();
    }
  }, [pageNo]);

  const getFiltersForRecommendedJobs = async () => {
    const endPoint = `job/filter/job_lake/`;

    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      const data = res?.data?.data;
      if (res?.data?.success) {
        let tempArr = { ...data };

        Object.keys(tempArr).forEach((header, index) => {
          let singlefilterArray = tempArr[header]?.data?.map((item) => {
            const modifiedItem = {
              ...item,
              is_selected: false,
            };
            return modifiedItem;
          });

          tempArr[header]["data"] = singlefilterArray;
        });
        setFilterObject(tempArr);
        setFilterData(tempArr);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getFilteredData();
    getFiltersForRecommendedJobs();
    const url = `api/manage_job/view/?college_id=${collegeId}`;
    dispatch(getJobsAPI(() => { }, alert, history, location, url));
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div>
          <Typography variant="h2" sx={{ fontWeight: "600" }}>
            {/* GetWork Fulfilled Job ({totalJobsCount}) */}
            GetWork Fulfilled Job (18K+)
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
          // style={{ marginTop: "10px" }}
          >
            We have {totalJobsCount?.totalCounts} jobs based on your preference.
          </Typography>
        </div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant={"outlined"}
            color="primary"
            startIcon={<PhoneOutlinedIcon />}
            onClick={() => {
              const url = `${window.location.origin}/Contact`;
              window.open(url, "_blank").focus();
              // history.push(`/Contact`)
            }}
            style={{
              boxShadow: "none",
              borderRadius: "8px",
              height: "48px",
              display: "flex",
              marginRight: 20,
            }}
          >
            Contact Sales Team
          </Button>
        </Box>
      </Box>

      <Box style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search by Job Title"
                inputProps={{
                  style: { fontSize: "14px" },
                }}
                onKeyPress={onPressingEnter}
                value={searchFilter}
                onChange={(e) => {
                  setSearchFilter(e.target.value);
                  setClickSearchButton(false);
                  if (e.target.value === "") {
                    if (filterPayLoad) {
                      setFilterPayLoad((prevFilterString) => {
                        let newfilterString =
                          prevFilterString?.split("&search=");
                        setFilterPayLoad(newfilterString[0]);
                      });
                      setSearchFilter("");
                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (searchFilter) {
                      setFilterPayLoad(
                        (prevFilterString) =>
                          prevFilterString + `&search=${searchFilter}`
                      );
                      setClickSearchButton(true);
                    }
                  }
                }}
              />

              {searchFilter && clickSearchButton && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilterPayLoad((prevFilterString) => {
                      let newfilterString = prevFilterString?.split("&search=");
                      // console.log(newfilterString);
                      setFilterPayLoad(newfilterString[0]);
                    });
                    setClickSearchButton(false);
                    setSearchFilter("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              {!clickSearchButton ? (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    if (searchFilter) {
                      setFilterPayLoad(
                        (prevFilterString) =>
                          prevFilterString + `&search=${searchFilter}`
                      );
                      setClickSearchButton(true);
                    }
                  }}
                >

                  <SearchIcon />
                </IconButton>
              ) : null}
            </Paper>
          </Grid>
          <Grid item md={2}>
            <Autocomplete
              options={filterData ? filterData?.job_type?.data : []}
              value={filterValues?.jobType?.name ? filterValues?.jobType : null}
              getOptionLabel={(option) => option?.name}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option?.name}
                </Typography>
              )}
              style={{ background: "#fff", borderRadius: 8 }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setFilterValues((prevData) => ({
                    ...prevData,
                    jobType: newValue,
                  }));
                  setFilterPayLoad(
                    (prevFilterString) =>
                      prevFilterString + `&job_type_id=${newValue?.id}`
                  );
                } else {
                  setFilterValues((prevData) => ({
                    ...prevData,
                    jobType: null,
                  }));
                  setFilterPayLoad((prevFilterString) => {
                    let newfilterString =
                      prevFilterString?.split("&job_type_id=");
                    // console.log(newfilterString);
                    setFilterPayLoad(newfilterString[0]);
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  // style={{ color: 'red' }}
                  label="Job Type"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Grid>
          {/* <Grid item md={2}>
                        <Autocomplete
                            options={top100Films}
                            getOptionLabel={(option) => option.title}
                            renderOption={(option) => (
                                <Typography style={{ fontSize: "14px" }}>
                                    {option.title}
                                </Typography>
                            )}
                            style={{ background: "#fff", borderRadius: 30 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Sort by"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    InputLabelProps={{
                                        className: classes.floatingLabelFocusStyle,
                                    }}
                                />
                            )}
                        />
                    </Grid> */}
          {filterPayLoad?.length > 0 ? (
            <Grid item md={2} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "8px",
                  boxShadow: "none",
                  height: "48px",
                }}
                onClick={handleClearFilters}
              >
                Clear Filter
              </Button>
            </Grid>
          ) : null}

          <Grid item md={2}>
            <FilterDrawer
              filterData={filterData}
              filterLoading={filterLoading}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
              setClickFilterApplyButton={setClickFilterApplyButton}
              filterDataError={filterDataError}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />
            {/* <CollegeFilterDrawer /> */}
          </Grid>
        </Grid>
      </Box>
      <Grid item md={12}>
        <Box>
          {/* <FormControlLabel
                        control={<Checkbox size="small" color="primary" />}
                        label={`Select All Jobs `}
                    /> */}
          <br />
          <span
            style={{
              color: "#6C757D",
              fontSize: "14px",
              fontStyle: "italic",
            }}
          >
            {totalJobsCount?.filteredJobCounts
              ? `Total Counts of filter results:${totalJobsCount?.filteredJobCounts} `
              : null}
          </span>
        </Box>
      </Grid>
      <section className={classes.grid}>
        <section className={classes.actionBar}>
          <section className={classes.filterSection}>
            {/* <Grid item xs={12} sm={6} style={{
                            marginLeft: 10, minWidth: "16rem",
                        }}>
                            <Paper component="form" className={classes.root}>
                                <InputBase
                                    className={classes.input}
                                    placeholder="Search by Company Name, Job Role"
                                    inputProps={{
                                        style: { fontSize: "14px" },
                                    }}
                                    value={searchFilter}
                                    onChange={(e) => {
                                        setSearchFilter(e.target.value);
                                    }}
                                />
                                {searchFilter && clickSearchButton && (
                                    <IconButton
                                        className={classes.iconButton}
                                        aria-label="search"
                                        onClick={() => {
                                            setFilterPayLoad((prevFilterString) => {
                                                let newfilterString = prevFilterString?.split('&search=');
                                                setFilterPayLoad(newfilterString[0]);
                                            }

                                            );
                                            setClickSearchButton(false);
                                            setSearchFilter('');
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                {!clickSearchButton ?
                                    <IconButton
                                        className={classes.iconButton}
                                        aria-label="search"
                                        onClick={() => {

                                            setFilterPayLoad((prevFilterString) => prevFilterString + `&search=${searchFilter}`);
                                            setClickSearchButton(true);
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                    : null

                                }

                            </Paper>
                        </Grid> */}

            {/* <Grid item xs={12}
                            sm={6}
                            style={{
                                marginLeft: 10,
                                // width: '30%',
                            }}
                        >
                            <Badge
                                badgeContent={4}

                                color="primary"
                            >


                                <Paper component="form"
                                    className={classes.root2}
                                    onClick={handleAdvancedFilter}
                                >

                                    <IconButton
                                        className={classes.iconButton}
                                        aria-label="search"
                                        onClick={() => {
                                            // setClickSearchButton(true);
                                        }}
                                    >
                                        <FilterList />
                                    </IconButton>
                                    <Typography>
                                        Advanced Filter
                                    </Typography>


                                </Paper>
                            </Badge>
                        </Grid> */}


          </section>
          <section></section>
        </section>

        {totalJobsCount?.totalCounts === 0 ||
          totalJobsCount?.filteredJobCounts === 0 ? (
          <section className={classes.noDataAvailableMessage}>
            <NoDataFunc width={"300Px"} height={"200px"} />
            <Typography style={{ fontSize: "16px" }}>
              No Data Available
            </Typography>
          </section>
        ) : null}

        {pageloading ? (
          <section className={classes.pageLoader}>
            <CustomCircularProgress />
          </section>
        ) : (
          <>
            <JobList
              allJobs={allJobs}
              handleOpen={handleOpen}
              nextPageUrl={nextPageUrl}
              setPageNo={setPageNo}
              pageNo={pageNo}
              showSkeletonCards={showSkeletonCards}
              handleJobDetails={handleJobDetails}
              handleNotInterestedCard={handleNotInterestedCard}
            />
          </>
        )}
      </section>
      <JobDetail
        open={open}
        handleClose={handleClose}
        jobDetails={jobDetails}
        handleforwardJobDetail={handleforwardJobDetail}
        handleBackwardJobDetail={handleBackwardJobDetail}
      />
    </>
  );
};

export default RecommendedJobs;
