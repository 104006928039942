import { Skeleton } from "@material-ui/lab";
import React from "react";

function SmallCardsSkeleton() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Skeleton
        variant="rectangular"
        width={250}
        height={100}
        style={{ marginBottom: "20px" }}
      />
      <Skeleton
        variant="rectangular"
        width={250}
        height={100}
        style={{ marginBottom: "20px" }}
      />
      <Skeleton
        variant="rectangular"
        width={250}
        height={100}
        style={{ marginBottom: "20px" }}
      />
    </div>
  );
}

export default SmallCardsSkeleton;
