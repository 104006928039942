import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, withStyles } from "@material-ui/core";
import CompanyCard from "./CompanyCard";
import OverViewCard from "./OverViewCard";
import { useEffect } from "react";
import OverViewSkelton from "./OverViewSkelton";
import CompanyCardSkelton from "./CompanyCardSkelton";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          className="livejobcardscroll"
          style={{ padding: 8, height: "62dvh", overflowY: "scroll" }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewTabs = withStyles((theme) => ({
  root: {
    // borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#000",
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function TabWithApply({
  setHrDetails,
  hrDetails,
  companyId,
  jobId,
  collegeId,
  isLiveJobs,
  pageloading,
  allJobs,
  setAllJobs
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (hrDetails) setValue(1);
    else setValue(0);
  }, [hrDetails]);

  return (
    <Box style={{ width: "100%", backgroundColor: "#ffff", borderRadius: 8 }}>
      <Box style={{ borderBottom: "1px solid #D5D7D8" }}>
        <NewTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <NewTab label="Overview" {...a11yProps(0)} />
          <NewTab label="Company" {...a11yProps(1)} />
        </NewTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {/* <OverViewSkelton /> */}
        <OverViewCard
          jobId={jobId}
          collegeId={collegeId}
          isLiveJobs={isLiveJobs}
          pageloading={pageloading}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {/* <CompanyCardSkelton /> */}
        <CompanyCard
          hrDetails={hrDetails}
          setHrDetails={setHrDetails}
          collegeId={collegeId}
          companyId={companyId}
          isLiveJobs={isLiveJobs}
          pageloading={pageloading}
          allJobs={allJobs}
          setAllJobs={setAllJobs}
        />
      </CustomTabPanel>
    </Box>
  );
}
