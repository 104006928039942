// import React, { useEffect, useState } from "react";
// import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import { useTheme, makeStyles } from "@material-ui/styles";
// import {
//   Paper,
//   IconButton,
//   InputBase,
//   Box,
//   Checkbox,
//   Typography,
//   FormControlLabel,
//   DialogActions,
//   Button,
// } from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
// import AllCollegesGrid from "./AllCollegesGrid";
// import { useAlert } from "react-alert";
// import { entityInfoSelectorFn } from "../../../api/SignIn/selector";
// import { useSelector } from "react-redux";
// import AllEligibleGrid from "./AllEligibleGrid";
// import AllCourseGrid from "./AllCourseGrid";
// import DegreeSpecializationSingleCollege from "../../DegreeSpecializationCustom/DegreeSpecializationSingleCollege";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: "2px 2px",
//     display: "flex",
//     alignItems: "center",
//     boxShadow: "none",
//     border: "1px solid #6C757D",
//     width: "300px ",
//   },
//   input: {
//     marginLeft: theme.spacing(1),
//     flex: 1,
//   },
//   iconButton: {
//     padding: 10,
//   },
//   divider: {
//     height: 28,
//     margin: 4,
//   },
// }));

// export default function AddEligibleCoursesDialog({
//   open,
//   handleClose,
//   allCollegeCoursesSet,
//   setallCollegeCoursesSet,
//   collegesList,
//   setCollegesList,
//   loading,
//   setGlobalCoursesList,
//   globalCoursesList,
// }) {
//   const [fullWidth, setFullWidth] = useState(true);
//   const [maxWidth, setMaxWidth] = useState("lg");
//   const alert = useAlert();

//   const entityInfo = useSelector(entityInfoSelectorFn) || {
//     college: "College",
//     university: "University",
//   };

//   const handleSaveAndUpdate = () => {
//     const isAnySpecializationSelectedInAnyEducationInAllColleges = Object.keys(
//       allCollegeCoursesSet
//     ).every((collegeKey) => {
//       const collegeCourses = allCollegeCoursesSet[collegeKey];

//       return collegeCourses.some((education) => {
//         return education.degrees.some((degree) => {
//           return degree.specialization.some((spec) => spec.selected);
//         });
//       });
//     });
//     if (!isAnySpecializationSelectedInAnyEducationInAllColleges) {
//       alert.error(
//         `Please select atleast one course/specialization for all ${entityInfo?.college} to proceed`
//       );
//     } else {
//       handleClose();
//     }
//   };

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         fullWidth={fullWidth}
//         maxWidth={maxWidth}
//         aria-labelledby="scroll-dialog-title"
//         aria-describedby="scroll-dialog-description"
//       >
//         <DialogTitle id="scroll-dialog-title">
//           <Typography style={{ color: "#5B5B5B", fontSize: "16px" }}>
//             Add Eligible Courses
//           </Typography>
//         </DialogTitle>
//         <DialogContent
//           dividers
//           style={{
//             paddingLeft: 12,
//             paddingRight: 12,
//             paddingTop: 0,
//             paddingBottom: 0,
//           }}
//         >
//           <Box sx={{ py: 3 }}>
//             <DegreeSpecializationSingleCollege
//               coursesArray={globalCoursesList}
//               setCoursesArray={setGlobalCoursesList}
//               errorMessage={null}
//               loading={loading}
//               allCollegeCoursesSet={allCollegeCoursesSet}
//               setallCollegeCoursesSet={setallCollegeCoursesSet}
//             />
//           </Box>
//           <Box>
//             <AllCourseGrid
//               allCollegeCoursesSet={allCollegeCoursesSet}
//               setallCollegeCoursesSet={setallCollegeCoursesSet}
//               collegesList={collegesList}
//               setCollegesList={setCollegesList}
//               loading={loading}
//               setGlobalCoursesList={setGlobalCoursesList}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions style={{ padding: 15, paddingRight: 25 }}>
//           <Button
//             onClick={handleClose}
//             variant="outlined"
//             color="inherit"
//             style={{ height: "40px", boxShadow: "none", width: "200px" }}
//           >
//             Cancel
//           </Button>
//           <Button
//             variant="contained"
//             color="primary"
//             style={{ height: "40px", boxShadow: "none", width: "200px" }}
//             onClick={handleSaveAndUpdate}
//           >
//             Save and Update
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme, makeStyles } from "@material-ui/styles";
import {
  Paper,
  IconButton,
  InputBase,
  Box,
  Typography,
  Button,
  DialogActions,
} from "@material-ui/core";
import AllCourseGrid from "./AllCourseGrid";
import DegreeSpecializationSingleCollege from "../../DegreeSpecializationCustom/DegreeSpecializationSingleCollege";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { entityInfoSelectorFn } from "../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid #6C757D",
    width: "300px ",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function AddEligibleCoursesDialog({
  open,
  handleClose,
  allCollegeCoursesSet,
  setallCollegeCoursesSet,
  collegesList,
  setCollegesList,
  loading,
  setGlobalCoursesList,
  globalCoursesList,
  nestedColleges,
  groups,
}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const alert = useAlert();

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const [tempGlobalCoursesList, setTempGlobalCoursesList] =
    useState(globalCoursesList);
  const [tempAllCollegeCoursesSet, setTempAllCollegeCoursesSet] =
    useState(allCollegeCoursesSet);

  useEffect(() => {
    if (open) {
      setTempGlobalCoursesList(globalCoursesList);
      setTempAllCollegeCoursesSet(allCollegeCoursesSet);
    }
  }, [open, globalCoursesList, allCollegeCoursesSet]);

  const handleSaveAndUpdate = () => {
    const isAnySpecializationSelectedInAnyEducationInAllColleges = Object.keys(
      tempAllCollegeCoursesSet
    ).every((collegeKey) => {
      const collegeCourses = tempAllCollegeCoursesSet[collegeKey];

      return collegeCourses.some((education) => {
        return education.degrees.some((degree) => {
          return degree.specialization.some((spec) => spec.selected);
        });
      });
    });

    if (!isAnySpecializationSelectedInAnyEducationInAllColleges) {
      alert.error(
        `Please select atleast one Course Family/Course  for all center to proceed`
      );
    } else {
      setGlobalCoursesList(tempGlobalCoursesList);
      setallCollegeCoursesSet(tempAllCollegeCoursesSet);
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography style={{ color: "#5B5B5B", fontSize: "16px" }}>
            Add Eligible Courses
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <Box sx={{ py: 3 }}>
            <DegreeSpecializationSingleCollege
              coursesArray={tempGlobalCoursesList}
              setCoursesArray={setTempGlobalCoursesList}
              errorMessage={null}
              loading={loading}
              allCollegeCoursesSet={tempAllCollegeCoursesSet}
              setallCollegeCoursesSet={setTempAllCollegeCoursesSet}
            />
          </Box>
          <Box>
            <AllCourseGrid
              allCollegeCoursesSet={tempAllCollegeCoursesSet}
              setallCollegeCoursesSet={setTempAllCollegeCoursesSet}
              collegesList={collegesList}
              setCollegesList={setCollegesList}
              loading={loading}
              setGlobalCoursesList={setTempGlobalCoursesList}
              nestedColleges={nestedColleges}
              groups={groups}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 15, paddingRight: 25 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="inherit"
            style={{ height: "40px", boxShadow: "none", width: "200px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ height: "40px", boxShadow: "none", width: "200px" }}
            onClick={handleSaveAndUpdate}
          >
            Save and Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
