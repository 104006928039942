import { PLACEMENT_CYCLES, USER_ROLES_ALL } from "./action";

export const PlacementCycles = (state = {}, action) => {
  switch (action.type) {
    case PLACEMENT_CYCLES:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const AllUserRoles = (state = {}, action) => {
  switch (action.type) {
    case USER_ROLES_ALL:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};
