import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CheckList({ localAccessControlList, setLocalAccessControlList, fromEdit, currentEditRole, groupName, setGroupName }) {
  const classes = useStyles();
  const [selectAll, setSelectAll] = useState(false);
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const accessControlList = useSelector((state) => state?.AccessControls?.details);

  useEffect(() => {
    if (accessControlList?.length > 0) {
      var arr = [...accessControlList];
      arr?.forEach((element) => {
        element["is_selected"] = checkIfExists(element);
      });
      setLocalAccessControlList(arr);
      var result = arr.reduce(function (r, a) {
        if (a.access_control_group_name) {
          r[a.access_control_group_name] = r[a.access_control_group_name] || [];
          r[a.access_control_group_name].push(a);
        }

        return r;
      }, Object.create(null));

      setGroupName(result);
    }
  }, [accessControlList?.length]);

  const handleSelectionChange = (e, item) => {
    var arr = [...localAccessControlList];
    item.is_selected = e.target.checked;
    var index = arr.findIndex((i) => i.id === item.id);
    if (index !== -1) {
      arr[index] = item;
      setLocalAccessControlList(arr);
    }
  };

  const checkIfExists = (i) => {
    var bool_ = false;
    if (currentEditRole && currentEditRole?.access_controls_list?.length > 0) {
      currentEditRole?.access_controls_list?.map((item) => {
        if (item.id === i.id) bool_ = true;
      });
    }
    return bool_;
  };

  useEffect(() => {
    if (localAccessControlList.every((i) => i.is_selected)) setSelectAll(true);
    else setSelectAll(false);
  }, [localAccessControlList]);

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <Checkbox
            color="primary"
            edge="start"
            checked={selectAll}
            tabIndex={-1}
            disableRipple
            // inputProps={{ "aria-labelledby": labelId }}
            onChange={(e) => {
              var arr = [...localAccessControlList];
              arr.forEach((item) => {
                item.is_selected = e.target.checked;
              });

              setLocalAccessControlList(arr);
              var result = arr.reduce(function (r, a) {
                if (a.access_control_group_name) {
                  r[a.access_control_group_name] = r[a.access_control_group_name] || [];
                  r[a.access_control_group_name].push(a);
                }

                return r;
              }, Object.create(null));

              setGroupName(result);
              setSelectAll(e.target.checked);
            }}
          />
        </ListItemIcon>
        <ListItemText style={{ marginLeft: "-20px" }} primary={"Select All"} />
      </ListItem>
      {Object.keys(groupName).map((item) => (
        <>
          <ListItemText style={{ marginLeft: "20px", fontWeight: "800", color: "grey" }} primary={item} />

          {groupName[item].map((value, index) => {
            const labelId = `checkbox-list-label-${value}`;
            return (
              <ListItem key={value.id} role={undefined} dense onClick={handleToggle(value.id)}>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    edge="start"
                    checked={value?.is_selected || selectAll}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    onChange={(e) => {
                      handleSelectionChange(e, value, index);
                    }}
                  />
                </ListItemIcon>
                <ListItemText style={{ marginLeft: "-20px" }} id={labelId} primary={value.access_control_name} />
              </ListItem>
            );
          })}
        </>
      ))}

      {/* localAccessControlList.map((value, index) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem key={value.id} role={undefined} dense onClick={handleToggle(value.id)}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                edge="start"
                checked={value?.is_selected}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
                onChange={(e) => {
                  handleSelectionChange(e, value, index);
                }}
              />
            </ListItemIcon>
            <ListItemText style={{ marginLeft: "-20px" }} id={labelId} primary={value.access_control_name} />
          </ListItem>
        );
      })} */}
    </List>
  );
}
