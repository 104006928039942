import React, { useEffect, useState } from "react";
import { Breadcrumbs, Typography, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useHistory } from "react-router-dom";
import Degree from "../../components/Cards/UniversityGraph/BarChart/Degree";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { EDGUniversityDegreeAPI } from "../../api/University/APIs/action";
import CustomCircularProgress from "../../components/CircularProgressBar";
import NoDataFunc from "../../components/NoDataSVG/index1";
const useStyles = makeStyles((theme) => ({
  Chip: {
    backgroundColor: "#FAFBFC",
    //   padding: "20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    //  width: "150px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "40px",
    color: "#8a94a6",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dot: {
    backgroundColor: theme.palette.primary.main,
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
  },
  paper: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    padding: "30px",
  },
  btn: {
    boxShadow: "none",
    height: "30px",
    borderRadius: "8px",
    display: "flex",
    backgroundColor: "#e0a800",
    color: "#fff",
  },
  item: {
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dotback: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    width: "120px",
    height: "40px",
    color: "#8a94a6",
    backgroundColor: "#FAFBFC",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dot: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function DegreeCampusPlacement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();

  const [loadingDegree, setLoadingDegree] = useState(false);
  useEffect(() => {
    dispatch(EDGUniversityDegreeAPI(setLoadingDegree, alert, history, location));
  }, []);

  const degrees = useSelector((state) => state?.EDGCommon?.edgDashboardDegree);

  return (
    <>
      {/* <UniversityLayout> */}
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: "10px", marginBottom: "20px" }}>
        <Link
          to="/dashboard"
          //  onClick={handleClick}
          style={{ textDecoration: "none" }}
        >
          <Typography color="secondary" variant="body2">
            Overview
          </Typography>
        </Link>
        <Link style={{ textDecoration: "none" }} color="inherit" href="/getting-started/installation/" onClick={handleClick}>
          <Typography color="primary" variant="body2">
            Degree Wise Campus Placement
          </Typography>
        </Link>
      </Breadcrumbs>

      <>
        <div style={{ width: "90%" }}>
          <Paper className={classes.paper}>
            {loadingDegree ? (
              <CustomCircularProgress />
            ) : degrees?.categories?.length > 0 ? (
              <>
                {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6">Degree wise Campus Placement</Typography>
                  <Link to="/degree-graph" style={{ textDecoration: "none" }}>
                    <Button variant="contained" className={classes.btn}>
                      View All
                    </Button>
                  </Link>
                </div> */}

                <div style={{ display: "flex", borderBottom: ".5px solid #f3f3f3" }}>
                  <div className={classes.item}>
                    <div className={classes.dotback}>
                      <div
                        className={classes.dot}
                        style={{
                          backgroundColor: "#377dff",
                        }}
                      />
                      <Typography variant="overline">Total Students</Typography>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.dotback}>
                      <div
                        className={classes.dot}
                        style={{
                          backgroundColor: "#38cb89",
                        }}
                      />
                      <Typography variant="overline">Placed Student</Typography>
                    </div>
                  </div>
                </div>
                <Degree data={degrees} />
                <Typography variant="body2" style={{ fontWeight: "bold", textAlign: "center" }}>
                  Number of Applicants
                </Typography>
              </>
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6">Degree wise Campus Placement</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <NoDataFunc width={"300Px"} height={"200px"} />
                  <Typography style={{ fontSize: "16px" }}> No Data Available</Typography>{" "}
                </div>
              </>
            )}
          </Paper>
        </div>
      </>

      {/* </UniversityLayout> */}
    </>
  );
}

export default DegreeCampusPlacement;
