import { Button, Dialog, TextField, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  button: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
}));

export default function BlockSingleTeam({ data, blockMember }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [buttonName, setButtonName] = useState("");
  const [buttonNameLower, setButtonNameLower] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [trigger, setTrigger] = useState(false);
  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const [companyDetails, setCompanyDetails] = useState({
    college_list: [],
  });

  const [companyDetailsError, setCompanyDetailsError] = useState({
    college_list: null,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const companyList = useSelector((state) => state?.CompanyData?.details);
  const teamList = useSelector((state) => state?.TeamData?.details);

  return (
    <div>
      <Button
        color="primary"
        style={{ padding: "0px" }}
        //   onClick={() => {
        //     if (data?.status === "ACTIVE") blockMember("INACTIVE");
        //     else blockMember("ACTIVE");
        //   }}
        onClick={handleClickOpen}
      >
        {data?.status === "ACTIVE" ? "Block Member" : "Unblock Member"}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "400px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure. <br />
              You want to block this member
            </Typography>
          </div>

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            multiline
            rows="2"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label={`Why do you want to block ${buttonNameLower} team member?`}
            value={deleteReason}
            onChange={(e) => {
              setDeleteReason(e.target.value);
              setDeleteReasonError("");
            }}
            style={{ marginBottom: 10 }}
            error={deleteReasonError ? true : false}
            helperText={<span>{deleteReasonError}</span>}
          />
        </div>

        <div className={classes.btngrid}>
          <Button
            variant="outlined"
            style={{ minWidth: "160px" }}
            className={classes.button}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            autoFocus
            className={classes.button}
            style={{ minWidth: "160px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (!deleteReason) {
                setDeleteReasonError("Please Enter Reason to block/unblock");
                return;
              }
              blockMember(
                data?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                deleteReason
              );
              //   handleSubmit(status);
            }}
          >
            {data?.status === "ACTIVE" ? "Block Member" : "Unblock Member"}
          </Button>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
