import { DESIGNATIONS, DESIGNATION_TEAM_LIST, JOB_LIST, NAVBAR, PUBLIC_USER_ROLE, SINGLE_TEAM_MEMBER, TEAMS_DATA, TEAM_INVITE_LINK } from "./action";

export const TeamData = (state = {}, action) => {
  switch (action.type) {
    case TEAMS_DATA:
      return { ...state, details: action.payload };
    case NAVBAR:
      return { ...state, navbar: action.payload };
    case DESIGNATIONS:
      return { ...state, designationList: action.payload };
    case DESIGNATION_TEAM_LIST:
      return { ...state, designationTeamList: action.payload };
    case SINGLE_TEAM_MEMBER:
      return { ...state, teamMember: action.payload };
    case JOB_LIST:
      return { ...state, listJobs: action.payload };
    case TEAM_INVITE_LINK:
      return { ...state, link: action.payload };
    case PUBLIC_USER_ROLE:
      return { ...state, userRole: action.payload };
    default:
      return state;
  }
};
