import {
  // FormControl,
  Grid,
  IconButton,
  InputBase,
  //InputLabel,
  // MenuItem,
  Typography,
  Paper,
  //Select,
  TextField,
  Tooltip,
  Menu,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect } from "react";
import Calendar from "../Calendar/index3";
import FilterCTC from "../FilterCTC";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { alpha, styled } from "@mui/material/styles";
import MuiCustomTheme from "../../../components/NewMUITheme/MuiCustomTheme";
import TableChartIcon from "@material-ui/icons/TableChart";
const NewFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  //height: "49px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  "& label.Mui-focused": {
    //  color: "#C4C4C4",
  },
  "& label": {
    color: "#c5c4ba",
  },
  "& .MuiInput-underline:after": {
    //borderBottomColor: "#C4C4C4",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&:hover fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&.Mui-focused fieldset": {
      //borderColor: "#C4C4C4",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
    // color: "#6c757d",
    "& ::-webkit-input-placeholder": {
      color: "#000 !important",
    },
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    marginRight: "12px",
    boxShadow: "none",
    borderRadius: 8,
    height: "48px",
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "8px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
}));

function JobActionBar2({
  filters,
  setFilters,
  selectedRowsData,
  jobTitleSearch,
  companyNameSearch,
  setJobTitleSearch,
  setCompanyNameSearch,
  selectedID,
  setSelectedID,
  setApplyClickedCTC,
  collegeId,
  applyClickedCTC,
  tableColumns,
  setTableColumns,
  loading,
}) {
  const [checked, setChecked] = React.useState(true);
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setFilters({ ...filters, created: selectedDate });
  }, [selectedDate]);

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {!loading ? (
          <Grid container spacing={3} style={{ display: "contents" }}>
            <Grid item xs={2} className={classes.grid}>
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Job by title"
                  value={filters.job_title}
                  onChange={(e) => {
                    setFilters({ ...filters, job_title: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      setJobTitleSearch(true);
                    }
                  }}
                />
                {filters?.job_title && (
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={() => {
                      setFilters({ ...filters, job_title: "" });
                      setJobTitleSearch(true);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setJobTitleSearch(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={2} className={classes.grid}>
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Company Name"
                  value={filters.company_name}
                  onChange={(e) => {
                    setFilters({ ...filters, company_name: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      setCompanyNameSearch(true);
                    }
                  }}
                />
                {filters?.company_name && (
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={() => {
                      setFilters({ ...filters, company_name: "" });
                      setCompanyNameSearch(true);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setCompanyNameSearch(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={2} className={classes.grid}>
              <MuiCustomTheme>
                <NewFormControl
                  variant="outlined"
                  //className={classes.formControl}
                >
                  <InputLabel
                    //className={classes.floatingLabelFocusStyle}
                    style={{
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontFamily: "Nunito",
                    }}
                  >
                    Job Type
                  </InputLabel>
                  <Select
                    // IconComponent={ExpandMoreIcon}
                    value={filters.job_type}
                    onChange={(e) => {
                      setFilters({ ...filters, job_type: e.target.value });
                    }}
                    label="Job Type"
                    style={{
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontFamily: "Nunito",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: 300, borderRadius: "8px" },
                      },
                    }}
                    // inputProps={{
                    //   style: {
                    //     padding: "16px",
                    //     backgroundColor: "#fff",
                    //     borderRadius: "30px",
                    //   },
                    // }}
                    // MenuProps={{
                    //   style: {
                    //     marginTop: "55px",
                    //   },
                    // }}
                  >
                    <MenuItem value={null}>
                      <Typography variant="body2"> All </Typography>
                    </MenuItem>

                    <MenuItem value={7}>
                      <Typography variant="body2"> Full-Time </Typography>
                    </MenuItem>
                    <MenuItem value={1}>
                      <Typography variant="body2"> Internship </Typography>
                    </MenuItem>
                    <MenuItem value={3}>
                      <Typography variant="body2"> Training </Typography>
                    </MenuItem>
                  </Select>
                </NewFormControl>
              </MuiCustomTheme>
            </Grid>
            {filters?.job_status === "OPEN" && (
              <Grid item xs={2} className={classes.grid}>
                <MuiCustomTheme>
                  <NewFormControl variant="outlined">
                    <InputLabel
                      style={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                    >
                      Job Stage
                    </InputLabel>
                    <Select
                      value={filters?.job_stage_id}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          job_stage_id: e.target.value,
                        });
                      }}
                      label="Job Stage"
                      style={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: 300, borderRadius: "8px" },
                        },
                      }}
                    >
                      <MenuItem value={null}>
                        <Typography variant="body2"> All </Typography>
                      </MenuItem>
                      <MenuItem value={1}>
                        <Typography variant="body2">
                          Registration Scheduled{" "}
                        </Typography>
                      </MenuItem>
                      <MenuItem value={2}>
                        <Typography variant="body2">
                          {" "}
                          Registration Open
                        </Typography>
                      </MenuItem>
                      <MenuItem value={3}>
                        <Typography variant="body2">
                          Registration Closed{" "}
                        </Typography>
                      </MenuItem>
                      <MenuItem value={4}>
                        <Typography variant="body2">
                          Drive Scheduled{" "}
                        </Typography>
                      </MenuItem>
                      <MenuItem value={5}>
                        <Typography variant="body2">Drive Completed</Typography>
                      </MenuItem>
                    </Select>
                  </NewFormControl>
                </MuiCustomTheme>
              </Grid>
            )}

            {/* <Grid item xs={2} className={classes.grid}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  className={classes.floatingLabelFocusStyle}
                  style={{ borderColor: "red" }}
                >
                  Job Status
                </InputLabel>
                <Select
                  native
                  value={state.age}
                  onChange={handleChange}
                  style={{ borderRadius: "30px" }}
                  label="Job Status"
                  inputProps={{
                    style: {
                      padding: "16px",
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                    },
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={2} className={classes.grid}>
              <FilterCTC
                ctcData={filters}
                setCtcData={setFilters}
                setApplyClickedCTC={setApplyClickedCTC}
                title={"CTC"}
                subTitle={"LPA"}
              />
              <div></div>
            </Grid>
            <Grid item xs={2} className={classes.grid}>
              {" "}
              <Calendar
                title="Filter by Date"
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </Grid>

            <Grid item style={{ display: "grid" }}>
              <Tooltip title="Customize Table Field" placement="top">
                <Button
                  onClick={handleClick}
                  variant="outlined"
                  color="inherit"
                  style={{
                    height: "50px",
                    borderColor: "#b7b7b7",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <TableChartIcon style={{ color: "#757575" }} />
                </Button>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                }}
                transformOrigin={{
                  vertical: "top",
                }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    //marginTop: "60px",
                  },
                }}
              >
                {tableColumns &&
                  tableColumns?.map((variant) => (
                    <MenuItem key={variant.field} value={variant}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={!variant?.hide}
                            onChange={(e) => {
                              let tempArray = [...tableColumns];
                              var itemIndex = tempArray.findIndex(
                                (x) => x.field === variant.field
                              );
                              var item = tempArray[itemIndex];
                              item.hide = !e.target.checked;
                              tempArray[itemIndex] = item;
                              setTableColumns(tempArray);
                            }}
                          />
                        }
                        label={variant.headerName}
                      />
                    </MenuItem>
                  ))}
              </Menu>
            </Grid>

            {/* <Grid item xs={2}>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox checked={checked} size="small" color="primary" onChange={handleCheckChange} inputProps={{ "aria-label": "primary checkbox" }} />
  
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#51c03e",
                  }}
                />
                <Typography variant="body2" style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  Active
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox checked={checked} size="small" color="primary" inputProps={{ "aria-label": "primary checkbox" }} />
  
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#f64a3f",
                  }}
                />
                <Typography variant="body2" style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  Inactive
                </Typography>
              </div>
            </Grid> */}
            {/* <Grid
              xs={4}
              className={classes.grid}
              style={{ justifyContent: "flex-end" }}
            >
              <Link to="/create-assignment" style={{ textDecoration: "none" }}>
                <Button
                  className={classes.btn}
                  color="primary"
                  variant="contained"
                >
                  Create Assignment
                </Button>
              </Link>
            </Grid> */}
            {/* <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <DeleteJobDialog />
              <BlockCompany />
              <AddTeamMember />
              <AddJobPlacementCycle />
              <ChangeJobStatus />
            </Grid> */}
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default JobActionBar2;
