import React from "react";
import ArrowForwardIosRounded from "@material-ui/icons/ArrowForwardIosRounded";

function NextOfferArrow({ onClick }) {
  const btn = {
    height: "40px",
    width: "40px",
    backgroundColor: "#000",
    color: "#fff",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    top: "45%",
    bottom: "50%",
    right: "0px",
    zIndex: 1000,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  };

  return (
    <div onClick={onClick}>
      <div style={btn}>
        <ArrowForwardIosRounded />
      </div>
    </div>
  );
}

export default NextOfferArrow;
