import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography,Chip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import pSBC from "shade-blend-color";


const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    marginBottom: "15px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
   chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",

  },
  chipDisplay:{
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
  },

}));

export default function SaveProject({
  data,
  projectIndex,
  handleDelete,
  handleOpen,
  setAddProject,
  setIsEdit,
  setEditIndex,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography
            variant="body1"
            style={{
              marginBottom: "4px",
            }}
          >
            {data?.title}
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton
              color="primary"
              onClick={() => {
                setAddProject(data);
                setEditIndex(projectIndex);
                setIsEdit(true);
                handleOpen();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                handleDelete(data?.id, projectIndex, "Project");
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        {data?.links && (
          <a href={data?.links} style={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              color="primary"
              style={{
                marginBottom: "4px",
              }}
            >
              View Live
            </Typography>
          </a>
        )}
        <Typography
          variant="body1"
          style={{
            marginBottom: "4px",
          }}
        >
          {data?.description}
        </Typography>
        <div className={classes.chipDisplay}>
                {data?.skills &&
                  data?.skills.map((item, index) => {
                    return (
                      <li key={item.skill_id}>
                        <Chip
                          color="primary"
                          variant="outlined"
                          size="small"
                          label={item.skill_name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
        </div>
      </div>
    </>
  );
}
