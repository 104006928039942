import { Grid, Tooltip, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export const columnsCheck = (isEducationGroup, isCollegeGroup, history, entityInfo={}) => {
  const columns = [
    // { field: "id", headerName: "No", width: 100 },
    {
      field: "name",
      headerName: "name",
      width: 230,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "75px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid container wrap="nowrap">
            <Grid
              item
              xs
              zeroMinWidth
              style={{
                textAlign: "left",
                display: "flex",
                //    margin: "auto",
                fontSize: "14px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "8px",
                  marginRight: "10px",
                  width: "8px",
                  backgroundColor:
                    params.row.current_status === "ACTIVE"
                      ? "#51c03e"
                      : "#f64a3f",
                  borderRadius: "50%",
                }}
              />
              <Link
                to={`/team-profile/${params?.row?.team_id}`}
                style={{ textDecoration: "none", display: "contents" }}
              >
                {" "}
                <Tooltip title={params.row.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px" }}
                  >
                    {params.row.name}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     //  margin: "auto",
        //     fontSize: "14px",
        //     alignItems: "center",
        //   }}
        // >
        //   <div
        //     style={{
        //       height: "8px",
        //       marginRight: "10px",
        //       width: "8px",
        //       backgroundColor:
        //         params.row.current_status === "ACTIVE" ? "#51c03e" : "#f64a3f",
        //       borderRadius: "50%",
        //     }}
        //   />
        //   <Link
        //     to={`/team-profile/${params?.row?.id}`}
        //     style={{ textDecoration: "none" }}
        //   >
        //     <Typography color="primary" style={{ fontSize: "14px" }}>
        //       {params.row.name}
        //     </Typography>
        //   </Link>
        // </span>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "70px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     // margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   {params.row.email}
        // </span>
      ),
    },
    {
      field: "phone",
      headerName: "Phone Number",

      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "40px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.phone}
        </span>
      ),
      // valueGetter: params =>
      //   `${params.getValue(params.id, "firstName") || ""} ${
      //     params.getValue(params.id, "lastName") || ""
      //   }`,
    },

    {
      field: "designation",
      headerName: "Phone Number",

      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "40px",
          }}
        >
          Designation
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.designation}
        </span>
      ),
      // valueGetter: params =>
      //   `${params.getValue(params.id, "firstName") || ""} ${
      //     params.getValue(params.id, "lastName") || ""
      //   }`,
    },
    {
      field: "no_of_colleges",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "50px",
          }}
        >
          No. of {entityInfo?.college ? entityInfo?.college : "College"}s
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push({
              pathname: `${
                isEducationGroup ? "/educational-group" : "/college-group"
              }/view-teams`,
              college_id: params.row.college_id,
              group_id: params.row.group_id,
            });
          }}
        >
          <u>{params.row.no_of_colleges}</u>
        </span>
      ),
    },
    {
      field: "group_name",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "50px",
          }}
        >
          Group Name
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.group_name}
        </span>
      ),
    },
    // {
    //   field: "assign_job",
    //   width: 200,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "50px",
    //       }}
    //     >
    //       Assign Job
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {params.row.assign_job}
    //     </span>
    //   ),
    // },
    // {
    //   field: "current_status",
    //   width: 200,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "60px",
    //       }}
    //     >
    //       Status
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {params.row.current_status === "ACTIVE" ? "Active" : "Blocked"}
    //     </span>
    //   ),
    // },
    // {
    //   field: "button",
    //   headerName: "",
    //   type: "button",
    //   width: 60,
    //   renderHeader: (params) => <strong style={{ color: "#5a6268" }}></strong>,
    //   renderCell: (cellValues) => {
    //     return <Action />;
    //   },
    // },
    // {
    //   field: "date",
    //   headerName: "Created on",
    //   type: "date",
    //   width: 160,
    //   editable: true,
    // },
  ];

  return columns;
};
