import { Divider, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import NoDataFunc from "../../components/NoDataSVG/index1";
import IconSelectorNew from "../../utility/iconSelectorNew";
import Title from "./Title";
import { Skeleton } from "@material-ui/lab";
import OverviewSkeletion from "./skeletons/OverviewSkeletion";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    display: "flex",
    height: "230px",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      transform: "scale(1.09)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
    // padding: theme.spacing(2),

    // "&:hover": {
    //   transform: "scale(1.09)",
    //   transition: "transform 0.2s",
    // },
    //textAlign: "center",
    backgroundColor: "#c8facd",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100px",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
  },
  text: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "left",
  },
}));

export default function Deposits({ data, placement, loader }) {
  const classes = useStyles();

  return (
    <>
      {/* <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Title>Overview</Title>
      </div> */}

      <div
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title>Overview</Title>
        {loader ? (
          <Skeleton variant="rectangular" width={150} height={10} />
        ) : (
          <Typography variant="h6">
            {placement?.title}: {placement?.count}
          </Typography>
        )}
      </div>
      <Divider style={{ marginBottom: "30px" }} />

      <Grid container spacing={3}>
        <Grid container spacing={3}>
          {loader ? (
            <OverviewSkeletion />
          ) : data?.length >= 1 ? (
            data?.map((item, index) => {
              return (
                <Grid item xs={12} md={3}>
                  <Grid item xs={12}>
                    <Link to={item?.link} style={{ textDecoration: "none" }}>
                      <Paper
                        className={classes.paper}
                        style={{ backgroundColor: item?.colour1 }}
                      >
                        <div
                          className={classes.item}
                          style={{
                            backgroundColor: item?.colour2,
                          }}
                        >
                          {IconSelectorNew(item?.icon, item?.colour2)}
                        </div>
                        <div className={classes.text}>
                          <Typography variant="h6" style={{ color: "#000" }}>
                            {item?.title}
                          </Typography>
                          <br />
                          <Typography
                            variant="h2"
                            style={{ fontWeight: "bold" }}
                            color="inherit"
                          >
                            {item?.count}
                          </Typography>
                          <br />
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {item?.subtitle_1}
                          </Typography>
                          <br />
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {item?.subtitle_2}
                          </Typography>
                        </div>
                      </Paper>
                    </Link>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <NoDataFunc width={"300Px"} height={"200px"} />
              <Typography style={{ fontSize: "16px" }}>
                {" "}
                No Data Available
              </Typography>{" "}
            </div>
          )}
        </Grid>
      </Grid>

      <br />
    </>
  );
}
