import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddPublicationsDialog from "./AddProjectDialog";
import SavePublications from "./SavePublications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  newBtnStyle: {
    maxWwidth: "200px",
    borderRadius: "8px",
    height: "40px",
  },
}));

export default function Publications({
  publications,
  handlePatchPublications,
  handleAddPublications,
  handleDeletePublications,
  pubDataLoading,
  approvalRequestCounts,
  redirectionData,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [tempPublications, setTempPublications] = useState([]);
  const [addPublications, setAddPublications] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  useEffect(() => {
    if (publications) setTempPublications(publications);
  }, [publications]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setAddPublications({});
    setOpen(false);
  };

  const savePublications = () => {
    isEdit
      ? handlePatchPublications(
          addPublications,
          addPublications?.id,
          editIndex,
          handleClose,
          "Publication"
        )
      : handleAddPublications(addPublications, handleClose, "Publication");
  };
  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Publications</Typography>
        <br />
        <Grid
          item
          xs={12}
          style={
            approvalRequestCounts?.PROJ
              ? { pointerEvents: "none", opacity: 0.4 }
              : {}
          }
        >
          {" "}
          {tempPublications &&
            tempPublications?.map((publication, index) => {
              return (
                <SavePublications
                  publications={publication}
                  publiIndex={index}
                  setAddPublications={setAddPublications}
                  handleOpen={handleClickOpen}
                  handleDelete={handleDeletePublications}
                  setIsEdit={setIsEdit}
                  setEditIndex={setEditIndex}
                />
              );
            })}
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
          {approvalRequestCounts?.PROJ ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.newBtnStyle}
              onClick={() => {
                history.push({
                  pathname: `/student-profile-request/${redirectionData?.name.replace(
                    / /g,
                    "_"
                  )}-${redirectionData?.userId}`,
                });
              }}
            >
              {`Approve Request (${approvalRequestCounts?.PROJ ?? 0})`}
            </Button>
          ) : (
            <div>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                }}
                variant="text"
                color="primary"
                onClick={handleClickOpen}
              >
                Add Publications
              </Button>
            </div>
          )}
        </Grid>
      </div>
      <AddPublicationsDialog
        open={open}
        editPublications={addPublications}
        setEditPublications={setAddPublications}
        handleClose={handleClose}
        savePublications={savePublications}
        pubDataLoading={pubDataLoading}
        isEdit={isEdit}
      />
    </>
  );
}
