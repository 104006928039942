import { Button, Typography, Switch, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CompanyGetAPI2,
  ExcelPostAPI,
  InviteLinkGetAPI,
} from "../../api/Company/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  isCollegeGroupFn,
  userRolesActionsFn,
  collegeGroupListSelectorFn,
  entityInfoSelectorFn
} from "../../api/SignIn/selector";
import sampleExcel from "../../assets/excel/Sample_Company.xlsx";
import AddNewCompany from "../../components/DialogSection/AddNewCompany";
import ImportData from "../../components/DialogSection/ImportData";
import UploadExcelFile from "../../components/DialogSection/UploadExcelFile";
import CompanyTab from "../../components/Header/CompanyTab";
import { dataRow, headerSampleFile } from "./sampleFile";
import axios from "axios";
import CustomCircularProgress from "../../components/CircularProgressBar";
import {
  praveshAxiosGetReq,
  sanchalanAxiosGetReq,
} from "../../api/BaseApi/apiBase";
import { checkForToken } from "../../constants/ApiCall";
import { CircularProgress } from "@mui/material";
import DropDownButton from "../../components/Input/DropDownButton";
import {
  PlacementListAPI,
  PlacementListEndedAPI,
  TeamMemberListAPI,
} from "../../api/Common/action";
import NoDataFuncNew from "../../components/NoDataSVG/index2";
import { groupViewCheck } from "../../utility/groupViewCheck";
import { isEducationGroupFn } from "../../api/Domain/selector";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 20.5,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  grid: {
    display: "flex",
    // marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function CompanyTrack({
  selectedGroup = false,
  handleToggle = () => {},
  toggleViewCollege = false,
  setToggleViewCollege = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const entityInfo = useSelector(entityInfoSelectorFn);

  const [clickSearchButton, setClickSearchButton] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(0);
  const [totalLoad, setTotalLoad] = useState(0);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [progress, setProgress] = useState(0);
  const link = useSelector((state) => state?.CompanyInviteLink?.details);
  const [applyClickedCTC, setApplyClickedCTC] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const [placementSession, setPlacementSession] = useState(null);
  const [companyListTotalCount, setCompanyListTotalCount] = useState(0);

  const initialPageData = { currentPage: 0, pageSize: 20 };
  const [pageData, setPageData] = useState(initialPageData);

  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );

  const companyList1 = useSelector((state) => state?.CompanyData?.details);
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    if (
      companyList.length / pageData.pageSize !== pageData.currentPage + 1 &&
      !loading
    ) {
      setCompanyList((prev) => {
        // const remainder = companyList.length % pageData.pageSize;
        // let arr = prev;
        // if (remainder) {
        //   arr = prev.slice(0, -remainder);
        // }

        return [...prev, ...(companyList1 || [])];
      });
      // setCompanyListTotalCount(companyList1?.count);
    }
    // setFirstCallDone(true);
    // setFirstApiCall(true);
  }, [companyList1]);

  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const [filtersCompany, setFiltersCompany] = useState({
    search: null,
    sector: null,
    industry: null,
    ctc: null,
    status: null,
    created: null,
    industryObj: null,
    min_ctc: null,
    max_ctc: null,
    current_tab: "All",
    approval_status: "PENDING",
  });
  const initialBackendDrivenFilter = {
    outerFilters: {
      search: null,
    },
    innerFilters: {},
  };

  const [backendDrivenFilter, setBackendDrivenFilter] = useState(
    initialBackendDrivenFilter
  );

  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_cycle")) {
        var placement_name = checkPlacementName(
          location?.search.replace("?placement_cycle=", "")
        );
        setPlacementSession({
          id: location?.search.replace("?placement_cycle=", ""),
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  const createQueryStringOuterFilter = (paramsObject) => {
    let queryParams = "";
    Object.keys(paramsObject).forEach((key) => {
      const value = paramsObject[key];

      if (value != null && value != undefined && value != "") {
        if (value instanceof Date) {
          queryParams += `${key}=${value.getDate()}/${
            value.getMonth() + 1
          }/${value.getFullYear()}&`;
        } else {
          if (Array.isArray(value)) {
            queryParams += `${key}=${value.join(",")}&`;
          } else {
            queryParams += `${key}=${value}&`;
          }
        }
      }
    });
    return queryParams;
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const createQueryStringInnerFilter = (paramsObject) => {
    let queryParams = "";
    Object.keys(paramsObject).forEach((key) => {
      const items = paramsObject[key];
      if (items) {
        const validItems = items.filter(
          (item) =>
            item.value != null &&
            item.value !== undefined &&
            item.value !== "" &&
            !(Array.isArray(item.value) && item.value.length === 0) &&
            !(
              typeof item.value === "object" &&
              !Array.isArray(item.value) &&
              !(item.value instanceof Date) &&
              Object.keys(item.value).length === 0
            )
        );
        if (validItems.length) {
          if (key == "ctc") {
            const { min, max } = validItems[0]?.value;

            if (min && max) {
              queryParams += `min_ctc=${Number(min) * 100000}&max_ctc=${
                Number(max) * 100000
              }`;
            } else if (min) {
              queryParams += `min_ctc=${Number(min) * 100000}`;
            } else if (max) {
              queryParams += `max_ctc=${Number(max) * 100000}`;
            }
          } else {
            let values = validItems
              .map((item) => {
                if (item.value instanceof Date) {
                  return formatDate(item.value);
                } else if (Array.isArray(item.value)) {
                  return item.value.join(",");
                } else {
                  return item.value;
                }
              })
              .join(",");

            if (values) {
              queryParams += `${key}=` + values;
            }
          }

          queryParams += "&";
        }
      }
    });

    queryParams = queryParams.slice(0, -1);

    return queryParams;
  };

  useEffect(() => {
    // let CancelToken = axios.CancelToken;
    // let source = CancelToken.source();

    if (firstCallDone && (collegeId || selectedGroup?.id)) {
      setPageData(initialPageData);
      setCompanyList([]);
      //   let params = `${createQueryStringOuterFilter(
      //     backendDrivenFilter?.outerFilters
      //   )}${createQueryStringInnerFilter(backendDrivenFilter?.innerFilters)}`;
      //   params = params.replace(/^&+/, "");
      //   params = params.replace(/&+$/, "");
      //   // const url = `api/company/view/?${params}`;
      //   let url = "";
      //   if (filtersCompany?.current_tab === "All")
      //     url = `api/company/view/?${params}`;
      //   else url = `api/company/invite_view/?${params}${approvalStatusFilter()}`;

      //   dispatch(
      //     CompanyGetAPI2(
      //       setLoading,
      //       alert,
      //       history,
      //       location,
      //       url,
      //       setClickSearchButton,
      //       setApplyClickedCTC,
      //       setTotalCount,
      //       setPendingCount,
      //       setRejectedCount,
      //       setCompanyList,
      //       source
      //     )
      //   );
      // }
      // return () => {
      //   source.cancel("ERR_CANCELED:- Cancelled due to stale request");
    }
  }, [backendDrivenFilter.innerFilters]);

  useEffect(() => {
    // let CancelToken = axios.CancelToken;
    // let source = CancelToken.source();

    if (
      firstCallDone &&
      (collegeId || selectedGroup?.id) &&
      (clickSearchButton || refreshData)
    ) {
      setPageData(initialPageData);
      setCompanyList([]);
      setRefreshData(false);
      //   let params = `${createQueryStringOuterFilter(
      //     backendDrivenFilter?.outerFilters
      //   )}${createQueryStringInnerFilter(backendDrivenFilter?.innerFilters)}`;
      //   params = params.replace(/^&+/, "");
      //   params = params.replace(/&+$/, "");
      //   const url = `api/company/view/?${params}`;

      //   dispatch(
      //     CompanyGetAPI2(
      //       setLoading,
      //       alert,
      //       history,
      //       location,
      //       url,
      //       setClickSearchButton,
      //       setApplyClickedCTC,
      //       setTotalCount,
      //       setPendingCount,
      //       setRejectedCount,
      //       setCompanyList,
      //       source
      //     )
      //   );
      // }
      // return () => {
      //   source.cancel("Cancelled due to stale request");
    }
  }, [clickSearchButton, refreshData]);

  const [firstCallDone, setFirstCallDone] = useState(false);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if (
      (collegeId || selectedGroup?.id) &&
      companyList.length / pageData.pageSize === pageData.currentPage
    ) {
      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(
        backendDrivenFilter?.innerFilters
      )}${checkPlacementSesion()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");
      // let url = `api/company/view/?page=${pageData.currentPage + 1}&page_size=${
      //   pageData.pageSize
      // }&${params}`;
      // url = url.replace(/&+$/, "");

      let url = "";
      if (filtersCompany?.current_tab === "All") {
        url = `api/company/view/paginated/?page=${
          pageData.currentPage + 1
        }&page_size=${pageData.pageSize}&${params}`;
      } else
        url = `api/company/invite_view/paginated/?college_id=${collegeId}&page=${
          pageData.currentPage + 1
        }&page_size=${
          pageData.pageSize
        }${approvalStatusFilter()}&${params}${checkSelectedGroup()}`;
      url += `${checkSelectedGroup()}`;
      // if (!selectedGroup) {
      //   url += `&college_id=${collegeId}`;
      // } else {
      //   url += `${checkSelectedGroup()}`;
      // }
      url = url.replace(/&+$/, "");

      dispatch(
        CompanyGetAPI2(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setApplyClickedCTC,
          setTotalCount,
          setPendingCount,
          setRejectedCount,
          setCompanyList,
          source,
          setCompanyListTotalCount
        )
      )
        .then(() => {
          setFirstCallDone(true);
        })
        .catch((error) => {
          console.error("Fetch data failed:", error);
        });
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [pageData]);

  const [resData, setresData] = useState({
    total_entries: null,
    user_created_count: null,
    email_already_exist: null,
    failed_entries: null,
    flag: null,
    error_list: [],
    already_exist_list: [],
  });

  const searchFilter = () => {
    if (filtersCompany.search) return `search=${filtersCompany.search}`;
    else return ``;
  };
  const approvalStatusFilter = () => {
    if (filtersCompany.approval_status)
      return `&approval_status=${filtersCompany.approval_status}`;
    else return ``;
  };
  const sectorFilter = () => {
    if (filtersCompany.sector && filtersCompany.sector !== "All")
      return `&sector=${filtersCompany.sector}`;
    else return ``;
  };
  const industryFilter = () => {
    if (filtersCompany.industryObj)
      return `&industry_id=${filtersCompany.industryObj?.id}`;
    else return ``;
  };
  const ctcFilter = () => {
    if (filtersCompany.ctc_min && filtersCompany?.ctc_max)
      return `&ctc_min=${Number(filtersCompany.ctc_min) * 100000}&ctc_max=${
        Number(filtersCompany.ctc_max) * 100000
      }`;
    else if (filtersCompany.ctc_min)
      return `&ctc_min=${Number(filtersCompany.ctc_min) * 100000}`;
    else if (filtersCompany.ctc_max)
      return `&ctc_max=${Number(filtersCompany.ctc_max) * 100000}`;
    else return ``;
  };
  const statusFilter = () => {
    if (filtersCompany.status && filtersCompany?.status !== "ALL")
      return `&status=${filtersCompany.status}`;
    else return ``;
  };
  const createdFilter = () => {
    if (filtersCompany.created)
      return `&created=${new Date(filtersCompany.created)
        .toISOString()
        .substring(0, 10)}`;
    else return ``;
  };
  const [callNavbar, setCallNavbar] = useState(false);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if ((collegeId || selectedGroup?.id) && callNavbar) {
      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(
        backendDrivenFilter?.innerFilters
      )}${checkPlacementSesion()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");
      console.log("trigger 1");
      let url = "";
      if (filtersCompany?.current_tab === "All")
        url = `api/company/view/paginated/?${params}`;
      else
        url = `api/company/invite_view/paginated/?college_id=${collegeId}&${params}${approvalStatusFilter()}`;
      url += `${checkSelectedGroup()}`;
      // if (!selectedGroup) {
      //   url += `&college_id=${collegeId}`;
      // } else {
      //   url += `&group_id=${selectedGroup?.id}`;
      // }
      url = url.replace(/&+$/, "");

      // if (filtersCompany?.current_tab === "All")
      //   var url = `api/company/view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
      // else
      //   var url = `api/company/invite_view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${approvalStatusFilter()}${createdFilter()}`;

      dispatch(
        CompanyGetAPI2(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setApplyClickedCTC,
          setTotalCount,
          setPendingCount,
          setRejectedCount,
          setCompanyList,
          source,
          setCompanyListTotalCount
        )
      );
    }
    return () => {
      source.cancel("ERR_CANCELED:- Cancelled due to stale request");
    };
  }, [callNavbar]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();

  //   if (filtersCompany?.current_tab && filtersCompany?.current_tab === "All")
  //     var url = `api/company/view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
  //   else
  //     var url = `api/company/invite_view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${approvalStatusFilter()}${createdFilter()}`;

  //   dispatch(
  //     CompanyGetAPI2(
  //       setLoading,
  //       alert,
  //       history,
  //       location,
  //       url,
  //       setClickSearchButton,
  //       setApplyClickedCTC,
  //       setTotalCount,
  //       setPendingCount,
  //       setRejectedCount,
  //       setCompanyList,
  //       source
  //     )
  //   );

  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //   };
  // }, [filtersCompany?.current_tab]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();

  //   if (collegeId) {
  //     if (filtersCompany?.current_tab === "All")
  //       var url = `api/company/view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
  //     else
  //       var url = `api/company/invite_view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${approvalStatusFilter()}${createdFilter()}`;

  //     dispatch(
  //       CompanyGetAPI2(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setClickSearchButton,
  //         setApplyClickedCTC,
  //         setTotalCount,
  //         setPendingCount,
  //         setRejectedCount,
  //         setCompanyList,
  //         source
  //       )
  //     );
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //   };
  // }, [
  //   filtersCompany.sector,
  //   filtersCompany.industryObj,
  //   filtersCompany.status,
  //   filtersCompany.created,
  //   filtersCompany.ctc,
  //   collegeId,
  // ]);

  // useEffect(() => {
  //   if (clickSearchButton) {
  //     if (filtersCompany?.current_tab === "All")
  //       var url = `api/company/view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
  //     else
  //       var url = `api/company/invite_view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${approvalStatusFilter()}${createdFilter()}`;

  //     dispatch(
  //       CompanyGetAPI2(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setClickSearchButton,
  //         setApplyClickedCTC,
  //         setTotalCount,
  //         setPendingCount,
  //         setRejectedCount,
  //         setCompanyList
  //       )
  //     );
  //   }
  // }, [clickSearchButton]);

  useEffect(() => {
    dispatch(InviteLinkGetAPI(alert, history, location));
  }, [collegeId]);

  // useEffect(() => {
  //   if (applyClickedCTC) {
  //     if (filtersCompany?.current_tab === "All")
  //       var url = `api/company/view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
  //     else
  //       var url = `api/company/invite_view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${approvalStatusFilter()}${createdFilter()}`;
  //     dispatch(
  //       CompanyGetAPI2(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setClickSearchButton,
  //         setApplyClickedCTC,
  //         setTotalCount,
  //         setPendingCount,
  //         setRejectedCount,
  //         setCompanyList
  //       )
  //     );
  //   }
  // }, [applyClickedCTC]);

  const handleClickOpenUpload = () => {
    if (!licenceData?.display) {
      setOpenUpload(true);
    } else {
      alert.error(licenceData?.display_message);
    }
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setFileNames([]);
  };

  const handleOpenImport = () => {
    setOpenUpload(false);

    setOpenImport(true);
    if (fileNames?.length > 0) {
      var formData = new FormData();
      formData.append("file", fileNames[0]);
      formData.append("college_id", collegeId);
      dispatch(
        ExcelPostAPI(
          formData,
          setLoadingExcel,
          alert,
          history,
          location,
          handleCloseImport,
          setProgress,
          setDataLoaded,
          setTotalLoad,
          setresData
        )
      )
        .then(() => {
          setRefreshData(true);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const userId = collegeDetails?.user_id;

  const handleCancelImport = () => {
    setOpenImport(false);
    setresData((resData) => ({ ...resData, flag: false }));
  };
  const handleCloseImport = () => {
    setOpenImport(true);
    setFileNames([]);
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  const [exportLoading, setExportLoading] = useState(false);

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else {
        return `&group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
      }
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  const sendExportLink = async () => {
    let params = `${createQueryStringOuterFilter(
      backendDrivenFilter?.outerFilters
    )}${createQueryStringInnerFilter(
      backendDrivenFilter?.innerFilters
    )}${checkPlacementSesion()}`;

    params = params.replace(/^&+/, "");
    params = params.replace(/&+$/, "");

    let url = `api/company/view/paginated/?college_id=${collegeId}&is_export=1&${params}${approvalStatusFilter()}`;
    // if (!selectedGroup) {
    //   url += `&college_id=${collegeId}`;
    // } else {
    //   url += `&group_id=${selectedGroup?.id}`;
    // }
    url += `${checkSelectedGroup()}`;
    url = url.replace(/&+$/, "");

    // var url = `api/company/view/?${searchFilter()}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${approvalStatusFilter()}${createdFilter()}&is_export=1`;
    setExportLoading(true);
    try {
      const res = await sanchalanAxiosGetReq(url);

      if (res.data.success) {
        const successMessage = res?.data?.data?.message;
        alert?.success(successMessage);
        setExportLoading(false);
      } else {
        console.log("error ocuured");
      }
    } catch (error) {
      setExportLoading(false);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const handleExport = () => {
    let str = "";
    sendExportLink();

    // alert.error("Select Columns to Export");
  };

  const [loadingPlacement, setLoadingPlacement] = useState(true);

  useEffect(() => {
    if (collegeId) {
      dispatch(
        PlacementListAPI(
          alert,
          history,
          location,
          setLoadingPlacement,
          collegeId
        )
      );
      dispatch(PlacementListEndedAPI(alert, history, location, collegeId));
      dispatch(TeamMemberListAPI(alert, history, location, collegeId, userId));
    }
    // if (!jobNavbar) dispatch(JobNavbarAPI());
  }, [collegeId]);

  // useEffect(() => {
  //   if (collegeId) {
  //     dispatch(
  //       PlacementListAPI(
  //         alert,
  //         history,
  //         location,
  //         setLoadingPlacement,
  //         collegeId
  //       )
  //     );
  //   }
  // }, [collegeId]);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  // const [placementSession, setPlacementSession] = useState(null);
  // useEffect(
  //   () => console.log("placementSession", placementSession),
  //   [placementSession]
  // );

  // useEffect(() => {
  //   if (placementSessionList?.length > 0) {
  //     if (location.search.includes("placement_cycle")) {
  //       var placement_name = checkPlacementName(
  //         location?.search.replace("?placement_cycle=", "")
  //       );
  //       setPlacementSession({
  //         id: location?.search.replace("?placement_cycle=", ""),
  //         name: placement_name,
  //       });
  //     } else {
  //       setPlacementSession({ id: "All", name: "All Placement Cycles" });
  //     }
  //   }
  // }, [location, placementSessionList?.length]);

  const [filterData, setFilterData] = useState([]);
  const [filterLoading, setFilterLoader] = useState(true);

  useEffect(() => {
    const getFilters = async () => {
      setFilterLoader(true);
      let endPoint = "";
      if (filtersCompany?.current_tab === "All")
        endPoint = `api/saas/college/manage_company/get/filters/`;
      else
        endPoint = `api/saas/college/manage_company/get/filters/?${approvalStatusFilter()}`;

      try {
        const res = await praveshAxiosGetReq(endPoint);
        const data = res?.data?.data;
        if (res?.data?.success) {
          setFilterData(data);
        }
        setFilterLoader(false);
      } catch (error) {
        throw error;
        setFilterLoader(false);
      }
    };
    getFilters();
    setBackendDrivenFilter(initialBackendDrivenFilter);
  }, [filtersCompany?.current_tab]);

  const checkPlacementSesion = () => {
    if (placementSession && placementSession.id !== "All")
      return `&placement_session=${placementSession.id}`;
    else return ``;
  };

  useEffect(() => {
    // let CancelToken = axios.CancelToken;
    // let source = CancelToken.source();

    if (firstCallDone && collegeId && placementSession) {
      setPageData(initialPageData);
      setCompanyList([]);
      //   let params = `${createQueryStringOuterFilter(
      //     backendDrivenFilter?.outerFilters
      //   )}${createQueryStringInnerFilter(
      //     backendDrivenFilter?.innerFilters
      //   )}${checkPlacementSesion()}`;
      //   params = params.replace(/^&+/, "");
      //   params = params.replace(/&+$/, "");
      //   let url = `api/company/view/?${params}`;
      //   // url = url.replace(/&$/, "");
      //   try {
      //     setLoading(true);
      //     dispatch(
      //       CompanyGetAPI2(
      //         setLoading,
      //         alert,
      //         history,
      //         location,
      //         url,
      //         setClickSearchButton,
      //         setApplyClickedCTC,
      //         setTotalCount,
      //         setPendingCount,
      //         setRejectedCount,
      //         setCompanyList,
      //         source
      //       )
      //     );
      //   } catch (err) {
      //     setLoading(false);
      //   }
      // }
      // return () => {
      //   source.cancel("ERR_CANCELED:- Cancelled due to stale request");
    }
  }, [placementSession]);

  return (
    <>
      {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) &&
        placementSessionList?.length > 0 && (
          <div style={{ marginTop: "5px", marginBottom: "5px" }}>
            <DropDownButton
              title="Placement Session 2021"
              placementSession={placementSession}
              setPlacementSession={setPlacementSession}
            />
          </div>
        )}
      {/* <Layout> */}
      {/* <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <DropDownButton title="Placement Session 2021" />
        </div> */}
      {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) &&
      loadingPlacement ? (
        <CustomCircularProgress />
      ) : groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) ||
        placementSessionList?.length > 0 ? (
        <>
          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Companies</Typography>
              <Typography
                variant="body2"
                color="secondary"
                // style={{ marginTop: "10px" }}
              >
                Manage your all Connected Companies in one place
              </Typography>
            </div>
            {userRolesPermission?.some(
              (item) => item.access_control_key === "manage_company"
            ) && (
              <div style={{ display: "flex" }}>
                {filtersCompany?.current_tab === "All" && (
                  <>
                    {!groupViewCheck(
                      isEducationGroup,
                      isCollegeGroup,
                      collegeId
                    ) ? (
                      <>
                        {" "}
                        <ImportData
                          open={openImport}
                          handleClickOpen={handleOpenImport}
                          handleClose={handleCloseImport}
                          dataLoaded={dataLoaded}
                          totalLoad={totalLoad}
                          progress={progress}
                          fileName={fileNames[0]?.name}
                          handleCancelImport={handleCancelImport}
                          resData={resData}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleClickOpenUpload}
                          className={classes.buttonBase}
                          startIcon={<PublishIcon />}
                        >
                          Import
                        </Button>
                        <UploadExcelFile
                          handleClickOpen={handleClickOpenUpload}
                          handleClose={handleCloseUpload}
                          open={openUpload}
                          fileNames={fileNames}
                          setFileNames={setFileNames}
                          handleOpenImport={handleOpenImport}
                          dataRow={dataRow}
                          headerSampleFile={headerSampleFile}
                          downloadText={"Sample_Company.csv"}
                          sampleFile={sampleExcel}
                        />{" "}
                      </>
                    ) : null}
                    <AddNewCompany
                      collegeId={collegeId}
                      setRefreshData={setRefreshData}
                      selectedGroup={selectedGroup}
                    />{" "}
                  </>
                )}
                {/* <InviteCompany link={link} /> */}
                {!groupViewCheck(
                  isEducationGroup,
                  isCollegeGroup,
                  collegeId
                ) && (
                  <CopyToClipboard
                    text={link}
                    onCopy={() => {
                      if (licenceData && licenceData?.display) {
                        alert.error(licenceData?.display_message);
                        return;
                      }

                      link
                        ? alert.success("Link Copied!")
                        : alert.error(
                            "Please Add Fields from Company ERF to generate your Link"
                          );
                    }}
                  >
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      className={classes.primebtn}
                      startIcon={<SendIcon />}
                    >
                      Invite
                    </Button>
                  </CopyToClipboard>
                )}

                {filtersCompany?.current_tab === "All" ? (
                  <Button
                    variant="contained"
                    startIcon={<GetAppIcon />}
                    size="small"
                    className={classes.button}
                    onClick={handleExport}
                  >
                    {exportLoading ? (
                      <CircularProgress size={18} style={{ color: "white" }} />
                    ) : (
                      "Export"
                    )}
                  </Button>
                ) : (
                  <CSVLink
                    data={
                      selectedRowsData?.length > 0
                        ? selectedRowsData
                        : companyList?.length
                        ? companyList
                        : []
                    }
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    filename={"Companies.csv"}
                  >
                    <Button
                      variant="contained"
                      startIcon={<GetAppIcon />}
                      size="small"
                      className={classes.button}
                      style={{ textDecoration: "none", marginRight: "0px" }}
                    >
                      Export
                    </Button>{" "}
                  </CSVLink>
                )}
                {groupViewCheck(
                  isEducationGroup,
                  isCollegeGroup,
                  collegeId
                ) && (
                  <Box
                    style={{
                      marginLeft: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      color={toggleViewCollege ? "primary" : "#6C757D"}
                      style={{ fontSize: 16, marginRight: 5 }}
                    >
                      Show {entityInfo?.college}s
                    </Typography>
                    <AntSwitch
                      checked={toggleViewCollege}
                      onChange={handleToggle}
                      name="checkedC"
                    />
                  </Box>
                )}
              </div>
            )}
          </div>
          <CompanyTab
            page={page}
            setPage={setPage}
            totalCount={totalCount}
            pendingCount={pendingCount}
            loading={loading}
            filtersCompany={filtersCompany}
            setFiltersCompany={setFiltersCompany}
            setClickSearchButton={setClickSearchButton}
            setSelectedRowsData={setSelectedRowsData}
            selectedRowsData={selectedRowsData}
            clickSearchButton={clickSearchButton}
            setApplyClickedCTC={setApplyClickedCTC}
            collegeId={collegeId}
            userRolesPermission={userRolesPermission}
            setCallNavbar={setCallNavbar}
            rejectedCount={rejectedCount}
            companyList={companyList}
            backendDrivenFilter={backendDrivenFilter}
            setBackendDrivenFilter={setBackendDrivenFilter}
            filterData={filterData}
            filterLoading={filterLoading}
            companyListTotalCount={companyListTotalCount}
            pageData={pageData}
            setPageData={setPageData}
            setCompanyList={setCompanyList}
            setRefreshData={setRefreshData}
            handleToggle={handleToggle}
            toggleViewCollege={toggleViewCollege}
            setToggleViewCollege={setToggleViewCollege}
          />
        </>
      ) : (
        <>
          <NoDataFuncNew
            title={"Please Add Placement Cycle First"}
            subtitle={"Click the below Button to add placement Cycle"}
            buttonText={"Add Placement Cycle"}
            height={"320px"}
            width={"320px"}
            link={"/placement-cycles"}
          />
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
