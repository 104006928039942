import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import pSBC from "shade-blend-color";
import { Typography, Chip } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ProfileApproveDialog from "../../EditStudent/ProfileApproveDialog/ProfileApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",
  },
  chipDisplay: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
}));

export default function Project({
  data,
  type,
  userId,
  ApproveAcceptList,
  StudentDetailsAPI,
  fromProfileUpdate,
  requestCount,
}) {
  const classes = useStyles();
  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <br />
      {type === "Projects" ? (
        data?.length > 0 ||
        (ApproveAcceptList &&
          Object.keys(ApproveAcceptList)?.includes("PROJ")) ? (
          <div className={classes.paper}>
            <Typography variant="h6">{type}</Typography>
            {data?.map((item) => (
              <>
                <div>
                  <div className={classes.title}>
                    <Typography variant="body1">{item?.title}</Typography>
                    {item?.links ? (
                      <Button color="primary">
                        <a
                          href={`//${item.links}`}
                          target="blank"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          View Live
                        </a>
                      </Button>
                    ) : null}
                  </div>
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{ marginTop: "5px", marginBottom: "7px" }}
                  >
                    {item.description}{" "}
                  </Typography>
                </div>
                <div className={classes.chipDisplay}>
                  {item?.skills &&
                    item?.skills.map((item, index) => {
                      return (
                        <li key={item.skill_id}>
                          <Chip
                            color="primary"
                            variant="outlined"
                            size="small"
                            label={item.skill_name}
                            className={classes.chip}
                          />
                        </li>
                      );
                    })}
                </div>
              </>
            ))}
            {fromProfileUpdate &&
            ApproveAcceptList &&
            Object.keys(ApproveAcceptList)?.includes("PROJ") ? (
              <div
                style={{
                  backgroundColor: "green ",
                  padding: "10px",
                  width: "160px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                  cursor: "pointer",
                }}
                onClick={handleClickOpen}
              >
                <Typography
                  style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
                >
                  {`Pending Request (${ApproveAcceptList?.PROJ ?? 0})`}
                </Typography>
              </div>
            ) : (
              <></>
            )}
            <ProfileApproveDialog
              title={"Project Request"}
              identifier={type}
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              formType="approveProject"
              data={data}
              userId={userId}
              type={"PROJ"}
              StudentDetailsAPI={StudentDetailsAPI}
            />
          </div>
        ) : null
      ) : data?.length > 0 ||
        (ApproveAcceptList &&
          Object.keys(ApproveAcceptList)?.includes("PUB")) ? (
        <div className={classes.paper}>
          <Typography variant="h6">{type}</Typography>
          {data?.map((item) => (
            <>
              <div>
                <div className={classes.title}>
                  <Typography variant="body1">{item?.title}</Typography>
                  {item?.links ? (
                    <Button color="primary">
                      <a
                        href={`//${item.links}`}
                        target="blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        View Live
                      </a>
                    </Button>
                  ) : null}
                </div>
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ marginTop: "5px", marginBottom: "7px" }}
                >
                  {item.description}{" "}
                </Typography>
              </div>
              <div className={classes.chipDisplay}>
                {item?.skills &&
                  item?.skills.map((item, index) => {
                    return (
                      <li key={item.skill_id}>
                        <Chip
                          color="primary"
                          variant="outlined"
                          size="small"
                          label={item.skill_name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
              </div>
            </>
          ))}
          {fromProfileUpdate &&
          ApproveAcceptList &&
          Object.keys(ApproveAcceptList)?.includes("PUB") ? (
            <div
              style={{
                backgroundColor: "green ",
                padding: "10px",
                width: "160px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                right: "10px",
                bottom: "10px",
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
              >
                {`Pending Request (${ApproveAcceptList?.PUB ?? 0})`}
              </Typography>
            </div>
          ) : (
            <></>
          )}
          <ProfileApproveDialog
            title={"Publications Request"}
            identifier={type}
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            formType={"approvePublications"}
            data={data}
            userId={userId}
            type={"PUB"}
            StudentDetailsAPI={StudentDetailsAPI}
          />
        </div>
      ) : null}
    </>
  );
}
