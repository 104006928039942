import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
// import Layout from "../../components/Layout";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "rgb(0 0 0 / 5%) 0px 3px 24px",
    padding: "25px",
    borderRadius: "8px",
    width: "65%",
  },
  btn: {
    borderRadius: "8px",
    // height: "100%",
    marginTop: "30px",
    padding: "10px",
    height: "48px",
    marginLeft: "20px",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginRight: "10px",
    boxShadow: "none",
    //width: "100%",
    display: "flex",
  },

  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
  },

  btngrid: {
    display: "flex",
    flexDirection: "column",
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
}));

export default function TeamMemberRegistrationForm() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // const [state, setState] = React.useState({
  //   age: "",
  //   name: "hai",
  // });

  return (
    <>
      {/* <Layout> */}
      <div style={{ marginTop: "10px", marginBottom: "30px" }}>
        <Typography variant="h2">
          Manage Team Member e-Registration Form
        </Typography>
        <Typography
          variant="body2"
          color="secondary"
          style={{
            marginBottom: "7px",
          }}
        >
          Create Custom Data Set for Team Member
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <div style={{ width: "100%" }}>
          <Typography variant="h6">Team Member e-Registration Form</Typography>
          <br />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                required
                fullWidth
                label="Name"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                required
                fullWidth
                label="Personal Email"
                type="email"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                required
                fullWidth
                label="Phone Number"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "5px", marginBottom: "10px" }}
            >
              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                label="Designation"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel className={classes.floatingLabelFocusStyle}>
                  Batch
                </InputLabel>
                <Select
                  native
                  value={""}
                  onChange={handleChange}
                  label="Batch"
                  inputProps={{
                    style: {
                      padding: "16px",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </FormControl>
              <Typography
                variant="overline"
                style={{ color: "#181818", marginLeft: "20px" }}
              >
                It's only for Student Team Member*
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "5px" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel className={classes.floatingLabelFocusStyle}>
                  Department
                </InputLabel>
                <Select
                  native
                  value={""}
                  onChange={handleChange}
                  label="Department"
                  inputProps={{
                    style: {
                      padding: "16px",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </FormControl>
              <Typography
                variant="overline"
                style={{ color: "#181818", marginLeft: "20px" }}
              >
                It's only for Student Team Member*
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                label="User Role"
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Button variant="contained" color="primary" className={classes.btn}>
        Update Team Member Form
      </Button>
      {/* </Layout> */}
    </>
  );
}
