import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { TeamMemberListAPI } from "../../api/Common/action";
import {
  getSinglePostedJobAPI,
  JobDetailsSideInfoAPI,
} from "../../api/JobPost/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  isCollegeGroupFn,
  userRolesActionsFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";
import Assignment from "../../components/Cards/Assignment";
import InterviewDetails from "../../components/Cards/Interview/InterviewDetails";
import JobApplicationsInfo from "../../components/Cards/JobApplicationsInfo";
import JobDetailsCard from "../../components/Cards/JobDetailsCard";
import AssignedPlacementCycle from "../../components/Cards/JobDetailsCard/AssignedPlacementCycle";
import AssignedTeamMember from "../../components/Cards/TeamMember/AssignedTeamMember";
import CustomCircularProgress from "../../components/CircularProgressBar";
import NoteListTable from "../../components/Table/EditHistoryTable/NoteListTable";
import CollegePostedJob from "../../components/Cards/JobDetailsCard/CollegePostedJob";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";
import { groupViewCheck } from "../../utility/groupViewCheck";

const useStyles = makeStyles((_) => ({
  fixedPosition: {
    position: "fixed",
    left: "71%",
    maxHeight: 630,
    overflowY: "scroll",
    width: "28%",
  },
  "@media only screen and (min-width: 1700px)": {
    fixedPosition: {
      position: "fixed",
      left: "71%",
      maxHeight: 900,
      overflowY: "scroll",
      width: "28%",
    },
  },
}));

export default function JobDetails() {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [jobId, setJobId] = useState(null);
  const [loadingSideData, setLoadingSideData] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const sourceOfJob = useSelector(
    (state) => state?.SingleJobData?.details?.job_source
  );

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const userId = collegeDetails?.user_id;

  useEffect(() => {
    if (params.job_id) {
      const job_id = params.job_id.split("-");
      setJobId(job_id[job_id.length - 1]);
    }
  }, [params]);

  useEffect(() => {
    if (jobId && !location?.pathname?.includes("ejd-job")) {
      var url = `api/manage_job/view/job/?job_id=${jobId}`;
      dispatch(
        getSinglePostedJobAPI(setLoading, alert, history, location, jobId, url)
      );
      dispatch(
        JobDetailsSideInfoAPI(
          setLoadingSideData,
          alert,
          history,
          location,
          jobId
        )
      );
    }
    if (jobId && location?.pathname?.includes("ejd-job")) {
      var url = `api/manage_job/view/job/?job_id=${jobId}&ejd_response=true`;
      dispatch(
        getSinglePostedJobAPI(setLoading, alert, history, location, jobId, url)
      );
      // dispatch(JobDetailsSideInfoAPI(setLoadingSideData, alert, history, location, jobId));
    }
  }, [jobId]);

  useEffect(() => {
    if (collegeId && userId && !location?.pathname?.includes("ejd-job"))
      dispatch(TeamMemberListAPI(alert, history, location, collegeId, userId));
  }, [collegeId, userId]);

  const jobData = useSelector(
    (state) => state?.SingleJobData?.details?.college_job_data
  );
  const jobStatus = useSelector((state) => state?.SingleJobData?.details);
  const jobSideData = useSelector((state) => state?.SingleJobData?.sideData);

  const teamMemberList = useSelector((state) => state?.commonData?.teamMember);
  const [teamMemberListing, setTeamMemberListing] = useState([]);

  useEffect(() => {
    if (jobSideData && Object?.keys(jobSideData)?.length > 0) {
      setTeamMemberListing(jobSideData["team_member"]);
    }
  }, [jobSideData]);

  const userRolesPermission = useSelector(userRolesActionsFn);
  const alert = useAlert();

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to={checkForBreadcrumb(
            isEducationGroup,
            isCollegeGroup,
            collegeId,
            "manage-jobs"
          )}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary">Manage Jobs</Typography>
        </Link>

        <Link style={{ textDecoration: "none" }}>
          <Typography color="secondary" variant="body2">
            {jobData?.job_title}
          </Typography>
        </Link>
      </Breadcrumbs>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          {loading ? (
            <CustomCircularProgress />
          ) : (
            <>
              <JobDetailsCard
                data={jobData}
                fromJobDetail={true}
                jobStatus={jobStatus}
              />
              {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_job"
              ) && (
                <>
                  {jobStatus?.job_status !== "CLOSED" &&
                    jobStatus?.job_status !== "POSTED" &&
                    jobStatus?.job_status !== "REJECTED" && (
                      <Button
                        color="primary"
                        variant="contained"
                        //size="small"
                        style={{
                          borderRadius: "8px",
                          boxShadow: "none",
                          paddingLeft: "50px",
                          height: "40px",
                          display: "flex",
                          marginTop: "20px",
                          paddingRight: "50px",
                        }}
                        onClick={() => {
                          if (!location?.pathname?.includes("ejd-job")) {
                            jobStatus?.job_status === "DRAFTED"
                              ? history.push(`/post-job/draft-${jobId}`)
                              : history.push(
                                  groupViewCheck(
                                    isEducationGroup,
                                    isCollegeGroup,
                                    collegeId
                                  )
                                    ? `/${
                                        isCollegeGroup
                                          ? "college"
                                          : "educational"
                                      }-group/post-job/edit-${jobId}`
                                    : `/post-job/edit-${jobId}${
                                        [
                                          "GETWORK_FULFILLED",
                                          "GETWORK_INBOUND",
                                        ].includes(sourceOfJob)
                                          ? "?is_niyukti_job=true"
                                          : ""
                                      }`
                                );
                          }

                          if (location?.pathname?.includes("ejd-job")) {
                            if (jobStatus?.ejd_comp_verification === "Verified")
                              history.push(`/post-job/ejd-${jobId}`);
                            else {
                              alert.error(
                                "Please Verify your Company First from Pending Companies Section in Manage Compaines"
                              );
                              history.push("/manage-companies");
                            }
                          }
                        }}
                      >
                        {location?.pathname?.includes("ejd-job")
                          ? "Accept & Post"
                          : jobStatus?.job_status === "DRAFTED"
                          ? "Post this Job"
                          : "Edit"}
                      </Button>
                    )}
                </>
              )}
            </>
          )}
          <br />
          {jobStatus?.notes_list && jobStatus?.notes_list?.length > 0 && (
            <Grid item xs={12}>
              <Divider style={{ marginBottom: 20 }} />
              <div
                style={{
                  padding: 20,
                  marginTop: "20px",
                  margin: "auto",
                  backgroundColor: "#fff",
                  boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
                  borderRadius: "8px",
                }}
              >
                <Accordion
                  style={{
                    boxShadow: "none",
                  }}
                  classes={{
                    root: classes.MuiAccordionroot,
                  }}
                  // classes={{
                  //   root: classes.test,
                  // }}
                >
                  <AccordionSummary
                    style={{ padding: "0px" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5">Action History</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "0px" }}>
                    <div style={{ width: "100%" }}>
                      <NoteListTable data={jobStatus?.notes_list} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={4} className={classes.fixedPosition} style={{}}>
          {!location?.pathname?.includes("ejd-job") &&
            jobStatus?.job_status !== "DRAFTED" && (
              <>
                {jobSideData &&
                  Object.keys(jobSideData).map(
                    (item) =>
                      item === "job_applications" &&
                      Object.keys(jobSideData[item])?.length > 0 && (
                        <>
                          <JobApplicationsInfo item={jobSideData[item]} />
                          <br />
                        </>
                      )
                  )}
                {jobSideData &&
                  Object.keys(jobSideData).map(
                    (item) =>
                      item === "assignment" &&
                      Object.keys(jobSideData[item])?.length > 0 && (
                        <>
                          <Assignment item={jobSideData[item]} jobId={jobId} />
                          <br />
                        </>
                      )
                  )}
                {jobSideData &&
                  Object.keys(jobSideData).map(
                    (item) =>
                      item === "interview" &&
                      Object.keys(jobSideData[item])?.length > 0 && (
                        <>
                          <InterviewDetails item={jobSideData[item]} />
                          <br />
                        </>
                      )
                  )}
                {/* <>
                  <AssignedTeamMember
                    teamMemberList={teamMemberList}
                    jobId={jobId}
                    collegeId={collegeId}
                    item={teamMemberListing}
                    allData={jobSideData}
                    setTeamMemberListing={setTeamMemberListing}
                  />
                </> */}
              </>
            )}
          {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) &&
            !location?.pathname?.includes("ejd-job") && (
              <div style={{}}>
                <AssignedPlacementCycle
                  jobId={jobId}
                  collegeId={collegeId}
                  placementList={jobStatus?.placement_cycle}
                />
                <br />
              </div>
            )}
          {groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
            <CollegePostedJob list={jobStatus?.job_college || []} />
          )}
        </Grid>
      </Grid>

      {/* </Layout> */}
    </>
  );
}
