import createBrowserHistory from "history/createBrowserHistory";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import "../src/style/button.css";
import "../src/style/index.css";
import "../src/style/reactVideo.css";
//import "../src/style/global.scss";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import RootReducer from "./RootReducer/RootReducer";
import ScrollToTop from "./scrollToTop";
import { ErrorBoundary } from "react-error-boundary";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import errorsvg from "../src/assets/svg/No-data-rafiki.svg";
//import "react-modal-video/scss/modal-video.scss";
import Layout from "./components/Layout";

export const history = createBrowserHistory();

const store = createStore(RootReducer, applyMiddleware(thunk, logger));

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  img: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function ErrorFallback({ error, resetErrorBoundary }) {
  const classes = useStyles();
  return (
    <div className={classes.img}>
      <img src={errorsvg} height="400px" width="400px" alt="404 Error" />
      <Typography
        variant="h1"
        style={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        Something went Wrong
      </Typography>
      <pre>{error.message}</pre>
      <br />
      <br />
      <Button className={classes.btn} variant="contained" color="primary">
        Try Again
      </Button>
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
