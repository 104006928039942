import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { InviteStudentLinkAPI } from "../../api/Common/action";
import { collegeDetailSelectorFn, userRolesActionsFn } from "../../api/SignIn/selector";
import { StudentFieldsPostAPI, StudentSelectedFieldsAPI, StudentUploaderFieldsAPI } from "../../api/StudentManagementForm/action";
import StudentUpload from "../../components/Cards/StudentUpload";
import CustomCircularProgress from "../../components/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "48px",
    marginTop: "30px",
    marginBottom: "30px",
    width: "20%",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  circularProgressColored: {
    color: theme.palette.primary.main,
  },
}));

function StudentUploader() {
  const classes = useStyles();
  const [localFieldList, setLocalFieldList] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSelected, setLoadingSelected] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  React.useEffect(() => {
    if (collegeDetails?.college_id) {
      dispatch(StudentUploaderFieldsAPI(setLoadingSelected, alert, history, location));
      dispatch(StudentSelectedFieldsAPI(setLoadingData, alert, history, location, collegeDetails?.college_id));
    }
  }, [collegeDetails?.college_id]);

  const [inviteCall, setInviteCall] = useState(false);
  const handleSubmit = () => {
    setInviteCall(false);
    var arr = [];
    localFieldList.map((item) => {
      if (item.is_selected) arr.push(item.id);
    });
    var data = {
      college_id: [collegeDetails?.college_id],
      student_form_fields: arr,
    };
    if (arr.length > 0) {
      dispatch(StudentFieldsPostAPI(data, setLoading, alert, history, location, collegeDetails?.college_id, setInviteCall));
    } else alert.error("Please Select at least one Checkbox");
  };

  useEffect(() => {
    if (inviteCall) dispatch(InviteStudentLinkAPI(alert, history, location));
  }, [inviteCall]);
  const fieldList = useSelector((state) => state?.StudentUploader?.details);
  const selectedFields = useSelector((state) => state?.StudentFields?.details);
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <>
      {/* <Layout> */}
      <Typography variant="h2">Student Data Management Form</Typography>
      <Typography variant="body2" color="secondary" style={{ marginBottom: "7px" }}>
        Create Custom Data Set for Students
      </Typography>
      {loadingSelected ? (
        <CustomCircularProgress />
      ) : (
        <>
          <StudentUpload
            localFieldList={localFieldList}
            setLocalFieldList={setLocalFieldList}
            fieldList={fieldList}
            selectedFields={selectedFields}
            userRolesPermission={userRolesPermission}
          />
          {userRolesPermission?.some((item) => item.access_control_key === "manage_forms") && (
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading ? <CircularProgress className={classes.circularProgress} size={14} /> : "Update Form"}
            </Button>
          )}
        </>
      )}
      {/* </Layout> */}
    </>
  );
}

export default StudentUploader;
