import { Typography, Box, Button, IconButton } from "@material-ui/core";
import React, { useRef } from "react";
import Charst from "react-apexcharts";
import html2canvas from 'html2canvas';
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DownloadIcon from '@mui/icons-material/Download';
// import PieChart from "../Report/PieChart";
// import PieTab from "./PieTab";
import * as FileSaver from 'file-saver';
import Theme from "../../../style/theme";



function CampusPieGraph({graphData,from}) {


 const numbers = graphData?.graph_data?.map(item => item.value);
 const targetRef = useRef(null);
 const getHeading = (from === "studentPlaced")? "Course vs Gender(%)" :"Course vs Gender";

  const chartData = {
    series: numbers ?? [0,0,0],

    options: {
      chart: { type: "donut" },
      download: true,
      title: {
        text: getHeading,
        style: {
          fontSize: "18px",
          fontFamily: "Nunito",
          fontWeight: 1000,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: ["rgba(205, 57, 62, 0.85)", "#E0A800"] },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 }, 
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "65%",
            labels: {
              show: true,
              name: { show: true },
              total: {
                show: true,
                showAlways: true,
                label: graphData?.text,
                formatter: function (w) {
                  return graphData?.total // Display total count in the center
                }
              },
            },
          },
        },
      },
    },
  };


  const takeScreenshot = () => {
    if (targetRef.current) {
      html2canvas(targetRef.current).then((canvas) => {
        // Convert the canvas to a Blob
        canvas.toBlob((blob) => {
          // Use FileSaver to save the Blob as an image file
          FileSaver.saveAs(blob, `${getHeading}.png`);
        });
      });
    }
  };
 
  return (
    <div
    ref={targetRef}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
    <div style={{display:"flex",flexDirection:"row-reverse", width:"100%"}} >  
    <Button style={{color:"grey"}} onClick={takeScreenshot}>Download <IconButton style={{padding:"0px"}}><DownloadIcon/></IconButton></Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "500px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop:"-8%"
            }}
          >
            <Charst
              options={chartData?.options}
              series={chartData?.series}
              type="donut"
              height={290}
              width="100%"
              //height="100%"
            />

            <Box style={{ display: "flex", alignItems: "center",marginLeft:"30px"}}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "50px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(205, 57, 62, 0.85)",
                    height: "20px",
                    width: "20px",
                    borderRadius: "4px",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="body2">Male</Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#E0A800",
                    height: "20px",
                    width: "20px",
                    borderRadius: "4px",
                    marginRight: "10px",
                  }}
                />

                <Typography variant="body2">Female</Typography>
              </div>
            </Box>
          </div>

          <div style={{marginLeft:"40px",marginTop:"-60px"}}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <FiberManualRecordIcon
                style={{
                  color: "rgba(205, 57, 62, 0.85)",
                  marginRight: "10px",
                }}
              />

              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">{numbers?.length ? from === "studentPlaced" ?  `${numbers[0]}%` : numbers[0] : 0}</Typography>
                <Typography variant="body2" style={{ color: "#6C757D" }}>
                  {from ==="studentData" ? "Males": "Males" }
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginRight: "10px",
              }}
            >
              <FiberManualRecordIcon
                style={{
                  color: "#E0A800",
                  marginRight: "10px",
                }}
              />

              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">{numbers?.length ? from === "studentPlaced" ? `${numbers[1]}%` : numbers[1] : 0}</Typography>
                <Typography variant="body2" style={{ color: "#6C757D" }}>
                {from ==="studentData" ? "Females": "Females"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusPieGraph;
