import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  useTheme,
  FormControlLabel,
  withStyles,
  Radio,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#fff",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function ChooseCategory({ setJobType, checked }) {
  const theme = useTheme();

  const handleChange = (event) => {
    setJobType(event.target.value);
  };
  return (
    <Box style={{ height: "100%" }}>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", margin: 0, height: "100%" }}
      >
        <Grid item md={6} style={{ borderRight: "1px solid #E0E0E0" }}>
          <Box
            style={{
              textAlign: "center",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{ fontSize: "20px", fontWeight: "600", marginBottom: 5 }}
            >
              Post Full Time Job
            </Typography>
            <Typography style={{ fontSize: "15px", color: "#000" }}>
              Full-time work offers a pathway to career advancement.
              <br /> Perks are the cherry on top of the employment cake, and
              <br />
              they come in all shapes and sizes.
            </Typography>

            <Box
              style={{
                marginTop: "15px",
                padding: 5,
                paddingLeft: 20,
                paddingRight: 30,
                width: "fit-content",
                borderRadius: 10,
                border: "1px solid",
                borderColor:
                  checked == 0 ? theme.palette.primary.main : "#6C757D",
                backgroundColor:
                  checked == 0 ? theme.palette.primary.main : "transparent ",
              }}
            >
              <FormControlLabel
                value={0}
                control={<StyledRadio />}
                checked={checked == 0}
                onChange={handleChange}
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: checked == 0 ? "#FFFFFF" : "#000",
                    }}
                  >
                    Select
                  </Typography>
                }
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: checked == 0 ? "#fff" : "" }}
                    value={0}
                    checked={checked == 0}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: checked == 0 ? "#FFFFFF" : "#6C757D",
                    }}
                  >
                    Select
                  </Typography>
                }
              /> */}
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            style={{
              textAlign: "center",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{ fontSize: "20px", fontWeight: "600", marginBottom: 5 }}
            >
              Post Internship
            </Typography>
            <Typography style={{ fontSize: "15px", color: "#000" }}>
              An internship is a great way to get career the much
              <br /> needed hands-on learning and real-time experience in
              <br /> the beginning of the career.
            </Typography>

            <Box
              style={{
                marginTop: "15px",
                padding: 5,
                paddingLeft: 20,
                paddingRight: 30,
                width: "fit-content",
                borderRadius: 10,
                border: "1px solid",
                borderColor:
                  checked == 1 ? theme.palette.primary.main : "#6C757D",
                backgroundColor:
                  checked == 1 ? theme.palette.primary.main : "transparent ",
              }}
            >
              <FormControlLabel
                control={<StyledRadio />}
                value={1}
                checked={checked == 1}
                onChange={handleChange}
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: checked == 1 ? "#FFFFFF" : "#000",
                    }}
                  >
                    Select
                  </Typography>
                }
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: checked == 1 ? "#fff" : "" }}
                    value={1}
                    checked={checked == 1}
                    onChange={handleChange}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: checked == 1 ? "#FFFFFF" : "#6C757D",
                    }}
                  >
                    Select
                  </Typography>
                }
              /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ChooseCategory;
