import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CustomCircularProgress from "../../components/CircularProgressBar";
import NoDataFuncNew from "../../components/NoDataSVG/index2";
import { generateCsv } from "../../utility/commaSeparatedStrings";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import InboundTab from "../../components/Header/InboundJobsTab/InboundTab";
import useAxiosApi from "../../Hooks/useAxiosApi";
import { BackendBaseApi } from "../../constants/constants";
import moment from "moment";
import { niyuktiAxiosGetReq } from "../../api/BaseApi/apiBase";
import { getJobsAPI } from "../../api/JobPost/action";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    justifyContent: "space-between",
    flexDirection: "row",
  },
}));

const InboundJobs = () => {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    job_status: "PENDING",
    job_search: null,
    company_search: null,
    job_type: null,
    start_date: null,
    ctc_min: null,
    ctc_max: null,
    created: null,
  });

  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const userId = collegeDetails?.user_id;
  const collegeId = useSelector(collegeIdFn);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const [jobTitleSearch, setJobTitleSearch] = useState(false);
  const [companyNameSearch, setCompanyNameSearch] = useState(false);
  const [applyClickedCTC, setApplyClickedCTC] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const filterString = useRef("");
  const apiCallFlag = useRef(false);

  // for getting total jobs so that we can check if the college has selected the form or not

  useEffect(() => {
    const url = `api/manage_job/view/?college_id=${collegeId}`;
    dispatch(getJobsAPI(() => {}, alert, history, location, url));
  }, []);

  const getFiltersParam = () => {
    var filterString = "?";

    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        if (key === "job_status") {
          filterString += `${key}=${value}&`;
        }
      }

      if (value) {
        if (key === "job_title") {
          filterString += `job_search=${value}&`;
        }
      }
      if (value) {
        if (key === "company_name") {
          filterString += `company_search=${value}&`;
        }
      }
      if (value) {
        if (key === "job_type") {
          filterString += `${key}=${value}&`;
        }
      }
      if (value) {
        if (key === "created") {
          filterString += `${"start_date"}=${moment(value).format(
            "YYYY-MM-DD"
          )}&`;
        }
      }

      if (value) {
        if (key === "min") {
          console.log("ctc");
          filterString += `ctc_min=${Number(value) * 100000}&`;
        }
      }

      if (value) {
        if (key === "max") {
          filterString += `ctc_max=${Number(value) * 100000}&`;
        }
      }
    }
    return filterString;
  };

  const {
    data: navbarData,
    apiCall: getNavbarData,
    loading: loadingNavbar,
  } = useAxiosApi({
    url: `${BackendBaseApi?.NIYUKTI}job/college_saas/inbound_jobs/job_navbar/`,
  });

  const jobsListCallBackFn = () => {
    apiCallFlag.current = false;
    setJobTitleSearch(false);
    setCompanyNameSearch(false);
  };

  const {
    apiCall: getJobsList,
    data: jobList,
    loading: loadingJobs,
    pagination: paginationData,
    setData: setJobsList,
    setLoading: setLoadingJobs,
  } = useAxiosApi({
    url: `${BackendBaseApi?.NIYUKTI}job/college_saas/inbound_jobs/job_lists/${
      filterString.current
    }page=${pageNo + 1}`,
    mountingCallFlag: apiCallFlag.current,
    callBackFn: jobsListCallBackFn,
  });

  useEffect(() => {
    console.log("jobList", jobList);
  }, [jobList]);

  useEffect(() => {
    if (!loadingJobs) {
      setLoadingJobs(true);
      apiCallFlag.current = true;
      setPageNo(0);
      filterString.current = getFiltersParam();
      setJobsList([]);
      getJobsList();
    }
  }, [
    jobTitleSearch,
    filters?.job_type,
    companyNameSearch,
    applyClickedCTC,
    filters?.job_search,
    filters?.job_status,
    filters?.created,
  ]);

  useEffect(() => {
    if (pageNo > 1 && !loadingJobs) {
      setLoadingJobs(true);
      setJobsList([]);
      getJobsList();
    }
  }, [pageNo]);

  useEffect(() => {
    if (loadingNavbar) {
      setJobsList([]);
      setLoadingJobs(true);
      getJobsList();
    }
  }, [loadingNavbar]);

  const addRejectJob = async (action, deleteReason) => {
    const endPoint = `job/college_saas/inbound_jobs/reject_job/?job_id=${
      selectedRowsData?.[0]?.job_id
    }${action === "ADD" ? "&undo=1" : ""}${
      deleteReason ? "&reason=" + deleteReason : ""
    }`;

    apiCallFlag.current = true;

    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        getNavbarData();
      } else {
        alert.error(res?.data?.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={classes.grid}>
        <div>
          <Typography variant="h2">Inbound Jobs</Typography>
          <Typography variant="body2" color="secondary">
            Jobs to you directly by Companies
          </Typography>
        </div>
      </div>
      <div>
        <InboundTab
          loading={loadingJobs}
          navbarData={navbarData}
          filters={filters}
          setFilters={setFilters}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={setSelectedRowsData}
          jobTitleSearch={jobTitleSearch}
          companyNameSearch={companyNameSearch}
          setJobTitleSearch={setJobTitleSearch}
          setCompanyNameSearch={setCompanyNameSearch}
          applyClickedCTC={applyClickedCTC}
          setApplyClickedCTC={setApplyClickedCTC}
          collegeId={collegeId}
          userRolesPermission={userRolesPermission}
          jobList={jobList}
          handleRejectJob={addRejectJob}
          paginationData={paginationData}
          updateJobsList={getJobsList}
          pageNo={pageNo}
          setPageNo={setPageNo}
          apiCallFlag={apiCallFlag}
          setJobsList={setJobsList}
        />
      </div>

      {/* correct this later onwards */}
      {/* {navbarData ? null :
                <NoDataFuncNew
                    title={"No Inbound Jobs Available"}
                    subtitle={"Click the below Button to manage jobs"}
                    buttonText={"Go to Manage Jobs"}
                    height={"320px"}
                    width={"320px"}
                    link={"/manage-jobs"}
                />
            } */}
    </>
  );
};

export default InboundJobs;
