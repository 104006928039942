import React, { useEffect, useState } from "react";
import { BackendBaseApi } from "../../constants/constants";
import axios from "axios";

const useOffCampusForm = (
  collegeId,
  alert
  //   setLoading
) => {
  const [placementCycleList, setPlacementCycleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    if (collegeId) {
      axios
        .get(
          BackendBaseApi.SANCHALAN +
            `api/placement_cycles/placements/?single_placement=true&college_param=${collegeId}`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response?.data?.success)
            setPlacementCycleList(response?.data?.data);
          else alert.error(response?.data?.error);
        })
        .catch((error) => {
          alert.error(error?.response?.data?.error);
          throw error;
        });

      axios
        .get(
          BackendBaseApi.SANCHALAN +
            `api/company/list/?college_id=${collegeId}&invitation_source=OFFCAMPUS_PLACEMENT,SAAS`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response?.data?.success) setCompanyList(response?.data?.data);
          else alert.error(response?.data?.error);
        })
        .catch((error) => {
          alert.error(error?.response?.data?.error);
          throw error;
        });
    }
  }, [collegeId]);

  return { placementCycleList, companyList, setCompanyList };
};

export default useOffCampusForm;
