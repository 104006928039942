import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { mergeClasses } from "@material-ui/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drop: {
    padding: "5px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "8px",
    width: "150px",
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function ReportDropDown(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={mergeClasses.title}
        aria-controls="customized-menu"
        style={{
          display: "flex",
          borderRadius: "8px",
          backgroundColor: "#e2e6ea",
          color: "#7a7e83",
          paddingRight: "20px",
          paddingLeft: "20px",
          boxShadow: "none",
        }}
        aria-haspopup="true"
        variant="contained"
        size="small"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <Typography
          variant="overline"
          color="inherit"
          style={{ marginRight: "50px" }}
        >
          {props.name}
        </Typography>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <StyledMenuItem>
          <ListItemText primary="Sent mail" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText primary="Drafts" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText primary="Inbox" />
        </StyledMenuItem> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
        </div>
      </StyledMenu>
    </div>
  );
}
