import { Box, ButtonBase, Grid, Menu, MenuItem, Chip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShareIcon from "@material-ui/icons/Share";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useHistory, useLocation } from "react-router-dom";
import { deletePost, likePost } from "../../../api/NoticeBoard/action";
import verified from "../../../assets/png/verified-user.png";
import { collegeIdFn } from "../../../api/SignIn/selector";
import pSBC from "shade-blend-color";
import { PdfLeft } from "./pdf";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    border: ".5px solid #e2e6ea",
    borderRadius: "8px",
    marginBottom: "20px",
    boxShadow: "none",
    // padding: "20px",
  },
  media: {
    height: 0,
    padding: "20px",
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  // avatar: {
  //   backgroundColor: red[500],
  // },
  headerTitle: {
    color: "red",
  },
  pdf: {
    marginTop: "20px",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    //border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "10px",
    display: "flex",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function Feed({
  data,
  setState,
  state,
  userRolesPermission,
  dialogOpen,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const collegeId = useSelector(collegeIdFn);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [currentElement, setCurrentElement] = React.useState(null);
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setCurrentElement(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const postDelete = (item) => {
    dispatch(deletePost(item?.feed_id, alert, history, location, data));
    setAnchorEl(null);
  };

  const editPost = (item) => {
    setState(item);
    setAnchorEl(null);
  };

  const postLike = (item, index) => {
    const payload = {
      feed_id: item?.feed_id,
    };
    dispatch(likePost(payload, alert, history, location, data, index, item));
  };

  return data?.map((element, index) => {
    return (
      <Card className={classes.root}>
        <div style={{ padding: "20px", paddingBottom: "0px" }}>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase className={classes.image}>
                <Avatar alt="user" src={element?.poster_image} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={1}>
                <Grid item xs>
                  <div style={{ display: "flex" }}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      {element.poster_name}
                    </Typography>
                    {/* <img src={verified} height="20px" width="20px" /> */}
                  </div>
                  <Typography
                    variant="body2"
                    style={{ color: "#7a7e83", fontSize: "14px" }}
                  >
                    {element.posted_by}
                  </Typography>
                </Grid>
                <Grid>
                  {!collegeId &&
                    element?.selected_college_ids &&
                    element?.selected_college_ids.map((item) => {
                      return (
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          label={item.name}
                          className={classes.chip}
                        />
                      );
                    })}
                </Grid>
              </Grid>
              {userRolesPermission?.some(
                (item) => item.access_control_key === "create_feed"
              ) && (
                <Grid item>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, element)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <StyledMenu
                    // <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => editPost(currentElement)}>
                      Edit Post
                    </MenuItem>
                    <MenuItem onClick={() => postDelete(currentElement)}>
                      Delete
                    </MenuItem>
                    {/* </Menu> */}
                  </StyledMenu>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        {element?.images?.length >= 1 ? (
          <div style={{ marginTop: 10 }}>
            <Carousel
              showThumbs={false}
              onChange={(index) => console.log("ddd", index)}
            >
              {element?.images.map((item) => {
                return (
                  <div>
                    <img src={item.url} height={500} width="100px" />
                    <div
                      style={{
                        position: "relative",
                        top: "-70px",
                        left: "44%",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(item.url, "_blank");
                        }}
                        style={{ backgroundColor: "white", opacity: 0.8 }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        ) : null}
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Typography variant="body2">
            {ReactHtmlParser(element?.content?.split("<p><br></p>")?.join(""))}
          </Typography>
          {element?.tags?.length >= 1 ? (
            <span style={{ color: "#007bff" }}>
              {Array.prototype.map
                .call(element?.tags, function (item) {
                  return item;
                })
                .join(", ")}
            </span>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Typography variant="overline" style={{ color: "#697882" }}>
            {moment(element?.update_time).format("lll")}
          </Typography>
        </div>

        {element?.documents.length >= 1 ? (
          <div className={classes.pdf}>
            <PdfLeft
              message={`${
                element?.documents[0]?.file_name
                  ? element?.documents[0]?.file_name
                  : "Attachment." + element?.documents[0].extension
              }`}
              timestamp={moment(element?.update_time).format("lll")}
              link={element?.documents[0]}
            />
          </div>
        ) : null}

        <CardActions disableSpacing>
          <Box
            component="fieldset"
            borderColor="transparent"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => postLike(element, index)}
              aria-label="add to favorites"
            >
              <FavoriteIcon
                style={{
                  color: element?.is_liked === true ? "red" : "#ccc8c8",
                }}
              />
            </IconButton>
            <Typography
              variant="body2"
              style={{ color: "#000", marginLeft: "10px" }}
            >
              {element?.like_count ? element?.like_count : "0"}
            </Typography>
          </Box>
          {/* <CopyToClipboard text={element?.share_link ? element?.share_link : element?.share_link} onCopy={() => alert.success("Link Copied!")}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              // onClick={() => handleExpandClick(item)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ShareIcon color="primary" />
            </IconButton>
          </CopyToClipboard> */}
        </CardActions>
      </Card>
    );
  });
}
