import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { isEducationGroupFn } from "../../api/Domain/selector";
import { HelpData } from "../../api/Help/action";

export default function SearchFaqs({ inDepth }) {
  const classes = useStyles();
  const [suggestionsData, setSuggestionsData] = React.useState([]);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isEducationGroup = useSelector(isEducationGroupFn);

  useEffect(() => {
    dispatch(HelpData(alert, history, location, isEducationGroup));
  }, []);

  const helpData = useSelector((state) => state?.Helps?.details);

  useEffect(() => {
    if (helpData) {
      const temp2 = [];
      setSuggestionsData([]);
      helpData.map((item) => {
        item.how_to_use_saas.map((item2) => {
          temp2.push({ id: item2.id, name: item2.Question });
        });
      });
      setSuggestionsData(temp2);
    }
  }, [helpData]);

  return (
    <div
      component="ul"
      style={{ paddingLeft: "10px", paddingRight: "10px", width: "400px" }}
    >
      {suggestionsData ? (
        <Autocomplete
          options={suggestionsData}
          getOptionLabel={(option) => String(option.name)}
          onChange={(e, newValue) => {
            if (newValue) {
              let path = newValue.id;
              history.push(
                isEducationGroup
                  ? `/educational-group/questions/${newValue?.name
                      ?.toLowerCase()
                      ?.replace(/ /g, "-")}-${path}`
                  : `/questions/${newValue?.name
                      ?.toLowerCase()
                      ?.replace(/ /g, "-")}-${path}`
              );
            }
          }}
          style={{ background: "#fff", borderRadius: 8 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={"Search your query.."}
              inputProps={{
                ...params.inputProps,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
            />
          )}
        />
      ) : null}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));
