import { Button, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavbarSearchListAPI } from "../../../api/Common/action";
import { themeSelectorFn } from "../../../api/Domain/selector";
import { CallCollegeNavbarAPI } from "../../../api/University/APIs/action";

const useStyles = makeStyles((theme) => ({
  root: {
    //   padding: "0px 4px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    //width: 450,
    minWidth: "340px",
    // paddingLeft: "15px",
    height: "40px",
    borderRadius: "8px",
    // border: ".5px solid #e2e6ea",
  },

  input: {
    // marginLeft: theme.spacing(1),
    flex: 1,
    marginTop: -7,
  },
  iconButton: {
    padding: 10,
    backgroundColor: "",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    height: "40px",
    marginTop: 1,
    marginLeft: "-63px",
    boxShadow: "none",
  },
}));

export default function Search({ placeholder, from }) {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);
  const [search, setSearch] = useState(null);
  const [searchSelected, setSearchSelected] = useState(null);

  const theme = createMuiTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          height: "40px",
          borderRadius: 8,
          width: "112%",
          // border: ".5px solid #e2e6ea",
          "&$focused $notchedOutline": {
            borderColor: theme_change?.main,
            // border: ".5px solid #007bff",
          },
          "&&& $input": {
            padding: "3px 4px",
          },
        },
        notchedOutline: {
          border: ".5px solid #e2e6ea",
        },
      },
      // MuiInputLabel: {
      //   formControl:( {
      //     top: -5,
      //   },
      // },
      MuiAutocomplete: {
        paper: {
          borderRadius: 8,
        },
      },
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (search) {
      debouncedSave(search);
    }
  }, [search]);

  const debouncedSave = useCallback(
    debounce((search) => dispatch(NavbarSearchListAPI(search)), 500),
    []
    // will be created only once initially
  );
  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  const searchList = useSelector((state) => state.commonData?.searchList);
  const history = useHistory();
  const [menuClicked, setMenuClicked] = useState(false);

  const handleClickUni = (newValue) => {
    dispatch(
      CallCollegeNavbarAPI(
        newValue?.college_id,
        newValue?.end_point,
        user_details,
        history,
        setMenuClicked
      )
    );
  };

  useEffect(() => {
    if (menuClicked) {
      console.log("inside");
      handleClickUni(searchSelected);
    }
  }, [menuClicked]);
  return (
    <Paper component="form" className={classes.root}>
      {/* <InputBase
        className={classes.input}
        placeholder="Search Student, Company, Job, Page …"
        style={{ fontSize: "14px" }}
      /> */}
      <MuiThemeProvider theme={theme}>
        <Autocomplete
          className={classes.input}
          options={searchList ? searchList : []}
          freesolo
          getOptionLabel={(option) => option.text}
          value={searchSelected}
          onChange={(e, newValue) => {
            if (from === "university") {
              setSearchSelected(newValue);
              setMenuClicked(true);
            } else {
              history.push(newValue?.end_point);
              setSearchSelected(newValue);
            }
          }}
          renderOption={(option) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>{`${option.text}`}</span>
                <span style={{ color: "#181818" }}>{`${option.type}`}</span>
              </div>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,

                style: { padding: "1px", fontSize: "14px" },
              }}
              margin="normal"
              variant="outlined"
              placeholder={placeholder}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          )}
        />
      </MuiThemeProvider>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<SearchIcon />}
      >
        Search
      </Button>
    </Paper>
  );
}

// const CssTextField = withStyles({
//   root: {
//     "& label.Mui-focused": {
//       color: "#007bff",
//       top: 0,
//     },

//   },
// })(TextField);
