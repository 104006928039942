import { Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpecGetAPI } from "../../../api/Common/action";
import Calendar from "../../Input/Calendar/blockedDates";
import { isGradeSystemFn } from "../../../api/Domain/selector";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },

  formControl: {
    marginTop: "12px",
    marginBottom: "5px",
    width: "100%",
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  submit: {
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function StudentData({ studentDetails, setStudentDetails, studentDetailsError, setStudentDetailsError, collegeId }) {
  const classes = useStyles();
  const [degree, setDegree] = React.useState("");
  const dispatch = useDispatch();
  const isGradeSystem = useSelector(isGradeSystemFn);

  const handleChange = (event) => {
    setDegree(event.target.value);
  };

  const handleChangeText = (e) => {
    var name = e.target.name;
    setStudentDetails({ ...studentDetails, [name]: e.target.value });
    setStudentDetailsError({ ...studentDetailsError, [name]: null });
  };

  useEffect(() => {
    if (studentDetails?.degree) {
      dispatch(SpecGetAPI(studentDetails?.degree?.id, collegeId));
    }
  }, [studentDetails?.degree]);

  const degreeList = useSelector((state) => state?.commonData?.degrees);

  const courseList = useSelector((state) => state?.commonData?.specialization);

  useEffect(() => {
    setStudentDetails({ ...studentDetails, backlog_count: null });
  }, [studentDetails?.backlog]);

  const selectedFields = useSelector((state) => state?.StudentPublicFields?.details);

  function getFieldContent(step, item) {
    switch (step) {
      case "textfield":
        return (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            InputProps={{ classes: { root: classes.inputRoot } }}
            label={item.key === "cgpa" ? (isGradeSystem ? "Current Education Marks (CGPA)" : "Current Education Marks (Percentage)") : item.field_name}
            name={item.key}
            value={studentDetails[item.key]}
            onChange={handleChangeText}
            error={studentDetailsError[item.key] ? true : false}
            helperText={<span>{studentDetailsError[item.key]}</span>}
            type={item.key === "phone" || item.key === "tenth_marks" || item.key === "twelfth_marks" || item.key === "cgpa" ? "number" : "text"}
          />
        );
      case "dropdown-with-checkbox":
        return (
          <>
            <Grid container spacing={1} style={{ marginTop: "20px", marginBottom: "0px" }}>
              <Grid item md={4} xs={12} style={{ marginBottom: 20 }}>
                <Calendar
                  selectedDate={studentDetails?.start_date}
                  title="Course Start Date"
                  onDateChange={(date) => {
                    setStudentDetails({
                      ...studentDetails,
                      start_date: moment(date).format("YYYY-MM-DD"),
                    });
                    setStudentDetailsError({
                      ...studentDetailsError,
                      start_date: null,
                    });
                  }}
                  errorMessage={studentDetailsError?.start_date}
                  minDate={"1990-11-17"}
                />{" "}
              </Grid>
              <br />
              {studentDetails?.start_date && (
                <Grid item md={8} xs={12}>
                  <Calendar
                    selectedDate={studentDetails?.end_date}
                    title="Course End Date"
                    onDateChange={(date) => {
                      setStudentDetails({
                        ...studentDetails,
                        end_date: moment(date).format("YYYY-MM-DD"),
                      });
                      setStudentDetailsError({
                        ...studentDetailsError,
                        end_date: null,
                      });
                    }}
                    errorMessage={studentDetailsError?.end_date}
                    minDate={new Date(studentDetails?.start_date).toISOString().substring(0, 10)}
                    futureDisableFlag={false}
                  />{" "}
                </Grid>
              )}
            </Grid>
          </>
        );
      case "autocomplete":
        return (
          <>
            {item.key === "degree" ? (
              <>
                <br />
                {degreeList && (
                  <Autocomplete
                    id="country-select-demo"
                    options={degreeList}
                    getOptionLabel={(option) => option.name}
                    value={studentDetails.degree}
                    onChange={(e, newValue) => {
                      setStudentDetails({
                        ...studentDetails,
                        degree: newValue,
                      });
                      setStudentDetailsError({
                        ...studentDetailsError,
                        degree: null,
                      });
                    }}
                    style={{ marginTop: 20 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Degree"}
                        style={{ marginBottom: "10px" }}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        error={studentDetailsError.degree ? true : false}
                        helperText={<span>{studentDetailsError.degree}</span>}
                      />
                    )}
                  />
                )}
                {/* <br /> */}
                {courseList && (
                  <Autocomplete
                    id="country-select-demo"
                    options={courseList}
                    getOptionLabel={(option) => option.specialization_name}
                    value={studentDetails.course}
                    onChange={(e, newValue) => {
                      setStudentDetails({
                        ...studentDetails,
                        course: newValue,
                      });
                      setStudentDetailsError({
                        ...studentDetailsError,
                        course: null,
                      });
                    }}
                    disabled={studentDetails?.degree ? false : true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Course"}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        style={{ marginBottom: "10px", marginTop: "15px" }}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        error={studentDetailsError.course ? true : false}
                        helperText={<span>{studentDetailsError.course}</span>}
                      />
                    )}
                  />
                )}
              </>
            ) : (
              <Autocomplete
                id="country-select-demo"
                options={courseList}
                getOptionLabel={(option) => option.name}
                value={studentDetails[item.key]}
                onChange={(e, newValue) => {
                  setStudentDetails({
                    ...studentDetails,
                    [item.key]: newValue,
                  });
                  setStudentDetailsError({
                    ...studentDetailsError,
                    [item.key]: null,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={item?.field_name}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    error={studentDetailsError[item.key] ? true : false}
                    helperText={<span>{studentDetailsError[item.key]}</span>}
                  />
                )}
              />
            )}
          </>
        );
      case "textfield-with-checkbox":
        return (
          <>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  checked={studentDetails?.backlog}
                  onChange={(e) => {
                    setStudentDetails({
                      ...studentDetails,
                      backlog: e.target.checked,
                    });
                  }}
                />
              }
              label="Any Backlogs"
              labelPlacement="end"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              disabled={!studentDetails.backlog}
              InputProps={{ classes: { root: classes.inputRoot } }}
              label={item.field_name}
              name={item.key}
              value={studentDetails[item.key]}
              onChange={handleChangeText}
              error={studentDetailsError[item.key] ? true : false}
              helperText={<span>{studentDetailsError[item.key]}</span>}
              type={"number"}
            />
          </>
        );
      case 4:
        return <></>;
      default:
        return <></>;
    }
  }

  return (
    <>
      <div>
        {selectedFields?.form_fields?.length > 0 && selectedFields?.form_fields?.map((item) => <>{getFieldContent(item.field_type, item)} </>)}

        {/* <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="Name"
          name="name"
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          id="email"
          label="Personal Email"
          name="email"
          autoComplete="email"
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          fullWidth
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="Phone Number"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          fullWidth
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="Enrollment Number"
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="College Email"
          autoComplete="email"
        />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-outlined-label"
            className={classes.floatingLabelFocusStyle}
            required
          >
            Degree
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={degree}
            onChange={handleChange}
            label="Degree"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Btech</MenuItem>
            <MenuItem value={20}>BCA</MenuItem>
            <MenuItem value={30}>MCA</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="Course"
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          name="course"
          autoComplete="course"
        />

        <TextField
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          fullWidth
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="10th Marks"
          name="10th Marks"
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="12th Marks"
          name="12th Marks"
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          fullWidth
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="Current Education Marks"
        />
        <FormControlLabel
          value="end"
          control={<Checkbox size="small" color="primary" />}
          label="Any Backlogs"
          labelPlacement="end"
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          label="Name of the subjects in backlogs"
        /> */}
        <br />
      </div>
    </>
  );
}
