import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { EDGJobsAPI } from "../../../api/University/APIs/action";
import CustomCircularProgress from "../../../components/CircularProgressBar";
import NewPostJob from "../../PostJob/NewPostJob";

function UniversityPostJob() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   dispatch(EDGJobsAPI(setLoading, alert, history, location));
  // }, []);

  return (
    <>
      {/* <UniversityLayout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <NewPostJob />
        // <>
        //   <Typography variant="h2">Post Job</Typography>
        //   <div style={{ marginTop: "20px" }}>
        //     <Grid container spacing={5}>
        //       {collegeJobsList?.length > 0 &&
        //         collegeJobsList?.map((item) => (
        //           <Grid item xs={4}>
        //             <UplacementCycle item={item} countTitle={"Open Jobs"} count={item?.open_jobs} link={`/post-job`} linkTitle={"Post Job"} from={"PostJob"} />
        //           </Grid>
        //         ))}
        //     </Grid>
        //   </div>
        // </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityPostJob;
