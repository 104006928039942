import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Box,
  Grid,
  TextField,
  Checkbox,
  Chip,
  Button,
  Typography,
  createMuiTheme,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  CircularProgress,
  MuiThemeProvider,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import pSBC from "shade-blend-color";
import CloseIcon from "@material-ui/icons/Close";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Calendar from "../../Input/Calendar/index2";
import Editor from "../../Editor";
import moment from "moment";

import { AllJobRolesAPI } from "../../../api/JobPost/action";
import { AllLocationsAPI } from "../../../api/JobPost/action";
import { themeSelectorFn } from "../../../api/Domain/selector";

const useStyles = makeStyles((theme) => ({
  box: {},
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  text: {
    color: theme.palette.primary.main,
  },
  plz: {
    color: "#DC3545",
    fontSize: 14,
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      marginTop: 100,
      marginBottom: "-40px",
    },
  },
}));

function AddNewJob({
  handleCloseWithClear,
  handleClose,
  setOffCampusFormData,
  offCampusFormData,
  handleSelectedJobChange,
  setIsUpdate,
}) {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  const theme_change = useSelector(themeSelectorFn);

  const Theme = createMuiTheme({
    palette: {
      type: "light",
      common: {
        black: "#000000",
        white: "#ffffff",
      },
      primary: {
        main: theme_change?.main ? theme_change?.main : "#01203F",
        hover: theme_change?.hover ? theme_change.hover : "#0069D9",
        disable: theme_change?.disable ? theme_change?.disable : "#58AAFF",
        contrastText: theme_change?.contrast_text
          ? theme_change.contrast_text
          : "#fff",
        secondary: theme_change?.secondary ? theme_change.secondary : "#e0a800",
      },
    },
    typography: {
      fontFamily: "Nunito",
      h1: {
        fontSize: "3em",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h2: {
        fontSize: "34px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h3: {
        fontSize: "24px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h4: {
        fontSize: "20px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h5: {
        fontSize: "18px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h6: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body1: {
        color: "#000000",
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body2: {
        color: "#000000",
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body3: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle1: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle2: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      caption: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      overline: {
        fontSize: "10px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      button: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      buttonmob: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          minHeight: 50,
          borderRadius: 8,
          fontSize: 15,
        },
        notchedOutline: {
          // borderColor: "transparent",
        },
        multiline: {
          height: "auto",
        },
      },
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadingJobRoles, setLoadingJobRoles] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const {
    job_title,
    job_role_id,
    job_role_name,
    city_id,
    city,
    job_type_id,
    job_type_name,
    full_ctc_min,
    full_ctc_max,
    hiring_date,
    joining_date,
    job_description,
    job_location_type,
    payment_type,
    ppo,
    ppo_ctc_max,
    ppo_ctc_min,
    stipend,
    locations,
  } = offCampusFormData;

  const [offCampusFormErrorData, setOffCampusFormErrorData] = useState({
    job_title_error: null,
    job_role_error: null,
    stipend_error: null,
    ppo_ctc_error: null,
    hiring_date_error: null,
    joining_date_error: null,
    full_ctc_error: null,
    job_description_error: null,
    city_error: null,
  });

  const validateData = () => {
    let is_valid = true;
    let job_title_error = null;
    let job_role_error = null;
    let stipend_error = null;
    let ppo_ctc_error = null;
    let full_ctc_error = null;
    let hiring_date_error = null;
    let joining_date_error = null;
    let job_description_error = null;
    let city_error = null;

    const numericRegex = /^-?\d*\.?\d+$/;

    if (!job_title) {
      job_title_error = "Job Title field cannot be left blank";
      is_valid = false;
    }

    if (!job_role_name) {
      job_role_error = "Job Role field cannot be left blank";
      is_valid = false;
    }

    if (jobtype === "Internship" && payment_type === "PAID" && !stipend) {
      stipend_error = "Stipend field cannot be left blank for internships";
      is_valid = false;
    }
    if (
      jobtype === "Internship" &&
      payment_type === "PAID" &&
      stipend &&
      !numericRegex.test(stipend)
    ) {
      stipend_error = "Stipend should be a numeric value";
      is_valid = false;
    }

    if (isPpo === "yes" && (!ppo_ctc_max || !ppo_ctc_min)) {
      ppo_ctc_error = "CTC fields cannot be left blank for PPO";
      is_valid = false;
    }
    if (isPpo === "yes" && ppo_ctc_max && !numericRegex.test(ppo_ctc_max)) {
      ppo_ctc_error = "CTC should be a numeric value";
      is_valid = false;
    }

    if (jobtype === "Full Time" && (!full_ctc_max || !full_ctc_min)) {
      full_ctc_error = "CTC fields cannot be left blank";
      is_valid = false;
    }
    if (
      jobtype === "Full Time" &&
      full_ctc_max &&
      !numericRegex.test(full_ctc_max)
    ) {
      full_ctc_error = "CTC should be a numeric value";
      is_valid = false;
    }

    if (!hiring_date) {
      hiring_date_error = "Hiring Date field cannot be left blank";
      is_valid = false;
    }

    if (!joining_date) {
      joining_date_error = "Joining Date field cannot be left blank";
      is_valid = false;
    }

    if (!job_description || job_description === "<p><br></p>") {
      job_description_error = "Job description cannot be left blank";
      is_valid = false;
    }

    if (job_location_type !== "WFH" && !locations.length) {
      city_error = "Joining Date field cannot be left blank";
      is_valid = false;
    }

    setOffCampusFormErrorData((prevState) => ({
      ...prevState,
      job_title_error,
      job_role_error,
      stipend_error,
      ppo_ctc_error,
      hiring_date_error,
      joining_date_error,
      full_ctc_error,
      job_description_error,
      city_error,
    }));

    return is_valid;
  };

  useEffect(() => {
    dispatch(AllJobRolesAPI(setLoadingJobRoles, alert, history, location));
    dispatch(AllLocationsAPI(setLoadingLocations, alert, history, location));
  }, []);

  const cityList = useSelector((state) =>
    state?.AllLocations?.details ? state?.AllLocations?.details : []
  );
  const jobRolesList = useSelector((state) =>
    state?.AllJobRoles?.details ? state?.AllJobRoles?.details : []
  );

  const [isPpo, setIsPpo] = useState(ppo ? ppo : null);
  const handleChangeIsPpo = (event) => {
    setIsPpo(event.target.value);
    setOffCampusFormData((prevState) => ({
      ...prevState,
      ppo: event.target.value,
    }));
    if (event.target.value === "no")
      setOffCampusFormData((prevState) => ({
        ...prevState,
        ppo_ctc_max: null,
        ppo_ctc_min: null,
      }));
  };

  const [jobDesc, setJobDesc] = useState(
    "Please enter the job description here. You can leave it blank if you don't have one"
  );
  useEffect(() => {
    setOffCampusFormData({
      ...offCampusFormData,
      job_description: jobDesc,
    });
    if (jobDesc && jobDesc !== "<p><br></p>")
      setOffCampusFormErrorData({
        ...offCampusFormErrorData,
        job_description_error: null,
      });
  }, [jobDesc]);

  const [jobtype, setJobtype] = React.useState(
    job_type_name ? job_type_name : "Full Time"
  );

  const handleChangeJob = (event) => {
    setJobtype(event.target.value);
    setOffCampusFormData((prevState) => ({
      ...prevState,
      job_type_name: event.target.value,
      job_type_id: event.target.value === "Full Time" ? 7 : 1,
    }));
    if (event.target.value === "Full Time") {
      setOffCampusFormData((prevState) => ({
        ...prevState,
        payment_type: "PAID",
        ppo: null,
        ppo_ctc_min: null,
        ppo_ctc_max: null,
        stipend: null,
      }));
      setIsPpo(null);
    } else {
      setOffCampusFormData((prevState) => ({
        ...prevState,
        full_ctc_min: null,
        full_ctc_max: null,
        payment_type: paid,
      }));
    }
  };

  const [paid, setPaid] = React.useState(payment_type ? payment_type : "PAID");
  const handleChangePaid = (event) => {
    setPaid(event.target.value);
    setOffCampusFormData((prevState) => ({
      ...prevState,
      payment_type: event.target.value,
    }));
    if (event.target.value !== "PAID") {
      setOffCampusFormData((prevState) => ({
        ...prevState,
        stipend: null,
        ppo_ctc_max: null,
        ppo_ctc_min: null,
      }));
      handleChangeIsPpo({ target: { value: null } });
    }
  };

  const handleJobTitleChange = (event) => {
    setOffCampusFormData({
      ...offCampusFormData,
      job_title: event.target.value,
    });
    setOffCampusFormErrorData({
      ...offCampusFormErrorData,
      job_title_error: null,
    });
  };

  const handleSubmit = async () => {
    if (validateData()) {
      handleSelectedJobChange(null, { job_title: job_title, noUpdate: true });
      setIsUpdate(false);
      handleClose();
    }
  };

  const [selectedLocations, setSelectedLocations] = useState([]);

  const handleSelectedLocationsChange = (e, newValue) => {
    setSelectedLocations(newValue);
    if (newValue) {
      setOffCampusFormData({
        ...offCampusFormData,
        locations: newValue,
      });
      setOffCampusFormErrorData({
        ...offCampusFormErrorData,
        city_error: null,
      });
    }
  };

  return (
    <Box className={classes.box}>
      <Grid container spacing={4}>
        <Grid item md={12} style={{ display: "grid" }}>
          <TextField
            label="Job Title"
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            value={job_title}
            onChange={handleJobTitleChange}
            error={offCampusFormErrorData.job_title_error ? true : false}
            helperText={<span>{offCampusFormErrorData.job_title_error}</span>}
          />
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            id="combo-box-demo"
            options={jobRolesList}
            value={
              jobRolesList.find((option) => option.name === job_role_name) ||
              null
            }
            onChange={(e, newValue) => {
              if (newValue) {
                setOffCampusFormData({
                  ...offCampusFormData,
                  job_role_id: newValue.id,
                  job_role_name: newValue.name,
                });
                setOffCampusFormErrorData({
                  ...offCampusFormErrorData,
                  job_role_error: null,
                });
              }
            }}
            renderOption={(option) => {
              return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Role"
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={offCampusFormErrorData.job_role_error ? true : false}
                helperText={
                  <span>{offCampusFormErrorData.job_role_error}</span>
                }
              />
            )}
          />
        </Grid>
        <Grid item md={12} style={{ paddingTop: 0 }}>
          <FormControl component="fieldset">
            <RadioGroup
              value={jobtype}
              onChange={handleChangeJob}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <Typography variant="body2" style={{ marginRight: "10px" }}>
                Job Type:
              </Typography>
              <FormControlLabel
                value="Full Time"
                control={<Radio size="small" color="primary" />}
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    Full-Time
                  </Typography>
                }
              />
              <FormControlLabel
                value="Internship"
                control={<Radio size="small" color="primary" />}
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    Internship
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {jobtype === "Full Time" ? (
          ""
        ) : (
          <>
            <Grid item md={12} style={{ paddingTop: 0, paddingBottom: 5 }}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={payment_type}
                  onChange={handleChangePaid}
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Typography variant="body2" style={{ marginRight: "10px" }}>
                    Payment Type:
                  </Typography>
                  <FormControlLabel
                    value="PAID"
                    control={<Radio size="small" color="primary" />}
                    label={
                      <Typography style={{ fontSize: "14px" }}>Paid</Typography>
                    }
                  />
                  <FormControlLabel
                    value="UNPAID"
                    control={<Radio size="small" color="primary" />}
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        Unpaid
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="NOT DISCLOSED"
                    control={<Radio size="small" color="primary" />}
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        Not Disclosed
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {paid === "PAID" && (
              <>
                <Grid item md={6} style={{ display: "grid" }}>
                  <TextField
                    label="Stipend"
                    value={stipend || ""}
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const validNumberRegex = /^[0-9]*\.?[0-9]*$/;

                      if (validNumberRegex.test(inputValue)) {
                        setOffCampusFormData({
                          ...offCampusFormData,
                          stipend: event.target.value,
                        });
                        setOffCampusFormErrorData({
                          ...offCampusFormErrorData,
                          stipend_error: null,
                        });
                      }
                    }}
                    error={offCampusFormErrorData.stipend_error ? true : false}
                    helperText={
                      <span>{offCampusFormErrorData.stipend_error}</span>
                    }
                  />
                </Grid>
                <Grid item md={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      style={{ flexDirection: "row", alignItems: "center" }}
                      value={isPpo}
                      onChange={handleChangeIsPpo}
                    >
                      <Typography
                        variant="body2"
                        style={{ marginRight: "10px" }}
                      >
                        Pre Placement offer (PPO):
                      </Typography>
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" color="primary" />}
                        label={
                          <Typography style={{ fontSize: "14px" }}>
                            Yes
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" color="primary" />}
                        label={
                          <Typography style={{ fontSize: "14px" }}>
                            No
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {isPpo === "yes" && (
                  <>
                    <Grid item md={6} style={{ display: "grid" }}>
                      <TextField
                        label="CTC(Inr)"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        value={ppo_ctc_max || ""}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          const validNumberRegex = /^[0-9]*\.?[0-9]*$/;

                          if (validNumberRegex.test(inputValue)) {
                            setOffCampusFormData({
                              ...offCampusFormData,
                              ppo_ctc_max: event.target.value,
                              ppo_ctc_min: event.target.value,
                            });
                            setOffCampusFormErrorData({
                              ...offCampusFormErrorData,
                              ppo_ctc_error: null,
                            });
                          }
                        }}
                        error={
                          offCampusFormErrorData.ppo_ctc_error ? true : false
                        }
                        helperText={
                          <span>{offCampusFormErrorData.ppo_ctc_error}</span>
                        }
                      />
                    </Grid>
                    <Grid item md={6} />
                  </>
                )}
              </>
            )}
          </>
        )}
        {jobtype === "Full Time" && (
          <>
            <Grid item md={6} style={{ display: "grid", paddingTop: 0 }}>
              <TextField
                label="CTC(Inr)"
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={full_ctc_max || ""}
                error={offCampusFormErrorData.full_ctc_error ? true : false}
                helperText={
                  <span>{offCampusFormErrorData.full_ctc_error}</span>
                }
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const validNumberRegex = /^[0-9]*\.?[0-9]*$/;

                  if (validNumberRegex.test(inputValue)) {
                    setOffCampusFormData({
                      ...offCampusFormData,
                      full_ctc_max: event.target.value,
                      full_ctc_min: event.target.value,
                    });
                    setOffCampusFormErrorData({
                      ...offCampusFormErrorData,
                      full_ctc_error: null,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item md={6} />
          </>
        )}

        <Grid item md={6}>
          <Calendar
            title="Hiring Date"
            selectedDate={hiring_date}
            setSelectedDate={(date) => {
              setOffCampusFormData({
                ...offCampusFormData,
                hiring_date: moment(date).format("YYYY-MM-DD"),
              });
              setOffCampusFormErrorData({
                ...offCampusFormErrorData,
                hiring_date_error: null,
              });
            }}
            // minDate={new Date()}
            readOnly={true}
            errorMessage={offCampusFormErrorData.hiring_date_error}
          />
        </Grid>
        <Grid item md={6}>
          <Calendar
            title="Joining Date"
            selectedDate={joining_date}
            setSelectedDate={(date) => {
              setOffCampusFormData({
                ...offCampusFormData,
                joining_date: moment(date).format("YYYY-MM-DD"),
              });
            }}
            minDate={
              hiring_date &&
              new Date(hiring_date).toISOString().substring(0, 10)
            }
            readOnly={true}
            errorMessage={offCampusFormErrorData.joining_date_error}
          />
        </Grid>
        <Grid item md={12}>
          <Typography variant="body2" style={{ marginBottom: "15px" }}>
            Job Description
          </Typography>
          <Editor
            state={jobDesc}
            setState={setJobDesc}
            scrollUp={true}
            useStateAsInitialValue={true}
          />
        </Grid>
        {offCampusFormErrorData.job_description_error && (
          <Typography
            variant="h6"
            className={classes.plz}
            style={{ marginTop: "20px", marginLeft: "32px" }}
          >
            Please enter Job Description
          </Typography>
        )}
        <Grid item md={12} style={{ paddingBottom: 0 }}>
          <FormControl component="fieldset" style={{ marginTop: "20px" }}>
            <RadioGroup
              style={{ flexDirection: "row", alignItems: "center" }}
              value={job_location_type}
              onChange={(e) => {
                setOffCampusFormData({
                  ...offCampusFormData,
                  job_location_type: e.target.value,
                });
              }}
            >
              <Typography variant="body2" style={{ marginRight: "10px" }}>
                Job Location:
              </Typography>
              <FormControlLabel
                value="WFO"
                control={<Radio color="primary" size="small" />}
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    Work from office
                  </Typography>
                }
              />
              <FormControlLabel
                value="WFH"
                control={<Radio color="primary" size="small" />}
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    Work from home
                  </Typography>
                }
              />
              <FormControlLabel
                value="hybrid"
                control={<Radio color="primary" size="small" />}
                label={
                  <Typography style={{ fontSize: "14px" }}> Hybrid</Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {job_location_type !== "WFH" && (
          <Grid item md={12}>
            {/* <Autocomplete
              id="combo-box-demo"
              multiple
              options={cityList}
              disableCloseOnSelect
              // value={cityList.find((option) => option.city === city) || null}
              value={selectedLocations}
              // onChange={(e, newValue) => {
              //   if (newValue) {
              //     setOffCampusFormData({
              //       ...offCampusFormData,
              //       city_id: newValue.city_id,
              //       city: newValue.city,
              //     });
              //     setOffCampusFormErrorData({
              //       ...offCampusFormErrorData,
              //       city_error: null,
              //     });
              //   }
              // }}
              onChange={handleSelectedLocationsChange}
              limitTags={2}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.city}`}</span>;
              }}
              getOptionLabel={(option) => option.city}
              // renderOption={(option, { selected }) => (
              //   <React.Fragment>
              //     <Checkbox
              //       icon={icon}
              //       color="primary"
              //       checkedIcon={checkedIcon}
              //       style={{ marginRight: 8 }}
              //       checked={selected}
              //     />
              //     <Typography style={{ fontSize: "14px" }}>
              //       {" "}
              //       {option.city}{" "}
              //     </Typography>
              //   </React.Fragment>
              // )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.city}
                    size="small"
                    color="primary"
                    {...getTagProps({ index })}
                    className={classes.chip}
                    deleteIcon={<CloseIcon className={classes.text} />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={offCampusFormErrorData.city_error ? true : false}
                  helperText={<span>{offCampusFormErrorData.city_error}</span>}
                />
              )}
            /> */}
            <MuiThemeProvider theme={Theme}>
              <Autocomplete
                filterOptions={filterOptions}
                multiple
                limitTags={1}
                id="combo-box-demo"
                value={selectedLocations}
                onChange={handleSelectedLocationsChange}
                options={cityList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.city}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.city}
                      size="small"
                      color="primary"
                      {...getTagProps({ index })}
                      className={classes.chip}
                      deleteIcon={<CloseIcon className={classes.text} />}
                    />
                  ))
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      color="primary"
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <Typography style={{ fontSize: "14px" }}>
                      {" "}
                      {option.city}{" "}
                    </Typography>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    error={offCampusFormErrorData.city_error ? true : false}
                    helperText={
                      <span>{offCampusFormErrorData.city_error}</span>
                    }
                  />
                )}
              />
            </MuiThemeProvider>
          </Grid>
        )}
        <Grid item md={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseWithClear}
              style={{ boxShadow: "none", marginRight: "10px", width: "150px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none", width: "150px" }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {false ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : (
                <>Add Job</>
              )}{" "}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddNewJob;
