export function generateCsv(arr, name) {
  let str = "";
  arr.forEach((item, _) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ", ")
      : (str += item[name])
  );
  return str;
}

export function generateCsvNew(arr) {
  let str = "";

  if (arr?.length > 0) {
    arr.forEach((item, _) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item + ", ")
        : (str += item)
    );
  }
  return str;
}
export function generateCsvNewWithoutSpace(arr) {
  let str = "";

  if (arr?.length > 0) {
    arr.forEach((item, _) =>
      arr.indexOf(item) !== arr.length - 1 ? (str += item + ",") : (str += item)
    );
  }
  return str;
}

export function generateCsvWithoutSpacing(arr, name) {
  let str = "";
  arr.forEach((item, _) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ",")
      : (str += item[name])
  );
  return str;
}

export function generateCsvWithoutSpacingAndName(arr, name) {
  let str = "";
  arr.forEach((item, _) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ",")
      : (str += item[name])
  );
  return str;
}

export function generateFileNameSpaceRemoved(filename) {
  const splittedFilename = filename.split(" ");
  var formattedFilename = [];
  splittedFilename.forEach((item) => {
    formattedFilename.push(item.trim().toLowerCase());
  });
  return formattedFilename.join("_");
}

export function generateCsvForParams(arr) {
  let str = "";

  if (arr?.length > 0) {
    arr.forEach((item, _) =>
      arr.indexOf(item) !== arr.length - 1 ? (str += item + ",") : (str += item)
    );
  }
  return str;
}

export const capitalizeFirstLetter = (str) => {
  let string = str?.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};
