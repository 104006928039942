import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { UserRolesGetAPI } from "../../api/AccessControls/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";
import {
  JobListDropdownAPI,
  SingleTeamsGetAPI,
} from "../../api/TeamManage/action";
import AssignedJobProfiles from "../../components/Cards/TeamMember/AssignedJobProfiles";
import AssignedPlacementCycles from "../../components/Cards/TeamMember/AssignedPlacementCycles";
import Contact from "../../components/Cards/TeamMember/Contact";
import CustomCircularProgress from "../../components/CircularProgressBar";
import PlacementHead from "../../components/Input/PlacementHead";
import NoteListTable from "../../components/Table/EditHistoryTable/NoteListTable";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";
import { groupViewCheck } from "../../utility/groupViewCheck";

const useStyles = makeStyles((_) => ({
  fixedPosition: {
    position: "fixed",
    left: "71%",
    maxHeight: 630,
    overflowY: "scroll",
    width: "28%",
  },
  "@media only screen and (min-width: 1700px)": {
    fixedPosition: {
      position: "fixed",
      left: "71%",
      maxHeight: 900,
      overflowY: "scroll",
      width: "28%",
    },
  },
  // "@media only screen and (min-width: 1500px)": {
  //   fixedPosition: {
  //     position: "fixed",
  //     left: "71%",
  //     maxHeight: 750,
  //     overflowY: "scroll",
  //     width: "28%",
  //   },
  // },
}));
function handleClick(event) {
  event.preventDefault();
}

export default function TeamMemberProfile() {
  const params = useParams();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingUserRoles, setLoadingUserRoles] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const dispatch = useDispatch();
  useEffect(() => {
    // if (collegeId) {
    dispatch(
      UserRolesGetAPI(setLoadingUserRoles, alert, history, location, collegeId)
    );
    dispatch(JobListDropdownAPI(collegeId));
    // }
  }, [params?.team_member_id, collegeId]);

  useEffect(() => {
    dispatch(
      SingleTeamsGetAPI(
        setLoading,
        alert,
        history,
        location,
        params?.team_member_id
      )
    );
  }, []);

  const teamMember = useSelector((state) => state?.TeamData?.teamMember);
  const [placementCycle, setPlacementCycle] = useState([]);
  const [placementError, setPlacementError] = useState(null);

  useEffect(() => {
    setPlacementCycle(teamMember?.placement_cycle_list);
  }, [teamMember?.placement_cycle_list?.length]);

  const userRoleList = useSelector((state) => state.AllUserRoles.details);
  const jobsList = useSelector((state) => state.TeamData.listJobs);

  return (
    <>
      {/* <Layout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to={checkForBreadcrumb(
                isEducationGroup,
                isCollegeGroup,
                collegeId,
                "manage-team"
              )}
              style={{ textDecoration: "none" }}
            >
              <Typography color="primary" variant="body2">
                Manage Team
              </Typography>
            </Link>
            <Typography color="secondary" variant="body2">
              {teamMember?.name}
            </Typography>
          </Breadcrumbs>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Contact
                data={teamMember}
                params={params}
                collegeId={collegeId}
              />
              <br />
              {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
                <PlacementHead
                  data={teamMember}
                  params={params}
                  collegeId={collegeId}
                  userRoleList={userRoleList}
                />
              )}
              {/* <br />
                <BasicInfo /> */}
              <br />
              {/* <Status /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
                <>
                  <AssignedPlacementCycles
                    data={teamMember}
                    params={params}
                    collegeId={collegeId}
                    placementCycle={placementCycle}
                    placementError={placementError}
                    setPlacementCycle={setPlacementCycle}
                    setPlacementError={setPlacementError}
                  />
                  <br />
                  <AssignedJobProfiles
                    data={teamMember}
                    params={params}
                    collegeId={collegeId}
                    jobsList={jobsList}
                  />
                </>
              )}
            </Grid>
            {teamMember?.notes_list && teamMember?.notes_list?.length > 0 && (
              <Grid item xs={12}>
                <Divider style={{ marginBottom: 20 }} />
                <div
                  style={{
                    padding: 20,
                    marginTop: "20px",
                    margin: "auto",
                    backgroundColor: "#fff",
                    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
                    borderRadius: "8px",
                  }}
                >
                  <Accordion
                    style={{
                      boxShadow: "none",
                    }}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                    // classes={{
                    //   root: classes.test,
                    // }}
                  >
                    <AccordionSummary
                      style={{ padding: "0px" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5">Action History</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "0px" }}>
                      <div style={{ width: "100%" }}>
                        <NoteListTable data={teamMember?.notes_list} />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
