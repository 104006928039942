import { Button, Container, Divider, Grid, Typography } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { userLoggedInSelectorFn } from "../../../api/Domain/selector";
import {
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  entityInfoSelectorFn
} from "../../../api/SignIn/selector";
import { UniversityPublicGet } from "../../../api/University/APIs/action";
import CollegeImage from "../../../assets/img/company.jpg";
import defaultCompany from "../../../assets/img/default.jpg";
import Facebook from "../../../assets/png/facebook.png";
import Insta from "../../../assets/png/insta.png";
import Linkedin from "../../../assets/png/linkedin.png";
import UplacementCycle from "../../../components/Cards/UniversityGraph/UPlcementCycle";
import FormFooter from "../../../components/Common/FormFooter";
import StudentFormNavbar from "../../../components/Common/StudentFormNavbar";
import { checkForUrl } from "../../../utility/checkForUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "50px",
  },
  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    height: "270px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    marginLeft: "12px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  imagedata: {
    display: "flex",
    width: 150,
    backgroundColor: "#fff",
    height: 80,
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  imgdata: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",

    borderRadius:8,
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  social: {
    margin: theme.spacing(3.5),
    marginTop: "40px",
  },
  socialicon: {
    marginLeft: "20px",
    cursor: "pointer",
  },
}));

function UniversityPublicProfile() {
  const classes = useStyles();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const entityInfo = useSelector(entityInfoSelectorFn);

  useEffect(() => {
    if (params?.group_id)
      dispatch(UniversityPublicGet(setLoading, alert, history, location, params?.group_id));
  }, [params?.group_id]);
  const data = useSelector((state) => state?.EDGCommon?.edgProfile);

  const Data = [
    {
      icon: <LanguageIcon color="secondary" />,
      info: data?.website,
      show: data?.website ? true : false,
    },
    {
      icon: <ApartmentIcon color="secondary" />,
      info: data?.city,
      show: data?.city ? true : false,
    },
    // {
    //   icon: <GolfCourseIcon color="secondary" />,
    //   info: "Private",
    // },
    {
      icon: <PeopleAltOutlinedIcon color="secondary" />,
      info: `${data?.total_affiliated_college} Affiliated ${entityInfo?.college ? entityInfo?.college : "Center"}s`,
      show: true,
    },

    {
      icon: <FlagOutlinedIcon color="secondary" />,
      info: `Founded in ${moment(data?.year_founded).format("yyyy")}`,
      show: data?.year_founded ? true : false,
    },
  ];

  const isLoggedIn = useSelector(userLoggedInSelectorFn);

  const [link, setLink] = useState({
    facebook: "",
    insta: "",
    linkedin: "",
  });

  useEffect(() => {
    setLink({
      ...link,
      facebook: data?.social_links[1].value,
      insta: data?.social_links[2].value,
      linkedin: data?.social_links[0].value,
    });
  }, [data]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeId = useSelector(collegeIdFn);
  return (
    <>
      <div style={{ backgroundColor: "#f8f9fa" }}>
        <StudentFormNavbar />
        {data?.banner && (
          <div
            style={{
              backgroundImage: `url(${data?.banner ? data?.banner : CollegeImage})`,
              backgroundRepeat: "no-repeat",
              height: "270px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        )}
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{ marginTop: "-30px" }}>
            <Grid item>
              <div className={classes.imagedata}>
                <img
                  className={classes.imgdata}
                  alt="complex"
                  src={data?.logo ? data?.logo : defaultCompany}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid
                item
                xs
                container
                direction="column"
                spacing={2}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div className={classes.title}>
                  <Typography variant="h3">{data?.group_name}</Typography>
                </div>
                <div className={classes.social}>
                  {link?.facebook && (
                    <img
                      src={Facebook}
                      height="30px"
                      className={classes.socialicon}
                      width="30px"
                      onClick={() => window.open(checkForUrl(link?.facebook), "_blank")}
                    />
                  )}
                  {link?.linkedin && (
                    <img
                      src={Linkedin}
                      height="30px"
                      width="30px"
                      className={classes.socialicon}
                      onClick={() => window.open(checkForUrl(link?.linkedin), "_blank")}
                    />
                  )}
                  {link?.insta && (
                    <img
                      src={Insta}
                      height="30px"
                      width="30px"
                      className={classes.socialicon}
                      onClick={() => window.open(checkForUrl(link?.insta), "_blank")}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <div>
            <Typography variant="h6">Overview</Typography>
            <Grid container spacing={3} style={{ marginTop: "10px", marginBottom: "10px" }}>
              {Data.map(
                (item) =>
                  item?.show && (
                    <Grid item xs={12} sm={4}>
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>{item.icon}</Grid>
                        <Grid item xs>
                          <Typography variant="body2" color="secondary">
                            {item.info}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid>
            {data?.about && (
              <Grid item xs={12}>
                <Typography variant="h6">About us</Typography>
                <br />
                <Typography variant="body2">{data?.about}</Typography>
              </Grid>
            )}
            <br />
            {data?.college_list?.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6">
                  Affiliated ${entityInfo?.college ? entityInfo?.college : "Center"}s Partnered with GetWork ({data?.college_list?.length})
                </Typography>
                <div>
                  <Grid container spacing={3} style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {data?.college_list?.map((item) => (
                      <Grid item xs={4}>
                        <UplacementCycle
                          item={item}
                          countTitle={"Registered Students"}
                          count={item?.registered_student}
                          link={`/public/college/profile/${item?.id}`}
                          linkTitle={`View ${entityInfo?.college ? entityInfo?.college : "Center"} Info`}
                          from={"PublicProfile"}
                        />{" "}
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            )}

            <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <div>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>
                    Address
                  </Typography>
                  <br />
                  <Typography variant="body4" color="secondary">
                    {data?.address}
                  </Typography>
                  <br />
                  {/* <br />
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Contact No. 0987654321
                  </Typography> */}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <iframe
                  src={`https://maps.google.com/maps?q=${data?.latitude},${data?.longitude}&output=embed`}
                  width="700"
                  height="350"
                  style={{ borderRadius: "8px", border: "none" }}
                  //  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
            {isLoggedIn && !isCollegeGroup && (
              <div style={{ paddingBottom: "20px" }}>
                {/* <Link to="/educational-group/profile-settings">
                  <Button variant="contained" color="primary" className={classes.btn}>
                    Edit Profile
                  </Button>
                </Link> */}
                {/* <Button className={classes.btn} variant="outlined" color="primary">
                Back
              </Button> */}
              </div>
            )}
          </div>
        </Container>
        <FormFooter />
      </div>
    </>
  );
}

export default UniversityPublicProfile;
