import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { ReactComponent as Vactor } from "../../../assets/svg/payment-proccessing.svg";
import { Typography, useTheme } from "@mui/material";

const btn = {
  borderRadius: "8px",
  boxShadow: "none",
  height: "40px",
  fontSize: "15px",
  mt: 1,
  mb: 1,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentProcessing({
  open,
  handleClickOpen,
  handleClose,
}) {
  const helper = () => {
    handleClose();
  };

  const theme = useTheme();

  return (
    <div style={{ zIndex: "100" }}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "8px",
            backgroundColor: "#ffff",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <Vactor height="280px" fill={theme.palette.primary.main} />
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", mt: 2, mb: 1 }}
          >
            Please Wait...
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "400",
              mt: 1,
              color: "#6C757D",
              pl: 8,
              pr: 8,
            }}
          >
            Your payment is currently being processed.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "400",
              mb: 5,
              color: "#6C757D",
              pl: 8,
              pr: 8,
            }}
          >
            Do not refresh or close your browser.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
