import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { collegeIdFn } from "../../api/SignIn/selector";
import { BillingDetails } from "../../api/Billing/action";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",

    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    margin: "10px 10px 0px 0px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  column: {
    marginTop: 5,
    marginBottom: 5,
  },
}));

export default function Billing() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const alert = useAlert();
  const collegeId = useSelector(collegeIdFn);

  useEffect(() => {
    if (collegeId)
      dispatch(BillingDetails(alert, history, location, collegeId, () => {}));
  }, [collegeId]);

  const billing = useSelector((state) => state?.billingData?.billing);
  return (
    <>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div>
            <Typography variant="h2">Billing</Typography>
            <Typography variant="body2" color="secondary">
              View your License Details
            </Typography>
          </div>
          {/* <Button color="primary" variant="contained" size="small" className={classes.button} startIcon={<GetAppIcon />}>
            Download Invoice
          </Button> */}
        </div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Plan Details</Typography>
            <hr style={{ border: "0.5px solid #ddd", width: "100%" }} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className={classes.column}>
                  <Typography variant="body2">Plan Start Date</Typography>
                  <Typography variant="body2" color="secondary">
                    {billing?.start_date}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.column}>
                  <Typography variant="body2">Plan End Date</Typography>
                  <Typography variant="body2" color="secondary">
                    {billing?.end_date}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.column}>
                  <Typography variant="body2">Estimated yearly bill</Typography>
                  <Typography variant="body2" color="secondary">
                    ₹{billing?.amount} INR
                    <br />
                    Annual Plan
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6" style={{ marginBottom: 5 }}>
              Total Licenses: {billing?.license_purchased}{" "}
            </Typography>
            <div style={{ marginBottom: 5 }}>
              <Typography variant="h6" style={{ marginBottom: 5 }}>
                Registered Users
              </Typography>
              <Typography variant="body2" color="primary">
                {billing?.license_used}/{billing?.license_purchased}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  (billing?.license_used / billing?.license_purchased) * 100
                }
                color="primary"
                style={{ borderRadius: "8px" }}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
