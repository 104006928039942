import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
import github from "../../../../assets/company/social/github.png";
import dri from "../../../../assets/company/social/dribbble.png";
import behance from "../../../../assets/company/social/behance.png";
import Linkedin from "../../../../assets/company/social/linkedin.png";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import { style } from "@mui/system";
import { Divider, IconButton } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import WcIcon from "@mui/icons-material/Wc";
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import HomeIcon from "@mui/icons-material/Home";
import MuiCustomTheme from "../../../NewMUITheme/MuiCustomTheme";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";

import { useLocation } from "react-router-dom";
import ProfileApproveDialog from "../../EditStudent/ProfileApproveDialog/ProfileApproveDialog";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
  GroupAddOutlined,
  LocalActivityOutlined,
  LocationCity,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    //maxWidth: 500,
  },
}));

export default function Contact({
  data,
  fromPublicTrack,
  validatorData,
  userId,
  StudentDetailsAPI,
  fromProfileUpdate,
}) {
  console.log("data11", data);
  const classes = useStyles();
  const alert = useAlert();
  const Data = [
    {
      icon: <PhoneIcon color="primary" />,
      info: data?.phone,
      show: data?.phone
        ? fromPublicTrack
          ? validatorData?.action_performed?.action?.length > 0 &&
            validatorData?.action_performed?.action?.includes("phone")
            ? true
            : false
          : true
        : false,
      type: "Phone Number",
      title: "Personal :",
      verified: data?.phone_verified,
    },
    {
      icon: <PhoneIcon color="primary" />,
      info: data?.guardian_contact,
      show: fromPublicTrack ? false : true,
      type: "Phone Number",
      title: "Guardian :",
    },
    {
      icon: <MailIcon color="primary" />,
      info: data?.email,
      show: data?.email
        ? fromPublicTrack
          ? validatorData?.action_performed?.action?.length > 0 &&
            validatorData?.action_performed?.action?.includes("mail")
            ? true
            : false
          : true
        : false,
      type: "Mail Id",
      verified: data?.email_verified,
    },

    {
      icon: <LanguageIcon color="primary" />,
      info: "www.roshankapoor.in",
      show: data?.website ? true : false,
    },
    {
      icon: <LocationOnIcon color="primary" />,
      info: data?.current_city_name,
      show: data?.current_city_name ? true : false,
    },
  ];

  const social = [
    {
      icon: github,
    },
    {
      icon: dri,
    },

    {
      icon: behance,
    },

    {
      icon: Linkedin,
    },
  ];

  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //console.log(data?.student_profile_update?.includes("BASIC"));
  return (
    <>
      <br />
      <div className={classes.paper}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Contact and Portfolio</Typography>{" "}
          <InfoOutlinedIcon style={{ marginLeft: "10px", fontSize: "18px" }} />
        </div>
        <br />
        {Data.map(
          (item) =>
            item.show &&
            item.info && (
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item>{item.icon}</Grid>
                {item.title && (
                  <Grid item style={{ paddingRight: 0 }}>
                    <Typography variant="body2" color="secondary">
                      {item.title}
                    </Typography>
                  </Grid>
                )}

                <CopyToClipboard
                  text={item.info}
                  onCopy={() => alert.success(` ${item.type} Copied!`)}
                  style={{ cursor: "pointer" }}
                >
                  <Grid item xs>
                    <Typography variant="body2" color="secondary">
                      {item.info}
                    </Typography>
                  </Grid>
                </CopyToClipboard>

                {item.verified && (
                  <Grid item style={{ paddingRight: 0 }}>
                    <VerifiedIcon
                      color="primary"
                      style={{ fontSize: "medium" }}
                    />
                  </Grid>
                )}
                <CopyToClipboard
                  text={item.info}
                  onCopy={() => alert.success(` ${item.type} Copied!`)}
                >
                  <IconButton onClick={null} style={{ marginRight: "10px" }}>
                    <FileCopyOutlinedIcon color="primary" />
                  </IconButton>
                </CopyToClipboard>
              </Grid>
            )
        )}

        {data?.student_user_details?.gender ? (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <MuiCustomTheme>
                <WcIcon color="primary" />
              </MuiCustomTheme>
            </Grid>
            <Grid item xs style={{ display: "flex" }}>
              <Typography
                variant="body2"
                color="secondary"
                style={{ marginRight: "5px" }}
              >
                Gender :
              </Typography>
              <Typography variant="body2" color="secondary">
                {data?.student_user_details?.gender?.charAt(0)?.toUpperCase() +
                  data?.student_user_details?.gender?.slice(1)}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {data?.current_city_name ? (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <MuiCustomTheme>
                <LocationCity color="primary" />
              </MuiCustomTheme>
            </Grid>
            <Grid item xs style={{ display: "flex" }}>
              <Typography
                variant="body2"
                color="secondary"
                style={{ marginRight: "5px" }}
              >
                Current Location :
              </Typography>
              <Typography variant="body2" color="secondary">
                {data?.current_city_name?.charAt(0)?.toUpperCase() +
                  data?.current_city_name?.slice(1)}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {data?.dob ? (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <MuiCustomTheme>
                <CalendarTodayTwoToneIcon color="primary" />
              </MuiCustomTheme>
            </Grid>

            <Grid item xs style={{ display: "flex" }}>
              <Typography
                variant="body2"
                color="secondary"
                style={{ marginRight: "5px" }}
              >
                DOB :
              </Typography>
              <Typography variant="body2" color="secondary">
                {moment(data?.dob).format("D MMM, YYYY")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {data?.current_address ? (
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>
              <MuiCustomTheme>
                <HomeIcon color="primary" />
              </MuiCustomTheme>
            </Grid>

            <Grid item xs style={{ display: "flex" }}>
              <Typography
                variant="body2"
                color="secondary"
                style={{ marginRight: "5px" }}
              >
                Current Address :
              </Typography>
              <Typography variant="body2" color="secondary">
                {data?.current_address}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {data?.permanent_address ? (
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>
              <MuiCustomTheme>
                <HomeIcon color="primary" />
              </MuiCustomTheme>
            </Grid>

            <Grid item xs style={{ display: "flex" }}>
              <Typography
                variant="body2"
                color="secondary"
                style={{ marginRight: "5px" }}
              >
                Permanent Address :
              </Typography>
              <Typography variant="body2" color="secondary">
                {data?.permanent_address}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {data?.marital_status ? (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <MuiCustomTheme>
                <GroupAddOutlined color="primary" />
              </MuiCustomTheme>
            </Grid>

            <Grid item xs style={{ display: "flex" }}>
              <Typography
                variant="body2"
                color="secondary"
                style={{ marginRight: "5px" }}
              >
                Marital Status :
              </Typography>
              <Typography variant="body2" color="secondary">
                {data?.marital_status}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {/* <div style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}> */}
        {/* {social.map((item) => (
          item.show &&
          <img src={item.icon} height="30px" width="30px" alt="socialmedia" style={{ marginRight: "30px", cursor: "pointer" }} />
        ))} */}
        {/* {data?.profile_url_app?.length > 0 &&
          data?.profile_url_app?.map((item) => (
            // <Button>
            <a href={item.value} target="_blank">
              <img
                src={item.logo}
                height="30px"
                width="30px"
                alt="socialmedia"
                style={{
                  marginRight: "30px",
                  cursor: "pointer",
                  borderRadius: 8,
                }}
              />
            </a>
            // </Button>
          ))}
      </div> */}
        {data?.student_user_details?.about && (
          <>
            <Typography variant="h6">About Me</Typography>
            <br />
            <Typography variant="body2" color="secondary">
              {data?.student_user_details?.about}
            </Typography>
          </>
        )}
        {/* <div style={{ display: "flex" }}></div> */}

        {fromProfileUpdate &&
        data?.student_profile_update &&
        Object.keys(data?.student_profile_update)?.includes("BASIC") ? (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  backgroundColor: "green ",
                  padding: "10px",
                  width: "160px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",

                  cursor: "pointer",
                }}
                onClick={handleClickOpen}
              >
                <Typography
                  style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
                >
                  Pending Request
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <ProfileApproveDialog
          title="Contact and Portfolio Request"
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          // formType="approveLanguages"
          data={data}
          userId={userId}
          type={"BASIC"}
          StudentDetailsAPI={StudentDetailsAPI}
        />
      </div>
    </>
  );
}
