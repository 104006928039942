// export default class PieChart extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [44, 55, 13, 43, 22],
//       options: {
//         labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
//       },
//     };
//   }
//   render() {
//     return (
//       <Paper
//         style={{
//           boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
//           borderRadius: "20px",
//           padding: "30px",
//         }}
//       >
//         <Typography
//           variant="h6"
//           style={{ textAlign: "center", marginBottom: "30px" }}
//         >
//           Highlighted Skills of Students
//         </Typography>

//         <Charst
//           options={this.state.options}
//           series={this.state.series}
//           type="pie"
//           width={400}
//         />
//         {/* <div style={{ display: "flex", justifyContent: "center" }}>
//           <div
//             style={{
//               padding: "20px",
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//             }}
//           >
//             <div
//               style={{
//                 border: "1px solid #e2e6ea",
//                 display: "flex",
//                 flexDirection: "row",
//                 borderRadius: "20px",
//                 width: "120px",
//                 height: "40px",
//                 color: "#8a94a6",
//                 justifyContent: "space-evenly",
//                 alignItems: "center",
//               }}
//             >
//               <div
//                 style={{
//                   backgroundColor: "#377dff",
//                   height: "10px",
//                   width: "10px",
//                   borderRadius: "50%",
//                 }}
//               />
//               <Typography variant="overline">Python</Typography>
//             </div>
//           </div>
//           <div
//             style={{
//               padding: "20px",
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//             }}
//           >
//             <div
//               style={{
//                 //   padding: "20px",
//                 display: "flex",
//                 flexDirection: "row",
//                 borderRadius: "20px",
//                 width: "100px",
//                 border: "1px solid #e2e6ea",
//                 height: "40px",
//                 color: "#8a94a6",
//                 justifyContent: "space-evenly",
//                 alignItems: "center",
//               }}
//             >
//               <div
//                 style={{
//                   backgroundColor: "#38cb89",
//                   height: "10px",
//                   width: "10px",
//                   borderRadius: "50%",
//                 }}
//               />
//               <Typography variant="overline">Java</Typography>
//             </div>
//           </div>
//         </div> */}
//       </Paper>
//     );
//   }
// }

import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";
import Course from "../../Course";

export default function PieChart({ placement }) {
  return (
    <div
      style={{
        boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
        borderRadius: "8px",
        padding: "30px",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginBottom: "10px" }}
      >
        Highlighted Skills of Students
      </Typography>
      {placement?.labels.length >= 1 ? (
        <Charst
          options={{
            labels: placement?.labels ? placement?.labels : [],
            dataLabels: {
              // enabled: true,
              // textAnchor: "start",
              style: {
                fontFamily: "Nunito",
                fontWeight: "normal",
                boxShadow: "none",
                fontSize: "11px",
              },
            },
            colors: [
              "#c4161c",
              "#67c3d0",
              "#ff8a47",
              "#e97b86",
              "#377dff",
              "#e0a800",
              "#ccccff",
              "#00ffff",
              "#8f9966",
              "#66ff33",
            ],
            stroke: {
              show: true,
              curve: "smooth",
              width: 1,
            },

            legend: {
              show: true,
              showForSingleSeries: false,
              showForNullSeries: true,
              showForZeroSeries: true,
              position: "bottom",
              horizontalAlign: "center",
              floating: false,
              fontSize: "11px",
              fontFamily: "Nunito",
              fontWeight: 400,
              formatter: undefined,
              inverseOrder: false,
              width: undefined,
              height: undefined,
              tooltipHoverFormatter: undefined,
              customLegendItems: [],
              offsetX: 0,
              offsetY: 0,
              labels: {
                colors: undefined,
                useSeriesColors: false,
              },
            },
          }}
          series={placement?.data ? placement?.data : []}
          type="pie"
          //width={auto}
          //height={400}
          height="100%"

          //  width="auto"
          //width="100%"
        />
      ) : null}
    </div>
  );
}
