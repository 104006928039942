import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircleOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Editor from "../../Editor";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20px",
    height: "auto",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    padding: "15px",
  },
  leftpaper: {
    height: "auto",
    boxShadow: "none",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  rightpaper: {
    height: "auto",
    boxShadow: "none",
    // padding: "15px",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  helper: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //padding: theme.spacing(1, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  include: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingTop: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textfield: {
    padding: "16px",
  },
  DropzoneArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  heading: {
    padding: "20px",
    paddingLeft: "60px",
  },
}));

export default function CompanyUpload({ localFieldList, setLocalFieldList, userRolesPermission }) {
  const classes = useStyles();
  const [degree, setDegree] = React.useState("");
  const handleChange = (event) => {
    setDegree(event.target.value);
  };
  const [editor, setEditor] = useState();
  const fieldList = useSelector((state) => state?.CompanyUploader?.details);
  const selectedFields = useSelector((state) => state?.CompanyFields?.details);

  useEffect(() => {
    if (fieldList?.length > 0) {
      var arr = [...fieldList];
      arr?.forEach((element) => {
        element["is_selected"] = checkIfExists(element);
      });
      setLocalFieldList(arr);
    }
  }, [selectedFields]);
  const checkIfExists = (i) => {
    var bool_ = false;
    if (selectedFields && selectedFields?.company_form_fields?.length > 0) {
      selectedFields?.company_form_fields?.map((item) => {
        if (item.id === i.id || i.pre_selected) bool_ = true;
      });
    } else {
      if (i.pre_selected) bool_ = true;
    }
    return bool_;
  };

  function getFieldContent(step, item) {
    switch (step) {
      case "textfield":
        return (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{ className: classes.textfield }}
            label={item.field_name}
            disabled={item.is_selected}
          />
        );
      case "linkedin":
        return (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{ className: classes.textfield }}
            label={item.field_name}
            disabled={item.is_selected}
          />
        );
      case "glassdoor":
        return (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{ className: classes.textfield }}
            label={item.field_name}
            disabled={item.is_selected}
          />
        );
      case "industry":
        return (
          <FormControl variant="outlined" className={classes.formControl} disabled={item.is_selected}>
            <InputLabel required id="demo-simple-select-outlined-label" className={classes.floatingLabelFocusStyle}>
              {item.field_name}
            </InputLabel>
            <Select
              id="demo-simple-select-outlined"
              value={degree}
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
              onChange={handleChange}
              label={item.field_name}
            >
              <MenuItem value={10}>IT Industry</MenuItem>
              <MenuItem value={20}>Communications</MenuItem>
            </Select>
          </FormControl>
        );
      case "industry_type":
        return (
          <FormControl variant="outlined" className={classes.formControl} disabled={item.is_selected}>
            <InputLabel required id="demo-simple-select-outlined-label" className={classes.floatingLabelFocusStyle}>
              {item.field_name}
            </InputLabel>
            <Select
              id="demo-simple-select-outlined"
              value={degree}
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
              onChange={handleChange}
              label={item.field_name}
            >
              <MenuItem value={10}>Private</MenuItem>
              <MenuItem value={20}>Government</MenuItem>
            </Select>
          </FormControl>
        );
      case "autocomplete":
        return (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel required id="demo-simple-select-outlined-label" className={classes.floatingLabelFocusStyle}>
              {item.field_name}{" "}
            </InputLabel>
            <Select
              id="demo-simple-select-outlined"
              value={degree}
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
              onChange={handleChange}
              label={item.field_name}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Btech</MenuItem>
              <MenuItem value={20}>BCA</MenuItem>
              <MenuItem value={30}>MCA</MenuItem>
            </Select>
          </FormControl>
        );
      case "company_location":
        return (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel required id="demo-simple-select-outlined-label" className={classes.floatingLabelFocusStyle}>
              {item.field_name}{" "}
            </InputLabel>
            <Select
              id="demo-simple-select-outlined"
              value={degree}
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
              onChange={handleChange}
              label={item.field_name}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Btech</MenuItem>
              <MenuItem value={20}>BCA</MenuItem>
              <MenuItem value={30}>MCA</MenuItem>
            </Select>
          </FormControl>
        );
      case "logo":
        return (
          <div style={{ width: "300px", marginTop: "10px" }}>
            <Typography variant="h6" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              {item.field_name}
            </Typography>
            <DropzoneAreaBase
              dropzoneClass={classes.DropzoneArea}
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an Image here"}
              onChange={(files) => console.log("")}
              onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
              dropzoneProps={{ disabled: item.is_selected }}
            />
            <Button variant="contained" color="primary" className={classes.upload}>
              {item.field_name}
            </Button>
          </div>
        );

      case "banner":
        return (
          <div style={{ width: "100%", marginTop: "10px" }}>
            <Typography variant="h6" style={{ paddingBottom: "10px" }}>
              {item.field_name}
            </Typography>
            <DropzoneAreaBase
              dropzoneClass={classes.DropzoneArea}
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an Image here"}
              onChange={(files) => console.log("Files:", files)}
              onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
              dropzoneProps={{ disabled: item.is_selected }}
            />
            <Button variant="contained" color="primary" className={classes.upload}>
              {item.field_name}
            </Button>
          </div>
        );
      case "description":
        return (
          <>
            <Typography style={{ paddingBottom: "10px", paddingTop: "25px" }} variant="h6">
              Description
            </Typography>
            <Editor state={editor} setState={setEditor} />
            <br />
            <br />
          </>
        );

      case "POC":
        return (
          <>
            <Typography style={{ paddingBottom: "0px", paddingTop: "25px" }} variant="h6">
              POC Details
            </Typography>
            <Grid item xs={9}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                inputProps={{ className: classes.textfield }}
                label="Contact Person Name"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                inputProps={{ className: classes.textfield }}
                label="Phone No."
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                inputProps={{ className: classes.textfield }}
                label="Email"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                inputProps={{ className: classes.textfield }}
                label="Designation"
              />
            </Grid>
            <Button color="primary" startIcon={<AddCircleOutlined />} style={{ display: "flex" }}>
              Add Alternate POC details
            </Button>
          </>
        );

      default:
        return "Unknown step";
    }
  }

  const handleSelectionChange = (e, item, index) => {
    var arr = [...localFieldList];
    item.is_selected = e.target.checked;
    if (index !== -1) {
      arr[index] = item;
      setLocalFieldList(arr);
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.heading}>
          Company Data Uploader
        </Typography>
        <Grid container>
          {localFieldList?.length > 0 &&
            localFieldList?.map((item, index) => (
              <>
                <Grid item xs={3} className={classes.grid}>
                  <Paper className={classes.leftpaper}>
                    {" "}
                    <FormControlLabel
                      value="end"
                      control={<Checkbox size="small" color="primary" checked={item?.is_selected} onChange={(e) => handleSelectionChange(e, item, index)} />}
                      label={
                        <Typography variant="body2" style={{ fontWeight: "bold" }}>
                          {" "}
                          Include in Form
                        </Typography>
                      }
                      labelPlacement="end"
                      disabled={userRolesPermission?.some((item) => item.access_control_key === "manage_forms") === true && !item?.pre_selected ? false : true}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={9}>
                  <div className={clsx(classes.column, classes.helper)}>
                    <Paper className={classes.rightpaper}>{getFieldContent(item.field_type, item)}</Paper>
                  </div>
                </Grid>
              </>
            ))}
        </Grid>
      </Paper>
    </>
  );
}
