import { Grid, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback } from "react";
import { useAlert } from "react-alert";
import "./drop.css";

import { useDropzone } from "react-dropzone";
import CustomCircularProgress from "../../CircularProgressBar";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    marginTop: "10px",
    borderRadius: "8px",
    //margin: theme.spacing(2),
    boxShadow: "none",
    fontSize: "14px",
    padding: "7px",
    height: "45px",
    display: "flex",
  },
  newBtn: {
    borderRadius: "8px",
    boxShadow: "none",
    display: "flex",
    height: "40px",
    backgroundColor: theme.palette.primary,
    "&:hover": {
      backgroundColor: theme.palette.primary,
    },
    color: "white",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

const UploadStudentDoc = ({
  handleClickOpen,
  handleClose,
  open,
  fileNames,
  setFileNames,

  getDocumentTypeList,
  disableUpload,

  setDataLoading,
  documentTypeList,
  setUploadDocData,
  uploadDocData,
  fileUploading,
  UploadFile,
}) => {
  const classes = useStyles();
  const alert = useAlert();

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const validateData = () => {
    if (!uploadDocData?.title) {
      alert.error("Title can't be empty");
      return false;
    } else if (!uploadDocData?.type) {
      alert.error("Document type can't be empty");
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    var new_file;
    var formData = new FormData();
    if (validateData()) {
      formData.append("file_type", uploadDocData?.type?.sub_category_id);
      formData.append("file_name", uploadDocData?.title);
      if (fileNames?.length === 1) {
        new_file = await dataURLtoFile(
          uploadDocData?.fileToUpload,
          fileNames[0]?.name
        );
        formData.append("file", new_file);
      }
      UploadFile(formData);
    }
  };

  const UploadDropZone = ({
    uploadDocData,
    setUploadDocData,
    setFileNames,
  }) => {
    const convertToBinary = (file) => {
      var reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var binary = reader.result;

          setUploadDocData({
            ...uploadDocData,
            fileToUpload: binary,
          });
        };
        reader.onerror = (error) => {
          alert.error(error);
        };
      }
    };

    const onDrop = useCallback((acceptedFiles, rejectFiles) => {
      var files = acceptedFiles.map((file) => file);
      if (acceptedFiles?.length > 1) alert.error("Please Select just one file");
      else {
        alert.success("File Selected");
        setFileNames(acceptedFiles?.map((file) => file));
        convertToBinary(files[0]);
      }
    }, []);

    const { getInputProps, getRootProps } = useDropzone({
      onDrop,
      acceptedFiles: ".pdf",
    });
    return (
      <div>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <PresentToAllIcon style={{ fontSize: "40px" }} />
          <Typography
            color="primary"
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              fontWeight: "normal",
              fontSize: "18px",
            }}
          >
            Drag and drop
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disabled={disableUpload}
        style={{
          height: "40px",
          marginLeft: "5px",
          paddingLeft: "20px",
          paddingRight: "20px",
          width: "200px",
        }}
        startIcon={<FileUploadIcon />}
        className={classes.newBtn}
        onClick={() => {
          getDocumentTypeList();
          handleClickOpen();
        }}
      >
        Upload
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {setDataLoading ? (
          <CustomCircularProgress />
        ) : (
          <div style={{ padding: "40px" }}>
            <Typography variant="h4">Upload Documents</Typography>

            <div>
              <Autocomplete
                id="country-select-demo"
                options={documentTypeList}
                getOptionLabel={(doc) => doc?.sub_category_name}
                renderOption={(option) => {
                  return (
                    <span
                      style={{ fontSize: 13 }}
                    >{`${option.sub_category_name}`}</span>
                  );
                }}
                value={uploadDocData?.type}
                onChange={(e, newValue) => {
                  setUploadDocData({
                    ...uploadDocData,
                    type: newValue,
                  });
                }}
                className={classes.formControl}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Document Type"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                  />
                )}
              />
              <TextField
                variant="outlined"
                required
                margin="normal"
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                style={{ margin: "25px 0" }}
                fullWidth
                label="Document Title"
                value={uploadDocData.title}
                onChange={(e) => {
                  setUploadDocData({
                    ...uploadDocData,
                    title: e.target.value,
                  });
                }}
              />
            </div>

            <br />

            {/* dropzone component was auto refreshing and making the other states empty */}
            <UploadDropZone
              uploadDocData={uploadDocData}
              setUploadDocData={setUploadDocData}
              setFileNames={setFileNames}
            />

            <Typography variant="body2" color="secondary">
              {" "}
              {fileNames?.length === 0 ? (
                <>Accepted files : image/*, pdf</>
              ) : (
                "Selected File: " + fileNames[0]?.name
              )}
            </Typography>

            <Grid container spacing={3} style={{ display: "flex" }}>
              <Grid
                item
                xs={6}
                className={classes.grid}
                style={{ display: "grid" }}
              >
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleClose}
                  color="primary"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.grid}
                style={{ display: "grid" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  //size="small"
                  className={classes.button}
                  disabled={fileNames?.length > 0 ? false : true}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {fileUploading ? (
                    <CustomButtonCircularProgress />
                  ) : (
                    "Upload File"
                  )}
                </Button>
              </Grid>
            </Grid>
            {/*  </div> */}
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default UploadStudentDoc;
