import { Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  collegeIdFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import {
  CallCollegeAPI,
  CallCollegeInternalAPI,
} from "../../../api/University/APIs/action";
import { allCollegeSelectedSelectorFn } from "../../../api/University/APIs/selector";
import CollegeCardList from "./CollegeCardList";
import CustomButtonCircularProgress from "../../../components/CircularProgressBar/index2";
import { GroupViewCheck } from "../../../api/Common/action";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "15px",
    height: "48px",
    paddingLeft: "30px",
    marginRight: "20px",
    paddingRight: "30px",
    display: "flex",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    // borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function CollegeListDialog({
  open,
  handleClickOpen,
  handleClose,
  collegeList,
  handleCloseOutSide,
}) {
  const classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const allCollegeSelected = useSelector(allCollegeSelectedSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const handleClickNew = (id) => {
    dispatch(
      CallCollegeAPI(
        id,
        alert,
        history,
        location,
        user_details,
        location.pathname,
        false,
        setLoader,
        setApiSuccess
      )
    );
  };
  const handleSubmit = (id) => {
    dispatch(
      CallCollegeInternalAPI(
        id,
        alert,
        history,
        location,
        user_details,
        location.pathname,
        setLoader,
        setApiSuccess
      )
    );
  };

  useEffect(() => {
    if (apiSuccess) {
      dispatch(GroupViewCheck(true));
      handleClose();
    }
    setApiSuccess(false);
  }, [apiSuccess]);

  return (
    <div>
      {/* <Button color="primary" size="small" className={classes.button} onClick={handleClickOpen}>
        Create Custom Role
      </Button> */}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleCloseOutSide}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <Grid container spacing={3} style={{ padding: "40px" }}>
            <Grid item xs={12}>
              <div className={classes.paper}>
                <Typography variant="h5">
                  {`Select ${entityInfo?.college} to View Info`}
                </Typography>
                <br />
                {collegeList?.map((item, index) => {
                  return (
                    <a
                      selected={item?.id === collegeId ? true : false}
                      onClick={() => {
                        if (
                          !location.pathname.includes("college-group") &&
                          !location.pathname.includes("educational-group") &&
                          !location.pathname.includes("dashboard")
                        ) {
                          handleClickNew(item?.id);
                        } else handleSubmit(item?.id);
                      }}
                    >
                      <CollegeCardList
                        name={item.name}
                        logo={item.logo}
                        city={item.city}
                        count={item.student_count}
                      />
                      {/* {loader ? "setting" : <> Submit</>}{" "} */}
                    </a>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
