import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import AcceptStudents from "../../DialogSection/AcceptStudents";
import AddStudentPlacement from "../../DialogSection/AddCompanyPlacement/AddStudentPlacement";
import ApplyJobProfile from "../../DialogSection/AddJobPlacementCycle/ApplyJobProfile";
import Block from "../../DialogSection/Block/BlockStudent";
import FilterScore from "../FilterScore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { alpha, styled } from "@mui/material/styles";
import MuiCustomTheme from "../../../components/NewMUITheme/MuiCustomTheme";
import AddStudentPlacementCycle from "../../DialogSection/AddJobPlacementCycle/AddStudentPlacementCycle";

const NewFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  //height: "49px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  "& label.Mui-focused": {
    //  color: "#C4C4C4",
  },
  "& label": {
    color: "#c5c4ba",
  },
  "& .MuiInput-underline:after": {
    //borderBottomColor: "#C4C4C4",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&:hover fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&.Mui-focused fieldset": {
      //borderColor: "#C4C4C4",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  button2: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    padding: "5px",
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
    // color: "#6c757d",
    "& ::-webkit-input-placeholder": {
      color: "#000 !important",
    },
  },

  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function AllStudentActionBar({
  filters,
  setFilters,
  setClickSearchButton,
  selectedRowsData,
  clickSearchButton,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  filterList,
  studentList,
  setApplyClickedScore,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  checkSelectedGroup,
  checkForPendingRejected,
  filterListPlacementCycle,
}) {
  const alert = useAlert();
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [searchQuery, setSearchQuery] = useState("");

  const handleCheckChange = (event) => {
    setFilters({ ...filters, status_active: event.target.checked });
  };
  const handleCheckChange2 = (event) => {
    setFilters({ ...filters, status_blocked: event.target.checked });
  };

  const classes = useStyles();

  const handleChange = (event) => {
    const name = event.target.name;

    setFilters({
      ...filters,
      [name]: event.target.value,
    });
  };

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search By Name"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              {filters?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      search: "",
                    });
                    setSearchQuery("");
                    setClickSearchButton(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setFilters({
                    ...filters,
                    search: searchQuery,
                  });
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {filterListPlacementCycle &&
            Object.keys(filterListPlacementCycle)?.length > 0 &&
            Object.keys(filterListPlacementCycle).map((item) =>
              filterListPlacementCycle[item].type === "autocomplete" ? (
                <Grid item xs={2}>
                  <Autocomplete
                    id="country-select-demo"
                    options={filterListPlacementCycle[item].data}
                    getOptionLabel={(option) => String(option.name)}
                    value={filters[filterListPlacementCycle[item].key]}
                    renderOption={(option) => (
                      <Typography style={{ fontSize: "14px" }}>
                        {option.name}
                      </Typography>
                    )}
                    onChange={(e, newValue) => {
                      setFilters({
                        ...filters,
                        [filterListPlacementCycle[item].key]: newValue,
                      });
                    }}
                    style={{ background: "#fff", borderRadius:8 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={filterListPlacementCycle[item]?.heading}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <FilterScore
                    scoreData={filters}
                    setScoreData={setFilters}
                    setApplyClickedScore={setApplyClickedScore}
                  />
                </Grid>
              )
            )}

          {/*  {!checkForPendingRejected() && (
            <Grid item xs={2} className={classes.grid}>
              <MuiCustomTheme>
                <NewFormControl
                  variant="outlined"
                  //className={classes.formControl}
                >
                  <InputLabel
                    //className={classes.floatingLabelFocusStyle}
                    style={{
                      borderRadius: "30px",
                      fontSize: "14px",
                      fontFamily: "Nunito",
                    }}
                  >
                    Status
                  </InputLabel>
                  <Select
                    // IconComponent={ExpandMoreIcon}
                    // value={filtersCompany.status}
                    //  onChange={handleChange}
                    label="Status"
                    name="status"
                    style={{
                      borderRadius: "30px",
                      fontSize: "14px",
                      fontFamily: "Nunito",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: 300, borderRadius: "10px" },
                      },
                    }}
                    // inputProps={{
                    //   style: {
                    //     padding: "16px",
                    //     backgroundColor: "#fff",
                    //     borderRadius: "30px",
                    //   },
                    // }}
                    // MenuProps={{
                    //   style: {
                    //     marginTop: "55px",
                    //   },
                    // }}
                  >
                    <MenuItem value={"ALL"}>
                      <Typography variant="body2"> All </Typography>
                    </MenuItem>
                    <MenuItem value={"ACTIVE"}>
                      <Typography variant="body2"> Active </Typography>
                    </MenuItem>
                    <MenuItem value={"INACTIVE"}>
                      <Typography variant="body2"> Blocked </Typography>
                    </MenuItem>
                  </Select>
                </NewFormControl>
              </MuiCustomTheme>
            </Grid>
          )} */}
        </Grid>
      </div>
    </>
  );
}
