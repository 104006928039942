import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { userLoggedInSelectorFn } from "../../api/Domain/selector";
import { ResetNewPasswordAPI } from "../../api/ForgotPassword/action";
import CollegeImage from "../../assets/img/company.jpg";
import CreateNewPasswordForm from "../../components/Input/NewPasswordForm/CreateNewPasswordForm";
import LeftSideComponent from "../../components/Public/LeftSideComponent";
import RightSideComponent from "../../components/Public/RightSideComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // marginTop: "50px",
  },
  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default function CreateNewPassword() {
  const isLoggedIn = useSelector(userLoggedInSelectorFn);
  const history = useHistory();
  const classes = useStyles();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  useEffect(() => {
    if (isLoggedIn) {
      history.push("/overview");
    }
  }, []);

  useEffect(() => {
    if (token) dispatch(ResetNewPasswordAPI(alert, setLoading, history, token));
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      {/* {loading ? (
        <CustomCircularProgress />
      ) : (
        <> */}
      <LeftSideComponent />
      <RightSideComponent ChildComponent={CreateNewPasswordForm} token={token}/>
      {/* </>
      )} */}
    </Grid>
  );
}
