import { Skeleton } from '@mui/material'
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    topSkeleton: {
        marginLeft: 26,
        width: "80%",
        marginTop: '20px',
    },
    middleSkeleton: {
        width: "80%",
    },
    bottomSkeleton: {
        marginLeft: 26,
        borderRadius: 8,
        height: "40px",
        width: "90%",

    },
    skeletonCard: {
        borderRadius: '8px',
        paddingTop: 10,
        paddingBottom: 10,
        height: '450px',
        display: "flex",
        flexDirection: 'column',
        backgroundColor: 'white',
        justifyContent: 'center',

    }

}))



const JobCardSkeleton = () => {
    const classes = useStyles();


    return (
        <section
            className={classes.skeletonCard}
        >
            <Skeleton className={classes.topSkeleton}
                style={{
                    height: 60,
                    borderRadius: 8,
                    marginTop: '20px',


                }}
            />
            <Skeleton className={classes.topSkeleton}
                animation="wave"
                style={{
                    height: 60,
                    borderRadius: 8,
                    marginTop: '20px',


                }}
            />
            <Skeleton className={classes.bottomSkeleton}
                animation={false}
                style={{
                    height: 60,
                    borderRadius: 8,
                    marginTop: '20px',

                }}
            />

        </section>
    )
}

export default JobCardSkeleton