import { Button, ListItemText, Menu, MenuItem } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userRolesActionsFn } from "../../../api/SignIn/selector";
import { changeUserRoleAPI } from "../../../api/TeamManage/action";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root: {
    //   padding: "0px 4px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    width: "80%",
    paddingLeft: "15px",
    height: "40px",
    borderRadius: "8px",
    border: ".5px solid #e2e6ea",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    backgroundColor: "",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    height: "40px",
    boxShadow: "none",
    width: 200,
  },
}));

const StyledMenu = withStyles({
  paper: {
    //border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "1px",
    display: "flex",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

export default function PlacementHead({ data, params, collegeId, userRoleList }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loader, setLoader] = useState(false);
  const [tempUserRoleList, setTempUserRoleList] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const alert = useAlert();
  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeUserRole = (id, name) => {
    var data_ = {
      college_id: [collegeId],
      user_role_id: id,
      object_id: params?.team_member_id,
    };
    var rcvObj = {
      id: id,
      name: name,
    };
    dispatch(changeUserRoleAPI(data_, alert, data, setLoader, rcvObj));
    handleClose();
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  const classes = useStyles();

  useEffect(() => {
    if (userRoleList?.length > 0) {
      var arr = [...userRoleList];
      var new_arr = arr.filter((i) => i.id !== data?.user_role_id);
      setTempUserRoleList(new_arr);
    }
  }, [userRoleList?.length]);

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={data?.user_role_name}
        style={{ fontSize: "14px" }}
        disabled
        inputProps={{
          style: { color: "black" },
        }}
      />
      {userRolesPermission?.some((item) => item.access_control_key === "manage_user_role") && (
        <Button variant="contained" onClick={handleClick} color="primary" className={classes.button} endIcon={<KeyboardArrowDownIcon />}>
          {loader ? <CustomButtonCircularProgress /> : "Change User Role"}
        </Button>
      )}

      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {tempUserRoleList?.length > 0 &&
          tempUserRoleList?.map((item) => (
            <StyledMenuItem
              onClick={() => {
                handleChangeUserRole(item?.id, item?.user_role_name);
              }}
            >
              <ListItemText primary={item?.user_role_name} />
            </StyledMenuItem>
          ))}
      </StyledMenu>
    </Paper>
  );
}
