import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { checkForBreadcrumb } from "../../../utility/checkBreadcrumbs";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import AcceptStudents from "../../DialogSection/AcceptStudents";
import AddStudentPlacement from "../../DialogSection/AddCompanyPlacement/AddStudentPlacement";
import ApplyJobProfile from "../../DialogSection/AddJobPlacementCycle/ApplyJobProfile";
import Block from "../../DialogSection/Block/BlockStudent";
import FilterScore from "../FilterScore";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  button2: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    padding: "5px",
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
    // color: "#6c757d",
    "& ::-webkit-input-placeholder": {
      color: "#000 !important",
    },
  },

  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function UpdateProfileActionBar({
  filters,
  setFilters,
  setClickSearchButton,
  selectedRowsData,

  filterList,

  fromStudentDetials = false,
  filteringDisabled = false,
  filtersSelectedbyUser,
}) {
  const alert = useAlert();
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const history = useHistory();

  const handleCheckChange = (event) => {
    setFilters({ ...filters, status_active: event.target.checked });
  };
  const handleCheckChange2 = (event) => {
    setFilters({ ...filters, status_blocked: event.target.checked });
  };

  const classes = useStyles();

  let disableCgpa = false;

  if (
    fromStudentDetials ? filtersSelectedbyUser["cgpa"]?.data?.length > 0 : null
  ) {
    disableCgpa = true;
  }

  // console.log("filters applied", filters);
  // console.log("Inside student actionbar selectedFilters", filtersSelectedbyUser);
  useEffect(() => {
    console.log("selectedRowData ID", selectedRowsData);
  }, [selectedRowsData]);
  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search By Name"
                value={filters.search}
                disabled={filteringDisabled}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setClickSearchButton(true);
                  }
                }}
              />
              {filters?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      search: "",
                    });
                    setClickSearchButton(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {filterList &&
            Object.keys(filterList)?.length > 0 &&
            Object.keys(filterList).map(
              (item) =>
                filterList[item].type === "autocomplete" && (
                  <Grid item xs={2}>
                    <Autocomplete
                      id="country-select-demo"
                      options={filterList[item].data}
                      // disabled={filteringDisabled}
                      disabled={
                        fromStudentDetials
                          ? filteringDisabled
                            ? true
                            : filtersSelectedbyUser[item]?.data?.length > 0
                            ? true
                            : false
                          : false
                      }
                      getOptionLabel={(option) => String(option.name)}
                      value={filters[filterList[item].key]}
                      renderOption={(option) => (
                        <Typography style={{ fontSize: "14px" }}>
                          {option.name}
                        </Typography>
                      )}
                      onChange={(e, newValue) => {
                        setFilters({
                          ...filters,
                          [filterList[item].key]: newValue,
                        });
                      }}
                      style={{ background: "#fff", borderRadius: 8 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={filterList[item].heading}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                )
            )}
          {selectedRowsData?.length > 0 && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button2}
                disabled={selectedRowsData?.length > 1 ? true : false}
                onClick={() => {
                  if (!selectedRowsData[0].user_id) {
                    alert.error(
                      "Something wrong with this data, kindly connect with GetWork"
                    );
                  } else {
                    history.push({
                      pathname: `/student-profile-request/${selectedRowsData[0]?.name.replace(
                        / /g,
                        "_"
                      )}-${selectedRowsData[0]?.user_id}`,
                    });
                  }
                }}
              >
                Accept/Approve
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}
