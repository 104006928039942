import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CompanyFieldsPostAPI,
  CompanySelectedFieldsAPI,
  CompanyUploaderFieldsAPI,
} from "../../api/CompanyManagementForm/action";
import { collegeDetailSelectorFn, userRolesActionsFn } from "../../api/SignIn/selector";
import CompanyUpload from "../../components/Cards/CompanyUpload";
import CustomCircularProgress from "../../components/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    marginTop: "30px",
    marginBottom: "30px",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  circularProgressColored: {
    color: theme.palette.primary.main,
  },
}));

function CompanyUploader() {
  const classes = useStyles();
  const [localFieldList, setLocalFieldList] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSelected, setLoadingSelected] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const [mainLoading, setMainLoading] = useState(false);
  React.useEffect(() => {
    if (collegeDetails?.college_id) {
      getData();
    }
  }, [collegeDetails?.college_id]);

  function getData() {
    dispatch(CompanyUploaderFieldsAPI(setLoadingData, alert, history, location));
    dispatch(
      CompanySelectedFieldsAPI(
        setLoadingSelected,
        alert,
        history,
        location,
        collegeDetails?.college_id
      )
    );
  }

  useEffect(() => {
    if (loadingData && loadingSelected) setMainLoading(true);
    else setMainLoading(false);
  }, [loadingData, loadingSelected]);

  const handleSubmit = () => {
    var arr = [];
    localFieldList.map((item) => {
      if (item.is_selected) arr.push(item.id);
    });
    var data = {
      college_id: [collegeDetails?.college_id],
      company_form_fields: arr,
    };
    if (arr.length > 0)
      dispatch(
        CompanyFieldsPostAPI(data, setLoading, alert, history, location, collegeDetails?.college_id)
      );
    else alert.error("Please Select at least one Checkbox");
  };

  return (
    <>
      {/* <Layout> */}
      <Typography variant="h2">Company Data Management Form</Typography>
      <Typography
        variant="body2"
        color="secondary"
        // style={{ marginBottom: "7px" }}
      >
        Create Custom Data Set for Companies
      </Typography>
      {loadingSelected ? (
        <CustomCircularProgress />
      ) : (
        <>
          <CompanyUpload
            localFieldList={localFieldList}
            setLocalFieldList={setLocalFieldList}
            userRolesPermission={userRolesPermission}
          />
          {userRolesPermission?.some((item) => item.access_control_key === "manage_forms") && (
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "257px" }}
              className={classes.btn}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading ? (
                <CircularProgress className={classes.circularProgress} size={14} />
              ) : (
                "Update Form"
              )}
            </Button>
          )}
        </>
      )}
      {/* </Layout> */}
    </>
  );
}

export default CompanyUploader;
