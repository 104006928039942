import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { collegeDetailSelectorFn, userRolesActionsFn } from "../../../api/SignIn/selector";
import ShareApplicant from "../../DialogSection/ShareApplicant";
import TrackShareSuccessfully from "../../DialogSection/TrackShareSuccessfull";
import HoverItem from "./HoverItem";
import { generateFileNameSpaceRemoved } from "../../../utility/commaSeparatedStrings";
import GetEmailAndExportForSharer from "../../DialogSection/ShareApplicant/GetEmailAndExportForSharer";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  icons: {
    display: "flex",
    marginRight: "15px",
  },
  info_icon: {
    color: "#6c757d",
    height: "18px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontWeight: "bold",
  },
  export: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
}));

export default function TrackHeader({
  data,
  tempExportList,
  filters,
  fromPublicTrack,
  validatorData,
  handleExport,
  exportLoading,
  setExportLoading = () => {},
}) {
  const classes = useStyles();
  const [hover, setHover] = useState(true);
  const onHover = () => {
    setHover(!hover);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setExportLoading({ loading: false, success: false });
    setOpen(false);
  };
  const [shareSuccessData, setShareSuccessData] = useState({
    link: null,
    passcode: null,
  });
  const userRolesPermission = useSelector(userRolesActionsFn);

  const collegeDetail = useSelector(collegeDetailSelectorFn);

  const JobSource = {
    GETWORK_FULFILLED: "GetWork Fulfilled",
    INTERNAL: "Internal",
    GETWORK_INBOUND: "GetWork Inbound",
    EJNF: "EJNF",
  };
  const [openExportSharer, setOpenExportSharer] = React.useState(false);
  const handleClickOpenExportSharer = () => {
    setOpenExportSharer(true);
  };

  const handleCloseExportSharer = () => {
    setOpenExportSharer(false);
  };

  return (
    <>
      {/* <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={12} sm={7}> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: "-10px" }}>
          <Typography variant="h3">Track</Typography>
          <div
            style={{ display: "flex", marginTop: "10px", alignItems: "center" }}
          >
            <Link
              to={`/job/${data?.job_title.toLowerCase().replace(" ", "-")}-${
                filters?.job_id
              }`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "bold", marginRight: "5px" }}
              >
                {data?.job_title}
              </Typography>
            </Link>
            <Typography variant="body2" color="secondary">
              at
            </Typography>

            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold", marginLeft: "5px" }}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              role="button"
            >
              {data?.company_name}
            </Typography>

            {data?.job_source ? (
              <div
                style={{
                  backgroundColor: "#000",
                  borderRadius: "8px",
                  height: "24px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Typography style={{ fontSize: "14px", color: "#fff" }}>
                  {JobSource[data?.job_source]}
                </Typography>
              </div>
            ) : null}

            {/* <div
              style={{
                backgroundColor: "#000",
                borderRadius: "5px",
                height: "34px",
                paddingLeft: "10px",
                paddingRight: "10px",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px ",
              }}
            >
              <Typography style={{ fontSize: "14px", color: "#fff" }}>
                Getwork Fulfilled
              </Typography>
            </div> */}
          </div>
        </div>

        {hover ? "" : <HoverItem data={data} />}
        {/* <Grid container spacing={3}>
          <Grid
            item
            md={12}
            container
            className={classes.info}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <div style={{ marginTop: "15px", display: "flex" }}>
              <div className={classes.icons}>
                <DateRangeOutlinedIcon className={classes.info_icon} />
                <div>
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.name}
                  >
                    Job Posted Date
                  </Typography>
                  <Typography variant="overline" color="secondary">
                    24/09/21
                  </Typography>
                </div>
              </div>
              <div className={classes.icons}>
                <DateRangeOutlinedIcon className={classes.info_icon} />
                <div>
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.name}
                  >
                    Date of Visit
                  </Typography>
                  <Typography variant="overline" color="secondary">
                    29/09/21
                  </Typography>
                </div>
              </div>
              <div className={classes.icons}>
                <DateRangeOutlinedIcon className={classes.info_icon} />
                <div>
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.name}
                  >
                    {" "}
                    Current Status
                  </Typography>
                  <Typography variant="overline" color="secondary">
                    Open
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid> */}

        {/* </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={5}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >{" "} */}
        <div style={{ display: "flex" }}>
          {!fromPublicTrack && (
            <>
              {collegeDetail?.college_id &&  userRolesPermission?.some(
                (item) => item.access_control_key === "action_job_track"
              ) && (
                <div>
                  <Link
                    to={`/eligible-students/${data?.job_title}-${filters?.job_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddIcon />}
                      className={classes.button}
                    >
                      Add Student
                    </Button>
                  </Link>
                </div>
              )}
              {/* <AddMember /> */}
              {userRolesPermission?.some(
                (item) => item.access_control_key === "share_track"
              ) && (
                <ShareApplicant
                  data={data}
                  filters={filters}
                  handleOpenShareSuccess={handleClickOpen}
                  shareSuccessData={shareSuccessData}
                  setShareSuccessData={setShareSuccessData}
                  jobTitle={data?.job_title}
                />
              )}
              <TrackShareSuccessfully
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                data={shareSuccessData}
                jobTitle={data?.job_title}
              />
            </>
          )}
          {/* <Button
            variant="contained"
            //  color="primary"
            size="small"
            style={{ backgroundColor: "#e0a800", color: "#fff" }}
            className={classes.button}
            startIcon={<GetAppIcon />}
          >
            Export
          </Button> */}
          {/* {fromPublicTrack
            ? validatorData?.action_performed?.authority?.download === true && (
                <CSVLink
                  data={tempExportList}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  filename={generateFileNameSpaceRemoved(
                    `${data?.company_name}_${data?.job_title}_track.csv`
                  )}
                >
                  <Button
                    variant="contained"
                    size="small"
                    style={{ marginRight: "0px" }}
                    className={classes.export}
                    startIcon={<GetAppIcon />}
                  >
                    Export
                  </Button>
                </CSVLink>
              )
            : userRolesPermission?.some(
                (item) => item.access_control_key === "action_job_track"
              ) && (
                <CSVLink
                  data={tempExportList}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  filename={generateFileNameSpaceRemoved(
                    `${data?.company_name}_${data?.job_title}_track.csv`
                  )}
                >
                  <Button
                    variant="contained"
                    //  color="primary"
                    size="small"
                    style={{ marginRight: "0px" }}
                    className={classes.export}
                    startIcon={<GetAppIcon />}
                  >
                    Export
                  </Button>
                </CSVLink>
              )} */}
          <GetEmailAndExportForSharer
            open={openExportSharer}
            handleClose={handleCloseExportSharer}
            handleExport={handleExport}
            exportLoading={exportLoading}
          />
          <Button
            variant="contained"
            size="small"
            style={{ marginRight: "0px" }}
            className={classes.export}
            startIcon={<GetAppIcon />}
            onClick={
              fromPublicTrack ? handleClickOpenExportSharer : handleExport
            }
          >
            {!fromPublicTrack && exportLoading ? (
              <CircularProgress size={18} style={{ color: "#fff" }} />
            ) : (
              "Export"
            )}
          </Button>
        </div>

        {/* </Grid>
      </Grid> */}
      </div>
    </>
  );
}
