import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const QUERY_DATA = "QUERY_DATA";

export const ContactUs = (setLoader, data, history, location, alert, setFormSubmitted) => {
  return async (dispatch) => {
    const res = await axios.post(`${BackendBaseApi.SANCHALAN}api/settings/contact_us/`, data, { withCredentials: true }).catch((error) => {
      checkForToken(error, history, location, alert);
      alert.error("Some Error Occurred!!!");
    });
    if (res.data.success) {
      setFormSubmitted(true);
      setLoader(false);
      alert.success("Query summited successfully, GetWork team will connect with you shortly.");
    } else {
      setLoader(false);
      alert.error("Some Error Occurred!!!");
    }
    dispatch({
      type: QUERY_DATA,
      payload: res.data,
    });
  };
};
