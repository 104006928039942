import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function EditHistoryTable({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Update Type</StyledTableCell>

            <StyledTableCell align="center">Action</StyledTableCell>
            <StyledTableCell align="center">Update Status</StyledTableCell>
            <StyledTableCell align="center">Request On</StyledTableCell>

            <StyledTableCell align="center">Action Message</StyledTableCell>
            <StyledTableCell align="center">Action Date</StyledTableCell>
            <StyledTableCell align="center">Action By</StyledTableCell>
            <StyledTableCell align="center">Designation</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 &&
            data?.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                  component="th"
                  scope="row"
                >
                  {row.change_text}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row?.change_type}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row?.change_status}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row.request_time}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row?.change_info?.response_message ?? "NA"}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row?.response_time ?? "NA"}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row?.response_by_name ?? "NA"}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row?.change_info?.response_by_designation ?? "NA"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
