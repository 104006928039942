import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  Button,
  Typography,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import ErrorIcon from "@material-ui/icons/Error";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import { ReactComponent as DefaultVactor } from "../../../assets/svg/passcode-otp.svg";
import { themeSelectorFn } from "../../../api/Domain/selector";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
    padding: 20,
    paddingBottom: 10,
  },
  root: {
    padding: "20px",
    width: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    width: "40%",
    marginBottom: "10px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  // circle: {
  //   width: "100px",
  //   height: "100px",
  //   backgroundColor: "#28a745",
  //   borderRadius: "50%",
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   marginBottom: "30px",
  // },
}));

export default function TrackPasscode({
  trackPasscode,
  setTrackPasscode,
  checkPasscode,
  open,
  handleClickOpen,
  handleClose,
  fromStudentDetials = false,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const theme_change = useSelector(themeSelectorFn);
  const validateData = () => {
    var passcode = null;
    var is_valid = true;
    if (!trackPasscode?.track_passcode) {
      is_valid = false;
      passcode = "Please enter your passcode";
    }
    setTrackPasscode({ ...trackPasscode, passcode_error: passcode });
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      if (fromStudentDetials) checkPasscode();
      else checkPasscode(setLoading);
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      {/* <Button color="primary" onClick={handleClickOpen}>
        Successfully
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.root}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <DefaultVactor
                  width="200"
                  height="200"
                  alt="img"
                  className={classes.img}
                  fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                />
              </div>
            </div>
            <div style={{ marginBottom: 5 }}>
              <Typography variant="body2 ">
                Enter the passcode associated with this job track link, and we
                will grant you access to the job tracker
              </Typography>
            </div>
            <Box style={{ padding: "15px 40px 20px 40px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={trackPasscode?.track_passcode}
                    onChange={(e) => {
                      setTrackPasscode({
                        ...trackPasscode,
                        track_passcode: e.target.value,
                        passcode_error: null,
                      });
                    }}
                    error={trackPasscode?.passcode_error ? true : false}
                    helperText={trackPasscode?.passcode_error}
                    placeholder="Passcode"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    style={{ boxShadow: "none", height: "45px" }}
                    variant="contained"
                    onClick={() => {
                      if (!loading) handleSubmit();
                    }}
                    color="primary"
                  >
                    {loading ? <CustomButtonCircularProgress /> : "Continue"}
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* <TextField
              variant="outlined"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="Passcode"
              inputProps={{
                style: {
                  padding: "16px",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                },
              }}
              value={trackPasscode?.track_passcode}
              onChange={(e) => {
                setTrackPasscode({
                  ...trackPasscode,
                  track_passcode: e.target.value,
                  passcode_error: null,
                });
              }}
              error={trackPasscode?.passcode_error ? true : false}
              helperText={trackPasscode?.passcode_error}
            /> */}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
