import React from "react";
import Charst from "react-apexcharts";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles, Typography } from "@material-ui/core";

function DashBoardGraphSkeleton() {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", bottom: "10px" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={118}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "6%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={158}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "12%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={70}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "18%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={50}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "24%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={90}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "30%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={140}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "36%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={118}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "42%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={70}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "48%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={178}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "54%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={108}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "60%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={88}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "66%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={158}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "72%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={58}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "78%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={200}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "84%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={38}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ position: "absolute", bottom: "10px", marginLeft: "90%" }}>
        <Skeleton
          variant="rect"
          width={15}
          height={88}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            marginLeft: 20,
          }}
        />
      </div>{" "}
      <div style={{ display: "flex", alignItems: "center", marginBottom: 30 }}>
        <Typography
          style={{ color: "#6C757D", fontSize: "14px", marginRight: 5 }}
        >
          25
        </Typography>
        <div
          style={{ backgroundColor: "#d2d2d2", height: "1px", width: "100%" }}
        />
      </div>{" "}
      <div style={{ display: "flex", alignItems: "center", marginBottom: 30 }}>
        <Typography
          style={{ color: "#6C757D", fontSize: "14px", marginRight: 5 }}
        >
          20
        </Typography>
        <div
          style={{ backgroundColor: "#d2d2d2", height: "1px", width: "100%" }}
        />
      </div>{" "}
      <div style={{ display: "flex", alignItems: "center", marginBottom: 30 }}>
        <Typography
          style={{ color: "#6C757D", fontSize: "14px", marginRight: 5 }}
        >
          15
        </Typography>
        <div
          style={{ backgroundColor: "#d2d2d2", height: "1px", width: "100%" }}
        />
      </div>{" "}
      <div style={{ display: "flex", alignItems: "center", marginBottom: 30 }}>
        <Typography
          style={{ color: "#6C757D", fontSize: "14px", marginRight: 5 }}
        >
          10
        </Typography>
        <div
          style={{ backgroundColor: "#d2d2d2", height: "1px", width: "100%" }}
        />
      </div>{" "}
      <div style={{ display: "flex", alignItems: "center", marginBottom: 30 }}>
        <Typography
          style={{ color: "#6C757D", fontSize: "14px", marginRight: 5 }}
        >
          5
        </Typography>
        <div
          style={{ backgroundColor: "#d2d2d2", height: "1px", width: "100%" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
          <Typography
            style={{ color: "#6C757D", fontSize: "14px", marginRight: 5 }}
          >
            0
          </Typography>
          <div
            style={{ backgroundColor: "#d2d2d2", height: "2px", width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}

export default DashBoardGraphSkeleton;
