import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import CollegeImage from "../../../assets/img/company.jpg";
import ForgotPassword from "../../Input/ForgotPassword";
import LeftSideComponent from "../../Public/LeftSideComponent";
import RightSideComponent from "../../Public/RightSideComponent";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    //   marginTop: "50px",
  },
  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default function Forgot() {
  const classes = useStyles();

  return (

    <>
      <Grid container component="main" className={classes.root}>
        {/* <Grid item xs={false} sm={4} md={6} className={classes.image} /> */}
        <LeftSideComponent />
        <RightSideComponent
            ChildComponent={ForgotPassword}
            >
        </RightSideComponent>
        {/* <Grid
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {" "}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={CompanyLogo} height="100px" width="100px" />
          </div>
          <ForgotPassword /> */}
          {/* <CheckRegister /> */}
       
      </Grid>
    </>
  );
}
