import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import AddTeamMemberTable from "../../Table/ChatTable/AddTeamMember";

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     display: "flex",
  //     "& > *": {
  //       margin: theme.spacing(1),
  //       width: theme.spacing(16),
  //       height: theme.spacing(16),
  //     },
  //   },
}));

export default function AddChatTeam({
  collegeData,
  selected_member,
  setMembers,
  setSelectedMemID,
  selectedMemID,
  memLoading,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper
        style={{
          borderRadius: "8px",
          width: "65%",
          padding: "20px",
          boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
        }}
      >
        <Typography variant="h6" style={{ padding: "20px" }}>
          Add Team Member
        </Typography>
        <AddTeamMemberTable
          collegeData={collegeData}
          selected_member={selected_member}
          setSelectedMemID={setSelectedMemID}
          selectedMemID={selectedMemID}
          setMembers={setMembers}
          memLoading={memLoading}
        />
      </Paper>
    </div>
  );
}
