import { Button } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import Theme from "../../../style/theme";
import { checkForUrl } from "../../../utility/checkForUrl";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.type === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const columns = [
  {
    field: "assignment_title",
    headerName: "Job Title",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          marginLeft: "65px",
          color: "white",
        }}
      >
        Assignment Title
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        {params.row.assignment_title}
      </span>
    ),
  },

  {
    field: "file",
    type: "button",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "white", marginLeft: "25px" }}>Download</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.file ? (
          <a href={params.row.file} download>
            <Button color="primary">Download</Button>
          </a>
        ) : (
          "NA"
        )}
      </span>
    ),
  },

  {
    field: "url",
    type: "button",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "white", marginLeft: "25px" }}>Link</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.url ? (
          <a href={checkForUrl(params.row.url)}>
            <Button color="primary">View</Button>
          </a>
        ) : (
          "NA"
        )}
      </span>
    ),
  },
];

const rows = [
  {
    id: 1,
    title: "Software Engineer",
    company: "Chaayos",
    jobtype: "Full-Time",
    ctc: "5-6 LPA",
    vacancies: 10,
    hired: 30,
    applied: 60,
    round: "Round 5",
    date: "12-12-2021",
  },
  {
    id: 2,
    title: "Software Engineer",
    company: "Chaayos",
    jobtype: "Full-Time",
    ctc: "5-6 LPA",
    vacancies: 10,
    hired: 30,
    applied: 60,
    round: "Round 5",
    date: "12-12-2021",
  },
];

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: 0,
      color:
        theme.palette.type === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.primary.main,
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-cell": {
        color: "#000",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      ...customCheckbox(theme),
    },
    "& .super-app-theme--header": {
      backgroundColor: Theme.palette.primary.main,
    },
  }),
  { defaultTheme }
);

export default function AssignmentDownloadTable({
  data,
  setSelectedAssignments,
}) {
  const classes = useStyles();
  return (
    <div style={{ height: 200, width: "98%" }} className={classes.root}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
        }}
        rows={data}
        columns={columns}
        pageSize={5}
        checkboxSelection
        hideFooterPagination
        disableSelectionOnClick
        onSelectionChange={(newSelection) => {
          setSelectedAssignments(newSelection.rows);
        }}
      />
    </div>
  );
}
