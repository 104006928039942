import {
  Button, CircularProgress, FormControl,
  FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ResetPasswordAPI } from "../../../api/ForgotPassword/action";
import Oval from "../../../assets/png/oval.png";
import { passwordRegex } from "../../../utility/regex";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    width: "60%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    borderRadius: "8px",
    margin: theme.spacing(3, 0, 2),
    boxShadow: "none",
  },
  paper2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
  },
  terms: {
    color: theme.palette.primary.main,
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function NewPasswordForm({ nextStep, setNextStep }) {
  const classes = useStyles();
  const [state, setState] = useState({ password: null, confPassword: null });
  const [errors, setErrors] = useState({ password: null, confPassword: null });
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [uid, setUid] = useState("");
  const [token, setToken] = useState("");
  React.useEffect(() => {
    const temp = location.pathname.split("/");
    setUid(temp[2]);
    setToken(temp[3]);
  }, []);
  const [values, setValues] = React.useState({
    password: "",

    showPassword: false,
    passwordConf: "",

    showPasswordConf: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPasswordConfirm = () => {
    setValues({ ...values, showPasswordConf: !values.showPasswordConf });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateData = () => {
    var password_error = null;
    var conf_password_error = null;
    var is_valid = true;
    if (!state.password) {
      password_error = "Password field cannot be left blank";
      is_valid = false;
    }
    if (state.password && !passwordRegex.test(state.password)) {
      password_error = "Please enter a valid Password 6 digits long";
      is_valid = false;
    }
    if (!state.confPassword) {
      conf_password_error = "Confirm Password field cannot be left blank";
      is_valid = false;
    }
    if (state.confPassword && state.password && state.confPassword !== state.password) {
      conf_password_error = "This field doesn't match with Password field";
      is_valid = false;
    }
    setErrors({ ...errors, confPassword: conf_password_error, password: password_error });
    return is_valid;
  };

  const submitData = () => {
    if (validateData()) {
      var data = {
        password: state.password,
        token: token,
        uid: uid,
      };
      dispatch(ResetPasswordAPI(data, alert, setLoading, setNextStep));
    }
  };

  return (
    <>
      <div className={classes.paper2}>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" color="inherit">
            Reset your Password
          </Typography>
          <br />

          <form className={classes.form} noValidate>
            <FormControl className={clsx(classes.margin, classes.textField, classes.form)} variant="outlined" error={errors.password ? true : false}>
              <InputLabel className={classes.floatingLabelFocusStyle}>New Password</InputLabel>
              <OutlinedInput
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={state.password}
                onChange={(e) => {
                  setState({ ...state, password: e.target.value });
                  setErrors({ ...errors, password: null });
                }}
                label="New Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText id="standard-weight-helper-text">{errors.password}</FormHelperText>
            </FormControl>
            <br />
            <br />
            <FormControl className={clsx(classes.margin, classes.textField, classes.form)} variant="outlined" error={errors.confPassword ? true : false}>
              <InputLabel className={classes.floatingLabelFocusStyle}>Confirm Password</InputLabel>
              <OutlinedInput
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                label="Confirm Password"
                id="outlined-adornment-password"
                type={values.showPasswordConf ? "text" : "password"}
                value={state.confPassword}
                onChange={(e) => {
                  setState({ ...state, confPassword: e.target.value });
                  setErrors({ ...errors, confPassword: null });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPasswordConfirm} onMouseDown={handleMouseDownPassword} edge="end">
                      {values.showPasswordConf ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText id="standard-weight-helper-text">{errors.confPassword}</FormHelperText>
            </FormControl>
            <Grid container>
              <Grid item xs>
                <br />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "baseline",
                  }}
                >
                  <div
                    style={{
                      display: "flex",

                      alignItems: "baseline",
                    }}
                  >
                    <img src={Oval} height="7px" width="7px" />
                    <div style={{ marginLeft: "10px" }}>
                      <Typography
                        variant="body2"
                        color="secondary"
                        //  style={{ marginTop: "10px" }}
                      >
                        Password must be at least 8 characters long and contain at least one uppercase, one lowercase and one digit.
                      </Typography>
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",

                      alignItems: "baseline",
                    }}
                  >
                    <img src={Oval} height="7px" width="7px" />
                    <div style={{ marginLeft: "10px" }}>
                      <Typography
                        variant="body2"
                        color="secondary"
                        //  style={{ marginTop: "10px" }}
                      >
                        Your new password must be different from previous used password.
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* <Link to="/reset-your-password" style={{ textDecoration: "none" }}> */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                if (!loading) submitData();
              }}
            >
              {loading ? <CircularProgress className={classes.circularProgress} size={14} /> : <>Reset Password</>}
            </Button>
            {/* </Link> */}
          </form>
        </div>
      </div>
    </>
  );
}
