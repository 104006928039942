import React from "react";
import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import BasicInfo from "./BasicInfo";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import TeamMember from "./TeamMember";
import StepConnector from "@material-ui/core/StepConnector";
import EventShare from "../../DialogSection/EventShare";
import Guest from "./Guest";

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}))(StepConnector);

const StyledStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-active": {
    fontWeight: "bold",
  },
  "& .MuiStepLabel-completed": {
    fontWeight: "bold",
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",

    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up((100 % +theme.spacing(2)) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: "8px",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    height: "48px",
    borderRadius: "8px",
    boxShadow: "none",
  },
}));

const steps = [
  "Event Info",
  "Payment",
  "Team Member",
  "Guests",
  "Review & Publish",
];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <BasicInfo />;
    case 1:
      return <PaymentForm />;
    case 2:
      return <TeamMember />;
    case 3:
      return <Guest />;
    case 4:
      return <Review />;
    default:
      throw new Error("Unknown step");
  }
}

export default function EventForm() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="left">
            Create Event
          </Typography>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            alternativeLabel
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StyledStepLabel>{label}</StyledStepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <EventShare />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}

                <div className={classes.buttons}>
                  <div>
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        color="primary"
                        variant="contained"
                        className={classes.button}
                      >
                        Back
                      </Button>
                    )}
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Publish"
                        : "Save & Continue"}
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{ marginLeft: "20px" }}
                      className={classes.button}
                    >
                      Save in Draft
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </>
  );
}
