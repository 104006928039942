import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ProTricks from "../../components/Common/HomeSection/ProTricks";
import Offer from "../../components/Common/HomeSection/Offer";
import { praveshAxiosGetReq } from "../../api/BaseApi/apiBase";
import { useAlert } from "react-alert";
import SuccessStory from "../../components/Common/HomeSection/SuccessStory";
import TrendingMeetUp from "../../components/Common/HomeSection/TrendingMeetUp";
import AllProgram from "../../components/Common/HomeSection/AllProgram";
import InstaSection from "../../components/Common/HomeSection/InstaSection";
import LinkedinSection from "../../components/Common/HomeSection/LinkedinSection";
import FloatingWhatsAppButton from "../../components/Common/HomeSection/WhatsAppButton";
import { Skeleton } from "@material-ui/lab";

function Home() {
  const alert = useAlert();
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [tipsData, setTipsData] = useState();
  const [succesData, setSuccessData] = useState();
  const [bannerFirstData, setBannerFirstData] = useState();
  const [bannerSecondData, setBannerSecondData] = useState();
  const [trendingData, setTrendingData] = useState();
  const [programData, setProgramData] = useState();
  const [squareBannerData, setSquareBannerData] = useState();

  const getAllTips = async () => {
    const url = `api/shared/banner/?${`page_name=superapp_home_pro_tips_tricks`}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setTipsData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };

  const getAllProgram = async () => {
    const url = `api/shared/banner/?${`page_name=superapp_home_hot_faculty_development_program`}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setProgramData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };

  const getAllSquareBanner = async () => {
    const url = `api/shared/banner/?${`page_name=superapp_home_square_banner`}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setSquareBannerData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };
  const getAllTrending = async () => {
    const url = `api/shared/banner/?${`page_name=superapp_home_trending_meetups`}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setTrendingData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };

  const getBannerFirst = async () => {
    const url = `api/shared/banner/?${`page_name=superapp_home_rectangle_banner_1`}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setBannerFirstData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };

  const getBannerSecond = async () => {
    const url = `api/shared/banner/?${`page_name=superapp_home_rectangle_banner_2`}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setBannerSecondData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };

  const getAllSuccess = async () => {
    const url = `api/shared/banner/?${`page_name=superapp_home_success_stories`}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setSuccessData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };

  const getAllBanner = async () => {
    const url = `api/shared/banner/?${`page_name=company-payment`}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(true);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setLoading(true);
    }
  };

  useEffect(() => {
    getAllTips();
    getAllBanner();
    getAllSuccess();
    getBannerFirst();
    getBannerSecond();
    getAllTrending();
    getAllProgram();
    getAllSquareBanner();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Grid container spacing={2}>
        <Grid item md={9}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <ProTricks tipsData={tipsData} loading={loading} />
            </Grid>
            <Grid item md={7}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  {loading ? (
                    <>
                      {bannerFirstData?.map((item, i) => (
                        <Link to={item?.redirection_url}>
                          <div
                            style={{
                              height: 232,
                              borderRadius: 10,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={item?.banner_path}
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: 10,
                              }}
                              alt="getwork-banner"
                            />
                          </div>
                        </Link>
                      ))}
                    </>
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{
                        height: 232,
                        borderRadius: 10,
                      }}
                    />
                  )}
                </Grid>
                <Grid item md={12}>
                  {loading ? (
                    <>
                      {bannerSecondData?.map((item, i) => (
                        <Link to={item?.redirection_url}>
                          {" "}
                          <div
                            style={{
                              height: 232,
                              borderRadius: 10,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={item?.banner_path}
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: 10,
                              }}
                              alt="getwork-banner"
                            />
                          </div>
                        </Link>
                      ))}
                    </>
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{
                        height: 232,
                        borderRadius: 10,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <div>
                    <Offer
                      squareBannerData={squareBannerData}
                      loading={loading}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <TrendingMeetUp trendingData={trendingData} loading={loading} />
            </Grid>
            <Grid item md={12}>
              <AllProgram programData={programData} loading={loading} />
            </Grid>
            <Grid item md={12}>
              <Typography variant="h4" style={{ marginTop: 15 }}>
                💫 Our{" "}
                <span
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(243, 199, 41, 0.45) 0%, rgba(250, 250, 250, 0.45) 100%)",
                  }}
                >
                  Social Media
                </span>
              </Typography>
            </Grid>
            <Grid item md={6}>
              <InstaSection />
            </Grid>
            <Grid item md={6}>
              <LinkedinSection />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} style={{ position: "relative" }}>
          <SuccessStory succesData={succesData} loading={loading} />
        </Grid>
      </Grid>
      <FloatingWhatsAppButton />
    </div>
  );
}

export default Home;
