import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function JobCardSkelton() {
  return (
    <div>
      <Skeleton
        animation="wave"
        variant="rect"
        style={{
          height: 250,
          width: "98%",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          marginBottom: 10,
        }}
      />
    </div>
  );
}
