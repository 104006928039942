import { Chip, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    //  display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  boxShadowClass: {
    position: "relative",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    padding: 20,
    margin: "10px 0",
  },
  statuschip: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 8,
    // borderRadius: "8px",
  },
}));
function SavedAchievements({ data, from = "" }) {
  const classes = useStyles();
  const mainData = from
    ? data?.change_data?.extra_curricular_activity?.length === 0 &&
      data?.change_type === "DELETE"
      ? data?.current_data
      : data?.change_data?.extra_curricular_activity
    : data?.change_data?.achievements?.length === 0 &&
      data?.change_type === "DELETE"
    ? data?.current_data
    : data?.change_data?.achievements;

  const ChangeType = data?.change_type;
  return (
    <div style={{ position: "relative" }}>
      <div className={classes?.boxShadowClass}>
        <Tooltip
          placement="bottom"
          title={
            data?.change_type === "ADD"
              ? "New project added"
              : data?.change_type === "UPDATE"
              ? "Update existing project"
              : "Delete existing project"
          }
        >
          <div
            style={{
              backgroundColor:
                data?.change_type === "ADD"
                  ? "#00203F"
                  : data?.change_type === "UPDATE"
                  ? "#2AF598"
                  : "#FF0000",
            }}
            className={classes.statuschip}
          >
            <Typography style={{ color: "#fff", fontSize: "14px" }}>
              {data?.change_type === "ADD"
                ? "Added"
                : data?.change_type === "UPDATE"
                ? "Updated"
                : "Deleted"}
            </Typography>
          </div>
        </Tooltip>
        {mainData?.map((item) => (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: "10px",
                  marginRight: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "10px",
                  backgroundColor: "#000",
                  borderRadius: "8px",
                }}
              />

              <Typography variant="body2">{item}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SavedAchievements;
