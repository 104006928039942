import pSBC from "shade-blend-color";
import { Hidden, Button } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  showing: {
    color: theme.palette.primary.main,
    fontSize: 14,
    cursor: "pointer",
    paddingTop: 2,
  },
}));

export default function ChipCustom({
  courseArray,
  displayLimit = "all",
  openDialog,
  selectCoursesArray = () => {},
  allInOneSelected = "All Courses",
  allSelected = "Open to All Courses",
  primaryItems,
  secondaryItems,
  primaryName,
  secondaryName,
  onlyGroup = false,
}) {
  const classes = useStyles();

  const selectedSpecializations = () => {
    let selectedSpecs = [];
    let allDegreesSelected = true;

    courseArray?.forEach((element) => {
      element[primaryItems].forEach((item) => {
        if (item.select_all) {
          selectedSpecs.push({
            primaryName: item[primaryName],
            secondaryName: allInOneSelected,
          });
        } else {
          allDegreesSelected = false;
          item[secondaryItems].forEach((spec) => {
            if (spec.selected) {
              selectedSpecs.push({
                primaryName: item[primaryName],
                secondaryName: spec[secondaryName],
              });
            }
          });
        }
      });
    });

    if (!onlyGroup && courseArray && courseArray.length && allDegreesSelected) {
      return [{ primaryName: "", secondaryName: allSelected }];
    }

    return selectedSpecs;
  };

  const selectedSpecs = selectedSpecializations();

  const chipsToDisplay =
    displayLimit === "all"
      ? selectedSpecs
      : selectedSpecs.slice(0, displayLimit);

  const isShowAllButtonVisible =
    displayLimit !== "all" && selectedSpecs.length > chipsToDisplay.length;

  return (
    <div className={classes.chipsection}>
      {chipsToDisplay.map((item, index) => (
        <Chip
          key={index}
          variant="outlined"
          size="small"
          label={
            onlyGroup
              ? item.primaryName
              : item.primaryName
              ? `${item.primaryName} / ${item.secondaryName}`
              : item.secondaryName
          }
          className={classes.chip}
        />
      ))}
      {isShowAllButtonVisible && (
        <Button
          onClick={() => {
            openDialog();
            selectCoursesArray(courseArray);
          }}
          style={{ width: "90px" }}
        >
          Show All
        </Button>
      )}
    </div>
  );
}
