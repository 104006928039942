import React from 'react'
import { LocationOn, WorkOutline } from "@material-ui/icons";
import moment from "moment";
import { Grid, Button, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    jobCard: {
        backgroundColor: "#DFDFDF",
        borderRadius: '8px',
        paddingTop: 10,
        paddingBottom: 10,
        height: '100%'
    },
    undo: {
        color: "white",
        backgroundColor: '#00A023',
        marginTop: 20,
        borderRadius: 8,
        height: 58,
        width: 150,
        border: 'none',
        cursor: 'pointer',
    },
    notInterestedText: {
        color: '#C4161C',
        fontWeight: "700",
        fontSize: '34px',

    },
    willNotShowText: {
        color: '#4F4F4F',
        fontSize: 30,
        marginTop: 30,

    },
    content: {
        display: 'flex',
        flexDirection: "column",
        marginLeft: 26,
        // marginTop: 20,
        paddingTop: 50,
    },
    undoButtonSection: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginRight: 30,
    },


}))

const RejectedResponse = ({ handleNotInterestedCard, jobIndex }) => {

    const classes = useStyles();


    return (
        <section className={classes.jobCard}>
            <section className={classes.content}>
                <Typography className={classes.notInterestedText}>
                    Marked as Not Interested
                </Typography>
                <Typography className={classes.willNotShowText}  >
                    We will not show you this job again.
                </Typography>
                <section className={classes.undoButtonSection}>
                    <button className={classes.undo}
                        onClick={() => {
                            handleNotInterestedCard?.undo(jobIndex);
                        }}
                    >
                        Undo
                    </button>
                </section>
            </section>

        </section>
    )
}

export default RejectedResponse