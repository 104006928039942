import { Container, Grid, Typography } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import LanguageIcon from "@material-ui/icons/Language";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { CollegeGet } from "../../api/CollegeProfile/action";
import { collegeDetailsSelectorFn } from "../../api/Domain/selector";
import CollegeImage from "../../assets/img/company.jpg";
import Facebook from "../../assets/png/facebook.png";
import Insta from "../../assets/png/insta.png";
import Linkedin from "../../assets/png/linkedin.png";
import FormFooter from "../../components/Common/FormFooter";
import StudentFormNavbar from "../../components/Common/StudentFormNavbar";
import CollegeInfo from "./CollegeInfo";
import ReactHtmlParser from "react-html-parser";
import { checkForUrl } from "../../utility/checkForUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginTop: "20px",
  },

  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    height: "270px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  submit: {
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  imagedata: {
    display: "flex",
    width: 150,
    backgroundColor: "#fff",
    height: 80,
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  imgdata: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",

    //  borderRadius: "50%",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  ranking: {
    borderRadius: "8px",
    padding: "20px",
    display: "flex",
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "#fff",
  },
  course: {
    borderRadius: "8px",
    border: ".5px solid #b0b6ba",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "80%",
    marginBottom: "20px",
  },
  social: {
    // margin: theme.spacing(3.5),
    // marginTop: "70px",
    display: "flex",

    alignItems: "center",
  },
  icons: {
    marginRight: "10px",
    cursor: "pointer",
  },
  infoicon: {
    color: "#5a6268",
  },
}));

function CompanyDetailView() {
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const data = useSelector((state) => state?.collegeProfile?.collegeData);
  const collegeDetail = useSelector(collegeDetailsSelectorFn);
  const [link, setLink] = useState({
    facebook: "",
    insta: "",
    linkedin: "",
  });

  useEffect(() => {
    const socialLinks = data?.social_links || [];

    setLink({
      ...link,
      facebook: socialLinks.length > 1 ? socialLinks[1]?.value || "" : "",
      insta: socialLinks.length > 2 ? socialLinks[2]?.value || "" : "",
      linkedin: socialLinks.length > 0 ? socialLinks[0]?.value || "" : "",
    });
  }, [data]);

  useEffect(() => {
    if (params?.college_id) {
      dispatch(
        CollegeGet(
          // setLoading,
          alert,
          history,
          location,
          params?.college_id
        )
      );
    }
  }, [params?.college_id]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f9fa", paddingBottom: "20px" }}>
        <StudentFormNavbar logo={data?.logo} />
        {data?.banner ? (
          <img className={classes.image} alt="complex" src={data?.banner} />
        ) : (
          <div className={classes.image} />
        )}
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{ marginTop: "-30px" }}>
            <Grid item>
              <div className={classes.imagedata}>
                <img
                  className={classes.imgdata}
                  alt="complex"
                  src={data?.logo}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Grid
                item
                xs
                container
                direction="column"
                spacing={2}
                style={{ display: "contents" }}
              >
                <div className={classes.title}>
                  <Typography
                    variant="h3"
                    style={{ fontWeight: 600, paddingBottom: "10px" }}
                  >
                    {data?.college_name}
                  </Typography>
                </div>
                <div className={classes.social}>
                  <img
                    src={Facebook}
                    height="40px"
                    className={classes.icons}
                    width="40px"
                    onClick={() => window.open(link?.facebook, "_blank")}
                  />
                  <img
                    src={Linkedin}
                    className={classes.icons}
                    height="40px"
                    width="40px"
                    onClick={() => window.open(link?.linkedin, "_blank")}
                  />
                  <img
                    src={Insta}
                    className={classes.icons}
                    height="40px"
                    width="40px"
                    onClick={() => window.open(link?.insta, "_blank")}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <br />
          {/* <div> */}
          <Typography variant="h6">Overview</Typography>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            {/* {Data.map((item) => ( */}
            <Grid item xs={12} sm={4}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <LanguageIcon className={classes.infoicon} />
                </Grid>
                <Grid
                  item
                  xs
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {/* <Link
                    to={`/public/college/profile/${data?.website}`}
                    style={{ textDecoration: "none" }}
                  > */}
                  <a
                    href={checkForUrl(data?.website)}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <Typography variant="body2" color="primary">
                      {data?.website}
                    </Typography>
                  </a>
                  {/* </Link> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <ApartmentIcon className={classes.infoicon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="secondary">
                    {data?.city}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <GolfCourseIcon className={classes.infoicon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="secondary">
                    {data?.college_type}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <PeopleAltOutlinedIcon className={classes.infoicon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="secondary">
                    {data?.no_of_students} Students
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <FlagOutlinedIcon className={classes.infoicon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="secondary">
                    {moment(data?.founded_in).format("YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <FlagOutlinedIcon className={classes.infoicon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="secondary">
                    Affliated to {data?.university_name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* ))} */}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">About Us</Typography>

            <Typography variant="body2">
              {ReactHtmlParser(data?.about_us)}
            </Typography>
          </Grid>
          <br />
          {/* <Typography variant="h6">Ranking</Typography> */}
          {/* <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid> */}
          {/* <Typography variant="h6"> Cutoff</Typography>
            <br />
            <CutOff /> */}
          {/* <div className={classes.ranking}>
            <BarChartIcon color="primary" />
            <div style={{ marginLeft: "20px" }}>
              <Typography variant="h6" style={{ color: "#5a6268" }}>
                No 1, 2018
              </Typography>
              <br />
              <Typography variant="h6" style={{ color: "#5a6268" }}>
                nirfindia
              </Typography>
            </div>
          </div> */}

          {/* <br />
            <Typography variant="h6">Course</Typography>
            <br /> */}
        </Container>
      </div>

      <CollegeInfo />
    </>
  );
}

export default CompanyDetailView;

const Data = [
  {
    icon: <LanguageIcon color="secondary" />,
    info: "www.microsoft.com",
  },
  {
    icon: <ApartmentIcon color="secondary" />,
    info: "Gurgaon",
  },
  {
    icon: <GolfCourseIcon color="secondary" />,
    info: "Private",
  },
  {
    icon: <PeopleAltOutlinedIcon color="secondary" />,
    info: "1000+ Employees",
  },

  {
    icon: <FlagOutlinedIcon color="secondary" />,
    info: "Founded in 1990",
  },
];

const Ranking = [
  {
    icon: <LanguageIcon color="secondary" />,
    info: "www.microsoft.com",
  },
  {
    icon: <ApartmentIcon color="secondary" />,
    info: "Gurgaon",
  },
  {
    icon: <PeopleAltOutlinedIcon color="secondary" />,
    info: "1000+ Employees",
  },

  {
    icon: <FlagOutlinedIcon color="secondary" />,
    info: "Founded in 1990",
  },
];

const tiers = [
  {
    description: [
      "Electronics and communication Engineering",
      "Civil Engineering",
      "Information Technology",
      "Mechanical Engineering",
    ],
  },
];
