import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";
import Course from "../../Course";

export default class HiredPie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 55, 13, 43, 22, 21],
      fill: {
        colors: ["#F44336", "#E91E63", "#9C27B0"],
      },
      options: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Team A", "Team B", "Team C", "Team D", "Team E", "Team F"],
        legend: {
          show: false,
        },
        colors: [
          "#e289f2",
          "#4f3694",
          "#acb9ff",
          "#855cf8",
          "#f8f2a8",
          "#f5777b",
        ],
        dataLabels: {
          //  enabled: false,
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  }
  render() {
    return (
      <Paper
        style={{
          boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
          borderRadius: "8px",
          padding: "30px",
        }}
      >
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: "30px" }}
        >
          Skills based hired Students
        </Typography>

        <Charst
          options={this.state.options}
          series={this.state.series}
          type="pie"
          //   width={320}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                border: "1px solid #e2e6ea",
                display: "flex",
                flexDirection: "row",
                borderRadius: "8px",
                width: "120px",
                height: "40px",
                color: "#8a94a6",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#377dff",
                  height: "10px",
                  width: "10px",
                  borderRadius: "8px",
                }}
              />
              <Typography variant="overline">Python</Typography>
            </div>
          </div>
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                //   padding: "20px",
                display: "flex",
                flexDirection: "row",
                borderRadius: "8px",
                width: "100px",
                border: "1px solid #e2e6ea",
                height: "40px",
                color: "#8a94a6",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#38cb89",
                  height: "10px",
                  width: "10px",
                  borderRadius: "8px",
                }}
              />
              <Typography variant="overline">Java</Typography>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}
