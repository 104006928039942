import {
  Button,
  Typography,
  Paper,
  InputBase,
  Icons,
  IconButton,
  FormControl,
  Breadcrumbs,
  InputLabel,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PublishIcon from "@material-ui/icons/Publish";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import EventIcon from "@material-ui/icons/Event";
import GuestTable from "../../components/Table/GuestTable";
import ManageGuest from "../../components/Table/GuestTable/ManageGuest";
import SearchIcon from "@material-ui/icons/Search";
import TotalMemberActionbar from "../../components/Input/ManageTeamActionBar/TotalMemberActionbar";
import MemberTable from "../../components/Table/GuestTable/MemberTable";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    //border: ".3px solid #b0b6ba",
    width: "20%",
    boxShadow: "none",
    borderRadius: "8px",
    boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
}));

export default function TotalMember() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <>
      {/* <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <DropDownButton title="Placement Session 2021" />
        </div> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/manage-event" style={{ textDecoration: "none" }}>
          <Typography color="primary" variant="body2">
            Events / Social Science Day
          </Typography>
        </Link>

        <Link style={{ textDecoration: "none" }}>
          <Typography color="secondary" variant="body2">
            Attendees
          </Typography>
        </Link>
      </Breadcrumbs>
      <br />
      <div className={classes.grid}>
        <div>
          <Typography variant="h2">Total Member</Typography>
        </div>
        {/* {userRolesPermission?.some(
          (item) => item.access_control_key === "manage_company"
        ) && ( */}
        <div style={{ display: "flex" }}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            className={classes.primebtn}
            startIcon={<AddIcon />}
          >
            Register Student
          </Button>

          {/* <Button variant="contained" startIcon={<GetAppIcon />} size="small" className={classes.button}>
              Export
            </Button> */}
        </div>
      </div>
      <TotalMemberActionbar />
      <MemberTable />
    </>
  );
}
