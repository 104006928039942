import { AppBar, Button, CircularProgress, Dialog, Grid, IconButton, makeStyles, Slide, Toolbar, Typography } from "@material-ui/core";
import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import JobDetailsCard from "../../Cards/JobDetailsCard";
import {useSelector } from "react-redux";
import { useAlert } from "react-alert";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  btn: {
    // marginTop: "30px",
    // paddingLeft: "70px",
    // paddingRight: "70px",
    // borderRadius: "30px",
    // boxShadow: "none",
    // height: "48px ",
    // display: "flex",
    // marginRight: "20px",
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: 8,
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenPreviewDialog({ open, handleClickOpen, handleClose, jobDetails, jobStatus, loadingPost, jobAction, handleSubmit, loadingDraft }) {


  const classes = useStyles();
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const alert = useAlert();



  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Preview
            </Typography>
            {jobStatus?.job_status === "DRAFTED" ? (
              <Button
                // onClick={handleClose}
                variant="outlined"
                color="primary"
                className={classes.btn}
                onClick={() => {

                  if(licenceData && licenceData?.display ){

                      alert.error(licenceData?.display_message);
                      return
                    }
                  if (!loadingPost) {
                    handleSubmit("OPEN");
                    handleClose();
                  }
                }}
              >
                {loadingPost ? (
                  <>
                    <CircularProgress className={classes.circularProgress} size={14} />
                  </>
                ) : (
                  "Post this Job"
                )}{" "}
              </Button>
            ) : (
              <Button
                // onClick={handleClose}
                variant="outlined"
                color="primary"
                onClick={() => {

                   if(licenceData && licenceData?.display ){

                      alert.error(licenceData?.display_message);
                      return
                    }

                  if (!loadingPost) {
                    handleSubmit("OPEN");
                    // handleClose();
                  }
                }}
                className={classes.btn}
              >
                {loadingPost ? (
                  <>
                    <CircularProgress className={classes.circularProgress} size={14} />
                  </>
                ) : jobAction === "edit" ? (
                  "Edit Job"
                ) : (
                  "Post Job"
                )}
              </Button>
            )}
            &nbsp; &nbsp; &nbsp;
            {(!jobAction || jobAction.includes("clone") || jobStatus?.job_status === "DRAFTED") && (
              <Button
                // color="inherit"
                variant="outlined"
                color="primary"
                className={classes.btn}
                onClick={() => {
                  if (!loadingDraft) {
                    handleSubmit("DRAFTED");
                    // handleClose();
                  }
                }}
              >
                {loadingDraft ? (
                  <>
                    <CircularProgress className={classes.circularProgress} size={14} />
                  </>
                ) : (
                  "Save in Draft"
                )}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <div style={{ width: "80%", display: "flex", justifyContent: "center", margin: "auto", marginTop: 30, marginBottom: 30 }}>
          <JobDetailsCard data={jobDetails} />
        </div>
      </Dialog>
    </div>
  );
}
