import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const RULES_DATA = "RULES_DATA";

export const RulesGet = (alert, history, location, college) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/settings/college/tnp_rules/?college_id=${college}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        // setLoading(false);

        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: RULES_DATA,
        payload: res.data.data,
      });
      // setLoading(false);
    } else {
      // setLoading(false);
    }
  };
};
export const updateTnpRules = (
  setLoading,
  data,
  alert,
  history,
  location,
  college
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/settings/college/tnp_rules/?college_id=${college}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      setLoading(false);
      alert.success(res?.data?.message || "Updated SuccessFully");
    } else {
      setLoading(false);
      alert.error("Some Error Occurred!!!");
    }
  };
};
