import React, { useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import PieChart from "../PieChart";
import DegreeBarChart from "../BarChart/DegreeBarChart";

const originalItems = ["a", "b"];

const initialLayouts = {
  lg: [
    { i: "a", w: 8, h: 4, x: 0, y: 0 },
    { i: "b", w: 4, h: 4, x: 8, y: 0 },
  ],
};

function Content({ size: { width }, placement, industry8 }) {
  const [items, setItems] = useState(originalItems);
  const [layouts, setLayouts] = useState(
    getFromLS("layouts") || initialLayouts
  );
  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };
  const onLayoutSave = () => {
    saveToLS("layouts", layouts);
  };
  const onRemoveItem = (itemId) => {
    setItems(items.filter((i) => i !== itemId));
  };
  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 896, sm: 768, xs: 480, xxs: 0 }}
        // breakpoints={{ lg: 900, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 }}
        rowHeight={100}
        width={width}
        onLayoutChange={onLayoutChange}
      >
        {/* <div
          key="c"
          className="widget"
          data-grid={{ w: 12, h: 4, x: 0, y: 6, y: Infinity }}
        >
          <Widget key="c" placement={placement} backgroundColor="#867ae9" />{" "}
        </div> */}
        <div key="a" style={{ display: "grid" }}>
          <DegreeBarChart placement={placement} />
        </div>
        <div key="b" style={{}}>
          <PieChart placement={industry8} />
        </div>
        {/* </div>
        ))} */}
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(Content);

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {}
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}
