// import { Grid, Typography } from "@material-ui/core";
// import React, { useEffect, useState } from "react";
// import { useAlert } from "react-alert";
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";
//new
import { Box, Grid, Switch, Typography, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEducationGroupFn } from "../../api/Domain/selector";
// import CustomCircularProgress from "../../components/CircularProgressBar";
import {
  collegeGroupListSelectorFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
} from "../../api/SignIn/selector";
import { EDGCompaniesAPI } from "../../api/University/APIs/action";
import UplacementCycle from "../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../components/CircularProgressBar";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";
import CompanyTrack from "../CompanyTrack";
import useCollegeGroupList from "../../Hooks/useCollegeGroupList";
import useNavBarCollegeGroupList from "../../Hooks/useNavBarCollegeGroupList";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 20.5,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function UniversityManageCompany() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  // const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn);

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else
        return `&group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  // useEffect(() => {
  //   if (collegeGroupList?.length > 0 && isCollegeGroup) {
  //     setNewCollegeGroupList(
  //       [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
  //     );
  //     setSelectedGroup({ id: "All", name: "All Groups" })
  //   } else {
  //     setNewCollegeGroupList(collegeGroupList);
  //     if(collegeGroupList?.length > 0)
  //     setSelectedGroup(collegeGroupList[0]);
  //   };
  // }, [collegeGroupList?.length]);

  const {
    newCollegeGroupList,
    setNewCollegeGroupList,
    selectedGroup,
    setSelectedGroup,
  } = useCollegeGroupList(collegeGroupList, isCollegeGroup);

  useEffect(() => {
    if (selectedGroup)
      dispatch(
        EDGCompaniesAPI(
          setLoading,
          alert,
          history,
          location,
          checkSelectedGroup()
        )
      );
  }, [selectedGroup]);

  useEffect(() => {
    dispatch(
      EDGCompaniesAPI(
        setLoading,
        alert,
        history,
        location,
        checkSelectedGroup()
      )
    );
  }, []);

  const collegeCompanyList = useSelector(
    (state) => state?.EDGCommon?.edgCompanyList
  );

  const [toggleViewCollege, setToggleViewCollege] = useState(false);
  const handleToggle = () => {
    setToggleViewCollege((prev) => !prev);
  };

  useNavBarCollegeGroupList(selectedGroup, setLoading);

  return (
    <>
      {/* <UniversityLayout> */}
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}

      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          {toggleViewCollege && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              {" "}
              <Box>
                <Typography variant="h2">Companies</Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  // style={{ marginTop: "10px" }}
                >
                  Manage your all Connected Companies in one place
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  color={toggleViewCollege ? "primary" : "#6C757D"}
                  style={{ fontSize: 16, marginRight: 5 }}
                >
                  Show {entityInfo?.college}s
                </Typography>

                <AntSwitch
                  checked={toggleViewCollege}
                  onChange={handleToggle}
                  name="checkedC"
                />
              </Box>
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            {toggleViewCollege ? (
              <Grid container spacing={5}>
                {collegeCompanyList?.length > 0 &&
                  collegeCompanyList?.map((item) => (
                    <Grid item xs={4}>
                      <UplacementCycle
                        item={item}
                        countTitle={"Visited Companies"}
                        count={item?.visited_company}
                        link={`/manage-companies`}
                        linkTitle={"View All Companies"}
                        from={"Company"}
                        secondaryCountTitle={"Active Companies"}
                        secondaryCount={item?.active_companies}
                        thirdCount={item?.pending_companies}
                        thirdCountTitle={"Pending Companies"}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <CompanyTrack
                selectedGroup={selectedGroup}
                handleToggle={handleToggle}
                toggleViewCollege={toggleViewCollege}
                setToggleViewCollege={setToggleViewCollege}
              />
            )}
            {/* <>
              {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_company"
              ) && (
                <div style={{ display: "flex" }}>
                  {filtersCompany?.current_tab === "All" && (
                    <>
                      <AddNewCompany
                        collegeId={collegeId}
                        setRefreshData={setRefreshData}
                      />{" "}
                    </>
                  )}

                  {filtersCompany?.current_tab === "All" ? (
                    <Button
                      variant="contained"
                      startIcon={<GetAppIcon />}
                      size="small"
                      className={classes.button}
                      onClick={handleExport}
                    >
                      {exportLoading ? (
                        <CircularProgress
                          size={18}
                          style={{ color: "white" }}
                        />
                      ) : (
                        "Export"
                      )}
                    </Button>
                  ) : (
                    <CSVLink
                      data={
                        selectedRowsData?.length > 0
                          ? selectedRowsData
                          : companyList?.length
                          ? companyList
                          : []
                      }
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      filename={"Companies.csv"}
                    >
                      <Button
                        variant="contained"
                        startIcon={<GetAppIcon />}
                        size="small"
                        className={classes.button}
                        style={{ textDecoration: "none", marginRight: "0px" }}
                      >
                        Export
                      </Button>{" "}
                    </CSVLink>
                  )}
                </div>
              )}

              <CompanyTab
                page={page}
                setPage={setPage}
                totalCount={totalCount}
                pendingCount={pendingCount}
                loading={loading}
                filtersCompany={filtersCompany}
                setFiltersCompany={setFiltersCompany}
                setClickSearchButton={setClickSearchButton}
                setSelectedRowsData={setSelectedRowsData}
                selectedRowsData={selectedRowsData}
                clickSearchButton={clickSearchButton}
                setApplyClickedCTC={setApplyClickedCTC}
                collegeId={collegeId}
                userRolesPermission={userRolesPermission}
                setCallNavbar={setCallNavbar}
                rejectedCount={rejectedCount}
                companyList={companyList}
                backendDrivenFilter={backendDrivenFilter}
                setBackendDrivenFilter={setBackendDrivenFilter}
                filterData={filterData}
                filterLoading={filterLoading}
                companyListTotalCount={companyListTotalCount}
                pageData={pageData}
                setPageData={setPageData}
                setCompanyList={setCompanyList}
                setRefreshData={setRefreshData}
              />
            </> */}
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityManageCompany;
