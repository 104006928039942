import {
  Divider, IconButton, ListItemText, Menu, MenuItem
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import React from "react";
import { Link } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "20px",
    display: "flex",
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function Action({ children }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleClick} style={{ cursor: "pointer" }}>
        <ArrowForwardIos />{" "}
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <Link to="/job-details" style={{ textDecoration: "none" }}>
            <ListItemText primary="View" />
          </Link>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <Link to="/" style={{ textDecoration: "none" }}>
            <ListItemText primary="Edit" />
          </Link>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <Link to="/" style={{ textDecoration: "none" }}>
            <ListItemText primary="Clone" />
          </Link>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <Link to="/" style={{ textDecoration: "none" }}>
            <ListItemText primary="Delete" />
          </Link>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <Link to="/assignments" style={{ textDecoration: "none" }}>
            <ListItemText primary="View Assignment" />
          </Link>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <Link to="/interview" style={{ textDecoration: "none" }}>
            <ListItemText primary="View Interviews" />
          </Link>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <Link to="/track" style={{ textDecoration: "none" }}>
            <ListItemText primary="Track Interviews" />
          </Link>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
