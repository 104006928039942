import "quill/dist/quill.snow.css";
import React, { useEffect } from "react";
import { useQuill } from "react-quilljs";
import "./editor.css";

const PlacementCyclesDialog = (props) => {
  const {
    state,
    setState,
    desc,
    placeholderText,
    removeAttachments,
    formSubmitted,
    setFormSubmitted,
    fromUniversitySetting = false,
    scrollUp,
    disabled,
    useStateAsInitialValue = false,
  } = props;

  const placeholder = placeholderText
    ? placeholderText
    : "Write your description.....";

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["link"],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const readOnly = disabled || false;
  const { quill, quillRef } = useQuill({ modules, placeholder, readOnly });

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        setState(quill.root.innerHTML);
      });

      if (fromUniversitySetting) {
        const limit = 512;
        quill.on("text-change", () => {
          if (quill.getLength() > limit) {
            quill.deleteText(limit, quill.getLength());
          }
          setState(quill.root.innerHTML);
        });
      }

      if (formSubmitted) {
        quill.clipboard.dangerouslyPasteHTML("\n");
        setState("");
        setFormSubmitted(false);
      }

      if (desc || useStateAsInitialValue) {
        const initialContent = useStateAsInitialValue ? state : desc;
        if (initialContent) {
          const delta = quill.clipboard.convert(
            initialContent.replace(/,/g, "")
          );
          quill.setContents(delta);
        }
      }
    }
  }, [
    quill,
    desc,
    formSubmitted,
    useStateAsInitialValue,
    fromUniversitySetting,
  ]);

  return (
    <div
      style={{
        width: "100%",
        height: 150,
        marginBottom: 50,
        borderRadius: 8,
      }}
    >
      <div
        ref={quillRef}
        style={{
          borderRadius: "0px 0px 8px 8px",
          backgroundColor: "white",
          paddingBottom: "10px",
        }}
      />
    </div>
  );
};

export default PlacementCyclesDialog;
