import {
    Box,
    Button,
    Divider,
    Grid,
    Link,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Carousel, { consts } from "react-elastic-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { EJDListAPI } from "../../api/Common/action";
import {
    EJDFieldsAPI,
    EJDSelectedFieldsAPI,
    EJDSelectedPostAPI,
} from "../../api/EJD/action";
import {
    collegeDetailSelectorFn,
    userRolesActionsFn,
} from "../../api/SignIn/selector";
import EJDJob from "../../components/Cards/PreviousJob/ejd";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import CompanyInfo from "../../components/Form/Manage/CompanyInfo";
import Customization from "../../components/Form/Manage/Customization";
import Item from "../PostJob/Item";
import TrackShareSuccessfully from "../../components/DialogSection/TrackShareSuccessfull";

const useStyles = makeStyles((_) => ({
    btn: {
        marginTop: "30px",
        paddingLeft: "70px",
        paddingRight: "70px",
        borderRadius: "8px",
        boxShadow: "none",
        height: "40px ",
        display: "flex",
        marginRight: "20px",
    },
    sliderbutton: {
        border: "none",
        backgroundColor: "transparent",
        padding: "0",
    },
    arrow: {
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: "40px",
        cursor: "pointer",
        height: "40px",
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://getwork.org//">
                GetWork
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const ManageEjnf = () => {

    const classes = useStyles();
    const [localFieldList, setLocalFieldList] = useState([]);
    const [loading, setLoading] = useState(false);
    const alert = useAlert();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loadingData, setLoadingData] = useState(false);
    const [loadingSelected, setLoadingSelected] = useState(false);
    const collegeDetail = useSelector(collegeDetailSelectorFn);
    const college_id = collegeDetail?.college_id;
    const userRolesPermission = useSelector(userRolesActionsFn);
    const [groupFieldList, setGroupFieldList] = useState({});
    const [currentItem, setCurrentItem] = useState(null);
    const licenceData = useSelector((state) => state?.commonData?.licenceData);

    React.useEffect(() => {
        if (college_id) {
            dispatch(
                EJDFieldsAPI(setLoadingData, alert, history, location, college_id)
            );
            dispatch(
                EJDSelectedFieldsAPI(
                    setLoadingSelected,
                    alert,
                    history,
                    location,
                    college_id
                )
            );
        }
    }, [college_id]);

    const fieldList = useSelector((state) => state?.EJDFields?.details);
    const selectedFields = useSelector(
        (state) => state?.EJDSelectedFields?.details
    );

    const initialEjnForm = {
        title: null,
        message: null,
        facilities: null,
    };
    const [ejdForm, setEjdForm] = useState(initialEjnForm);

    const [ejdTitleError, setEjdTitleError] = useState(null);

    const validateData = () => {
        var title_error = null;
        var is_valid = true;
        if (!ejdForm.title) {
            title_error = "Please Enter EJD Title";
            alert.error("Please Enter your EJD Title");
            is_valid = false;
        }
        setEjdTitleError(title_error);

        return is_valid;
    };

    useEffect(() => {
        if (fieldList?.length > 0) {
            var arr = [...fieldList];
            arr?.forEach((element) => {
                element["is_selected"] = checkIfExists(element);
            });
            setLocalFieldList(arr);
        }
    }, [selectedFields, fieldList]);

    useEffect(() => {
        if (fieldList?.length > 0 && currentItem) {
            var arr = [...fieldList];
            arr?.forEach((element) => {
                element["is_selected"] = checkIfExists(element);
            });

            setLocalFieldList(arr);
            setEjdForm({
                ...ejdForm,
                title: currentItem?.ejd_title,
                message: currentItem?.ejd_message,
                facilities: currentItem?.facilities,
            });
        }
    }, [currentItem]);

    useEffect(() => {
        if (localFieldList?.length > 0) {
            var result = localFieldList.reduce(function (r, a) {
                if (a.ejd_section) {
                    r[a.ejd_section] = r[a.ejd_section] || [];
                    r[a.ejd_section].push(a);
                } else {
                    r["top"] = r[["top"]] || [];

                    r["top"].push(a);
                }

                return r;
            }, Object.create(null));

            setGroupFieldList(result);
        }
    }, [localFieldList?.length, localFieldList]);

    const checkIfExists = (i) => {
        var bool_ = false;
        if (currentItem && currentItem?.ejd_form_fields?.length > 0) {
            currentItem?.ejd_form_fields?.map((item) => {
                if (item.id === i.id || i.pre_selected) bool_ = true;
            });
        } else {
            if (i.pre_selected) bool_ = true;
        }
        return bool_;
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [shareSuccessData, setShareSuccessData] = useState({
        link: null,
    });
    const handleSuccessData = (data) => {
        //console.log("handleSuccessData", data);
        setShareSuccessData({
            ...shareSuccessData,
            link: data?.ejd_link,
        });
        handleClickOpen();
    };
    const [inviteCall, setInviteCall] = useState(false);

    const handleSubmit = () => {
        if (validateData()) {
            setInviteCall(false);
            var arr = [];
            localFieldList.map((item) => {
                if (item.is_selected) arr.push(item.id);
            });
            var data = {
                college_id: [college_id],
                ejd_form_fields: arr,
                ejd_title: ejdForm.title,
                ejd_message: ejdForm.message,
                facilities: ejdForm.facilities,
            };
            if (arr.length > 0)
                dispatch(
                    EJDSelectedPostAPI(
                        data,
                        setLoading,
                        alert,
                        history,
                        location,
                        selectedFields,
                        college_id,
                        setInviteCall,
                        handleSuccessData
                    )
                );
            else alert.error("Please Select at least one Checkbox");
        }
    };

    useEffect(() => {
        if (inviteCall) dispatch(EJDListAPI(alert, history, location));
    }, [inviteCall]);

    return (
        <>
            {/* <Layout> */}
            <Typography variant="h2">e-JNFs</Typography>

            <Typography variant="body2" color="secondary">
                Managing e-JNFs, Creating and Updating
            </Typography>

            {/* <Successfully /> */}
            <Grid container spacing={3} style={{ marginTop: "10px" }}>
                {/* <LinkCard /> */}
                {/* </Grid>
          <Grid item xs={12} sm={4}>
            <LinkCard />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LinkCard />
            </
          </Grid> */}

                {loadingSelected ? (
                    <></>
                ) : selectedFields?.length > 0 && selectedFields?.length <= 4 ? (
                    <>
                        {selectedFields?.map((item) => (
                            <Grid item xs={3}>
                                <EJDJob item={item} setCurrentItem={setCurrentItem} />
                            </Grid>
                        ))}
                    </>
                ) : (
                    selectedFields?.length > 0 && (
                        <Grid item xs={12} sm={12}>
                            <Carousel
                                itemsToShow={3}
                                //  breakPoints={breakPoints}
                                pagination={false}
                                renderArrow={({ type, onClick }) => {
                                    const pointer =
                                        type === consts.PREV ? (
                                            <ArrowBackIosIcon color="secondary" />
                                        ) : (
                                            <ArrowForwardIosIcon color="secondary" />
                                        );
                                    return (
                                        <button className={classes.sliderbutton} onClick={onClick}>
                                            <div className={classes.arrow}>{pointer}</div>
                                        </button>
                                    );
                                }}
                                itemPadding={[10, 10]}
                            >
                                {selectedFields?.map((item) => (
                                    <Item>
                                        <EJDJob item={item} setCurrentItem={setCurrentItem} />
                                    </Item>
                                ))}
                            </Carousel>
                        </Grid>
                    )
                )}

                <Grid item xs={12} md={12} lg={12}>
                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                        <Divider />
                    </div>
                    <Typography variant="h5">Creating e-JNF</Typography>
                    <br />
                    <Customization
                        ejdForm={ejdForm}
                        setEjdForm={setEjdForm}
                        ejdTitleError={ejdTitleError}
                        setEjdTitleError={setEjdTitleError}
                    />
                    <Typography
                        variant="h5"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                        Customization for e-JNF
                    </Typography>
                    {/* <br /> */}
                    {groupFieldList &&
                        Object.keys(groupFieldList)?.length > 0 &&
                        Object.keys(groupFieldList).map((item) => (
                            <>
                                <CompanyInfo
                                    title={item}
                                    dataArray={groupFieldList[item]}
                                    localFieldList={localFieldList}
                                    setLocalFieldList={setLocalFieldList}
                                    userRolesPermission={userRolesPermission}
                                />
                                <br />
                            </>
                        ))}
                    {/* <br />
            <JobProfileForm /> */}
                    <br />
                </Grid>
            </Grid>
            {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_ejnf_forms"
            ) && (
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                borderRadius: "8px",
                                boxShadow: "none",
                                height: "48px",
                                minWidth: "250px",
                                paddingLeft: "70px",
                                paddingRight: "70px",
                            }}
                            onClick={() => {
                                if (licenceData && licenceData?.display) {
                                    alert.error(licenceData?.display_message);
                                    return;
                                }
                                if (!loading) handleSubmit();
                            }}
                        >
                            {loading ? <CustomButtonCircularProgress /> : "Create E-JNF"}
                        </Button>
                    </div>
                )}
            <Box pt={4}>
                <Copyright />
            </Box>
            <TrackShareSuccessfully
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                data={shareSuccessData}
                source="EJNF"
                jobTitle={ejdForm?.title}
            />
            {/* </Layout> */}
        </>
    );

}

export default ManageEjnf