import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import OffCampusForm from "../../components/Cards/OffCampus/OffCampusForm";

const useStyles = makeStyles((theme) => ({
  textDiv: {
    marginTop: "10px",
  },
  mainDiv: {
    display: "flex",
    justifyContent: "center",
  },
  formDiv: {
    marginTop: "30px",
    width: "80%",
    borderRadius: "8px",
  },
}));

function AddOffCampusPlacement() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.textDiv}>
        <Typography variant="h3">Add Off Campus Placement Details</Typography>
        <Typography variant="body2" color="secondary">
          Manage Students who got placed off campus
        </Typography>
      </div>
      <div className={classes.mainDiv}>
        <div className={classes.formDiv}>
          <OffCampusForm />
        </div>
      </div>
    </div>
  );
}

export default AddOffCampusPlacement;
