import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import AddNewMember from "../DialogItem/AddNewMember";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function AddMember({
  teamList,
  userRoleList,
  designationList,
  placementSession,
  userRoleId,
  setApiCalled,
  collegeId,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const alert = useAlert();

  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const handleClickOpen = () => {
    if (licenceData && licenceData?.display) {
      alert.error(licenceData?.display_message);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [teamDetails, setTeamDetails] = useState({
    name: null,
    email: null,
    phone: null,
    designation: null,
    batch: null,
    degree: null,
    course: null,
    user_role_id: null,
  });

  const [teamDetailsError, setTeamDetailsError] = useState({
    name: null,
    email: null,
    phone: null,
    designation: null,
    batch: null,
    degree: null,
    course: null,

    user_role: null,
  });

  useEffect(() => {
    if (userRoleList?.length > 0 && teamDetails?.user_role_id) {
      checkAccessControlList(teamDetails?.user_role_id);
    }
  }, [teamDetails?.user_role_id]);
  const [accessControlList, setAccessControlList] = useState([]);
  const [groupName, setGroupName] = useState(null);
  const checkAccessControlList = (id) => {
    var accessControlListnew = getAccessControlList(id);
    // var description = [];
    // accessControlListnew?.map((item) => {
    //   description.push(item.access_control_name);
    // });

    // var obj = {
    //   description: description,
    // };
    // var arr = [];
    // arr.push(obj);
    setAccessControlList(accessControlListnew);

    var result = accessControlListnew.reduce(function (r, a) {
      if (a.access_control_group_name) {
        r[a.access_control_group_name] = r[a.access_control_group_name] || [];
        r[a.access_control_group_name].push(a);
      }

      return r;
    }, Object.create(null));

    setGroupName(result);
  };

  const getAccessControlList = (id) => {
    var arr = [];
    userRoleList?.map((item) => {
      if (item.id === id) {
        arr = item.access_controls_list;
      }
    });
    return arr;
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Add New
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <Grid container spacing={3} style={{ padding: "40px" }}>
            <Grid item xs={6}>
              <AddNewMember
                handleClose={handleClose}
                teamList={teamList}
                userRoleList={userRoleList}
                teamDetails={teamDetails}
                setTeamDetails={setTeamDetails}
                teamDetailsError={teamDetailsError}
                setTeamDetailsError={setTeamDetailsError}
                designationList={designationList}
                placementSession={placementSession}
                userRoleId={userRoleId}
                setApiCalled={setApiCalled}
                collegeId={collegeId}
              />
            </Grid>
            <div className={clsx(classes.column, classes.helper)}>
              <Grid item xs={12}>
                <div className={classes.paper}>
                  {" "}
                  <Typography variant="h6">User Access Control</Typography>
                  <div>
                    {/* {accessControlList.map((round) => (
                      <div style={{ marginTop: "7px" }}>
                        <ul style={{ display: "contents" }}>
                          {round.description.map(line => (
                            <Typography
                              variant="body2"
                              color="secondary"
                              // align="center"
                              key={line}
                              style={{ fontSize: "14px", marginBottom: "10px" }}
                            >
                              {line}
                            </Typography>
                          ))}
                        </ul>
                      </div>
                    ))} */}
                    {groupName &&
                      Object.keys(groupName)?.map((item) => (
                        <div style={{ marginTop: "7px" }}>
                          <br />
                          <Typography
                            variant="body2"
                            color="secondary"
                            // align="center"
                            // key={item.id}
                            style={{ fontSize: "14px", marginBottom: "10px" }}
                          >
                            <b> {item}</b>
                          </Typography>
                          <ul style={{ display: "contents" }}>
                            {groupName[item].map((line) => (
                              <Typography
                                variant="body2"
                                color="secondary"
                                // align="center"
                                key={line}
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "10px",
                                }}
                              >
                                {line.access_control_name}
                              </Typography>
                            ))}
                          </ul>
                        </div>
                      ))}
                  </div>
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const round = [
  {
    description: [
      "Can Track and Change Applicants Status",
      "Can add/remove custom Fields on e-JD/e-JNF",
      "Can download/Upload student Data",
      "Can Manage all the controls of other TnP Members",
      "Can Send & Customize Invite E-Mails Body and Signature ",
      "Can Generate Custom Student registration form (With Passcode)",
      "Can View Activity Logs (Students & Companies) ",
      "Has Approval access while students want to change their Details",
    ],
  },
];
