import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlacementPatchAPI } from "../../../api/PlacementCycles/action";
import Calendar from "../../Input/Calendar/index3";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  btngrid: {
    // paddingRight: "25px",
    display: "flex",
    //paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  add: {
    //padding: "20px",
    paddingTop: "20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function EditPlacement({ item, collegeId }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedDate, setSelectedDate] = React.useState(item?.start_date);
  const [placement, setPlacement] = useState(item?.name);
  const [placementError, setPlacementError] = useState(null);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const placementList = useSelector((state) => state.PlacementCycles.details);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateData = () => {
    var is_valid = true;
    var name_error = null;
    var date_error = null;
    if (!placement) {
      name_error = "PLacement Name Name field cannot be left blank";
      is_valid = false;
    }

    setPlacementError(name_error);
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      var arr = [];

      var data = {
        college_id: [collegeId],
        name: placement,
        start_date: new Date(selectedDate).toISOString(),
      };
      dispatch(
        PlacementPatchAPI(
          data,
          item?.id,
          setLoading,
          alert,
          history,
          location,
          placementList,
          handleClose,
          false
        )
      );
    }
  };
  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        variant="contained"
        style={{
          marginLeft: "10px",
          padding: "0px",
        }}
      >
        <EditIcon color="primary" />
      </IconButton>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.add}>
          <div
            style={{
              // padding: "10px",
              //   paddingLeft: "20px",
              display: "flex",
              marginBottom: "30px",
              marginLeft: "140px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Edit Placement Cycle</Typography>
          </div>

          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{
              style: { padding: "16px" },
            }}
            value={placement}
            onChange={(e) => {
              setPlacement(e.target.value);
              setPlacementError(null);
            }}
            error={placementError ? true : false}
            helperText={<span>{placementError}</span>}
            // fullWidth
            InputProps={{ classes: { root: classes.inputRoot } }}
            style={{ width: "100%" }}
            label="Enter Placement Cycle"
            name="Enter Placement Cycle"
          />

          <div
            style={{
              display: "flex",
              // paddingLeft: "70px",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            {/* <Button
              color="primary"
              startIcon={<DateRangeIcon />}
              style={{ padding: "0px", display: "flex" }}
            >
              Select Start Date
            </Button> */}
            {/* <DateRangeIcon color="primary" />
            <Typography variant="body2" style={{ fontWeight: "bold", marginLeft: "7px" }} color="primary">
              03-01-2021
            </Typography> */}
            <Calendar
              title="Start Date"
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
          <div className={classes.btngrid}>
            <Button
              variant="outlined"
              className={classes.button}
              style={{ height: "48px" }}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              style={{ marginLeft: "15px", height: "48px", minWidth: "150px" }}
              color="primary"
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : (
                <>Update</>
              )}{" "}
            </Button>
          </div>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
