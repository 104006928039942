import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import { Hidden, Typography } from "@material-ui/core";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
    [theme.breakpoints.only("sm")]: {
      // width: "340px",
      maxWidth: "100%",
      // width: "300px",
      // paddingLeft: "15px"
    },
    [theme.breakpoints.only("xs")]: {
      // width: "340px",
      maxWidth: "300px",
      // width: "300px",
      // paddingLeft: "15px"
    },

    // border:"1px solid #007bff"
  },
}));
export default function ChipSection({ qualifications, specLength }) {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className={classes.chipsection}>
        <>
          {Object.keys(qualifications)?.map((item, index) => (
            <>
              <>
                {item === "Open to All" && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={"Open to All Courses"}
                    className={classes.chip}
                  />
                )}
              </>
            </>
          ))}
        </>
        <>
          <>
            {!showMore && (
              <>
                {specLength > 5 ? (
                  <>
                    {Object.keys(qualifications)
                      ?.slice(0, 5)
                      .map((item, index) => (
                        <>
                          {item !== "Open to All" &&
                            !showMore &&
                            qualifications[item].slice(0, 5).map((item2) => (
                              <>
                                {
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    label={
                                      <Typography
                                        noWrap
                                        color="primary"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {item2.name
                                          ? item + " / " + item2.name
                                          : item +
                                            " / " +
                                            item2.specialization_name}
                                      </Typography>
                                    }
                                    className={classes.chip}
                                  />
                                }
                              </>
                            ))}
                          {/* <span onClick={() => setShowMore(true)} style={{ color: "#007bff", fontSize: 14, cursor: "pointer", paddingTop: 2 }}>
                            Show More...
                          </span> */}
                        </>
                      ))}
                    {Object.keys(qualifications)?.length > 5 && (
                      <span
                        onClick={() => setShowMore(true)}
                        style={{
                          color: "#000",
                          fontSize: 14,
                          cursor: "pointer",
                          paddingTop: 3,
                        }}
                      >
                        Show More...
                      </span>
                    )}
                  </>
                ) : (
                  Object.keys(qualifications)?.map((item, index) => (
                    <>
                      {item !== "Open to All" &&
                        qualifications[item].map((item2) => (
                          <>
                            <Hidden smUp>
                              <Chip
                                variant="outlined"
                                size="small"
                                label={
                                  <Typography
                                    noWrap
                                    color="primary"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {item2.name
                                      ? item + " / " + item2.name
                                      : item +
                                        " / " +
                                        item2.specialization_name}
                                  </Typography>
                                }
                                className={classes.chip}
                                style={{ maxWidth: "100%" }}
                              />
                            </Hidden>
                            <Hidden smDown>
                              <Chip
                                variant="outlined"
                                size="small"
                                label={
                                  <Typography
                                    noWrap
                                    color="primary"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {item2.name
                                      ? item + " / " + item2.name
                                      : item +
                                        " / " +
                                        item2.specialization_name}
                                  </Typography>
                                }
                                className={classes.chip}
                              />
                            </Hidden>
                          </>
                        ))}
                    </>
                  ))
                )}
              </>
            )}
          </>

          {/* {!showMore && qualifications[Object.keys(qualifications)[0]].length > 5 && (
            <span onClick={() => setShowMore(true)} style={{ color: "#007bff", fontSize: 14, cursor: "pointer", paddingTop: 3 }}>
              Show More...
            </span>
          )} */}
          <>
            {showMore &&
              Object.keys(qualifications)?.map((item, index) => (
                <>
                  {item !== "Open to All" &&
                    showMore &&
                    qualifications[item].map((item2) => (
                      <>
                        {/* <Hidden smUp>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                            className={classes.chip}
                            style={{ maxWidth: "100%" }}
                          />
                        </Hidden> */}
                        {/* <Hidden smDown> */}
                        <Chip
                          variant="outlined"
                          size="small"
                          label={
                            item2.name
                              ? item + " / " + item2.name
                              : item + " / " + item2.specialization_name
                          }
                          className={classes.chip}
                        />
                        {/* </Hidden> */}
                      </>
                    ))}
                </>
              ))}
            {showMore && (
              <span
                onClick={() => setShowMore(false)}
                style={{
                  color: "#000",
                  fontSize: 14,
                  cursor: "pointer",
                  paddingTop: 3,
                }}
              >
                Show less...
              </span>
            )}
          </>
        </>
      </div>
    </>
  );
}
