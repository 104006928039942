import React, { useEffect, useState } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";

const SingalIndustry = ({ placement }) => {
  return (
    <>
      {placement ? (
        <div
          style={{
            boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
            borderRadius: "8px",
            padding: "30px",
          }}
        >
          {/* <div style={{ display: "flex", borderBottom: ".5px solid #f3f3f3" }}>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FAFBFC",
              //   padding: "20px",
              display: "flex",
              flexDirection: "row",
              borderRadius: "20px",
              width: "120px",
              height: "40px",
              color: "#8a94a6",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#377dff",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
              }}
            />
            <Typography variant="overline">Total Students</Typography>
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FAFBFC",
              //   padding: "20px",
              display: "flex",
              flexDirection: "row",
              borderRadius: "20px",
              width: "100px",
              height: "40px",
              color: "#8a94a6",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#38cb89",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
              }}
            />
            <Typography variant="overline">Placed Student</Typography>
          </div>
        </div>
      </div> */}
          <Charst
            options={{
              xaxis: {
                title: {
                  text: "Industry Type",
                  style: {
                    fontSize: "14px",
                    fontFamily: "Nunito",
                    fontWeight: 600,
                    cssClass: "apexcharts-yaxis-title",
                  },
                },

                categories: placement?.categories ? placement?.categories : [],
                labels: {
                  rotate: 0,
                  // rotateAlways: true,
                  // hideOverlappingLabels: true,
                  trim: true,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  borderRadius: 8,
                  dataLabels: {
                    position: "top", // top, center, bottom
                  },
                },
              },

              dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                  fontSize: "10px",
                  fontFamily: "Nunito",
                  colors: ["#304758"],
                },
              },
              yaxis: {
                title: {
                  text: "Visited Company",
                  style: {
                    fontSize: "14px",
                    fontFamily: "Nunito",
                    fontWeight: 600,
                    cssClass: "apexcharts-yaxis-title",
                  },
                },
              },
              title: {
                text: "Industry Type Vs Visited Companies",
                style: {
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            }}
            series={placement?.series_list ? placement?.series_list : []}
            type="bar"
            height="100%"
            // width="100%"
            // width={"85%"}
          />
        </div>
      ) : null}
    </>
  );
};

export default SingalIndustry;
