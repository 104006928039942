import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NoDataFunc from "../../NoDataSVG/index1";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(rank, skills, studentnumber) {
  return { rank, skills, studentnumber };
}

const rows = [
  createData(1, "Software Development", 1345),
  createData(2, "Sales", 1345),
  createData(3, "Hospitality", 1345),
  createData(4, "Medical", 1345),
  createData(5, "Government", 1345),
  createData(6, "IT", 1345),
  createData(7, "Designer", 1345),
  createData(8, "Sales", 1345),
  createData(9, "Medial", 1345),
  createData(10, "Sales", 1345),
];

export default function HighlighedIndustryTable({ data }) {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: "8px",
        boxShadow: "rgb(0 0 0 / 5%) 0px 3px 24px",
      }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{ fontSize: "14px", color: "#5a6268" }}
            >
              Rank#
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "14px", color: "#5a6268" }}
            >
              Industry Name
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "14px", color: "#5a6268" }}
            >
              Number of Jobs
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 ? (
            data.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "14px", color: "#5a6268" }}
                  align="center"
                >
                  {row.rank}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontSize: "14px", color: "#5a6268" }}
                >
                  {row.industry_name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontSize: "14px", color: "#5a6268" }}
                >
                  {row.job_count}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: 143,
                  marginBottom: 44,
                }}
              >
                <NoDataFunc width={"300Px"} height={"200px"} />
                <Typography style={{ fontSize: "16px" }}>
                  {" "}
                  No Data Available
                </Typography>{" "}
              </div>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
