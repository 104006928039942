import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  collegeDetailSelectorFn,
  collegeUserDetailSelectorFn,
} from "../../../api/SignIn/selector";
import { db } from "../../../utility/firebaseConfig";
import AddChatTeam from "../../Cards/AddChatTeam";
import ChatActionbar from "../../Input/ChatActionbar";
import ChatTable from "../../Table/ChatTable";
import config from "../../../config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);
const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function ChatTab({
  selectedTab,
  loader,
  row,
  selected,
  selected_member,
  studentList,
  teamMemberList,
  selectedStudent,
  setSelectedStudent,
  setMembers,
  setSelectedID,
  selectedID,
  setSelectedMemID,
  selectedMemID,
  filters,
  setFilters,
  filterList,
  dataLoading,
  memLoading,
  setCurrentTab,
  currentTab,
  setApiCalledOnce,
  jobProfileList,
  setJobProfileState,
  jobProfileState,
  groupTab,
  data,
  setData,
  setCollegeMember,
  collegeMember,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState("two");
  const [loading, setLoading] = useState(true);
  //const [data, setData] = useState([]);
  //const [collegeMember, setCollegeMember] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [departmentValue, setDepartmentValue] = React.useState("");
  const [batch, setBatch] = React.useState();
  const [statusValue, setStatusValue] = React.useState("");
  const [specializationValue, setSpecializationValue] = React.useState("");
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const collegeUserDetail = useSelector(collegeUserDetailSelectorFn);
  const [temporary, setTemporary] = React.useState([]);

  const college_id = collegeDetail?.college_id;

  const [helper, setHelper] = React.useState({
    search: false,
    department: false,
    batch: false,
    status: false,
    specialization: false,
  });

  let temp = searchData;

  const params = useParams();

  useEffect(() => {
    let res = [];
    if (batch && helper.batch) {
      let filterTracks = temp.filter((item) => {
        if (item.batch == batch) {
          return item;
        }
      });
      res.push(...filterTracks);
      temp = res;
    }
    if (statusValue && helper.status) {
      let filterTracks = temp.filter((item) => {
        if (item.status == statusValue) {
          return item;
        }
      });
      res.push(...filterTracks);
      temp = res;
    }
    if (specializationValue && helper.specialization) {
      let filterTracks = temp.filter((item) => {
        if (item.branch == specializationValue) {
          return item;
        }
      });
      res.push(...filterTracks);
      temp = res;
    }
    if (departmentValue && helper.department) {
      let filterTracks = temp.filter((item) => {
        if (item.department.toLowerCase().match(departmentValue)) {
          return item;
        }
      });
      res.push(...filterTracks);
      temp = res;
    }

    if (searchData && helper.search) {
      let filterTracks = temp.filter((item) => {
        if (item.name.toLowerCase().match(searchData)) {
          return item;
        }
      });
      res.push(...filterTracks);
      temp = res;
    }
    setData(temp);
  }, [helper]);

  // useEffect(() => {
  //   console.log("Studnet list bahar");
  //   if (studentList?.length > 0) {
  //     console.log("Studnet list andar");
  //     let arr = [...studentList];
  //     setData(arr);
  //   }
  // }, [studentList]);
  // useEffect(() => {
  //   if (teamMemberList?.length > 0) {
  //     let arr = [...teamMemberList];
  //     setCollegeMember(arr);
  //   }
  // }, [teamMemberList]);

  // useEffect(() => {
  //   setData([]);
  //   //setCollegeMember([]);
  // }, [filters, currentTab, jobProfileState]);

  // useEffect(async () => {
  //   setLoading(true);
  //   if (college_id) {
  //     if (params.thread_id) {
  //       const chat_group_q = query(collection(db, config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV"), where("__name__", "==", params.thread_id));
  //       var helper_temp = [];
  //       const test = await getDocs(chat_group_q);
  //       test.forEach((doc) => {
  //         doc.data().users.map((item) => {
  //           helper_temp.push(item.id);
  //         });
  //       });
  //       const q = query(collection(db, config.prod ? "STUDENTS_PROD" : "STUDENTS_DEV"),where('cur_college.id', "==", college_id))
  //       const querySnapshot = await getDocs(q);
  //       console.log("GET STUDENT FIREBASE COUNT", querySnapshot.docs.length)
  //       const objectsArray = [];
  //       querySnapshot.forEach((user) => {
  //         if (user.data().cur_college.id == parseInt(college_id) && !helper_temp.includes(user.data().id)) {
  //           objectsArray.push(user.data());
  //         }
  //       });
  //       const q2 = query(collection(db, config.prod ? "MEMBERS_PROD" : "MEMBERS_DEV"), where('college.id', "==", college_id))
  //       const collegeStaff = await getDocs(q2);
  //       console.log("GET MEMBERS FIREBASE COUNT", collegeStaff.docs.length)
  //       const objectsArray1 = [];
  //       collegeStaff.forEach((user) => {
  //         if (user.data().college.id == parseInt(college_id) && !helper_temp.includes(user.data().id)) {
  //           objectsArray1.push(user.data());
  //         }
  //       });
  //       setCollegeMember(objectsArray1);
  //       setData(objectsArray);
  //       setSearchData(objectsArray);

  //       setLoading(false);
  //     } else {
  //       const q = query(collection(db, config.prod ? "STUDENTS_PROD" : "STUDENTS_DEV"),where('cur_college.id', "==", college_id))
  //       const querySnapshot = await getDocs(q);
  //       console.log("GET STUDENT FIREBASE COUNT", querySnapshot.docs.length)
  //       const objectsArray = [];
  //       querySnapshot.forEach((user) => {
  //         if (user.data().cur_college.id == college_id) {
  //           objectsArray.push(user.data());
  //         }
  //       });
  //       const q2 = query(collection(db, config.prod ? "MEMBERS_PROD" : "MEMBERS_DEV"), where('college.id', "==", college_id))
  //       const collegeStaff = await getDocs(q2);
  //       console.log("GET MEMBERS FIREBASE COUNT", collegeStaff.docs.length)
  //       const objectsArray1 = [];
  //       collegeStaff.forEach((user) => {
  //         if (user.data().college.id == college_id) {
  //           objectsArray1.push(user.data());
  //         }
  //       });
  //       setCollegeMember(objectsArray1);
  //       setData(objectsArray);
  //       setSearchData(objectsArray);
  //       setLoading(false);
  //     }
  //   }
  // }, [college_id]);

  const handleChange = (event, newValue) => {
    setApiCalledOnce(false);
    setData([]);
    setCollegeMember([]);
    setFilters([]);
    setCurrentTab(newValue);
  };

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      setData(searchData);
    } else {
      let txt = e.target.value.toLowerCase();
      let filterTracks = data.filter((item) => {
        if (item.name.toLowerCase().match(txt)) {
          return item;
        }
      });
      setData(filterTracks);
    }
  };

  const handleDepartMent = async (e) => {
    if (e.target.value === "") {
      setDepartmentValue("");
      // setData(searchData);
      const update = { department: false };
      setHelper({ ...helper, ...update });
    } else {
      setDepartmentValue(e.target.value.toLowerCase());
      setHelper({ ...helper, department: true });
    }
  };

  const handleBatch = async (e) => {
    if (e.target.value === "") {
      setBatch("");
      const update = { batch: false };
      setHelper({ ...helper, ...update });
    } else {
      setBatch(e.target.value);
      const update = { batch: true };
      setHelper({ ...helper, ...update });
    }
  };

  const handleStatus = async (e) => {
    if (e.target.value === "") {
      setStatusValue("");
      const update = { status: false };
      setHelper({ ...helper, ...update });

      helper.status = false;
    } else {
      setStatusValue(e.target.value);
      const update = { status: true };
      setHelper({ ...helper, ...update });
    }
  };

  const handleSpecialization = async (e) => {
    if (e.target.value === "") {
      setSpecializationValue("");
      const spec = { specialization: false };
      setHelper({ ...helper, ...spec });
      setData(searchData);
    } else {
      setSpecializationValue(e.target.value);
      const spec = { specialization: true };
      setHelper({ ...helper, ...spec });
      let filterTracks = searchData.filter((item) => {
        if (item.branch == e.target.value) {
          return item;
        }
      });
      setData(filterTracks);
    }
  };
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <NewTab
            value="two"
            wrapped
            disabled={groupTab && groupTab === "three"}
            label="All Students"
            {...a11yProps("two")}
          />
          <NewTab
            value="three"
            wrapped
            disabled={groupTab && groupTab === "two"}
            label="Job Profile"
            {...a11yProps("three")}
          />
        </NewTabs>
      </AppBar>

      <ChatActionbar
        selectedTab={value}
        tableData={data}
        onsearch={(e) => handleSearch(e)}
        departmentValue={departmentValue}
        handleChange={(e) => handleDepartMent(e)}
        handleBatch={(e) => handleBatch(e)}
        handleStatus={(e) => handleStatus(e)}
        handleSpecialization={(e) => handleSpecialization(e)}
        filters={filters}
        setFilters={setFilters}
        filterList={filterList}
        currentTab={currentTab}
        jobProfileList={jobProfileList}
        setJobProfileState={setJobProfileState}
        jobProfileState={jobProfileState}
        dataLoading={dataLoading}
      />
      <br />

      <TabPanel value={value} index="two">
        <ChatTable
          selectedTab={value}
          loader={loading}
          row={data}
          selectRow={selected}
          setSelectedStudent={setSelectedStudent}
          setSelectedID={setSelectedID}
          selectedID={selectedID}
          dataLoading={dataLoading}
          setData={setData}
          currentTab={currentTab}
        />
        <div style={{ marginTop: "30px" }}>
          <AddChatTeam
            collegeData={collegeMember}
            selected_member={selected_member}
            setMembers={setMembers}
            setSelectedMemID={setSelectedMemID}
            selectedMemID={selectedMemID}
            memLoading={memLoading}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index="three">
        <ChatTable
          selectedTab={value}
          loader={loading}
          row={data}
          selectRow={selected}
          setSelectedStudent={setSelectedStudent}
          setSelectedID={setSelectedID}
          selectedID={selectedID}
          dataLoading={dataLoading}
          setData={setData}
          currentTab={currentTab}
        />
      </TabPanel>
    </div>
  );
}
