import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Typography, Grid, Tooltip } from "@material-ui/core";
import { truncate, truncateLarge } from "../../../utility/ellipsify";

// import { CircularProgress } from "@mui/material";

const column2 = [
  // { field: "id", headerName: "No", width: 100 },
  {
    field: "userid",
    headerName: "Enrollment Id",
    width: 150,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Enrollment Id
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.userid}
      </span>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    width: 130,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.name}
      </span>
    ),
  },
  // {
  //   field: "profile",
  //   width: 160,
  //   renderHeader: (params) => (
  //     <strong
  //       style={{
  //         color: "#5a6268",
  //         marginLeft: "25px",
  //       }}
  //     >
  //       Job Profile
  //     </strong>
  //   ),
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params.row.profile}
  //     </span>
  //   ),
  //   // valueGetter: params =>
  //   //   `${params.getValue(params.id, "firstName") || ""} ${
  //   //     params.getValue(params.id, "lastName") || ""
  //   //   }`,
  // },
  {
    field: "batch",
    headerName: "Batch",
    type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        Batch
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.batch}
      </span>
    ),
  },
  {
    field: "degree",
    type: "text",
    width: 130,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "7px",
        }}
      >
        Department
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.degree}
      </span>
    ),
  },
  {
    field: "branch",
    type: "text",
    width: 110,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Branch
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.branch}
      </span>
    ),
  },
  {
    field: "company_name",
    type: "text",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "40px",
        }}
      >
        Company Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.company_name}
      </span>
    ),
  },
  {
    field: "status",
    headerName: "Placement Round",
    type: "text",
    width: 110,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Placement Round
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.status}
      </span>
    ),
  },
];

export default function ChatTable({
  selectedTab,
  loader,
  row,
  selectRow,
  setSelectedStudent,
  selectedID,
  setSelectedID,
  dataLoading,
  currentTab,
}) {
  const [pageSize, setPageSize] = React.useState(20);

  /*  if (loader) {
    return null;
  } */
  const columns = [
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.enrollment_id}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            textAlign: "left",
            display: "flex",
            marginLeft: "40px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid container wrap="nowrap">
            <Grid
              item
              xs
              zeroMinWidth
              style={{
                textAlign: "left",
                // display: "flex",
                //    margin: "auto",
                fontSize: "14px",
                marginLeft: "20px",
              }}
            >
              <Tooltip title={params.row.name} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "center",
        //     display: "flex",
        //     margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   {params.row.name}
        // </span>
      ),
    },

    {
      field: "batch",
      headerName: "Batch",
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            textAlign: "center",
            display: "flex",
            marginLeft: "45px",
          }}
        >
          Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.batch}
        </span>
      ),
    },
    {
      field: "degree",
      type: "text",
      width: 240,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "40px",
            textAlign: "center",
          }}
        >
          Department
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            // display: "flex",
            //margin: "auto",
            fontSize: "14px",
            marginLeft: "20px",
          }}
        >
          <Tooltip title={params?.row?.degree?.name} placement="right">
            <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
              {truncateLarge(params?.row?.degree?.name)}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "branch",
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            textAlign: "left",
            display: "flex",
            marginLeft: "40px",
          }}
        >
          Branch
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid container wrap="nowrap">
            <Grid
              item
              xs
              zeroMinWidth
              style={{
                textAlign: "left",
                //display: "flex",
                marginLeft: "20px",
                fontSize: "14px",
              }}
            >
              <Tooltip title={params?.row?.course?.name} placement="right">
                <Typography color="inherit" style={{ fontSize: "14px" }}>
                  {truncateLarge(params?.row?.course?.name)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "center",
        //     display: "flex",
        //     margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   {params.row.branch}
        // </span>
      ),
    },
    currentTab === "three" && {
      field: "company_name",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "40px",
          }}
        >
          Company Name
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.company_name}
        </span>
      ),
    },

    currentTab === "three" && {
      field: "job_title",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "40px",
          }}
        >
          Job Title
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.job_title}
        </span>
      ),
    },
    currentTab === "two"
      ? {
          field: "status",
          headerName: "Status",
          type: "text",
          width: 150,
          renderHeader: (params) => (
            <strong
              style={{
                color: "#5a6268",
                marginLeft: "40px",
              }}
            >
              Status
            </strong>
          ),
          renderCell: (params) => (
            <span
              style={{
                textAlign: "center",
                display: "flex",
                margin: "auto",
                fontSize: "14px",
              }}
            >
              {params.row.status}
            </span>
          ),
        }
      : {
          field: "application_status",
          //headerName: "Placement Round",
          type: "text",
          width: 180,
          renderHeader: (params) => (
            <strong
              style={{
                color: "#5a6268",
                marginLeft: "15px",
              }}
            >
              Placement Round
            </strong>
          ),
          renderCell: (params) => (
            <span
              style={{
                textAlign: "center",
                display: "flex",
                margin: "auto",
                fontSize: "14px",
              }}
            >
              {params?.row?.application_status}
            </span>
          ),
        },
  ];

  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        style={{
          borderRadius: "8px",
          boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
          backgroundColor: "#fff",
          border: "none",
        }}
        rows={row?.length > 0 ? row : []}
        columns={/* selectedTab === "two" ?  */ columns /*  : column2 */}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        pagination
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selectedID}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = row?.filter((row) => selectedIDs.has(row.id));
          setSelectedStudent(selectedRowData);
          setSelectedID(ids);
        }} /* {selectRow} */
        loading={dataLoading}
        componentsProps={{
          pagination: {
            style: {
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            },
          },
        }}
      />
    </div>
  );
}
