import {
  Button,
  //FormControl,
  Grid,
  IconButton,
  InputBase,
  //InputLabel,
  Menu,
  //MenuItem,
  Paper,
  Switch,
  Box,
  withStyles,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import AcceptCompany from "../../DialogSection/AcceptCompany";
import BlockCompany from "../../DialogSection/BlockCompany";
import BackendDrivenFilterDrawer from "../../DialogSection/FilterDrawer/BackendDrivenFilterDrawer";
import Calendar from "../Calendar/index3";
import FilterCTC from "../FilterCTC";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    fontSize: "14px",
    color: "#c5c4ba",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    display: "flex",
    height: "40px",
    paddingLeft: "30px",
    marginRight: "10px",
    paddingRight: "30px",
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "8px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
  clearIndicator: {
    backgroundColor: "gray",
    "& span": {
      "& svg": {
        backgroundColor: "red",
      },
    },
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')",
        },
      },
    },
  },
}));

export default function CompanyActionBar({
  filtersCompany,
  setFiltersCompany,
  setClickSearchButton,
  selectedRowsData,
  clickSearchButton,
  setSelectedRowsData,
  setApplyClickedCTC,
  selectedID,
  setSelectedID,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  fromGroup,
  backendDrivenFilter,
  setBackendDrivenFilter = () => {},
  filterData,
  filterLoading,
  loading,
  setRefreshData,
  toggleViewCollege,
  handleToggle,
  setToggleViewCollege,
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  useEffect(() => {
    if (
      backendDrivenFilter?.innerFilters?.created &&
      backendDrivenFilter?.innerFilters?.created[0] &&
      backendDrivenFilter?.innerFilters?.created[0].value
    )
      setSelectedDate(backendDrivenFilter?.innerFilters?.created[0].value);
  }, [backendDrivenFilter?.innerFilters]);
  useEffect(() => {
    setBackendDrivenFilter((prev) => ({
      ...prev,
      innerFilters: {
        ...prev.innerFilters,
        created: [
          {
            filterParamName: "created",
            value: selectedDate,
            chipName: "Date",
          },
        ],
      },
    }));
  }, [selectedDate]);
  const [selectedCtcRange, setSelectedCtcRange] = useState({});
  useEffect(() => {
    setBackendDrivenFilter((prev) => ({
      ...prev,
      innerFilters: {
        ...prev.innerFilters,
        ctc: [
          {
            filterParamName: "ctc",
            value: selectedCtcRange,
            chipName: "Ctc",
          },
        ],
      },
    }));
  }, [selectedCtcRange]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const handleChange = (event) => {
    const name = event.target.name;
    setFiltersCompany({
      ...filtersCompany,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
    setFiltersCompany({ ...filtersCompany, created: selectedDate });
  }, [selectedDate]);

  const classes = useStyles();

  const industryList = useSelector((state) => state?.commonData?.industryData);

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* {!loading ? ( */}
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                disabled={loading}
                className={classes.input}
                placeholder="Search by Name"
                inputProps={{
                  style: { fontSize: "14px" },
                }}
                value={
                  backendDrivenFilter?.outerFilters?.search
                    ? backendDrivenFilter?.outerFilters?.search
                    : ""
                }
                onChange={(e) => {
                  setBackendDrivenFilter((prev) => ({
                    ...prev,
                    outerFilters: {
                      ...prev?.outerFilters,
                      search: e.target.value,
                    },
                  }));
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setClickSearchButton(true);
                  }
                }}
              />
              {backendDrivenFilter?.outerFilters?.search && (
                <IconButton
                  disabled={loading}
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setClickSearchButton(true);
                    setBackendDrivenFilter((prev) => ({
                      ...prev,
                      outerFilters: {
                        ...prev?.outerFilters,
                        search: "",
                      },
                    }));
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                disabled={loading}
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {/* <Grid item xs={2}>
            <MuiCustomTheme>
              <NewFormControl
                variant="outlined"
                //</Grid>className={classes.formControl}
              >
                <InputLabel
                  // className={classes.floatingLabelFocusStyle}
                  htmlFor="outlined-age-native-simple"
                  style={{
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontFamily: "Nunito",
                  }}
                >
                  Industry Type
                </InputLabel>
                <Select
                  // IconComponent={ExpandMoreIcon}
                  value={filtersCompany?.industry_type}
                  onChange={handleChange}
                  label="IndustriesType"
                  name="sector"
                  style={{
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontFamily: "Nunito",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300, borderRadius: "8px" },
                    },
                  }}
                  // inputProps={{
                  //   style: {
                  //     padding: "16px",
                  //     backgroundColor: "#fff",
                  //     borderRadius: "30px",
                  //     id: "filled-age-native-simple",
                  //   },
                  // }}
                  // MenuProps={{
                  //   style: {
                  //     marginTop: "55px",
                  //   },
                  // }}
                >
                  <MenuItem value={"All"}>
                    <Typography variant="body2"> All </Typography>
                  </MenuItem>

                  <MenuItem value={1}>
                    <Typography variant="body2"> Private</Typography>
                  </MenuItem>
                  <MenuItem value={2}>
                    <Typography variant="body2">Government </Typography>{" "}
                  </MenuItem>
                  <MenuItem value={3}>
                    <Typography variant="body2">Semi-Government</Typography>
                  </MenuItem>
                </Select>
              </NewFormControl>
            </MuiCustomTheme>
          </Grid>  */}
          {/* <Grid item xs={2}>
           
            <Autocomplete
              // classes={{
              //   clearIndicatorDirty: classes.clearIndicator,
              //   popupIndicator: classes.popupIndicator,
              // }}
              id="country-select-demo"
              options={industryList}
              getOptionLabel={(option) => option.name}
              value={filtersCompany.industryObj}
              onChange={(e, newValue) => {
                setFiltersCompany({ ...filtersCompany, industryObj: newValue });
              }}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.name}
                </Typography>
              )}
              style={{ background: "#fff", borderRadius: 8 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Industry"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Grid> */}
          {filtersCompany?.current_tab === "All" && (
            <>
              <Grid item xs={2}>
                <FilterCTC
                  ctcData={
                    backendDrivenFilter?.innerFilters["ctc"] &&
                    backendDrivenFilter?.innerFilters["ctc"][0]
                      ? backendDrivenFilter?.innerFilters["ctc"][0].value
                      : null
                  }
                  setCtcData={setSelectedCtcRange}
                  setApplyClickedCTC={setApplyClickedCTC}
                  loading={loading}
                  title={"CTC"}
                  subTitle={"LPA"}
                />
              </Grid>
              {/* {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
                <Grid item xs={2}>
                  <MuiCustomTheme>
                    <NewFormControl
                      variant="outlined"
                      //className={classes.formControl}
                    >
                      <InputLabel
                        //className={classes.floatingLabelFocusStyle}
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                      >
                        Status
                      </InputLabel>
                      <Select
                        // IconComponent={ExpandMoreIcon}
                        value={filtersCompany.status}
                        onChange={handleChange}
                        label="Status"
                        name="status"
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: 300, borderRadius: "8px" },
                          },
                        }}
                        // inputProps={{
                        //   style: {
                        //     padding: "16px",
                        //     backgroundColor: "#fff",
                        //     borderRadius: "30px",
                        //   },
                        // }}
                        // MenuProps={{
                        //   style: {
                        //     marginTop: "55px",
                        //   },
                        // }}
                      >
                        <MenuItem value={"ALL"}>
                          <Typography variant="body2"> All </Typography>
                        </MenuItem>
                        <MenuItem value={"ACTIVE"}>
                          <Typography variant="body2"> Active </Typography>
                        </MenuItem>
                        <MenuItem value={"INACTIVE"}>
                          <Typography variant="body2"> Blocked </Typography>
                        </MenuItem>
                      </Select>
                    </NewFormControl>
                  </MuiCustomTheme>
                </Grid>
              )} */}
            </>
          )}
          <Grid item xs={2}>
            {" "}
            <Calendar
              title="Action Date"
              selectedDate={
                backendDrivenFilter?.innerFilters["created"] &&
                backendDrivenFilter?.innerFilters["created"][0]
                  ? backendDrivenFilter?.innerFilters["created"][0].value
                  : null
              }
              setSelectedDate={setSelectedDate}
              loading={loading}
            />
          </Grid>
          <Grid item xs={2}>
            {/* {filterData.length ? ( */}
            <BackendDrivenFilterDrawer
              backendDrivenFilter={backendDrivenFilter}
              setBackendDrivenFilter={setBackendDrivenFilter}
              filterData={filterData}
              filterLoading={filterLoading}
              loading={loading}
            />
            {/* ) : null} */}
          </Grid>
          {/* {groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
            <Grid
              item
              md={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 0,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginRight: 12,
                }}
              >
                <Typography
                  color={toggleViewCollege ? "primary" : "#6C757D"}
                  style={{ fontSize: 16, marginRight: 5 }}
                >
                  Show Colleges
                </Typography>
                <AntSwitch
                  checked={toggleViewCollege}
                  onChange={handleToggle}
                  name="checkedC"
                />
              </Box>
            </Grid>
          )} */}
          {/* <Grid item xs={6} className={classes.grid}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={checked}
                size="small"
                color="primary"
                onChange={handleCheckChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />

              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#51c03e",
                }}
              />
              <Typography
                variant="body2"
                style={{ marginLeft: "10px", fontWeight: "bold" }}
              >
                Active
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={checked}
                size="small"
                color="primary"
                onChange={handleCheckChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />

              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#f64a3f",
                }}
              />
              <Typography
                variant="body2"
                style={{ marginLeft: "10px", fontWeight: "bold" }}
              >
                Inactive
              </Typography>
            </div>
          </Grid> */}
          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_company"
          ) &&
            selectedRowsData?.length > 0 &&
            filtersCompany?.current_tab === "All" && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {selectedRowsData?.length === 1 && (
                  <Link
                    to={`/edit-company-profile/${selectedRowsData[0].id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                    >
                      Edit
                    </Button>
                  </Link>
                )}
                {selectedRowsData?.every(
                  (item) => item?.last_job_post === "NA"
                ) && (
                  <>
                    <BlockCompany
                      deleteUser={true}
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      selectedID={selectedID}
                      setSelectedID={setSelectedID}
                      collegeId={collegeId}
                      setCallNavbar={setCallNavbar}
                    />
                    {fromGroup && selectedRowsData?.length === 1 && (
                      <>
                        <BlockCompany
                          deleteUser={false}
                          selectedRowsData={selectedRowsData}
                          setSelectedRowsData={setSelectedRowsData}
                          selectedID={selectedID}
                          setSelectedID={setSelectedID}
                          collegeId={collegeId}
                          setCallNavbar={setCallNavbar}
                          fromGroup={fromGroup}
                        />
                      </>
                    )}
                    {!fromGroup && (
                      <>
                        <BlockCompany
                          deleteUser={false}
                          selectedRowsData={selectedRowsData}
                          setSelectedRowsData={setSelectedRowsData}
                          selectedID={selectedID}
                          setSelectedID={setSelectedID}
                          collegeId={collegeId}
                          setCallNavbar={setCallNavbar}
                        />
                      </>
                    )}
                  </>
                )}

                {/* <AddCompanyPlacement />
            <AddNewPlacementCycle /> */}
                {/* <UploadExcelFile /> */}
                {/* <SendEd /> */}
              </Grid>
            )}

          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_company"
          ) &&
            selectedRowsData?.length > 0 &&
            filtersCompany?.current_tab === "Verify" && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <AcceptCompany
                    deleteUser={true}
                    selectedRowsData={selectedRowsData}
                    setSelectedRowsData={setSelectedRowsData}
                    selectedID={selectedID}
                    setSelectedID={setSelectedID}
                    collegeId={collegeId}
                    setCallNavbar={setCallNavbar}
                    setRefreshData={setRefreshData}
                  />
                  <AcceptCompany
                    deleteUser={false}
                    selectedRowsData={selectedRowsData}
                    setSelectedRowsData={setSelectedRowsData}
                    selectedID={selectedID}
                    setSelectedID={setSelectedID}
                    collegeId={collegeId}
                    setCallNavbar={setCallNavbar}
                    setRefreshData={setRefreshData}
                  />
                </Grid>
              </>
            )}
        </Grid>
      </div>
    </>
  );
}
