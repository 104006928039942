import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./trending.css";
import ModalVideo from "react-modal-video";
import "../../../style/reactVideo.css";
import { Link, Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

function AllProgram({ programData, loading }) {
  var settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const [isOpen, setOpen] = useState(false);
  const [isvideo, setvideo] = useState();

  const handleClickOpen = (id) => {
    setOpen(true);
    setvideo(id);
  };

  // const temp = programData?.filter((doc) => doc.banner_id === isvideo);

  const handleClose = () => {
    setOpen(false);
  };

  // const VID_REGEX =
  //   /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  return (
    <>
      <div>
        {loading ? (
          <Typography variant="h4" style={{ marginTop: 15, marginBottom: 15 }}>
            🔥 Hot Faculty
            <span
              style={{
                background:
                  "linear-gradient(90deg, rgba(255, 85, 36, 0.2025) 0%, rgba(250, 250, 250, 0.45) 100%)",
                borderRadius: 4,
              }}
            >
              {" "}
              Development Program
            </span>
          </Typography>
        ) : (
          <Skeleton
            animation="wave"
            variant="rect"
            style={{
              height: "30px",
              width: "200px",
              borderRadius: 4,
              marginBottom: "15px",
            }}
          />
        )}
        <div className="slider-container">
          {" "}
          <div>
            {loading ? (
              <Slider {...settings}>
                {programData?.map((value, index) => (
                  // <Link href={value?.redirection_url} target="_blank">
                  <div
                    onClick={() => {
                      window.open(value?.banner_url, "_blank");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${value?.banner_path})`,
                        height: 180,
                        width: 600,
                        position: "relative",
                        borderRadius: 8,
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    {/* <span>
                  proper banner than use img tag code
                  </span> */}
                    {/* <img
                      src={value?.banner_path}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 8,
                      }}
                      alt="getwork-banner"
                    /> */}
                  </div>
                  // </Link>
                ))}
              </Slider>
            ) : (
              <>
                <Grid container spacing={2}>
                  {[1, 2]?.map((item) => (
                    <Grid item md={6}>
                      <div style={{ width: "100%", marginRight: 10 }}>
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          style={{
                            height: "180px",
                            width: "100%",
                            borderRadius: 10,
                          }}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </div>
          <div>
            {/* {temp?.map((item) => (
              <ModalVideo
                channel="youtube"
                videoId={item?.redirection_url?.match(VID_REGEX)[1]}
                youtube={{ mute: 0, autoplay: 0 }}
                isOpen={isOpen}
                onClose={handleClose}
              />
            ))} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllProgram;
