import { Breadcrumbs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  collegeDetailSelectorFn,
  collegeUserDetailSelectorFn,
} from "../../api/SignIn/selector";
import CreateGroup from "../../components/DialogSection/CreateGroup";
import ChatTab from "../../components/Header/ChatTab";
import { db } from "../../utility/firebaseConfig";
import config from "../../config";
import { sanchalanAxiosGetReq } from "../../api/BaseApi/apiBase";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    height: "50px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
  },
  gridtext: {
    marginTop: "20px",
    ///   display: "flex",
    alignItems: "center",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #e2e6ea",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function ChatGroup({ selected }) {
  const params = useParams();

  const classes = useStyles();
  const [selectedStudent, setSelectedStudent] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [AllUsers, setAllUsers] = React.useState([]);
  const [AllMembers, setAllMembers] = React.useState([]);
  const [temp, setTemp] = React.useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedMemID, setSelectedMemID] = useState([]);
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const collegeUserDetail = useSelector(collegeUserDetailSelectorFn);

  const college_id = collegeDetail?.college_id;
  const location = useLocation();
  const [runOnce, setRunOnce] = useState(false);

  const [currentTab, setCurrentTab] = useState(
    location?.groupTab ? location?.groupTab : "two"
  );
  const [filterList, setFilterList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [apiCalledOnce, setApiCalledOnce] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [memLoading, setMemLoading] = useState(false);
  const [jobProfileList, setJobProfileList] = useState([]);
  const [jobProfileState, setJobProfileState] = useState(null);
  const [groupTab, setGroupTab] = useState(
    location?.groupTab ? location?.groupTab : null
  );
  const [data, setData] = useState([]);
  const [collegeMember, setCollegeMember] = useState([]);
  const [firstJobPofileData, setFirstJobPofileData] = useState(false);
  useEffect(() => {
    if (AllUsers?.length > 0 && AllMembers?.length > 0 && !runOnce) {
      let selctedStu = [];
      let selectedStuIds = [];
      let selectMem = [];
      let selectedMemIds = [];

      location?.state?.length > 0 &&
        location.state.map((obj) => {
          if (obj.is_admin) {
            let obj1 = { ...obj, id: obj.newId };
            selectMem.push(obj1);
            selectedMemIds.push(obj1.id);
          } else {
            let obj1 = { ...obj, id: obj.newId };
            selctedStu.push(obj1);
            selectedStuIds.push(obj1.id);
          }
          setSelectedID(selectedStuIds);
          setSelectedStudent(selctedStu);
          setSelectedMemID(selectedMemIds);
          setMembers(selectMem);
          setRunOnce(true);
        });
    }
  }, [AllUsers, AllMembers]);

  // useEffect(async () => {
  //   if (college_id) {
  //     if (params.thread_id) {
  //       var cur_members_id = [];
  //       const chat_group_q = query(collection(db, config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV"), where("__name__", "==", params.thread_id));

  //       const test = onSnapshot(chat_group_q, (querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           setTemp(doc.data().users);
  //         });
  //       });
  //     } else {
  //       const q = query(collection(db, config.prod ? "STUDENTS_PROD" : "STUDENTS_DEV"),where('cur_college.id', "==", college_id))
  //       const querySnapshot = await getDocs(q);
  //       console.log("GET STUDENT FIREBASE COUNT", querySnapshot.docs.length)
  //       const objectsArray = [];
  //       querySnapshot.forEach((user) => {
  //         if (user.data().cur_college.id == parseInt(college_id)) {
  //           objectsArray.push(user.data());
  //         }
  //       });
  //       const q2 = query(collection(db, config.prod ? "MEMBERS_PROD" : "MEMBERS_DEV"), where('college.id', "==", college_id))
  //       const collegeStaff = await getDocs(q2);
  //       console.log("GET MEMBERS FIREBASE COUNT", collegeStaff.docs.length)
  //       const objectsArray1 = [];
  //       collegeStaff.forEach((user) => {
  //         if (user.data().college.id == parseInt(college_id)) {
  //           objectsArray1.push(user.data());
  //         }
  //       });
  //       setAllMembers(objectsArray1);
  //       setAllUsers(objectsArray);
  //     }
  //   }
  // }, [college_id]);

  // useEffect(async () => {
  //   var user_id = [];
  //   temp.map((user) => {
  //     user_id.push(user.id);
  //   });
  //   const q = query(collection(db, config.prod ? "STUDENTS_PROD" : "STUDENTS_DEV"),where('cur_college.id', "==", college_id))
  //   const querySnapshot = await getDocs(q);
  //   console.log("GET STUDENT FIREBASE COUNT", querySnapshot.docs.length)
  //   const objectsArray = [];
  //   querySnapshot.forEach((user) => {
  //     if (user.data().cur_college.id == parseInt(college_id) && !user_id.includes(user.data().id)) {
  //       objectsArray.push(user.data());
  //     }
  //   });
  //   const q2 = query(collection(db, config.prod ? "MEMBERS_PROD" : "MEMBERS_DEV"), where('college.id', "==", college_id))
  //   const collegeStaff = await getDocs(q2);
  //   console.log("GET MEMBERS FIREBASE COUNT", collegeStaff.docs.length)
  //   const objectsArray1 = [];
  //   collegeStaff.forEach((user) => {
  //     if (user.data().college.id == parseInt(college_id) && !user_id.includes(user.data().id)) {
  //       objectsArray1.push(user.data());
  //     }
  //   });
  //   setAllMembers(objectsArray1);
  //   setAllUsers(objectsArray);
  // }, [temp]);

  const [filters, setFilters] = useState({
    search: null,
    course: null,
    degree: null,
    batch: null,
    /*  status: null,
    status: "Approved", */
  });
  const getFiltersParam = () => {
    var filterString = "&";
    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        if (key === "status") {
          filterString += `${key}=${value.id}&`;
        }
      }
      if (value) {
        if (key === "application_status") {
          filterString += `${key}=${value.id}&`;
        }
      }
      if (value) {
        if (key === "degree") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      if (value) {
        if (key === "course") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      if (value) {
        if (key === "batch") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      // if (value) {
      //   if (key === "company") {
      //     filterString += `${key}_name=${value?.name}&`;
      //   }
      // }
      if (value) {
        if (key === "search") {
          filterString += `${key}=${value}&`;
        }
      }
      /*  if (value) {
        if (key === "status") {
          filterString += `${key}=${value?.name}&`;
        }
      } */
      // if (value) {
      //   if (key === "cgpa_min") {
      //     filterString += `min_cgpa=${Number(value)}&`;
      //   }
      // }
      // if (value) {
      //   if (key === "cgpa_max") {
      //     filterString += `max_cgpa=${Number(value)}&`;
      //   }
      // }
      // if (value) {
      //   if (key === "job_type") {
      //     filterString += `job_type=${value?.id}&`;
      //   }
      // }
    }
    filterString = filterString.slice(0, -1);
    return filterString;
  };
  const getJobProfile = () => {
    let str = ``;
    if (currentTab === "three" && jobProfileState?.id) {
      str += `&job_id=${jobProfileState?.id}`;
    }
    return str;
  };
  /*   useEffect(() => {
    const url = `api/manage_student/off_campus_jobs/${getFiltersParam()}`;
    const getDataOffCampus = async () => {

      setStudentDataOffCampus([]);
      setLoading(true);
      try {
        const res = await sanchalanAxiosGetReq(url);
        // console.log("response from backend", res);
        // console.log("filterListOffCampusData", res?.data?.data?.filter_data);
        if (res.data.success) {
          setLoading(false);
          setStudentDataOffCampus(res?.data?.data?.data);
        } else {
          setLoading(false);
          // console.log("error ocuured");
        }
      } catch (error) {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      }
    };
    if (firstCall) {
      getDataOffCampusFirstTime();
      getNavbarData();
    } else {
      getDataOffCampus();
      getNavbarData();
    }
  }, [filters, refreshData]); */

  const fetchReqJobDetails = async () => {
    const url = `/api/chat/student/jobs/list/?college_id=${college_id}`; //${getFiltersParam()}  /api/chat/student/jobs/list/   api/chat/college/jobs/
    try {
      setDataLoading(true);
      const res = await sanchalanAxiosGetReq(url);
      if (res.data.success) {
        setJobProfileList(res?.data?.data?.data);
        await setJobProfileState(res?.data?.data?.data[0]);
      } else {
        //console.log("error ocuured");
      }
    } catch (error) {
      //console.log(error.message);
      throw error;
    }
  };

  const fetchStudentListFirstTime = async () => {
    const url = `api/chat/student/view/?college_id=${college_id}${getFiltersParam()}${getJobProfile()}`;
    setDataLoading(true);
    try {
      const res = await sanchalanAxiosGetReq(url);
      if (res.data.success) {
        setData(res?.data?.data?.data);
        setAllUsers(res?.data?.data?.data);
        setFilterList(res?.data?.data?.filter_data);
        setApiCalledOnce(true);
      } else {
        //console.log("error ocuured");
      }
      setDataLoading(false);
    } catch (error) {
      //console.log(error.message);
      setDataLoading(false);
      throw error;
    }
  };

  const fetchMembersList = async () => {
    const url = `/api/chat/team/view/?college_id=${college_id}`; //`api/chat/student/view/?college_id=${college_id}`;
    setMemLoading(true);
    try {
      const res = await sanchalanAxiosGetReq(url);

      if (res.data.success) {
        setCollegeMember(res?.data?.data?.data);
        setAllMembers(res?.data?.data?.data);
      } else {
        //console.log("error ocuured");
      }
      setMemLoading(false);
    } catch (error) {
      // console.log(error.message);
      setMemLoading(false);
      throw error;
    }
  };

  const fetchStudentList = async () => {
    const url = `api/chat/student/view/?college_id=${college_id}${getFiltersParam()}${getJobProfile()}`;
    setDataLoading(true);
    try {
      let selectedStu = [...selectedStudent];
      let selectedId = [...selectedID];
      setData([]);
      const res = await sanchalanAxiosGetReq(url);

      if (res.data.success) {
        !filterList?.length > 0 && setFilterList(res?.data?.data?.filter_data);
        let arr = [...selectedStu, ...res?.data?.data?.data];
        const unique = [...new Map(arr.map((m) => [m.id, m])).values()];
        console.log("unique", unique);
        setData(unique);
        //setStudentList(unique);
        setAllUsers(unique);
        setSelectedStudent(selectedStu);
        setSelectedID(selectedId);
      } else {
        //console.log("error ocuured");
      }
      setDataLoading(false);
    } catch (error) {
      //console.log(error.message);
      setDataLoading(false);
      throw error;
    }
  };

  const fetchStudentListJobProfile = async () => {
    const url = `api/chat/student/view/job/?college_id=${college_id}${getFiltersParam()}${getJobProfile()}`; ///api/chat/student/view/job/?job_id=6294d6177bf7132fc8eba360
    setDataLoading(true);
    try {
      let selectedStu = [...selectedStudent];
      let selectedId = [...selectedID];
      setData([]);
      const res = await sanchalanAxiosGetReq(url);
      if (res.data.success) {
        if (!firstJobPofileData) {
          setFilterList(res?.data?.data?.filter_data);
          setData(res?.data?.data?.data);
          setAllUsers(res?.data?.data?.data);
          setFirstJobPofileData(true);
        } else {
          let arr = [...selectedStu, ...res?.data?.data?.data];
          const unique = [...new Map(arr.map((m) => [m.id, m])).values()];
          console.log("unique", unique);
          setData(unique);
          //setStudentList(unique);
          setAllUsers(unique);
          setSelectedStudent(selectedStu);
          setSelectedID(selectedId);
        }
      }
      setDataLoading(false);
    } catch (error) {
      //console.log(error.message);
      setDataLoading(false);
      throw error;
    }
  };

  const state = useSelector((state) => state);
  useEffect(() => {
    if (college_id && currentTab === "two") {
      if (!apiCalledOnce) {
        fetchStudentListFirstTime();
        fetchMembersList();
      } else {
        fetchStudentList();
        collegeMember?.length < 1 && fetchMembersList();
      }
    }
  }, [college_id, filters, currentTab]);

  useEffect(() => {
    if (college_id && currentTab === "three") {
      if (!apiCalledOnce && !jobProfileState) {
        fetchReqJobDetails();
        fetchMembersList();
      } else if (jobProfileState?.id) {
        fetchStudentListJobProfile();
        collegeMember?.length < 1 && fetchMembersList();
      }
    }
  }, [college_id, filters, currentTab, jobProfileState?.id]);

  const stateNew = useSelector((state) => state);

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/chat-room" style={{ textDecoration: "none" }}>
          <Typography color="primary" variant="body2">
            Chat Room
          </Typography>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          <Typography color="secondary" variant="body2">
            Create Group
          </Typography>
        </Link>
      </Breadcrumbs>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={classes.gridtext}>
          <Typography variant="h2">Chat Room</Typography>

          <Typography variant="body2" style={{ color: "#6c757d" }}>
            Connect with students in groups based on
          </Typography>
        </div>
        <div>
          <CreateGroup
            selected={selectedStudent}
            selected_member={members}
            currentTab={currentTab}
          />
        </div>
      </div>
      <br />
      {/* <AssignmentTabs /> */}
      <ChatTab
        /*  studentList={studentList}
        teamMemberList={teamMemberList} */
        setSelectedStudent={setSelectedStudent}
        selectedStudent={selectedStudent}
        setMembers={setMembers}
        setSelectedID={setSelectedID}
        selectedID={selectedID}
        setSelectedMemID={setSelectedMemID}
        selectedMemID={selectedMemID}
        filters={filters}
        setFilters={setFilters}
        filterList={filterList}
        dataLoading={dataLoading}
        memLoading={memLoading}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        setApiCalledOnce={setApiCalledOnce}
        jobProfileList={jobProfileList}
        setJobProfileState={setJobProfileState}
        jobProfileState={jobProfileState}
        groupTab={groupTab}
        data={data}
        setData={setData}
        collegeMember={collegeMember}
        setCollegeMember={setCollegeMember}

        /*    selected={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = AllUsers.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedStudent(selectedRowData);
        }} */
        // selected_member={(member_ids) => {
        //   const selectedIDs = new Set(member_ids);
        //   const selectedRowData = AllMembers.filter((row) =>
        //     selectedIDs.has(row.id)
        //   );
        //   setMembers(selectedRowData);
        // }}
      />
      <br />

      {/* </Layout> */}
    </>
  );
}
