import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Check } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import { praveshAxiosGetReq } from "../../../../api/BaseApi/apiBase";
import { generateCsvNewWithoutSpace } from "../../../../utility/commaSeparatedStrings";
import SavedAchievements from "./SavedAchievements";
import SavedCertification from "./SavedCertification";
import SavedContact from "./SavedContact";
import SavedEducation from "./SavedEducation";
import SavedHobbies from "./SavedHobbies";
import SavedLanguage from "./SavedLanguage";
import SavedLinks from "./SavedLinks";
import SavedProject from "./SavedProject";
import SavedSkills from "./SavedSkills";
import SavedWorkExperience from "./SavedWorkExperience";

const useStyles = makeStyles((theme) => ({
  //   newDialog: {
  //     position: "absolute",
  //   },
  margin: {
    width: "100%",
  },
  newBtnStyle: {
    borderRadius: "8px",
    boxShadow: "none",
    height: "45px",
  },
  alignPill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
}));

export default function ProfileApproveDialog(props) {
  const classes = useStyles();
  const alert = useAlert();
  const {
    title,
    open,
    handleClose,
    data,
    formType,
    identifier,
    StudentDetailsAPI,
  } = props;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const router = useLocation();

  const [approveRejectData, setApproveRejectData] = React.useState(null);
  const [allApproveRejectIds, setAllApproveRejectIds] = React.useState([]);
  const [approveRejectDataLoading, setApproveRejectDataLoading] =
    React.useState(false);
  const getApproveRejectData = async () => {
    if (props?.userId && props?.type) {
      const url = `api/saas/profile/get/segment/?change_status=PENDING&user_id=${props?.userId}&change_in=${props?.type}`;
      setApproveRejectDataLoading(true);

      try {
        const res = await praveshAxiosGetReq(url);
        if (res.data.success) {
          const statusResponse = res?.data?.data;
          const updatedResponse = [];
          const allIds = [];
          statusResponse?.map((item) => {
            const obj = { ...item };
            obj.updatedData = { ...obj?.current_data, ...obj?.change_data };
            updatedResponse.push(obj);
            allIds?.push(item?.id);
          });
          setAllApproveRejectIds(allIds);
          if (props?.type === "LANG" || props?.type === "HOB") {
            setApproveRejectData(statusResponse);
          } else {
            setApproveRejectData(updatedResponse);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setApproveRejectDataLoading(false);
      }
    }
  };

  const approveReject = async (ids, update, allBtnClicked = false) => {
    console.log(ids, update);
    const idString = generateCsvNewWithoutSpace(ids);
    const url = `api/saas/profile/update_approval/?update_id=${idString}&response=${update}`;
    //setApproveRejectDataLoading(true);

    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        alert.success(res.data?.data?.message);
        if (ids?.length === 1) {
          const arr = [...approveRejectData];
          const newArr = arr?.filter((item) => item?.id !== ids[0]);
          setApproveRejectData(newArr);
          StudentDetailsAPI(true);
        } else {
          setApproveRejectData([]);
          StudentDetailsAPI(true);
        }
        if (allBtnClicked) handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      //setApproveRejectDataLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getApproveRejectData();
    }
  }, [open]);

  // useEffect(() => {
  //   console.log("approveRejectData", approveRejectData);
  //   console.log("allApproveRejectIds", allApproveRejectIds);
  // }, [approveRejectData, allApproveRejectIds]);

  const formAction = () => {
    if (formType === "approveProfile") {
      return (
        <SavedWorkExperience
          data={approveRejectData}
          approveReject={approveReject}
        />
      );
    } else if (formType === "approveEducation") {
      return (
        <SavedEducation
          data={approveRejectData}
          approveReject={approveReject}
        />
      );
    } else if (formType === "approveCertification") {
      return (
        <SavedCertification
          data={approveRejectData}
          approveReject={approveReject}
        />
      );
    } else if (formType === "approveSkills") {
      return (
        <SavedSkills
          data={approveRejectData[0]}
          approveReject={approveReject}
        />
      );
    } else if (formType === "approveLinks") {
      return (
        <SavedLinks data={approveRejectData[0]} approveReject={approveReject} />
      );
    } else if (formType === "approveAchievements") {
      return (
        <SavedAchievements
          data={approveRejectData[0]}
          approveReject={approveReject}
        />
      );
    } else if (formType === "approveExtraCurricular") {
      return (
        <SavedAchievements
          from="extraCurricular"
          data={approveRejectData[0]}
          approveReject={approveReject}
        />
      );
    } else if (formType === "approveHobbies") {
      return (
        <SavedHobbies
          data={approveRejectData[0]}
          approveReject={approveReject}
        />
      );
    } else if (formType === "approveLanguages") {
      return (
        <SavedLanguage
          data={approveRejectData[0]}
          approveReject={approveReject}
        />
      );
    } else if (
      formType === "approveProject" ||
      formType === "approvePublications"
    ) {
      return (
        <SavedProject data={approveRejectData} approveReject={approveReject} />
      );
    } else
      return (
        <SavedContact
          data={approveRejectData?.length > 0 ? approveRejectData[0] : null}
          approveReject={approveReject}
        />
      );
  };

  return (
    <div>
      <Dialog
        classes={{
          paper: classes.newDialog,
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 8,
            minHeight: 200,
          },
        }}
      >
        <DialogContent style={{ padding: "20px" }}>
          <div className={classes?.alignPill}>
            {" "}
            <Typography
              variant="h4"
              style={{
                fontWeight: 600,
                marginBottom: 10,
              }}
            >
              {title}
            </Typography>
            {approveRejectData?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  // position: "absolute",
                  // right: 0,
                  // bottom: 5,
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    height: "35px",
                    boxShadow: "none",
                  }}
                  size="small"
                  onClick={() => {
                    approveReject(allApproveRejectIds, "APPROVED", true);
                  }}
                >
                  <Typography
                    style={{
                      color: "#fff",
                      fontSize: "14px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Accept all
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#FF0000",
                    height: "35px",
                    boxShadow: "none",
                  }}
                  size="small"
                  onClick={() => {
                    approveReject(allApproveRejectIds, "REJECTED", true);
                  }}
                >
                  <Typography
                    style={{
                      color: "#fff",
                      fontSize: "14px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    {" "}
                    Decline all
                  </Typography>
                </Button>
              </div>
            ) : null}
          </div>

          {approveRejectDataLoading ? (
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : approveRejectData?.length > 0 ? (
            formAction()
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No Changes</Typography>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
