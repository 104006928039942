import {
  FormControl,
  Grid,
  InputLabel, MenuItem, Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    //border: ".5px solid black",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
  },
}));

function AssignmentActionBar({
  compantData,
  jobData,
  companyName,
  handleChange,
  handleJobprofile,
  jobtitle,
}) {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          marginTop: "25px",
          marginBottom: "10px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2} className={classes.grid}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.floatingLabelFocusStyle}>
                Company Name
              </InputLabel>
              <Select
                value={companyName}
                onChange={handleChange}
                label="Company Name"
                inputProps={{
                  style: {
                    padding: "16px",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  },
                }}
                MenuProps={{
                  style: {
                    marginTop: "55px",
                  },
                }}
              >
                {/* <option aria-label="None" value="" />
                {compantData?.map(item => {
                  return <option value={item.id}>{item.name}</option>;
                })} */}

                <MenuItem aria-label="None" value="">
                  CLEAR
                </MenuItem>
                {compantData?.map(item => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} className={classes.grid}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.floatingLabelFocusStyle}>
                Job Title
              </InputLabel>
              <Select
                value={jobtitle}
                onChange={handleJobprofile}
                style={{ borderRadius: "8px" }}
                label="Job Title"
                inputProps={{
                  style: {
                    padding: "16px",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  },
                }}
                MenuProps={{
                  style: {
                    marginTop: "55px",
                  },
                }}
              >
                <MenuItem aria-label="None" value="">
                  CLEAR
                </MenuItem>
                {jobData?.map(item => {
                  return <MenuItem value={item.id}>{item.job_title}</MenuItem>;
                })}
                {/*                 
                <option aria-label="None" value="" />
                {jobData?.map(item => {
                  return <option value={item.id}>{item.job_title}</option>;
                })} */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default AssignmentActionBar;
