import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import EducationForm from "./EductionForm";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
  },
  newBtnStyle: {
    borderRadius: "8px",
    boxShadow: "none",
    height: "45px",
  },
}));

export default function AddEducationDialog(props) {
  const classes = useStyles();
  const alert = useAlert();
  const {
    collegeList,
    boardsList,
    open,
    handleClose,
    editEducation,
    setEditEducation,
    saveEducation,
    eduDataLoading,
    isEdit,
  } = props;
  const [collegeNotListed, setCollegeNotListed] = useState(false);
  const [isCGPA, setIsCgpa] = useState(false);

  const validateForm = () => {
    if (!editEducation?.education) {
      alert?.error("Education type can't be empty");
      return false;
    }
    if (editEducation?.education > 2) {
      if (
        !collegeNotListed &&
        (!editEducation?.college || editEducation.college === 142)
      ) {
        alert.error("Select a college");
        return false;
      }
      if (collegeNotListed && !editEducation?.temp_college_name) {
        alert.error("Type a college");
        return false;
      }
      if (editEducation?.is_saas && !editEducation?.enrollment_id) {
        alert.error("Enter Enrollment Id");
        return false;
      }
      if (!editEducation?.degree_id) {
        alert.error("Degree can't be empty");
        return false;
      }
      if (!editEducation?.specialization_id) {
        alert.error("Specialization can't be empty");
        return false;
      }
    } else {
      if (!editEducation?.board) {
        alert.error("Select Board");
        return false;
      }
    }

    if (!editEducation?.start_date) {
      alert.error("Start Date can't be empty");
      return false;
    }
    if (!editEducation?.end_date) {
      alert.error("End Date can't be empty");
      return false;
    }
    if (
      !editEducation?.is_grade_system &&
      (!editEducation?.percentage ||
        editEducation?.percentage <= 0 ||
        editEducation?.percentage > 100)
    ) {
      alert.error("Invalid Percentage");
      return false;
    }
    if (
      editEducation?.is_grade_system &&
      (!editEducation?.cgpa ||
        editEducation?.cgpa <= 0 ||
        editEducation?.cgpa > 100)
    ) {
      alert.error("Invalid CGPA");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (collegeNotListed && !isEdit) {
      setIsCgpa(false);
      setEditEducation({
        ...editEducation,
        degree_id: null,
        degree: null,
        specialization: null,
        specialization_id: null,
        enrollment_id: null,
      });
    }
  }, [collegeNotListed]);

  const finalHandleClose = () => {
    setIsCgpa(false);
    setCollegeNotListed(false);
    handleClose();
  };

  useEffect(() => {
    if (
      Object.keys(editEducation).length !== 0 &&
      (!editEducation?.college || editEducation?.college === 142)
    ) {
      setCollegeNotListed(true);
    } else {
      setCollegeNotListed(false);
    }
  }, [editEducation?.college]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={finalHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
      >
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>{isEdit ? "Edit" : "Add"} Education</b>
          </Typography>
          <EducationForm
            editEducation={editEducation}
            setEditEducation={setEditEducation}
            collegeList={collegeList}
            boardsList={boardsList}
            collegeNotListed={collegeNotListed}
            setCollegeNotListed={setCollegeNotListed}
            isCGPA={isCGPA}
            setIsCgpa={setIsCgpa}
          />
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                onClick={finalHandleClose}
                color="primary"
                className={classes.newBtnStyle}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.newBtnStyle}
                onClick={() => {
                  if (validateForm()) {
                    saveEducation();
                  }
                }}
              >
                {eduDataLoading ? <CustomButtonCircularProgress /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
