import { BILLING_DETAILS } from "./action";

export const billingData = (state = {}, action) => {
  switch (action.type) {
    case BILLING_DETAILS:
      return { ...state, billing: action.payload };

    default:
      return state;
  }
};
