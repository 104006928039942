import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import pSBC from "shade-blend-color";
import { Typography, Chip } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ProfileUpdateRequest from "../../../../pages/ProfileUpdateRequest";
import ProfileApproveDialog from "../../EditStudent/ProfileApproveDialog/ProfileApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    position: "relative",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",
  },
  chipDisplay: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
}));

export default function WorkExpereince({
  data,
  userId,
  StudentDetailsAPI,
  fromProfileUpdate,
}) {
  const classes = useStyles();
  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <br />
      {data?.student_user_details?.work_ex?.length > 0 ||
      (fromProfileUpdate &&
        data?.student_profile_update &&
        Object.keys(data?.student_profile_update)?.includes("WORK")) ? (
        <>
          <div className={classes.paper}>
            <Typography variant="h6">Work Experience</Typography>

            {data?.student_user_details?.work_ex?.length > 0 &&
              data?.student_user_details?.work_ex?.map((item) => (
                <>
                  <div>
                    <Typography variant="body1" style={{ marginTop: "10px" }}>
                      {item?.job_designation}{" "}
                      {item?.company_name && " at " + item?.company_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="secondary"
                      style={{ marginTop: "5px", marginBottom: "7px" }}
                    >
                      {item?.job_description}
                    </Typography>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body2">
                        {" "}
                        {item?.start_date && item?.start_date !== null && (
                          <>
                            {moment(item?.start_date).format("MMM, YYYY")}{" "}
                            {item.end_date && item.end_date !== null ? (
                              <>
                                {" - "}
                                {moment(item?.end_date).format(
                                  "MMM, YYYY"
                                )}{" "}
                              </>
                            ) : (
                              <>- Present</>
                            )}
                          </>
                        )}{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ marginLeft: "5px", fontWeight: "bold" }}
                      >
                        {item?.job_type_name && "(" + item?.job_type_name + ")"}{" "}
                      </Typography>
                    </div>
                    <div className={classes.chipDisplay}>
                      {item?.skills &&
                        item?.skills.map((item, index) => {
                          return (
                            <li key={item.skill_id}>
                              <Chip
                                color="primary"
                                variant="outlined"
                                size="small"
                                label={item.skill_name}
                                className={classes.chip}
                              />
                            </li>
                          );
                        })}
                    </div>
                  </div>
                  <br />
                </>
              ))}
            {fromProfileUpdate &&
            data?.student_profile_update &&
            Object.keys(data?.student_profile_update)?.includes("WORK") ? (
              <div
                style={{
                  backgroundColor: "green ",
                  padding: "10px",
                  width: "160px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                  cursor: "pointer",
                }}
                onClick={handleClickOpen}
              >
                <Typography
                  style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
                >
                  {`Pending Request (${data?.student_profile_update?.WORK})`}
                </Typography>
              </div>
            ) : (
              <></>
            )}
            <ProfileApproveDialog
              title="Work Experience Request"
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              formType="approveProfile"
              data={data}
              userId={userId}
              type={"WORK"}
              StudentDetailsAPI={StudentDetailsAPI}
            />
          </div>
          <br />
        </>
      ) : null}
    </>
  );
}
