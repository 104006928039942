import React, { useEffect, useMemo, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Typography, useTheme } from "@material-ui/core";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import ReportTable from "./ReportTable";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import LastYearCampusBarGraph from "./LastYearCampusBarGraph";
import LastYearTable from "./LastYearTable";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";
import GraphSkeleton from "./GraphSkeleton";

function LastYearReportHeading({
  selectedPlacement,
  collegeId,
  heading,
  yAxis,
  xAxis,
  lastYears,
  setLastYears = ()=>{}
}) {
  const theme = useTheme();
  const [value, setValue] = useState(1);
  const [chartData, setChartData] = useState(null);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  function handleChange(id) {
    setValue(id);
  }

  useEffect(() => {
    if (selectedPlacement && heading) {
      let url;
      //  if(heading === "Course vs Student Applicant")
      //  url= `${BackendBaseApi.SANCHALAN}/api/placement_cycles/last_three_year/student_data/bar_chart/?college_id=${collegeId}`;
      if (heading === "Course vs Student")
        url = `${BackendBaseApi.SANCHALAN}/api/placement_cycles/last_three_year/student_data/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Course vs Student Placed(%)")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/student_placed_data/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Course vs Job Count")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/job_data/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Course vs Job Selection(%)")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/job_data_placed/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Job vs Salary Scale")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/job_salary_scale_data/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Placed Salary Scale")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/job_placed_salary_scale_data/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Course vs Highest CTC Offered")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/job_highest_ctc/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Course vs Highest CTC Placed")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/job_placed_highest_ctc/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Course vs Average CTC Offered")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/job_average_ctc/bar_chart/?college_id=${collegeId}`;
      else if (heading === "Course vs Average CTC Placed")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/last_three_year/job_placed_average_ctc/bar_chart/?college_id=${collegeId}`;

      setLoading(true);
      url &&
        axios
          .get(url, { withCredentials: true })
          .then((res) => {
            if (res?.data?.success) {
              setLastYears(res?.data?.data[0]?.years);
              setChartData(res?.data?.data?.slice(1));
              setLoading(false);
            }
          })
          .catch((err) => {
            alert.error(err?.response?.data?.error);
            setLoading(false);
          });
    }
  }, [selectedPlacement, heading]);


  const chartHelper = () => {
    const keys = chartData?.length && Object.keys(chartData[0]); // Assuming all objects have the same keys

    const arraysOfValues =
      chartData?.length &&
      keys.map((key) => {
        const res = chartData.map((obj) => obj[key]);
        return { [key]: res };
      });

    const resultObject =
      arraysOfValues?.length &&
      arraysOfValues?.reduce((acc, obj) => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        acc[key] = value;
        return acc;
      }, {});

    return { ...resultObject, yAxis: yAxis, xAxis: xAxis,lastYears: lastYears && lastYears , heading:heading};
  };

  const data = [
    {
      id: 2,
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TableChartOutlinedIcon style={{ marginRight: 5 }} /> Table
        </div>
      ),
      tab: (
        <div style={{ maxHeight: "300px", overflow: "scroll" }}>
          {" "}
          <LastYearTable chartDataForGraph={chartHelper()} />{" "}
        </div>
      ),
    },
    {
      id: 1,
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <BarChartOutlinedIcon /> Graph
        </div>
      ),
      tab: (<>{loading ? <GraphSkeleton/> :<LastYearCampusBarGraph chartDataForGraph={chartHelper()}/>}</>),
      // tab: <LastYearCampusBarGraph chartDataForGraph={chartHelper()} />,
    },
  ];

  const memoizedData = useMemo(() => {
    return data;
  }, [loading]);

  return (
    <>
      <div
        style={{
          border: "1px solid #b0b6ba",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection:"row-reverse"
          }}
        >
          {/* <div>
            <Typography variant="h6">{heading}</Typography>
          </div> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              {" "}
              {/* <Autocomplete
                id="custom-input-demo"
                options={options}
                renderInput={(params) => (
                  <div
                    ref={params.InputProps.ref}
                    style={{
                      padding: 10,
                      borderRadius: 8,
                      backgroundColor: "#F6F6F6",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <SearchRoundedIcon style={{ color: "#6C757D" }} />
                    <input
                      style={{
                        width: 250,
                        backgroundColor: "#F6F6F6",
                        border: "none",
                        color: "#6C757D",
                      }}
                      type="text"
                      placeholder="Search course"
                      {...params.inputProps}
                    />
                  </div>
                )}
              /> */}
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  //   padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <div style={{ display: "flex" }}>
                  {memoizedData.map((item, id) => (
                    <ul
                      style={{
                        padding: 0,
                        display: "flex",
                        listStyleType: "none",
                        flexWrap: "wrap",
                        textAlign: "center",
                      }}
                      id="myTab"
                      data-tabs-toggle="#myTabContent"
                      role="tablist"
                    >
                      <li
                        className="mr-2"
                        role="presentation"
                        onClick={() => handleChange(item.id)}
                      >
                        <div
                          style={{
                            padding: "6px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            color:
                              value === item.id
                                ? "#fff"
                                : theme.palette.primary.main,
                            backgroundColor:
                              value === item.id
                                ? theme.palette.primary.main
                                : "#fff",
                          }}
                        >
                          {item.title}
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {memoizedData.map((item, id) => (
          <div id="myTabContent">
            <div
              style={{
                display: value === item.id ? "block " : "none",
                marginTop: "20px",
              }}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {item.tab}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default LastYearReportHeading;
