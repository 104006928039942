import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Typography } from "@material-ui/core";

const columns = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 170,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "50px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          marginLeft: "5px",
          margin: "auto",
        }}
      >
        <Typography color="primary" variant="body2">
          {params.row.name}{" "}
        </Typography>
      </span>
    ),
  },
  {
    field: "companyname",

    width: 170,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "22px",
        }}
      >
        Company Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Typography color="primary" variant="body2">
          {params.row.companyname}
        </Typography>
      </span>
    ),
  },
  {
    field: "experties",
    width: 170,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "35px",
        }}
      >
        Experties
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.experties}
      </span>
    ),
  },
  {
    field: "email",
    headerName: "Email ID",
    width: 150,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Email
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.email}
      </span>
    ),
  },
  {
    field: "phone",
    // type: "number",
    width: 160,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "20px",
        }}
      >
        Phone Number
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.phone}
      </span>
    ),
  },
  {
    field: "social",
    sortable: false,
    width: 160,

    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "30px",
        }}
      >
        Social Link
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Typography color="primary" variant="body2">
          {params.row.social}
        </Typography>
      </span>
    ),
  },
];

const rows = [
  {
    id: 1,
    name: "Rohan Singh",
    companyname: "Intech.org",
    experties: "Entrepreneur",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    social: "www.linkedin.com",
  },
  {
    id: 2,
    name: "Rohan Singh",
    companyname: "Intech.org",
    experties: "Entrepreneur",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    social: "www.linkedin.com",
  },
  {
    id: 3,
    name: "Rohan Singh",
    companyname: "Intech.org",
    experties: "Entrepreneur",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    social: "www.linkedin.com",
  },
  {
    id: 4,
    name: "Rohan Singh",
    companyname: "Intech.org",
    experties: "Entrepreneur",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    social: "www.linkedin.com",
  },
  {
    id: 5,
    name: "Rohan Singh",
    companyname: "Intech.org",
    experties: "Entrepreneur",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    social: "www.linkedin.com",
  },
  {
    id: 6,
    name: "Rohan Singh",
    companyname: "Intech.org",
    experties: "Entrepreneur",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    social: "www.linkedin.com",
  },
];

export default function GuestTable() {
  return (
    <div style={{ height: 400, width: "90%" }}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
          boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        }}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}
