import React from 'react'

import UniversityLayout from '../Layout/UniversityLayout'
import CustomCircularProgress from '../CircularProgressBar'
import Layout from '../Layout'
import CollegeGroupLayout from '../Layout/CollegeGroupLayout'

const Fallback = ({layout}) => {
    
  return (
    <div>
      { layout === "Layout" && <Layout><CustomCircularProgress/></Layout>   }
      { layout === "CollegeGroupLayout" && <CollegeGroupLayout><CustomCircularProgress/></CollegeGroupLayout>}
      { layout === "UniversityGroupLayout" && <UniversityLayout><CustomCircularProgress/></UniversityLayout> }
    </div>
  )
}

export default Fallback