import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
  text: {
    marginBottom: "7px",
  },
}));

export default function Assignment({ item, jobId }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h6">Assignment</Typography>
          <br />
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <AssignmentIcon color="primary" />
            <div style={{ marginLeft: "10px" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Assignment Details
              </Typography>

              <Typography
                variant="body2"
                color="secondary"
                className={classes.text}
                style={{ marginTop: "7px" }}
              >
                Assignment Title: {item?.assignment_title}
              </Typography>

              <Typography
                variant="body2"
                color="secondary"
                className={classes.text}
              >
                Last Date Submission:{" "}
                {moment(item?.assignment_deadline).format("DD MMMM' YYYY")}
              </Typography>
            </div>
          </div>
          <Link
            to={`/assignment-details?job_id=${jobId}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              style={{ display: "flex" }}
              endIcon={<DoubleArrowIcon />}
            >
              Track Assignment
            </Button>
          </Link>
        </Paper>
      </div>
    </>
  );
}
