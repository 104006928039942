import {
  Box,
  Button,
  Chip,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HistoryIcon from "@material-ui/icons/History";
import moment from "moment";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",
  },
  chipDisplay: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  boxShadowClass: {
    position: "relative",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    padding: 10,
    margin: "10px 0",
  },
  alignPill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statuschip: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 8,
    // borderRadius: "8px",
  },
}));

function SavedProject({ data, approveReject }) {
  const classes = useStyles();

  return (
    <div /* style={{ position: "relative" }} */>
      {data?.length ? (
        <>
          {data?.map((item) => (
            <>
              <div className={classes?.boxShadowClass}>
                <div>
                  <div className={classes.title}>
                    <Typography variant="body1">
                      {item?.updatedData?.title}
                    </Typography>

                    {/* <Chip
                      color="primary"
                      deleteIcon={<CloseIcon color="primary" />}
                      variant="outlined"
                      style={{
                        backgroundColor:
                          item?.change_type === "ADD"
                            ? "red"
                            : item?.change_type === "UPDATE"
                            ? "yellow"
                            : "maroon",
                      }}
                      label={
                        item?.change_type === "ADD"
                          ? "Added"
                          : item?.change_type === "UPDATE"
                          ? "Updated"
                          : "Deleted"
                      }
                      className={classes.chip}
                      size="small"
                    /> */}
                    <Tooltip
                      placement="bottom"
                      title={
                        item?.change_type === "ADD"
                          ? "New project added"
                          : item?.change_type === "UPDATE"
                          ? "Update existing project"
                          : "Delete existing project"
                      }
                    >
                      <div
                        style={{
                          backgroundColor:
                            item?.change_type === "ADD"
                              ? "#00203F"
                              : item?.change_type === "UPDATE"
                              ? "#2AF598"
                              : "#FF0000",
                        }}
                        className={classes.statuschip}
                      >
                        <Typography style={{ color: "#fff", fontSize: "14px" }}>
                          {item?.change_type === "ADD"
                            ? "Added"
                            : item?.change_type === "UPDATE"
                            ? "Updated"
                            : "Deleted"}
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>

                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{ marginTop: "5px", marginBottom: "7px" }}
                  >
                    {item.updatedData?.description}{" "}
                  </Typography>
                </div>

                <div className={classes.chipDisplay}>
                  {item?.updatedData?.skills &&
                    item?.updatedData?.skills.map((item, index) => {
                      return (
                        <li key={item.skill_id}>
                          <Chip
                            color="primary"
                            variant="outlined"
                            size="small"
                            label={item.skill_name}
                            className={classes.chip}
                          />
                        </li>
                      );
                    })}
                </div>
                <div className={classes?.alignPill}>
                  {item.updatedData?.links ? (
                    <Button color="primary">
                      <a
                        href={`//${item.updatedData?.links}`}
                        target="blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        View Live
                      </a>
                    </Button>
                  ) : null}
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // position: "absolute",
                      // right: 0,
                      // bottom: 5,
                    }}
                  >
                    <Tooltip title="Approve" placement="left">
                      <IconButton
                        style={{
                          backgroundColor: "#51c03e",
                          marginRight: "10px",
                        }}
                        size="small"
                        onClick={() => {
                          approveReject([item?.id], "APPROVED", "EDU");
                        }}
                      >
                        <Check style={{ color: "#fff" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reject" placement="right">
                      <IconButton
                        style={{ backgroundColor: "#f64a3f" }}
                        size="small"
                        onClick={() => {
                          approveReject([item?.id], "REJECTED", "EDU");
                        }}
                      >
                        <CloseIcon style={{ color: "#fff" }} />
                      </IconButton>
                    </Tooltip>
                  </div> */}
                </div>
                <Divider
                  variant="middle"
                  style={{ marginLeft: 0, marginRight: 0 }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HistoryIcon style={{ color: "#010101" }} />{" "}
                    <Typography
                      variant="body2"
                      style={{ color: "#010101", paddingLeft: "5px" }}
                    >
                      {moment(item?.create_time)?.format(
                        "MMMM Do YYYY, h:mm a"
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        marginRight: "10px",
                        height: "35px",
                        boxShadow: "none",
                      }}
                      onClick={() => {
                        approveReject([item?.id], "APPROVED");
                      }}
                    >
                      <Typography
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        Accept
                      </Typography>
                    </Button>

                    <Button
                      variant="outlined"
                      style={{
                        borderColor: "#FF0000",
                        height: "35px",
                        boxShadow: "none",
                      }}
                      size="small"
                      onClick={() => {
                        approveReject([item?.id], "REJECTED");
                      }}
                    >
                      <Typography
                        style={{
                          color: "#FF0000",
                          fontSize: "14px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        Decline
                      </Typography>
                    </Button>
                  </div>
                </Box>
              </div>
            </>
          ))}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>No Changes</Typography>
        </div>
      )}
    </div>
  );
}

export default SavedProject;
