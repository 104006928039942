import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  collegeDetailSelectorFn,
  collegeGroupListSelectorFn,
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import { TeamsGetAPI } from "../../api/TeamManage/action";
import CustomCircularProgress from "../../components/CircularProgressBar";
import ManageJobTabGroups from "../../components/Header/ManageJobTab/groupTeam";
import ManageTeamHeader from "../../components/Header/ManageTeamHeader";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import {
  generateCsvForParams,
  generateCsv,
} from "../../utility/commaSeparatedStrings";
import checkSelectedGroup from "../../utility/getGroupIdParam";
import useCollegeGroupList from "../../Hooks/useCollegeGroupList";
import useNavBarCollegeGroupList from "../../Hooks/useNavBarCollegeGroupList";

function GroupTeam() {
  const [filters, setFilters] = useState({
    search: null,
    course: null,
    batch: null,
    status_active: true,
    status_blocked: true,
    designation: null,
    current_tab: null,
  });
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [firstApiCall, setFirstApiCall] = useState(false);
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);
  const [userRoleId, setUserRoleId] = useState();
  const [apiCalled, setApiCalled] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const [callNavbar, setCallNavbar] = useState(false);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  // const [selectedGroup, setSelectedGroup] = useState(null);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const {
    newCollegeGroupList,
    setNewCollegeGroupList,
    selectedGroup,
    setSelectedGroup,
  } = useCollegeGroupList(collegeGroupList, isCollegeGroup);
  // const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);
  // const checkValue = () => {
  //   if (!userRoleId) return ``;
  //   else if (filters?.current_tab?.key === "pending_team") return `&key=${"pending_team"}`;
  //   else return `&user_role_id=${userRoleId}`;
  // };
  useEffect(() => {
    if (newCollegeGroupList) setSelectedGroup(newCollegeGroupList[0]);
  }, [newCollegeGroupList]);

  const searchFilter = () => {
    if (filters.search) return `&search=${filters.search}`;
    else return ``;
  };
  const departmentFilter = () => {
    if (filters.designation)
      return `&designation=${filters.designation.name.toUpperCase()}`;
    else return ``;
  };
  const batchFilter = () => {
    if (filters.batch) return `&batch=${filters.batch.value}`;
    else return ``;
  };
  const statusFilter = () => {
    if (filters.status_active && filters.status_blocked) return ``;
    else if (filters.status_active) return `&status=ACTIVE`;
    else if (filters.status_blocked) return `&status=INACTIVE`;
    else return ``;
  };

  // const checkSelectedGroup = () => {
  //   if (placementSession) return `&placement_session=${placementSession.id}`;
  //   else return ``;
  // };

  // useEffect(() => {
  //   if (callNavbar) {
  //     var url_filter = `api/manage_team/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkSelectedGroup(isCollegeGroup, selectedGroup, collegeGroupList)}`;
  //     dispatch(NavbarTeamGetAPI(setLoadingUserRoles, alert, history, location, url_filter));
  //   }
  // }, [callNavbar]);

  useEffect(() => {
    if (selectedGroup) {
      var url = `api/manage_team/group_view/?${checkSelectedGroup(
        isEducationGroup,
        isCollegeGroup,
        selectedGroup,
        collegeGroupList
      )}`;
      dispatch(
        TeamsGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall
        )
      );
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (firstApiCall) {
      var url = `api/manage_team/group_view/?${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkSelectedGroup(
        isEducationGroup,
        isCollegeGroup,
        selectedGroup,
        collegeGroupList
      )}`;
      dispatch(
        TeamsGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall
        )
      );
    }
  }, [filters.status_blocked, filters.status_active, filters.designation]);

  useEffect(() => {
    if (clickSearchButton) {
      var url = `api/manage_team/group_view/?${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkSelectedGroup(
        isEducationGroup,
        isCollegeGroup,
        selectedGroup,
        collegeGroupList
      )}`;
      dispatch(
        TeamsGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall
        )
      );
    }
  }, [clickSearchButton]);

  useEffect(() => {
    if (placementSession) {
      var url = `api/manage_team/group_view/?${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkSelectedGroup(
        isEducationGroup,
        isCollegeGroup,
        selectedGroup,
        collegeGroupList
      )}`;
      dispatch(
        TeamsGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall
        )
      );
    }
  }, [placementSession]);

  const teamList = useSelector((state) => state?.TeamData?.details);
  const [loadingPlacement, setLoadingPlacement] = useState(false);

  // useEffect(() => {
  //   if (apiCalled) {
  //     var url_filter = `api/manage_team/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkSelectedGroup(isEducationGroup,isCollegeGroup, selectedGroup, collegeGroupList)}`;

  //     dispatch(NavbarTeamGetAPI(setLoadingUserRoles, alert, history, location, url_filter));
  //     setApiCalled(false);
  //   }
  // }, [apiCalled]);

  const userRoleList = useSelector((state) => state?.TeamData?.navbar);
  const designationList = useSelector(
    (state) => state?.TeamData?.designationTeamList
  );
  const designationNewList = useSelector(
    (state) => state?.TeamData?.designationList
  );
  const inviteLink = useSelector((state) => state?.TeamData?.link);
  // useEffect(() => {
  //   if (placementSessionList?.length > 0) {
  //     if (location.search.includes("placement_cycle")) {
  //       var placement_name = checkPlacementName(location?.search.replace("?placement_cycle=", ""));
  //       setPlacementSession({
  //         id: location?.search.replace("?placement_cycle=", ""),
  //         name: placement_name,
  //       });
  //     } else {
  //       setPlacementSession({ id: "All", name: "All Placement Cycles" });
  //     }
  //   }
  // }, [location, placementSessionList?.length]);

  const [tempTeamList, setTempTeamList] = useState([]);
  useEffect(() => {
    if (teamList?.length > 0) {
      var arr = [...teamList];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        var a = [];
        if (item?.placement_cycle_list?.length > 0)
          a = [...item?.placement_cycle_list];
        var n = a.filter((i) => i.is_selected);
        new_arr.push({
          name: item?.name,
          email: item?.email,
          phone: item?.phone,
          designation: item?.designation,
          current_status: item?.current_status,
          placement_cycles: generateCsv(n, "name"),
        });
      });
      setTempTeamList(new_arr);
    }
  }, [teamList?.length]);

  useEffect(() => {
    if (selectedRowsData?.length > 0) {
      let arr = [...selectedRowsData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        new_arr.push({
          name: item?.name,
          email: item?.email,
          phone: item?.phone,
          designation: item?.designation,
          current_status: item?.current_status,
          no_of_colleges: item?.no_of_colleges,
          group_name: item?.group_name,
        });
      });
      setTempTeamList(new_arr);
    } else {
      if (teamList?.length > 0) {
        var arr = [...teamList];
        let new_arr = [];
        var obj = {};
        arr.forEach((item) => {
          new_arr.push({
            name: item?.name,
            email: item?.email,
            phone: item?.phone,
            designation: item?.designation,
            current_status: item?.current_status,
            no_of_colleges: item?.no_of_colleges,
            group_name: item?.group_name,
          });
        });
        setTempTeamList(new_arr);
      }
    }
  }, [selectedRowsData?.length]);

  useNavBarCollegeGroupList(selectedGroup, setLoading);

  // useEffect(() => {
  //   if (collegeGroupList?.length > 1 && isCollegeGroup) {
  //     setCollegeGroupList(
  //       [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
  //     );
  //   } else setCollegeGroupList(collegeGroupList);
  // }, [collegeGroupList?.length]);

  return (
    <>
      {/* <Layout> */}
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : (
        <>
          <ManageTeamHeader
            teamList={teamList}
            userRoleList={userRoleList}
            designationList={designationNewList}
            selectedRowsData={selectedRowsData}
            placementSession={placementSession}
            userRoleId={userRoleId}
            setApiCalled={setApiCalled}
            tempTeamList={tempTeamList}
            collegeId={collegeId}
            userRolesPermission={userRolesPermission}
            inviteLink={inviteLink}
            filters={filters}
            fromGroup={true}
          />

          <ManageJobTabGroups
            loading={loading}
            filters={filters}
            setFilters={setFilters}
            setClickSearchButton={setClickSearchButton}
            setSelectedRowsData={setSelectedRowsData}
            selectedRowsData={selectedRowsData}
            clickSearchButton={clickSearchButton}
            value={value}
            setValue={setValue}
            setUserRoleId={setUserRoleId}
            designationList={designationList}
            collegeId={collegeId}
            userRolesPermission={userRolesPermission}
            setCallNavbar={setCallNavbar}
          />
        </>
      )}
      {/* <Oops /> */}
      {/* </Layout> */}
    </>
  );
}

export default GroupTeam;
