import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  newBtnStyle: {
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));

export default function AddPublicationsDialog(props) {
  const classes = useStyles();
  const alert = useAlert();

  const {
    open,
    editPublications,
    setEditPublications,
    savePublications,
    handleClose,
    pubDataLoading,
    isEdit,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [publicationError, setPublicationError] = useState({
    title: null,
    links: null,
    description: null,
  });

  const validateData = () => {
    if (!editPublications.title) {
      alert.error("Title field cannot be left blank");
      return false;
    }
    if (
      editPublications?.links &&
      !(
        editPublications?.links?.startsWith("https://") ||
        editPublications?.links?.startsWith("http://") ||
        editPublications?.links?.startsWith("HTTPS://") ||
        editPublications?.links?.startsWith("HTTP://")
      )
    ) {
      alert.error("Invalid ! Link should start with http://");
      return false;
    }
    if (!editPublications.description) {
      alert.error("Description field cannot be left blank");
      return false;
    }

    return true;
  };
  const CHARACTER_LIMIT = 150;
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
      >
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>{isEdit ? "Edit" : "Add"} Publications</b>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                margin="normal"
                label="Title*"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editPublications?.title}
                onChange={(e) => {
                  setEditPublications({
                    ...editPublications,
                    title: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                label="Link"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editPublications?.links}
                onChange={(e) => {
                  setEditPublications({
                    ...editPublications,
                    links: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid
              xs={12}
              className={classes.grid}
              style={{
                marginBottom: "10px",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginTop: "20px",
                display: "grid",
              }}
            >
              <TextField
                label="Write Description*"
                multiline
                maxRows={4}
                minRows={4}
                inputProps={{
                  className: classes.textfiledrow,
                  maxlength: CHARACTER_LIMIT,
                }}
                variant="outlined"
                id="custom-css-outlined-input"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={editPublications?.description}
                onChange={(e) => {
                  setEditPublications({
                    ...editPublications,
                    description: e.target.value,
                  });
                }}
                error={publicationError.description ? true : false}
                helperText={
                  publicationError.description ? (
                    <span>{publicationError.description}</span>
                  ) : (
                    `${
                      editPublications?.description?.length
                        ? editPublications?.description?.length
                        : 0
                    }/${CHARACTER_LIMIT}`
                  )
                }
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                className={classes.newBtnStyle}
                onClick={() => {
                  handleClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.newBtnStyle}
                onClick={() => {
                  if (validateData()) {
                    savePublications();
                  }
                }}
              >
                {pubDataLoading ? <CustomButtonCircularProgress /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
