import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import pSBC from "shade-blend-color";
import { themeSelectorFn } from "../../api/Domain/selector";
import { HelpData } from "../../api/Help/action";
import IconSelectorNew from "../../utility/iconSelectorNew";
import SearchFaqs from "./Search";
import { isEducationGroupFn } from "../../api/Domain/selector";
const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  paper: {
    padding: "30px",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    minHeight: "200px",
  },
  email: {
    padding: "30px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
  btn: {
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    boxShadow: "none",
    borderRadius: "8px",
    color: theme.palette.primary.main,
    height: "34px",
    display: "flex",
    textDecoration: "none",
  },
  loader: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(-50%)",
  },
}));

export default function NewHelp() {
  const classes = useStyles();
  const [loader, SetLoader] = React.useState(true);
  const [data, setData] = React.useState([]);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isEducationGroup = useSelector(isEducationGroupFn);

  useEffect(() => {
    SetLoader(true);
    dispatch(HelpData(alert, history, location, isEducationGroup));
  }, []);

  const helpData = useSelector((state) => state?.Helps?.details);
  const theme_change = useSelector(themeSelectorFn);

  useEffect(() => {
    if (helpData) {
      const temp = [];
      setData([]);
      helpData.map((item) => {
        if (item.how_to_use_saas.length > 0) {
          temp.push(item);
        }
      });
      setData(temp);
      SetLoader(false);
    }
  }, [helpData]);

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography
            variant="h2"
            style={{ marginTop: "17px", marginBottom: "15px" }}
          >
            Help & Support
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            marginTop: "17px",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          <SearchFaqs />
        </Grid>
        <br />

        {!loader ? (
          data.map((item, index) => (
            <Grid item xs={6} key={index}>
              <Paper className={classes.paper}>
                <div>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      {IconSelectorNew(
                        item.Group_Name,
                        theme_change?.main ? theme_change?.main : "#007BFF"
                      )}
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h5" style={{ marginBottom: "15px" }}>
                        {item.Group_Name}
                      </Typography>

                      {item.how_to_use_saas.map(
                        (line, idx) =>
                          idx < 2 && (
                            <li style={{ display: "contents" }}>
                              <Link
                                to={
                                  isEducationGroup
                                    ? `/educational-group/questions/${line.id}`
                                    : `/questions/${line.id}`
                                }
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    color: "#6c757d",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                  variant="body2"
                                  key={line}
                                >
                                  {idx + 1}
                                  {". "}
                                  {line.Question}
                                </Typography>
                              </Link>
                            </li>
                          )
                      )}

                      <Link
                        to={
                          isEducationGroup
                            ? `/educational-group/group_FAQs/${item.Group_Name?.toLowerCase()?.replace(
                                / /g,
                                "-"
                              )}-${item.id}`
                            : `/group_FAQs/${item.Group_Name?.toLowerCase()?.replace(
                                / /g,
                                "-"
                              )}-${item.id}`
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <Button variant="contained" className={classes.btn}>
                          View All FAQs
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          ))
        ) : (
          <div className={classes.loader}>
            <CircularProgress size={50} />
          </div>
        )}

        <Grid item xs={12} sm={12}>
          <Typography variant="h6">Not finding the help you need?</Typography>
          <br />
          <Link
            to={isEducationGroup ? `/educational-group/contact-us` : `/contact`}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{
                boxShadow: "none",
                borderRadius: "8px",
                height: "40px",
                display: "flex",
              }}
            >
              Contact us
            </Button>
          </Link>
        </Grid>

        {/* <Grid item xs={12} sm={12}>
            <Paper className={classes.email}>
              <div style={{ width: "70%" }}>
                <Typography variant="h6" color="secondary">
                  Please Add Your Query
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  inputProps={{
                    style: {
                      padding: "16px",
                    },
                  }}
                  label="Title"
                />

                <br />
                <br />
                <MyEditor />
                <br />
                <Typography variant="h6" style={{ marginBottom: "10px" }}>
                  Upload Images
                </Typography>
                <DropzoneArea
                  acceptedFiles={["image/*"]}
                  dropzoneText={"Max upload up to 3 image *"}
                  onChange={files => console.log("Files:", files)}
                />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  style={{
                    boxShadow: "none",
                    borderRadius: "30px",
                    paddingLeft: "70px",

                    paddingRight: "70px",
                  }}
                >
                  Send
                </Button>
              </div>
            </Paper>
          </Grid> */}
      </Grid>
      {/* </Layout> */}
    </>
  );
}
