import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./trending.css";
import ModalVideo from "react-modal-video";
import "../../../style/reactVideo.css";
import { Typography, Grid } from "@material-ui/core";
import flash from "../../../assets/svg/Thunder.svg";
import { Skeleton } from "@material-ui/lab";

function TrendingMeetUp({ trendingData, loading }) {
  var settings = {
    className: "",
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // adaptiveHeight: true,
    arrows: false,
  };

  const [isOpen, setOpen] = useState(false);
  const [isvideo, setvideo] = useState();

  const handleClickOpen = (id) => {
    setOpen(true);
    setvideo(id);
  };

  const temp = trendingData?.filter((doc) => doc.banner_id === isvideo);

  const handleClose = () => {
    setOpen(false);
  };

  const VID_REGEX =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  return (
    <>
      <div>
        {loading ? (
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 15 }}
          >
            <div>
              <img src={flash} />
            </div>
            <Typography variant="h4">
              {" "}
              Trend
              <span
                style={{
                  background:
                    "linear-gradient(90deg, rgba(255, 191, 105, 0.36) 0%, rgba(250, 250, 250, 0.45) 100%)",
                  borderRadius: 4,
                }}
              >
                ing Meetups
              </span>
            </Typography>
          </div>
        ) : (
          <Skeleton
            animation="wave"
            variant="rect"
            style={{
              height: "30px",
              width: "200px",
              borderRadius: 4,
              marginBottom: "15px",
            }}
          />
        )}
        <div className="slider-container">
          {" "}
          <div>
            {loading ? (
              <Slider {...settings}>
                {trendingData?.map((value, index) => (
                  <div
                    onClick={() =>
                      // handleClickOpen(value?.banner_id)
                      window.open(value?.banner_url, "_blank")
                    }
                  >
                    <img
                      src={value?.banner_path}
                      style={{
                        width: "100%",
                        // aspectRatio: "3/2",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <>
                <Grid container spacing={2}>
                  {[1, 2]?.map((item) => (
                    <Grid item md={6}>
                      <div style={{ width: "100%", marginRight: 10 }}>
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          style={{
                            height: "300px",
                            width: "100%",
                            borderRadius: 10,
                          }}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </div>
          <div>
            {temp?.map((item) => (
              <ModalVideo
                channel="youtube"
                videoId={item?.redirection_url?.match(VID_REGEX)[1]}
                youtube={{ mute: 0, autoplay: 0 }}
                isOpen={isOpen}
                onClose={handleClose}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default TrendingMeetUp;
