import { Button, Container, Grid, Hidden, Typography } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ApartmentIcon from "@material-ui/icons/Apartment";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { collegeDetailsSelectorFn } from "../../api/Domain/selector";
import CollegeImage from "../../assets/img/company.jpg";
import defaultCompany from "../../assets/img/default.jpg";
import CustomCircularProgress from "../../components/CircularProgressBar";
import FormFooter from "../../components/Common/FormFooter";
import StudentFormNavbar from "../../components/Common/StudentFormNavbar";
import InviteMemberForm from "../../components/Form/InviteMemberForm";
import { NEXT_APP_ENDPOINT } from "../../constants/constants";
import ThankYou from "../ThankYou";
import Facebook from "../../assets/png/facebook.png";
import Insta from "../../assets/png/insta.png";
import Linkedin from "../../assets/png/linkedin.png";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px",
    marginRight: "10px",
    height: "48px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  submit: {
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "row",
    //justifyContent: "flex-start",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingTop: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingBottom: "10px",
  },

  imagedata: {
    display: "flex",
    width: 150,
    backgroundColor: "#fff",
    height: 80,
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  imgdata: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",

    //  borderRadius: "50%",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      // width: "100px",
    },
  },
  icons: {
    marginRight: "10px",
  },
}));

export default function TeamMemberInvite() {
  const classes = useStyles();
  const [step, setStep] = useState(0);

  const collegeDetails = useSelector(collegeDetailsSelectorFn);

  const Data = [
    {
      icon: <LanguageIcon color="secondary" />,
      info: collegeDetails?.website,
      website: true,
      show: collegeDetails?.website ? true : false,
    },
    {
      icon: <ApartmentIcon color="secondary" />,
      info: collegeDetails?.city,
      show: collegeDetails?.city ? true : false,
    },
    {
      icon: <GolfCourseIcon color="secondary" />,
      info: collegeDetails?.college_type,
      show: collegeDetails?.college_type ? true : false,
    },
    {
      icon: <PeopleAltOutlinedIcon color="secondary" />,
      info: collegeDetails?.size + " Students",
      show: collegeDetails?.size ? true : false,
    },
    {
      icon: <FlagOutlinedIcon color="secondary" />,
      info: collegeDetails?.founded_year,
      show: collegeDetails?.founded_year ? true : false,
    },
    {
      icon: <LocationCityIcon color="secondary" />,
      info: collegeDetails?.affiliated,
      show: collegeDetails?.affiliated ? true : false,
    },
  ];

  return (
    <>
      {/* <StudentFormNavbar /> */}
      {false ? (
        <CustomCircularProgress />
      ) : step === 0 ? (
        <>
          <div
            style={{
              backgroundImage: `url(${
                collegeDetails?.banner ? collegeDetails?.banner : CollegeImage
              })`,
              backgroundRepeat: "no-repeat",
              height: "270px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />{" "}
          <Container maxWidth="lg">
            <Grid container spacing={2} style={{ marginTop: "-30px" }}>
              <Grid item>
                <div className={classes.imagedata}>
                  <img
                    className={classes.imgdata}
                    alt="complex"
                    src={
                      collegeDetails?.logo
                        ? collegeDetails?.logo
                        : defaultCompany
                    }
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm
                container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  spacing={2}
                  style={{ display: "contents" }}
                >
                  <div className={classes.title}>
                    <Typography
                      variant="h3"
                      style={{ fontWeight: 600, paddingBottom: "10px" }}
                    >
                      {collegeDetails?.college_name}
                    </Typography>
                  </div>
                  <div className={classes.social}>
                    {collegeDetails?.social_links[1]?.value && (
                      <a
                        href={collegeDetails?.social_links[1]?.value}
                        target="blank"
                      >
                        <img
                          src={Facebook}
                          height="40px"
                          className={classes.icons}
                          width="40px"
                        />
                      </a>
                    )}
                    {collegeDetails?.social_links[0]?.value && (
                      <a
                        href={collegeDetails?.social_links[0]?.value}
                        target="blank"
                      >
                        <img
                          src={Linkedin}
                          className={classes.icons}
                          height="40px"
                          width="40px"
                        />
                      </a>
                    )}
                    {collegeDetails?.social_links[2]?.value && (
                      <a
                        href={collegeDetails?.social_links[2]?.value}
                        target="blank"
                      >
                        <img
                          src={Insta}
                          className={classes.icons}
                          height="40px"
                          width="40px"
                        />
                      </a>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <div>
              <Typography variant="h6">Overview</Typography>
              <Grid
                container
                spacing={3}
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                {Data.map(
                  (item) =>
                    item.show && (
                      <Grid item xs={12} sm={4}>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item>{item.icon}</Grid>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              color="secondary"
                              noWrap
                              className={classes.text}
                            >
                              {item.website ? (
                                <a href={item.info} target="_blank">
                                  {item.info}
                                </a>
                              ) : (
                                item.info
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>

              {collegeDetails?.about_us && (
                <Grid item xs={12}>
                  <Typography variant="h6">About us </Typography>
                  <br />
                  <Typography variant="body2">
                    {ReactHtmlParser(collegeDetails?.about_us)}
                  </Typography>
                </Grid>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className={classes.form}>
                <Hidden smDown>
                  <Typography
                    variant="h2"
                    style={{ textAlign: "center", marginBottom: "40px" }}
                  >
                    Provide your Information to Register
                  </Typography>
                </Hidden>
                <Hidden smUp>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    Provide your Information to Register
                  </Typography>
                </Hidden>
                <InviteMemberForm
                  collegeId={collegeDetails?.college_id}
                  step={step}
                  setStep={setStep}
                />
              </div>
            </div>
          </Container>
          <FormFooter />
          {/* <ThankYou /> */}
        </>
      ) : (
        <ThankYou
          text={"You have been successfully registered!!"}
          link={`${NEXT_APP_ENDPOINT}/employer`}
        />
      )}
    </>
  );
}
