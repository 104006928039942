import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { collegeUserDetailSelectorFn } from "../../../api/SignIn/selector";
import AcceptTeam from "../../DialogSection/AcceptTeam";
import ChangePlacement from "../../DialogSection/AddJobPlacementCycle/ChnagePlacement";
import BlockCompany from "../../DialogSection/BlockCompany";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "40px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".3px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

function ManageTeamActionBar({
  filters,
  setFilters,
  setClickSearchButton,
  selectedRowsData,
  clickSearchButton,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  designationList,
  teamList,
  collegeId,
  userRolesPermission,
  setCallNavbar,
}) {
  const alert = useAlert();
  const handleCheckChange = (event) => {
    setFilters({ ...filters, status_active: event.target.checked });
  };
  const handleCheckChange2 = (event) => {
    setFilters({ ...filters, status_blocked: event.target.checked });
  };
  const classes = useStyles();
  const userIdSelector = useSelector(collegeUserDetailSelectorFn);

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.key === "pending_team" ||
      filters?.current_tab?.key === "rejected_team"
    );
  };

  const checkForRejected = () => {
    return filters?.current_tab?.key === "rejected_team";
  };

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Type & Search"
                value={filters.search}
                inputProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setClickSearchButton(true);
                  }
                }}
              />
              {filters?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      search: "",
                    });
                    setClickSearchButton(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Autocomplete
              id="country-select-demo"
              options={designationList}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.name}
                </Typography>
              )}
              getOptionLabel={(option) => option.name}
              value={filters.designation}
              onChange={(e, newValue) => {
                setFilters({ ...filters, designation: newValue });
              }}
              style={{ background: "#fff", borderRadius: 8 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Designation"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={2}>
            {/* <Autocomplete
              id="country-select-demo"
              options={years_list}
              getOptionLabel={(option) => String(option.name)}
              value={filters.batch}
              onChange={(e, newValue) => {
                setFilters({ ...filters, batch: newValue });
              }}
              style={{ background: "#fff", borderRadius: 30 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Batch"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            /> */}
          </Grid>
          {/* <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.floatingLabelFocusStyle}>
                College
              </InputLabel>
              <Select
                native
                value={state.age}
                onChange={handleChange}
                label="College"
                inputProps={{
                  style: {
                    padding: "16px",
                    backgroundColor: "#fff",
                    borderRadius: "30px",
                  },
                }}
              >
                <option aria-label="None" value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={2}>
            {" "}
            <Calendar title="End Date" />
          </Grid> */}
          {/* <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.floatingLabelFocusStyle}>
                Skills
              </InputLabel>
              <Select
                native
                value={state.age}
                onChange={handleChange}
                style={{ borderRadius: "30px" }}
                label="Skills"
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="None" value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>
          </Grid> */}
          {!checkForPendingRejected() && (
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={filters.status_active}
                  size="small"
                  color="primary"
                  onChange={handleCheckChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />

                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "8px",
                    backgroundColor: "#51c03e",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ marginLeft: "7px", fontWeight: "bold" }}
                >
                  Active
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={filters.status_blocked}
                  size="small"
                  color="primary"
                  onChange={handleCheckChange2}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />

                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "8px",
                    backgroundColor: "#f64a3f",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ marginLeft: "7px", fontWeight: "bold" }}
                >
                  Block
                </Typography>
              </div>
            </Grid>
          )}
          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_team"
          ) &&
            selectedRowsData?.length > 0 &&
            !checkForPendingRejected() && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {/* <DeleteJobDialog /> */}
                {!selectedRowsData?.some(
                  (i) => i.email === userIdSelector?.email
                ) ? (
                  <>
                    <BlockCompany
                      deleteUser={true}
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      selectedID={selectedID}
                      setSelectedID={setSelectedID}
                      fromTeams={true}
                      collegeId={collegeId}
                      setCallNavbar={setCallNavbar}
                    />
                    <BlockCompany
                      deleteUser={false}
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      selectedID={selectedID}
                      setSelectedID={setSelectedID}
                      fromTeams={true}
                      collegeId={collegeId}
                      setCallNavbar={setCallNavbar}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      onClick={() =>
                        alert.error("User cannot delete it's own account")
                      }
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      onClick={() =>
                        alert.error("User cannot block it's own account")
                      }
                    >
                      Block
                    </Button>
                  </>
                )}

                {/* <BlockTeam /> */}
                {selectedRowsData?.length === 1 && (
                  <ChangePlacement
                    selectedRowsData={selectedRowsData}
                    setSelectedRowsData={setSelectedRowsData}
                    selectedID={selectedID}
                    setSelectedID={setSelectedID}
                    teamList={teamList}
                    collegeId={collegeId}
                  />
                )}
              </Grid>
            )}

          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_team"
          ) &&
            selectedRowsData?.length > 0 &&
            filters?.current_tab?.key === "pending_team" && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <AcceptTeam
                  deleteUser={true}
                  selectedRowsData={selectedRowsData}
                  setSelectedRowsData={setSelectedRowsData}
                  selectedID={selectedID}
                  setSelectedID={setSelectedID}
                  collegeId={collegeId}
                  setCallNavbar={setCallNavbar}
                />
                <AcceptTeam
                  deleteUser={false}
                  selectedRowsData={selectedRowsData}
                  setSelectedRowsData={setSelectedRowsData}
                  selectedID={selectedID}
                  setSelectedID={setSelectedID}
                  collegeId={collegeId}
                  setCallNavbar={setCallNavbar}
                />
              </Grid>
            )}
        </Grid>
      </div>
    </>
  );
}

export default ManageTeamActionBar;
