import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, AppBar, Toolbar } from "@material-ui/core";
import clogo from "../../../assets/company/micro.png";
import Getworklogo from "../../../assets/png/getwork-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { collegeDetailsSelectorFn, isEducationGroupFn, userLoggedInSelectorFn } from "../../../api/Domain/selector";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default function StudentFormNavbar({ logo }) {
  const classes = useStyles();
  const collegeDetails = useSelector(collegeDetailsSelectorFn); 

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "#fff" }}>
        <Toolbar>
          <Container maxWidth="lg">
            <div className={classes.logo}>
              <img src={collegeDetails?.logo} height="50px" width="auto" />
              {/* <img src={logo ? logo : Getworklogo} height="30px" width="auto" /> */}
              {logo && <img src={Getworklogo} height="25px" width="auto" />}
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );
}
