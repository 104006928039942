import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { IconButton } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    marginBottom: "15px",
  },
  icon: {
    color: "#007bff",
  },
  font: {
    fontFamily: "",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function SavePublications({
  publications,
  publiIndex,
  handleDelete,
  setAddPublications,
  handleOpen,
  setIsEdit,
  setEditIndex,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography
            variant="body1"
            style={{
              marginBottom: "4px",
            }}
          >
            {publications?.title}
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton
              color="primary"
              onClick={() => {
                setEditIndex(publiIndex);
                setIsEdit(true);
                setAddPublications(publications);
                handleOpen();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                handleDelete(publications?.id, publiIndex, "Publications");
              }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </div>
        </div>
        {publications?.links && (
          <a href={publications?.links} style={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              color="primary"
              style={{
                marginBottom: "4px",
              }}
            >
              View Live
            </Typography>
          </a>
        )}
        <Typography
          variant="body1"
          style={{
            marginBottom: "4px",
          }}
        >
          {publications?.description}
        </Typography>
      </div>
    </>
  );
}
