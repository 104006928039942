import { Button, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import * as React from "react";
import { Link } from "react-router-dom";

const rows = [
  {
    id: 101,
    college: "Maharaja Surajmal Institute",
    ejdtitle: "Amazon",
    date: "02-08-2021",
  },
  {
    id: 102,
    college: "Maharaja Surajmal Institute",
    ejdtitle: "Amazon",
    date: "02-08-2021",
  },
  {
    id: 103,
    college: "Maharaja Surajmal Institute",
    ejdtitle: "Amazon",
    date: "02-08-2021",
  },
  {
    id: 104,
    college: "Maharaja Surajmal Institute",
    ejdtitle: "Amazon",
    date: "02-08-2021",
  },
];

export default function EjdLinkTable({ data, columns, loading }) {
  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
        }}
        rows={data}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
        loading={loading}
      />
    </div>
  );
}
