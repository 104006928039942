import {
  FormControl,



  Grid, IconButton, InputBase,




  InputLabel, Paper,






  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";



const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    //border: ".3px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
    boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
}));

export default function TotalMemberActionbar() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <Grid item xs={3}>
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Search By Name..."
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
            />

            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          {" "}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.floatingLabelFocusStyle}>
              Role
            </InputLabel>
            <Select
              native
              value={state.age}
              onChange={handleChange}
              label="Role"
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
            >
              <option aria-label="None" value="" />
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
