import { Box, Button, ButtonBase, Chip, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import PeopleIcon from "@material-ui/icons/People";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import WorkIcon from "@material-ui/icons/Work";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import React from "react";
import { useHistory } from "react-router-dom";
import pSBC from "shade-blend-color";
import defaultCompany from "../../../../assets/img/default.jpg";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    "&:hover": {
      transform: "scale(1.008)",
    },
  },
  image: {
    display: "flex",
    width: 150,
    height: 80,
    borderRadius: "8px",
    border: ".5px solid #e2e6ea",
  },
  img: {
    margin: "auto",
    //  display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    //  borderRadius: "50%",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "15px",
  },
  infoitem: {
    display: "flex",
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  info_icon: {
    color: "#6c757d",
    height: "18px",
  },
  detail: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    alignContent: "center",
  },
  location: {
    color: "#6c757d",
    marginTop: "10px",
  },
  skill: {
    marginTop: "15px",
    color: "#6c757d",
  },
  btn: {
    marginTop: "15px",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "10px",
  },
  title: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "row",
    alignItems: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

  },
  check: {
    color: "#fff",
    paddingLeft: "30px",
    height: "35px",
    paddingRight: "30px",
    borderRadius: "8px",
    boxShadow: "none",
  },
  open: {
    backgroundColor: " #74c686",
    color: "#fff",
    paddingLeft: "30px",
    paddingRight: "30px",
    borderRadius: "8px",
    boxShadow: "none",
  },
}));

export default function CurrentOpening({ companyJobDetails }) {
  const classes = useStyles();

  const Data = [
    {
      icon: <WorkIcon color="primary" />,
      info: "No. of Applicants: " + companyJobDetails?.applicants,
      show: true,
    },
    {
      icon: <PeopleIcon color="primary" />,
      info: "Students Placed: " + companyJobDetails?.student_placed,
      show: companyJobDetails?.student_placed ? true : false,
    },
  ];

  const startDate = new Date(companyJobDetails?.start_date)?.toISOString()?.split("T")[0];
  const history = useHistory();
  return (
    <div
      className={classes.paper}
      onClick={() => {
        history.push(companyJobDetails?.job_link);
      }}
      style={{ cursor: "pointer" }}
    >
      <div>
        <Grid container spacing={2}>
          <Grid item>
            <div className={classes.image}>
              <img
                className={classes.img}
                alt="defaultCompany"
                src={
                  companyJobDetails?.logo
                    ? companyJobDetails?.logo
                    : companyJobDetails?.company_website
                    ? `//logo.clearbit.com/${companyJobDetails?.company_website}`
                    : defaultCompany
                }
                onError={(e) => {
                  e.target.onerror = () => null;
                  e.target.src = defaultCompany;
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.grid} sm container>
            <Grid item xs container direction="column" spacing={2} style={{ display: "contents" }}>
              <div className={classes.text}>
                <Typography variant="h4">{companyJobDetails?.job_title}</Typography>
                <div className={classes.title}>
                  <Typography variant="body2" style={{ color: "#6c757d", fontWeight: 600 }}>
                    {companyJobDetails?.company_name}
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <div
                className={classes.open}
                style={{
                  padding: "5px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginRight: "5px",
                }}
              >
                Open
              </div>

              {/* <IconButton
                  aria-label="save"
                  style={{
                    transition: "all 0.3s ease-in-out 0s",
                  }}
                >
                  <BookmarkBorderOutlinedIcon color="secondary" />
                </IconButton>
                <IconButton
                  aria-label="save"
                  style={{
                    transition: "all 0.3s ease-in-out 0s",
                  }}
                >
                  <ShareOutlinedIcon color="secondary" />
                </IconButton> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={3}>
        <Grid item md={12} container className={classes.info} direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          {companyJobDetails?.start_date ? (
            <Grid item xs={6} md={2}>
              <div className={classes.infoitem} style={{}}>
                <PlayCircleFilledWhiteOutlinedIcon className={classes.info_icon} />
                <div className={classes.detail}>
                  <Typography variant="body2" color="textSecondary" style={{ fontWeight: "600" }}>
                    Start Date
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {startDate}
                  </Typography>
                </div>
              </div>
            </Grid>
          ) : null}

          {companyJobDetails?.job_duration ? (
            <Grid item xs={6} md={2}>
              <div className={classes.infoitem}>
                <ButtonBase className={classes.info_icon}>
                  <DateRangeOutlinedIcon className={classes.info_icon} />
                </ButtonBase>
                <div className={classes.detail}>
                  <Typography variant="body2" color="textSecondary" style={{ fontWeight: "600" }}>
                    Duration
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {companyJobDetails?.job_duration}
                  </Typography>
                </div>
              </div>
            </Grid>
          ) : null}

          {companyJobDetails?.ctc_min && companyJobDetails?.ctc_max ? (
            <Grid item xs={6} md={2}>
              <div className={classes.infoitem}>
                <AccountBalanceWalletOutlinedIcon className={classes.info_icon} />
                <div className={classes.detail}>
                  <Typography variant="body2" color="textSecondary" style={{ fontWeight: "600" }}>
                    Compensation
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {companyJobDetails?.ctc_min} - {companyJobDetails?.ctc_max}
                  </Typography>
                </div>
              </div>
            </Grid>
          ) : null}

          <Grid item xs={6} md={2}>
            <div className={classes.infoitem}>
              <HourglassEmptyOutlinedIcon className={classes.info_icon} />
              <div className={classes.detail}>
                <Typography variant="body2" color="textSecondary" style={{ fontWeight: "600" }}>
                  Apply By
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {companyJobDetails?.apply_by}
                </Typography>
              </div>
            </div>
          </Grid>

          {companyJobDetails?.job_type_name ? (
            <Grid item xs={6} md={2}>
              <div className={classes.infoitem}>
                <WorkOutlineOutlinedIcon className={classes.info_icon} />
                <div className={classes.detail}>
                  <Typography variant="body2" color="textSecondary" style={{ fontWeight: "600" }}>
                    Job Type
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {companyJobDetails?.job_type_name}
                  </Typography>
                </div>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      {companyJobDetails?.placement_cycle ? (
        <div className={classes.location}>
          <Box style={{ display: "flex" }}>
            <RoomOutlinedIcon />
            <Typography variant="body2" color="textSecondary" style={{ paddingBottom: "10px" }}>
              Placement Cycles
            </Typography>
          </Box>
          <div>
            {companyJobDetails?.placement_cycle.map((item) => (
              <Chip className={classes.chip} variant="outlined" size="small" label={item} color="primary" />
            ))}
          </div>
        </div>
      ) : null}
      {companyJobDetails.skills ? (
        <>
          <div className={classes.skill}>
            <Box style={{ display: "flex" }}>
              <SubjectIcon />
              <Typography variant="body2" color="textSecondary" style={{ paddingBottom: "10px" }}>
                Skills
              </Typography>
            </Box>
          </div>
          <div>
            {companyJobDetails?.skills.map((item) => (
              <Chip className={classes.chip} variant="outlined" size="small" label={item.skill_name} color="primary" />
            ))}
          </div>
        </>
      ) : null}

      {companyJobDetails?.job_location ? (
        <div className={classes.location}>
          <Box style={{ display: "flex" }}>
            <RoomOutlinedIcon />
            <Typography variant="body2" color="textSecondary" style={{ paddingBottom: "10px" }}>
              Location
            </Typography>
          </Box>
          <div>
            {companyJobDetails?.job_location.map((item) => (
              <Chip className={classes.chip} variant="outlined" size="small" label={item.city} color="primary" />
            ))}
          </div>
        </div>
      ) : null}

      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {Data.map(
            (item) =>
              item.show && (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>{item.icon}</Grid>
                  <Grid item xs>
                    <Typography variant="body2" color="secondary">
                      {item.info}
                    </Typography>
                  </Grid>
                </Grid>
              )
          )}
        </div>
        <div>
          {/* <Link to={`${companyJobDetails?.job_track_link}`}> */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.check}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`${companyJobDetails?.job_track_link}`);
            }}
          >
            Track
          </Button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}
