import { Typography, Box, Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { BackendBaseApi } from "../../../constants/constants";
import axios from "axios";
import { useAlert } from "react-alert";
import CustomCircularProgress from "../../CircularProgressBar";
import { CircularProgress } from "@mui/material";

function UploadDoc({
  disabled = false,
  details,
  setDetails,
  collegeId = "",
  selectedCollegeIds = null,
}) {
  console.log("selectedCollegeIds", selectedCollegeIds);
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const handleFile = (e) => {
    setFile(e.target.files[0]);
    // upload file api call
    setLoading(true);
    const body = new FormData();
    body.append("file", e.target.files[0]);
    body.append("college_id", collegeId ? collegeId : selectedCollegeIds);
    axios
      .post(`${BackendBaseApi.SANCHALAN}api/file/upload/`, body)
      .then((res) => {
        if (res?.data?.success) {
          alert.success(res?.data?.data?.message);
          setDetails({
            ...details,
            document_for_jd: res?.data?.data?.link,
            file_name: res?.data?.data?.file_name,
          });
        } else alert.error(res?.data?.data?.message);
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err?.data?.error);
        setLoading(false);
      });
  };
  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          Upload File
        </Typography>
        <Typography
          variant="subtitle2"
          style={{ color: "#6C757D", fontWeight: "400" }}
        >
          Supported files: doc, docx, pdf (2 MB)
        </Typography>

        <Box
          style={{
            marginTop: "10px",
            border: "1px solid #c5c5c5",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <input
            ref={inputRef}
            type="file"
            accept=".pdf, .doc"
            onChange={handleFile}
            style={{ display: "none" }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              style={{
                height: "40px",
                boxShadow: "none",
                width: "170px",
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
              onClick={() => {
                inputRef.current.click();
              }}
            >
              {loading ? (
                <>
                  <CircularProgress style={{ color: "white" }} size={14} />
                </>
              ) : (
                "Choose File"
              )}
            </Button>
            {details?.document_for_jd && details?.file_name ? (
              <>
                <Typography variant="body2" style={{ color: "#6C757D" }}>
                  {details?.file_name}
                </Typography>
              </>
            ) : !file ? (
              <>
                <Typography variant="body2" style={{ color: "#6C757D" }}>
                  No file chosen
                </Typography>
              </>
            ) : (
              <Typography variant="body2" style={{ color: "#6C757D" }}>
                {file?.name}
              </Typography>
            )}
          </div>
        </Box>
      </div>
    </>
  );
}

export default UploadDoc;
