import React, { useState } from "react";
import {
  Typography,
  Grid,
  InputLabel,
  Button,
  makeStyles,
  TextField,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

function CreateAccount({
  activeStep,
  setActiveStep,
  activeForm,
  setActiveForm,
  email,
  setEmail,
  loginDetails,
  setLoginDetails,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const sendOTP = (params) => {
    if (params) {
      const payload = {
        email: email,
        otp_type: "EMAIL",
        action_type: params,
      };
      axios
        .post(
          BackendBaseApi.PRAVESH + "api/saas/public_job/otp/send/",
          payload,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setLoading(false);
          if (res?.data?.success) {
            setActiveStep(1);
            alert.success(res?.data?.data?.message);
          } else alert.error(res?.data?.error);
        })
        .catch((err) => {
          alert.error("something went wrong");
          setLoading(false);
        });
    }
  };

  function validateEmail() {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleLogIn = async () => {
    const response = await axios.post(
      BackendBaseApi.PRAVESH + "api/saas/public_job/check_user/",
      { email: email },
      {
        withCredentials: true,
      }
    );
    return response;
  };

  const handleSubmit = () => {
    if (validateEmail()) {
      setLoading(true);
      handleLogIn()
        .then((res) => {
          if (res?.data?.success) {
            setLoginDetails({
              ...loginDetails,
              studentUser: res?.data?.data?.is_student_user,
              educationUser: res?.data?.data?.is_education_user,
              actionType: res?.data?.data?.action_type,
            });
            sendOTP(res?.data?.data?.action_type);
          } else {
            setLoading(false);
            alert.error(res?.data?.error);
          }
        })
        .catch((err) => {
          alert.error("something went wrong");
          setLoading(false);
        });
    } else alert.error("Enter Valid Email Address");
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 10, fontWeight: 600 }}>
            What’s your email?
          </InputLabel>
          <TextField
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleChangeEmail}
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ display: "grid" }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: "45px",
              boxShadow: "none",
              marginTop: "10px",
            }}
            onClick={() => handleSubmit()}
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Next"
            )}
          </Button>
          <Typography
            style={{ fontSize: 14, textAlign: "center", marginTop: 5 }}
          >
            Already have an account?{" "}
            <span
              style={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                fontWeight: "600",
              }}
              onClick={() => setActiveForm(1)}
            >
              Log in
            </span>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateAccount;
