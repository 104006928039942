//Imports
import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
//Exports
export const SELECTED_JOB_UPLOADER_FIELDS = "SELECTED_JOB_UPLOADER_FIELDS";
export const JOB_UPLOADER_FIELDS = "JOB_UPLOADER_FIELDS";

//JOb Fields Api Call Action
export const JobUploaderFieldsAPI = (
  setLoading,
  alert,
  history,
  location,
  collegeId,
  job_type
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${
          BackendBaseApi.SANCHALAN
        }api/uploader_form/job_post_fields/?college_id=${collegeId}&is_internship=${
          job_type && job_type === "internship" ? 1 : 0
        }`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: JOB_UPLOADER_FIELDS,
      payload: res.data.data,
    });
  };
};

//company Form Selected Fields
export const JobSelectedFieldsAPI = (
  setLoading,
  alert,
  history,
  location,
  collegeId,
  job_type
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${
          BackendBaseApi.SANCHALAN
        }api/uploader_form/job_post_fields/selected/?filter_college_id=${collegeId}&is_internship=${
          job_type && job_type === "internship" ? 1 : 0
        }`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_JOB_UPLOADER_FIELDS,
      payload: res.data.data,
    });
  };
};

export const JobSelectedLocalStateFieldsAPI = (
  alert,
  history,
  location,
  collegeId,
  job_type,
  setFullTimeJobs,
  setInternships
) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/uploader_form/job_post_fields/selected/?filter_college_id=${collegeId}&is_internship=${job_type}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      if (job_type === 0) {
        setFullTimeJobs(
          res?.data?.data?.job_form_fields
            ? res?.data?.data?.job_form_fields
            : []
        );
      } else {
        setInternships(
          res?.data?.data?.job_form_fields
            ? res?.data?.data?.job_form_fields
            : []
        );
      }
    } else {
      alert.error("Some Error Occurred!!!");
    }
  };
};

// Job Field Post Api
export const JobFieldsPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "api/uploader_form/job_post_fields/selected/?filter_college_id=" +
          collegeId,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("Updated Successfully");
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};
