import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";
import Course from "../../Course";

const VisitCompanyBar = ({ placement }) => {
  return (
    <div
      style={{
        boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
        borderRadius: "8px",
        padding: "30px",
      }}
    >
      <Charst
        options={{
          xaxis: {
            title: {
              text: "CTC in Lpa",
              style: {
                fontSize: "14px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            categories: placement?.categories ? placement?.categories : [],
          },

          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: "10px",
              fontFamily: "Nunito",
              colors: ["#304758"],
            },
          },

          yaxis: {
            title: {
              text: "Company",
              style: {
                fontSize: "14px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 8,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          title: {
            text: "Offered CTC vs Visited Company",
            style: {
              fontSize: "14px",
              fontFamily: "Nunito",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        }}
        series={placement?.series_list ? placement?.series_list : []}
        type="bar"
        // height={220}
        height="100%"
        // width="100%"
      />
    </div>
  );
};

export default VisitCompanyBar;
