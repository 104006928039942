import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    width: "60%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    borderRadius: "8px",
    margin: theme.spacing(3, 0, 2),
    boxShadow: "none",
  },
  paper2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function ResetyourPassword() {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  return (
    <>
      <div className={classes.paper2}>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" color="inherit">
            Reset your Password
          </Typography>
          <br />
          <Typography variant="body2" color="secondary">
            You have successfully updated your Password
          </Typography>
          <form className={classes.form} noValidate>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
}
