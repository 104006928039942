import React, { useEffect, useMemo, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Typography,
  useTheme,
  // Checkbox,
  TextField,
  // FormControl,
  InputLabel,
  // Input,
  // MenuItem,
  // ListItemText,
  FormLabel,
  makeStyles,
  Button,
  withStyles,
} from "@material-ui/core";
import CampusBarGraph from "./CampusBarGraph";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import ReportTable from "./ReportTable";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import {
  Select,
  Input,
  ListItemText,
  Checkbox,
  FormControl,
  MenuItem,
  MenuList,
  Menu,
  styled,
  InputBase,
  InputAdornment,
} from "@mui/material";
import ScrollBarGraph from "./ScrollBarGraph";
import { sanchalanAxiosGetReq } from "../../../api/BaseApi/apiBase";
import { useAlert } from "react-alert";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import GraphSkeleton from "./GraphSkeleton";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#F6F6F6",
    border: "none",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    fontFamily: "Nunito",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 350,
    width: 350,
  },
  selectOptions: {
    "& .MuiListItem-root": {
      // borderTop: "1px solid rgb(3,15,252)",
      // borderRadius: 8,
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
    backgroundColor: "#fff",
    "& .MuiCheckbox-root": {
      // color: "green",
    },
    "& .MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        // color: "orange",
      },
    },
  },
}));

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 350,
      marginLeft: "-17px",
    },
  },
};

function ReportHeading({
  selectedPlacement,
  collegeId,
  yAxis,
  xAxis,
  heading,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [value, setValue] = useState(2);
  const [tabData, setTabData] = useState(null);

  function handleChange(id) {
    setValue(id);
  }

  const [loading, setLoading] = useState(false);

  const [personName, setPersonName] = React.useState([]);
  MenuProps.PaperProps.className = classes.selectOptions; //had to be here to use hook, and menu uses popper

  const handleChangeField = (event) => {
    setPersonName(event.target.value);
  };

  const chartHelper = () => {
    if (tabData) {
      const labels = [];
      const data = [];
      const color = [];
      for (let key of tabData) {
        labels.push(key.name);
        data.push(key.value);
        color.push(key.color);
      }

      return {
        labels: labels,
        data: data,
        color: color[0],
        yAxis: yAxis,
        xAxis: xAxis,
        heading:heading
      };
    }
  };

  useEffect(() => {
    if (selectedPlacement) {
      let url;
      if (heading === "Course vs Job")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_data/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Course vs Student")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_data/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Course vs Highest CTC")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles//get_job_data/job_highest_ctc/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Course vs Average CTC")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_data/job_average_ctc/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Industry vs Jobs")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_data/job_industry_wise/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Course vs Student Placed(%)")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_placed/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Course vs Job Selection(%)")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else if (heading === "Course vs Highest CTC Placed")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_highest_ctc/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
        else if (heading === "Course vs Average CTC Placed")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_average_ctc/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
        else if (heading === "Industry vs Placed Jobs")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_placed_data/job_industry_wise/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      setLoading(true);
      url &&
        axios
          .get(url, { withCredentials: true })
          .then((res) => {
            if (res?.data?.success) {
              setTabData(res?.data?.data);
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            alert.error(err?.response?.data?.error);
          });
    }
  }, [selectedPlacement]);

  const data = [
    {
      id: 2,
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <BarChartOutlinedIcon /> Graph
        </div>
      ),
      // tab: (<>{loading ? <GraphSkeleton/> :<ScrollBarGraph chartData={chartHelper()} yAxis={yAxis} xAxis={xAxis}  />}</>),
      tab: (
        <>
          {loading ? (
            <GraphSkeleton />
          ) : (
            <CampusBarGraph DATA={chartHelper()} />
          )}
        </>
      ),
    },
    {
      id: 1,
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TableChartOutlinedIcon style={{ marginRight: 5 }} /> Table
        </div>
      ),
      tab: (
        <div style={{ maxHeight: "300px", overflow: "scroll" }}>
          {" "}
          <ReportTable chartData={chartHelper()} />{" "}
        </div>
      ),
    },
  ];

  const memoizedData = useMemo(() => {
    return data;
  }, [loading]);

  return (
    <>
      <div
        style={{
          border: "1px solid #b0b6ba",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection:"row-reverse"

          }}
        >
          {/* <div>
            <Typography variant="h6">{heading}</Typography>
          </div> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              {" "}
              {/* <Autocomplete
                id="custom-input-demo"
                options={options}
                renderInput={(params) => (
                  <div
                    ref={params.InputProps.ref}
                    style={{
                      padding: 10,
                      borderRadius: 8,
                      backgroundColor: "#F6F6F6",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <SearchRoundedIcon style={{ color: "#6C757D" }} />
                    <input
                      style={{
                        width: 250,
                        backgroundColor: "#F6F6F6",
                        border: "none",
                        color: "#6C757D",
                      }}
                      type="text"
                      placeholder="Search course"
                      {...params.inputProps}
                    />
                  </div>
                )}
              /> */}
              {/* <FormControl className={classes.formControl} variant="outlined">
                <Select
                  style={{
                    backgroundColor: "#F6F6F6",
                    paddingLeft: 10,
                    borderRadius: 8,
                  }}
                  displayEmpty
                  multiple
                  value={personName}
                  onChange={handleChangeField}
                  input={
                    <BootstrapInput
                      style={{ width: "100%" }}
                      startAdornment={
                        <SearchRoundedIcon style={{ color: "#6C757D" }} />
                      }
                    />
                  }
                  // input={
                  //   <Input
                  //     disableUnderline={true}
                  //     placeholder="dfghj"
                  //     style={{
                  //       width: "100%",
                  //       // width: 300,
                  //       backgroundColor: "#F6F6F6",
                  //       border: "none",
                  //       color: "#6C757D",
                  //       fontSize: "14px",
                  //       height: "40px",
                  //       // padding: 5,
                  //       borderRadius: 6,
                  //     }}
                  //     startAdornment={
                  //       <SearchRoundedIcon style={{ color: "#6C757D" }} />
                  //     }
                  //   />
                  // }
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <>Search course</>;
                    }
                    return selected.join(", ");
                  }}
                  //renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={personName.indexOf(name) > -1}
                        sx={{
                          color: theme.palette.primary.main,
                          "&.Mui-checked": {
                            color: theme.palette.primary.main,
                          },
                        }}
                        size="small"
                      />
                      <Typography style={{ fontSize: "14px" }} noWrap>
                        {name}
                      </Typography>
                    </MenuItem>
                  ))}

                  <MenuItem
                    style={{
                      position: "sticky",
                      bottom: 0,
                      marginBottom: "-20px",
                      backgroundColor: "#EFEEEE",
                      display: "flex ",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      padding: 0,
                    }}
                  >
                    <div
                      style={{
                        padding: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        width: "100%",
                        backgroundColor: "#EFEEEE",
                        display: "flex ",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        style={{ height: "35px", display: "flex" }}
                        color="primary"
                        variant="text"
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          height: "35px",
                          display: "flex",
                          boxShadow: "none",
                          marginRight: 10,
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl> */}
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  //   padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <div style={{ display: "flex" }}>
                  {memoizedData.map((item, id) => (
                    <ul
                      style={{
                        padding: 0,
                        display: "flex",
                        listStyleType: "none",
                        flexWrap: "wrap",
                        textAlign: "center",
                      }}
                      id="myTab"
                      data-tabs-toggle="#myTabContent"
                      role="tablist"
                    >
                      <li
                        className="mr-2"
                        role="presentation"
                        onClick={() => handleChange(item.id)}
                      >
                        <div
                          style={{
                            padding: "6px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            color:
                              value === item.id
                                ? "#fff"
                                : theme.palette.primary.main,
                            backgroundColor:
                              value === item.id
                                ? theme.palette.primary.main
                                : "#fff",
                          }}
                        >
                          {item.title}
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {memoizedData.map((item, id) => (
          <div id="myTabContent">
            <div
              style={{
                display: value === item.id ? "block " : "none",
                marginTop: "20px",
              }}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {item.tab}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ReportHeading;
