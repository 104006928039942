// import { makeStyles } from "@material-ui/core/styles";
// import { DataGrid } from "@material-ui/data-grid";
// import React from "react";
// import { useSelector } from "react-redux";
// import { themeSelectorFn } from "../../../api/Domain/selector";
// import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";

// const useStyles = makeStyles((theme) => ({
//   lay: {
//     width: "100%",
//     "@media only screen and (min-width: 2000px)": {
//       height: "600px",
//     },
//     "@media only screen and (max-width: 2000px)": {
//       height: "400px",
//     },
//     "@media only screen and (max-width: 1700px)": {
//       height: "370px",
//     },
//     "@media only screen and (max-width: 1300px)": {
//       height: "370px",
//     },
//   },

//   root: {
//     display: "flex",
//     justifyContent: "center",
//   },
// }));

// export default function CompanyTable({
//   loading,
//   list,
//   columns,
//   dataLoading,
//   setSelectedRowsData,
//   selectedID,
//   setSelectedID,
//   filterDataText,
//   checkBoxSelection = true,
//   fromStudentDetials = false,
//   isPaginated = true,
//   pageData = null,
//   setPageData = () => {},
//   fromProfileUpdate = false,
// }) {
//   const theme_change = useSelector(themeSelectorFn);
//   const classes = useStyles();
//   const [pageSize, setPageSize] = React.useState(20);

//   return (
//     <div className={classes.lay}>
//       <DataGrid
//         style={{
//           borderRadius: "8px",
//           backgroundColor: "#fff",
//           border: "none",
//           textAlign: "center",
//         }}
//         rows={list?.length > 0 ? list : []}
//         columns={columns}
//         selectionModel={selectedID}
//         onSelectionModelChange={(ids) => {
//           const selectedIDs = new Set(ids);
//           const selectedRowData = fromProfileUpdate
//             ? list?.filter((row) => selectedIDs.has(row?.id))
//             : list?.filter((row) => selectedIDs.has(row?.id?.toString()));

//           setSelectedRowsData(selectedRowData);
//           setSelectedID(ids);
//         }}
//         disableColumnSelector
//         pageSize={pageData ? pageData?.pageSize : pageSize}
//         onPageSizeChange={
//           pageData
//             ? (newPageSize) =>
//                 setPageData({
//                   ...pageData,
//                   pageSize: newPageSize,
//                   currentPage: 0,
//                 })
//             : (newPageSize) => setPageSize(newPageSize)
//         }
//         // pageSize={pageData?.pageSize ?? pageSize}
//         page={pageData?.currentPage ? pageData?.currentPage : null}
//         onPageChange={
//           pageData
//             ? (page) =>
//                 setPageData({
//                   ...pageData,
//                   previousPage: pageData?.currentPage,
//                   currentPage: page,
//                 })
//             : null
//         }
//         loading={loading}
//         // onPageSizeChange={
//         //   pageData
//         //     ? (newPageSize) =>
//         //         setPageData({
//         //           ...pageData,
//         //           pageSize: newPageSize,
//         //           currentPage: 0,
//         //         })
//         //     : (newPageSize) => setPageSize(newPageSize)
//         // }
//         rowsPerPageOptions={[5, 10, 15, 20]}
//         hideFooterPagination={!isPaginated}
//         hideFooterSelectedRowCount={!isPaginated}
//         pagination
//         {...list}
//         checkboxSelection={fromStudentDetials ? false : checkBoxSelection}
//         disableSelectionOnClick
//         rowCount={list?.length}
//         // loading={dataLoading}
//         componentsProps={{
//           pagination: {
//             style: {
//               display: "flex",
//               justifyContent: "center",
//               margin: "auto",
//             },
//           },
//         }}
//         components={{
//           NoRowsOverlay: () => (
//             <>
//               <br /> <br />
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   margin: "auto",
//                   paddingTop: 30,
//                 }}
//               >
//                 <NoData
//                   height="180px"
//                   width="180px"
//                   alt="404 Error"
//                   fill={theme_change?.main ? theme_change?.main : "#007BFF"}
//                 />

//                 <span align="center" style={{ fontSize: 14, color: "#6c757d" }}>
//                   <b>
//                     {" "}
//                     {filterDataText ? filterDataText : "No Data Available"}
//                   </b>
//                 </span>
//               </div>
//             </>
//           ),
//         }}
//       />
//     </div>
//   );
// }

import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../../api/Domain/selector";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";

const useStyles = makeStyles((theme) => ({
  lay: {
    width: "100%",
    "@media only screen and (min-width: 2000px)": {
      height: "600px",
    },
    "@media only screen and (max-width: 2000px)": {
      height: "400px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "370px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "370px",
    },
  },

  root: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function CompanyTable({
  loading,
  list,
  columns,
  setSelectedRowsData = [],
  selectedID,
  setSelectedID,
  filterDataText,
  checkBoxSelection = true,
  fromStudentDetials = false,
  isPaginated = true,
  pageData = null,
  setPageData = () => {},
  fromProfileUpdate = false,
}) {
  const theme_change = useSelector(themeSelectorFn);
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState(20);

  return (
    <div className={classes.lay}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
        }}
        rows={list?.length > 0 ? list : []}
        columns={columns}
        selectionModel={selectedID}
        slots={{
          toolbar: GridToolbar,
        }}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = fromProfileUpdate
            ? list?.filter((row) => selectedIDs.has(row?.id))
            : list?.filter((row) => selectedIDs.has(row?.id?.toString()));

          setSelectedRowsData(selectedRowData);
          setSelectedID(ids);
        }}
        disableColumnSelector
        pageSize={pageData ? pageData?.pageSize : pageSize}
        onPageSizeChange={
          pageData
            ? (newPageSize) =>
                setPageData({
                  ...pageData,
                  pageSize: newPageSize,
                  currentPage: 0,
                })
            : (newPageSize) => setPageSize(newPageSize)
        }
        // pageSize={pageData?.pageSize ?? pageSize}
        // page={pageData?.currentPage ? pageData?.currentPage : null}
        onPageChange={
          pageData
            ? (page) =>
                setPageData({
                  ...pageData,
                  previousPage: pageData?.currentPage,
                  currentPage: page,
                })
            : null
        }
        // onPageSizeChange={
        //   pageData
        //     ? (newPageSize) =>
        //         setPageData({
        //           ...pageData,
        //           pageSize: newPageSize,
        //           currentPage: 0,
        //         })
        //     : (newPageSize) => setPageSize(newPageSize)
        // }
        rowsPerPageOptions={[5, 10, 15, 20]}
        hideFooterPagination={!isPaginated}
        hideFooterSelectedRowCount={!isPaginated}
        pagination
        {...list}
        checkboxSelection={fromStudentDetials ? false : checkBoxSelection}
        disableSelectionOnClick
        rowCount={list?.length}
        loading={loading}
        componentsProps={{
          pagination: {
            style: {
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            },
          },
        }}
        components={{
          NoRowsOverlay: () => (
            <>
              <br /> <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  paddingTop: 30,
                }}
              >
                <NoData
                  height="180px"
                  width="180px"
                  alt="404 Error"
                  fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                />

                <span align="center" style={{ fontSize: 14, color: "#6c757d" }}>
                  <b>
                    {" "}
                    {filterDataText ? filterDataText : "No Data Available"}
                  </b>
                </span>
              </div>
            </>
          ),
        }}
      />
    </div>
  );
}
