import { Typography } from "@material-ui/core";
import React from "react";
import Logo from "../../../assets/png/white-logo.png";

function FormFooter() {
  return (
    <div
      style={{
        backgroundColor: "#000",
        textAlign: "center",
        marginTop: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Typography
          variant="body2"
          style={{ color: "#fff", marginRight: "10px" }}
        >
          Powered by
        </Typography>
        <img src={Logo} height="20px" width="auto" />
      </div>
    </div>
  );
}

export default FormFooter;
