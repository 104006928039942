import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Chip, Grid, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import pSBC from "shade-blend-color";
import { Autocomplete } from "@material-ui/lab";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",
  },
  paper: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  newBtnStyle: {
    maxWidth: "200px",
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));

export default function Skills({
  skillsList,
  userSkills,
  postSkills,
  skillsDataLoading,
  redirectionData,
  approvalRequestCounts,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const [tempUserSkills, setTempUserSkills] = useState([]);

  useEffect(() => {
    setTempUserSkills(userSkills ? userSkills : []);
  }, [userSkills]);

  const handleDelete = (index) => {
    setTempUserSkills((tempUserSkills) =>
      tempUserSkills.filter((_, skillIndex) => skillIndex !== index)
    );
  };

  const addSkill = (skill) => {
    if (skill) {
      const skillAlreadyAdded = tempUserSkills.some(
        (skl) => skl.skill_id === skill.skill_id
      );
      if (skillAlreadyAdded) alert.error("Already Added!");
      else {
        setTempUserSkills([
          ...tempUserSkills,
          {
            skill_id: skill.skill_id,
            skill_name: skill.skill_name,
            type: skill.type,
            skill_rating_value: 3,
            skill_rating: "Intermediate",
          },
        ]);
      }
    }
  };
  return (
    <div className={classes.paper}>
      <Typography variant="h5">Skills</Typography>
      <Grid
        item
        xs={12}
        style={
          approvalRequestCounts?.SKILL
            ? { pointerEvents: "none", opacity: 0.4 }
            : {}
        }
      >
        <div style={{ margin: "20px 10px" }}>
          <Grid container spacing={2}>
            <Autocomplete
              // multiple
              style={{ width: "100%" }}
              id="combo-box-demo"
              onChange={(_, newValue) => {
                if (newValue) {
                  addSkill(newValue);
                }
              }}
              options={skillsList}
              getOptionLabel={(option) => `${option.skill_name}`}
              renderOption={(option) => {
                return (
                  <span style={{ fontSize: 13 }}>{`${option.skill_name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Select Skill"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                  />
                );
              }}
            />
          </Grid>
        </div>

        <div className={classes.root}>
          {tempUserSkills &&
            tempUserSkills.map((data, index) => {
              return (
                <li key={data.skill_id}>
                  <Chip
                    color="primary"
                    deleteIcon={<CloseIcon color="primary" />}
                    variant="outlined"
                    size="small"
                    label={data.skill_name}
                    onDelete={() => {
                      handleDelete(index);
                    }}
                    className={classes.chip}
                  />
                </li>
              );
            })}
        </div>
      </Grid>

      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.newBtnStyle}
          onClick={
            approvalRequestCounts?.SKILL
              ? () => {
                  history.push({
                    pathname: `/student-profile-request/${redirectionData?.name.replace(
                      / /g,
                      "_"
                    )}-${redirectionData?.userId}`,
                  });
                }
              : () => postSkills(tempUserSkills)
          }
        >
          {skillsDataLoading ? (
            <CustomButtonCircularProgress />
          ) : approvalRequestCounts?.SKILL ? (
            `Approve Request (${approvalRequestCounts?.SKILL})`
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
}
