import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import Chart from "react-apexcharts";

const BarChart = ({ data, xAxis }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      title: {
        text: "Degree wise Placements",

        style: {
          fontSize: "14px",
          fontFamily: "Nunito",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      categories: xAxis,
    },
    yaxis: {
      title: {
        text: "No. of Hired Students",
        style: {
          fontSize: "14px",
          fontFamily: "Nunito",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
  });
  return (
    <>
      {data?.length >= 0 ? (
        <div>
          <Chart
            options={options}
            series={data ? data : []}
            type="bar"
            height={360}
            width="100%"
          />
        </div>
      ) : null}
    </>
  );
};

export default BarChart;
