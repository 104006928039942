export const getWebsiteLogo = (logo, domain, defaultLogo) => {
  if (logo) {
    return logo;
  }

  if (domain) {
    const formattedDomain = domain.startsWith("http")
      ? domain
      : `https://${domain}`;
    return `https://logo.clearbit.com/${formattedDomain}`;
  }

  return defaultLogo;
};
