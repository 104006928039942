import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

function ColorPicker(props) {
  const styles = reactCSS({
    default: {
      color: {
        width: "56px",
        height: "24px",
        borderRadius: "8px",
        background: props.data.color,
      },
      swatch: {
        borderRadius: "8px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "relative",
        zIndex: "2",
      },
      cover: {
        // position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={props.handleClick}>
        <div style={styles.color} />
      </div>
      {props.data.displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={props.handleClose} />
          <SketchPicker color={props.data.color} onChange={props.handleChange} />
        </div>
      ) : null}
    </div>
  );
}

export default ColorPicker;
