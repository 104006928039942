import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ReactComponent as Intern }  from "../../assets/svg/intern.svg";
import { ReactComponent as FullTime }  from "../../assets/svg/Multitasking-rafiki.svg";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../api/Domain/selector";

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "48px",
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
  },
  sliderbutton: {
    border: "none",
    backgroundColor: "transparent",
    padding: "0",
  },
  arrow: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "40px",
    cursor: "pointer",
    height: "40px",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  paper: {
    padding: "20px",
    // maxHeight: "400px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid",
    borderColor: "#e2e6ea",
  },
  img: {
    marginTop: "20px",
    marginBottom: "20px",
  },
}));

function JobPosting({ action, fullTimeJobs, internships }) {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);

  return (
    <>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              {/* <img src={Multi} height="150px" width="150px" className={classes.img} /> */}
              <FullTime height="150px" width="150px" alt="404 Error" className={classes.img} fill={theme_change?.main ? theme_change?.main : "#007BFF"} />
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                }}
              >
                Manage Full Time Job posting form
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#6c757d",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Full-time work offers a pathway to career advancement. Perks are the cherry on top of the employment cake, and they come in all shapes and sizes.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={() => {
                  action("full-time");
                }}
              >
                {fullTimeJobs?.length > 0 ? "Update Full time Job Form" : "Create Full time Job Form"}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Intern height="150px" width="150px" alt="404 Error" className={classes.img} fill={theme_change?.main ? theme_change?.main : "#007BFF"} />
              <Typography variant="h6">Manage Internship Job Form</Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#6c757d",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                An internship is a great way to get career the much needed hands-on learning and real-time experience in the beginning of the career.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={() => {
                  action("internship");
                }}
              >
                {internships?.length > 0 ? "Update Internship Form" : "Create Internship Form"}{" "}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default JobPosting;
