import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CheckUserAPI,
  DegreesGetAPI,
  SpecGetAPI,
} from "../../../../api/Common/action";
import { isGradeSystemFn } from "../../../../api/Domain/selector";
import { StudentsPostAPI } from "../../../../api/ManageStudents/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../../api/SignIn/selector";
import { groupViewCheck } from "../../../../utility/groupViewCheck";
import { emailRegex } from "../../../../utility/regex";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import Calendar from "../../../Input/Calendar/blockedDates";
import debounce from "lodash.debounce";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },

  btncancel: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },

  formControl: {
    minWidth: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function StudentDialogForm({
  handleClose,
  studentList,
  currentTab,
  placementSession,
  fromGroup,
  setAddStudentClicked,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeId = useSelector(collegeIdFn);
  const mainCollegeList = useSelector(collegeListSelectorFn);

  const [studentDetails, setStudentDetails] = useState({
    name: null,
    email: null,
    phone: null,
    enrollment_no: null,
    degree: null,
    course: null,
    batch: null,
    start_date: null,
    end_date: null,
    cgpa: null,
    backlog_check: false,
    backlog: null,
    college: collegeId ? { id: collegeId } : null,
  });

  const [studentDetailsError, setStudentDetailsError] = useState({
    name: null,
    email: null,
    phone: null,
    enrollment_no: null,
    degree: null,
    course: null,
    batch: null,
    start_date: null,
    end_date: null,
    cgpa: null,
    backlog: null,
    college: null,
  });

  const validateData = () => {
    var is_valid = true;
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    var enrollment_no_error = null;
    var degree_error = null;
    var course_error = null;
    var batch_error = null;
    var cgpa_error = null;
    var start_date_error = null;
    var end_date_error = null;
    var backlog_error = null;
    var college_error = null;
    if (!studentDetails.name) {
      name_error = "Name field cannot be left blank";
      is_valid = false;
    }

    if (!studentDetails.email || studentDetailsError?.email) {
      email_error = studentDetailsError?.email
        ? studentDetailsError?.email
        : "Email field cannot be left blank";
      is_valid = false;
    }
    if (!emailRegex.test(studentDetails.email)) {
      email_error = "Please enter a valid email";
      is_valid = false;
    }
    if (!studentDetails.phone) {
      phone_error = "Phone Number field cannot be left blank";
      is_valid = false;
    }
    if (studentDetails.phone && studentDetails?.phone?.length !== 10) {
      phone_error = "Enter a valid 10 digit phone number";
      is_valid = false;
    }
    if (!studentDetails?.enrollment_no) {
      enrollment_no_error = "Please enter student's enrollment number";
      is_valid = false;
    }

    const emptySpaceCheck = /\s/g;

    if (emptySpaceCheck.test(studentDetails?.enrollment_no)) {
      enrollment_no_error = "No blank space in enrollment Number";
      is_valid = false;
    }

    if (
      groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) &&
      !studentDetails?.college
    ) {
      college_error = "Please enter student's college";
      is_valid = false;
    }
    if (!studentDetails?.degree) {
      degree_error = "Please enter student's degree";
      is_valid = false;
    }
    if (!studentDetails?.course) {
      course_error = "Please enter student's course";
      is_valid = false;
    }
    // if (!studentDetails?.batch) {
    //   batch_error = "Please enter student's Batch";
    //   is_valid = false;
    // }
    if (!studentDetails?.start_date) {
      start_date_error = "Please enter student's Course Start Date";
      is_valid = false;
    }
    if (!studentDetails?.end_date) {
      end_date_error = "Please enter student's Course End Date";
      is_valid = false;
    }
    if (!studentDetails?.cgpa) {
      cgpa_error = "Please enter student's Percentage";
      is_valid = false;
    }
    if (studentDetails?.cgpa) {
      if (isGradeSystem) {
        if (Number(studentDetails?.cgpa) > 10) {
          cgpa_error =
            "Please enter Student's CGPA Correctly within the range of 0 to 10";
          is_valid = false;
        }
      } else {
        if (Number(studentDetails?.cgpa) > 100) {
          cgpa_error =
            "Please enter Student's Percentage Correctly within the range of 0 to 100";
          is_valid = false;
        }
      }
    }
    if (studentDetails?.backlog_check && !studentDetails?.backlog) {
      backlog_error = "Please enter backlog count";
      is_valid = false;
    }

    setStudentDetailsError({
      ...studentDetailsError,
      name: name_error,
      email: email_error,
      phone: phone_error,
      enrollment_no: enrollment_no_error,
      degree: degree_error,
      course: course_error,
      batch: batch_error,
      start_date: start_date_error,
      end_date: end_date_error,
      cgpa: cgpa_error,
      backlog: backlog_error,
      college: college_error,
    });
    return is_valid;
  };
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const college_id = collegeDetail?.college_id;
  const [loadingDegree, setLoadingDegree] = useState(false);

  useEffect(() => {
    if (studentDetails?.college)
      dispatch(DegreesGetAPI(setLoadingDegree, studentDetails?.college?.id));
  }, [studentDetails?.college]);

  useEffect(() => {
    if (studentDetails?.degree) {
      dispatch(
        SpecGetAPI(studentDetails?.degree?.id, studentDetails?.college?.id)
      );
    }
  }, [studentDetails?.degree]);

  const degreeList = useSelector((state) => state?.commonData?.degrees);

  const courseList = useSelector((state) => state?.commonData?.specialization);

  const handleSubmit = () => {
    if (validateData()) {
      var obj = { ...studentDetails };
      obj.batch = parseInt(studentDetails?.end_date?.slice(0, 4));
      obj.course = {
        id: studentDetails?.course?.specialization_id,
        name: studentDetails?.course?.specialization_name,
      };
      if (isGradeSystem) {
        obj.percentage = 0;
        obj.cgpa = parseFloat(studentDetails?.cgpa ? studentDetails?.cgpa : 0);
      } else {
        obj.cgpa = 0;
        obj.percentage = parseFloat(
          studentDetails?.cgpa ? studentDetails?.cgpa : 0
        );
      }
      if (studentDetails?.backlog_check) {
        obj.backlog = studentDetails?.backlog ? studentDetails?.backlog : 0;
      }
      var data = {};
      if (placementSession?.id !== "All") {
        if (!fromGroup)
          data = {
            college_id: groupViewCheck(
              isEducationGroup,
              isCollegeGroup,
              collegeId
            )
              ? [studentDetails?.college?.id]
              : [collegeId],
            student_form_data: obj,
            assigned_placement_cycle: [placementSession?.id],
          };
        else
          data = {
            college_id: groupViewCheck(
              isEducationGroup,
              isCollegeGroup,
              collegeId
            )
              ? [studentDetails?.college?.id]
              : [collegeId],
            student_form_data: obj,
          };
      } else {
        data = {
          college_id: groupViewCheck(
            isEducationGroup,
            isCollegeGroup,
            collegeId
          )
            ? [studentDetails?.college?.id]
            : [collegeId],
          student_form_data: obj,
        };
      }
      delete data.student_form_data["college"];

      dispatch(
        StudentsPostAPI(
          data,
          setLoading,
          alert,
          history,
          location,
          college_id,
          false,
          studentList,
          handleClose,
          currentTab
        )
      ).then(() => {
        setAddStudentClicked(true);
      });
    }
  };
  const isGradeSystem = useSelector(isGradeSystemFn);

  const debouncedSave = useCallback(
    debounce(
      (search) =>
        dispatch(
          CheckUserAPI(search, setStudentDetailsError, studentDetailsError)
        ),
      700
    ),
    []
  );
  useEffect(() => {
    if (studentDetails?.email) {
      debouncedSave(studentDetails?.email);
    }
  }, [studentDetails?.email]);

  return (
    <>
      <div className={classes.paper}>
        <Typography variant="h6">Add New Student</Typography>
        <br />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label="Email"
          value={studentDetails.email}
          onChange={(e) => {
            setStudentDetails({ ...studentDetails, email: e.target.value });
            setStudentDetailsError({ ...studentDetailsError, email: null });
          }}
          error={studentDetailsError.email ? true : false}
          helperText={<span>{studentDetailsError.email}</span>}
        />

        <TextField
          variant="outlined"
          required
          margin="normal"
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          fullWidth
          label="Name"
          value={studentDetails.name}
          onChange={(e) => {
            setStudentDetails({ ...studentDetails, name: e.target.value });
            setStudentDetailsError({ ...studentDetailsError, name: null });
          }}
          error={studentDetailsError.name ? true : false}
          helperText={<span>{studentDetailsError.name}</span>}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label="Enrollment Id"
          value={studentDetails.enrollment_no}
          onChange={(e) => {
            setStudentDetails({
              ...studentDetails,
              enrollment_no: e.target.value,
            });
            setStudentDetailsError({
              ...studentDetailsError,
              enrollment_no: null,
            });
          }}
          error={studentDetailsError.enrollment_no ? true : false}
          helperText={<span>{studentDetailsError.enrollment_no}</span>}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label="Phone Number"
          name="phone number"
          type="number"
          value={studentDetails.phone}
          onChange={(e) => {
            setStudentDetails({ ...studentDetails, phone: e.target.value });
            setStudentDetailsError({ ...studentDetailsError, phone: null });
          }}
          error={studentDetailsError.phone ? true : false}
          helperText={<span>{studentDetailsError.phone}</span>}
        />

        {groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
          <Autocomplete
            id="country-select-demo"
            options={mainCollegeList}
            getOptionLabel={(option) => option.name}
            value={studentDetails.college}
            onChange={(e, newValue) => {
              setStudentDetails({ ...studentDetails, college: newValue });
              setStudentDetailsError({ ...studentDetailsError, college: null });
            }}
            className={classes.formControl}
            style={{ marginBottom: 23 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select College"
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={studentDetailsError.college ? true : false}
                helperText={<span>{studentDetailsError.college}</span>}
              />
            )}
          />
        )}

        <Autocomplete
          id="country-select-demo"
          options={degreeList}
          getOptionLabel={(option) => option.name}
          value={studentDetails.degree}
          onChange={(e, newValue) => {
            setStudentDetails({ ...studentDetails, degree: newValue });
            setStudentDetailsError({ ...studentDetailsError, degree: null });
          }}
          className={classes.formControl}
          disabled={
            !fromGroup ? false : studentDetails?.college?.id ? false : true
          }
          style={{ marginBottom: 23 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Degrees"
              inputProps={{
                ...params.inputProps,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={studentDetailsError.degree ? true : false}
              helperText={<span>{studentDetailsError.degree}</span>}
            />
          )}
        />

        <Autocomplete
          id="country-select-demo"
          options={courseList}
          getOptionLabel={(option) => option.specialization_name}
          value={studentDetails.course}
          onChange={(e, newValue) => {
            setStudentDetails({ ...studentDetails, course: newValue });
            setStudentDetailsError({ ...studentDetailsError, course: null });
          }}
          disabled={courseList ? false : true}
          style={{ marginBottom: 23 }}
          className={classes.formControl}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Courses"
              inputProps={{
                ...params.inputProps,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={studentDetailsError.course ? true : false}
              helperText={<span>{studentDetailsError.course}</span>}
            />
          )}
        />

        {/* <Autocomplete
          id="country-select-demo"
          options={years_list}
          getOptionLabel={(option) => String(option.name)}
          value={studentDetails?.batch}
          className={classes.formControl}
          onChange={(e, newValue) => {
            setStudentDetails({ ...studentDetails, batch: newValue });
            setStudentDetailsError({ ...studentDetailsError, batch: null });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Eligible Batches"
              inputProps={{
                ...params.inputProps,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={studentDetailsError?.batch ? true : false}
              helperText={<span>{studentDetailsError?.batch}</span>}
            />
          )}
        /> */}
        <Grid
          container
          spacing={3}
          style={{ marginTop: "-10px", marginBottom: "0px" }}
        >
          <Grid item xs={6}>
            <Calendar
              selectedDate={studentDetails?.start_date}
              title="Course Start Date"
              onDateChange={(date) => {
                setStudentDetails({
                  ...studentDetails,
                  start_date: moment(date).format("YYYY-MM-DD"),
                });
                setStudentDetailsError({
                  ...studentDetailsError,
                  start_date: null,
                });
              }}
              errorMessage={studentDetailsError?.start_date}
              minDate={"1990-11-17"}
            />{" "}
          </Grid>
          <Grid item xs={6}>
            {studentDetails?.start_date && (
              <Calendar
                selectedDate={studentDetails?.end_date}
                title="Course End Date"
                onDateChange={(date) => {
                  setStudentDetails({
                    ...studentDetails,
                    end_date: moment(date).format("YYYY-MM-DD"),
                  });
                  setStudentDetailsError({
                    ...studentDetailsError,
                    end_date: null,
                  });
                }}
                errorMessage={studentDetailsError?.end_date}
                minDate={new Date(studentDetails?.start_date)
                  .toISOString()
                  .substring(0, 10)}
                futureDisableFlag={false}
              />
            )}
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: "10px" }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label={isGradeSystem ? "CGPA" : "Percentage"}
            value={studentDetails.cgpa}
            onChange={(e) => {
              setStudentDetails({ ...studentDetails, cgpa: e.target.value });
              setStudentDetailsError({ ...studentDetailsError, cgpa: null });
            }}
            style={{ marginBottom: 0 }}
            error={studentDetailsError.cgpa ? true : false}
            helperText={<span>{studentDetailsError.cgpa}</span>}
          />
          {isGradeSystem ? <span>Enter grade between 1-10</span> : null}
        </Grid>
        <>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={studentDetails?.backlog_check}
                onChange={(e) => {
                  setStudentDetails({
                    ...studentDetails,
                    backlog_check: e.target.checked,
                  });
                }}
              />
            }
            label="Any Backlogs"
            labelPlacement="end"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            disabled={!studentDetails.backlog_check}
            InputProps={{ classes: { root: classes.inputRoot } }}
            label={"Backlog count"}
            value={studentDetails.backlog}
            onChange={(e) => {
              setStudentDetails({ ...studentDetails, backlog: e.target.value });
              setStudentDetailsError({ ...studentDetailsError, backlog: null });
            }}
            error={studentDetailsError.backlog ? true : false}
            helperText={<span>{studentDetailsError.backlog}</span>}
            type={"number"}
          />
        </>
      </div>

      <br />
      <Grid container spacing={3} style={{ display: "flex" }}>
        <Grid item sm={6} style={{ display: "grid" }}>
          <Button
            size="small"
            className={classes.btncancel}
            variant="outlined"
            style={{
              borderRadius: "8px",
              boxShadow: "none",
              height: "48px",
              padding: "10px",
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item sm={6} style={{ display: "grid" }}>
          <Button
            variant="contained"
            size="small"
            style={{
              borderRadius: "8px",
              boxShadow: "none",
              padding: "10px",
              height: "48px",
            }}
            color="primary"
            onClick={() => {
              if (!loading) handleSubmit();
            }}
          >
            {loading ? <CustomButtonCircularProgress /> : <>Add Student</>}{" "}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
