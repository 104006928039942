import { Button, Chip, Dialog, TextField, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { BlockStudentAPI } from "../../../api/ManageStudents/action";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: "25px",
  },
  primebtn: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function BlockStudent({
  selectedRowsData,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  studentList,
  collegeId,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };
  const [showMore, setShowMore] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const [loading, setLoading] = React.useState();

  const handleSubmit = (status) => {
    if (!deleteReason) {
      setDeleteReasonError("Please Enter Reason");
      return;
    }
    var arr = [];
    selectedRowsData?.map((item) => arr.push(item.id));
    var college_arr = [];
    selectedRowsData?.map((item) => {
      college_arr.push(...item.college_id);
    });
    college_arr = [...new Set([...college_arr])];

    var data = {
      college_id: groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
        ? college_arr
        : [collegeId],
      object_id: arr,
      status: status,
      reason: deleteReason,
    };

    dispatch(
      BlockStudentAPI(
        data,
        alert,
        history,
        location,
        studentList,
        handleClose,
        setLoading,
        setSelectedRowsData
      )
    );
  };
  const [buttonName, setButtonName] = useState("");
  const [buttonNameLower, setButtonNameLower] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (
      selectedRowsData?.length === 1 &&
      selectedRowsData[0].status === "INACTIVE"
    ) {
      setButtonName("Unblock");
      setButtonNameLower("unblock");
      setStatus("ACTIVE");
    } else {
      if (
        selectedRowsData?.length > 0 &&
        selectedRowsData.every((item) => item.status === "INACTIVE")
      ) {
        setButtonName("Unblock");
        setButtonNameLower("unblock");
        setStatus("ACTIVE");
      } else {
        setButtonName("Block");
        setButtonNameLower("block");
        setStatus("INACTIVE");
      }
    }
  }, [selectedRowsData?.length, open]);
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        className={classes.primebtn}
        onClick={handleClickOpen}
      >
        {buttonName}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "400px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure. <br />
              Want to {buttonNameLower + " "} these student(s)
            </Typography>
            <br />
            <div component="ul" className={classes.root}>
              {!showMore
                ? selectedRowsData.slice(0, 5).map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : selectedRowsData.map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
            </div>
            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            multiline
            rows="2"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label={`Why you want to ${buttonNameLower} these students?`}
            value={deleteReason}
            onChange={(e) => {
              setDeleteReason(e.target.value);
              setDeleteReasonError("");
            }}
            style={{ marginBottom: 10 }}
            error={deleteReasonError ? true : false}
            helperText={<span>{deleteReasonError}</span>}
          />
          <br />
          <div className={classes.btngrid}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              autoFocus
              className={classes.button}
              variant="contained"
              color="primary"
              style={{ minWidth: "140px" }}
              onClick={() => {
                if (!loading) handleSubmit(status);
              }}
            >
              {loading ? <CustomButtonCircularProgress /> : buttonName}
            </Button>
          </div>
        </div>

        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
