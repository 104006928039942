import { STUDENT_DATA, STUDENT_DATA_ELIGIBLE, STUDENT_DATA_ELIGIBLE_ALL, STUDENT_FILTER_DATA, STUDENT_FILTER_ELIGIBLE_DATA, STUDENT_NAVBAR } from "./action";

export const StudentData = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_DATA:
      return { ...state, details: action.payload };
    case STUDENT_DATA_ELIGIBLE:
      return { ...state, eligible: action.payload };
    case STUDENT_DATA_ELIGIBLE_ALL:
      return { ...state, allEligible: action.payload };
    case STUDENT_NAVBAR:
      return { ...state, navbar: action.payload };
    case STUDENT_FILTER_DATA:
      return { ...state, filterData: action.payload };
    case STUDENT_FILTER_ELIGIBLE_DATA:
      return { ...state, filterEligibleData: action.payload };
    default:
      return state;
  }
};
