import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import pSBC from "shade-blend-color";
import { getThemeDetails } from "../../api/Domain/action";
import {
  collegeDetailsSelectorFn,
  mainDetailsFn,
  themeSelectorFn,
} from "../../api/Domain/selector";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function MuiCustomTheme({ children }) {
  const dispatch = useDispatch();

  const theme_change = useSelector(themeSelectorFn);
  const trackingId = "UA-65993236-5";
  ReactGA.initialize(trackingId);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeDetail = useSelector(collegeDetailsSelectorFn);
  const allData = useSelector(mainDetailsFn);
  // const location = useLocation();
  // console.log("hjsfcknenf", location);

  useEffect(() => {
    if (collegeDetail)
      ReactGA.set({
        user_id: collegeDetail?.user_id,
      });
  }, [collegeDetail]);

  useEffect(() => {
    if (!theme_change?.main) dispatch(getThemeDetails());
  }, []);

  const Theme = createTheme({
    palette: {
      type: "light",
      common: {
        black: "#000000",
        white: "#ffffff",
      },
      primary: {
        main: theme_change?.main ? theme_change?.main : "#007BFF",
        hover: theme_change?.hover ? theme_change.hover : "#0069D9",
        disable: theme_change?.disable ? theme_change?.disable : "#58AAFF",
        contrastText: theme_change?.contrast_text
          ? theme_change.contrast_text
          : "#fff",
        secondary: theme_change?.secondary ? theme_change.secondary : "#e0a800",
      },
      secondary: {
        main: "#000",
        hover: "#5A6268",
        disable: "#B0B6BA",
      },
      success: {
        main: "#28A745",
        hover: "#218838",
        disable: "#74C686",
      },
      text: {
        primary: "#424242",
        secondary: "#181818",
      },
      error: {
        main: "#DC3545",
        hover: "#C82333",
        disable: "#E97B86",
      },
      warning: {
        main: "#FFC107",
        hover: "#E0A800",
        disable: "#FFD75E",
      },

      grey: {
        50: "#F2F2F2",
      },
    },
    typography: {
      fontFamily: "Nunito",
      h1: {
        fontSize: "3em",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h2: {
        fontSize: "34px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h3: {
        fontSize: "24px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h4: {
        fontSize: "20px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h5: {
        fontSize: "18px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h6: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body1: {
        color: "#000000",
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body2: {
        color: "#000000",
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body3: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle1: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle2: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      caption: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      overline: {
        fontSize: "10px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      button: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      buttonmob: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          position: "relative",
          textTransform: "none",
          transition: "all .15s ease",
          letterSpacing: ".025em",
          fontSize: ".875rem",
          padding: ".625rem 1.25rem",
          willChange: "transform",
          border: "1px solid transparent",
          lineHeight: "1.5",
          borderRadius: "8px",
          userSelect: "none",
          display: "inline-block",
          boxShadow: "none",
          fontWeight: "600",
          textAlign: "center",
          verticalAlign: "middle",
        },
        contained: {
          "&$disabled": {
            color: "#FFF",
            backgroundColor: pSBC(
              0.7,
              theme_change?.main ? theme_change?.main : "#007BFF"
            ),
          },
        },
        text: {
          color: "#EE7D66",
        },
      },
      MuiOutlinedInput: {
        // MuiInputLabel: {
        //   root: {
        //     // color:'black',
        //     fontSize: 13,
        //   },
        // },
        root: {
          height: 50,
          borderRadius: 8,
          fontSize: 15,

          // border: "1px solid #ced4da",
          // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
        },
        notchedOutline: {
          // borderColor: "transparent",
        },
        multiline: {
          height: "auto",
        },
      },

      MuiFab: {
        root: {
          backgroundColor: "#EE7D66",
          color: "#FFF",
        },
      },
      MuiTab: {
        wrapper: {
          color: "#000",
          fontSize: "14px",
        },
        textColorInherit: {
          color: "#FFF",
        },
      },
      MuiAutocomplete: {
        paper: {
          borderRadius: 8,
        },
      },
      MuiMenu: {
        paper: {
          borderRadius: 8,
        },
      },
      // MuiFormLabel: {
      //   root: {
      //     color: "rgba(0, 0, 0, 0.23)",
      //   },
      // },
      MuiSelect: {
        root: {
          background: "transparent",
        },
        select: {
          backgroundColor: "transparent",

          "&:focus": {
            backgroundColor: "transparent",
            borderRadius: 8,
          },
        },
      },
    },
    publicLeftSide: {},
  });

  return <ThemeProvider theme={Theme}>{children}</ThemeProvider>;
}
