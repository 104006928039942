import { ListItemIcon } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Description from "@material-ui/icons/Description";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { mergeClasses } from "@material-ui/styles";
import React from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drop: {
    padding: "5px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    //border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "15px",
    display: "flex",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

export default function SendEjd({ ejdList }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const alert = useAlert();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={mergeClasses.title}
        aria-controls="customized-menu"
        style={{
          display: "flex",
          borderRadius: "8px",
          boxShadow: "none",
          height: "40px",
        }}
        color="primary"
        aria-haspopup="true"
        startIcon={<Description />}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Send e-JNF
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {ejdList?.map((item) => (
          <CopyToClipboard
            text={item?.link}
            onCopy={() => alert.success("EJD Link Copied!")}
          >
            <StyledMenuItem>
              <ListItemText secondary={item.title} />
              <ListItemIcon
                style={{ display: "flex", justifyContent: "center" }}
              >
                <FileCopyOutlinedIcon fontSize="small" />
              </ListItemIcon>
            </StyledMenuItem>
          </CopyToClipboard>
        ))}
      </StyledMenu>

      {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        > */}
      {/* <List sx={{ width: "100%", maxWidth: 560 }}  >
            {[1, 2, 3].map(value => (
              <ListItem
                key={value}
                disableGutters
                secondaryAction={
                  <IconButton>
                    <CommentOutlined />
                  </IconButton>
                }
              >
                <ListItemText primary={`Line item ${value}`} />
              </ListItem>
            ))}
          </List> */}

      {/* <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography> */}
      {/* </div>
      </StyledMenu> */}
    </div>
  );
}
