import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Button, TextField } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import { url, websiteRegexNew } from "../../../utility/regex";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    //maxWidth: 500,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  errorMessage: {
    color: "#DC3545",
  },
  newBtnStyle: {
    maxWidth: "200px",
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));

export default function ImportantLink({
  socialLinks,
  postSocialLinks,
  linksDataLoading,
  approvalRequestCounts,
  redirectionData,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [githubLink, setGithubLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [reelLink, setReelLink] = useState("");
  useEffect(() => {
    if (socialLinks) {
      socialLinks?.forEach((item) => {
        if (item?.id === 1) setLinkedinLink(item?.value);
        if (item?.id === 2) setGithubLink(item?.value);
        if (item?.id === 4) setFacebookLink(item?.value);
        if (item?.id === 3) setInstagramLink(item?.value);
        if (item?.id === 5) setReelLink(item?.value);
      });
    }
  }, [socialLinks]);

  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Important Links</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={
              approvalRequestCounts?.LINKS
                ? { pointerEvents: "none", opacity: 0.4 }
                : {}
            }
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <TextField
                id="outlined-basic"
                label="Linkedin Link"
                value={linkedinLink}
                onChange={(e) => {
                  setLinkedinLink(e.target.value);
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                margin="normal"
              />
              <TextField
                id="outlined-basic"
                label="Github Link"
                value={githubLink}
                onChange={(e) => {
                  setGithubLink(e.target.value);
                }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
              <TextField
                id="outlined-basic"
                label="Facebook Link "
                value={facebookLink}
                onChange={(e) => {
                  setFacebookLink(e.target.value);
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
              <TextField
                id="outlined-basic"
                label="Instagram Link"
                value={instagramLink}
                onChange={(e) => {
                  setInstagramLink(e.target.value);
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
              <TextField
                id="outlined-basic"
                label="Add Reel"
                value={reelLink}
                onChange={(e) => {
                  setReelLink(e.target.value);
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.newBtnStyle}
                onClick={
                  approvalRequestCounts?.LINKS
                    ? () => {
                        history.push({
                          pathname: `/student-profile-request/${redirectionData?.name.replace(
                            / /g,
                            "_"
                          )}-${redirectionData?.userId}`,
                        });
                      }
                    : () => {
                        var newSocialLinks = [];
                        if (
                          !(
                            linkedinLink ||
                            githubLink ||
                            facebookLink ||
                            instagramLink ||
                            reelLink
                          )
                        ) {
                          alert.error("Please add atleast one social links!");
                          return;
                        }

                        if (
                          linkedinLink &&
                          !(
                            linkedinLink.startsWith("https://") ||
                            linkedinLink.startsWith("http://") ||
                            linkedinLink.startsWith("HTTPS://") ||
                            linkedinLink.startsWith("HTTP://")
                          )
                        ) {
                          alert.error(
                            "Enter Correct Web Address for LinkedIn, Start with http:// "
                          );
                          return;
                        }
                        if (
                          githubLink &&
                          !(
                            githubLink.startsWith("https://") ||
                            githubLink.startsWith("http://") ||
                            githubLink.startsWith("HTTPS://") ||
                            githubLink.startsWith("HTTP://")
                          )
                        ) {
                          alert.error(
                            "Enter Correct Web Address for Github, Start with http:// "
                          );
                          return;
                        }
                        if (
                          facebookLink &&
                          !(
                            facebookLink.startsWith("https://") ||
                            facebookLink.startsWith("http://") ||
                            facebookLink.startsWith("HTTPS://") ||
                            facebookLink.startsWith("HTTP://")
                          )
                        ) {
                          alert.error(
                            "Enter Correct Web Address for Facebook, Start with http:// "
                          );
                          return;
                        }
                        if (
                          instagramLink &&
                          !(
                            instagramLink.startsWith("https://") ||
                            instagramLink.startsWith("http://") ||
                            instagramLink.startsWith("HTTPS://") ||
                            instagramLink.startsWith("HTTP://")
                          )
                        ) {
                          alert.error(
                            "Enter Correct Web Address for Instagram, Start with http:// "
                          );
                          return;
                        }
                        if (
                          reelLink &&
                          !(
                            reelLink.startsWith("https://") ||
                            reelLink.startsWith("http://") ||
                            reelLink.startsWith("HTTPS://") ||
                            reelLink.startsWith("HTTP://")
                          )
                        ) {
                          alert.error(
                            "Enter Correct Web Address for Reels, Start with http:// "
                          );
                          return;
                        }

                        if (linkedinLink) {
                          newSocialLinks.push({
                            id: 1,
                            name: "Linkedin",
                            value: linkedinLink,
                          });
                        }
                        if (githubLink) {
                          newSocialLinks.push({
                            id: 2,
                            name: "Github",
                            value: githubLink,
                          });
                        }
                        if (facebookLink) {
                          newSocialLinks.push({
                            id: 4,
                            name: "Facebook",
                            value: facebookLink,
                          });
                        }
                        if (instagramLink) {
                          newSocialLinks.push({
                            id: 3,
                            name: "Instagram",
                            value: instagramLink,
                          });
                        }
                        if (instagramLink) {
                          newSocialLinks.push({
                            id: 5,
                            name: "Reels",
                            value: reelLink,
                          });
                        }

                        postSocialLinks(newSocialLinks);
                      }
                }
              >
                {linksDataLoading ? (
                  <CustomButtonCircularProgress />
                ) : approvalRequestCounts?.LINKS ? (
                  `Approve Request (${approvalRequestCounts?.LINKS})`
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
