import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./pro.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ModalVideo from "react-modal-video";
import "../../../style/reactVideo.css";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

function ProTricks({ tipsData, loading }) {
  var settings = {
    // className: "",
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    adaptiveHeight: true,
    arrows: false,
    // autoplay: true,
    // autoplaySpeed: 2000,
  };

  console.log(tipsData, "tipsdata");
  const [isOpen, setOpen] = useState(false);
  const [isvideo, setvideo] = useState();

  const handleClickOpen = (id) => {
    setOpen(true);
    setvideo(id);
  };

  const temp = tipsData?.filter((doc) => doc.banner_id === isvideo);

  const handleClose = () => {
    setOpen(false);
  };

  const VID_REGEX =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  return (
    <>
      <div
        style={{
          backgroundColor: "#eeeeee",
          padding: 15,
          borderRadius: 10,
          paddingBottom: 30,
        }}
      >
        {loading ? (
          <Typography variant="h4" style={{ marginBottom: 15 }}>
            🪄 Pro Tips & Tricks
          </Typography>
        ) : (
          <Skeleton
            animation="wave"
            variant="rect"
            style={{
              height: "30px",
              width: "200px",
              borderRadius: 4,
              marginBottom: "15px",
            }}
          />
        )}
        <div className="slider-container">
          {" "}
          <div style={{ color: "red" }}>
            {loading ? (
              <Slider {...settings}>
                {tipsData?.map((value, index) => (
                  <>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: 15,
                      }}
                    >
                      <div
                        style={{
                          //         backgroundImage: `url(${value?.banner_path})`,
                          height: 200,
                          position: "relative",
                          backgroundPosition: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 8,
                          backgroundOrigin: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            backgroundColor: "rgba(0,0,0,.3)",
                            borderRadius: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={value?.banner_path}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: 8,
                              backgroundColor: "rgba(0,0,0,.3)",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            backgroundColor: "rgba(0,0,0,.3)",
                            borderRadius: 8,
                          }}
                        />
                        <IconButton
                          style={{ backgroundColor: "#fff" }}
                          size="small"
                          onClick={() => handleClickOpen(value?.banner_id)}
                        >
                          {" "}
                          <PlayArrowIcon style={{ color: "#000" }} />
                        </IconButton>
                      </div>
                    </div>
                  </>
                ))}
              </Slider>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7]?.map((item) => (
                  <div style={{ width: "100%", marginRight: 10 }}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{
                        height: "180px",
                        width: "100%",
                        borderRadius: 10,
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            {temp?.map((item) => (
              <ModalVideo
                channel="youtube"
                videoId={item?.redirection_url?.match(VID_REGEX)[1]}
                youtube={{ mute: 0, autoplay: 0 }}
                isOpen={isOpen}
                onClose={handleClose}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProTricks;
