import { Box, Grid } from "@material-ui/core";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function OverViewSkelton() {
  return (
    <div
      style={{
        width: "100%",
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        backgroundColor: "#fff",
        position: "relative",
      }}
    >
      <div style={{ position: "relative", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",

            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
            <Grid item style={{ paddingLeft: 0 }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 54,
                  width: 54,
                  borderRadius: 4,
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 8,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={20}
                    width={120}
                    style={{ marginBottom: 10, borderRadius: 4 }}
                  />

                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={18}
                    width={70}
                    style={{ borderRadius: 4 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {[1, 2, 3, 4, 5]?.map((item) => (
            <div
              style={{
                marginTop: 20,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                height={24}
                width={130}
                style={{ marginBottom: 10, borderRadius: 4 }}
              />

              <Skeleton
                animation="wave"
                variant="rect"
                height={70}
                style={{ marginBottom: 10, borderRadius: 4, width: "100%" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OverViewSkelton;
