import {
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles, withStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isGradeSystemFn } from "../../../api/Domain/selector";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "8px",
    width: "150px",
    paddingRight: "0px",
    marginTop: "4px",
    // paddingLeft: "0px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "48px",
    paddingLeft: "30px",
    marginRight: "10px",
    paddingRight: "30px",
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "8px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
  inputRoot: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}));

const FilterScore = ({
  scoreData,
  setScoreData,
  setApplyClickedScore,
  filteringDisabled = false,
  disableCgpa = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  filteringDisabled = disableCgpa ? true : filteringDisabled;
  //used to manage the internal onChange state so that we update the
  // fliters(here scoreDate) state only on click Apply button
  const [localMinMaxCgpa, setLocalMinMaxCgpa] = useState({
    min: null,
    max: null,
  });
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const isGradeSystem = useSelector(isGradeSystemFn);

  return (
    <>
      <TextField
        variant="outlined"
        style={{ width: "100%", background: "#fff", borderRadius: 8 }}
        required
        fullWidth
        className={clsx(classes.margin, classes.textField)}
        InputLabelProps={{
          className: classes.floatingLabelFocusStyle,
        }}
        // value={`${scoreData?.ctc_min} LPA - ${scoreData?.ctc_max}`}
        label={isGradeSystem ? "CGPA" : "Percentage"}
        disabled
        inputProps={{ style: { padding: "16px" } }}
        InputProps={{
          classes: { root: classes.inputRoot },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
              // onClick={handleClickNew("bottom-start")}
              >
                {" "}
                <ArrowDropDownIcon style={{ cursor: "pointer" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onClick={handleClick}
      />

      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="overline">Min:</Typography>
            <InputBase
              className={classes.inputctc}
              placeholder=""
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              value={localMinMaxCgpa?.min}
              disabled={filteringDisabled}
              onChange={(e) => {
                setLocalMinMaxCgpa({ ...localMinMaxCgpa, min: e.target.value });
              }}
            />
            {/* <Typography variant="overline" style={{ marginLeft: "10px" }}>
              LPA
            </Typography> */}
          </div>
          <div
            style={{
              marginTop: "7px",
              marginBottom: "7px",
              width: "100%",
              borderTop: "1px solid #ced4da",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="overline">Max:</Typography>
            <InputBase
              className={classes.inputctc}
              placeholder=""
              disabled={filteringDisabled}
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              value={localMinMaxCgpa?.max}
              onChange={(e) => {
                setLocalMinMaxCgpa({ ...localMinMaxCgpa, max: e.target.value });
              }}
            />
            {/* <Typography variant="overline" style={{ marginLeft: "10px" }}>
              LPA
            </Typography> */}
          </div>
          <div
            style={{
              marginTop: "7px",
              marginBottom: "7px",
              width: "100%",
              borderTop: "1px solid #ced4da",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              color="primary"
              disabled={filteringDisabled}
              onClick={() => {
                setApplyClickedScore(true);
                setScoreData({ ...scoreData, cgpa_max: null, cgpa_min: null });
                setLocalMinMaxCgpa({
                  ...localMinMaxCgpa,
                  min: "",
                  max: "",
                });
                handleClose();
              }}
            >
              Clear
            </Button>
            <Button
              color="primary"
              disabled={filteringDisabled}
              onClick={() => {
                setApplyClickedScore(true);
                setScoreData({
                  ...scoreData,
                  cgpa_max: localMinMaxCgpa.max,
                  cgpa_min: localMinMaxCgpa.min,
                });
                handleClose();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </StyledMenu>
    </>
  );
};

export default FilterScore;
