import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  TextField,
  useTheme,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../Accordian/CustomAccordian";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { entityInfoSelectorFn } from "../../../api/SignIn/selector";
import { useSelector } from "react-redux";
import DegreeSpecializationDialog from "../../Cards/UniversityPostJob/DegreeSpecializationDialog";
import ChipCustom from "../../Form/ChipCourses/index2";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  typography: {
    padding: theme.spacing(2),
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },

  expansionPanelSummaryContent: {
    margin: 0,
  },
}));

export default function CompanyGroupAccordian({
  setGroupArray = () => {},
  groupArray = [],
  errorMessage = null,
  loading = false,
  disabled = false,
  setErrorMessage = () => {},
  errorMessageKey = "",
  onlyGroup = false,
  placeholder = "",
}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [displayArray, setDisplayArray] = useState([]);

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const [search, setSearch] = useState("");
  const anchorRef = useRef(null);
  const theme = useTheme();

  const handleClickAndOnChange = (event) => {
    if (search) setOpen(true);

    setSearch(event.target.value);

    var courses = [...groupArray];
    var arr = [];
    courses.map((items) => {
      new_arr = items.groups.map((element) => {
        return {
          ...element,
          colleges: element.colleges.filter((subElement) =>
            subElement.college_name
              .toLowerCase()
              .startsWith(event.target.value.toLowerCase())
          ),
        };
      });

      if (new_arr.every((item) => item.colleges.length > 0)) {
        new_arr.map((element) => {
          if (element.colleges.length > 0)
            return {
              ...element,
              colleges: element.colleges.filter((subElement) =>
                subElement.college_name
                  .toLowerCase()
                  .startsWith(event.target.value.toLowerCase())
              ),
            };
        });
      }

      if (new_arr.every((item) => item.colleges.length == 0)) {
        var new_arr = items.groups.filter((item) =>
          item.group_name
            .toLowerCase()
            .startsWith(event.target.value.toLowerCase())
        );
      }

      var nnn = new_arr.filter((item) => item.colleges.length > 0);
      new_arr = nnn;

      var obj = { ...items };
      obj["groups"] = new_arr;

      arr.push(obj);
    });

    if (setErrorMessage)
      setErrorMessage((prev) => ({ ...prev, [errorMessageKey]: null }));
    setDisplayArray(arr);
  };

  const handleClickWithSearchEmpty = (event) => {
    setOpen((prev) => !prev);
    if (setErrorMessage)
      setErrorMessage((prev) => ({ ...prev, [errorMessageKey]: null }));
    setDisplayArray(groupArray);
  };

  const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: 14 }} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkedIcon} />;

  const handleSelectAllSpecializations = (e, item) => {
    const isSelectAll = e.target.checked;

    const updatedDisplayArray = displayArray.map((section) => {
      return {
        ...section,
        groups: section.groups.map((group) => {
          const updatedColleges = group.colleges.map((spec) => {
            if (
              group.group_id === item.group_id ||
              (!onlyGroup &&
                item.colleges.some((s) => s.college_id === spec.college_id))
            ) {
              return { ...spec, selected: isSelectAll };
            }
            return spec;
          });

          const isAllSelected = updatedColleges.every((spec) => spec.selected);

          return {
            ...group,
            select_all: isAllSelected,
            colleges: updatedColleges,
          };
        }),
      };
    });

    setDisplayArray(updatedDisplayArray);

    const updatedCoursesArray = groupArray.map((section) => {
      return {
        ...section,
        groups: section.groups.map((group) => {
          const updatedColleges = group.colleges.map((spec) => {
            if (
              group.group_id === item.group_id ||
              (!onlyGroup &&
                item.colleges.some((s) => s.college_id === spec.college_id))
            ) {
              return { ...spec, selected: isSelectAll };
            }
            return spec;
          });

          const isAllSelected = updatedColleges.every((spec) => spec.selected);

          return {
            ...group,
            select_all: isAllSelected,
            colleges: updatedColleges,
          };
        }),
      };
    });

    setGroupArray(updatedCoursesArray);
  };

  const handleSelectSingleSpecialization = (e, item) => {
    const isSelected = e.target.checked;

    const updatedDisplayArray = displayArray.map((section) => {
      return {
        ...section,
        groups: section.groups.map((group) => {
          const updatedColleges = group.colleges.map((spec) => {
            if (spec.college_id === item.college_id) {
              return { ...spec, selected: isSelected };
            }
            return spec;
          });

          const isAllSelected = updatedColleges.every((spec) => spec.selected);

          return {
            ...group,
            select_all: isAllSelected,
            colleges: updatedColleges,
          };
        }),
      };
    });

    setDisplayArray(updatedDisplayArray);

    const updatedCoursesArray = groupArray.map((section) => {
      return {
        ...section,
        groups: section.groups.map((group) => {
          const updatedColleges = group.colleges.map((spec) => {
            if (spec.college_id === item.college_id) {
              return { ...spec, selected: isSelected };
            }
            return spec;
          });

          const isAllSelected = updatedColleges.every((spec) => spec.selected);

          return {
            ...group,
            select_all: isAllSelected,
            colleges: updatedColleges,
          };
        }),
      };
    });

    setGroupArray(updatedCoursesArray);
  };

  const [expandedIndex, setExpandedIndex] = useState(null);

  const [open1, setOpen1] = useState(false);
  const openDialog1 = () => {
    setOpen1(true);
  };
  const closeDialog1 = () => {
    setOpen1(false);
  };

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div className={classes.root}>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement={"bottom-start"}
            transition
            style={{ top: 6, zIndex: 10000 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={10}>
                <Paper
                  style={{
                    width: 785,
                    borderRadius: 8,
                    maxHeight: 255,
                    overflowY: "scroll",
                  }}
                >
                  {!loading ? (
                    displayArray.map((items, parentIndex) =>
                      items?.groups?.length ? (
                        <div key={parentIndex}>
                          <FormLabel
                            component="legend"
                            style={{
                              fontSize: 14,
                              color: "grey",
                              marginBottom: 5,
                              marginLeft: 20,
                              marginTop: 10,
                            }}
                          >
                            {items.section_name}
                          </FormLabel>
                          {items.groups.map((item, childIndex) => {
                            const uniqueIndex = `${parentIndex}-${childIndex}`;
                            return (
                              <Accordion
                                key={childIndex}
                                expanded={
                                  expandedIndex == uniqueIndex || search !== ""
                                }
                                onChange={() =>
                                  setExpandedIndex(
                                    expandedIndex == uniqueIndex
                                      ? null
                                      : uniqueIndex
                                  )
                                }
                              >
                                <AccordionSummary
                                  expandIcon={<ArrowDropDownIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  classes={{
                                    content:
                                      classes.expansionPanelSummaryContent,
                                    expanded: "expanded",
                                  }}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          checked={
                                            item.select_all ? true : false
                                          }
                                          onChange={(e) => {
                                            handleSelectAllSpecializations(e, {
                                              ...item,
                                              section_id: items?.section_id,
                                            });
                                          }}
                                          name="gilad"
                                          disabled={search != ""}
                                        />
                                      }
                                      label={
                                        <span style={{ fontSize: 13 }}>
                                          {item.group_name + " "}
                                        </span>
                                      }
                                    />
                                  </FormGroup>{" "}
                                </AccordionSummary>

                                <AccordionDetails>
                                  <FormControl
                                    component="fieldset"
                                    className={classes.formControl}
                                  >
                                    <FormLabel
                                      component="legend"
                                      style={{
                                        fontSize: 14,
                                        color: "grey",
                                        marginBottom: 5,
                                      }}
                                    >
                                      College
                                    </FormLabel>
                                    <FormGroup>
                                      {item?.colleges.map((i, index) => {
                                        return (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={
                                                  <CheckBoxIcon
                                                    className={
                                                      classes.checkedIcon
                                                    }
                                                    style={{
                                                      color: onlyGroup
                                                        ? "gray"
                                                        : theme.palette.primary
                                                            .main,
                                                    }}
                                                    disabled={onlyGroup}
                                                  />
                                                }
                                                disabled={onlyGroup}
                                                checked={
                                                  i?.selected ? true : false
                                                }
                                                onChange={(e) => {
                                                  handleSelectSingleSpecialization(
                                                    e,
                                                    {
                                                      ...i,
                                                      section_id:
                                                        items?.section_id,
                                                    },
                                                    item
                                                  );
                                                }}
                                                name="gilad"
                                              />
                                            }
                                            label={
                                              <span style={{ fontSize: 13 }}>
                                                {i.college_name}
                                              </span>
                                            }
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </FormControl>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </div>
                      ) : null
                    )
                  ) : (
                    <CircularProgress size={17} style={{ color: "black" }} />
                  )}
                </Paper>
              </Fade>
            )}
          </Popper>

          <TextField
            disabled={disabled}
            variant="outlined"
            style={{ width: "100%", marginTop: "20px" }}
            fullWidth
            className={clsx(classes.margin, classes.textField)}
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{ style: { padding: "16px" } }}
            ref={anchorRef}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ marginRight: "-8px", color: "#757575" }}
                >
                  <ArrowDropDownIcon
                    style={{ cursor: !disabled ? "pointer" : "auto" }}
                  />
                </InputAdornment>
              ),
            }}
            onClick={() => {
              if (!disabled)
                search
                  ? handleClickAndOnChange()
                  : handleClickWithSearchEmpty();
            }}
            required
            placeholder={placeholder}
            onChange={handleClickAndOnChange}
            error={errorMessage ? true : false}
            helperText={<span>{errorMessage}</span>}
          />
          <div style={{ marginBottom: "10px" }}>
            <ChipCustom
              courseArray={groupArray}
              displayLimit={3}
              openDialog={openDialog1}
              allInOneSelected="All Colleges"
              allSelected="All Colleges Selected"
              primaryItems={"groups"}
              primaryName={"group_name"}
              secondaryName={"college_name"}
              secondaryItems={"colleges"}
              onlyGroup={onlyGroup}
            />
          </div>
        </div>
      </ClickAwayListener>
      <DegreeSpecializationDialog
        open={open1}
        coursesArray={groupArray}
        handleClose={closeDialog1}
        allInOneSelected="All Colleges"
        allSelected="All Colleges Selected"
        primaryItems={"groups"}
        primaryName={"group_name"}
        secondaryName={"college_name"}
        secondaryItems={"colleges"}
        onlyGroup={onlyGroup}
      />
    </>
  );
}
