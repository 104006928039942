import React from "react"

export default function ManageInterviewsAssignment({ primaryColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="99" height="101" viewBox="0 0 99 101">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_5064" data-name="Rectangle 5064" width="99" height="101" transform="translate(380 270)" fill="#fff" stroke="#707070" strokeWidth="1" />
        </clipPath>
      </defs>
      <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-380 -270)" clipPath="url(#clip-path)">
        <g id="resume" transform="translate(387.43 274.07)">
          <path id="Path_17321" data-name="Path 17321" d="M68.427,62.876a1.3,1.3,0,0,0-1.293,1.293v6.994a4.5,4.5,0,0,1-4.5,4.486,4.429,4.429,0,0,1-3.167-1.319,4.378,4.378,0,0,1-1.319-3.154V65.384a1.3,1.3,0,0,0-1.293-1.293H12.076V21.353a1.293,1.293,0,1,0-2.586,0V64.092H1.863A1.3,1.3,0,0,0,.57,65.384v5.934a6.924,6.924,0,0,0,6.916,6.916H62.636a7.084,7.084,0,0,0,7.084-7.071V64.169A1.3,1.3,0,0,0,68.427,62.876ZM7.486,75.649a4.331,4.331,0,0,1-4.331-4.331V66.677H55.577v4.5a7,7,0,0,0,1.6,4.486H7.486Z" transform="translate(0 5.601)" fill={primaryColor} />
          <path id="Path_17322" data-name="Path 17322" d="M64.994,7.988H33.011V5.144A4.212,4.212,0,0,0,28.81.93H5.6A4.215,4.215,0,0,0,1.39,5.144V18.486a4.212,4.212,0,0,0,4.214,4.2H18.325l6.58,6a1.3,1.3,0,0,0,.866.336,1.207,1.207,0,0,0,.517-.116,1.3,1.3,0,0,0,.776-1.176V22.687H28.8a4.21,4.21,0,0,0,4.2-4.2V10.574h32a1.89,1.89,0,0,1,1.887,1.887V63.306a1.293,1.293,0,1,0,2.586,0V12.474A4.477,4.477,0,0,0,64.994,7.988ZM30.425,18.5a1.617,1.617,0,0,1-1.616,1.616H25.784a1.3,1.3,0,0,0-1.293,1.293V24.82l-4.8-4.37a1.255,1.255,0,0,0-.866-.336H5.6A1.628,1.628,0,0,1,3.976,18.5V5.144A1.63,1.63,0,0,1,5.6,3.516H28.81a1.619,1.619,0,0,1,1.616,1.629ZM19.773,65.232H58.944a1.293,1.293,0,1,0,0-2.586H19.773a1.293,1.293,0,1,0,0,2.586Z" transform="translate(0.24)" fill={primaryColor} />
          <path id="Path_17323" data-name="Path 17323" d="M16.279,49.135a1.3,1.3,0,0,0,1.293,1.293H56.743a1.293,1.293,0,1,0,0-2.586H17.572A1.288,1.288,0,0,0,16.279,49.135ZM17.572,57.1H56.743a1.293,1.293,0,1,0,0-2.586H17.572a1.293,1.293,0,0,0,0,2.586Zm6.089-14.647a1.3,1.3,0,0,0,1.293,1.293h24.42a1.293,1.293,0,0,0,0-2.586H24.954A1.3,1.3,0,0,0,23.661,42.451Zm3.051-7.227A1.3,1.3,0,0,0,28,36.518H46.323a1.3,1.3,0,0,0,1.293-1.293,10.484,10.484,0,0,0-6.36-9.618,5.7,5.7,0,1,0-8.2,0A10.492,10.492,0,0,0,26.712,35.225Zm7.343-13.548a3.116,3.116,0,1,1,3.116,3.116A3.117,3.117,0,0,1,34.055,21.677Zm3.116,5.688a7.878,7.878,0,0,1,7.757,6.567H29.414A7.857,7.857,0,0,1,37.171,27.365Zm44.807-3.038a3.313,3.313,0,0,0-3.3-3.309h-4.8a3.3,3.3,0,0,0-3.3,3.309V68.966c0,.013.013.026.013.039a1.441,1.441,0,0,0,.065.4l4.408,12.126a1.292,1.292,0,0,0,2.43,0l4.408-12.126a1.044,1.044,0,0,0,.065-.4c0-.013.013-.026.013-.039ZM76.91,75.572,76.277,77.3,75.126,74.15h0l-1.409-3.891h5.119Zm-3.749-7.9V31.9h6.231V67.673H73.161Zm0-43.346a.72.72,0,0,1,.711-.724h4.8a.712.712,0,0,1,.711.724v4.99H73.148v-4.99ZM18.878,6.306l-5.611,5.611L11.108,9.757A1.289,1.289,0,0,0,9.286,11.58l3.077,3.077a1.269,1.269,0,0,0,.918.375,1.324,1.324,0,0,0,.918-.375l6.528-6.528a1.287,1.287,0,0,0,0-1.823A1.319,1.319,0,0,0,18.878,6.306Z" transform="translate(2.441 1.463)" fill={primaryColor} />
        </g>
      </g>
    </svg>
  )
}