import {
  Button,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { themeSelectorFn } from "../../../api/Domain/selector";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";
import { isEducationGroupFn } from "../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  lay: {
    width: "100%",
    "@media only screen and (min-width: 2000px)": {
      height: "600px",
    },
    "@media only screen and (max-width: 2000px)": {
      height: "500px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "370px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "230px",
    },
  },
}));

export default function AssignmentTrackHistory({ data }) {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);

  const columns = [
    {
      field: "company",
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "80px" }}>
          Company
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              marginLeft: "20px",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link
                to={`/company-profile/${params.row.company_id}`}
                target={`${isEducationGroup ? "_self" : "_blank"}`}
                style={{ textDecoration: "none", display: "contents" }}
              >
                {" "}
                <Tooltip title={params.row.company_name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px" }}
                  >
                    {params.row.company_name}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "center",
        //     display: "flex",
        //     margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   {" "}
        //   <Link
        //     to={`/company-profile/${params.row.id}`}
        //     style={{ textDecoration: "none" }}
        //   >
        //     <Typography color="primary" style={{ fontSize: "14px" }}>
        //       {params.row.company_name}
        //     </Typography>
        //   </Link>
        // </span>
      ),
    },

    {
      field: "title",
      headerName: "Job Title",
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "75px",
          }}
        >
          Job Profile
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              marginLeft: "20px",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link
                to={`/job/${params.row.job_id}`}
                target={`${isEducationGroup ? "_self" : "_blank"}`}
                style={{ textDecoration: "none", display: "contents" }}
              >
                {" "}
                <Tooltip title={params.row.job_title} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px" }}
                  >
                    {params.row.job_title}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "center",
        //     display: "flex",
        //     margin: "auto",
        //   }}
        // >
        //   <Link
        //     to={`/job-details/${params.row.job_id}`}
        //     style={{ textDecoration: "none" }}
        //   >
        //     <Typography color="primary" style={{ fontSize: "14px" }}>
        //       {params.row.job_title}
        //     </Typography>
        //   </Link>
        // </span>
      ),
    },

    {
      field: "total",
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "45px" }}>
          Total Assignments
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.total_assignment}
        </span>
      ),
    },
    {
      field: "submit",
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
          Submitted Assignments
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.submitted_assignments}
        </span>
      ),
    },

    {
      field: "button",
      headerName: "",
      type: "button",
      width: 250,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "80px" }}>
          Actions
        </strong>
      ),
      renderCell: (cellValues) => {
        return (
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Link
              to={{
                pathname: "/assignment-details",
                search: `?job_id=${cellValues.row.job_id}`,
              }}
              target={`${isEducationGroup ? "_self" : "_blank"}`}
            >
              <Button color="primary">View Assignments</Button>
            </Link>
          </span>
        );
      },
    },
  ];

  return (
    <div className={classes.lay}>
      {data !== undefined ? (
        <DataGrid
          style={{
            borderRadius: "8px",
            backgroundColor: "#fff",
            border: "none",
          }}
          rows={data}
          columns={columns}
          pageSize={5}
          components={{
            NoRowsOverlay: () => (
              <>
                <br /> <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    paddingTop: 30,
                  }}
                >
                  <NoData
                    height="180px"
                    width="180px"
                    alt="404 Error"
                    fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                  />

                  <span
                    align="center"
                    style={{ fontSize: 14, color: "#6c757d" }}
                  >
                    <b> No Data Available</b>
                  </span>
                </div>
              </>
            ),
          }}
        />
      ) : null}
    </div>
  );
}
