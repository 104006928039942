
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlacementAccordingToJob } from "../../api/JobPost/action";
import { collegeGroupListSelectorFn, isCollegeGroupFn, isEducationGroupFn } from "../../api/SignIn/selector";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import DropDownButton from "../../components/Input/DropDownButton/trackPlacement";

export default function TopDropDown({selectedGroup, setSelectedGroup, placementSession, setPlacementSession, collegeId}){

    const isEducationGroup = useSelector(isEducationGroupFn);
    const isCollegeGroup = useSelector(isCollegeGroupFn);
    const collegeGroupList = useSelector(collegeGroupListSelectorFn);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    
    const placementSessionList = useSelector(
        (state) => state?.JobPlacement?.placementList
      );

      useEffect(() => {
        if(collegeId){
            var search = location.search;
            var params = new URLSearchParams(search);
            var jobId = params.get("job_id");
        dispatch(
          PlacementAccordingToJob(
            alert,
            history,
            location,
            jobId,
            collegeId
          )
        );
    }
      }, []);

    const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

    useEffect(() => {
        if (collegeGroupList?.length > 1 && isCollegeGroup) {
        setNewCollegeGroupList(
            [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
        );
        } else setNewCollegeGroupList(collegeGroupList);
    }, [collegeGroupList?.length]);

    return (
    <>
        {collegeId && placementSessionList?.length > 0 && (
            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              <DropDownButton
                title="Placement Session 2021"
                placementSession={placementSession}
                setPlacementSession={setPlacementSession}
                placementSessionList={placementSessionList}
              />
            </div>
          )}
          {!collegeId && (isCollegeGroup || isEducationGroup) && (
              <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <GroupListDropdown
                  title="Group"
                  state={selectedGroup}
                  setState={setSelectedGroup}
                  groupList={newCollegeGroupList}
                  />
              </div>
          )
          }
    </>)
}