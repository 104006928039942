import {
  Avatar,
  Button,
  IconButton,
  InputBase,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AttachFileRoundedIcon from "@material-ui/icons/AttachFileRounded";
import SearchIcon from "@material-ui/icons/Search";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import pSBC from "shade-blend-color";
import { chatstatus, MediaFiles } from "../../../api/Chat/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  collegeUserDetailSelectorFn,
} from "../../../api/SignIn/selector";
import loo from "../../../assets/png/default_college.png";
import { db } from "../../../utility/firebaseConfig";
import NoDataFunc from "../../NoDataSVG/index1";
import RightInfo from "./ChatDrawer/RightInfo";
import CompanyChat from "./CompanyChat";
import {
  ImageMessageLeft,
  ImageMessageRight,
  MessageLeft,
  MessageRight,
  PdfMessageLeft,
  PdfMessageRight,
} from "./Message";
import config from "../../../config";
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      //width: "80vw",
      height: "700px",
      width: "100%",
      //  maxWidth: "900px",
      boxShadow: "none",
      maxHeight: "600px",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      position: "relative",
      justifyContent: "flex-start",
      borderTopRightRadius: "12px",
    },
    paper2: {
      // width: "80vw",
      //  maxWidth: "500px",
      display: "flex",

      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    container: {
      //  width: "auto",
      width: "100%",
      //height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
    },
    root: {
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      marginTop: "10px",

      //width: "100%",
      height: "600px",
      position: "relative",

      right: 0,
    },
    paperdemo: {
      padding: theme.spacing(2),
      textAlign: "center",

      color: theme.palette.text.secondary,
    },
    rootsearch: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      boxShadow: "none",
      border: "1px solid #e2e6ea",
      borderRadius: "8px",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    item: {
      backgroundColor: "#FAFCFC",
      borderRadius: "8px",

      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
      "&:hover": {
        backgroundColor: pSBC(0.8, theme.palette.primary.main),
      },
    },
    msg: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      boxShadow: "none",
      border: "1px solid #6c757d",
      borderRadius: "8px",
      width: "100%",
    },
    messagesBody: {
      //width: "calc( 100% - 20px )",
      //  marginTop: "1.5px",
      width: "100%",
      //   margin: 10,
      overflowY: "scroll",
      height: "calc( 100% - 80px )",
      boxShadow: "none",
    },
    notData: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    avatar: {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  })
);

export default function ChatRoomCard() {
  const classes = useStyles();
  const [isOpen, setisOpen] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [id, setId] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [ChatRoomTitile, setChatRoomTitile] = useState("");
  const [ChatRoomid, setChatRoomid] = useState("");
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const collegeUserDetail = useSelector(collegeUserDetailSelectorFn);
  const [numberOfUsers, setnumberOfUsers] = useState(0);
  const [UserName, setUserName] = useState("");
  const college_id = useSelector(collegeIdFn);
  const user_id = collegeDetail?.user_id;
  const [ListOfUsers, setListOfUsers] = useState([]);
  const [Created_Time, setCreated_Time] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  const mediaFiles = useSelector((state) => state?.MediaFiles?.url);
  const messagesEndRef = useRef(null);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [groupTab, setGroupTab] = useState(null);

  useEffect(() => {
    if (data) {
      if (search === "") {
        setFilteredData(data);
      } else {
        let txt = search.toLowerCase();
        let filterTracks = filteredData.filter((item) => {
          if (item.name.toLowerCase().match(txt)) {
            return item;
          }
        });
        setFilteredData(filterTracks);
      }
    }
  }, [search]);

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    var data = new FormData();
    data.append("file", fileUploaded);
    alert.success("File Uploadinggggg.....");
    dispatch(MediaFiles(alert, history, location, data, setLoader));
  };

  useEffect(() => {
    if (mediaFiles) {
      handlePress(message, mediaFiles.url, mediaFiles.pdf);
    }
  }, [mediaFiles]);

  React.useEffect(() => {
    if (college_id) {
      const q = query(
        collection(db, config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV"),
        where("collegeid", "==", parseInt(college_id))
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const temp = [];
        querySnapshot.forEach((doc) => {
          const obj = {
            name: doc.data().name,
            latestMessage: doc.data().latestMessage.text,
            time: doc.data().latestMessage.createdAt,
            threadId: doc.id,
            users: doc.data().users,
          };
          temp.push(obj);
        });
        let result = temp.map((element) => {
          return {
            ...element,
            users: element.users.filter(
              (subElement) => subElement?.college?.id === college_id
            ),
          };
        });

        function sortArrayByTime(arr) {
          arr.sort(function (a, b) {
            return b.time - a.time;
          });
          return arr;
        }

        result = sortArrayByTime(result);
        setData(temp);
        setFilteredData(result);

        /* ################################################ */

        //setId(result[0]?.threadId);

        // if (result[0]?.users.length >= 1) {
        //   setData(temp);
        //   setFilteredData(result);
        // } else {
        //   setData([]);
        // }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [college_id]);

  useEffect(async () => {
    if (id) {
      getMessages();
      setUserName(collegeUserDetail?.name);
    }
  }, [id]);

  const getMessages = async () => {
    const temp = [];
    const chat_group_q = query(
      collection(db, config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV"),
      where("__name__", "==", id)
    );

    const test = onSnapshot(chat_group_q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setChatRoomTitile(doc.data()?.name);
        setChatRoomid(doc.data()?.id);
        setGroupTab(doc.data()?.groupTab);
        setListOfUsers(doc.data().users);
        setnumberOfUsers(doc.data().users.length);
        setCreated_Time(doc.data().group_created_at);
      });
    });
    // const querySnapshot2 = await getDocs(chat_group_q);

    const messgaeRef = collection(
      db,
      config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV",
      id,
      "MESSAGES"
    );
    const q = query(messgaeRef, orderBy("createdAt"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const temp = [];
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
      });
      setAllMessages(temp);
    });
  };

  function sendMessage(e) {
    e.preventDefault();
    handlePress(message, "", "");
    setMessage("");
  }

  const handlePress = async (message, image, pdf) => {
    if (
      (UserName && user_id && message && image === "" && pdf === "") ||
      (UserName && user_id && (image !== "" || pdf !== ""))
    ) {
      const data = {
        text: message ? message : "",
        createdAt: new Date().getTime(),
        system: false,
        sentBy: user_id,
        name: UserName,
        image: pdf?.s3_url ? "" : image ? image : "",
        type: pdf?.s3_url ? "pdf" : "",
        docImage: pdf?.s3_url ? image : "",
        doc: pdf ? pdf : "",
        user: {
          _id: user_id,
        },
      };
      const data_latest_message = {
        text: message,
        createdAt: new Date().getTime(),
      };
      const lref = doc(
        db,
        config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV",
        id
      );
      const updateLatestMessageRef = await updateDoc(lref, {
        latestMessage: data_latest_message,
      });
      setMessage("");
      const docRef = addDoc(
        collection(
          db,
          config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV",
          id,
          "MESSAGES"
        ),
        data
      );
      const payload = {
        group_name: ChatRoomTitile,
        group_id: ChatRoomid,
        type: "MESSAGE",
        message: message,
      };
      dispatch(chatstatus(alert, history, location, payload));
    }
  };

  return (
    <div className={classes.root}>
      {filteredData?.length > 0 ? (
        <Grid container style={{ display: "flex" }}>
          <Grid item xs={12} sm={4}>
            <div
              style={
                {
                  // padding: "10px",
                }
              }
            >
              <div
                style={{ borderBottom: "1px solid #e2e6ea", padding: "20px" }}
              >
                <Paper component="form" className={classes.rootsearch}>
                  <IconButton className={classes.iconButton} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    className={classes.input}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search By Name"
                    inputProps={{
                      style: {
                        fontSize: "15px",
                        color: "#6c757d",
                        padding: "10px",
                      },
                    }}
                  />
                </Paper>
              </div>

              <div
                style={{
                  overflowY: "scroll",
                  height: "470px",
                }}
              >
                {filteredData?.length > 0 &&
                  filteredData.map((item, index) => {
                    return (
                      <CompanyChat
                        chatName={item.name}
                        latestmessage={item.latestMessage}
                        time={moment(item.time).format("LT")}
                        onPress={() => setId(item.threadId)}
                      />
                    );
                  })}
              </div>
            </div>
          </Grid>
          {id !== "" ? (
            <Grid item xs={12} sm={isOpen ? 4 : 8}>
              <div style={{ display: "flex" }}>
                <div
                  className={classes.container}
                  // style={{ borderLeft: "1px solid #e2e6ea" }}
                >
                  {id !== "" && UserName ? (
                    <Paper className={classes.paper} zDepth={2}>
                      <div
                        style={{
                          padding: "21px",
                          borderLeft: "1px solid #e2e6ea",
                        }}
                      >
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid
                            item
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              src={loo}
                              alt="group"
                              className={classes.avatar}
                            />
                          </Grid>

                          <Grid item>
                            <Typography
                              variant="h6"
                              noWrap
                              style={{ fontWeight: 600 }}
                            >
                              {ChatRoomTitile}
                            </Typography>
                            <Button
                              color="primary"
                              onClick={() => setisOpen(!isOpen)}
                              style={{ padding: "0px" }}
                            >
                              {" "}
                              Click here to view group info
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                      <Paper
                        id="style-1"
                        className={classes.messagesBody}
                        style={{
                          borderTop: "1px solid #e2e6ea",
                          borderRadius: "0px",
                          borderLeft: "1px solid #e2e6ea",
                        }}
                      >
                        <br />
                        {allMessages.map((item) => {
                          if (item.sentBy === user_id) {
                            if (item.type === "pdf") {
                              return (
                                <PdfMessageRight
                                  messagedetails={item?.text}
                                  timestamp={moment(item?.createdAt).format(
                                    "LT"
                                  )}
                                  thumbnail={item.docImage}
                                  link={item.doc.s3_url}
                                  message={item.doc.name}
                                />
                              );
                            } else if (item.type === "" && item.image !== "") {
                              return (
                                <ImageMessageRight
                                  messagedetails={item?.text}
                                  timestamp={moment(item?.createdAt).format(
                                    "LT"
                                  )}
                                  thumbnail={item.image}
                                  link={item.image}
                                  message={item.name}
                                />
                              );
                            } else {
                              return (
                                <MessageRight
                                  messagedetails={item?.text}
                                  timestamp={moment(item?.createdAt).format(
                                    "LT"
                                  )}
                                />
                              );
                            }
                          } else {
                            if (item.type === "pdf") {
                              return (
                                <PdfMessageLeft
                                  message={item.doc.name}
                                  messagedetails={item?.text}
                                  timestamp={moment(item?.createdAt).format(
                                    "LT"
                                  )}
                                  thumbnail={item.docImage}
                                  link={item.doc.s3_url}
                                />
                              );
                            } else if (item.type === "" && item.image !== "") {
                              return (
                                <ImageMessageLeft
                                  message={item.name}
                                  messagedetails={item?.text}
                                  timestamp={moment(item?.createdAt).format(
                                    "LT"
                                  )}
                                  thumbnail={item.image}
                                  link={item.image}
                                />
                              );
                            } else {
                              return (
                                <MessageLeft
                                  message={item?.name}
                                  messagedetails={item?.text}
                                  timestamp={moment(item?.createdAt).format(
                                    "LT"
                                  )}
                                />
                              );
                            }
                          }
                        })}
                        {/* <div>{loader && <>uploading</>}</div> */}

                        <div ref={messagesEndRef}></div>
                      </Paper>

                      <div
                        style={{
                          bottom: 10,
                          borderBottomRightRadius: "10px",
                          backgroundColor: "#e2e6ea",
                          padding: "10px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <IconButton>
                    <InsertEmoticonIcon />
                  </IconButton> */}
                        <IconButton
                          onClick={handleClick}
                          style={{ marginRight: "10px" }}
                        >
                          {/* <Button onClick={handleClick}> */}
                          <AttachFileRoundedIcon />
                          {/* </Button> */}
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </IconButton>
                        <Paper
                          component="form"
                          onSubmit={sendMessage}
                          className={classes.msg}
                        >
                          <InputBase
                            className={classes.input}
                            value={message}
                            onChange={(e) => {
                              setMessage(e.target.value);
                            }}
                            // onKeyPress={(e) => {
                            //   if (e.target.keyCode === 13) {
                            //     e.preventDefault();
                            //     handlePress();
                            //   }
                            // }}
                            placeholder="Type a message"
                            inputProps={{
                              style: {
                                fontSize: "15px",
                                color: "#6c757d",
                                padding: "10px",
                              },
                            }}
                          />
                        </Paper>
                      </div>
                    </Paper>
                  ) : null}
                  {/* {isOpen && <RightInfo />} */}
                </div>
              </div>
            </Grid>
          ) : (
            <>
              <NoDataFunc
                height={250}
                title={"No Chat Room Selected"}
                subtitle={"Please select any chat room to continue"}
                buttonText={null}
              />
            </>
          )}

          <Grid item xs={12} sm={4}>
            {isOpen && (
              <RightInfo
                groupid={ChatRoomid}
                groupTab={groupTab}
                ChatRoomTitile={ChatRoomTitile}
                numberOfUsers={numberOfUsers}
                ListOfUsers={ListOfUsers}
                Created_Time={Created_Time}
                ThreadId={id}
                AllMessages={allMessages}
                rightInfoClose={() => setisOpen(!isOpen)}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <div style={{ marginTop: "2%" }}>
            <NoDataFunc width={"320px"} height={"320px"} />
          </div>
          <div
            style={{
              marginTop: "-50px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              No Chat Room Available
            </Typography>{" "}
            <Button
              onClick={() => history.push("chat-group")}
              variant="contained"
              color="primary"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                height: "40px",
                borderRadius: "8px",
                boxShadow: "none",
              }}
            >
              Create New Chat Room
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
