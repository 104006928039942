export const checkForUrl = (str) => {
  var new_str = "";

  if (str) {
    if (str.includes("http")) new_str = str;
    else new_str = "https://" + str;
  } else {
    new_str = str;
  }

  return new_str;
};
