import React, { useState } from "react";
import OtpInput from "react-otp-input";
import {
  Typography,
  IconButton,
  Grid,
  Button,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Countdown from "react-countdown";
import { useAlert } from "react-alert";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";

export default function OtpForm({
  activeStep,
  setActiveStep,
  email,
  setEmail,
  loginDetails,
  setLoginDetails,
  handleClose,
  prefilDetails,
  setPrefilDetails,
  setLoginSucessful,
}) {
  const [otp, setOtp] = useState("");
  const theme = useTheme();
  const [timerComplete, setTimerComplete] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const alert = useAlert();
  const partialEmail = email?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2");

  const handleResend = () => {
    if (email && loginDetails?.actionType) {
      setTimerComplete(false);
      setLoadingResend(true);
      const payload = {
        email: email,
        otp_type: "EMAIL",
        action_type: loginDetails?.actionType,
      };
      axios
        .post(
          BackendBaseApi.PRAVESH + "api/saas/public_job/otp/resend/ ",
          payload,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setLoadingResend(false);
          if (res?.data?.success) {
            alert.success(res?.data?.data?.message);
            setOtp("");
          } else {
            alert.error(res?.data?.error);
            setOtp("");
          }
        })
        .catch((err) => {
          alert.error("something went wrong");
          setLoadingResend(false);
          setOtp("");
        });
    }
  };

  const handleOtp = async () => {
    const payload = {
      email: email,
      otp: otp,
      action_type: loginDetails?.actionType,
      otp_type: "EMAIL",
    };
    const response = await axios.post(
      BackendBaseApi.PRAVESH + "api/saas/public_job/otp/validate/ ",
      payload,
      {
        withCredentials: true,
      }
    );
    return response;
  };

  const handleClick = () => {
    if (otp.length === 4 && email && loginDetails?.actionType) {
      setLoadingSubmit(true);
      handleOtp()
        .then((res) => {
          setLoadingSubmit(false);
          if (res?.data?.success) {
            alert.success(res?.data?.data?.message);

            if (loginDetails?.studentUser && !loginDetails?.educationUser) {
              setActiveStep(2);
              setPrefilDetails({
                ...prefilDetails,
                name: res?.data?.data?.user_details?.name,
                phone: Number(res?.data?.data?.user_details?.phone),
                enrollmentId: res?.data?.data?.user_details?.enrollment_id,
                collegePasscode:
                  res?.data?.data?.user_details?.college_passcode,
                degree: {
                  degree_name: res?.data?.data?.user_details?.degree_name,
                  degree_id: res?.data?.data?.user_details?.degree_id,
                  education_id: res?.data?.data?.user_details?.education_id,
                },
                course: {
                  specialization_id:
                    res?.data?.data?.user_details?.specialization_id,
                  specialization_name:
                    res?.data?.data?.user_details?.specialization_name,
                },
                resume: res?.data?.data?.user_details?.resume?.resume_link
                  ? res?.data?.data?.user_details?.resume
                  : null,
              });
            } else if (
              !loginDetails?.studentUser &&
              !loginDetails?.educationUser
            )
              setActiveStep(2);
            else if (loginDetails?.studentUser && loginDetails?.educationUser) {
              setLoginSucessful(true);
              handleClose();
              window.localStorage.setItem("loginApplyJob", true);
              window.localStorage.setItem(
                "userDetails_first_name",
                res?.data?.data?.user_details?.first_name
              );
              window.localStorage.setItem(
                "userDetails_last_name",
                res?.data?.data?.user_details?.last_name
              );
            }
          } else {
            alert.error(res?.data?.error);
            setCodeError(res?.data?.error);
            setOtp("");
          }
        })
        .catch((err) => {
          alert.error("something went wrong");
          setCodeError("something went wrong");
          setLoadingSubmit(false);
          setOtp("");
        });
    } else alert.error("Enter Valid OTP");
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Typography
            color="primary"
            style={{
              fontSize: 16,
              textAlign: "flex-start",
              fontWeight: "bold",
              padding: 12,
            }}
          >
            <span style={{ color: "#000", fontWeight: 400 }}>
              {" "}
              Sent OTP to{" "}
            </span>
            {partialEmail ? <>{partialEmail} </> : <> {partialEmail} </>}
          </Typography>

          <IconButton
            color="primary"
            onClick={() => setActiveStep(0)}
            style={{ padding: 0 }}
          >
            <EditIcon />
          </IconButton>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <OtpInput
                  value={otp}
                  onChange={(e) => {
                    setOtp(e);
                  }}
                  numInputs={4}
                  renderSeparator={<span style={{ width: 20 }}></span>}
                  renderInput={(props) => <input {...props} />}
                  separator={<span style={{ width: "20px" }}></span>}
                  isInputNum={true}
                  inputType="number"
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: ".5px solid #B0B6BA",
                    borderRadius: "8px",
                    width: "56px",
                    height: "56px",
                    fontSize: "20px",
                    fontFamily: "Nunito",
                    color: "#000",
                    fontWeight: "400",
                    caretColor: "#00203F",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                />

                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    marginTop: "10px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span
                    disabled={!timerComplete}
                    style={
                      timerComplete
                        ? {
                            color: "#6c757d",
                            cursor: "pointer",
                            fontSize: "14px",
                          }
                        : { color: "#6c757d", fontSize: "14px" }
                    }
                    onClick={handleResend}
                  >
                    Resend OTP
                  </span>
                  {!timerComplete && (
                    <span style={{ color: "#6c757d" }}>{""}&nbsp; in </span>
                  )}
                  &nbsp;
                  <span style={{ color: theme.palette.primary.main }}>
                    {!timerComplete && (
                      <Countdown
                        date={Date.now() + 30000}
                        onComplete={() => setTimerComplete(true)}
                        renderer={(props) => (
                          <div>
                            <b>{props.seconds + " sec"}</b>
                          </div>
                        )}
                      />
                    )}
                  </span>
                </Typography>

                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: 12,
                  }}
                >
                  {/* <span style={{ color: "#ed1111" }}> {codeError}</span>{" "} */}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "grid",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!otp}
                style={{
                  height: "45px",
                  boxShadow: "none",
                }}
                onClick={() => handleClick()}
              >
                {loadingSubmit ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  "Verify"
                )}
              </Button>
              <Typography
                style={{
                  color: "#A3A3A3",
                  textAlign: "center",
                  fontSize: "14px",
                  marginBottom: "20px",
                  padding: 12,
                }}
              >
                Check spam folders if email is not in the inbox.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
