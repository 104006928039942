// import React, { useState } from "react";
// import { useTheme, Typography } from "@material-ui/core";

// function PieTab() {
//   const theme = useTheme();
//   const [value, setValue] = useState(1);
//   function handleChange(id) {
//     setValue(id);
//   }

//   return (
//     <div>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",

//           padding: "20px",
//           borderRadius: "8px",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           {data.map((item, id) => (
//             <ul
//               style={{
//                 padding: 0,
//                 display: "flex",
//                 listStyleType: "none",
//                 flexWrap: "wrap",
//                 textAlign: "center",
//                 width: "100%",
//               }}
//               id="myTab"
//               data-tabs-toggle="#myTabContent"
//               role="tablist"
//             >
//               <li
//                 style={{ width: "100%" }}
//                 role="presentation"
//                 onClick={() => handleChange(item.id)}
//               >
//                 <div
//                   style={{
//                     padding: "10px",
//                     cursor: "pointer",
//                     borderRadius: "8px",
//                     color: value === item.id ? "#fff" : "#000",
//                     backgroundColor:
//                       value === item.id
//                         ? theme.palette.primary.main
//                         : "#FAFAFA",
//                   }}
//                 >
//                   {item.title}
//                 </div>
//               </li>
//             </ul>
//           ))}
//         </div>

//         {data.map((item, id) => (
//           <div id="myTabContent">
//             <div
//               style={{
//                 display: value === item.id ? "block " : "none",
//                 marginTop: "20px",
//               }}
//               id="profile"
//               role="tabpanel"
//               aria-labelledby="profile-tab"
//             >
//               {item.tab}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default PieTab;

// const data = [
//   {
//     id: 1,
//     title: "Student Data",
//     tab: "RDGF",
//   },
//   {
//     id: 2,
//     title: "Job Data",
//     // tab: <ReportHeading />,
//   },
//   {
//     id: 3,
//     title: "Student Placed Data",
//     // tab: <ReportHeading />,
//   },
//   {
//     id: 4,
//     title: "Job Placed Data",
//     // tab: <ReportHeading />,
//   },
//   {
//     id: 5,
//     title: "Star Performer",
//     // tab: <ReportHeading />,
//   },
// ];

import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Typography, useTheme } from "@material-ui/core";
import CampusBarGraph from "./CampusBarGraph";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import ReportTable from "./ReportTable";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import CampusPieGraph from "./CampusPieGraph";
import ReportTableSecond from "./ReportTableSecond";

const options = ["Option 1", "Option 2"];

function PieTab() {
  const theme = useTheme();
  const [value, setValue] = useState(1);
  function handleChange(id) {
    setValue(id);
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography variant="h6">Course vs Gender</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>
            {" "}
            <Autocomplete
              id="custom-input-demo"
              options={options}
              renderInput={(params) => (
                <div
                  ref={params.InputProps.ref}
                  style={{
                    padding: 10,
                    borderRadius: 8,
                    backgroundColor: "#F6F6F6",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SearchRoundedIcon style={{ color: "#6C757D" }} />
                  <input
                    style={{
                      width: 250,
                      backgroundColor: "#F6F6F6",
                      border: "none",
                      color: "#6C757D",
                    }}
                    type="text"
                    placeholder="Search course"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                //   padding: "10px",
                borderRadius: "8px",
              }}
            >
              <div style={{ display: "flex" }}>
                {data.map((item, id) => (
                  <ul
                    style={{
                      padding: 0,
                      display: "flex",
                      listStyleType: "none",
                      flexWrap: "wrap",
                      textAlign: "center",
                    }}
                    id="myTab"
                    data-tabs-toggle="#myTabContent"
                    role="tablist"
                  >
                    <li
                      className="mr-2"
                      role="presentation"
                      onClick={() => handleChange(item.id)}
                    >
                      <div
                        style={{
                          padding: "6px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          color:
                            value === item.id
                              ? "#fff"
                              : theme.palette.primary.main,
                          backgroundColor:
                            value === item.id
                              ? theme.palette.primary.main
                              : "#fff",
                        }}
                      >
                        {item.title}
                      </div>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.map((item, id) => (
        <div id="myTabContent">
          <div
            style={{
              display: value === item.id ? "block " : "none",
              marginTop: "20px",
            }}
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {item.tab}
          </div>
        </div>
      ))}
    </>
  );
}

export default PieTab;

const data = [
  {
    id: 1,
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <TableChartOutlinedIcon style={{ marginRight: 5 }} /> Table
      </div>
    ),
    tab: <ReportTableSecond />,
  },
  {
    id: 2,
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <BarChartOutlinedIcon /> Graph
      </div>
    ),
    tab: <CampusPieGraph />,
  },
];
