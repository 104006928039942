import {
  EDUCATIONAL_GROUP_ASSIGNMENTS,
  EDUCATIONAL_GROUP_COMPANY,
  EDUCATIONAL_GROUP_EJD,
  EDUCATIONAL_GROUP_INTERVIEWS,
  EDUCATIONAL_GROUP_JOBS,
  EDUCATIONAL_GROUP_LIST,
  EDUCATIONAL_GROUP_PLACEMENT_CYCLES,
  EDUCATIONAL_GROUP_STUDENTS,
  EDUCATIONAL_GROUP_TEAM_MEMBER,
  EDUCATIONAL_GROUP_USER_ROLES,
  EDUCATIONAL_GROUP_COURSES,
  EDUCATIONAL_GROUP_COLLEGES,
  EDUCATIONAL_GROUP_PROFILE,
  EDUCATIONAL_GROUP_EJNF_TABLE,
  EDUCATIONAL_GROUP_STUDENTS_TABLE,
  EDUCATIONAL_GROUP_COMPANY_TABLE,
  EDUCATIONAL_GROUP_DASHBOARD,
  EDUCATIONAL_GROUP_ALL_PLACEMENTS,
  EDUCATIONAL_GROUP_ALL_COLLEGE_DASHBOARD,
  EDUCATIONAL_GROUP_DEGREE,
  EDUCATIONAL_GROUP_SKILLS,
  EDUCATIONAL_GROUP_INDUSTRY,
  EDUCATIONAL_GROUP_SKILLS_TABLE,
  EDUCATIONAL_GROUP_INDUSTRY_TABLE,
  EDUCATIONAL_GROUP_BILLING,
  COLLEGE_GROUPS_ADMIN,
  EDUCATIONAL_GROUP_COLLEGE_GROUPS,
  EDUCATIONAL_GROUP_COLLEGE_GROUPS_DETAIL,
  SELECTED_COLLEGE_GROUPS_ADMIN,
  ALL_COLLEGE_SELECTED
} from "./action";

export const EDGCommon = (state = {}, action) => {
  switch (action.type) {
    case EDUCATIONAL_GROUP_PLACEMENT_CYCLES:
      return { ...state, edgPlacementList: action.payload };
    case EDUCATIONAL_GROUP_COMPANY:
      return { ...state, edgCompanyList: action.payload };
    case COLLEGE_GROUPS_ADMIN:
      return { ...state, admins: action.payload };
      case SELECTED_COLLEGE_GROUPS_ADMIN:
        return { ...state, selectedGroupAdmins: action.payload };
    case EDUCATIONAL_GROUP_JOBS:
      return { ...state, edgJobsList: action.payload };
    case EDUCATIONAL_GROUP_STUDENTS:
      return { ...state, edgStudentsList: action.payload };
    case EDUCATIONAL_GROUP_EJD:
      return { ...state, edgEjdList: action.payload };
    case EDUCATIONAL_GROUP_INTERVIEWS:
      return { ...state, edgInterviewList: action.payload };
    case EDUCATIONAL_GROUP_ASSIGNMENTS:
      return { ...state, edgAssignmentList: action.payload };
    case EDUCATIONAL_GROUP_USER_ROLES:
      return { ...state, edgUserRoles: action.payload };
    case EDUCATIONAL_GROUP_TEAM_MEMBER:
      return { ...state, edgTeamList: action.payload };
    case EDUCATIONAL_GROUP_LIST:
      return { ...state, edgList: action.payload };
    case EDUCATIONAL_GROUP_COURSES:
      return { ...state, edgCourses: action.payload };
    case EDUCATIONAL_GROUP_COLLEGES:
      return { ...state, edgCollegeList: action.payload };
    case EDUCATIONAL_GROUP_PROFILE:
      return { ...state, edgProfile: action.payload };
    case EDUCATIONAL_GROUP_EJNF_TABLE:
      return { ...state, edgTableEJNF: action.payload };
    case EDUCATIONAL_GROUP_STUDENTS_TABLE:
      return { ...state, edgTableStudents: action.payload };
    case EDUCATIONAL_GROUP_COMPANY_TABLE:
      return { ...state, edgTableCompany: action.payload };
    case EDUCATIONAL_GROUP_DASHBOARD:
      return { ...state, edgDashboard: action.payload };
    case EDUCATIONAL_GROUP_ALL_PLACEMENTS:
      return { ...state, edgDashboardPlacements: action.payload };
    case EDUCATIONAL_GROUP_ALL_COLLEGE_DASHBOARD:
      return { ...state, edgDashboardAllCollege: action.payload };
    case EDUCATIONAL_GROUP_DEGREE:
      return { ...state, edgDashboardDegree: action.payload };
    case EDUCATIONAL_GROUP_SKILLS:
      return { ...state, edgDashboardSkills: action.payload };
    case EDUCATIONAL_GROUP_SKILLS_TABLE:
      return { ...state, edgDashboardSkillsTable: action.payload };
    case EDUCATIONAL_GROUP_INDUSTRY:
      return { ...state, edgDashboardIndustry: action.payload };
    case EDUCATIONAL_GROUP_INDUSTRY_TABLE:
      return { ...state, edgDashboardIndustryTable: action.payload };
    case EDUCATIONAL_GROUP_BILLING:
      return { ...state, edgBilling: action.payload };
    case EDUCATIONAL_GROUP_COLLEGE_GROUPS:
      return { ...state, collegeGroups: action.payload };
      case EDUCATIONAL_GROUP_COLLEGE_GROUPS_DETAIL:
        return { ...state, collegeGroupsDetail: action.payload };
      case ALL_COLLEGE_SELECTED:
        return { ...state, allCollegeSelected: action.payload };
    default:
      return state;
  }
};
