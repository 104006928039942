import { Button, Chip, Dialog, TextField, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
//import { BlockStudentAPI } from "../../../api/ManageStudents/action";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: "20px",
    paddingTop: "20px",
  },

  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
}));

export default function DeleteJob({setEjdJobsList,ejdJobsList,open,handleClose,index}) {


  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDelete = (index_n) => {
    if (index_n !== -1) {
      var arr = [...ejdJobsList];
      arr.splice(index_n, 1);
      setEjdJobsList(arr);
   
    }
 };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "400px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure <br />
              you want to delete this job
            </Typography>
          </div>

          <div className={classes.btngrid}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              autoFocus
              className={classes.button}
              variant="contained"
              color="primary"
              style={{ minWidth: "140px" }}
              onClick={
                () =>{
                 handleDelete(index);
                 handleClose();
              }
            }
            >
              Delete
            
            </Button>
          </div>
        </div>

        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
