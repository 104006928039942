import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AppBar, Tab, Typography, Box, Tabs } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ManageTeamActionnBar from "../../Input/ManageTeamActionBar";
import ManageTeamTable from "../../Table/ManageTeamTable";
import { useSelector } from "react-redux";
import { columns, columns2, columnsTeamRejected } from "./column";
import CompanyTable from "../../Table/CompanyTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    color: "black",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    color: "red",
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function ManageJobTab({
  value,
  setValue,
  loading,
  filters,
  setFilters,
  setClickSearchButton,
  setSelectedRowsData,
  selectedRowsData,
  clickSearchButton,
  setUserRoleId,
  designationList,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  teamList,
}) {
  const classes = useStyles();
  const [selectedID, setSelectedID] = React.useState([]);
  // const teamList = useSelector((state) => state?.TeamData?.details);

  const userRoleList = useSelector((state) => state.TeamData.navbar);

  useEffect(() => {
    if (userRoleList?.length > 0) setUserRoleId(userRoleList[0].id);
  }, [userRoleList?.length]);

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.key === "pending_team" ||
      filters?.current_tab?.key === "rejected_team"
    );
  };

  const checkForRejected = () => {
    return filters?.current_tab?.key === "rejected_team";
  };

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={value}
          onChange={(e) => {
            // console.log("");
          }}
          indicatorColor="primary"
          textColor="primary"
          variant={userRoleList?.length < 4 ? "" : "scrollable"}
        >
          {userRoleList?.length > 0 &&
            userRoleList?.map((item) => (
              <>
                <NewTab
                  value={item.tab_id}
                  label={`${item.user_role_name}(${item.count})`}
                  wrapped
                  {...a11yProps(item.tab_id)}
                  onClick={() => {
                    setValue(item.tab_id);
                    setUserRoleId(item.id);
                    setFilters({ ...filters, current_tab: item });
                  }}
                />
              </>
            ))}
        </NewTabs>
      </AppBar>

      <ManageTeamActionnBar
        filters={filters}
        setFilters={setFilters}
        setClickSearchButton={setClickSearchButton}
        selectedRowsData={selectedRowsData}
        clickSearchButton={clickSearchButton}
        setSelectedRowsData={setSelectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        designationList={designationList}
        teamList={teamList}
        collegeId={collegeId}
        userRolesPermission={userRolesPermission}
        setCallNavbar={setCallNavbar}
      />
      <br />
      {userRoleList?.map((item) => (
        <TabPanel value={value} index={item.tab_id}>
          <CompanyTable
            loading={loading}
            list={teamList}
            columns={
              checkForPendingRejected
                ? checkForRejected()
                  ? columnsTeamRejected
                  : columns2
                : columns
            }
            setSelectedRowsData={setSelectedRowsData}
            selectedRowData={selectedRowsData}
            selectedID={selectedID}
            setSelectedID={setSelectedID}
            checkBoxSelection={checkForRejected() ? false : true}
          />
        </TabPanel>
      ))}
      {/* <TabPanel value={value} index="two">
        <CompanyTable
          loading={loading}
          list={teamList}
          columns={columns}
          setSelectedRowsData={setSelectedRowsData}
          selectedRowData={selectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index="three">
        <CompanyTable
          loading={loading}
          list={teamList}
          columns={columns}
          setSelectedRowsData={setSelectedRowsData}
          selectedRowData={selectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
        />{" "}
      </TabPanel> */}
    </div>
  );
}
