import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import {
  AllCoursesApi,
  CoursesGetAPI,
  CoursesPostAPI,
} from "../../api/Common/action";
import {
  collegeDetailSelectorFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import DegreeSpecializationCustom from "../../components/DegreeSpecializationCustom/index2";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "30px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    // height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textbutton: {
    padding: "0px",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.12)",
    // border: `solid 0.5px ${theme.palette.primary.main}`,
    border: "none",
    borderRadius: "8px",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    marginTop: "30px",
    marginBottom: "30px",
  },
  btnDownload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    marginTop: "30px",
    marginBottom: "30px",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  sec_download_textAdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: "2rem",
  },
}));

export default function CourseSetting() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingGet, setLoadingGet] = useState(false);
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const college_id = collegeDetail?.college_id;
  const alert = useAlert();

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const [state, setState] = React.useState({
    bca: "",
    name: "hai",
  });
  const selectedCoursesIdDownload = useRef([]);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
    if (college_id) {
      dispatch(
        CoursesGetAPI(setLoadingGet, alert, history, location, college_id)
      );

      dispatch(AllCoursesApi(alert, history, location, setLoading));
    }
  }, [college_id]);

  const [coursesArray, setCoursesArray] = useState([]);

  const courses = useSelector((state) => state?.commonData?.allCourseData);

  const selectedCourses = useSelector(
    (state) => state?.commonData?.selectedCourseData?.data
  );
  const userRolesPermission = useSelector(userRolesActionsFn);

  useEffect(() => {
    if (selectedCourses?.length > 0) {
      let arr = selectedCourses.map((item) => ({
        ...item,
        degrees: item.degrees.map((degree) => ({
          ...degree,
          show_more: false,
        })),
      }));
      setDataSelectedNewFormat(arr);
      arr = [...dataSelected];
      selectedCourses.map((item) => {
        item.degrees.map((i) =>
          arr.push({
            ...i,
            education_id: item.education_id,
            education_name: item?.education_name,
            show_more: false,
          })
        );
      });
      setDataSelected(arr);
    }
  }, [selectedCourses?.length]);

  useEffect(() => {
    if (selectedCourses?.length > 0) {
      const tempArr = [];
      selectedCourses?.map((education) => {
        education?.degrees?.map((degree) => {
          degree?.specialization?.map((specialization) => {
            tempArr?.push({
              Specialization_Id: specialization?.specialization_id,
              Specialization_Name: specialization?.specialization_name,
              Degree_id: specialization?.degree_id,
              Degree_Name: specialization?.degree_name,
              Education_id: degree?.education_id,
              Education_name: degree?.education_name,
            });
          });
        });
      });

      selectedCoursesIdDownload.current = tempArr;
    }
  }, [selectedCourses?.length]);

  useEffect(() => {
    if (courses?.length && selectedCourses?.length) {
      var arr = [...courses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2["select_all"] = checkForDegree(item2);
          item2["education_name"] = item.education_name;
          item2["education_id"] = item.education_id;
          item2.specialization.forEach((item3) => {
            item3["selected"] = checkForSpec(
              item3,
              item2.degree_id,
              item.education_id,
              item2.specialization
            );
            item3["degree_id"] = item2.degree_id;
            item3["degree_name"] = item2.degree_name;
          });
        });
      });
      setCoursesArray(arr);
    }
  }, [courses?.length, selectedCourses?.length]);

  const checkForDegree = (item) => {
    var bool_ = false;

    var arr = [...selectedCourses];
    if (selectedCourses?.length > 0) {
      arr.map((i) => {
        i.degrees.map((internal) => {
          if (internal.degree_id === item.degree_id) {
            if (internal.specialization.length > 0) bool_ = true;
            else bool_ = false;
          }
        });
      });
    } else bool_ = false;

    return bool_;
  };

  const checkForSpec = (item, deg_id, edu_id, spec_arr) => {
    var bool_ = false;
    var arr = [...selectedCourses];
    if (selectedCourses?.length > 0) {
      var edu_index = arr.findIndex((i) => i.education_id === edu_id);
      if (edu_index !== -1)
        var deg_index = arr[edu_index].degrees.findIndex(
          (i) => i.degree_id === deg_id
        );

      if (edu_index !== -1 && deg_index !== -1) {
        arr[edu_index].degrees[deg_index].specialization.map((items) => {
          if (items.specialization_id === item.specialization_id) bool_ = true;
          // else bool_ = false;
        });
      }
    } else bool_ = false;

    return bool_;
  };

  const [dataSelected, setDataSelected] = useState([]);
  const [dataSelectedNewFormat, setDataSelectedNewFormat] = useState([]);
  const [specializationInDegreeId, setSpecializationInDgegreeId] = useState([]);
  const [pushArray, setPushArray] = useState([]);
  const [loadingPost, setLoadingPost] = useState(false);

  useEffect(() => {
    if (dataSelectedNewFormat?.length > 0) {
      dataSelectedNewFormat?.forEach((item) => {
        item["show_more"] = false;
      });
    }
  }, [dataSelectedNewFormat?.length]);

  const handleDeleteChip = (spec_id, spec, deg_id, edu_id) => {
    var arr = [...dataSelected];

    var index = spec.findIndex((i) => i.specialization_id === spec_id);
    var deg_index = arr.findIndex((i) => i.degree_id === deg_id);

    var arrn = [...spec];
    arrn.splice(index, 1);
    if (arrn?.length > 0) {
      arr[deg_index].specialization = arrn;
    } else {
      arr.splice(deg_index, 1);
    }

    var push_arr = [...pushArray];
    push_arr.push(1);

    var loc = specializationInDegreeId;
    loc[deg_id] = arrn;

    var select_array = [...coursesArray];
    var edu_index = select_array.findIndex((i) => i.education_id === edu_id);
    var new_deg_index = select_array[edu_index].degrees.findIndex(
      (i) => i.degree_id === deg_id
    );
    if (arrn?.length === 0) {
      select_array[edu_index].degrees[new_deg_index].select_all = false;
      select_array[edu_index].degrees[new_deg_index].specialization.forEach(
        (item) => {
          item.selected = false;
        }
      );
    } else {
      select_array[edu_index].degrees[new_deg_index].specialization.forEach(
        (item) => {
          if (item.specialization_id === spec_id) item.selected = false;
        }
      );
    }
    setCoursesArray(select_array);
    setSpecializationInDgegreeId(loc);
    setDataSelected(arr);
    setPushArray(push_arr);
  };

  const handleSubmit = () => {
    var data = {
      college_id: [college_id],
      data: dataSelectedNewFormat,
    };
    if (dataSelectedNewFormat.length > 0)
      dispatch(
        CoursesPostAPI(
          data,
          setLoadingPost,
          alert,
          history,
          location,
          college_id
        )
      );
    else alert.error("Please Select at least one Course");
  };

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">Courses</Typography>

          <Typography variant="body2" color="secondary">
            {`Manage your ${entityInfo?.college}'s Courses Settings`}
          </Typography>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {userRolesPermission?.some(
              (item) => item.access_control_key === "create_college_profile"
            ) && (
              <section className={classes.sec_download_textAdd}>
                <Typography variant="h6">Add Course</Typography>

                <CSVLink
                  data={selectedCoursesIdDownload.current}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  filename={"courseIdSelected.csv"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginRight: "0px",
                      display: "flex",
                      alignContent: "center",
                    }}
                    className={classes.btnDownload}
                    startIcon={<GetAppIcon />}
                  >
                    Download IDs
                  </Button>
                </CSVLink>
              </section>
            )}
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
              <Grid item xs={12}>
                {" "}
                {userRolesPermission?.some(
                  (item) => item.access_control_key === "create_college_profile"
                ) && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    {/* <InputLabel className={classes.floatingLabelFocusStyle}>Courses</InputLabel> */}
                    <DegreeSpecializationCustom
                      data={dataSelected}
                      setData={setDataSelected}
                      specialization={specializationInDegreeId}
                      setSpecialization={setSpecializationInDgegreeId}
                      coursesArray={coursesArray}
                      pushArray={pushArray}
                      setPushArray={setPushArray}
                      errorMessage={null}
                      loading={loading}
                      dataSelectedNewFormat={dataSelectedNewFormat}
                      setDataSelectedNewFormat={setDataSelectedNewFormat}
                    />
                  </FormControl>
                )}
              </Grid>

              <br />

              {loadingGet ? (
                <Grid item xs={12}>
                  <CustomCircularProgress />
                </Grid>
              ) : (
                // dataSelectedNewFormat?.map((item, index) => (
                //   <>
                //     <Grid item xs={12}>
                //       <Typography variant="h6" color="secondary">
                //         {item.education_name}
                //       </Typography>
                //     </Grid>
                //     {item.degrees.map((item2, index2) => (
                //       <Grid item xs={6}>
                //         <Paper className={classes.paper}>
                //           <Typography variant="h6">
                //             {item2?.degree_duration
                //               ? item2.degree_name +
                //                 " (" +
                //                 item2?.degree_duration +
                //                 ")"
                //               : item2.degree_name}
                //           </Typography>
                //           <br />
                //           {!item.show_more
                //             ? item2.specialization.slice(0, 6).map((item3) => (
                //                 <Chip
                //                   variant="outlined"
                //                   color="primary"
                //                   size="small"
                //                   onDelete={() => {
                //                     if (
                //                       userRolesPermission?.some(
                //                         (item) =>
                //                           item.access_control_key ===
                //                           "create_college_profile"
                //                       )
                //                     )
                //                       handleDeleteChip(
                //                         item3.specialization_id,
                //                         item2.specialization,
                //                         item2.degree_id,
                //                         item.education_id
                //                       );
                //                   }}
                //                   deleteIcon={
                //                     userRolesPermission?.some(
                //                       (item) =>
                //                         item.access_control_key ===
                //                         "create_college_profile"
                //                     ) ? (
                //                       <CloseIcon />
                //                     ) : (
                //                       <></>
                //                     )
                //                   }
                //                   label={item3.specialization_name}
                //                   className={classes.chip}
                //                   style={{
                //                     maxWidth: 200,
                //                     marginBottom: 20,
                //                     height: 30,
                //                   }}
                //                 />
                //               ))
                //             : item2.specialization.map((item3) => (
                //                 <Chip
                //                   variant="outlined"
                //                   color="primary"
                //                   size="small"
                //                   onDelete={() => {
                //                     if (
                //                       userRolesPermission?.some(
                //                         (item) =>
                //                           item.access_control_key ===
                //                           "create_college_profile"
                //                       )
                //                     )
                //                       handleDeleteChip(
                //                         item3.specialization_id,
                //                         item2.specialization,
                //                         item2.degree_id,
                //                         item.education_id
                //                       );
                //                   }}
                //                   deleteIcon={
                //                     userRolesPermission?.some(
                //                       (item) =>
                //                         item.access_control_key ===
                //                         "create_college_profile"
                //                     ) ? (
                //                       <CloseIcon />
                //                     ) : (
                //                       <></>
                //                     )
                //                   }
                //                   label={item3.specialization_name}
                //                   className={classes.chip}
                //                   style={{
                //                     maxWidth: 200,
                //                     marginBottom: 20,
                //                     height: 30,
                //                   }}
                //                 />
                //               ))}
                //           <br />
                //           {item2.specialization?.length > 7 && (
                //             <Button
                //               color="primary"
                //               className={classes.textbutton}
                //               onClick={() => {
                //                 var arr = [...dataSelectedNewFormat];
                //                 arr[index][index2].show_more =
                //                   !arr[index][index2].show_more;
                //                 setDataSelectedNewFormat(arr);
                //               }}
                //             >
                //               {item.show_more
                //                 ? "Hide Complete List"
                //                 : "Show Complete List"}
                //             </Button>
                //           )}
                //         </Paper>
                //       </Grid>
                //     ))}
                //   </>
                // ))
                dataSelectedNewFormat.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="secondary">
                        {item.education_name}
                      </Typography>
                    </Grid>
                    {item.degrees.map((degree, degreeIndex) => (
                      <Grid item xs={6} key={degreeIndex}>
                        <Paper className={classes.paper}>
                          <Typography variant="h6">
                            {degree.degree_duration
                              ? `${degree.degree_name} (${degree.degree_duration})`
                              : degree.degree_name}
                          </Typography>
                          <br />
                          {!degree.show_more
                            ? degree.specialization
                                .slice(0, 6)
                                .map((spec, specIndex) => (
                                  <Chip
                                    key={specIndex}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onDelete={() => {
                                      if (
                                        userRolesPermission?.some(
                                          (perm) =>
                                            perm.access_control_key ===
                                            "create_college_profile"
                                        )
                                      ) {
                                        handleDeleteChip(
                                          spec.specialization_id,
                                          degree.specialization,
                                          degree.degree_id,
                                          item.education_id
                                        );
                                      }
                                    }}
                                    deleteIcon={
                                      userRolesPermission?.some(
                                        (perm) =>
                                          perm.access_control_key ===
                                          "create_college_profile"
                                      ) ? (
                                        <CloseIcon />
                                      ) : null
                                    }
                                    label={spec.specialization_name}
                                    className={classes.chip}
                                    style={{
                                      maxWidth: 200,
                                      marginBottom: 20,
                                      height: 30,
                                    }}
                                  />
                                ))
                            : degree.specialization.map((spec, specIndex) => (
                                <Chip
                                  key={specIndex}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onDelete={() => {
                                    if (
                                      userRolesPermission?.some(
                                        (perm) =>
                                          perm.access_control_key ===
                                          "create_college_profile"
                                      )
                                    ) {
                                      handleDeleteChip(
                                        spec.specialization_id,
                                        degree.specialization,
                                        degree.degree_id,
                                        item.education_id
                                      );
                                    }
                                  }}
                                  deleteIcon={
                                    userRolesPermission?.some(
                                      (perm) =>
                                        perm.access_control_key ===
                                        "create_college_profile"
                                    ) ? (
                                      <CloseIcon />
                                    ) : null
                                  }
                                  label={spec.specialization_name}
                                  className={classes.chip}
                                  style={{
                                    maxWidth: 200,
                                    marginBottom: 20,
                                    height: 30,
                                  }}
                                />
                              ))}
                          <br />
                          {degree.specialization.length > 7 && (
                            <Button
                              color="primary"
                              className={classes.textbutton}
                              onClick={() => {
                                const arr = [...dataSelectedNewFormat];
                                arr[index].degrees[degreeIndex].show_more =
                                  !arr[index].degrees[degreeIndex].show_more;
                                setDataSelectedNewFormat(arr);
                              }}
                            >
                              {degree.show_more
                                ? "Hide Complete List"
                                : "Show Complete List"}
                            </Button>
                          )}
                        </Paper>
                      </Grid>
                    ))}
                  </React.Fragment>
                ))
              )}
            </Grid>
          </Paper>
          {userRolesPermission?.some(
            (item) => item.access_control_key === "create_college_profile"
          ) && (
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "230px" }}
              className={classes.btn}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loadingPost ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : (
                "Update Courses"
              )}
            </Button>
          )}
        </Grid>
      </Grid>
      {/* </Layout> */}
    </>
  );
}
