import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: ".2px solid #ced4da",
  },
  body: {
    fontSize: 14,
    border: ".2px solid #ced4da",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Roshan Kapoor", "9839302906", "HR Head", "Technology", "prerit.chaudhary@getwork.org"),
  createData("Roshan Kapoor", "9839302906", "HR Head", "Technology", "prerit.chaudhary@getwork.org"),
  createData("Roshan Kapoor", "9839302906", "HR Head", "Technology", "prerit.chaudhary@getwork.org"),
  createData("Roshan Kapoor", "9839302906", "HR Head", "Technology", "prerit.chaudhary@getwork.org"),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CompanyDetailsTable({ companyData, pocList, setPocList }) {
  const classes = useStyles();

  return (
    <>
      {pocList?.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Contact Person Name</StyledTableCell>
                <StyledTableCell align="center">Designation</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Phone</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pocList?.length > 0 &&
                pocList?.map((row, index) => (
                  <StyledTableRow key={row.contact_person_name}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {row.contact_person_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.designation}</StyledTableCell>
                    <StyledTableCell align="center">{row.email}</StyledTableCell>
                    <StyledTableCell align="center">{row.phone}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                          var arr = [...pocList];
                          arr.splice(index, 1);
                          setPocList(arr);
                        }}
                      >
                        Delete
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
