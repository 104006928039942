import { RULES_DATA } from "./action";

export const rulesTnp = (state = {}, action) => {
  switch (action.type) {
    case RULES_DATA:
      return { ...state, rulesData: action.payload };

    default:
      return state;
  }
};
