import {
  CITY_LIST,
  COMPANY_LISTING,
  JOB_DATA,
  JOB_DETAILS_SIDE_DATA,
  JOB_NAVBAR,
  JOB_ROLES_LIST,
  JOB_SEGMENT_LIST,
  PLACEMENT_JOB_TRACK,
  ROUNDS_LIST,
  SINGLE_JOB_DATA,
  SKILLS,
  DISABLE_FIELDS_POST_JOB,
  GROUP_COLLEGE_LIST,
  GROUP_JOB_DATA,
} from "./action";

export const AllCompany = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_LISTING:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const AllJobSegments = (state = {}, action) => {
  switch (action.type) {
    case JOB_SEGMENT_LIST:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};
export const AllSkills = (state = {}, action) => {
  switch (action.type) {
    case SKILLS:
      return { ...state, skills: action.payload };
    default:
      return state;
  }
};

export const AllJobRoles = (state = {}, action) => {
  switch (action.type) {
    case JOB_ROLES_LIST:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const AllLocations = (state = {}, action) => {
  switch (action.type) {
    case CITY_LIST:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const AllJobRounds = (state = {}, action) => {
  switch (action.type) {
    case ROUNDS_LIST:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const SingleJobData = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_JOB_DATA:
      return { ...state, details: action.payload };
    case JOB_DETAILS_SIDE_DATA:
      return { ...state, sideData: action.payload };
    default:
      return state;
  }
};

export const JobData = (state = {}, action) => {
  switch (action.type) {
    case JOB_DATA:
      return { ...state, details: action.payload };
    case JOB_NAVBAR:
      return { ...state, navbar: action.payload };

    default:
      return state;
  }
};

export const GruopJobData = (state = {}, action) => {
  switch (action.type) {
    case GROUP_JOB_DATA:
      return { ...state, details: action.payload };

    default:
      return state;
  }
};

export const JobPlacement = (state = {}, action) => {
  switch (action.type) {
    case PLACEMENT_JOB_TRACK:
      return { ...state, placementList: action.payload };
    default:
      return state;
  }
};

export const DisableFieldsPostJob = (state = {}, action) => {
  switch (action.type) {
    case DISABLE_FIELDS_POST_JOB:
      return { ...state, disabledFields: action.payload };
    default:
      return state;
  }
};

export const GroupCollegeList = (state = {}, action) => {
  switch (action.type) {
    case GROUP_COLLEGE_LIST:
      return { ...state, collegeList: action.payload };
    default:
      return state;
  }
};
