import {
  AppBar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  praveshAxiosGetReq,
  sanchalanAxiosGetReq,
} from "../../../api/BaseApi/apiBase";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import {
  collegeListSelectorFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import moment from "moment";

import {
  getAllColumnsStudentField,
  getColumns2StudentField,
  getColumnsStudentField,
  getColumns2HigherStudies,
} from "../../../utility/allColumns";
import { Link } from "react-router-dom";

import { truncate } from "../../../utility/ellipsify";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import InfoToolTip from "../../Common/ToolTips/InfoToolTip";
import StudentActionBar from "../../Input/StudentActionBar";
import CompanyTableNew from "../../Table/CompanyTableNew";
import OffersManageStudents from "../../DialogSection/OfferLetter/OffersManageStudents";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    color: "red",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    color: "red",
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function StudentTabNew({
  loading,
  filters,
  setFilters,
  setClickSearchButton,
  setSelectedRowsData,
  selectedRowsData,
  clickSearchButton,
  value,
  setValue,
  filterList,
  applyClickedScore,
  setApplyClickedScore,
  filterDataText,
  setFilterDataText,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  checkSelectedGroup = () => {},
  clearData = () => {},
  setSelectedID,
  selectedID,
  selectedColumnFields,
  setSelectedColumnFields = () => {},
  fromStudentSharer = false,
  authority,
  studentListFromSharer,
  callNavbarHigherStudies,
  setCallNavbarHigherStudies,
  backendDrivenFilter,
  setBackendDrivenFilter,
  filterData,
  filterLoading,
  studentList,
  setStudentList,
  studentListTotalCount,
  pageData,
  setPageData,
  setAddStudentClicked,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isGradeSystem = useSelector(isGradeSystemFn);
  const studentListFromRedux = useSelector(
    (state) => state?.StudentData?.details
  );
  const navbarData = useSelector((state) => state?.StudentData?.navbar);
  //   const [studentList, setStudentList] = useState([]);
  const [studentListTable, setStudentListTable] = useState([]);

  // const [pageData, setPageData] = useState({ currentPage: 0, pageSize: 20 });
  const collegeListSelector = useSelector(collegeListSelectorFn);

  const [tempStudentList, setTempStudentList] = useState([]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };
  const alert = useAlert();
  const [openOfferTable, setOpenOfferTable] = useState(false);
  const [studentId, setStudentId] = useState(null);

  const checkCollegeName = (id) => {
    var name = "";
    collegeListSelector.map((item) => {
      if (item?.id === id) name = item?.name;
    });
    return name;
  };

  const [applicationStatusData, setApplicationStatusData] =
    React.useState(null);
  const [applicationStatusLoading, setApplicationStatusLoading] =
    React.useState(false);

  const [educationDetialsData, setEducationDetialsData] = React.useState(null);
  const [educationDetialsLoading, setEducationDetialsLoading] =
    React.useState(true);

  const [loadingCandidateResume, setloadingCandidateResume] = useState(true);
  const [loadingGetResume, setLoadingGetResume] = useState(false);
  const [resumeLinks, setResumeLinks] = useState([]);
  const [CandidateId, setCandidateId] = useState(null);
  const [currentEducations, setCurrentEducations] = useState([]);
  const loader = loading;
  // ||
  // applicationStatusLoading ||
  // educationDetialsLoading ||
  // loadingGetResume
  //   ? true
  //   : false;

  const downloadResume = async (userId) => {
    const endPoint = `api/college/college-saas/update-download-student-resume/?student_id=${userId}`;
    setloadingCandidateResume(true);
    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        const college_resume = res?.data?.data?.link;
        const tempList = [...studentList];

        if (college_resume) {
          tempList?.map((item) => {
            if (item?.user_id === userId) {
              item.college_resume = college_resume;
            }
          });

          setStudentList(tempList);

          window.open(college_resume, "blank").focus();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloadingCandidateResume(false);
      setCandidateId(null);
    }
  };

  const checkForAll = () => {
    return filters?.current_tab?.params === "ALL" || !filters?.current_tab;
  };

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.params === "pending_students" ||
      filters?.current_tab?.params === "rejected_students"
    );
  };

  const checkForPlaced = () => {
    return filters?.current_tab?.params === "PLACED";
  };

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    if (!loading) {
      setFilters({ ...filters, status: newValue });
      setFilterDataText("No Data Found");
    }
  };

  //   useEffect(() => {
  //     if (navbarData?.length) {
  //       setFilters({ ...filters, status: "ALL" });
  //     }
  //   }, [navbarData?.length]);

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);
  //All Cloumns
  const allColumns = [
    // { field: "id", headerName: "No", width: 100 },
    {
      field: "course_college",
      headerName: "Current College",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "20px",
          }}
        >
          {`Current ${entityInfo.college}`}
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.current_college} placement="right">
                <Typography
                  color="inherit"
                  noWrap
                  style={{ fontSize: "14px", marginLeft: "5px" }}
                >
                  {params?.row?.current_college}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      hide: false,
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.enrollment_id
            ? params.row.enrollment_id
            : params.row.enrollment_no
            ? params.row.enrollment_no
            : "NA"}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.user_id ? (
              <Grid item xs zeroMinWidth>
                {/* <Link
                    to={`/student-profile/${params?.row?.name.replace(
                      / /g,
                      "_"
                    )}-${params?.row?.user_id}`}
                    style={{ textDecoration: "none", display: "contents" }}
                    state={{ name: "nikhil" }}
                  > */}{" "}
                <Link
                  to={`/student-profile/${params?.row?.name.replace(
                    / /g,
                    "_"
                  )}-${params?.row?.user_id}`}
                  target={"_self"}
                  style={{ textDecoration: "none", display: "contents" }}
                >
                  <Tooltip title={params?.row?.name} placement="right">
                    <Typography
                      color="primary"
                      noWrap
                      style={{ fontSize: "14px", cursor: "pointer" }}
                      // onClick={() => {
                      //   // history.push({
                      //   //   pathname: `/student-profile/${params?.row?.name.replace(
                      //   //     / /g,
                      //   //     "_"
                      //   //   )}-${params?.row?.user_id}`,
                      //   // });
                      //   window.open(
                      //     `/educational-group/student-profile/${params?.row?.name.replace(
                      //       / /g,
                      //       "_"
                      //     )}-${params?.row?.user_id}`,
                      //     "_self"
                      //   );
                      // }}
                    >
                      {params?.row?.name}
                    </Typography>
                  </Tooltip>
                </Link>
                {/* </Link> */}
              </Grid>
            ) : (
              <Grid item xs zeroMinWidth>
                {/*   <Link
                    to={`/student-profile/${params?.row?.name.replace(
                      / /g,
                      "_"
                    )}-${params?.row?.user_id}`}
                    style={{ textDecoration: "none", display: "contents" }}
                  > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      alert.error(
                        "Something wrong with this data, kindly connect with GetWork"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/* </Link> */}
              </Grid>
            )}
          </Grid>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "phone",
      headerName: "Phone Number",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.phone}
        </span>
      ),
      // valueGetter: params =>
      //   `${params.getValue(params.id, "firstName") || ""} ${
      //     params.getValue(params.id, "lastName") || ""
      //   }`,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Gender
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.gender ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.gender ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "last_login",
      // type: "text",
      headerName: "Last Login",
      width: 150,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "7px" }}>
          Last Login
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.education_info?.last_login
            ? moment(params?.row?.education_info?.last_login).format(
                "YYYY-MM-DD HH:mm"
              )
            : "NA"}
        </span>
      ),
    },
    {
      field: "eligible_count",
      headerName: "Eligible Count",
      type: "number",
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "2px" }}>Eligible</div>
          <InfoToolTip
            rejectedDate={""}
            label={
              "Eligibility is based on course, specialization, percentage, batch & placement cycle of posted jobs."
            }
          />
        </strong>
      ),
      renderCell: (params) => (
        <>
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.eligible_count ?? "..."}
          </span>
        </>
      ),
    },
    {
      field: "applied_count",
      headerName: "Applied Count",
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Applied
        </strong>
      ),
      renderCell: (params) => (
        <>
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.applied_count ?? "..."}
          </span>
        </>
      ),
    },
    {
      field: "present_count",
      headerName: "Present Count",
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Present
        </strong>
      ),
      renderCell: (params) => (
        <>
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.present_count ?? "..."}
          </span>
        </>
      ),
    },
    {
      field: "hired_count",
      headerName: "Hired Count",
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Hired
        </strong>
      ),
      renderCell: (params) => (
        <>
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.hired_count ?? "..."}
          </span>
        </>
      ),
    },

    {
      field: "batch",
      headerName: "Batch",
      hide: false,
      // type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.batch}
        </span>
      ),
    },
    {
      field: "degree",
      headerName: "Current Degree",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Current Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={
              params?.row?.degree?.name
                ? params?.row?.degree?.name
                : params?.row?.degree
                ? params?.row?.degree
                : "NA"
            }
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.degree?.name
                ? params?.row?.degree?.name
                : params?.row?.degree
                ? params?.row?.degree
                : "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "course",
      headerName: "Current Course",
      type: "text",
      hide: false,
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Current Course
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            //  margin: "auto",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={
              params?.row?.course?.name
                ? params?.row?.course?.name
                : params?.row?.course
                ? params?.row?.course
                : "NA"
            }
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.course?.name
                ? params?.row?.course?.name
                : params?.row?.course
                ? params?.row?.course
                : "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      headerName: isGradeSystem ? "Current CGPA" : " Current Percentage",
      width: 200,
      type: "text",
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          {isGradeSystem ? "Current CGPA" : " Current Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {isGradeSystem
            ? params?.row?.cgpa
              ? params?.row?.cgpa
              : "NA"
            : params?.row?.percentage
            ? params?.row?.percentage
            : "NA"}
        </span>
      ),
    },

    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(UG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.ugCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.ugCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization (UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.ugSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(UG)" : "Percentage(UG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "pgCollege",
      headerName: "College Name(pG)",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(PG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.pgCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.pgCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization (PG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.pgSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.pgSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(PG)" : "Percentage(PG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: true,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip
                title={params?.row?.phdCollege ?? "NA"}
                placement="right"
              >
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.phdCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: true,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.phdDegree ?? "NA")}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization (Phd)",
      hide: true,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.phdSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: true,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    // {
    //   field: "secondarySchool",
    //   headerName: " Secondary School",
    //   hide: false,
    //   type: "text",
    //   width: 180,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.secondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.secondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: true,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: true,
      type: "text",
      width: 240,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "Secondary CGPA" : "Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: false,
    //   type: "text",
    //   width: 220,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Senior Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.seniorSecondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.seniorSecondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: true,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Senior Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: "Senior Secondary Percentage",
      hide: true,
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem
            ? "Senior Secondary CGPA"
            : "Senior Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      hide: false,
      // type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "20px",
          }}
        >
          Status
        </strong>
      ),
      // renderCell: (params) => (
      //   <span
      //     style={{
      //       textAlign: "center",
      //       display: "flex",
      //       margin: "auto",
      //       fontSize: "14px",
      //     }}
      //   >
      //     {params.row.status}
      //   </span>
      // ),

      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "8px",
              marginRight: "10px",
              width: "8px",
              backgroundColor:
                params.row.status === "ACTIVE" ? "#51c03e" : "#f64a3f",
              borderRadius: "50%",
            }}
          />

          {params.row.status === "ACTIVE" ? "Active" : "Blocked"}
        </span>
      ),
    },
    // {
    //   field: "applied_jobs",
    //   headerName: "Applied Jobs",
    //   type: "text",
    //   width: 120,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "0px",
    //       }}
    //     >
    //       Applied Jobs
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {params.row.applied_jobs}
    //     </span>
    //   ),
    // },

    {
      field: "college_resume",
      headerName: "Resume",
      width: 180,
      type: "text",
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
            width: "100%",
          }}
        >
          {"Resume"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.college_resume ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                className={classes?.btn_view}
                variant="outlined"
                color="primary"
                size="small"
                disabled={
                  params?.row?.college_resume &&
                  params?.row?.college_resume !== "NA"
                    ? false
                    : true
                }
                style={{ width: "7rem", marginRight: ".25rem" }}
                onClick={() => {
                  if (
                    params?.row?.college_resume &&
                    params?.row?.college_resume !== "NA"
                  )
                    window.open(params?.row?.college_resume, "_blank");
                }}
              >
                View
              </Button>
            </div>
          ) : (
            //  : !fromStudentSharer && params.row.user_id ? (
            //   <Button
            //     className={classes?.btn_view}
            //     variant="outlined"
            //     color="primary"
            //     size="small"
            //     style={{ width: "7rem" }}
            //     onClick={() => {
            //       setCandidateId(params.row.user_id);
            //       downloadResume(params.row.user_id);
            //     }}
            //   >
            //     {loadingCandidateResume && CandidateId === params.row.user_id ? (
            //       <CustomButtonCircularProgress isWhite={false} />
            //     ) : (
            //       "Generate"
            //     )}
            //   </Button>
            // )
            "N/A"
          )}
        </span>
      ),
    },
  ];
  //PLACED
  const columns1 = [
    // { field: "id", headerName: "No", width: 100 },
    {
      field: "Offers",
      headerName: "Offers",
      width: 160,
      type: "text",
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
            // width:'10rem'
          }}
        >
          {"Offers"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Button
            className={classes?.btn_view}
            variant="outlined"
            color="primary"
            size="small"
            style={{ width: "5rem" }}
            onClick={() => {
              setOpenOfferTable(true);
              setStudentId(params.row.id);
            }}
          >
            View
          </Button>
        </span>
      ),
    },
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      hide: false,
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.enrollment_id}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.user_id ? (
              <Grid item xs zeroMinWidth>
                {/* <Link
                    to={`/student-profile/${params?.row?.name.replace(
                      / /g,
                      "_"
                    )}-${params?.row?.user_id}`}
                    style={{ textDecoration: "none", display: "contents" }}
                    state={{ name: "nikhil" }}
                  > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      // history.push({
                      //   pathname: `/student-profile/${params?.row?.name.replace(
                      //     / /g,
                      //     "_"
                      //   )}-${params?.row?.user_id}`,
                      // });
                      window.open(
                        `/educational-group/student-profile/${params?.row?.name.replace(
                          / /g,
                          "_"
                        )}-${params?.row?.user_id}`,
                        "_blank"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/* </Link> */}
              </Grid>
            ) : (
              <Grid item xs zeroMinWidth>
                {/*   <Link
                    to={`/student-profile/${params?.row?.name.replace(
                      / /g,
                      "_"
                    )}-${params?.row?.user_id}`}
                    style={{ textDecoration: "none", display: "contents" }}
                  > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      alert.error(
                        "Something wrong with this data, kindly connect with GetWork"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/* </Link> */}
              </Grid>
            )}
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     //  margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Link
        //     to={`/student-profile/${params?.row?.user_id}`}
        //     style={{ textDecoration: "none" }}
        //   >
        //     <Tooltip title={params?.row?.name} placement="right">
        //       <Typography color="primary" style={{ fontSize: "14px" }}>
        //         {params?.row?.name}
        //       </Typography>
        //     </Tooltip>
        //   </Link>
        // </span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     // margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Tooltip title={params?.row?.email} placement="right">
        //     <Typography color="inherit" style={{ fontSize: "14px" }}>
        //       {params?.row?.email}
        //     </Typography>
        //   </Tooltip>
        // </span>
      ),
    },

    {
      field: "phone",
      headerName: "Phone Number",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.phone}
        </span>
      ),
      // valueGetter: params =>
      //   `${params.getValue(params.id, "firstName") || ""} ${
      //     params.getValue(params.id, "lastName") || ""
      //   }`,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Gender
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.gender ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.gender ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "last_login",
      // type: "text",
      headerName: "Last Login",
      width: 150,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "7px" }}>
          Last Login
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.education_info?.last_login
            ? moment(params?.row?.education_info?.last_login).format(
                "YYYY-MM-DD HH:mm"
              )
            : "NA"}
        </span>
      ),
    },
    {
      field: "batch",
      headerName: "Batch",
      hide: false,
      // type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.batch}
        </span>
      ),
    },
    {
      field: "degree",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Current Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.degree?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.degree?.name}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "course",
      type: "text",
      hide: false,
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Current Course
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.course?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.course?.name}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      width: 200,
      type: "text",
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          {isGradeSystem ? "Current CGPA" : " Current Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {isGradeSystem ? params?.row?.cgpa : params?.row?.percentage}
        </span>
      ),
    },

    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(UG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.ugCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.ugCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization (UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.ugSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(UG)" : "Percentage(UG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "pgCollege",
      headerName: "College Name(pG)",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(PG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.pgCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.pgCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization (PG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.pgSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.pgSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(PG)" : "Percentage(PG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: true,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip
                title={params?.row?.phdCollege ?? "NA"}
                placement="right"
              >
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.phdCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: true,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.phdDegree ?? "NA")}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization (Phd)",
      hide: true,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.phdSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: true,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    // {
    //   field: "secondarySchool",
    //   headerName: " Secondary School",
    //   hide: true,
    //   type: "text",
    //   width: 180,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.secondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.secondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: true,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: true,
      type: "text",
      width: 240,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "Secondary CGPA" : "Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: true,
    //   type: "text",
    //   width: 220,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Senior Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.seniorSecondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.seniorSecondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: true,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Senior Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: "Senior Secondary Percentage",
      hide: true,
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem
            ? "Senior Secondary CGPA"
            : "Senior Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "applied_jobs",
      hide: false,
      headerName: "Applied Jobs",
      type: "text",
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "0px",
          }}
        >
          Applied Jobs
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.applied_jobs}
        </span>
      ),
    },
    {
      field: "status",
      hide: false,
      headerName: "Status",
      // type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "20px",
          }}
        >
          Status
        </strong>
      ),
      // renderCell: (params) => (
      //   <span
      //     style={{
      //       textAlign: "center",
      //       display: "flex",
      //       margin: "auto",
      //       fontSize: "14px",
      //     }}
      //   >
      //     {params.row.status}
      //   </span>
      // ),

      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "8px",
              marginRight: "10px",
              width: "8px",
              backgroundColor:
                params.row.status === "ACTIVE" ? "#51c03e" : "#f64a3f",
              borderRadius: "50%",
            }}
          />

          {params.row.status === "ACTIVE" ? "Active" : "Blocked"}
        </span>
      ),
    },
  ];
  //UNPLACED
  const columns3 = [
    // { field: "id", headerName: "No", width: 100 },
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      hide: false,
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.enrollment_id}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.user_id ? (
              <Grid item xs zeroMinWidth>
                {/* <Link
                    to={`/student-profile/${params?.row?.name.replace(
                      / /g,
                      "_"
                    )}-${params?.row?.user_id}`}
                    style={{ textDecoration: "none", display: "contents" }}
                    state={{ name: "nikhil" }}
                  > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      // history.push({
                      //   pathname: `/student-profile/${params?.row?.name.replace(
                      //     / /g,
                      //     "_"
                      //   )}-${params?.row?.user_id}`,
                      // });
                      window.open(
                        `/educational-group/student-profile/${params?.row?.name.replace(
                          / /g,
                          "_"
                        )}-${params?.row?.user_id}`,
                        "_blank"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/* </Link> */}
              </Grid>
            ) : (
              <Grid item xs zeroMinWidth>
                {/*   <Link
                    to={`/student-profile/${params?.row?.name.replace(
                      / /g,
                      "_"
                    )}-${params?.row?.user_id}`}
                    style={{ textDecoration: "none", display: "contents" }}
                  > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      alert.error(
                        "Something wrong with this data, kindly connect with GetWork"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/* </Link> */}
              </Grid>
            )}
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     //  margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Link
        //     to={`/student-profile/${params?.row?.user_id}`}
        //     style={{ textDecoration: "none" }}
        //   >
        //     <Tooltip title={params?.row?.name} placement="right">
        //       <Typography color="primary" style={{ fontSize: "14px" }}>
        //         {params?.row?.name}
        //       </Typography>
        //     </Tooltip>
        //   </Link>
        // </span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     // margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Tooltip title={params?.row?.email} placement="right">
        //     <Typography color="inherit" style={{ fontSize: "14px" }}>
        //       {params?.row?.email}
        //     </Typography>
        //   </Tooltip>
        // </span>
      ),
    },

    {
      field: "phone",
      headerName: "Phone Number",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.phone}
        </span>
      ),
      // valueGetter: params =>
      //   `${params.getValue(params.id, "firstName") || ""} ${
      //     params.getValue(params.id, "lastName") || ""
      //   }`,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Gender
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.gender ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.gender ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "last_login",
      // type: "text",
      headerName: "Last Login",
      width: 150,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "7px" }}>
          Last Login
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.education_info?.last_login
            ? moment(params?.row?.education_info?.last_login).format(
                "YYYY-MM-DD HH:mm"
              )
            : "NA"}
        </span>
      ),
    },
    {
      field: "batch",
      headerName: "Batch",
      hide: false,
      // type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.batch}
        </span>
      ),
    },
    {
      field: "degree",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.degree?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.degree?.name}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "course",
      type: "text",
      hide: false,
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Course
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.course?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.course?.name}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      width: 200,
      type: "text",
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          {isGradeSystem ? "Current CGPA" : " Current Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {isGradeSystem ? params?.row?.cgpa : params?.row?.percentage}
        </span>
      ),
    },

    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(UG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.ugCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.ugCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization (UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.ugSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(UG)" : "Percentage(UG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "pgCollege",
      headerName: "College Name(pG)",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(PG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.pgCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.pgCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization (PG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.pgSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.pgSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(PG)" : "Percentage(PG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: true,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip
                title={params?.row?.phdCollege ?? "NA"}
                placement="right"
              >
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.phdCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: true,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.phdDegree ?? "NA")}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization (Phd)",
      hide: true,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.phdSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: true,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    // {
    //   field: "secondarySchool",
    //   headerName: " Secondary School",
    //   hide: true,
    //   type: "text",
    //   width: 180,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.secondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.secondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: true,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: true,
      type: "text",
      width: 240,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "Secondary CGPA" : "Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: true,
    //   type: "text",
    //   width: 220,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Senior Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.seniorSecondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.seniorSecondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: true,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Senior Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: "Senior Secondary Percentage",
      hide: true,
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem
            ? "Senior Secondary CGPA"
            : "Senior Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "applied_jobs",
      hide: false,
      headerName: "Applied Jobs",
      type: "text",
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "0px",
          }}
        >
          Applied Jobs
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.applied_jobs}
        </span>
      ),
    },
    {
      field: "status",
      hide: false,
      headerName: "Status",
      // type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "20px",
          }}
        >
          Status
        </strong>
      ),
      // renderCell: (params) => (
      //   <span
      //     style={{
      //       textAlign: "center",
      //       display: "flex",
      //       margin: "auto",
      //       fontSize: "14px",
      //     }}
      //   >
      //     {params.row.status}
      //   </span>
      // ),

      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "8px",
              marginRight: "10px",
              width: "8px",
              backgroundColor:
                params.row.status === "ACTIVE" ? "#51c03e" : "#f64a3f",
              borderRadius: "50%",
            }}
          />

          {params.row.status === "ACTIVE" ? "Active" : "Blocked"}
        </span>
      ),
    },
  ];
  // PENDING AND REJECTED
  const columns2 = [
    // { field: "id", headerName: "No", width: 100 },
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      hide: false,
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.enrollment_id}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.user_id ? (
              <Grid item xs zeroMinWidth>
                <Link
                  to={`/student-profile/${params?.row?.name.replace(
                    / /g,
                    "_"
                  )}-${params?.row?.user_id}`}
                  target={`${
                    isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                  }`}
                  style={{ textDecoration: "none", display: "contents" }}
                >
                  <Tooltip title={params?.row?.name} placement="right">
                    <Typography
                      color="primary"
                      noWrap
                      style={{ fontSize: "14px", cursor: "pointer" }}
                      onClick={() => {
                        // history.push({
                        //   pathname: `/student-profile/${params?.row?.name.replace(
                        //     / /g,
                        //     "_"
                        //   )}-${params?.row?.user_id}`,
                        // });
                        // window.open(
                        //   `/educational-group/student-profile/${params?.row?.name.replace(
                        //     / /g,
                        //     "_"
                        //   )}-${params?.row?.user_id}`,
                        //   "_blank"
                        // );
                      }}
                    >
                      {params?.row?.name}
                    </Typography>
                  </Tooltip>
                </Link>
              </Grid>
            ) : (
              <Grid item xs zeroMinWidth>
                {/*   <Link
            to={`/student-profile/${params?.row?.name.replace(
              / /g,
              "_"
            )}-${params?.row?.user_id}`}
            style={{ textDecoration: "none", display: "contents" }}
          > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      alert.error(
                        "Something wrong with this data, kindly connect with GetWork"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/* </Link> */}
              </Grid>
            )}
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     //  margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Link
        //     to={`/student-profile/${params?.row?.user_id}`}
        //     style={{ textDecoration: "none" }}
        //   >
        //     <Tooltip title={params?.row?.name} placement="right">
        //       <Typography color="primary" style={{ fontSize: "14px" }}>
        //         {params?.row?.name}
        //       </Typography>
        //     </Tooltip>
        //   </Link>
        // </span>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "phone",
      headerName: "Phone Number",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.phone}
        </span>
      ),
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Gender
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.gender ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.gender ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "last_login",
      // type: "text",
      headerName: "Last Login",
      width: 150,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "7px" }}>
          Last Login
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.education_info?.last_login
            ? moment(params?.row?.education_info?.last_login).format(
                "YYYY-MM-DD HH:mm"
              )
            : "NA"}
        </span>
      ),
    },
    {
      field: "invite_status",
      headerName: "Status",
      hide: false,
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Status
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            fontWeight: "600",
            color:
              params.row.invite_status === "PENDING" ||
              params.row.invite_status === "CHANGE_PENDING"
                ? "#f64a3f" /* "#0B8A00" */
                : params.row.invite_status === "CHANGE_REQUESTED"
                ? "#ffcb1f"
                : "#f64a3f",
          }}
        >
          {params?.row?.invite_status?.split("_")?.join(" ")}
        </span>
      ),
    },

    {
      field: "reason_message",
      headerName: "Reason Message",
      hide: false,
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Reason Message
        </strong>
      ),
      renderCell: (params) => (
        <span
        // style={{
        //   textAlign: "center",
        //   display: "flex",
        //   margin: "auto",
        //   fontSize: "14px",
        // }}
        >
          {/* <Tooltip title={params?.row?.reason_message} placement="right">
              {params.row.invite_status === "CHANGE_REQUESTED" ||
              filters?.current_tab?.params === "rejected_students"
                ? params?.row?.reason_message ?? "NA"
                : "NA"}
            </Tooltip> */}
          <Tooltip
            title={
              params.row.invite_status === "CHANGE_REQUESTED" ||
              filters?.current_tab?.params === "rejected_students"
                ? params?.row?.reason_message ?? "NA"
                : "NA"
            }
            placement="right"
          >
            <Typography
              style={{
                textAlign: "center",
                display: "flex",
                margin: "auto",
                fontSize: "14px",
              }}
              noWrap
            >
              {params.row.invite_status === "CHANGE_REQUESTED" ||
              filters?.current_tab?.params === "rejected_students"
                ? params?.row?.reason_message ?? "NA"
                : "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "batch",
      headerName: "Batch",
      hide: false,
      // type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.batch}
        </span>
      ),
    },
    {
      field: "degree",
      hide: false,
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          Current Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.degree?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.degree?.name}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "course",
      type: "text",
      hide: false,
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          Current Course
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            //  margin: "auto",
            cursor: "pointer",
            width: "100%",
            justifyContent: "space-evenly",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.course?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.course?.name}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      width: 200,
      type: "text",
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          {isGradeSystem ? "Current CGPA" : " Current Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {isGradeSystem ? params?.row?.cgpa : params?.row?.percentage}
        </span>
      ),
    },

    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(UG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.ugCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.ugCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization (UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.ugSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(UG)" : "Percentage(UG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "pgCollege",
      headerName: "College Name(pG)",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(PG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.pgCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.pgCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization (PG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.pgSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.pgSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(PG)" : "Percentage(PG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: true,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip
                title={params?.row?.phdCollege ?? "NA"}
                placement="right"
              >
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.phdCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: true,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.phdDegree ?? "NA")}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization (Phd)",
      hide: true,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.phdSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: true,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: true,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: true,
      type: "text",
      width: 240,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "Secondary CGPA" : "Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: true,
    //   type: "text",
    //   width: 220,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Senior Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.seniorSecondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.seniorSecondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: true,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Senior Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: isGradeSystem
        ? "Senior Secondary CGPA"
        : "Senior Secondary Percentage",
      hide: true,
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem
            ? "Senior Secondary CGPA"
            : "Senior Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    // {
    //   field: "college_resume",
    //   width: 180,
    //   type: "text",

    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "25px",
    //         width: "100%",
    //       }}
    //     >
    //       {"Resume"}
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {
    //         // (params.row.user_id && params.row.college_resume)  ?

    //         <div style={{ display: "flex", alignItems: "center" }}>
    //           <Button
    //             className={classes?.btn_view}
    //             variant="outlined"
    //             color="primary"
    //             size="small"
    //             style={{ width: "7rem", marginRight: ".25rem" }}
    //             disabled={params.row.student_resume ? false : true}
    //             onClick={() => {
    //               if (params.row.student_resume) {
    //                 window.open(params.row.student_resume, "_blank");
    //               }
    //             }}
    //           >
    //             View
    //           </Button>
    //         </div>
    //       }
    //     </span>
    //   ),
    // },

    // {
    //   field: "college_resume",
    //   width: 180,
    //   type: "text",

    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "25px",
    //         width: "100%",
    //       }}
    //     >
    //       {"Resume"}
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {
    //         // (params.row.user_id && params.row.college_resume)  ?

    //         <div style={{ display: "flex", alignItems: "center" }}>
    //           <Button
    //             className={classes?.btn_view}
    //             variant="outlined"
    //             color="primary"
    //             size="small"
    //             style={{ width: "7rem", marginRight: ".25rem" }}
    //             disabled={params.row.student_resume ? false : true}
    //             onClick={() => {
    //               if (params.row.student_resume) {
    //                 window.open(params.row.student_resume, "_blank");
    //               }
    //             }}
    //           >
    //             View
    //           </Button>
    //         </div>
    //       }
    //     </span>
    //   ),
    // },
  ];

  const getCurrentColumns = () => {
    return checkForAll()
      ? allColumns
      : checkForPendingRejected()
      ? columns2
      : checkForPlaced()
      ? columns1
      : checkForUnPlaced()
      ? columns3
      : higherStudies;
  };

  const checkForUnPlaced = () => {
    return filters?.current_tab?.params === "UNPLACED";
  };

  const higherStudies = [
    // { field: "id", headerName: "No", width: 100 },
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      hide: false,
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.enrollment_id}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.user_id ? (
              <Grid item xs zeroMinWidth>
                {/* <Link
                    to={`/student-profile/${params?.row?.name.replace(
                      / /g,
                      "_"
                    )}-${params?.row?.user_id}`}
                    style={{ textDecoration: "none", display: "contents" }}
                    state={{ name: "nikhil" }}
                  > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      // history.push({
                      //   pathname: `/student-profile/${params?.row?.name.replace(
                      //     / /g,
                      //     "_"
                      //   )}-${params?.row?.user_id}`,
                      // });
                      window.open(
                        `/educational-group/student-profile/${params?.row?.name.replace(
                          / /g,
                          "_"
                        )}-${params?.row?.user_id}`,
                        "_blank"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/* </Link> */}
              </Grid>
            ) : (
              <Grid item xs zeroMinWidth>
                {/*   <Link
                    to={`/student-profile/${params?.row?.name.replace(
                      / /g,
                      "_"
                    )}-${params?.row?.user_id}`}
                    style={{ textDecoration: "none", display: "contents" }}
                  > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      alert.error(
                        "Something wrong with this data, kindly connect with GetWork"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/* </Link> */}
              </Grid>
            )}
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     //  margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Link
        //     to={`/student-profile/${params?.row?.user_id}`}
        //     style={{ textDecoration: "none" }}
        //   >
        //     <Tooltip title={params?.row?.name} placement="right">
        //       <Typography color="primary" style={{ fontSize: "14px" }}>
        //         {params?.row?.name}
        //       </Typography>
        //     </Tooltip>
        //   </Link>
        // </span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     // margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Tooltip title={params?.row?.email} placement="right">
        //     <Typography color="inherit" style={{ fontSize: "14px" }}>
        //       {params?.row?.email}
        //     </Typography>
        //   </Tooltip>
        // </span>
      ),
    },

    {
      field: "phone",
      headerName: "Phone Number",
      hide: false,
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.phone}
        </span>
      ),
      // valueGetter: params =>
      //   `${params.getValue(params.id, "firstName") || ""} ${
      //     params.getValue(params.id, "lastName") || ""
      //   }`,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Gender
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.gender ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.gender ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "last_login",
      // type: "text",
      headerName: "Last Login",
      width: 150,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "7px" }}>
          Last Login
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.education_info?.last_login
            ? moment(params?.row?.education_info?.last_login).format(
                "YYYY-MM-DD HH:mm"
              )
            : "NA"}
        </span>
      ),
    },
    {
      field: "batch",
      headerName: "Batch",
      hide: false,
      // type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.batch}
        </span>
      ),
    },
    {
      field: "degree",
      headerName: "Current Degree",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.degree?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.degree?.name}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "course",
      headerName: "Current course",
      type: "text",
      hide: false,
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Course
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.course?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.course?.name}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "percentage",
      headerName: "Current Percentage",
      width: 200,
      type: "text",
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Current Percentage
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.percentage}
        </span>
      ),
    },

    {
      field: "higherCollegeName",
      headerName: "College Name",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          College Name
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.college_name ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.college_name ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "higherUniveristyName",
      headerName: "University Name",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          University Name
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.university_name ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.university_name ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "state",
      headerName: "State",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          State
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.state ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.state ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Country
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.country ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.country ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "higherCollegeAddress",
      headerName: "College Address",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          College Address
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.college_address ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.college_address ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "higherDegree",
      headerName: "Higher Degree",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Higher Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.higher_studies_degree ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.higher_studies_degree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "specialization",
      headerName: "Specialization",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Specialization
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.specialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.specialization ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "qualifying_exam",
      headerName: "Qualifying Exam",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Qualifying Exam
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.qualify_exam ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.qualify_exam ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "courseStartDate",
      headerName: "Course Start Date",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Course Start Date
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.course_start_date ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.course_start_date ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "courseEndDate",
      headerName: "Course End Date",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Course End Date
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.course_end_date ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.course_end_date ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },

    {
      field: "status",
      hide: false,
      headerName: "Status",
      // type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "20px",
          }}
        >
          Status
        </strong>
      ),
      // renderCell: (params) => (
      //   <span
      //     style={{
      //       textAlign: "center",
      //       display: "flex",
      //       margin: "auto",
      //       fontSize: "14px",
      //     }}
      //   >
      //     {params.row.status}
      //   </span>
      // ),

      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "8px",
              marginRight: "10px",
              width: "8px",
              backgroundColor:
                params.row.status === "ACTIVE" ? "#51c03e" : "#f64a3f",
              borderRadius: "50%",
            }}
          />

          {params.row.status === "ACTIVE" ? "Active" : "Blocked"}
        </span>
      ),
    },
  ];

  const getCurrentFields = () => {
    return checkForAll()
      ? getAllColumnsStudentField(
          isEducationGroup,
          isCollegeGroup,
          collegeId,
          isGradeSystem,
          currentEducations
        )
      : checkForPendingRejected()
      ? getColumns2StudentField(
          isEducationGroup,
          isCollegeGroup,
          collegeId,
          isGradeSystem,
          currentEducations
        )
      : checkForHigherStudies()
      ? getColumns2HigherStudies()
      : getColumnsStudentField(
          isEducationGroup,
          isCollegeGroup,
          collegeId,
          isGradeSystem,
          currentEducations
        );
  };

  const checkForHigherStudies = () => {
    return filters?.current_tab?.params === "higher_studies";
  };
  const getCurrentFieldString = () => {
    return checkForAll()
      ? `allColumnsStudentField`
      : checkForPendingRejected()
      ? `columns2StudentField`
      : checkForHigherStudies()
      ? `higherStudiesStudentField`
      : `columnsStudentField`;
  };
  const [columns, setColumns] = useState(getCurrentColumns());
  useEffect(() => {
    setColumns(getCurrentColumns());
  }, [CandidateId, studentList]);

  const [shareColumns, setShareColumns] = useState(null);

  // useEffect(() => {
  //   if (shareColumns && columns && !fromStudentSharer) {
  //     let temp = [...columns];
  //     let fieldsToShare = [];
  //     shareColumns?.map((item, index) => {
  //       if (temp[index]) temp[index].hide = item.hide;
  //       if (!item?.hide) fieldsToShare.push(item?.headerName);
  //     });
  //     setSelectedColumnFields(fieldsToShare);
  //     setColumns(temp);
  //     localStorage?.setItem(
  //       `${getCurrentFieldString()}_column_fields`,
  //       JSON.stringify(shareColumns)
  //     );
  //   }
  // }, [shareColumns]);

  useEffect(() => {
    if (shareColumns && columns && !fromStudentSharer) {
      let temp = [...columns];
      let fieldsToShare = [];

      temp.forEach((column) => {
        const sharedColumn = shareColumns.find(
          (item) => item.field === column.field
        );

        if (sharedColumn) {
          column.hide = sharedColumn.hide;

          if (!sharedColumn.hide) {
            fieldsToShare.push(sharedColumn.headerName);
          }
        }
      });

      setSelectedColumnFields(fieldsToShare);
      setColumns(temp);

      localStorage?.setItem(
        `${getCurrentFieldString()}_column_fields`,
        JSON.stringify(shareColumns)
      );
    }
  }, [shareColumns]);

  useEffect(() => {
    if (filters?.current_tab?.params || !filters?.current_tab) {
      setColumns(getCurrentColumns());
      setStudentListTable([]);
      setShareColumns(
        localStorage.getItem(`${getCurrentFieldString()}_column_fields`) !==
          null
          ? JSON.parse(
              localStorage.getItem(`${getCurrentFieldString()}_column_fields`)
            )
          : getCurrentFields()
      );
    }
  }, [filters?.status]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={filters?.status}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="ant example"
        >
          {navbarData?.length > 0 &&
            navbarData?.map((item) => (
              <NewTab
                value={String(item.params)}
                label={`${item.heading}(${item.count})`}
                onClick={() => {
                  setFilters({
                    ...filters,
                    current_tab: item,
                    status: String(item.params),
                  });
                  clearData();
                  setFilterDataText("No Data Found");
                }}
                wrapped
                {...a11yProps(String(item.params))}
              />
            ))}
        </NewTabs>
      </AppBar>

      {
        <StudentActionBar
          filters={filters}
          setFilters={setFilters}
          setClickSearchButton={setClickSearchButton}
          selectedRowsData={selectedRowsData}
          clickSearchButton={clickSearchButton}
          setSelectedRowsData={setSelectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          filterList={filterList}
          studentList={studentList}
          setStudentList={setStudentList}
          setApplyClickedScore={setApplyClickedScore}
          collegeId={collegeId}
          userRolesPermission={userRolesPermission}
          setCallNavbar={setCallNavbar}
          checkSelectedGroup={checkSelectedGroup}
          checkForPendingRejected={checkForPendingRejected}
          tableColumns={shareColumns}
          setTableColumns={setShareColumns}
          callNavbarHigherStudies={callNavbarHigherStudies}
          setCallNavbarHigherStudies={setCallNavbarHigherStudies}
          studentListTable={studentListTable}
          setStudentListTable={setStudentListTable}
          loader={loader}
          backendDrivenFilter={backendDrivenFilter}
          setBackendDrivenFilter={setBackendDrivenFilter}
          filterData={filterData}
          filterLoading={filterLoading}
          loading={loader}
          setAddStudentClicked={setAddStudentClicked}
        />
      }

      <br />
      {navbarData?.length > 0 &&
        navbarData?.map((item, index) => {
          return (
            <TabPanel
              value={String(filters?.status)}
              index={String(item.params)}
            >
              <CompanyTableNew
                loading={
                  loading
                  // ||
                  // applicationStatusLoading ||
                  // educationDetialsLoading ||
                  // loadingGetResume
                  //   ? true
                  //   : false
                }
                list={
                  // groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
                  //   ? tempStudentList
                  //   : /*  studentList */ studentListTable
                  studentList
                }
                columns={columns}
                checkBoxSelection={
                  /* checkForRejected()  */ checkForPendingRejected()
                    ? false
                    : true
                }
                setSelectedRowsData={setSelectedRowsData}
                selectedRowData={selectedRowsData}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                filterDataText={filterDataText}
                pageData={pageData}
                setPageData={setPageData}
                companyListTotalCount={studentListTotalCount}
                setCompanyList={setStudentList}
              />
            </TabPanel>
          );
        })}

      {openOfferTable && (
        <OffersManageStudents
          open={openOfferTable}
          studentId={studentId}
          handleClose={() => {
            setOpenOfferTable(false);
          }}
        />
      )}
    </div>
  );
}
