import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  useTheme,
  TextField,
  makeStyles,
  Button,
  Switch,
  FormGroup,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import month1 from "../../assets/png/calendarIcons/month1.png";
import month2 from "../../assets/png/calendarIcons/month2.png";
import month3 from "../../assets/png/calendarIcons/month3.png";
import month4 from "../../assets/png/calendarIcons/month4.png";
import month5 from "../../assets/png/calendarIcons/month5.png";
import month6 from "../../assets/png/calendarIcons/month6.png";
import month7 from "../../assets/png/calendarIcons/month7.png";
import month8 from "../../assets/png/calendarIcons/month8.png";
import month9 from "../../assets/png/calendarIcons/month9.png";
import month10 from "../../assets/png/calendarIcons/month10.png";
import month11 from "../../assets/png/calendarIcons/month11.png";
import month12 from "../../assets/png/calendarIcons/month12.png";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { withStyles } from "@material-ui/core/styles";
import { BackendBaseApi } from "../../constants/constants";
import axios from "axios";
import { useAlert } from "react-alert";
import { MenuItem, Select } from "@mui/material";
import NoDataFunc from "../../components/NoDataSVG/index1";

const ComingTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#656464",
    color: "#fff",
  },
}))(Tooltip);

const DownloadSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

function DownloadReports() {
  const theme = useTheme();
  const classes = useStyles();
  const alert = useAlert();
  const [currentYear, setCurrentYear] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [month, setMonth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newsLetterCard, setNewsLetterCard] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [state, setState] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [updatedNewsCard, setUpdatedNewsCard] = useState(false);

  useEffect(() => {
    var currentDate = new Date();
    // Get the current year
    var Year = currentDate.getFullYear();
    // Get the current month (0-11, where 0 is January and 11 is December)
    var Month = currentDate.getMonth();
    // setCurrentMonth(months[Month])
    setCurrentYear("" + Year);
    setMonth(Month);
  }, []);


  useEffect(() => {
    if (currentYear) {
      let url = "";
      if (currentYear && currentMonth)
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/generate_news_letter/?year=${currentYear}&month=${currentMonth}`;
      else
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/generate_news_letter/?year=${currentYear}`;
      setLoading(true);
      axios
        .get(url, { withCredentials: true })
        .then((res) => {
          setLoading(false);
          if (res?.data?.success) {
            var currentDate = new Date();
            // Get the current year
            var Year = currentDate.getFullYear();
            // Get the current month (0-11, where 0 is January and 11 is December)
            var Month = currentDate.getMonth();


            let nums = allData?.map(item => ({ ...item, link: "" }))
            if (currentYear == Year) {

              nums = nums.slice(0, Month + 1)
              setMonth(Month);
            } else {
              setMonth(12)
            }
            res?.data?.data?.forEach((item) => {
              nums?.forEach((data, id) => {
                if (data?.title === item?.month) {
                  // nums[id]["link"] = item.news_letter_link;
                  nums[id]["link"] = item.news_letter_link;
                }
              });
            });
            setNewsLetterCard([...nums]);
          } else alert.error(res?.data?.error);
        })
        .catch((err) => {
          alert.error("something went wrong");
          setLoading(false);
        });
    }
  }, [currentYear, currentMonth]);



  const handleChange = (event) => {
    setState(event.target.checked);
  };

  const handleDownload = (title, index) => {
    if (currentYear && title) {
      setLoadingDownload(true);
      const news = [...newsLetterCard];
      news[index]["loading"] = true;
      setNewsLetterCard([...news]);
      axios
        .post(
          `${BackendBaseApi.SANCHALAN}api/placement_cycles/generate_news_letter/`,
          { year: currentYear, month: title },
          { withCredentials: true }
        )
        .then((res) => {
          setLoadingDownload(false);
          const nums = [...newsLetterCard];
          nums[index]["loading"] = false;

          if (res?.data?.success) {
            alert.success(res?.data?.data?.message);
            nums[index]["link"] = res?.data?.data?.news_letter_link;
          } else alert.error(res?.data?.error);

          setNewsLetterCard([...nums]);

        })
        .catch((err) => {
          // alert.error("something went wrong")
          setLoadingDownload(false);
          const nums = [...newsLetterCard];
          nums[index]["loading"] = false;
          setNewsLetterCard([...nums]);
        });
    }
  };


  const handleUpdate = (title, index) => {
    if (currentYear && title) {
      setLoadingUpdate(true);
      const news = [...newsLetterCard];
      news[index]["loading"] = true;
      setNewsLetterCard([...news]);
      axios
        .post(
          `${BackendBaseApi.SANCHALAN}api/placement_cycles/generate_news_letter/`,
          { year: currentYear, month: title },
          { withCredentials: true }
        )
        .then((res) => {
          setLoadingUpdate(false);
          const nums = [...newsLetterCard];
          nums[index]["loading"] = false;

          if (res?.data?.success) {
            alert.success(res?.data?.data?.message);

            nums[index]["link"] = res?.data?.data?.news_letter_link;

          } else alert.error(res?.data?.error);

          setNewsLetterCard([...nums]);

        })
        .catch((err) => {
          // alert.error("something went wrong")
          setLoadingUpdate(false);
          const nums = [...newsLetterCard];
          nums[index]["loading"] = false;
          setNewsLetterCard([...nums]);
        });
    }
  };


  const NewsLetter = ({ data, index }) => {
    return (
      <Grid item md={4}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            padding: 12,
            borderRadius: 8,
            height: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={data.img} alt="months-icon" />
            <Typography
              style={{
                marginLeft: 10,
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {data?.title} Newsletter
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {data?.title !== months[month] ? (
              !data?.link ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  disabled={loadingDownload || loadingUpdate}
                  onClick={() => handleDownload(data?.title, index)}
                >
                  {data?.loading ? <CircularProgress size={16} /> : "Generate"}
                </Button>
              ) : (
                <>
                  {(!loadingUpdate) && (<IconButton onClick={() => handleUpdate(data?.title, index)}>
                    <AutorenewIcon />
                  </IconButton>)}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={() => window.open(data?.link)}
                    // disabled={loadingUpdate}
                    disabled={data?.loading}
                  >
                    {data?.loading ? <CircularProgress size={16} /> : "View"}
                  </Button>
                </>
              )
            ) : (
              <ComingTooltip
                title={
                  <Typography style={{ fontSize: 14, color: "#fff" }}>
                    It will be updated at the end
                    <br /> of the month
                  </Typography>
                }
              >
                <Typography
                  style={{
                    fontSize: 16,
                    color: "#EE2C3C",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  Coming Soon
                </Typography>
              </ComingTooltip>
            )}
          </div>
        </div>
      </Grid>
    );
  };



  const getNodata = () => {
    if (state) {
      const res = newsLetterCard?.map((data, index) => {
        if (data?.link && data?.title === currentMonth)
          return 1
        else if (data?.link && !currentMonth) return 1;
        return 0;
      })
      const final = res.find(data => data === 1)
      return !final ? (<>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <NoDataFunc width={"300Px"} height={"200px"} />
          <Typography style={{ fontSize: "16px" }}>
            {" "}
            No Data Available
          </Typography>{" "}
        </div>
      </>) : (<></>)
    }
  }

  const showNoData = () => {

    var currentDate = new Date();
    var Year = currentDate.getFullYear();

    let index = -1;

    months.forEach((item, id) => {
      if (item === currentMonth) index = id;
    })

    if (Year == currentYear) {
      if (index > month) {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <NoDataFunc width={"300Px"} height={"200px"} />
              <Typography style={{ fontSize: "16px" }}>
                {" "}
                No Data Available
              </Typography>{" "}
            </div>
          </>
        )
      }
    }

  }


  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/report" style={{ textDecoration: "none" }}>
          <Typography color="primary" variant="body2">
            Reports
          </Typography>
        </Link>
        <Link style={{ textDecoration: "none" }} color="inherit">
          <Typography color="secondary" variant="body2">
            Download Reports
          </Typography>
        </Link>
      </Breadcrumbs>

      <div
        style={{
          marginTop: 15,
          borderBottom: "1px solid #CFCFCF",
        }}
      >
        <Typography
          variant="h5"
          style={{
            borderColor: theme.palette.primary.main,
            borderBottom: "1px solid",
            width: "fit-content",
            paddingTop: 12,
            paddingBottom: 12,
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          Newsletter
        </Typography>
      </div>
      <div
        style={{
          marginTop: 15,
          borderBottom: "1px solid #CFCFCF",
          paddingBottom: 15,
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Autocomplete
              options={months}
              getOptionLabel={(option) => option}
              fullwidth
              value={currentMonth}
              onChange={(e, val) => {
                setCurrentMonth(val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Month"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={3}>
            <Autocomplete
              options={year}
              value={currentYear}
              disableClearable
              onChange={(e, val) => {
                setCurrentYear(val);
              }}
              getOptionLabel={(option) => option}
              fullwidth
              onKeyDown={(e) => { e.preventDefault(); }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Year"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />



          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <FormGroup>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item style={{ fontSize: 16, fontWeight: 600 }}>
                      {" "}
                      Downloaded Newsletter Only
                    </Grid>
                    <Grid item>
                      <DownloadSwitch
                        checked={state}
                        onChange={handleChange}
                        name="checkedC"
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </FormGroup>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 20 }}>
        <Grid container spacing={2}>
          {!state ? (
            loading ? (
              <>
                {newsLetterCard?.map(() => (
                  <Grid item md={4}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height={70}
                      style={{ borderRadius: 8 }}
                    />
                  </Grid>
                ))}
              </>
            ) : currentMonth ? (
              <>
                {newsLetterCard?.map((data, index) => {
                  if (currentMonth === data?.title) return <NewsLetter data={data} index={index} />;
                  else return <></>;
                })}
                {showNoData()}
              </>
            ) : (
              <>
                {newsLetterCard?.map((data, index) => {
                  if (index <= month) return <NewsLetter data={data} index={index} />;
                  else return <></>;
                })}
              </>
            )
          ) : (
            <>
              {newsLetterCard?.map((data, index) => {
                if (data?.link && data?.title === currentMonth)
                  return <NewsLetter data={data} />;
                else if (data?.link && !currentMonth)
                  return <NewsLetter data={data} index={index} />;
                else return <></>;
              })}
              {getNodata()}
            </>
          )}


        </Grid>
      </div>
    </div>
  );
}

export default DownloadReports;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const year = ["2020", "2021", "2022", "2023", "2024"];

const allData = [
  {
    img: month1,
    title: "January",
    loading: false,
  },
  {
    img: month2,
    title: "February",
    loading: false,
  },
  {
    img: month3,
    title: "March",
    loading: false,
  },
  {
    img: month4,
    title: "April",
    loading: false,
  },
  {
    img: month5,
    title: "May",
    loading: false,
  },
  {
    img: month6,
    title: "June",
    loading: false,
  },
  {
    img: month7,
    title: "July",
    loading: false,
  },
  {
    img: month8,
    title: "August",
    loading: false,
  },
  {
    img: month9,
    title: "September",
    loading: false,
  },
  {
    img: month10,
    title: "October",
    loading: false,
  },
  {
    img: month11,
    title: "November",
    loading: false,
  },
  {
    img: month12,
    title: "December",
    loading: false,
  },
];
