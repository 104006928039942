import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { isEducationGroupFn } from "../../api/Domain/selector";
import { HelpData, QuestionData } from "../../api/Help/action";
import { BackendBaseApi } from "../../constants/constants";
import SearchFaqs from "./Search";

const useStyles = makeStyles((_) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  paper: {
    padding: "30px",
    paddingBottom: "10px",
    borderRadius: "8px",
    boxShadow: "none",
    // height: "270px",
  },
  email: {
    padding: "30px",
    boxShadow: "none",
    borderRadius: "8px",
  },
  img: {
    borderRadius: "8px",
    marginLeft: "35px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  loader: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translateX(-50%)",
  },
}));

export default function Question() {
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const new_param = params.question_id + location.search;
  var split_id = new_param.split("-");
  console.log(split_id);
  var question_id = split_id[split_id?.length - 1];
  // const [suggestionsData, setSuggestionsData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [data, setData] = useState(null);
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    dispatch(QuestionData(alert, history, location, question_id));
  }, [question_id]);

  const questionData = useSelector((state) => state?.Questions?.details);
  const isEducationGroup = useSelector(isEducationGroupFn);

  useEffect(() => {
    setLoader(true);
    dispatch(HelpData(alert, history, location, isEducationGroup));
  }, []);

  const helpData = useSelector((state) => state?.Helps?.details);

  // useEffect(() => {
  //   setLoader(true)
  //   if (helpData) {
  //     const temp2 = [];
  //     setSuggestionsData([]);
  //     helpData.map(item => {
  //       item.how_to_use_saas.map(item2 => {
  //         temp2.push({ id: item2.id, name: item2.Question.slice(2) });
  //       });
  //     });
  //     setSuggestionsData(temp2);
  //     setLoader(false);
  //   }
  // }, [helpData]);

  useEffect(() => {
    if (helpData && questionData) {
      helpData?.map((item) => {
        if (
          item.how_to_use_saas.length > 0 &&
          item.Group_Name === questionData?.help_support_group?.Group_Name
        ) {
          setData(item.how_to_use_saas);
        }
      });
      setLoader(false);
    }
  }, [questionData, helpData]);

  const formatResult = (item) => {
    return item;
  };

  // const handleOnSelect = item => {
  //   let path = `/questions/${item.id}`;
  //   history.push(path);
  // };

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to={isEducationGroup ? "/educational-group/help" : "/help"}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" variant="body2">
            Help & Support
          </Typography>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={`/group_FAQs/${questionData?.help_support_group.id}`}
        >
          <Typography color="primary" variant="body2">
            {questionData?.help_support_group.Group_Name}
          </Typography>
        </Link>
        {/* <Link
          style={{ textDecoration: "none" }}
          href="/getting-started/installation/"
        > */}
        <Typography color="secondary" variant="body2">
          {questionData?.Question}
        </Typography>
        {/* </Link> */}
      </Breadcrumbs>
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ marginTop: "17px", marginBottom: "15px" }}>
          <Typography variant="h2">GetWork Help & Support Centre.</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            marginTop: "17px",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          <SearchFaqs />
        </Grid>
        <br />

        {!loader && questionData ? (
          <Grid item xs={8}>
            {/* {Data.map(item => ( */}
            <Paper className={classes.paper}>
              <Typography variant="h4">
                {/* <FiberManualRecordRoundedIcon style={{ marginRight: "10px" }} color="secondary" /> */}
                {questionData.Question}
              </Typography>

              <div style={{ padding: "20px", paddingBottom: "0px" }}>
                {questionData?.is_education_group && isEducationGroup && (
                  <>
                    {questionData.education_group_content ? (
                      <Typography
                        variant="body1"
                        style={{ color: "#000", fontSize: "15px" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: questionData.education_group_content,
                          }}
                        />
                      </Typography>
                    ) : (
                      <></>
                    )}
                    {questionData.education_group_media[0] ? (
                      <img
                        src={`${BackendBaseApi.CMS}${questionData.education_group_media[0]?.url}`}
                        width="100%"
                        className={classes.img}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {questionData.step1 ? (
                  <Typography
                    variant="body1"
                    style={{ color: "#000", fontSize: "15px" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: questionData.step1 }}
                    />
                  </Typography>
                ) : (
                  <></>
                )}
                {questionData.step1_ss[0] ? (
                  <img
                    src={`${BackendBaseApi.CMS}${questionData.step1_ss[0]?.url}`}
                    width="100%"
                    className={classes.img}
                  />
                ) : (
                  <></>
                )}
                {questionData.step2 ? (
                  <Typography
                    variant="body1"
                    style={{ color: "#000", fontSize: "15px" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: questionData.step2 }}
                    />
                  </Typography>
                ) : (
                  <></>
                )}
                {questionData.step2_ss[0] ? (
                  <img
                    src={`${BackendBaseApi.CMS}${questionData.step2_ss[0]?.url}`}
                    width="100%"
                    className={classes.img}
                  />
                ) : (
                  <></>
                )}

                <br />
                {questionData.step3 ? (
                  <Typography
                    variant="body1"
                    style={{ color: "#000", fontSize: "15px" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: questionData.step3 }}
                    />
                  </Typography>
                ) : (
                  <></>
                )}
                {questionData.step3_ss[0] ? (
                  <img
                    src={`${BackendBaseApi.CMS}${questionData.step3_ss[0]?.url}`}
                    width="100%"
                    className={classes.img}
                  />
                ) : (
                  <></>
                )}
                <br />
                {questionData.step4 ? (
                  <Typography
                    variant="body1"
                    style={{ color: "#000", fontSize: "15px" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: questionData.step4 }}
                    />
                  </Typography>
                ) : (
                  <></>
                )}
                {questionData.step4_ss[0] ? (
                  <img
                    src={`${BackendBaseApi.CMS}${questionData.step4_ss[0]?.url}`}
                    width="100%"
                    className={classes.img}
                  />
                ) : (
                  <></>
                )}
                <br />
                {questionData.step5 ? (
                  <Typography
                    variant="body1"
                    style={{ color: "#000", fontSize: "15px" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: questionData.step5 }}
                    />
                  </Typography>
                ) : (
                  <></>
                )}
                {questionData.step5_ss[0] ? (
                  <img
                    src={`${BackendBaseApi.CMS}${questionData.step5_ss[0]?.url}`}
                    width="100%"
                    className={classes.img}
                  />
                ) : (
                  <></>
                )}

                {/* <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FiberManualRecord style={{ fontSize: "15px" }} />
                    <Typography variant="body2" style={{ marginLeft: "12px" }}>
                      Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                      seed dam nonarmy.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ display: "flex" }}>
                    <FiberManualRecord style={{ fontSize: "15px" }} />
                    <Typography variant="body2" style={{ marginLeft: "12px" }}>
                      Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                      seed dam nonarmy.
                    </Typography>
                  </Grid>
                  <img src={poster} height="300px" className={classes.img} />

                  <Grid item xs={12} style={{ display: "flex" }}>
                    <FiberManualRecord style={{ fontSize: "15px" }} />
                    <Typography variant="body2" style={{ marginLeft: "12px" }}>
                      Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                      seed dam nonarmy.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ display: "flex" }}>
                    <FiberManualRecord style={{ fontSize: "15px" }} />
                    <Typography variant="body2" style={{ marginLeft: "12px" }}>
                      Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                      seed dam nonarmy.
                    </Typography>
                  </Grid>
                </Grid> */}
              </div>
            </Paper>
            {/* <Link to="/help" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: "30px",
                    boxShadow: "none",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    height: "40px",
                    display: "flex",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Back
                </Button>
              </Link> */}
          </Grid>
        ) : (
          <div className={classes.loader}>
            <CircularProgress size={60} />
          </div>
        )}

        {!loader ? (
          <Grid item xs={4}>
            <div>
              <Typography variant="h5">Related FAQs</Typography>
              {data
                ? data.map((line) =>
                    parseInt(question_id) !== line.id ? (
                      <Link
                        to={
                          isEducationGroup
                            ? `/educational-group/questions/${line.Question?.toLowerCase()?.replace(
                                / /g,
                                "-"
                              )}-${line.id}`
                            : `/questions/${line.Question?.toLowerCase()?.replace(
                                / /g,
                                "-"
                              )}-${line.id}`
                        }
                        style={{ textDecoration: "none" }}
                        key={line.id}
                      >
                        <Button
                          // startIcon={<SubjectIcon />}
                          color="primary"
                          variant="text"
                          style={{
                            display: "flex",
                            padding: "0px",
                            textAlign: "left",
                            marginLeft: "-4px",
                            marginTop: "10px",
                            // color: "#1d7ddd",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <SubjectIcon style={{ marginRight: "10px" }} />
                            {line.Question}
                          </div>
                        </Button>
                      </Link>
                    ) : null
                  )
                : null}
            </div>
          </Grid>
        ) : (
          <div className={classes.loader}>
            <CircularProgress size={60} />
          </div>
        )}
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">Not finding the help you need?</Typography>
          <br />
          <Link
            to={isEducationGroup ? `/educational-group/contact-us` : `/contact`}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{
                boxShadow: "none",
                borderRadius: "8px",
                height: "40px",
                display: "flex",
              }}
            >
              Contact us
            </Button>
          </Link>
        </Grid>
      </Grid>
      {/* </Layout> */}
    </>
  );
}
