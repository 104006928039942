import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
  IconButton,
  Typography,
  Accordion,
  AccordionDetails,
  Chip,
  AccordionSummary,
  Paper,
  MenuItem,
  InputBase,
  Divider,
  Button,
  Menu,
  Grid,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentTable from "../../Table/AssignmentTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { deleteAssignment } from "../../../api/Assignment/action";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import pSBC from "shade-blend-color";
import NoDataFunc from "../../NoDataSVG/index1";
import ReactHtmlParser from "react-html-parser";
import { userRolesActionsFn } from "../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    marginTop: "20px",
    marginBottom: "30px",
  },

  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  link: {
    boxShadow: "none",
    border: " 1px solid",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    borderColor: pSBC(0.8, theme.palette.primary.main),
  },

  title: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },

  labellink: {
    color: theme.palette.primary.main,
    padding: "16px",
    // width: "300px",
  },
  open: {
    backgroundColor: "#38cb89",
    padding: "5px",
    borderRadius: "8px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
}));

const StyledMenu = withStyles({
  paper: {
    //border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "10px",
    display: "flex",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

export default function OpenAssignment({ data }) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDelete = (item) => {
    dispatch(deleteAssignment(alert, history, location, item.id, data));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <div>
      {data?.length >= 1 ? (
        data?.map((item) => {
          return (
            <div className={classes.paper}>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <div
                      onClick={() => history.push(`job/${item?.job_id}`)}
                      className={classes.text}
                    >
                      <Typography variant="h6">
                        {item?.job_title} - {item?.job_type}
                      </Typography>
                      {/* <div
                        style={{
                          backgroundColor: "#000",
                          borderRadius: "5px",
                          height: "34px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography style={{ fontSize: "14px", color: "#fff" }}>
                          Getwork Fulfilled
                        </Typography>
                      </div> */}
                      <div
                        onClick={() =>
                          history.push(`/company-profile/${item?.company_id}`)
                        }
                        className={classes.title}
                      >
                        <Typography variant="body2">
                          {item?.company_name}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div className={classes.text}>
                      <div className={classes.open}>
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "14px",
                            display: "contents",
                          }}
                        >
                          {item?.assignment_status}
                        </Typography>
                      </div>
                    </div>

                    <div className={classes.text}>
                      {/* <Button
                      onClick={() => handleDelete(item)}
                      endIcon={<GetAppIcon />}
                      color="primary"
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Delete
                    </Button> */}
                      {userRolesPermission?.some(
                        (item) =>
                          item.access_control_key === "manage_assignment"
                      ) && (
                        <div>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <StyledMenuItem>
                              <Button
                                onClick={() => handleDelete(item)}
                                style={{
                                  display: "flex",
                                  // marginTop: "10px",
                                  // marginBottom: "10px",
                                  color: "#000",
                                }}
                              >
                                {" "}
                                Delete
                              </Button>
                            </StyledMenuItem>
                          </StyledMenu>
                        </div>
                      )}
                    </div>
                  </Grid>
                  {/* <Grid item xs>
                  <div className={classes.text}>
                    <Button
                      onClick={() => handleDelete(item)}
                      endIcon={<GetAppIcon />}
                      color="primary"
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Delete
                    </Button>
                  </div>
                </Grid> */}
                </Grid>
              </div>
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Typography variant="h6">{item?.assignment_title}</Typography>
              </div>
              <Typography variant="body2" style={{ width: "90%" }}>
                {ReactHtmlParser(item?.description)}
              </Typography>
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                {item?.assignment_link && (
                  <Grid item xs={4}>
                    <Paper component="form" className={classes.link}>
                      <InputBase
                        className={classes.input}
                        value={item?.assignment_link}
                        disabled
                        placeholder="https://getwork-ejd-mba.in"
                        inputProps={{
                          className: classes.labellink,
                          "aria-label": "https://getwork-ejd-mba.in",
                        }}
                      />
                      <CopyToClipboard
                        text={item?.assignment_link}
                        onCopy={() => alert.success("Link Copied!")}
                      >
                        <IconButton
                          // type="copy"
                          className={classes.iconButton}
                          aria-label="copy"
                        >
                          <FileCopyOutlinedIcon color="primary" />
                        </IconButton>
                      </CopyToClipboard>
                    </Paper>
                  </Grid>
                )}
                {item?.assignment_file ? (
                  <Grid
                    item
                    xs={6}
                    style={
                      item?.assignment_link
                        ? { display: "flex", justifyContent: "flex-end" }
                        : {}
                    }
                  >
                    <a
                      href={item?.assignment_file}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        endIcon={<GetAppIcon />}
                        color="primary"
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {" "}
                        Assignment file
                      </Button>
                    </a>
                  </Grid>
                ) : null}
              </Grid>

              <Grid container spacing={1} alignItems="center" marginTop="30px">
                <Grid item xs={2}>
                  <Typography variant="h6">Assignment Deadline</Typography>
                </Grid>
                <Grid item xs className={classes.row}>
                  <div style={{ display: "contents" }}>
                    <EventOutlinedIcon
                      style={{ color: "#7a7e83", marginRight: "10px" }}
                    />
                    <Typography style={{ fontSize: "14px", color: "#7a7e83" }}>
                      {moment(item?.assignment_deadline).format("LL")}
                    </Typography>
                  </div>
                  <div style={{ display: "contents" }}>
                    <AccessTimeIcon
                      style={{
                        color: "#7a7e83",
                        marginRight: "10px",
                        marginLeft: "50px",
                      }}
                    />
                    <Typography style={{ fontSize: "14px", color: "#7a7e83" }}>
                      {moment(item?.assignment_deadline).format("LT")}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                alignItems="center"
                style={{ marginTop: "15px", marginBottom: "15px" }}
              >
                <Grid item xs={2}>
                  <Typography variant="h6">Assignment Send To</Typography>
                </Grid>
                <Grid item xs className={classes.row}>
                  {item?.round_list.map((item) => {
                    // return <Typography> {item.name}</Typography>;
                    return (
                      <Chip
                        variant="outlined"
                        size="small"
                        label={item.name}
                        // label={
                        //   item.name
                        //     ? item + " / " + item.name
                        //     : item + " / " + item.round_list
                        // }
                        className={classes.chip}
                      />
                    );
                  })}
                </Grid>
              </Grid>

              <Accordion
                style={{
                  boxShadow: "none",
                }}
                classes={{
                  root: classes.MuiAccordionroot,
                }}
                // classes={{
                //   root: classes.test,
                // }}
              >
                <AccordionSummary
                  style={{ padding: "0px" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5">Assignment Status</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <div style={{ width: "100%" }}>
                    <AssignmentTable data={item?.student_assignment} />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : (
        <NoDataFunc title={"No Assignment Found"} />
      )}
    </div>
  );
}
