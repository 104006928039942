import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((_) => ({
  second: {
    background: "#c3e6cb",
    borderRadius: "8px",
    padding: "10px",
    color: "red",
    boxShadow: "none",
    position: "relative",
    // borderColor: theme.palette.primary.main,
    //  border: "2px solid",
    top: "57px",
    "&:after": {
      content: '" "',
      position: "absolute",

      right: "-10px",
      top: "20px",
      borderTop: "10px solid transparent",
      borderRight: "none",
      //borderColor: theme.palette.primary.main,
      borderLeft: "10px solid #c3e6cb",
      borderBottom: "10px solid transparent",
    },
  },
  std: {
    marginLeft: "20px",
    boxShadow: "none",
    borderRadius: "8px",
    display: "flex",
    height: "40px",
  },
}));

export default function InviteStudents({ inviteLink }) {
  
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  // const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  // const handleClose = () => {
  //   setState({ ...state, open: false });
  // };

  const buttons = (
    <React.Fragment>
      <CopyToClipboard text={inviteLink} onCopy={() => 
          {
            if(licenceData && licenceData?.display ){

             alert.error(licenceData?.display_message);
             return
            }

            inviteLink ? alert.success("Link Copied!")
            : alert.error("Please Add Fields from Student ERF to generate your Link")

          }
        
        }>
        <Button
          className={classes.std}
          startIcon={<SendIcon />}
          color="primary"
          variant="contained"
          onClick={
            //   handleClick({
            //   vertical: "top",
            //   horizontal: "right",
            // })
            () => {
              if (!inviteLink) {
                history.push("/student-erf");
              }
            }
          }
        >
          Invite Student
        </Button>
      </CopyToClipboard>
    </React.Fragment>
  );

  return (
    <>
      <div>
        {buttons}
        {/* <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} onClose={handleClose} key={vertical + horizontal}>
          <Alert severity="false" className={classes.second}>
            <Typography variant="body2" style={{ color: "#155724" }}>
              Student Invite Link Copied
            </Typography>
          </Alert>
        </Snackbar> */}
      </div>
    </>
  );
}
