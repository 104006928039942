import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Divider, Typography, Chip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    marginBottom: "15px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    paddingLeft: "0px",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",

  },
}));

export default function SaveLanguages({ langDetail, langIndex, handleDelete }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            {langDetail.name}
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton color="primary" onClick={() => handleDelete(langIndex)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
          }}
        >
          Proficiency: {langDetail.proficiency}
        </Typography>

        <div className={classes.root}>
          {langDetail &&
            langDetail.level.map((data, i) => {
              return (
                <li key={i}>
                  <Chip
                    color="primary"
                    deleteIcon={<CloseIcon color="primary" />}
                    variant="outlined"
                    size="small"
                    label={data}
                    className={classes.chip}
                  />
                </li>
              );
            })}
        </div>
      </div>
    </>
  );
}
