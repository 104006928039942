import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Chip, Typography } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import "./form.css";
import pSBC from "shade-blend-color";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  chip: {
    borderRadius: "8px",
  },
}));

export default function RightDropDown({
  index,
  placementCycles,
  setCollegesList,
  error,
  disable,
  setSearchState,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [selectedCycles, setSelectedCycles] = useState([]);
  useEffect(() => {
    setSelectedCycles(placementCycles.filter((option) => option.checked));
  }, [placementCycles]);

  const updatePlacementCycles = (updatedCycles) => {
    setCollegesList((prev) => {
      const newCollegesList = [...prev];

      newCollegesList[index] = {
        ...newCollegesList[index],
        placementCycles: updatedCycles,
        error: false,
      };

      return newCollegesList;
    });

    const selectedOptions = updatedCycles.filter((cycle) => cycle.checked);
    setSelectedCycles(selectedOptions);

    setSearchState((prevSearchState) => {
      const newSearchState = [...prevSearchState];

      newSearchState[index] = {
        ...newSearchState[index],
        placementCycles: updatedCycles,
        error: false,
      };

      return newSearchState;
    });
  };

  const handleDelete = (option) => {
    const updatedCycles = placementCycles.map((cycle) =>
      cycle.id === option.id ? { ...cycle, checked: false } : cycle
    );
    updatePlacementCycles(updatedCycles);
  };

  const handleChange = (newValue) => {
    const updatedCycles = placementCycles.map((cycle) => ({
      ...cycle,
      checked: newValue.some((selected) => selected.id === cycle.id),
    }));
    updatePlacementCycles(updatedCycles);
  };

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        limitTags={3}
        options={placementCycles}
        disabled={disable}
        value={selectedCycles}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id}
              className={classes.chip}
              disabled={disable}
              size="medium"
              color="primary"
              label={
                <Typography style={{ fontSize: "14px" }} color="primary">
                  {option?.name}
                </Typography>
              }
              style={{
                backgroundColor: pSBC(0.8, theme.palette.primary.main),
                border: `solid 0.5px ${theme.palette.primary.main}`,
                marginRight: 5,
                marginTop: 2,
                marginBottom: 2,
              }}
              deleteIcon={
                <CloseRoundedIcon
                  style={{ color: theme.palette.primary.main }}
                />
              }
              onDelete={() => {
                handleDelete(option);
              }}
            />
          ))
        }
        onChange={(event, newValue) => handleChange(newValue)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              color="primary"
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={option.checked}
            />
            {option?.name}
          </React.Fragment>
        )}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label="Select Placement Cycle"
            placeholder="Select Placement Cycle"
            error={error}
            helperText={error ? "Select at least one placement cycle" : ""}
          />
        )}
      />
    </>
  );
}
