import { Button, Chip, Dialog, Typography, TextField } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { ApproveTeamAPI } from "../../../api/TeamManage/action";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  button: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

  },
}));

export default function AcceptTeam({ deleteUser, selectedRowsData, setSelectedRowsData, selectedID, setSelectedID, fromTeams, collegeId, setCallNavbar }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [buttonName, setButtonName] = useState("");
  const [buttonNameLower, setButtonNameLower] = useState("");
  const [deleteReason, setDeleteReason] = useState("");
  const [deleteReasonError, setDeleteReasonError] = useState("");
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [trigger, setTrigger] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseNew = () => {
    setOpen(false);
    setSelectedRowsData([]);
  };

  useEffect(() => {
    if (deleteUser) {
      setButtonName("Accept Team Member");
      setButtonNameLower("accept");
      setStatus("ACCEPT");
    } else {
      setButtonName("Reject Team Member");
      setButtonNameLower("reject");
      setStatus("REJECT");
    }
  }, [selectedRowsData?.length, open]);
  // const companyList = useSelector((state) => state?.CompanyData?.details);
  const companyList = useSelector((state) => state?.TeamData?.details);
  const handleSubmit = (status) => {
    setCallNavbar(false);
    var arr = [];
    selectedRowsData?.map((item) => arr.push(item.id));

    if (status === "ACCEPT") {
      var data = {
        college_id: collegeId,
        team_id: arr,
        status: "APPROVED",
      };
      dispatch(ApproveTeamAPI(data, alert, history, location, companyList, handleClose, setCallNavbar, setLoader));
    } else {
      if(!deleteReason){
        setDeleteReasonError("Please enter reason");
        return;
      }
      var data = {
        college_id: collegeId,
        team_id: arr,
        status: "REJECTED",
        reason: deleteReason
      };
      dispatch(ApproveTeamAPI(data, alert, history, location, companyList, handleCloseNew, setCallNavbar, setLoader));
    }

    setTrigger(!trigger);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  const [showMore, setShowMore] = React.useState(false);

  return (
    <div>
      <Button variant="contained" color="primary" size="small" className={classes.button} onClick={handleClickOpen}>
        {buttonName}
      </Button>
      <Dialog fullScreen={fullScreen} maxWidth="md" PaperProps={{ classes: { root: classes.root1 } }} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <div style={{ padding: "20px", width: "400px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure, <br />
              you want to {buttonNameLower + " "}
              the selected Pending Team Member(s)?
            </Typography>

            <div component="ul" className={classes.root}>
              {!showMore
                ? selectedRowsData.slice(0, 5).map((data, index) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : selectedRowsData.map((data, index) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
            </div>
            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
            {status === "REJECT" &&
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              multiline
              rows="2"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label={`Why you want to reject these member${selectedRowsData?.length > 1? 's' : ''}?`}
              value={deleteReason}
              onChange={(e) => {
                setDeleteReason(e.target.value);
                setDeleteReasonError("");
              }}
              style={{ marginBottom: 10 }}
              error={deleteReasonError ? true : false}
              helperText={<span>{deleteReasonError}</span>}
            />
        }

          </div>
        </div>

        <div className={classes.btngrid}>
          <Button variant="outlined" style={{ minWidth: "160px" }} className={classes.button} onClick={handleClose} color="primary">
            Cancel
          </Button>

          <Button
            autoFocus
            className={classes.button}
            style={{ minWidth: "160px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit(status);
            }}
          >
            {loader ? <CustomButtonCircularProgress /> :buttonName}
          </Button>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
