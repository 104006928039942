import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { mergeClasses } from "@material-ui/styles";
import React, { useEffect } from "react";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "8px",
    // width: "150px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    height: 30,
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      // borderRadius: 30,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        // color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function GroupListDropdown({
  title,
  state,
  setState,
  setFilterDataText,
  groupList,
  show,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={mergeClasses.title}
        aria-controls="customized-menu"
        style={{
          display: "flex",
          borderRadius: "8px",
          backgroundColor: "#e2e6ea",
          color: "#7a7e83",
          boxShadow: "none",
        }}
        aria-haspopup="true"
        variant="contained"
        size="small"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <Typography variant="overline" color="inherit">
          {state ? state?.name : groupList ? groupList[0]?.name : null}
        </Typography>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <StyledMenuItem selected={state && state.id === "All" ? true : false}>
          <Typography
            variant="overline"
            color="inherit"
            onClick={() => {
              setState({ id: "All", name: "All College Groups" });
              handleClose();
              //   if (show) setFilterDataText("No Data Found");
            }}
          >
            All College Groups
          </Typography>
        </StyledMenuItem> */}
        {groupList?.map((item, index) => {
          return (
            <StyledMenuItem
              onClick={() => {
                setState(item);
                handleClose();
                //   if (show) setFilterDataText("No Data Found");
              }}
              selected={
                !state && index === 0
                  ? true
                  : state && state.id === item.id
                  ? true
                  : false
                // false
              }
            >
              <Typography variant="overline" color="inherit">
                {item.name}
              </Typography>
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
