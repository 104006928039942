import PremiumJob from "./JobTags/PremiumJob"
import HotJob from "./JobTags/HotJob"
import UrgentJob from "./JobTags/UrgentJob"

const JobCategoryTags = ({ type, full = true }) => {


    function getTagTypeComponent() {

        switch (type?.toUpperCase()) {

            case 'HOT':
                return <HotJob full={full} />
            case 'PREMIUM':
                return <PremiumJob full={full} />
            case 'URGENT':
                return <UrgentJob full={full} />
            default:
                return <></>
        }
    }
    return (
        getTagTypeComponent()
    )

}

export default JobCategoryTags