import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddWorkExperienceDialog from "./AddWorkExperienceDialog";
import SaveWorkExperience from "./SaveWorkExperience";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
}));

export default function WorkExperience({
  companiesList,
  workExperiences,
  handleAddWorkEx,
  handlePatchWorkEx,
  handleDeleteWorkEx,
  workExDataLoading,
  skillsList,
  approvalRequestCounts,
  redirectionData,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [tempWorkEx, setTempWorkEx] = useState([]);
  const [editWorkEx, setEditWorkEx] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [isCompanyNotListed, setIsCompanyNotListed] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setOpen(false);
    setEditWorkEx({});
    setIsCompanyNotListed(false);
  };

  useEffect(() => {
    if (workExperiences) setTempWorkEx(workExperiences);
  }, [workExperiences]);

  const saveWorkEx = () => {
    isEdit
      ? handlePatchWorkEx(editWorkEx, editWorkEx?.id, editIndex, handleClose)
      : handleAddWorkEx(editWorkEx, handleClose);
  };

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Work Experience</Typography>
        <br />
        <Grid
          item
          xs={12}
          style={
            approvalRequestCounts?.WORK
              ? { pointerEvents: "none", opacity: 0.4 }
              : {}
          }
        >
          {tempWorkEx &&
            tempWorkEx.map((exp, index) => {
              return (
                <SaveWorkExperience
                  experience={exp}
                  index={index}
                  setEditWorkEx={setEditWorkEx}
                  handleDelete={handleDeleteWorkEx}
                  handleClickOpen={handleClickOpen}
                  setIsEdit={setIsEdit}
                  setEditIndex={setEditIndex}
                />
              );
            })}
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
          {approvalRequestCounts?.WORK ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.newBtnStyle}
              onClick={() => {
                history.push({
                  pathname: `/student-profile-request/${redirectionData?.name.replace(
                    / /g,
                    "_"
                  )}-${redirectionData?.userId}`,
                });
              }}
            >
              {`Approve Request (${approvalRequestCounts?.WORK ?? 0})`}
            </Button>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                startIcon={<AddCircleOutlineIcon />}
                className={classes.ne}
                variant="text"
                color="primary"
                onClick={() => {
                  handleClickOpen();
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                }}
              >
                Add Work Experience
              </Button>
            </div>
          )}
        </Grid>
      </div>
      <AddWorkExperienceDialog
        open={open}
        handleClose={handleClose}
        companiesList={companiesList}
        editWorkEx={editWorkEx}
        setEditWorkEx={setEditWorkEx}
        saveWorkEx={saveWorkEx}
        isCompanyNotListed={isCompanyNotListed}
        setIsCompanyNotListed={setIsCompanyNotListed}
        workExDataLoading={workExDataLoading}
        isEdit={isEdit}
        skillsList={skillsList}
      />
    </>
  );
}
