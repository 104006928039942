import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { BlockSingleCompanyAPI } from "../../../api/Company/action";
import BlockSingleCompany from "../../DialogSection/BlockSingleCompany";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#28a745",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#28a745",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 19,
    height: 19,
  },
  track: {
    borderRadius: 26/2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "red",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function CustomSwitches({
  companyData,
  collegeId,
  setCompanyData,
}) {
  const [isOpen, setisOpen] = useState(
    companyData?.current_status === "ACTIVE" ? true : false
  );

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [tempEvent, setTempEvent] = useState(null);

  const handleChange = (event) => {
    handleClickOpen();
    setTempEvent(event);
    // setState({ ...state, [event.target.name]: event.target.checked });
  };
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (status, reason) => {
    const handleResponse = () => {
      setCompanyData({ ...companyData, current_status: status });
    };
    var data = {
      college_id: companyData?.college_id,
      object_id: [companyData?.id],
      status: status,
      reason: reason,
    };
    dispatch(BlockSingleCompanyAPI(data, alert, setLoading, handleResponse));
    setState({ ...state, [tempEvent.target.name]: tempEvent.target.checked });
    handleClose();
    setisOpen(!isOpen);
  };

  useEffect(() => {
    setisOpen(companyData?.current_status === "ACTIVE" ? true : false);
  }, [companyData]);

  return (
    <>
      <div style={{ marginRight: "40px" }}>
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>
              {/* {isOpen && (
                <Typography variant="h6" style={{ color: "red" }}>
                  Block
                </Typography>
              )} */}
            </Grid>
            {companyData?.current_status && (
              <>
                <Grid item>
                  <IOSSwitch
                    checked={isOpen}
                    onChange={handleChange}
                    // onClick={() => {
                    //   if (!loading) handleSubmit(!isOpen);
                    // }}
                    name="checkedB"
                  />
                </Grid>
                <Grid item>
                  <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                    {" "}
                    {isOpen ? "Active" : "Blocked"}
                  </span>
                </Grid>
              </>
            )}
          </Grid>
        </Typography>
      </div>
      <BlockSingleCompany
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        data={companyData}
        handleSubmit={handleSubmit}
      />
    </>
  );
}
