import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  IconButton,
  InputBase,
  Select,
  InputLabel,
  Paper,
} from "@material-ui/core";
import pSBC from "shade-blend-color";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Calendar from "../../Input/Calendar/index2.js";
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { enableRipple } from "@syncfusion/ej2-base";
import Editor from "../../Editor";
import { DropzoneAreaBase } from "material-ui-dropzone";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";

enableRipple(true);

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    height: "50px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
  },
  btn: {
    borderRadius: "8px",
    // height: "100%",
    padding: "10px",
    height: "48px",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginRight: "10px",
    boxShadow: "none",
    //width: "100%",
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,     
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },

  link: {
    boxShadow: "none",
    // border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    //  padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    // borderColor: theme.palette.primary.main,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "8px",
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    //  color: theme.palette.primary.main,
    color: "#6c757d",
  },
  move: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "48px",
    paddingLeft: "20px",
    marginLeft: "10px",
    paddingRight: "20px",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  interview: {
    borderRadius: "8px",
    marginTop: "20px",
    width: "70%",
  },
  DropzoneArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },

  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  share: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: "#e2e6ea",
    // backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function BasicInfo() {
  const classes = useStyles();
  const [htmlDesc, setHtmlDesc] = useState(null);
  const [editor, setEditor] = useState(null);
  const [collegeProfile, setCollegeProfile] = useState(null);
  const [generatedLink, setGeneratedLink] = useState();
  const [data, setData] = React.useState({
    companyid: null,
    jobProfileId: null,
    jobProfileName: "",
    deadline: "",
    time: "",
    jobRound: null,
    title: "",
    interviewType: "Online",
    link: "Auto generate",
    meetLink: "",
    address: null,
    slotinterval: "",
    description: "",
    endTime: "",
    companyName: "",
    jobRoundName: "",
  });

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup
              defaultValue="paid"
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
              name="customized-radios"
              // value="demo"
            >
              <Typography
                style={{ marginRight: "10px", fontWeight: "600" }}
                variant="body2"
              >
                Event Type:
              </Typography>
              <FormControlLabel
                value="public"
                control={<StyledRadio />}
                label={<Typography variant="body2">Public</Typography>}
              />
              <FormControlLabel
                value="private"
                control={<StyledRadio />}
                label={<Typography variant="body2">Private</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            fullWidth
            label="Event Name"
          />
        </Grid>
        <Grid item xs={3}>
          <Calendar
            title="Start Date"
            // selectedDate={applicationStartDate}
            // setSelectedDate={setApplicationStartDate}
            // errorMessage={jobDetailsError?.application_start_date}
            minDate={new Date()}
          />
        </Grid>
        <Grid item xs={3}>
          {" "}
          <TimePickerComponent
            //value="demo"
            style={{
              //border: "none",
              border: "1px solid #fff",
            }}
            //  cssClass="e-custom-style"
            placeholder="Start time"
            format="HH:mm"
            onChange={(e) => {
              setData({
                ...data,
                time: moment(e.target.value).format("HH:mm"),
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Calendar
            title="End Date"
            // selectedDate={applicationStartDate}
            // setSelectedDate={setApplicationStartDate}
            // errorMessage={jobDetailsError?.application_start_date}
            minDate={new Date()}
          />
        </Grid>
        <Grid item xs={3}>
          {" "}
          <TimePickerComponent
            style={{
              //border: "none",
              border: "1px solid #fff",
            }}
            //  cssClass="e-custom-style"
            placeholder="End time"
            format="HH:mm"
            onChange={(e) => {
              setData({
                ...data,
                time: moment(e.target.value).format("HH:mm"),
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body2"
            style={{ marginBottom: "20px", fontWeight: "600" }}
          >
            Event Description*
          </Typography>
          <Editor state={editor} setState={setEditor} desc={htmlDesc} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography
            variant="body2"
            style={{ marginBottom: "20px", fontWeight: "600" }}
          >
            Event Logo
          </Typography>
          <DropzoneAreaBase
            dropzoneClass={classes.DropzoneArea}
            acceptedFiles={["image/*"]}
            dropzoneText={"Drag and drop an Event Logo"}
            showPreviewsInDropzone={true}
            showPreviews={true}
            dropzoneText={"Drag and drop an Event Logo"}
            onAdd={(files) => {
              setCollegeProfile({
                ...collegeProfile,
                logo: files[0].file,
                logoData: files[0].data,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography
            variant="body2"
            style={{ marginBottom: "20px", fontWeight: "600" }}
          >
            Banner Image
          </Typography>
          <DropzoneAreaBase
            dropzoneClass={classes.DropzoneArea}
            acceptedFiles={["image/*"]}
            dropzoneText={"Drag and drop an Banner Image"}
            showPreviewsInDropzone={true}
            showPreviews={true}
            dropzoneText={"Drag and drop an Banner Image"}
            onAdd={(files) => {
              setCollegeProfile({
                ...collegeProfile,
                logo: files[0].file,
                logoData: files[0].data,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {" "}
            Where will the event take place?
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              defaultValue="college"
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
              name="customized-radios"
              // value="demo"
            >
              <FormControlLabel
                value="college"
                control={<StyledRadio />}
                label={<Typography variant="body2">Online</Typography>}
              />
              <FormControlLabel
                value="getwork"
                control={<StyledRadio />}
                label={<Typography variant="body2">Offline</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.paper}>
            <Paper component="form" className={classes.link}>
              <InputBase
                className={classes.input}
                placeholder="Enter Online Link"
                // placeholder={generatedLink}
                inputProps={{
                  className: classes.labellink,
                  "aria-label": "https://getwork-ejd-mba.in",
                }}
              />
              <CopyToClipboard
                text={generatedLink}
                onCopy={() => alert.success("Link Copied!")}
              >
                <IconButton className={classes.iconButton} aria-label="copy">
                  <FileCopyOutlinedIcon color="primary" />
                </IconButton>
              </CopyToClipboard>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.floatingLabelFocusStyle}>
              Event Category
            </InputLabel>
            <Select
              native
              value={state.age}
              onChange={handleChange}
              label="Age"
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value={10}>Demo</option>
              <option value={20}>Demo</option>
              <option value={30}>Demo</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ fontWeight: "600" }}>
            Event Notification Reminder
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Calendar
            title="Reminder Date"
            // selectedDate={applicationStartDate}
            // setSelectedDate={setApplicationStartDate}
            // errorMessage={jobDetailsError?.application_start_date}
            minDate={new Date()}
          />
        </Grid>
        <Grid item xs={3}>
          <TimePickerComponent
            //value="demo"
            style={{
              //border: "none",
              border: "1px solid #fff",
            }}
            //  cssClass="e-custom-style"
            placeholder="Reminder Time"
            format="HH:mm"
            onChange={(e) => {
              setData({
                ...data,
                time: moment(e.target.value).format("HH:mm"),
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
