import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper } from "@material-ui/core";
import CompanyLogo from "../../../assets/img/default.jpg";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import NoDataFunc from "../../NoDataSVG/index1";
import SmallCardsSkeleton from "../../../pages/Dashboard/skeletons/SmallCardsSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: theme.spacing(2),
    textAlign: "center",
    border: "1px solid #e2e6ea",
    marginBottom: "10px",
  },
  Jobdetail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
  },
  dot: {
    backgroundColor: "#38cb89",
    height: "8px",
    borderRadius: "50%",
    width: "8px",
    marginRight: "10px",
  },
  live: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",

    "&:hover": {
      transform: "scale(1.020)",
      transition: "transform 0.2s",
    },
  },
}));

export default function SmallCards({ data, type, loading }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loading ? (
              <SmallCardsSkeleton />
            ) : data?.length >= 1 ? (
              data?.slice(0, 3).map((item, index) => {
                return (
                  <Link to={`job/${item?.id}`} className={classes.link}>
                    <Paper className={classes.paper}>
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={
                              item?.company_logo
                                ? item?.company_logo
                                : item?.company_website
                                ? `//logo.clearbit.com/${item?.company_website}`
                                : CompanyLogo
                            }
                            onError={(e) => {
                              e.target.onerror = () => null;
                              e.target.src = CompanyLogo;
                            }}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item xs className={classes.Jobdetail}>
                          <Typography variant="h6" style={{ color: "#000" }}>
                            {item?.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ color: "#181818" }}
                          >
                            {item?.company_name} •{" "}
                            {item?.location?.length >= 1
                              ? item?.location[0].city
                              : "PAN India"}
                          </Typography>
                          {type === "upcoming" ? (
                            <div className={classes.live}>
                              <div className={classes.dot} />
                              <Typography
                                variant="body2"
                                style={{ color: "#181818 " }}
                              >
                                LIVE{" "}
                                {moment(item?.application_start_date)
                                  .endOf("day")
                                  .fromNow()}
                              </Typography>
                            </div>
                          ) : (
                            <div className={classes.live}>
                              <div className={classes.dot} />
                              <Typography
                                variant="body2"
                                style={{ color: "#181818 " }}
                              >
                                {moment(item?.application_end_date).isBefore()
                                  ? "Application Closed"
                                  : "Closing"}{" "}
                                {moment(item?.application_end_date)
                                  .endOf("day")
                                  .fromNow()}
                              </Typography>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Link>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <NoDataFunc width={"300Px"} height={"200px"} />
                <Typography style={{ fontSize: "16px" }}>
                  No Jobs Available
                </Typography>{" "}
                <div
                  onClick={() => history.push("post-job")}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <Typography style={{ fontSize: "16px" }}>Please </Typography>
                  <Typography
                    style={{
                      paddingLeft: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                    color={"primary"}
                  >
                    {" "}
                    Post a Job
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
