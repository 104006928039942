import { Avatar, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import pSBC from "shade-blend-color";
import loo from "../../../../assets/png/default_college.png";

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: "#FAFCFC",
    width: "380px",
    borderRadius: "8px",
    height: "73px",
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: pSBC(0.8, theme.palette.primary.main),
    },
    cursor: "pointer",
  },
  alert: {
    height: "15px",
    width: "15px",
    display: "flex",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    borderColor: theme.palette.primary.main,
    border: "1px solid",
  },
}));

export default function CompanyChat({
  chatName,
  latestmessage,
  time,
  onPress,
}) {
  const classes = useStyles();
  var latestmessage_ = "";
  if (latestmessage.length > 25) {
    latestmessage_ = latestmessage.slice(0, 25) + "...";
  } else {
    latestmessage_ = latestmessage;
  }

  var chatTitle = "";
  if (chatName.length > 25) {
    chatTitle = chatName.slice(0, 25) + "...";
  } else {
    chatTitle = chatName;
  }

  return (
    <div onClick={onPress} className={classes.item}>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar src={loo} className={classes.avatar} />
        </Grid>
        <Grid container spacing={2} style={{ display: "contents" }}>
          <Grid item xs={9}>
            <Typography variant="body2" noWrap style={{ fontWeight: 600 }}>
              {chatTitle}
            </Typography>
            <Typography variant="overline" noWrap style={{ color: "#7a7e83" }}>
              {latestmessage_}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="overline" style={{ fontWeight: 600 }}>
              {time}
            </Typography>
            {/* <div className={classes.alert}>
              <Typography variant="overline" style={{ color: "white" }}>
                {" "}
                2
              </Typography>
            </div> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
