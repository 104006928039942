import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
  InviteStudentLinkAPI,
  PlacementListAPI,
} from "../../api/Common/action";
import { isGradeSystemFn } from "../../api/Domain/selector";
import { OFF_STUDENT_DATA } from "../../api/ManageOffStudents/action";
import {
  collegeIdFn,
  collegeUserDetailSelectorFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import NoDataFuncNew from "../../components/NoDataSVG/index2";
import OffCampusTab from "../../components/Header/OffCampusTab";
import AddNewOffCampusStudent from "../../components/DialogSection/AddNewCompany/AddNewOffCampusStudent";
import { checkForToken } from "../../constants/ApiCall";
import {
  sanchalanAxiosGetReq,
  sanchalanAxiosPatchReq,
} from "../../api/BaseApi/apiBase";
import OffCampusWithoutTab from "../../components/Header/OffCampusTab/OffCampusWithoutTab";
import { AddCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    //  alignItems: "center",
    flexDirection: "row",
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function ManageOffCampusNew() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const [loading, setLoading] = useState(true);

  const collegeId = useSelector(collegeIdFn);
  const [studentDataOffCampus, setStudentDataOffCampus] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  //fetching the student list that's passed to the add new off campus student form dropdown

  useEffect(() => {
    const fetchStudentList = async () => {
      setLoading(true);
      const url = `api/manage_job/get_off_campus_job/?college_id=${collegeId}`;
      setDataLoading(true);
      try {
        const res = await sanchalanAxiosGetReq(url);
        // console.log("response from backend", res);
        if (res?.data?.success) {
          setStudentDataOffCampus(res?.data?.data);
          // console.log("studentListOffCampus", studentListOffCampus);
        } else {
          console.log("error ocuured");
        }
        setLoading(false);
      } catch (error) {
        // setDataLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
        setLoading(false);
      }
    };
    if (collegeId) {
      fetchStudentList();
      setDataLoading(false);
    }
  }, [collegeId]);

  const [loadingPlacement, setLoadingPlacement] = useState(false);
  const userRolesPermission = useSelector(userRolesActionsFn);

  const [tempStudentList, setTempStudentList] = useState([]);
  useEffect(() => {
    if (studentDataOffCampus?.length > 0) {
      var arr = [...studentDataOffCampus];
      let new_arr = [];

      arr.forEach((item) => {
        new_arr.push({
          "Company Name": item?.company_name,
          "Student Name": item?.student_name,
          Email: item?.email,
          "Job Title": item?.job_title,
          "Job Type": item?.job_type,
          "Joining Date": item?.joining_date,
          "Posted Date": item?.created_date,
        });
      });
      setTempStudentList(new_arr);
    }
  }, [studentDataOffCampus?.length]);

  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  return (
    <>
      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : // ) : placementSessionList?.length > 0 ? (
      true ? (
        <>
          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Manage Off Campus Placement</Typography>
              <Typography variant="body2" color="secondary">
                Manage all your Off-Campus Placement Data
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_students"
              ) && (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Link
                      to="/offcampus-add-student"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: "10px",
                          borderRadius: "8px",
                          boxShadow: "none",
                          padding: "7px",
                          height: "40px",
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        startIcon={<AddCircle />}
                      >
                        Add new
                      </Button>
                    </Link>

                    <CSVLink
                      data={tempStudentList}
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        textAlign: "left",
                        marginLeft: "5px",
                      }}
                      filename={"Students-Offcampus.csv"}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        style={{ marginRight: "0px" }}
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                      >
                        Export
                      </Button>
                    </CSVLink>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <OffCampusWithoutTab
              loading={loading}
              // filters={filters}
              // setFilters={setFilters}
              // setClickSearchButton={setClickSearchButton}
              // setSelectedRowsData={setSelectedRowsData}
              // selectedRowsData={selectedRowsData}
              // setApplyClickedScore={setApplyClickedScore}
              // filterDataText={filterDataText}
              // setFilterDataText={setFilterDataText}
              // collegeId={collegeId}
              userRolesPermission={userRolesPermission}
              // setCallNavbar={setCallNavbar}
              // clearData={clearStudentData}
              // studentData={studentData}
              studentDataOffCampus={studentDataOffCampus}
              // studentListOffCampus={studentListOffCampus}
              // filterListOffCampusData={filterListOffCampusData}
              // fetchStudentList={fetchStudentList}
              dataLoading={dataLoading}
              // deleteOffCampusStudentData={deleteOffCampusStudentData}
              // navbarDataOffCampus={navbarDataOffCampus}
              // setDeleteReason={setDeleteReason}
              // deleteReason={deleteReason}
              // deleteReasonError={deleteReasonError}
              // setDeleteReasonError={setDeleteReasonError}
              // setRefreshData={setRefreshData}
              // refreshData={refreshData}
              // deleteResponse={deleteResponse}
              // approveRejectOffCampus={approveRejectOffCampus}
            />
          </div>
        </>
      ) : (
        <>
          <NoDataFuncNew
            title={"Please Add Placement Cycle First"}
            subtitle={"Click the below Button to add placement Cycle"}
            buttonText={"Add Placement Cycle"}
            height={"320px"}
            width={"320px"}
            link={"/placement-cycles"}
          />
        </>
      )}
    </>
  );
}
