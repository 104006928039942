import { Grid, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CompanyTable from "../../Table/CompanyTable";
import { Link } from "react-router-dom";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import {
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "30px",
  },
}));

export default function OffCampusWithoutTab(props) {
  const classes = useStyles();
  const [selectedID, setSelectedID] = React.useState([]);
  const isGradeSystem = useSelector(isGradeSystemFn);
  const studentList = useSelector((state) => state?.StudentData?.details);

  const collegeListSelector = useSelector(collegeListSelectorFn);

  const [tempStudentList, setTempStudentList] = useState([]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const {
    studentDataOffCampus,
    loading,
    dataLoading,
    filters,
    setSelectedRowsData,
    selectedRowsData,
    filterDataText,
    collegeId,
  } = props;

  useEffect(() => {
    if (
      studentList?.length > 0 &&
      groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
    ) {
      var arr = [...studentList];
      arr.forEach((item) => {
        item["college_name"] = checkCollegeName(item?.college_id[0]);
      });
      setTempStudentList(arr);
    } else setTempStudentList([]);
  }, [studentList, studentList?.length]);

  const checkCollegeName = (id) => {
    var name = "";
    collegeListSelector.map((item) => {
      if (item?.id === id) name = item?.name;
    });
    return name;
  };

  const columns = [
    {
      field: "name",
      width: 230,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Student Name
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.student_name}
        </span>
      ),
    },
    {
      field: "email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.email}
        </span>
      ),
    },

    {
      field: "company_name",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          Company Name
        </strong>
      ),
      renderCell: (params) => (
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Grid item xs zeroMinWidth>
            <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
              {params?.row?.company_name}{" "}
            </Typography>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "job_title",
      width: 170,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Job Title
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params?.row?.job_title}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "job_type_id",
      headerName: "Job Type",
      width: 170,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          Job Type
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params?.row?.job_type}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "joining",
      headerName: "Job Type",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Joining Date
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params?.row?.joining_date}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "create_date",

      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Create date
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params?.row?.created_date}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);

  return (
    <div className={classes.root}>
      <CompanyTable
        loading={loading}
        dataLoading={dataLoading}
        list={studentDataOffCampus}
        columns={columns}
        setSelectedRowsData={setSelectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        filterDataText={filterDataText}
        filters={filters}
        checkBoxSelection={false}
      />
    </div>
  );
}
