import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, Typography, Grid, LinearProgress, Chip, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import pSBC from "shade-blend-color";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Autocomplete } from "@material-ui/lab";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "45px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,     
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  move: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "40px",
    marginLeft: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
}));

export default function MoveStepReject({ selectedRowsData, setSelectedRowsData, setSelectedID, moveToNext, setMoveToNext, MoveToNextStep, navbarData,forMarkingAbsent }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const [showMore, setShowMore] = useState(false);
  const [rejectError, setRejectError] = useState(null);
  const [typeFeedbackError, setTypeFeedbackError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateData = () => {
    var is_valid = true;
    var reject_error = null;
    var type_error = null;
    if (!moveToNext?.feedback) {
      reject_error = "Please Select a reason";
      is_valid = false;
    }
    if (moveToNext?.feedback && moveToNext?.feedback?.name?.toLowerCase() === "other" && !moveToNext?.feedback_typed) {
      type_error = "Please Type your feedback";
      is_valid = false;
    }
    setTypeFeedbackError(type_error);
    setRejectError(reject_error);
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      MoveToNextStep(setLoading);
    }
  };

  const feedbackList = [{ value: "Bad Candidate", name: "Bad Candidate" }, { name: "Other", value: "Other" },];

  const absentReasonList = [{ value: "Turned Out", name: "Turned Out" }, { name: "Other", value: "Other" },]
  return (
    <div>
      <Button color="primary" variant="contained" size="small" onClick={handleClickOpen} className={classes.move}>
      {forMarkingAbsent ? "Mark Absent" : "Reject"}  
      </Button>
      <Dialog fullScreen={fullScreen} maxWidth="md" PaperProps={{ classes: { root: classes.root1 } }} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">{forMarkingAbsent ? "Mark as Absent" : "Reject Students"} </Typography>
          </div>

          <div component="ul" className={classes.root}>
            {!showMore
              ? selectedRowsData.slice(0, 5).map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : selectedRowsData.map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
            <Autocomplete
              id="country-select-demo"
              options={forMarkingAbsent ? absentReasonList : feedbackList}
              getOptionLabel={(option) => String(option.name)}
              value={moveToNext.feedback}
              onChange={(e, newValue) => {

                if(forMarkingAbsent){

                   setMoveToNext({ ...moveToNext, round_id: navbarData["absent"]?.round_id, round_name: "Marked Absent", feedback: newValue });
                }else{

                    setMoveToNext({ ...moveToNext, round_id: navbarData["rejected"]?.round_id, round_name: "Rejected", feedback: newValue });
                }              

                setRejectError(null);
              }}
              style={{ background: "#fff", borderRadius: 8, width: "100%", marginBottom: 20, marginTop: 20 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Feedbacks"}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={rejectError ? true : false}
                  helperText={rejectError}
                />
              )}
            />
            {moveToNext?.feedback && moveToNext?.feedback?.name?.toLowerCase() === "other" && (
              <TextField
                id="outlined-multiline-static"
                label="Enter Your Feedback"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                multiline
                style={{ width: "100%" }}
                rows={6}
                variant="outlined"
                value={moveToNext?.feedback_typed}
                onChange={(e) => {
                  setMoveToNext({ ...moveToNext, feedback_typed: e.target.value });
                  setTypeFeedbackError(null);
                }}
                error={typeFeedbackError ? true : false}
                helperText={typeFeedbackError}
              />
            )}
          </div>
          <Grid container spacing={5}>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button variant="outlined" className={classes.button} onClick={handleClose} color="primary">
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => {
                  if (!loading) handleSubmit();
                }}
              >
                {loading ? <CustomButtonCircularProgress /> : `${forMarkingAbsent ? "Mark Absent" : "Reject"}`}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
