import React, { useState,useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  Button,Chip,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import { useAlert } from "react-alert";
import CloseIcon from "@material-ui/icons/Close";
import pSBC from "shade-blend-color";
import { Autocomplete } from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  newBtnStyle: {
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",

  },
  chipDisplay:{
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
  },

}));

export default function AddProjectDialog(props) {
 
  const classes = useStyles();
  const alert = useAlert();

  const {
    open,
    editProject,
    setEditProject,
    handleClose,
    saveProject,
    projectDataLoading,
    isEdit,skillsList,
  } = props;

  const [tempUserSkills, setTempUserSkills] = useState(editProject?.skills?.length > 0 ? editProject?.skills : []);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

   useEffect(() => {

   if(editProject?.skills?.length > 0){

     setTempUserSkills(editProject?.skills)

    }else if(Object.keys(editProject)?.length === 0){
      setTempUserSkills([])      
   }
                  
  },[editProject?.skills,isEdit])

  useEffect(() => {
   
    setEditProject({ ...editProject, skills:tempUserSkills  });
                  
  },[tempUserSkills?.length])

 const handleDelete = (index) => {
    setTempUserSkills((tempUserSkills) =>
      tempUserSkills.filter((_, skillIndex) => skillIndex !== index)
    );
  };

  const addSkill = (skill) => {
    if (skill) {
      const skillAlreadyAdded = tempUserSkills.some(
        (skl) => skl.skill_id === skill.skill_id
      );
      if (skillAlreadyAdded) alert.error("Already Added!");
      else {
        setTempUserSkills([
          ...tempUserSkills,
          {
            skill_id: skill.skill_id,
            skill_name: skill.skill_name,
            type: skill.type,
            skill_rating_value: 3,
            skill_rating: "Intermediate",
          },
        ]);
        
      }
    }
  }; 

  const [projectError, setProjectError] = useState({
    title: null,
    links: null,
    description: null,
  });

  const validateData = () => {
    if (!editProject.title) {
      alert.error("Title field cannot be left blank");
      return false;
    }

    if (
      editProject?.links &&
      !(
        editProject?.links?.startsWith("https://") ||
        editProject?.links?.startsWith("http://") ||
        editProject?.links?.startsWith("HTTPS://") ||
        editProject?.links?.startsWith("HTTP://")
      )
    ) {
      alert.error("Invalid ! Link should start with http://");
      return false;
    }
    if (!editProject.description) {
      alert.error("Description field cannot be left blank");
      return false;
    }

    return true;
  };

  const CHARACTER_LIMIT = 150;

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
      >
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>{isEdit ? "Edit" : "Add"} Project</b>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                margin="normal"
                label="Title*"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editProject?.title}
                onChange={(e) => {
                  setEditProject({ ...editProject, title: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                label="Link"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editProject?.links}
                onChange={(e) => {
                  setEditProject({ ...editProject, links: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
                      <Autocomplete
                        // multiple
                        style={{ width: "100%" }}
                        id="combo-box-demo"
                        onChange={(_, newValue) => {
                          if (newValue) {
                            addSkill(newValue);
                          }
                        }}
                        options={skillsList}
                        getOptionLabel={(option) => `${option.skill_name}`}
                        renderOption={(option) => {
                          return (
                            <span style={{ fontSize: 13 }}>{`${option.skill_name}`}</span>
                          );
                        }}
                        className={classes.fieldStyling1}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Select Skill"
                              variant="outlined"
                              InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                              }}
                            />
                          );
                        }}
                  />
           </Grid>
            <div className={classes.chipDisplay}>
                {tempUserSkills &&
                  tempUserSkills.map((data, index) => {
                    return (
                      <li key={data.skill_id}>
                        <Chip
                          color="primary"
                          deleteIcon={<CloseIcon color="primary" />}
                          variant="outlined"
                          size="small"
                          label={data.skill_name}
                          onDelete={() => {
                            handleDelete(index);
                          }}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
              </div>
            <Grid
              xs={12}
              className={classes.grid}
              style={{
                marginBottom: "10px",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginTop: "20px",
                display: "grid",
              }}
            >
              <TextField
                label="Write Description*"
                multiline
                maxRows={5}
                minRows={5}
                inputProps={{
                  className: classes.textfiledrow,
                  maxlength: CHARACTER_LIMIT,
                }}
                variant="outlined"
                id="custom-css-outlined-input"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={editProject?.description}
                onChange={(e) => {
                  setEditProject({
                    ...editProject,
                    description: e.target.value,
                  });
                }}
                error={projectError.description ? true : false}
                helperText={`${
                  editProject?.description?.length
                    ? editProject?.description?.length
                    : 0
                }/${CHARACTER_LIMIT}`}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                className={classes.newBtnStyle}
                onClick={() => {
                  handleClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.newBtnStyle}
                onClick={() => {
                  if (validateData()) {
                    saveProject();
                  }
                }}
              >
                {projectDataLoading ? <CustomButtonCircularProgress /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
