import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { Typography } from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  toolTipIcon: { color: theme.palette.primary.main },
}));

const ToolTipWithQuestionMark = ({ rejectedDate, label }) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="left-start"
      title={
        <>
          <Typography style={{ fontSize: "14px", color: "#fff" }}>
            {`Rejected On: ${rejectedDate.slice(0, 10)}`}
          </Typography>
          <Typography style={{ fontSize: "14px", color: "#fff" }}>
            {label}
          </Typography>
        </>
      }
    >
      <HelpIcon className={classes?.toolTipIcon} />
    </Tooltip>
  );
};

export default ToolTipWithQuestionMark;
