import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ApartmentIcon from "@material-ui/icons/Apartment";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import GroupIcon from '@mui/icons-material/Group';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';import React from "react";
import ReactHtmlParser from "react-html-parser";
import Glassdoor from "../../../../assets/company/social/glassdoor.png";
import Linkedin from "../../../../assets/company/social/linkedin.png";
import { checkForUrl } from "../../../../utility/checkForUrl";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 5%) 0px 8px 24px",
    borderRadius: "8px",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    marginBottom: "30px",
    marginRight: "12px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
}));

function Overview({ companyData }) {
  const classes = useStyles();

  function formatNumber(input) {
    const number = parseFloat(input);

    if (isNaN(number)) {
      return false;
    }
  
    const crore = 10000000; // 1 Crore = 10,000,000
    const lakh = 100000; // 1 Lakh = 100,000
  
    if (number >= crore) {
      return (number / crore).toFixed(2) + ' Cr';
    } else if (number >= lakh) {
      return (number / lakh).toFixed(2) + ' L';
    } else {
      return number.toString();
    }
  }


  console.log(formatNumber(companyData?.company_turnover)  , companyData?.company_turnover , "TURNOVER")

  const Data = [
    {
      icon: <LanguageIcon color="secondary" />,

      info: (
        <a href={checkForUrl(companyData?.company_website)} style={{ textDecoration: "none" }} target="_blank">
          <Typography color="primary" variant="body2" style={{ fontWeight: 600 }}>
            {companyData?.company_website}
          </Typography>
        </a>
      ),
      show: companyData?.company_website ? true : false,
    },
    {
      icon: <ApartmentIcon color="secondary" />,
      info: companyData?.company_location?.name,
      show: companyData?.company_location ? true : false,
    },
    {
      icon: <PeopleAltOutlinedIcon color="secondary" />,
      info: companyData?.size,
      show: companyData?.size ? true : false,
    },
    {
      icon: <GroupIcon style={{color:"#6c757d"}}  />,
      info: (<p>{companyData?.company_size} Employees</p>),
      show: companyData?.company_size ? true : false,
    },
    {
      icon: <LocationOnIcon style={{color:"#6c757d"}}  />,
      info: companyData?.company_address,
      show: companyData?.company_address ? true : false,
    },
    {
      icon: <ContactPhoneIcon style={{color:"#6c757d"}}  />,
      info: companyData?.landline,
      show: companyData?.landline ? true : false,
    },
    {
      icon: <CurrencyRupeeIcon style={{color:"#6c757d"}} />,
      info: formatNumber(companyData?.company_turnover)     ,
      show: formatNumber(companyData?.company_turnover)  ? true : false,
    },
    {
      icon: <WorkOutlineIcon color="secondary" />,
      info: companyData?.industry_type_name,
      show: companyData?.industry_type_name ? true : false,
    },
    {
      icon: <FlagOutlinedIcon color="secondary" />,
      info: companyData?.year_founded,
      show: companyData?.year_founded ? true : false,
    },
  ];
  return (
    <>
      <div className={classes.paper}>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h6" color="secondary">
              Overview
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {(companyData?.linkedin || companyData?.glassdoor) && (
              <div
                style={{
                  //  border: "1px solid #b0b7c3",
                  borderRadius: "8px",
                  padding: "5px",
                  display: "flex",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                {companyData?.glassdoor && (
                  <a href={checkForUrl(companyData?.glassdoor)} target="blank">
                    <img src={Glassdoor} height="30px" alt="glassdoor-icons" width="30px" />
                  </a>
                )}
                {companyData?.linkedin && (
                  <a href={checkForUrl(companyData?.linkedin)} target="blank">
                    <img style={{ marginLeft: "20px" }} src={Linkedin} height="30px" alt="glassdoor-icons" width="30px" />
                  </a>
                )}
              </div>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: "10px", marginBottom: "10px" }}>
          {Data.map(
            (item) =>
              item.show && (
                <Grid item xs={12} sm={4}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body2" color="secondary">
                        {item.info}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
          )}
        </Grid>
        {companyData?.about && (
          <Grid item xs={12}>
            <Typography variant="h6">About {companyData?.name}</Typography>
            <br />
            <Typography variant="body2">{ReactHtmlParser(companyData?.about)}</Typography>
          </Grid>
        )}
      </div>
    </>
  );
}

export default Overview;
