import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {
  IconButton,
  ButtonBase,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Paper,
  InputBase,
  Divider,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import EventIcon from "@material-ui/icons/Event";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import pSBC from "shade-blend-color";
import ApplicantsStatus from "../../../Table/ApplicantsStatus";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteInterview } from "../../../../api/Assignment/action";
import ReactHtmlParser from "react-html-parser";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import NoDataFunc from "../../../NoDataSVG/index1";
import { userRolesActionsFn } from "../../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    marginTop: "10px",
    marginBottom: "30px",
  },
  image: {
    display: "flex",
    width: 150,
    height: 80,
    borderRadius: "8px",
    border: ".5px solid #e2e6ea",
  },
  img: {
    margin: "auto",
    //  display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    //  borderRadius: "50%",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoitem: {
    display: "flex",
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  info_icon: {
    color: "#6c757d",
    height: "18px",
  },
  detail: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    alignContent: "center",
  },
  location: {
    color: "#6c757d",
    marginTop: "10px",
  },
  skill: {
    marginTop: "15px",
    color: "#6c757d",
  },
  btn: {
    marginTop: "15px",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  link: {
    boxShadow: "none",
    border: " 1px solid",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    borderColor: pSBC(0.8, theme.palette.primary.main),
  },

  title: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "row",
    alignItems: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
  labellink: {
    color: theme.palette.primary.main,
    padding: "16px",
    width: "300px",
  },
  check: {
    backgroundColor: "#0069d9",
    color: "#fff",
    paddingLeft: "30px",
    paddingRight: "30px",
    borderRadius: "8px",
    boxShadow: "none",
  },
  // open: {
  //   backgroundColor: "#38cb89",
  //   color: "#fff",
  //   paddingLeft: "30px",
  //   paddingRight: "30px",
  //   height: "33px",
  //   borderRadius: "30px",
  //   boxShadow: "none",
  // },
  open: {
    backgroundColor: "#38cb89",
    padding: "5px",
    borderRadius: "8px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
}));

const StyledMenu = withStyles({
  paper: {
    //border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "10px",
    display: "flex",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

export default function OnGoing({ data }) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDelete = (item) => {
    dispatch(deleteInterview(alert, history, location, item.id, data));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <div>
      {data?.length >= 1 ? (
        data?.map((item) => {
          return (
            <div className={classes.paper}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.grid} sm container>
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      spacing={2}
                      style={{ display: "contents" }}
                    >
                      <div className={classes.text}>
                        <Typography variant="h6">
                          {item?.job_title} - {item?.job_type}
                        </Typography>
                        {/* <div
                          style={{
                            backgroundColor: "#000",
                            borderRadius: "5px",
                            height: "34px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "14px", color: "#fff" }}
                          >
                            Getwork Fulfilled
                          </Typography>
                        </div> */}
                        <div className={classes.title}>
                          <Typography variant="body2">
                            {item?.company_name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      className={classes.root}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* <div className={classes.chip}> */}
                      <Chip
                        variant="outlined"
                        className={classes.chip}
                        size="medium"
                        label={item?.round_name}
                        color="primary"
                      />
                      <div className={classes.text}>
                        <div className={classes.open}>
                          <Typography
                            style={{
                              color: "#fff",
                              fontSize: "14px",
                              display: "contents",
                            }}
                          >
                            {" "}
                            {item?.status}{" "}
                          </Typography>
                        </div>
                      </div>
                      {/* 
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.open}
                      >
                        <Typography style={{ color: "#fff", fontSize: "14px" }}>
                          {item?.status}
                        </Typography>
                      </Button> */}
                      {userRolesPermission?.some(
                        (item) => item.access_control_key === "manage_interview"
                      ) && (
                        <div>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <StyledMenuItem>
                              <Button
                                onClick={() => handleDelete(item)}
                                style={{
                                  display: "flex",
                                  color: "#000",
                                }}
                              >
                                {" "}
                                Delete
                              </Button>
                            </StyledMenuItem>
                          </StyledMenu>
                        </div>
                      )}
                      {/* <Button
                      onClick={() => handleDelete(item)}
                      variant="contained"
                      size="small"
                      className={classes.open}
                      style={{ backgroundColor: "#c4161c" }}
                    >
                      Delete
                    </Button> */}
                      {/* </div> */}
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={6} container>
                  <div>
                    <Typography
                      variant="h6"
                      style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                      {item?.type === "ONLINE" ? "Online" : "Offline"} Interview
                    </Typography>
                    {item?.type === "ONLINE" ? (
                      <div style={{ marginBottom: "20px" }}>
                        <Paper component="form" className={classes.link}>
                          <InputBase
                            className={classes.input}
                            value={
                              item?.calendar_link
                                ? item?.calendar_link
                                : item?.custom_link
                            }
                            placeholder="https://getwork-ejd-mba.in"
                            inputProps={{
                              className: classes.labellink,
                              "aria-label": "https://getwork-ejd-mba.in",
                            }}
                          />
                          <CopyToClipboard
                            text={
                              item?.calendar_link
                                ? item?.calendar_link
                                : item?.custom_link
                            }
                            onCopy={() => alert.success("Link Copied!")}
                          >
                            <IconButton
                              // type="copy"
                              className={classes.iconButton}
                              aria-label="copy"
                            >
                              <FileCopyOutlinedIcon color="primary" />
                            </IconButton>
                          </CopyToClipboard>
                        </Paper>
                      </div>
                    ) : (
                      <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                        <Typography
                          variant="body2"
                          style={{ marginRight: "40px" }}
                        >
                          Venue:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {item?.address}
                          </span>{" "}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                alignItems="center"
                marginTop="30px"
                style={{ marginBottom: "20px" }}
              >
                <Grid item xs={3} className={classes.row}>
                  <EventOutlinedIcon
                    style={{ color: "#7a7e83", marginRight: "10px" }}
                  />
                  <Typography style={{ fontSize: "14px", color: "#7a7e83" }}>
                    {moment(item?.start_date).format("LL")}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.row}>
                  <AccessTimeIcon
                    style={{ color: "#7a7e83", marginRight: "10px" }}
                  />
                  <Typography style={{ fontSize: "14px", color: "#7a7e83" }}>
                    {moment(item?.start_date).format("LT")}
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="h6">Interview Description</Typography>

              <Typography
                variant="body2"
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  width: "90%",
                }}
              >
                {ReactHtmlParser(item?.description)}
              </Typography>

              <Accordion
                style={{
                  boxShadow: "none",
                  marginTop: "10px",
                }}
                classes={{
                  root: classes.MuiAccordionroot,
                }}
                // classes={{
                //   root: classes.test,
                // }}
              >
                <AccordionSummary
                  style={{ padding: "0px" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">Applicants Status</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <div style={{ width: "70%", marginLeft: "20px" }}>
                    <ApplicantsStatus data={item.interview_applicant} />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : (
        <NoDataFunc title={"No Interview Found"} />
      )}
    </div>
  );
}
