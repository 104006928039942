import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AppBar, Tab, Typography, Box, Tabs } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ManageTeamActionnBar from "../../Input/ManageTeamActionBar";
import ManageTeamTable from "../../Table/ManageTeamTable";
import { useSelector } from "react-redux";
import { columns, columns2 } from "./column";
import CompanyTable from "../../Table/CompanyTable";
import EventActionBar from "../../Input/ManageTeamActionBar/EventActionBar";
import AllEvent from "../../Table/EventTable/AllEvent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    // color: "red",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    // color: "red",
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function EventTab() {
  const classes = useStyles();
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          // onChange={(e) => {
          //   console.log("");
          // }}
          indicatorColor="primary"
          textColor="primary"
        >
          <NewTab value="one" label="Public" wrapped {...a11yProps("one")} />
          <NewTab value="two" label="Private" wrapped {...a11yProps("two")} />
        </NewTabs>
      </AppBar>
      <div style={{ marginTop: "20px" }}>
        <EventActionBar />
      </div>
      <TabPanel value={value} index="one">
        <AllEvent />
      </TabPanel>
      <TabPanel value={value} index="two">
        <h1>In Progress</h1>
      </TabPanel>
    </div>
  );
}
