import { Breadcrumbs, Grid, IconButton, InputBase, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { assignmentDetails } from "../../api/Assignment/action";
import OpenAssignment from "../../components/Cards/OpenAssignment";
import CustomCircularProgress from "../../components/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    height: "50px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
  },
  gridtext: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #e2e6ea",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  heading: {
    color: theme.palette.primary.main,
  },
}));

export default function Assignment(props) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [text, setTxt] = useState("");
  const [data, setData] = useState(useSelector((state) => state?.assignment?.assignmentdetails?.job_assignment));

  const list = useSelector((state) => state?.assignment?.assignmentdetails?.job_assignment);

  useEffect(() => {
    if (location.search) {
      dispatch(assignmentDetails(setLoading, alert, history, location, location.search.substring(8)));
    }
  }, [location.search]);

  const handleText = (event) => {
    const text = event.target.value;
    setTxt(event.target.value);
    let txt = text.toLowerCase();
    let filterTracks = list.filter((item) => {
      if (item.assignment_title.toLowerCase().match(txt)) {
        return item;
      }
    });
    setData(filterTracks);
  };

  return (
    <>
      {/* <Layout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/assignments" style={{ textDecoration: "none" }}>
              <Typography color="primary" variant="body2">
                Assignments
              </Typography>
            </Link>

            <Typography color="secondary" variant="body2">
              {list ? list[0]?.job_title : ""}{" "}
            </Typography>
          </Breadcrumbs>
          <div onClick={() => (list ? history.push(`/job/${list[0]?.job_id}`) : null)} className={classes.gridtext}>
            <Typography variant="h2">
              <span className={classes.heading}> {list ? list[0]?.job_title : ""} </span> Assignments
            </Typography>
          </div>
          {/* <AssignmentTabs /> */}
          <Grid container spacing={3} style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Grid item xs={3} className={classes.grid}>
              <Paper component="form" className={classes.root}>
                <InputBase
                  onChange={handleText}
                  className={classes.input}
                  placeholder="Search By Assignment Title"
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      padding: "12px",
                    },
                  }}
                />
                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
          <OpenAssignment data={text ? data : list} />
        </div>
      )}
      {/* </Layout> */}
    </>
  );
}
