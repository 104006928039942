import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import errorsvg from "../../assets/svg/error.svg";
import Navbar from "../../components/Common/Navbar";

const useStyles = makeStyles(_ => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  img: {
    display: "flex",
    flexDirection: "column",
    // marginTop: "30px",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function LinkNotFound() {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <div className={classes.img}>
        <img src={errorsvg} height="320px" width="320px" alt="404 Error" />
        <Typography
          variant="h1"
          style={{
            fontWeight: "bold",
            marginBottom: "20px",
            fontSize: "24px",
            marginTop: "-20px",
          }}
        >
          The link is Not valid!
        </Typography>
        <Typography
          variant="h5"
          style={{
            color: "#6c757d",
            fontSize: "18px",
            textAlign: "center",
            fontWeight: "normal",
          }}
        >
         Please confirm the link from the provider.
        </Typography>
        <br />
       
      </div>
    </>
  );
}
