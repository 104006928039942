import React from "react";
import ChatRoomCard from "../../components/Cards/ChatRoomCard";
import ChatRoomHeader from "../../components/Header/ChatRoomHeader";

function ChatRoom() {
  return (
    <>
      {/* <Layout> */}
      <ChatRoomHeader />
      <ChatRoomCard />
      {/* </Layout> */}
    </>
  );
}

export default ChatRoom;
