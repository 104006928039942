import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const OVERVIEW_DATA = "OVERVIEW_DATA";
export const CAMPUS_PLACEMENT_DATA = "CAMPUS_PLACEMENT_DATA";
export const COMPANY_APPLICANT = "COMPANY_APPLICANT";
export const COMPANY_APPLICANT2 = "COMPANY_APPLICANT2";
export const COMPANY_APPLICANT3 = "COMPANY_APPLICANT3";
export const COMPANY_APPLICANT4 = "COMPANY_APPLICANT4";
export const COMPANY_APPLICANT5 = "COMPANY_APPLICANT5";
export const COMPANY_APPLICANT6 = "COMPANY_APPLICANT6";
export const COMPANY_APPLICANT7 = "COMPANY_APPLICANT7";
export const COMPANY_APPLICANT8 = "COMPANY_APPLICANT8";

export const overviewGet = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view1/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: OVERVIEW_DATA,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const overviewGroupGet = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view1/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: OVERVIEW_DATA,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CampusplacementGet = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view2/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: CAMPUS_PLACEMENT_DATA,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicant = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view3/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicant2 = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view4/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT2,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicant3 = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view5/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT3,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicant4 = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view6/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT4,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicant5 = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view7/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT5,
        payload: res.data.data,
      });
    } else {
    }
  };
};
export const CompanyApplicant6 = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view8/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT6,
        payload: res.data.data,
      });
    } else {
    }
  };
};
export const CompanyApplicant7 = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/view9/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT7,
        payload: res.data.data,
      });
    } else {
    }
  };
};
export const CompanyApplicant8 = (alert, history, location, college, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.PRAVESH}api/saas/skill_dashboard2/?college_id=${college}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT8,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicantGroup8 = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.PRAVESH}api/saas/group_skill_dashboard2/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT8,
        payload: res.data.data,
      });
    } else {
    }
  };
};
export const CampusplacementGetGroup = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view2/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: CAMPUS_PLACEMENT_DATA,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicantGroup = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view3/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicantGroup2 = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view4/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT2,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicantGroup3 = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view5/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT3,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicantGroup4 = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view6/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT4,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const CompanyApplicantGroup5 = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view7/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT5,
        payload: res.data.data,
      });
    } else {
    }
  };
};
export const CompanyApplicantGroup6 = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view8/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT6,
        payload: res.data.data,
      });
    } else {
    }
  };
};
export const CompanyApplicantGroup7 = (alert, history, location, checkGroup, startDate, endDate, checkPlacementSesion) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/reports/group_view9/?${checkGroup()}&start_date=${startDate}&end_date=${endDate}${checkPlacementSesion()}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COMPANY_APPLICANT7,
        payload: res.data.data,
      });
    } else {
    }
  };
};
