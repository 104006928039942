import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ManageUniversityInterview from "../../../components/Cards/UniversityGraph/UPlcementCycle/ManageUniversityInterview";


function UniversityTeamErf() {
  return (
    <>
      {/* <UniversityLayout> */}
      <Typography variant="h2">Team e-RF</Typography>
      <div style={{ marginTop: "20px" }}>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <ManageUniversityInterview />
          </Grid>

          <Grid item xs={4}>
            <ManageUniversityInterview />
          </Grid>
          <Grid item xs={4}>
            <ManageUniversityInterview />
          </Grid>
          <Grid item xs={4}>
            <ManageUniversityInterview />
          </Grid>
          <Grid item xs={4}>
            <ManageUniversityInterview />
          </Grid>
        </Grid>
      </div>
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityTeamErf;
