import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  InviteStudentLinkAPI,
  PlacementListAPI,
} from "../../api/Common/action";
import { isGradeSystemFn } from "../../api/Domain/selector";
import { OFF_STUDENT_DATA } from "../../api/ManageOffStudents/action";
import {
  collegeIdFn,
  collegeUserDetailSelectorFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import NoDataFuncNew from "../../components/NoDataSVG/index2";
import OffCampusTab from "../../components/Header/OffCampusTab";
import AddNewOffCampusStudent from "../../components/DialogSection/AddNewCompany/AddNewOffCampusStudent";
import { checkForToken } from "../../constants/ApiCall";
import {
  sanchalanAxiosGetReq,
  sanchalanAxiosOffCampusGetReq,
  sanchalanAxiosOffCampusPatchReq,
  sanchalanAxiosPatchReq,
} from "../../api/BaseApi/apiBase";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    //  alignItems: "center",
    flexDirection: "row",
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function ManageOffCampus() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [value, setValue] = React.useState("all");
  const [openUpload, setOpenUpload] = useState(false);
  const [openImport, setOpenImport] = useState(false);

  const [fileNames, setFileNames] = useState([]);

  const [applyClickedScore, setApplyClickedScore] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);
  const collegeId = useSelector(collegeIdFn);
  const [callNavbar, setCallNavbar] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [studentDataOffCampus, setStudentDataOffCampus] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [studentListOffCampus, setStudentListOffCampus] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [filterListOffCampusData, setFilterListOffCampusData] = useState({});
  const [firstCall, setFirstCall] = useState(true);
  const [navbarDataOffCampus, setNavbarDataOffCampus] = useState([]);
  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);
  const [deleteResponse, setDeleteResponse] = useState(null);

  const [filters, setFilters] = useState({
    search: null,
    course: null,
    degree: null,
    batch: null,
    cgpa_min: null,
    cgpa_max: null,
    company: null,
    job_type: null,
    status: "Approved",
    current_tab: null,
  });

  const [clickSearchButton, setClickSearchButton] = useState(false);
  const isGradeSystem = useSelector(isGradeSystemFn);

  const userDetails = useSelector(collegeUserDetailSelectorFn);

  const getNavbarData = async () => {
    const url = `api/manage_student/off_campus/navbar/${getFiltersParam()}`;
    setLoading(true);
    try {
      const res = await sanchalanAxiosGetReq(url);
      // console.log("setNavbarDataOffCampus", res.data);
      if (res.data.success) {
        setLoading(false);
        setNavbarDataOffCampus(res.data.data);
      } else {
        setLoading(false);
        // console.log("error ocuured");
      }
    } catch (error) {
      setLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };
  const getFiltersParam = () => {
    var filterString = "?";
    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        if (key === "status") {
          filterString += `key=${value}&`;
        }
      }
      if (value) {
        if (key === "degree") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      if (value) {
        if (key === "course") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      if (value) {
        if (key === "batch") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      if (value) {
        if (key === "company") {
          filterString += `${key}_name=${value?.name}&`;
        }
      }
      if (value) {
        if (key === "search") {
          filterString += `${key}=${value}&`;
        }
      }
      /*  if (value) {
        if (key === "status") {
          filterString += `${key}=${value?.name}&`;
        }
      } */
      if (value) {
        if (key === "cgpa_min") {
          filterString += `min_cgpa=${Number(value)}&`;
        }
      }
      if (value) {
        if (key === "cgpa_max") {
          filterString += `max_cgpa=${Number(value)}&`;
        }
      }
      if (value) {
        if (key === "job_type") {
          filterString += `job_type=${value?.id}&`;
        }
      }
    }

    return filterString;
  };

  const getDataOffCampusFirstTime = async () => {
    const url = `api/manage_student/off_campus_jobs/${getFiltersParam()}`;
    setLoading(true);
    try {
      const res = await sanchalanAxiosGetReq(url);

      if (res.data.success) {
        setLoading(false);
        setStudentDataOffCampus(res?.data?.data?.data);
        setFilterListOffCampusData(res?.data?.data?.filter_data);
        setFirstCall(false);
      } else {
        setLoading(false);
        console.log("error ocuured");
      }
    } catch (error) {
      setLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  //fetching the student list that's passed to the add new off campus student form dropdown
  const fetchStudentList = async () => {
    const url = "api/manage_student/approved/off_campus_jobs/";
    setDataLoading(true);
    try {
      const res = await sanchalanAxiosGetReq(url);
      console.log("response from backend", res);
      if (res.data.success) {
        setStudentListOffCampus(res?.data?.data?.data);
        setDataLoading(false);
        // console.log("studentListOffCampus", studentListOffCampus);
      } else {
        console.log("error ocuured");
      }
    } catch (error) {
      setDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const deleteOffCampusStudentData = async () => {
    const url = "api/manage_student/verify_student/status/";
    if (!deleteReason) {
      setDeleteReasonError("Please Enter Reason");
      return;
    }
    var arr = [];
    selectedRowsData?.map((item) => {
      return arr.push(item.id);
    });

    const data = {
      is_deleted: true,
      offcampus_id: arr,
      note: [
        {
          user_id: userDetails.user_id,
          user_name: userDetails.name,
          reason: deleteReason,
        },
      ],
    };

    try {
      setDataLoading(true);
      const res = await sanchalanAxiosPatchReq(url, data);
      console.log(res);
      if (res.data.success) {
        alert.success("Data Deleted Successfully");
        setDataLoading(false);
        setDeleteResponse(res.data.success);
        setRefreshData(!refreshData);
        setDeleteReason(null);
        setDeleteReasonError(null);
      }
    } catch (error) {
      setDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const approveRejectOffCampus = async (data) => {
    const url = `api/manage_student/verify_student/status/`;
    try {
      setDataLoading(true);
      const res = await sanchalanAxiosPatchReq(url, data);
      console.log(res);
      if (res.data.success) {
        alert.success(res.data.data.message);
        setDataLoading(false);
        setDeleteResponse(res.data.success);
        setRefreshData(!refreshData);
        setDeleteReason(null);
        setDeleteReasonError(null);
      }
    } catch (error) {
      setDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  useEffect(() => {
    const url = `api/manage_student/off_campus_jobs/${getFiltersParam()}`;
    const getDataOffCampus = async () => {

      setStudentDataOffCampus([]);
      setLoading(true);
      try {
        const res = await sanchalanAxiosGetReq(url);
        // console.log("response from backend", res);
        // console.log("filterListOffCampusData", res?.data?.data?.filter_data);
        if (res.data.success) {
          setLoading(false);
          setStudentDataOffCampus(res?.data?.data?.data);
        } else {
          setLoading(false);
          // console.log("error ocuured");
        }
      } catch (error) {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      }
    };
    if (firstCall) {
      getDataOffCampusFirstTime();
      getNavbarData();
    } else {
      getDataOffCampus();
      getNavbarData();
    }
  }, [filters, refreshData]);


  const studentList = useSelector((state) => state?.StudentData?.details);
  const filterList = useSelector((state) => state?.StudentData?.filterData);

  const clearStudentData = () => {
    dispatch({
      type: OFF_STUDENT_DATA,
      payload: [],
    });
  };

  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setFileNames([]);
  };

  const navbarData = useSelector((state) => state?.StudentData?.navbar);
  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );
  const [loadingPlacement, setLoadingPlacement] = useState(false);
  const userRolesPermission = useSelector(userRolesActionsFn);

  useEffect(() => {
    if (collegeId)
      dispatch(
        PlacementListAPI(
          alert,
          history,
          location,
          setLoadingPlacement,
          collegeId
        )
      );
  }, [collegeId]);

  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_cycle")) {
        var placement_name = checkPlacementName(
          location?.search.replace("?placement_cycle=", "")
        );
        setPlacementSession({
          id: location?.search.replace("?placement_cycle=", ""),
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  const inviteLink = useSelector((state) => state.commonData?.studentLink);

  useEffect(() => {
    if (!inviteLink) dispatch(InviteStudentLinkAPI(alert, history, location));
  }, []);

  const [tempStudentList, setTempStudentList] = useState([]);
  useEffect(() => {
    if (studentDataOffCampus?.length > 0) {
      var arr = [...studentDataOffCampus];
      let new_arr = [];

      arr.forEach((item) => {
        new_arr.push({
          Name: item?.name,
          "Enrollment No": item?.enrollment_no,
          Batch: item?.batch,
          Degree: item?.degree,
          Course: item?.course,
          "Job Title": item?.job_title,
          "Job Type": item?.job_type_name,
          Status: item?.status,
          "Placed Date": item.placed_date,
          CGPA: item?.cgpa,
          "CTC/Stipend": item?.ctc,
        });
      });
      setTempStudentList(new_arr);
    }
  }, [studentDataOffCampus?.length]);

  useEffect(() => {
    let new_arr = [];

    if (selectedRowsData?.length > 0) {
      let arr = [...selectedRowsData];

      arr.forEach((item) => {
        new_arr.push({
          Name: item?.name,
          "Enrollment No": item?.enrollment_no,
          Batch: item?.batch,
          Degree: item?.degree,
          Course: item?.course,
          "Job Title": item?.job_title,
          "Job Type": item?.job_type_name,
          "Placed At": item?.company_name,
          Status: item?.status,
          "Placed Date": item.placed_date,
          CGPA: item?.cgpa,
          "CTC/Stipend": item?.ctc,
        });
      });
      setTempStudentList(new_arr);
    } else {
      if (studentDataOffCampus?.length > 0) {
        var arr = [...studentDataOffCampus];
        let new_arr = [];

        arr.forEach((item) => {
          new_arr.push({
            Name: item?.name,
            "Enrollment No": item?.enrollment_no,
            Batch: item?.batch,
            Degree: item?.degree,
            Course: item?.course,
            "Job Title": item?.job_title,
            "Job Type": item?.job_type_name,
            Status: item?.status,
            "Placed Date": item.placed_date,
            CGPA: item?.cgpa,
            "CTC/Stipend": item?.ctc,
          });
        });
        setTempStudentList(new_arr);
      }
    }
  }, [selectedRowsData?.length]);

  const [filterDataText, setFilterDataText] = useState(null);
  useEffect(() => {
    if (
      (filters.status_blocked ||
        filters.status_active ||
        filters.batch ||
        filters.course ||
        filters.degree ||
        applyClickedScore ||
        clickSearchButton) &&
      studentList?.length === 0
    ) {
      setFilterDataText("No Results Found");
    }
  }, [
    filters.status_blocked,
    filters.status_active,
    filters.batch,
    filters.course,
    filters.degree,
    applyClickedScore,
    clickSearchButton,
  ]);

  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  return (
    <>
      {/* <Layout> */}

      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : placementSessionList?.length > 0 ? (
        <>
          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Manage Off Campus Placement</Typography>
              <Typography variant="body2" color="secondary">
                Manage Students who got placed off campus
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_students"
              ) && (
                <>
                  <div style={{ display: "flex" }}>
                    {filters?.current_tab?.params !== "pending_students" && (
                      <>
                        <AddNewOffCampusStudent
                          currentTab={filters?.status}
                          placementSession={placementSession}
                          collegeId={collegeId}
                          fetchStudentList={fetchStudentList}
                          dataLoading={dataLoading}
                          studentListOffCampus={studentListOffCampus}
                          setRefreshData={setRefreshData}
                          refreshData={refreshData}
                        />
                      </>
                    )}

                    <CSVLink
                      data={tempStudentList}
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        textAlign: "left",
                        marginLeft: "5px",
                      }}
                      filename={"Students-Offcampus.csv"}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        style={{ marginRight: "0px" }}
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                      >
                        Export
                      </Button>
                    </CSVLink>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <OffCampusTab
              loading={loading}
              filters={filters}
              setFilters={setFilters}
              setClickSearchButton={setClickSearchButton}
              setSelectedRowsData={setSelectedRowsData}
              selectedRowsData={selectedRowsData}
              setApplyClickedScore={setApplyClickedScore}
              filterDataText={filterDataText}
              setFilterDataText={setFilterDataText}
              collegeId={collegeId}
              userRolesPermission={userRolesPermission}
              setCallNavbar={setCallNavbar}
              clearData={clearStudentData}
              studentData={studentData}
              studentDataOffCampus={studentDataOffCampus}
              studentListOffCampus={studentListOffCampus}
              filterListOffCampusData={filterListOffCampusData}
              fetchStudentList={fetchStudentList}
              dataLoading={dataLoading}
              deleteOffCampusStudentData={deleteOffCampusStudentData}
              navbarDataOffCampus={navbarDataOffCampus}
              setDeleteReason={setDeleteReason}
              deleteReason={deleteReason}
              deleteReasonError={deleteReasonError}
              setDeleteReasonError={setDeleteReasonError}
              setRefreshData={setRefreshData}
              refreshData={refreshData}
              deleteResponse={deleteResponse}
              approveRejectOffCampus={approveRejectOffCampus}
            />
          </div>
        </>
      ) : (
        <>
          <NoDataFuncNew
            title={"Please Add Placement Cycle First"}
            subtitle={"Click the below Button to add placement Cycle"}
            buttonText={"Add Placement Cycle"}
            height={"320px"}
            width={"320px"}
            link={"/placement-cycles"}
          />
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
