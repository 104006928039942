import React from "react";
import {
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  makeStyles,
  InputBase,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CollegeFilterDrawer from "../../components/Input/FilterDrawer/CollegeFilterDrawer";
import SelectFeaturedCollege from "../../components/Cards/JobApplicationsInfo/SelectFeaturedCollege";
import ContactUsDialog from "../../components/DialogSection/ContactDialog/ContactUsDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    fontSize: "14px",
    color: "#c5c4ba",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    display: "flex",
    height: "40px",
    paddingLeft: "30px",
    marginRight: "10px",
    paddingRight: "30px",
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "8px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
  clearIndicator: {
    backgroundColor: "gray",
    "& span": {
      "& svg": {
        backgroundColor: "red",
      },
    },
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')",
        },
      },
    },
  },
}));

function JobRecommended() {
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div>
          <Typography variant="h2" sx={{ fontWeight: "600" }}>
            GetWork Fulfilled Job (100 K+)
          </Typography>
        </div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <ContactUsDialog source={"COLLEGES"} /> */}

          <ContactUsDialog />

          <Button
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              borderRadius: "8px",
              height: "48px",
              minWidth: "200px",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            Accept Jobs
          </Button>
        </Box>
      </Box>

      <Box style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search by Job Title"
                inputProps={{
                  style: { fontSize: "14px" },
                }}
              />

              <IconButton className={classes.iconButton} aria-label="search">
                <ClearIcon />
              </IconButton>

              <IconButton className={classes.iconButton} aria-label="search">
                {/* {!clickSearchButton &&  ? <SearchIcon /> : <ClearIcon />} */}
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item md={2}>
            <Autocomplete
              options={top100Films}
              getOptionLabel={(option) => option.title}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.title}
                </Typography>
              )}
              style={{ background: "#fff", borderRadius: 8 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Job Type"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={2}>
            <Autocomplete
              options={top100Films}
              getOptionLabel={(option) => option.title}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.title}
                </Typography>
              )}
              style={{ background: "#fff", borderRadius: 8 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Sort by"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={2} style={{ display: "grid" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                borderRadius: "8px",
                boxShadow: "none",
                height: "48px",
              }}
            >
              Clear Filter
            </Button>
          </Grid>
          <Grid item md={2}>
            <CollegeFilterDrawer />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Box>
              <FormControlLabel
                control={<Checkbox size="small" color="primary" />}
                label={`Select All Institutes `}
              />
              <br />
              <span
                style={{
                  color: "#6C757D",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                {`Total Counts of filter results: `}
              </span>
            </Box>
          </Grid>
          <Grid item md={4}>
            <SelectFeaturedCollege />
          </Grid>
          <Grid item md={4}>
            <SelectFeaturedCollege />
          </Grid>{" "}
          <Grid item md={4}>
            <SelectFeaturedCollege />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default JobRecommended;

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
];
