import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    marginBottom: "15px",
  },
  icon: {
    color: "#007bff",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function SaveEducation({
  education,
  index,
  setEditEducation,
  handleDelete,
  handleClickOpen,
  setIsEdit,
  setEditIndex,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{
              marginBottom: "4px",
            }}
          >
            {education?.highest_qualification}
          </Typography>

          <div style={{ display: "flex" }}>
            <IconButton
              color="primary"
              onClick={() => {
                setEditIndex(index);
                setIsEdit(true);
                handleClickOpen();
                setEditEducation(education);
              }}
            >
              <EditIcon />
            </IconButton>
            {!education?.is_current && (
              <IconButton
                color="primary"
                onClick={() => {
                  handleDelete(education?.education_id, index);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
        <Typography
          variant="body2"
          style={{
            marginBottom: "5px",
          }}
        >
          {education?.education > 2 ? (
            `${
              education?.college && education?.college !== 142
                ? education?.college_name
                : education?.temp_college_name
                ? education?.temp_college_name
                : education?.college_name
            }`
          ) : (
            <div>
              {education?.board_name} <br />
              {education?.college_name
                ? education?.college_name
                : education?.temp_college_name}
            </div>
          )}
        </Typography>
        {education?.education > 2 && (
          <Typography
            variant="body2"
            style={{
              color: "#6C757D",
              marginBottom: "5px",
            }}
          >
            {education?.degree} in {education?.specialization}
          </Typography>
        )}
        {education?.enrollment_id && (
          <Typography
            variant="body2"
            style={{
              marginBottom: "5px",
            }}
          >
            Enrollment Id: {education?.enrollment_id}
          </Typography>
        )}
        <Typography
          variant="body2"
          style={{
            marginBottom: "5px",
          }}
        >
          {education?.is_grade_system ? "CGPA" : "Percentage"}:{" "}
          {education?.is_grade_system ? education?.cgpa : education?.percentage}
        </Typography>

        <Typography variant="body2">
          {education?.start_date && education?.start_date !== null && (
            <>
              {moment(education?.start_date).format("MMM, YYYY")}{" "}
              {education.end_date && education.end_date !== null && (
                <>
                  {" - "}
                  {moment(education?.end_date).format("MMM, YYYY")}{" "}
                </>
              )}
            </>
          )}
        </Typography>
      </div>
    </>
  );
}
