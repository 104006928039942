import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { generateCsv } from "../../../utility/commaSeparatedStrings";
import { ApproveStudentsAPI } from "../../../api/ManageStudents/action";
import { useAlert } from "react-alert";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
    padding: "20px",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function ChangeRequestDialog({
  open,
  handleClickOpen,
  handleClose,
  userId,
  collegeId,
  getInvitationStatus,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [loading, setLoading] = useState(false);

  const reasonListInitialState = [
    {
      id: 1,
      message: "Basic detail is incorrect",
      checked: false,
      name: "Basic detail",
    },
    {
      id: 2,
      message: "Contact detail is incorrect",
      checked: false,
      name: "Contact detail",
    },

    {
      id: 3,
      message: "Skills detail is incorrect",
      checked: false,
      name: "Skills detail",
    },

    {
      id: 5,
      message: "Work experience detail is incorrect",
      checked: false,
      name: "Work experience detail",
    },
    {
      id: 6,
      message: "Education detail is incorrect",
      checked: false,
      name: "Education detail",
    },
    {
      id: 7,
      message: "Project Details is incorrect",
      checked: false,
      name: "Project Details",
    },
    {
      id: 8,
      message: "Publications Details is incorrect",
      checked: false,
      name: "Publications Details",
    },
    {
      id: 9,
      message: "Certifications Details is incorrect",
      checked: false,
      name: "Certifications Details",
    },

    // { id: 15, message: "Other Information", checked: false, name: "other" },
  ];
  const [reasonsList, setReasonList] = useState(reasonListInitialState);
  const otherReasonInitalState = {
    message: "",
    show: false,
  };
  const [otherReason, setOtherReason] = useState(otherReasonInitalState);
  const [textReason, setTextReason] = useState("");

  const handleClickClose = () => {
    setOtherReason(otherReasonInitalState);
    setReasonList(reasonListInitialState);
    setTextReason("");
    handleClose();
  };
  const handleChange = (item) => {
    const list = [...reasonsList];
    var index = list.findIndex((item2) => item2.id === item?.id);
    const newItem = { ...item, checked: !item?.checked };
    list.splice(index, 1, newItem);
    setReasonList(list);
    if (newItem?.name === "other") {
      newItem?.checked
        ? setOtherReason({ ...otherReason, show: newItem?.checked })
        : setOtherReason(otherReasonInitalState);
    }
  };

  const getMessageStr = (arr) => {
    let str = "";
    if (arr?.length > 0) {
      arr.forEach((item, _) => {
        if (item?.checked) {
          arr.indexOf(item) !== arr.length - 1
            ? (str += item?.name + ", ")
            : (str += item?.name);
        }
      });
    }
    return str;
  };

  const callback = () => {
    getInvitationStatus(userId);
  };
  const handleSubmit = () => {
    if (!otherReason?.message) {
      setOtherReason({
        ...otherReason,
        reasonError: true,
        reasonErrorMessage: "Please enter your reason",
      });
      return;
    }
    var data = {
      college_id: [collegeId],
      user_id: [userId],
      student_id: [],
      status: "CHANGE_REQUESTED",
      message: otherReason?.message,
    };
    const fromStudentDetail = true;
    dispatch(
      ApproveStudentsAPI(
        data,
        alert,
        history,
        location,
        [], //companyList
        handleClickClose,
        () => {}, //setCallNavbar
        setLoading,
        false, //changeStatus
        fromStudentDetail,
        callback
      )
    );
  };

  useEffect(() => {
    let messageString = getMessageStr(reasonsList);
    setOtherReason({
      ...otherReason,
      reasonError: false,
      reasonErrorMessage: "",
      message: messageString
        ? `${messageString?.substring(
            0,
            messageString.length - 1
          )} is incorrect. ${textReason ? textReason : ""}`
        : `${textReason ? textReason : ""}`,
    });
  }, [reasonsList]);

  const LIMIT = 512;
  useEffect(() => {
    setOtherReason({
      ...otherReason,
      reasonError: false,
      reasonErrorMessage: "",
      message: `${textReason ? textReason?.slice(0, LIMIT) : ""}`,
    });
  }, [textReason]);
  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClickClose}
      >
        <div
          style={{
            padding: "10px",
          }}
        >
          <Typography variant="h6">Select the requirement change</Typography>
        </div>

        <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
          {reasonsList?.map((item) => (
            <Grid item md={6} style={{ display: "grid" }}>
              <Box
                style={{
                  border: "1px solid",
                  borderColor: item.checked
                    ? theme.palette.primary.main
                    : "#6f6f6f",
                  borderRadius: "4px",
                }}
              >
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleRoundedIcon />}
                      onChange={() => {
                        handleChange(item);
                      }}
                      value={item?.message}
                      name={item?.name}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: item?.checked
                          ? theme.palette.primary.main
                          : "#6f6f6f",
                      }}
                    >
                      {item?.message}
                    </Typography>
                  }
                />
              </Box>
            </Grid>
          ))}

          {/* <Grid item md={6} style={{ display: "grid" }}>
            <Box
              style={{
                border: "1px solid",
                borderColor: state.checkedB
                  ? theme.palette.primary.main
                  : "#6f6f6f",
                borderRadius: "4px",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleRoundedIcon />}
                    onChange={handleChange}
                    value="Education detail is incorrect"
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: state.checkedB
                        ? theme.palette.primary.main
                        : "#6f6f6f",
                    }}
                  >
                    Education detail is incorrect
                  </Typography>
                }
              />
            </Box>
          </Grid>
          <Grid item md={6} style={{ display: "grid" }}>
            <Box
              style={{
                border: "1px solid",
                borderColor: state.checkedC
                  ? theme.palette.primary.main
                  : "#6f6f6f",
                borderRadius: "4px",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleRoundedIcon />}
                    onChange={handleChange}
                    value="Change Profile pic"
                    name="checkedC"
                    color="primary"
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: state.checkedC
                        ? theme.palette.primary.main
                        : "#6f6f6f",
                    }}
                  >
                    Change Profile pic
                  </Typography>
                }
              />
            </Box>
          </Grid>
          <Grid item md={6} style={{ display: "grid" }}>
            <Box
              style={{
                border: "1px solid",
                borderColor: state.checkedD
                  ? theme.palette.primary.main
                  : "#6f6f6f",
                borderRadius: "4px",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleRoundedIcon />}
                    onChange={handleChange}
                    value="Other Information"
                    name="checkedD"
                    color="primary"
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: state.checkedD
                        ? theme.palette.primary.main
                        : "#6f6f6f",
                    }}
                  >
                    Other Information
                  </Typography>
                }
              />
            </Box>
          </Grid> */}

          <Grid item md={12}>
            <Typography variant="body1" style={{ fontWeight: "600" }}>
              Write a message{" "}
              <span style={{ fontSize: "14px" }}>
                {otherReason?.message
                  ? `(${otherReason?.message?.length}/512)`
                  : ``}
              </span>
            </Typography>

            <TextField
              style={{ width: "100%", marginTop: "15px" }}
              variant="outlined"
              multiline
              value={otherReason?.message}
              onChange={(e) => {
                if (otherReason?.message?.length >= LIMIT) {
                  setTextReason(e.target.value?.slice(0, LIMIT));
                } else {
                  setTextReason(e.target.value);
                }
              }}
              error={otherReason?.reasonError ? true : false}
              helperText={otherReason?.reasonErrorMessage}
              rows={4}
              label="Change required message"
            />
          </Grid>

          <Grid item md={12}>
            <div className={classes.btngrid}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleSubmit}
                color="primary"
              >
                {loading ? <CustomButtonCircularProgress /> : "Request Change"}
              </Button>
            </div>
          </Grid>
        </Grid>

        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
