import { Grid, TextField, withStyles, InputAdornment, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StarPerformer from "./StarPerformer";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { BackendBaseApi } from "../../../constants/constants";
import axios from "axios";
import { useAlert } from "react-alert";
import { skeletonClasses } from "@mui/material";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const options = ["Option 1", "Option 2"];

const SearchTextField = withStyles({
  root: {
    backgroundColor: "#F6F6F6",
    borderRadius: 8,
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F5F5F5",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F5F5F5",
      },
      "&:hover fieldset": {
        borderColor: "#F5F5F5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F5F5F5",
      },
    },
  },
})(TextField);

function StartPerformerSection({selectedPlacement,collegeId}) {
  const [cardData,setCardData] = useState(null);
  const [courseData,setCourseData] = useState(null);
  const [loading,setLoading] = useState(false);
  const [tabvalue, setTabValue] = useState(0);

  const alert = useAlert();
  function handleChangeTab(id,newValue) {
    setTabValue(newValue);
  }
  useEffect(() => {
    if (selectedPlacement && collegeId) {
      axios
        .get(
          `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_data/get_courses/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`,
          { withCredentials: true }
        )
        .then((res) => {
          if (res?.data?.success) {
            setCourseData(res?.data?.data);
            // setTabValue(res?.data?.data[0])
          }
        })
        .catch((err) => alert.error(err?.data?.error));
    }

  }, [selectedPlacement, collegeId]);

  useEffect(()=>{
    if(selectedPlacement && collegeId){ 
    if(tabvalue?.degree_id && tabvalue?.course_id){  
      setLoading(true);
      axios.get(`${BackendBaseApi.SANCHALAN}api/placement_cycles/get_star_performer_data/?college_id=${collegeId}&placement_id=${selectedPlacement.id}&degree_id=${tabvalue?.degree_id}&course_id=${tabvalue?.course_id}`,{withCredentials:true})
   .then(res=>{
     if(res?.data?.success) setCardData(res?.data?.data);
     setLoading(false);
   }).catch(err=>{
     alert.error(err?.data?.error);
     setLoading(false);
   })
    }
    else{
      setLoading(true);
      axios.get(`${BackendBaseApi.SANCHALAN}api/placement_cycles/get_star_performer_data/?college_id=${collegeId}&placement_id=${selectedPlacement.id}`,{withCredentials:true})
      .then(res=>{
        if(res?.data?.success) setCardData(res?.data?.data);
        setLoading(false);
      }).catch(err=>{
        alert.error(err?.data?.error);
        setLoading(false);
      })
    } 
  }
   },[selectedPlacement,collegeId,tabvalue])

  return (
    <div style={{ backgroundColor: "#fff", borderRadius: 8 }}>
      <Grid container spacing={5} style={{ width: "100%", margin: 0 }}>
        <Grid item md={12}>
          {/* <Autocomplete
          id="combo-box-demo"
          value={ tabvalue?? {}}
          options={courseData ?? []}
          onChange={handleChangeTab}
          getOptionLabel={(option) => option.name}
            //popupIcon={<KeyboardArrowDownRoundedIcon />}
            renderInput={(params) => (
              <SearchTextField
                {...params}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Search Course"
              />
            )}

          /> */}

        {/* <Autocomplete
          value={tabvalue ?? {}}
          freeSolo
          id="free-solo-demo"
          options={courseData ?? []}
          onChange={handleChangeTab}
          getOptionLabel={option => option?.name}
          renderInput={(params) => (
            <SearchTextField
              {...params}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="Search Course"
            />
          )}
        /> */}

      <Autocomplete
      options={courseData ?? []}
      getOptionLabel={(option) => option?.name}
      onChange={handleChangeTab}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search Course"       
             InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <SearchIcon color="disabled" />
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
        </Grid>
       {loading ? (<div style={{textAlign:"center",width:"100%"}}><CircularProgress/></div>) : (<>{cardData?.map((detail) =>(
        <Grid item md={4}>
          <StarPerformer data={detail}  />
        </Grid>))}</>)}
      </Grid>
    </div>
  );
}

export default StartPerformerSection;
