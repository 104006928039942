import React from "react";
import Charst from "react-apexcharts";

function CampusBarGraph({ DATA }) {
  return (
    <div>
      <Charst
        options={{
          title: {
            text: "BAR GRAPH",
          },
          chart: {
            id: DATA?.heading
              ?.split(" ")
              ?.join("_")
              ?.replaceAll("(%)", "_Percent"),
            toolbar: {
              export: {
                csv: {
                  headerCategory: DATA?.xAxis,
                  // headerValue: DATA?.xAxis,
                },
              },
            },
          },
          xaxis: {
            title: {
              text: DATA?.xAxis ?? "",
              style: {
                fontSize: "14px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            labels: {
              // rotateAlways: true,
              // hideOverlappingLabels: true,
              rotate: -45,
              trim: true,
            },
            categories: DATA?.labels ?? [],

            //categories: placement?.categories ? placement?.categories : [],
          },

          colors: ["#CD393E"],
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: "10px",
              fontFamily: "Nunito",
              colors: ["#304758"],
            },
          },

          yaxis: {
            title: {
              text: DATA?.yAxis ?? "",
              style: {
                fontSize: "14px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },

            //  labels: {
            //   formatter: function (value) {
            //     return Math.round(value); // Rounds the value to an integer
            //   }
            // }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 8,
              columnWidth: "20%",
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          title: {
            text: DATA?.heading,
            style: {
              fontSize: "18px",
              fontFamily: "Nunito",
              fontWeight: 1000,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        }}
        series={[
          {
            name: DATA?.yAxis,
            data: DATA?.data,
          },
        ]}
        type="bar"
        height={280}
        //height="100%"
      />
    </div>
  );
}

export default CampusBarGraph;
