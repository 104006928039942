import {
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { userRolesActionsFn } from "../../../api/SignIn/selector";
import { checkForToken } from "../../../constants/ApiCall";
import { BackendBaseApi } from "../../../constants/constants";
import BasicDetails from "../../Cards/Student/BasicDetails";
import Contact from "../../Cards/Student/Contact";
import Education from "../../Cards/Student/Education";
import Feedback from "../../Cards/Student/FeedBack";
import Skill from "../../Cards/Student/Skill";
import WorkExpereince from "../../Cards/Student/WorkExpereince";
import CustomCircularProgress from "../../CircularProgressBar";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import Reel from "../../Cards/Student/Reel";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  button: {
    borderRadius: "8px",
    height: "50px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
  },
  btn: {
    borderRadius: "8px",
    // height: "100%",
    padding: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginRight: "10px",
    boxShadow: "none",
    //width: "100%",
    display: "flex",
  },

  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  btns: {
    borderRadius: "8px",
    boxShadow: "none",
    height: "45px",
    display: "flex",
  },
}));

export default function StudentResume({
  data,
  moveNextArray,
  moveToNext,
  setMoveToNext,
  MoveToNextStep,
  fromPublicTrack = false,
  validatorData,
  filters,
  navbarData,
  handleClose,
  open,
}) {
  const classes = useStyles();
  const [feedbackError, setFeedbackError] = useState(null);

  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [typedFeedback, setTypedFeedback] = useState("");

  const [studentData, setStudentData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    if (open) StudentDetailsAPI();
  }, [open]);

  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseButton = () => {
    setAnchorEl(null);
  };

  const StudentDetailsAPI = () => {
    setLoading(true);
    const url = fromPublicTrack
      ? `${BackendBaseApi.PRAVESH}api/shared/v2/public_profile/?user_id=${data?.user_id}`
      : `${BackendBaseApi.PRAVESH}api/saas/v1/student_profile/?user_id=${data?.user_id}`;
    axios
      .get(
        url,
        {
          withCredentials: true,
        } //    api/shared/v2/public_profile/?user_id=${data?.user_id} //api/saas/v1/student_profile/?user_id=${data?.user_id}
      )
      .then((res) => {
        if (res.data.success) {
          setStudentData(res?.data?.data);
          setLoading(false);
        } else {
        }
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoading(false);
      });
  };

  const validate = () => {
    var feedback = null;
    var is_valid = true;
    if (!typedFeedback) {
      feedback = "Please enter Feedback";
      is_valid = false;
    }
    setFeedbackError(feedback);
    return is_valid;
  };

  const [showTrack, setShowTrack] = useState(false);

  useEffect(() => {
    if (fromPublicTrack) {
      if (validatorData?.action_performed?.authority?.track === true) {
        setShowTrack(true);
      } else setShowTrack(false);
    } else {
      setShowTrack(true);
    }
  }, []);
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <div>
      {/* <Tooltip title={data?.name} placement="right">
        <Typography
          color="primary"
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => {
            if (!data.user_id) {
              alert.error(
                "Something wrong with this data, kindly connect with GetWork"
              );
            } else {
              handleClickOpen();
            }
          }}
          noWrap
        >
          {data?.name}
        </Typography>
      </Tooltip> */}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <div style={{ padding: "30px" }}>
            {loading ? (
              <>
                <CustomCircularProgress />
              </>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <br />
                    <BasicDetails
                      data={studentData}
                      fromTrack={true}
                      fromPublicTrack={fromPublicTrack}
                      validatorData={validatorData}
                    />
                    <br />
                    <Contact
                      data={studentData}
                      fromPublicTrack={fromPublicTrack}
                      validatorData={validatorData}
                    />{" "}
                    <br />
                    {studentData?.student_user_details?.skill?.length > 0 && (
                      <>
                        <Skill data={studentData} />
                        <br />
                      </>
                    )}{" "}
                    {/* {studentData?.student_user_details?.skill?.length > 0 && ( */}
                    <>
                      <Reel data={studentData} />
                      <br />
                    </>
                    {/* )}{" "} */}
                    <br />
                    {studentData?.student_user_details?.education?.length >
                      0 && (
                      <>
                        <br />
                        <Education data={studentData} />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <br />
                    {studentData?.student_user_details?.work_ex?.length > 0 && (
                      <>
                        <br />
                        <WorkExpereince data={studentData} />
                      </>
                    )}
                    {data?.assignment_list?.length > 0 && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            borderRadius: "8px",
                            boxShadow: "none",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            display: "flex",
                            height: "45px",
                            marginTop: "20px",
                          }}
                          onClick={() => {
                            data?.assignment_list?.forEach((item) => {
                              window.open(item?.file);
                            });
                          }}
                        >
                          Download Submitted Assignment
                        </Button>
                        <br />
                      </>
                    )}
                    <Feedback
                      data={data}
                      moveNextArray={moveNextArray}
                      moveToNext={moveToNext}
                      setMoveToNext={setMoveToNext}
                      MoveToNextStep={MoveToNextStep}
                      feedbackError={feedbackError}
                      setFeedbackError={setFeedbackError}
                      showTrack={showTrack}
                      filters={filters}
                      userRolesPermission={userRolesPermission}
                      typedFeedback={typedFeedback}
                      setTypedFeedback={setTypedFeedback}
                    />
                    <br />
                    {showTrack &&
                      navbarData &&
                      userRolesPermission?.some(
                        (item) =>
                          item?.access_control_key === "action_job_track"
                      ) &&
                      filters?.current_status !==
                        navbarData["joined"]?.round_id &&
                      filters?.current_status !==
                        navbarData["rejected"]?.round_id && (
                        <Grid container spacing={5}>
                          <Grid item xs={6} style={{ display: "grid" }}>
                            <Button
                              variant="outlined"
                              className={classes.btns}
                              onClick={() => {
                                if (validate()) {
                                  setMoveToNext({
                                    ...moveToNext,
                                    round_id: navbarData["rejected"]?.round_id,
                                    round_name: "Rejected",
                                  });
                                  var roundData = {
                                    round_id: navbarData["rejected"]?.round_id,
                                    round_name: "Rejected",
                                  };
                                  MoveToNextStep(
                                    setLoadingNext,
                                    true,
                                    data?.student_id,
                                    roundData
                                  );
                                  handleClose();
                                } else {
                                  alert.error(
                                    "Please enter feedback to reject the candidate"
                                  );
                                }
                              }}
                              color="primary"
                            >
                              Reject
                            </Button>
                          </Grid>
                          <Grid item xs={6} style={{ display: "grid" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.btns}
                              aria-controls="customized-menu"
                              aria-haspopup="true"
                              onClick={handleClickButton}
                            >
                              {loadingNext ? (
                                <CustomButtonCircularProgress />
                              ) : (
                                "Move to next Step"
                              )}
                            </Button>

                            <StyledMenu
                              id="customized-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleCloseButton}
                            >
                              {moveNextArray?.map((item) => (
                                <StyledMenuItem>
                                  <ListItemText
                                    primary={item.name}
                                    onClick={() => {
                                      setMoveToNext({
                                        ...moveToNext,
                                        round_id: item.round_id,
                                        round_name: item.name,
                                        feedback: { name: "other" },
                                        feedback_typed: typedFeedback,
                                      });
                                      var roundData = {
                                        round_id: item.round_id,
                                        round_name: item.name,
                                      };
                                      var feedbackData = {
                                        feedback: { name: "other" },
                                        feedback_typed: typedFeedback,
                                      };
                                      MoveToNextStep(
                                        setLoadingNext,
                                        true,
                                        data?.student_id,
                                        roundData,
                                        feedbackData
                                      );
                                      handleClose();
                                    }}
                                  />
                                </StyledMenuItem>
                              ))}
                            </StyledMenu>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Grid>{" "}
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
