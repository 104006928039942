import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import {
  Grid,
  Typography,
  LinearProgress,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Csv from "../../../assets/icons/csv.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UploadTable from "../../Table/UploadTable/UploadTable";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    //padding: "7px",
    height: "45px",
    // paddingLeft: "30px",
    //paddingRight: "30px",
    display: "flex",
  },
  progressgrid: {
    display: "flex",
    // flexDirection: "row",
    alignItems: "center",
  },
  progress: {
    display: "flex",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function ImportData({
  open,
  handleClickOpen,
  handleClose,
  totalLoad,
  dataLoaded,
  progress,
  fileName,
  handleCancelImport,
  resData
}) {

      
  const classes = useStyles();
  // const [progress, setProgress] = React.useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [parentSize, setParentSize] = useState(0);

  return (
    <div>
      {/* <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClickOpen}
        className={classes.button}
        // startIcon={<PublishIcon />}
      >
        Import
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              // paddingBlockEnd: "40px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">Uploading Excel File </Typography>{" "}
          </div>

          <Grid container spacing={3}>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                //  justifyContent: "center",
              }}
            >
              <img src={Csv} height="50px" width="50px" alt="icon" />
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              {" "}
              <div className={classes.pro}>
                <Typography variant="overline">{fileName}</Typography>
                <BorderLinearProgress variant="determinate" value={progress} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="overline" color="secondary">
                    {dataLoaded} MB of {totalLoad} MB
                  </Typography>
                  <Typography color="primary" variant="overline">
                   { resData?.flag ?  `Uploaded ${progress}% `:
                    `Uploading... ${progress}%`}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.progressgrid}>
                <Typography variant="body2">Total Uploaded Data:</Typography> 
                {
                (resData?.flag) ?  resData.total_entries  : 
                (<div className={classes.progress}>
                  <CircularProgress style={{ padding: "10px" }} />
                  {/* <Typography variant="body2">6</Typography> */}
                </div>  ) }
                
              </div>
              <div className={classes.progressgrid}>
                <Typography variant="body2">Sucess Entries:</Typography>
               {
                (resData?.flag) ? resData.user_created_count   : 
                (<div className={classes.progress}>
                  <CircularProgress style={{ padding: "10px" }} />
                </div>  ) }
              
               
              </div>
              <div className={classes.progressgrid}>
                <Typography variant="body2">Already Exist:</Typography>
                 {
                (resData?.flag) ? resData.email_already_exist  : 
                (<div className={classes.progress}>
                  <CircularProgress style={{ padding: "10px" }} />
                  {/* <Typography variant="body2">6</Typography> */}
                </div>  ) }
              </div>
              <div className={classes.progressgrid}>
                <Typography variant="body2">Failed Entries:</Typography>
                {
                (resData?.flag) ? resData.failed_entries  : 
                (<div className={classes.progress}>
                  <CircularProgress style={{ padding: "10px" }} />
                  {/* <Typography variant="body2">6</Typography> */}
                </div>  ) }
              </div>

              <div>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ padding: "0px", width: "35%" }}
                  >
                    <Typography variant="body2" color="primary">
                      Show Failed Entries
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "0px" }}>
                    <UploadTable rowEntries = {resData?.error_list} />
                  </AccordionDetails>
                </Accordion>
                 {/* <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ padding: "0px", width: "35%" }}
                  >
                    <Typography variant="body2" color="primary">
                      Show Already Existing Entries
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "0px" }}>
                    <UploadTable rowEntries = {resData?.already_exist_list} />
                  </AccordionDetails>
                </Accordion> */}

              </div>
            </Grid>
            <Grid item xs={6} style={{ display: "grid", paddingTop: "0px" }}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={ handleCancelImport}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid", paddingTop: "0px" }}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={ handleCancelImport}
                color="primary"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
