import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect, useState } from "react";
import AddCertificationDialog from "./AddCertificationDialog";
import SaveCertifications from "./SaveCertifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
}));

const Certifications = ({
  cerifications,
  handleAddCertifications,
  handlePatchCertifications,
  handleDeleteCertifications,
  certiDataLoading,
  approvalRequestCounts,
  redirectionData,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [tempCertifications, setTempCertifications] = useState([]);
  const [addCertifications, setAddCertifications] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  useEffect(() => {
    setTempCertifications(cerifications ? cerifications : []);
  }, [cerifications]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setAddCertifications({});
    setOpen(false);
  };

  const saveCertifications = () => {
    isEdit
      ? handlePatchCertifications(
          addCertifications,
          addCertifications?.id,
          editIndex,
          handleClose
        )
      : handleAddCertifications(addCertifications, handleClose);
  };
  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Courses and Ceritifications</Typography>
        <br />
        <Grid
          item
          xs={12}
          style={
            approvalRequestCounts?.CERT
              ? { pointerEvents: "none", opacity: 0.4 }
              : {}
          }
        >
          {" "}
          {tempCertifications &&
            tempCertifications?.map((certification, index) => {
              return (
                <SaveCertifications
                  certification={certification}
                  certiIndex={index}
                  setAddCertifications={setAddCertifications}
                  handleOpen={handleClickOpen}
                  handleDelete={handleDeleteCertifications}
                  setIsEdit={setIsEdit}
                  setEditIndex={setEditIndex}
                />
              );
            })}
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
          {approvalRequestCounts?.CERT ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.newBtnStyle}
              onClick={() => {
                history.push({
                  pathname: `/student-profile-request/${redirectionData?.name.replace(
                    / /g,
                    "_"
                  )}-${redirectionData?.userId}`,
                });
              }}
            >
              {`Approve Request (${approvalRequestCounts?.CERT ?? 0})`}
            </Button>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="text"
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                }}
                onClick={handleClickOpen}
              >
                Add Course or Certification
              </Button>
            </div>
          )}
        </Grid>
      </div>
      <AddCertificationDialog
        open={open}
        editCertifications={addCertifications}
        setEditCertifications={setAddCertifications}
        handleClose={handleClose}
        saveCertifications={saveCertifications}
        certiDataLoading={certiDataLoading}
        isEdit={isEdit}
      />
    </>
  );
};

export default Certifications;
