import { CHAT_ADD, DEPARTMENT_DATA } from "./action";
import { MEDIA_FILES } from "./action";

export const Departments = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_DATA:
      return { ...state, details: action.payload };
    case CHAT_ADD:
      return { ...state, chatdata: action.payload };

    default:
      return state;
  }
};

export const MediaFiles = (state = {}, action) => {
  switch (action.type) {
    case MEDIA_FILES:
      return { ...state, url: action.payload };
    default:
      return state;
  }
};
