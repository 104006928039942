import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";
import { useAlert } from "react-alert";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ForgotPasswordAgainAPI } from "../../../api/ForgotPassword/action";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    width: "60%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    borderRadius: "8px",
    margin: theme.spacing(3, 0, 2),
    boxShadow: "none",
  },
  paper2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  span: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    cursor: "pointer",
  },
  spanDisabled: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
}));

export default function CheckPasswordLink() {
  const classes = useStyles();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [timerComplete, setTimerComplete] = React.useState(false);
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  return (
    <>
      <div className={classes.paper2}>
        <div className={classes.paper}>
          {/* <Typography component="h1" variant="h5" color="inherit">
            Check your Register E-mail ?
          </Typography>
          <br /> */}

          <Typography variant="h6" color="secondary">
            We've sent you a link on your email address to verify your email.
          </Typography>
          <form className={classes.form} noValidate>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </Link>
            <Grid container>
              <Grid item xs>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body2" color="secondary">
                    Didn't receive the email?{" "}
                    <span
                      className={timerComplete ? classes.span : classes.spanDisabled}
                      onClick={() => {
                        if (location?.email && timerComplete) dispatch(ForgotPasswordAgainAPI(location?.email, alert));
                      }}
                    >
                      {" "}
                      Click here
                    </span>{" "}
                    to send the mail again in
                  </Typography>
                  <br />
                  {!timerComplete ? (
                    <Countdown
                      date={Date.now() + 30000}
                      onComplete={() => setTimerComplete(true)}
                      renderer={(props) => <Typography variant="h6">{props.seconds + " Sec"}</Typography>}
                    />
                  ) : (
                    <div>
                      <b>
                        {" "}
                        <Typography variant="h6">{0 + " Sec"}</Typography>
                      </b>
                    </div>
                  )}
                </div> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Still Didn't receive the email?
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: "7px" }}>
                      <CheckIcon
                        style={{
                          color: "#28a745",
                          fontSize: "18px",
                          marginRight: "10px",
                        }}
                      />
                      <Typography variant="subtitle2" color="secondary">
                        Is {location?.email} Your correct email without typos?
                        If not,
                        <Link
                          to={"/forgot-password"}
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <span className={classes.span}>
                            {" "}
                            Restart forgot password process.
                          </span>
                        </Link>
                      </Typography>
                    </div>
                    <div style={{ display: "flex", marginBottom: "7px" }}>
                      <CheckIcon
                        style={{
                          color: "#28a745",
                          fontSize: "18px",
                          marginRight: "10px",
                        }}
                      />
                      <Typography variant="subtitle2" color="secondary">
                        Check your spam folder
                      </Typography>
                    </div>
                    {/* <div style={{ display: "flex", marginBottom: "10px" }}>
                      <CheckIcon
                        style={{
                          color: "#28a745",
                          fontSize: "18px",
                          marginRight: "10px",
                        }}
                      />
                      <Typography variant="subtitle2" color="secondary">
                        Add getwork@m.joingetwork.com to your contacts
                      </Typography>
                    </div> */}
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Still having trouble?
                      <a
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        id="my_custom_link"
                      >
                        <span className={classes.span}> Contact Us</span>
                      </a>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
}
