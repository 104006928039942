import React from "react";
import { Skeleton } from "@material-ui/lab";

function RechargeSkelton({ height }) {
  return (
    <Skeleton
      variant="rect"
      height={height}
      style={{
        background:
          "linear-gradient(334.3deg, #D9D9D9 -17.63%, #F3F3F3 140.82%)",
        width: "100%",
        borderRadius: "10px",
      }}
    />
  );
}

export default RechargeSkelton;
