import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../api/Domain/selector";
import companyLogo from "../../assets/company/c5.png";
import logo from "../../assets/png/getwork-logo.png";
const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    //  marginTop: "50px",
  },
  topMargin: {
    marginTop: "5%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function RightSideComponent({
  ChildComponent,
  state = null,
  setState = null,
  errors = null,
  setErrors = null,
  nextStep = null,
  setNextStep = null,
  token=null
}) {
  const classes = useStyles();
  const details = useSelector(themeSelectorFn);
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={6}
      component={Paper}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      //  elevation={6}
      square
    >
      <div className={classes.topMargin}>
        <img
          src={details?.logo ? details?.logo : companyLogo}
          height="100px"
          width="auto"
          alt="logo"
        />
      </div>
      <ChildComponent
        state={state}
        setState={setState}
        errors={errors}
        setErrors={setErrors}
        setNextStep={setNextStep}
        nextStep={nextStep}
        token={token}
      />
      <CopyRightFooter />
    </Grid>
  );
}

export function CopyRightFooter() {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Typography
        variant="overline"
        style={{ fontWeight: "bold", marginRight: "10px" }}
      >
        Powered by
      </Typography>
      <img src={logo} height="20px" width="80px" />
    </div>
  );
}
