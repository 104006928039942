import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
export const OFF_STUDENT_DATA = "OFF_STUDENT_DATA";
export const OFF_STUDENT_FILTER_DATA = "OFF_STUDENT_FILTER_DATA";
export const OFF_STUDENT_NAVBAR = "OFF_STUDENT_NAVBAR";

export const StudentsGetAPI = (
  setLoading,
  alert,
  history,
  location,
  url,
  setClickSearchButton,
  setFirstApiCall,
  setApplyClickedScore,
  setStudentData
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      setStudentData(res.data.data);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    setClickSearchButton(false);
    setFirstApiCall(true);
    setApplyClickedScore(false);
    // dispatch({
    //   type: OFF_STUDENT_DATA,
    //   payload: res.data.data,
    // });
    // setStudentData(res.data);

    dispatch({
      type: OFF_STUDENT_FILTER_DATA,
      payload: res.data.filter_data,
    });
  };
};

export const Off_StudentsPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  college_id,
  fromPublicPost,
  studentList,
  handleClose,
  currentTab,
  setStep
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN + "api/manage_student/off_campus_jobs/",
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      });

    if (res.data.success) {
      alert.success("Student Added Successfully!!");
      if (!fromPublicPost) {
        if (
          currentTab === "All" ||
          // currentTab === "ALL" ||
          // currentTab === "all" ||
          currentTab === "UNPLACED"
        ) {
          var arr = [...studentList];

          arr.push(res.data);
          dispatch({
            type: OFF_STUDENT_DATA,
            payload: arr,
          });
        }

        handleClose();
      } else {
        setStep(1);
      }
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const StudentsPublicPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  college_id,
  fromPublicPost,
  studentList,
  handleClose,
  currentTab,
  setStep
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "public/add_student/?college_id=" +
          college_id,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      });

    if (res.data.success) {
      alert.success("Student Added Successfully!!");
      if (!fromPublicPost) {
        if (
          currentTab === "All" ||
          currentTab === "ALL" ||
          currentTab === "all" ||
          currentTab === "UNPLACED"
        ) {
          var arr = [...studentList];

          arr.push(res.data);
          dispatch({
            type: OFF_STUDENT_DATA,
            payload: arr,
          });
        }

        handleClose();
      } else {
        setStep(1);
      }
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const StudentNavbarAPI = (url) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, {
        withCredentials: true,
      })
      .catch((error) => {
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: OFF_STUDENT_NAVBAR,
        payload: res.data,
      });
      //   history.push("/manage-jobs");
    }
  };
};
