import { STUDENT_UPLOADER_FIELDS, SELECTED_STUDENT_UPLOADER_FIELDS, SELECTED_STUDENT_UPLOADER_FIELDS_PUBLIC } from "./action";

export const StudentUploader = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_UPLOADER_FIELDS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const StudentFields = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_STUDENT_UPLOADER_FIELDS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const StudentPublicFields = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_STUDENT_UPLOADER_FIELDS_PUBLIC:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};
