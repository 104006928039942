import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/Phone";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import moment from "moment";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    marginTop: "15px",
  },
  statusStyle: {
    width: "100px",
    marginLeft: "10px",
    height: "25px",
    borderRadius: "8px",
    color: "#fff",
    textAlign: "center",
  },
}));
export default function OnGoing({ onCampusOnGoingRejectedData }) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Link
        to={`/job/${onCampusOnGoingRejectedData?.job_title
          .toLowerCase()
          .replace(" ", "-")}-${onCampusOnGoingRejectedData?.job_id}`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Grid container spacing={2}>
          <Grid item sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item>
                <CorporateFareIcon style={{ color: "#6C757D" }} />
              </Grid>
              <Grid item xs style={{ display: "grid" }}>
                <Typography variant="body2" noWrap style={{ color: "#6C757D" }}>
                  Company Name: {onCampusOnGoingRejectedData.company_name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item>
                <WorkOutlineIcon style={{ color: "#6C757D" }} />
              </Grid>
              <Grid item xs style={{ display: "grid" }}>
                <Typography variant="body2" noWrap style={{ color: "#6C757D" }}>
                  Job Type:{" "}
                  {onCampusOnGoingRejectedData.job_type_id === 7
                    ? "Full Time"
                    : "Internship"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item>
                <BadgeOutlinedIcon style={{ color: "#6C757D" }} />
              </Grid>
              <Grid item xs style={{ display: "grid" }}>
                <Typography variant="body2" noWrap style={{ color: "#6C757D" }}>
                  Designation: {onCampusOnGoingRejectedData.job_title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item>
                <EventOutlinedIcon style={{ color: "#6C757D" }} />
              </Grid>
              <Grid item xs style={{ display: "grid" }}>
                <Typography variant="body2" noWrap style={{ color: "#6C757D" }}>
                  Updated On :
                  {onCampusOnGoingRejectedData?.placed_date &&
                    onCampusOnGoingRejectedData?.placed_date !== null &&
                    moment(onCampusOnGoingRejectedData?.placed_date).format(
                      " Do MMM, YYYY"
                    )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item>
                <CurrencyRupeeIcon style={{ color: "#6C757D" }} />
              </Grid>
              <Grid item xs style={{ display: "grid" }}>
                <Typography variant="body2" noWrap style={{ color: "#6C757D" }}>
                  CTC:
                  {onCampusOnGoingRejectedData.job_type_id === 7
                    ? onCampusOnGoingRejectedData.formatted_ctc
                    : onCampusOnGoingRejectedData.formatted_stipend}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} md={6} container spacing={2}>
            <Typography
              variant="body2"
              style={{ display: "inline-block", color: "#6C757D" }}
            >
              Status:
            </Typography>

            <Grid
              className={classes.statusStyle}
              style={{
                backgroundColor:
                  onCampusOnGoingRejectedData.round_name === "Hired"
                    ? "#51c03e"
                    : "	#ffcc00",
              }}
            >
              {onCampusOnGoingRejectedData.round_name}
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Link>
    </div>
  );
}

/* 
  <Link
        to={`/job/${data?.job_title.toLowerCase().replace(" ", "-")}-${
          data?.job_id
        }`}
      >
        <Button
          color="primary"
          variant="contained"
          size="small"
          style={{ boxShadow: "none", borderRadius: "30px" }}
        >
          View Job Description
        </Button>
      </Link>
*/
