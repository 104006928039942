import {
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import {
  CompanyGet,
  createAssignment,
  jobProfileGet,
  jobRoundGet,
} from "../../api/Assignment/action";
import {
  collegeDetailSelectorFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import Editor from "../../components/Editor";
// import Calendar from "../../components/Input/Calendar";
import Calendar from "../../components/Input/Calendar/index2";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  paper: {
    padding: "40px",
    borderRadius: "8px",
    boxShadow: "none",
    color: "#1d1d1d",
  },
  formControl: {
    marginTop: "15px",
    marginBottom: "15px",
    minWidth: "100%",
  },
  root: {
    //   padding: "0px 4px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",

    width: "auto",
    //  paddingLeft: "15px",
    height: "50px",
    borderRadius: "8px",
    border: ".5px solid #e2e6ea",
  },

  input: {
    marginLeft: theme.spacing(5),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    backgroundColor: "",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    height: "50px",
    color: "#fff",
    paddingLeft: "50px",
    //  backgroundColor: "#bfbfbf",
    paddingRight: "50px",
    boxShadow: "none",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    paddingLeft: "80px",
    paddingRight: "80px",
    height: "48px",
    margin: theme.spacing(0.5),
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
}));

export default function CreateAssignment() {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const hiddenFileInput = React.useRef(null);
  const [time, setTime] = useState(null);
  const [data, setData] = React.useState({
    companyid: null,
    jobProfileId: null,
    assignmentTitle: "",
    assignmentLink: "",
    deadline: "",
    time: "",
    jobRound: null,
    file: "",
  });

  const [editor, setEditor] = useState();

  const companyList = useSelector((state) => state?.assignment.companyData);
  const jobprofileList = useSelector(
    (state) => state?.assignment.jobProfileData
  );
  const jobRoundList = useSelector((state) =>
    state?.assignment?.jobRound ? state?.assignment?.jobRound : []
  );

  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const [deadline, setDeadline] = useState(null);

  useEffect(() => {
    const url = `api/assignment/company_job_dropdown/?is_remove_off_campus=${true}`;
    dispatch(CompanyGet(alert, history, location, url));
  }, []);

  const handleChange = (event) => {
    setData({
      ...data,
      companyid: event.target.value,
    });
  };

  const handleProfile = (event) => {
    setData({
      ...data,
      jobProfileId: event.target.value,
    });
  };

  // const handleJobround = event => {
  //   setData({
  //     ...data,
  //     jobRound: event.target.value,
  //   });
  // };

  useEffect(() => {
    if (data.companyid) {
      const url = `api/assignment/company_job_dropdown/?company_id=${data.companyid}`;
      dispatch(jobProfileGet(alert, history, location, url));
    }
  }, [data.companyid]);

  useEffect(() => {
    if (data.jobProfileId) {
      dispatch(jobRoundGet(alert, history, location, data.jobProfileId));
    }
  }, [data.jobProfileId]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = (event) => {
    const fileUploaded = event.target.files[0];
    setData({
      ...data,
      file: fileUploaded,
    });
  };

  const handleDelete = () => {
    setData({
      ...data,
      file: null,
    });
  };

  function handleClick1(event) {
    event.preventDefault();
  }

  const onTagsChange = (_, values) => {
    setData({
      ...data,
      jobRound: values,
    });
  };

  const create = () => {
    if (data.file !== "") {
      if (
        data.companyid &&
        data.jobProfileId &&
        data?.jobRound.length >= 1 &&
        editor &&
        data.deadline &&
        data.time &&
        (data.assignmentTitle || data.file)
      ) {
        var formData = new FormData();
        if (data.file !== "") {
          formData.append("assignment_file", data.file);
        }
        formData.append(
          "college_id",
          JSON.stringify([parseInt(collegeDetail.college_id)])
        );
        formData.append("job_id", data.jobProfileId);
        formData.append("company_id", data.companyid);
        formData.append("assignment_title", data.assignmentTitle);
        formData.append("assignment_link", data.assignmentLink);
        formData.append("description", editor);
        formData.append("round_list", JSON.stringify(data.jobRound));
        formData.append("assignment_deadline", `${data.deadline} ${data.time}`);
        setLoading(true);
        dispatch(
          createAssignment(setLoading, formData, alert, history, location)
        );
      } else {
        alert.error("Please fill all the details");
      }
    } else {
      if (
        data.companyid &&
        data.jobProfileId &&
        data.jobRound.length >= 1 &&
        data.assignmentTitle &&
        data.assignmentLink &&
        editor &&
        data.deadline &&
        data.time
      ) {
        var formData = new FormData();
        if (data.file !== "") {
          formData.append("assignment_file", data.file);
        }
        formData.append(
          "college_id",
          JSON.stringify([parseInt(collegeDetail.college_id)])
        );
        formData.append("job_id", data.jobProfileId);
        formData.append("company_id", data.companyid);
        formData.append("assignment_title", data.assignmentTitle);
        formData.append("assignment_link", data.assignmentLink);
        formData.append("description", editor);
        formData.append("round_list", JSON.stringify(data.jobRound));
        formData.append("assignment_deadline", `${data.deadline} ${data.time}`);
        setLoading(true);
        dispatch(
          createAssignment(setLoading, formData, alert, history, location)
        );
      } else {
        alert.error("Please fill all the details");
      }
    }
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          href="/"
          to="/assignments"
          // onClick={handleClick1}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" variant="body2">
            Assignments
          </Typography>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick1}
        >
          <Typography color="secondary" variant="body2">
            Create New Assignment
          </Typography>
        </Link>
      </Breadcrumbs>
      <Grid container spacing={3} style={{ marginTop: "5px" }}>
        <Grid item xs={6}>
          <Typography variant="h2">Create Assignment</Typography>

          <Typography variant="body2" style={{ color: "#6c757d" }}>
            Create your Assignment
          </Typography>
        </Grid>
        <Grid item xs={6}></Grid>
        <br />

        <Grid item xs={12} sm={10} style={{ marginTop: "10px" }}>
          <Paper className={classes.paper}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.floatingLabelFocusStyle} required>
                Select Company
              </InputLabel>
              <Select
                value={data.companyName}
                onChange={handleChange}
                label="Select Company"
                inputProps={{
                  style: {
                    padding: "16px",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                  },
                }}
                MenuProps={{
                  style: {
                    marginTop: "45px",
                  },
                }}
              >
                {/* <MenuItem aria-label="None" value="" /> */}
                {companyList?.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.floatingLabelFocusStyle} required>
                Select Job Profile
              </InputLabel>
              <Select
                value={data.jobProfileId}
                onChange={handleProfile}
                label="Select Job Profile"
                inputProps={{
                  style: {
                    padding: "16px",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                  },
                }}
              >
                {jobprofileList?.length === 0 ? (
                  <MenuItem aria-label="None" value="">
                    No Job Available
                  </MenuItem>
                ) : null}
                {jobprofileList?.map((item) => {
                  return <MenuItem value={item.id}>{item.job_title}</MenuItem>;
                })}
              </Select>
            </FormControl>
            {/* <Autocomplete
                multiple
                fullWidth
                onChange={onTagsChange}
                id="checkboxes-tags-demo"
                options={jobRoundList}
                disableCloseOnSelect
                getOptionLabel={option => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Send Assignment to"
                    margin="normal"
                    // className={classes.formControl}
                    // fullWidth
                    // InputLabelProps={{
                    //   className: classes.floatingLabelFocusStyle,
                    // }}
                    // inputProps={{
                    //   style: {
                    //     padding: "16px",
                    //   },
                    // }}
                  />
                )}
              /> */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              onChange={(event) =>
                setData({
                  ...data,
                  assignmentTitle: event.target.value,
                })
              }
              className={classes.formControl}
              fullWidth
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              label="Assignment Title"
            />

            <br />
            <Typography variant="h6" color="inherit">
              Upload File
            </Typography>
            <br />
            <Paper
              component="form"
              onClick={handleClick}
              className={classes.root}
            >
              <Button
                // onClick={handleClick}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Choose file
              </Button>
              <input
                type="file"
                ref={hiddenFileInput}
                name="file"
                onChange={handleFile}
                style={{ display: "none" }}
                // onClick={handleClick}
              />

              {/* <InputBase className={classes.input} placeholder={data.file !== null ? "File Selected" : "No file selected"} style={{ fontSize: "14px" }} /> */}
              {data?.file ? (
                <div>
                  <Chip
                    variant="outlined"
                    label={data.file?.name}
                    className={classes.chip}
                    size="small"
                    deleteIcon={<CloseIcon />}
                    color="primary"
                    onDelete={handleDelete}
                  />
                </div>
              ) : null}
            </Paper>
            <br />
            <Typography
              variant="body2"
              style={{ color: "#181818", marginBottom: "4px" }}
            >
              Supported files: Doc, Pdf, Csv, Zip, File size should not greater
              than
              <span style={{ fontWeight: "bold", color: "#000" }}>
                {" "}
                10 MB.{" "}
              </span>
            </Typography>

            {/* <AssignmentChip /> */}
            <TextField
              variant="outlined"
              margin="normal"
              onChange={(event) =>
                setData({
                  ...data,
                  assignmentLink: event.target.value,
                })
              }
              className={classes.formControl}
              fullWidth
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              label="Add Assignment Link"
            />
            <Typography
              variant="h6"
              color="inherit"
              style={{ marginTop: "10px" }}
            >
              Description
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <Editor state={editor} setState={setEditor} />
            </div>
            <br />
            <Typography variant="h6" color="inherit">
              Assignment Deadline
            </Typography>
            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item xs={4}>
                <Calendar
                  title="Application Deadline"
                  // selectedDate={moment(deadline).format("YYYY-MM-DD")}
                  selectedDate={deadline}
                  setSelectedDate={(date) => {
                    setData({
                      ...data,
                      deadline: moment(date).format("YYYY-MM-DD"),
                    });
                    setDeadline(date);
                  }}
                  readOnly={true}
                  minDate={new Date()}
                />
                {/* <Calendar
                  selectedDate={data.deadline}
                  title="Application Deadline"
                  onDateChange={(date) =>
                    setData({
                      ...data,
                      deadline: moment(date).format("YYYY-MM-DD"),
                    })
                  }
                  errorMessage={""}
                /> */}
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "-20px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    label="Select Time"
                    emptyLabel="hh:mm"
                    value={time}
                    onChange={(date) => {
                      setData({
                        ...data,
                        time: moment(date).format("HH:mm"),
                      });
                      // setTime(moment(date).format("HH:mm"));
                      setTime(date);
                    }}
                  />
                </MuiPickersUtilsProvider>

                {/* <TimePickerComponent
                  value={data.time}
                  format={"HH:mm"}
                  style={{ border: "none", borderBottom: "1px solid #fff" }}
                  placeholder="Select a time"
                  onChange={(e) => {
                    setData({
                      ...data,
                      time: moment(e.target.value).format("HH:MM"),
                    });
                  }}
                /> */}
              </Grid>
            </Grid>
            <br />
            <Typography variant="h6" color="inherit">
              Send Assignment To
            </Typography>

            <Autocomplete
              multiple
              fullWidth
              onChange={onTagsChange}
              id="checkboxes-tags-demo"
              options={jobRoundList}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Send Assignment to"
                  margin="normal"
                  // className={classes.formControl}
                  // fullWidth
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  // inputProps={{
                  //   style: {
                  //     padding: "16px",
                  //   },
                  // }}
                />
              )}
            />
          </Paper>
          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_assignment"
          ) && (
            <div style={{ marginTop: "20px" }}>
              {/* <Link to="/assignment" style={{ textDecoration: "none" }}> */}

              <Button
                onClick={create}
                variant="contained"
                color="primary"
                style={{ minWidth: "200px" }}
                className={classes.btn}
              >
                {loading ? <CustomButtonCircularProgress /> : <> Create</>}{" "}
              </Button>
              {/* </Link> */}
              <Link to="/assignment-track" style={{ textDecoration: "none" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.btn}
                >
                  Cancel
                </Button>
              </Link>
            </div>
          )}
        </Grid>
      </Grid>
      {/* </Layout> */}
    </>
  );
}
