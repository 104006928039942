import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, Chip } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import pSBC from "shade-blend-color";
import AssignNewMember from "../../../DialogSection/AddTeamMember/AssignNewMember";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { userRolesActionsFn } from "../../../../api/SignIn/selector";
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
  span: {
    color: theme.palette.primary.main,
  },
  text: {
    marginBottom: "7px",
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
}));

export default function AssignedTeamMember({ item, teamMemberList, jobId, collegeId, allData, setTeamMemberListing }) {
  const classes = useStyles();
  const [showMore, setShowMore] = React.useState(false);
  const [tempTeamMemberList, setTempTeamMemberList] = useState([]);
  useEffect(() => {
    if (teamMemberList?.length > 0) {
      var arr = [];
      if (item?.length > 0) {
        item?.map((item2) => {
          arr.push(item2?.team_member_id);
        });
        var new_arr = teamMemberList.filter((item3) => !arr.includes(item3?.id));
        setTempTeamMemberList(new_arr);
      } else {
        setTempTeamMemberList(teamMemberList);
      }
    }
  }, [teamMemberList]);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Assigned Team Member</Typography>{" "}
            {item?.length > 5 && (
              <Button
                color="primary"
                style={{ display: "flex" }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                {" "}
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
          </div>

          <div className={classes.chipsection}>
            {!showMore
              ? item?.slice(0, 5)?.map((data, index) => <Chip className={classes.chip} color="primary" variant="outlined" size="small" label={data?.name} />)
              : item?.map((data, index) => <Chip className={classes.chip} color="primary" variant="outlined" size="small" label={data?.name} />)}
          </div>
          <AssignNewMember teamMemberList={tempTeamMemberList} jobId={jobId} collegeId={collegeId} item={item} allData={allData} setTeamMemberListing={setTeamMemberListing} />
        </Paper>
      </div>
    </>
  );
}
