// import Demo from "../../../Assets/Images/png/microsoft.jpg";
// import logo from "../../../Assets/Images/png/getwork-logo.png";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import * as React from "react";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { niyuktiAxiosGetReq } from "../../../api/BaseApi/apiBase";
import { collegeFormCountSelectorFn } from "../../../api/SignIn/selector";
import companyBanner from "../../../assets/svg/company-banner.svg";
import defaultCompanyLogo from "../../../assets/svg/default_company_logo.svg";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import JobCategoryTags from "../../Common/Tags/JobCategoryTags";

const useStyles = makeStyles((theme) => ({
  skillText: {
    fontWeight: 600,
    color: "#000000",
    fontSize: 14,
  },
  logo: {
    margin: "auto",
    maxWidth: "44px",
    // marginBottom:10,
    // maxHeight: "93px",
    // marginTop: '14px',
    // marginLeft: '26px',
  },

  companytitle: {
    width: "270px",
    [theme.breakpoints.only("md")]: {
      width: "170px",
    },
  },
}));

const location = [
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 5C10.5 8.5 6 11.5 6 11.5C6 11.5 1.5 8.5 1.5 5C1.5 3.80653 1.97411 2.66193 2.81802 1.81802C3.66193 0.974106 4.80653 0.5 6 0.5C7.19347 0.5 8.33807 0.974106 9.18198 1.81802C10.0259 2.66193 10.5 3.80653 10.5 5Z"
      stroke="#1DAEFF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6.5C6.82843 6.5 7.5 5.82843 7.5 5C7.5 4.17157 6.82843 3.5 6 3.5C5.17157 3.5 4.5 4.17157 4.5 5C4.5 5.82843 5.17157 6.5 6 6.5Z"
      stroke="#1DAEFF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>,
];

export default function SelectFeaturedCollege({
  jobData,
  handleOpen,
  handleJobDetails,
  jobIndex,
  handleNotInterestedCard,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const collegeID = useSelector(
    (state) => state?.UserDetails?.user_details?.college_details?.college_id
  );

  const collegeFormCount = useSelector(collegeFormCountSelectorFn);

  const data = [
    {
      heading: "Job Type: ",
      // result: collegeDetail?.organization_type ? ` ${collegeDetail?.organization_type}` : " NA",
      result: jobData?.job_type_name ? (
        <Typography className={classes.skillText}>
          {`  ${jobData?.job_type_name}`}
        </Typography>
      ) : (
        " NA"
      ),
    },
    {
      heading: "CTC: ",
      // result: collegeDetail?.avg_ctc ? collegeDetail?.avg_ctc : "NA",
      result: jobData?.ctc_min ? (
        <Typography className={classes.skillText}>
          {`₹${(jobData?.ctc_min / 100000).toFixed(2)}-${(
            jobData?.ctc_max / 100000
          ).toFixed(2)}LPA`}
        </Typography>
      ) : (
        "NA"
      ),
    },
    {
      heading: "Skills:  ",
      // result: jobData?.year_founded ? `${moment(jobData?.year_founded).format("YYYY")}`: " NA",
      result: jobData?.["eligibility_criteria"]?.skills
        .slice(0, 4)
        .map((skill, index) => {
          return (
            <strong className={classes.skillText}>
              {`${!(index === 0) ? "," : ""} ${skill?.["skill_name"]}`}
            </strong>
          );
        }),
    },

    {
      heading: "Website: ",
      result: jobData?.company?.["company_website"] ? (
        <strong style={{ color: "#007BFF" }}>
          {jobData?.company?.["company_website"].substr(0, 30)}
        </strong>
      ) : (
        " NA"
      ),
    },
  ];

  const history = useHistory();
  const [undo, setUndo] = useState(false);
  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const totalJobs = useSelector((store) => store?.JobData?.details?.length);

  const handleJobRejection = async () => {
    let tempUndo;
    if (!undo) {
      tempUndo = "";
    } else {
      tempUndo = `&undo=1`;
    }
    const endPoint = `job/college_jobs/reject_recommended/?college_id=${collegeID}&job_id=${jobData?.job_id}${tempUndo}`;

    setUndo(!undo);

    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      // console.log("handle job rejection",res);
      const data = res?.data?.data;

      if (res?.data?.success) {
        alert?.success(data?.message);
      } else {
        alert?.error(res?.data?.error);
      }
    } catch (error) {
      alert?.error("Something went wrong!!");
    }
  };

  const getLocationDetails = () => {
    if (jobData?.job_location?.length > 0) {
      return `${jobData?.job_location[0]?.city} ${
        jobData?.job_location[1]?.city
          ? `,${jobData?.job_location[1]?.city}`
          : ""
      }            
            ${
              jobData?.job_location?.length > 2
                ? ` +${jobData?.job_location?.length - 2}`
                : ""
            }`;
    } else {
      /* return "NA"; */
      return "Work From Home";
    }
  };

  const getCompanyLogoSrc = () => {
    if (jobData?.company?.company_logo) {
      return jobData?.company?.company_logo;
    }
    if (jobData?.company?.["company_website"]) {
      return `//logo.clearbit.com/${jobData?.company?.["company_website"]}`;
    } else {
      return defaultCompanyLogo;
    }
  };

  return (
    <Card
      style={{
        // cursor: 'pointer' ,
        //  minWidth: 300,
        // maxHeight:'28rem',
        minHeight: "16rem",
        borderRadius: "8px",
        boxShadow: "0px 0px 4px 0px #00000040",
        // width:"22rem"
        width: "auto",
        // paddingRight:20
      }}
    >
      <CardMedia style={{ position: "relative" }}>
        <img
          src={jobData?.company?.company_banner || companyBanner}
          style={{
            height: "150px",
            objectFit: "cover",
          }}
          width="100%"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = companyBanner;
          }}
        />
        <Box
          style={{
            position: "absolute",
            top: "12px",
            left: "12px",
            // backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          <Box
            style={{
              width: 127,
              height: 46,
              // padding: "10px",
            }}
          >
            <img
              className={classes.logo}
              style={{
                backgroundColor: "white",
                // margin: "auto",
                // display: "block",
                // height: "150px",
                // width: "150px",
                // maxWidth: "100%",
                // maxHeight: "100%",
              }}
              //className={classes.img}
              alt="complex"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultCompanyLogo;
              }}
              src={getCompanyLogoSrc()}
            />
          </Box>
        </Box>
      </CardMedia>
      <CardContent
        style={{
          // padding: "16px",
          cursor: "pointer",
          height: "16rem",
          // minWidth:'12rem',
          // width:"22rem",
          // marginLeft:6,
        }}
        onClick={() => {
          const url = `${window.location.origin}/getwork-job/${jobData?.job_id}`;
          window.open(url, "_blank").focus();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // marginRight:'25%',
            position: "relative",
          }}
        >
          <section
            style={
              {
                // width:'80%',
              }
            }
          >
            <Typography
              gutterBottom
              variant="h4"
              noWrap
              className={classes.companytitle}
              // variant="h5"
              style={{ fontWeight: 600, fontSize: 16 }}
            >
              {jobData?.company?.company_name}
            </Typography>
          </section>
          <div style={{ position: "absolute", right: -18, top: -22 }}>
            <JobCategoryTags type={jobData?.job_category || "HOT"} />
          </div>
        </div>

        <Typography
          gutterBottom
          // variant="h4"
          style={{ fontSize: 15, fontWeight: 700 }}
          component="div"
        >
          {jobData?.job_title}
        </Typography>

        <Typography
          variant="body2"
          color="text.secondary"
          style={{ marginBottom: 10 }}
        >
          {location} {"   "}
          <strong style={{ fontWeight: 600, marginLeft: 6, fontSize: 14 }}>
            {getLocationDetails()}
          </strong>
        </Typography>
        {data?.map((value, index) => (
          <Box>
            <div
              style={{
                // backgroundColor: "#F6F6F6",
                display: "inline-flex",
                borderRadius: "8px",
                paddingRight: "8px",
                paddingTop: "2px",
                paddingBottom: "2px",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <Typography style={{ color: "#7E8392" }} variant="body2">
                {value.heading}
              </Typography>
              <Typography
                style={{ color: "black", fontWeight: 600, marginLeft: 6 }}
                variant="body2"
              >
                {value.result}
              </Typography>
            </div>
          </Box>
        ))}
      </CardContent>
      <CardContent
        style={{ paddingTop: 0, paddingBottom: 10, marginBottom: 16 }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box style={{ display: "contents" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                height: "48px",
                borderRadius: "8px",
                width: "100%",
                marginRight: "10px",
              }}
              disabled={
                undo ||
                !(
                  collegeFormCount?.job_form_count > 0 ||
                  collegeFormCount?.internship_form_count > 0
                )
              }
              onClick={() => {
                if (licenceData && licenceData?.display) {
                  alert.error(licenceData?.display_message);
                  return;
                }
                if (
                  collegeFormCount?.job_form_count > 0 ||
                  collegeFormCount?.internship_form_count > 0
                ) {
                  history.push(
                    `/post-recommended-job/recommended-${jobData?.job_id}`
                  );
                } else {
                  history.push("/job-posting-form/full-time-job");
                }
              }}
            >
              {collegeFormCount?.job_form_count > 0 ||
              collegeFormCount?.internship_form_count > 0 ? (
                "Accept & Post"
              ) : collegeFormCount?.job_form_count === 0 &&
                collegeFormCount?.internship_form_count === 0 ? (
                "Please fill Post form Creation"
              ) : (
                <CustomButtonCircularProgress />
              )}
            </Button>

            <IconButton style={{ border: "1px solid #000" }}>
              <Tooltip
                title={undo ? " Undo" : "Job Not Relevant"}
                placement="right"
              >
                <ThumbDownOutlinedIcon
                  style={{ color: ` ${undo ? "#FF0000" : "#000"}` }}
                  onClick={() => {
                    handleJobRejection();
                  }}
                />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
