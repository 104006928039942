import { Chip, LinearProgress, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { AddTeamMemberAPI } from "../../../api/JobPost/action";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "40px",
    paddingRight: "40px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  primebtn: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function AddTeamMember({ selectedRowsData, setSelectedRowsData, selectedID, setSelectedID, teamMemberList, jobList, collegeId }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const [teamMember, setTeamMember] = useState(null);
  const [teamMemberError, setTeamMemberError] = useState(null);
  const [showMore, setShowMore] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };
  const [loader, setLoader] = useState(false);
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const validateData = () => {
    var is_valid = true;
    var team_error = null;
    if (!teamMember) {
      is_valid = false;
      team_error = "Please Enter Active Placement Cycle";
    }
    setTeamMemberError(team_error);
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      var arr = [];
      var new_arr = [];
      selectedRowsData?.map((item) => arr.push(item.id));

      var data = {
        college_id: [collegeId],
        object_id: teamMember?.id,
        job_id_list: arr,
      };
      dispatch(AddTeamMemberAPI(data, alert, history, location, jobList, handleClose, setLoader, collegeId, setSelectedRowsData));
    }
  };

  return (
    <div>
      <Button color="primary" size="small" variant="contained" className={classes.primebtn} onClick={handleClickOpen}>
        Add Team Member
      </Button>
      <Dialog fullScreen={fullScreen} maxWidth="md" PaperProps={{ classes: { root: classes.root1 } }} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">Add Team Member</Typography>
          </div>
          <Typography variant="body2" style={{ color: "#6c757d", marginLeft: "10px" }}>
            Selected Jobs
          </Typography>
          <div component="ul" className={classes.root}>
            {!showMore
              ? selectedRowsData.slice(0, 5).map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.job_title}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : selectedRowsData.map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.job_title}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
          </div>

          {selectedRowsData?.length > 5 && (
            <Button
              className={classes.button}
              onClick={() => {
                setShowMore(!showMore);
              }}
              style={{
                padding: "5px",
                marginLeft: "7px",
                display: "flex",
                justifyContent: "flex-start",
              }}
              endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              color="primary"
            >
              {showMore ? "Hide" : "View All"}
            </Button>
          )}
          <br />
          <Autocomplete
            id="country-select-demo"
            options={teamMemberList}
            getOptionLabel={(option) => option.name}
            value={teamMember}
            onChange={(e, newValue) => {
              setTeamMember(newValue);
              setTeamMemberError(null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={"Team Member"}
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={teamMemberError ? true : false}
                helperText={<span>{teamMemberError}</span>}
              />
            )}
          />

          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Button autoFocus variant="outlined" className={classes.button} onClick={handleClose} color="primary">
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                if (!loader) handleSubmit();
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
