import {
  Box,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DegreeSpecializationCustom from "../../DegreeSpecializationCustom";
import AddEligible from "./AddEligible";
import DegreeSpecializationMultipleCollege from "../../DegreeSpecializationCustom/DegreeSpecializationMultipleCollege";
import ChipCustom from "../../Form/ChipCourses/index2";
import DegreeSpecializationDialog from "./DegreeSpecializationDialog";
import { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function AllCourseGrid({
  allCollegeCoursesSet,
  setallCollegeCoursesSet,
  collegesList,
  loadingFromData,
  loading,
  setGlobalCoursesList,
  nestedColleges,
  groups,
}) {
  const [selectedCoursesArray, setSelectedCoursesArray] = useState([]);
  const selectCoursesArray = (coursesArray) => {
    setSelectedCoursesArray(coursesArray);
  };

  const [open, setOpen] = useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <Box>
        <Grid container spacing={0} style={{ margin: 0, width: "100%" }}>
          {nestedColleges ? (
            <Grid item sm={12}>
              {/* {console.log("qwerty", groups, collegesList)} */}
              {groups?.map(
                (groups, index) =>
                  collegesList?.some(
                    (college) =>
                      groups?.colleges.includes(college.id) &&
                      college.checked === true
                  ) && (
                    <>
                      <Accordion style={{ boxShadow: "none" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-label="Expand"
                          aria-controls="additional-actions1-content"
                          id="additional-actions1-header"
                        >
                          <Typography color="black">
                            {groups?.group_name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0, margin: 0 }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            {collegesList
                              ?.filter((college) => college.checked)
                              ?.filter((college) => {
                                return groups?.colleges.includes(college.id);
                              })
                              ?.map((college, index) => (
                                <Grid
                                  container
                                  spacing={0}
                                  style={{ margin: 0, width: "100%" }}
                                >
                                  {!loadingFromData ? (
                                    <Grid
                                      item
                                      md={6}
                                      style={{
                                        borderTop: "1px solid #E0E0E0",
                                        borderBottom: "1px solid #E0E0E0",
                                        borderRight: "1px solid #E0E0E0",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <AddEligible
                                          details={college?.details}
                                        />
                                      </div>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      md={6}
                                      style={{
                                        borderTop: "1px solid #E0E0E0",
                                        borderBottom: "1px solid #E0E0E0",
                                        borderRight: "1px solid #E0E0E0",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignTtems: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <Skeleton
                                          variant="rect"
                                          width={560}
                                          height={108}
                                          style={{
                                            borderTopRightRadius: "5px",
                                            borderTopLeftRadius: "5px",
                                            marginLeft: 20,
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                  )}
                                  {!loadingFromData ? (
                                    <Grid
                                      item
                                      md={6}
                                      style={{
                                        borderTop: "1px solid #E0E0E0",
                                        borderBottom: "1px solid #E0E0E0",
                                        paddingRight: 20,
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          height: "100%",
                                          padding: "0px 20px 0px 20px",
                                          flexDirection: "column",
                                          marginTop: 5,
                                        }}
                                      >
                                        <DegreeSpecializationMultipleCollege
                                          collegeId={college?.id}
                                          allCollegeCoursesSet={
                                            allCollegeCoursesSet
                                          }
                                          coursesArray={
                                            allCollegeCoursesSet[college?.id]
                                          }
                                          setCoursesArray={
                                            setallCollegeCoursesSet
                                          }
                                          errorMessage={null}
                                          loading={loading}
                                          setGlobalCoursesList={
                                            setGlobalCoursesList
                                          }
                                        />
                                        <div style={{ marginBottom: "10px" }}>
                                          <ChipCustom
                                            courseArray={
                                              allCollegeCoursesSet[college?.id]
                                            }
                                            displayLimit={3}
                                            openDialog={openDialog}
                                            selectCoursesArray={
                                              selectCoursesArray
                                            }
                                            primaryItems={"degrees"}
                                            primaryName={"degree_name"}
                                            secondaryName={
                                              "specialization_name"
                                            }
                                            secondaryItems={"specialization"}
                                          />
                                          {/* {data?.length > 0 && ( */}
                                          {/* <Button
                            onClick={() => {
                              openDialog();
                              selectCoursesArray(
                                allCollegeCoursesSet[college?.id]
                              );
                            }}
                            style={{ width: "90px" }}
                          >
                            Show All
                          </Button> */}
                                        </div>
                                      </Box>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      md={6}
                                      style={{
                                        borderTop: "1px solid #E0E0E0",
                                        borderBottom: "1px solid #E0E0E0",
                                        borderRight: "1px solid #E0E0E0",
                                        paddingRight: 20,
                                      }}
                                    >
                                      <Skeleton
                                        variant="rect"
                                        width={560}
                                        height={108}
                                        style={{
                                          borderTopRightRadius: "5px",
                                          borderTopLeftRadius: "5px",
                                          marginLeft: 20,
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )
              )}
            </Grid>
          ) : (
            collegesList
              ?.filter((college) => college.checked)
              ?.map((college, index) => (
                <>
                  {!loadingFromData ? (
                    <Grid
                      item
                      md={6}
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                        borderRight: "1px solid #E0E0E0",
                      }}
                    >
                      <AddEligible details={college?.details} />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={6}
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                        borderRight: "1px solid #E0E0E0",
                      }}
                    >
                      <Skeleton
                        variant="rect"
                        width={560}
                        height={108}
                        style={{
                          borderTopRightRadius: "5px",
                          borderTopLeftRadius: "5px",
                          marginLeft: 20,
                        }}
                      />
                    </Grid>
                  )}
                  {!loadingFromData ? (
                    <Grid
                      item
                      md={6}
                      style={{ borderBottom: "1px solid #E0E0E0" }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          padding: "0px 20px 0px 20px",
                          flexDirection: "column",
                        }}
                      >
                        <DegreeSpecializationMultipleCollege
                          collegeId={college?.id}
                          allCollegeCoursesSet={allCollegeCoursesSet}
                          coursesArray={allCollegeCoursesSet[college?.id]}
                          setCoursesArray={setallCollegeCoursesSet}
                          errorMessage={null}
                          loading={loading}
                          setGlobalCoursesList={setGlobalCoursesList}
                        />
                        <div style={{ marginBottom: "10px" }}>
                          <ChipCustom
                            courseArray={allCollegeCoursesSet[college?.id]}
                            displayLimit={3}
                            openDialog={openDialog}
                            selectCoursesArray={selectCoursesArray}
                            primaryItems={"degrees"}
                            primaryName={"degree_name"}
                            secondaryName={"specialization_name"}
                            secondaryItems={"specialization"}
                          />
                          {/* {data?.length > 0 && ( */}
                          {/* <Button
                          onClick={() => {
                            openDialog();
                            selectCoursesArray(
                              allCollegeCoursesSet[college?.id]
                            );
                          }}
                          style={{ width: "90px" }}
                        >
                          Show All
                        </Button> */}
                        </div>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={6}
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                        borderRight: "1px solid #E0E0E0",
                      }}
                    >
                      <Skeleton
                        variant="rect"
                        width={560}
                        height={108}
                        style={{
                          borderTopRightRadius: "5px",
                          borderTopLeftRadius: "5px",
                          marginLeft: 20,
                        }}
                      />
                    </Grid>
                  )}
                </>
              ))
          )}
        </Grid>
      </Box>
      <DegreeSpecializationDialog
        open={open}
        coursesArray={selectedCoursesArray}
        handleClose={closeDialog}
        primaryItems={"degrees"}
        primaryName={"degree_name"}
        secondaryName={"specialization_name"}
        secondaryItems={"specialization"}
      />
    </>
  );
}

export default AllCourseGrid;
