import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import collegelogo from "../../../../assets/png/lpu.png";
import LanguageIcon from "@material-ui/icons/Language";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import { Divider, Button } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SubjectIcon from "@material-ui/icons/Subject";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: "auto",
    maxWidth: 500,
    boxShadow: "rgb(0 0 0 / 5%) 0px 3px 24px",
    borderRadius: "8px",
  },
  paperchild: {
    padding: theme.spacing(2),
  },
  image: {
    width: 100,
    height: 100,
  },
  img: {
    margin: "auto",
    display: "block",

    maxWidth: "100%",
    maxHeight: "100%",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function ManageUniversityInterview() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.paperchild}>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase className={classes.image}>
                <img className={classes.img} alt="complex" src={collegelogo} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid container spacing={2}>
                {Data.map((item) => (
                  <Grid item xs={12}>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item className={classes.grid}>
                        {item.icon}
                      </Grid>
                      <Grid item xs className={classes.grid}>
                        <Typography
                          variant="body2"
                          color="secondary"
                          style={{ fontSize: "14px" }}
                        >
                          {item.info}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className={classes.paperchild}>
          <Button
            color="primary"
            style={{ display: "flex" }}
            endIcon={<ChevronRightIcon />}
          >
            View All Interview Info
          </Button>
        </div>
      </Paper>
    </div>
  );
}

const Data = [
  {
    icon: <ApartmentIcon color="secondary" />,
    info: "Maharaja Surajmal Institute",
  },
  {
    icon: <LocationOnOutlinedIcon color="secondary" />,
    info: "Janakpuri, Delhi",
  },

  {
    icon: <SubjectIcon color="secondary" />,
    info: "Interviews Count: 4",
  },
];
