import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Chip, Grid, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import pSBC from "shade-blend-color";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    borderRadius: "8px",
  },
  paper: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  newBtnStyle: {
    maxWidth: "200px",
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));

export default function Hobbies({
  hobbies,
  postHobbies,
  hobbieDataLoading,
  approvalRequestCounts,
  redirectionData,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [tempHobbies, setTempHobbies] = useState([]);
  const [addedHobby, setAddedHobby] = useState("");
  const alert = useAlert();

  const handleDelete = (index) => () => {
    setTempHobbies((tempHobbies) =>
      tempHobbies.filter((chip, hobbyIndex) => hobbyIndex !== index)
    );
  };

  useEffect(() => {
    setTempHobbies(hobbies ? hobbies : []);
  }, [hobbies]);

  const addHobby = () => {
    setTempHobbies([...tempHobbies, addedHobby]);
    setAddedHobby("");
  };

  const validateData = () => {
    if (tempHobbies.length === 0) {
      alert?.error("Please add atleast 1 hobby");
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className={classes.paper}>
      <Typography variant="h6">Hobbies</Typography>
      <Grid
        item
        xs={12}
        style={
          approvalRequestCounts?.HOB
            ? { pointerEvents: "none", opacity: 0.4 }
            : {}
        }
      >
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={10} style={{ display: "grid" }}>
              <TextField
                id="outlined-basic"
                label="Add Hobby"
                //  size="small"
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                onChange={(e) => {
                  setAddedHobby(e.target.value);
                }}
                value={addedHobby}
              />
            </Grid>
            <Grid item xs={2} style={{ display: "grid" }}>
              <Button
                style={{ display: "flex", borderRadius: "8px" }}
                startIcon={<AddCircleOutlineIcon />}
                variant="text"
                color="primary"
                disabled={addedHobby ? false : true}
                onClick={() => {
                  addHobby();
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className={classes.root}>
          {tempHobbies &&
            tempHobbies.map((data, index) => {
              return (
                <li key={index}>
                  <Chip
                    color="primary"
                    deleteIcon={<CloseIcon color="primary" />}
                    variant="outlined"
                    label={data}
                    onDelete={handleDelete(index)}
                    className={classes.chip}
                    size="small"
                  />
                </li>
              );
            })}
        </div>
      </Grid>

      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          className={classes.newBtnStyle}
          // disabled={tempHobbies && tempHobbies.length > 0 ? false : true}
          onClick={
            approvalRequestCounts?.HOB
              ? () => {
                  history.push({
                    pathname: `/student-profile-request/${redirectionData?.name.replace(
                      / /g,
                      "_"
                    )}-${redirectionData?.userId}`,
                  });
                }
              : () => {
                  if (validateData()) {
                    postHobbies(tempHobbies);
                  }
                }
          }
        >
          {hobbieDataLoading ? (
            <CustomButtonCircularProgress />
          ) : approvalRequestCounts?.HOB ? (
            `Approve Request (${approvalRequestCounts?.HOB})`
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
}
