import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { useSelector } from "react-redux";
import {
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boxShadow: "none",

    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

function createData(name, enrollment) {
  return { name, enrollment };
}

const rows = [
  createData("John Smith", "3121123"),
  createData("John Smith", "3121123"),
  createData("John Smith", "3121123"),
  createData("John Smith", "3121123"),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function BlockedStatusTable({ data }) {
  const classes = useStyles();

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{`${entityInfo?.college} Id`}</StyledTableCell>
            <StyledTableCell align="center">{`${entityInfo?.college} Name`}</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 &&
            data?.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                  component="th"
                  scope="row"
                >
                  {row.id}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ border: ".5px solid #ced4da" }}
                >
                  {row?.is_blocked ? (
                    <span style={{ color: "red" }}>Blocked</span>
                  ) : (
                    <span style={{ color: "#38cb43" }}>Active</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
