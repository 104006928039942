import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  EventAvailableOutlined,
  KeyboardArrowDownSharp,
  KeyboardArrowUpSharp,
  TimelapseOutlined,
} from "@material-ui/icons";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import LinkIcon from "@material-ui/icons/Link";
import PeopleIcon from "@material-ui/icons/People";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import WorkIcon from "@material-ui/icons/Work";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import { AutoGraphOutlined } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { isGradeSystemFn, themeSelectorFn } from "../../../api/Domain/selector";
import {
  collegeDetailSelectorFn,
  isEducationGroupFn,
  isCollegeGroupFn,
} from "../../../api/SignIn/selector";
import defaultCompany from "../../../assets/img/default.jpg";
import { BackendBaseApi } from "../../../constants/constants";
import { checkForUrl } from "../../../utility/checkForUrl";
import { generateCsvNew } from "../../../utility/commaSeparatedStrings";
import kFormatter, {
  LFormatter,
  MFormatter,
} from "../../../utility/salaryFormatter";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import ArrowCards from "../ArrowCards";
import ChipSection from "./chipSection";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 10px 0px #0000001C",
    // boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      padding: "0px !important",
      boxShadow: "none",
    },
  },
  image: {
    display: "flex",
    width: 150,
    height: 80,
    borderRadius: "8px",
    border: ".5px solid #e2e6ea",
  },
  img: {
    margin: "auto",
    //  display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    //  borderRadius: "50%",
  },
  grid: {
    margin: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoitem: {
    display: "flex",
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  info_icon: {
    color: "#6c757d",
    height: "18px",
  },
  detail: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    alignContent: "center",
  },
  location: {
    color: "#6c757d",
    marginTop: "20px",
    [theme.breakpoints.only("xs")]: {
      marginTop: "0px",
    },
  },
  skill: {
    marginTop: "15px",
    // color: "#6c757d",
  },
  btn: {
    marginTop: "15px",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "row",
    alignItems: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  check: {
    backgroundColor: "#58aaff",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "none",
  },
  arrowcard: {},
  share: {
    border: "none",
    marginInline: "1rem",
    marginTop: "-5px",
  },
  maingrid: {
    margin: 0,
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      padding: "0px !important",
    },
  },
  paperTab: {
    position: "sticky",
    top: 55,
    zIndex: 1000,
    backgroundColor: "#fff",
    marginBottom: 20,
    boxShadow: "0px 4px 4px 0px #00000026",
    borderBottom: "1px solid #ABABAB",
  },
  mobileview: {
    [theme.breakpoints.only("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
    },
  },
  alljobs: {
    border: "1px solid #C5C5C5",
    padding: 10,
    borderRadius: "8px",
    marginBottom: "5px !important",
  },
}));

export default function JobDetailsCard({
  data,
  fromJobDetail,
  jobStatus = true,
  getworkjobText,
  fromRecommendedJobs,
  from,
  loginSucessful,
  applied,
  handleClickOpen,
  OTHER_JOB_DATA,
  setJobId,
  disabled,
  loadingApply,
  liveJobs = false,
}) {
  const classes = useStyles();
  function valueLabelFormat(value) {
    var new_value;
    if (value > 99999) {
      if (value > 999999) new_value = MFormatter(value);
      else new_value = LFormatter(value);
    } else new_value = kFormatter(value);
    return new_value;
  }

  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [showRoundList, setShowRoundList] = useState(false);
  const [scores, setScores] = useState([]);
  const [doc, setDoc] = useState([]);
  const [eligibility, setEligibility] = useState([]);
  const [roundList, setRoundList] = useState([]);
  const [qualifications, setQualifications] = useState({});
  const [specLength, setSpecLength] = useState(0);
  const sourceOfJob = useSelector(
    (state) => state?.SingleJobData?.details?.job_source
  );
  const jobDetails = useSelector((store) => store?.SingleJobData?.details);
  const [degressCourses, setDegressCourses] = useState();

  const [showMore, setShowMore] = useState(false);
  const theme_change = useSelector(themeSelectorFn);
  const location = useLocation();
  const [share, setShare] = useState(false);
  const [open, setOpen] = useState(true);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function changeJobIdInLink(job_id) {
    setJobId(job_id);
    const res = window.location.href.lastIndexOf("/");
    const nums = window.location.href.slice(res + 1).split("_");
    window.location.href =
      window.location.href.slice(0, res + 1) + job_id + "_" + nums[1];
  }
  const alert = useAlert();
  const detailRef = useRef(null);
  const wcaRef = useRef(null);
  const docRef = useRef(null);
  const roundRef = useRef(null);
  // const aboutCompanyRef = useRef(null);
  const similarJobRef = useRef(null);
  const disbaled_Text = "REGISTRATION CLOSED";

  const scrollToComponent = (type) => {
    if (type === "detail") {
      detailRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (type === "wca") {
      wcaRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (type === "doc") {
      docRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (type === "round") {
      roundRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (type === "similarJob") {
      similarJobRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (data?.eligibility_criteria?.courses?.length > 0) {
      let tempArr;
      let finalCourses = [];
      tempArr = data?.eligibility_criteria?.courses;
      for (let i in tempArr) {
        finalCourses.push(`${tempArr[i]?.degree_name}/${tempArr[i]?.name}`);
      }
      setDegressCourses(finalCourses);
    }
  }, [data]);

  useEffect(() => {
    if (!showRoundList) setRoundList(data?.rounds?.slice(0, 4));
    else setRoundList(data?.rounds);
  }, [showRoundList, data?.rounds?.length]);

  useEffect(() => {
    var arr = [];
    if (data?.grad_min_score)
      arr.push(
        `Graduation - ${checkScoring(data?.grad_scoring, data?.grad_min_score)}`
      );
    if (data?.senior_sec_min_score)
      arr.push(
        `12th - ${checkScoring(
          data?.senior_sec_scoring,
          data?.senior_sec_min_score
        )}`
      );
    if (data?.sec_min_score)
      arr.push(
        `10th - ${checkScoring(data?.sec_scoring, data?.sec_min_score)}`
      );
    var obj = {
      description: arr,
    };
    var array = [];
    array.push(obj);
    setScores(array);

    var arr1 = [];
    if (data?.eligible_batches?.length > 0) {
      arr1.push(
        `Candidates From Batches - ${generateCsvNew(data?.eligible_batches)}`
      );
    }
    if (data?.backlog) {
      arr1.push(`Backlogs Allowed`);
    } else {
      arr1.push(`Backlogs Not Allowed`);
    }
    if (data?.service_bond) {
      arr1.push("Include Service Agreement/Bond");
    } else {
      arr1.push("Does not include any Service Agreement/Bond");
    }
    var obj1 = {
      description: arr1,
    };
    var array1 = [];
    array1.push(obj1);
    setEligibility(array1);

    var new_doc_obj = [
      {
        description: data?.documents?.length > 0 ? data?.documents : [],
      },
    ];
    setDoc(new_doc_obj);

    var object = {};
    var count = 0;

    if (data?.degree_spec_selected?.length > 0) {
      data?.degree_spec_selected?.map((arr) => {
        if (arr.degrees[0].all_degree_selected) {
          object["Open to All"] = {};
        } else {
          arr.degrees.map((item) => {
            object[item.degree_name] = checkValue(item);
          });
        }

        arr.degrees.map((item) => {
          count = count + item?.specialization?.length;
        });
      });
    }
    setSpecLength(count);

    setQualifications(object);
  }, [data]);

  const isGradeSystem = useSelector(isGradeSystemFn);

  const checkValue = (item) => {
    var boole = item.this_degree_selected;
    var obj;
    if (boole) {
      obj = [{ name: "All Courses" }];
    } else {
      obj = item.specialization;
    }
    return obj;
  };

  const checkScoring = (str, score) => {
    if (str === "CGPA") return `${score} CGPA`;
    else if (str === "Percentage") return `${score} %`;
    else if (str === "GPA") return `${score} GPA`;
    else return `${score} ${isGradeSystem ? "CGPA" : "%"}`;
  };

  const mainData = [
    {
      icon: <HourglassEmptyOutlinedIcon className={classes.info_icon} />,
      heading: "Apply By",
      details: moment(data?.application_end_date).format("DD MMM YY"),
      show: data?.application_end_date ? true : false,
    },
    {
      icon: <WorkOutlineOutlinedIcon className={classes.info_icon} />,
      heading: "Job Type",
      details: data?.job_type_name,
      show: data?.job_type_id ? true : false,
    },
    {
      icon: <EventAvailableOutlined className={classes.info_icon} />,
      heading: "Drive Date",
      details: moment(data?.campus_drive_date).format("DD MMM YY"),
      show: data?.campus_drive_date ? true : false,
    },
    {
      icon: <PlayCircleFilledWhiteOutlinedIcon className={classes.info_icon} />,
      heading: "Joining Date",
      details: moment(data?.joining_date).format("DD MMM 'YY"),
      show: data?.joining_date ? true : false,
    },
    {
      icon: <TimelapseOutlined className={classes.info_icon} />,
      heading: "Duration",
      details:
        data?.internship_duration && data?.internship_duration > 1
          ? `${data?.internship_duration} months`
          : `${data?.internship_duration} month`,
      show: data?.internship_duration ? true : false,
    },
    // {
    //   icon: <DateRangeIcon />,
    //   heading: "Duration",
    //   details: monthDiff(new Date(data?.job_duration_start), new Date(data?.job_duration_end)) + " months",
    //   show: data?.job_duration_start && data?.job_duration_end ? true : false,
    // },
    {
      icon: <AccountBalanceWalletOutlinedIcon className={classes.info_icon} />,
      heading: "Stipend",
      details:
        data?.salary_type === "PAID"
          ? data?.ctc_min !== 0 &&
            data?.ctc_max !== 0 &&
            valueLabelFormat(data?.ctc_min) +
              " - " +
              valueLabelFormat(data?.ctc_max) +
              " per month"
          : data?.salary_type === "UNPAID"
          ? "Unpaid"
          : "Not Disclosed",
      show: data?.job_type_id === 1 || data?.job_type_id === 3 ? true : false,
    },
    {
      icon: <CardMembershipIcon className={classes.info_icon} />,
      heading: "PPO",
      details: "Yes",
      show: data?.ppo ? true : false,
    },
    {
      icon: <AccountBalanceWalletOutlinedIcon className={classes.info_icon} />,
      heading: <>Salary After PPO</>,
      details:
        data?.ppo_salary_type === "PAID"
          ? data?.ppo_ctc_min !== 0 &&
            data?.ppo_ctc_max !== 0 &&
            valueLabelFormat(data?.ppo_ctc_min) +
              " - " +
              valueLabelFormat(data?.ppo_ctc_max) +
              " PA"
          : data?.salary_type === "UNPAID"
          ? "Unpaid"
          : "Not Disclosed",
      show: data?.job_type_id === 1 && data?.ppo === true ? true : false,
    },
    {
      icon: <AccountBalanceWalletOutlinedIcon className={classes.info_icon} />,
      heading: "Compensation",
      details:
        data?.salary_type === "PAID"
          ? data?.ctc_min !== 0 &&
            data?.ctc_max !== 0 &&
            valueLabelFormat(data?.ctc_min) +
              " - " +
              valueLabelFormat(data?.ctc_max) +
              " PA"
          : data?.salary_type === "UNPAID"
          ? "Unpaid"
          : "Not Disclosed",
      show: data?.job_type_id === 7 ? true : false,
    },
    {
      icon: <AutoGraphOutlined className={classes.info_icon} />,
      heading: "Stage",
      details: jobDetails?.job_stage || "NA",
      show: jobDetails?.job_stage && sourceOfJob !== "EJNF" ? true : false,
    },
  ];

  const getColor = () => {
    if (from === "Inbound_Jobs") {
      return theme_change?.secondary;
    } else {
      return "#000";
    }
  };

  const JobSource = {
    GETWORK_FULFILLED: "GetWork Fulfilled",
    INTERNAL: "Internal",
    GETWORK_INBOUND: "GetWork Inbound",
  };

  // const [open, setOpen] = useState(true);
  const handleClickOpenShare = () => {
    setOpen(true);
  };
  const handleCloseShare = () => {
    setOpen(false);
  };

  const handleSuccessData = (data) => {
    handleClickOpen();
  };

  const handleShare = () => {
    setShare(true);
    handleClickOpenShare();
  };

  const res = window.location.href.lastIndexOf("/");
  const title = window.location.href.slice(res + 1).split("-");
  const linkToCopy =
    window.location.origin +
    "/public-job-details/" +
    title[title.length - 1] +
    "_" +
    collegeDetails?.college_id;

  return (
    <>
      <div
        className={classes.paper}
        style={{
          boxShadow: window.location.pathname.includes("/live-jobs")
            ? "none"
            : window.location.pathname.includes("/brand-jobs")
            ? "none"
            : "auto",
          padding: window.location.pathname.includes("/live-jobs")
            ? "0px"
            : window.location.pathname.includes("/brand-jobs")
            ? "0px"
            : "auto",
        }}
      >
        <div>
          <Grid container spacing={2} className={classes.maingrid}>
            <Grid item>
              <div
                className={classes.image}
                style={{
                  height: window.location.pathname.includes("/live-jobs")
                    ? "54px"
                    : window.location.pathname.includes("/brand-jobs")
                    ? "54px"
                    : "80px",
                  width: window.location.pathname.includes("/live-jobs")
                    ? "54px"
                    : window.location.pathname.includes("/brand-jobs")
                    ? "54px"
                    : "150px",

                  padding: window.location.pathname.includes("/live-jobs")
                    ? "5px"
                    : window.location.pathname.includes("/brand-jobs")
                    ? "5px"
                    : "none",
                  borderRadius: window.location.pathname.includes("/live-jobs")
                    ? "5px"
                    : window.location.pathname.includes("/brand-jobs")
                    ? "5px"
                    : "8px",
                }}
              >
                <img
                  className={classes.img}
                  alt="complex"
                  src={
                    fromJobDetail && jobStatus?.logo
                      ? jobStatus?.logo
                      : jobStatus?.company_website
                      ? `//logo.clearbit.com/${jobStatus?.company_website}`
                      : defaultCompany
                  }
                  onError={(e) => {
                    e.target.onerror = () => null;
                    e.target.src = defaultCompany;
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.grid} sm container>
              <Grid
                item
                xs
                container
                direction="column"
                spacing={2}
                style={{ display: "contents", margin: 0, width: "100%" }}
              >
                <div className={classes.text} style={{ width: "100%" }}>
                  {/* {getworkjobText ? ( */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5">{data?.job_title}</Typography>
                    {window.location.pathname.includes(
                      "/public-job-details/"
                    ) ? (
                      <>
                        <Hidden only={["sm", "xs"]}>
                          <Button
                            color="primary"
                            disabled={disabled}
                            variant="contained"
                            onClick={handleClickOpen}
                            style={{
                              borderRadius: "8px",
                              boxShadow: "none",
                              height: "40px",
                              display: "flex",
                            }}
                          >
                            {disabled ? (
                              disbaled_Text
                            ) : loginSucessful ? (
                              loadingApply ? (
                                <CustomButtonCircularProgress />
                              ) : applied ? (
                                "APPLIED"
                              ) : (
                                "APPLY"
                              )
                            ) : (
                              "LOGIN TO APPLY"
                            )}
                          </Button>
                        </Hidden>
                      </>
                    ) : (
                      !liveJobs && (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {!(isEducationGroup || isCollegeGroup) && (
                            <CopyToClipboard
                              text={linkToCopy}
                              onCopy={() => alert.success("Link Copied!")}
                            >
                              <IconButton
                                className={classes.share}
                                aria-label="copy"
                              >
                                <FileCopyOutlinedIcon color="primary" />
                              </IconButton>
                            </CopyToClipboard>
                          )}

                          {/* <IconButton
                          className={classes.share}
                          size="small"
                          onClick={handleShare}
                        >
                          <ShareIcon />
                        </IconButton> */}
                          <div
                            style={{
                              backgroundColor: getColor(),
                              borderRadius: "8px",
                              height: "34px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px", color: "#fff" }}
                            >
                              {getworkjobText
                                ? `GetWork ${
                                    from === "Inbound_Jobs"
                                      ? "Inbound"
                                      : "Fulfilled"
                                  }`
                                : sourceOfJob}
                            </Typography>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div className={classes.title}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontWeight: 600 }}
                    >
                      {data?.company_name}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid>
                {
                  // getworkjobText ? null :
                  //  <Chip variant="outlined" size="small"
                  //       label={`${sourceOfJob}`}
                  //       // label="source of the job"
                  //       color="primary" className={classes.chip} />
                }
              </Grid>

              {/* <Grid item>
                <IconButton
                  aria-label="save"
                  style={{
                    transition: "all 0.3s ease-in-out 0s",
                  }}
                >
                  <BookmarkBorderOutlinedIcon color="secondary" />
                </IconButton>
                <IconButton
                  aria-label="save"
                  style={{
                    transition: "all 0.3s ease-in-out 0s",
                  }}
                >
                  <ShareOutlinedIcon color="secondary" />
                </IconButton>
              </Grid> */}
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px",
            margin: 0,
            width: "100%",
          }}
        >
          <Grid
            item
            md={12}
            container
            className={classes.info}
            direction="row"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: 0,
              width: "100%",
              paddingLeft: 3,
            }}
            spacing={1}
          >
            {mainData?.map(
              (item) =>
                item.show && (
                  <Grid item xs={6} md={2}>
                    <div className={classes.infoitem}>
                      {item.icon}
                      <div className={classes.detail}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontWeight: 600 }}
                        >
                          {item.heading}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {item.details}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
        <div className={classes.mobileview}>
          {(data?.wfh || data?.pan_india || data?.job_location?.length > 0) && (
            <div className={classes.location}>
              <Box style={{ display: "flex" }}>
                <RoomOutlinedIcon />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ paddingBottom: "10px", fontWeight: 600 }}
                >
                  Location
                </Typography>
              </Box>
              <div className={classes.chipsection}>
                {data?.wfh && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label="Work from Home"
                    color="primary"
                    className={classes.chip}
                  />
                )}
                {data?.pan_india && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={"PAN India"}
                    className={classes.chip}
                    color="primary"
                  />
                )}
                {data?.hybrid && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={"Hybrid"}
                    className={classes.chip}
                    color="primary"
                  />
                )}
                {!data?.pan_india &&
                  data?.job_location?.length > 0 &&
                  data?.job_location?.map((item) => (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={item.city}
                      className={classes.chip}
                      color="primary"
                    />
                  ))}
              </div>
            </div>
          )}
        </div>

        <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

        {window.location.pathname.includes("/public-job-details/") ? (
          <>
            <Hidden only={["sm", "md", "xl", "lg"]}>
              <Paper square className={classes.paperTab}>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  variant="scrollable"
                >
                  {data?.job_description?.length > 0 && (
                    <Tab
                      label={
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Job Detail
                        </Typography>
                      }
                      onClick={() => {
                        scrollToComponent("detail");
                      }}
                    />
                  )}
                  {eligibility?.length > 0 && (
                    <Tab
                      label={
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Who can apply
                        </Typography>
                      }
                      onClick={() => {
                        scrollToComponent("wca");
                      }}
                    />
                  )}
                  {data?.documents?.length > 0 && (
                    <Tab
                      label={
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Documents Required
                        </Typography>
                      }
                      onClick={() => {
                        scrollToComponent("doc");
                      }}
                    />
                  )}
                  {data?.rounds?.length > 0 && (
                    <Tab
                      label={
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Job Round
                        </Typography>
                      }
                      onClick={() => {
                        scrollToComponent("round");
                      }}
                    />
                  )}

                  <Tab
                    label={
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        Other Jobs
                      </Typography>
                    }
                    onClick={() => {
                      scrollToComponent("similarJob");
                    }}
                  />
                </Tabs>
              </Paper>
            </Hidden>
          </>
        ) : (
          <div></div>
        )}
        <div className={classes.mobileview}>
          <div ref={detailRef} style={{ scrollMarginTop: 120 }}>
            {data?.company?.company_website && (
              <div style={{}}>
                <Typography variant="h6">About {data?.company_name}</Typography>
                {data?.company?.company_website && (
                  <a
                    href={checkForUrl(data?.company?.company_website)}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <Button
                      color="primary"
                      startIcon={<LinkIcon />}
                      style={{ display: "flex" }}
                    >
                      {data?.company?.company_website}
                    </Button>
                  </a>
                )}
                {(data?.company?.about ||
                  data?.company?.company_description) && (
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    {ReactHtmlParser(
                      data?.company?.about
                        ? data?.company?.about
                        : data?.company?.company_description
                    )}
                  </Typography>
                )}
              </div>
            )}

            <div>
              {data?.job_description && (
                <>
                  <Typography variant="h6">
                    About the{" "}
                    {data?.job_type_id === 1
                      ? "Internship"
                      : data?.job_type_id === 7
                      ? "Job"
                      : "Training"}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="secondary"
                    // align="center"
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    {ReactHtmlParser(data?.job_description)}
                  </Typography>
                </>
              )}
            </div>
          </div>
          {data?.vacancy && (
            <Typography variant="h6">
              Number of Openings: {data?.vacancy}
            </Typography>
          )}
          {/* <br /> */}
          {data?.salary_breakup && data?.salary_breakup !== "<p><br></p>" && (
            <div>
              <Typography variant="h6">Salary Breakup & Conditions</Typography>
              <Typography
                variant="body2"
                color="secondary"
                // align="center"
                style={{ fontSize: "14px", marginBottom: "10px" }}
              >
                {ReactHtmlParser(data?.salary_breakup)}
              </Typography>
            </div>
          )}
          {data?.salary_breakup_ppo &&
            data?.salary_breakup_ppo !== "<p><br></p>" && (
              <div>
                <Typography variant="h6">
                  Salary Breakup & Conditions after PPO{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  // align="center"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  {ReactHtmlParser(data?.salary_breakup_ppo)}
                </Typography>
              </div>
            )}

          {/* <br /> */}
          {data?.service_bond === true && data?.conditions && (
            <div>
              <Typography variant="h6">Service Bond & Conditions</Typography>
              <Typography
                variant="body2"
                color="secondary"
                // align="center"
                style={{ fontSize: "14px", marginBottom: "10px" }}
              >
                {ReactHtmlParser(data?.conditions)}
              </Typography>
            </div>
          )}
          {!(degressCourses?.length > 0) && (
            <div className={classes.skill}>
              <Typography variant="h6">Eligiblity Criteria</Typography>
            </div>
          )}

          {degressCourses?.length > 0 && (
            <>
              <div>
                <Typography variant="h6">Eligible Degrees</Typography>

                {showMore ? (
                  <>
                    {degressCourses?.map((item) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        label={item}
                        color="primary"
                        className={classes.chip}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {degressCourses?.slice(0, 4)?.map((item) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        label={item}
                        color="primary"
                        className={classes.chip}
                      />
                    ))}
                  </>
                )}
                <span
                  style={{
                    color: "secondary",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {!showMore ? "Show More..." : "...Show Less"}
                </span>
              </div>
            </>
          )}

          <ChipSection
            qualifications={qualifications}
            specLength={specLength}
          />
          <div style={{ marginTop: 10 }}>
            {data?.skills?.length > 0 && (
              <>
                <Typography variant="h6">Skills Required</Typography>
                <div className={classes.chipsection}>
                  {data?.skills?.map((item) => (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={item?.name || item?.skill_name}
                      color="primary"
                      className={classes.chip}
                    />
                  ))}
                </div>
              </>
            )}

            {scores.map((eles) => (
              <div style={{ marginTop: "7px" }}>
                <ol
                  style={{
                    display: "flex",
                    marginLeft: "-25px",
                    flexDirection: "column",
                  }}
                >
                  {eles.description.map((line) => (
                    <Typography
                      component="li"
                      variant="body2"
                      color="secondary"
                      // align="center"
                      key={line}
                      style={{
                        fontSize: "14px",
                        marginRight: "20px",
                        //marginBottom: "10px",
                      }}
                    >
                      {line}
                    </Typography>
                  ))}
                </ol>
              </div>
            ))}
          </div>
          {/* <div className={classes.skill}>
          <Typography variant="h6" style={{ paddingBottom: "10px" }}>
            Skills Required
          </Typography>
        </div>
        <div className={classes.chipsection}>
          <Chip variant="outlined" size="small" className={classes.chip} label="UI Designing" color="primary" />
          <Chip variant="outlined" size="small" className={classes.chip} label="UX Designing" color="primary" />
        </div> */}
          {/* <br /> */}
          <div ref={wcaRef} style={{ scrollMarginTop: 120 }}>
            <Typography variant="h6">Who can apply</Typography>
            {eligibility.map((tier) => (
              <div style={{ marginLeft: "15px", marginTop: "7px" }}>
                <ol style={{ display: "contents" }}>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="body2"
                      color="secondary"
                      // align="center"
                      key={line}
                      style={{ fontSize: "14px", marginBottom: "10px" }}
                    >
                      {line}
                    </Typography>
                  ))}
                </ol>
              </div>
            ))}
          </div>
          {/* <div className={classes.skill}>
          <Typography variant="h6" style={{ paddingBottom: "10px" }}>
            Perks and Benefits
          </Typography>
        </div>
        <div className={classes.chipsection}>
          <Chip variant="outlined" size="small" label="Certificate" className={classes.chip} color="primary" />
          <Chip variant="outlined" className={classes.chip} size="small" label="Swags and T-shirt" color="primary" />
        </div>
        <br /> */}
          <div ref={docRef} style={{ scrollMarginTop: 120 }}>
            {data?.documents?.length > 0 && (
              <div>
                <Typography variant="h6">Documents Required</Typography>
                {doc?.map((tier) => (
                  <div style={{ marginLeft: "15px", marginTop: "7px" }}>
                    <ol style={{ display: "contents" }}>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="body2"
                          color="secondary"
                          // align="center"
                          key={doc}
                          style={{ fontSize: "14px", marginBottom: "10px" }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ol>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* <div>
          <Typography variant="h6">Job Rounds</Typography>
          {round.map(round => (
            <div style={{ marginLeft: "5px", marginTop: "7px" }}>
              <ul style={{ display: "contents" }}>
                {round.description.map(line => (
                  <Typography
                    variant="body2"
                    color="secondary"
                    // align="center"
                    key={line}
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    {line}
                  </Typography>
                ))}
              </ul>
            </div>
          ))}
        </div> */}
          {/* 
        <Typography variant="h6">Employment Type: Part-Time</Typography> */}
          <div ref={roundRef} style={{ scrollMarginTop: 120 }}>
            {data?.rounds?.length > 0 && (
              <div>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">Selection Procedure</Typography>
                  <div>
                    {data?.rounds?.length > 4 && (
                      <div>
                        <Button
                          color="primary"
                          style={{ display: "flex" }}
                          endIcon={
                            !showRoundList ? (
                              <KeyboardArrowDownSharp />
                            ) : (
                              <KeyboardArrowUpSharp />
                            )
                          }
                          onClick={() => {
                            setShowRoundList(!showRoundList);
                          }}
                        >
                          {showRoundList ? "Hide" : "View All Job Rounds"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <ArrowCards data={roundList} fromPostJob={false} />

                {/* {data?.rounds?.length > 4 && (
              <div style={{ marginTop: "20px", paddingBottom: "40px" }}>
                <Button
                  color="primary"
                  style={{ display: "flex" }}
                  endIcon={
                    !showRoundList ? (
                      <KeyboardArrowDownSharp />
                    ) : (
                      <KeyboardArrowUpSharp />
                    )
                  }
                  onClick={() => {
                    setShowRoundList(!showRoundList);
                  }}
                >
                  {showRoundList ? "Hide" : "View All Job Rounds"}
                </Button>
              </div>
            )} */}
              </div>
            )}
          </div>

          {data?.document_for_jd && (
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h6">Attached Document</Typography>

              {
                <>
                  <Grid
                    conatiner
                    xs={12}
                    md={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0.5rem 1rem",
                      borderRadius: "5px",
                      border: "1px solid #c5c5c5",
                      marginTop: "20px",
                    }}
                  >
                    <Grid item xs={9}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "600", marginRight: "0px" }}
                      >
                        <IconButton
                          disabled
                          style={{
                            marginBottom: "3px",
                            border: "0px",
                            padding: "0px",
                          }}
                        >
                          <AttachFileIcon />
                        </IconButton>{" "}
                        {data?.file_name}
                      </Typography>
                    </Grid>
                    <Grid xs={1.5}>
                      <IconButton
                        style={{ border: "1px solid ", padding: "4px" }}
                        color="primary"
                        onClick={() => {
                          if (data?.document_for_jd) {
                            let endPoint = new URL(data?.document_for_jd);
                            let code = endPoint.pathname.split("/");

                            let endUrl = `${BackendBaseApi.SANCHALAN}api/file/download/${code[2]}?download=true`;
                            window.open(endUrl, "_blank").focus();
                          } else {
                            alert.error("Please upload file");
                          }
                        }}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              }
            </div>
          )}

          {window.location.pathname.includes("/public-job-details/") ? (
            <Hidden only={["md", "lg", "xl"]}>
              <div
                className={classes.alljobs}
                ref={similarJobRef}
                style={{ scrollMarginTop: 120 }}
              >
                <Typography variant="h6" style={{ marginBottom: "10px" }}>
                  Other Open Job {`(${OTHER_JOB_DATA.length})`}
                </Typography>
                {OTHER_JOB_DATA.map((item) => (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "5px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      noWrap
                      style={{ color: "#6C757D", cursor: "pointer" }}
                      onClick={() => {
                        changeJobIdInLink(item?.id);
                      }}
                    >
                      {item?.job_title}
                    </Typography>{" "}
                    <ArrowForwardIosRoundedIcon
                      style={{ color: "#6C757D", fontSize: "14px" }}
                    />
                  </div>
                ))}
              </div>
            </Hidden>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* {share && (
        <JobShareSuccessfully
          // companyName={data?.company_name}
          open={open}
          handleClickOpenShare={handleClickOpenShare}
          handleCloseShare={handleCloseShare}
        />
      )} */}
    </>
  );
}
