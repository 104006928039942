import axios from "axios";
import { checkForToken } from "../../../constants/ApiCall";
import { BackendBaseApi } from "../../../constants/constants";
import { IS_GRADE_SYSTEM } from "../../Domain/action";
import { USER_DETAILS } from "../../SignIn/action";

export const EDUCATIONAL_GROUP_PLACEMENT_CYCLES =
  "EDUCATIONAL_GROUP_PLACEMENT_CYCLES";
export const EDUCATIONAL_GROUP_COMPANY = "EDUCATIONAL_GROUP_COMPANY";
export const EDUCATIONAL_GROUP_JOBS = "EDUCATIONAL_GROUP_JOBS";
export const EDUCATIONAL_GROUP_STUDENTS = "EDUCATIONAL_GROUP_STUDENTS";
export const EDUCATIONAL_GROUP_EJD = "EDUCATIONAL_GROUP_EJD";
export const EDUCATIONAL_GROUP_INTERVIEWS = "EDUCATIONAL_GROUP_INTERVIEWS";
export const EDUCATIONAL_GROUP_ASSIGNMENTS = "EDUCATIONAL_GROUP_ASSIGNMENTS";
export const EDUCATIONAL_GROUP_USER_ROLES = "EDUCATIONAL_GROUP_USER_ROLES";
export const EDUCATIONAL_GROUP_TEAM_MEMBER = "EDUCATIONAL_GROUP_TEAM_MEMBER";
export const EDUCATIONAL_GROUP_LIST = "EDUCATIONAL_GROUP_LIST";
export const EDUCATIONAL_GROUP_COURSES = "EDUCATIONAL_GROUP_COURSES";
export const EDUCATIONAL_GROUP_COLLEGES = "EDUCATIONAL_GROUP_COLLEGES";
export const COLLEGE_GROUPS_ADMIN = "COLLEGE_GROUPS_ADMIN";
export const SELECTED_COLLEGE_GROUPS_ADMIN = "SELECTED_COLLEGE_GROUPS_ADMIN";

export const EDUCATIONAL_GROUP_PROFILE = "EDUCATIONAL_GROUP_PROFILE";
export const EDUCATIONAL_GROUP_EJNF_TABLE = "EDUCATIONAL_GROUP_EJNF_TABLE";
export const EDUCATIONAL_GROUP_STUDENTS_TABLE =
  "EDUCATIONAL_GROUP_STUDENTS_TABLE";
export const EDUCATIONAL_GROUP_COMPANY_TABLE =
  "EDUCATIONAL_GROUP_COMPANY_TABLE";
export const EDUCATIONAL_GROUP_DASHBOARD = "EDUCATIONAL_GROUP_DASHBOARD";
export const EDUCATIONAL_GROUP_ALL_PLACEMENTS =
  "EDUCATIONAL_GROUP_ALL_PLACEMENTS";
export const EDUCATIONAL_GROUP_DEGREE = "EDUCATIONAL_GROUP_DEGREE";
export const EDUCATIONAL_GROUP_ALL_COLLEGE_DASHBOARD =
  "EDUCATIONAL_GROUP_ALL_COLLEGE_DASHBOARD";
export const EDUCATIONAL_GROUP_SKILLS = "EDUCATIONAL_GROUP_SKILLS";
export const EDUCATIONAL_GROUP_SKILLS_TABLE = "EDUCATIONAL_GROUP_SKILLS_TABLE";

export const EDUCATIONAL_GROUP_INDUSTRY = "EDUCATIONAL_GROUP_INDUSTRY";
export const EDUCATIONAL_GROUP_INDUSTRY_TABLE =
  "EDUCATIONAL_GROUP_INDUSTRY_TABLE";
export const EDUCATIONAL_GROUP_BILLING = "EDUCATIONAL_GROUP_BILLING";
export const EDUCATIONAL_GROUP_COLLEGE_GROUPS =
  "EDUCATIONAL_GROUP_COLLEGE_GROUPS";
export const EDUCATIONAL_GROUP_COLLEGE_GROUPS_DETAIL =
  "EDUCATIONAL_GROUP_COLLEGE_GROUPS_DETAIL";
export const ALL_COLLEGE_SELECTED = "ALL_COLLEGE_SELECTED";

export const EDGPlacementCyclesAPI = (
  setLoading,
  alert,
  history,
  location,
  url
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_PLACEMENT_CYCLES,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGCompaniesAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId = null
) => {
  return async (dispatch) => {
    setLoading(true);
    var filter = "";
    if (groupId) filter = groupId;
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/university/university_count/?query=company" +
          filter,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_COMPANY,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGJobsAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId = null
) => {
  return async (dispatch) => {
    setLoading(true);
    var filter = "";
    if (groupId) filter = groupId;
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/university/university_count/?query=jobs" +
          filter,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_JOBS,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGStudentsAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId = null
) => {
  return async (dispatch) => {
    setLoading(true);
    var filter = "";
    if (groupId) filter = groupId;
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/university/university_count/?query=students" +
          filter,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_STUDENTS,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGEjdLinksAPI = (setLoading, alert, history, location, url) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_EJD,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGInterviewsAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/university/university_count/?query=interview",
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_INTERVIEWS,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGAssignmentsAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId = null
) => {
  return async (dispatch) => {
    // var filter = "";
    // if (groupId) filter = "&group_id=" + groupId;
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/university/university_count/?query=assignment" +
          groupId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_ASSIGNMENTS,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGUserRolesAPI = (setLoading, alert, history, location, url) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_USER_ROLES,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGTeamManageAPI = (setLoading, alert, history, location, url) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_TEAM_MEMBER,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGAllAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "api/university/university_count/", {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_LIST,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGAllAPIUrl = (setLoading, alert, history, location, url) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(`${url}`, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_LIST,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGBillingAPI = (
  setLoading,
  alert,
  history,
  location,
  group_id,
  url
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_BILLING,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGCoursesAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/university/university_count/?query=courses",
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_COURSES,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGCollegesAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/university/university_count/?query=college",
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_COLLEGES,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGCollegeGroupsAPI = (
  setLoading,
  alert,
  history,
  location,
  edu_group_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/university/college_group/?education_group_id=${edu_group_id}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_COLLEGE_GROUPS,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGCollegeDetailGroupsAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId,
  url,
  setClickSearchButton
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_COLLEGE_GROUPS_DETAIL,
        payload: res.data.data ? res.data.data : [],
      });
      setClickSearchButton("");
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGCollegesUrlAPI = (
  setLoading,
  alert,
  history,
  location,
  url
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_COLLEGES,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGCollegeGroupAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "api/college_group/college_admin/", {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: COLLEGE_GROUPS_ADMIN,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const EDGCollegeGroupAdminsAPI = (alert, history, location, groupId) => {
  return async (dispatch) => {
    var filter = "";
    if (groupId) filter = "?group_id=" + groupId;
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN + "api/college_group/group_admin/" + filter,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: SELECTED_COLLEGE_GROUPS_ADMIN,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const EDGCollegeGroupAddAPI = (
  setLoading,
  alert,
  history,
  handleClose,
  location,
  data,
  groupList
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "api/college_group/add/", data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoading(false);

        throw error;
      });

    if (res.data.success) {
      var arr = [...groupList];
      arr.push(res.data.data);
      dispatch({
        type: EDUCATIONAL_GROUP_COLLEGE_GROUPS,
        payload: arr,
      });
      alert.success("Group Created Successfully");
      handleClose();
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGTableEjnfAPI = (setLoading, alert, history, location, url) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      res.data.data.forEach((item) => {
        item["id"] = item?.ejnf_id;
      });
      dispatch({
        type: EDUCATIONAL_GROUP_EJNF_TABLE,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGTableStudentsAPI = (
  setLoading,
  alert,
  history,
  location,
  url
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      res.data.data.forEach((item) => {
        item["id"] = item?.student_form_id;
      });
      dispatch({
        type: EDUCATIONAL_GROUP_STUDENTS_TABLE,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};
export const EDGTableCompanyAPI = (
  setLoading,
  alert,
  history,
  location,
  url
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      res.data.data.forEach((item) => {
        item["id"] = item?.form_id;
      });
      dispatch({
        type: EDUCATIONAL_GROUP_COMPANY_TABLE,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const CallCollegeAPI = (
  collegeId,
  alert,
  history,
  location,
  user_details,
  link,
  isAllCollege = false,
  setLoader = () => {},
  setApiSuccess = () => {},
  handleButtonClick
) => {
  return async (dispatch) => {
    setLoader(true);
    var url = "api/saas/selected_college_token_set/?";

    if (isAllCollege) {
      url = url + "is_all_college=1";

      // handleButtonClick();
    } else {
      url = url + "college_id=" + collegeId;
    }

    const res = await axios
      .get(BackendBaseApi.PRAVESH + url, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoader(false);
        throw error;
      });

    if (res.data.success) {
      if (!isAllCollege) {
        var obj = {
          ...user_details,
          college_details: {
            ...user_details?.college_details,
            college_id: collegeId,
          },
        };
        dispatch({
          type: USER_DETAILS,
          payload: obj,
        });
        dispatch({
          type: IS_GRADE_SYSTEM,
          payload: res?.data?.data?.is_grade_system,
        });
        dispatch({
          type: ALL_COLLEGE_SELECTED,
          payload: false,
        });
      } else {
        dispatch({
          type: ALL_COLLEGE_SELECTED,
          payload: true,
        });
      }
      if (link) history.push(link);
      setLoader(false);
      setApiSuccess(true);
    } else {
      alert.error("Some Error Occurred!!!");
      setLoader(false);
      setApiSuccess(false);
    }
  };
};

export const resetSelectedCollegeToken = () => {
  return async (dispatch) => {
    const fixedUrl = "api/saas/selected_college_token_set/?is_all_college=1";
    const res = await axios
      .get(BackendBaseApi.PRAVESH + fixedUrl, {
        withCredentials: true,
      })
      .catch((error) => {
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: ALL_COLLEGE_SELECTED,
        payload: true,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const CallCollegeInternalAPI = (
  collegeId,
  alert,
  history,
  location,
  user_details,
  link,
  setLoader = () => {},
  setApiSuccess = () => {}
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/saas/selected_college_token_set/?college_id=" +
          collegeId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoader(false);
        throw error;
      });

    if (res.data.success) {
      var obj = {
        ...user_details,
        college_details: {
          ...user_details?.college_details,
          college_id: collegeId,
        },
      };
      dispatch({
        type: USER_DETAILS,
        payload: obj,
      });
      dispatch({
        type: ALL_COLLEGE_SELECTED,
        payload: false,
      });
      if (link.includes("dashboard")) history.push("/overview");
      else {
        if (link.split("/")[2] === "all-colleges") {
          history.push("/college-profile");
        } else if (link.split("/")[2] === "interview") {
          history.push("/interview-calendar");
        } else if (link.includes("view-teams")) history.push("/manage-team");
        else {
          history.push("/" + link.split("/")[2]);
        }
      }
      setLoader(false);
      setApiSuccess(true);
    } else {
      setLoader(false);
      alert.error("Some Error Occurred!!!");
      setApiSuccess(false);
    }
  };
};

export const CallCollegeNavbarAPI = (
  collegeId,
  link,
  user_details,
  history,
  setMenuClicked
) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/saas/selected_college_token_set/?college_id=" +
          collegeId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        // checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      var obj = {
        ...user_details,
        college_details: {
          ...user_details?.college_details,
          college_id: collegeId,
        },
      };
      dispatch({
        type: USER_DETAILS,
        payload: obj,
      });
      dispatch({
        type: ALL_COLLEGE_SELECTED,
        payload: false,
      });
      history.push(link);
      setMenuClicked(false);
    } else {
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const setCollegeNull = (user_details) => {
  return async (dispatch) => {
    var obj = {
      ...user_details,
      college_details: { ...user_details?.college_details, college_id: null },
    };
    dispatch({
      type: USER_DETAILS,
      payload: obj,
    });
  };
};

export const UniversityGet = (
  setLoading,
  alert,
  history,
  location,
  group_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.PRAVESH}api/saas/education_group/?group_id=${group_id}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);

        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_PROFILE,
        payload: res.data.data,
      });
      setLoading(false);

      // setLoading(false);
    } else {
      setLoading(false);
    }
  };
};

export const UniversityPublicGet = (
  setLoading,
  alert,
  history,
  location,
  group_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.PRAVESH}api/saas/public/education_group/?group_id=${group_id}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);

        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_PROFILE,
        payload: res.data.data,
      });
      setLoading(false);

      // setLoading(false);
    } else {
      setLoading(false);
    }
  };
};

export const UniversityPatch = (
  setLoading,
  alert,
  history,
  location,
  data,
  group_id
) => {
  return async (dispatch) => {
    const res = await axios
      .patch(
        `${BackendBaseApi.PRAVESH}api/saas/education_group/${group_id}/`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);

        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      setLoading(false);
      alert.success("Profile has been Successfully Updated");
    } else {
      setLoading(false);
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const EDGUniversityDashboardAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId = ""
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/dashboard/university_overview/?" +
          groupId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_DASHBOARD,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGUniversityPlacementAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId = ""
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/dashboard/college_applicant/?" +
          groupId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_ALL_PLACEMENTS,
        payload: res.data.data,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGUniversityAllCollegeAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/dashboard/campus_placement_table/?" +
          groupId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      if (res?.data?.data?.length > 0) {
        res.data.data.forEach((item, index) => {
          item["id"] = index + 1;
        });
      }
      dispatch({
        type: EDUCATIONAL_GROUP_ALL_COLLEGE_DASHBOARD,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGUniversityDegreeAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/dashboard/degree_wise_placement/?" +
          groupId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_DEGREE,
        payload: res.data.data,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGUniversitySkillsAPI = (
  setLoading,
  alert,
  history,
  location,
  group_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/saas/group_skill_dashboard/?group_id=" +
          group_id,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_SKILLS,
        payload: res.data.data,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};
export const EDGUniversitySkillsTableAPI = (
  setLoading,
  alert,
  history,
  location,
  group_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/saas/group_skill_table/?group_id=" +
          group_id,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_SKILLS_TABLE,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGUniversityIndustryAPI = (
  setLoading,
  alert,
  history,
  location,
  groupId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/dashboard/university_industry/?" +
          groupId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_INDUSTRY,
        payload: res.data.data,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const EDGUniversityIndustryTableAPI = (
  setLoading,
  alert,
  history,
  location
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN + "api/dashboard/university_industry_table/",
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: EDUCATIONAL_GROUP_INDUSTRY_TABLE,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};
