import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function PieSkeleton() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <Skeleton variant="circle" width={310} height={310} />
      </div>
      <div style={{ marginLeft: 20 }}>
        <Skeleton
          variant="rect"
          width={200}
          height={50}
          style={{ borderRadius: 8, marginBottom: 10 }}
        />
        <Skeleton
          variant="rect"
          width={150}
          height={50}
          style={{ borderRadius: 8 }}
        />
      </div>
    </div>
  );
}
