import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { mergeClasses } from "@material-ui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drop: {
    padding: "5px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "8px",
    // width: "150px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    height: 30,
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      // borderRadius: 30,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        // color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function DropDownButton({ title, placementSession, setPlacementSession, setFilterDataText, show, placementSessionList }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={mergeClasses.title}
        aria-controls="customized-menu"
        style={{
          display: "flex",
          borderRadius: "8px",
          backgroundColor: "#e2e6ea",
          color: "#7a7e83",
          boxShadow: "none",
        }}
        aria-haspopup="true"
        variant="contained"
        size="small"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <Typography variant="overline" color="inherit">
          {placementSession ? placementSession?.name : placementSessionList[0].name}
        </Typography>
      </Button>
      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {placementSessionList?.map((item, index) => {
          return (
            <StyledMenuItem selected={!placementSession && index === 0 ? true : placementSession && placementSession.id === item.id ? true : false}>
              <Typography
                variant="overline"
                color="inherit"
                onClick={() => {
                  setPlacementSession(item);
                  handleClose();
                  if (show) setFilterDataText("No Data Found");
                }}
              >
                {item.name}
              </Typography>
            </StyledMenuItem>
          );
        })}
        <StyledMenuItem selected={placementSession && placementSession.id === "All" ? true : false} onClick={() => {
              setPlacementSession({ id: "All", name: "All Placement Cycles" });
              handleClose();
              if (show) setFilterDataText("No Data Found");
            }}>
          <Typography
            variant="overline"
            color="inherit"
          >
            All Placement Cycles
          </Typography>
        </StyledMenuItem>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
        </div> */}
      </StyledMenu>
    </div>
  );
}
