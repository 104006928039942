import { Box, Typography } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import React from "react";
import pSBC from "shade-blend-color";
import AttachFileIcon from "@material-ui/icons/AttachFile";
const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    messageBlue: {
      position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: pSBC(0.8, theme.palette.primary.main),
      // width: "60%",
      maxWidth: "350px",
      //height: "50px",
      textAlign: "left",
      // font: "400 .9em 'Open Sans', sans-serif",
      // border: "1px solid #97C6E3",
      borderColor: pSBC(0.8, theme.palette.primary.main),
      border: "1px solid ",
      borderRadius: "8px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        ///borderColor: pSBC(0.8, theme.palette.primary.main),
        borderTopColor: pSBC(0.8, theme.palette.primary.main),
        borderTop: "15px solid ",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        // borderBottom: "15px solid transparent",
        top: "0",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTopColor: pSBC(0.8, theme.palette.primary.main),
        borderTop: "17px solid",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      color: "white",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: theme.palette.primary.main,
      //  width: "60%",
      maxWidth: "350px",
      //height: "50px",
      textAlign: "left",
      color: "white",
      // font: "400 .9em 'Open Sans', sans-serif",
      borderColor: theme.palette.primary.main,
      border: "1px solid",
      borderRadius: "8px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTopColor: theme.palette.primary.main,
        borderTop: "15px solid",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTopColor: theme.palette.primary.main,
        borderTop: "17px solid ",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "-3px",
      right: "5px",
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

export const PdfLeft = (props) => {
  function pdfClick(e) {
    e.preventDefault();
    window.open(props.link.url, "_blank");
  }
  const message = props.message ? props.message : "";
  const messagedetails = props.messagedetails ? props.messagedetails : "";
  const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const classes = useStyles();
  return (
    <>
      <div className={classes.messageRow}>
        <div>
          <div className={classes.messageBlue}>
            <div>
              <Box>
                {props.pdf ? <PictureAsPdfIcon /> : <AttachFileIcon />}
                <GetAppIcon onClick={pdfClick} style={{ cursor: "pointer" }} />
                <Typography variant="body2" className={classes.messageContent}>
                  {message}
                </Typography>
                <Typography
                  style={{ fontSize: "14px" }}
                  className={classes.messageContent}
                >
                  {messagedetails}
                </Typography>
              </Box>
            </div>
            <div className={classes.messageTimeStampRight}></div>
          </div>
        </div>
      </div>
    </>
  );
};
