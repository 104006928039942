import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import "./trending.css";
import Slider from "react-slick";
import { Skeleton } from "@material-ui/lab";

function SuccessStory({ succesData, loading }) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    cssEase: "linear",
    autoplaySpeed: 8000,
    speed: 8000,
    rows: 2,
    slidesPerRow: 2,
  };

  return (
    <div
      style={{
        border: "1px solid #c3c7ca",
        padding: 15,
        borderRadius: 8,
        position: "sticky",
        top: 82,
        backgroundColor: "#f5f7f8",
      }}
    >
      {loading ? (
        <Typography
          variant="h4"
          style={{
            marginBottom: 15,
          }}
        >
          <span
            style={{
              background: "linear-gradient(90deg, #007BFF 0%, #0B57D0 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Success Stories
          </span>{" "}
          🎉
        </Typography>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          style={{
            height: "30px",
            width: "200px",
            borderRadius: 4,
            marginBottom: "15px",
          }}
        />
      )}

      <div style={{ maxHeight: "75vh", overflow: "hidden" }}>
        <div className="slider-container">
          {loading ? (
            <Slider {...settings}>
              {succesData?.map((item, i) => (
                <div>
                  <img
                    src={item?.banner_path}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6, 7]?.map((item) => (
                <Grid item md={6}>
                  <div style={{ width: "100%", marginRight: 10 }}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{
                        height: "180px",
                        width: "100%",
                        borderRadius: 10,
                      }}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

export default SuccessStory;
