import React from "react"

export default function ManageCompanies({ primaryColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="99" height="101" viewBox="0 0 99 101">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_5064" data-name="Rectangle 5064" width="99" height="101" transform="translate(380 270)" fill="#fff" stroke="#707070" strokeWidth="1" />
        </clipPath>
      </defs>
      <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-380 -270)" clipPath="url(#clip-path)">
        <path id="company" d="M84,5.122V2.661A2.874,2.874,0,0,0,81.129-.21H2.871A2.874,2.874,0,0,0,0,2.661V5.122a2.874,2.874,0,0,0,2.625,2.86v13.97A2.873,2.873,0,0,0,0,24.81V27.27a2.874,2.874,0,0,0,2.625,2.86V44.1A2.873,2.873,0,0,0,0,46.958v2.461a2.874,2.874,0,0,0,2.625,2.86V81.364H1.23a1.23,1.23,0,1,0,0,2.461H82.77a1.23,1.23,0,0,0,0-2.461H81.375V52.279A2.874,2.874,0,0,0,84,49.419V46.958A2.873,2.873,0,0,0,81.375,44.1V30.13A2.874,2.874,0,0,0,84,27.27V24.81a2.873,2.873,0,0,0-2.625-2.858V7.982A2.874,2.874,0,0,0,84,5.122ZM40.77,81.364H28.957V64.6H40.77Zm14.273,0H43.23V64.6H55.043Zm2.379-19.222H26.578a.084.084,0,0,1-.082-.082v-2.3a.082.082,0,0,1,.082-.082H57.422a.082.082,0,0,1,.082.082v2.3A.084.084,0,0,1,57.422,62.142ZM78.914,81.364H57.506V64.6a2.545,2.545,0,0,0,2.461-2.538v-2.3a2.546,2.546,0,0,0-2.543-2.543H26.578a2.546,2.546,0,0,0-2.543,2.543v2.3A2.545,2.545,0,0,0,26.5,64.6V81.364H5.086V52.29H78.914Zm2.625-34.406v2.461a.41.41,0,0,1-.41.41H2.871a.41.41,0,0,1-.41-.41V46.958a.41.41,0,0,1,.41-.41H81.129a.41.41,0,0,1,.41.41ZM5.086,44.087V30.142h12.8V44.087Zm15.258,0V30.142h12.8V44.087Zm15.258,0V30.142H48.4V44.087Zm15.258,0V30.142h12.8V44.087Zm15.258,0V30.142h12.8V44.087ZM81.539,24.81V27.27a.41.41,0,0,1-.41.41H2.871a.41.41,0,0,1-.41-.41V24.81a.41.41,0,0,1,.41-.41H81.129a.41.41,0,0,1,.41.41ZM5.086,21.938V7.993h12.8V21.938Zm15.258,0V7.993h12.8V21.938Zm15.258,0V7.993H48.4V21.938Zm15.258,0V7.993h12.8V21.938Zm15.258,0V7.993h12.8V21.938ZM81.129,5.532H2.871a.41.41,0,0,1-.41-.41V2.661a.41.41,0,0,1,.41-.41H81.129a.41.41,0,0,1,.41.41V5.122a.41.41,0,0,1-.41.41ZM9.2,77.8H22.312a1.23,1.23,0,0,0,1.23-1.23V68.494a1.23,1.23,0,0,0-1.23-1.23H9.2a1.23,1.23,0,0,0-1.23,1.23v8.077A1.23,1.23,0,0,0,9.2,77.8Zm1.23-8.077H21.074v5.616H10.426ZM61.7,77.8H74.813a1.23,1.23,0,0,0,1.23-1.23V68.494a1.23,1.23,0,0,0-1.23-1.23H61.7a1.23,1.23,0,0,0-1.23,1.23v8.077A1.23,1.23,0,0,0,61.7,77.8Zm1.23-8.077H73.574v5.616H62.926Z" transform="translate(387.5 278.693)" fill={primaryColor} />
      </g>
    </svg>
  )
}