import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CheckUserAPI,
  DegreesGetAPI,
  SpecGetAPI,
} from "../../../../api/Common/action";
import { TeamsPostAPI } from "../../../../api/TeamManage/action";
import { BackendBaseApi } from "../../../../constants/constants";
import { emailRegex } from "../../../../utility/regex";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import debounce from "lodash.debounce";
import {
  collegeGroupListSelectorFn,
  entityInfoSelectorFn,
} from "../../../../api/SignIn/selector";
import CompanyGroupAccordian from "../../../Accordian/CollegeGroupAccordain";

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main, //"#106ba3",
    },
  },
  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function AddNewMemberGroup({
  handleClose,
  teamList,
  userRoleList,
  teamDetails,
  setTeamDetails,
  teamDetailsError,
  setTeamDetailsError,
  designationList,
  placementSession,
  userRoleId,
  setApiCalled,
  collegeId,
  groupArray,
  setGroupArray,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const isAtLeastOneCollegeSelected = (groupArray) => {
    for (let section of groupArray) {
      for (let group of section.groups) {
        const isGroupSelected = group.select_all;

        if (isGroupSelected) {
          return true;
        }
      }
    }
    return false;
  };

  const validateData = () => {
    var is_valid = true;
    var is_super_admin_error = null;
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    var batch_error = null;
    var group_error = null;
    var designation_error = null;
    var college_list_error = null;
    if (teamDetails.is_super_admin === null) {
      is_super_admin_error = "Please Select one Admin";
      is_valid = false;
    }
    // if (!teamDetails.group) {
    //   group_error = "Please Select one Group";
    //   is_valid = false;
    // }
    if (!teamDetails.name) {
      name_error = "Name field cannot be left blank";
      is_valid = false;
    }

    if (!teamDetails.email) {
      email_error = "Email field cannot be left blank";
      is_valid = false;
    }
    if (!emailRegex.test(teamDetails.email)) {
      email_error = "Please enter a valid email";
      is_valid = false;
    }
    if (!teamDetails.phone) {
      phone_error = "Phone Number field cannot be left blank";
      is_valid = false;
    }
    if (teamDetails.phone && teamDetails?.phone?.length !== 10) {
      phone_error = "Enter a valid 10 digit phone number";
      is_valid = false;
    }
    if (
      teamDetails.is_super_admin === false &&
      !isAtLeastOneCollegeSelected(groupArray)
    ) {
      college_list_error = "Please Select one Group";
      is_valid = false;
    }

    // if (!teamDetails?.batch) {
    //   batch_error = "Please enter student's Batch";
    //   is_valid = false;
    // }
    if (!teamDetails?.designation) {
      designation_error = "Please enter student's Designation";
      is_valid = false;
    }
    setTeamDetailsError({
      ...teamDetailsError,
      is_super_admin: is_super_admin_error,
      name: name_error,
      email: email_error,
      phone: phone_error,
      group: group_error,
      batch: batch_error,
      designation: designation_error,
      college_list: college_list_error,
    });
    return is_valid;
  };

  const getSelectedGroupIds = (data) => {
    const selectedCollegeIds = new Set();

    data.forEach((item) => {
      item.groups.forEach((group) => {
        if (group.select_all) {
          selectedCollegeIds.add(group.group_id);
        }
      });
    });

    return Array.from(selectedCollegeIds).join(",");
  };

  const handleSubmit = () => {
    if (validateData()) {
      //   var data = {};
      //   if (placementSession?.id !== "All") {
      //     data = {
      //       ...teamDetails,
      //       college_id: collegeId,
      //       assigned_placement_cycle: [placementSession?.id],
      //     };
      //   } else {
      //     data = {
      //       ...teamDetails,
      //       college_id: collegeId,
      //     };
      //   }
      //   data.batch = teamDetails?.batch ? Number(teamDetails?.batch?.value) : null;
      //   data.course = teamDetails?.course
      //     ? {
      //         id: teamDetails.course.specialization_id,
      //         name: teamDetails.course.specialization_name,
      //       }
      //     : null;
      //   data.designation = teamDetails?.designation;
      const data = { ...teamDetails };
      if (teamDetails?.is_super_admin === false) {
        data.group = getSelectedGroupIds(groupArray);
      }
      dispatch(
        TeamsPostAPI(
          data,
          setLoading,
          alert,
          history,
          location,
          teamList,
          handleClose,
          userRoleId,
          setTeamDetails,
          setApiCalled,
          collegeId
        )
      );
    }
  };

  useEffect(() => {
    if (userRoleList?.length > 0) {
      setTeamDetails({ ...teamDetails, user_role_id: userRoleList[0].id });
    }
  }, [userRoleList?.length]);

  useEffect(() => {
    if (teamDetails?.email) {
      debouncedSave(teamDetails?.email);
    }
  }, [teamDetails?.email]);

  const debouncedSave = useCallback(
    debounce(
      (search) =>
        dispatch(CheckUserAPI(search, setTeamDetailsError, teamDetailsError)),
      700
    ),
    []
  );
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);

  return (
    <>
      <div className={classes.paper}>
        <Typography variant="h6">Add a New Member</Typography>
        <br />
        {/* <FormControl component="fieldset" error={teamDetailsError?.user_role ? true : false}>
          <FormLabel component="legend">Access Level</FormLabel>
          <RadioGroup
            style={{ flexDirection: "row" }}
            name="customized-radios"
            value={teamDetails?.user_role_id}
            onChange={(e) => {
              setTeamDetails({ ...teamDetails, user_role_id: e.target.value });
              setTeamDetailsError({ ...teamDetailsError, user_role: null });
            }}
          >
            {userRoleList?.length > 0 && userRoleList?.map((item) => <FormControlLabel value={item.id} control={<StyledRadio />} label={item.user_role_name} />)}
          </RadioGroup>
          <FormHelperText id="standard-weight-helper-text">{teamDetailsError.user_role}</FormHelperText>
        </FormControl> */}

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="customized-radios"
            className={classes.customize}
            style={{ display: "flex", flexDirection: "row" }}
            value={
              teamDetails?.is_super_admin !== null
                ? teamDetails?.is_super_admin
                  ? "super"
                  : "college"
                : null
            }
            onChange={(e) => {
              setTeamDetails({
                ...teamDetails,
                is_super_admin: e.target.value == "super" ? true : false,
              });
              setTeamDetailsError({
                ...teamDetailsError,
                is_super_admin: null,
              });
            }}
          >
            <FormControlLabel
              value={"super"}
              control={<StyledRadio />}
              label={<Typography variant="body2">Super Admin</Typography>}
            />
            <FormControlLabel
              value={"college"}
              control={<StyledRadio />}
              label={<Typography variant="body2">Regional Admin</Typography>}
            />
          </RadioGroup>
        </FormControl>
        {teamDetailsError?.is_super_admin && (
          <p
            style={{
              margin: 0,
              marginLeft: "15px",
              color: "#dc3545",
            }}
          >
            {teamDetailsError?.is_super_admin}
          </p>
        )}
        {/* <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ marginTop: "20px" }}
          error={teamDetailsError?.group ? true : false}
        >
          <InputLabel
            className={
              teamDetailsError.group
                ? classes.redfloatingLabelFocusStyle
                : classes.floatingLabelFocusStyle
            }
          >
            Group Name *
          </InputLabel>
          <Select
            error={teamDetailsError?.group ? true : false}
            value={teamDetails?.group}
            onChange={(e) => {
              setTeamDetails({ ...teamDetails, group: e.target.value });
              setTeamDetailsError({ ...teamDetailsError, group: null });
            }}
            label="Group Name"
            inputProps={{
              style: {
                padding: "16px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              },
            }}
          >
            {collegeGroupList?.length > 0 &&
              collegeGroupList?.map((item) => (
                <MenuItem value={item.id} name={item?.name}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText id="standard-weight-helper-text">
            {teamDetailsError.group}
          </FormHelperText>
        </FormControl> */}

        {/* <Autocomplete
          id="country-select-demo"
          options={designationList}
          getOptionLabel={(option) => String(option.name)}
          value={teamDetails?.designation}
          className={classes.formControl}
          onChange={(e, newValue) => {
            setTeamDetails({ ...teamDetails, designation: newValue });
            setTeamDetailsError({ ...teamDetailsError, designation: null });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Designation *"
              inputProps={{
                ...params.inputProps,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={teamDetailsError?.designation ? true : false}
              helperText={<span>{teamDetailsError?.designation}</span>}
            />
          )}
        /> */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          id="name"
          label="Admin Name"
          name="Name"
          autoComplete="name"
          value={teamDetails.name}
          onChange={(e) => {
            setTeamDetails({ ...teamDetails, name: e.target.value });
            setTeamDetailsError({ ...teamDetailsError, name: null });
          }}
          error={teamDetailsError.name ? true : false}
          helperText={<span>{teamDetailsError.name}</span>}
          //autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          id="name"
          label="Designation"
          name="Name"
          autoComplete="name"
          value={teamDetails.designation}
          onChange={(e) => {
            setTeamDetails({ ...teamDetails, designation: e.target.value });
            setTeamDetailsError({ ...teamDetailsError, designation: null });
          }}
          error={teamDetailsError.designation ? true : false}
          helperText={<span>{teamDetailsError.designation}</span>}
          //autoFocus
        />

        <TextField
          variant="outlined"
          margin="normal"
          //defaultValue="Small"
          required
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          id="email"
          label="Email ID"
          name="email"
          autoComplete="email"
          value={teamDetails.email}
          onChange={(e) => {
            setTeamDetails({ ...teamDetails, email: e.target.value });
            setTeamDetailsError({ ...teamDetailsError, email: null });
          }}
          error={teamDetailsError.email ? true : false}
          helperText={<span>{teamDetailsError.email}</span>}
          //  autoFocus
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          type="number"
          // InputProps={{
          //   inputComponent: NumberFormatCustom,
          // }}
          label="Phone Number"
          name="phone number"
          value={teamDetails.phone}
          onChange={(e) => {
            setTeamDetails({ ...teamDetails, phone: e.target.value });
            setTeamDetailsError({ ...teamDetailsError, phone: null });
          }}
          error={teamDetailsError.phone ? true : false}
          helperText={<span>{teamDetailsError.phone}</span>}
        />

        {teamDetails?.is_super_admin === false && (
          <CompanyGroupAccordian
            groupArray={groupArray}
            setGroupArray={setGroupArray}
            errorMessage={teamDetailsError?.college_list}
            setErrorMessage={setTeamDetailsError}
            errorMessageKey={"college_list"}
            onlyGroup={true}
            placeholder="Select Group*"
          />
        )}

        {/* {teamDetails?.designation?.id === 7 && (
          <>
            <Autocomplete
              id="country-select-demo"
              options={degreeList}
              getOptionLabel={(option) => option.name}
              value={teamDetails.degree}
              onChange={(e, newValue) => {
                setTeamDetails({ ...teamDetails, degree: newValue });
              }}
              className={classes.formControl}
              style={{ marginBottom: 23 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Degrees"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  helperText={<span>{"Mandatory for Team Members who are students"}</span>}
                />
              )}
            />
            <Autocomplete
              id="country-select-demo"
              options={courseList}
              getOptionLabel={(option) => option.specialization_name}
              value={teamDetails.course}
              onChange={(e, newValue) => {
                setTeamDetails({ ...teamDetails, course: newValue });
              }}
              disabled={teamDetails?.degree ? false : true}
              style={{ marginBottom: 23 }}
              className={classes.formControl}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Courses"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  helperText={<span>{"Mandatory for Team Members who are students"}</span>}
                />
              )}
            />
            <Autocomplete
              id="country-select-demo"
              options={years_list}
              getOptionLabel={(option) => String(option.name)}
              value={teamDetails?.batch}
              className={classes.formControl}
              onChange={(e, newValue) => {
                setTeamDetails({ ...teamDetails, batch: newValue });
                setTeamDetailsError({ ...teamDetailsError, batch: null });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Batch"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={teamDetailsError?.batch ? true : false}
                  helperText={<span>{teamDetailsError?.batch}</span>}
                />
              )}
            />
          </>
        )} */}
      </div>
      <br />
      <Grid container spacing={3} style={{ display: "flex" }}>
        <Grid item sm={6} style={{ display: "grid" }}>
          <Button
            size="small"
            variant="outlined"
            style={{
              borderRadius: "8px",
              boxShadow: "none",
              padding: "10px",
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item sm={6} style={{ display: "grid" }}>
          <Button
            variant="contained"
            size="small"
            style={{
              borderRadius: "8px",
              boxShadow: "none",
              padding: "10px",
            }}
            color="primary"
            onClick={() => {
              if (!loading) handleSubmit();
            }}
          >
            {loading ? <CustomButtonCircularProgress /> : <>Add Member</>}{" "}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
