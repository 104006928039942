import { Chip, Typography, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { JobEJDRejectedAPI } from "../../../api/JobPost/action";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },

  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    marginRight: "10px",
    boxShadow: "none",
    padding: "10px",
    height: "40px",
    paddingLeft: "70px",
    paddingRight: "70px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function RejectJobDialog({
  selectedRowsData,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  jobList,
  collegeId,
  from,
  handleRejectJob = () => {},
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loader, setLoader] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [deleteReasonError, setDeleteReasonError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  const [showMore, setShowMore] = React.useState(false);

  const handleSubmit = () => {
    var arr = [];
    selectedRowsData?.map((item) => arr.push(item.id));
    if (!deleteReason) {
      setDeleteReasonError("Please enter reason");
      return;
    }
    var data = {
      college_id: collegeId,
      object_id: arr,
      job_status: "REJECTED",
      reason: deleteReason,
    };
    if (from === "INBOUND_JOBS") {
      handleRejectJob("REJECT", deleteReason);
      handleClose();
    } else {
      dispatch(
        JobEJDRejectedAPI(
          data,
          alert,
          history,
          location,
          jobList,
          handleClose,
          setLoader,
          collegeId,
          setSelectedRowsData
        )
      );
    }
  };
  return (
    <div>
      <Button
        color="primary"
        size="small"
        className={classes.button}
        variant="contained"
        onClick={handleClickOpen}
      >
        Reject
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure, <br />
              you want to Reject the selected job
              {selectedRowsData?.length > 1 ? "s" : ""}?
            </Typography>
          </div>

          <div component="ul" className={classes.root}>
            {!showMore
              ? selectedRowsData.slice(0, 5).map((data, index) => {
                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.job_title}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : selectedRowsData.map((data, index) => {
                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.job_title}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              multiline
              rows="2"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label={`Why you want to reject these job${
                selectedRowsData?.length > 1 ? "s" : ""
              }?`}
              value={deleteReason}
              onChange={(e) => {
                setDeleteReason(e.target.value);
                setDeleteReasonError("");
              }}
              style={{ marginBottom: 10 }}
              error={deleteReasonError ? true : false}
              helperText={<span>{deleteReasonError}</span>}
            />
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
              paddingLeft: "10px",
              paddingRight: "10px",
              flexDirection: "row",
            }}
          >
            <Button
              className={classes.button}
              onClick={handleClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: "12px" }}
              className={classes.button}
              onClick={() => {
                if (loader) {
                  return;
                }
                if (licenceData && licenceData?.display) {
                  alert.error(licenceData?.display_message);
                  return;
                }

                handleSubmit();
              }}
            >
              {loader ? <CustomButtonCircularProgress /> : "Reject"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
