import { Dialog, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Chip, Divider, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardArrowDownSharp,
  KeyboardArrowUpSharp,
  TimelapseOutlined,
} from "@material-ui/icons";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import LinkIcon from "@material-ui/icons/Link";
import PeopleIcon from "@material-ui/icons/People";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import WorkIcon from "@material-ui/icons/Work";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import pSBC from "shade-blend-color";
import defaultCompany from "../../../assets/img/default.jpg";
import { checkForUrl } from "../../../utility/checkForUrl";
import { generateCsvNew } from "../../../utility/commaSeparatedStrings";
import kFormatter, {
  LFormatter,
  MFormatter,
} from "../../../utility/salaryFormatter";
import ArrowCards from "../../Cards/ArrowCards";
// import ChipSection from "./chipSection";
import ChipSection from "../../Cards/JobDetailsCard/chipSection";
import {
  Payment,
  Update,
  Circle,
  LocationOn,
  ArrowBackIosNewRounded,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowForwardIos,
  ArrowBackIos,
} from "@mui/icons-material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import RejectedResponse from "../../../components/Cards/RecommendedJobs/RejectedResponse";
import { useHistory } from "react-router-dom";

// D:\GetWork\niyantran\src\components\Cards\RecommendedJobs\RejectedResponse.jsx'

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    width: "80%",
    paddingBottom: "5%",
  },
  root2: {
    borderRadius: "8px",
    width: "80%",
    // paddingBottom: '5%',
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    width: "100%",
  },
  blackText: {
    color: "black",
    fontWeight: 500,
    paddingLeft: 5,
  },
  boxDescription: {
    marginLeft: 26,
    marginRight: 40,
    marginBottom: 20,
  },
  Gfullfilled: {
    color: "white",
    backgroundColor: "black",
  },

  img: {
    margin: "auto",
    width: "6rem",
    //  display: "block",
    // maxWidth: "100%",
    // maxHeight: "100%",
    //  borderRadius: "50%",
  },
  ArrowIconsForward: {
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    position: "fixed",
    top: "50%",
    // left: '80%',
    right: "10%",
  },

  ArrowIconsBackword: {
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    position: "fixed",
    top: "50%",
    // marginLeft: "-60px",
    left: "10%",
  },

  grid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoitem: {
    display: "flex",
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  info_icon: {
    color: "#6c757d",
    height: "18px",
  },
  detail: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    alignContent: "center",
  },
  location: {
    color: "#6c757d",
    marginTop: "20px",
  },
  skill: {
    marginTop: "15px",
    // color: "#6c757d",
  },
  btn: {
    marginTop: "15px",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "row",
    alignItems: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  check: {
    backgroundColor: "#58aaff",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "none",
  },
  arrowcard: {
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
  },
  logoJObDescription: {
    display: "flex",
    alignItems: "center",
    marginTop: "14px",
    marginLeft: "26px",
  },
  logo: {
    margin: "auto",
    maxWidth: "93px",
    maxHeight: "93px",
  },
  jobDetails: {
    marginLeft: "26px",
  },
  singleJobDetail: {
    minWidth: 135,
    marginTop: 5,
  },
  jobAcceptanceSection: {
    marginLeft: 26,
    marginTop: 30,
    display: "flex",
    justifyContent: "space-between",
    // alignContent: "center",
    alignItems: "center",
  },
  daysPosted: {
    display: "flex",
  },
  skilsIconSection: {
    display: "flex",
    width: "8rem",
  },

  buttonSection: {
    width: "40%",
    display: "flex",
    justifyContent: "space-around",
    marginRight: "26px",
  },
  buttonAccept: {
    borderRadius: 8,
    width: 146,
    height: 40,
    color: "green",
    backgroundColor: "white",
    border: ".5px solid green",
    cursor: "pointer",
  },
  buttonReject: {
    borderRadius: 8,
    width: 146,
    height: 40,
    color: "#C4161C",
    backgroundColor: "white",
    border: ".5px solid #C4161C",
    marginRight: 10,
    cursor: "pointer",
  },
  Circle: {
    color: "#646464",
    width: "6px",
    height: "6px",
    minWidth: "6px",
    root: {
      width: "6px",
      height: "6px",
    },
  },
  postedDays: {
    color: "#6C757D",
  },
  updateIcon: {
    color: "#6C757D",
    marginRight: ".5rem",
  },
  daysAndNoOfOpenings: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "38%",
  },
  keySkillsSection: {
    marginLeft: 32,
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  skills: {
    display: "grid",
    marginLeft: "26px",
    marginTop: "25px",
    gridTemplateColumns: "auto auto auto auto auto auto auto ",
  },
  elligibilityCriteria: {
    display: "grid",
    marginLeft: "26px",
    marginTop: "25px",
    gridTemplateColumns: "auto auto auto auto ",
  },

  skillText: {
    fontWeight: 500,
    color: "#000000",
  },
  skill: {
    display: "flex",
    marginRight: "10px",
    alignItems: "center",
  },
  viewMoreText: {
    color: "#007BFF",
    textDecoration: "underline",
    cursor: "pointer",
  },
  arrowWithJobDetails: {
    display: "flex",
    alignItems: "center",
  },
}));

const JobDetail = ({
  open,
  handleClose,
  jobDetails,
  handleforwardJobDetail,
  handleBackwardJobDetail,
}) => {
  const classes = useStyles();
  const [hideViewMore, setHideViewMore] = useState(false);
  const [hideLocationViewMore, setHideLocationViewMore] = useState(false);
  const history = useHistory();

  // const skills = ["html", "css", "javascript", "React", "java", "html", "css", "javascript", "React", "java", "javascript", "React", "java", "html", "css", "javascript", "React", "java",
  //     "javascript", "React", "java", "javascript", "React", "java", "html", "css", "javascript", "React", "java",];
  const location = [
    "html",
    "css",
    "javascript",
    "React",
    "java",
    "html",
    "css",
    "javascript",
    "React",
    "java",
    "javascript",
    "React",
    "java",
    "html",
    "css",
    "javascript",
    "React",
    "java",
    "javascript",
    "React",
    "java",
    "javascript",
    "React",
    "java",
    "html",
    "css",
    "javascript",
    "React",
    "java",
  ];

  const [displaySkills, setDisplaySkills] = useState([]);
  const [displayLocations, setDisplayLocations] = useState([]);
  const [eligibilityCriteria, seteligibilityCriteria] = useState([]);

  const [openInterestedDialog, setOpenInterestedDialog] = useState(false);

  useEffect(() => {
    let skillArray = jobDetails?.eligibility_criteria?.skills?.map(
      (item) => item?.skill_name
    );
    setDisplaySkills(skillArray?.slice(0, 19));
    if (skillArray?.length < 18) {
      setHideViewMore(true);
    }

    let locationArray = jobDetails?.job_location?.map((item) => item?.city);
    setDisplayLocations(locationArray?.slice(0, 15));
    if (locationArray?.length < 15) {
      setHideLocationViewMore(true);
    }

    let eligibilityCriteriaArray =
      jobDetails?.eligibility_criteria?.courses?.map((item) => item?.name);
    seteligibilityCriteria(eligibilityCriteriaArray?.slice(0, 15));
    if (eligibilityCriteriaArray?.length < 15) {
      // setHideLocationViewMore(true);
    }
  }, [open, jobDetails]);

  const handleViewMore = () => {
    let skillArray = jobDetails?.eligibility_criteria?.skills?.map(
      (item) => item?.skill_name
    );

    setDisplaySkills(skillArray);
    setHideViewMore(true);
  };
  const handleLocationViewMore = () => {
    setDisplayLocations(location);
    setHideLocationViewMore(true);
  };

  const data = {
    job_title: "Front End Dev",
    application_end_date: jobDetails?.apply_end_date,
    job_type_name: jobDetails?.job_type_name,
    job_type_id: jobDetails?.job_type,
    joining_date: jobDetails?.job_duration_start,
    internship_duration: jobDetails?.internship_duration,
    salary_type: jobDetails?.salary_type,
    ctc_min: jobDetails?.ctc_max,
    ctc_max: jobDetails?.ctc_min,
    company_name: jobDetails?.company?.company_name,
  };

  function valueLabelFormat(value) {
    var new_value;
    if (value > 99999) {
      if (value > 999999) new_value = MFormatter(value);
      else new_value = LFormatter(value);
    } else new_value = kFormatter(value);
    return new_value;
  }

  const mainData = [
    {
      icon: <WorkOutlineOutlinedIcon className={classes.info_icon} />,
      heading: "Job Type",
      details: data?.job_type_name,
      show: data?.job_type_id ? true : false,
    },
    {
      icon: (
        <Payment
          // className={classes.jobDetailIcon}
          style={{ color: "#6C757D", height: "18px", marginLeft: "20px" }}
        />
      ),
      heading: "CTC:",
      details: `₹${(data?.ctc_min / 100000).toFixed(2)}-${(
        data?.ctc_max / 100000
      ).toFixed(2)}LPA`,
      show: data?.job_type_id === 7 ? true : false,
    },
    {
      icon: (
        <Payment
          // className={classes.jobDetailIcon}
          style={{ color: "#6C757D", height: "18px", marginLeft: "20px" }}
        />
      ),
      heading: "Experience:",
      details: jobDetails?.eligibility_criteria?.experience[0],
      show: data?.job_type_id === 7 ? true : false,
    },
    {
      icon: <HourglassEmptyOutlinedIcon className={classes.info_icon} />,
      heading: "Apply By",
      details: moment(data?.application_end_date).format("DD MMM YY"),
      show: data?.application_end_date ? true : false,
    },
    {
      icon: <PlayCircleFilledWhiteOutlinedIcon className={classes.info_icon} />,
      heading: "Joining Date",
      details: moment(data?.joining_date).format("DD MMM 'YY"),
      show: data?.joining_date ? true : false,
    },
    // {
    //     icon: <TimelapseOutlined className={classes.info_icon} />,
    //     heading: "Duration",
    //     details: data?.internship_duration && data?.internship_duration > 1 ? `${data?.internship_duration} months` : `${data?.internship_duration} month`,
    //     show: data?.internship_duration ? true : false,
    // },
    // {
    //   icon: <DateRangeIcon />,
    //   heading: "Duration",
    //   details: monthDiff(new Date(data?.job_duration_start), new Date(data?.job_duration_end)) + " months",
    //   show: data?.job_duration_start && data?.job_duration_end ? true : false,
    // },
    // {
    //     icon: <AccountBalanceWalletOutlinedIcon className={classes.info_icon} />,
    //     heading: "Stipend",
    //     details:
    //         data?.salary_type === "PAID"
    //             ? data?.ctc_min !== 0 && data?.ctc_max !== 0 && valueLabelFormat(data?.ctc_min) + " - " + valueLabelFormat(data?.ctc_max) + " per month"
    //             : data?.salary_type === "UNPAID"
    //                 ? "Unpaid"
    //                 : "Not Disclosed",
    //     show: data?.job_type_id === 1 || data?.job_type_id === 3 ? true : false,
    // },
    // {
    //     icon: <CardMembershipIcon className={classes.info_icon} />,
    //     heading: "PPO",
    //     details: "Yes",
    //     show: data?.ppo ? true : false,
    // },
    // {
    //     icon: <AccountBalanceWalletOutlinedIcon className={classes.info_icon} />,
    //     heading: <>Salary After PPO</>,
    //     details:
    //         data?.ppo_salary_type === "PAID"
    //             ? data?.ppo_ctc_min !== 0 && data?.ppo_ctc_max !== 0 && valueLabelFormat(data?.ppo_ctc_min) + " - " + valueLabelFormat(data?.ppo_ctc_max) + " PA"
    //             : data?.salary_type === "UNPAID"
    //                 ? "Unpaid"
    //                 : "Not Disclosed",
    //     show: data?.job_type_id === 1 && data?.ppo === true ? true : false,
    // },
    // {
    //     icon: <AccountBalanceWalletOutlinedIcon className={classes.info_icon} />,
    //     heading: "Compensation",
    //     details:
    //         data?.salary_type === "PAID"
    //             ? data?.ctc_min !== 0 && data?.ctc_max !== 0 && valueLabelFormat(data?.ctc_min) + " - " + valueLabelFormat(data?.ctc_max) + " PA"
    //             : data?.salary_type === "UNPAID"
    //                 ? "Unpaid"
    //                 : "Not Disclosed",
    //     show: data?.job_type_id === 7 ? true : false,
    // },
  ];

  const roundList = [
    {
      round_id: "dsgrs4563546354",
      round_name: "Hello world type",
      duration: "90",
      type: "Online",
      venue: "Delho",
      random_round_id: "Noadsf",
    },
    {
      round_id: "dsgrs4563546354",
      round_name: "Hello world type",
      duration: "90",
      type: "Online",
      venue: "Delho",
      random_round_id: "Noadsf",
    },
    {
      round_id: "dsgrs4563546354",
      round_name: "Hello world type",
      duration: "90",
      type: "Online",
      venue: "Delho",
      random_round_id: "Noadsf",
    },
  ];

  const handleOpenNotInterested = () => {
    // setOpenInterestedDialog(true);
    setOpenInterestedDialog(true);
    // console.log("open interested");
  };
  const handleCloseNotInterested = () => {
    setOpenInterestedDialog(false);
  };

  return (
    <section>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={true}
        maxWidth={"md"}
        contentStyle={{
          // width: '100%',
          // width: '650px',
          maxWidth: "none",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <section className={classes.arrowWithJobDetails}>
          <div style={{ position: "relative" }}>
            <IconButton
              className={classes.ArrowIconsBackword}
              onClick={handleBackwardJobDetail}
            >
              <ArrowBackIos />
            </IconButton>
          </div>
          <section>
            <Box>
              <Grid
                container
                spacing={2}
                className={classes.logoJObDescription}
              >
                <Grid item>
                  <img
                    className={classes.logo}
                    alt="complex"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultCompany;
                    }}
                    src={
                      jobDetails?.company?.["company_website"]
                        ? `//logo.clearbit.com/${jobDetails?.company?.["company_website"]}`
                        : defaultCompany
                    }
                    // src={defaultCompany}

                    // src={"https://s3.ap-south-1.amazonaws.com/getwork-store/test/logo/college/GWCG-212_college_logo.jpg"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "5px",
                  }}
                  sm
                  container
                >
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    spacing={2}
                    style={{ display: "contents" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box>
                        <Typography variant="h3">
                          {jobDetails?.job_title}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            marginBottom: "10px",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            // color="textSecondary"
                            style={{
                              fontWeight: 600,
                              color: "black",
                            }}
                          >
                            {jobDetails?.company?.company_name}
                          </Typography>
                        </div>
                      </Box>
                      <Grid>
                        <button className={classes.Gfullfilled}>
                          GetWork FullFilled
                        </button>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={3} style={{ marginTop: "10px" }}>
                <Grid
                  item
                  md={12}
                  container
                  className={classes.jobDetails}
                  direction="row"
                  // style={{ display: "flex", justifyContent: "flex-start" }}
                  spacing={1}
                >
                  {mainData?.map(
                    (item) => (
                      // item.show && (
                      <Grid
                        item
                        xs={6}
                        md={3}
                        className={classes.singleJobDetail}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "space-around",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          {item.icon}
                          <div
                            style={{
                              display: "flex",
                              // flexWrap: "wrap",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignItems: "flex-start",
                              alignContent: "center",
                              marginRight: "40px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{
                                fontWeight: 500,
                                minWidth: "85px",
                                maxWidth: 90,
                              }}
                            >
                              {item.heading}
                            </Typography>
                            <Typography
                              variant="caption"
                              // color="textSecondary"
                              style={{
                                minWidth: "80px",
                                fontWeight: 700,
                                maxWidth: 90,
                              }}
                            >
                              {item.details}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    )
                    // )
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box>
              <section className={classes.keySkillsSection}>
                <section>
                  <section className={classes.skilsIconSection}>
                    <TipsAndUpdatesIcon className={classes.updateIcon} />
                    <Typography className={classes.postedDays}>
                      Key Skills:
                    </Typography>
                  </section>
                </section>
                <section>
                  <section className={classes.skills}>
                    {displaySkills?.map((skill) => {
                      return (
                        <div className={classes.skill}>
                          <Circle
                            className={classes.Circle}
                            style={{ width: 6, height: 6, marginRight: 5 }}
                          />
                          <Typography className={classes.skillText}>
                            {skill}
                          </Typography>
                        </div>
                      );
                    })}
                    {hideViewMore ? null : (
                      <Typography
                        onClick={handleViewMore}
                        className={classes.viewMoreText}
                      >
                        View More
                      </Typography>
                    )}
                  </section>
                </section>
              </section>
            </Box>

            <Box>
              <section className={classes.keySkillsSection}>
                <section>
                  <section className={classes.skilsIconSection}>
                    <LocationOn className={classes.updateIcon} />
                    <Typography className={classes.postedDays}>
                      Job Location:
                    </Typography>
                  </section>
                </section>
                <section>
                  <section className={classes.skills}>
                    {displayLocations?.map((skill) => {
                      return (
                        <Chip
                          variant="outlined"
                          size="small"
                          label={skill}
                          color="primary"
                          className={classes.chip}
                        />
                      );
                    })}
                    {hideLocationViewMore ? null : (
                      <Typography
                        onClick={handleLocationViewMore}
                        className={classes.viewMoreText}
                      >
                        View More
                      </Typography>
                    )}
                  </section>
                </section>
              </section>
            </Box>
            <Box>
              <Grid className={classes.jobAcceptanceSection}>
                <section className={classes.daysAndNoOfOpenings}>
                  <section className={classes.daysPosted}>
                    <Update className={classes.updateIcon} />
                    <Typography className={classes.postedDays}>
                      <span className={classes.blackText}>
                        {jobDetails?.formatted_time}
                        {/* {`${'2'}`} Days ago */}
                      </span>
                    </Typography>
                  </section>
                  <section className={classes.daysPosted}>
                    <PersonAddAlt1Icon className={classes.updateIcon} />
                    <Typography className={classes.postedDays}>
                      No.Of Openings:
                      <span className={classes.blackText}>{`${"2"}`}</span>
                    </Typography>
                  </section>
                </section>
              </Grid>
            </Box>
            <Divider
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "90%",
                marginLeft: "3rem",
              }}
            />
            <Box className={classes.boxDescription}>
              <>
                <Typography variant="h6">
                  {" "}
                  {data?.job_type_id === 1
                    ? "Internship"
                    : data?.job_type_id === 7
                    ? "Job"
                    : "Training"}{" "}
                  Description
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  // align="center"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  {ReactHtmlParser(jobDetails?.job_description)}
                  {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). */}
                </Typography>
              </>
            </Box>
            <Box className={classes.boxDescription}>
              <Typography variant="h6">Eligiblity Criteria</Typography>
              <section className={classes.elligibilityCriteria}>
                {eligibilityCriteria?.map((course) => {
                  return (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={course}
                      color="primary"
                      className={classes.chip}
                    />
                  );
                })}
              </section>
            </Box>
            <Box className={classes.boxDescription}>
              <>
                <Typography variant="h6">Who can apply? </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  // align="center"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  1.Candidates From Batches -{" "}
                  {`${jobDetails?.eligibility_criteria?.graduation_years?.join(
                    ", "
                  )}`}
                  <br />
                  2. Backlogs {jobDetails?.backlog ? "Allowed" : "Not Allowed"}
                  <br />
                  3.{jobDetails?.stipend
                    ? "Includes"
                    : "Does not include any"}{" "}
                  Service Agreement/Bond
                  <br />
                </Typography>
              </>
            </Box>
            <Box className={classes.boxDescription}>
              <Typography variant="h6">Documents Required </Typography>
              {jobDetails?.documents?.map((item, index) => {
                return (
                  <>
                    <Typography
                      variant="body2"
                      color="secondary"
                      // align="center"
                      style={{ fontSize: "14px", marginBottom: "10px" }}
                    >
                      {index + 1}. {item}
                    </Typography>
                  </>
                );
              })}
            </Box>
            <Box className={classes.boxDescription}>
              <>
                <Typography variant="h6">Selection Procedure </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  // align="center"
                  style={{
                    fontSize: "14px",
                    marginBottom: "10px",
                    marginTop: 15,
                  }}
                >
                  <ArrowCards data={jobDetails?.round} fromPostJob={false} />
                </Typography>
              </>
            </Box>
            <Divider
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "90%",
                marginLeft: "3rem",
              }}
            />

            <Box className={classes.boxDescription}>
              <>
                <Typography variant="h6">
                  About {jobDetails?.company?.company_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  // align="center"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  {ReactHtmlParser(jobDetails?.company?.company_description)}

                  {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). */}
                </Typography>
              </>
            </Box>
            <Box className={classes.boxDescription}>
              <>
                <Typography variant="h6">Company Info </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  // align="center"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  {/* Website:    https://famepilot.com/careers/ui-ux-designer/ */}
                  Website: {jobDetails?.company?.company_website}
                </Typography>
              </>
            </Box>
            <section className={classes.buttonSection}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "8px",
                  boxShadow: "none",
                  height: "48px",
                }}
                // onClick={handleClearFilters}
              >
                Accept & Post Job
              </Button>
              {/* <button className={classes.buttonAccept}
                                onClick={() => {
                                    history.push(`/post-recommended-job/recommended-${jobDetails?.job_id}`)
                                }}
                            > Accept & Post</button> */}
              <Button
                variant={"outlined"}
                color="primary"
                onClick={() => {}}
                style={{
                  boxShadow: "none",
                  borderRadius: "8px",
                  height: "48px",
                  display: "flex",
                  marginRight: 20,
                  minWidth: 100,
                }}
              >
                Ignore
              </Button>

              {/* <button
                                onClick={handleOpenNotInterested}
                                className={classes.buttonReject}
                            > Ignore</button> */}
            </section>
          </section>
          {/* <ArrowCircleRight /> */}
          <IconButton
            onClick={handleforwardJobDetail}
            className={classes.ArrowIconsForward}
          >
            <ArrowForwardIos />
          </IconButton>
        </section>
      </Dialog>
      <Dialog
        PaperProps={{ classes: { root: classes.root2 } }}
        fullWidth={true}
        maxWidth={"sm"}
        contentStyle={{
          maxWidth: "none",
        }}
        open={openInterestedDialog}
        onClose={handleCloseNotInterested}
        aria-labelledby="responsive-dialog-title"
      >
        <RejectedResponse
          handleNotInterestedCard={{ undo: handleCloseNotInterested }}
        />
      </Dialog>
    </section>
  );
};

export default JobDetail;
