import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
  Menu,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { checkForBreadcrumb } from "../../../utility/checkBreadcrumbs";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import AcceptStudents from "../../DialogSection/AcceptStudents";
import AddStudentPlacement from "../../DialogSection/AddCompanyPlacement/AddStudentPlacement";
import ApplyJobProfile from "../../DialogSection/AddJobPlacementCycle/ApplyJobProfile";
import Block from "../../DialogSection/Block/BlockStudent";
import FilterScore from "../FilterScore";
import { useHistory } from "react-router";
import TableChartIcon from "@material-ui/icons/TableChart";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { BackendBaseApi } from "../../../constants/constants";
import axios from "axios";
import MarkHigherStudentDialog from "../../DialogSection/MarkHigherStudent/MarkHigherStudentDialog";
import RemoveFromHigherStudies from "../../DialogSection/Block/RemoveFromHIgherStudies";
import BackendDrivenFilterDrawer from "../../DialogSection/FilterDrawer/BackendDrivenFilterDrawer";
import FilterCTC from "../FilterCTC";
import DeleteStudent from "../../DialogSection/Block/DeleteStudent";
import { STUDENT_NAVBAR } from "../../../api/ManageStudents/action";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  button2: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    padding: "5px",
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
    // color: "#6c757d",
    "& ::-webkit-input-placeholder": {
      color: "#000 !important",
    },
  },

  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function StudentActionBar({
  filters,
  setFilters,
  setClickSearchButton,
  selectedRowsData,
  clickSearchButton,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  filterList,
  studentList,
  setStudentList,
  setApplyClickedScore,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  checkSelectedGroup,
  checkForPendingRejected,
  fromStudentDetials = false,
  filteringDisabled = false,
  filtersSelectedbyUser,
  tableColumns,
  setTableColumns,
  fromPublic = false,
  callNavbarHigherStudies,
  setCallNavbarHigherStudies,
  studentListTable,
  setStudentListTable,
  loader,
  backendDrivenFilter,
  setBackendDrivenFilter = () => {},
  filterData,
  loading,
  filterLoading,
  setAddStudentClicked,
}) {
  const alert = useAlert();
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const history = useHistory();
  const [removed, setRemoved] = useState(false);
  // const dispatch = useDispatch();
  // const navbarData = useSelector((state) => state?.StudentData?.navbar);

  const [selectedCtcRange, setSelectedCtcRange] = useState({});
  useEffect(() => {
    setBackendDrivenFilter((prev) => ({
      ...prev,
      innerFilters: {
        ...prev.innerFilters,
        cgpa: [
          {
            filterParamName: "cgpa",
            value: selectedCtcRange,
            chipName: "Cgpa",
          },
        ],
      },
    }));
  }, [selectedCtcRange]);

  const handleClickOpenRemove = () => {
    setRemoved(true);
  };
  const handleClickOpenDelete = () => {
    setDeleted(true);
  };

  // const handleCheckChange = (event) => {
  //   setFilters({ ...filters, status_active: event.target.checked });
  // };
  // const handleCheckChange2 = (event) => {
  //   setFilters({ ...filters, status_blocked: event.target.checked });
  // };

  const classes = useStyles();

  let disableCgpa = false;

  if (
    fromStudentDetials ? filtersSelectedbyUser["cgpa"]?.data?.length > 0 : null
  ) {
    disableCgpa = true;
  }

  const checkHigherStudies = () =>
    filters?.current_tab?.params === "higher_studies";

  const handleRemove = () => {
    const payload = {
      ids: selectedID,
    };
    const url = `${BackendBaseApi.SANCHALAN}api/manage_student/delete_student_higher_studies/`;
    axios
      .post(url, payload, { withCredentials: true })
      .then((res) => {
        if (res?.data?.success) {
          alert.success(res?.data?.data?.message);
          setCallNavbarHigherStudies(!callNavbarHigherStudies);
          removingFromHigherStudies();
        } else alert.error(res?.data?.error);
      })
      .catch((err) => {
        alert.error(err?.data?.error);
      });
  };

  const [deleteLoader, setDeleteLoader] = useState(false);
  const handleDelete = () => {
    // let newNavbarData = navbarData;

    // newNavbarData.forEach((item) => {
    //   if (item.params === filters?.status) {
    //     item.count -= selectedRowsData?.length;
    //   }
    // });

    // dispatch({
    //   type: STUDENT_NAVBAR,
    //   payload: newNavbarData,
    // });
    const idList = selectedRowsData.map((item) => item.user_id).join(",");
    setDeleteLoader(true);
    const url = `${BackendBaseApi.SANCHALAN}api/manage_student/student_delete/?user_id=${idList}&college_filter_id=${collegeId}`;
    axios
      .get(url, { withCredentials: true })
      .then((res) => {
        if (res?.data?.success) {
          alert.success(res?.data?.data?.message);
          // setCallNavbarHigherStudies(!callNavbarHigherStudies);
          removingFromTable();
          setDeleted(false);
          setAddStudentClicked(true);
        } else alert.error(res?.data?.error);
        setDeleteLoader(false);
      })
      .catch((err) => {
        alert.error(err?.data?.error);
        setDeleteLoader(false);
      });
  };

  const removingFromTable = () => {
    const matchingObjects = studentList?.filter((largerObj) =>
      selectedRowsData?.some((smallerObj) => smallerObj?.id !== largerObj?.id)
    );
    if (selectedRowsData?.length === studentList?.length) setStudentList([]);
    else setStudentList(matchingObjects);

    setSelectedRowsData([]);
  };

  const removingFromHigherStudies = () => {
    const matchingObjects = studentListTable?.filter((largerObj) =>
      selectedRowsData?.some((smallerObj) => smallerObj?.id !== largerObj?.id)
    );
    if (selectedRowsData?.length === studentListTable?.length)
      setStudentListTable([]);
    else setStudentListTable(matchingObjects);

    setSelectedRowsData([]);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openHigher, setOpenHigher] = useState(false);

  const handleClickOpenHigher = () => {
    if (selectedRowsData[0]?.placement_status === "PLACED") {
      setSelectedRowsData([]);
      alert.error("Can't Move Placed Student to Higher Studies");
    } else setOpenHigher(true);
  };

  const handleHigherClose = () => {
    setOpenHigher(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // loader ? (
  //   <></>
  // ) : (

  const [deleted, setDeleted] = useState(false);

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          // flexDirection: "row",
          // justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3} style={{ display: "contents" }}>
          <Grid item xs={2} style={{ paddingLeft: 0 }}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search By Name"
                value={
                  backendDrivenFilter?.outerFilters?.search
                    ? backendDrivenFilter?.outerFilters?.search
                    : ""
                }
                disabled={loading}
                onChange={(e) => {
                  setBackendDrivenFilter((prev) => ({
                    ...prev,
                    outerFilters: {
                      ...prev?.outerFilters,
                      search: e.target.value,
                    },
                  }));
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setClickSearchButton(true);
                  }
                }}
              />
              {backendDrivenFilter?.outerFilters?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setBackendDrivenFilter((prev) => ({
                      ...prev,
                      outerFilters: {
                        ...prev?.outerFilters,
                        search: "",
                      },
                    }));
                    setClickSearchButton(true);
                  }}
                  disabled={loading}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
                disabled={loading}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {/* {filterList &&
            Object.keys(filterList)?.length > 0 &&
            Object.keys(filterList).map(
              (item) =>
                filterList[item].type === "autocomplete" && (
                  <Grid item xs={2}>
                    <Autocomplete
                      id="country-select-demo"
                      options={filterList[item].data}
                      // disabled={filteringDisabled}
                      disabled={
                        fromStudentDetials
                          ? filteringDisabled
                            ? true
                            : filtersSelectedbyUser[item]?.data?.length > 0
                            ? true
                            : false
                          : false
                      }
                      getOptionLabel={(option) => String(option.name)}
                      value={filters[filterList[item].key]}
                      renderOption={(option) => (
                        <Typography style={{ fontSize: "14px" }}>
                          {option.name}
                        </Typography>
                      )}
                      onChange={(e, newValue) => {
                        setFilters({
                          ...filters,
                          [filterList[item].key]: newValue,
                        });
                      }}
                      style={{ background: "#fff", borderRadius: 8 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={filterList[item].heading}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                )
          )} */}
          <Grid item xs={2}>
            <FilterCTC
              ctcData={
                backendDrivenFilter?.innerFilters["cgpa"] &&
                backendDrivenFilter?.innerFilters["cgpa"][0]
                  ? backendDrivenFilter?.innerFilters["cgpa"][0].value
                  : null
              }
              setCtcData={setSelectedCtcRange}
              loading={loading}
              title={"Percentage"}
              subTitle={"%"}
            />
            {/* <FilterScore
              scoreData={filters}
              setScoreData={setFilters}
              setApplyClickedScore={setApplyClickedScore}
              filteringDisabled={filteringDisabled}
              disableCgpa={disableCgpa}
            /> */}
          </Grid>

          {/* {checkForPendingRejected() && (
            <Grid item xs={2} s>
              <Autocomplete
                style={{
                  display: "grid",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                }}
                options={statusOption}
                onChange={(e, newValue) => {
                  if (newValue?.title === "Active") {
                    setFilters({
                      ...filters,
                      status_active: true,
                      status_blocked: false,
                    });
                  } else if (newValue?.title === "Block") {
                    setFilters({
                      ...filters,
                      status_blocked: true,
                      status_active: false,
                    });
                  } else
                    setFilters({
                      ...filters,
                      status_blocked: true,
                      status_active: true,
                    });
                }}
                getOptionLabel={(option) => option.title}
                renderOption={(option) => (
                  <Typography style={{ fontSize: "14px", color: option.color }}>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: 10,
                        color: option.color,
                        marginRight: 5,
                      }}
                    />
                    {option.title}
                  </Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                  />
                )}
              />
              {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={filters.status_active}
                      size="small"
                      color="primary"
                      onChange={handleCheckChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />

                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "8px",
                        backgroundColor: "#51c03e",
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{ marginLeft: "7px", fontWeight: "bold" }}
                    >
                      Active
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={filters.status_blocked}
                      size="small"
                      color="primary"
                      onChange={handleCheckChange2}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />

                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "8px",
                        backgroundColor: "#f64a3f",
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{ marginLeft: "7px", fontWeight: "bold" }}
                    >
                      Block
                    </Typography>
                  </div>
            </Grid>
          )} */}
          <Grid item md={2}>
            <BackendDrivenFilterDrawer
              backendDrivenFilter={backendDrivenFilter}
              setBackendDrivenFilter={setBackendDrivenFilter}
              filterData={filterData}
              loading={loading}
              filterLoading={filterLoading}
            />
          </Grid>
          {!fromPublic && (
            <Grid item style={{ display: "grid", paddingRight: 0 }}>
              <Tooltip title="Customize Table Field" placement="top">
                <Button
                  onClick={handleClick}
                  variant="outlined"
                  color="inherit"
                  style={{
                    height: "50px",
                    borderColor: "#b7b7b7",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    marginLeft: "550px",
                  }}
                >
                  <TableChartIcon style={{ color: "#757575" }} />
                </Button>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                }}
                transformOrigin={{
                  vertical: "top",
                }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    //marginTop: "60px",
                  },
                }}
              >
                {tableColumns?.map((variant) =>
                  variant?.show ? (
                    <MenuItem key={variant.field} value={variant}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={!variant?.hide}
                            onChange={(e) => {
                              let tempArray = [...tableColumns];
                              var itemIndex = tempArray.findIndex(
                                (x) => x.field === variant.field
                              );
                              var item = tempArray[itemIndex];
                              item.hide = !e.target.checked;
                              tempArray[itemIndex] = item;
                              setTableColumns(tempArray);
                            }}
                          />
                        }
                        label={variant.headerName}
                      />
                    </MenuItem>
                  ) : (
                    <></>
                  )
                )}
              </Menu>
            </Grid>
          )}
        </Grid>
      </div>

      <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
        {userRolesPermission?.some(
          (item) => item.access_control_key === "manage_students"
        ) &&
          selectedRowsData?.length > 0 &&
          !checkForPendingRejected() &&
          !checkHigherStudies() && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                paddingLeft: 0,
              }}
            >
              {selectedRowsData?.length === 1 &&
                (selectedRowsData[0].user_id ? (
                  <Link
                    // to={checkForBreadcrumb(
                    //   isEducationGroup,
                    //   isCollegeGroup,
                    //   collegeId,
                    //   `edit-student/${selectedRowsData[0].user_id}`
                    // )}
                    to={`/edit-student/${selectedRowsData[0].user_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button2}
                    >
                      Edit
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button2}
                    onClick={() => {
                      if (!selectedRowsData[0].user_id) {
                        alert.error(
                          "Something wrong with this data, kindly connect with GetWork"
                        );
                      }
                    }}
                  >
                    Edit
                  </Button>
                ))}

              <Block
                selectedRowsData={selectedRowsData}
                setSelectedRowsData={setSelectedRowsData}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                studentList={studentList}
                collegeId={collegeId}
              />
              {/* {selectedRowsData?.length >0 && ( */}
              {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
                <AddStudentPlacement
                  selectedRowsData={selectedRowsData}
                  setSelectedRowsData={setSelectedRowsData}
                  selectedID={selectedID}
                  setSelectedID={setSelectedID}
                  jobList={studentList}
                  collegeId={collegeId}
                />
              )}
              {/* )} */}
              {/* <AddJobPlacementCycle
                selectedRowsData={selectedRowsData}
                setSelectedRowsData={setSelectedRowsData}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                jobList={studentList}
              /> */}
              {/* <AddNewStudentPlacement /> */}
              <ApplyJobProfile
                selectedRowsData={selectedRowsData}
                setSelectedRowsData={setSelectedRowsData}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                studentList={studentList}
                collegeId={collegeId}
                checkSelectedGroup={checkSelectedGroup}
              />
              {selectedRowsData?.length === 1 && !checkHigherStudies() && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      boxShadow: "none",
                      borderRadius: "8px",
                      marginRight: "10px",
                      boxShadow: "none",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      display: "flex",
                      height: "40px",
                    }}
                    onClick={handleClickOpenHigher}
                  >
                    Add to Higher Studies
                  </Button>

                  <MarkHigherStudentDialog
                    openHigher={openHigher}
                    handleClickOpenHigher={handleClickOpenHigher}
                    handleHigherClose={handleHigherClose}
                    selectedRowsData={selectedRowsData}
                    setSelectedRowsData={setSelectedRowsData}
                    selectedID={selectedID}
                    setSelectedID={setSelectedID}
                    callNavbarHigherStudies={callNavbarHigherStudies}
                    setCallNavbarHigherStudies={setCallNavbarHigherStudies}
                    // callViewHigherStudies={callViewHigherStudies}
                    // setCallViewHigherStudies={callViewHigherStudies}
                  />
                </>
              )}
              {/* <UploadExcelFile /> */}
              {selectedRowsData.length > 0 &&
                (isEducationGroup || isCollegeGroup) && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button2}
                      // onClick={handleRemove}
                      onClick={handleClickOpenDelete}
                    >
                      Delete
                    </Button>
                    <DeleteStudent
                      removed={deleted}
                      setRemoved={setDeleted}
                      handleRemove={handleDelete}
                      deleteLoader={deleteLoader}
                    />
                  </>
                )}
            </Grid>
          )}

        {userRolesPermission?.some(
          (item) => item.access_control_key === "manage_students"
        ) &&
          selectedRowsData?.length > 0 &&
          checkForPendingRejected() &&
          !checkHigherStudies() && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                paddingRight: 0,
              }}
            >
              {!licenceData?.display ? (
                <AcceptStudents
                  deleteUser={true}
                  selectedRowsData={selectedRowsData}
                  setSelectedRowsData={setSelectedRowsData}
                  selectedID={selectedID}
                  setSelectedID={setSelectedID}
                  collegeId={collegeId}
                  setCallNavbar={setCallNavbar}
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button2}
                  onClick={() => {
                    alert.error(
                      "Student license reaches its limit so you cannot accept these Students. Kindly connect with your Key Account Manager"
                    );
                  }}
                >
                  Accept Students
                </Button>
              )}

              <AcceptStudents
                deleteUser={false}
                selectedRowsData={selectedRowsData}
                setSelectedRowsData={setSelectedRowsData}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                collegeId={collegeId}
                setCallNavbar={setCallNavbar}
              />
              <AcceptStudents
                ChangeRequest={true}
                selectedRowsData={selectedRowsData}
                setSelectedRowsData={setSelectedRowsData}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                collegeId={collegeId}
                setCallNavbar={setCallNavbar}
              />
            </Grid>
          )}
        {selectedRowsData.length > 0 && checkHigherStudies() && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button2}
              // onClick={handleRemove}
              onClick={handleClickOpenRemove}
            >
              Remove
            </Button>
            <RemoveFromHigherStudies
              removed={removed}
              setRemoved={setRemoved}
              handleRemove={handleRemove}
            />
          </>
        )}
      </Grid>
    </>
  );
  // );
}

const statusOption = [
  { title: "Active", color: "#33AA28" },
  { title: "Block", color: "#C4161C" },
];
