import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ContactUs } from "../../api/ContactUs/action";
import { isEducationGroupFn } from "../../api/Domain/selector";
import {
  collegeDetailSelectorFn,
  collegeGroupListSelectorFn,
  isCollegeGroupFn,
} from "../../api/SignIn/selector";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import Editor from "../../components/Editor";
import ServiceDeskGroupListDropDown from "../../components/Input/DropDownButton/ServiceDeskGroupListDropDown";
import axios from "axios";
import { BackendBaseApi } from "../../constants/constants";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";

const useStyles = makeStyles((_) => ({
  DropzoneArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  upload: {
    "@media only screen and (max-width: 2000px)": {
      marginTop: "100px",
    },
    "@media only screen and (max-width: 1700px)": {
      marginTop: "70px",
    },
    "@media only screen and (max-width: 1300px)": {
      marginTop: "100px",
    },
  },
}));

export default function Contact() {
  const classes = useStyles();

  const [attachment, setAttachment] = useState([]);
  const [query, setQuery] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const alert = useAlert();
  const [loader, SetLoader] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);

  const SendEmail = (e) => {
    e.preventDefault();
    if (attachment.length < 1 && !query) {
      alert.error("Fields should not be empty");
      return;
    }

    SetLoader(true);
    const formData = new FormData();

    formData.append("description", query ? query : null);
    formData.append(
      "module_id",
      selectedModule ? selectedModule?.module_id : null
    );
    attachment[0] && formData.append("image_1", attachment[0]);
    attachment[1] && formData.append("image_2", attachment[1]);
    attachment[2] && formData.append("image_3", attachment[2]);
    collegeDetail
      ? formData.append("submitted_by", collegeDetail.user_id)
      : alert.error("Something went wrong, Try again!!");
    if (isEducationGroup)
      collegeDetail
        ? formData.append(
            "education_group_id",
            collegeDetail.education_group_id
          )
        : alert.error("Something went wrong, Try again!!");
    else
      collegeDetail
        ? formData.append("college_id", collegeDetail.college_id)
        : alert.error("Something went wrong, Try again!!");

    dispatch(
      ContactUs(SetLoader, formData, history, location, alert, setFormSubmitted)
    );

    setQuery("");
    setAttachment([]);
    // setInterval(function () {
    //   window.location.reload(false);
    // }, 2000);
  };

  const [selectedModule, setSelectedModule] = useState(null);

  const [moduleList, setModuleList] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BackendBaseApi.SANCHALAN}api/settings/module/all/`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.success) {
          setModuleList(res?.data?.data);
          setSelectedModule(res?.data?.data?.[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err?.data?.error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography variant="h2">Service Desk</Typography>
              {/* <br /> */}
              <Typography variant="body2" color="secondary">
                Have any questions? We'd love to hear from you
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  history.push("/service-request");
                }}
                style={{ boxShadow: "none", height: 50, width: 250 }}
              >
                All Service Request
              </Button>
            </Box>
          </Box>
        </Grid>
        {/* <br /> */}
        <Grid item xs={12} sm={8}>
          <Box style={{ marginBottom: 15 }}>
            {/* <span
              style={{
                fontSize: "14px",
                color: "#000",
                marginBottom: 7,
                fontWeight: 600,
              }}
            > */}
            <Typography
              variant="h6"
              color="secondary"
              style={{ marginBottom: 10 }}
            >
              {" "}
              Select Module
            </Typography>
            {/* </span> */}
            {/* {moduleList?.length > 0 && ( */}
            <div style={{}}>
              <ServiceDeskGroupListDropDown
                title="Group"
                width="150px"
                bgcolor="#fff"
                state={selectedModule}
                setState={setSelectedModule}
                groupList={moduleList}
              />
            </div>
            {/* )} */}
            <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
              {/* {" "}
            {moduleList[0]?.name} */}
            </Typography>
          </Box>

          <Box style={{ marginTop: 10 }}>
            <Typography variant="h6" color="secondary">
              Enter your query
            </Typography>
            <br />
            <Editor
              state={query}
              setState={setQuery}
              formSubmitted={formSubmitted}
              setFormSubmitted={setFormSubmitted}
              placeholderText="Write your Query.."
              removeAttachments={true}
            />
            <div className={classes.upload}>
              <Typography variant="h6" color="secondary">
                Upload Images
              </Typography>
              <br />
              <DropzoneArea
                key={formSubmitted ? "contact_us_1" : "contact_us_2"}
                dropzoneClass={classes.DropzoneArea}
                acceptedFiles={["image/*"]}
                dropzoneText={"Max upload up to 3 image"}
                onChange={(files) => setAttachment(files)}
              />
            </div>
            <br />
            <Button
              variant="contained"
              color="primary"
              size="medium"
              style={{
                boxShadow: "none",
                borderRadius: "8px",
                paddingLeft: "70px",
                height: "40px",
                display: "flex",
                paddingRight: "70px",
              }}
              disabled={loader}
              onClick={(e) => SendEmail(e)}
            >
              {loader ? <CustomButtonCircularProgress /> : <> Submit</>}{" "}
            </Button>
          </Box>
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <Box
            style={{
              margin: "auto",
              maxWidth: 500,
              minHeight: 200,
              padding: 20,
              boxShadow: "rgb(0 0 0 / 5%) 0px 3px 24px",
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
          >
            <Typography
              variant="h6"
              color="secondary"
              style={{ marginBottom: 10 }}
            >
              Recent
            </Typography>
            {data.map((item) => (
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <FiberManualRecordRoundedIcon
                  style={{ fontSize: 10, marginTop: 5, marginRight: 10 }}
                />
                <Typography variant="body2" style={{ cursor: "pointer" }}>
                  {item}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid> */}
      </Grid>
      {/* </Layout> */}
    </>
  );
}

const data = [
  "It is a long established fact that a reader will be distracted by the readable content ",
  "It is a long established fact that a reader will be distracted by the readable content ",
  "It is a long established fact that a reader will be distracted by the readable content ",
  "It is a long established fact that a reader will be distracted by the readable content ",
  "It is a long established fact that a reader will be distracted by the readable content ",
  "It is a long established fact that a reader will be distracted by the readable content ",
];
