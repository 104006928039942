import {
  Box,
  Button,
  Chip,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HistoryIcon from "@material-ui/icons/History";
import moment from "moment";
import React from "react";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    position: "relative",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",
  },
  chipDisplay: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  boxShadowClass: {
    position: "relative",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    padding: 10,
    margin: "10px 0",
  },
  alignPill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statuschip: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 8,
    // borderRadius: "8px",
  },
}));

function SavedWorkExperience({ data, approveReject }) {
  const classes = useStyles();

  // console.log("data WORK WKEK", data);
  return (
    <div /* style={{ position: "relative" }} */>
      {data?.map((item) => (
        <>
          <div className={classes?.boxShadowClass}>
            <div className={classes?.alignPill}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">
                  {item?.updatedData?.job_designation}{" "}
                  {item?.updatedData?.company_name &&
                    " at " + item?.updatedData?.company_name}
                </Typography>
                <Tooltip
                  placement="bottom"
                  title={
                    item?.change_type === "ADD"
                      ? "New work experience added"
                      : item?.change_type === "UPDATE"
                      ? "Update existing work experience"
                      : "Delete existing work experience"
                  }
                >
                  <div
                    style={{
                      backgroundColor:
                        item?.change_type === "ADD"
                          ? "#00203F"
                          : item?.change_type === "UPDATE"
                          ? "#2AF598"
                          : "#FF0000",
                    }}
                    // label={
                    //   item?.change_type === "ADD"
                    //     ? "Added"
                    //     : item?.change_type === "UPDATE"
                    //     ? "Updated"
                    //     : "Deleted"
                    // }
                    className={classes.statuschip}
                  >
                    <Typography style={{ color: "#fff", fontSize: "14px" }}>
                      {item?.change_type === "ADD"
                        ? "Added"
                        : item?.change_type === "UPDATE"
                        ? "Updated"
                        : "Deleted"}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            </div>
            <Typography
              variant="body2"
              color="secondary"
              style={{ marginTop: "5px", marginBottom: "7px" }}
            >
              {item?.updatedData?.job_description}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography variant="body2">
                {" "}
                {item?.updatedData?.start_date &&
                  item?.updatedData?.start_date !== null && (
                    <>
                      {moment(item?.updatedData?.start_date).format(
                        "MMM, YYYY"
                      )}{" "}
                      {item?.updatedData?.end_date &&
                      item?.updatedData?.end_date !== null ? (
                        <>
                          {" - "}
                          {moment(item?.updatedData?.end_date).format(
                            "MMM, YYYY"
                          )}{" "}
                        </>
                      ) : (
                        <>- Present</>
                      )}
                    </>
                  )}{" "}
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                style={{ marginLeft: "5px", fontWeight: "bold" }}
              >
                {item?.updatedData?.job_type_name &&
                  "(" + item?.updatedData?.job_type_name + ")"}{" "}
              </Typography>
            </div>
            <div className={classes.chipDisplay}>
              {item?.updatedData?.skills &&
                item?.updatedData?.skills.map((item, index) => {
                  return (
                    <li key={item.skill_id}>
                      <Chip
                        color="primary"
                        variant="outlined"
                        size="small"
                        label={item.skill_name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
            </div>
            <Divider
              variant="middle"
              style={{ marginLeft: 0, marginRight: 0, marginTop: 5 }}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <HistoryIcon style={{ color: "#010101" }} />{" "}
                <Typography
                  variant="body2"
                  style={{ color: "#010101", paddingLeft: "5px" }}
                >
                  {moment(item?.create_time)?.format("MMMM Do YYYY, h:mm a")}
                </Typography>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    marginRight: "10px",
                    height: "35px",
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    approveReject([item?.id], "APPROVED");
                  }}
                >
                  <Typography
                    style={{
                      color: "#fff",
                      fontSize: "14px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Accept
                  </Typography>
                </Button>

                <Button
                  variant="outlined"
                  style={{
                    borderColor: "#FF0000",
                    height: "35px",
                    boxShadow: "none",
                  }}
                  size="small"
                  onClick={() => {
                    approveReject([item?.id], "REJECTED");
                  }}
                >
                  <Typography
                    style={{
                      color: "#FF0000",
                      fontSize: "14px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Decline
                  </Typography>
                </Button>
              </div>
            </Box>
          </div>
        </>
      ))}
    </div>
  );
}

export default SavedWorkExperience;
