import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",
  },
  chipDisplay: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  boxShadowClass: {
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    padding: 10,
    margin: "10px 0",
  },
  alignPill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function SavedLinks({ data, approveReject }) {
  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      {data?.change_data?.profile_url?.length ? (
        <>
          {data?.change_data?.profile_url?.length > 0 &&
            data?.change_data?.profile_url?.map((item) => (
              item?.logo &&  item?.value &&
              <div style={{ display: "flex", marginLeft: "5px", padding: 5 }}>
                <a href={item.value} target="_blank">
                  <img
                    src={item.logo}
                    height="30px"
                    width="30px"
                    alt="socialmedia"
                    style={{
                      marginRight: "30px",
                      cursor: "pointer",
                      borderRadius: 8,
                    }}
                  />
                </a>
                <Typography variant="body2">{item.value}</Typography>
              </div>
            ))}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>No Changes</Typography>
        </div>
      )}
    </div>
  );
}

export default SavedLinks;
