import React from "react";
import Layout from "../../components/Layout";
import thank from "../../assets/svg/Done-rafiki.svg";
import { Typography } from "@material-ui/core";

function ContactThankYou() {
  return (
    <>
      <Layout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={thank} height="400px" width="400px" />
          <div>
            <Typography variant="h2">
              {" "}
              Thank You For Submitting Your Query !
            </Typography>
            <br />
            <Typography variant="h6" color="secondary">
              Our representative will connect with you shortly.
            </Typography>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ContactThankYou;
