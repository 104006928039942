import { createTheme } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import Theme from "../../../style/theme";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.type === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "37%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const columns = [
  {
    field: "title",
    headerName: "Job Title",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          marginLeft: "85px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          fontSize: "14px",
          margin: "auto",
        }}
      >
        {params?.row?.name}
      </span>
    ),
  },

  {
    field: "designation",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          marginLeft: "65px",
        }}
      >
        Designation
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          fontSize: "14px",
          margin: "auto",
        }}
      >
        {params?.row?.designation}
      </span>
    ),
  },
  {
    field: "role",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          marginLeft: "85px",
        }}
      >
        Role
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.user_role_name}
      </span>
    ),
  },
];

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: 0,
      color:
        theme.palette.type === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.85)",

      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: "#e2e6ea",
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        border: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },

      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      //...customCheckbox(theme),
    },
    "& .super-app-theme--header": {
      backgroundColor: Theme.palette.primary.main,
    },
  }),
  { defaultTheme }
);

export default function AddTeamMemberTable({
  collegeData,
  selected_member,
  setSelectedMemID,
  selectedMemID,
  setMembers,
  memLoading,
}) {
  const classes = useStyles();
  return (
    <div style={{ height: 300, width: "100%" }} className={classes.root}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
        }}
        rows={collegeData}
        columns={columns}
        selectionModel={selectedMemID}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = collegeData?.filter((row) =>
            selectedIDs.has(row.id)
          );
          setMembers(selectedRowData);
          setSelectedMemID(ids);
        }} /* {selectRow} */
        pageSize={5}
        checkboxSelection
        loading={memLoading}
        // onSelectionModelChange={selected_member}
      />
    </div>
  );
}
