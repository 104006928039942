import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Editor from "../../../Editor";

const useStyles = makeStyles(theme => ({
  paper: {
    height: "auto",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    padding: "15px",
  },
  leftpaper: {
    height: "auto",
    boxShadow: "none",
    padding: "20px",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  text: { marginTop: "20px", marginBottom: "20px" },

  rightpaper: {
    height: "auto",
    boxShadow: "none",
    // padding: "15px",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  helper: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    //padding: theme.spacing(1, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& .MuiFormLabel-root": {
      color: "red", // or black
    },
  },
  include: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textfield: {
    padding: "16px",
  },
  upload: {
    "@media only screen and (max-width: 2000px)": {
      marginBottom: "66px",
    },
    "@media only screen and (max-width: 1700px)": {
      marginBottom: "66px",
    },
    "@media only screen and (max-width: 1300px)": {
      marginBottom: "90px",
    },
  },
}));
function Customization({
  ejdForm,
  setEjdForm,
  ejdTitleError,
  setEjdTitleError,
}) {
  const classes = useStyles();
  const [editor, setEditor] = useState("");
  const [editor2, setEditor2] = useState("");

  useEffect(() => {
    if (editor) setEjdForm({ ...ejdForm, message: editor });
  }, [editor]);
  useEffect(() => {
    if (editor2) setEjdForm({ ...ejdForm, facilities: editor2 });
  }, [editor2]);

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6" style={{ padding: "20px" }}>
          Fill e-JNF Details
        </Typography>
        <Grid
          container
          spacing={0}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={10}>
            <Paper className={classes.rightpaper}>
              <TextField
                floatingLabelStyle={{ color: "" }}
                variant="outlined"
                //   margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                  shrink: true,
                }}
                required
                fullWidth
                inputProps={{
                  className: classes.textfield,
                }}
                id="title"
                label="e-JNF Title"
                name="Title"
                autoComplete="title"
                value={ejdForm?.title}
                onChange={e => {
                  setEjdForm({ ...ejdForm, title: e.target.value });
                  setEjdTitleError(null);
                }}
                error={ejdTitleError ? true : false}
                helperText={<span>{ejdTitleError}</span>}
              />
              <Typography variant="h6" className={classes.text}>
                Your Message
              </Typography>
              {/* <MyEditor /> */}
              <div className={classes.upload}>
                <Editor state={editor} setState={setEditor} />
              </div>
              <Typography variant="h6" className={classes.text}>
                Facilities Available on Campus for Recruitment*
              </Typography>
              {/* <MyEditor /> */}
              <div className={classes.upload}>
                <Editor state={editor2} setState={setEditor2} />
              </div>
              <br />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Customization;
