import {
  Button,
  InputBase,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import {
  collegeDetailSelectorFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import { RulesGet, updateTnpRules } from "../../api/TnpRules/action";
import CustomCircularProgress from "../../components/CircularProgressBar";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#28a745",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 18,
    marginTop: "1.3px",
    height: 17,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#bfbfbf",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  chip: {
    borderColor: theme.palette.primary.main,
    border: "1px solid",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    borderRadius: "8px",
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    paddingLeft: "5px",
    marginRight: "4px",
    marginLeft: "4px",
    paddingRight: "5px",
    borderRadius: "8px",
  },
  chiptext: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "7px",
    alignItems: "center",
  },
  details2: {
    //  fontSize: "14px",
    // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
    textAlign: "justify",
    color: "#333",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "48px",
    display: "flex",
    marginTop: "20px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  root: {
    //padding: "4px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
    border: ".5px solid",
    // width: "auto",
    boxShadow: "none",
    marginLeft: "10px",
    marginRight: "10px",
    borderRadius: "8px",
    width: "42px",
    height: "22px",
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
  },
  input: {
    marginTop: theme.spacing(0.2),
    // marginLeft: theme.spacing(0.5),
    flex: 1,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    padding: "4px",
  },
}));

const Rules = (props) => {
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const [checkRules, setCheckedRules] = useState([]);
  const rulesList = useSelector((state) =>
    state?.rulesTnp?.rulesData ? state?.rulesTnp?.rulesData : []
  );
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  useEffect(() => {
    if (collegeDetail?.college_id) {
      dispatch(RulesGet(alert, history, location, collegeDetail?.college_id));
    }
  }, [collegeDetail]);

  useEffect(() => {
    if (rulesList) {
      const result = rulesList.filter((item) => item?.is_selected === true);
      setCheckedRules(result);
      setList(rulesList);
      setLoading(false);
    }
  }, [rulesList]);

  function replaceJSX(str, find, replace) {
    const parts = str.split(find);
    const result = [];
    for (let i = 0; i < parts.length; i++) {
      result.push(parts[i]);
      if (i < parts.length - 1) result.push(replace);
    }
    return result;
  }

  const handleToggle = (item, index) => {
    let arr = checkRules;
    const result = arr?.findIndex((a) => a.id === item.id);
    if (item?.is_selected === false) {
      if (result === -1) {
        if (item?.is_input) {
          if (item?.value !== null) {
            rulesList[index].is_selected = true;
            arr.push(item);
          } else {
            alert.error("Please enter the number");
          }
        } else {
          rulesList[index].is_selected = true;
          arr.push(item);
        }
      } else {
        rulesList[index].is_selected = false;
        arr.splice(result, 1);
      }
    } else {
      rulesList[index].is_selected = false;
      arr.splice(result, 1);
    }
    setCheckedRules(arr);
    setList([...rulesList]);
    setLoading(false);
  };

  const update = () => {
    const paylaod = {
      college_id: [collegeDetail?.college_id],
      selected_tnp_rule: checkRules,
    };
    dispatch(
      updateTnpRules(
        setUpdating,
        paylaod,
        alert,
        history,
        location,
        collegeDetail?.college_id
      )
    );
  };

  const checkDisabled = (item_) => {
    var boole = true;
    if (
      userRolesPermission?.some(
        (item) => item.access_control_key === "manage_tnp_rules"
      )
    )
      boole = false;
    if (
      item_?.rule_key === "auto_approve_student_registration" &&
      licenceData?.display
    )
      boole = true;

    return boole;
  };

  return (
    <div>
      {loading ? (
        <CustomCircularProgress />
      ) : (
        // <Layout>
        <>
          <Typography variant="h2">
            Campus Placement Rules and Regulations
          </Typography>
          <Typography variant="body2" color="secondary">
            Manage your College Rules Settings
          </Typography>

          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              marginTop: "20px",
              padding: "20px",
            }}
          >
            <Typography variant="h6">
              Campus Placement Automation Rules
            </Typography>
            {list?.map((item, index) => {
              const isChecked =
                (checkRules
                  ? checkRules.findIndex((a) => a.id === item.id)
                  : -1) >= 0;
              return (
                <div key={item?.id} className={classes.chiptext}>
                  <div key={item?.id}>
                    {/* <ul style={{ display: "contents" }}>
              
                  <Typography
                    variant="body2"
                    color="secondary"
                    // align="center"
                    key={line}
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  > */}
                    <div style={{ marginLeft: "18px" }}>
                      <ul style={{ display: "contents" }}>
                        <Typography variant="body2" component="li" key={item}>
                          <div style={{ display: "flex" }}>
                            {replaceJSX(
                              item?.rule_text,
                              "{#number#}",

                              <Paper component="form" className={classes.root}>
                                <InputBase
                                  className={classes.input}
                                  inputProps={{
                                    style: {
                                      fontSize: "14px",
                                      textAlign: "center",
                                    },
                                  }}
                                  value={item?.value}
                                  onChange={(event) => {
                                    // if (item.is_input) {
                                    var arr = [...list];
                                    arr[index].value = event.target.value;
                                    setList(arr);
                                    // item.value = event.target.value;
                                    // (item.is_input = false);
                                  }}
                                  disabled={
                                    userRolesPermission?.some(
                                      (item) =>
                                        item.access_control_key ===
                                        "manage_tnp_rules"
                                    ) === true
                                      ? false
                                      : true
                                  }
                                />
                              </Paper>

                              // <TextField
                              //   value={item?.value}
                              //   type="number"
                              //   onChange={event => {
                              //     // if (item.is_input) {
                              //     item.value = event.target.value;
                              //     // (item.is_input = false);
                              //   }}
                              // />
                            )}
                          </div>
                        </Typography>
                      </ul>
                    </div>
                  </div>
                  <div key={item?.id}>
                    <IOSSwitch
                      checked={isChecked}
                      onChange={props.handleChange}
                      onChange={() => handleToggle(item, index)}
                      name="checkedB"
                      disabled={checkDisabled(item)}
                    />

                    {/* <Switch
                      color={"primary"}
                      checked={isChecked}
                      onChange={() => handleToggle(item, index)}
                    /> */}
                  </div>
                </div>
              );
            })}
            <Typography
              variant="h6"
              style={{ marginTop: "10px", marginBottom: "20px" }}
            >
              General Instructions for Students
            </Typography>
            {students.map((students) => (
              <div style={{ marginTop: "7px" }}>
                <ul style={{ marginLeft: "-22px" }}>
                  {students.description.map((line) => (
                    <li>
                      <Typography
                        //  component="li"
                        variant="body2"
                        // align="center"
                        className={classes.details2}
                        key={line}
                        style={{ marginBottom: "10px" }}
                      >
                        {line}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <Typography
              variant="h6"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              General Instructions for Companies
            </Typography>
            {company.map((company) => (
              <div style={{ marginTop: "7px" }}>
                <ul style={{ marginLeft: "-22px" }}>
                  {company.description.map((line) => (
                    <li>
                      <Typography
                        //  component="li"
                        variant="body2"
                        // align="center"
                        className={classes.details2}
                        key={line}
                        style={{ marginBottom: "10px" }}
                      >
                        {line}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {/* {company.map(company => (
            <ul style={{ listStyleType: "disc" }}>
              {company.description.map(line => (
                <li>
                  <div
                    //  key={index2}
                    className={classes.details2}
                  >
                    {line}
                  </div>
                </li>
              ))}
            </ul>
          ))} */}
          </div>
          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_tnp_rules"
          ) && (
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "170px", height: "40px" }}
              className={classes.btn}
              onClick={() => update()}
            >
              {updating ? <CustomButtonCircularProgress /> : <> Update Rule</>}
            </Button>
          )}
          {/* </Layout> */}
        </>
      )}
    </div>
  );
};

export default Rules;

const students = [
  {
    description: [
      "Keep your resume and profile updated on the portal.",
      "Make sure you learn about a visiting company from internet, seniors and other sources before deciding to register for the company.",
      "Once registered for a company, a student is liable to attend all rounds of selection process: - PPT (Pre-Placement Talk), Written & Online Assessments as well as Interviews.",
      "Failure to do so will result in penalty in terms of TPO credits which may lead to your debarment from future placement activities.",
      "Pay attention to the company-specific instructions sent to you on the mail by TPO team. Adhere to the specific format of attachments as asked for.",
      "It is the responsibility of the student to check announcements/notices / updated information / shortlisted names etc. in the notice boards of Placement /Department Notice Board.",
    ],
  },
];

const company = [
  {
    description: [
      "Companies wishing for campus placements should fill the Company e-Registration Form or e-JD Form, we entertain entries received only through e-JD form.",
      "The Training and Placement Team will send you an invitation mail within 24 hrs seeking essential information about the Job profile and other Relevant details after receiving e-JD/e-RF Response.",
      "The details of Campus visit of a Company will be negotiated with the Training and Placements Team and a Team member (Singe Point of Contact - SPoC)  will be allotted to the respective Company.",
      "Your entire schedule will be managed by the Team member allotted to you. You can organize your placement session as per your convenience using the facilities available at our campus.",
      "After the recruitment process, the company will be required to declare the results of the final recruitment on the Portal either on-campus or from Office.",
      "The Company can then send the offer letter within a reasonable span of time.",
      "The Training and Placement Team also coordinates the signing of offer letters by students, who have been selected, to ensure that they reach the company/organization as early as possible.",
    ],
  },
];
