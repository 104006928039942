import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { isGradeSystemFn } from "../../api/Domain/selector";
import { StudentsEligibleGetAPI } from "../../api/ManageStudents/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
} from "../../api/SignIn/selector";
import EligibleTab from "../../components/Header/StudentTab/EligibleTab";
import { generateCsv } from "../../utility/commaSeparatedStrings";

const useStyles = makeStyles((theme) => ({
  clear: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
}));

export default function EligibleTrack() {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [filterDataText, setFilterDataText] = useState(null);
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const [applyClickedScore, setApplyClickedScore] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const [firstApiCall, setFirstApiCall] = useState(false);
  const isGradeSystem = useSelector(isGradeSystemFn);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const applied = queryParams.get("applied");

  const [filters, setFilters] = useState({
    search: null,
    course: null,
    degree: null,
    batch: null,
    cgpa_min: null,
    cgpa_max: null,
    status_active: true,
    status_blocked: true,
    status: "ALL",
    candidate_status: applied == 1 ? { name: "Applied", id: "APPLIED" } : null,
  });

  // useEffect(() => {
  //   dispatch(PlacementListAPI(alert, history, location));
  // }, []);
  // const placementSessionList = useSelector((state) => state.commonData.placementList);

  // useEffect(() => {
  //   if (placementSessionList?.length > 0) {
  //     if (location.search.includes("placement_cycle")) {
  //       var placement_name = checkPlacementName(location?.search.replace("?placement_cycle=", ""));
  //       setPlacementSession({ id: location?.search.replace("?placement_cycle=", ""), name: placement_name });
  //     } else {
  //       setPlacementSession({ id: "All", name: "All Placement Cycles" });
  //     }
  //   }
  // }, [location, placementSessionList?.length]);

  // const checkPlacementName = (id) => {
  //   var name = "";
  //   placementSessionList.map((item) => {
  //     if (item.id === id) name = item.name;
  //   });
  //   return name;
  // };

  const searchFilter = () => {
    if (filters.search) return `&search=${filters.search}`;
    else return ``;
  };
  const departmentFilter = () => {
    if (filters.course) return `&course=${filters.course.id}`;
    else return ``;
  };
  const degreeFilter = () => {
    if (filters.degree) return `&degree=${filters.degree.id}`;
    else return ``;
  };
  const batchFilter = () => {
    if (filters.batch) return `&batch=${filters.batch.id}`;
    else return ``;
  };
  const statusFilter = () => {
    if (filters.status_active && filters.status_blocked) return ``;
    else if (filters.status_active) return `&status=ACTIVE`;
    else if (filters.status_blocked) return `&status=INACTIVE`;
    else return ``;
  };

  const candidateStatusFilter = () => {
    if (!filters?.candidate_status) return ``;
    return `&candidate_status=${filters?.candidate_status?.id}`;
  };

  const scoreFilter = () => {
    if (isGradeSystem) {
      if (filters.cgpa_min && filters?.cgpa_max)
        return `&min_cgpa=${Number(filters.cgpa_min)}&max_cgpa=${Number(
          filters.cgpa_max
        )}`;
      else if (filters.cgpa_min) return `&min_cgpa=${Number(filters.cgpa_min)}`;
      else if (filters.cgpa_max) return `&max_cgpa=${Number(filters.cgpa_max)}`;
      else return ``;
    } else {
      if (filters.cgpa_min && filters?.cgpa_max)
        return `&min_percentage=${Number(
          filters.cgpa_min
        )}&max_percentage=${Number(filters.cgpa_max)}`;
      else if (filters.cgpa_min)
        return `&min_percentage=${Number(filters.cgpa_min)}`;
      else if (filters.cgpa_max)
        return `&max_percentage=${Number(filters.cgpa_max)}`;
      else return ``;
    }
  };

  useEffect(() => {
    if (params?.job) {
      var url = `api/manage_student/shortlisted_student/?job_id=${params?.job
        ?.split("-")
        ?.pop()}${candidateStatusFilter()}`;
      dispatch(
        StudentsEligibleGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore
        )
      );
    }
  }, [collegeId]);

  useEffect(() => {
    if (firstApiCall && params?.job) {
      var url = `api/manage_student/shortlisted_student/?college_id=${collegeId}&job_id=${params?.job
        ?.split("-")
        ?.pop()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${candidateStatusFilter()}`;

      dispatch(
        StudentsEligibleGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore
        )
      );
    }
  }, [
    filters.status_blocked,
    filters.status_active,
    filters.batch,
    filters.course,
    filters.degree,
    filters.candidate_status,
  ]);

  useEffect(() => {
    if (clickSearchButton && params?.job) {
      var url = `api/manage_student/shortlisted_student/?college_id=${collegeId}&job_id=${params?.job
        ?.split("-")
        ?.pop()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}`;

      dispatch(
        StudentsEligibleGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore
        )
      );
    }
  }, [clickSearchButton]);

  useEffect(() => {
    if (applyClickedScore && params?.job) {
      var url = `api/manage_student/shortlisted_student/?college_id=${collegeId}&job_id=${params?.job
        ?.split("-")
        ?.pop()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}`;

      dispatch(
        StudentsEligibleGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore
        )
      );
    }
  }, [applyClickedScore]);

  const studentList = useSelector((state) => state?.StudentData?.eligible);
  const filterList = useSelector(
    (state) => state?.StudentData?.filterEligibleData
  );
  const allData = useSelector((state) => state?.StudentData?.allEligible);

  const [tempStudentList, setTempStudentList] = useState([]);
  useEffect(() => {
    if (studentList?.length > 0) {
      var arr = [...studentList];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        var a = [...item?.placement_cycle_list];
        var n = a.filter((i) => i.is_selected);
        new_arr.push({
          name: item?.name,
          enrollment_id: item?.enrollment_id,
          email: item?.email,
          phone: item?.phone,
          batch: item?.batch,
          degree: item?.degree?.name,
          course: item?.course?.name,
          status: item?.status,
          cgpa: item?.cgpa,
          applied_jobs: item?.applied_jobs,
          created: item?.created,
          placement_cycles: generateCsv(n, "name"),
        });
        new_arr.push(obj);
      });
      setTempStudentList(new_arr);
    }
  }, [studentList?.length]);

  useEffect(() => {
    if (selectedRowsData?.length > 0) {
      let arr = [...selectedRowsData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        var a = [...item?.placement_cycle_list];
        var n = a.filter((i) => i.is_selected);
        new_arr.push({
          name: item?.name,
          enrollment_id: item?.enrollment_id,
          email: item?.email,
          phone: item?.phone,
          batch: item?.batch,
          degree: item?.degree?.name,
          course: item?.course?.name,
          status: item?.status,
          cgpa: item?.cgpa,
          applied_jobs: item?.applied_jobs,
          created: item?.created,
          placement_cycles: generateCsv(n, "name"),
        });
      });
      setTempStudentList(new_arr);
    } else {
      if (studentList?.length > 0) {
        var arr = [...studentList];
        let new_arr = [];
        var obj = {};
        arr.forEach((item) => {
          var a = [...item?.placement_cycle_list];
          var n = a.filter((i) => i.is_selected);
          new_arr.push({
            name: item?.name,
            enrollment_id: item?.enrollment_id,
            email: item?.email,
            phone: item?.phone,
            batch: item?.batch,
            degree: item?.degree?.name,
            course: item?.course?.name,
            status: item?.status,
            cgpa: item?.cgpa,
            applied_jobs: item?.applied_jobs,
            created: item?.created,
            placement_cycles: generateCsv(n, "name"),
          });
          new_arr.push(obj);
        });
        setTempStudentList(new_arr);
      }
    }
  }, [selectedRowsData?.length]);

  return (
    <>
      {/* <Layout> */}
      {/* {placementSessionList?.length > 0 && (
          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <DropDownButton
              title="Placement Session 2021"
              placementSession={placementSession}
              setPlacementSession={setPlacementSession}
              setFilterDataText={setFilterDataText}
              show={true}
            />
          </div>
        )} */}
      <div className={classes.grid}>
        <div>
          <Typography variant="h2">
            {applied ? "Applied" : "Eligible"} Students for{" "}
            {params?.job?.split("-")[0]}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            // style={{ marginTop: "10px" }}
          >
            Showing eligible students based on the Job profile criteria.
          </Typography>
        </div>

        <div style={{ display: "flex" }}>
          <Link
            to={
              location?.state?.from === "/manage-students"
                ? `/manage-students`
                : `/track/?job_id=${params?.job?.split("-").pop()}`
            }
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.clear}
            >
              {/* Clear */}
              Close
            </Button>
          </Link>
          <CSVLink
            data={tempStudentList}
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            filename={"Students.csv"}
          >
            <Button
              variant="contained"
              startIcon={<GetAppIcon />}
              size="small"
              style={{ marginRight: "0px" }}
              className={classes.button}
            >
              Export
            </Button>
          </CSVLink>
        </div>
      </div>
      <EligibleTab
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        setClickSearchButton={setClickSearchButton}
        setSelectedRowsData={setSelectedRowsData}
        selectedRowsData={selectedRowsData}
        clickSearchButton={clickSearchButton}
        filterList={filterList}
        setApplyClickedScore={setApplyClickedScore}
        filterDataText={filterDataText}
        setFilterDataText={setFilterDataText}
        collegeId={collegeId}
        allData={allData}
      />
      {/* </Layout> */}
    </>
  );
}
