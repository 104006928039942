import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlacementCyclesGetAPI } from "../../api/PlacementCycles/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import Event from "../../components/Cards/Event/Event";
import PlacementCycle from "../../components/Cards/PlacementCycle";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import AddCycle from "../../components/DialogSection/AddCycle";
import EventForm from "../../components/Form/EventForm";
import NoDataFunc from "../../components/NoDataSVG";
import { Link } from "react-router-dom";

export default function ManageEvents() {
  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={5}>
        <Grid item xs={12} style={{ paddingBottom: "0px" }}>
          <Typography variant="h2">Manage Events</Typography>

          <Typography variant="body2" style={{ color: "#6c757d" }}>
            Create New Events or Manage Existing
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingBottom: "0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Recent Events</Typography>
          <Link to="/manage-event">
            <Button variant="text" color="primary">
              {" "}
              View All Events
            </Button>
          </Link>
        </Grid>
        <Grid item xs={4} style={{}}>
          <div>
            <Event />
          </div>
        </Grid>
        <Grid item xs={4} style={{}}>
          <div>
            <Event />
          </div>
        </Grid>
        <Grid item xs={4} style={{}}>
          <div>
            <Event />
          </div>
        </Grid>
        <Grid item xs={12} style={{}}>
          <Divider />
        </Grid>
        <Grid item xs={12} style={{}}>
          <EventForm />
        </Grid>
      </Grid>
    </>
  );
}
