import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, Grid, FormControl, InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
import { Autocomplete } from "@material-ui/lab";
import { uniqueArrayWithId } from "../../../../utility/uniqueArray";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import { AddJobToTeamMemberAPI } from "../../../../api/JobPost/action";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    width: "80%",
    marginBottom: "10px",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingTop: "10px",
    marginTop: "20px",
    display: "flex",
    marginBottom: "20px",
    paddingBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
  formControl: {
    marginTop: "12px",
    marginBottom: "5px",
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  circle: {
    height: "7px",
    width: "7px",
    marginRight: "10px",
    border: "1px solid black",
    borderRadius: "50%",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px",
    marginBottom: "5px",
  },
  button2: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "40px",
    paddingRight: "40px",
    display: "flex",
  },
}));

export default function AssignedJobProfiles({ data, params, collegeId, jobsList }) {
  const classes = useStyles();
  const [degree, setDegree] = React.useState("");
  const [tempJobs, setTempJobs] = useState([]);
  const [currentJobProfile, setCurrentJobProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setDegree(event.target.value);
  };

  useEffect(() => {
    if (data?.assigned_job?.length > 0) {
      setTempJobs(data?.assigned_job);
    }
  }, [data?.assigned_job]);

  const handleDeleteFunc = (index, array, name) => {
    var arr = [...array];

    arr.splice(index, 1);
    setTempJobs(arr);
  };
  const handleSubmit = () => {
    var arr = [];
    var new_arr = [];
    tempJobs?.map((item) => arr.push(item.id));
    var data = {
      college_id: [collegeId],
      object_id: params?.team_member_id,
      job_id_list: arr,
    };
    dispatch(AddJobToTeamMemberAPI(data, alert, history, location, setLoading, collegeId));
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Assigned Job Profiles</Typography>
          </div>

          <Grid container spacing={3} style={{ display: "flex", alignItems: "center" }}>
            <Grid item md={9}>
              <Autocomplete
                id="country-select-demo"
                options={jobsList}
                getOptionLabel={(option) => option.name}
                value={currentJobProfile}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCurrentJobProfile(newValue);
                    var arr = [...tempJobs];
                    arr.push(newValue);
                    setTempJobs(uniqueArrayWithId(arr, "id"));
                  } else {
                    setCurrentJobProfile(null);
                  }
                }}
                style={{ background: "#fff", borderRadius: 8 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Job Profiles"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <Button
                variant="contained"
                color="primary"
                className={classes.upload}
                onClick={() => {
                  if (!loading) handleSubmit();
                }}
              >
                {loading ? <CustomButtonCircularProgress /> : "Assign"}
              </Button>
            </Grid>
          </Grid>
          {!showMore
            ? tempJobs?.length > 0 &&
              tempJobs?.slice(0, 5).map((item, index) => (
                <div className={classes.item}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.circle} />
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      {item.name}
                    </Typography>
                  </div>
                  <Button
                    color="primary"
                    style={{ padding: "0px" }}
                    onClick={() => {
                      handleDeleteFunc(index, tempJobs);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              ))
            : tempJobs?.length > 0 &&
              tempJobs?.map((item, index) => (
                <div className={classes.item}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.circle} />
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      {item.name}
                    </Typography>
                  </div>
                  <Button
                    color="primary"
                    style={{ padding: "0px" }}
                    onClick={() => {
                      handleDeleteFunc(index, tempJobs);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              ))}

          {tempJobs?.length > 5 && (
            <Button
              onClick={() => {
                setShowMore(!showMore);
              }}
              className={classes.button2}
              endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              color="primary"
            >
              {showMore ? "Hide" : "View All"}
            </Button>
          )}
        </Paper>
      </div>
    </>
  );
}
const Data = [
  {
    icon: <PhoneIcon color="primary" />,
    info: "9839302906",
  },
  {
    icon: <MailIcon color="primary" />,
    info: "roshan.kapoor34@gmail.com",
  },
  {
    icon: <LanguageIcon color="primary" />,
    info: "www.roshankapoor.in",
  },
];
