import React from "react"

export default function ManageForms({ primaryColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="99" height="101" viewBox="0 0 99 101">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_5064" data-name="Rectangle 5064" width="99" height="101" transform="translate(380 270)" fill="#fff" stroke="#707070" stroke-width="1" />
        </clipPath>
      </defs>
      <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-380 -270)" clip-path="url(#clip-path)">
        <g id="form" transform="translate(389.863 277.935)">
          <path id="Path_17304" data-name="Path 17304" d="M15.391,11.391H5V84.129H63.883V73.738H74.274V1H15.391ZM60.419,80.666H8.464V14.855H48.3V26.978H60.419ZM51.76,17.3l6.21,6.21H51.76V17.3ZM18.855,4.464H70.811V70.274H63.883V24.529L50.745,11.391H18.855Z" transform="translate(0 0)" fill={primaryColor} />
          <path id="Path_17305" data-name="Path 17305" d="M30.782,14H10V34.782H30.782ZM27.319,31.319H13.464V17.464H27.319Zm8.659-8.659H51.565v3.464H35.978Zm0,8.659H51.565v3.464H35.978ZM10,39.978H35.978v3.464H10Zm31.173,0H51.565v3.464H41.173ZM10,48.637H20.391V52.1H10Zm15.587,0H51.565V52.1H25.587ZM10,57.3H34.246V60.76H10Zm29.442,0H51.565V60.76H39.442Z" transform="translate(3.659 9.514)" fill={primaryColor} />
        </g>
      </g>
    </svg>
  )
}
