import { Typography, TextField, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IndustryGetAPI } from "../../../api/Common/action";
import AddNewCompanyForm from "../DialogItem/AddNewCompanyForm";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Editor from "../../../components/Editor";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    fontSize: "16px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  card: {
    // marginTop: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
  },
  btngrid: {
    display: "flex",
    //paddingLeft: "25px",
    marginTop: "50px",
    justifyContent: "flex-start",
  },

  //   upload: {
  //     "@media only screen and (max-width: 2000px)": {
  //       marginTop: "100px",
  //     },
  //     "@media only screen and (max-width: 1700px)": {
  //       marginTop: "70px",
  //     },
  //     "@media only screen and (max-width: 1300px)": {
  //       marginTop: "100px",
  //     },
  //   },
}));

export default function JobDescripition({
  jobRolesList,
  setJobDesc,
  setPreSelectedJD,
  setJdSelectButton,
  handleClose,
  open,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [query, setQuery] = useState(null);
  const [mainQuery, setMainQuery] = useState(null);

  const [tempelateAvailable, setTempelateAvailable] = useState(false);
  useEffect(() => {
    dispatch(IndustryGetAPI(alert, history, location));
  }, []);

  const [currentJobRole, setCurrentJobRole] = useState(null);
  const getJobDescription = () => {
    axios
      .get(
        BackendBaseApi.NIYUKTI +
          "job/search/job_description/?job_role_id=" +
          currentJobRole.id
      )
      .then((res) => {
        if (res.data.success) {
          if (Object.keys(res.data.data).length > 0) {
            var new_data =
              "<h4>" +
              String(res.data.data.job_title) +
              "</h4>" +
              "<h4>" +
              String(res.data.data.job_role_name) +
              "</h4>" +
              "<p>" +
              String(res.data.data.brief) +
              String(res.data.data.description) +
              String(res.data.data.requirements) +
              "</p>";

            setQuery(new_data);
            setMainQuery(new_data);
          } else setTempelateAvailable(false);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  useEffect(() => {
    setTempelateAvailable(true);
    if (currentJobRole) getJobDescription();
  }, [currentJobRole]);

  return (
    <div>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <Typography variant="h5">Job Description Template</Typography>
          <div className={classes.card}>
            <Typography
              variant="body2"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              Choose the Job Role you want to edit the template for
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  id="combo-box-demo"
                  options={jobRolesList}
                  getOptionLabel={(option) => option.name}
                  value={currentJobRole}
                  onChange={(e, newValue) => {
                    setCurrentJobRole(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      variant="outlined"
                      label="Select a Job Role"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {tempelateAvailable && currentJobRole?.id && (
                  <div className={classes.upload}>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "20px" }}
                    >
                      Job Description Template
                    </Typography>
                    <Editor
                      state={mainQuery}
                      setState={setMainQuery}
                      placeholderText="Description"
                      removeAttachments={true}
                      desc={query}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            {tempelateAvailable && currentJobRole?.id && (
              <div className={classes.btngrid}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleClose}
                  color="primary"
                >
                  Cancel
                </Button>

                <Button
                  autoFocus
                  className={classes.button}
                  variant="contained"
                  style={{ marginLeft: "15px", minWidth: "140px" }}
                  color="primary"
                  onClick={() => {
                    setJdSelectButton(true);
                    setPreSelectedJD(mainQuery);
                    handleClose();
                  }}
                >
                  Select Template
                </Button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const Job = [{ title: "Full time", year: 1994 }];
