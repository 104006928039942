import { useState, useEffect } from "react";

const useCollegeGroupList = (collegeGroupList, isCollegeGroup) => {
  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (collegeGroupList?.length) {
      if (isCollegeGroup) {
        setNewCollegeGroupList(
          [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
        );
        setSelectedGroup({ id: "All", name: "All Groups" });
      } else {
        setNewCollegeGroupList(collegeGroupList);
        setSelectedGroup(collegeGroupList[0]);
      }
    }
  }, [collegeGroupList?.length, isCollegeGroup]);

  return {
    newCollegeGroupList,
    setNewCollegeGroupList,
    selectedGroup,
    setSelectedGroup,
  };
};

export default useCollegeGroupList;
