import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme, makeStyles } from "@material-ui/styles";
import {
  Paper,
  IconButton,
  InputBase,
  Grid,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PreviousJob from "../PreviousJob";
import AllCollegesGrid from "./AllCollegesGrid";
import MaangeJob from "../ManageJob/index2";
import { userRolesActionsFn } from "../../../api/SignIn/selector";
import { useDispatch, useSelector } from "react-redux";
import {
  JobFieldsPostAPI,
  JobSelectedFieldsAPI,
  JobUploaderFieldsAPI,
} from "../../../api/JobManagementForm/action";
import { useAlert } from "react-alert";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid #6C757D",
    width: "300px ",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  closeButton: {
    color: theme.palette.grey[500],
    marginRight: -theme.spacing(1),
  },
}));

export default function JobPostFormPreviewDialog({
  open,
  handleClose,
  jobType,
  collegeDetail,
}) {
  const [scroll, setScroll] = React.useState("paper");
  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [state, setState] = React.useState({
    checkedA: true,
  });

  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [loadingData1, setLoadingData1] = useState(false);
  const [loadingSelected, setLoadingSelected] = useState(false);

  useEffect(() => {
    if (collegeDetail?.id && jobType == 1 ? "internship" : "fullTime") {
      dispatch(
        JobUploaderFieldsAPI(
          setLoadingData1,
          alert,
          history,
          location,
          collegeDetail?.id,
          jobType == 1 ? "internship" : "fullTime"
        )
      );
      dispatch(
        JobSelectedFieldsAPI(
          setLoadingSelected,
          alert,
          history,
          location,
          collegeDetail?.id,
          jobType == 1 ? "internship" : "fullTime"
        )
      );
    }
  }, []);

  const [localFieldList, setLocalFieldList] = useState([]);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const [loadingData, setLoadingData] = useState(false);
  const fieldList = useSelector((state) => state?.JobUploader?.details);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ padding: 0 }}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          style={{ padding: 0, paddingTop: 0, paddingLeft: 5 }}
        >
          <Box>
            <MaangeJob
              localFieldList={localFieldList}
              setLocalFieldList={setLocalFieldList}
              userRolesPermission={userRolesPermission}
              loadingData={loadingData}
              fieldList={fieldList}
              job_type={jobType == 0 ? "full-time-job" : "internship"}
              disableAll={true}
              collegeDetail={collegeDetail}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
