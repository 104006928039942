import { Chip, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  boxShadowClass: {
    position: "relative",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    padding: 10,
    margin: "10px 0",
  },
  alignPill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statuschip: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 8,
    // borderRadius: "8px",
  },
}));

function SavedLanguage({ data }) {
  const classes = useStyles();
  return (
    <div>
      {data?.change_data?.languages?.length === 0 &&
      data?.change_type === "DELETE"
        ? data?.current_data?.map((item) => (
            <div className={classes?.boxShadowClass}>
              <Typography variant="body2">{item?.name}</Typography>
              <Tooltip placement="bottom" title={"Delete Language"}>
                <div
                  style={{
                    backgroundColor: "#FF0000",
                  }}
                  className={classes.statuschip}
                >
                  <Typography style={{ color: "#fff", fontSize: "14px" }}>
                    {"Delete"}
                  </Typography>
                </div>
              </Tooltip>
              <Typography variant="overline">
                Proficiency: {item?.proficiency}
              </Typography>
              {item?.level?.length > 0 && (
                <div className={classes.root}>
                  {item?.level.map((item2) => (
                    <Chip
                      color="primary"
                      variant="outlined"
                      size="small"
                      label={item2}
                      borderRadius="8px"
                    />
                  ))}
                </div>
              )}
            </div>
          ))
        : data?.change_data?.languages?.map((item) => (
            <div className={classes?.boxShadowClass}>
              <Typography variant="body2">{item?.name}</Typography>

              <Typography variant="overline">
                Proficiency: {item?.proficiency}
              </Typography>
              {item?.level?.length > 0 && (
                <div className={classes.root}>
                  {item?.level.map((item2) => (
                    <Chip
                      color="primary"
                      variant="outlined"
                      size="small"
                      label={item2}
                      borderRadius="8px"
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
    </div>
  );
}

export default SavedLanguage;
