import React, { useEffect, useState } from "react";
import { Typography, useTheme, makeStyles } from "@material-ui/core";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import ReportTable from "./ReportTable";
import StudentPlacedGraph from "./StudentPlacedGraph";
import axios from "axios";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../constants/constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 350,
    width: 350,
  },
}));

function ThirdGraphSection({
  selectedPlacement,
  collegeId,
  heading,
  yAxis,
  xAxis,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [value, setValue] = useState(2);
  const [graphData, setGraphData] = useState(null);
  function handleChange(id) {
    setValue(id);
  }

  const chartHelper = () => {
    if (graphData) {
      const labels = [];
      const data = [];
      const color = [];
      for (let key of graphData) {
        labels.push(key.name);
        data.push(key.value);
        color.push(key.color);
      }

      return {
        labels: labels,
        data: data,
        color: color[0],
        yAxis: yAxis,
        xAxis: xAxis,
      };
    }
  };

  useEffect(() => {
    if (selectedPlacement) {
      let url;
      if (heading === "Math vs Jobs")
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_job_data/job_month_wise/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;
      else
        url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/get_student_placed/month_wise/bar_chart/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`;

      url &&
        axios
          .get(url, { withCredentials: true })
          .then((res) => {
            if (res?.data?.success) setGraphData(res?.data?.data);
          })
          .catch((err) => {
            alert.error(err?.data?.error);
          });
    }
  }, [selectedPlacement]);

  const data = [
    {
      id: 2,
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <BarChartOutlinedIcon /> Graph
        </div>
      ),
      tab: (
        <StudentPlacedGraph yAxis={yAxis} xAxis={xAxis} DATA={chartHelper()} />
      ),
    },
    {
      id: 1,
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TableChartOutlinedIcon style={{ marginRight: 5 }} /> Table
        </div>
      ),
      tab: (
        <div style={{ maxHeight: "300px", overflow: "scroll" }}>
          {" "}
          <ReportTable chartData={chartHelper()} />{" "}
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          border: "1px solid #b0b6ba",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Typography variant="h6">{heading}</Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  //   padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <div style={{ display: "flex" }}>
                  {data.map((item, id) => (
                    <ul
                      style={{
                        padding: 0,
                        display: "flex",
                        listStyleType: "none",
                        flexWrap: "wrap",
                        textAlign: "center",
                      }}
                      id="myTab"
                      data-tabs-toggle="#myTabContent"
                      role="tablist"
                    >
                      <li
                        className="mr-2"
                        role="presentation"
                        onClick={() => handleChange(item.id)}
                      >
                        <div
                          style={{
                            padding: "6px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            color:
                              value === item.id
                                ? "#fff"
                                : theme.palette.primary.main,
                            backgroundColor:
                              value === item.id
                                ? theme.palette.primary.main
                                : "#fff",
                          }}
                        >
                          {item.title}
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {data.map((item, id) => (
          <div id="myTabContent">
            <div
              style={{
                display: value === item.id ? "block " : "none",
                marginTop: "20px",
              }}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {item.tab}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ThirdGraphSection;
