import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import TuneIcon from "@mui/icons-material/Tune";

import {
  Typography,
  Chip,
  Paper,
  Checkbox,
  FormControlLabel,
  Badge,
  FormHelperText,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
  CircularProgress,
  Switch,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
// import FilterSearch from "../Search/FilterSearch";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExperienceSlider from "../Slider/ExperienceSlider";
// import SalarySlider from "../Slider/SalarySlider";
import pSBC from "shade-blend-color";
import ShowMore from "./ShowMore";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: 5,
    margin: 0,
    boxShadow: "none",
  },
  checkboxroot: {
    padding: 5,
  },
  chip: {
    margin: 5,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    borderRadius: "8px",
  },
  chipicon: {
    color: theme.palette.primary.main,
  },
  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    //fontSize: "14px",
    color: "#c5c4ba",
    transform: "translate(15px, 14px) scale(1)",
  },
}));

const FilterDrawer = ({
  filterData,
  filterLoading,
  setSelectedFilters,
  selectedFilters,
  setClickFilterApplyButton,
  filterDataError,
  clearFilter,
  setClearFilter,
}) => {
  const classes = useStyles();
  const [filterCount, setFilterCount] = useState(0);
  const [tempFilterApplied, setTempFilterApplied] = useState(selectedFilters);
  const [state, setState] = React.useState({
    right: false,
  });

  useEffect(() => {
    if (selectedFilters) {
      setTempFilterApplied(selectedFilters);
    }
  }, [state]);

  useEffect(() => {
    if (filterData && Object.keys(filterData).length > 0) {
      let obj = {};
      Object.keys(filterData).forEach((item) => {
        obj[filterData[item].param_name] = [];
      });
      setTempFilterApplied(obj);
    }
  }, [filterData]);

  const selectCheckboxData = (paramName, currentObj, selectionType) => {
    // if (tempFilterApplied[paramName].length > 0) {
    // console.log("tempfilter", tempFilterApplied[paramName]);
    let currentArray = [...tempFilterApplied[paramName]];
    if (
      tempFilterApplied[paramName].some((item) => item?.id === currentObj?.id)
    ) {
      currentArray = currentArray.filter((item) => item?.id !== currentObj?.id);
    } else {
      if (selectionType === "single") currentArray = [currentObj];
      else currentArray.push(currentObj);
    }
    setTempFilterApplied({ ...tempFilterApplied, [paramName]: currentArray });

    // }
  };

  const checkApplyButtonDisabled = () => {
    let flag = true;
    Object.keys(tempFilterApplied).forEach((item) => {
      if (tempFilterApplied[item]?.length > 0) flag = false;
    });
    console.log(flag);
    return flag;
  };

  const is_multipleCheckboxType = (filterName) => {
    const multipleBoxes = {
      education_level: "education_level",
      emp_type_id: "emp_type_id",
      service_bond: "service_bond",
      work_exp: "work_exp",
      ctc: "ctc",
      job_type_id: "job_type_id",
    };
    if (multipleBoxes[filterName]) {
      return true;
    } else {
      return false;
    }
  };
  const is_autocompleteCheckboxType = (filterName) => {
    const autocomplete = {
      job_segment_id: "job_segment_id",
      job_role_id: "job_role_id",
      city_id: "city_id",
    };
    if (autocomplete[filterName]) {
      return true;
    } else {
      return false;
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
    //   return;
    // }
    setState({ ...state, [anchor]: open });
  };

  const toggleDrawerOnClick = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  // const [chipData, setChipData] = React.useState([
  //     { key: 0, label: "Matching" },
  //     { key: 1, label: "High Match" },
  //     { key: 2, label: "6 - 8 LPA" },
  // ]);

  const handleDelete = (chipToDelete, paramName) => () => {
    let tempArray = [...tempFilterApplied[paramName]];
    tempArray = tempArray.filter((item) => item?.id !== chipToDelete?.id);
    setTempFilterApplied({ ...tempFilterApplied, [paramName]: tempArray });
  };

  const [newstate, setNewState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setNewState({ ...newstate, [event.target.name]: event.target.checked });
  };

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  useEffect(() => {
    let count = 0;
    if (selectedFilters && Object.keys(selectedFilters)?.length) {
      Object.keys(selectedFilters).map((item) => {
        count = count + selectedFilters[item]?.length;
      });
    } else count = 0;
    setFilterCount(count);
  }, [selectedFilters]);

  useEffect(() => {
    if (clearFilter) {
      var obj = {};
      Object.keys(tempFilterApplied).forEach((item) => {
        obj[item] = [];
      });
      setTempFilterApplied(obj);
      setClearFilter(false);
    }
  }, [clearFilter]);

  // console.log("temp FILter", tempFilterApplied, filterData);

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <>
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "fixed",
            backgroundColor: "#fff",
            width: "inherit",
            zIndex: 100,
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          <Typography variant="h6">
            Filters {filterCount ? `(${filterCount})` : ""}
          </Typography>
          {tempFilterApplied && filterData && (
            <Button
              variant="contained"
              color="primary"
              style={{
                height: "35px",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "8px",
                display: "flex",
                boxShadow: "none",
              }}
              // disabled={checkApplyButtonDisabled}
              onClick={() => {
                setSelectedFilters(tempFilterApplied);
                toggleDrawerOnClick(anchor, false);
                setClickFilterApplyButton(true);
              }}
            >
              Apply
            </Button>
          )}
        </div>

        {/* <Divider /> */}
        <div style={{ marginTop: "60px" }}>
          <Paper component="ul" className={classes.root}>
            {tempFilterApplied &&
              filterData &&
              Object.keys(tempFilterApplied).map(
                (data) =>
                  tempFilterApplied[data].length > 0 &&
                  tempFilterApplied[data].map((item) => (
                    <li key={item?.id}>
                      <Chip
                        variant="outlined"
                        label={item?.name}
                        onDelete={handleDelete(item, data)}
                        size="small"
                        className={classes.chip}
                        deleteIcon={<CloseIcon className={classes.chipicon} />}
                      />
                    </li>
                  ))
              )}
          </Paper>
        </div>

        <div style={{ padding: "10px" }}>
          {tempFilterApplied &&
            filterData &&
            Object.keys(filterData).map((item) =>
              item !== "work_from_home" ? (
                <div style={{ marginBottom: 10 }}>
                  {filterData[item].data?.length > 0 && (
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      {filterData[item]?.heading}
                    </Typography>
                  )}
                  {filterData[item]?.field_type === "checkbox" &&
                    filterData[item].data?.length && (
                      <div className={classes.checkboxroot}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            {filterData[item]?.data.map((displayData) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    size="small"
                                    name="gilad"
                                    checked={tempFilterApplied[
                                      filterData[item]?.param_name
                                    ].some(
                                      (item) => item?.id === displayData?.id
                                    )}
                                    onChange={(e) => {
                                      selectCheckboxData(
                                        filterData[item]?.param_name,
                                        displayData,
                                        "single"
                                      );
                                    }}
                                  />
                                }
                                label={displayData?.name}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </div>
                    )}
                  {/* {filterData[item]?.field_type === "multiple_checkbox" && */}
                  {
                    // filterData[item]?.param_name === "education_level" &&
                    is_multipleCheckboxType(filterData[item]?.param_name) &&
                    filterData[item].data?.length && (
                      <div className={classes.checkboxroot}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            {filterData[item]?.data.map((displayData) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    size="small"
                                    name="gilad"
                                    checked={tempFilterApplied[
                                      filterData[item]?.param_name
                                    ]?.some(
                                      (item) => item?.id === displayData?.id
                                    )}
                                    onChange={(e) => {
                                      selectCheckboxData(
                                        filterData[item]?.param_name,
                                        displayData,
                                        "multiple"
                                      );
                                    }}
                                  />
                                }
                                label={displayData?.name}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </div>
                    )
                  }
                  {/* {filterData[item]?.field_type === "autocomplete_checkbox" && */}
                  {is_autocompleteCheckboxType(filterData[item]?.param_name) &&
                    filterData[item].data?.length ? (
                    <>
                      <Autocomplete
                        id="select-demo"
                        options={filterData[item].data}
                        getOptionLabel={(option) => String(option.name)}
                        renderOption={(option) => (
                          <Typography style={{ fontSize: "14px" }}>
                            {option.name}
                          </Typography>
                        )}
                        // value={filters[filterData[item].key]}
                        onChange={(e, newValue) => {
                          selectCheckboxData(
                            filterData[item]?.param_name,
                            newValue,
                            "multiple"
                          );
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: 30,
                          marginTop: 10,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={filterData[item].heading}
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                          />
                        )}
                      />
                      {/* <br /> */}
                      <div className={classes.checkboxroot}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            {filterData[item]?.data
                              .slice(0, 4)
                              .map((displayData) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      size="small"
                                      name="gilad"
                                      checked={tempFilterApplied[
                                        filterData[item]?.param_name
                                      ]?.some(
                                        (item) => item?.id === displayData?.id
                                      )}
                                      onChange={(e) => {
                                        selectCheckboxData(
                                          filterData[item]?.param_name,
                                          displayData,
                                          "multiple"
                                        );
                                      }}
                                    />
                                  }
                                  label={displayData?.name}
                                />
                              ))}
                          </FormGroup>
                        </FormControl>
                      </div>
                      {filterData[item]?.data?.length > 4 && (
                        <ShowMore
                          data={filterData[item]?.data?.slice(
                            4,
                            filterData[item]?.data?.length
                          )}
                          heading={filterData[item]?.heading}
                          handleChange={selectCheckboxData}
                          isCheckboxSelected={
                            tempFilterApplied[filterData[item]?.param_name]
                          }
                          paramName={filterData[item]?.param_name}
                        />
                      )}
                    </>
                  ) : null}
                  {/* {filterData[item]?.field_type === "slider" && <ExperienceSlider />} */}
                  {filterData[item]?.param_name === "wfh" && (
                    <>
                      <Switch
                        checked={tempFilterApplied[
                          filterData[item]?.param_name
                        ]?.some((item) => item?.id === 1)}
                        onChange={() => {
                          let param_name = filterData[item]?.param_name;
                          if (
                            tempFilterApplied[param_name]?.some(
                              (item) => item?.id === 1
                            )
                          ) {
                            setTempFilterApplied({
                              ...tempFilterApplied,
                              [param_name]: [],
                            });
                          } else {
                            setTempFilterApplied({
                              ...tempFilterApplied,
                              [param_name]: [{ id: 1, name: "WFH" }],
                            });
                          }
                        }}
                        color="primary"
                      />
                    </>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {filterData[item]?.heading}
                  </Typography>
                  {filterData[item]?.param_name === "wfh" && (
                    <>
                      <Switch
                        checked={tempFilterApplied[
                          filterData[item]?.param_name
                        ]?.some((item) => item?.id === 1)}
                        onChange={() => {
                          let param_name = filterData[item]?.param_name;
                          if (
                            tempFilterApplied[param_name]?.some(
                              (item) => item?.id === 1
                            )
                          ) {
                            setTempFilterApplied({
                              ...tempFilterApplied,
                              [param_name]: [],
                            });
                          } else {
                            setTempFilterApplied({
                              ...tempFilterApplied,
                              [param_name]: [{ id: 1, name: "WFH" }],
                            });
                          }
                        }}
                        color="primary"
                      />
                    </>
                  )}
                </div>
              )
            )}
          {/* <div>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {filterData[item]?.heading}
            </Typography>
          </div> */}
        </div>
      </>
    </div>
  );
  return (
    <>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Badge
                        badgeContent={filterCount}
                        color="primary"
                        style={{ width: "100%" }}
                    > */}

          <Button
            // startIcon={<FilterListIcon />}
            color="primary"
            startIcon={<TuneIcon />}
            variant="contained"
            onClick={toggleDrawer(anchor, true)}
            //variant="text"
            //color="inherit"

            style={{
              display: "flex",
              fontWeight: "normal",
              width: "100%",
              height: "48px",
              minWidth: 200,
              borderRadius: "8px",
              boxShadow: "none",
            }}
            // style={{
            //     display: "flex",
            //     boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            //     color: "#6C757D",
            //     fontWeight: "normal",
            //     backgroundColor: "#fff",
            //     paddingLeft: "20px",
            //     paddingRight: "20px",
            //     height: "50px",
            //     width: "12rem",
            //     borderRadius: "8px",
            // }}
          >
            Advanced Filter {filterCount ? `(${filterCount})` : ""}
          </Button>
          {/* </Badge> */}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {filterLoading ? (
              <CircularProgress size={24} />
            ) : filterDataError ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                }}
              >
                <p align="center" style={{ margin: 20 }}>
                  {" "}
                  Some Error Occurred
                  <br />
                  <span style={{ color: "red" }}>"{filterDataError}"</span>
                </p>
              </div>
            ) : (
              <> {list(anchor)}</>
            )}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  );
};

export default FilterDrawer;
