import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from "react";
import SelectFeaturedCollege from "../JobApplicationsInfo/SelectFeaturedCollege";
import JobCardSkeleton from "./JobCardSkeleton";
import RejectedResponse from "./RejectedResponse";

const useStyles = (theme) => ({
    watch: {
        color: "black",
    }
})

const JobList = ({ allJobs, handleOpen, nextPageUrl, setPageNo, showSkeletonCards, handleJobDetails, handleNotInterestedCard }) => {

    const classes = useStyles();
    //  for better performance this has been shifted to separate component as useCallback memoizes 
    // from where it is called.
    const loaderRef = useRef(null);

    const handleObserver = useCallback((entries) => {

        // console.log("observe entries", entries);

        const target = entries[0];
        // console.log("XXXXXX", nextPageUrl, showSkeletonCards, target.isIntersecting);
        if (target.isIntersecting && nextPageUrl && !showSkeletonCards) {

            setPageNo((pageNo) => pageNo + 1);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0,
        };

        // observer creation
        const observer = new IntersectionObserver(handleObserver, option);

        //  observe the reference
        if (loaderRef && loaderRef.current) observer.observe(loaderRef.current);

        // clean up on unmount
        // console.log("observing", observer);

        // return () => observer?.unobserve(loaderRef?.current);


    }, [handleObserver, loaderRef]);



    return (
        <Grid container spacing={5}
        // style={{ paddingRight: "4rem", paddingLeft: "2rem" }}
        >

            {allJobs?.length > 0 && allJobs?.map((job, index) => {

                if (job?.['removed']) {
                    return (
                        <Grid item md={6}>

                            <RejectedResponse
                                handleNotInterestedCard={handleNotInterestedCard}
                                jobIndex={index}

                            />
                        </Grid>
                    )
                };
                //this is the same component as used in company SAAS for colleges here we are
                //selecting companies.
                return (<Grid item md={4}>
                    <SelectFeaturedCollege
                        jobData={job?.['job']}
                        handleOpen={handleOpen}
                        handleJobDetails={handleJobDetails}
                        jobIndex={index}
                        handleNotInterestedCard={handleNotInterestedCard}
                    />

                    {/* <FullTimeJob
                        data={job?.['job']}
                        handleOpen={handleOpen}
                        handleJobDetails={handleJobDetails}
                        jobIndex={index}
                        handleNotInterestedCard={handleNotInterestedCard}
                    /> */}
                </Grid>
                )
            })
            }
            {
                showSkeletonCards ?
                    <>
                        <Grid item md={4}>
                            <JobCardSkeleton />
                        </Grid>
                        <Grid item md={4}>
                            <JobCardSkeleton />
                        </Grid>
                        <Grid item md={4}>
                            <JobCardSkeleton />
                        </Grid>
                    </>
                    : null
            }
            <div
                ref={loaderRef}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className={classes.watch}
            >
                {/* {allJobs?.length > 10 && <CustomCircularProgress />} */}
            </div>

        </Grid>
    )
}

export default JobList