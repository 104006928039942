import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getSinglePostedJobAPI } from "../../api/JobPost/action";
import JobDetailsCard from "../../components/Cards/JobDetailsCard";
import CustomCircularProgress from "../../components/CircularProgressBar";
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function PostDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [jobId, setJobId] = useState("");

  useEffect(() => {
    if (params.job_id) {
      const job_id = params.job_id.split("-");
      setJobId(job_id[job_id.length - 1]);
    }
  }, [params]);

  useEffect(() => {
    if (jobId) {
      var url = `api/manage_job/view/job/?job_id=${jobId}`;
      dispatch(getSinglePostedJobAPI(setLoading, alert, history, location, jobId, url));
    }
  }, [jobId]);

  const jobData = useSelector((state) => state?.SingleJobData?.details?.college_job_data);

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link href="/" onClick={handleClick} style={{ textDecoration: "none" }}>
          <Typography color="secondary" variant="body2">
            Manage Students
          </Typography>
        </Link>
        <Link style={{ textDecoration: "none" }} color="inherit" href="/getting-started/installation/" onClick={handleClick}>
          <Typography color="primary" variant="body2">
            {jobData?.company_name}
          </Typography>
        </Link>
      </Breadcrumbs>
      <br />
      {/* <Typography variant="h2">Post Jobs</Typography>
        <Typography variant="body2" style={{ color: "#6c757d" }}>
          Post Jobs for students Placement
        </Typography> */}
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={12}>
          {loading ? <CustomCircularProgress /> : <JobDetailsCard data={jobData} />}
          {/* <Button
              color="primary"
              variant="contained"
              //size="small"
              style={{
                borderRadius: "30px",
                boxShadow: "none",
                paddingLeft: "100px",
                height: "48px",
                display: "flex",
                marginTop: "20px",
                paddingRight: "100px",
              }}
            >
              Post Job
            </Button> */}
        </Grid>
      </Grid>
      {/* </Layout> */}
    </>
  );
}
