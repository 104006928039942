import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const COLLEGE_DATA = "COLLEGE_DATA";
export const AFFILIATED_FROM = "AFFILIATED_FROM";
export const COLLEGE_CITY = "COLLEGE_CITY";
export const COLLEGE_STATE = "COLLEGE_STATE";

export const CollegeGet = (alert, history, location, college) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.PRAVESH}api/saas/college_details/?college_id=${college}`, {
        withCredentials: true,
      })
      .catch((error) => {
        // setLoading(false);

        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: COLLEGE_DATA,
        payload: res.data.data,
      });
      // setLoading(false);
    } else {
      // setLoading(false);
    }
  };
};

export const CollegePatch = (setLoading, alert, history, location, data, college) => {
  return async (dispatch) => {
    const res = await axios
      .patch(`${BackendBaseApi.PRAVESH}api/saas/college/profile/edit/?college_id=${college}`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);

        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      setLoading(false);
      alert.success("College Profile Successfully Updated");
    } else {
      setLoading(false);
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const affliationgGet = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.PRAVESH}api/saas/university/all/`, {
        withCredentials: true,
      })
      .catch((error) => {
        // setLoading(false);

        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: AFFILIATED_FROM,
        payload: res.data.data,
      });
      // setLoading(false);
    } else {
      // setLoading(false);
    }
  };
};

export const cityGet = (alert, history, location, cityid) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.PRAVESH}api/location/city/?search=${cityid}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.status === 200) {
      dispatch({
        type: COLLEGE_CITY,
        payload: res.data,
      });
    } else {
    }
  };
};

export const stateGet = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.PRAVESH}api/location/state/`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.status === 200) {
      dispatch({
        type: COLLEGE_STATE,
        payload: res.data,
      });
    } else {
    }
  };
};
