import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LanguageIcon from "@material-ui/icons/Language";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import { GroupAddOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { themeSelectorFn } from "../../../../api/Domain/selector";
import tick from "../../../../assets/png/verified-user.png";
import { ReactComponent as Default } from "../../../../assets/svg/student_placeholer.svg";
import MuiCustomTheme from "../../../NewMUITheme/MuiCustomTheme";
import moment from "moment";
import { useAlert } from "react-alert";
import WcIcon from "@mui/icons-material/Wc";
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
}));

export default function SavedContact({ data, fromPublicTrack, validatorData }) {
  const classes = useStyles();
  const alert = useAlert();
  const theme_change = useSelector(themeSelectorFn);
  const Data = [
    {
      icon: <PhoneIcon color="primary" />,
      info: data?.updatedData?.phone,
      show: data?.updatedData?.phone
        ? fromPublicTrack
          ? validatorData?.action_performed?.action?.length > 0 &&
            validatorData?.action_performed?.action?.includes("phone")
            ? true
            : false
          : true
        : false,
      type: "Phone Number",
    },
    {
      icon: <MailIcon color="primary" />,
      info: data?.updatedData?.email,
      show: data?.updatedData?.email
        ? fromPublicTrack
          ? validatorData?.action_performed?.action?.length > 0 &&
            validatorData?.action_performed?.action?.includes("mail")
            ? true
            : false
          : true
        : false,
      type: "Mail Id",
    },

    {
      icon: <LanguageIcon color="primary" />,
      info: "www.roshankapoor.in",
      show: data?.updatedData?.website ? true : false,
    }, //LocationOnOutlinedIcon
    {
      icon: <LocationOnIcon color="primary" />,
      info: data?.updatedData?.current_city_name,
      show: data?.updatedData?.current_city_name ? true : false,
    },
  ];

  // const social = [
  //   {
  //     icon: github,
  //   },
  //   {
  //     icon: dri,
  //   },

  //   {
  //     icon: behance,
  //   },

  //   {
  //     icon: Linkedin,
  //   },
  // ];

  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.paper}>
      <Grid container spacing={2}>
        {/* <Grid item>
          <ButtonBase className={classes.image}>
            {data?.updatedData?.profile_picture ? (
              <img
                className={classes.img}
                alt="complex"
                src={data?.updatedData?.profile_picture}
              />
            ) : (
              <>
                <Default
                  width="128"
                  height="128"
                  alt="img"
                  className={classes.img}
                  fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                />
              </>
            )}
          </ButtonBase>
        </Grid> */}
        <Grid item xs={12} sm container>
          <Grid
            item
            xs
            container
            direction="column"
            spacing={2}
            style={{ display: "contents" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography variant="h6">
                  {" "}
                  {data?.updatedData?.first_name +
                    " " +
                    data?.updatedData?.last_name}
                </Typography>
                &nbsp;{" "}
                {/* <img src={tick} style={{ height: 20, marginTop: 2 }} /> */}
              </div>
              {/* <div style={{ display: "flex", marginTop: "10px" }}>
                <Typography variant="body2">
                  {" "}
                  Profession: {data?.updatedData?.profession}
                </Typography>
                &nbsp;
              </div> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {Data.map(
        (item) =>
          item.show && (
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>{item.icon}</Grid>

              <Grid item xs>
                <Typography variant="body2" color="secondary">
                  {item.info}
                </Typography>
              </Grid>
            </Grid>
          )
      )}

      {data?.updatedData?.gender ? (
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <MuiCustomTheme>
              <WcIcon color="primary" />
            </MuiCustomTheme>
          </Grid>
          <Grid item xs style={{ display: "flex" }}>
            <Typography
              variant="body2"
              color="secondary"
              style={{ marginRight: "5px" }}
            >
              Gender :
            </Typography>
            <Typography variant="body2" color="secondary">
              {data?.updatedData?.gender?.charAt(0)?.toUpperCase() +
                data?.updatedData?.gender?.slice(1)}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {data?.updatedData?.dob ? (
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <MuiCustomTheme>
              <CalendarTodayTwoToneIcon color="primary" />
            </MuiCustomTheme>
          </Grid>

          <Grid item xs style={{ display: "flex" }}>
            <Typography
              variant="body2"
              color="secondary"
              style={{ marginRight: "5px" }}
            >
              DOB :
            </Typography>
            <Typography variant="body2" color="secondary">
              {moment(data?.updatedData?.dob).format("D MMM, YYYY")}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {data?.updatedData?.marital_status ? (
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <MuiCustomTheme>
              <GroupAddOutlined color="primary" />
            </MuiCustomTheme>
          </Grid>

          <Grid item xs style={{ display: "flex" }}>
            <Typography
              variant="body2"
              color="secondary"
              style={{ marginRight: "5px" }}
            >
              Marital Status :
            </Typography>
            <Typography variant="body2" color="secondary">
              {data?.updatedData?.marital_status}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {/* <div style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
     
        {data?.updatedData?.profile_url_app?.length > 0 &&
          data?.updatedData?.profile_url_app?.map((item) => (
            // <Button>
            <a href={item.value} target="_blank">
              <img
                src={item.logo}
                height="30px"
                width="30px"
                alt="socialmedia"
                style={{
                  marginRight: "30px",
                  cursor: "pointer",
                  borderRadius: 8,
                }}
              />
            </a>
            // </Button>
          ))}
      </div> */}
      {data?.updatedData?.about && (
        <>
          <Typography variant="h6">About Me</Typography>
          <br />
          <Typography variant="body2" color="secondary">
            {data?.updatedData?.about}
          </Typography>
        </>
      )}
    </div>
  );
}
