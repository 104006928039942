import {
  Paper,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleSharpIcon from "@material-ui/icons/AddCircleSharp";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Editor from "../../Editor";
import Calendar from "../../Input/Calendar";
import { isGradeSystemFn } from "../../../api/Domain/selector";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: "20px",
    height: "auto",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    padding: "15px",
  },
  leftpaper: {
    height: "auto",
    boxShadow: "none",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  rightpaper: {
    height: "auto",
    boxShadow: "none",
    // padding: "15px",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  helper: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //padding: theme.spacing(1, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textfield: {
    padding: "16px",
  },
  heading: {
    padding: "20px",
    paddingLeft: "60px",
  },
}));

export default function StudentUpload({
  localFieldList,
  setLocalFieldList,
  fieldList,
  selectedFields,
  userRolesPermission,
}) {
  const classes = useStyles();
  const [degree, setDegree] = React.useState("");
  const isGradeSystem = useSelector(isGradeSystemFn);
  const handleChange = event => {
    setDegree(event.target.value);
  };

  useEffect(() => {
    if (fieldList?.length > 0) {
      var arr = [...fieldList];
      arr?.forEach(element => {
        element["is_selected"] = checkIfExists(element);
      });
      setLocalFieldList(arr);
    }
  }, [fieldList]);

  const checkIfExists = i => {
    var bool_ = false;
    if (selectedFields && selectedFields?.student_form_fields?.length > 0) {
      selectedFields?.student_form_fields?.map(item => {
        if (item.id === i.id || i.pre_selected) bool_ = true;
      });
    } else {
      if (i.pre_selected) bool_ = true;
    }
    return bool_;
  };

  function getFieldContent(step, item) {
    switch (step) {
      case "textfield":
        return (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            disabled={item.is_selected}
            inputProps={{ className: classes.textfield }}
            label={item.key === "cgpa" ? (isGradeSystem ? "Current Education Marks (CGPA)" : "Current Education Marks (Percentage)") : item.field_name}
          />
        );
      case "dropdown":
        return (
          <FormControl
            variant="outlined"
            className={classes.formControl}
            disabled={item.is_selected}
          >
            <InputLabel
              required
              id="demo-simple-select-outlined-label"
              className={classes.floatingLabelFocusStyle}
            >
              {item.field_name}
            </InputLabel>
            <Select
              id="demo-simple-select-outlined"
              value={degree}
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
              onChange={handleChange}
              label={item.field_name}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Btech</MenuItem>
              <MenuItem value={20}>BCA</MenuItem>
              <MenuItem value={30}>MCA</MenuItem>
            </Select>
          </FormControl>
        );
      case "autocomplete":
        return (
          <FormControl
            variant="outlined"
            className={classes.formControl}
            disabled={item.is_selected}
          >
            <InputLabel
              required
              id="demo-simple-select-outlined-label"
              className={classes.floatingLabelFocusStyle}
            >
              {item.field_name}{" "}
            </InputLabel>
            <Select
              id="demo-simple-select-outlined"
              value={degree}
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
              onChange={handleChange}
              label={item.field_name}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Btech</MenuItem>
              <MenuItem value={20}>BCA</MenuItem>
              <MenuItem value={30}>MCA</MenuItem>
            </Select>
          </FormControl>
        );
      case "dropdown-with-checkbox":
        return (
          <>
            <Grid
              container
              spacing={1}
              style={{ marginTop: "0px", marginBottom: "0px" }}
            >
              <Grid item xs={3}>
                <Calendar title="Course Start Date" />{" "}
              </Grid>
              <Grid item xs={9}>
                <Calendar title="Course End Date" />{" "}
              </Grid>
            </Grid>
          </>
        );
      case "textfield-with-checkbox":
        return (
          <>
            <FormControlLabel
              style={{ paddingTop: "20px" }}
              value="end"
              control={
                <Checkbox
                  color="primary"
                  size="small"
                  disabled={item.is_selected}
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Any BackLogs
                </Typography>
              }
              labelPlacement="end"
            />
            <TextField
              disabled={item.is_selected}
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              inputProps={{ className: classes.textfield }}
              label={item.field_name}
            />
          </>
        );
      default:
        return "Unknown step";
    }
  }

  const handleSelectionChange = (e, item, index) => {
    var arr = [...localFieldList];
    item.is_selected = e.target.checked;
    if (index !== -1) {
      arr[index] = item;
      setLocalFieldList(arr);
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.heading}>
          Student Data Uploader
        </Typography>
        <Grid container>
          {localFieldList?.length > 0 &&
            localFieldList?.map((item, index) => (
              <>
                <>
                  <Grid item xs={3} className={classes.grid}>
                    <Paper className={classes.leftpaper}>
                      {" "}
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={item?.is_selected}
                            onChange={e => {
                              if (!item?.pre_selected)
                                handleSelectionChange(e, item, index);
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {" "}
                            Include in Form
                          </Typography>
                        }
                        labelPlacement="end"
                        disabled={item?.pre_selected}
                        // disabled={
                        //   userRolesPermission?.some(
                        //     item => item.access_control_key === "manage_forms"
                        //   ) === true
                        //     ? false
                        //     : true
                        // }
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={9}>
                    <div className={clsx(classes.column, classes.helper)}>
                      <Paper className={classes.rightpaper}>
                        {getFieldContent(item.field_type, item)}
                      </Paper>
                    </div>
                  </Grid>
                </>
              </>
            ))}
        </Grid>
      </Paper>
    </>
  );
}
