import { Button, Container, Grid, Typography } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import LanguageIcon from "@material-ui/icons/Language";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CoursesGetPublicAPI, IndustryGetAPI } from "../../api/Common/action";
import { AllCompanyPublicAPI } from "../../api/Company/action";
import {
  collegeDetailsSelectorFn,
  isGradeSystemFn,
} from "../../api/Domain/selector";
import { EJDPostAPI, EJDSelectedSingleAPI } from "../../api/EJD/action";
import {
  AllJobRolesAPI,
  AllJobRoundsAPI,
  AllJobSegmentAPI,
  AllLocationsAPI,
} from "../../api/JobPost/action";
import CollegeImage from "../../assets/img/company.jpg";
import defaultCompany from "../../assets/img/default.jpg";
import Facebook from "../../assets/png/facebook.png";
import Insta from "../../assets/png/insta.png";
import Linkedin from "../../assets/png/linkedin.png";
import FullTimeJob from "../../components/Cards/JobProfile/FullTimeJob";
import InternshipJob from "../../components/Cards/JobProfile/InternshipJob";
import CustomCircularProgress from "../../components/CircularProgressBar";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import FormFooter from "../../components/Common/FormFooter";
import BasicInfo from "../../components/Form/BasicInfo";
import JobProfileInfo from "../../components/Form/JobProfile";
import { NEXT_APP_ENDPOINT } from "../../constants/constants";
import { checkForUrl } from "../../utility/checkForUrl";
import ThankYou from "../ThankYou";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },

  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    height: "270px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  imgdata: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",

    //  borderRadius: "50%",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
  },
  submit: {
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  paper: {
    padding: "10px",
    borderRadius: "8px",
    //boxShadow: "none",
    // border: ".5px solid #5a6268",
    boxShadow: "rgb(0 0 0 / 21%) 0px 2px 4px",
  },
  textgrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  imagedata: {
    display: "flex",
    width: 150,
    backgroundColor: "#fff",
    height: 80,
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  icons: {
    marginRight: "10px",
    cursor: "pointer",
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      // width: "300px",
    },
  },
}));

function EjdCompany() {

  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [loadingJobSegment, setLoadingJobSegment] = useState(false);
  const [loadingJobRoles, setLoadingJobRoles] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [jobDesc, setJobDesc] = useState("");
  const [salaryBreakup, setSalaryBreakup] = useState("");
  const [ppoSalaryBreakup, setPpoSalaryBreakup] = useState("");
  const [dataSelectedNewFormat, setDataSelectedNewFormat] = useState([]);
  const [loadingPost, setLoadingPost] = useState(false);
  const [ejdJobsList, setEjdJobsList] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [qualifications, setQualifications] = useState({});
  const [specializationInDegreeId, setSpecializationInDgegreeId] = useState([]);
  const [coursesArray, setCoursesArray] = useState([]);
  const [pushArray, setPushArray] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [step, setStep] = useState(0);
  const [pocList, setPocList] = useState([]);
  const [companyNotExist, setCompanyNotExist] = useState(false);
  const isGradeSystem = useSelector(isGradeSystemFn);
  const [disableCompany, setDisableCompany] = useState(false);
  const [currentJobLocation, setCurrentJobLocation] = useState(null);
  const [currentbatch, setCurrentBatch] = useState(null);
  const [preSelectedJD, setPreSelectedJD] = useState(false);
  const [jdPath, setJdPath] = useState("");


  useEffect(() => {
    if (params.token) {
      dispatch(
        EJDSelectedSingleAPI(
          setLoading,
          alert,
          history,
          location,
          window.location.href
        )
      );
    }
  }, [params?.token]);

  const [groupFieldList, setGroupFieldList] = useState({});

  const selectedFields = useSelector(
    (state) => state?.SingleEJDField?.details?.job_form_fields
  );
  const mainEJDData = useSelector((state) => state?.SingleEJDField?.details);
  const collegeDetails = useSelector(collegeDetailsSelectorFn);

  useEffect(() => {
    if (selectedFields?.length > 0) {
      var result = selectedFields.reduce(function (r, a) {
        if (a.ejd_section) {
          r[a.ejd_section] = r[a.ejd_section] || [];
          r[a.ejd_section].push(a);
        } else {
          r["top"] = r[["top"]] || [];

          r["top"].push(a);
        }

        return r;
      }, Object.create(null));
      setGroupFieldList(result);
    }
  }, [selectedFields?.length]);

  useEffect(() => {
    dispatch(AllJobSegmentAPI(setLoadingJobSegment, alert, history, location));
    dispatch(AllJobRolesAPI(setLoadingJobRoles, alert, history, location));
    dispatch(AllLocationsAPI(setLoadingLocations, alert, history, location));
    dispatch(AllJobRoundsAPI(alert, history, location));
  }, []);

  useEffect(() => {
    if (mainEJDData?.college_id) {
      dispatch(
        CoursesGetPublicAPI(
          setLoadingCourses,
          alert,
          history,
          location,
          mainEJDData?.college_id
        )
      );
      dispatch(
        AllCompanyPublicAPI(alert, history, location, mainEJDData?.college_id)
      );
    }
  }, [mainEJDData]);

  const companyList = useSelector((state) => state?.PublicCompany?.list);

  const initialState = {
    company_id: null,
    company_name: null,
    company: null,
    job_title: null,
    job_type_id: 7,
    job_type_name: "Full Time",
    job_role_id: null,
    job_role_name: null,
    job_role: null,
    job_segment_id: null,
    job_segement_name: null,
    job_segment: null,
    document_for_jd:null,
    application_start_date: null,
    application_end_date: null,
    joining_date: null,
    campus_drive_date:null,
    job_description: null,
    ctc_min: null,
    ctc_max: null,
    bonus: false,
    salary_type: "NOT DISCLOSED",
    salary_breakup: null,
    wfh: false,
    pan_india: false,
    job_location: [],
    vacancy: null,
    service_bond: false,
    conditions: null,
    ppo: false,
    ppo_ctc_min: null,
    ppo_salary_type: "NOT DISCLOSED",
    ppo_ctc_max: null,
    salary_breakup_ppo: null,
    degree_spec_selected: [],
    grad_scoring: isGradeSystem ? "CGPA" : "Percentage",
    grad_min_score: "",
    backlog: false,
    sec_scoring: "Percentage",
    sec_min_score: "",
    senior_sec_scoring: "Percentage",
    senior_sec_min_score: "",
    eligible_batches: [],
    rounds: [],
    documents: [],
    skills: [],
    internship_duration: null,
  };

  const initialDates = {
    applicationStartDate: null,
    applicationEndDate: null,
    joiningDate: null,
    campusDriveDate:null,
  };

  const [applicationDates, setApplicationDates] = useState({ ...initialDates });
  const [jobDetailsError, setJobDetailsError] = useState({
    company_id: null,
    company_name: null,
    company: null,
    job_title: null,
    job_type_id: null,
    job_type_name: null,
    job_role_id: null,
    job_role_name: null,
    job_role: null,
    job_segment_id: null,
    job_segement_name: null,
    job_segment: null,
    application_start_date: null,
    application_end_date: null,
    joining_date: null,
    campus_drive_date:null,
    job_description: null,
    ctc_min: null,
    ctc_max: null,
    salary_type: null,
    ppo_salary_type: null,
    bonus: null,
    salary_breakup: null,
    wfh: null,
    pan_india: null,
    job_location: null,
    vacancy: null,
    service_bond: null,
    conditions: null,
    ppo: null,
    ppo_ctc_min: null,
    ppo_ctc_max: null,
    salary_breakup_ppo: null,
    degree_spec_selected: null,
    grad_scoring: null,
    grad_min_score: null,
    sec_scoring: null,
    sec_min_score: null,
    senior_sec_scroing: null,
    senior_sec_min_score: null,
    eligible_batches: null,
    rounds: null,
    round_duration: null,
    venue_type: null,
    venue: null,
    documents: null,
    placement_cycle: null,
    skills: null,
    internship_duration: null,
  });

  const [jobDetails, setJobDetails] = useState({
    ...initialState,
  });

  const initialCompanyDetails = {
    company_name: null,
    industry: null,
    industry_type_id: null,
    industry_type_name: null,
    company_object: null,
    visiting_date: null,
    about: null,
    single_poc: {
      contact_person_name: null,
      email: null,
      phone: null,
      designation: null,
    },
    poc_list: [],
  };
  const [companyDetails, setCompanyDetails] = useState({
    ...initialCompanyDetails,
  });

  const [companyDetailsError, setCompanyDetailsError] = useState({
    company_name: null,
    industry: null,
    industry_type: null,
    visiting_date: null,
    about: null,
    single_poc: {
      contact_person_name: null,
      email: null,
      phone: null,
      designation: null,
    },
    poc_list: null,
  });

  const validateData = () => {
    var is_valid = true;
    var company_error = null;
    var job_title_error = null;
    var job_role_error = null;
    var job_segment_error = null;
    var start_date_error = null;
    let campus_drive_date_error = null;
    var end_date_error = null;
    var job_desc_error = null;
    var ctc_min_error = null;
    var ctc_max_error = null;
    var location_error = null;
    var vacancy_error = null;
    var ppo_ctc_max_error = null;
    var ppo_ctc_min_error = null;
    var courses_error = null;
    var min_score_error = null;
    var batch_error = null;
    var round_error = null;
    var duration_error = null;
    var venue_error = null;
    var placement_error = null;
    let document_error = null;
    var industry_error = null;
    var industry_type_error = null;
    var internship_duration_error = null;
    var spoc_error = null;
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    var skills_error = null;
    var twelve_error = null;
    var tenth_error = null;
    selectedFields?.length > 0 &&
      selectedFields?.map((item) => {
        // if (item.field_type.toLowerCase() === "company") {
        //   if (!jobDetails?.company) {
        //     company_error = "Please Enter the company!!";
        //     is_valid = false;
        //   }
        // }
        if (
          item.field_type.toLowerCase() === "textfield" &&
          item?.key === "job_title"
        ) {
          if (!jobDetails?.job_title) {
            job_title_error = "Please Enter Job Title";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "autocomplete") {
          if (item?.key === "job_role") {
            if (!jobDetails?.job_role) {
              job_role_error = "Please Enter Job Role";
              is_valid = false;
            }
          }
          if (item?.key === "job_segment") {
            if (!jobDetails?.job_segment) {
              job_segment_error = "Please Enter Job Segment";
              is_valid = false;
            }
          }
        }
        if (item.field_type.toLowerCase() === "application_date") {
          if (!jobDetails?.application_start_date) {
            start_date_error = "Please Enter application start date";
            is_valid = false;
            // alert.error("Please Enter Application Start Date!");
          }
          if (!jobDetails?.application_end_date) {
            end_date_error = "Please Enter application end date";
            is_valid = false;
            // alert.error("Please Enter Application End Date!");
          }
        }
        // if (item.field_type.toLowerCase() === "campus_drive_date") {

        //   if (!jobDetails?.campus_drive_date) {

        //     campus_drive_date_error = "Please Enter Campus Drive date";
        //     is_valid = false;
        //   }          
        // }

        if (item.field_type.toLowerCase() === "job-desc") {
          if (!jobDetails?.job_description) {
            job_desc_error = "Enter Job Description";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "ctc_range") {
          if (jobDetails?.salary_type === "PAID") {
            if (!jobDetails?.ctc_min) {
              ctc_min_error = "Enter Min Ctc";
              is_valid = false;
            }
            if (!jobDetails?.ctc_max) {
              ctc_max_error = "Enter Max Ctc";
              is_valid = false;
            }
            if (jobDetails?.ctc_min && jobDetails?.ctc_max) {
              if (jobDetails?.ctc_max < jobDetails?.ctc_min) {
                alert.error("Please Enter Max CTC more than min CTC");
                ctc_min_error = "Enter correct Min Ctc";
                ctc_max_error = "Enter correct Max Ctc";

                is_valid = false;
              }
            }
          }
        }
        if (item.field_type.toLowerCase() === "location") {
          if (!jobDetails?.pan_india && jobDetails.job_location?.length === 0) {
            location_error = "Please enter job locations";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "vacancy") {
          if (!jobDetails?.vacancy || jobDetails?.vacancy === 0) {
            vacancy_error = "Please enter Vacancy";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "duration") {
          if (
            (jobDetails?.job_type_id === 1 &&
              !jobDetails?.internship_duration) ||
            jobDetails?.internship_duration === 0
          ) {
            internship_duration_error = "Please enter internship duration";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "ppo") {
          if (jobDetails?.ppo) {
            if (jobDetails?.ppo_salary_type === "PAID") {
              if (!jobDetails?.ppo_ctc_min || jobDetails?.ppo_ctc_min === 0) {
                ppo_ctc_min_error = "Enter Min Ctc";
                is_valid = false;
              }
              if (!jobDetails?.ppo_ctc_max || jobDetails?.ppo_ctc_max === 0) {
                ppo_ctc_max_error = "Enter Max Ctc";
                is_valid = false;
              }
              if (jobDetails?.ppo_ctc_min && jobDetails?.ppo_ctc_max) {
                if (jobDetails?.ppo_ctc_max < jobDetails?.ppo_ctc_min) {
                  alert.error("Please Enter Max CTC more than min CTC");
                  ctc_min_error = "Enter correct Min Ctc";
                  ctc_max_error = "Enter correct Max Ctc";

                  is_valid = false;
                }
              }
            }
          }
        }
        if (item.field_type.toLowerCase() === "courses") {
          if (jobDetails?.degree_spec_selected?.length === 0) {
            courses_error = "Please Select eligible courses";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "skills") {
          if (jobDetails?.skills?.length === 0) {
            skills_error = "Please Select Skills";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "graduation_grade") {
          if (!jobDetails.grad_min_score || jobDetails.grad_min_score === 0) {
            min_score_error = "Please enter minimum score";
            is_valid = false;
          }
          if (
            jobDetails?.grad_scoring === "Percentage" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 100)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
          }
          if (
            jobDetails?.grad_scoring === "CGPA" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 10)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
          }
          if (
            jobDetails?.grad_scoring === "GPA" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 4)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 4";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "school_grade") {
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "Percentage" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 100)
          ) {
            twelve_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
          }
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "CGPA" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 10)
          ) {
            twelve_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
          }
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "GPA" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 4)
          ) {
            twelve_error = "Please enter your minimum score between 0 to 4";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "school_grade") {
          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "Percentage" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 100))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
          }

          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "CGPA" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 10))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
          }
          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "GPA" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 4))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 4";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "batch") {
          if (jobDetails?.eligible_batches?.length === 0) {
            batch_error = "Please enter Eligible batches";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "selection") {
          if (jobDetails?.rounds?.length === 0) {
            round_error = "Please select Round";
            duration_error = "Please Enter duration";
            venue_error = "Plese select your venue";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "job-desc-docs") {
          if (jobDetails?.document_for_jd === null) {
            
            document_error = "Please select required documents";
            is_valid = false;
          }
        }

        if (item?.key?.toLowerCase() === "company_name") {
          if (!disableCompany) {
            if (!companyDetails?.company_name && companyNotExist) {
              company_error = "Please Enter the company!!";
              is_valid = false;
            }
            if (!companyDetails?.company_object && !companyNotExist) {
              company_error = "Please Enter the company!!";
              is_valid = false;
            }
          }
        }
        if (companyNotExist) {
          if (item?.key?.toLowerCase() === "industry") {
            if (!disableCompany) {
              if (!companyDetails?.industry_type_id) {
                industry_type_error = "Please Enter Industry Type";
                is_valid = false;
              }
              if (!companyDetails?.industry) {
                industry_error = "Please Enter Industry Type";
                is_valid = false;
              }
            }
          }
          if (item?.field_type === "spoc") {
            if (pocList?.length === 0 && ejdJobsList.length === 0  ) {
              alert.error("Please Enter atleast one POC Detail");
              is_valid = false;
            }
          }
        }
      });
    setJobDetailsError({
      ...jobDetailsError,
      job_title: job_title_error,
      job_role: job_role_error,
      job_segment: job_segment_error,
      application_end_date: end_date_error,
      application_start_date: start_date_error,
      campus_drive_date:campus_drive_date_error,
      job_description: job_desc_error,
      ctc_min: ctc_min_error,
      ctc_max: ctc_max_error,
      job_location: location_error,
      vacancy: vacancy_error,
      ppo_ctc_max: ppo_ctc_max_error,
      ppo_ctc_min: ppo_ctc_min_error,
      degree_spec_selected: courses_error,
      grad_min_score: min_score_error,
      eligible_batches: batch_error,
      rounds: round_error,
      round_duration: duration_error,
      venue: venue_error,
      skills: skills_error,
      senior_sec_min_score: twelve_error,
      sec_min_score: tenth_error,
      internship_duration: internship_duration_error,
      document_for_jd:document_error,
    });
    setCompanyDetailsError({
      ...companyDetailsError,
      company_name: company_error,
      industry_type: industry_type_error,
      industry: industry_error,
      // single_poc: {
      //   ...companyDetailsError?.single_poc,
      //   contact_person_name: name_error,
      //   email: email_error,
      //   phone: phone_error,
      //   designation: null,
      // },
    });

    return is_valid;
  };
  const selectedCourses = useSelector(
    (state) => state?.commonData?.selectedCourseData?.data
  );

  const handleSubmit = () => {
    if (ejdJobsList.length > 0 || validateData()) {

      var arr = [...ejdJobsList];
      // var obj = {
      //   college_id: mainEJDData?.college_id,
      //   college_job_data: jobDetails,
      //   company_id: companyDetails?.company_object
      //     ? companyDetails?.company_object?.id
      //     : null,
      //   application_start_date: new Date(jobDetails?.application_start_date)
      //     .toISOString()
      //     .slice(0, 10),
      //   application_end_date: new Date(jobDetails?.application_end_date)
      //     .toISOString()
      //     .slice(0, 10),
      // };
      // arr.push(obj);
      // arr.forEach((item) => {
      //   item.college_job_data["fields"] = selectedFields;
      // });
      // setEjdJobsList(arr);

      var objNew = {};
      if (companyNotExist) {
        var company_details = {
          company_name: ejdJobsList[0]?.companyDetails?.company_name,
          industry_id: ejdJobsList[0]?.companyDetails?.industry?.id,
          industry_name: ejdJobsList[0]?.companyDetails?.industry?.name,
          industry_type_id: ejdJobsList[0]?.companyDetails?.industry_type_id,
          industry_type_name:
            ejdJobsList[0]?.companyDetails?.industry_type_id === 1
              ? "Private"
              : ejdJobsList[0]?.companyDetails?.industry_type_id === 2
              ? "Government"
              : "Semi-Government",
          visiting_date: ejdJobsList[0]?.companyDetails?.visiting_date
            ? ejdJobsList[0]?.companyDetails?.visiting_date
            : null,
        };
        objNew = {
          company_details: {
            college_id: mainEJDData?.college_id,
            company_form_data: company_details,
            poc_list: ejdJobsList[0]?.companyDetails?.poc_list,
            invitation_source:"EJNF"
          },
          job_details: arr,
          ejd_form_id: mainEJDData?.ejd_form_id,
        };
      } else {
        objNew = {
          company_details: {
            college_id: mainEJDData?.college_id,
            poc_list: ejdJobsList[0]?.companyDetails?.poc_list,
            company_id: ejdJobsList[0]?.companyDetails?.company_object?.id,
            invitation_source:"EJNF"
          },
          job_details: arr,
          ejd_form_id: mainEJDData?.ejd_form_id,
        };
      }
      dispatch(
        EJDPostAPI(objNew, alert, history, location, setStep, setLoadingPost)
      );
    } else {
      // if (pocList?.length === 0) alert.error("Please Enter atleast one POC Detail");

      alert.error("Please Fill all the mandatory fields!");
    }
  };

  const addNewJob = (data) => {
    var arr = [...ejdJobsList];
    arr.push({
      college_id: mainEJDData?.college_id,
      college_job_data: { ...jobDetails, degree_spec_selected: data },
      company_id: companyDetails?.company_object
        ? companyDetails?.company_object?.id
        : null,
      application_start_date: new Date(jobDetails?.application_start_date)
        .toISOString()
        .slice(0, 10),
      application_end_date: new Date(jobDetails?.application_end_date)
        .toISOString()
        .slice(0, 10),
      ...(jobDetails?.campus_drive_date && {campus_drive_date: new Date(jobDetails?.campus_drive_date)
        .toISOString().slice(0, 10) }),    
      companyDetails: { ...companyDetails, poc_list: pocList },
      // spocDetails:{...pocList},
    });
    setEjdJobsList(arr);
    alert.success("Added");
    setDisableCompany(true);
    window.scroll({ top: 800, behavior: "smooth" });
  };

  const [currentItemButtonClicked, setCurrentItemButtonClicked] =
    useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  useEffect(() => {
    if (currentItemButtonClicked) {
      setJobDetails(currentItem);
      setCurrentItemButtonClicked(false);
    }
  }, [currentItemButtonClicked]);

  const industryList = useSelector((state) => state?.commonData?.industryData);

  useEffect(() => {
    dispatch(IndustryGetAPI(alert, history, location));
  }, []);

  const clearFields = () => {
    setJobDetails({
      ...initialState,
    });
    setDataSelectedNewFormat([]);
    setJobDesc("");
    setSalaryBreakup("");
    setJdPath('');                           

    var arr = [...selectedCourses];
    arr.map((item) => {
      item.degrees.forEach((item2) => {
        item2.select_all = false;
        item2.specialization.forEach((item3) => {
          item3.selected = false;
        });
      });
    });
    setCoursesArray(arr);

    var temp = {};

    arr.forEach((item) => {
      item?.degrees.forEach((item2) => {
        temp[item2.degree_id] = [];
      });
    });

    setSpecializationInDgegreeId(temp);
    var push_arr = [...pushArray];
    push_arr.push(1);
    setPushArray(push_arr);
    setDataSelected([]);
    setCompanyDetails({ ...initialCompanyDetails });
    setPocList([]);
    setApplicationDates({ ...initialDates });
    setCurrentJobLocation(null);
    setCurrentBatch(null);
  };

  const Data = [
    {
      icon: <LanguageIcon color="secondary" />,
      info: checkForUrl(collegeDetails?.website),
      website: true,
      show: collegeDetails?.website ? true : false,
    },
    {
      icon: <ApartmentIcon color="secondary" />,
      info: collegeDetails?.city,
      show: collegeDetails?.city ? true : false,
    },
    {
      icon: <GolfCourseIcon color="secondary" />,
      info: collegeDetails?.college_type,
      show: collegeDetails?.college_type ? true : false,
    },
    {
      icon: <PeopleAltOutlinedIcon color="secondary" />,
      info: collegeDetails?.size + " Students",
      show: collegeDetails?.size ? true : false,
    },
    {
      icon: <FlagOutlinedIcon color="secondary" />,
      info: collegeDetails?.founded_year?.slice(0, 4),
      show: collegeDetails?.founded_year ? true : false,
    },
    {
      icon: <LocationCityIcon color="secondary" />,
      info: collegeDetails?.affiliated,
      show: collegeDetails?.affiliated ? true : false,
    },
  ];

  const someTruthy = Object.values(Data).some((val) => val.show === true);

  useEffect(() => {
    setJobDetails({
      ...jobDetails,
      grad_scoring: isGradeSystem ? "CGPA" : "Percentage",
    });
  }, [isGradeSystem]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <StudentFormNavbar /> */}
      {loading ? (
        <CustomCircularProgress />
      ) : step === 0 ? (
        <>
          <div
            style={{
              backgroundColor: "#f8f9fa",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${
                  collegeDetails?.banner ? collegeDetails?.banner : CollegeImage
                })`,
                backgroundRepeat: "no-repeat",
                height: "270px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />

            <Container maxWidth="lg">
              <Grid
                container
                spacing={3}
                style={{ marginTop: "-50px", padding: "20px" }}
              >
                <Grid item>
                  <div className={classes.imagedata}>
                    <img
                      className={classes.imgdata}
                      alt="complex"
                      src={
                        collegeDetails?.logo
                          ? collegeDetails?.logo
                          : defaultCompany
                      }
                    />
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    spacing={2}
                    style={{ display: "contents" }}
                  >
                    <div className={classes.title}>
                      <Typography
                        variant="h3"
                        style={{ fontWeight: 600, paddingBottom: "10px" }}
                      >
                        {collegeDetails?.college_name}
                      </Typography>
                    </div>
                    <div className={classes.social}>
                      {collegeDetails?.social_links?.length > 1 && collegeDetails?.social_links[1].value && (
                        <a
                          href={collegeDetails?.social_links[1].value}
                          target="blank"
                        >
                          <img
                            src={Facebook}
                            height="40px"
                            className={classes.icons}
                            width="40px"
                          />
                        </a>
                      )}
                      {collegeDetails?.social_links?.length > 0 && collegeDetails?.social_links[0].value && (
                        <a
                          href={collegeDetails?.social_links[0].value}
                          target="blank"
                        >
                          <img
                            src={Linkedin}
                            className={classes.icons}
                            height="40px"
                            width="40px"
                          />
                        </a>
                      )}
                      {collegeDetails?.social_links?.length > 2 && collegeDetails?.social_links[2].value && (
                        <a
                          href={collegeDetails?.social_links[2].value}
                          target="blank"
                        >
                          <img
                            src={Insta}
                            className={classes.icons}
                            height="40px"
                            width="40px"
                          />
                        </a>
                      )}
                    </div>
                  </Grid>
                </Grid>

                {/* <Grid item xs={2}>
                  <Paper className={classes.paper}>
                    <img
                      className={classes.imgdata}
                      alt="complex"
                      src={
                        collegeDetails?.logo
                          ? collegeDetails?.logo
                          : defaultCompany
                      }
                    />
                  </Paper>
                </Grid>
                <Grid item xs={10} className={classes.textgrid}>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>
                    {collegeDetails?.college_name}
                  </Typography>
                  <br />
                </Grid> */}
                <Grid item xs={12}>
                  {someTruthy && <Typography variant="h6">Overview</Typography>}{" "}
                </Grid>

                {Data.map(
                  (item) =>
                    item.show && (
                      <Grid item xs={12} sm={4}>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item>{item.icon}</Grid>
                          <Grid
                            item
                            xs
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              variant="body2"
                              color="secondary"
                              noWrap
                              className={classes.text}
                            >
                              {item.website ? (
                                <a href={item.info} target="_blank">
                                  {item.info}
                                </a>
                              ) : (
                                item.info
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                )}

                {collegeDetails?.about_us && (
                  <Grid item xs={12}>
                    <Typography variant="h6">About Us</Typography>

                    <Typography variant="body2">
                      {ReactHtmlParser(collegeDetails?.about_us)}
                    </Typography>
                  </Grid>
                )}

                {mainEJDData?.ejd_message && (
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {mainEJDData?.ejd_title}
                    </Typography>
                    <br />
                    <Typography variant="body2">
                      {ReactHtmlParser(mainEJDData?.ejd_message)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Container>
          </div>
          <br />
          <Container maxWidth="lg">
            {/* <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <div style={{ padding: "20px" }}>
                <Typography variant="h6">MBA Placement Drive</Typography>
                <Typography
                  variant="body2"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Symbiosis International University (Private University), is a
                  multi-campus private deemed university located in the city of
                  Pune, India. The university has more than 30 academic
                  institutions spread over various campuses in Pune, Bangalore,
                  Hyderabad, Indore, Nagpur, Nashik, Noida. In 2019, the
                  university opened a campus in Nagpur. Symbiosis International
                  University (Private University), is a multi-campus private
                  deemed university located in the city of Pune, India. The
                  university has more than 30 academic institutions spread over
                  various campuses in Pune, Bangalore, Hyderabad, Indore,
                  Nagpur, Nashik, Noida. In 2019, the university opened a campus
                  in Nagpur.
                </Typography>
              </div>
            </Grid> */}
            <Grid container spacing={3} style={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={8}>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <Typography variant="h6">
                    Fill e-JNF form for Campus Placement
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Company Basic Info
                  </Typography>
                  {groupFieldList &&
                    Object.keys(groupFieldList)?.length > 0 &&
                    Object.keys(groupFieldList).map(
                      (item) =>
                        item === "Company Basic Info" && (
                          <BasicInfo
                            data={groupFieldList[item]}
                            companyDetails={companyDetails}
                            setCompanyDetails={setCompanyDetails}
                            companyDetailsError={companyDetailsError}
                            setCompanyDetailsError={setCompanyDetailsError}
                            industryList={industryList}
                            pocList={pocList}
                            setPocList={setPocList}
                            companyNotExist={companyNotExist}
                            setCompanyNotExist={setCompanyNotExist}
                            companyList={companyList}
                            jobDesc={jobDesc}
                            disableCompany={disableCompany}
                          />
                        )
                    )}
                </div>
                <div style={{ padding: "20px" }}>
                  {/* {ejdJobsList?.length > 0 && (
                    <Typography variant="h6">
                      Other Job Profiles in this e-JNF
                    </Typography>
                  )}
                  <br /> */}
                  {/* <Grid
                    container
                    spacing={3}
                    // style={{ marginTop: "-50px", padding: "20px" }}
                  >
                    {ejdJobsList?.length > 0 &&
                      ejdJobsList?.map((item, index) => (
                        <Grid item xs={6}>
                          <JobProfile
                            item={item}
                            index={index}
                            setCurrentItem={setCurrentItem}
                            setJobDetails={setJobDetails}
                            setCurrentItemButtonClicked={
                              setCurrentItemButtonClicked
                            }
                            setCurrentItemIndex={setCurrentItemIndex}
                            selectedCourses={selectedCourses}
                            setDataSelectedNewFormat={setDataSelectedNewFormat}
                            setCoursesArray={setCoursesArray}
                            setPushArray={setPushArray}
                            pushArray={pushArray}
                            setDataSelected={setDataSelected}
                            coursesArray={coursesArray}
                            setSpecializationInDgegreeId={
                              setSpecializationInDgegreeId
                            }
                            ejdJobsList={ejdJobsList}
                            setEjdJobsList={setEjdJobsList}
                          />
                        </Grid>
                      ))}
                    {/* <br /> */}
                  {/* </Grid> */}
                  <Typography variant="h6">Job Info</Typography>

                  {groupFieldList &&
                    Object.keys(groupFieldList)?.length > 0 &&
                    Object.keys(groupFieldList).map(
                      (item) =>
                        item !== "Company Basic Info" && (
                          <JobProfileInfo
                            data={groupFieldList[item]}
                            jobDetails={jobDetails}
                            setJobDetails={setJobDetails}
                            jobDetailsError={jobDetailsError}
                            setJobDetailsError={setJobDetailsError}
                            loadingCourses={loadingCourses}
                            jobDesc={jobDesc}
                            setJobDesc={setJobDesc}
                            salaryBreakup={salaryBreakup}
                            setSalaryBreakup={setSalaryBreakup}
                            dataSelectedNewFormat={dataSelectedNewFormat}
                            setDataSelectedNewFormat={setDataSelectedNewFormat}
                            qualifications={qualifications}
                            setQualifications={setQualifications}
                            coursesArray={coursesArray}
                            setCoursesArray={setCoursesArray}
                            specializationInDegreeId={specializationInDegreeId}
                            setSpecializationInDgegreeId={
                              setSpecializationInDgegreeId
                            }
                            pushArray={pushArray}
                            setPushArray={setPushArray}
                            dataSelected={dataSelected}
                            setDataSelected={setDataSelected}
                            setApplicationDates={setApplicationDates}
                            applicationDates={applicationDates}
                            currentJobLocation={currentJobLocation}
                            setCurrentJobLocation={setCurrentJobLocation}
                            currentbatch={currentbatch}
                            setCurrentBatch={setCurrentBatch}
                            preSelectedJD={preSelectedJD}
                            setPreSelectedJD={setPreSelectedJD}
                            setJdPath={setJdPath}
                            jdPath={jdPath}
                          />
                        )
                    )}

                  {/* <JobProfile /> */}
                </div>
                {mainEJDData?.facilities && (
                  <div style={{ padding: "20px" }}>
                    <Typography variant="h6">
                      Facilities Available at Campus
                    </Typography>
                    <Typography variant="body2">
                      {ReactHtmlParser(mainEJDData?.facilities)}
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid item sm={4} xs={12}>
                <div
                  style={{
                    backgroundColor: "#f8f9fa",
                    padding: "20px",
                    borderRadius: "8px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ marginBottom: "20px", textAlign: "center" }}
                  >
                    {ejdJobsList.length > 0 ? ejdJobsList.length : "No"} Jobs
                    Added in e-JNF
                  </Typography>
                  {ejdJobsList?.length > 0 &&
                    ejdJobsList?.map((item, index) => {
                      if (item?.college_job_data?.job_type_id === 7) {
                        return (
                          <FullTimeJob
                            item={item}
                            index={index}
                            setCurrentItem={setCurrentItem}
                            setJobDetails={setJobDetails}
                            setCurrentItemButtonClicked={
                              setCurrentItemButtonClicked
                            }
                            setCurrentItemIndex={setCurrentItemIndex}
                            selectedCourses={selectedCourses}
                            setDataSelectedNewFormat={setDataSelectedNewFormat}
                            setCoursesArray={setCoursesArray}
                            setPushArray={setPushArray}
                            pushArray={pushArray}
                            setDataSelected={setDataSelected}
                            coursesArray={coursesArray}
                            setSpecializationInDgegreeId={
                              setSpecializationInDgegreeId
                            }
                            ejdJobsList={ejdJobsList}
                            setEjdJobsList={setEjdJobsList}
                            currentItemIndex={currentItemIndex}
                            companyDetails={companyDetails}
                            currentItem={currentItem}
                            companyList={companyList}
                            setCompanyDetails={setCompanyDetails}
                            setPocList={setPocList}
                            disableCompany={disableCompany}
                            setApplicationDates={setApplicationDates}
                            setCurrentJobLocation={setCurrentJobLocation}
                            setCurrentBatch={setCurrentBatch}
                            preSelectedJD={preSelectedJD}
                            setPreSelectedJD={setPreSelectedJD}
                            setJdPath={setJdPath}
                          />
                        );
                      }

                      if (item?.college_job_data?.job_type_id === 1) {
                        return (
                          <InternshipJob
                            item={item}
                            index={index}
                            setCurrentItem={setCurrentItem}
                            setJobDetails={setJobDetails}
                            setCurrentItemButtonClicked={
                              setCurrentItemButtonClicked
                            }
                            setCurrentItemIndex={setCurrentItemIndex}
                            selectedCourses={selectedCourses}
                            setDataSelectedNewFormat={setDataSelectedNewFormat}
                            setCoursesArray={setCoursesArray}
                            setPushArray={setPushArray}
                            pushArray={pushArray}
                            setDataSelected={setDataSelected}
                            coursesArray={coursesArray}
                            setSpecializationInDgegreeId={
                              setSpecializationInDgegreeId
                            }
                            ejdJobsList={ejdJobsList}
                            setEjdJobsList={setEjdJobsList}
                            currentItemIndex={currentItemIndex}
                            companyList={companyList}
                            setCompanyDetails={setCompanyDetails}
                            setPocList={setPocList}
                            setApplicationDates={setApplicationDates}
                            setCurrentJobLocation={setCurrentJobLocation}
                            setCurrentBatch={setCurrentBatch}
                            preSelectedJD={preSelectedJD}
                            setPreSelectedJD={setPreSelectedJD}
                            setJdPath={setJdPath}
                          />
                        );
                      }
                    })}
                  {/* remove this once the components for this is finalised. */}
                  {/* <FullTimeJob /> */}
                  {currentItemIndex === null && (
                    <Grid
                      container
                      spacing={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "10px",
                      }}
                    >
                      {/* <Grid item xs={6} style={{ display: "grid" }}>
                        <AddAnotherJob
                        setCurrentItem={setCurrentItem}
                        setJobDetails={setJobDetails}
                        setCurrentItemButtonClicked={
                          setCurrentItemButtonClicked
                        }
                        setCurrentItemIndex={setCurrentItemIndex}
                        selectedCourses={selectedCourses}
                        setDataSelectedNewFormat={setDataSelectedNewFormat}
                        setCoursesArray={setCoursesArray}
                        setPushArray={setPushArray}
                        pushArray={pushArray}
                        setDataSelected={setDataSelected}
                        coursesArray={coursesArray}
                        setSpecializationInDgegreeId={
                          setSpecializationInDgegreeId
                        }
                        ejdJobsList={ejdJobsList}
                        setEjdJobsList={setEjdJobsList} 
                        currentItemIndex={currentItemIndex}
                        
                        validateData={validateData}
                        // dataSelectedNewFormat={
                        //   jobDetails?.degree_spec_selected
                        // }
                        clearFields={clearFields}
                                           
                        cloneJobDetails={cloneJobDetails}
                                              
                        />
                      </Grid> */}
                      <Grid
                        xs={6}
                        sm={8}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {ejdJobsList.length > 0 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "200px" }}
                              className={classes.btn}
                              onClick={() => {
                                if (!loadingPost) handleSubmit();
                              }}
                            >
                              {loadingPost ? (
                                <>
                                  <CustomButtonCircularProgress />
                                </>
                              ) : (
                                "Send e-JNF "
                              )}
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Grid>
            </Grid>
            {/* {mainEJDData?.facilities && (
              <div style={{ padding: "20px" }}>
                <Typography variant="h6">
                  Facilities Available at Campus
                </Typography>
                <Typography variant="body2">
                  {ReactHtmlParser(mainEJDData?.facilities)}
                </Typography>
              </div>
            )} */}
          </Container>
          <div
            style={{
              backgroundColor: "#f8f9fa",
              textAlign: "center",
              padding: "30px",
            }}
          >
            {" "}
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  {currentItemIndex !== null && currentItemIndex >= 0 && (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={6}>
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{
                              boxShadow: "none",
                              borderRadius: "8px",
                              padding: "6px",
                              height: "48px",
                              width: "10rem",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              fontWeight: "normal",
                            }}
                            onClick={() => {
                              clearFields();
                              setCurrentItemIndex(null);
                              setCurrentItem(null);
                            }}
                          >
                            Clear Form
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{
                              boxShadow: "none",
                              marginLeft: "10px",
                              borderRadius: "8px",
                              padding: "6px",
                              height: "48px",
                              width: "10rem",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              fontWeight: "normal",
                            }}
                            onClick={() => {
                              var arr = [...ejdJobsList];
                              arr[currentItemIndex].college_job_data =
                                jobDetails;
                              if (validateData()) {
                                setEjdJobsList(arr);
                                setCurrentItemIndex(null);
                                setCurrentItem(null);
                                clearFields();
                              }
                            }}
                          >
                            Save Job
                          </Button>
                        </Grid>
                      </Grid>
                    </>

                    // <Button
                    //   size="small"
                    //   color="primary"
                    //   className={classes.addbtn}
                    //   variant="contained"
                    //   // style={{
                    //   //   boxShadow: "none",
                    //   //   marginLeft: "10px",
                    //   //   borderRadius: "30px",
                    //   //   padding: "6px",
                    //   //   marginRight: 10,
                    //   // }}
                    //   style={{
                    //     borderRadius: 30,
                    //     marginTop: "10px",
                    //     boxShadow: "none",
                    //   }}
                    //   onClick={() => {
                    //     var arr = [...ejdJobsList];
                    //     arr[currentItemIndex].college_job_data = jobDetails;
                    //     if (validateData()) {
                    //       setEjdJobsList(arr);
                    //       setCurrentItemIndex(null);
                    //       setCurrentItem(null);
                    //       clearFields();
                    //     }
                    //   }}
                    // >
                    //   Save Job
                    // </Button>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  {currentItemIndex === null && (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={6}>
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{
                              boxShadow: "none",
                              borderRadius: "8px",
                              padding: "6px",
                              height: "48px",
                              width: "100%",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              fontWeight: "normal",
                            }}
                            onClick={() => {
                              clearFields();
                              setCurrentItemIndex(null);
                              setCurrentItem(null);
                            }}
                          >
                            Clear Form
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{
                              boxShadow: "none",
                              marginLeft: "10px",
                              borderRadius: "8px",
                              padding: "6px",
                              height: "48px",
                              width: "100%",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              fontWeight: "normal",
                            }}
                            onClick={() => {
                              var arr = [...ejdJobsList];
                              if (currentItemIndex !== null)
                                arr[currentItemIndex].college_job_data =
                                  jobDetails;
                              if (validateData()) {
                                addNewJob(jobDetails?.degree_spec_selected);

                                clearFields();
                              }
                            }}
                          >
                            Create Job
                          </Button>
                        </Grid>

                        {/* <EjdDialog
                            addNewJob={addNewJob}
                            validateData={validateData}
                            dataSelectedNewFormat={
                              jobDetails?.degree_spec_selected
                            }
                            clearFields={clearFields}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            style={{
                              boxShadow: "none",
                              //marginLeft: "10px",
                              borderRadius: "30px",
                              padding: "6px",
                              height: "48px",
                              width: "100%",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              fontWeight: "normal",
                            }}
                            onClick={() => {
                              if (!loadingPost) handleSubmit();
                            }}
                          >
                            {loadingPost ? (
                              <>
                                <CustomButtonCircularProgress />
                              </>
                            ) : (
                              " Submit e-JNF to the Institute"
                            )}
                          </Button> 
                        </Grid>*/}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Container>
          </div>
          <FormFooter />{" "}
        </>
      ) : (
        <>
          <ThankYou
            text={"You have successfully Posted your E-JNF!!"}
            link={NEXT_APP_ENDPOINT}
          />
        </>
      )}
    </>
  );
}

export default EjdCompany;
