import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Chip, TextField } from "@material-ui/core";
import moment from "moment";
import { ReactComponent as NoData } from "../../../../assets/svg/No-data-rafiki.svg";
import { themeSelectorFn } from "../../../../api/Domain/selector";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function Feedback({
  data,
  moveNextArray,
  moveToNext,
  setMoveToNext,
  MoveToNextStep,
  feedbackError,
  setFeedbackError,
  showTrack,
  filters,
  userRolesPermission,
  typedFeedback,
  setTypedFeedback,
}) {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);

  // useEffect(() => {
  //   setMoveToNext({ ...moveToNext, feedback: { name: "other" }, feedback_typed: feedbackTyped });
  // }, [feedbackTyped]);
  return (
    <div className={classes.paper}>
      {showTrack &&
        filters?.current_status !== "618f97264ded0fcb55962cce" &&
        filters?.current_status !== "618f97324ded0fcb55962cd5" && (
          <Typography variant="h6">Feedback</Typography>
        )}

      <br />
      {showTrack &&
        userRolesPermission?.some(
          (item) => item.access_control_key === "action_job_track"
        ) &&
        filters?.current_status !== "618f97264ded0fcb55962cce" &&
        filters?.current_status !== "618f97324ded0fcb55962cd5" && (
          <div className={classes.root}>
            <TextField
              id="outlined-multiline-static"
              label="Add Feedback"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              type="text"
              multiline
              style={{ width: "100%" }}
              rows={6}
              variant="outlined"
              value={typedFeedback}
              onChange={(e) => {
                setTypedFeedback(e.target.value);
                // setMoveToNext({ ...moveToNext, feedback_typed: e.target.value });
                setFeedbackError(null);
              }}
              error={feedbackError ? true : false}
              helperText={feedbackError}
            />
            {/* <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ borderRadius: "30px", boxShadow: "none" }}
        >
          Add Feedback
        </Button> */}
          </div>
        )}
      <br />
      <Typography variant="h6">Previous Feedbacks</Typography>
      {data?.feedback_list?.length > 0 ? (
        data?.feedback_list?.map((item) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div>
                <Typography variant="body2">
                  {item?.user_name === "undefined undefined"
                    ? "Anonymous Admin"
                    : item?.user_name}
                </Typography>
                {item?.posted_on && (
                  <Typography variant="body2">
                    {moment(item?.posted_on).format("DD MMMM' YYYY")}
                  </Typography>
                )}
              </div>
              <div>
                <Chip
                  color="primary"
                  variant="outlined"
                  size="small"
                  label={item.round_name}
                  borderRadius="8px"
                />
              </div>
            </div>
            <Typography variant="overline">{item?.feedback_name}</Typography>
          </>
        ))
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <NoData
            height="200px"
            width="200px"
            alt="404 Error"
            fill={theme_change?.main ? theme_change?.main : "#007BFF"}
          />
        </div>
      )}
    </div>
  );
}
