import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userLoggedInSelectorFn } from "../../api/Domain/selector";
import Forgot from "../../components/Form/Forgot";

function ForgotPassword() {
  const isLoggedIn = useSelector(userLoggedInSelectorFn);
  const history = useHistory();
  useEffect(() => {
      if (isLoggedIn){
          history.push("/overview");
      }
  }, [])

  
  return (
    <>
      {/* <Navbar /> */}
      <Forgot />
    </>
  );
}

export default ForgotPassword;
