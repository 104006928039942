import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { themeSelectorFn } from "../../api/Domain/selector";
import CollegeImage from "../../assets/img/company.jpg";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default function LeftSideComponent() {
  const classes = useStyles();
  const details = useSelector(themeSelectorFn);
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  // useEffect(() => {
  //   dispatch(userDetailsApi(history, location, alert, true));
  // }, []);

  return (
        <Grid item xs={false} sm={4} md={6} className={classes.image} style={{ backgroundImage: `url(${details?.side_image ? details?.side_image : CollegeImage})` }} />
        // <Grid item xs={false} sm={4} md={6}>
        //   <img src={details?.side_image ? details?.side_image : CollegeImage} />
        // </Grid>
        // <div  className={classes.image} style={{ backgroundImage: `url(${details?.side_image ? details?.side_image : CollegeImage})`, width: "50%", height: "100vh" }}>
        // </div>
        );
}
