import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, Tooltip } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Cycle from "../../DialogSection/Cycle";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import EditPlacement from "../../DialogSection/AddCycle/EditPlacement";
import Event from "@material-ui/icons/Event";
import moment from "moment";
import MuiCustomTheme from "../../NewMUITheme/MuiCustomTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    flexDirection: "column",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "10px 20px",
    //paddingRight: "20px",
    fontSize: "14px",
    fontWeight: "normal",
    //paddingLeft: "20px",
    display: "flex",
    height: 40,
    marginLeft: "7px",
    "&:disabled": {
      background: "#b0b7c3!important",
    },
    // marginTop: "7px",
  },
  // disabledButton: {
  //   backgroundColor: theme.palette.primary || "green",
  // },
  newBtn: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "10px 20px",
    //paddingRight: "20px",
    fontSize: "14px",
    fontWeight: "normal",
    //paddingLeft: "20px",
    marginLeft: "7px",

    display: "flex",
    height: 40,
    // marginTop: "7px",

    backgroundColor: theme.palette.primary.secondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  btnInRow: {
    display: "flex",
  },
}));

export default function PlacementCycle({
  item,
  collegeId,
  userRolesPermission,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex" }}>
            <Tooltip title={item?.name} placement="right">
              <Typography
                variant="h6"
                noWrap
                style={{ marginLeft: "7px", maxWidth: "95" }}
              >
                {item?.name}
              </Typography>
            </Tooltip>
            {!item?.is_ended &&
              userRolesPermission?.some(
                (item) => item.access_control_key === "manage_placement_cycle"
              ) && <EditPlacement item={item} collegeId={collegeId} />}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "7px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Event style={{ color: "#7a7e83", fontSize: "15px" }} />
            <Typography
              variant="caption"
              style={{
                color: "#7a7e83",
                marginLeft: "7px",
              }}
            >
              {moment(item?.start_date).format("DD MMMM YYYY")} -{" "}
              {item?.end_date
                ? moment(item?.end_date).format("DD MMMM YYYY")
                : "Present"}
            </Typography>
          </div>
          {item?.company_count && (
            <Link to="/manage-companies" style={{ textDecoration: "none" }}>
              <Button
                color="primary"
                style={{ display: "flex" }}
                endIcon={<ArrowForwardIosIcon style={{ fontSize: "14px" }} />}
              >
                Available Companies: 34
              </Button>
            </Link>
          )}
          <Link
            to={{
              pathname: `/manage-jobs`,
              search: `?placement_cycle=${item.id}`,
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              style={{ display: "flex" }}
              endIcon={<ArrowForwardIosIcon style={{ fontSize: "14px" }} />}
            >
              Open Jobs: {item.job_count}
            </Button>
          </Link>

          <Link
            to={{
              pathname: `/manage-students`,
              search: `?placement_cycle=${item.id}`,
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              style={{ display: "flex" }}
              endIcon={<ArrowForwardIosIcon style={{ fontSize: "14px" }} />}
            >
              Registered Student: {item.student_count}
            </Button>
          </Link>

          <br />
          <div className={classes.btnInRow}>
            {item?.is_ended ? (
              <></>
            ) : (
              <>
                {" "}
                <Link
                  to={{
                    pathname: `/post-job`,
                    search: `?placement_cycle_id=${item.id}`,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.button}
                  >
                    Post Job
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/add-students`,
                    search: `?placement_cycle_id=${item.id}&placement_cycle_name=${item.name}`,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.button}
                  >
                    Add Students
                  </Button>
                </Link>
              </>
            )}
            <Button
              color="secondary"
              onClick={handleClickOpen}
              variant="contained"
              size="small"
              className={classes.newBtn}
              disabled={
                item?.is_ended ||
                !userRolesPermission?.some(
                  (item) => item.access_control_key === "manage_placement_cycle"
                )
              }
            >
              {item?.is_ended ? "Cycle Ended" : "End Cycle"}
            </Button>

            <Cycle
              handleClickOpen={handleClickOpen}
              open={open}
              handleClose={handleClose}
              item={item}
              collegeId={collegeId}
            />
          </div>
        </Paper>
      </div>
    </>
  );
}
