import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import React, { useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import Carousel, { consts } from "react-elastic-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CoursesGetAPI, PlacementListEndedAPI } from "../../api/Common/action";
import { isGradeSystemFn } from "../../api/Domain/selector";
import {
  JobSelectedFieldsAPI,
  JobSelectedLocalStateFieldsAPI,
} from "../../api/JobManagementForm/action";
import {
  AllCompanyAPI,
  AllJobRolesAPI,
  AllJobRoundsAPI,
  AllJobSegmentAPI,
  AllLocationsAPI,
  getJobsAPI,
  getSinglePostedJobAPI,
  JobPatchAPI,
  JobPostAPI,
  SKillsAPI,
} from "../../api/JobPost/action";
import {
  collegeIdFn,
  collegeUserDetailSelectorFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import JobTypeCards from "../../components/Cards/JobTypeCards";
import PreviousJob from "../../components/Cards/PreviousJob";
import CustomCircularProgress from "../../components/CircularProgressBar";
import FullScreenPreviewDialog from "../../components/DialogSection/PostJobPreview";
import SuccessfullyPostJob from "../../components/DialogSection/SuccessfullyPostJob";
import PostJobForm from "../../components/Form/PostJobForm";
import Item from "./Item";

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px ",
    display: "flex",
    marginRight: "20px",
  },
  sliderbutton: {
    border: "none",
    backgroundColor: "transparent",
    padding: "0",
  },
  arrow: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "40px",
    cursor: "pointer",
    height: "40px",
  },
  post: {
    display: "flex",
    justifyContent: "center",
  },
  bottombtn: {
    display: "flex",
    marginLeft: "120px",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  circularProgressTheme: {
    color: theme.palette.primary.main,
  },
}));

function PostJob({ selectedJobData, isRecommended = false }) {
  const classes = useStyles();
  const params = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [returnJobId, setReturnJobId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingJobSegment, setLoadingJobSegment] = useState(false);
  const [loadingJobRoles, setLoadingJobRoles] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobAction, setJobAction] = useState("");
  const [jobId, setJobId] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [placementCycle, setPlacementCycle] = useState([]);
  const [loadingjobData, setLoadingjobData] = useState(false);

  const [loadingCourses, setLoadingCourses] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const [coursesArray, setCoursesArray] = useState([]);
  const [step, setStep] = useState(params?.job_id?.split("-")[1] ? 1 : 0);
  const isGradeSystem = useSelector(isGradeSystemFn);
  const [editReason, setEditReason] = useState(null);
  const [editReasonError, setEditReasonError] = useState(null);
  const isInbound = useRef(false);
  const userDetails = useSelector(collegeUserDetailSelectorFn);

  useEffect(() => {
    if (location.search) {
      let params = new URLSearchParams(location.search);
      isInbound.current = params.get("inbound_job");
    }
  }, [location]);

  const [jobDetails, setJobDetails] = useState({
    company_id: null,
    company_name: null,
    company: null,
    job_title: null,
    job_type_id: 7,
    job_type_name: "Full Time",
    job_role_id: null,
    job_role_name: null,
    job_role: null,
    job_segment_id: null,
    job_segement_name: null,
    job_segment: null,
    application_start_date: null,
    application_end_date: null,
    joining_date: null,
    campus_drive_date: null,
    job_description: null,
    ctc_min: null,
    ctc_max: null,
    bonus: false,
    salary_type: "NOT DISCLOSED",
    salary_breakup: null,
    wfh: false,
    pan_india: false,
    job_location: [],
    vacancy: null,
    service_bond: false,
    conditions: null,
    ppo: false,
    ppo_ctc_min: null,
    ppo_salary_type: "NOT DISCLOSED",
    ppo_ctc_max: null,
    salary_breakup_ppo: null,
    degree_spec_selected: [],
    grad_scoring: isGradeSystem ? "CGPA" : "Percentage",
    grad_min_score: null,
    backlog: false,
    sec_scoring: "Percentage",
    sec_min_score: null,
    senior_sec_scoring: "Percentage",
    senior_sec_min_score: null,
    eligible_batches: [],
    rounds: [],
    documents: [],
    skills: [],
  });
  //  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    if (params?.job_id) {
      setJobAction(params?.job_id?.split("-")[0]);
      setJobId(params?.job_id?.split("-")[1]);

      setStep(1);
    }
  }, [params]);

  useState(() => {
    if (jobId) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [jobId]);

  useEffect(() => {
    if (collegeId) {
      dispatch(SKillsAPI());

      dispatch(
        AllCompanyAPI(setLoadingCompanies, alert, history, location, collegeId)
      );
      dispatch(
        AllJobSegmentAPI(setLoadingJobSegment, alert, history, location)
      );
      dispatch(AllJobRolesAPI(setLoadingJobRoles, alert, history, location));
      dispatch(AllLocationsAPI(setLoadingLocations, alert, history, location));
      dispatch(AllJobRoundsAPI(alert, history, location));

      const url = `api/manage_job/view/?college_id=${collegeId}&job_status=OPEN`;
      dispatch(getJobsAPI(setLoadingCards, alert, history, location, url));
      dispatch(
        CoursesGetAPI(setLoadingCourses, alert, history, location, collegeId)
      );
    }
  }, [collegeId]);

  const selectedCourses = useSelector(
    (state) => state?.commonData?.selectedCourseData?.data
  );

  const [PlacementFlag, resetPlacementFlag] = useState(false);

  useEffect(() => {
    if (PlacementFlag) {
      setPlacementCycle([]);
    }
  }, [PlacementFlag]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openPreview, setOpenPreview] = React.useState(false);

  const handleClickOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const checkForEJD = () => {
    if (jobAction === "ejd") return `&ejd_response=true`;
    else return ``;
  };

  const [fullTimeJobs, setFullTimeJobs] = useState([]);
  const [internships, setInternships] = useState([]);
  const [jobTypeSelected, setJobTypeSelected] = useState(null);

  useEffect(() => {
    if (!jobId) {
      if (collegeId) {
        dispatch(
          JobSelectedFieldsAPI(setLoading, alert, history, location, collegeId)
        );
        dispatch(
          JobSelectedLocalStateFieldsAPI(
            alert,
            history,
            location,
            collegeId,
            0,
            setFullTimeJobs,
            setInternships
          )
        );
        dispatch(
          JobSelectedLocalStateFieldsAPI(
            alert,
            history,
            location,
            collegeId,
            1,
            setFullTimeJobs,
            setInternships
          )
        );
      }
    } else {
      // stops un-necessary api call for form updation in recommended jobs
      if (jobAction === "recommended") {
        setJobDetails((prevData) => ({
          ...prevData,
          rounds: selectedData?.rounds,
        }));
        return;
        //  setLoadingjobData(false);
      } else {
        var url = `api/manage_job/view/job/?job_id=${jobId}${checkForEJD()}`;
        dispatch(
          getSinglePostedJobAPI(
            setLoadingjobData,
            alert,
            history,
            location,
            jobId,
            url
          )
        );
      }
    }
  }, [jobId, collegeId]);

  useEffect(() => {
    if (!jobId) {
      if (collegeId) {
        dispatch(
          JobSelectedFieldsAPI(
            setLoading,
            alert,
            history,
            location,
            collegeId,
            jobTypeSelected
          )
        );
      }
    } else {
      var url = `api/manage_job/view/job/?job_id=${jobId}${checkForEJD()}`;
      dispatch(
        getSinglePostedJobAPI(setLoading, alert, history, location, jobId, url)
      );
    }
  }, [jobTypeSelected]);

  const handleFunction = (key) => {
    if (key === "full-time") {
      if (fullTimeJobs?.length > 0) {
        setStep(1);
        setJobTypeSelected("full-time-jobs");
        setJobDetails({
          ...jobDetails,
          job_type_id: 7,
          job_type_name: "Full Time",
        });
      } else {
        setStep(0);
        history.push("/job-posting-form/full-time-job");
      }
    } else {
      if (internships?.length > 0) {
        setStep(1);
        setJobTypeSelected("internship");
        setJobDetails({
          ...jobDetails,
          job_type_id: 1,
          job_type_name: "Internship",
        });
      } else {
        setStep(0);
        history.push("/job-posting-form/internship");
      }
    }
  };
  const selectedFields = useSelector((state) =>
    jobId & !isRecommended
      ? state?.SingleJobData?.details?.college_job_data?.fields
      : state?.JobFields?.details?.job_form_fields
  );

  const getDataforJob = (state) => {
    if (jobAction === "recommended") {
      return state?.SingleJobData?.details;
    } else {
      return state?.SingleJobData?.details?.college_job_data;
    }
  };

  const selectedData = useSelector((state) =>
    // jobId ? state?.SingleJobData?.details : []
    jobId ? getDataforJob(state) : []
  );

  // for pre-filling recommended job data in the table otherwise jobData from API call.

  useEffect(() => {
    if (jobAction === "recommended" || selectedJobData) {
      const obj = { ...jobDetails, ...selectedJobData };
      setJobDetails(obj);
    }
  }, [selectedData, jobAction, selectedJobData, jobId]);

  const jobStatus = useSelector((state) =>
    jobId ? state?.SingleJobData?.details : []
  );
  const [qualifications, setQualifications] = useState({});
  const [dataSelected, setDataSelected] = useState([]);
  const [specializationInDegreeId, setSpecializationInDgegreeId] = useState([]);
  const [pushArray, setPushArray] = useState([]);
  const jobData = useSelector((state) => state?.JobData?.details);
  const [conditionsText, setConditionsText] = useState(null);
  const [ppoSalaryBreakup, setPpoSalaryBreakup] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateData = () => {
    var is_valid = true;
    var company_error = null;
    var job_title_error = null;
    var job_role_error = null;
    var job_segment_error = null;
    var start_date_error = null;
    var end_date_error = null;
    var job_desc_error = null;
    var ctc_min_error = null;
    var ctc_max_error = null;
    var location_error = null;
    var vacancy_error = null;
    var ppo_ctc_max_error = null;
    var ppo_ctc_min_error = null;
    var courses_error = null;
    var min_score_error = null;
    var batch_error = null;
    var round_error = null;
    var duration_error = null;
    var venue_error = null;
    var placement_error = null;
    var skills_error = null;
    var twelve_error = null;
    var tenth_error = null;

    var company_alert = null;
    var job_title_alert = null;
    var job_role_alert = null;
    var job_segment_alert = null;
    var start_date_alert = null;
    var end_date_alert = null;
    var desc_alert = null;
    var salary_alert = null;
    var location_alert = null;
    var vacancy_alert = null;
    var internship_duration_alert = null;
    var internship_duration_error = null;
    var ppo_salary_alert = null;
    var courses_alert = null;

    var batch_alert = null;
    var selection_alert = null;

    var placement_alert = null;
    var skills_alert = null;
    var school_grading_alert = null;
    var grading_alert = null;
    var edit_reason = null;

    var arr_alerts = [];

    if (placementCycle?.length === 0) {
      placement_error = "Please Enter Placement Cycle!!";
      is_valid = false;
      placement_alert = "Placement";
      arr_alerts.push("Placement");
    }
    if (jobAction === "edit" && !editReason) {
      setEditReasonError("Please Enter Reason");
      is_valid = false;
      arr_alerts.push("Edit Reason");
    }

    selectedFields?.length > 0 &&
      selectedFields?.map((item) => {
        if (item.field_type.toLowerCase() === "company") {
          if (!jobDetails?.company) {
            company_error = "Please Enter the company!!";
            is_valid = false;
            company_alert = "Company";
            arr_alerts.push("Company");
          }
        }
        if (
          item.field_type.toLowerCase() === "textfield" &&
          item?.key === "job_title"
        ) {
          if (!jobDetails?.job_title) {
            job_title_error = "Please Enter Job Title";
            is_valid = false;
            job_title_alert = "Job Title";
            arr_alerts.push("Job Title");
          }
        }
        if (item.field_type.toLowerCase() === "autocomplete") {
          if (item?.key === "job_role") {
            if (!jobDetails?.job_role) {
              job_role_error = "Please Enter Job Role";
              is_valid = false;
              job_role_alert = "Job Role";
              arr_alerts.push("Job Role");
            }
          }
          if (item?.key === "job_segment") {
            if (!jobDetails?.job_segment) {
              job_segment_error = "Please Enter Job Segment";
              is_valid = false;
              job_segment_alert = "Job Segment";
              arr_alerts.push("Job Segment");
            }
          }
        }
        if (item.field_type.toLowerCase() === "application_date") {
          if (!jobDetails?.application_start_date) {
            start_date_error = "Please Enter application start date";
            is_valid = false;
            start_date_alert = "Application Start Date";
            arr_alerts.push("Application Start Date");

            // alert.error("Please Enter Application Start Date!");
          }
          if (!jobDetails?.application_end_date) {
            end_date_error = "Please Enter application end date";
            is_valid = false;
            end_date_alert = "Application End Date";
            arr_alerts.push("Application End Date");

            // alert.error("Please Enter Application End Date!");
          }
        }
        if (item.field_type.toLowerCase() === "job-desc") {
          if (!jobDetails?.job_description) {
            job_desc_error = "Enter Job Description";
            is_valid = false;
            desc_alert = "Job Description";
            arr_alerts.push("Job Description");
          }
        }
        if (item.field_type.toLowerCase() === "ctc_range") {
          if (jobDetails?.salary_type === "PAID") {
            if (!jobDetails?.ctc_min || jobDetails?.ctc_min === 0) {
              ctc_min_error = "Enter Min Ctc";
              is_valid = false;
              salary_alert = "Salary";
              arr_alerts.push("Salary");
            }
            if (!jobDetails?.ctc_max || jobDetails?.ctc_max === 0) {
              ctc_max_error = "Enter Max Ctc";
              is_valid = false;
              salary_alert = "Salary";
              arr_alerts.push("Salary");
            }
            if (jobDetails?.ctc_min && jobDetails?.ctc_max) {
              if (
                parseFloat(jobDetails?.ctc_max) <
                parseFloat(jobDetails?.ctc_min)
              ) {
                alert.error("Please Enter Max CTC more than min CTC");
                ctc_min_error = "Enter correct Min Ctc";
                ctc_max_error = "Enter correct Max Ctc";
                is_valid = false;
                salary_alert = "Salary";
                arr_alerts.push("Salary");
              }
            }
          }
        }
        if (item.field_type.toLowerCase() === "location") {
          if (!jobDetails?.pan_india && jobDetails.job_location?.length === 0) {
            location_error = "Please enter job locations";
            is_valid = false;
            location_alert = "Location";
            arr_alerts.push("CompanyLocation");
          }
        }
        if (item.field_type.toLowerCase() === "vacancy") {
          if (!jobDetails?.vacancy || jobDetails?.vacancy === 0) {
            vacancy_error = "Please enter Vacancy";
            is_valid = false;
            vacancy_alert = "Vacancy";
            arr_alerts.push("Vacancy");
          }
        }
        if (item.field_type.toLowerCase() === "duration") {
          if (
            (jobDetails?.job_type_id === 1 &&
              !jobDetails?.internship_duration) ||
            jobDetails?.internship_duration === 0
          ) {
            internship_duration_error = "Please enter duration";
            is_valid = false;
            internship_duration_alert = "duration";
            arr_alerts.push("duration");
          }
        }
        if (item.field_type.toLowerCase() === "ppo") {
          if (jobDetails?.ppo) {
            if (jobDetails?.ppo_salary_type === "PAID") {
              if (!jobDetails?.ppo_ctc_min || jobDetails?.ppo_ctc_min === 0) {
                ppo_ctc_min_error = "Enter Min Ctc";
                is_valid = false;
                ppo_salary_alert = "Salary Ppo";
                arr_alerts.push("Salary Ppo");
              }
              if (!jobDetails?.ppo_ctc_max || jobDetails?.ppo_ctc_max === 0) {
                ppo_ctc_max_error = "Enter Max Ctc";
                is_valid = false;
                ppo_salary_alert = "Salary Ppo";
                arr_alerts.push("Salary Ppo");
              }
              if (jobDetails?.ppo_ctc_min && jobDetails?.ppo_ctc_max) {
                if (
                  parseFloat(jobDetails?.ppo_ctc_max) <
                  parseFloat(jobDetails?.ppo_ctc_min)
                ) {
                  alert.error("Please Enter Max CTC more than min CTC");
                  ctc_min_error = "Enter correct Min Ctc";
                  ctc_max_error = "Enter correct Max Ctc";
                  is_valid = false;
                  ppo_salary_alert = "Salary Ppo";
                  arr_alerts.push("Salary Ppo");
                }
              }
            }
          }
        }

        if (item.field_type.toLowerCase() === "courses") {
          if (
            !jobDetails?.degree_spec_selected ||
            jobDetails?.degree_spec_selected?.length === 0
          ) {
            courses_error = "Please Select eligible courses";
            is_valid = false;
            courses_alert = "Specialization";
            arr_alerts.push("Specialization");
          }
        }
        if (item.field_type.toLowerCase() === "skills") {
          if (jobDetails?.skills?.length === 0) {
            skills_error = "Please Select Skills";
            is_valid = false;
            skills_alert = "Skills";
            arr_alerts.push("Skills");
          }
        }
        if (item.field_type.toLowerCase() === "graduation_grade") {
          if (!jobDetails.grad_min_score || jobDetails.grad_min_score === 0) {
            min_score_error = "Please enter mimium score";
            is_valid = false;
            grading_alert = "Current Education Marks";
            arr_alerts.push("Current Education Marks");
          }
          if (
            jobDetails?.grad_scoring === "Percentage" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 100)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
            grading_alert = "Current Education Marks";
            arr_alerts.push("Current Education Marks");
          }
          if (
            jobDetails?.grad_scoring === "CGPA" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 10)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
            grading_alert = "Current Education Marks";
            arr_alerts.push("Current Education Marks");
          }
          if (
            jobDetails?.grad_scoring === "GPA" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 4)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 4";
            is_valid = false;
            grading_alert = "Current Education Marks";
            arr_alerts.push("Current Education Marks");
          }
        }
        if (item.field_type.toLowerCase() === "school_grade") {
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "Percentage" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 100)
          ) {
            twelve_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "CGPA" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 10)
          ) {
            twelve_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "GPA" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 4)
          ) {
            twelve_error = "Please enter you minimum score between 0 to 4";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
        }
        if (item.field_type.toLowerCase() === "school_grade") {
          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "Percentage" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 100))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }

          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "CGPA" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 10))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "GPA" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 4))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 4";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
        }
        if (item.field_type.toLowerCase() === "batch") {
          if (jobDetails?.eligible_batches?.length === 0) {
            batch_error = "Please enter Eligible batches";
            is_valid = false;
            batch_alert = "Btach";
            arr_alerts.push("Btach");
          }
        }
        if (item.field_type.toLowerCase() === "selection") {
          if (jobDetails?.rounds?.length === 0) {
            round_error = "Please select Round";
            duration_error = "Please Enter duration";
            venue_error = "Plese select your venue";
            is_valid = false;
            selection_alert = "Selection Procedure";
            arr_alerts.push("Selection Procedure");
          }
        }
        // if (item.field_type.toLowerCase() === "document") {
        //   if (jobDetails?.documents?.length === 0) {
        //     document_error = "Please select required documents";
        //     is_valid = false;
        //   }
        // }
      });
    // var unique = arr_alerts.filter(onlyUnique);
    // var str = `Please Enter ${generateCsvNew(unique)}`;
    // alert.error(str);
    setJobDetailsError({
      ...jobDetailsError,
      company: company_error,
      job_title: job_title_error,
      job_role: job_role_error,
      job_segment: job_segment_error,
      application_end_date: end_date_error,
      application_start_date: start_date_error,
      job_description: job_desc_error,
      ctc_min: ctc_min_error,
      ctc_max: ctc_max_error,
      job_location: location_error,
      vacancy: vacancy_error,
      ppo_ctc_max: ppo_ctc_max_error,
      ppo_ctc_min: ppo_ctc_min_error,
      degree_spec_selected: courses_error,
      grad_min_score: min_score_error,
      eligible_batches: batch_error,
      rounds: round_error,
      round_duration: duration_error,
      venue: venue_error,
      placement_cycle: placement_error,
      skills: skills_error,
      senior_sec_min_score: twelve_error,
      sec_min_score: tenth_error,
      internship_duration: internship_duration_error,
    });

    return is_valid;
  };

  const timer = React.useRef();
  const [jobText, setJobText] = useState("");
  // const [placementvalue, setPlacementValue] = useState([]);
  const a = [];

  const handleSubmit = (status) => {
    // if (!loadingPost) {
    //   setLoadingPost(true);
    //   timer.current = window.setTimeout(() => {
    //     setLoadingPost(false);
    //   }, 2000);
    // }
    if (validateData()) {
      jobDetails["fields"] = selectedFields;

      var data = {
        college_id: [collegeId],
        college_job_data: jobDetails,
        company_id: jobDetails?.company_id,
        job_status: status,
        placement_cycle: placementCycle,
        college_to_cycle: [
          { details: { id: collegeId }, placementCycle: placementCycle },
        ],
        application_start_date: new Date(jobDetails?.application_start_date)
          .toISOString()
          .slice(0, 10),
        application_end_date: new Date(jobDetails?.application_end_date)
          .toISOString()
          .slice(0, 10),
        ...(jobDetails?.campus_drive_date && {
          campus_drive_date: new Date(jobDetails?.campus_drive_date)
            .toISOString()
            .slice(0, 10),
        }),
      };

      if (jobAction === "edit") {
        var arr = [...jobStatus?.notes_list];

        data["notes_list"] = arr;
        data["reason"] = editReason;
      }

      if (jobAction === "draft") {
        var arr = [...jobStatus?.notes_list];

        data["notes_list"] = arr;
        data["reason"] = "Draft Job Posting";
      }

      if (jobAction === "ejd") {
        data["is_ejd_job"] = true;
        data["ejd_job_id"] = jobId;
      }

      if (jobAction === "recommended") {
        data["niyukti_job_id"] = selectedData?.niyukti_job_id;
        data["niyukti_company_id"] = selectedData?.companyDetails?.company_id;
      }
      // flag for inbound jobs
      if (isInbound.current) {
        let [userName, lastName] = userDetails?.name.split(" ");
        data["is_getwork_inbound"] = true;
        data["user_id"] = userDetails?.user_id;
        data["first_name"] = userName;
        data["last_name"] = lastName;
      }

      setJobText(status === "DRAFTED" ? "Saved" : "Posted");

      if (jobAction === "edit" || jobAction === "draft") {
        dispatch(
          JobPatchAPI(
            data,
            jobAction === "draft" ? setLoadingDraft : setLoadingPost,
            alert,
            history,
            location,
            handleClickOpen,
            jobId,
            setJobDetails,
            setReturnJobId,
            setSalaryBreakup,
            setPlacementCycle,
            setJobDesc,
            setDataSelectedNewFormat,
            pushArray,
            setPushArray,
            setCoursesArray,
            selectedCourses,
            setDataSelected,
            setSpecializationInDgegreeId,
            setQualifications,
            handleClosePreview,
            setPpoSalaryBreakup,
            setConditionsText,
            setFormSubmitted
          )
        );
        setPlacementCycle([...a]);
      } else {
        dispatch(
          JobPostAPI(
            data,
            setLoadingPost,
            alert,
            history,
            location,
            handleClickOpen,
            jobData,
            setReturnJobId,
            jobText,
            setLoadingDraft,
            setJobDetails,
            setSalaryBreakup,
            setPlacementCycle,
            setJobDesc,
            setDataSelectedNewFormat,
            pushArray,
            setPushArray,
            setCoursesArray,
            selectedCourses,
            setDataSelected,
            setSpecializationInDgegreeId,
            setQualifications,
            handleClosePreview,
            setPpoSalaryBreakup,
            setConditionsText,
            setFormSubmitted
          )
        );
        setPlacementCycle([...a]);
      }
    } else {
      alert.error("Please Fill All the above mandatory fields");
    }
  };

  const [jobDetailsError, setJobDetailsError] = useState({
    company_id: null,
    company_name: null,
    company: null,
    job_title: null,
    job_type_id: null,
    job_type_name: null,
    job_role_id: null,
    job_role_name: null,
    job_role: null,
    job_segment_id: null,
    job_segement_name: null,
    job_segment: null,
    application_start_date: null,
    application_end_date: null,
    joining_date: null,
    campus_drive_date: null,
    job_description: null,
    ctc_min: null,
    ctc_max: null,
    salary_type: null,
    ppo_salary_type: null,
    bonus: null,
    salary_breakup: null,
    wfh: null,
    pan_india: null,
    job_location: null,
    vacancy: null,
    service_bond: null,
    conditions: null,
    ppo: null,
    ppo_ctc_min: null,
    ppo_ctc_max: null,
    salary_breakup_ppo: null,
    degree_spec_selected: null,
    grad_scoring: null,
    grad_min_score: null,
    sec_scoring: null,
    sec_min_score: null,
    senior_sec_scroing: null,
    senior_sec_min_score: null,
    eligible_batches: null,
    rounds: null,
    round_duration: null,
    venue_type: null,
    venue: null,
    documents: null,
    placement_cycle: null,
    skills: null,
  });

  useEffect(() => {
    if (jobId) {
      setJobDetails({ ...selectedData });
      setPlacementCycle(
        jobStatus?.placement_cycle ? jobStatus?.placement_cycle : []
      );
      setDataFetched(true);
    }
  }, [selectedFields?.length, jobId]);

  useEffect(() => {
    if (collegeId)
      dispatch(PlacementListEndedAPI(alert, history, location, collegeId));
  }, [collegeId]);

  const [jobDesc, setJobDesc] = useState(
    jobDetails?.job_description ? jobDetails?.job_description : ""
  );
  const [salaryBreakup, setSalaryBreakup] = useState(
    jobDetails?.salary_breakup
  );
  const [dataSelectedNewFormat, setDataSelectedNewFormat] = useState([]);
  const userRolesPermission = useSelector(userRolesActionsFn);

  useEffect(() => {
    setJobDetails({
      ...jobDetails,
      grad_scoring: isGradeSystem ? "CGPA" : "Percentage",
    });
  }, [isGradeSystem]);

  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  const search = useLocation().search;
  const placementCycleId = new URLSearchParams(search).get(
    "placement_cycle_id"
  );

  useEffect(() => {
    if (selectedCourses?.length > 0 && isRecommended) {
      var arr = [...selectedCourses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2.select_all = false;
          item2.specialization.forEach((item3) => {
            item3.selected = false;
          });
        });
      });
      setCoursesArray(arr);
    }
  }, [selectedCourses?.length, isRecommended]);

  useEffect(() => {
    if (
      selectedCourses?.length > 0 &&
      selectedCourses?.length > 0 &&
      isRecommended
    ) {
      var arr = [...selectedCourses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2.select_all = checkForD(item2);
          item2.specialization.forEach((item3) => {
            item3.selected = checkForS(
              item3,
              item2.degree_id,
              item.education_id,
              item2.specialization
            );
          });
        });
      });
      setCoursesArray(arr);
      setDataSelectedNewFormat(selectedCourses);
      var push_arr = [...pushArray];
      push_arr.push(1);
      setPushArray(push_arr);
    }
  }, [isRecommended, selectedCourses]);

  useEffect(() => {
    if (selectedCourses?.length > 0 && isRecommended) {
      // var arr = [...dataSelected];
      var arr = [];
      selectedCourses?.map((item) => {
        item.degrees.map((i) => arr.push(i));
      });
      setDataSelected(arr);
    }
  }, [selectedCourses?.length, isRecommended]);

  useEffect(() => {
    if (dataSelectedNewFormat?.length) {
      setJobDetails({
        ...jobDetails,
        degree_spec_selected: dataSelectedNewFormat,
      });
      setJobDetailsError({ ...jobDetailsError, degree_spec_selected: null });
    }
  }, [dataSelectedNewFormat]);

  const checkForD = (item) => {
    var bool_ = false;

    var arr = [...selectedCourses];
    if (selectedCourses?.length > 0) {
      arr.map((i) => {
        i.degrees.map((internal) => {
          if (internal.degree_id === item.degree_id) {
            if (internal.specialization.length > 0) bool_ = true;
            else bool_ = false;
          }
        });
      });
    } else bool_ = false;

    return bool_;
  };

  const checkForS = (item, deg_id, edu_id, spec_arr) => {
    var bool_ = false;
    var arr = [...selectedCourses];
    if (selectedCourses?.length > 0) {
      var edu_index = arr.findIndex((i) => i.education_id === edu_id);
      if (edu_index !== -1)
        var deg_index = arr[edu_index].degrees.findIndex(
          (i) => i.degree_id === deg_id
        );

      if (edu_index !== -1 && deg_index !== -1) {
        arr[edu_index].degrees[deg_index].specialization.map((items) => {
          if (items.specialization_id === item.specialization_id) bool_ = true;
          // else bool_ = false;
        });
      }
    } else bool_ = false;

    return bool_;
  };

  return (
    <>
      {/* <Layout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Typography variant="h2">Post Job</Typography>
          <Typography
            variant="body2"
            color="secondary"
            style={{ marginBottom: "20px" }}
          >
            Post Jobs for students Placement
          </Typography>
          {jobData?.length > 0 && (
            <Typography
              variant="body2"
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "bold",
              }}
            >
              Latest Posted Job
            </Typography>
          )}
          {loadingCards ? (
            <></>
          ) : (
            jobData?.length > 0 &&
            (jobData?.length <= 3 ? (
              <>
                <Grid container spacing={3} style={{ marginBottom: "30px" }}>
                  {jobData?.map((item) => (
                    <Grid item xs={4}>
                      <PreviousJob
                        item={item}
                        setDataSelected={setDataSelected}
                        placementCycleId={placementCycleId}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                <Carousel
                  itemsToShow={3}
                  pagination={false}
                  renderArrow={({ type, onClick }) => {
                    const pointer =
                      type === consts.PREV ? (
                        <ArrowBackIosIcon color="secondary" />
                      ) : (
                        <ArrowForwardIosIcon color="secondary" />
                      );
                    return (
                      <button
                        className={classes.sliderbutton}
                        onClick={onClick}
                      >
                        <div className={classes.arrow}>{pointer}</div>
                      </button>
                    );
                  }}
                  itemPadding={[10, 10]}
                >
                  {jobData?.map((item) => (
                    <Item>
                      <PreviousJob
                        item={item}
                        setDataSelected={setDataSelected}
                        placementCycleId={placementCycleId}
                      />
                    </Item>
                  ))}
                </Carousel>
              </Grid>
            ))
          )}
          <div style={{ marginTop: "10px", marginBottom: "30px" }}>
            <Divider style={{ backgroundColor: "#dddddd" }} />
          </div>
          {step === 1 ? (
            <>
              {!jobId && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    style={{ marginLeft: "10%", marginBottom: "10px" }}
                    onClick={() => setStep(0)}
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Back
                  </Button>
                  <br />
                </>
              )}
              <div className={classes.post}>
                {loadingjobData ? (
                  <CustomCircularProgress />
                ) : (
                  <PostJobForm
                    jobDetails={jobDetails}
                    setJobDetails={setJobDetails}
                    jobDetailsError={jobDetailsError}
                    setJobDetailsError={setJobDetailsError}
                    // jobId={jobId}
                    // setJobId={setJobId}
                    jobAction={jobAction}
                    setJobAction={setJobAction}
                    dataFetched={dataFetched}
                    loadingCourses={loadingCourses}
                    placementCycle={placementCycle}
                    placementCycleId={placementCycleId}
                    setPlacementCycle={setPlacementCycle}
                    jobDesc={jobDesc}
                    setJobDesc={setJobDesc}
                    salaryBreakup={salaryBreakup}
                    setSalaryBreakup={setSalaryBreakup}
                    dataSelectedNewFormat={dataSelectedNewFormat}
                    setDataSelectedNewFormat={setDataSelectedNewFormat}
                    qualifications={qualifications}
                    setQualifications={setQualifications}
                    coursesArray={coursesArray}
                    setCoursesArray={setCoursesArray}
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                    pushArray={pushArray}
                    setPushArray={setPushArray}
                    specializationInDegreeId={specializationInDegreeId}
                    setSpecializationInDgegreeId={setSpecializationInDgegreeId}
                    conditionsText={conditionsText}
                    setConditionsText={setConditionsText}
                    ppoSalaryBreakup={ppoSalaryBreakup}
                    setPpoSalaryBreakup={setPpoSalaryBreakup}
                    collegeId={collegeId}
                    setFormSubmitted={setFormSubmitted}
                    formSubmitted={formSubmitted}
                    editReason={editReason}
                    setEditReason={setEditReason}
                    editReasonError={editReasonError}
                    setEditReasonError={setEditReasonError}
                    selectedData={selectedData}
                    resetPlacementFlag={resetPlacementFlag}
                    PlacementFlag={PlacementFlag}
                    isRecommended={isRecommended}
                  />
                )}
              </div>
              {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_job"
              ) && (
                <div className={classes.bottombtn}>
                  {jobStatus?.job_status === "DRAFTED" ? (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          if (!loadingPost) handleSubmit("OPEN");
                        }}
                        className={classes.btn}
                      >
                        {loadingPost ? (
                          <>
                            <CircularProgress
                              className={classes.circularProgress}
                              size={14}
                            />
                          </>
                        ) : (
                          "Post this Job"
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        if (!loadingPost) handleSubmit("OPEN");
                      }}
                      className={classes.btn}
                      style={{ minWidth: "200px" }}
                    >
                      {loadingPost ? (
                        <>
                          <CircularProgress
                            className={classes.circularProgress}
                            size={14}
                          />
                        </>
                      ) : jobAction === "edit" ? (
                        "Edit Job"
                      ) : (
                        "Post Job"
                      )}
                    </Button>
                  )}
                  <SuccessfullyPostJob
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    jobDetails={jobDetails}
                    jobAction={jobAction}
                    returnJobId={returnJobId}
                    jobText={jobText}
                    setJobDetails={setJobDetails}
                    setPlacementCycle={setPlacementCycle}
                    setJobDesc={setJobDesc}
                    setSalaryBreakup={setSalaryBreakup}
                    setDataSelectedNewFormat={setDataSelectedNewFormat}
                  />
                  <FullScreenPreviewDialog
                    open={openPreview}
                    handleClickOpen={handleClickOpenPreview}
                    handleClose={handleClosePreview}
                    jobDetails={jobDetails}
                    handleSubmit={handleSubmit}
                    loadingPost={loadingPost}
                    loadingDraft={loadingDraft}
                    jobStatus={jobStatus}
                    jobAction={jobAction}
                  />
                  {(!jobAction ||
                    jobAction.includes("clone") ||
                    jobStatus?.job_status === "DRAFTED") && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (!loadingDraft) handleSubmit("DRAFTED");
                      }}
                      className={classes.btn}
                    >
                      {loadingDraft ? (
                        <>
                          <CircularProgress
                            className={classes.circularProgressTheme}
                            size={14}
                          />
                        </>
                      ) : (
                        "Save in Draft"
                      )}
                    </Button>
                  )}

                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.btn}
                    onClick={() => {
                      handleClickOpenPreview();
                    }}
                    disabled={!validateData}
                  >
                    Preview
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <Typography
                variant="body2"
                style={{
                  marginTop: "30px",
                  marginBottom: "20px",
                  fontWeight: "bold",
                }}
              >
                Choose Job Category
              </Typography>

              <>
                <Grid container spacing={4} style={{ marginBottom: "30px" }}>
                  <Grid item xs={4}>
                    <JobTypeCards
                      heading={"Post Full Time Job"}
                      subTitle={
                        "Full-time work offers a pathway to career advancement. Perks are the cherry on top of the employment cake, and they come in all shapes and sizes."
                      }
                      buttonName={
                        fullTimeJobs?.length > 0
                          ? "Fill Details"
                          : "Please Create Your Form"
                      }
                      action={handleFunction}
                      actionKey={"full-time"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <JobTypeCards
                      heading={"Post Internship"}
                      subTitle={
                        "An internship is a great way to get career the much needed hands-on learning and real-time experience in the beginning of the career."
                      }
                      buttonName={
                        internships?.length > 0
                          ? "Fill Details"
                          : "Please Create Your Form"
                      }
                      action={handleFunction}
                      actionKey={"internships"}
                    />
                  </Grid>
                </Grid>
              </>
            </>
          )}
        </>
      )}
      {/* </Layout> */}
    </>
  );
}

export default PostJob;
