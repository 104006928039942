import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../api/Domain/selector";
import { ReactComponent as NoData } from "../../assets/svg/New entries-rafiki.svg";

const useStyles = makeStyles(theme => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  img: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    alignItems: "center",
    margin: "auto",
  },
}));

export default function NoDataFunc({ handleClickOpen }) {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);

  return (
    <>
      <div className={classes.img}>
        <NoData
          height="320px"
          width="320px"
          alt="404 Error"
          fill={theme_change?.main ? theme_change?.main : "#007BFF"}
        />

        {/* <Typography
          variant="h2"
          style={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          No Placement Cycle Available
        </Typography> */}
        <Typography style={{ fontSize: "18px" }}>
          Add a new Placement Cycle
          {/* You may also refresh the page or try again later */}
        </Typography>
        <br />
        {/* <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={() => {
            handleClickOpen();
          }}
        >
          Add New Placement Cycle
        </Button> */}
      </div>
    </>
  );
}
