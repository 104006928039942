import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Dialog, Button, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ApproveStudentsAPI } from "../../../api/ManageStudents/action";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
    padding: "20px",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "20px",
  },
}));

export default function RejectDialog({
  open,
  handleClickOpen,
  handleClose,
  reject = false,
  collegeId,
  userId,
  getInvitationStatus,
  StudentDetailsAPI,
}) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);

  const handleClickClose = () => {
    setDeleteReason("");
    setDeleteReasonError("");
    handleClose();
  };

  const callback = () => {
    getInvitationStatus(userId);
    if (!reject) {
      StudentDetailsAPI(true);
    }
  };
  const handleSubmit = () => {
    if (reject && !deleteReason) {
      setDeleteReasonError(`Please Provide Reason For Rejection`);
      return;
    }

    var data = {
      college_id: [collegeId],
      user_id: [userId],
      student_id: [],
      status: reject ? "REJECTED" : "APPROVED",
    };
    if (reject) data["message"] = deleteReason;

    const fromStudentDetail = true;
    dispatch(
      ApproveStudentsAPI(
        data,
        alert,
        history,
        location,
        [], //companyList
        handleClickClose,
        () => {}, //setCallNavbar
        setLoading,
        false, //changeStatus
        fromStudentDetail,
        callback
      )
    );
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClickClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ width: "400px" }}>
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure you want to {reject ? "reject" : "approve"} this student ?
            </Typography>

            <Typography variant="body1" style={{ color: "#ABABAB" }}>
              If you choose yes , student added will be in <br /> {reject ? "rejected" : "approved"} list.
            </Typography>
          </div>
        </div>
        {reject ? (
          <>
            {" "}
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              multiline
              rows="2"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label="Reason (if any)"
              value={deleteReason}
              onChange={(e) => {
                setDeleteReason(e.target.value);
                setDeleteReasonError("");
              }}
              style={{ marginBottom: 10 }}
              error={deleteReasonError ? true : false}
              helperText={<span>{deleteReasonError}</span>}
            />
          </>
        ) : null}
        <br />{" "}
        <div className={classes.btngrid}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={handleClose}
            color="primary"
          >
            No
          </Button>

          <Button
            autoFocus
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading ? <CustomButtonCircularProgress /> : "Yes"}
          </Button>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
