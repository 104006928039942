import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  Dialog,
  DialogContent,
  Radio,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import LanguageForm from "./LanguageForm";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
    fontFamily: " Nunito",
  },
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "5px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  font: {
    fontWeight: "600",
  },

  input: {
    height: 48,
  },

  inputbase: {
    padding: "10px 14px",
    fontSize: "14px",
  },
  resize: {
    color: "#7e7e7e",
    padding: "6px",
    fontSize: "14px",
    paddingLeft: "10px",
  },

  MuiInput: {
    height: 48,
  },
  forgot: {
    fontSize: "14px",
    fontWeight: "bold",

    color: "#007bff",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
    marginRight: "10px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  margin: {
    width: "100%",
  },
  cal: {
    marginBottom: "20px",
  },
  btn: {
    backgroundColor: "#007bff",
    borderRadius: "8px",
    height: "48px",
    boxShadow: "none",
    fontSize: "16px",
  },
  check: {
    display: "flex",
    flexDirection: "row",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  newBtnStyle: {
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));
function StyledRadio(props) {
  const classes = useStyles();

  return <Radio disableRipple color="primary" {...props} />;
}

export default function AddLanguageDialog(props) {
  const classes = useStyles();

  const { open, handleClose, setTempLangData, tempLangData } = props;
  const [addLang, setAddLang] = useState("");
  const [proficiency, setProficiency] = useState("Beginner");
  const [level, setLevel] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const clearLanguageForm = () => {
    setAddLang("");
    setProficiency("Beginner");
    setLevel([]);
  };

  const validateNewLangForm = () => {
    if (!(addLang && proficiency && level)) return false;
    return true;
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
      >
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>Add Language</b>
          </Typography>
          <LanguageForm
            addLang={addLang}
            setAddLang={setAddLang}
            setProficiency={setProficiency}
            setLevel={setLevel}
            level={level}
          />

          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                className={classes.newBtnStyle}
                onClick={() => {
                  clearLanguageForm();
                  props.handleClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.newBtnStyle}
                onClick={() => {
                  if (validateNewLangForm()) {
                    setTempLangData([
                      ...tempLangData,
                      {
                        name: addLang,
                        level: level,
                        proficiency: proficiency,
                      },
                    ]);
                    clearLanguageForm();
                    props.handleClose();
                  }
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
