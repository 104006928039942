import {
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlacementPatchAPI } from "../../../api/PlacementCycles/action";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    height: "35px",
    marginTop: "7px",
  },
  actionbutton: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "45px",
    paddingLeft: "45px",
    //marginLeft: "7px",
    height: "40px",
    marginTop: "7px",
  },
  btngrid: {
    paddingRight: "45px",
    display: "flex",
    paddingLeft: "45px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function Cycle({
  open,
  handleClose,
  handleClickOpen,
  item,
  collegeId,
  multiCycle = false,
  setSelectedID = () => {},
  setSelectedRowsData = () => {},
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const placementList = useSelector((state) => state.PlacementCycles.details);

  const handleSubmit = () => {
    var ending_form = true;
    if (multiCycle) {
      var data = {
        placement_id: collegeId,
        end_date: new Date().toISOString(),
        is_ended: true,
      };
      dispatch(
        PlacementPatchAPI(
          data,
          item?.id,
          setLoading,
          alert,
          history,
          location,
          placementList,
          handleClose,
          ending_form,
          true,
          setSelectedID,
          setSelectedRowsData
        )
      );
    } else {
      var data = {
        college_id: [collegeId],
        end_date: new Date().toISOString(),
        is_ended: true,
      };
      dispatch(
        PlacementPatchAPI(
          data,
          item?.id,
          setLoading,
          alert,
          history,
          location,
          placementList,
          handleClose,
          ending_form,
          false
        )
      );
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "30px", width: "450px" }}>
          <div
            style={{
              padding: "10px",
              //    paddingLeft: "20px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h5">
              Are you Sure? <br />
              You want end {item?.name}
            </Typography>
          </div>
        </div>

        <div className={classes.btngrid}>
          <Button
            variant="outlined"
            className={classes.actionbutton}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            autoFocus
            className={classes.actionbutton}
            variant="contained"
            color="primary"
            style={{ minWidth: "160px" }}
            onClick={() => {
              if (!loading) handleSubmit();
            }}
          >
            {loading ? (
              <CircularProgress
                className={classes.circularProgress}
                size={14}
              />
            ) : (
              <>End Cycle</>
            )}{" "}
          </Button>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
