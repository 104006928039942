import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

const useStyles = makeStyles(theme => ({
  hovercard: {
    backgroundColor: "white",
    padding: "10px",
    width: "auto",
    display: "flex",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    marginLeft: "-50px",
    alignItems: "center",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  icons: {
    display: "flex",
    marginLeft: "10px",
  },
  info_icon: {
    color: "#6c757d",
    height: "18px",
  },
  info: {
    display: "flex",
    // flexDirection: "column",
  },
  name: {
    fontWeight: "bold",
  },
  text: {
    minWidth: "105px",
  },
}));

function HoverItem({ data }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.hovercard}>
        <Grid container spacing={3}>
          <Grid
            item
            md={12}
            container
            className={classes.info}
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <div style={{ display: "flex" }}>
              <div className={classes.icons}>
                <DateRangeOutlinedIcon className={classes.info_icon} />
                <div className={classes.text}>
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.name}
                  >
                    Job Posted Date
                  </Typography>
                  <Typography variant="overline" color="secondary">
                    {data?.job_posted_date}
                  </Typography>
                </div>
              </div>
              {/* <div className={classes.icons}>
                <DateRangeOutlinedIcon className={classes.info_icon} />
                <div>
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.name}
                  >
                    Date of Visit
                  </Typography>
                  <Typography variant="overline" color="secondary">
                    29/09/21
                  </Typography>
                </div>
              </div> */}
              <div className={classes.icons}>
                <DateRangeOutlinedIcon className={classes.info_icon} />
                <div className={classes.text}>
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.name}
                  >
                    {" "}
                    Current Status
                  </Typography>
                  <Typography variant="overline" color="secondary">
                    {data?.status}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default HoverItem;
