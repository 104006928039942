import React, { useEffect, useState } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";

const DegreeBarChart = ({ placement }) => {
  return (
    <div
      style={{
        boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
        borderRadius: "8px",
        padding: "30px",
        height: "100%",
        // width: "100%",
      }}
    >
      {" "}
      {/* <div style={{ display: "flex", borderBottom: ".5px solid #f3f3f3" }}>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FAFBFC",
              //   padding: "20px",
              display: "flex",
              flexDirection: "row",
              borderRadius: "20px",
              width: "120px",
              height: "40px",
              color: "#8a94a6",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#377dff",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
              }}
            />
            <Typography variant="overline">Total Students</Typography>
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FAFBFC",
              //   padding: "20px",
              display: "flex",
              flexDirection: "row",
              borderRadius: "20px",
              width: "100px",
              height: "40px",
              color: "#8a94a6",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#38cb89",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
              }}
            />
            <Typography variant="overline">Placed Student</Typography>
          </div>
        </div>
      </div> */}
      <Charst
        options={{
          xaxis: {
            title: {
              text: "Degree",
              offsetY: -10,
              style: {
                fontSize: "11px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            categories: placement?.categories ? placement?.categories : [],
            labels: {
              // rotate: 0,
              // rotateAlways: true,
              // hideOverlappingLabels: true,
              trim: true,
              // maxWidth: 10,
              show: true,
              rotate: -45,
              rotateAlways: false,
              hideOverlappingLabels: true,
              showDuplicates: false,
              // trim: false,
              minHeight: undefined,
              maxHeight: 100,
              style: {
                colors: [],
                fontSize: "11px",
                fontFamily: "Nunito",
                fontWeight: 400,
                cssClass: "apexcharts-xaxis-label",
              },
            },
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },

          plotOptions: {
            bar: {
              //  horizontal: false,
              borderRadius: 8,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          legend: {
            // show: true,
            // position: "bottom",
            // floating: true,
            // verticalAlign: "bottom",
            // align: "center",
            // offsetX: 0,
            offsetY: 7,
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: "10px",
              fontFamily: "Nunito",
              colors: ["#304758"],
            },
          },
          yaxis: {
            title: {
              text: "Number of Applicants",
              style: {
                fontSize: "11px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          title: {
            text: "Campus Placement based on Degrees",
            style: {
              fontSize: "14px",
              fontFamily: "Nunito",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        }}
        series={placement?.series_list ? placement?.series_list : []}
        type="bar"
        height="100%"
        //  width="100%"
      />
    </div>
  );
};

export default DegreeBarChart;
