import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Typography, Grid, IconButton, Divider } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const PaymentSuccessful = (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 48C0 35.2696 5.05713 23.0606 14.0589 14.0589C23.0606 5.05713 35.2696 0 48 0C60.7304 0 72.9394 5.05713 81.9411 14.0589C90.9429 23.0606 96 35.2696 96 48C96 60.7304 90.9429 72.9394 81.9411 81.9411C72.9394 90.9429 60.7304 96 48 96C35.2696 96 23.0606 90.9429 14.0589 81.9411C5.05713 72.9394 0 60.7304 0 48ZM45.2608 68.544L72.896 33.9968L67.904 30.0032L44.3392 59.4496L27.648 45.5424L23.552 50.4576L45.2608 68.544Z"
      fill="#029E73"
    />
  </svg>
);

const PaymentFailed = (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.7251 14.2766C63.2001 -4.24844 32.8001 -4.24844 14.2751 14.2766C-4.2499 32.8016 -4.2499 63.2016 14.2751 81.7266C32.8001 100.252 62.7251 100.252 81.2501 81.7266C99.7751 63.2016 100.25 32.8016 81.7251 14.2766ZM61.3001 67.9516L48.0001 54.6516L34.7001 67.9516L28.0501 61.3016L41.3501 48.0016L28.0501 34.7016L34.7001 28.0516L48.0001 41.3516L61.3001 28.0516L67.9501 34.7016L54.6501 48.0016L67.9501 61.3016L61.3001 67.9516Z"
      fill="#EE2C3C"
    />
  </svg>
);

const PaymenyPending = (
  <svg
    width="97"
    height="108"
    viewBox="0 0 97 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="48.5" cy="52.207" r="48.5" fill="#E0A800" />
    <path
      d="M48.4135 64.9743C47.57 64.9743 46.9109 64.7107 46.4365 64.1834C46.0147 63.6562 45.7511 62.9181 45.6456 61.9691L43.7476 28.9125C43.6422 27.3309 43.9849 26.0655 44.7757 25.1165C45.5665 24.1675 46.7791 23.693 48.4135 23.693C49.9425 23.693 51.1023 24.1675 51.8932 25.1165C52.684 26.0655 53.0003 27.3309 52.8422 28.9125L50.9442 61.9691C50.8914 62.9181 50.6278 63.6562 50.1533 64.1834C49.7316 64.7107 49.1516 64.9743 48.4135 64.9743ZM48.4135 80.3163C46.8846 80.3163 45.6456 79.8418 44.6966 78.8928C43.7476 77.9438 43.2731 76.7312 43.2731 75.255C43.2731 73.7788 43.7476 72.5926 44.6966 71.6963C45.6456 70.7473 46.8846 70.2728 48.4135 70.2728C49.9425 70.2728 51.1551 70.7473 52.0513 71.6963C52.9476 72.5926 53.3957 73.7788 53.3957 75.255C53.3957 76.7312 52.9476 77.9438 52.0513 78.8928C51.1551 79.8418 49.9425 80.3163 48.4135 80.3163Z"
      fill="white"
    />
  </svg>
);

export default function PaymentDialog({
  dialogOpen,
  payment,
  setPaymentFailed,
  setPaymentCancelledMessage,
  setPaymentFailedMessage,
  setPaymentFailedFlag,
  setPaymentSuccessful,
  setPaymentPending,
  paymentStatusDetails,
  setPaymentStatusDetails,
}) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = useState(dialogOpen || false);
  const handleClose = () => {
    setOpen(false);
    if (payment === "SUCCESS") {
      setPaymentSuccessful(false);
    } else if (payment === "FAILED") {
      setPaymentFailed(false);
      setPaymentCancelledMessage(null);
      setPaymentFailedMessage(null);
      setPaymentFailedFlag(false);
    } else {
      setPaymentPending(false);
    }
    setPaymentStatusDetails(null);
  };

  const data = [
    {
      title: "Transaction ID",
      info: paymentStatusDetails?.transaction_id,
    },
    {
      title: "Transaction Type",
      info: paymentStatusDetails?.transaction_type,
    },
    {
      title: "Date & Time",
      info: paymentStatusDetails?.date_time,
    },
    {
      title: "Credit Purchased",
      info:
        payment === "SUCCESS" ? (
          <span style={{ color: "#029E73" }}>
            {paymentStatusDetails?.credit_purchased}
          </span>
        ) : (
          <div>
            {payment === "FAILED" ? (
              <span style={{ color: "#EE2C3C" }}>Failed</span>
            ) : (
              <span style={{ color: "#E0A800" }}>Pending</span>
            )}{" "}
          </div>
        ),
    },
  ];

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div
          style={{
            padding: 20,
            paddingBottom: 30,
            paddingLeft: "3%",
            paddingRight: "3%",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 5, top: 5 }}
          >
            <CloseRoundedIcon style={{ color: "#B3B3B3" }} />
          </IconButton>
          <div style={{ textAlign: "center" }}>
            {payment === "SUCCESS" ? (
              <div>{PaymentSuccessful}</div>
            ) : (
              <div>
                {payment === "FAILED" ? (
                  <div> {PaymentFailed}</div>
                ) : (
                  <div> {PaymenyPending}</div>
                )}
              </div>
            )}
            <Typography
              variant="h3"
              style={{ fontWeight: "600", fontSize: "24px", marginTop: 10 }}
            >
              {payment === "SUCCESS" ? (
                "Payment successful"
              ) : (
                <div>
                  {" "}
                  {payment === "FAILED"
                    ? "Payment Failed"
                    : "Payment Pending"}{" "}
                </div>
              )}
            </Typography>
            <Typography
              variant="body2"
              style={{ marginTop: "10px", color: "#6C757D" }}
            >
              {payment === "SUCCESS" ? (
                "Payment has been successful done"
              ) : (
                <div>
                  {payment === "FAILED"
                    ? "Payment has been Failed"
                    : "Payment has been pending. You can check the status after some time in the Payment history"}{" "}
                </div>
              )}
            </Typography>
          </div>

          <div style={{ marginTop: "15px" }}>
            <Grid
              container
              spacing={4}
              style={{
                margin: 0,
                width: "100%",
                backgroundColor: "#F5F7F8",
                borderRadius: 10,
              }}
            >
              <Grid item md={12}>
                <div>
                  <Typography
                    style={{
                      fontSize: "16px",
                      textAlign: "left",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    Receipt
                  </Typography>
                  {data.map((item) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "12px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          noWrap
                          style={{
                            color: "#6C757D",
                            fontSize: "14px",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "600",
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          {item.info}
                        </Typography>
                      </div>
                    </>
                  ))}
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      noWrap
                      style={{
                        color: "#6C757D",
                        fontSize: "14px",
                      }}
                    >
                      Amount Paid
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      {payment === "SUCCESS" ? (
                        <span style={{ color: "#029E73" }}>
                          ₹ {paymentStatusDetails?.amount_paid}
                        </span>
                      ) : (
                        <div>
                          {" "}
                          {payment === "FAILED" ? (
                            <span style={{ color: "#EE2C3C" }}>Failed</span>
                          ) : (
                            <span style={{ color: "#E0A800" }}>Pending</span>
                          )}{" "}
                        </div>
                      )}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
