import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DegreesGetAPI, IndustryGetAPI } from "../../../api/Common/action";
import { AllLocationsAPI } from "../../../api/JobPost/action";
import AddCompanyMember from "../../DialogSection/AddNewCompany/AddCompanyMember";
import Editor from "../../Editor";
import CompanyDetailsTable from "../../Table/CompanyDetailsTable/public";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "12px",
    marginBottom: "5px",
    width: "100%",
  },

  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingTop: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function CompanyData({
  selectedFields,
  companyDetails,
  setCompanyDetails,
  companyDetailsError,
  setCompanyDetailsError,
  pocList,
  setPocList,
  fileNames,
  setFileNames,
  collegeId,
}) {
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const classes = useStyles();
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [degree, setDegree] = React.useState(false);
  const [editor, setEditor] = React.useState("");
  const dispatch = useDispatch();
  // const handleChange = (event) => {
  //   setDegree(event.target.value);
  // };
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const handleChangeText = (e) => {
    var name = e.target.name;
    setCompanyDetails({ ...companyDetails, [name]: e.target.value });
    setCompanyDetailsError({ ...companyDetailsError, [name]: null });
  };

  useEffect(() => {
    dispatch(IndustryGetAPI(alert, history, location));
    dispatch(AllLocationsAPI(setLoadingLocations, alert, history, location));
  }, []);

  useEffect(() => {
    if (collegeId) dispatch(DegreesGetAPI(setDegree, collegeId));
  }, [collegeId]);

  const industryList = useSelector((state) => state?.commonData?.industryData);
  const cityList = useSelector((state) =>
    state?.AllLocations?.details ? state?.AllLocations?.details : []
  );
  const degreeList = useSelector((state) =>
    state?.commonData?.degrees ? state?.commonData?.degrees : []
  );

  const checkForList = (key) => {
    switch (key) {
      case "company_location":
        return cityList;
    }
  };
  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles?.length > 1)
      alert.error("Please Select just one Excel file");
    else {
      setFileNames(acceptedFiles.map((file) => file));
    }
  };

  useEffect(() => {
    setCompanyDetails({ ...companyDetails, about: editor });
  }, [editor]);
  function getFieldContent(step, item) {
    switch (step) {
      case "textfield":
        return (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            InputProps={{ classes: { root: classes.inputRoot } }}
            label={item.field_name}
            name={item.key}
            value={companyDetails[item.key]}
            onChange={handleChangeText}
            error={companyDetailsError[item.key] ? true : false}
            helperText={<span>{companyDetailsError[item.key]}</span>}
            type={item.key === "text"}
          />
        );

      case "POC":
        return (
          <>
            <Typography style={{ paddingBottom: "10px" }} variant="h6">
              POC Details
            </Typography>
            <div style={{}}>
              {pocList && pocList?.length > 0 && (
                <CompanyDetailsTable
                  companyData={[]}
                  pocList={pocList}
                  setPocList={setPocList}
                  fromPublic={true}
                />
              )}
            </div>
            <div style={{ marginBottom: "20px" }}>
              <AddCompanyMember
                pocList={pocList}
                setPocList={setPocList}
                fromPublic={true}
              />
            </div>
          </>
        );
      case "industry":
        return (
          <>
            <Autocomplete
              id="country-select-demo"
              options={industryList}
              getOptionLabel={(option) => option.name}
              value={companyDetails.industry}
              onChange={(e, newValue) => {
                setCompanyDetails({ ...companyDetails, industry: newValue });
                setCompanyDetailsError({
                  ...companyDetailsError,
                  industry: null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Industry"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={companyDetailsError.industry ? true : false}
                  helperText={<span>{companyDetailsError.industry}</span>}
                />
              )}
            />
          </>
        );
      case "industry_type":
        return (
          <>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ marginTop: "20px" }}
            >
              <InputLabel
                className={
                  companyDetailsError.industry_type
                    ? classes.redfloatingLabelFocusStyle
                    : classes.floatingLabelFocusStyle
                }
              >
                Industry Type
              </InputLabel>
              <Select
                error={companyDetailsError.industry_type ? true : false}
                value={companyDetails.industry_type_id}
                onChange={(e) => {
                  setCompanyDetails({
                    ...companyDetails,
                    industry_type_id: e.target.value,
                    industry_type_name: e.target.name,
                  });
                  setCompanyDetailsError({
                    ...companyDetailsError,
                    industry_type: null,
                  });
                }}
                label="Industry Type"
                inputProps={{
                  style: {
                    padding: "16px",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  },
                }}
                MenuProps={{
                  style: {
                    marginTop: "55px",
                  },
                }}
              >
                <MenuItem value={1} name={"Private"}>
                  Private
                </MenuItem>
                <MenuItem value={2} name={"Government"}>
                  Government
                </MenuItem>
                <MenuItem value={3} name={"Semi-Government"}>
                  Semi-Government
                </MenuItem>
              </Select>
              <FormHelperText className={classes.errorMessage}>
                {companyDetailsError.industry_type}
              </FormHelperText>
            </FormControl>
          </>
        );

      case "company_location":
        return (
          <>
            <br />
            <Autocomplete
              id="country-select-demo"
              options={cityList}
              getOptionLabel={(option) => option.city}
              value={
                companyDetails.company_location
                  ? companyDetails.company_location
                  : ""
              }
              onChange={(e, newValue) => {
                setCompanyDetails({
                  ...companyDetails,
                  company_location: newValue,
                });
                setCompanyDetailsError({
                  ...companyDetailsError,
                  company_location: null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={item?.field_name}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={companyDetailsError?.company_location ? true : false}
                  helperText={
                    <span>{companyDetailsError?.company_location}</span>
                  }
                />
              )}
            />
          </>
        );

      case "autocomplete":
        return (
          <>
            <br />
            <Autocomplete
              id="country-select-demo"
              options={degreeList}
              getOptionLabel={(option) => option.name}
              value={companyDetails[item.key] ? companyDetails[item.key] : ""}
              onChange={(e, newValue) => {
                setCompanyDetails({ ...companyDetails, [item.key]: newValue });
                setCompanyDetailsError({
                  ...companyDetailsError,
                  [item.key]: null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={item?.field_name}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={companyDetailsError[item.key] ? true : false}
                  helperText={<span>{companyDetailsError[item.key]}</span>}
                />
              )}
            />
          </>
        );
      case "logo":
        return (
          <>
            <Typography
              variant="h6"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              Upload Logo
            </Typography>
            <div style={{ width: "300px" }}>
              <DropzoneArea
                onDrop={handleDrop}
                multiple={false}
                acceptedFiles={["image/*"]}
                dropzoneText={"Drag and drop an Image here"}
                onDelete={() => {
                  setFileNames();
                }}
                filesLimit={1}
                maxFileSize={2097152}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <PresentToAllIcon />
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                    >
                      Drag and drop
                    </Typography>
                  </div>
                )}
              </DropzoneArea>
            </div>
          </>
        );
      case "description":
        return (
          <>
            <Typography
              variant="h6"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              Description
            </Typography>
            <Editor state={editor} setState={setEditor} />
          </>
        );
    }
  }

  return (
    <>
      {selectedFields?.length > 0 &&
        selectedFields?.map((item) => (
          <>{getFieldContent(item.field_type, item)} </>
        ))}
    </>
  );
}
