import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
  span: {
    color: theme.palette.primary.main,
  },
  text: {
    marginBottom: "7px",
  },
}));

export default function InterviewDetails({ item }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h6">Interview</Typography>
          <br />
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <AssignmentIcon color="primary" />
            <div style={{ marginLeft: "10px" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Interview Details
              </Typography>

              <Typography variant="body2" color="secondary" className={classes.text} style={{ marginTop: "7px" }}>
                Interview Round Name :{item?.round_name}
              </Typography>

              <Typography variant="body2" color="secondary" className={classes.text}>
                Interview Type :{item?.interview_type}
              </Typography>
              <Typography variant="body2" color="secondary" className={classes.text}>
                Date & Time :<span style={{ fontWeight: "bold", marginLeft: "5px" }}>{item?.date_time}</span>
              </Typography>
              <Typography variant="body2" color="secondary" className={classes.text}>
                Venue : <span className={classes.span}>{item?.venue}</span>
              </Typography>
              <Typography variant="body2" color="secondary" className={classes.text}>
                Invited Students : {item?.student_count}
              </Typography>
            </div>
          </div>
          <Link to={item?.interview_link} style={{ textDecoration: "none" }}>
            <Button color="primary" style={{ display: "flex" }} endIcon={<DoubleArrowIcon />}>
              Track Interview
            </Button>
          </Link>
        </Paper>
      </div>
    </>
  );
}
