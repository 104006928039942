import React from "react";
import { ReactComponent as Intern } from "../../../assets/svg/ApproveDocument.svg";
import { Box, Typography, Button, useTheme } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

function SuccessfullyMsg({ handlePostNewJob }) {
  const theme = useTheme();
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Intern fill={theme.palette.primary.main} />
        <Box style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
            You have successfully posted the job.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            Now you can manage and track the job by clicking on "Manage Job."
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "200px", boxShadow: "none" }}
          onClick={handlePostNewJob}
        >
          Post New Job
        </Button>
        <Button variant="text" style={{ width: "200px", boxShadow: "none" }}>
          <Link
            to={`/educational-group/manage-jobs`}
            target={"_self"}
            style={{ textDecoration: "none", display: "contents" }}
          >
            <span style={{ color: "#343434" }}>Manage Job</span>
          </Link>
        </Button>
      </Box>
    </div>
  );
}

export default SuccessfullyMsg;
