import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import React from "react";
import { useAlert } from "react-alert";
import Dropzone from "react-dropzone";
import "./drop.css";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(2),
    boxShadow: "none",
    fontSize: "16px",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
}));

export default function UploadExcelFile({
  handleClickOpen,
  handleClose,
  open,
  fileNames,
  setFileNames,
  handleOpenImport,

  dataRow,
  headerSampleFile,
  downloadText,
  sampleFile,
}) {
  const classes = useStyles();
  const alert = useAlert();

  const handleDrop = (acceptedFiles) => {
    var files = acceptedFiles.map((file) => file);
    if (acceptedFiles?.length > 1)
      alert.error("Please Select just one Excel file");
    else if (!files[0].name.includes("xlsx")) {
      alert.error("Please Enter Correct format file");
    } else {
      setFileNames(acceptedFiles.map((file) => file));
    }
  };

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  return (
    <div>
      {/* <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        className={classes.button}
        onClick={handleClickOpen}
      >
        Upload
      </Button> */}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <Typography style={{ fontSize: "24px" }}>
            Upload Excel File
          </Typography>
          <br />
          <Typography variant="body2" color="secondary">
            Download Sample Excel File and Upload the data accordingly. Your
            Correct Uploaded data will get reflected in the data table{" "}
          </Typography>
          {/* <CSVLink data={dataRow} headers={headerSampleFile} filename={"sample.xlsx"} style={{ textDecoration: "none" }}> */}
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a href={sampleFile} download>
              <Button
                color="primary"
                style={{
                  padding: "0px",
                  marginBottom: "20px",
                  fontSize: "16px",
                }}
              >
                Download Sample Excel File
              </Button>
            </a>
            {/* <ImportExcel /> */}
          </div>
          {/* </CSVLink> */}

          <Dropzone
            onDrop={handleDrop}
            multiple={false}
            acceptedFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <PresentToAllIcon style={{ fontSize: "40px" }} />
                <Typography
                  color="primary"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontWeight: "normal",
                    fontSize: "18px",
                  }}
                >
                  Drag and drop
                </Typography>
              </div>
            )}
          </Dropzone>

          <Typography variant="body2" color="secondary">
            {" "}
            {fileNames.length === 0 ? (
              <>Accepted files :- .xlsx (Exel file)</>
            ) : (
              "Selected File: " + fileNames[0].name
            )}
          </Typography>

          <div
            style={{
              padding: "25px",
              marginLeft: "10px",
              paddingBottom: "0px",
              display: "flex",
              paddingLeft: "25px",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              disabled={fileNames?.length > 0 ? false : true}
              onClick={() => {
                handleOpenImport();
              }}
            >
              Upload File
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
