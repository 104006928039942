import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { sanchalanAxiosGetReq } from "../../api/BaseApi/apiBase";
import { collegeDetailsSelectorFn } from "../../api/Domain/selector";
import {
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";
import Achievements from "../../components/Cards/Student/Achievements";
import BasicDetails from "../../components/Cards/Student/BasicDetails";
import Certifictions from "../../components/Cards/Student/Certifications/Certifictions";
import Contact from "../../components/Cards/Student/Contact";
import Education from "../../components/Cards/Student/Education";
import Graph from "../../components/Cards/Student/Graph/Graph";
import Hobbies from "../../components/Cards/Student/Hobbies";
import JobOffer from "../../components/Cards/Student/JobOffer";
import LanguagesCard from "../../components/Cards/Student/LanguagesCard";
import Links from "../../components/Cards/Student/Links";
import Project from "../../components/Cards/Student/Project";
import Resume from "../../components/Cards/Student/Resume/Resume";
import Skill from "../../components/Cards/Student/Skill";
import WorkExpereince from "../../components/Cards/Student/WorkExpereince";
import CustomCircularProgress from "../../components/CircularProgressBar";
import EditHistoryTable from "../../components/Table/EditHistoryTable";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi, HIDE_PROFILE_ACTION } from "../../constants/constants";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";
import HigherEducation from "../../components/Cards/Student/Education/HigherEducation";
import Reel from "../../components/Cards/Student/Reel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

function StudentDetails() {
  const classes = useStyles();
  const params = useParams();
  const alert = useAlert();

  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState();
  const [projectArray, setProjectArray] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [publicationArray, setPublicationArray] = useState([]);
  const [loadingOffer, setLoadingOffer] = useState(false);
  const [jobOfferData, setJobOfferData] = useState([]);
  const [blockStatus, setBlockStatus] = useState(false);
  const collegeDetails = useSelector(collegeDetailsSelectorFn);
  const [jobHistoryData, setJobHistoryData] = useState([]);
  const collegeId = useSelector(collegeIdFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [higherEducationData, setHigherEducationData] = useState(null);

  const [userId, setUserId] = useState(null);
  //const [userName, setUserName] = useState(null);

  const isProfileUpdatePage = HIDE_PROFILE_ACTION?.includes(
    location?.pathname?.split("/")[1]
  );

  useEffect(() => {
    if (params?.user_id) {
      const userId = params?.user_id?.split("-")?.pop();
      setUserId(userId);
    }
  }, [params]);

  const StudentDetailsAPI = (profileUpdate = false) => {
    !profileUpdate && setLoading(true);

    const url = `${BackendBaseApi.PRAVESH}api/saas/v1/student_profile/?user_id=${userId}`;
    axios
      .get(url, {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.success) {
          setStudentData(res?.data?.data);
          var arr_project =
            res?.data.data?.student_user_details?.project?.filter(
              (item) => item?.type === "Project"
            );
          var arr_publication =
            res?.data.data?.student_user_details?.project?.filter(
              (item) => item.type === "Publication"
            );
          setProjectArray(arr_project);
          setPublicationArray(arr_publication);
          setBlockStatus(res?.data?.data?.student_user_details?.is_blocked);
          setCertifications(
            res?.data?.data?.student_user_details?.certification_data
          );
          setLoading(false);
        } else {
        }
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoading(false);
      });
  };

  // const HigherEducationDetailsAPI = () => {
  //   // !profileUpdate &&
  //   setLoading(true);
  //   const url = `${BackendBaseApi.SANCHALAN}api/manage_student/get_student_higher_studies/?user_id=${userId}`;
  //   axios
  //     .get(url, {
  //       withCredentials: true,
  //     })
  //     .then((res) => {
  //       if (res?.data?.success) {
  //         setHigherEducationData(res?.data?.data);
  //         setLoading(false);
  //       } else {
  //       }
  //     })
  //     .catch((error) => {
  //       // checkForToken(error, history, location, alert);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    if (userId) {
      StudentDetailsAPI();
      // HigherEducationDetailsAPI();
      JobOfferAPI();
      JobHistoryApi();
    }
  }, [params, userId]);

  const JobOfferAPI = () => {
    setLoadingOffer(true);
    axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/manage_student/student/jobs/?user_id=${
          /* params?. */ userId
        }`,
        { withCredentials: true }
      )
      .then((res) => {
        if (res?.data?.success) {
          setJobOfferData(res?.data?.data);
        } else {
        }
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoadingOffer(false);
      });
  };
  const JobHistoryApi = () => {
    axios
      .get(
        `${BackendBaseApi.PRAVESH}api/saas/profile/get/request_list/?user_ids=${userId}&sort=3`,
        { withCredentials: true }
      )
      .then((res) => {
        if (res?.data?.success) {
          setJobHistoryData(res?.data?.data);
        } else {
        }
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
      });
  };

  const BlockStudent = (status, reason, handleClose) => {
    var data = {
      college_id: [collegeDetails?.college_id],
      user_id: userId,
      status: status,
      reason: reason,
    };
    axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_student/block_student/`,
        data,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          if (status === "INACTIVE") setBlockStatus(true);
          else setBlockStatus(false);

          handleClose();
        } else {
          alert.error("Some Error Occurred");
        }
      })
      .catch((err) => {
        checkForToken(err, history, location, alert);
        handleClose();

        throw err;
      });
  };

  // const AcceptRejectStudent = (status, handleClose) => {
  //   var data = {
  //     college_id: collegeDetails?.college_id,
  //     user_id: [params?.user_id],
  //     status: status,
  //   };
  //   axios
  //     .post(`${BackendBaseApi.SANCHALAN}api/manage_student/verify_student/`, data, {
  //       withCredentials: true,
  //     })
  //     .then((res) => {
  //       if (res.data.success) {
  //         alert.success(res.data.data.message);
  //         history.push("/manage-students");
  //       } else {
  //         alert.error("Some Error Occurred");
  //       }
  //     })
  //     .catch((err) => {
  //       checkForToken(err, history, location, alert);

  //       throw err;
  //     });
  // };

  const [applicationStatusData, setApplicationStatusData] =
    React.useState(false);
  const [applicationStatusLoading, setApplicationStatusLoading] =
    React.useState(false);

  const getApplicationStatusData = async (id) => {
    const url = `api/manage_student/student_application_count/?user_id=${id}`;
    setApplicationStatusLoading(true);
    try {
      const res = await sanchalanAxiosGetReq(url);
      if (res?.data?.success) {
        const statusResponse = Object.values(res?.data?.data)[0];
        setApplicationStatusData(statusResponse);
        setApplicationStatusLoading(false);
      } else {
        console.log("error ocuured");
      }
    } catch (error) {
      setApplicationStatusLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const [invitationStatusData, setInvitationStatusData] = React.useState(false);

  const getInvitationStatus = async (id) => {
    const url = `api/sandhi/get/invitation_status/?user_id=${id}&college_id=${collegeId}`;
    try {
      const res = await sanchalanAxiosGetReq(url);
      if (res?.data?.success) {
        setInvitationStatusData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    if (userId) getApplicationStatusData(userId);
  }, [userId]);

  useEffect(() => {
    if (userId && collegeId) {
      getInvitationStatus(userId);
    }
  }, [userId, collegeId]);

  return (
    <>
      {/* <Layout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              // approveRejectData={approveRejectData}
              // setApproveRejectData={setApproveRejectData}
              // approveRejectDataLoading={approveRejectDataLoading}
              // getApproveRejectData={getApproveRejectData}
              to={
                isProfileUpdatePage
                  ? checkForBreadcrumb(
                      isEducationGroup,
                      isCollegeGroup,
                      collegeId,
                      "profile-update-request"
                    )
                  : checkForBreadcrumb(
                      isEducationGroup,
                      isCollegeGroup,
                      collegeId,
                      "manage-students"
                    )
              }
              style={{ textDecoration: "none" }}
            >
              <Typography color="primary" variant="body2">
                {isProfileUpdatePage
                  ? "Profile Update Request"
                  : "Manage Students"}
              </Typography>
            </Link>
            {/* <Link style={{ textDecoration: "none" }} color="inherit"> */}
            <Typography color="secondary" variant="body2">
              {studentData?.first_name + " " + studentData?.last_name}
            </Typography>
            {/* </Link> */}
          </Breadcrumbs>
          <div>
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12} sm={6}>
                <br />
                <BasicDetails
                  data={studentData}
                  collegeId={collegeId}
                  setStudentData={setStudentData}
                  BlockStudent={BlockStudent}
                  blockStatus={blockStatus}
                  StudentDetailsAPI={StudentDetailsAPI}
                  fromProfileUpdate={isProfileUpdatePage}
                  invitationStatusData={invitationStatusData}
                  getInvitationStatus={getInvitationStatus}
                />
                <Contact
                  data={studentData}
                  userId={userId}
                  StudentDetailsAPI={StudentDetailsAPI}
                  fromProfileUpdate={isProfileUpdatePage}
                />
                {/*   <br /> */}
                <>
                  <Links
                    data={studentData}
                    userId={userId}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                  {/*  <br /> */}
                </>

                <Reel data={studentData} />

                <>
                  <Skill
                    data={studentData}
                    userId={userId}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                  {/* <br /> */}
                </>

                {
                  <>
                    {" "}
                    {studentData?.student_user_details
                      ?.saas_verification_status && (
                      <Resume
                        data={studentData}
                        userId={userId}
                        StudentDetailsAPI={StudentDetailsAPI}
                        fromProfileUpdate={isProfileUpdatePage}
                      />
                    )}
                  </>
                }

                {!isProfileUpdatePage && applicationStatusData && (
                  <>
                    {" "}
                    <Graph
                      data={applicationStatusData}
                      userId={userId}
                      StudentDetailsAPI={StudentDetailsAPI}
                    />
                    {/*   <br /> */}
                  </>
                )}
                {/*  <GraphSecond /> */}
                {!isProfileUpdatePage &&
                  Object.keys(jobOfferData)?.length > 0 && (
                    <JobOffer data={jobOfferData} />
                  )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <>
                  {/*   <br /> */}
                  <WorkExpereince
                    data={studentData}
                    userId={userId}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                </>

                <>
                  {/*   <br /> */}
                  <Education
                    data={studentData}
                    userId={userId}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                  {/* <HigherEducation
                    data={higherEducationData}
                  /> */}
                </>

                <>
                  {/*  <br /> */}
                  <Project
                    data={projectArray}
                    requestCount={studentData?.student_profile_update?.PROJ}
                    type={"Projects"}
                    userId={userId}
                    ApproveAcceptList={studentData?.student_profile_update}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                </>

                <>
                  {/*  <br /> */}
                  <Project
                    data={publicationArray}
                    type={"Publications"}
                    userId={userId}
                    ApproveAcceptList={studentData?.student_profile_update}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                    requestCount={studentData?.student_profile_update?.PROJ}
                  />
                </>

                <>
                  {/*   <br /> */}
                  <Certifictions
                    data={certifications}
                    userId={userId}
                    ApproveAcceptList={studentData?.student_profile_update}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                </>

                <>
                  {/*    <br /> */}
                  <Achievements
                    data={studentData?.student_user_details?.achievements}
                    userId={userId}
                    ApproveAcceptList={studentData?.student_profile_update}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                </>

                <>
                  {/*  <br /> */}
                  <Achievements
                    data={
                      studentData?.student_user_details
                        ?.extra_curricular_activity
                    }
                    from={"Exta Curricular Activities"}
                    userId={userId}
                    ApproveAcceptList={studentData?.student_profile_update}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                </>

                <>
                  {/* <br /> */}
                  <Hobbies
                    data={studentData}
                    userId={userId}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                </>

                <>
                  {/* <br /> */}
                  <LanguagesCard
                    data={studentData}
                    userId={userId}
                    StudentDetailsAPI={StudentDetailsAPI}
                    fromProfileUpdate={isProfileUpdatePage}
                  />
                </>
              </Grid>
              {!isProfileUpdatePage &&
                jobHistoryData &&
                jobHistoryData?.length > 0 && (
                  <Grid item xs={12}>
                    <Divider style={{ marginBottom: 20 }} />
                    <div
                      style={{
                        padding: 20,
                        marginTop: "20px",
                        margin: "auto",
                        backgroundColor: "#fff",
                        boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
                        borderRadius: "8px",
                      }}
                    >
                      <Accordion
                        style={{
                          boxShadow: "none",
                        }}
                        classes={{
                          root: classes.MuiAccordionroot,
                        }}
                        // classes={{
                        //   root: classes.test,
                        // }}
                      >
                        <AccordionSummary
                          style={{ padding: "0px" }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h5">Action History</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px" }}>
                          <div style={{ width: "100%" }}>
                            <EditHistoryTable data={jobHistoryData} />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                )}
            </Grid>
          </div>
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
export default StudentDetails;
