import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userRolesActionsFn } from "../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  icons: {
    display: "flex",
    marginRight: "15px",
  },
  info_icon: {
    color: "#6c757d",
    height: "18px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontWeight: "bold",
  },
}));

export default function ChatRoomHeader() {
  const classes = useStyles();
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <>
      {/* <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={12} sm={7}> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: "20px",
        }}
      >
        <div>
          <Typography variant="h2">Chat Room</Typography>
          <div style={{ display: "flex" }}>
            <Typography variant="body2" color="secondary">
              Connect with students in groups
            </Typography>
          </div>
        </div>
        {userRolesPermission?.some(
          (item) => item.access_control_key === "create_group"
        ) && (
          <div style={{ display: "flex" }}>
            <div>
              <Link to="/chat-group" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                  className={classes.button}
                >
                  Create New Chat Room
                </Button>
              </Link>
            </div>
          </div>
        )}
        {/* </Grid>
      </Grid> */}
      </div>
    </>
  );
}
