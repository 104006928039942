import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

function OverviewSkeletion() {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        {[0, 1, 2, 3].map((item) => (
          <Grid item xs={12} md={3}>
            <Skeleton
              variant="rectangular"
              height={230}
              style={{
                width: "100%",
                marginTop: 10,
                borderRadius: 10,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default OverviewSkeletion;
