import { Button, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import ProgressBar from "react-customizable-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PlacementListAPI } from "../../api/Common/action";
import { userLoggedInSelectorFn } from "../../api/Domain/selector";
import { overviewGet, placementGet, skillGet } from "../../api/Overview/action";
import { collegeDetailSelectorFn } from "../../api/SignIn/selector";
import SmallCards from "../../components/Cards/SmallCards";
import BarChart from "../../components/Chart/BarChart";
import DoughnutChart from "../../components/Chart/DoughnutChart/index,";
import CollegeListDialog from "../../components/DialogSection/CollegeList";
import DropDownButton from "../../components/Input/DropDownButton";
import Deposits from "./Deposits";
import { Skeleton } from "@material-ui/lab";
import PieSkeleton from "../../components/Cards/CampusPlacementReports/PieSkeleton";
import DashBoardGraphSkeleton from "../../components/Cards/CampusPlacementReports/DashBoardGraphSkeleton";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://getwork.org//">
        GetWork
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "auto",
    boxShadow: "none",
    //  boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    boxShadow: "none",
  },
  fixedHeightPapernew: {
    height: "auto",
    padding: "10px",
    boxShadow: "none",
  },
  box: {
    // boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "20px",
    borderBottom: "1px solid #e2e6ea",
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "10px",
  },
  section: {
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sectionbody: {
    backgroundColor: "#e2e6ea",
    //   padding: "20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    width: "100px",
    height: "40px",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  col: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
  indicator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
    margin: "0 auto",
    fontSize: "2.2em",
    fontWeight: "100",
    color: "#555",
    userSelect: "none",
  },
  view: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: 35,
    backgroundColor: theme.palette.primary.secondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  progressbar: {
    backgroundColor: "#fff",
    width: "90%",
    paddingTop: "20px",
    paddingBottom: "50px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    alignItems: "center",
  },
  progressgrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-end",
    alignItems: "flex-end",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [placementSession, setPlacementSession] = useState({
    id: "All",
    name: "All Placement Cycles",
  });
  const isLoggedIn = useSelector(userLoggedInSelectorFn);
  const dispatch = useDispatch();
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const [loadingPlacement, setLoadingPlacement] = useState(false);

  useEffect(() => {
    if (collegeDetail?.college_id)
      dispatch(
        PlacementListAPI(
          alert,
          history,
          location,
          setLoadingPlacement,
          collegeDetail?.college_id
        )
      );
  }, [collegeDetail?.college_id]);

  const [loaderOverView, setLoaderOverView] = useState(true);
  const [loaderPlacement, setLoaderPlacement] = useState(true);
  const [loaderSkill, setLoaderSkill] = useState(true);

  useEffect(() => {
    if (collegeDetail?.college_id && placementSession.id) {
      dispatch(
        overviewGet(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          placementSession?.id === "All" ? null : placementSession?.id,
          setLoaderOverView
        )
      );
      dispatch(
        placementGet(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          placementSession?.id === "All" ? null : placementSession?.id,
          setLoaderPlacement
        )
      );
      dispatch(
        skillGet(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          placementSession?.id === "All" ? null : placementSession?.id,
          setLoaderSkill
        )
      );
    }
  }, [collegeDetail, placementSession]);

  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );
  const overview = useSelector((state) => state.overview.overviewdata);
  const placement = useSelector((state) => state.overview.placementdata);
  const skill = useSelector((state) => state.overview.skilldata);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [currentEditRole, setCurrentEditRole] = useState({});
  const collegeDetails = useSelector(collegeDetailSelectorFn);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEdit = (item) => {
    setOpenEdit(true);
    setCurrentEditRole(item);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {placementSessionList?.length > 0 && (
            <div style={{ marginTop: "10px", marginBottom: "27px" }}>
              <DropDownButton
                className={classes.title}
                placementSession={placementSession}
                setPlacementSession={setPlacementSession}
              />
            </div>
          )}
          <Paper
            className={fixedHeightPaper}
            style={{ paddingLeft: "40px", paddingRight: "40px" }}
          >
            {/* <Button
              color="primary"
              variant="contained"
              size="small"
              className={classes.button}
              onClick={handleClickOpen}
            >
              Create Custom Role
            </Button>
            <CollegeListDialog
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
            /> */}
            <Deposits
              data={overview?.overview}
              placement={overview?.placement_cycle}
              loader={loaderOverView}
            />
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          spacing={2}
          //  container
          // direction="row"
          // justifyContent="center"
          // alignItems="center"
        >
          <Grid item xs={12} md={12}>
            {loaderPlacement ? (
              <DashBoardGraphSkeleton />
            ) : (
              <div className={classes.box}>
                <div className={classes.heading}>
                  <div>
                    <Typography variant="h6" style={{ color: "#000" }}>
                      {placement?.title}
                    </Typography>

                    <Typography variant="h6">
                      {placement?.total_count}
                    </Typography>
                  </div>
                </div>

                <div className={classes.fixedHeightPapernew}>
                  <BarChart
                    data={placement?.series}
                    xAxis={placement?.months}
                  />
                </div>
              </div>
            )}
          </Grid>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} md={6}>
              {/* <div className={classes.box}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      borderBottom: "1px solid #e2e6ea",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Typography variant="h6">
                        No. of Students by Skills Set
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.fixedHeightPapernew}>
                    <DoughnutChart />
                  </div>
                  <SkillColor />
                </div> */}

              <div className={classes.progressbar}>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6">Student Created Profile</Typography>
                </div>
                <br />
                {loaderSkill ? (
                  <Skeleton variant="rectangular" width={350} height={350} />
                ) : (
                  <>
                    <ProgressBar
                      radius={100}
                      progress={
                        skill?.student?.student_percentage
                          ? skill?.student?.student_percentage
                          : 0
                      }
                      cut={172}
                      rotate={-180}
                      strokeWidth={28}
                      strokeColor={
                        skill?.student?.colour
                          ? skill?.student?.colour
                          : "#c4161c"
                      }
                      strokeLinecap="square"
                      trackStrokeWidth={28}
                      trackStrokeLinecap="butt"
                    >
                      <div className={classes.indicator}>
                        <div>
                          {skill?.student?.student_percentage
                            ? skill?.student?.student_percentage
                            : 0}
                          %
                        </div>
                      </div>
                    </ProgressBar>

                    <Typography
                      variant="body2"
                      color="secondary"
                      style={{
                        marginTop: "-70px",
                        textAlign: "center",
                        paddingBlockEnd: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {skill?.student?.title
                        ? skill?.student?.title
                        : "You definitely need to make an effort to reach your goal"}
                    </Typography>
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.box}>
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    borderBottom: "1px solid #e2e6ea",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="h6">
                      No. of Students by Skills Set
                    </Typography>
                  </div>
                </div>
                <div className={classes.fixedHeightPapernew}>
                  {loaderSkill ? (
                    <PieSkeleton />
                  ) : (
                    <DoughnutChart
                      data={skill?.data?.labels}
                      data1={skill?.data?.datasets}
                    />
                  )}
                </div>
              </div>

              {/* <div className={classes.progressbar}>
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="h6">
                      Student Created Profile
                    </Typography>
                  </div>

                  <ProgressBar
                    radius={100}
                    progress={66}
                    cut={170}
                    rotate={-180}
                    strokeWidth={28}
                    strokeColor="#ffce54"
                    strokeLinecap="square"
                    trackStrokeWidth={14}
                    trackStrokeLinecap="butt"
                  >
                    <div className={classes.indicator}>
                      <div>50%</div>
                    </div>
                  </ProgressBar>

                  <Typography
                    variant="body2"
                    style={{ marginTop: "-70px", paddingBlockEnd: "20px" }}
                  >
                    You definitely need to make an effort to reach your goal
                  </Typography>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "30px",
                      boxShadow: "none",
                      backgroundColor: "#e0a800",
                      color: "#fff",
                    }}
                  >
                    Learn More
                  </Button>
                </div> */}
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12}>
            <Paper
              className={fixedHeightPaper}
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Typography variant="h6" style={{ color: "#000" }}>
                    Ongoing Jobs
                  </Typography>

                  {loaderOverView ? (
                    <Skeleton variant="rectangular" width={150} height={10} />
                  ) : (
                    <Typography
                      variant="body1"
                      style={{ color: "#181818", marginTop: "5px" }}
                    >
                      {overview?.ongoing?.count}/
                      {overview?.ongoing?.total_count}
                    </Typography>
                  )}
                  <br />
                </Grid>
                {overview?.ongoing?.count ? (
                  <Grid item xs className={classes.btn}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      className={classes.view}
                      onClick={() => history.push("/manage-jobs")}
                      disabled={loaderOverView}
                    >
                      <Typography variant="body2" color="inherit">
                        View All
                      </Typography>
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
              <Divider />
              <br />
              <SmallCards
                data={overview?.ongoing?.jobs}
                loading={loaderOverView}
              />
            </Paper>
          </Grid>
          <br />
          <Grid item xs={12} md={12}>
            <Paper
              className={fixedHeightPaper}
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Typography variant="h6" style={{ color: "#000" }}>
                    Scheduled Jobs
                  </Typography>

                  {loaderOverView ? (
                    <Skeleton variant="rectangular" width={150} height={10} />
                  ) : (
                    <Typography
                      variant="body1"
                      style={{ color: "#181818", marginTop: "5px" }}
                    >
                      {overview?.upcoming?.count}/
                      {overview?.upcoming?.total_count}
                    </Typography>
                  )}
                  <br />
                </Grid>
                {overview?.upcoming?.count ? (
                  <Grid item xs className={classes.btn}>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      className={classes.view}
                      onClick={() =>
                        history.push({
                          pathname: "/manage-jobs",
                          flag: "from-overview",
                        })
                      }
                      disabled={loaderOverView}
                    >
                      <Typography variant="body2" color="inherit">
                        View All
                      </Typography>
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
              <Divider />
              <br />
              <SmallCards
                data={overview?.upcoming?.jobs}
                type={"upcoming"}
                loading={loaderOverView}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {/* <Box pt={4}>
          <Copyright />
        </Box> */}
      {/* </Layout> */}
    </>
  );
}
