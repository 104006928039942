import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import {
  Grid,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  FormGroup,
  Checkbox,
  FormControlLabel,
  TextField,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  FormHelperText,
  Box,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import History from "../../Table/History";
import Share from "../../../assets/png/share.png";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { collegeDetailSelectorFn } from "../../../api/SignIn/selector";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import { emailRegex } from "../../../utility/regex";
import CloseIcon from "@material-ui/icons/Close";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import pSBC from "shade-blend-color";
import { sharerColumnsForTrack as tableColumns } from "../../../utility/allColumns";

const shareIcon = (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="57"
      height="57"
      rx="7.5"
      fill="url(#paint0_linear_9863_3467)"
    />
    <rect x="0.5" y="0.5" width="57" height="57" rx="7.5" stroke="black" />
    <path
      d="M47.6673 28.9993L32.734 12.666V20.8327C25.2673 20.8327 10.334 25.7327 10.334 45.3327C10.334 42.6097 14.814 37.166 32.734 37.166V45.3327L47.6673 28.9993Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9863_3467"
        x1="29"
        y1="1"
        x2="29"
        y2="57"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0061BE" />
        <stop offset="1" stop-color="#00203F" />
      </linearGradient>
    </defs>
  </svg>
);

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    width: "100%",
    height: "100%",
    display: "flex",
  },

  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // borderColor: theme.palette.primary.main,
    // backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: 45,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    //color: theme.palette.primary.main,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  share: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    borderRadius: "8px",
  },
}));

export default function ShareApplicant({
  data,
  filters,
  handleOpenShareSuccess,
  shareSuccessData,
  setShareSuccessData,
  jobTitle,
}) {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(true);
  const [scroll, setScroll] = React.useState("paper");

  const handleChange = (event) => {};
  const alert = useAlert();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const [loading, setLoading] = useState(false);
  const initalSharerObject = {
    passcode: null,
    email: [],
    action: [],
    authority: { track: false, download: false },
    current_email: null,
    selectedTableColumns: [],
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const [shareObject, setShareObject] = useState(initalSharerObject);

  const initialShareObjectError = {
    passcode: null,
    current_email: null,
    selectedTableColumns: null,
  };
  const [shareObjectError, setShareObjectError] = useState(
    initialShareObjectError
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShareObject(initalSharerObject);
    setShareObjectError(initialShareObjectError);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      getSharerLink();
    }
  }, [open]);
  const [generatedLink, setGeneratedLink] = useState();
  const [shareHistory, setShareHistory] = useState([]);
  const getSharerLink = () => {
    var data = {
      job_id: filters?.job_id,
      college_id: [parseInt(collegeDetails?.college_id)],
      sharer_user_id: collegeDetails?.user_id,
    };
    axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/track_sharer_link_generate/`,
        data,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          setGeneratedLink(res.data.data.link);
          setShareHistory(res.data.data.data);
        } else {
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleDelete = (index) => {
    if (index !== -1) {
      var arr = [...shareObject?.email];
      arr.splice(index, 1);
      setShareObject({ ...shareObject, email: arr });
    }
  };

  const validateData = () => {
    var passcode_error = null;
    var email_error = null;
    var column_error = null;
    var is_valid = true;
    if (!shareObject?.passcode) {
      passcode_error = "Please enter Passcode";
      is_valid = false;
    }
    if (shareObject?.selectedTableColumns?.length === 0) {
      column_error = "Please Select Columns to be displayed in Table";
      is_valid = false;
    }
    setShareObjectError({
      shareObjectError,
      passcode: passcode_error,
      selectedTableColumns: column_error,
    });
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      setLoading(true);
      sharePostAPI();
    }
  };

  const sharePostAPI = () => {
    const columnsToShare = [];
    shareObject?.selectedTableColumns?.map((item) => {
      if (item?.field === "degree_name") {
        columnsToShare.push(item?.field);
        columnsToShare.push("degree_id");
      } else if (item?.field === "course_name") {
        columnsToShare.push(item?.field);
        columnsToShare.push("course_id");
      } else if (item?.field === "job_title") {
        columnsToShare.push(item?.field);
        columnsToShare.push("job_id");
      } else return columnsToShare.push(item?.field);
    });

    var data = {
      job_id: filters?.job_id,
      job_link: generatedLink,
      passcode: shareObject?.passcode,
      college_id: [collegeDetails?.college_id],
      sharer_user_id: collegeDetails?.user_id,
      action_performed: {
        email: shareObject?.email,
        action: columnsToShare,
        authority: shareObject?.authority,
        // table_columns: shareObject?.selectedTableColumns?.map(
        //   (item) => item?.field
        // ),
      },
      sharer_user_name:
        collegeDetails?.first_name + " " + collegeDetails?.last_name,
    };
    setShareSuccessData({
      ...shareSuccessData,
      link: generatedLink,
      passcode: shareObject?.passcode,
    });
    axios
      .post(`${BackendBaseApi.SANCHALAN}api/manage_job/track_sharer/`, data, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Link generated Successfully");
          setLoading(false);
          setShareObject({
            ...shareObject,
            passcode: "",
            email: [],
            action: [],
            authority: { track: false, download: false },
            current_email: null,
          });
          handleOpenShareSuccess();
        } else {
          alert.error("Some Error Occurred");
          setLoading(false);
        }
        handleClose();
      })
      .catch((err) => {
        setLoading(false);

        throw err;
      });
  };

  const handleChangeCheckTableColumn = (event) => {
    // const {
    //   target: { value },
    // } = event;
    const value = event.target.value;

    const preventDuplicate = value.filter((v, i, a) => {
      // return a.findIndex((t) => t.id === v.id) === i;
      if (!shareObject?.selectedTableColumns?.includes(v.field)) return value;
    });

    setShareObject({
      ...shareObject,
      selectedTableColumns:
        typeof preventDuplicate === "string"
          ? preventDuplicate.split(",")
          : preventDuplicate,
    });
    setShareObjectError({ ...shareObjectError, selectedTableColumns: null });
  };

  useEffect(() => {
    if (open) {
      setShareObject({
        ...shareObject,
        selectedTableColumns: tableColumns,
      });
    }
  }, [open]);

  return (
    <div>
      <Button
        variant="contained"
        className={classes.share}
        color="primary"
        onClick={handleClickOpen}
      >
        <img
          src={Share}
          height="12px"
          width="12px"
          style={{ marginRight: "5px" }}
        />
        Share
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        scroll={scroll}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            style={{
              display: "flex",
              alignItems: "center",

              padding: 10,
              paddingBottom: 5,
            }}
          >
            <Box> {shareIcon}</Box>
            <Box style={{ paddingLeft: 10 }}>
              <Typography variant="h3" style={{ fontWeight: "600" }}>
                Share Applicant Tracking - {data?.job_title}
              </Typography>

              <Typography style={{ fontSize: "16px", color: "#6C757D" }}>
                Manage the users than can access your job track
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{ padding: 10, paddingTop: 0 }}>
              <Grid container spacing={2}>
                <Grid item className={classes.grid} xs={12}>
                  <Typography
                    style={{
                      color: "#464343",
                      fontSize: "16px",
                      marginBottom: 10,
                    }}
                  >
                    Job Track Link
                  </Typography>
                  <div className={classes.paper}>
                    <Paper component="form" className={classes.link}>
                      <InputBase
                        disabled
                        className={classes.input}
                        placeholder={generatedLink}
                        inputProps={{
                          className: classes.labellink,
                          "aria-label": "https://getwork-ejd-mba.in",
                        }}
                      />
                      {/* <CopyToClipboard
                        text={generatedLink}
                        onCopy={() => alert.success("Link Copied!")}
                      >
                        <IconButton
                          className={classes.iconButton}
                          aria-label="copy"
                        >
                          <FileCopyOutlinedIcon color="primary" />
                        </IconButton>
                      </CopyToClipboard> */}
                    </Paper>
                  </div>
                </Grid>

                <Grid item xs={12} className={classes.grid}>
                  <Typography
                    style={{
                      color: "#464343",
                      fontSize: "16px",
                      marginBottom: 10,
                    }}
                  >
                    Create Passcode (For who access the job track)
                  </Typography>
                  <TextField
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    required
                    fullWidth
                    label="Passcode"
                    inputProps={{
                      style: {
                        padding: "16px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                      },
                    }}
                    value={shareObject?.passcode}
                    onChange={(e) => {
                      setShareObject({
                        ...shareObject,
                        passcode: e.target.value,
                      });
                      setShareObjectError({
                        ...shareObjectError,
                        passcode: null,
                      });
                    }}
                    error={shareObjectError?.passcode ? true : false}
                    helperText={shareObjectError?.passcode}
                  />
                </Grid>

                <Grid item xs={12} className={classes.grid}>
                  <Typography
                    style={{
                      color: "#464343",
                      fontSize: "16px",
                      marginBottom: 10,
                    }}
                  >
                    Select Table Columns
                  </Typography>
                  <FormControl
                    //className={classes.formControl}
                    variant="outlined"
                    // size="small"
                    sx={{
                      "& label": {
                        color: "#6C757D",
                        fontSize: "14px",
                        paddingTop: "1.2px",
                      },
                      "& .MuiOutlinedInput-root": {
                        fontSize: "14px",
                        height: "40px",
                        "& fieldset": {
                          borderRadius: "8px",
                          color: "#7e7e7e",
                        },

                        "&.Mui-focused fieldset": {
                          fontSize: "14px",
                        },
                        "&:hover fieldset": {
                          fontSize: "14px",
                        },
                      },
                    }}
                    error={shareObjectError?.selectedTableColumns}

                    // style={{
                    //   boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                    //   borderColor: "none",
                    // }}
                  >
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      inputProps={{
                        style: {
                          padding: "16px",
                          borderRadius: "8px",
                        },
                      }}
                      // style={{
                      //   width: "350px",
                      // }}
                      placeholder="Table Name"
                      variant="outlined"
                      value={shareObject?.selectedTableColumns}
                      onChange={handleChangeCheckTableColumn}
                      // input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) =>
                        selected.map((x) => x.headerName).join(", ")
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            maxWidth: "220px",
                            borderRadius: "8px",
                          },
                        },
                      }}
                    >
                      {tableColumns?.map((variant) => (
                        <MenuItem
                          key={variant?.id}
                          value={variant}
                          style={{ backgroundColor: "#fff" }}
                        >
                          <Checkbox
                            color="primary"
                            checked={
                              shareObject?.selectedTableColumns?.indexOf(
                                variant
                              ) > -1
                            }
                          />
                          <ListItemText primary={variant.headerName} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {shareObjectError?.selectedTableColumns}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      // marginTop: "20px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#464343",
                        fontSize: "16px",
                        // marginBottom: 10,
                      }}
                    >
                      User Actions
                    </Typography>

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={shareObject?.authority?.track}
                            onChange={(e) => {
                              setShareObject({
                                ...shareObject,
                                authority: {
                                  ...shareObject.authority,
                                  track: e.target.checked,
                                },
                              });
                            }}
                            name="checkedA"
                          />
                        }
                        label={<Typography variant="body2">Track</Typography>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={shareObject?.authority?.download}
                            onChange={(e) => {
                              setShareObject({
                                ...shareObject,
                                authority: {
                                  ...shareObject.authority,
                                  download: e.target.checked,
                                },
                              });
                            }}
                            name="checkedA"
                          />
                        }
                        label={
                          <Typography variant="body2">Download</Typography>
                        }
                      />
                    </FormGroup>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#464343",
                      fontSize: "16px",
                      marginBottom: "-4px",
                    }}
                  >
                    Invite via email (optional)
                  </Typography>
                </Grid>

                <Grid item xs={10}>
                  <div className={classes.paper}>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      // InputProps={{
                      //   startAdornment:
                      //     shareObject?.email?.length > 0 &&
                      //     shareObject?.email?.map((item, index) => (
                      //       <Chip
                      //         variant="outlined"
                      //         color="primary"
                      //         size="small"
                      //         onDelete={() => handleDelete(index)}
                      //         deleteIcon={<CloseIcon color="primary" />}
                      //         label={item}
                      //         className={classes.chip}
                      //         style={{
                      //           border: "1px solid #F2F1F1",
                      //           backgroundColor: "#FAFAFA",
                      //           borderRadius: 5,
                      //           color: "#181818",
                      //         }}
                      //       />
                      //     )),
                      // }}
                      fullWidth
                      inputProps={{
                        style: {
                          padding: "16px",
                          borderRadius: "8px",
                          backgroundColor: "#fff",
                        },
                      }}
                      id="email"
                      placeholder="Email Id"
                      name="email"
                      // autoComplete="off"
                      autoComplete="off"
                      value={shareObject?.current_email}
                      onChange={(e) => {
                        setShareObject({
                          ...shareObject,
                          current_email: e.target.value,
                        });
                        setShareObjectError({
                          ...shareObjectError,
                          current_email: null,
                        });
                      }}
                      error={shareObjectError?.current_email ? true : false}
                      helperText={shareObjectError?.current_email}
                      //autoFocus
                    />

                    {/* <br /> */}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      height: "50px",
                      backgroundColor: "#181818",
                      boxShadow: "none",
                    }}
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      if (emailRegex.test(shareObject.current_email)) {
                        var arr = [...shareObject.email];
                        arr.push(shareObject.current_email);
                        setShareObject({
                          ...shareObject,
                          email: arr,
                          current_email: "",
                        });
                      } else {
                        alert.error("Invalid Email");
                      }
                    }}
                  >
                    Add Email
                  </Button>
                </Grid>
              </Grid>
              {/* <br /> */}
              <Grid
                item
                xs={12}
                style={{ marginTop: "15px", marginBottom: "10px" }}
              >
                <div>
                  {shareObject?.email?.length > 0 &&
                    shareObject?.email?.map((item, index) => (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(index)}
                        deleteIcon={<CloseIcon color="primary" />}
                        label={item}
                        className={classes.chip}
                        style={{
                          border: "1px solid #F2F1F1",
                          backgroundColor: "#FAFAFA",
                          borderRadius: 5,
                          color: "#181818",
                        }}
                      />
                    ))}
                </div>
              </Grid>

              <Accordion
                style={{
                  boxShadow: "none",
                  marginTop: "-2px",
                }}
                classes={{
                  root: classes.MuiAccordionroot,
                }}
                // classes={{
                //   root: classes.test,
                // }}
              >
                <AccordionSummary
                  style={{ width: "19%" }}
                  expandIcon={<ExpandMoreIcon color="primary" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ marginLeft: "-10px" }}
                  >
                    View History
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <History data={shareHistory} jobTitle={jobTitle} />
                </AccordionDetails>
              </Accordion>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingTop: 15, paddingBottom: 15 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 30,
            }}
          >
            <Button
              variant="text"
              color="primary"
              onClick={handleClose}
              style={{
                height: "48px",
                borderRadius: "8px",
                paddingLeft: "40px",
                paddingRight: "40px",
                marginRight: "10px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                height: "48px",
                width: 150,
                borderRadius: "8px",
                boxShadow: "none",
              }}
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              {loading ? <CustomButtonCircularProgress /> : "Share"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// export const tableColumns = [
//   {
//     id: 1,
//     field: "enrollment_id",
//     headerName: "Enrollment Id",
//   },
//   {
//     id: 2,
//     field: "name",
//     headerName: "Name",
//   },
//   {
//     id: 3,
//     field: "email",
//     headerName: "Email",
//   },
//   {
//     id: 4,
//     field: "phone",
//     headerName: "Phone Number",
//   },
//   {
//     id: 5,
//     field: "degree_name",
//     headerName: "Degree",
//   },
//   {
//     id: 6,
//     field: "course_name",
//     headerName: "Course",
//   },
//   {
//     id: 7,
//     field: "batch",
//     headerName: "Batch",
//   },
//   {
//     id: 8,
//     field: "applied_on",
//     headerName: "Applied On",
//   },
//   {
//     id: 9,
//     field: "assignment",
//     headerName: "Assignment",
//   },
// ];
