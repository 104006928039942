import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import React from "react";
import { useAlert } from "react-alert";
import Dropzone from "react-dropzone";
import "./drop.css";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },

    root1: {
        borderRadius: "8px",
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },

    formControl: {
        minWidth: "100%",
        marginTop: "20px",
    },
    button: {
        borderRadius: "8px",
        margin: theme.spacing(2),
        boxShadow: "none",
        fontSize: "16px",
        padding: "7px",
        height: "40px",
        width: '10rem',
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "flex",
    },
}));

const UploadFile = ({ handleClose, open, fileNames, setFileNames, handleUpload, data, loading }) => {

    const classes = useStyles();
    const alert = useAlert();

    const handleDrop = (acceptedFiles) => {

        let fileSize = acceptedFiles[0]?.size / 1024;
        if (fileSize > 2048) {

            alert.error("Uploaded File size should be less than 2 Mb")
            return;
        }

        let files = acceptedFiles.map((file) => file);

        if (acceptedFiles?.length > 1) {

            alert.error("Please Select just one file");

        } else {

            setFileNames(acceptedFiles.map((file) => file), files[0]);

        }
    };

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("sm");

    return (
        <div>
            <Dialog
                PaperProps={{ classes: { root: classes.root1 } }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={() => {
                    if (loading) return;
                    handleClose()
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <div style={{ padding: "40px" }}>
                    <Typography style={{ fontSize: "24px" }}>
                        Upload {`${data?.title}`}
                    </Typography>
                    <br />
                    <Typography variant="body2" color="secondary">
                        Please upload {`${data?.title}`}
                    </Typography>

                    <br />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                    </div>

                    <Dropzone
                        onDrop={handleDrop}
                        multiple={false}
                    // acceptedFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} accept={data?.acceptedFileType} />
                                <PresentToAllIcon style={{ fontSize: "40px" }} />
                                <Typography
                                    color="primary"
                                    style={{
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        fontWeight: "normal",
                                        fontSize: "18px",
                                    }}
                                >
                                    Drag and drop
                                </Typography>
                            </div>
                        )}
                    </Dropzone>

                    <Typography variant="body2" color="secondary">
                        {" "}
                        {fileNames.length === 0 ? (
                            <>Accepted files :- {`${data?.acceptedFileType ?? "*img"}`}</>
                        ) : (
                            "Selected File: " + fileNames[0].name
                        )}
                    </Typography>

                    <div
                        style={{
                            padding: "25px",
                            marginLeft: "10px",
                            paddingBottom: "0px",
                            display: "flex",
                            paddingLeft: "25px",
                            justifyContent: "center",
                            flexDirection: "row",
                        }}
                    >
                        <Button
                            variant="outlined"
                            className={classes.button}
                            onClick={handleClose}
                            color="primary"
                            disabled={loading}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            disabled={(fileNames?.length > 0 && !loading) ? false : true}
                            onClick={() => {
                                handleUpload()
                            }}
                        >
                            {loading ? <CustomButtonCircularProgress /> : "Upload File"}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default UploadFile