import {
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import pSBC from "shade-blend-color";
import {
  collegeGroupListSelectorFn,
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import {
  EDGCollegeDetailGroupsAPI,
  EDGCollegeGroupAdminsAPI,
} from "../../../api/University/APIs/action";
import {
  collegeGroupAdminsSelectorFn,
  collegeGroupDetailSelectorFn,
} from "../../../api/University/APIs/selector";
import { BackendBaseApi } from "../../../constants/constants";
import { checkForBreadcrumb } from "../../../utility/checkBreadcrumbs";
import CollegeGroupDetailTable from "../../../components/Table/CollegeGroupDetailTable";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

function AllGroupDetails() {
  const params = useParams();
  const classes = useStyles();
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeGroupAdmins = useSelector(collegeGroupAdminsSelectorFn);
  const collegeGroupDetails = useSelector(collegeGroupDetailSelectorFn);
  const [collegeAdminDetails, setCollegeAdminDetails] = useState([]);

  const columns = [
    {
      field: "collegeName",
      headerName: "College Name",
      type: "Email",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "70px",
          }}
        >
          College Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.collegeName} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.collegeName}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "location",
      headerName: "Location",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "70px",
          }}
        >
          Location
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.location} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.location}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "collegeAdmin",
      headerName: "College Admin",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "70px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.collegeAdmin} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.collegeAdmin}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "phone",
      headerName: "Phone No.",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "70px",
          }}
        >
          Phone No.
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.phone} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.phone}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "70px",
          }}
        >
          Email Id
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "studentNumber",
      headerName: "studentNumber",
      type: "text",
      width: 300,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "70px",
          }}
        >
          Number of Students
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.studentNumber} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.studentNumber}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (collegeGroupDetails) {
      var tempData = [];
      collegeGroupDetails.map((item) => {
        item?.admin_detail.map((admin) => {
          tempData.push({
            id: admin.id + "_" + item.id,
            collegeName: item?.name,
            studentNumber: item?.student_count,
            location: item?.location,
            collegeAdmin: admin.name,
            email: admin.email,
            phone: admin.phone,
          });
        });
      });
      setCollegeAdminDetails(tempData);
    }
  }, [collegeGroupDetails]);

  const checkForGroupName = () => {
    var name = "";
    if (collegeGroupList) {
      collegeGroupList.forEach((item) => {
        if (item?.id === params?.groupId) name = item?.name;
      });
      return name;
    }
  };

  const [filtersCompany, setFiltersCompany] = useState({
    search: null,
  });

  useEffect(() => {
    if (params?.groupId) {
      var url = `${BackendBaseApi.SANCHALAN}api/college_group/detail/?group_id=${params?.groupId}`;
      dispatch(
        EDGCollegeDetailGroupsAPI(
          setLoading,
          alert,
          history,
          location,
          params?.groupId,
          url,
          setClickSearchButton
        )
      );
      dispatch(
        EDGCollegeGroupAdminsAPI(alert, history, location, params?.groupId)
      );
    }
  }, [params?.groupId]);

  return (
    <>
      <div className={classes.grid}>
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to={checkForBreadcrumb(
                isEducationGroup,
                isCollegeGroup,
                collegeId,
                "sub-groups"
              )}
              style={{ textDecoration: "none" }}
            >
              <Typography color="primary" variant="body2">
                Groups
              </Typography>
            </Link>
            {/* <Link style={{ textDecoration: "none" }} color="inherit"  onClick={handleClick}> */}
            <Typography color="secondary" variant="body2">
              {checkForGroupName()}
            </Typography>
            {/* </Link> */}
          </Breadcrumbs>
          <br />
          {/* <Typography variant="h2">{params && checkForGroupName()}</Typography> */}
          <Typography
            variant="body2"
            color="secondary"
            // style={{ marginTop: "10px" }}
          >
            Manages Admins in this Group
          </Typography>
        </div>

        <div style={{ display: "flex" }}>
          <CSVLink
            // data={selectedRowsData?.length > 0 ? selectedRowsData : companyList?.length ? companyList : []}
            data={collegeAdminDetails}
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            filename={"Companies.csv"}
          >
            <Button
              variant="contained"
              startIcon={<GetAppIcon />}
              size="small"
              className={classes.button}
              style={{ textDecoration: "none", marginRight: "0px" }}
            >
              Export
            </Button>{" "}
          </CSVLink>
        </div>
      </div>
      <Typography
        variant="h5"
        // style={{ marginTop: "10px" }}
      >
        Group Admins
      </Typography>
      {collegeGroupAdmins &&
        collegeGroupAdmins.map((item) => (
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            // onDelete={() => handleDeleteCollege(data.id, index)}
            // deleteIcon={<CloseIcon />}
            label={item?.name}
            className={classes.chip}
          />
        ))}
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Typography
        variant="h5"
        // style={{ marginTop: "10px" }}
      >
        Colleges in the Group
      </Typography>

      {/* <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search by Name"
                inputProps={{
                  style: { fontSize: "14px" },
                }}
                value={filtersCompany.search}
                onChange={(e) => {
                  setFiltersCompany({
                    ...filtersCompany,
                    search: e.target.value,
                  });
                }}
              />
              {filtersCompany?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFiltersCompany({
                      ...filtersCompany,
                      search: "",
                    });
                    setClickSearchButton(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
              >
                
                <SearchIcon />
              </IconButton>
            </Paper>
              </Grid> 
          </Grid></div> */}
      {/* <div style={{marginTop: "20px"}> */}
      <div style={{ marginTop: "20px" }}>
        <CollegeGroupDetailTable
          loading={loading}
          list={collegeAdminDetails}
          columns={columns}
        />
      </div>
      {/* // </div> */}
    </>
  );
}

export default AllGroupDetails;
