import { Button, Container, Grid, Hidden, Typography } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ApartmentIcon from "@material-ui/icons/Apartment";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DegreesGetAPI } from "../../api/Common/action";
import { collegeDetailsSelectorFn } from "../../api/Domain/selector";
import { StudentsPublicPostAPI } from "../../api/ManageStudents/action";
import { StudentSelectedFieldsPublicAPI } from "../../api/StudentManagementForm/action";
import CollegeImage from "../../assets/img/company.jpg";
import defaultCompany from "../../assets/img/default.jpg";
import CustomCircularProgress from "../../components/CircularProgressBar";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import FormFooter from "../../components/Common/FormFooter";
import StudentFormNavbar from "../../components/Common/StudentFormNavbar";
import StudentData from "../../components/Form/StudentData";
import { NEXT_APP_ENDPOINT } from "../../constants/constants";
import { emailRegex } from "../../utility/regex";
import ThankYou from "../ThankYou";
import Facebook from "../../assets/png/facebook.png";
import Insta from "../../assets/png/insta.png";
import Linkedin from "../../assets/png/linkedin.png";
import { isGradeSystemFn } from "../../api/Domain/selector";
import { checkForUrl } from "../../utility/checkForUrl";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    marginTop: "20px",
  },

  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    height: "270px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  submit: {
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  imagedata: {
    display: "flex",
    width: 150,
    backgroundColor: "#fff",
    height: 80,
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  imgdata: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",

    //  borderRadius: "50%",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  icons: {
    marginRight: "10px",
    cursor: "pointer",
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      // width: "100px",
    },
  },
}));

function StudentForm() {
  const classes = useStyles();

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [loadingDegree, setLoadingDegree] = useState(false);
  const [stepNo, setStepNo] = useState(0);
  React.useEffect(() => {
    dispatch(StudentSelectedFieldsPublicAPI(setLoadingForm, alert, history, location, window.location.href));
  }, []);
  const isGradeSystem = useSelector(isGradeSystemFn);

  const selectedFields = useSelector((state) => state?.StudentPublicFields?.details?.form_fields);
  const collegeData = useSelector((state) => state?.StudentPublicFields?.details);
  const collegeDetails = useSelector(collegeDetailsSelectorFn);

  const [studentDetails, setStudentDetails] = useState({
    name: null,
    email: null,
    phone: null,
    enrollment_no: null,
    college_mail: null,
    degree: null,
    course: null,
    batch: null,
    start_date: null,
    end_date: null,
    passout: false,
    tenth_marks: null,
    twelfth_marks: null,
    cgpa: null,
    backlog_count: null,
    backlog: false,
  });

  const [studentDetailsError, setStudentDetailsError] = useState({
    name: null,
    email: null,
    phone: null,
    enrollment_no: null,
    college_mail: null,
    degree: null,
    course: null,
    batch: null,
    passout: null,
    tenth_marks: null,
    twelfth_marks: null,
    cgpa: null,
    backlog_count: null,
    backlog: null,
    batch: null,
    start_date: null,
    end_date: null,
  });

  const validateData = () => {
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    var enrollment_no_error = null;
    var college_mail_error = null;
    var degree_error = null;
    var course_error = null;
    var batch_error = null;
    var tenth_marks_error = null;
    var twelfth_marks_error = null;
    var current_marks_error = null;
    var backlog_count_error = null;
    var start_date_error = null;
    var end_date_error = null;
    var is_valid = true;
    selectedFields?.length > 0 &&
      selectedFields?.map((item) => {
        if (item.field_type.toLowerCase() === "textfield" && item?.key === "name") {
          if (!studentDetails?.name) {
            name_error = "Please Enter Your Name";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "textfield" && item?.key === "email") {
          if (!studentDetails?.email) {
            email_error = "Please Enter Your Email";
            is_valid = false;
          }

          if (studentDetails?.email && !emailRegex.test(studentDetails?.email)) {
            email_error = "Please Enter a valid Email address";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "textfield" && item?.key === "phone") {
          if (!studentDetails?.phone) {
            phone_error = "Please Enter Your Phone Number";
            is_valid = false;
          }

          if (studentDetails?.phone && studentDetails?.phone?.length !== 10) {
            phone_error = "Please Enter a valid Phone Number";
            is_valid = false;
          }
        }

        if (item.field_type.toLowerCase() === "textfield" && item?.key === "college_mail") {
          if (!studentDetails?.college_mail) {
            college_mail_error = "Please Enter College Email";
            is_valid = false;
          }

          if (studentDetails?.college_mail && !emailRegex.test(studentDetails?.college_mail)) {
            email_error = "Please Enter a valid College Email address";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "textfield" && item?.key === "enrollment_no") {

          if (!studentDetails?.enrollment_no) {

             enrollment_no_error = "Please Enter Your Enrollment Number";
             is_valid = false;
          }
          const emptySpaceCheck = /\s/g;
          if(emptySpaceCheck.test(studentDetails?.enrollment_no)){
            enrollment_no_error = "No blank space in enrollment Number";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "textfield" && item?.key === "cgpa") {
          if (!studentDetails?.cgpa) {
            current_marks_error = `Please Enter Your ${isGradeSystem ? "CGPA" : "Percentage"}`;
            is_valid = false;
          }

          if (studentDetails?.cgpa) {
            if (isGradeSystem) {
              if (Number(studentDetails?.cgpa) > 10) {
                current_marks_error = "Please enter Student's CGPA Correctly within the range of 0 to 10";
                is_valid = false;
              }
            } else {
              if (Number(studentDetails?.cgpa) > 100) {
                current_marks_error = "Please enter Student's Percentage Correctly within the range of 0 to 100";
                is_valid = false;
              }
            }
          }
        }
        if (item.field_type.toLowerCase() === "autocomplete" && item?.key === "degree") {
          if (!studentDetails?.degree) {
            degree_error = "Please Enter Your Degree";
            is_valid = false;
          }

          if (!studentDetails?.course) {
            course_error = "Please Enter Your Course";
            is_valid = false;
          }
        }

        if (item?.key === "batch") {
          if (!studentDetails?.start_date) {
            start_date_error = "Please Enter Your Start";
            is_valid = false;
          }
          if (!studentDetails?.end_date) {
            end_date_error = "Please Enter Your Start";
            is_valid = false;
          }
        }
        
        if (item?.key === "backlog_count") {
          if (studentDetails?.backlog && !studentDetails?.backlog_count) {
            backlog_count_error = "Please Enter Your valid Backlog Count";
            is_valid = false;
          }
        }
      });
    setStudentDetailsError({
      ...studentDetailsError,
      name: name_error,
      email: email_error,
      phone: phone_error,
      college_mail: college_mail_error,
      enrollment_no: enrollment_no_error,
      cgpa: current_marks_error,
      degree: degree_error,
      course: course_error,
      backlog_count: backlog_count_error,
      start_date: start_date_error,
      end_date: end_date_error,
    });
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      var obj = { ...studentDetails };
      obj.batch = parseInt(studentDetails?.end_date?.slice(0, 4));
      obj.course = {
        id: studentDetails?.course?.specialization_id,
        name: studentDetails?.course?.specialization_name,
      };
      if (isGradeSystem) {
        obj.percentage = 0;
        obj.cgpa = parseFloat(studentDetails?.cgpa ? studentDetails?.cgpa : 0);
      } else {
        obj.cgpa = 0;
        obj.percentage = parseFloat(studentDetails?.cgpa ? studentDetails?.cgpa : 0);
      }
      if (studentDetails.backlog) obj.backlog = studentDetails?.backlog_count?studentDetails?.backlog_count:0;

      var data = {
        college_id: [collegeDetails?.college_id],
        student_form_data: obj,
      };

      dispatch(StudentsPublicPostAPI(data, setLoadingPost, alert, history, location, collegeDetails?.college_id, true, [], handleClose, 0, setStepNo));
    }
  };

  useEffect(() => {

    if (collegeDetails?.college_id) dispatch(DegreesGetAPI(setLoadingDegree, collegeDetails?.college_id, alert));

  }, [collegeDetails?.college_id]);

  const handleClose = () => {};

  const Data = [
    {
      icon: <LanguageIcon color="secondary" />,
      info: checkForUrl(collegeDetails?.website),
      website: true,
      show: collegeDetails?.website ? true : false,
    },
    {
      icon: <ApartmentIcon color="secondary" />,
      info: collegeDetails?.city,
      show: collegeDetails?.city ? true : false,
    },
    {
      icon: <GolfCourseIcon color="secondary" />,
      info: collegeDetails?.college_type,
      show: collegeDetails?.college_type ? true : false,
    },
    {
      icon: <PeopleAltOutlinedIcon color="secondary" />,
      info: collegeDetails?.size + " Students",
      show: collegeDetails?.size ? true : false,
    },
    {
      icon: <FlagOutlinedIcon color="secondary" />,
      info: collegeDetails?.founded_year,
      show: collegeDetails?.founded_year ? true : false,
    },
    {
      icon: <LocationCityIcon color="secondary" />,
      info: collegeDetails?.affiliated,
      show: collegeDetails?.affiliated ? true : false,
    },
  ];

  const someTruthy = Object.values(Data).some((val) => val.show === true);

  return (
    <>
      {/* <StudentFormNavbar logo={collegeData?.logo} /> */}
      {loadingForm ? (
        <CustomCircularProgress />
      ) : stepNo === 0 ? (
        <>
          <div style={{ backgroundColor: "#fafcfc", paddingBottom: "20px" }}>
            <div
              style={{
                backgroundImage: `url(${collegeDetails?.banner ? collegeDetails?.banner : CollegeImage})`,
                backgroundRepeat: "no-repeat",
                height: "270px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Container maxWidth="lg">
              <Grid container spacing={2} style={{ marginTop: "-30px" }}>
                <Grid item>
                  <div className={classes.imagedata}>
                    <img className={classes.imgdata} alt="complex" src={collegeDetails?.logo ? collegeDetails?.logo : defaultCompany} />
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2} style={{ display: "contents" }}>
                  <div className={classes.title}>
                    <Typography variant="h3">{collegeDetails?.college_name}</Typography>
                  </div>
                </Grid>
              </Grid> */}

                <Grid
                  item
                  xs={12}
                  sm
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Grid item xs container direction="column" spacing={2} style={{ display: "contents" }}>
                    <div className={classes.title}>
                      <Typography variant="h3" style={{ fontWeight: 600, paddingBottom: "10px" }}>
                        {collegeDetails?.college_name}
                      </Typography>
                    </div>
                    <div className={classes.social}>
                      {collegeDetails?.social_links?.length > 1 && collegeDetails?.social_links[1].value && (
                        <a href={collegeDetails?.social_links[1].value} target="blank">
                          <img src={Facebook} height="40px" className={classes.icons} width="40px" />
                        </a>
                      )}
                      {collegeDetails?.social_links?.length > 0 && collegeDetails?.social_links[0].value && (
                        <a href={collegeDetails?.social_links[0].value} target="blank">
                          <img src={Linkedin} className={classes.icons} height="40px" width="40px" />
                        </a>
                      )}
                      {collegeDetails?.social_links?.length > 2 && collegeDetails?.social_links[2].value && (
                        <a href={collegeDetails?.social_links[2].value} target="blank">
                          <img src={Insta} className={classes.icons} height="40px" width="40px" />
                        </a>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <div>
                {someTruthy && <Typography variant="h6">Overview</Typography>}

                <Grid container spacing={3} style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {Data.map(
                    (item, index) =>
                      item.show && (
                        <Grid item xs={12} sm={4} key={index}>
                          <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>{item.icon}</Grid>
                            <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                              <Typography variant="body2" color="secondary" noWrap className={classes.text}>
                                {item.website ? (
                                  <a href={item.info} target="_blank">
                                    {item.info}
                                  </a>
                                ) : (
                                  item.info
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                  )}
                </Grid>
                {collegeDetails?.about_us && (
                  <Grid item xs={12}>
                    <Typography variant="h6">About us </Typography>

                    <Typography variant="body2">{ReactHtmlParser(collegeDetails?.about_us)}</Typography>
                  </Grid>
                )}
              </div>
            </Container>
          </div>
          <Container maxWidth="lg">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div className={classes.form}>
                <div style={{ paddingBottom: "20px" }}>
                  <Hidden smDown>
                    <Typography variant="h3" style={{ textAlign: "center" }}>
                      Provide your Information to Register for Placement
                    </Typography>
                  </Hidden>
                  <Hidden smUp>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      Provide your Information to Register for Placement
                    </Typography>
                  </Hidden>
                </div>
                <StudentData
                  studentDetails={studentDetails}
                  setStudentDetails={setStudentDetails}
                  studentDetailsError={studentDetailsError}
                  setStudentDetailsError={setStudentDetailsError}
                  collegeId={collegeDetails?.college_id}
                />
              </div>
              <div className={classes.submit}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={() => {
                    if (!loadingPost) handleSubmit();
                  }}
                >
                  {loadingPost ? (
                    <>
                      <CustomButtonCircularProgress />
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </Container>
          <div style={{ backgroundColor: "#fafcfc", paddingTop: "20px" }}>
            <Container maxWidth="lg">
              <Grid item xs={12} style={{ marginBottom: "30px" }}>
                <Typography variant="h5">General Instruction</Typography>
                <br />
                {instructions.map((instructions) => (
                  <div style={{ marginTop: "7px" }}>
                    <ul style={{ marginLeft: "-22px", color: "#707070" }}>
                      {instructions.line.map((line) => (
                        <li>
                          <Typography
                            //  component="li"
                            variant="body2"
                            // align="center"
                            key={line}
                            style={{ marginBottom: "10px", color: "#707070" }}
                          >
                            {line}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
                {/* <Typography variant="body2" color="secondary">
                {instructions.map((item, index) => (
                  <li style={{ marginBottom: "8px" }} key={index}>
                    {" "}
                    {item.line}{" "}
                  </li>
                ))}
              </Typography> */}
              </Grid>
            </Container>
          </div>
          <FormFooter />{" "}
        </>
      ) : (
        <ThankYou text={"You have been successfully registered!!"} link={NEXT_APP_ENDPOINT} fromStudent={true} />
      )}
    </>
  );
}

const instructions = [
  {
    line: [
      "Do register on the placements portal to take part in the placements session.",
      "Keep your resume and profile updated on the portal.",
      "Make sure you learn about a visiting company from internet, seniors and other sources before deciding to register for the company.",
      "Once registered for a company, a student is liable to attend all rounds of selection process: - PPT (Pre-Placement Talk), Written & Online Assessments as well as Interviews.",
      "Failure to do so will result in penalty in terms of TPO credits which may lead to your debarment from future placement activities.",
      "Pay attention to the company-specific instructions sent to you on the mail by TPO team. Adhere to the specific format of attachments as asked for.",
      "It is the responsibility of the student to check announcements/notices / updated information / shortlisted names etc. in the notice boards of Placement / Department Notice Board.",
    ],
  },
  // {
  //   line: "Do register on the placements portal to take part in the placements session.",
  // },
  // {
  //   line: "Keep your resume and profile updated on the portal.",
  // },
  // {
  //   line: "Make sure you learn about a visiting company from internet, seniors and other sources before deciding to register for the company.",
  // },
  // {
  //   line: "Once registered for a company, a student is liable to attend all rounds of selection process: - PPT (Pre-Placement Talk), Written & Online Assessments as well as Interviews.",
  // },
  // {
  //   line: "Failure to do so will result in penalty in terms of TPO credits which may lead to your debarment from future placement activities.",
  // },
  // {
  //   line: "Pay attention to the company-specific instructions sent to you on the mail by TPO team. Adhere to the specific format of attachments as asked for.",
  // },
  // {
  //   line: "It is the responsibility of the student to check announcements/notices / updated information / shortlisted names etc. in the notice boards of Placement / Department Notice Board.",
  // },
];

export default StudentForm;
