import {
  //FormControl,
  Grid,
  IconButton,
  InputBase,
  //InputLabel,
  //MenuItem,
  Paper,
  TextField,
  Typography,
  //Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DepartmentData } from "../../../api/Chat/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
} from "../../../api/SignIn/selector";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { alpha, styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import MuiCustomTheme from "../../NewMUITheme/MuiCustomTheme";
import { Autocomplete } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
const NewFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  height: "49px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  // "& label.Mui-focused": {
  //   color: theme.palette.primary.main,
  // },
  "& label": {
    color: "#7e7e7e",
  },
  "& .MuiInput-underline:after": {
    // borderBottomColor: "#C4C4C4",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: "#C4C4C4",
    },
    "&:hover fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&.Mui-focused fieldset": {
      // borderColor: "#C4C4C4",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    padding: "2px",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function ChatActionbar({
  selectedTab,
  onsearch,
  handleChange,
  departmentValue,
  batch,
  handleBatch,
  status,
  handleStatus,
  specialization,
  handleSpecialization,
  tableData,
  filters,
  setFilters,
  filterList,
  currentTab,
  jobProfileList,
  setJobProfileState,
  jobProfileState,
  dataLoading,
}) {
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState([]);
  // for (let i = currentYear; i >= currentYear - 5; i--) {
  //   year.push(i);
  // }

  useEffect(() => {
    if (tableData) {
      var arr = [...year];
      tableData.forEach((item) => {
        if (item.batch && !arr.includes(item.batch)) {
          arr.push(item.batch);
        }
      });
      setYear(arr);
    }
  }, [tableData]);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(DepartmentData(alert, history, location, collegeId));
  }, []);

  const department = useSelector((state) => state?.Departments?.details);
  const [searchQuery, setSearchQuery] = useState("");

  const allDegrees = [];
  const allSpecialization = [];

  department?.data?.map((item) => {
    item.degrees?.map((item2) => {
      allDegrees.push(item2.degree_name);
    });
  });

  department?.data?.map((item) => {
    item.degrees?.map((item2) => {
      item2.specialization?.map((item3) => {
        allSpecialization.push(item3.specialization_name);
      });
    });
  });

  const statusoptions = ["Placed", "Unplaced"];

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search By Name"
                value={searchQuery}
                disabled={dataLoading ? true : false}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              {filters?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  disabled={dataLoading ? true : false}
                  onClick={() => {
                    setFilters({
                      ...filters,
                      search: "",
                    });
                    setSearchQuery("");
                    //setClickSearchButton(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                disabled={dataLoading ? true : false}
                onClick={() => {
                  setFilters({
                    ...filters,
                    search: searchQuery,
                  });
                  //setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {currentTab === "three" && (
            <Grid item xs={2}>
              <Autocomplete
                id="country-select-demo"
                options={jobProfileList}
                getOptionLabel={(option) => (option?.name ? option?.name : "")}
                value={jobProfileState}
                disabled={dataLoading ? true : false}
                onChange={(e, newValue) => {
                  setJobProfileState(newValue);
                }}
                style={{ background: "#fff", borderRadius: 8 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={"Job Profile"}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                  />
                )}
              />
            </Grid>
          )}
          {filterList &&
            Object.keys(filterList)?.length > 0 &&
            Object.keys(filterList).map(
              (item) =>
                filterList[item].type === "autocomplete" && (
                  <Grid item xs={2}>
                    <Autocomplete
                      id="country-select-demo"
                      options={filterList[item].data}
                      getOptionLabel={(option) => String(option.name)}
                      value={filters[filterList[item].key]}
                      disabled={dataLoading ? true : false}
                      onChange={(e, newValue) => {
                        setFilters({
                          ...filters,
                          [filterList[item].key]: newValue,
                        });
                      }}
                      style={{ background: "#fff", borderRadius: 8 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={filterList[item].heading}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                )
            )}

          {/* <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                onChange={onsearch}
                placeholder="Search By Name"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    padding: "10px",
                  },
                }}
              />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {selectedTab === "two" ? (
            <>
              <Grid item xs={2}>
                <MuiCustomTheme>
                  <NewFormControl
                    color="primary"
                    variant="outlined"
                    //className={classes.formControl}
                  >
                    <InputLabel
                      //  className={classes.floatingLabelFocusStyle}
                      style={{
                        borderRadius: "30px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                        //color: "#7e7e7e",
                      }}
                    >
                      Department
                    </InputLabel>
                    <Select
                      value={departmentValue}
                      onChange={handleChange}
                      label="Department"
                      style={{
                        borderRadius: "30px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                        height: "49px",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            maxWidth: "200px",
                            borderRadius: "15px",
                          },
                        },
                      }}
                    >
                      <MenuItem aria-label="None" value="">
                        <Typography variant="body2" noWrap>
                          Clear
                        </Typography>
                      </MenuItem>
                      {/* <option aria-label="None" value="teszt" /> */}

          {/* {allDegrees.map((item) => {
                        return (
                          <MenuItem value={item}>
                            <Typography variant="body2" noWrap>
                              {item}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </NewFormControl>
                </MuiCustomTheme>
              </Grid>
              <Grid item xs={2}>
                <MuiCustomTheme>
                  <NewFormControl
                    variant="outlined"
                    //className={classes.formControl}
                  >
                    <InputLabel
                      style={{
                        borderRadius: "30px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                    >
                      Branch
                    </InputLabel>
                    <Select
                      value={specialization}
                      onChange={handleSpecialization}
                      label="Branch"
                      style={{
                        borderRadius: "30px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                        height: "49px",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            maxWidth: "200px",
                            borderRadius: "15px",
                          },
                        },
                      }}
                    >
                      <MenuItem aria-label="None" value="">
                        <Typography variant="body2" noWrap>
                          {" "}
                          Clear
                        </Typography>
                      </MenuItem>
                      {allSpecialization.map((item) => {
                        return (
                          <MenuItem value={item}>
                            {" "}
                            <Typography variant="body2" noWrap>
                              {" "}
                              {item}
                            </Typography>{" "}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </NewFormControl>
                </MuiCustomTheme>
              </Grid>
              <Grid item xs={2}>
                <MuiCustomTheme>
                  <NewFormControl
                    variant="outlined"
                    // className={classes.formControl}
                  >
                    <InputLabel
                      //className={classes.floatingLabelFocusStyle}
                      style={{
                        borderRadius: "30px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                    >
                      Batch
                    </InputLabel>
                    <Select
                      value={batch}
                      onChange={handleBatch}
                      label="Batch"
                      style={{
                        borderRadius: "30px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                        height: "49px",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            maxWidth: "200px",
                            borderRadius: "15px",
                          },
                        },
                      }}
                    >
                      <MenuItem aria-label="None" value="">
                        <Typography variant="body2" noWrap>
                          Clear
                        </Typography>
                      </MenuItem>
                      {year.map((item) => {
                        return (
                          <MenuItem value={item}>
                            <Typography variant="body2" noWrap>
                              {item}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </NewFormControl>
                </MuiCustomTheme>
              </Grid>{" "}
            </> */}
          {/* ) : (
            <Grid item xs={2}>
              <NewFormControl
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel className={classes.floatingLabelFocusStyle}>
                  Company Name
                </InputLabel>
                <Select
                  native
                  value={state.age}
                  onChange={handleChange}
                  label="Filter by CGPA"
                  inputProps={{
                    style: {
                      padding: "16px",
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                    },
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </NewFormControl>
            </Grid>
          )}
          {selectedTab === "three" ? (
            <Grid item xs={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel className={classes.floatingLabelFocusStyle}>
                  Job Profile
                </InputLabel>
                <Select
                  value={state.age}
                  onChange={handleChange}
                  label="Filter by CGPA"
                  inputProps={{
                    style: {
                      padding: "16px",
                      borderRadius: "30px",
                    },
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={2}>
            <MuiCustomTheme>
              <NewFormControl variant="outlined">
                <InputLabel
                  // className={classes.floatingLabelFocusStyle}
                  style={{
                    borderRadius: "30px",
                    fontSize: "14px",
                    fontFamily: "Nunito",
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  value={status}
                  onChange={handleStatus}
                  label="Status"
                  style={{
                    borderRadius: "30px",
                    fontSize: "14px",
                    fontFamily: "Nunito",
                    height: "49px",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        maxWidth: "200px",
                        borderRadius: "15px",
                      },
                    },
                  }}
                >
                  {/* <option aria-label="None" value="" />
                {statusoptions.map(item => {
                  return <option value={item}>{item}</option>;
                })} */}
          {/* <MenuItem aria-label="None" value="">
                    <Typography variant="body2" noWrap>
                      {" "}
                      Clear
                    </Typography>
                  </MenuItem>
                  {statusoptions.map((item) => {
                    return (
                      <MenuItem value={item}>
                        {" "}
                        <Typography variant="body2" noWrap>
                          {item}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </NewFormControl>
            </MuiCustomTheme>
          </Grid> */}
        </Grid>
      </div>
    </>
  );
}
