import React, { useState, useEffect } from "react";
import { Button, } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@mui/material/Slide";
import { Typography, Box, Divider } from "@mui/material";
import { Grid, Tooltip } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import useAxiosApi from "../../../Hooks/useAxiosApi"
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CompanyTable from "../../Table/CompanyTable";
import { BackendBaseApi } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { praveshAxiosPatchReq } from "../../../api/BaseApi/apiBase";
import { CancelOutlined, CheckCircle } from "@mui/icons-material";
import axios from "axios";
import { useAlert } from "react-alert";

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            <Typography sx={{ fontSize: "24px", fontWeight: "600", pl: 1 }}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 10,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({

    rowText: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        // fontSize: '12'
    },
    rowTextSelection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around'
    },
    iconBtn: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.secondary,
        },
        cursor: "pointer",
    },


}));



const OffersManageStudents = ({ open, handleClose, studentId }) => {

    const theme = useTheme();
    const classes = useStyles();

    const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const userDetails = useSelector(store => store?.UserDetails?.user_details);
    const [rowData,setRowData] = useState([]);
    const [loading , setLoading] = useState(false);
    const alert = useAlert();

    useEffect(()=>{
        if(studentId && userDetails?.college_details?.college_id){
            setLoading(true);
        axios.get(`${BackendBaseApi.SANCHALAN}api/manage_student/student_offered_details/?college_id=${userDetails?.college_details?.college_id}&student_id=${studentId}`,{withCredentials:true})
        .then(res =>{
           if(res?.data?.success) setRowData(res?.data?.data);
           else alert.error(res?.data?.message)
           setLoading(false);
        })
        .catch(err=>{
            alert.error(err?.response?.data?.error);
            setLoading(false);
        })
     }  
    },[])


    const columns = [

        {
            field: "companyName",
            headerName: "Company Name",
            // type: "text",
            width: 150,
            sortable: false,
            headerAlign: "center",
            renderHeader: (params) => (
                <strong className={classes.rowText} style={{ paddingLeft: "10px" }}>
                   Company Name
                </strong>
            ),
            renderCell: (params) => (
                <>
                    <Grid className={classes.rowText} container wrap="nowrap" >
                        {" "}
                        <Grid item xsclassName={classes.rowText} >
                            <Tooltip title={params?.row?.company_name} placement="right">
                                <Typography variant="h7">{params?.row?.company_name?.length > 20 ? `${params?.row?.company_name?.slice(0,20)}...`: params?.row?.company_name}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </>
            ),
        },

        {
            field: "job_profile",
            // type: "text",
            width: 180,
            headerAlign: "center",
            sortable: false,
            renderHeader: (params) => (
                <strong className={classes.rowText}>Job Profile</strong>
            ),
            renderCell: (params) => (
                <span className={classes.rowText} >

                    <Tooltip title={params?.row?.job_profile} placement="right">
                        <Typography variant="h7">
                           {params?.row?.job_profile?.length>20 ?`${params?.row?.job_profile?.slice(0,20)}...`:params?.row?.job_profile}
                        </Typography>
                    </Tooltip>
                </span>
            ),
        },

        {
            field: "dateOfHiring",
            // type: "text",
            sortable: false,
            width: 120,
            headerAlign: "center",
            renderHeader: (params) => (
                <strong className={classes.rowText} >Date</strong>
            ),
            renderCell: (params) => (
                <>
                    <span className={classes.rowText}>{params?.row?.date_of_hiring}</span>
                </>
            ),
        },
       
        {
            field: "currentStatus",
            // type: "text",
            sortable: false,
            width: 120,
            headerAlign: "center",
            renderHeader: (params) => (
                <strong className={classes.rowText} >Status</strong>
            ),
            renderCell: (params) => (
                <>
                    <span className={classes.rowText}>{params?.row?.current_status}</span>
                </>
            ),
        },
        {
            field: "ctc",
            // type: "text",
            sortable: false,
            width: 120,
            headerAlign: "center",
            renderHeader: (params) => (
                <strong className={classes.rowText} >CTC</strong>
            ),
            renderCell: (params) => (
                <>
                    <span className={classes.rowText}>{params?.row?.ctc_text}</span>
                </>
            ),
        },
        {
            field: "placement_type",
            // type: "text",
            sortable: false,
            width: 120,
            headerAlign: "center",
            renderHeader: (params) => (
                <strong className={classes.rowText} >Job Type</strong>
            ),
            renderCell: (params) => (
                <>
                    <span className={classes.rowText}>{params?.row?.placement_type}</span>
                </>
            ),
        },

        {
            field: "link",
            // type: "text",
            width: 150,
            headerAlign: "center",
            sortable: false,
            renderHeader: (params) => (
                <strong className={classes.rowText}>Offer Letter</strong>
            ),
            renderCell: (params) => (
                <span className={classes.rowText}>
                    <Button
                        size="small"
                        color='primary'
                        variant="contained"
                        disabled={!params?.row?.offer_letter}
                        onClick={() => {
                            window.open(params?.row?.offer_letter, "_blank");
                        }}
                    >
                        View
                    </Button>
                </span>
            ),
        },
    ]



    const handleClickClose = () => {
        // setOffersManageStudnetssList([]);
        handleClose();
    };


    return (
        <div>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                maxWidth={1500}
                // fullWidth={"lg"}
                PaperProps={{
                    style: {
                        width: "960px",
                        height: "500px",
                        borderRadius: "8px",
                        backgroundColor: "#ffff",
                    },
                }}
                onClose={handleClickClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClickClose}
                >
                     Offer letters 
                </BootstrapDialogTitle>
                <Typography variant="body2" sx={{ color: "#6C757D", pl: 3.5, mt: -2 }}>
                    {/* {data?.job_title} */}
                </Typography>
                <DialogContent>
                    <Box sx={{}}>
                        <Grid container spacing={2}>
                            <Grid md={12} sx={{ display: "grid" }}>
                                <CompanyTable
                                    loading={loading}
                                    list={rowData.length ? rowData :  []}
                                    columns={columns}
                                />
                                {/* <MainTable
                                    checkboxSelection={false}
                                    columns={TotalOffersManageStudnetss}
                                    rows={OffersManageStudnetssList?.length ? OffersManageStudnetssList : []}
                                    loading={OffersManageStudnetssListLoading}
                               
                                /> */}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );

}

export default OffersManageStudents