import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CreateAccount from "./CreateAccount";
import OtpForm from "./OtpForm";
import YourInformation from "./YourInformation";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepperRoot: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    [theme.breakpoints.up("xs")]: {
      maxHeight: "100dvh",
      overflowY: "scroll",
    },
  },
}));

export default function FormStepper({
  activeForm,
  setActiveForm,
  handleClose,
  setLoginSucessful,
  job_id,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [email, setEmail] = useState("");
  const [prefilDetails, setPrefilDetails] = useState({
    name: "",
    phone: null,
    enrollmentId: "",
    collegePasscode: "",
    degree: null,
    course: null,
    resume: null,
  });
  const [loginDetails, setLoginDetails] = useState({
    studentUser: false,
    educationUser: false,
    actionType: "",
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    return ["Enter your email adress", "Verify Email Id", "Your Information"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <CreateAccount
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            activeForm={activeForm}
            setActiveForm={setActiveForm}
            email={email}
            setEmail={setEmail}
            loginDetails={loginDetails}
            setLoginDetails={setLoginDetails}
          />
        );
      case 1:
        return (
          <OtpForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            email={email}
            setEmail={setEmail}
            loginDetails={loginDetails}
            setLoginDetails={setLoginDetails}
            prefilDetails={prefilDetails}
            setPrefilDetails={setPrefilDetails}
            handleClose={handleClose}
            setLoginSucessful={setLoginSucessful}
          />
        );
      case 2:
        return (
          <YourInformation
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            email={email}
            loginDetails={loginDetails}
            setLoginDetails={setLoginDetails}
            prefilDetails={prefilDetails}
            setPrefilDetails={setPrefilDetails}
            handleClose={handleClose}
            setLoginSucessful={setLoginSucessful}
            job_id={job_id}
          />
        );
      default:
        return <CreateAccount />;
    }
  }

  return (
    <div className={classes.stepperRoot}>
      <Typography
        variant="h3"
        style={{ textAlign: "center", fontWeight: 600, paddingTop: 20 }}
      >
        Create an account
      </Typography>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ width: "100%" }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div
        style={{
          backgroundColor: "#fff",
          padding: 20,
          width: "100%",
        }}
      >
        {getStepContent(activeStep)}
      </div>
    </div>
  );
}
