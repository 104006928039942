// import React from "react";
import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AccountSettingUpdateAPI } from "../../api/AccountSetting/action";
import { ChangeCurrentPasswordAPI } from "../../api/ForgotPassword/action";
import { collegeUserDetailSelectorFn } from "../../api/SignIn/selector";
import Oval from "../../assets/png/oval.png";
import Editor from "../../components/Editor";
import ImageCropperDialog from "../../components/ImageCropper/ImageCropperDialog";
import { passwordRegex } from "../../utility/regex";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "30px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  button: {
    borderRadius: "8px",
    paddingLeft: "100px",
    display: "flex",
    marginRight: "12px",
    paddingRight: "100px",
    boxShadow: "none",
    height: "48px",
    marginTop: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textbutton: {
    padding: "0px",
  },
  text: {
    fontWeight: "bold",
    marginTop: "5px",
  },
  btn: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    height: "48px",
    marginTop: "50px",
    borderRadius: "8px",
    margin: theme.spacing(3, 0, 2),
    boxShadow: "none",
  },
  paper2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  terms: {
    color: theme.palette.primary.main,
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  fileInput: {
    display: "none",
  },
  cameraIcon: {
    color: theme.palette.primary.main,
    fontSize: "20px",
  },
  // fixedPosition: {
  //   position: "fixed",
  //   left: "59%",
  //   // maxHeight: 650,
  //   //overflowY: "scroll",
  //   display: "flex",
  //   alignItems: "flex-end",
  //   flexDirection: "column",
  //   width: "40%",
  //   marginTop: "5.5%",
  // },
  // "@media only screen and (min-width: 1700px)": {
  //   fixedPosition: {
  //     marginTop: "5.5%",
  //     // position: "fixed",
  //     height: "400px",
  //     left: "57%",
  //     overflowY: "scroll",
  //     width: "40%",
  //   },
  // },
  fixedPosition: {
    position: "fixed",

    "@media only screen and (max-width: 2000px)": {
      marginTop: "5.5%",
      // position: "fixed",
      // height: "400px",
      left: "57%",
      overflowY: "scroll",
      width: "40%",
    },
    "@media only screen and (max-width: 1700px)": {
      marginTop: "5.5%",
      //height: "450px",
      left: "60%",
      overflowY: "scroll",
      width: "38%",
    },
    "@media only screen and (max-width: 1500px)": {
      marginTop: "7%",
      // position: "fixed",
      height: "500px",
      left: "60%",
      overflowY: "scroll",
      width: "38%",
    },
    "@media only screen and (max-width: 1200px)": {
      marginTop: "8%",
      // position: "fixed",
      height: "400px",
      left: "60%",
      overflowY: "scroll",
      width: "38%",
    },
  },
}));

export default function AccountSetting() {
  const classes = useStyles();
  const alert = useAlert();
  const userDetail = useSelector(collegeUserDetailSelectorFn);
  const [state, setState] = useState({
    password: null,
    confPassword: null,
    currentPassword: null,
  });
  const [errors, setErrors] = useState({
    password: null,
    confPassword: null,
    currentPassword: null,
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [previewImage, setPreviewImage] = useState(null);

  const fileInputRefNew = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [signature, setSignature] = useState("");
  const [userRole, setUserRole] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  // const user_id = userDetail.
  useEffect(() => {
    if (userDetail) {
      setName(userDetail?.name);
      setPhone(userDetail?.phone);
      setEmail(userDetail?.email);
      setDesignation(userDetail?.designation);
      setSignature(userDetail?.signature);
      setUserRole(userDetail?.user_role_name);
      setPreviewImage(userDetail?.profile_image);
    }
  }, [userDetail]);

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    passwordConf: "",
    showPasswordConf: false,
    currentPassword: "",
    showCurrentPassword: false,
  });

  const validateData = () => {
    var password_error = null;
    var conf_password_error = null;
    var current_password_error = null;
    var is_valid = true;
    if (!state.currentPassword) {
      current_password_error = "Current Password field cannot be left blank";
      is_valid = false;
    }
    if (!state.password) {
      password_error = "Password field cannot be left blank";
      is_valid = false;
    }
    if (state.password && !passwordRegex.test(state.password)) {
      password_error = "Please enter a valid Password 6 digits long";
      is_valid = false;
    }
    if (!state.confPassword) {
      conf_password_error = "Confirm Password field cannot be left blank";
      is_valid = false;
    }
    if (state.confPassword && state.password && state.confPassword !== state.password) {
      conf_password_error = "This field doesn't match with Password field";
      is_valid = false;
    }
    setErrors({
      ...errors,
      confPassword: conf_password_error,
      password: password_error,
      currentPassword: current_password_error,
    });
    return is_valid;
  };

  const submitData = () => {
    if (validateData()) {
      var data = {
        password: state.currentPassword,
        new_password: state.password,
      };
      dispatch(ChangeCurrentPasswordAPI(data, alert, setLoading, setState));
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPasswordConfirm = () => {
    setValues({ ...values, showPasswordConf: !values.showPasswordConf });
  };

  const handleClickShowCurrentPassword = () => {
    setValues({ ...values, showCurrentPassword: !values.showCurrentPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fileInputClickedNew = (e) => {
    fileInputRefNew.current.click();
  };

  function validateChange() {
    if (!name) {
      alert.error("Please enter correct name");
      return false;
    } else if (!phone || String(phone).length !== 10) {
      alert.error("Please enter correct phone number");
      return false;
    } else if (!designation) {
      alert.error("Please enter correct designation");
      return false;
    }
    return true;
  }

  const updateAccount = () => {
    if (validateChange()) {
      var new_file;
      var isChanged = false;
      var formData = new FormData();
      if (name !== userDetail.name) {
        formData.append("name", name);
        isChanged = true;
      }
      if (signature !== userDetail?.signature) {
        formData.append("signature", signature);
        isChanged = true;
      }
      if (phone !== userDetail.phone) {
        formData.append("phone", phone);
        isChanged = true;
      }
      if (designation !== userDetail.designation) {
        formData.append("designation", designation);
        isChanged = true;
      }
      if (previewImage !== userDetail.profile_image && selectedImage) {
        new_file = dataURLtoFile(previewImage, selectedImage?.name);
        formData.append("profile_pic", new_file);
        isChanged = true;
      }
      if (!isChanged) {
        alert.error("No changes made");
      } else {
        dispatch(AccountSettingUpdateAPI(formData, setLoadingProfile, alert, userDetail.user_id, location, history, new_file));
      }
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setProfilePic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      handleClickOpen();
    }
  };

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">Account Settings</Typography>

          <Typography variant="body2" color="secondary">
            Manage your Account
          </Typography>
        </Grid>
        <br />
        <Grid item xs={6} md={6} sm={6}>
          <Paper className={classes.paper}>
            <div
              style={{
                display: "flex",
                width: "90%",
                flexDirection: "column",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <ImageCropperDialog
                  open={open}
                  setOpen={setOpen}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  profilePic={profilePic}
                  setProfilePic={setProfilePic}
                  setPreviewImage={setPreviewImage}
                />
                <div>
                  <input
                    ref={fileInputRefNew}
                    className={classes.fileInput}
                    type="file"
                    id="file"
                    onChange={(e) => {
                      onSelectFile(e);
                    }}
                  />
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    style={{ marginBottom: 20 }}
                    badgeContent={
                      <IconButton
                        style={{
                          background: "#fff",
                          padding: 6,
                          boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                          border: "solid 1px #f1f1f1",
                        }}
                        onClick={(e) => fileInputClickedNew(e)}
                      >
                        <PhotoCameraIcon className={classes.cameraIcon} />
                      </IconButton>
                    }
                  >
                    <Avatar alt="Travis Howard" style={{ height: "100px", width: "100px" }} src={previewImage} />
                  </Badge>
                </div>
              </div>

              <TextField
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                label="Name"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />

              <TextField
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                label="Email"
                name="email"
                value={email}
                disabled
              />

              <TextField
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                label="Contact Number"
                name="phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />

              <TextField
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                label="Designation"
                name="designation"
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
              />

              <TextField
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                fullWidth
                label="User Role"
                name="user_role_name"
                value={userRole}
                disabled
              />
              <Typography variant="h6" style={{ marginTop: "10px", marginBottom: "20px" }}>
                Signature
              </Typography>

              <Editor state={signature} setState={setSignature} desc={userDetail?.signature} />
              <br />
              <br />
              <div className={classes.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ minWidth: "300px" }}
                  onClick={() => {
                    if (!loadingProfile) updateAccount();
                  }}
                >
                  {loadingProfile ? <CircularProgress className={classes.circularProgress} size={14} /> : <>Save Changes</>}
                </Button>
              </div>
            </div>
          </Paper>
        </Grid>

        <Grid
          item
          xs={6}
          md={6}
          sm={6}
          // style={{
          //   position: "fixed",
          //   left: "57%",
          //   width: "40%",
          //   marginTop: "5%",
          // }}
          className={classes.fixedPosition}
        >
          <Paper className={classes.paper} style={{ maxHeight: "90%", overflowY: "scroll" }}>
            <div
              style={{
                display: "flex",
                width: "80%",
                flexDirection: "column",
                maxHeight: "80%",
                overflowY: "scroll",
              }}
            >
              <Typography variant="h6" style={{ marginTop: "10px", marginBottom: "10px" }}>
                Change Password
              </Typography>

              <form className={classes.form} noValidate>
                <FormControl className={clsx(classes.margin, classes.textField, classes.form)} variant="outlined" error={errors.password ? true : false}>
                  <InputLabel className={classes.floatingLabelFocusStyle}>Current Password</InputLabel>
                  <OutlinedInput
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    id="outlined-adornment-password"
                    type={values.showCurrentPassword ? "text" : "password"}
                    value={state.currentPassword}
                    onChange={(e) => {
                      setState({ ...state, currentPassword: e.target.value });
                      setErrors({ ...errors, currentPassword: null });
                    }}
                    label="Current Password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowCurrentPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {values.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  <FormHelperText id="standard-weight-helper-text">{errors.currentPassword}</FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl className={clsx(classes.margin, classes.textField, classes.form)} variant="outlined" error={errors.password ? true : false}>
                  <InputLabel className={classes.floatingLabelFocusStyle}>New Password</InputLabel>
                  <OutlinedInput
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={state.password}
                    onChange={(e) => {
                      setState({ ...state, password: e.target.value });
                      setErrors({ ...errors, password: null });
                    }}
                    label="New Password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  <FormHelperText id="standard-weight-helper-text">{errors.password}</FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl className={clsx(classes.margin, classes.textField, classes.form)} variant="outlined" error={errors.confPassword ? true : false}>
                  <InputLabel className={classes.floatingLabelFocusStyle}>Confirm Password</InputLabel>
                  <OutlinedInput
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    label="Confirm Password"
                    id="outlined-adornment-password"
                    type={values.showPasswordConf ? "text" : "password"}
                    value={state.confPassword}
                    onChange={(e) => {
                      setState({ ...state, confPassword: e.target.value });
                      setErrors({ ...errors, confPassword: null });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPasswordConfirm} onMouseDown={handleMouseDownPassword} edge="end">
                          {values.showPasswordConf ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  <FormHelperText id="standard-weight-helper-text">{errors.confPassword}</FormHelperText>
                </FormControl>
                <Grid container>
                  <Grid item xs>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",

                          alignItems: "baseline",
                        }}
                      >
                        <img src={Oval} height="7px" width="7px" />
                        <div style={{ marginLeft: "10px" }}>
                          <Typography
                            variant="body2"
                            color="secondary"
                            //  style={{ marginTop: "10px" }}
                          >
                            Password must be at least 8 characters long and contain at least one uppercase, one lowercase and one digit.
                          </Typography>
                        </div>
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          width: "100%",

                          alignItems: "baseline",
                        }}
                      >
                        <img src={Oval} height="7px" width="7px" />
                        <div style={{ marginLeft: "10px" }}>
                          <Typography
                            variant="body2"
                            color="secondary"
                            //  style={{ marginTop: "10px" }}
                          >
                            Your new password must be different from previous used password.
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* <Link to="/reset-your-password" style={{ textDecoration: "none" }}> */}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    if (!loading) submitData();
                  }}
                >
                  {loading ? <CircularProgress className={classes.circularProgress} size={14} /> : <>Change Password</>}
                </Button>
                {/* </Link> */}
              </form>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {/* </Layout> */}
    </>
  );
}
