import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const columns = [
  {
    field: "enrollmentid",
    headerName: "Enrollment ID",
    type: "Enrollment Id",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Enrollment ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.enrollment_id}
      </span>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "65px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Link
          to={`/student-profile/${params.row.user_id}`}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" style={{ fontSize: "14px" }}>
            {params.row.name}
          </Typography>
        </Link>
      </span>
    ),
  },

  {
    field: "degree",
    headerName: "Degree",

    width: 160,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "40px",
        }}
      >
        Degree
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.degree.name}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },

  {
    field: "course",
    type: "text",
    width: 130,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "27px",
        }}
      >
        Course
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.course.name}
      </span>
    ),
  },
];

export default function ListStudents({ data, onSelect }) {
  return (
    <div style={{ height: 300, width: "100%" }}>
      {data !== undefined ? (
        <DataGrid
          style={{
            borderRadius: "8px",
            backgroundColor: "#fff",
            boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
            border: "none",
          }}
          rows={data}
          onSelectionModelChange={onSelect}
          columns={columns}
          pageSize={5}
          checkboxSelection
          disableSelectionOnClick
        />
      ) : null}
    </div>
  );
}
