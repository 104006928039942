import { createSelector } from "reselect";

const domainSelector = (state) => state.domainData;

export const themeSelectorFn = createSelector(domainSelector, (domainData) => domainData?.themeDetails);
export const userLoggedInSelectorFn = createSelector(domainSelector, (domainData) => domainData?.isLoggedIn);
export const isEducationGroupFn = createSelector(domainSelector, (domainData) => domainData?.isEducationGroup);
export const isGradeSystemFn = createSelector(domainSelector, (domainData) => domainData?.isGradeSystem);
export const collegeDetailsSelectorFn = createSelector(domainSelector, (domainData) => domainData?.collegeDetails);
export const mainDetailsFn = createSelector(domainSelector, (domainData) => domainData?.allData);
