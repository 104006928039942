import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ReactEcharts from "echarts-for-react";
import React, { useEffect, useState } from "react";

export default function Graph({
  popUp = false,
  Heading = "",
  title = "Application Status",
  legendData = [],
  data,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      //justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    paper: {
      padding: theme.spacing(2),
      //margin: "auto",
      backgroundColor: "#fff",
      boxShadow: popUp ? "" : "rgb(0 0 0 / 10%) 0px 8px 24px",
      borderRadius: "8px",
      width: popUp ? "100%" : "100%",
    },
  }));
  const classes = useStyles();

  const option = {
    // title: {
    //   text: title,
    // },
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c}",
    },
    // toolbox: {
    //   feature: {
    //     dataView: { readOnly: false },
    //     // restore: {  },
    //     saveAsImage: {},
    //   },
    // },
    legend: {
      // orient: "vertical",
      // left: "left",
      data: ["Applied", "Eligible", "Present", "Hired"],
    },

    series: [
      {
        name: "Funnel",
        type: "funnel",
        left: "10%",
        top: 60,
        bottom: 60,
        width: "80%",
        min: 0,
        max: 100,
        minSize: "10%",
        maxSize: "200%",
        sort: "descending",
        gap: 0,

        label: {
          show: true,
          position: "outside" /* popUp ? "outside" : "inside" */,
          formatter: "{b} ({c})",
        },

        labelLine: {
          length: 50,
          lineStyle: {
            width: 1,
            type: "solid",
          },
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 1,
        },
        emphasis: {
          label: {
            fontSize: 18,
          },
        },

        data: data,
      },
    ],
  };
  return (
    <>
      <br />
      <div className={classes.paper}>
        <Typography
          variant="h6"
          style={{ textAlign: popUp ? "center" : "left" }}
        >
          {title}
        </Typography>

        <br />

        <div className={classes.root}>
          <ReactEcharts option={option} />
        </div>
      </div>
    </>
  );
}
