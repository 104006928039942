import React, { useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Dialog, Button, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { db } from "../../../utility/firebaseConfig";
import { getDoc, updateDoc, doc } from "@firebase/firestore";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { useHistory, useLocation } from "react-router-dom";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  collegeUserDetailSelectorFn,
} from "../../../api/SignIn/selector";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import config from "../../../config";
import { chatstatus } from "../../../api/Chat/action";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    marginRight: "20px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    paddingRight: "40px",
    display: "flex",
    paddingLeft: "40px",
    justifyContent: "flex-start",
    paddingBottom: "35px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  add: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "20px",
    paddingBottom: "10px",
    width: "450px",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    //alignItems: "center",
  },
  cycle: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function CreateGroup({ selected, selected_member, currentTab }) {
  const params = useParams();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [groupName, setGroupName] = React.useState("");
  const theme = useTheme();

  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const collegeUserDetail = useSelector(collegeUserDetailSelectorFn);

  const college_id = useSelector(collegeIdFn);

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createChatgroup = () => {
    if (groupName.length > 0) {
      const userTemp = [];
      const user = [];
      selected_member.map((item) => {
        const temp3 = {
          ...item,
          newId: item.id,
          id: item.user_id,
          is_admin: true,
        };
        userTemp.push(temp3);
      });

      selected.map((item) => {
        const temp2 = {
          ...item,
          newId: item.id,
          id: item.user_id,
          is_admin: false,
        };
        userTemp.push(temp2);
      });
      selected.map((item) => {
        const temp4 = item.user_id;
        user.push(temp4);
      });

      const data = {
        name: groupName,
        groupTab: currentTab,
        id: Math.floor(Math.random() * 100000),
        latestMessage: {
          text: `You have joined the room ${groupName}.`,
          createdAt: new Date().getTime(),
        },
        users: userTemp,
        collegeid: parseInt(college_id),
        group_created_at: new Date().getTime(),
      };
      const payload = {
        group_id: data.id,
        group_name: groupName,
        type: "ADD_GROUP",
        user_ids: user,
      };
      dispatch(chatstatus(alert, history, location, payload));
      const docRef = addDoc(
        collection(db, config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV"),
        data
      ).then((res) =>
        addDoc(collection(res, "MESSAGES"), {
          text: `You have joined the room ${groupName}.`,
          createdAt: new Date().getTime(),
          system: true,
        })
      );
    }
    handleClose();
    history.push("/chat-room");
  };

  const updateGroupChat = async () => {
    const threadRef = doc(
      db,
      config.prod ? "CHATGROUP_PROD" : "CHATGROUP_DEV",
      params.thread_id
    );
    const docSnap = await getDoc(threadRef);
    const temp = docSnap.data().users;
    const studentTemp = [];
    const adminTemp = [];
    const user = [];
    // temp.map((item) => {
    //   if (item.is_admin) {
    //     adminTemp.push(item);
    //   } else {
    //     studentTemp.push(item);
    //   }
    // });

    selected.map((item) => {
      const temp4 = item.user_id;
      user.push(temp4);
    });

    selected_member.map((item) => {
      const temp3 = {
        ...item,
        newId: item.id,
        id: item.user_id,
        is_admin: true,
      };
      adminTemp.push(temp3);
    });

    selected.map((item) => {
      const temp2 = {
        ...item,
        newId: item.id,
        id: item.user_id,
        is_admin: false,
      };
      studentTemp.push(temp2);
    });
    await updateDoc(threadRef, {
      users: [...adminTemp, ...studentTemp],
    });
    const payload = {
      group_id: docSnap.data().id,
      group_name: docSnap.data().name,
      type: "ADD_GROUP",
      user_ids: user,
    };
    dispatch(chatstatus(alert, history, location, payload));
    history.push("/chat-room");
  };

  return (
    <div>
      {params.thread_id ? (
        <Button
          onClick={updateGroupChat}
          variant="contained"
          className={classes.cycle}
          color="primary"
          style={{
            borderRadius: "8px",
            boxShadow: "none",
            paddingLeft: "70px",
            paddingRight: "70px",
          }}
        >
          Update Group
        </Button>
      ) : (
        <Button
          onClick={() => {
            selected?.length > 0 || selected_member?.length > 0
              ? handleClickOpen()
              : alert.error("Select Atleast One Person");
          }}
          variant="contained"
          className={classes.cycle}
          color="primary"
          style={{
            borderRadius: "8px",
            boxShadow: "none",
            paddingLeft: "70px",
            paddingRight: "70px",
          }}
        >
          Create Group
        </Button>
      )}

      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.add}>
          <div
            style={{
              // padding: "10px",
              // paddingLeft: "20px",
              display: "flex",
              marginBottom: "25px",
              marginLeft: "10px",
              justifyContent: "flex-start",
              textAlign: "left",
            }}
          >
            <Typography variant="h6">Create Group Name</Typography>
          </div>

          <TextField
            variant="outlined"
            onChange={(e) => setGroupName(e.target.value)}
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            // fullWidth
            style={{ width: "100%", marginBottom: "20px" }}
            label="Enter Group Name"
            name="Amazon | Software Developer PC 2019"
          />
        </div>

        <div className={classes.btngrid}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            autoFocus
            onClick={createChatgroup}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
