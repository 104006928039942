import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  collegeGroupListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import {
  CallCollegeAPI,
  EDGTableCompanyAPI,
} from "../../../api/University/APIs/action";
import GroupListDropdown from "../../../components/Input/DropDownButton/GroupListDropdown";
import EjdLinkTable from "../../../components/Table/UniversityTable/EjdLinkTable";
import { BackendBaseApi } from "../../../constants/constants";
import { generateCsvForParams } from "../../../utility/commaSeparatedStrings";
export default function UniversityCompanyLink() {
  const location = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  const handleSubmit = (id, link) => {
    dispatch(CallCollegeAPI(id, alert, history, location, user_details, link));
  };

  const columns = [
    // { field: "id", headerName: "No", width: 100 },

    // {
    //   field: "id",
    //   type: "text",
    //   width: 250,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "65px",
    //       }}
    //     >
    //       College Code
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {params.row.id}
    //     </span>
    //   ),
    // },

    {
      field: "college_name",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          College Name
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            cursoe: "pointer",
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: "14px" }}
            onClick={() => {
              handleSubmit(params?.row?.college_id, "/company-erf");
            }}
          >
            {params.row.college_name}
          </Typography>
        </span>
      ),
    },

    {
      field: "create_date",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "40px",
          }}
        >
          Created Date
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.create_date}
        </span>
      ),
    },
    {
      field: "link",
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "50px",
          }}
        >
          e-Registration Link
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
          }}
        >
          <CopyToClipboard
            text={params?.row?.form_link}
            onCopy={() => alert.success("Link Copied!")}
          >
            <Button color="primary">Copy</Button>
          </CopyToClipboard>
        </span>
      ),
    },
    {
      field: "placed",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Action
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
          }}
        >
          <a
            href={params?.row?.form_link}
            target="blank"
            style={{ textDecoration: "none" }}
          >
            <Button color="primary">View</Button>
          </a>
        </span>
      ),
    },
  ];

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `group_id=${selectedGroup.id}`;
      else
        return `group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      var url = `${
        BackendBaseApi.SANCHALAN
      }api/university/company_link/?${checkSelectedGroup()}`;
      dispatch(EDGTableCompanyAPI(setLoading, alert, history, location, url));
    }
  }, [selectedGroup]);

  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  useEffect(() => {
    if (collegeGroupList?.length > 0 && isCollegeGroup) {
      setNewCollegeGroupList(
        [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
      );
    } else setNewCollegeGroupList(collegeGroupList);
  }, [collegeGroupList?.length]);
  const data = useSelector((state) =>
    state?.EDGCommon?.edgTableCompany ? state?.EDGCommon?.edgTableCompany : []
  );

  return (
    <>
      {/* <UniversityLayout> */}
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      <br />
      <Typography variant="h2">Invite Company Links</Typography>
      <div style={{ marginTop: "20px" }}>
        <EjdLinkTable columns={columns} data={data} loading={loading} />
      </div>
      {/* </UniversityLayout> */}
    </>
  );
}
