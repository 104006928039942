import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Grid,
  InputLabel,
  Button,
  makeStyles,
  TextField,
  useTheme,
  Box,
  LinearProgress,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useAlert } from "react-alert";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { CircularProgress } from "@mui/material";
import { PlayCircleFilledRounded } from "@material-ui/icons";

const uploadIcon = (
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="44" height="44" rx="22" fill="#F5F5F5" />
    <path
      d="M19.4995 27.75C19.0895 27.75 18.7495 27.41 18.7495 27V22.81L18.0295 23.53C17.7395 23.82 17.2595 23.82 16.9695 23.53C16.6795 23.24 16.6795 22.76 16.9695 22.47L18.9695 20.47C19.1795 20.26 19.5095 20.19 19.7895 20.31C20.0695 20.42 20.2495 20.7 20.2495 21V27C20.2495 27.41 19.9095 27.75 19.4995 27.75Z"
      fill="#00203F"
    />
    <path
      d="M21.4995 23.7499C21.3095 23.7499 21.1195 23.6799 20.9695 23.5299L18.9695 21.5299C18.6795 21.2399 18.6795 20.7599 18.9695 20.4699C19.2595 20.1799 19.7395 20.1799 20.0295 20.4699L22.0295 22.4699C22.3195 22.7599 22.3195 23.2399 22.0295 23.5299C21.8795 23.6799 21.6895 23.7499 21.4995 23.7499Z"
      fill="#00203F"
    />
    <path
      d="M25.5 32.75H19.5C14.07 32.75 11.75 30.43 11.75 25V19C11.75 13.57 14.07 11.25 19.5 11.25H24.5C24.91 11.25 25.25 11.59 25.25 12C25.25 12.41 24.91 12.75 24.5 12.75H19.5C14.89 12.75 13.25 14.39 13.25 19V25C13.25 29.61 14.89 31.25 19.5 31.25H25.5C30.11 31.25 31.75 29.61 31.75 25V20C31.75 19.59 32.09 19.25 32.5 19.25C32.91 19.25 33.25 19.59 33.25 20V25C33.25 30.43 30.93 32.75 25.5 32.75Z"
      fill="#00203F"
    />
    <path
      d="M32.5 20.75H28.5C25.08 20.75 23.75 19.42 23.75 16V12C23.75 11.7 23.93 11.42 24.21 11.31C24.49 11.19 24.81 11.26 25.03 11.47L33.03 19.47C33.24 19.68 33.31 20.01 33.19 20.29C33.07 20.57 32.8 20.75 32.5 20.75ZM25.25 13.81V16C25.25 18.58 25.92 19.25 28.5 19.25H30.69L25.25 13.81Z"
      fill="#00203F"
    />
  </svg>
);

const uploadedSvg = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4993 18.9582H7.49935C2.97435 18.9582 1.04102 17.0248 1.04102 12.4998V7.49984C1.04102 2.97484 2.97435 1.0415 7.49935 1.0415H11.666C12.0077 1.0415 12.291 1.32484 12.291 1.6665C12.291 2.00817 12.0077 2.2915 11.666 2.2915H7.49935C3.65768 2.2915 2.29102 3.65817 2.29102 7.49984V12.4998C2.29102 16.3415 3.65768 17.7082 7.49935 17.7082H12.4993C16.341 17.7082 17.7077 16.3415 17.7077 12.4998V8.33317C17.7077 7.9915 17.991 7.70817 18.3327 7.70817C18.6744 7.70817 18.9577 7.9915 18.9577 8.33317V12.4998C18.9577 17.0248 17.0243 18.9582 12.4993 18.9582Z"
      fill="#353535"
    />
    <path
      d="M18.3327 8.95841H14.9993C12.1493 8.95841 11.041 7.85007 11.041 5.00007V1.66674C11.041 1.41674 11.191 1.18341 11.4243 1.09174C11.6577 0.991739 11.9243 1.05007 12.1077 1.22507L18.7743 7.89174C18.9493 8.06674 19.0077 8.34174 18.9077 8.57507C18.8077 8.8084 18.5827 8.95841 18.3327 8.95841ZM12.291 3.17507V5.00007C12.291 7.15007 12.8493 7.70841 14.9993 7.70841H16.8243L12.291 3.17507Z"
      fill="#353535"
    />
    <path
      d="M10.834 11.4585H5.83398C5.49232 11.4585 5.20898 11.1752 5.20898 10.8335C5.20898 10.4918 5.49232 10.2085 5.83398 10.2085H10.834C11.1757 10.2085 11.459 10.4918 11.459 10.8335C11.459 11.1752 11.1757 11.4585 10.834 11.4585Z"
      fill="#353535"
    />
    <path
      d="M9.16732 14.7915H5.83398C5.49232 14.7915 5.20898 14.5082 5.20898 14.1665C5.20898 13.8248 5.49232 13.5415 5.83398 13.5415H9.16732C9.50898 13.5415 9.79232 13.8248 9.79232 14.1665C9.79232 14.5082 9.50898 14.7915 9.16732 14.7915Z"
      fill="#353535"
    />
  </svg>
);

const deleteicons = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="14" fill="#E3E3E3" />
    <path
      d="M21.4999 9.60745C21.4832 9.60745 21.4582 9.60745 21.4332 9.60745C17.0248 9.16578 12.6247 8.99911 8.26628 9.44078L6.56624 9.60745C6.21624 9.64078 5.9079 9.39078 5.87456 9.04077C5.84123 8.69077 6.09123 8.39076 6.43291 8.35743L8.13294 8.19076C12.5664 7.74075 17.0581 7.91575 21.5582 8.35743C21.8999 8.39076 22.1499 8.6991 22.1165 9.04077C22.0915 9.36578 21.8165 9.60745 21.4999 9.60745Z"
      fill="#00203F"
    />
    <path
      d="M11.0827 8.76609C11.0493 8.76609 11.016 8.76609 10.9743 8.75776C10.641 8.69942 10.4076 8.37441 10.466 8.04107L10.6493 6.94939C10.7827 6.14937 10.966 5.04102 12.9077 5.04102H15.0911C17.0411 5.04102 17.2244 6.19104 17.3495 6.95772L17.5328 8.04107C17.5911 8.38275 17.3578 8.70775 17.0244 8.75776C16.6828 8.81609 16.3578 8.58275 16.3078 8.24941L16.1244 7.16606C16.0078 6.44104 15.9828 6.29937 15.0994 6.29937H12.916C12.0327 6.29937 12.016 6.41604 11.891 7.15772L11.6993 8.24108C11.6493 8.54942 11.3827 8.76609 11.0827 8.76609Z"
      fill="#00203F"
    />
    <path
      d="M16.6747 22.9579H11.3246C8.41619 22.9579 8.29952 21.3495 8.20785 20.0495L7.66618 11.6577C7.64117 11.316 7.90785 11.016 8.24952 10.991C8.59953 10.9743 8.8912 11.2327 8.9162 11.5743L9.45788 19.9662C9.54955 21.2329 9.58288 21.7079 11.3246 21.7079H16.6747C18.4247 21.7079 18.4581 21.2329 18.5414 19.9662L19.0831 11.5743C19.1081 11.2327 19.4081 10.9743 19.7497 10.991C20.0914 11.016 20.3581 11.3077 20.3331 11.6577L19.7914 20.0495C19.6997 21.3495 19.5831 22.9579 16.6747 22.9579Z"
      fill="#00203F"
    />
    <path
      d="M15.383 18.374H12.6079C12.2662 18.374 11.9829 18.0907 11.9829 17.749C11.9829 17.4074 12.2662 17.124 12.6079 17.124H15.383C15.7246 17.124 16.008 17.4074 16.008 17.749C16.008 18.0907 15.7246 18.374 15.383 18.374Z"
      fill="#00203F"
    />
    <path
      d="M16.0828 15.041H11.916C11.5744 15.041 11.291 14.7577 11.291 14.416C11.291 14.0744 11.5744 13.791 11.916 13.791H16.0828C16.4244 13.791 16.7078 14.0744 16.7078 14.416C16.7078 14.7577 16.4244 15.041 16.0828 15.041Z"
      fill="#00203F"
    />
  </svg>
);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

function YourInformation({
  activeStep,
  setActiveStep,
  email,
  loginDetails,
  setLoginDetails,
  prefilDetails,
  setPrefilDetails,
  handleClose,
  setLoginSucessful,
  job_id,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [prefill, setPrefill] = useState(false);
  const [file, setFile] = useState(null);
  const [degreeList, setDegreeList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);

  const handleFile = (e) => {
    const res = e.target.files[0];
    setFile(res);
  };
  const handleRemoveFile = () => {
    setFile(null);
    setPrefill(false);
    if (prefilDetails?.resume)
      setPrefilDetails({ ...prefilDetails, resume: null });
  };

  function checkFileType(filePath) {
    // Extract the file extension
    const fileExtension = filePath.split(".").pop().toLowerCase();

    // Check if the file extension matches PDF, DOC, or DOCX
    if (fileExtension === "pdf") {
      return "PDF";
    } else if (fileExtension === "doc") {
      return "DOC";
    } else if (fileExtension === "docx") {
      return "DOCX";
    } else {
      return "Unknown";
    }
  }

  const validate = () => {
    let error = false;
    if (!(prefilDetails?.name && prefilDetails?.name?.indexOf(" "))) {
      error = true;
      alert.error("Enter your  Full Name");
    } else if (String(prefilDetails?.phone)?.length < 10) {
      error = true;
      alert.error("Enter valid Phone Number");
    } else if (!prefilDetails?.enrollmentId) {
      error = true;
      alert.error("Enter valid enrollment ID");
    } else if (!prefilDetails?.collegePasscode) {
      error = true;
      alert.error("Enter valid College Passcode");
    } else if (!prefilDetails?.degree?.degree_id) {
      error = true;
      alert.error("Select Valid  Degree");
    } else if (!prefilDetails?.course?.specialization_id) {
      error = true;
      alert.error("Select Valid  Course");
    } else if (!file && !prefilDetails?.resume) {
      error = true;
      alert.error("Upload your Resume");
    } else if (file.size > 10000000) {
      error = true;
      alert.error("Upload your Resume within the specefic size");
    } else if (checkFileType(file?.name) === "Unknown") {
      error = true;
      alert.error("Upload the Resume in the given File Type only");
    }

    return !error;
  };
  const handleApply = () => {
    if (validate()) {
      setLoading(true);
      const fd = new FormData();
      fd.append("name", prefilDetails?.name);
      fd.append("email", email);
      fd.append("phone", String(prefilDetails?.phone));
      fd.append("enrollment_id", prefilDetails?.enrollmentId);
      fd.append("college_passcode", prefilDetails?.collegePasscode);
      fd.append("degree", prefilDetails?.degree?.degree_id);
      fd.append("specialization", prefilDetails?.course?.specialization_id);
      fd.append("education", prefilDetails?.degree?.education_id);
      file && fd.append("resume", file);
      fd.append("job_id", job_id);

      axios
        .post(
          BackendBaseApi.PRAVESH + "api/saas/public_job/student-register/ ",
          fd,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setLoading(false);
          if (res?.data?.success) {
            alert.success(res?.data?.data?.message);
            handleClose();
            setLoginSucessful(true);
            window.localStorage.setItem("loginApplyJob", true);
            window.localStorage.setItem(
              "userDetails_first_name",
              res?.data?.data?.user_details?.first_name
            );
            window.localStorage.setItem(
              "userDetails_last_name",
              res?.data?.data?.user_details?.last_name
            );
          } else {
            alert.error(res?.data?.error);
          }
        })
        .catch((err) => {
          alert.error("something went wrong");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    axios
      .get(BackendBaseApi.SANCHALAN + "api/sandhi/college_courses/")
      .then((res) => {
        if (res?.data?.success) {
          let resultingArray = res?.data?.data
            ?.map((obj) => obj?.degrees)
            .reduce((acc, val) => acc.concat(val), []);
          setDegreeList(resultingArray);
        } else alert.error(res?.data?.error);
      })
      .catch((err) => {
        alert.error("something went wrong");
      });
  }, []);
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 15, fontWeight: 600 }}>
            Personal Information
          </InputLabel>
          <TextField
            label="Full Name"
            type="name"
            fullWidth
            variant="outlined"
            value={prefilDetails?.name}
            onChange={(e) =>
              setPrefilDetails({ ...prefilDetails, name: e.target.value })
            }
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email ID"
            type="email"
            fullWidth
            variant="outlined"
            defaultValue={email}
            disabled
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VerifiedIcon style={{ color: theme.palette.primary.main }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone Number"
            type="number"
            fullWidth
            value={prefilDetails?.phone}
            onChange={(e) =>
              setPrefilDetails({ ...prefilDetails, phone: e.target.value })
            }
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            style={{ marginTop: 10, marginBottom: 15, fontWeight: 600 }}
          >
            College Information
          </InputLabel>
          <TextField
            label="Enrollment Id / Roll No"
            type="name"
            fullWidth
            variant="outlined"
            value={prefilDetails?.enrollmentId}
            onChange={(e) =>
              setPrefilDetails({
                ...prefilDetails,
                enrollmentId: e.target.value,
              })
            }
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="College Passcode"
            fullWidth
            value={prefilDetails?.collegePasscode}
            onChange={(e) =>
              setPrefilDetails({
                ...prefilDetails,
                collegePasscode: e.target.value,
              })
            }
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
          />
          <Typography variant="body2" style={{ color: "#6C757D" }}>
            {" "}
            Note: If you do not have the Enrollment id and college passcode,
            please check with the college administration.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            style={{ marginTop: 10, marginBottom: 15, fontWeight: 600 }}
          >
            Education Information
          </InputLabel>
          <Autocomplete
            id="combo-box-demo"
            value={prefilDetails?.degree}
            onChange={(_, newValue) => {
              if (newValue) {
                setCoursesList(newValue?.specialization);
                setPrefilDetails({ ...prefilDetails, degree: newValue });
              } else {
                setCoursesList([]);
                setPrefilDetails({ ...prefilDetails, degree: "", course: "" });
              }
            }}
            options={degreeList?.length ? degreeList : []}
            getOptionLabel={(option) => option?.degree_name ?? ""}
            fullWidth
            renderOption={(option) => {
              return (
                <span style={{ fontSize: 13 }}>{`${option?.degree_name}`}</span>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Your Degree"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="combo-box-demo"
            disabled={!prefilDetails?.degree}
            value={prefilDetails?.course}
            onChange={(_, newValue) => {
              // if (newValue) {
              setPrefilDetails({ ...prefilDetails, course: newValue });
              // }
            }}
            options={coursesList?.length ? coursesList : []}
            getOptionLabel={(option) => option?.specialization_name ?? ""}
            fullWidth
            renderOption={(option) => {
              return (
                <span
                  style={{ fontSize: 13 }}
                >{`${option?.specialization_name}`}</span>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Your Course"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel style={{ marginTop: 15, fontWeight: 600 }}>
            Resume
          </InputLabel>
        </Grid>
        {(file || prefilDetails?.resume) && (
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <>
              <Box>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {file ? (
                    <div>
                      <Typography variant="body2">{file?.name}</Typography>
                    </div>
                  ) : prefilDetails?.resume ? (
                    <div>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(prefilDetails?.resume?.resume_link)
                        }
                      >
                        {prefilDetails?.resume?.resume_name}
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}

                  {(file || prefilDetails?.resume) && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={handleRemoveFile}
                    >
                      {deleteicons}
                    </div>
                  )}
                </div>
              </Box>
            </>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            style={{
              border: "1px dashed #CACACA",
              padding: 15,
              borderRadius: 10,
              textAlign: "center",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              inputRef.current.click();
            }}
          >
            {uploadIcon}

            <input
              ref={inputRef}
              type="file"
              onChange={handleFile}
              style={{ display: "none" }}
              accept=".doc, .docx, .pdf"
            />
            {!file && !prefill && !prefilDetails?.resume ? (
              <Typography
                variant="body1"
                color="primary"
                style={{ fontSize: 14, marginTop: 10 }}
                onClick={() => {
                  // inputRef.current.click();
                }}
              >
                Click to Upload
              </Typography>
            ) : (
              <Typography
                variant="body1"
                color="primary"
                style={{ fontSize: 14, marginTop: 10 }}
                onClick={() => {
                  handleRemoveFile();
                }}
              >
                Update Resume
              </Typography>
            )}
            {/* <div>
              <span
                style={{ fontSize: 14, textAlign: "center", color: "#6C757D" }}
              >
                {file?.name}
              </span>
              <LinearProgress variant="determinate" value={progress} />
            </div> */}
            <Typography
              variant="body1"
              style={{ fontSize: 14, color: "#6C757D", marginTop: 5 }}
            >
              Supported Format: doc, docs, pdf upto 10 MB
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ display: "grid" }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: "45px",
              boxShadow: "none",
              marginTop: "10px",
            }}
            onClick={handleApply}
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              " Sign up to apply"
            )}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default YourInformation;
