export const headerSampleFile = [
  { label: "company_name", key: "company_name" },
  { label: "company_webiste", key: "company_website" },
  { label: "contact_person_name", key: "contact_person_name" },
  { label: "email", key: "email" },

  { label: "phone", key: "phone" },
  { label: "designtion", key: "designation" },

  { label: "industry_name", key: "industry_name" },

  { label: "industry_type_name", key: "industry_type_name" },
];

export const dataRow = [
  {
    company_name: "",
    comapny_website: "",
    contact_person_name: "",
    email: "",
    phone: "",
    designation: "",
    industry_name: "",
    industry_type_name: "",
  },
];
