import {
  Button,
  Grid,
  Paper,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { DropzoneAreaBase } from "material-ui-dropzone";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { changeState } from "../../../action";
import { cityGet, stateGet } from "../../../api/CollegeProfile/action";
import { GET_THEME_DETAILS } from "../../../api/Domain/action";
import { themeSelectorFn } from "../../../api/Domain/selector";
import {
  collegeDetailSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  entityInfoSelectorFn,
} from "../../../api/SignIn/selector";
import {
  UniversityGet,
  UniversityPatch,
} from "../../../api/University/APIs/action";
import CustomCircularProgress from "../../../components/CircularProgressBar";
import CustomButtonCircularProgress from "../../../components/CircularProgressBar/index2";
import Editor from "../../../components/Editor";
import ColorPicker from "../../../components/Form/ColorPicker";
import ColorPickerSecondary from "../../../components/Form/ColorPicker/secondary";
import Calendar from "../../../components/Input/Calendar/index4";
import { GET_THEME_DETAILS as GET_THEME_DETAILS2 } from "../../../../src/action";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#fff",
    display: "flex",
    // justifyContent: "center",
    borderRadius: "8px",
    padding: "30px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  button: {
    borderRadius: "8px",
    paddingLeft: "60px",
    display: "flex",
    height: "48px",
    marginRight: "12px",
    paddingRight: "60px",
    boxShadow: "none",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textbutton: {
    padding: "0px",
  },
  text: {
    fontWeight: "bold",
    marginTop: "5px",
  },
  btn: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "flex-start",
  },
  formControl: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  customize: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function UniversitySetting() {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const entityInfo = useSelector(entityInfoSelectorFn);
  const [editor, setEditor] = useState();

  const [mediaLinks, setMediaLinks] = useState([
    { id: 1, Name: "LinkedIn", value: "" },
    { id: 2, Name: "Facebook", value: "" },
    { id: 3, Name: "Instagram", value: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [patchLoading, setPatchLoading] = useState(false);

  const collegeDetail = useSelector(collegeDetailSelectorFn);

  const theme_change = useSelector(themeSelectorFn);

  const [state, setState] = useState({
    displayColorPicker: false,
    color: theme_change?.main,
  });
  const [state1, setState1] = useState({
    displayColorPicker: false,
    color: theme_change?.secondary,
  });

  const isCollegeGroup = useSelector(isCollegeGroupFn);

  // const handleChange = event => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  const data = useSelector((state) => state?.EDGCommon?.edgProfile);

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };

  useEffect(() => {
    if (collegeDetail?.education_group_id) {
      dispatch(
        UniversityGet(
          setLoading,
          alert,
          history,
          location,
          collegeDetail?.education_group_id
        )
      );

      dispatch(stateGet(alert, history, location));
    }
  }, [collegeDetail]);

  const [collegeProfile, setCollegeProfile] = React.useState({
    about: "",
    banner: "",
    city: "",
    city_id: "",
    address: "",
    group_id: "",
    group_name: "",

    country: "",
    country_id: 1,
    year_founded: "",
    latitude: null,
    logo: "",
    longitude: null,

    primary_color: "",
    secondary_color: "",

    social_links: [],
    state: "",
    state_id: 12,

    website: "",
    logoData: "",
    bannerData: "",
    state_obj: null,
    city_obj: null,

    college_list: null,
    total_affiliated_college: "",
  });

  useEffect(() => {
    mediaLinks[0].value = data?.social_links[0]?.value;
    mediaLinks[1].value = data?.social_links[1]?.value;
    mediaLinks[2].value = data?.social_links[2]?.value;
  }, [data]);

  useEffect(() => {
    setCollegeProfile({
      ...collegeProfile,
      about: data?.about,
      city: data?.city,
      city_id: data?.city_id,
      address: data?.address,
      group_id: data?.group_id,
      group_name: data?.group_name,

      country: data?.country,
      country_id: 1,
      year_founded: moment(data?.year_founded),
      latitude: data?.latitude,
      logo: "",
      longitude: data?.longitude,

      primary_color: data?.primary_color,
      secondary_color: data?.secondary_color,

      social_links: data?.social_links,
      state: data?.state,
      state_id: data?.state_id,

      website: data?.website,
      logoData: data?.logo,
      bannerData: data?.banner,

      college_list: data?.college_list,
      state_obj: data?.state_id
        ? { state_id: data?.state_id, state: data?.state }
        : null,
      city_obj: data?.city_id
        ? { city_id: data?.city_id, city: data?.city }
        : null,
      total_affiliated_college: data?.total_affiliated_college,
    });
  }, [data]);

  useEffect(() => {
    if (collegeProfile?.state_id) {
      dispatch(cityGet(alert, history, location, collegeProfile?.state_id));
    }
  }, [collegeProfile.state_id]);

  useEffect(() => {
    setCollegeProfile({ ...collegeProfile, about: editor });
  }, [editor]);

  const city = useSelector((state) => state?.collegeProfile?.cityData);
  const stateList = useSelector((state) =>
    state?.collegeProfile?.stateData ? state?.collegeProfile?.stateData : []
  );

  const [loadingPost, setLoadingPost] = useState(false);

  const save = () => {
    const formDataChanged = new FormData();

    if (!(collegeProfile.group_name == data?.group_name))
      formDataChanged.append("group_name", collegeProfile.group_name);

    if (!(collegeProfile.about == data?.about))
      formDataChanged.append("about", collegeProfile.about);

    if (!(collegeProfile.address == data?.address))
      formDataChanged.append("address", collegeProfile.address);

    if (
      !(
        moment(collegeProfile.year_founded).format("yyyy-MM-DD") ==
        moment(data?.year_founded).format("yyyy-MM-DD")
      )
    )
      formDataChanged.append(
        "year_founded",
        moment(collegeProfile.year_founded).format("yyyy-MM-DD")
      );

    if (!(collegeProfile.website == data?.website))
      formDataChanged.append("website", collegeProfile.website);

    if (!(collegeProfile.logo == data?.logo))
      if (collegeProfile.logo)
        formDataChanged.append("logo", collegeProfile.logo);

    if (!(collegeProfile.banner == data?.banner))
      if (collegeProfile.banner)
        formDataChanged.append("banner", collegeProfile.banner);

    if (!(collegeProfile.latitude == data?.latitude))
      formDataChanged.append(
        "latitude",
        collegeProfile.latitude ? collegeProfile.latitude : ""
      );

    if (!(collegeProfile.longitude == data?.longitude))
      formDataChanged.append(
        "longitude",
        collegeProfile.longitude ? collegeProfile.longitude : ""
      );

    // if(!(collegeProfile.primary_color == data?.primary_color))
    formDataChanged.append("primary_color", state.color);

    // if(!(collegeProfile.secondary_color == data?.secondary_color))
    formDataChanged.append("secondary_color", state1.color);

    if (!(collegeProfile.city_id == data?.city_id))
      formDataChanged.append("city", collegeProfile.city_id);

    if (!(collegeProfile.state_id == data?.state_id))
      formDataChanged.append("state", collegeProfile.state_id);

    // if (!(collegeProfile.social_links == data?.social_links))
    formDataChanged.append("social_links", JSON.stringify(mediaLinks));

    if (
      !(
        collegeProfile.total_affiliated_college ==
        data?.total_affiliated_college
      )
    )
      formDataChanged.append(
        "total_affiliated_college",
        collegeProfile.total_affiliated_college
          ? parseInt(collegeProfile.total_affiliated_college)
          : ""
      );

    setLoadingPost(true);

    let PrimaryColorData = {
      main: state.color,
      hover: "#0069D9",
      disable: "#58AAFF",
      contrast_text: "#fff",
      secondary: theme_change?.secondary,
    };
    let SecondaryColorData = {
      main: state.color,
      hover: "#0069D9",
      disable: "#58AAFF",
      contrast_text: "#fff",
      secondary: state1.color,
    };
    dispatch(
      UniversityPatch(
        setLoadingPost,
        alert,
        history,
        location,
        formDataChanged,
        collegeDetail?.education_group_id
      )
    ).then(() => {
      dispatch(
        UniversityGet(
          setPatchLoading,
          alert,
          history,
          location,
          collegeDetail?.education_group_id
        )
      );
      dispatch({
        type: GET_THEME_DETAILS,
        payload: PrimaryColorData,
      });
      dispatch({
        type: GET_THEME_DETAILS2,
        payload: SecondaryColorData,
      });
    });

    // handleFilterChange(GET_THEME_DETAILS, PrimaryColorData);

    // handleFilterChange(GET_THEME_DETAILS2, SecondaryColorData);
  };

  return (
    <>
      {/* <UniversityLayout> */}
      {loading || patchLoading ? (
        <CustomCircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Typography variant="h2">
                {entityInfo?.university} Profile
              </Typography>
              <br />
              <Typography variant="body2" color="secondary">
                Manage your {entityInfo?.university} Profile Settings
              </Typography>
            </div>
            <div>
              {/* <Link to={`/public/educational-group/profile/${data?.group_id}`} style={{ textDecoration: "none" }}>
                <Button variant="contained" color="primary" className={classes.button}>
                  View Educational Group
                </Button>
              </Link> */}

              <a
                href={`http://${data?.domain}/public/educational-group/profile/${data?.group_id}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  View {entityInfo?.university}
                </Button>
              </a>
            </div>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div
                style={{
                  display: "flex",
                  width: "70%",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  {entityInfo?.university} Name
                </Typography>
                <TextField
                  floatingLabelStyle={{ color: "" }}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  required
                  label={`${entityInfo?.university} Name`}
                  value={collegeProfile?.group_name}
                  onChange={(e) =>
                    setCollegeProfile({
                      ...collegeProfile,
                      group_name: e.target.value,
                    })
                  }
                />
                <Typography variant="body2" className={classes.text}>
                  {entityInfo?.university} Website
                </Typography>
                <TextField
                  floatingLabelStyle={{ color: "" }}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label={`${entityInfo?.university} URL`}
                  name={`${entityInfo?.university} URL`}
                  value={collegeProfile?.website}
                  onChange={(e) =>
                    setCollegeProfile({
                      ...collegeProfile,
                      website: e.target.value,
                    })
                  }
                />
                <Typography variant="body2" className={classes.text}>
                  Established In
                </Typography>
                <div style={{ marginTop: "20px" }}>
                  <Calendar
                    selectedDate={collegeProfile?.year_founded}
                    title="Established In"
                    onDateChange={(date) =>
                      setCollegeProfile({
                        ...collegeProfile,
                        year_founded: moment(date).format("YYYY"),
                        date: moment(date).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label" required className={classes.floatingLabelFocusStyle} >
                  Year
                </InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={degree} onChange={handleChange} label="Year">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Btech</MenuItem>
                  <MenuItem value={20}>BCA</MenuItem>
                  <MenuItem value={30}>MCA</MenuItem>
                </Select>
              </FormControl> */}
                <Typography variant="body2" className={classes.text}>
                  {entityInfo?.university} Address
                </Typography>
                <br />
                <Grid container spacing={4}>
                  <Grid item xs={4} style={{ display: "grid" }}>
                    <Autocomplete
                      id="country-select-demo"
                      options={stateList}
                      getOptionLabel={(option) => option.state}
                      value={collegeProfile?.state_obj}
                      onChange={(e, newValue) => {
                        setCollegeProfile({
                          ...collegeProfile,
                          state_obj: newValue,
                          state: newValue?.state,
                          state_id: newValue?.state_id,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={"State"}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ display: "grid" }}>
                    <Autocomplete
                      id="country-select-demo"
                      options={city}
                      getOptionLabel={(option) => option.city}
                      value={collegeProfile?.city_obj}
                      onChange={(e, newValue) => {
                        setCollegeProfile({
                          ...collegeProfile,
                          city_obj: newValue,
                          city: newValue?.city,
                          city_id: newValue?.city_id,
                        });
                      }}
                      disabled={collegeProfile?.state_obj ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={"City"}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <TextField
                  floatingLabelStyle={{ color: "" }}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label={`${entityInfo?.university} Address`}
                  name="College Address"
                  value={collegeProfile?.address}
                  onChange={(e) =>
                    setCollegeProfile({
                      ...collegeProfile,
                      address: e.target.value,
                    })
                  }
                />
                <Typography variant="body2" className={classes.text}>
                  Total Affiliated {entityInfo?.college}
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label={`Total Affiliated ${entityInfo?.college} No.`}
                  value={collegeProfile?.total_affiliated_college}
                  onChange={(e) =>
                    setCollegeProfile({
                      ...collegeProfile,
                      total_affiliated_college: e.target.value,
                    })
                  }
                />
                <Typography variant="body2" className={classes.text}>
                  Map Location
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    {" "}
                    <TextField
                      floatingLabelStyle={{ color: "" }}
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      label="Enter Latitude"
                      name="Enter Latitude "
                      value={collegeProfile?.latitude}
                      onChange={(e) =>
                        setCollegeProfile({
                          ...collegeProfile,
                          latitude: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      floatingLabelStyle={{ color: "" }}
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      label="Enter Longitude"
                      name="Enter Longitude"
                      value={collegeProfile?.longitude}
                      onChange={(e) =>
                        setCollegeProfile({
                          ...collegeProfile,
                          longitude: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Typography variant="body2" className={classes.text}>
                  {entityInfo?.university} Description
                  <span style={{ color: "#181818", fontWeight: "normal" }}>
                    {" "}
                    (Max 512 Characters){" "}
                  </span>
                </Typography>
                <br />
                {/* <MyEditor /> */}
                <Editor
                  state={editor}
                  setState={setEditor}
                  desc={data?.about}
                  fromUniversitySetting={true}
                />
                <br />
                <Typography variant="body2" className={classes.text}>
                  Social Media
                </Typography>
                <TextField
                  floatingLabelStyle={{ color: "" }}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label="LinkedIn URL"
                  name="LinkedIn URL"
                  value={mediaLinks[0]?.value}
                  onChange={(e) => {
                    var arr = [...mediaLinks];
                    arr[0].value = e.target.value;
                    setMediaLinks(arr);
                  }}
                />
                <TextField
                  floatingLabelStyle={{ color: "" }}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label="Facebook URL"
                  name="Facebook URL"
                  value={mediaLinks[1]?.value}
                  onChange={(e) => {
                    var arr = [...mediaLinks];
                    arr[1].value = e.target.value;
                    setMediaLinks(arr);
                  }}
                />
                <TextField
                  floatingLabelStyle={{ color: "" }}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label="Instagram URL"
                  name="Instagram URL"
                  value={mediaLinks[2]?.value}
                  onChange={(e) => {
                    var arr = [...mediaLinks];
                    arr[2].value = e.target.value;
                    setMediaLinks(arr);
                  }}
                />
                <Typography
                  variant="h6"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  Upload Logo
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                      {/* <div style={{ width: "300px" }}> */}
                      <DropzoneAreaBase
                        dropzoneClass={classes.DropzoneArea}
                        acceptedFiles={["image/*"]}
                        dropzoneText={"Drag and drop an Image here"}
                        showPreviewsInDropzone={true}
                        showPreviews={true}
                        onAdd={(files) => {
                          setCollegeProfile({
                            ...collegeProfile,
                            logo: files[0].file,
                            logoData: files[0].data,
                          });
                        }}
                      />
                      {/* </div> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {collegeProfile?.logoData ? (
                        <div style={{ width: "300px", marginTop: "10px" }}>
                          <img
                            src={collegeProfile?.logoData}
                            width="200px"
                            height="200px"
                          />
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                  {/* <br />
                <Button variant="contained" color="primary" style={{ borderRadius: "30px", boxShadow: "none" }}>
                  Upload Logo
                </Button> */}
                </div>{" "}
                <br />
                <Typography
                  variant="h6"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  Upload Banner Image
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      {/* <div style={{ width: "500px", marginTop: "10px" }}> */}
                      <DropzoneAreaBase
                        dropzoneClass={classes.DropzoneArea}
                        acceptedFiles={["image/*"]}
                        showPreviewsInDropzone={true}
                        showPreviews={true}
                        dropzoneText={"Drag and drop an Image here"}
                        onAdd={(files) =>
                          setCollegeProfile({
                            ...collegeProfile,
                            banner: files[0].file,
                            bannerData: files[0].data,
                          })
                        }
                        // onAlert={(message, variant) =>
                        //   console.log(`${variant}: ${message}`)
                        // }
                      />
                      {/* </div> */}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      {collegeProfile?.bannerData ? (
                        <div style={{ width: "500px", marginTop: "10px" }}>
                          <img
                            src={collegeProfile?.bannerData}
                            width="400px"
                            height="200px"
                          />
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                  {/* <br />
                <Button variant="contained" color="primary" style={{ borderRadius: "30px", boxShadow: "none" }}>
                  Upload Banner
                </Button> */}
                </div>
                <br />
                <Typography
                  variant="body2"
                  className={classes.text}
                  style={{ marginBottom: 10 }}
                >
                  Choose University Theme
                </Typography>
                {/* <SketchPicker /> */}
                {/* <div style={{ display: "flex" }}>
                <span style={{ marginRight: 20 }}>
                  Primary <ColorPicker />
                </span>
                <span>
                  Secondary <ColorPickerSecondary />
                </span>
              </div> */}
                <div style={{ display: "flex" }}>
                  <span style={{ marginRight: 20 }}>
                    Primary
                    <ColorPicker
                      data={state}
                      handleChange={(color) => {
                        setState({ ...state, color: color.hex });
                        // let data = {
                        //   main: color.hex,
                        //   hover: "#0069D9",
                        //   disable: "#58AAFF",
                        //   contrast_text: "#fff",
                        //   secondary: theme_change?.secondary,
                        // };
                        // handleFilterChange(GET_THEME_DETAILS, data);
                      }}
                      handleClose={() =>
                        setState({ ...state, displayColorPicker: false })
                      }
                      handleClick={() =>
                        setState({
                          ...state,
                          displayColorPicker: !state.displayColorPicker,
                        })
                      }
                    />
                  </span>
                  {/* <span>
                    Secondary <ColorPickerSecondary state={state1} setState={setState1} />
                  </span> */}

                  <span>
                    Secondary
                    <ColorPicker
                      data={state1}
                      handleChange={(color) => {
                        setState1({ ...state1, color: color.hex });
                      }}
                      handleClose={() =>
                        setState1({ ...state1, displayColorPicker: false })
                      }
                      handleClick={() =>
                        setState1({
                          ...state1,
                          displayColorPicker: !state1.displayColorPicker,
                        })
                      }
                    />
                  </span>
                </div>
              </div>
            </Paper>
            {!isCollegeGroup && (
              <div className={classes.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    setPatchLoading(true);
                    save();
                  }}
                >
                  {loadingPost ? <CustomButtonCircularProgress /> : <> Save</>}{" "}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}
