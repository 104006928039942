import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useSelector } from "react-redux";
import pSBC from "shade-blend-color";
import { groupViewSelectorFn } from "../../../api/Common/selector";
import {
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  roots: {
    display: "flex",
    borderRadius: "8px",
    width: "160px",
  },
  classesnotToggled: {
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    width: "160px",
    borderRadius: "8px",
    color: theme.palette.primary.main,
  },
  classTogglad: {
    backgroundColor: pSBC(0.8, theme.palette.primary.secondary),
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    color: theme.palette.primary.secondary,
    border: "1px solid",
    borderColor: theme.palette.primary.secondary,
    width: "160px",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    margin: theme.spacing(0.9),
  },

  switchBase: {
    padding: 1,
    color: theme.palette.primary.main,
    left: "2px",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.primary.secondary,
      left: "-1px",

      "& + $track": {
        backgroundColor: "#fff",
        opacity: 1,
        border: "none",
      },
    },
    // "&$focusVisible $thumb": {
    //   color: "blue",
    //   border: "6px solid #fff",
    // },
  },
  thumb: {
    width: 19,
    height: 19,
  },
  track: {
    borderRadius: 8,
    // border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "white",
    color: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  const groupView = useSelector(groupViewSelectorFn);
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      inputProps={{ "aria-label": "controlled" }}
      checked={groupView}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function CollegeGroupSwitch(props) {
  const classes = useStyles();
  const groupView = useSelector(groupViewSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };
  return (
    <>
      <div
        className={groupView ? classes.classTogglad : classes.classesnotToggled}
      >
        <IOSSwitch
          onChange={props.ToggleSwitch}
          name="checkedB"
          disabled={props.disable}
          checked={props.value}
        />
        <Typography
          variant="body2"
          color="inherit"
          // noWrap
        >
          {!groupView
            ? `${entityInfo?.university} View`
            : `${entityInfo?.college} View`}
        </Typography>
      </div>
    </>
  );
}
