import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography, Button } from "@material-ui/core";
import Course from "../../Course";
import { Link } from "react-router-dom";
import CustomCircularProgress from "../../../CircularProgressBar";
import NoDataFunc from "../../../NoDataSVG/index1";

export default class HiredPie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: props?.data?.data ? props?.data?.data : [],
      fill: {
        colors: ["#F44336", "#E91E63", "#9C27B0"],
      },
      options: {
        // chart: {
        //   width: 380,
        //   type: "pie",
        // },
        labels: props?.data?.labels ? props?.data?.labels : [],
        dataLabels: {
          // enabled: true,
          // textAnchor: "start",
          style: {
            fontFamily: "Nunito",
            fontWeight: "normal",
            boxShadow: "none",
            fontSize: "11px",
          },
        },
        colors: [
          "#F44336",
          "#67c3d0",
          "#9C27B0",
          "#e97b86",
          "#377dff",
          "#e0a800",
          "#ccccff",
          "#00ffff",
          "#8f9966",
          "#66ff33",
        ],
        stroke: {
          show: true,
          curve: "smooth",
          width: 1,
        },

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "11px",
          fontFamily: "Nunito",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
        },
        // legend: {
        //   show: false,
        // },
        // colors: [
        //   "#e289f2",
        //   "#4f3694",
        //   "#acb9ff",
        //   "#855cf8",
        //   "#f8f2a8",
        //   "#f5777b",
        // ],
        // dataLabels: {
        //   //  enabled: false,
        // },

        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      },
    };
  }
  render() {
    return (
      <Paper
        style={{
          boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
          borderRadius: "8px",
          padding: "30px",
        }}
      >
        {this.props?.loading ? (
          <CustomCircularProgress />
        ) : this.props?.data?.data?.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h6" style={{}}>
                {this?.props?.title}
              </Typography>
              <Link to={this?.props?.link} style={{ textDecoration: "none" }}>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    borderRadius: "8px",
                    height: "30px",
                    backgroundColor: "#e0a800",
                    boxShadow: "none",
                    color: "#fff",
                  }}
                >
                  {" "}
                  View All
                </Button>
              </Link>
            </div>
            <Charst
              options={this.state.options}
              series={this.state.series}
              type="pie"
              //   width={320}
            />
          </>
        ) : (
          <>
            <Typography variant="h6" style={{}}>
              Highlighted Skills of Students
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <NoDataFunc width={"300Px"} height={"200px"} />
              <Typography style={{ fontSize: "16px" }}>
                {" "}
                No Data Available
              </Typography>{" "}
            </div>
          </>
        )}
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                border: "1px solid #e2e6ea",
                display: "flex",
                flexDirection: "row",
                borderRadius: "20px",
                width: "120px",
                height: "40px",
                color: "#8a94a6",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#377dff",
                  height: "10px",
                  width: "10px",
                  borderRadius: "50%",
                }}
              />
              <Typography variant="overline">Python</Typography>
            </div>
          </div>
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                //   padding: "20px",
                display: "flex",
                flexDirection: "row",
                borderRadius: "20px",
                width: "100px",
                border: "1px solid #e2e6ea",
                height: "40px",
                color: "#8a94a6",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#38cb89",
                  height: "10px",
                  width: "10px",
                  borderRadius: "50%",
                }}
              />
              <Typography variant="overline">Java</Typography>
            </div>
          </div>
        </div> */}
      </Paper>
    );
  }
}
