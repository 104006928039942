import { groupViewCheck } from "./groupViewCheck"

export const checkForBreadcrumb=(isEducationGroup, isCollegeGroup, collegeId, link)=>{
    var group_check = groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
    var return_val = ""
    if(group_check)
    {
      if(isCollegeGroup)
        return_val = `/college-group/${link}`
      if(isEducationGroup)
        return_val = `/educational-group/${link}`
    }
    else
      return_val = "/" + link
    return return_val

  }