import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
  icon: {
    color: "#007bff",
  },
  font: {
    fontFamily: "",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function SaveCertifications({
  certification,
  certiIndex,
  handleDelete,
  setAddCertifications,
  handleOpen,
  setIsEdit,
  setEditIndex,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography
            variant="body1"
            style={{
              // fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            {certification?.name}
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton
              color="primary"
              onClick={() => {
                setEditIndex(certiIndex);
                setIsEdit(true);
                setAddCertifications(certification);
                handleOpen();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                handleDelete(certification?.id, certiIndex);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          style={{
            // fontWeight: "bold",
            marginBottom: "4px",
          }}
        >
          {certification?.institute}
        </Typography>

        <Typography variant="body2">
          {certification?.start_date && certification?.start_date !== null && (
            <>
              {moment(certification?.start_date).format("MMM, YYYY")}{" "}
              {certification.end_date && certification.end_date !== null && (
                <>
                  {" - "}
                  {moment(certification?.end_date).format("MMM, YYYY")}{" "}
                </>
              )}
            </>
          )}
        </Typography>
        <Typography
          variant="body2"
          style={{
            // fontWeight: "bold",
            marginBottom: "4px",
          }}
        >
          Valid Upto:{" "}
          {!certification?.expiration && certification?.valid_date !== null
            ? moment(certification?.valid_date).format("MMM, YYYY")
            : "Doesn't Expire"}
        </Typography>
        <Typography>{certification?.description}</Typography>
      </div>
    </>
  );
}
