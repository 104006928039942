import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import HeaderCard from "../../../../components/Cards/HeaderCard";
import graduated from "../../../../assets/icons/graduated.png";
import hand from "../../../../assets/icons/hand.png";
import office from "../../../../assets/icons/office-building.png";
import s1 from "../../../../assets/icons/suitcase.png";
import { Divider, Grid, Paper } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Business from "@material-ui/icons/Business";
import { People } from "@material-ui/icons";
import { Work } from "@material-ui/icons";
import Calendar from "../../../../assets/icons/calendar.png";
import NoDataFunc from "../../../NoDataSVG/index1";
import IconSelectorNew from "../../../../utility/iconSelectorNew";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    display: "flex",
    height: "170px",
    borderRadius: "8px",
    boxShadow: "none",
    // padding: theme.spacing(2),

    "&:hover": {
      transform: "scale(1.09)",
      transition: "transform 0.2s",
    },
    //textAlign: "center",
    backgroundColor: "#c8facd",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100px",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
  },
  text: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "left",
  },
}));

function preventDefault(event) {
  event.preventDefault();
}

export default function ReportCards({ data }) {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid container spacing={3}>
          {data?.length >= 1 ? (
            data?.map((item, index) => {
              return (
                <Grid item xs={12} md={3}>
                  <Grid item xs={12}>
                    {/* <Link to="/company" style={{ textDecoration: "none" }}> */}
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: item?.colour1 }}
                    >
                      <div
                        className={classes.item}
                        style={{
                          backgroundColor: item?.colour2,
                        }}
                      >
                        {IconSelectorNew(item?.icon, item?.colour2)}
                      </div>
                      <div className={classes.text}>
                        <Typography variant="h6" style={{ color: "#000" }}>
                          {item?.title}
                        </Typography>
                        <br />
                        <Typography
                          variant="h2"
                          style={{ fontWeight: "bold" }}
                          color="inherit"
                        >
                          {item?.count}
                        </Typography>
                        <br />
                      </div>
                    </Paper>
                    {/* </Link> */}
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <NoDataFunc width={"300Px"} height={"200px"} />
              <Typography style={{ fontSize: "16px" }}>
                No Data Available
              </Typography>{" "}
            </div>
          )}
        </Grid>
      </Grid>

      <br />
    </>
  );
}
