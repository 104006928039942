import {
  COLLEGE_DATA,
  AFFILIATED_FROM,
  COLLEGE_CITY,
  COLLEGE_STATE,
} from "./action";

export const collegeProfile = (state = {}, action) => {
  switch (action.type) {
    case COLLEGE_DATA:
      return { ...state, collegeData: action.payload };
    case AFFILIATED_FROM:
      return { ...state, affiliatedData: action.payload };
    case COLLEGE_CITY:
      return { ...state, cityData: action.payload };
    case COLLEGE_STATE:
      return { ...state, stateData: action.payload };

    default:
      return state;
  }
};
