import { Button, Grid, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { praveshAxiosPatchReq } from "../../../api/BaseApi/apiBase";
import { BackendBaseApi } from "../../../constants/constants";
import useAxiosApi from "../../../Hooks/useAxiosApi";
import CompanyTable from "../../Table/CompanyTable";

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            <Typography sx={{ fontSize: "24px", fontWeight: "600", pl: 1 }}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 10,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({

    rowText: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        // fontSize: '12'
    },
    rowTextSelection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around'
    },
    iconBtn: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.secondary,
        },
        cursor: "pointer",
    },


}));



const OfferLetter = ({ data, open, handleClose, jobId }) => {

    const theme = useTheme();
    const classes = useStyles();

    const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const userDetails = useSelector(store => store?.UserDetails?.user_details);
    // const initialOfferStatusId = {
    //     id: '',
    // }
    // const [offerStatusData, setOfferStatusData] = useState(initialOfferStatusId)

    const url = `${BackendBaseApi?.PRAVESH}api/common/offer_letter/?job_id=${jobId}`

    const { data: offerLettersList, setData: setOfferLettersList, loading, } = useAxiosApi({ url: url });


    const changeStatus = async (status, id, userId) => {

        const endPoint = `api/common/offer_letter/${id}/`
        const payload = {
            offer_source: "COLLEGE",
            source_page: 'College Manage Jobs',
            offer_status: status || 'ACCEPTED',
            college_id: userDetails?.college_details?.college_id,
        }
        try {
            const res = await praveshAxiosPatchReq(endPoint, payload);

            if (res?.data?.success) {

                const data = [...offerLettersList];
                const student = data?.find(item => item?.user_id === userId);
                const studentIndex = data?.findIndex(item => item?.user_id === userId);
                data?.splice(studentIndex, 1, { ...student, offer_status: status })
                setOfferLettersList(data);

            }

        } catch (error) {

            console.log(error);
        }
    }



    const columns = [

        {
            field: "name",
            headerName: "Name",
            // type: "text",
            width: 150,
            sortable: false,
            headerAlign: "left",
            renderHeader: (params) => (
                <strong className={classes.rowText} style={{ paddingLeft: "10px" }}>
                    Name
                </strong>
            ),
            renderCell: (params) => (
                <>
                    <Grid className={classes.rowText} container wrap="nowrap" >
                        {" "}
                        <Grid item xsclassName={classes.rowText} >
                            <Tooltip title={params?.row?.name} placement="right">
                                <Typography variant="h7">{params?.row?.name}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </>
            ),
        },

        {
            field: "updated_by",
            // type: "text",
            width: 145,
            headerAlign: "center",
            sortable: false,
            renderHeader: (params) => (
                <strong className={classes.rowText}>Updated By</strong>
            ),
            renderCell: (params) => (
                <span className={classes.rowText} >

                    <Tooltip title={params?.row?.name} placement="right">
                        <Typography variant="h7">
                            {`${params?.row?.action_history[
                                params?.row?.action_history?.length - 1
                            ]?.name
                                }`}
                        </Typography>
                    </Tooltip>
                </span>
            ),
        },

        {
            field: "update_time",
            // type: "text",
            sortable: false,
            width: 150,
            headerAlign: "center",
            renderHeader: (params) => (
                <strong className={classes.rowText} >Last Updated</strong>
            ),
            renderCell: (params) => (
                <>
                    <span className={classes.rowText}>{params?.row?.update_time}</span>
                </>
            ),
        },
        {
            field: "Status",
            // type: "text",
            sortable: false,
            width: 150,
            headerAlign: "center",
            renderHeader: (params) => (
                <strong className={classes.rowText}>Status</strong>
            ),
            renderCell: (params) => (
                <div style={{ display: 'flex', alignContent: 'center', width: '100%' }}>
                    <span className={classes.rowTextSelection}>{params?.row?.offer_status === "PENDING" ?
                        <>
                            {/* <Tooltip title={"Accept"} placement="right">
                                <CheckCircle className={classes.iconBtn} marginRight={5} onClick={() => { changeStatus("ACCEPTED", params?.id, params?.row?.user_id) }} />
                            </Tooltip> */}
                            <div onClick={() => { changeStatus("ACCEPTED", params?.id, params?.row?.user_id) }} style={{cursor: "pointer", color: "green"}}>
                                Accept
                            </div>
                            |
                            {/* <Tooltip title={"Reject"} placement="right">
                                <CancelOutlined className={classes.iconBtn} onClick={() => { changeStatus("REJECTED", params?.id, params?.row?.user_id) }} />
                            </Tooltip> */}
                            <div onClick={() => { changeStatus("REJECTED", params?.id, params?.row?.user_id) }} style={{cursor: "pointer", color: "red"}}>
                                Reject
                            </div>

                        </>
                        :
                        params?.row?.offer_status
                    }
                    </span>
                </div>
            ),
        },

        {
            field: "link",
            // type: "text",
            width: 180,
            headerAlign: "center",
            sortable: false,
            renderHeader: (params) => (
                <strong className={classes.rowText}>Offer Letter</strong>
            ),
            renderCell: (params) => (
                <span className={classes.rowText}>
                    <Button
                        size="small"
                        color='primary'
                        variant="contained"
                        onClick={() => {
                            window.open(params?.row?.offer_letter, "_blank");
                        }}
                    >
                        View
                    </Button>
                </span>
            ),
        },
    ]



    const handleClickClose = () => {
        setOfferLettersList([]);
        handleClose();
    };





    return (
        <div>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                maxWidth={1200}
                // fullWidth={"lg"}
                PaperProps={{
                    style: {
                        width: "860px",
                        height: "500px",
                        borderRadius: "8px",
                        backgroundColor: "#ffff",
                    },
                }}
                onClose={handleClickClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClickClose}
                >
                    Offer letters for {data?.job_title}
                </BootstrapDialogTitle>
                <Typography variant="body2" sx={{ color: "#6C757D", pl: 3.5, mt: -2 }}>
                    {/* {data?.job_title} */}
                </Typography>
                <DialogContent>
                    <Box sx={{}}>
                        <Grid container spacing={2}>
                            <Grid md={12} sx={{ display: "grid" }}>
                                <CompanyTable
                                    loading={loading}
                                    list={offerLettersList ?? []}
                                    columns={columns}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );

}

export default OfferLetter