import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import axios from 'axios';
import { BackendBaseApi } from '../../../constants/constants';
import CustomButtonCircularProgress from '../../CircularProgressBar/index2';
import { useAlert } from 'react-alert';




const useStyles = makeStyles((theme) => ({
    move: {
        boxShadow: "none",
        borderRadius: "8px",
        height: "40px",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "10px",
        minWidth:"200px"
      }
}))

const DownloadResume = ({selectedRowsData,setSelectedRowsData,setSelectedID,data}) => {
const classes = useStyles();
const alert = useAlert()

const [downloadResume,setDownloadResume] = useState(false)


function generateCsvWithoutSpacing(arr) {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1 ? (str += item + ",") : (str += item)
    );
    return str;
  }

const getResumeZip = () => {
    const selectedUsers = [];

    selectedRowsData?.map((item) => {
      selectedUsers?.push(item?.user_id);
    });
    const ids = generateCsvWithoutSpacing(selectedUsers);

    setDownloadResume(true);
    axios
      .get(
        `${BackendBaseApi.PRAVESH}api/college/download_candidate_resume_data/?student_id=${btoa(
          ids
        )}`,
        {
          // headers: { Authorization: `Token ${getCookie("user_token_test")}` },
          withCredentials: true,
          // responseType: "arraybuffer",
        }
      )
      .then((res) => {
        setDownloadResume(false);
        // const blob = new Blob([res.data], {
        //   type: "application/x-zip-compressed",
        // });
        // const fileURL = URL.createObjectURL(blob);
        // // window.open(fileURL);
        // const link = document.createElement('a');
        // link.href = fileURL;
        // link.setAttribute('download', `${data?.job_title} at ${data?.company_name}.zip`);
        // // Simulate a click on the link to trigger the download
        // link.click();
        // // Release the object URL resources
        // window.URL.revokeObjectURL(fileURL);
        if(res?.data?.success){
          alert.success(res?.data?.data?.message);
          setSelectedRowsData([]);
          setSelectedID([]);
        }else{
          alert.error("Something went wrong")
        }
       
      })
      .catch((err) => {
        setDownloadResume(false);
        alert.error(err?.response?.data?.error)
        // console.log("Err", err);
      });
  };




  return (
    <div>
        <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={getResumeZip}
        className={classes.move}
      >
        {downloadResume ? <CustomButtonCircularProgress/> : "Download Resumes"}
      </Button>
    </div>
  )
}

export default DownloadResume
