import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import LastYearReportHeading from "./LastYearReportHeading";
import { Grid } from "@material-ui/core";
import StarPerformer from "./StarPerformer";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "600",
  },
}));

export default function LastYearCard({selectedPlacement,collegeId}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(1);
  const [lastYears,setLastYears] = useState(null);
  function handleChange(id) {
    setValue(id);
  }


  const data = [
    {
      id: 1,
      title: "Student Data",
      tab: (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Student" yAxis="Number of Student" xAxis="Course" lastYears={lastYears} setLastYears={setLastYears} />
            <br />
            <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Student Placed(%)" yAxis="Number of Applicant Placed (%) " xAxis="Course" lastYears={lastYears} />
          </div>
        </>
      ),
    },
    {
      id: 2,
      title: "Job Data",
      tab: (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
          <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Job Count" yAxis="Number of Job Count" xAxis="Course"  lastYears={lastYears}/>
            <br />
            <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Job Selection(%)" yAxis="Number of Job Selection (%) " xAxis="Course" lastYears={lastYears}/>
          </div>
        </>
      ),  },
    {
      id: 3,
      title: "Job Salary Scale Data",
      tab: (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
          <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Job vs Salary Scale" yAxis="Number of Jobs" xAxis="Job Salary"  lastYears={lastYears} />
            <br />
          <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Placed Salary Scale" yAxis="Number of Jobs" xAxis="Job Salary"  lastYears={lastYears} />
          </div>
        </>
      ),  },
    {
      id: 4,
      title: "Highest CTC",
      tab: (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
          <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Highest CTC Offered" yAxis="Highest CTC (LPA)" xAxis="Course" lastYears={lastYears}/>
            <br />
          <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Highest CTC Placed" yAxis="Highest CTC (LPA)" xAxis="Course" lastYears={lastYears}/>
          </div>
        </>
      ), 
     },
  
      {
        id: 5,
        title: "Average CTC",
        tab: (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
            <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Average CTC Offered" yAxis="Average CTC (LPA)" xAxis="Course" lastYears={lastYears} />
              <br />
            <LastYearReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Average CTC Placed" yAxis="Average CTC (LPA)" xAxis="Course" lastYears={lastYears} />
            </div>
          </>
        ),  },
    
  ];

  
  const year = [
    {
      color: "#0B57D0",
      year: lastYears && lastYears[0],
    },
    { color: "#E0A800", 
      year: lastYears && lastYears[1], 
    },
    {
      color: "#029E73",
      year: lastYears && lastYears[2],
    },
  ];
  

  return (
    <div className={classes.root}>
      <Accordion style={{ boxShadow: "none", backgroundColor: "transparent" }}>
        <AccordionSummary
          style={{ backgroundColor: "#fff", borderRadius: 8 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <div>
              <Typography className={classes.heading}>
                Last 3 year comparison
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: -5 }}
            >
              {year.map((item) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StopRoundedIcon style={{ color: item.color }} />
                  <Typography variant="body2" style={{ marginRight: 5 }}>
                    {item.year}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "grid",
            backgroundColor: "#fff",
            borderRadius: 8,
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#fff",
              // padding: "20px",
              paddingTop: 10,
              borderRadius: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {data.map((item, id) => (
                <ul
                  style={{
                    padding: 0,
                    display: "flex",
                    listStyleType: "none",
                    flexWrap: "wrap",
                    textAlign: "center",
                    width: "20%",
                  }}
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li
                    style={{ width: "100%" }}
                    role="presentation"
                    onClick={() => handleChange(item.id)}
                  >
                    <div
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "8px",
                        color: value === item.id ? "#fff" : "#000",
                        backgroundColor:
                          value === item.id
                            ? theme.palette.primary.main
                            : "#fff",
                      }}
                    >
                      {item.title}
                    </div>
                  </li>
                </ul>
              ))}
            </div>

            {value && data.map((item, id) => {
              return value===item.id ? (<div id="myTabContent">
                <div
                  style={{
                    display: value === item.id ? "block " : "none",
                    marginTop: "20px",
                  }}
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {item.tab}
                </div>
              </div>) : (<></>)
})}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}


