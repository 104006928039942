import {
  Box,
  Button,
  Divider,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  boxShadowClass: {
    position: "relative",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    padding: 10,
    margin: "10px 0",
  },
  statuschip: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 8,
    // borderRadius: "8px",
  },
}));

function SavedCertification({ data, approveReject }) {
  const classes = useStyles();
  return (
    <div style={{ position: "relative" }}>
      {data?.map((certification) => (
        <div className={classes?.boxShadowClass}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <Typography
              variant="body1"
              style={{
                // fontWeight: "bold",
                marginBottom: "4px",
              }}
            >
              {certification?.updatedData?.name}
            </Typography>
            {/* <Chip
              color="primary"
              variant="outlined"
              style={{
                //margin: theme.spacing(0.5),
                marginLeft: 0,
                borderRadius: "8px",
                backgroundColor:
                  certification?.change_type === "ADD"
                    ? "red"
                    : certification?.change_type === "UPDATE"
                    ? "yellow"
                    : "maroon",
              }}
              label={
                certification?.change_type === "ADD"
                  ? "Added"
                  : certification?.change_type === "UPDATE"
                  ? "Updated"
                  : "Deleted"
              }
              size="small"
            /> */}
            <Tooltip
              placement="bottom"
              title={
                certification?.change_type === "ADD"
                  ? "New certification added"
                  : certification?.change_type === "UPDATE"
                  ? "Updated existing certification"
                  : "Delete existing certification"
              }
            >
              <div
                style={{
                  backgroundColor:
                    certification?.change_type === "ADD"
                      ? "#00203F"
                      : certification?.change_type === "UPDATE"
                      ? "#2AF598"
                      : "#FF0000",
                }}
                className={classes.statuschip}
              >
                <Typography style={{ color: "#fff", fontSize: "14px" }}>
                  {certification?.change_type === "ADD"
                    ? "Added"
                    : certification?.change_type === "UPDATE"
                    ? "Updated"
                    : "Deleted"}
                </Typography>
              </div>
            </Tooltip>
          </div>
          <Typography
            variant="body1"
            style={{
              // fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            {certification?.updatedData?.institute}
          </Typography>

          <Typography variant="body2">
            {certification?.updatedData?.start_date &&
              certification?.updatedData?.start_date !== null && (
                <>
                  {moment(certification?.updatedData?.start_date).format(
                    "MMM, YYYY"
                  )}{" "}
                  {certification?.updatedData?.end_date &&
                    certification?.updatedData?.end_date !== null && (
                      <>
                        {" - "}
                        {moment(certification?.updatedData?.end_date).format(
                          "MMM, YYYY"
                        )}{" "}
                      </>
                    )}
                </>
              )}
          </Typography>

          <Typography
            variant="body2"
            style={{
              // fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            Valid Upto:{" "}
            {certification?.updatedData?.valid_date &&
            certification?.updatedData?.valid_date !== null
              ? moment(certification?.updatedData?.valid_date).format(
                  "MMM, YYYY"
                )
              : "Doesn't Expire"}
          </Typography>
          {certification?.updatedData?.description && (
            <Typography
              variant="body2"
              style={{
                marginBottom: "4px",
              }}
            >
              Description:{" "}
              {certification?.updatedData?.description &&
                certification?.updatedData?.description}
            </Typography>
          )}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // position: "absolute",
              // right: 0,
              // bottom: 5,
            }}
          >
            <Tooltip title="Approve" placement="left">
              <IconButton
                style={{
                  backgroundColor: "#51c03e",
                  marginRight: "10px",
                }}
                size="small"
                onClick={() => {
                  approveReject([certification?.id], "APPROVED", "EDU");
                }}
              >
                <Check style={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject" placement="right">
              <IconButton
                style={{ backgroundColor: "#f64a3f" }}
                size="small"
                onClick={() => {
                  approveReject([certification?.id], "REJECTED", "EDU");
                }}
              >
                <CloseIcon style={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </div> */}
          <Divider
            variant="middle"
            style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <HistoryIcon style={{ color: "#010101" }} />{" "}
              <Typography
                variant="body2"
                style={{ color: "#010101", paddingLeft: "5px" }}
              >
                {moment(certification?.create_time)?.format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </Typography>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  marginRight: "10px",
                  height: "35px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  approveReject([certification?.id], "APPROVED");
                }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "14px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Accept
                </Typography>
              </Button>

              <Button
                variant="outlined"
                style={{
                  borderColor: "#FF0000",
                  height: "35px",
                  boxShadow: "none",
                }}
                size="small"
                onClick={() => {
                  approveReject([certification?.id], "REJECTED");
                }}
              >
                <Typography
                  style={{
                    color: "#FF0000",
                    fontSize: "14px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Decline
                </Typography>
              </Button>
            </div>
          </Box>
        </div>
      ))}
    </div>
  );
}

export default SavedCertification;
