import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const HELP_DATA = "HELP_DATA";
export const CARD_DATA = "CARD_DATA";
export const QUESTION_DATA = "QUESTION_DATA";

export const HelpData = (alert, history, location, isEducationGroup) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.CMS}/help-and-support-groups/and?${isEducationGroup ? `show_in_edu_group=1&institute_id=2` : `show_in_college=1&institute_id=1`}`, {})
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });
    if (res.data.success) {
    } else {
      dispatch({
        type: HELP_DATA,
        payload: res.data,
      });
    }
  };
};

export const CardData = (alert, history, location, card_id, isEducationGroup) => {
  return async (dispatch) => {
    const res = await axios.get(`${BackendBaseApi.CMS}/help-and-support-groups/find-one?id=${card_id}&institute_id=${isEducationGroup ? 2 : 1}`, {}).catch((error) => {
      checkForToken(error, history, location, alert);

      throw error;
    });

    if (res.data.success) {
    } else {
      dispatch({
        type: CARD_DATA,
        payload: res.data,
      });
    }
  };
};

export const QuestionData = (alert, history, location, question_id) => {
  return async (dispatch) => {
    const res = await axios.get(`${BackendBaseApi.CMS}/how-to-use-saas/${question_id}`, {}).catch((error) => {
      checkForToken(error, history, location, alert);

      throw error;
    });

    if (res.data.success) {
    } else {
      dispatch({
        type: QUESTION_DATA,
        payload: res.data,
      });
    }
  };
};
