import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { JobPlacementCycleChangeAPI } from "../../../api/JobPost/action";
import { StudentPlacementCycleChangeAPI } from "../../../api/ManageStudents/action";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "10px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "40px",
    paddingRight: "40px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  primebtn: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function GetEmailAndExportForSharer({
  open,
  handleClose,
  handleExport,
  exportLoading,
}) {
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: 14 }} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkedIcon} />;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateData = () => {
    var is_valid = true;
    var email_error = "";
    if (!email) {
      email_error = "Email Required";
      is_valid = false;
    }
    setEmailError(email_error);
    return is_valid;
  };
  const handleClickClose = () => {
    handleClose();
    setEmail("");
    setEmailError("");
  };

  const handleSubmit = () => {
    if (validateData()) {
      handleExport(email);
    }
  };

  useEffect(() => {
    if (exportLoading?.success) {
      handleClickClose();
    }
  }, [exportLoading?.success]);

  return (
    <div>
      {/* <Button
        color="primary"
        size="small"
        variant="contained"
        className={classes.primebtn}
        onClick={handleClickOpen}
      >
        Export
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClickClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div
          style={{
            padding: "20px",
            width: "400px",
            height: 250,
          }}
        >
          <div
            style={{
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Please Provide Email for Export
            </Typography>
          </div>

          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(null);
            }}
            error={emailError ? true : false}
            helperText={<span>{emailError}</span>}
            style={{ width: "100%", marginBottom: 30, marginTop: 30 }}
            label="Enter Email"
          />
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Button
              autoFocus
              variant="outlined"
              className={classes.button}
              onClick={handleClickClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "140px" }}
              className={classes.button}
              onClick={() => {
                if (!exportLoading?.loading) handleSubmit();
              }}
            >
              {exportLoading?.loading ? (
                <CustomButtonCircularProgress />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
