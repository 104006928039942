import axios from "axios";
import { BackendBaseApi } from "../../constants/constants";

const sanchalanAxiosGetReq = function (
  url,
  headers = {
    withCredentials: true,
  }
) {
  return axios.get(`${BackendBaseApi.SANCHALAN}${url}`, headers);
};

const sanchalanAxiosPostReq = function (
  url,
  data,
  headers = {
    withCredentials: true,
  }
) {
  return axios.post(`${BackendBaseApi.SANCHALAN}${url}`, data, headers);
};

const sanchalanAxiosPatchReq = function (
  url,
  data,
  headers = {
    withCredentials: true,
  }
) {
  return axios.patch(`${BackendBaseApi.SANCHALAN}${url}`, data, headers);
};

const sanchalanAxiosDeleteReq = function (
  url,
  headers = {
    withCredentials: true,
  }
) {
  return axios.delete(`${BackendBaseApi.SANCHALAN}${url}`, headers);
};
const praveshAxiosGetReq = function (
  url,
  headers = {
    withCredentials: true,
  }
) {
  return axios.get(`${BackendBaseApi.PRAVESH}${url}`, headers);
};
const praveshAxiosPostReq = function (
  url,
  data,
  headers = {
    withCredentials: true,
  }
) {
  return axios.post(`${BackendBaseApi.PRAVESH}${url}`, data, headers);
};
const praveshAxiosPatchReq = function (
  url,
  data,
  headers = {
    withCredentials: true,
    
  }
) {
  return axios.patch(`${BackendBaseApi.PRAVESH}${url}`, data, headers);
};
const praveshAxiosDeleteReq = function (
  url,
  headers = {
    withCredentials: true,
  }
) {
  return axios.delete(`${BackendBaseApi.PRAVESH}${url}`, headers);
};

const niyuktiAxiosGetReq = function (
  endPoint,
  requestConfig = {
    withCredentials: true,
    // headers:{
    //   Authorization: `Token ${localStorage.getItem("cllgtoken")}`,
    // }
  }
) {
  return axios.get(`${BackendBaseApi.NIYUKTI}${endPoint}`, requestConfig);
};

export {
  sanchalanAxiosGetReq,
  sanchalanAxiosPostReq,
  sanchalanAxiosPatchReq,
  sanchalanAxiosDeleteReq,
  praveshAxiosGetReq,
  praveshAxiosPostReq,
  praveshAxiosPatchReq,
  praveshAxiosDeleteReq,
  niyuktiAxiosGetReq,
};
