import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import React from "react";

const useStyles = makeStyles((theme) => ({
  include: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  cal: {
    borderRadius: "8px",
    border: "1px soild black",
    backgroundColor: "#fff",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
    },
  },
}));

export default function Calendar(props) {
  const {
    title,
    selectedDate,
    setSelectedDate,
    onDateChange,
    errorMessage,
    readOnly = false,
  } = props;
  const classes = useStyles();
  // The first commit of Material-UI

  const [open, setOpen] = React.useState(false);

  const handleManualChange = () => {
    if (readOnly) setOpen(!open);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* <Grid container justifyContent="flex-start"> */}
      <div className={classes.cal}>
        <KeyboardDatePicker
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
            shrink: true,
          }}
          //margin="normal"
          //   id="Tentative Date"
          label={title}
          inputVariant="outlined"
          inputProps={{
            style: {
              padding: "16px",
              backgroundColor: "#fff",
              fontSize: "14px",
              color: "#6c757d",
              borderRadius: "8px",
            },
            readOnly: readOnly,
          }}
          disabled={props?.disabled ? props.disabled : false}
          error={errorMessage ? true : false}
          format="MM/dd/yyyy"
          value={selectedDate}
          onChange={onDateChange}
          disablePast={props?.disablePast === false ? props.disablePast : true}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          // helperText={<span>{errorMessage}</span>}
          onClick={handleManualChange}
          onClose={handleManualChange}
          open={open}
        />
      </div>
      {/* </Grid> */}
    </MuiPickersUtilsProvider>
  );
}
