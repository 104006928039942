import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GetAppIcon from "@material-ui/icons/GetApp";
import doc from "../../../assets/img/about-us-longer.jpg";
import New from "../../../assets/png/new.pdf";
import { Item } from "devextreme-react/accordion";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    width: "100%",
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    /// padding: theme.spacing(12),
    // width: "500px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ImagePopUp({ open, handleClickOpen, handleClose, currentItem }) {
  const classes = useStyles();
  const [maxWidth, setMaxWidth] = React.useState("md");

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        //  fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ classes: { root: classes.root1 } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="close"
              //   className={classes.closeButton}
              onClick={handleClose}
            >
              <ArrowBackIcon style={{ color: "#000" }} />
            </IconButton>
            <Typography variant="h6">{currentItem?.file_name}</Typography>
          </div>
          <a href={currentItem?.file} target="blank" download style={{ textDecoration: "none" }}>
            <Button variant="text" color="primary" startIcon={<GetAppIcon />} style={{ display: "flex" }}>
              Download
            </Button>
          </a>
        </div>
        <DialogContent>
          {currentItem?.file_ext?.includes("pdf") || currentItem?.file_ext?.includes("doc") ? (
            <iframe style={{ width: "100%", height: 800 }} src={currentItem?.file} />
          ) : (
            <>
              <div style={{ marginBottom: 50 }}>
                <img src={currentItem?.file} style={{ borderRadius: 8, boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px" }} />
              </div>
            </>
          )}
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
}
