import { Grid } from "@material-ui/core";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function CompanyCardSkelton() {
  return (
    <div
      style={{
        width: "100%",
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        backgroundColor: "#fff",
        position: "relative",
      }}
    >
      <div style={{ position: "relative", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Skeleton
                animation="wave"
                variant="rect"
                height={110}
                style={{ marginBottom: 10, borderRadius: 4 }}
              />
            </Grid>
            {[1, 2]?.map((item) => (
              <Grid item md={6}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={140}
                  style={{ marginBottom: 10, borderRadius: 4 }}
                />
              </Grid>
            ))}
          </Grid>

          {[1, 2, 3, 4, 5]?.map((item) => (
            <div
              style={{
                marginTop: 15,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                height={24}
                width={130}
                style={{ marginBottom: 10, borderRadius: 4 }}
              />

              <Skeleton
                animation="wave"
                variant="rect"
                height={65}
                style={{ marginBottom: 10, borderRadius: 4, width: "100%" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CompanyCardSkelton;
