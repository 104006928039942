import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";
import Course from "../../Course";

export default class CampusPlacement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        chart: {
          id: "apexchart-example",
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          categories: props?.data?.categories,
          title: {
            text: `${props?.entityInfo?.college}`,
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Nunito",
            },
          },
          labels: {
            rotate: 0,
            // rotateAlways: true,
            // hideOverlappingLabels: true,
            trim: true,
          },
        },
        yaxis: {
          title: {
            text: "Students",
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Nunito",
            },
          },
        },
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            borderRadius: 8,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: true,
        },
      },
      series: props?.data?.series_list,

      // series: [
      //   {
      //     name: "Placed Students Count",
      //     data: [20, 40, 35, 50, 49, 60],
      //     color: "#138496",
      //   },
      // ],
    };
  }
  render() {
    return (
      <Paper
        style={{
          boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
          borderRadius: "8px",
          padding: "30px",
        }}
      >
        <Charst
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={420}
        />
      </Paper>
    );
  }
}
