import { Button, Container, Grid, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../api/Domain/selector";
import CollegeImage from "../../assets/img/company.jpg";
import { ReactComponent as Thank } from "../../assets/svg/Done-rafiki.svg";
import FormFooter from "../../components/Common/FormFooter";
import play_store from "../../assets/svg/google-play.svg";
import app_store from "../../assets/svg/app-store.svg";

const useStyles = makeStyles((_) => ({
  root: {
    // height: "100vh",
    marginTop: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },

  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    height: "270px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  submit: {
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingTop: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingBottom: "10px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "90vh",
  },
  playstore: {
    borderRadius: "8px",
  },
  // thankSvg: {
  //   "> path": {
  //     fill: "white"
  //   }
  // }
}));

function ThankYou({ text, link, fromStudent }) {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);

  return (
    <>
      {/* <StudentFormNavbar /> */}
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Container maxWidth="lg">
          <div className={classes.container}>
            <Thank
              width="35%"
              fill={theme_change?.main ? theme_change?.main : "black"}
            />
            <Typography variant="h4">Thanks for the Submission!!</Typography>
            <br />
            <Typography color="secondary" variant="body2">
              {text}
            </Typography>
            <a href={link}>
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
              >
                Explore GetWork
              </Button>
            </a>
            {fromStudent && (
              <>
                <Hidden smDown>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      // flexDirection: "row",
                      // justifyContent: "flex-start",
                      // alignItems: "center",
                      //margin: "auto",
                      margin: "5px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        // flexDirection: "row",
                        justifyContent: "center",
                        // alignItems: "center",
                        // marginLeft: "450px",
                      }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.getwork_app"
                        target="_blank"
                      >
                        <img
                          id="footerBtn"
                          src={play_store}
                          height="50px"
                          width="160px"
                          alt="playstore"
                          className={classes.playstore}
                        />
                      </a>
                      &nbsp;
                      <a
                        href="https://apps.apple.com/in/app/getwork/id1579830388"
                        target="_blank"
                      >
                        <img
                          id="footerBtn"
                          src={app_store}
                          height="50px"
                          width="160px"
                          alt="playstore"
                          className={classes.playstore}
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Hidden>
                <Hidden smUp>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      // flexDirection: "row",
                      // justifyContent: "flex-start",
                      // alignItems: "center",
                      margin: "50px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        // flexDirection: "row",
                        // justifyContent: "flex-start",
                        // alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.getwork_app"
                        target="_blank"
                      >
                        <img
                          id="footerBtn"
                          src={play_store}
                          height="50px"
                          width="160px"
                          alt="playstore"
                          className={classes.playstore}
                        />
                      </a>
                      &nbsp;
                      <a
                        href="https://apps.apple.com/in/app/getwork/id1579830388"
                        target="_blank"
                      >
                        <img
                          id="footerBtn"
                          src={app_store}
                          height="50px"
                          width="160px"
                          alt="playstore"
                          className={classes.playstore}
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Hidden>
                <br />
                <br />
              </>
            )}
          </div>
          {/* <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            {/* <img src={thank} height="70%" alt="thank-you" /> */}

          {/* </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >

          </Grid>
        </Grid> */}
        </Container>
        <FormFooter />
      </div>
    </>
  );
}

export default ThankYou;
