import DateFnsUtils from "@date-io/date-fns";
import {
  Breadcrumbs,
  Button,
  Chip,
  //FormControl,
  FormControlLabel,
  Grid,
  //InputLabel,
  // MenuItem,
  Paper,
  Radio,
  RadioGroup,
  //Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import TimePicker from '@mui/x-date-pickers/TimePicker'
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { min } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import {
  CompanyGet,
  createInterview,
  getListofStudents,
  jobProfileGet,
  jobRoundGet,
  INTERVIEW_STUDENTS,
} from "../../api/Assignment/action";
import {
  collegeDetailSelectorFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import Editor from "../../components/Editor";
import AutoFillEmail from "../../components/Input/AutoFillEmail";
import Calendar from "../../components/Input/Calendar/index2";
import MuiCustomTheme from "../../components/NewMUITheme/MuiCustomTheme";
import ListStudents from "../../components/Table/StudentsTable/ListStudents";

const NewFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  //height: "49px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  "& label.Mui-focused": {
    //  color: "#C4C4C4",
  },
  "& label": {
    color: "#7e7e7e",
  },
  "& .MuiInput-underline:after": {
    //borderBottomColor: "#C4C4C4",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&:hover fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&.Mui-focused fieldset": {
      //borderColor: "#C4C4C4",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    height: "50px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
  },
  btn: {
    borderRadius: "8px",
    // height: "100%",
    padding: "10px",
    height: "48px",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginRight: "10px",
    boxShadow: "none",
    //width: "100%",
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },

  link: {
    boxShadow: "none",
    // border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    //  padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    // borderColor: theme.palette.primary.main,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "8px",
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  move: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "48px",
    paddingLeft: "20px",
    marginLeft: "10px",
    paddingRight: "20px",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  interview: {
    borderRadius: "8px",
    marginTop: "20px",
    width: "70%",
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function handleClick(event) {
  event.preventDefault();
  // console.info("You clicked a breadcrumb.");
}

export default function CreateInterview() {
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [value, onChange] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [time, setTime] = useState(null);

  const [data, setData] = useState({
    companyid: null,
    jobProfileId: null,
    jobProfileName: "",
    deadline: null,
    time: "",
    jobRound: null,
    title: "",
    interviewType: "Online",
    link: "Auto generate",
    meetLink: "",
    address: null,
    slotinterval: "",
    description: "",
    endTime: "",
    companyName: "",
    jobRoundName: "",
  });
  const [htmlDesc, setHtmlDesc] = useState(null);
  const [editor, setEditor] = useState(null);

  const companyList = useSelector((state) => state?.assignment.companyData);
  const jobprofileList = useSelector(
    (state) => state?.assignment.jobProfileData
  );
  const jobRoundList = useSelector((state) =>
    state?.assignment?.jobRound ? state?.assignment?.jobRound : []
  );
  // const studentList = useSelector((state) =>
  //   state?.assignment?.allstudents ? state?.assignment?.allstudents : []
  // );
  const [studentList, setStudentList] = useState([]);

  const collegeDetail = useSelector(collegeDetailSelectorFn);

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = `api/assignment/company_job_dropdown/?is_remove_off_campus=${true}`;
    dispatch(CompanyGet(alert, history, location, url));
  }, []);

  useEffect(() => {
    if (data.companyid) {
      const url = `api/assignment/company_job_dropdown/?company_id=${data.companyid}`;
      dispatch(jobProfileGet(alert, history, location, url));
      dispatch({
        type: INTERVIEW_STUDENTS,
        payload: [],
      });
      setStudentList([]);
      setData({
        ...data,
        title: "",
      });
    }
  }, [data.companyid]);

  useEffect(() => {
    if (data.jobProfileId) {
      dispatch(jobRoundGet(alert, history, location, data.jobProfileId));
      if (data.jobProfileId && data.jobRound) {
        dispatch(
          getListofStudents(
            alert,
            history,
            location,
            data.jobProfileId,
            data.jobRound,
            setStudentList
          )
        );
      }
    }
  }, [data.jobProfileId, data.jobRound]);

  const handleDelete = (index) => {
    let array = [...emails];
    array.splice(index, 1);
    setEmails(array);
  };

  useEffect(() => {
    calculateEndTime();
  }, [data.time, data.slotinterval]);

  const calculateEndTime = () => {
    var len = selectedStudent.length;
    var min_fixed;

    var min = len * data.slotinterval;

    var start_time = String(data.time + ":00");
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }

    var piece = start_time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +min;

    setData({
      ...data,
      endTime: D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60),
    });
  };

  useEffect(() => {
    perObjectTime();
  }, [data.time, data.endTime]);

  const perObjectTime = () => {
    let endTime = calculateTime(data.time, data.slotinterval, 1);
    selectedStudent.forEach((item, index) => {
      item.start_date = String(
        value.getFullYear() +
          "-" +
          (value.getMonth() + 1) +
          "-" +
          value.getDate() +
          " " +
          calculateTime(data.time, data.slotinterval, index)
      );
      item.end_date = String(
        value.getFullYear() +
          "-" +
          (value.getMonth() + 1) +
          "-" +
          value.getDate() +
          " " +
          calculateTime(endTime, data.slotinterval, index)
      );
      item.slot_number = index + 1;
    });
  };

  const calculateTime = (start, min, i) => {
    min = min * i;
    var start_time = String(start + ":00");
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }

    var piece = start_time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +min;
    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        selectedStudent.length >= 1 &&
        data.deadline &&
        data.slotinterval &&
        data.time
      ) {
        var des = selectedStudent.map((item) => {
          console.log("item", item);
          return `<p><b>${item.name}  (${item?.enrollment_id}) ${moment(
            item.start_date
          ).format("LT")} - ${moment(item.end_date).format("LT")}</b></p>`;
        });
      }
      if (des?.length >= 1) {
        setHtmlDesc(String(des));
      }
    }, 2000);
  }, [selectedStudent, data.deadline, data.slotinterval, data.time]);

  const createinterview = () => {
    let collegeIdsList = selectedStudent?.map((item) => {
      return item["college_id"];
    });
    const collegeIds = Array.from(new Set(collegeIdsList));
    if (data.interviewType === "ONLINE") {
      if (
        data.companyid &&
        data.jobProfileId &&
        data.jobRound &&
        data.deadline &&
        data.endTime &&
        data.interviewType &&
        data.title &&
        emails.length >= 1 &&
        data.time?.length &&
        data.slotinterval.length &&
        data.slotinterval[0] > 0 &&
        selectedStudent.length >= 1
      ) {
        const payload = {
          job_id: data.jobProfileId,
          company_id: data.companyid,
          start_date: `${data.deadline} ${data.time}`,
          end_date: `${moment(`${data.deadline} ${data.time}`)
            .add(data?.slotinterval * selectedStudent.length, "minutes")
            .format("YYYY-MM-DD HH:mm")}`,
          user_id: collegeDetail.user_id,
          type: data.interviewType,
          address: data.address,
          student_detail: selectedStudent,
          custom_email: emails,
          round: data.jobRound,
          description: editor,
          college_id: collegeIds,
          custom_link: data.meetLink,
          title: data.title,
        };
        setLoading(true);
        dispatch(
          createInterview(setLoading, payload, alert, history, location)
        );
      } else {
        alert.error("Please fill all the details");
      }
    } else {
      if (
        data.companyid &&
        data.jobProfileId &&
        data.jobRound &&
        data.deadline &&
        data.endTime &&
        data.interviewType &&
        data.title &&
        data.address &&
        data.time?.length &&
        data.slotinterval.length &&
        data.slotinterval[0] > 0 &&
        selectedStudent.length >= 1
      ) {
        const payload = {
          job_id: data.jobProfileId,
          company_id: data.companyid,
          start_date: `${data.deadline} ${data.time}`,
          end_date: `${moment(`${data.deadline} ${data.time}`)
            .add(data?.slotinterval * selectedStudent.length, "minutes")
            .format("YYYY-MM-DD HH:mm")}`,
          user_id: collegeDetail.user_id,
          type: data.interviewType,
          address: data.address,
          student_detail: selectedStudent,
          custom_email: emails,
          round: data.jobRound,
          description: editor,
          college_id: collegeIds,
          custom_link: data.meetLink,
          title: data.title,
        };
        setLoading(true);
        dispatch(
          createInterview(setLoading, payload, alert, history, location)
        );
      } else {
        alert.error("Please fill all the details");
      }
    }
  };
  const classes = useStyles();
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to="/interview-calendar"
          //  onClick={handleClick}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" variant="body2">
            Interviews
          </Typography>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          <Typography color="secondary" variant="body2">
            Schedule New Interview
          </Typography>
        </Link>
      </Breadcrumbs>
      <Paper className={classes.interview}>
        <div>
          <div style={{ padding: "40px" }}>
            <Grid container spacing={2} style={{ marginTop: "0px" }}>
              <Grid item xs={12}>
                <MuiCustomTheme>
                  <NewFormControl
                    variant="outlined"
                    //className={classes.formControl}
                  >
                    <InputLabel
                      //className={classes.floatingLabelFocusStyle}
                      required
                      style={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                    >
                      Select Company
                    </InputLabel>
                    <Select
                      value={data?.companyName ? data?.companyName : ""}
                      label="Select Company"
                      // inputProps={{
                      //   style: {
                      //     padding: "16px",
                      //     backgroundColor: "#fff",
                      //     borderRadius: "30px",
                      //   },
                      // }}
                      style={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: 300, borderRadius: "8px" },
                        },
                      }}
                    >
                      {/* <MenuItem 
                      aria-label="None" 
                      value=""
                       /> */}
                      {companyList?.length > 0 &&
                        companyList?.map((item) => {
                          return (
                            <MenuItem
                              onClick={() => {
                                setData({
                                  ...data,
                                  companyid: item.id,
                                  companyName: item.name,
                                  jobRoundName: "",
                                  jobRound: null,
                                  jobProfileName: "",
                                  // title: '',
                                });
                                // setStudentList([]);
                              }}
                              name={item.id}
                              value={item.name}
                            >
                              <Typography variant="body2" noWrap>
                                {item.name ? (
                                  <>
                                    {item.name}{" "}
                                    <span style={{ color: "gray" }}>
                                      (Open Jobs:{" "}
                                      {item?.live_job_count
                                        ? item?.live_job_count
                                        : 0}
                                      )
                                    </span>
                                  </>
                                ) : null}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </NewFormControl>
                </MuiCustomTheme>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "5px" }}>
                <MuiCustomTheme>
                  <NewFormControl
                    variant="outlined"
                    // className={classes.formControl}
                  >
                    <InputLabel
                      className={classes.floatingLabelFocusStyle}
                      required
                      style={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                    >
                      Select Job Profile
                    </InputLabel>
                    <Select
                      value={data.jobProfileName}
                      label="Select Job Profile"
                      // inputProps={{
                      //   style: {
                      //     padding: "16px",
                      //     backgroundColor: "#fff",
                      //     borderRadius: "30px",
                      //   },
                      // }}
                      style={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: 300, borderRadius: "8px" },
                        },
                      }}
                    >
                      {/* <MenuItem aria-label="None" value="" /> */}
                      {jobprofileList?.map((item) => {
                        return (
                          <MenuItem
                            onClick={() =>
                              setData({
                                ...data,
                                jobProfileId: item.id,
                                jobProfileName: item.job_title,
                                title: "",
                              })
                            }
                            name={item.id}
                            value={item.job_title}
                          >
                            <Typography variant="body2" noWrap>
                              {item.job_title}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </NewFormControl>
                </MuiCustomTheme>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "5px" }}>
                <MuiCustomTheme>
                  <NewFormControl
                    variant="outlined"
                    // className={classes.formControl}
                  >
                    <InputLabel
                      // className={classes.floatingLabelFocusStyle}
                      required
                      style={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                    >
                      Select Job Round
                    </InputLabel>
                    <Select
                      value={data.jobRoundName}
                      label="Select Job Round"
                      // inputProps={{
                      //   style: {
                      //     padding: "16px",
                      //     backgroundColor: "#fff",
                      //     borderRadius: "30px",
                      //   },
                      // }}
                      style={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: 300, borderRadius: "8px" },
                        },
                      }}
                    >
                      {/* <MenuItem aria-label="None" value="" /> */}
                      {jobRoundList?.map((item) => {
                        return (
                          <MenuItem
                            onClick={() =>
                              setData({
                                ...data,
                                jobRound: item.id,
                                jobRoundName: item.name,
                                title: `${item.name} Round Interview`,
                              })
                            }
                            value={item.name}
                            name={item.id}
                          >
                            <Typography variant="body2" noWrap>
                              {item.name}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </NewFormControl>
                </MuiCustomTheme>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "5px" }}>
                <TextField
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  required
                  label="Interview Title"
                  value={`${data.title}`}
                  onChange={(event) =>
                    setData({
                      ...data,
                      title: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={10} style={{ marginTop: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Calendar
                    title="Date"
                    // selectedDate={moment(deadline).format("YYYY-MM-DD")}
                    selectedDate={deadline}
                    setSelectedDate={(date) => {
                      setData({
                        ...data,
                        deadline: moment(date).format("YYYY-MM-DD"),
                      });
                      setDeadline(date);
                    }}
                    readOnly={true}
                    minDate={new Date()}
                  />
                  {/* <Calendar
                    // selectedDate={data.deadline}
                    selectedDate={moment(data?.deadline).format("YYYY-MM-DD")}

                    title="Date"
                    onDateChange={(date) =>
                      setData({
                        ...data,
                        deadline: moment(date).format("YYYY-MM-DD"),
                      })
                    }
                  /> */}
                  <div
                    style={{
                      width: "170px",
                      display: "flex",
                      marginLeft: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          label="Select Time"
                          emptyLabel="hh:mm"
                          value={time}
                          required
                          onChange={(date) => {
                            setData({
                              ...data,
                              time: moment(date).format("HH:mm"),
                            });
                            // setTime(moment(date).format("HH:mm"));
                            setTime(date);
                          }}
                        />
                      </MuiPickersUtilsProvider>

                      {/* <TimePickerComponent
                          value={data.endTime}
                          style={{
                            border: "none",
                            borderBottom: "1px solid #fff",
                          }}
                          placeholder="Select Start time"
                          onChange={(e) => {
                            setData({
                              ...data,
                              endTime: moment(e.target.value).format(
                                "hh:mm:ss"
                              ),
                            });
                          }}
                        /> */}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">List of Students</Typography>
                <br />
                <ListStudents
                  data={studentList ? studentList : []}
                  onSelect={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = studentList.filter((row) =>
                      selectedIDs.has(row.id)
                    );
                    setSelectedStudent(selectedRowData);
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Typography variant="h6">Invite Interviewer</Typography>
              </Grid>

              <Grid item xs={9} className={classes.grid}>
                <div className={classes.paper}>
                  <AutoFillEmail
                    value={email}
                    title=""
                    onchange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <br />
                  {emails.map((option, index) => (
                    <Chip
                      className={classes.chip}
                      variant="outlined"
                      label={option}
                      color="primary"
                      onDelete={() => handleDelete(index)}
                    />
                  ))}
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  onClick={() => {
                    var re = /\S+@\S+\.\S+/;
                    const result = re.test(email);
                    if (result === true) {
                      emails.push(email);
                      setEmail("");
                    } else {
                      alert.error("Invalid Emaid id");
                    }
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue="Online"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                    name="customized-radios"
                    value={data.interviewType}
                    onChange={(event) =>
                      setData({
                        ...data,
                        interviewType: event.target.value,
                        link: "",
                        meetLink: "",
                        address: null,
                      })
                    }
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginRight: "10px" }}
                      variant="body2"
                    >
                      Interview Type
                    </Typography>
                    <FormControlLabel
                      value="ONLINE"
                      control={<StyledRadio />}
                      label={<Typography variant="body2">Online</Typography>}
                    />
                    <FormControlLabel
                      value="OFFLINE"
                      control={<StyledRadio />}
                      label={<Typography variant="body2">Offline</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {data.interviewType === "ONLINE" ? (
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      defaultValue="Admin"
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                      name="customized-radios"
                      value={data.link}
                      onChange={(event) =>
                        setData({
                          ...data,
                          link: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="Auto generate"
                        control={<StyledRadio />}
                        label={
                          <Typography variant="body2">
                            Auto Meet Link Generate
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Enter link"
                        control={<StyledRadio />}
                        label={
                          <Typography variant="body2">
                            Enter Your Link
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={9} className={classes.grid}>
                {data.interviewType === "ONLINE" &&
                data.link === "Enter link" ? (
                  <div className={classes.paper}>
                    <Paper component="form" className={classes.link}>
                      <TextField
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        fullWidth
                        label="Add Meet Link"
                        value={data.meetLink}
                        onChange={(event) =>
                          setData({
                            ...data,
                            meetLink: event.target.value,
                          })
                        }
                      />
                    </Paper>
                  </div>
                ) : data.interviewType === "ONLINE" &&
                  data.link === "Auto generate" ? (
                  <Typography variant="body2">
                    Interview link has been generated, you can see the link in
                    your mail after click on Schedule Interview button.
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={9} className={classes.grid}>
                {data.interviewType === "OFFLINE" ? (
                  <div className={classes.paper} style={{ marginTop: "-20px" }}>
                    <Typography variant="h6" style={{ marginBottom: "15px" }}>
                      {" "}
                      Add Location{" "}
                    </Typography>
                    <Paper component="form" className={classes.link}>
                      <TextField
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        fullWidth
                        label="Enter Address"
                        value={data.address}
                        onChange={(event) =>
                          setData({
                            ...data,
                            address: event.target.value,
                          })
                        }
                      />
                    </Paper>
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                marginTop: "-10px",
                flexDirection: "column",
              }}
            >
              <br />
              {/* <form className={classes.container}> */}
              <TextField
                id="time"
                label="Interview Slot Duration (in mins) "
                variant="outlined"
                style={{ width: "300px" }}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: min, // 5 min
                }}
                value={data?.slot_interval}
                onChange={(event) => {
                  setData({
                    ...data,
                    slotinterval: event.target.value,
                  });
                }}
              />

              {/* </form> */}

              <div style={{ marginTop: "30px" }}>
                <Editor state={editor} setState={setEditor} desc={htmlDesc} />
              </div>

              {/* <TextField
                  id="outlined-multiline-static"
                  label="Add Description"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  multiline
                  style={{ width: "100%" }}
                  rows={6}
                  variant="outlined"
                  value={data.description ? data.description : ""}
                  onChange={(event) =>
                    setData({
                      ...data,
                      description: event.target.value,
                    })
                  }
                /> */}
            </div>
          </div>
          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_interview"
          ) && (
            <div
              style={{
                marginTop: "0px",
                padding: "25px",
                marginLeft: "10px",
                display: "flex",
                paddingLeft: "25px",
                justifyContent: "flex-start",
                flexDirection: "row",
                marginLeft: "15px",
              }}
            >
              <Link to="/interview-calendar" style={{ textDecoration: "none" }}>
                <Button
                  variant="outlined"
                  className={classes.btn}
                  //onClick={handleClose}
                  color="primary"
                >
                  Cancel
                </Button>
              </Link>
              {/* <Link to="/interview" style={{ textDecoration: "none" }}> */}
              <Button
                onClick={createinterview}
                variant="contained"
                style={{ minWidth: "220px" }}
                color="primary"
                size="small"
                className={classes.btn}
              >
                {loading ? (
                  <CustomButtonCircularProgress />
                ) : (
                  <> Interview Schedule</>
                )}{" "}
              </Button>
              {/* </Link> */}
            </div>
          )}
        </div>
      </Paper>
      {/* </Layout> */}
    </>
  );
}
