import { GET_THEME_DETAILS, LOGGED_IN, COLLEGE_DETAILS, IS_EDUCATION_GROUP, IS_GRADE_SYSTEM, MAIN_DETAILS_ALL } from "./action";

export const domainData = (state = {}, action) => {
  switch (action.type) {
    case GET_THEME_DETAILS:
      return { ...state, themeDetails: action.payload };
    case LOGGED_IN:
      return { ...state, isLoggedIn: action.payload };
    case IS_EDUCATION_GROUP:
      return { ...state, isEducationGroup: action.payload };
    case COLLEGE_DETAILS:
      return { ...state, collegeDetails: action.payload };
    case IS_GRADE_SYSTEM:
      return { ...state, isGradeSystem: action.payload };
    case MAIN_DETAILS_ALL:
      return { ...state, allData: action.payload };
    default:
      return state;
  }
};
