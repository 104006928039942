import { Box, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  circularProgressColored: {
    color: theme.palette.primary.main,
  },
}));

function CustomCircularProgress() {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop="150px"
    >
      <CircularProgress className={classes.circularProgressColored} size={50} />
    </Box>
  );
}

export default CustomCircularProgress;
