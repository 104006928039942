import { combineReducers } from "redux";
import { AccessControls, AllUserRoles } from "../api/AccessControls/reducer";
import { assignment } from "../api/Assignment/reducer";
import { Departments, MediaFiles } from "../api/Chat/reducer";
import { commonData } from "../api/Common/reducer";
import {
  CompanyData,
  CompanyInviteLink,
  CompanyJobData,
  PublicCompany,
} from "../api/Company/reducer";
import {
  CompanyFields,
  CompanyUploader,
} from "../api/CompanyManagementForm/reducer";
import {
  EJDFields,
  EJDSelectedFields,
  SingleEJDField,
} from "../api/EJD/reducer";
import { Cards, Helps, Questions } from "../api/Help/reducer";
import { JobFields, JobUploader } from "../api/JobManagementForm/reducer";
import {
  AllCompany,
  AllJobRoles,
  AllJobRounds,
  AllJobSegments,
  AllLocations,
  JobData,
  GruopJobData,
  SingleJobData,
  AllSkills,
  JobPlacement,
  DisableFieldsPostJob,
  GroupCollegeList,
} from "../api/JobPost/reducer";
import { StudentData } from "../api/ManageStudents/reducer";
import { PlacementCycles } from "../api/PlacementCycles/reducer";
import { UserDetails } from "../api/SignIn/reducer";
import {
  StudentFields,
  StudentPublicFields,
  StudentUploader,
} from "../api/StudentManagementForm/reducer";
import { TeamData } from "../api/TeamManage/reducer";
import { domainData } from "../api/Domain/reducer";
import { collegeProfile } from "../api/CollegeProfile/reducer";
import { rulesTnp } from "../api/TnpRules/reducer";
import { overview } from "../api/Overview/reducer";
import { report } from "../api/Reports/reducer";
import { EDGCommon } from "../api/University/APIs/reducer";
import { billingData } from "../api/Billing/reducer";
import { feed } from "../api/NoticeBoard/reducer";
import { creditData } from "../api/Credits/reducer";
const appReducer = combineReducers({
  // ThemeDetails,
  // UpdateTheme,
  AccessControls,
  AllUserRoles,
  StudentUploader,
  StudentFields,
  CompanyUploader,
  CompanyFields,
  JobFields,
  JobUploader,
  PlacementCycles,
  CompanyData,
  CompanyInviteLink,
  commonData,
  AllCompany,
  AllJobSegments,
  AllJobRoles,
  AllLocations,
  AllJobRounds,
  SingleJobData,
  JobData,
  GruopJobData,
  UserDetails,
  Departments,
  MediaFiles,
  EJDFields,
  EJDSelectedFields,
  SingleEJDField,
  StudentData,
  assignment,
  TeamData,
  StudentPublicFields,
  Helps,
  Cards,
  Questions,
  domainData,
  collegeProfile,
  AllSkills,
  JobPlacement,
  rulesTnp,
  overview,
  report,
  CompanyJobData,
  EDGCommon,
  billingData,
  PublicCompany,
  feed,
  DisableFieldsPostJob,
  creditData,
  GroupCollegeList,
});

const initialState = {
  PlacementCycles: {},
  AccessControls: {},
  AllUserRoles: {},
  StudentUploader: {},
  StudentFields: {},
  CompanyUploader: {},
  CompanyFields: {},
  JobFields: {},
  JobUploader: {},
  CompanyData: {},
  CompanyInviteLink: {},
  commonData: {},
  AllCompany: {},
  AllJobSegments: {},
  AllJobRoles: {},
  AllLocations: {},
  AllJobRounds: {},
  SingleJobData: {},
  JobData: {},
  GruopJobData: {},
  Departments: {},
  MediaFiles: {},
  EJDFields: {},
  EJDSelectedFields: {},
  UserDetails: {},
  SingleEJDField: {},
  StudentData: {},
  TeamData: {},
  StudentPublicFields: {},
  Helps: {},
  Cards: {},
  Questions: {},
  EDGPlacementCycles: {},
  assignment: {
    interviewList: [],
  },
  PublicCompany: {},
  DisableFieldsPostJob: {},
  GroupCollegeList: {},
};

const rootReducer = (state = initialState, action) => {
  if (action.type === "USER_LOGOUT") {
    const { domainData } = state;
    state = { domainData };
  }
  return appReducer(state, action);
};

export default rootReducer;
