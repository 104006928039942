import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Tooltip,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AllStudentActionBar from "../../Input/StudentActionBar/AllStudentActionBar";
import CompanyTable from "../../Table/CompanyTable";
import { Link } from "react-router-dom";
import { truncate } from "../../../utility/ellipsify";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import {
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import { useAlert } from "react-alert";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    color: "red",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    color: "red",
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function AllStudentTab({
  loading,
  filters,
  setFilters,
  setClickSearchButton,
  setSelectedRowsData,
  selectedRowsData,
  clickSearchButton,
  navbarData,
  setApplyClickedScore,
  filterDataText,
  setFilterDataText,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  navbarDataPlacementCycle,
  filterListPlacementCycle,
  placementCycleData,
  setSelectedID,
  selectedID,
  checkSelectedGroup = () => {},
  clearData = () => {},
}) {
  const classes = useStyles();

  const isGradeSystem = useSelector(isGradeSystemFn);
  const studentList = useSelector((state) => state?.StudentData?.details);

  const collegeListSelector = useSelector(collegeListSelectorFn);

  const [tempStudentList, setTempStudentList] = useState([]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const alert = useAlert();
  useEffect(() => {
    if (
      studentList?.length > 0 &&
      groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
    ) {
      var arr = [...studentList];
      arr.forEach((item) => {
        item["college_name"] = checkCollegeName(item?.college_id[0]);
      });
      setTempStudentList(arr);
    } else setTempStudentList([]);
  }, [studentList, studentList?.length]);

  const checkCollegeName = (id) => {
    var name = "";
    collegeListSelector.map((item) => {
      if (item?.id === id) name = item?.name;
    });
    return name;
  };
  const columns = [
    // { field: "id", headerName: "No", width: 100 },
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            display: "flex",
            fontSize: "14px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "8px",
              marginRight: "10px",
              width: "8px",
              backgroundColor:
                params.row.status === "ACTIVE" ? "#51c03e" : "#f64a3f",
              borderRadius: "50%",
            }}
          />
          {params.row.enrollment}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              fontSize: "14px",
            }}
          >
            {" "}
            {params?.row?.user_id ? (
              <Grid item xs zeroMinWidth>
                <Link
                  to={`/student-profile/${params?.row?.name?.replace(
                    / /g,
                    "_"
                  )}-${params?.row?.user_id}`}
                  style={{ textDecoration: "none", display: "contents" }}
                >
                  {" "}
                  <Tooltip title={params?.row?.name} placement="right">
                    <Typography
                      color="primary"
                      noWrap
                      style={{ fontSize: "14px" }}
                    >
                      {params?.row?.name}
                    </Typography>
                  </Tooltip>
                </Link>
              </Grid>
            ) : (
              <Grid item xs zeroMinWidth>
                {/* <Link
                  to={`/student-profile/${params?.row?.name?.replace(
                    / /g,
                    "_"
                  )}-${params?.row?.user_id}`}
                  style={{ textDecoration: "none", display: "contents" }}
                > */}{" "}
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      alert.error(
                        "Something wrong with this data, kindly connect with GetWork"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
                {/*   </Link> */}
              </Grid>
            )}
          </Grid>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     // margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Tooltip title={params?.row?.email} placement="right">
        //     <Typography color="inherit" style={{ fontSize: "14px" }}>
        //       {params?.row?.email}
        //     </Typography>
        //   </Tooltip>
        // </span>
      ),
    },
    groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && {
      field: "college_name",
      headerName: "College",
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.college_name} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.college_name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "left",
        //     display: "flex",
        //     // margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   <Tooltip title={params?.row?.email} placement="right">
        //     <Typography color="inherit" style={{ fontSize: "14px" }}>
        //       {params?.row?.email}
        //     </Typography>
        //   </Tooltip>
        // </span>
      ),
    },
    {
      field: "phone",
      headerName: "Phone Number",

      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.phone}
        </span>
      ),
      // valueGetter: params =>
      //   `${params.getValue(params.id, "firstName") || ""} ${
      //     params.getValue(params.id, "lastName") || ""
      //   }`,
    },

    {
      field: "degree",
      type: "text",
      headerName: "Degree",
      width: 100,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "7px",
          }}
        >
          Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            margin: "15px",
            fontSize: "14px",
          }}
        >
          {params?.row?.degree?.name}
        </span>
      ),
    },
    {
      field: "course",
      type: "text",
      width: 170,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Course
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            margin: "15px",
            cursor: "pointer",
            fontSize: "14px",
            width: "100%",
          }}
        >
          <Tooltip title={params?.row?.course?.name} placement="right">
            <Typography style={{ fontSize: "14px", width: "100%" }} noWrap>
              {params?.row?.course?.name}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "batch",
      headerName: "Batch",
      // type: "text",
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.batch}
        </span>
      ),
    },

    {
      field: "degree",
      type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.degree?.name} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.degree?.name)}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },

    {
      field: "applied_jobs",
      headerName: "Applied Jobs",
      type: "text",
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "0px",
          }}
        >
          Applied Jobs
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.applied_jobs}
        </span>
      ),
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      width: 120,
      type: "text",

      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          {isGradeSystem ? "CGPA" : "Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {isGradeSystem ? params?.row?.cgpa : params?.row?.percentage}
        </span>
      ),
    },
  ];

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.params === "pending_students" ||
      filters?.current_tab?.params === "rejected_students"
    );
  };

  const checkForRejected = () => {
    return filters?.current_tab?.params === "rejected_students";
  };

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    setFilters({ ...filters, status: newValue });
    setFilterDataText("No Data Found");
  };

  useEffect(() => {
    if (navbarData?.length) {
      setFilters({ ...filters, status: "ALL" });
    }
  }, [navbarData?.length]);

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);

  const checkBoxSelection =
    filters?.current_tab?.params === "ALLREADY" ? false : true;

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={filters?.status}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="ant example"
        >
          {navbarDataPlacementCycle?.length > 0 &&
            navbarDataPlacementCycle?.map((item) => (
              <NewTab
                value={String(item.params)}
                label={`${item.heading}(${item.count})`}
                onClick={() => {
                  setFilters({
                    ...filters,
                    current_tab: item,
                    status: String(item.params),
                  });
                  clearData();
                  setFilterDataText("No Data Found");
                }}
                wrapped
                {...a11yProps(String(item.params))}
              />
            ))}
        </NewTabs>
      </AppBar>

      <AllStudentActionBar
        filters={filters}
        setFilters={setFilters}
        setClickSearchButton={setClickSearchButton}
        selectedRowsData={selectedRowsData}
        clickSearchButton={clickSearchButton}
        setSelectedRowsData={setSelectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        //filterList={filterList}
        studentList={studentList}
        setApplyClickedScore={setApplyClickedScore}
        collegeId={collegeId}
        userRolesPermission={userRolesPermission}
        setCallNavbar={setCallNavbar}
        checkSelectedGroup={checkSelectedGroup}
        checkForPendingRejected={checkForPendingRejected}
        filterListPlacementCycle={filterListPlacementCycle}
      />
      <br />
      {/*    {navbarData?.length > 0 &&
        navbarData?.map((item, index) => {
          return ( */}
      <TabPanel
      /*   value={String(filters?.status)}
              index={String(item.params)} */
      >
        <CompanyTable
          loading={loading}
          /*  list={
                  groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
                    ? tempStudentList
                    : studentList
                } */
          list={placementCycleData}
          columns={columns}
          checkBoxSelection={checkForRejected() ? false : true}
          setSelectedRowsData={setSelectedRowsData}
          selectedRowData={selectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          filterDataText={filterDataText}
          checkBoxSelection={checkBoxSelection}
        />{" "}
      </TabPanel>
      {/*   );
        })} */}
    </div>
  );
}
