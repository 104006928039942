import { Typography, Tooltip, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { truncate } from "../../../utility/ellipsify";

export const columns = [
  // { field: "id", headerName: "No", width: 100 },

  {
    field: "name",
    headerName: "Name",
    width: 160,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/student-profile/${params?.row?.user_id}`}
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params?.row?.name} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.name}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //  margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   <Link
      //     to={`/student-profile/${params?.row?.user_id}`}
      //     style={{ textDecoration: "none" }}
      //   >
      //     <Tooltip title={params?.row?.name} placement="right">
      //       <Typography color="primary" style={{ fontSize: "14px" }}>
      //         {params?.row?.name}
      //       </Typography>
      //     </Tooltip>
      //   </Link>
      // </span>
    ),
  },
  {
    field: "enrollment_id",
    headerName: "Enrollment Id",
    width: 150,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Enrollment Id
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.enrollment_id}
      </span>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    // type: "Email",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "65px",
        }}
      >
        Email
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          <Grid item xs zeroMinWidth>
            <Tooltip title={params?.row?.email} placement="right">
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params?.row?.email}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     // margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   <Tooltip title={params?.row?.email} placement="right">
      //     <Typography color="inherit" style={{ fontSize: "14px" }}>
      //       {params?.row?.email}
      //     </Typography>
      //   </Tooltip>
      // </span>
    ),
  },
  {
    field: "phone",
    headerName: "Phone Number",

    width: 160,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Phone Number
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.phone}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },
  {
    field: "batch",
    headerName: "Batch",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        Batch
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.batch}
      </span>
    ),
  },
  {
    field: "degree",
    type: "text",
    headerName: "Degree",
    width: 100,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "7px",
        }}
      >
        Degree
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.branch}
      </span>
    ),
  },

  {
    field: "degree",
    type: "text",
    width: 110,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Degree
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Tooltip title={params?.row?.degree?.name} placement="right">
          <Typography style={{ fontSize: "14px" }}>
            {truncate(params?.row?.degree?.name)}
          </Typography>
        </Tooltip>{" "}
      </span>
    ),
  },
  {
    field: "course",
    type: "text",
    width: 110,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Course
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          //  margin: "auto",
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        <Tooltip title={params?.row?.course?.name} placement="right">
          <Typography style={{ fontSize: "14px" }}>
            {truncate(params?.row?.course?.name)}
          </Typography>
        </Tooltip>
      </span>
    ),
  },

  {
    field: "status",
    headerName: "Status",
    // type: "text",
    width: 110,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "20px",
        }}
      >
        Status
      </strong>
    ),
    // renderCell: (params) => (
    //   <span
    //     style={{
    //       textAlign: "center",
    //       display: "flex",
    //       margin: "auto",
    //       fontSize: "14px",
    //     }}
    //   >
    //     {params.row.status}
    //   </span>
    // ),

    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "8px",
            marginRight: "10px",
            width: "8px",
            backgroundColor:
              params.row.status === "ACTIVE" ? "#51c03e" : "#f64a3f",
            borderRadius: "50%",
          }}
        />

        {params.row.status === "ACTIVE" ? "Active" : "Blocked"}
      </span>
    ),
  },
  {
    field: "applied_jobs",
    headerName: "Applied Jobs",
    type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "0px",
        }}
      >
        Applied Jobs
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.applied_jobs}
      </span>
    ),
  },
  {
    field: "cgpa",
    width: 120,
    type: "text",

    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        CGPA
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.cgpa}
      </span>
    ),
  },
  {
    field: "candidate_status",
    width: 200,
    type: "text",

    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        Candidate Status
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.candidate_status}
      </span>
    ),
  },
];

export const columns2 = [
  // { field: "id", headerName: "No", width: 100 },

  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "65px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            {/* <Link to={`/pending-student-profile/${params?.row?.user_id}`} style={{ textDecoration: "none", display: "contents" }}> */}{" "}
            <Tooltip title={params?.row?.name} placement="right">
              {/* <Typography color="primary" noWrap style={{ fontSize: "14px" }}> */}
              <span> {params?.row?.name}</span>
              {/* </Typography> */}
            </Tooltip>
            {/* </Link> */}
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //  margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   <Link
      //     to={`/student-profile/${params?.row?.user_id}`}
      //     style={{ textDecoration: "none" }}
      //   >
      //     <Tooltip title={params?.row?.name} placement="right">
      //       <Typography color="primary" style={{ fontSize: "14px" }}>
      //         {params?.row?.name}
      //       </Typography>
      //     </Tooltip>
      //   </Link>
      // </span>
    ),
  },
  {
    field: "enrollment_id",
    headerName: "Enrollment Id",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "40px",
        }}
      >
        Enrollment Id
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.enrollment_id}
      </span>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    // type: "Email",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "65px",
        }}
      >
        Email
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          <Grid item xs zeroMinWidth>
            <Tooltip title={params?.row?.email} placement="right">
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params?.row?.email}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     // margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   <Tooltip title={params?.row?.email} placement="right">
      //     <Typography color="inherit" style={{ fontSize: "14px" }}>
      //       {params?.row?.email}
      //     </Typography>
      //   </Tooltip>
      // </span>
    ),
  },
  {
    field: "phone",
    headerName: "Phone Number",

    width: 160,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Phone Number
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.phone}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },
  {
    field: "batch",
    headerName: "Batch",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        Batch
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.batch}
      </span>
    ),
  },
  {
    field: "degree",
    type: "text",
    headerName: "Degree",
    width: 100,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "7px",
        }}
      >
        Degree
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.branch}
      </span>
    ),
  },

  {
    field: "degree",
    type: "text",
    width: 110,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Degree
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Tooltip title={params?.row?.degree?.name} placement="right">
          <Typography style={{ fontSize: "14px" }}>
            {truncate(params?.row?.degree?.name)}
          </Typography>
        </Tooltip>{" "}
      </span>
    ),
  },
  {
    field: "course",
    type: "text",
    width: 110,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Course
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          //  margin: "auto",
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        <Tooltip title={params?.row?.course?.name} placement="right">
          <Typography style={{ fontSize: "14px" }}>
            {truncate(params?.row?.course?.name)}
          </Typography>
        </Tooltip>
      </span>
    ),
  },

  // {
  //   field: "status",
  //   headerName: "Status",
  //   // type: "text",
  //   width: 110,
  //   renderHeader: (params) => (
  //     <strong
  //       style={{
  //         color: "#5a6268",
  //         marginLeft: "20px",
  //       }}
  //     >
  //       Status
  //     </strong>
  //   ),
  //   // renderCell: (params) => (
  //   //   <span
  //   //     style={{
  //   //       textAlign: "center",
  //   //       display: "flex",
  //   //       margin: "auto",
  //   //       fontSize: "14px",
  //   //     }}
  //   //   >
  //   //     {params.row.status}
  //   //   </span>
  //   // ),

  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //         alignItems: "center",
  //       }}
  //     >
  //       <div
  //         style={{
  //           height: "8px",
  //           marginRight: "10px",
  //           width: "8px",
  //           backgroundColor: params.row.status === "ACTIVE" ? "#51c03e" : "#f64a3f",
  //           borderRadius: "50%",
  //         }}
  //       />

  //       {params.row.status === "ACTIVE" ? "Active" : "Blocked"}
  //     </span>
  //   ),
  // },
  // {
  //   field: "applied_jobs",
  //   headerName: "Applied Jobs",
  //   type: "text",
  //   width: 120,
  //   renderHeader: (params) => (
  //     <strong
  //       style={{
  //         color: "#5a6268",
  //         marginLeft: "0px",
  //       }}
  //     >
  //       Applied Jobs
  //     </strong>
  //   ),
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params.row.applied_jobs}
  //     </span>
  //   ),
  // },
  {
    field: "cgpa",
    width: 120,
    type: "text",

    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        CGPA
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.cgpa}
      </span>
    ),
  },
];
