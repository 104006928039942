import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isCollegeGroupFn, isEducationGroupFn, entityInfoSelectorFn } from "../../../api/SignIn/selector";
import ManageTeamActionnBarGroupView from "../../Input/ManageTeamActionBar/groupView";
import GroupTeamTable from "../../Table/GroupTeamTable";
import { columnsCheck } from "./columnGroup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`wrapped-tabpanel-${index}`} aria-labelledby={`wrapped-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ManageJobTabGroups({
  value,
  setValue,
  loading,
  filters,
  setFilters,
  setClickSearchButton,
  setSelectedRowsData,
  selectedRowsData,
  clickSearchButton,
  setUserRoleId,
  designationList,
  collegeId,
  userRolesPermission,
  setCallNavbar,
}) {
  const classes = useStyles();
  const [selectedID, setSelectedID] = React.useState([]);
  const teamList = useSelector((state) => state?.TeamData?.details);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn);
  const history = useHistory();
  const userRoleList = useSelector((state) => state.TeamData.navbar);

  useEffect(() => {
    if (userRoleList?.length > 0) setUserRoleId(userRoleList[0].id);
  }, [userRoleList?.length]);

  // useEffect(() => {
  //   console.log(value);
  // }, [value]);
  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);
  return (
    <div className={classes.root}>
      <ManageTeamActionnBarGroupView
        filters={filters}
        setFilters={setFilters}
        setClickSearchButton={setClickSearchButton}
        selectedRowsData={selectedRowsData}
        clickSearchButton={clickSearchButton}
        setSelectedRowsData={setSelectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        designationList={designationList}
        teamList={teamList}
        collegeId={collegeId}
        userRolesPermission={userRolesPermission}
        setCallNavbar={setCallNavbar}
      />
      <br />

      <GroupTeamTable
        loading={loading}
        list={teamList}
        columns={columnsCheck(isEducationGroup, isCollegeGroup, history, entityInfo)}
        setSelectedRowsData={setSelectedRowsData}
        selectedRowData={selectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
      />

      {/* <TabPanel value={value} index="two">
        <CompanyTable
          loading={loading}
          list={teamList}
          columns={columns}
          setSelectedRowsData={setSelectedRowsData}
          selectedRowData={selectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index="three">
        <CompanyTable
          loading={loading}
          list={teamList}
          columns={columns}
          setSelectedRowsData={setSelectedRowsData}
          selectedRowData={selectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
        />{" "}
      </TabPanel> */}
    </div>
  );
}
