import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import SendIcon from "@material-ui/icons/Send";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEducationGroupFn } from "../../../api/SignIn/selector";
import { ExcelTeamPostAPI } from "../../../api/TeamManage/action";
import sampleExcel from "../../../assets/excel/Sample_Team.xlsx";
import UploadExcelFile from "../../../components/DialogSection/UploadExcelFile";
import { dataRow, headerSampleFile } from "../../../pages/ManageTeam/sampleExcelFile";
import AddMember from "../../DialogSection/AddMember";
import AddMemberGroup from "../../DialogSection/AddMember/groupView";
import ImportData from "../../DialogSection/ImportData";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function ManageTeamHeader({
  teamList,
  userRoleList,
  designationList,
  selectedRowsData,
  placementSession,
  userRoleId,
  setApiCalled,
  tempTeamList,
  collegeId,
  userRolesPermission,
  inviteLink,
  filters,
  fromGroup,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [openUpload, setOpenUpload] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(0);
  const [totalLoad, setTotalLoad] = useState(0);
  const [fileNames, setFileNames] = useState([]);
  const [progress, setProgress] = useState(0);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const licenceData = useSelector((state) => state?.commonData?.licenceData);


  const [resData, setresData] = useState({
          total_entries:null,
          user_created_count: null,
          email_already_exist:null,
          failed_entries : null,
          flag:null,
          error_list: [],
          already_exist_list:[],
          });


  const handleClickOpenUpload = () => {

     if(licenceData && licenceData?.display ){

             alert.error(licenceData?.display_message);
             return
        }
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setFileNames([]);
  };

  const handleOpenImport = () => {
    setOpenUpload(false);

    setOpenImport(true);
    if (fileNames?.length > 0) {
      var formData = new FormData();
      formData.append("file", fileNames[0]);
      formData.append("college_id", collegeId);
      dispatch(ExcelTeamPostAPI(formData, setLoadingExcel, alert, history, location, handleCloseImport, setProgress, setDataLoaded, setTotalLoad,setresData));
    }
  };

  const checkForPendingRejected = () => {
    return (filters?.current_tab?.key === "pending_team" || filters?.current_tab?.key === "rejected_team")
  }

  const handleCancelImport = () => {
    setOpenImport(false);
     setresData((resData) => ({...resData, flag: false}));
  }

  const handleCloseImport = () => {
    setOpenImport(true);//to hold the dialog box open
    setFileNames([]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          marginBottom: "20px",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <div>
          <Typography variant="h2">Team Members</Typography>
          <Typography
            variant="body2"
            color="secondary"
            // style={{ marginTop: "10px" }}
          >
            Manage your all team members in one place
          </Typography>
          {/* <FormControlLabel
            value="end"
            control={<Checkbox color="primary" size="small" />}
            label={
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Show Data of All Active Placement Sessions
              </Typography>
            }
            labelPlacement="end"
          /> */}
        </div>
        {userRolesPermission?.some((item) => item.access_control_key === "manage_team") && (
          <div style={{ display: "flex" }}>
            {!checkForPendingRejected() && (
              <>
                {/* {!fromGroup && (
                  <>
                    <Button variant="contained" color="primary" size="small" onClick={handleClickOpenUpload} className={classes.buttonBase} startIcon={<PublishIcon />}>
                      Import
                    </Button>
                    <UploadExcelFile
                      handleClickOpen={handleClickOpenUpload}
                      handleClose={handleCloseUpload}
                      open={openUpload}
                      fileNames={fileNames}
                      setFileNames={setFileNames}
                      handleOpenImport={handleOpenImport}
                      dataRow={dataRow}
                      headerSampleFile={headerSampleFile}
                      downloadText={"Sample_Team_Member.csv"}
                      sampleFile={sampleExcel}
                    />
                    <ImportData
                      open={openImport}
                      handleClickOpen={handleOpenImport}
                      handleClose={handleCloseImport}
                      dataLoaded={dataLoaded}
                      totalLoad={totalLoad}
                      progress={progress}
                      fileName={fileNames[0]?.name}
                      handleCancelImport={handleCancelImport}
                      resData={resData}

                    />{" "}
                  </>
                )} */}
                {fromGroup ? (
                  <>
                    {isEducationGroup && (
                      <AddMemberGroup
                        userRoleList={userRoleList}
                        designationList={designationList}
                        teamList={teamList}
                        placementSession={placementSession}
                        userRoleId={userRoleId}
                        setApiCalled={setApiCalled}
                        collegeId={collegeId}
                      />
                    )}
                  </>
                ) : (
                  <AddMember
                    userRoleList={userRoleList}
                    designationList={designationList}
                    teamList={teamList}
                    placementSession={placementSession}
                    userRoleId={userRoleId}
                    setApiCalled={setApiCalled}
                    collegeId={collegeId}
                  />
                )}
              </>
            )}
            {!fromGroup && (
              <CopyToClipboard text={inviteLink} onCopy={() => {
                
                 if(licenceData && licenceData?.display ){

                    alert.error(licenceData?.display_message);
                    return
                }
                inviteLink ? alert.success("Link Copied!") : alert.error("Some Error")
                
                }}>
                <Button color="primary" size="small" variant="contained" className={classes.primebtn} startIcon={<SendIcon />}>
                  Invite Link
                </Button>
              </CopyToClipboard>
            )}
            {/* <InviteMember userRoleList={userRoleList} /> */}
            <CSVLink
              data={tempTeamList}
              style={{
                cursor: "pointer",
                textDecoration: "none",
              }}
              filename={"Teams.csv"}
            >
              <Button
                variant="contained"
                //  color="primary"
                size="small"
                style={{ marginRight: "0px" }}
                className={classes.button}
                startIcon={<GetAppIcon />}
              >
                Export
              </Button>
            </CSVLink>
          </div>
        )}
      </div>
    </>
  );
}
