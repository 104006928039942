import { Button, Typography, Tooltip, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import View from "../../Table/JobTable/View";
import moment from "moment";
import InfoToolTip from "../../Common/ToolTips/InfoToolTip";

export const inboundJobColumns = [
  {
    field: "job_title",
    headerName: "Job Title",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/getwork-job/${params?.row?.id}?inbound_job=true`}
              target="_blank"
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params?.row?.job_title} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.job_title}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
    ),
  },
  {
    field: "company_name",
    // type: "text",
    headerName: "Company Name",

    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>Company</strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          <Grid item xs zeroMinWidth>
            {/* <Link to={`/company-profile/${params?.row?.company_id}`} style={{ textDecoration: "none", display: "contents" }}>
                                {" "} */}
            <Tooltip title={params?.row?.company_name} placement="right">
              <Typography noWrap style={{ fontSize: "14px" }}>
                {params?.row?.company_name}
              </Typography>
            </Tooltip>
            {/* </Link> */}
          </Grid>
        </Grid>
      </>
    ),
  },
  {
    field: "job_type",
    sortable: false,
    headerName: "Job Type",

    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.job_type}
      </span>
    ),
  },
  {
    field: "job_source",
    // type: "text",
    sortable: false,
    width: 110,
    headerName: "Source",
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Source</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.job_source}
      </span>
    ),
  },

  {
    field: "ctc",
    // type: "text",
    sortable: false,
    width: 150,
    headerName: "CTC",

    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        CTC (Min-Max)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.ctc}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    sortable: false,
    width: 180,
    headerName: "Stipend",

    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Stipend (Min-Max)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    // type: "text",
    width: 120,
    headerName: "Vacancies",

    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.vacancies || "NA"}
      </span>
    ),
  },

  {
    field: "end_date",
    // type: "text",
    width: 145,
    headerName: "Apply By",

    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Apply By</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.end_date?.slice(0, 10) || "NA"}
      </span>
    ),
  },
  {
    field: "created",
    headerName: "Created",
    type: "date",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>Created</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.created || "NA"}
      </span>
    ),
  },
];

export const inboundRejectedColumns = [
  ...inboundJobColumns,
  {
    field: "college_action_by",
    headerName: "Rejected By",
    sortable: false,
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
        Rejected by
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Typography noWrap style={{ fontSize: "14px" }}>
          {params?.row?.college_action_by?.college_user_name || "NA"}
        </Typography>{" "}
      </>
    ),
  },
  {
    field: "reason",
    headerName: "Reason",
    sortable: false,
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Reason</strong>
    ),
    renderCell: (params) => (
      <>
        <Typography noWrap style={{ fontSize: "14px" }}>
          {params?.row?.college_action_by?.reason || "NA"}
        </Typography>
        {params?.row?.college_action_by?.reason && (
          <InfoToolTip
            rejectedDate={
              params?.row?.college_action_by?.last_updated_date || "NA"
            }
            label={params?.row?.college_action_by?.reason || "NA"}
          />
        )}{" "}
      </>
    ),
  },
];

export const inboundPostedColumns = [
  ...inboundJobColumns,
  {
    field: "college_action_by",
    headerName: "Posted by",
    sortable: false,
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
        Posted by
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Typography noWrap style={{ fontSize: "14px" }}>
          {params?.row?.college_action_by?.college_user_name || "NA"}
        </Typography>{" "}
      </>
    ),
  },
  {
    field: "reason",
    headerName: "Reason",
    sortable: false,
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
        Posted On
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Typography noWrap style={{ fontSize: "14px" }}>
          {params?.row?.college_action_by?.last_updated_date?.slice(0, 10) ||
            "NA"}
        </Typography>{" "}
      </>
    ),
  },
];
