import React from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import WorkExperienceForm from "./WorkExperienceForm";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    color: "grey",
    fontSize: 13,
  },
});

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
  },
  newBtnStyle: {
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));

export default function AddWorkExperienceDialog(props) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    companiesList,
    editWorkEx,
    setEditWorkEx,
    saveWorkEx,
    isCompanyNotListed,
    setIsCompanyNotListed,
    workExDataLoading,
    isEdit,skillsList
  } = props;
  const alert = useAlert();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const validateForm = () => {
    if (!editWorkEx?.job_designation) {
      alert.error("Job title can't be empty");
      return false;
    }
    if (!isCompanyNotListed && !editWorkEx?.company_id) {
      alert.error("Select a company");
      return false;
    }
    if (isCompanyNotListed && !editWorkEx?.company_name) {
      alert.error("Company name can't be empty");
      return false;
    }
    if (editWorkEx?.company_website && !editWorkEx?.company_name) {
      alert.error("Company name can't be empty");
      return false;
    }

    if (
      editWorkEx?.company_website &&
      !(
        editWorkEx?.company_website?.startsWith("https://") ||
        editWorkEx?.company_website?.startsWith("http://") ||
        editWorkEx?.company_website?.startsWith("HTTPS://") ||
        editWorkEx?.company_website?.startsWith("HTTP://")
      )
    ) {
      alert.error("Invalid ! Link should start with http://");
      return false;
    }
    if (!editWorkEx?.start_date) {
      alert.error("Start Date can't be empty");

      return false;
    }
    if (!editWorkEx?.is_current_working && !editWorkEx?.end_date) {
      alert.error("End Date can't be empty");
      return false;
    }
    if (!editWorkEx?.job_description) {
      alert.error("Description can't be null");
      return false;
    }
    return true;
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        // maxWidth="sm"
        classes={{
          paper: classes.newDialog,
        }}
        open={props.open}
        onClose={() => {
          setIsCompanyNotListed(false);
          props.handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
      >
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>{isEdit ? "Edit" : "Add"} Work Experience</b>
          </Typography>
          <WorkExperienceForm
            addedWorkEx={editWorkEx}
            setAddedWorkEx={setEditWorkEx}
            companiesList={companiesList}
            isCompanyNotListed={isCompanyNotListed}
            setIsCompanyNotListed={setIsCompanyNotListed}
            isEdit={isEdit}
            skillsList={skillsList}
          />
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                className={classes.newBtnStyle}
                onClick={() => {
                  handleClose();
                  setIsCompanyNotListed(false);
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.newBtnStyle}
                onClick={() => {
                  if (validateForm()) {
                    saveWorkEx();
                  }
                }}
              >
                {workExDataLoading ? <CustomButtonCircularProgress /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
