import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  praveshAxiosGetReq,
  praveshAxiosPatchReq,
  praveshAxiosPostReq
} from "../../api/BaseApi/apiBase";
import { collegeIdFn, isCollegeGroupFn, isEducationGroupFn } from "../../api/SignIn/selector";
import ImageCard from "../../components/Cards/DriveItem/ImageCard";
import SortMenu from "../../components/Cards/DriveItem/SortMenu";
import CustomCircularProgress from "../../components/CircularProgressBar";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import ImagePopUp from "../../components/DialogSection/ImagePopUp";
import UploadStudentDoc from "../../components/DialogSection/UploadStudentDocumentDialog/UploadStudentDoc";
import NoDataFunc from "../../components/NoDataSVG/index1";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";
import {
  generateCsvNew
} from "../../utility/commaSeparatedStrings";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    display: "flex",
    height: "40px",
  },
  btnEmpty: {
    borderRadius: "8px",
    boxShadow: "none",
    display: "flex",
    height: "40px",
    backgroundColor: "white",
  },
  btnNew: {
    borderRadius: "8px",
    boxShadow: "none",
    display: "flex",
    height: "40px",
    backgroundColor: theme.palette.primary.secondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
    color: "white",
  },
  btnSelected: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    height: "40px",
    color: "#6c757d",
    borderColor: "#6c757d",
    display: "flex",
    // backgroundColor: theme.palette.primary.hover,
    "&:hover": {
      //backgroundColor: theme.palette.primary.hover,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  btngroup: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  newbutton: {
    borderRadius: "8px",
    margin: theme.spacing(2),
    boxShadow: "none",
    fontSize: "16px",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
}));

function Drive() {
  const classes = useStyles();
  const params = useParams();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState();

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeId = useSelector(collegeIdFn);

  const [selectedCategory, setSelectedCategory] = useState(4535);
  const [selectedVault, setSelectedVault] = useState([]);
  const [firstApiCall, setFirstApiCall] = useState(true);

  const [sort, setSort] = useState({
    date: false,
    name: false,
  });
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    if (params.user_id) {
      const user_id = params.user_id.split("-");

      setUserName(user_id[0].replace(/_/g, " "));
      setUserId(user_id[user_id.length - 1]);
    }
  }, [params]);
  const checkCategory = () => {
    if (selectedCategory && selectedCategory !== 4535)
      return `&category_id=${selectedCategory}`;
    else return ``;
  };
  const checkSort = () => {
    if (sort?.name) return `&sort=name`;
    else if (sort?.date) return `&sort=date`;
    else return ``;
  };
  const StudentDetailsAPI = () => {
    setLoading(true);
    axios
      .get(
        `${
          BackendBaseApi.PRAVESH
        }api/saas/vault/?user_id=${userId}${checkCategory()}${checkSort()}`,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          res.data.data.forEach((item) => {
            item["select"] = false;
          });
          setStudentData(res.data.data);
          if (res.data.data.length > 0) setFirstApiCall(true);
          setLoading(false);
        } else {
        }
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoading(false);
      });
  };
  const [categoryList, setCategoryList] = useState([]);
  const CategoryAPI = () => {
    axios
      .get(`${BackendBaseApi.PRAVESH}api/saas/vault_category/?is_active=1`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data?.length > 0)
            res.data.data.unshift({ id: 4535, name: "All Documents" });
          setCategoryList(res.data.data);
        } else {
        }
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
      });
  };

  useEffect(() => {
    //StudentDetailsAPI();
    CategoryAPI();
  }, [refreshData]);

  useEffect(() => {
    if (userId) StudentDetailsAPI();
  }, [selectedCategory, sort?.name, sort?.date, userId, refreshData]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedVault([]);
    }
  }, [selectedCategory]);
  const [currentItem, setCurrentItem] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (item) => {
    setCurrentItem(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  useEffect(() => {
    setSort({ name: false, date: false });
    if (studentData?.length > 0) {
      var arr = [...studentData];
      arr.forEach((item) => {
        item.select = false;
      });

      setStudentData(arr);
    }
  }, [selectedCategory]);

  const checkVault = () => {
    if (selectedVault?.length > 0)
      return `vault_id=${generateCsvNew(selectedVault)}`;
    else return ``;
  };
  const [loadingZip, setLoadingZip] = useState(false);

  /* #################################################################################################################### */

  const [openUpload, setOpenUpload] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileDeleting, setFileDeleting] = useState(false);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [uploadDocData, setUploadDocData] = useState({
    type: null,
    title: null,
    fileToUpload: null,
  });
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setUploadDocData({
      type: null,
      title: null,
      fileToUpload: null,
    });
    setFileNames([]);
  };

  const getDocumentTypeList = async () => {
    const url = `api/saas/college/vault/subcategory/`;
    setDataLoading(true);
    try {
      const res = await praveshAxiosGetReq(url);
      if (res.data.success) {
        setDocumentTypeList(res.data.data);
      } else {
        alert.error(res.data.error);
      }
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const UploadFile = async (formData) => {
    const url = `api/saas/college/vault/?user_id=${userId}`;
    setFileUploading(true);
    try {
      const res = await praveshAxiosPostReq(url, formData);
      if (res.data.success) {
        alert.success(res.data.data.message);
        setRefreshData(!refreshData);
        handleCloseUpload();
        setFileNames([]);
      } else {
        alert.error(res.data.error);
      }
      setFileUploading(false);
    } catch (error) {
      alert.error(error.response.message);
      setFileUploading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const deleteFile = async () => {
    //const ids = [...selectedVault];
    const url = `api/saas/college/vault/?user_id=${userId}`;
    const data = {
      user_id: userId,
      ids: [...selectedVault],
    };
    setFileDeleting(true);
    try {
      const res = await praveshAxiosPatchReq(url, data);
      if (res.data.success) {
        alert.success(res.data.data.message);
        setRefreshData(!refreshData);
      } else {
        alert.error(res.data.error);
      }
      setFileDeleting(false);
    } catch (error) {
      alert.error(error.response.message);
      setFileDeleting(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  return (
    <>
      {/* <Layout> */}

      <Breadcrumbs aria-label="breadcrumb">
        <Link to={checkForBreadcrumb(isEducationGroup, isCollegeGroup, collegeId, "manage-students")} style={{ textDecoration: "none" }}>
          <Typography color="primary" variant="body2">
            Manage Students
          </Typography>
        </Link>

        {userName && (
          <Link
            to={`/student-profile/${userName?.replace(/ /g, "_")}-${userId}`}
            style={{ textDecoration: "none" }}
          >
            <Typography color="primary" variant="body2">
              {userName}
            </Typography>
          </Link>
        )}
        <Typography color="secondary" variant="body2">
          View Documents
        </Typography>
        {/* </Link> */}
      </Breadcrumbs>

      <div>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <div className={classes.btngroup}>
              {categoryList?.length && firstApiCall
                ? categoryList?.map((item) => (
                    <Button
                      variant={
                        selectedCategory === item?.id ? "contained" : "outlined"
                      }
                      color="primary"
                      className={
                        selectedCategory === item?.id
                          ? classes.btn
                          : classes.btnEmpty
                      }
                      onClick={() => {
                        setSelectedCategory(item?.id);
                      }}
                    >
                      {item?.name}
                    </Button>
                  ))
                : null}
            </div>
          </Grid>

          {loading ? (
            <Grid item xs={12}>
              <CustomCircularProgress />
            </Grid>
          ) : studentData?.length > 0 ? (
            <>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alginItems: "center",
                  }}
                >
            <div style={{ display: "flex" }}>
                    <UploadStudentDoc
                      disableUpload={
                        studentData?.some((item) => item.select) ? true : false
                      }
                      handleClickOpen={handleClickOpenUpload}
                      handleClose={handleCloseUpload}
                      open={openUpload}
                      fileNames={fileNames}
                      setFileNames={setFileNames}
                      documentTypeList={documentTypeList}
                      getDocumentTypeList={getDocumentTypeList}
                      dataLoading={dataLoading}
                      setUploadDocData={setUploadDocData}
                      uploadDocData={uploadDocData}
                      fileUploading={fileUploading}
                      UploadFile={UploadFile}
                    />
                    {studentData?.some((item) => item.select) ? (
                      <>
                        <Button
                          variant="contained"
                          className={classes.btnNew}
                          startIcon={<DeleteIcon />}
                          style={{
                            height: "40px",
                            marginLeft: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                          onClick={() => {
                            deleteFile();
                          }}
                        >
                          {fileDeleting ? (
                            <CustomButtonCircularProgress />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.btnNew}
                          style={{
                            height: "40px",
                            marginLeft: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                          startIcon={<GetAppIcon />}
                          onClick={() => {
                            if (!loadingZip) {
                              // ZipDownloadAPI();
                              window.open(
                                `${
                                  BackendBaseApi.PRAVESH
                                }api/saas/vault_download/?user_id=${userId}&${checkVault()}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {loadingZip ? (
                            <CustomButtonCircularProgress />
                          ) : (
                            `Download ${
                              selectedVault?.length > 1 ? "All" : ""
                            } (.zip)`
                          )}
                        </Button>
                      </>
                    ) : (
                      <div
                        style={{
                          height: "48px",
                          marginLeft: "5px",
                           paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                          ></div>
                    )}
                  </div>

                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={studentData?.every((item) => item.select)}
                          onChange={(e) => {
                            var arr = [...studentData];
                            arr.forEach((item) => {
                              item.select = e.target.checked;
                            });
                            setStudentData(arr);
                            if (e.target?.checked) {
                              var selected_id = [];
                              arr.forEach((item) => {
                                selected_id.push(item?.id);
                              });
                              setSelectedVault(selected_id);
                            } else {
                              var selected_id = [];

                              setSelectedVault(selected_id);
                            }
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{ color: "#6c757d" }}
                        >
                          Select All
                        </Typography>
                      }
                    />
                    <SortMenu sort={sort} setSort={setSort} />
                  </div>
                </div>
              </Grid>
              {studentData?.map((item, index) => (
                <Grid item xs={2}>
                  <ImageCard
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    item={item}
                    index={index}
                    data={studentData}
                    setData={setStudentData}
                    selectedVault={selectedVault}
                    setSelectedVault={setSelectedVault}
                  />
                </Grid>
              ))}

              <ImagePopUp
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                currentItem={currentItem}
              />
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
              }}
            >
              <NoDataFunc
                title={"No Documents Found"}
                width="250px"
                height="200px"
              />
              <UploadStudentDoc
                handleClickOpen={handleClickOpenUpload}
                handleClose={handleCloseUpload}
                open={openUpload}
                fileNames={fileNames}
                setFileNames={setFileNames}
                documentTypeList={documentTypeList}
                getDocumentTypeList={getDocumentTypeList}
                dataLoading={dataLoading}
                setUploadDocData={setUploadDocData}
                uploadDocData={uploadDocData}
                fileUploading={fileUploading}
                UploadFile={UploadFile}
              />
            </div>
          )}
        </Grid>
      </div>

      {/* </Layout> */}
    </>
  );
}

export default Drive;

