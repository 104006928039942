import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Box,
  Grid,
  TextField,
  Checkbox,
  Chip,
  Button,
  Typography,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import pSBC from "shade-blend-color";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import AddNewCompanyForm from "../../DialogSection/DialogItem/AddNewCompanyForm";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IndustryGetAPI } from "../../../api/Common/action";
import { AllCompanyAPI } from "../../../api/JobPost/action";
import AddNewJob from "./AddNewJob";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { themeSelectorFn } from "../../../api/Domain/selector";
import useOffCampusForm from "../../../api/OffCampusForm/useOffCampusForm";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";

import { collegeIdFn } from "../../../api/SignIn/selector";
import { event } from "react-ga";
import { off } from "devextreme/events";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  box: {
    boxShadow: "0px 4px 6px 0px #0000001C",
    padding: "30px",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  text: {
    color: theme.palette.primary.main,
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  circularProgressColored: {
    color: theme.palette.primary.main,
  },
}));

function OffCampusForm() {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const params = useParams();
  const selectedData = useSelector((state) =>
    state?.SingleJobData?.details ? state?.SingleJobData?.details : []
  );
  const collegeId = useSelector(collegeIdFn);
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openJob, setOpenJob] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [makeCompanyCall, setMakeCompanyCall] = useState(false);

  const initialFormState = {
    company_id: null, //cl
    company_name: null, //cl
    company_website: null, //cl

    job_title: null, //f

    job_type_name: "Full Time", //initially full time is selected
    job_type_id: 7, //f

    job_role_id: null,
    job_role_name: null,

    hiring_date: null, //f
    joining_date: null, //f

    job_description: "<p>vdzcdvsf</p>", //f

    full_ctc_min: null,
    full_ctc_max: null,

    //Internship:
    payment_type: "PAID",
    stipend: null,
    ppo: null, //t/f
    ppo_ctc_min: null,
    ppo_ctc_max: null,

    // salary_type: "PAID",
    wfh: true,

    college_student_ids: [],

    job_location_type: "WFO",

    locations: [],

    city_id: null,
    city: null,

    placement_cycle_id: null,
    placement_cycle_name: null,
  };
  const [offCampusFormData, setOffCampusFormData] = useState(initialFormState);
  useEffect(() => console.log(offCampusFormData), [offCampusFormData]);

  const initialCompantState = {
    company_name: null,
    company_website: null,
    industry_id: null,
    industry_name: null,
    industry_type_id: null,
    industry_type_name: null,
    contact_person_name: null,
    email: null,
    phone: null,
    designation: null,
  };
  const [companyDetails, setCompanyDetails] = useState(initialCompantState);

  // const [jobAction, setJobAction] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setMakeCompanyCall(false);
  };

  const handleClose = () => {
    setOpen(false);
    setCompanyDetails(initialCompantState);
  };

  const handleClickOpenJob = () => {
    setOpenJob(true);
  };

  const handleCloseJob = () => {
    setOpenJob(false);
    // setOffCampusFormData(initialFormState);
  };
  const handleCloseWithClear = () => {
    setOpenJob(false);
    setOffCampusFormData(initialFormState);
  };

  // useEffect(() => {
  //   if (params.job_id) {
  //     setJobAction(params.job_id.split("-")[0]);
  //   }
  // }, [params]);

  // will be open for no job data from recommended job values when company details are null
  // for jobs from recommended section

  // useEffect(() => {
  //   if (selectedData && jobAction === "recommended" && !makeCompanyCall) {
  //     setOpen(!selectedData?.company);
  //   }
  // }, [selectedData]);

  // useEffect(() => {
  //   if (makeCompanyCall) {
  //     dispatch(AllCompanyAPI(setLoading, alert, history, location));
  //   }
  // }, [makeCompanyCall]);

  const industryList = useSelector((state) => state?.commonData?.industryData);

  useEffect(() => {
    dispatch(IndustryGetAPI(alert, history, location));
  }, []);

  const Theme = createMuiTheme({
    palette: {
      type: "light",
      common: {
        black: "#000000",
        white: "#ffffff",
      },
      primary: {
        main: theme_change?.main ? theme_change?.main : "#01203F",
        hover: theme_change?.hover ? theme_change.hover : "#0069D9",
        disable: theme_change?.disable ? theme_change?.disable : "#58AAFF",
        contrastText: theme_change?.contrast_text
          ? theme_change.contrast_text
          : "#fff",
        secondary: theme_change?.secondary ? theme_change.secondary : "#e0a800",
      },
    },
    typography: {
      fontFamily: "Nunito",
      h1: {
        fontSize: "3em",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h2: {
        fontSize: "34px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h3: {
        fontSize: "24px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h4: {
        fontSize: "20px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h5: {
        fontSize: "18px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h6: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body1: {
        color: "#000000",
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body2: {
        color: "#000000",
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body3: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle1: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle2: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      caption: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      overline: {
        fontSize: "10px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      button: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      buttonmob: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          minHeight: 50,
          borderRadius: 8,
          fontSize: 15,
        },
        notchedOutline: {
          // borderColor: "transparent",
        },
        multiline: {
          height: "auto",
        },
      },
    },
  });

  const [selectedPlacementCycleOption, setSelectedPlacementCycleOption] =
    useState(null);

  const handlePlacementCycleChange = (event, newValue) => {
    // if (newValue) {
    setSelectedPlacementCycleOption(newValue);
    setSelectedStudents([]);
    setSelectedCompany(null);

    setOffCampusFormData({
      ...offCampusFormData,
      placement_cycle_id: newValue ? newValue.value : null,
      placement_cycle_name: newValue ? newValue.label : null,
      college_student_ids: selectedStudents,
      company_id: null,
      company_name: null,
      company_website: null,
    });
    setLandinPageError({
      ...landingPageError,
      placement_cycle_error: null,
    });
    // }
  };

  const { placementCycleList, companyList, setCompanyList } = useOffCampusForm(
    collegeId,
    alert
  );

  const placementCycleOptions = placementCycleList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const [students, setStudents] = useState([]);
  useEffect(() => {
    setStudents([]);
    setSelectedStudents([]);
    if (selectedPlacementCycleOption) {
      axios
        .get(
          BackendBaseApi.SANCHALAN +
            `api/manage_student/college_student_list/?placement_cycle_id=${selectedPlacementCycleOption.value}`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          // setLoading(false);
          if (response?.data?.success) setStudents(response?.data?.data);
          else alert.error(response?.data?.error);
        })
        .catch((error) => {
          // setLoading(false);
          alert.error(error?.response?.data?.error);
          throw error;
        });
    }
  }, [selectedPlacementCycleOption]);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const handleSelectedCompanyChange = (event, newValue) => {
    // if (newValue) {
    setSelectedCompany(newValue);
    setOffCampusFormData({
      ...offCampusFormData,
      company_id: newValue ? newValue.id : null,
      company_name: newValue ? newValue.name : null,
      company_website: newValue ? newValue.company_website : null,
    });
    setLandinPageError({
      ...landingPageError,
      company_error: null,
    });
    // }
  };

  const [jobList, setJobList] = useState([]);
  useEffect(() => {
    setJobList([]);
    setSelectedJob(null);

    if (selectedCompany) {
      axios
        .get(
          BackendBaseApi.SANCHALAN +
            `api/manage_job/company/job_list/?company_id=${selectedCompany.id}`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response?.data?.success) {
            setJobList(response?.data?.data.job_lists);
          } else alert.error(response?.data?.error);
        })
        .catch((error) => {
          alert.error(error?.response?.data?.error);
          throw error;
        });
    }
  }, [selectedCompany]);

  const [selectedStudents, setSelectedStudents] = useState([]);
  const handleSelectedStudentsChange = (event, newValue) => {
    setSelectedStudents(newValue);
    const selectedStudentIds = newValue.map((item) => item.sanchalan_user_id);
    setOffCampusFormData({
      ...offCampusFormData,
      college_student_ids: selectedStudentIds,
    });
    setLandinPageError({
      ...landingPageError,
      student_error: null,
    });
  };

  const [selectedJob, setSelectedJob] = useState(null);
  const handleSelectedJobChange = (event, newValue) => {
    setSelectedJob(newValue);
    if (!newValue?.noUpdate) setIsUpdate(true);
    setLandinPageError({
      ...landingPageError,
      job_error: null,
    });
  };

  const [landingPageError, setLandinPageError] = useState({
    placement_cycle_error: null,
    student_error: null,
    company_error: null,
    job_error: null,
  });

  const validateData = () => {
    let is_valid = true;
    let placement_cycle_error = null;
    let student_error = null;
    let company_error = null;
    let job_error = null;

    if (!selectedPlacementCycleOption) {
      placement_cycle_error = "Placement Cycle field cannot be left blank";
      is_valid = false;
    }
    if (!selectedStudents.length) {
      student_error = "Students List cannot be left blank";
      is_valid = false;
    }

    if (!selectedCompany) {
      company_error = "Company field cannot be left blank";
      is_valid = false;
    }

    if (!selectedJob) {
      job_error = "Job field cannot be left blank";
      is_valid = false;
    }

    setLandinPageError((prevState) => ({
      ...prevState,
      placement_cycle_error,
      student_error,
      company_error,
      job_error,
    }));

    return is_valid;
  };

  const handleSubmit = async () => {
    if (validateData()) {
      setLoading(true);
      const payload = {
        college_id: [collegeId],
        college_job_data: {
          company_id: offCampusFormData.company_id,
          company_name: offCampusFormData.company_name,
          company: {
            id: offCampusFormData.company_id,
            college_id: [collegeId],
            name: offCampusFormData.company_name,
            niyukti_id: null,
            company_website: offCampusFormData.company_website,
          },
          job_title: offCampusFormData.job_title,
          job_type_id: offCampusFormData.job_type_id,
          job_type_name: offCampusFormData.job_type_name,
          job_role_id: null,
          job_role_name: null,
          job_role: {
            id: offCampusFormData.job_role_id,
            job_role_group: null,
            job_role_group_name: null,
            job_role_name: offCampusFormData.job_role_name,
            name: offCampusFormData.job_role_name,
          },
          job_segment_id: null,
          job_segement_name: null,
          hiring_date: offCampusFormData.hiring_date,
          joining_date: offCampusFormData.joining_date,
          campus_drive_date: null,
          job_description: offCampusFormData.job_description,
          ctc_max:
            offCampusFormData.job_type_name === "Full Time"
              ? offCampusFormData.full_ctc_max
              : offCampusFormData.stipend,
          ctc_min:
            offCampusFormData.job_type_name === "Full Time"
              ? offCampusFormData.full_ctc_min
              : offCampusFormData.stipend,
          salary_type: offCampusFormData.payment_type,
          // offCampusFormData.job_type_name === "Full Time"
          //   ? offCampusFormData.payment_type
          //   : offCampusFormData.payment_type === "PAID"
          //   ? "PAID"
          //   : "UNPAID", //
          wfh: offCampusFormData.job_location_type === "WFO" ? false : true,
          pan_india: false,
          job_location: offCampusFormData.locations,
          // [
          //   {
          //     city_id: offCampusFormData.city_id,
          //     city: offCampusFormData.city,
          //   },
          // ],
          ppo:
            offCampusFormData.ppo === null
              ? null
              : offCampusFormData.ppo === "yes"
              ? true
              : false,
          ppo_ctc_min: offCampusFormData.ppo_ctc_min,
          ppo_ctc_max: offCampusFormData.ppo_ctc_max,
          ppo_salary_type:
            offCampusFormData.job_type_name === "Internship"
              ? offCampusFormData.payment_type === "PAID"
                ? "PAID"
                : "UNPAID"
              : null, //
          salary_breakup_ppo: null,
          internship_duration: null,
          college_student_ids: offCampusFormData.college_student_ids,
        },
        company_id: offCampusFormData.company_id,
        job_status: "CLOSED",
        placement_cycle: [
          {
            id: offCampusFormData.placement_cycle_id,
            name: offCampusFormData.placement_cycle_name,
            is_selected: true,
          },
        ],
      };

      if (isupdate) {
        const payloadUpdate = {
          job_id: selectedJob.job_id,
          college_id: [collegeId],
          college_job_data: {
            college_student_ids: offCampusFormData.college_student_ids,
          },
        };
        const res = await axios
          .post(
            BackendBaseApi.SANCHALAN + "api/manage_job/add_off_campus_job/",
            payloadUpdate,
            {
              withCredentials: true,
            }
          )
          .catch((error) => {
            // setLoading(false);
            if (error?.response?.status !== 401) {
              alert.error("Unable to add your job. Some Error Occurred");
            }
            alert.error("Something went wrong");
            throw error;
          });

        if (res.data.success) {
          alert.success(res.data.data.message);
        } else {
          alert.error("Something went wrong");
        }
      } else {
        const res = await axios
          .post(
            BackendBaseApi.SANCHALAN + "api/manage_job/add_off_campus_job/",
            payload,
            {
              withCredentials: true,
            }
          )
          .catch((error) => {
            // setLoading(false);
            if (error?.response?.status !== 401) {
              alert.error("Unable to add your job. Some Error Occurred");
            }
            alert.error("Something went wrong");
            throw error;
          });

        if (res.data.success) {
          alert.success(res.data.data.message);
        } else {
          alert.error("Something went wrong");
        }
      }
      setLoading(false);
      setSelectedPlacementCycleOption(null);
      setSelectedJob(null);
      setSelectedStudents([]);
      setSelectedCompany(null);
      setOffCampusFormData(initialFormState);
      setCompanyDetails(initialCompantState);
    }
  };

  const [isupdate, setIsUpdate] = useState(true);

  return (
    <>
      <Box className={classes.box}>
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Autocomplete
              id="combo-box-demo"
              options={placementCycleOptions}
              value={selectedPlacementCycleOption}
              onChange={handlePlacementCycleChange}
              renderOption={(option) => {
                return (
                  <span style={{ fontSize: 13 }}>{`${option.label}`}</span>
                );
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Placement Cycle"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={landingPageError.placement_cycle_error ? true : false}
                  helperText={
                    <span>{landingPageError.placement_cycle_error}</span>
                  }
                />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <MuiThemeProvider theme={Theme}>
              <Autocomplete
                disabled={selectedPlacementCycleOption ? false : true}
                multiple
                limitTags={2}
                id="checkboxes-tags-demo"
                value={selectedStudents}
                onChange={handleSelectedStudentsChange}
                options={students}
                disableCloseOnSelect
                // getOptionLabel={(option) => option.name}
                getOptionLabel={(option) => `${option.name} (${option.email})`}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={`${option.name} (${option.email})`}
                      size="small"
                      color="primary"
                      {...getTagProps({ index })}
                      className={classes.chip}
                      deleteIcon={<CloseIcon className={classes.text} />}
                    />
                  ))
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      color="primary"
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <Typography style={{ fontSize: "14px" }}>
                      {" "}
                      {option.name} ({option.email})
                    </Typography>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Students"
                    placeholder="Add more student"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    error={landingPageError.student_error ? true : false}
                    helperText={<span>{landingPageError.student_error}</span>}
                  />
                )}
              />
            </MuiThemeProvider>
          </Grid>
          <Grid item md={12}>
            <Autocomplete
              disabled={selectedPlacementCycleOption ? false : true}
              id="combo-box-demo"
              value={selectedCompany}
              onChange={handleSelectedCompanyChange}
              options={companyList}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={landingPageError.company_error ? true : false}
                  helperText={<span>{landingPageError.company_error}</span>}
                />
              )}
            />

            <div>
              <Button
                disabled={selectedPlacementCycleOption ? false : true}
                variant="text"
                color="primary"
                startIcon={<AddCircleRoundedIcon />}
                onClick={handleClickOpen}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2" style={{ color: "#000" }}>
                  {" "}
                  Add New Company{" "}
                </Typography>
              </Button>
            </div>
          </Grid>
          <Grid item md={12}>
            <Autocomplete
              disabled={selectedCompany ? false : true}
              id="combo-box-demo"
              options={jobList}
              value={selectedJob}
              onChange={handleSelectedJobChange}
              renderOption={(option) => {
                return (
                  <span style={{ fontSize: 13 }}>{`${option.job_title}`}</span>
                );
              }}
              getOptionLabel={(option) => option.job_title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Job Tittle"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={landingPageError.job_error ? true : false}
                  helperText={<span>{landingPageError.job_error}</span>}
                />
              )}
            />

            <div>
              <Button
                variant="text"
                color="primary"
                startIcon={<AddCircleRoundedIcon />}
                style={{ display: "flex", alignItems: "center" }}
                onClick={handleClickOpenJob}
                disabled={selectedCompany ? false : true}
              >
                <Typography variant="body2" style={{ color: "#000" }}>
                  Add New Job
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>

        <Dialog
          PaperProps={{ classes: { root: classes.root1 } }}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <div style={{ padding: "40px" }}>
            <AddNewCompanyForm
              collegeId={collegeId}
              handleClose={handleClose}
              setMakeCompanyCall={setMakeCompanyCall}
              industryList={industryList}
              setCompanyList={setCompanyList}
              // setSelectedCompany = {setSelectedCompany}
              handleSelectedCompanyChange={handleSelectedCompanyChange}
              companyDetails={companyDetails}
              setCompanyDetails={setCompanyDetails}
              source="OFF_CAMPUS"
            />
          </div>
        </Dialog>

        <Dialog
          PaperProps={{ classes: { root: classes.root1 } }}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={openJob}
          onClose={() => setOpenJob(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <div style={{ padding: "40px" }}>
            <AddNewJob
              handleClose={handleCloseJob}
              handleCloseWithClear={handleCloseWithClear}
              setOffCampusFormData={setOffCampusFormData}
              offCampusFormData={offCampusFormData}
              handleSelectedJobChange={handleSelectedJobChange}
              setIsUpdate={setIsUpdate}
            />
          </div>
        </Dialog>
      </Box>
      <Box
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {" "}
        <Button
          variant="contained"
          color="primary"
          style={{ boxShadow: "none", width: "150px" }}
          onClick={() => {
            if (!loading) handleSubmit();
          }}
        >
          {loading ? (
            <CircularProgress className={classes.circularProgress} size={14} />
          ) : (
            <>Add Student</>
          )}
        </Button>
      </Box>
    </>
  );
}

export default OffCampusForm;
