import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  collegeDetailSelectorFn,
  collegeGroupListSelectorFn,
  isCollegeGroupFn,
  entityInfoSelectorFn,
} from "../../../api/SignIn/selector";
import { EDGBillingAPI } from "../../../api/University/APIs/action";
import UplacementCycle from "../../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../../components/CircularProgressBar";
import GroupListDropdown from "../../../components/Input/DropDownButton/GroupListDropdown";
import { BackendBaseApi } from "../../../constants/constants";
import { generateCsvForParams } from "../../../utility/commaSeparatedStrings";
import useCollegeGroupList from "../../../Hooks/useCollegeGroupList";
import useNavBarCollegeGroupList from "../../../Hooks/useNavBarCollegeGroupList";

function BillingUniversity() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  // const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const entityInfo = useSelector(entityInfoSelectorFn);

  const {
    newCollegeGroupList,
    setNewCollegeGroupList,
    selectedGroup,
    setSelectedGroup,
  } = useCollegeGroupList(collegeGroupList, isCollegeGroup);

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `group_id=${selectedGroup.id}`;
      else
        return `group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      var url = `${
        BackendBaseApi.PRAVESH
      }api/saas/educational_group_billing/?${checkSelectedGroup()}`;
      dispatch(
        EDGBillingAPI(
          setLoading,
          alert,
          history,
          location,
          collegeDetail?.education_group_id,
          url
        )
      );
    }
  }, [selectedGroup]);

  // const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  // useEffect(() => {
  //   if (collegeGroupList?.length > 0 && isCollegeGroup) {
  //     setNewCollegeGroupList(
  //       [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
  //     );
  //   } else setNewCollegeGroupList(collegeGroupList);
  // }, [collegeGroupList?.length]);

  const collegeCompanyErfList = useSelector(
    (state) => state?.EDGCommon?.edgBilling
  );

  // useEffect(() => {
  //   if (collegeGroupList) setSelectedGroup(collegeGroupList[0]);
  // }, [collegeGroupList]);

  useNavBarCollegeGroupList(selectedGroup, setLoading);

  return (
    <>
      {/* <UniversityLayout> */}
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      <br />
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Typography variant="h2">
            {entityInfo?.college} Billing Info
          </Typography>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={5}>
              {collegeCompanyErfList?.length > 0 &&
                collegeCompanyErfList?.map((item) => (
                  <Grid item xs={4}>
                    <UplacementCycle
                      item={item}
                      link={`/billing-info`}
                      linkTitle={`View ${entityInfo?.college} Billing Info`}
                      from={"Billing"}
                      countTitle={"License"}
                      count={`${item?.license_used}/${item?.license_purchased}`}
                      secondaryCountTitle={"Amount"}
                      secondaryCount={
                        item?.amount ? `₹ ${item?.amount} INR` : "NA"
                      }
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default BillingUniversity;
