import React from "react";

export default function Settings({ primaryColor }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="99"
        height="101"
        viewBox="0 0 99 101"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_5064"
              data-name="Rectangle 5064"
              width="99"
              height="101"
              transform="translate(380 270)"
              fill="#fff"
              stroke="#707070"
              stroke-width="1"
            />
          </clipPath>
        </defs>
        <g
          id="Mask_Group_1"
          data-name="Mask Group 1"
          transform="translate(-380 -270)"
          clip-path="url(#clip-path)"
        >
          <g id="form" transform="translate(389.863 277.935)">
            <path
              id="Path_31920"
              data-name="Path 31920"
              d="M88.9,58.8A12.171,12.171,0,0,1,85.2,50a12.34,12.34,0,0,1,3.7-8.8,14.96,14.96,0,0,1,1.3-1.1,2.051,2.051,0,0,0,.7-2.2,40.213,40.213,0,0,0-3.4-8.3,2.031,2.031,0,0,0-2-1,10.9,10.9,0,0,1-1.7.1A12.484,12.484,0,0,1,71.3,16.3a10.284,10.284,0,0,1,.1-1.7,2.031,2.031,0,0,0-1-2,40.213,40.213,0,0,0-8.3-3.4,2.242,2.242,0,0,0-2.2.7c-.4.5-.8,1-1.1,1.3A12.665,12.665,0,0,1,50,14.8a12.488,12.488,0,0,1-8.8-3.6,14.96,14.96,0,0,1-1.1-1.3,2.051,2.051,0,0,0-2.2-.7,46.629,46.629,0,0,0-8.3,3.4,2.031,2.031,0,0,0-1,2,10.9,10.9,0,0,1,.1,1.7A12.42,12.42,0,0,1,16.2,28.7a10.284,10.284,0,0,1-1.7-.1,2.031,2.031,0,0,0-2,1,40.213,40.213,0,0,0-3.4,8.3,2.051,2.051,0,0,0,.7,2.2,8.116,8.116,0,0,1,1.3,1.1,12.373,12.373,0,0,1,0,17.6,14.96,14.96,0,0,1-1.3,1.1,2.051,2.051,0,0,0-.7,2.2,46.63,46.63,0,0,0,3.4,8.3,2.031,2.031,0,0,0,2,1,10.9,10.9,0,0,1,1.7-.1A12.484,12.484,0,0,1,28.7,83.7a10.284,10.284,0,0,1-.1,1.7,2.031,2.031,0,0,0,1,2,40.213,40.213,0,0,0,8.3,3.4,2.051,2.051,0,0,0,2.2-.7c.4-.5.8-1,1.1-1.3A12.665,12.665,0,0,1,50,85.2a12.488,12.488,0,0,1,8.8,3.6,14.96,14.96,0,0,1,1.1,1.3,2.088,2.088,0,0,0,1.6.8,1.268,1.268,0,0,0,.6-.1,46.63,46.63,0,0,0,8.3-3.4,2.031,2.031,0,0,0,1-2,10.9,10.9,0,0,1-.1-1.7A12.42,12.42,0,0,1,83.8,71.3a10.284,10.284,0,0,1,1.7.1,2.031,2.031,0,0,0,2-1,40.213,40.213,0,0,0,3.4-8.3,2.051,2.051,0,0,0-.7-2.2,7.474,7.474,0,0,1-1.3-1.1Zm-4.3,8.5h-.9A16.5,16.5,0,0,0,67.2,83.7v.9a38.878,38.878,0,0,1-5.1,2.1l-.6-.6a16.417,16.417,0,0,0-23.2,0l-.6.6a54.859,54.859,0,0,1-5.1-2.1v-.9A16.431,16.431,0,0,0,16.1,67.3h-.9a38.878,38.878,0,0,1-2.1-5.1l.6-.6a16.538,16.538,0,0,0,0-23.3l-.6-.6a54.858,54.858,0,0,1,2.1-5.1h.9A16.5,16.5,0,0,0,32.6,16.2v-.9a38.878,38.878,0,0,1,5.1-2.1l.6.6a16.417,16.417,0,0,0,23.2,0l.6-.6a54.859,54.859,0,0,1,5.1,2.1v.9A16.431,16.431,0,0,0,83.7,32.6h.9a38.878,38.878,0,0,1,2.1,5.1l-.6.6a16.417,16.417,0,0,0,0,23.2l.6.6a35.234,35.234,0,0,1-2.1,5.2ZM50,26.2A23.8,23.8,0,1,0,73.8,50,23.857,23.857,0,0,0,50,26.2Zm0,43.6A19.8,19.8,0,1,1,69.8,50,19.845,19.845,0,0,1,50,69.8Z"
              transform="translate(-10.363 -7.448)"
              fill={primaryColor}
            />
          </g>
        </g>
      </svg>
    </>
  );
}
