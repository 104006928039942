import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import SaveEducation from "./SaveEducation";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddEducationDialog from "./AddEducationDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  icon: {
    color: "#007bff",
  },
  font: {
    fontFamily: "",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  newBtnStyle: {
    maxWidth: "200px",
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));

export default function Education({
  collegeList,
  boardsList,
  education,
  handleAddEdu,
  handlePatchEdu,
  handleDeleteEdu,
  eduDataLoading,
  approvalRequestCounts,
  redirectionData,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [tempEduList, setTempEduList] = useState([]);
  const [editEducation, setEditEducation] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  useEffect(() => {
    if (education) setTempEduList(education);
  }, [education]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setOpen(false);
    setEditEducation({});
  };

  const saveEducationChange = () => {
    isEdit
      ? handlePatchEdu(
          editEducation,
          editEducation?.education_id,
          editIndex,
          handleClose
        )
      : handleAddEdu(editEducation, handleClose);
  };

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h5">Education</Typography>
        <br />
        <Grid
          item
          xs={12}
          style={
            approvalRequestCounts?.EDU
              ? { pointerEvents: "none", opacity: 0.4 }
              : {}
          }
        >
          {tempEduList &&
            tempEduList.map((stuEdObj, index) => {
              return (
                <SaveEducation
                  education={stuEdObj}
                  index={index}
                  setEditEducation={setEditEducation}
                  handleDelete={handleDeleteEdu}
                  handleClickOpen={handleClickOpen}
                  setIsEdit={setIsEdit}
                  setEditIndex={setEditIndex}
                />
              );
            })}
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
          {approvalRequestCounts?.EDU ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.newBtnStyle}
              onClick={() => {
                history.push({
                  pathname: `/student-profile-request/${redirectionData?.name.replace(
                    / /g,
                    "_"
                  )}-${redirectionData?.userId}`,
                });
              }}
            >
              {`Approve Request (${approvalRequestCounts?.EDU ?? 0})`}
            </Button>
          ) : (
            <div>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="text"
                color="primary"
                onClick={handleClickOpen}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                }}
              >
                {"Save"}
              </Button>
            </div>
          )}
        </Grid>
      </div>
      <AddEducationDialog
        collegeList={collegeList}
        boardsList={boardsList}
        open={open}
        handleClose={handleClose}
        editEducation={editEducation}
        setEditEducation={setEditEducation}
        saveEducation={saveEducationChange}
        eduDataLoading={eduDataLoading}
        isEdit={isEdit}
      />
    </>
  );
}
