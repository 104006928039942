import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography, Button } from "@material-ui/core";
import Course from "../../Course";

export default class Degree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        chart: {
          id: "apexchart-example",
          toolbar: {
            show: false,
          },
        },

        xaxis: {
          categories: props?.data?.categories,
        },
        yaxis: {
          title: {
            text: "Degree",
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Nunito",
            },
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "75%",
            borderRadius: 8,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      },

      // series: [
      //   {
      //     name: "Total Students",
      //     data: [60, 40, 35, 50, 49, 60],
      //     color: "#377dff",
      //   },
      //   {
      //     name: "Placed Students",
      //     data: [50, 49, 60, 70, 91, 125],
      //     color: "#38cb89",
      //   },
      // ],
      series: props?.data?.series_list,
    };
  }
  render() {
    return (
      <Charst
        options={this.state.options}
        series={this.state.series}
        type="bar"
        height={600}
      />
    );
  }
}
