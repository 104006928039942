import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";
import Course from "../../Course";
import DropDownButton from "../../../Input/DropDownButton";
import ReportDropDown from "../../../Input/DropDownButton/ReportDropDown";

export default class SecondBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        chart: {
          id: "apexchart-example",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            "Software Eng",
            "Tester",
            "UI Designer",
            "UX Designer",
            "F Developer",
            "B Developer",
          ],
        },
        yaxis: {
          title: {
            text: "Number of Students",
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Nunito",
            },
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            borderRadius: 8,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      },

      series: [
        {
          name: "series-1",
          data: [60, 40, 35, 50, 49, 60],
          color: "#377dff",
        },
        {
          name: "series-b",
          data: [50, 49, 60, 70, 91, 125],
          color: "#38cb89",
        },
      ],
    };
  }
  render() {
    return (
      <Paper
        style={{
          boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
          borderRadius: "8px",
          padding: "30px",
        }}
      >
        <Typography variant="h6">
          Campus Placement based on Specializations
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", borderBottom: ".5px solid #f3f3f3" }}>
            <div
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FAFBFC",
                  //   padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "8px",
                  width: "120px",
                  height: "40px",
                  color: "#8a94a6",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#377dff",
                    height: "10px",
                    width: "10px",
                    borderRadius: "8px",
                  }}
                />
                <Typography variant="overline">Total Students</Typography>
              </div>
            </div>
            <div
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FAFBFC",
                  //   padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "8px",
                  width: "100px",
                  height: "40px",
                  color: "#8a94a6",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#38cb89",
                    height: "10px",
                    width: "10px",
                    borderRadius: "8px",
                  }}
                />
                <Typography variant="overline">Placed Student</Typography>
              </div>
            </div>
          </div>
          <div>
            <ReportDropDown name="Degree" />
            {/* <DropDownButton title="Degree" /> */}
          </div>
        </div>
        <Charst
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={320}
        />
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          Specialization
        </Typography>
      </Paper>
    );
  }
}
