import React, { useEffect, useState } from "react";
import { BackendBaseApi } from "../../constants/constants";
import axios from "axios";

const usePublicJobPost = (
  jobId,
  college_id,
  setLoading,
  setLoadingSideData,
  loginSucessful
) => {
  const [otherJobs, setOtherJobs] = useState([]);
  const [jobDetails, setJobDetails] = useState({});
  const [applied, setApplied] = useState(false);


  useEffect(() => {
    setLoadingSideData(true);
    axios
      .get(
        BackendBaseApi.SANCHALAN +
          `public/public-job-lists/?college_id=${college_id}&job_id=${jobId}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setLoadingSideData(false);
        // console.log(response?.data?.data ,"rrrr")
       if(response?.data?.success) setOtherJobs(response?.data?.data);
       else alert.error(response?.data?.error);
      })
      .catch((error) => {
        setLoadingSideData(false);
        alert.error(error?.response?.data?.error);
        throw error;
      });
  }, [loginSucessful, jobId]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        BackendBaseApi.SANCHALAN + `public/public-job-details/?job_id=${jobId}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setLoading(false);
       if(response?.data?.success){ setJobDetails(response?.data?.data);
        setApplied(response?.data?.data?.is_applied);
       }
       else alert.error(response?.data?.error);
      })
      .catch((error) => {
        setLoading(false);
        alert.error(error?.response?.data?.error);
        throw error;
      }); 
  }, [loginSucessful, jobId]);

  return [otherJobs, jobDetails, applied, setApplied];
};

export default usePublicJobPost;
