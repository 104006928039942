import {
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import clsx from "clsx";
import { min } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { createInterview } from "../../../api/Assignment/action";
import { collegeDetailSelectorFn } from "../../../api/SignIn/selector";
import { emailRegex } from "../../../utility/regex";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import Editor from "../../Editor";
import AutoFillEmail from "../../Input/AutoFillEmail";
// import Calendar from "../../Input/Calendar";
import Calendar from "../../Input/Calendar/index2";
import "./time.css";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    height: "50px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
  },
  btn: {
    borderRadius: "8px",
    // height: "100%",
    padding: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginRight: "10px",
    boxShadow: "none",
    //width: "100%",
    display: "flex",
  },

  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "8px",
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  move: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "40px",
    paddingLeft: "20px",
    marginLeft: "10px",
    paddingRight: "20px",
  },
  labellink: {
    color: theme.palette.primary.main,
  },

  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  chipview: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    //  padding: theme.spacing(0.5),
    margin: 0,
  },
  view: {
    borderRadius: "8px",
    marginRight: "10px",
    boxShadow: "none",
    padding: "10px",
    height: "48px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
    justifyContent: "flex-start",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  time: {
    marginTop: "0px",
    marginBottom: "10px",
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      /// className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function InterviewScheduling({
  jobData,
  selectedRowsData,
  setSelectedRowsData,
  setSelectedID,
  filters,
}) {
  const classes = useStyles();
  const [interviewDetails, setInterviewDetails] = useState({
    title: filters?.navbar_object?.tooltip + " Round Interview",
    start_time: null,
    end_time: null,
    date: null,
    email_list: [],
    type: "ONLINE",
    address: null,
    link: "Auto generate",
    slot_interval: "",
    description: "",
    custom_link: null,
  });
  const [loading, setLoading] = useState(false);
  const [interviewDetailsError, setInterviewDetailsError] = useState({
    title: null,
    start_time: null,
    end_time: null,
    date: null,
    email_list: null,
    type: "Online",
    address: null,
    link: "Auto generate",
    slot_interval: null,
    description: null,
    custom_link: null,
  });
  const alert = useAlert();
  const [value, onChange] = useState(new Date());
  const [email, setEmail] = useState();
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    var arr = [];
    selectedRowsData.map((item, index) => {
      arr.push({
        student_id: item.id,
        slot_number: index + 1,
        name: item.name,
        college_id: item?.college_id,
        enrollment_id: item?.enrollment_id,
      });
    });
    setSelectedStudent(arr);
  }, [selectedRowsData]);
  const [editor, setEditor] = useState();

  const [open, setOpen] = React.useState(false);
  const fullWidth = true;
  const maxWidth = "md";

  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (index) => {
    if (index !== -1) {
      var arr = [...interviewDetails?.email_list];
      arr.splice(index, 1);
      setInterviewDetails({ ...interviewDetails, email_list: arr });
    }
  };

  const handleDeleteChip = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  useEffect(() => {
    calculateEndTime();
  }, [interviewDetails.start_time, interviewDetails.slot_interval]);

  const calculateEndTime = () => {
    var len = selectedRowsData.length;
    var min_fixed;

    var min = len * interviewDetails.slot_interval;

    var start_time = String(interviewDetails.start_time + ":00");
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }

    var piece = start_time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +min;

    setInterviewDetails({
      ...interviewDetails,
      end_time: D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60),
    });
  };

  useEffect(() => {
    perObjectTime();
  }, [interviewDetails.start_time, interviewDetails.end_time]);

  const perObjectTime = () => {
    let endTime = calculateTime(
      interviewDetails.start_time,
      interviewDetails.slot_interval,
      1
    );
    selectedStudent.forEach((item, index) => {
      item.start_date = String(
        value.getFullYear() +
          "-" +
          (value.getMonth() + 1) +
          "-" +
          value.getDate() +
          " " +
          calculateTime(
            interviewDetails.start_time,
            interviewDetails.slot_interval,
            index
          )
      );
      item.end_date = String(
        value.getFullYear() +
          "-" +
          (value.getMonth() + 1) +
          "-" +
          value.getDate() +
          " " +
          calculateTime(endTime, interviewDetails.slot_interval, index)
      );
      item.slot_number = index + 1;
    });
  };

  const calculateTime = (start, min, i) => {
    min = min * i;
    var start_time = String(start + ":00");
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }

    var piece = start_time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +min;
    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  };

  const validateData = () => {
    var title_error = null;
    var date_error = null;
    var start_time_error = null;
    var email_error = null;
    var address_error = null;
    var custom_link_error = null;
    var slot_int_error = null;
    var is_valid = true;

    if (!interviewDetails?.title) {
      title_error = "Please Enter Title";
      is_valid = false;
    }
    if (!interviewDetails?.date) {
      date_error = "Please Enter Date";
      is_valid = false;
    }
    // if (!interviewDetails?.start_time) {
    //   start_time_error = "Please Enter start time";
    //   is_valid = false;
    // }
    if (interviewDetails?.email_list?.length === 0) {
      email_error = "Please enter atleast one email";
      is_valid = false;
    }
    if (interviewDetails?.type === "OFFLINE" && !interviewDetails?.address) {
      address_error = "Please enter your address";
      is_valid = false;
    }
    if (
      interviewDetails?.type === "ONLINE" &&
      interviewDetails?.link !== "Auto generate" &&
      !interviewDetails?.custom_link
    ) {
      custom_link_error = "Please enter custom link";
      is_valid = false;
    }
    if (!interviewDetails?.slot_interval) {
      slot_int_error = "Please enter Slot Duration";
      is_valid = false;
    }

    setInterviewDetailsError({
      ...interviewDetailsError,
      title: title_error,
      start_time: start_time_error,
      date: date_error,
      email_list: email_error,
      address: address_error,
      slot_interval: slot_int_error,
      custom_link: custom_link_error,
    });
    return is_valid;
  };
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    // return;
    if (validateData()) {
      let collegeIdsList = selectedStudent?.map((item) => {
        return item["college_id"];
      });
      const collegeIds = Array.from(new Set(collegeIdsList));
      var data = {
        job_id: filters?.job_id,
        company_id: jobData.company_id,
        // start_date: `${interviewDetails?.date} ${interviewDetails?.start_time}`,
        // end_date: `${interviewDetails?.date} ${interviewDetails?.end_time}`,
        start_date: `${interviewDetails?.date} ${interviewDetails?.start_time}`,
        end_date: `${moment(
          `${interviewDetails?.date} ${interviewDetails?.start_time}`
        )
          .add(
            interviewDetails?.slot_interval * selectedStudent.length,
            "minutes"
          )
          .format("YYYY-MM-DD HH:mm")}`,
        user_id: collegeDetails.user_id,
        type: interviewDetails?.type.toUpperCase(),
        address: interviewDetails?.address,
        student_detail: selectedStudent,
        round: filters?.current_status,
        description: editor,
        custom_email: interviewDetails?.email_list,
        college_id: collegeIds,
        title: interviewDetails?.title,
      };

      if (interviewDetails?.link !== "Auto generated") {
        data.custom_link = interviewDetails?.custom_link;
      }
      setLoading(true);
      dispatch(
        createInterview(
          setLoading,
          data,
          alert,
          history,
          location,
          true,
          handleClose
        )
      );
    }
  };
  const [htmlDesc, setHtmlDesc] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (
        selectedStudent.length >= 1 &&
        interviewDetails.end_time &&
        interviewDetails.slot_interval &&
        interviewDetails.start_time
      ) {
        var des = selectedStudent.map((item) => {
          return `<p><b>${item?.name} (${item?.enrollment_id}) - ${moment(
            item?.start_date
          ).format("LT")} - ${moment(item?.end_date).format("LT")}${"\n"}</p>`;
        });
      }
      if (des?.length >= 1) {
        setHtmlDesc(String(des));

        // setInterviewDetails({
        //   ...interviewDetails,
        //   end_time: moment(`${interviewDetails?.end_time} ${interviewDetails?.start_time}`)
        //     .add(interviewDetails?.slot_interval * 5, "minutes")
        //     .format("hh:mm:ss"),
        // });
      }
    }, 2000);
  }, [
    selectedStudent,
    interviewDetails.end_time,
    interviewDetails.slot_interval,
    interviewDetails.start_time,
  ]);

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={handleClickOpen}
        className={classes.move}
      >
        Schedule Interview
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <div style={{ padding: "40px" }}>
            {/* <Typography variant="h6">Interview Scheduling</Typography> */}

            <TextField
              variant="outlined"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="Add Title"
              value={interviewDetails?.title}
              onChange={(e) => {
                setInterviewDetails({
                  ...interviewDetails,
                  title: e.target.value,
                });
                setInterviewDetailsError({
                  ...interviewDetailsError,
                  title: "",
                });
              }}
              error={interviewDetailsError?.title ? true : false}
              helperText={<span>{interviewDetailsError?.title}</span>}
            />
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {" "}
              <Calendar
                title="Date"
                // selectedDate={moment(deadline).format("YYYY-MM-DD")}
                selectedDate={interviewDetails?.date}
                setSelectedDate={(date) => {
                  setInterviewDetails({
                    ...interviewDetails,
                    date: moment(date).format("YYYY-MM-DD"),
                  });
                  setInterviewDetailsError({
                    ...interviewDetailsError,
                    date: "",
                  });
                }}
                readOnly={true}
                minDate={new Date()}
              />
              {/* <Calendar
                selectedDate={interviewDetails?.date}
                title="Date"
                onDateChange={(date) => {
                  setInterviewDetails({
                    ...interviewDetails,
                    date: moment(date).format("YYYY-MM-DD"),
                  });
                  setInterviewDetailsError({ ...interviewDetailsError, date: "" });
                }}
                readOnly={true} 
                errorMessage={interviewDetailsError?.date}
                minDate={new Date()}
              /> */}
              <div
                style={{ width: "170px", display: "flex", marginLeft: "20px" }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TimePickerComponent
                    style={{ border: "none", borderBottom: "1px solid #fff" }}
                    placeholder="Start time"
                    value={interviewDetails?.start_time}
                    format="HH:mm"
                    onChange={(e) => {
                      setInterviewDetails({
                        ...interviewDetails,
                        start_time: moment(e.target.value).format("HH:mm"),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div component="ul" className={classes.chipview}>
              {!showMore
                ? selectedRowsData.slice(0, 5).map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDeleteChip(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : selectedRowsData.map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDeleteChip(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
              {selectedRowsData?.length > 5 && (
                <Button
                  className={classes.view}
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                  style={{ padding: "5px" }}
                  endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  color="primary"
                >
                  {showMore ? "Hide" : "View All"}
                </Button>
              )}
            </div>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <Typography variant="h6">Invite Interviewer(s)</Typography>
              </Grid>

              <Grid item xs={10} className={classes.grid}>
                <div className={classes.paper}>
                  <AutoFillEmail
                    value={email}
                    title=""
                    onchange={(event) => {
                      setEmail(event.target.value);
                    }}
                    error={interviewDetailsError?.email_list}
                  />{" "}
                </div>
              </Grid>

              <Grid
                item
                xs={2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  onClick={() => {
                    if (emailRegex.test(email)) {
                      var arr = [...interviewDetails.email_list];
                      arr.push(email);
                      setInterviewDetails({
                        ...interviewDetails,
                        email_list: arr,
                      });
                      setEmail("");
                    } else {
                      alert.error("Invalid Email");
                    }
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={12}>
                <div>
                  {interviewDetails?.email_list?.length > 0 &&
                    interviewDetails?.email_list?.map((item, index) => (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(index)}
                        deleteIcon={<CloseIcon />}
                        label={item}
                        className={classes.chip}
                      />
                    ))}
                </div>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue="Admin"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                    name="customized-radios"
                    value={interviewDetails?.type}
                    onChange={(e) => {
                      setInterviewDetails({
                        ...interviewDetails,
                        type: e.target.value,
                      });
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginRight: "10px" }}
                      variant="body2"
                    >
                      Interview Type
                    </Typography>
                    <FormControlLabel
                      value="ONLINE"
                      control={<StyledRadio />}
                      label={<Typography variant="body2">Online</Typography>}
                    />
                    <FormControlLabel
                      value="OFFLINE"
                      control={<StyledRadio />}
                      label={<Typography variant="body2">Offline</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {interviewDetails?.type &&
                interviewDetails?.type === "OFFLINE" && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        fullWidth
                        label="Add your venue"
                        value={interviewDetails.address}
                        onChange={(event) => {
                          setInterviewDetails({
                            ...interviewDetails,
                            address: event.target.value,
                          });
                          setInterviewDetailsError({
                            ...interviewDetailsError,
                            address: "",
                          });
                        }}
                        error={interviewDetailsError?.address ? true : false}
                        helperText={interviewDetailsError?.address}
                      />
                    </Grid>
                  </>
                )}

              {interviewDetails?.type &&
                interviewDetails?.type === "ONLINE" && (
                  <>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          defaultValue="Admin"
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                          }}
                          name="customized-radios"
                          value={interviewDetails?.link}
                          onChange={(e) => {
                            setInterviewDetails({
                              ...interviewDetails,
                              link: e.target.value,
                            });
                          }}
                        >
                          <FormControlLabel
                            value="Auto generate"
                            control={<StyledRadio />}
                            label={
                              <Typography variant="body2">
                                Auto Link Generate
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="Enter link"
                            control={<StyledRadio />}
                            label={
                              <Typography variant="body2">
                                Enter Your Link
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                      <div className={classes.paper}>
                        {interviewDetails?.link === "Auto generate" ? (
                          <Typography variant="body2">
                            Interview link has been generated, you can see the
                            link in your mail after Scheduling your Interview.
                          </Typography>
                        ) : (
                          <>
                            <TextField
                              variant="outlined"
                              InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                              }}
                              fullWidth
                              label="Add Meet Link"
                              value={interviewDetails.custom_link}
                              onChange={(event) => {
                                setInterviewDetails({
                                  ...interviewDetails,
                                  custom_link: event.target.value,
                                });
                                setInterviewDetailsError({
                                  ...interviewDetailsError,
                                  custom_link: "",
                                });
                              }}
                              error={
                                interviewDetailsError?.custom_link
                                  ? true
                                  : false
                              }
                              helperText={interviewDetailsError?.custom_link}
                            />
                          </>
                        )}
                        {/* <Paper component="form" className={classes.link}>
                        <InputBase
                          className={classes.input}
                          placeholder="https://getwork-ejd-mba.in"
                          inputProps={{
                            className: classes.labellink,
                            "aria-label": "https://getwork-ejd-mba.in",
                          }}
                        />
                        <IconButton type="copy" className={classes.iconButton} aria-label="copy">
                          <FileCopyOutlinedIcon color="primary" />
                        </IconButton>
                      </Paper> */}
                      </div>
                    </Grid>{" "}
                  </>
                )}
            </Grid>

            <div
              style={{
                display: "flex",
                marginTop: "30px",
                flexDirection: "column",
              }}
            >
              <form className={classes.time}>
                <TextField
                  id="time"
                  label="Interview Slot Duration (in mint) "
                  // type="time"
                  variant="outlined"
                  style={{ width: "300px" }}
                  //  defaultValue="07:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: min, // 5 min
                  }}
                  value={interviewDetails?.slot_interval}
                  onChange={(e) => {
                    setInterviewDetails({
                      ...interviewDetails,
                      slot_interval: e.target.value,
                    });
                    setInterviewDetailsError({
                      ...interviewDetailsError,
                      slot_interval: "",
                    });
                  }}
                  error={interviewDetailsError?.slot_interval ? true : false}
                  helperText={interviewDetailsError?.slot_interval}
                />
              </form>

              {/* <Location /> */}
              <br />
              {/* <TextField
                id="outlined-multiline-static"
                label="Add Description"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                multiline
                style={{ width: "100%" }}
                rows={6}
                variant="outlined"
                value={interviewDetails?.description}
                onChange={(e) => {
                  setInterviewDetails({ ...interviewDetails, description: e.target.value });
                }}
              /> */}
              <Editor state={editor} setState={setEditor} desc={htmlDesc} />
            </div>
          </div>
          <div
            style={{
              marginTop: "-40px",
              padding: "25px",
              marginLeft: "10px",
              display: "flex",
              paddingLeft: "25px",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              className={classes.btn}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.btn}
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? (
                <CustomButtonCircularProgress />
              ) : (
                "Schedule Interview"
              )}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
