import React from "react";
import ArrowBackIosRounded from "@mui/icons-material/ArrowBackIosRounded";

function PreOfferArrow({ onClick }) {
  const btn = {
    height: "40px",
    width: "40px",

    backgroundColor: "#000",
    color: "#fff",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    zIndex: 1000,
    top: "45%",
    bottom: "50%",
    left: "0px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  };
  return (
    <div onClick={onClick}>
      <div style={btn}>
        <ArrowBackIosRounded />
      </div>
    </div>
  );
}

export default PreOfferArrow;
