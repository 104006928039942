import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { emailRegex } from "../../../utility/regex";
import AddCompanyMember from "../../DialogSection/AddNewCompany/AddCompanyMember";
import Editor from "../../Editor";
import Calendar from "../../Input/Calendar";
import CompanyDetailsTable from "../../Table/CompanyDetailsTable";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  errorMessage: {
    color: "#DC3545",
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
}));
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default function BasicInfo({
  data,
  companyDetails,
  setCompanyDetails,
  companyDetailsError,
  setCompanyDetailsError,
  industryList,
  pocList,
  setPocList,
  companyNotExist,
  setCompanyNotExist,
  companyList,
  jobDesc,
  disableCompany,
}) {

  const classes = useStyles();
  const [state, setState] = React.useState({
    industry: "",
    name: "hai",
  });
  
 
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const [editor, setEditor] = React.useState("");

  const [values, setValues] = React.useState({
    textmask: "(1  )    -    ",
    numberformat: "1320",
  });

  const handleChangeText = (e) => {
    var name = e.target.name;
    setCompanyDetails({ ...companyDetails, [name]: e.target.value });
    setCompanyDetailsError({ ...companyDetailsError, [name]: null });
  };

  const validatePoc = () => {
    var is_valid = true;
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    if (!companyDetails?.single_poc?.contact_person_name) {
      is_valid = false;
      name_error = "Please Enter contact person name";
    }
    if (!companyDetails?.single_poc?.email) {
      is_valid = false;
      email_error = "Please Enter contact person Email";
    }
    if (
      companyDetails?.single_poc?.email &&
      !emailRegex.test(companyDetails?.single_poc?.email)
    ) {
      is_valid = false;
      email_error = "Please Enter valid contact person Email";
    }
    if (!companyDetails?.single_poc?.phone) {
      is_valid = false;
      phone_error = "Please Enter contact person Phone No.";
    }
    if (
      companyDetails?.single_poc?.phone &&
      companyDetails?.single_poc?.phone?.length !== 10
    ) {
      is_valid = false;
      phone_error = "Please Enter valid phone No.";
    }

    setCompanyDetailsError({
      ...companyDetailsError,
      single_poc: {
        ...companyDetailsError.single_poc,
        contact_person_name: name_error,
        email: email_error,
        phone: phone_error,
        designation: null,
      },
    });
    return is_valid;
  };

  const handleAddPoc = () => {
    if (validatePoc()) {
      var arr = [...companyDetails?.poc_list];
      arr.push(companyDetails?.single_poc);
      setCompanyDetails({
        ...companyDetails,
        poc_list: arr,
        single_poc: {
          contact_person_name: "",
          email: "",
          phone: "",
          designation: "",
        },
      });
    }
  };

  const handleDeletePoc = (index) => {
    if (index !== -1) {
      var arr = [...companyDetails?.poc_list];
      arr.splice(index, 1);
      setCompanyDetails({ ...companyDetails, poc_list: arr });
    }
  };

  useEffect(() => {
    setCompanyDetails({ ...companyDetails, about: editor });
  }, [editor]);

  function getFieldContent(step, item, index) {

    switch (item?.field_type) {
      case "textfield":
        return (
          <>
            {item.key !== "company_name" ? (
              <TextField
                variant="outlined"
                margin="normal"
                required
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={companyDetails[item.key] ? companyDetails[item.key] : ""}
                onChange={handleChangeText}
                fullWidth
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                id="title"
                style={{ marginBottom: "25px" }}
                label={item?.field_name}
                name={item.key}
                error={companyDetailsError[item.key] ? true : false}
                helperText={<span>{companyDetailsError[item.key]}</span>}
              />
            ) : (
              <>
                {companyNotExist ? (
                  <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      InputLabelProps={{
                        className: classes?.floatingLabelFocusStyle,
                      }}
                      value={companyDetails[item.key] ? companyDetails[item.key] : "" }
                      onChange={handleChangeText}
                      fullWidth
                      inputProps={{
                        style: {
                          padding: "16px",
                        },
                      }}
                      id="title"
                      style={{ marginBottom: "25px" }}
                      label={item?.field_name}
                      name={item?.key}
                      error={companyDetailsError[item.key] ? true : false}
                      helperText={<span>{companyDetailsError[item.key]}</span>}
                      disabled={disableCompany}

                    />
                    <Button
                      color="primary"
                      style={{
                        display: "flex",
                        marginTop: -20,
                        marginLeft: 0,
                        marginBottom: "10px",
                      }}
                      startIcon={<AddCircleIcon />}
                      onClick={() => {
                        setCompanyNotExist(false);
                      }}
                      disabled={disableCompany}
                    >
                      Choose From Existing Company
                    </Button>
                  </>
                ) : (
                  <>
                    <Autocomplete
                      id="country-select-demo"
                      options={companyList ? companyList : []}
                      getOptionLabel={(option) => {return option?.name ? option?.name : ""} }
                      value={companyDetails?.company_object ? companyDetails?.company_object : "" }
                      
                      disabled={disableCompany}
                      onChange={(e, newValue) => {
                        
                        setCompanyDetails({
                          ...companyDetails,
                          company_object: newValue,
                        });
                        setCompanyDetailsError({
                          ...companyDetailsError,
                          company_name: null,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Choose From Existing Companies"
                          inputProps={{
                            ...params?.inputProps,
                          }}
                          InputLabelProps={{
                            className: classes?.floatingLabelFocusStyle,
                          }}
                          error={companyDetailsError[item?.key] ? true : false}
                          helperText={
                            <span>{companyDetailsError[item?.key]}</span>
                          }
                        />
                      )}
                    />
                    <br />
                    <Button
                      color="primary"
                      style={{ display: "flex", marginTop: -15, marginLeft: 0 }}
                      startIcon={<AddCircleIcon />}
                      onClick={() => {
                        setCompanyNotExist(true);
                      }}
                      disabled={disableCompany}
                    >
                      Add Company
                    </Button>
                  </>
                )}
              </>
            )}

            {/* <br /> */}
          </>
        );

      case "autocomplete":
        return (
          <>
            {companyNotExist && (
              <>
                <Autocomplete
                  id="country-select-demo"
                  options={industryList}
                  getOptionLabel={(option) => option.name}
                  value={companyDetails.industry}
                  disabled={disableCompany}
                  onChange={(e, newValue) => {
                    setCompanyDetails({
                      ...companyDetails,
                      industry: newValue,
                    });
                    setCompanyDetailsError({
                      ...companyDetailsError,
                      industry: null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Industry"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={companyDetailsError.industry ? true : false}
                      helperText={<span>{companyDetailsError.industry}</span>}
                    />
                  )}
                />
                <br />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginBottom: "20px" }}
                  disabled={disableCompany}
                >
                  <InputLabel
                    className={
                      companyDetailsError.industry_type
                        ? classes.redfloatingLabelFocusStyle
                        : classes.floatingLabelFocusStyle
                    }
                  >
                    Industry Type
                  </InputLabel>
                  <Select
                    error={companyDetailsError.industry_type ? true : false}
                    value={companyDetails.industry_type_id}
                    onChange={(e) => {
                      setCompanyDetails({
                        ...companyDetails,
                        industry_type_id: e.target.value,
                      });
                      setCompanyDetailsError({
                        ...companyDetailsError,
                        industry_type: null,
                      });
                    }}
                    label="Industry Type"
                    inputProps={{
                      style: {
                        padding: "16px",
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <MenuItem value={1} name={"Private"}>
                      Private
                    </MenuItem>
                    <MenuItem value={2} name={"Government"}>
                      Government
                    </MenuItem>
                    <MenuItem value={3} name={"Semi-Government"}>
                      Semi-Government
                    </MenuItem>
                  </Select>
                  <FormHelperText className={classes.errorMessage}>
                    {companyDetailsError.industry_type}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          </>
        );
      case "date":
        return (
          <>
            {/* {companyNotExist && ( */}
              <>
              <Calendar
                title={item.field_name}
                selectedDate={companyDetails?.visiting_date}
                setSelectedDate={setCompanyDetails}
                onDateChange={(date) => {
                  setCompanyDetails({
                    ...companyDetails,
                    visiting_date: moment(date).format("YYYY-MM-DD"),
                  });
                  setCompanyDetailsError({
                    ...companyDetailsError,
                    visiting_date: null,
                  });
                }}
                errorMessage={""}
                readOnly={true}
              />
               <Typography
             
              style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "10px" ,        fontSize:".6rem" }}
            >
              Tentative date to visit college
            </Typography>
            </>
              
            {/* )} */}
          </>
        );
      case "spoc":
        return (
          <>
            <Typography
              variant="h6"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              SPOC Details (1/3)
            </Typography>

            {/* {companyDetails?.poc_list?.length > 0 &&
              companyDetails?.poc_list?.map((item, index) => (
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      id="full name"
                      label="Full Name"
                      name="full name"
                      autoComplete="title"
                      value={item.contact_person_name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      id="full name"
                      label="Full Name"
                      name="full name"
                      autoComplete="title"
                      value={item.email}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      id="full name"
                      label="Full Name"
                      name="full name"
                      autoComplete="title"
                      value={item.phone}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        handleDeletePoc(index);
                      }}
                    >
                      <DeleteOutlineIcon className={classes.iconColor} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            <Grid container spacing={3}>
              {companyDetails?.poc_list?.length <= 2 && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      id="full name"
                      label="Full Name"
                      name="full name"
                      autoComplete="title"
                      value={companyDetails?.single_poc?.contact_person_name}
                      onChange={e => {
                        setCompanyDetails({
                          ...companyDetails,
                          single_poc: {
                            ...companyDetails?.single_poc,
                            contact_person_name: e.target.value,
                          },
                        });
                        setCompanyDetailsError({
                          ...companyDetailsError,
                          single_poc: {
                            ...companyDetailsError?.single_poc,
                            contact_person_name: null,
                          },
                        });
                      }}
                      error={
                        companyDetailsError.single_poc.contact_person_name
                          ? true
                          : false
                      }
                      helperText={
                        <span>
                          {companyDetailsError.single_poc.contact_person_name}
                        </span>
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      id="email"
                      label="Email ID"
                      name="email"
                      value={companyDetails?.single_poc?.email}
                      onChange={e => {
                        setCompanyDetails({
                          ...companyDetails,
                          single_poc: {
                            ...companyDetails?.single_poc,
                            email: e.target.value,
                          },
                        });
                        setCompanyDetailsError({
                          ...companyDetailsError,
                          single_poc: {
                            ...companyDetailsError?.single_poc,
                            email: null,
                          },
                        });
                      }}
                      error={
                        companyDetailsError.single_poc.email ? true : false
                      }
                      helperText={
                        <span>{companyDetailsError.single_poc.email}</span>
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      // InputProps={{
                      //   inputComponent: NumberFormatCustom,
                      // }}
                      label="Phone Number"
                      name="phone number"
                      value={companyDetails?.single_poc?.phone}
                      onChange={e => {
                        setCompanyDetails({
                          ...companyDetails,
                          single_poc: {
                            ...companyDetails?.single_poc,
                            phone: e.target.value,
                          },
                        });
                        setCompanyDetailsError({
                          ...companyDetailsError,
                          single_poc: {
                            ...companyDetailsError?.single_poc,
                            phone: null,
                          },
                        });
                      }}
                      error={
                        companyDetailsError.single_poc.phone ? true : false
                      }
                      helperText={
                        <span>{companyDetailsError.single_poc.phone}</span>
                      }
                    />
                  </Grid> */}
            <div style={{}}>
              {pocList && pocList?.length > 0 && (
                <CompanyDetailsTable
                  companyData={[]}
                  pocList={pocList}
                  setPocList={setPocList}
                  fromPublic={true}
                />
              )}
            </div>
            <div style={{ marginBottom: "0px" }}>
              <AddCompanyMember
                pocList={pocList}
                setPocList={setPocList}
                fromPublic={true}
              />
            </div>
            {/* </>
              )}
              {companyDetails?.poc_list?.length <= 2 && (
                <Button
                  style={{
                    display: "flex",
                    marginTop: "-10px",
                    marginBottom: "7px",
                  }}
                  startIcon={<AddCircleOutlineIcon />}
                  color="primary"
                  onClick={() => {
                    handleAddPoc();
                  }}
                >
                  {companyDetails?.poc_list?.length === 2
                    ? "Add SPOC"
                    : "Add Another SPOC"}
                </Button>
              )}
            </Grid> */}
          </>
        );
      case "about":
        return (
          <>
            {companyNotExist && (
              <>
                <Typography
                  variant="h6"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  About Company*
                </Typography>
                {/* <MyEditor /> */}
                <Editor state={editor} setState={setEditor} />
              </>
            )}
          </>
        );
    }
  }

  return (
    <>
      <>
        {data.map((item2, index2) => (
          <>{getFieldContent(item2?.field_type, item2, index2)}</>
        ))}
      </>
    </>
  );
}
