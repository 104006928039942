import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F6F6F6",
    color: "#000000",
  },
  body: {
    fontSize: 14,
    border: "none",
    borderRight: "1px solid #6C757D",
    // "&:last-of-type": {
    //   borderRight: "none",
    // },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function LastYearTable({chartDataForGraph}) {
  const classes = useStyles();
  const courses = chartDataForGraph?.name; 
  const lastYears = chartDataForGraph?.lastYears;
 
  const first = lastYears && chartDataForGraph[lastYears[0]];
  const second = lastYears &&  chartDataForGraph[lastYears[1]];
  const third = lastYears && chartDataForGraph[lastYears[2]];

  let rows = [];
  if(first?.length && second?.length && third?.length){
    rows = courses?.map((element, index) => ({
    id: index + 1,
    course : element,
    first: first[index],
    second: second[index],
    third: third[index],
  }));
}
  return (
    <TableContainer
      style={{ border: "1px solid #6C757D", borderRadius: "8px" }}
    >
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ borderRight: "1px solid #6C757D", padding: "0px" }}
              align="center"
              rowSpan={2}
            >
              S.No
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ borderRight: "1px solid #6C757D", padding: "0px" }}
              rowSpan={2}
            >
              {chartDataForGraph?.xAxis}
            </StyledTableCell>

            <StyledTableCell
              align="center"
              colSpan={3}
              style={{ padding: "0px" }}
            >
              {chartDataForGraph?.yAxis}
            </StyledTableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: "#D8D9DB" }}>
            <StyledTableCell
              style={{
                backgroundColor: "#0B57D0",
                padding: "0px",
                borderRight: "1px solid #6C757D",
              }}
              align="center"
            >
              2021
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: "#E0A800",
                padding: "0px",
                borderRight: "1px solid #6C757D",
              }}
              align="center"
            >
              2022
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: "#029E73", padding: "0px" }}
              align="center"
            >
              2023
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length && rows?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" align="center">
                {row.id}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="center">
                {row.course}
              </StyledTableCell>

              <StyledTableCell
                align="center"
                style={{
                  width: "200px",
                  borderRight: "none",
                  borderRight: "1px solid #6C757D",
                }}
              >
                {row.first}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  borderRight: "unset",
                  width: "200px",
                  borderRight: "1px solid #6C757D",
                }}
              >
                {row.second}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ borderRight: "unset", width: "200px" }}
              >
                {row.third}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
