import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CompanyProfilePatch } from "../../../api/Company/action";
import { emailRegex } from "../../../utility/regex";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "30px",
    width: "90%",
    paddingRight: "30px",
    display: "flex",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  root21: {
    "& .MuiFormLabel-root": {
      color: "red", // or black
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root21}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function UpdateCompanyMember({ item, index, pocList, setPocList }) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [validate, setValidate] = useState(pocList[index].is_validated ? "true" : "false");
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [tempPocList, setTempPocList] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const params = useParams();
  const [pocErrors, setPocErrors] = useState({
    contact_person_name: null,
    email: null,
    phone: null,
    designation: null,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (pocList?.length > 0) {
      setTempPocList(pocList);
    }
  }, [pocList]);

  const validateData = () => {
    var is_valid = true;
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    var designation_error = null;
    if (!tempPocList[index].contact_person_name) {
      name_error = "Please Enter contact person name";
      is_valid = false;
    }
    if (!tempPocList[index].email) {
      email_error = "Please Enter Email";
      is_valid = false;
    }
    if (tempPocList[index].email && !emailRegex.test(tempPocList[index].email)) {
      email_error = "Please Enter valid Email";
      is_valid = false;
    }
    if (!tempPocList[index].phone) {
      phone_error = "Please Enter Phone No.";
      is_valid = false;
    }
    if (tempPocList[index].phone && tempPocList[index].phone.length !== 10) {
      phone_error = "Please Enter valid Phone No.";
      is_valid = false;
    }
    if (!tempPocList[index].designation) {
      designation_error = "Please Enter Designation";
      is_valid = false;
    }

    setPocErrors({
      ...pocErrors,
      contact_person_name: name_error,
      email: email_error,
      phone: phone_error,
      designation: designation_error,
    });
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      var data = {
        poc_list: tempPocList,
        reason: "POC details updated"
      };
      dispatch(CompanyProfilePatch(data, setLoadingSubmit, alert, history, location, setPocList, params?.company_id, handleClose));
    }
  };

  return (
    <div>
      <Button color="primary" size="small" onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <Typography variant="h6">Update Details</Typography>
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            defaultValue=""
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Contact Person Name"
            value={item.contact_person_name}
            onChange={(e) => {
              var arr = [...tempPocList];
              arr[index].contact_person_name = e.target.value;
              setTempPocList(arr);
            }}
            error={pocErrors?.contact_person_name ? true : false}
            helperText={<span>{pocErrors?.contact_person_name}</span>}
          />
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Phone"
            value={item.phone}
            onChange={(e) => {
              var arr = [...tempPocList];
              arr[index].phone = e.target.value;
              setTempPocList(arr);
            }}
            error={pocErrors?.phone ? true : false}
            helperText={<span>{pocErrors?.phone}</span>}
          />
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            defaultValue="9839302906"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Email"
            value={item.email}
            onChange={(e) => {
              var arr = [...tempPocList];
              arr[index].email = e.target.value;
              setTempPocList(arr);
            }}
            error={pocErrors?.email ? true : false}
            helperText={<span>{pocErrors?.email}</span>}
          />
            {/* personal Email  */}
           <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            defaultValue="9839302906"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Personal Email (optional)"
            value={item?.personal_email ?? ""}
            onChange={(e) => {
              var arr = [...tempPocList];
              arr[index].personal_email = e.target.value;
              setTempPocList(arr);
            }}
            // error={pocErrors?.email ? true : false}
            // helperText={<span>{pocErrors?.email}</span>}
          />
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            defaultValue="9839302906"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Designation"
            value={item.designation}
            onChange={(e) => {
              var arr = [...tempPocList];
              arr[index].designation = e.target.value;
              setTempPocList(arr);
            }}
            error={pocErrors?.designation ? true : false}
            helperText={<span>{pocErrors?.designation}</span>}
          />
          <br />
          <Typography variant="body2" style={{ marginRight: "10px", marginTop: 20 }}>
            Verification Status
          </Typography>
          <FormControl component="fieldset" row>
            {/* <FormLabel component="legend">Gender</FormLabel> */}

            <RadioGroup
              name="customized-radios"
              className={classes.customize}
              value={validate}
              onChange={(e) => {
                var arr = [...tempPocList];
                arr[index].is_validated = e.target.value === "true" ? true : false;
                setTempPocList(arr);
                setValidate(e.target.value);
              }}
              row
            >
              <FormControlLabel value={"true"} name="Verified" control={<StyledRadio />} label={<Typography variant="body2">Verified</Typography>} />
              <FormControlLabel value={"false"} name="Not Verified" control={<StyledRadio />} label={<Typography variant="body2">Not Verified</Typography>} />
            </RadioGroup>
          </FormControl>
          <Grid container spacing={6} style={{ marginTop: "0px" }}>
            <Grid item sm={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{
                  height: "48px",
                  borderRadius: "8px",
                  boxShadow: "none",
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item sm={6} style={{ display: "grid" }}>
              {" "}
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "48px",
                  borderRadius: "8px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  if (!loadingSubmit) handleSubmit();
                }}
              >
                {loadingSubmit ? <CustomButtonCircularProgress /> : "Update"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
