import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const DEPARTMENT_DATA = "DEPARTMENT_DATA";
export const MEDIA_FILES = "MEDIA_FILES";
export const CHAT_ADD = "CHAT_ADD";

export const DepartmentData = (alert, history, location, collegeId) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/chat/department/?college_id=${collegeId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: DEPARTMENT_DATA,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const MediaFiles = (alert, history, location, file, setLoader) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/chat/file/upload/`, file, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(" Media not uploaded ");
        }
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: MEDIA_FILES,
        payload: res.data.data,
      });
      alert.success(" Media uploaded ");
    } else {
      alert.error("Media not uploaded");
    }

    setLoader(false);
  };
};

export const chatstatus = (alert, history, location, data) => {
  return async (dispatch) => {
    const res = await axios
      .post(`${BackendBaseApi.PRAVESH}api/saas/chat/app_notification/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(" Media not uploaded ");
        }
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: CHAT_ADD,
        payload: res.data.data,
      });
    }
  };
};
