import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Tooltip,
  Grid,
  Paper,
  InputBase,
  IconButton,
  Button,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 4%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    //   marginBottom: "10px",
    padding: "30px",
  },
  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    height: "34px",
    display: "flex",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function EJDJob({ item, setCurrentItem }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <BusinessIcon color="secondary" style={{ marginRight: "10px" }} />{" "}
            {/* <Typography variant="h6" noWrap>
              e-JNF Title:- {item?.ejd_title}
            </Typography> */}
            <Tooltip title={item?.ejd_title} placement="right">
              <Typography variant="h6" noWrap>
                e-JNF Title:- {item?.ejd_title}
              </Typography>
            </Tooltip>
          </div>
          <Typography
            variant="body2"
            color="secondary"
            style={{
              fontWeight: "normal",
            }}
          >
            Created Date : {moment(item?.created).format("DD MMMM' YYYY")}
          </Typography>
          {/* <Typography
            variant="body2"
            color="secondary"
            style={{
              marginTop: "10px",
              marginBottom: "20px",
              fontWeight: "bold",
            }}
          >
            : {item?.job_title}
          </Typography> */}
          <br />
          <Grid container spacing={3}>
            <Grid item xs={6} className={classes.btngrid}>
              {/* <Link to={`/job/${item.job_title}-${item?.job_id}`} target="_blank" > */}
              <Button
                color="primary"
                size="small"
                variant="contained"
                className={classes.del}
                onClick={() => {
                  window.open(`${item?.ejd_link}`);
                }}
              >
                View
              </Button>
              {/* </Link> */}
            </Grid>
            <Grid item xs={6} className={classes.btngrid}>
              {" "}
              <Button
                size="small"
                color="primary"
                variant="contained"
                className={classes.del}
                onClick={() => {
                  //   history.push(`/manage-ejd/clone-${item.id}`);
                  setCurrentItem(item);
                }}
              >
                Clone
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}
