import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import OffCampusStudentDialogForm from "../DialogItem/OffCampusStudentDialogForm";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function AddNewOffCampusStudent({
  refreshData,
  setRefreshData,
  studentListOffCampus,
  selectedRowsData,
  dataLoading,
  fetchStudentList,
  collegeId,
  fromGroup,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const alert = useAlert();
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        className={classes.button}
        onClick={() => {
          if (!licenceData?.display) {
            fetchStudentList();
            handleClickOpen();
          } else
            alert.error(
              "Student license reaches its limit so you cannot add Students. Kindly connect with your Key Account Manager"
            );
        }}
      >
        Add New
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <OffCampusStudentDialogForm
            handleClose={handleClose}
            collegeId={collegeId}
            fromGroup={fromGroup}
            studentListOffCampus={studentListOffCampus}
            dataLoading={dataLoading}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
            selectedRowsData={selectedRowsData}
          />
        </div>
      </Dialog>
    </div>
  );
}
