import {
  Box,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import FormStepper from "../../Form/SignupForm/FormStepper";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: 4,
    top: 4,
    color: "grey",
    fontSize: 13,
  },
});

const useStyles = makeStyles((theme) => ({
  newDialog: {
    // [theme.breakpoints.down("sm")]: {
    //   height: "100%"
    // }

    // width: "500px",
    [theme.breakpoints.only("xs")]: {
      borderRadius: "0px",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      padding: "0px",
      alignSelf: "flex-end",
      width: "100%",
      height: "auto",
    },
  },

  paper: {
    boxShadow: "0px 0px 8px 4px #0000001A",
    borderRadius: "12px",
    padding: "17px",
    height: "500px",
  },
  root1: {
    borderRadius: "10px",
    border: ".5px solid #707070",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
    },
  },
  paperBlue: {
    padding: 8,
    textAlign: "center",
    color: theme.palette.common.black,
    borderRadius: "15px",
    backgroundColor: "#007bff",
    border: "solid 1px #007bff",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
  },

  img: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  h1: {
    fontWeight: "700",
    fontSize: "34px",
    // paddingLeft: "15px",
    // marginTop: "20px",
    [theme.breakpoints.only("sm")]: {
      fontSize: "24px",
      marginTop: "-16px",
      // paddingLeft: "15px"
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "20px",
      marginTop: "-16px",
      // paddingLeft: "15px"
    },
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h6"
        style={{ marginLeft: "27px", marginTop: "13px" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ fontSize: 15 }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ApplyDialog({
  open,
  setOpen,
  handleClickOpen,
  alert,
  job_id,
  loginSuccessful,
  setLoginSucessful,
  setUserDetails,
  college_id,
  jobDetails,
  type,
}) {
  const classes = useStyles();
  const [activeForm, setActiveForm] = React.useState(1);
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [loginDetails, setLoginDetails] = useState({});
  const [forgotPassword, setForgotPassword] = useState(false);
  const [enrollID, setEnrollId] = useState("");
  const [passCode, setPassCode] = useState("");
  const [loadingLink, setLoadingLink] = useState(false);
  // const [forgotLink,setForgotLink] = useState(false);
  const [mail, setMail] = useState("");

  const handlechangeEnrollment = (event) => {
    setEnrollId(event.target.value);
  };
  const handlechangePassCode = (event) => {
    setPassCode(event.target.value);
  };
  const handlechangeEmail = (event) => {
    setMail(event.target.value);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogIn = async (loginDetails) => {
    const response = await axios.post(
      BackendBaseApi.PRAVESH + "candidate-saas/passcode/student-login/",
      loginDetails,
      {
        withCredentials: true,
      }
    );
    return response;
  };

  const handleClose = () => {
    setOpen(false);
    setForgotPassword(false);
  };

  const onSubmit = () => {
    setLoginDetails({
      enrollment_email: enrollID,
      passcode: passCode,
      password: values?.password,
      job_id: job_id,
      college_id: college_id,
    });

    if (enrollID && passCode && values?.password) {
      setLoading(true);
      handleLogIn({
        enrollment_email: enrollID,
        passcode: passCode,
        password: values?.password,
        job_id: job_id,
        college_id: college_id,
      })
        .then((response) => {
          setLoading(false);
          setLoginSucessful(response?.data?.success);
          setValues({ ...values, password: "", showPassword: false });
          if (response?.data?.success) {
            handleClose();
            alert.success("LogIn Successful");
            window.localStorage.setItem("loginApplyJob", true);
            window.localStorage.setItem(
              "userDetails_first_name",
              response?.data?.data?.first_name
            );
            window.localStorage.setItem(
              "userDetails_last_name",
              response?.data?.data?.last_name
            );
            setUserDetails({
              first_name: response?.data?.data?.first_name,
              last_name: response?.data?.data?.last_name,
            });
          } else alert.error(response?.data?.error);
        })
        .catch((error) => {
          setLoading(false);
          // if(!error.success) console.log(error,"error")
          // alert.error(error.error);
          setValues({ ...values, password: "", showPassword: false });
          alert.error(error?.response?.data?.error);
        });
    } else alert.error("Required Fields Can't be Empty ");
  };

  const handleSendLinkApi = async (payload) => {
    const response = await axios.post(
      BackendBaseApi.PRAVESH + "api/email/generate",
      payload
    );
    return response;
  };

  const handleSendLink = () => {
    if (mail) {
      const payload = { email: mail };
      setLoadingLink(true);
      handleSendLinkApi(payload)
        .then((response) => {
          setLoadingLink(false);
          // setForgotLink(response?.success)
          setForgotPassword(false);
          if (response?.data?.success)
            alert.success(response?.data?.data?.message);
          else alert.error(response?.data?.error);
          handleClose();
        })
        .catch((error) => {
          setLoadingLink(false);
          alert.error("Please Enter Valid Email");
          throw error;
        });
    } else alert.error("Required Fields Can't be Empty ");
  };

  const handleForgot = () => {
    setForgotPassword(true);
  };

  function getStepForm() {
    switch (activeForm) {
      case 1:
        return (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {forgotPassword ? (
                "Reset Password"
              ) : (
                <>
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "center",
                      margin: "2rem",
                      fontSize: "2rem",
                    }}
                  >
                    {" "}
                    Login{" "}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontSize: "1.5rem",
                      color: "#8F8989",
                    }}
                  >
                    {" "}
                    Login to your account - For applying
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        fontSize: "1.5rem",
                        color: "#8F8989",
                      }}
                    >
                      {" "}
                      many jobs.{" "}
                    </Typography>
                  </Typography>
                </>
              )}
            </DialogTitle>
            <DialogContent
              style={{
                padding: "20px",
              }}
            >
              {forgotPassword ? (
                <Grid
                  container
                  spacing={2}
                  style={{ width: "100%", margin: 0 }}
                >
                  <Grid item xs={12} style={{ display: "grid" }}>
                    <TextField
                      label="Email"
                      type="email"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      onChange={handlechangeEmail}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "grid", alignItems: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSendLink}
                      style={{
                        height: "45px",
                        boxShadow: "none",
                        marginTop: "10px",
                      }}
                    >
                      {loadingLink ? (
                        <CustomButtonCircularProgress />
                      ) : (
                        "Send Link"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <div>
                  <Grid
                    container
                    spacing={2}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Grid item xs={12} style={{ display: "grid" }}>
                      <TextField
                        label="Enrollment Id/Email*"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        onChange={handlechangeEnrollment}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: "grid" }}>
                      <TextField
                        label="College Passcode"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        required
                        onChange={handlechangePassCode}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: "grid" }}>
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange("password")}
                          required={true}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            margin: "0.2rem",
                          }}
                        >
                          <Button color="primary" onClick={handleForgot}>
                            Forgot Password?
                          </Button>
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ display: "grid" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        style={{
                          height: "45px",
                          boxShadow: "none",
                          marginTop: "10px",
                        }}
                      >
                        {loading ? <CustomButtonCircularProgress /> : "Login"}
                      </Button>
                      {jobDetails?.is_student_apply_directly ? (
                        <Typography
                          style={{
                            color: "#333333",
                            fontSize: "14px",
                            textAlign: "center",
                            marginTop: 5,
                          }}
                        >
                          Don’t have an account?{" "}
                          <span
                            style={{
                              cursor: "pointer",
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                            }}
                            onClick={() => setActiveForm(2)}
                          >
                            Sign up
                          </span>
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}

              {/* {forgotLink && <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
              fjkfmglmgo
          </Grid>} */}
            </DialogContent>
          </>
        );
      case 2:
        return (
          <FormStepper
            activeForm={activeForm}
            job_id={job_id}
            setActiveForm={setActiveForm}
            handleClose={handleClose}
            setLoginSucessful={setLoginSucessful}
          />
        );

      default:
        return <FormStepper />;
    }
  }

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [maxWidth, setMaxWidth] = useState("sm");

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        // onClose={handleClose}
        PaperProps={{ classes: { root: classes.root1 } }}
      >
        {getStepForm()}
      </Dialog>
    </>
  );
}
