import React, { useEffect,useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import { praveshAxiosGetReq } from "../../../api/BaseApi/apiBase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  btn: {
    display: "flex",
  },
  newBtnStyle: {
    width: "140px",
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));

export default function Resume({
  resume,
  postResume,
  resumeDataLoading,
  resumeName,
  setUploadedResume,
  uploadedResume,data,
}) {
  const classes = useStyles();
  const [loadingResumeDownload,setLoadingResumeDownload] = useState(false)

  const downloadResume= async(url,resumeName) => {

    const requestconfig = {
      
      withCredentials: true,
      responseType: "arraybuffer",
    };
    setLoadingResumeDownload(true);

    try{
      
      const res = await praveshAxiosGetReq(url,requestconfig);
      const blob = new Blob([res.data], { type: "application/pdf" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = resumeName;
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);     

    }catch(error){

      console.log(error);

    }finally{

          setLoadingResumeDownload(false);

    }  

  }

  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Resume</Typography>
          </Grid>
          {/* {resume && ( */}
          { (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  Uploaded Resume: {resumeName ? resumeName : ""}
                </Typography>
                <Typography variant="body">
                  
                    <Button color="primary" 
                     onClick={() => {
                       const url = `api/college/college-saas/update-download-student-resume/?student_id=${data?.id}`
                      let resumeName = `${data?.first_name}:${data?.id}`
                      downloadResume(url,resumeName);               
                     }}
                    style={{ borderRadius: "8px" }}>
                      
                      {loadingResumeDownload ? <CustomButtonCircularProgress isWhite={false}/> : "Click here to see Resume!"}  
                      {" "}
                    </Button>
                  
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                startIcon={<AddCircleOutlineIcon color="primary" />}
                style={{
                  display: "flex",
                  borderRadius: "8px",
                }}
                color="primary"
                variant="text"
                component="label"
              >
                Add Resume
                <input
                  type="file"
                  hidden
                  onChange={(e) => setUploadedResume(e.target.files[0])}
                />
              </Button>
              {uploadedResume ? uploadedResume?.name : ""}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={uploadedResume ? false : true}
                className={classes.newBtnStyle}
                onClick={() => {
                  postResume(uploadedResume);
                }}
              >
                {resumeDataLoading ? (
                  <CustomButtonCircularProgress />
                ) : (
                  "Upload"
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
