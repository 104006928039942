import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { IconSelector } from "../../../utility/iconSelector";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector } from "react-redux";
import { mainDetailsFn } from "../../../api/Domain/selector";
import { entityInfoSelectorFn } from "../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: "16px",
    marginRight: "16px",
    "white-space": "nowrap",
  },
  sidebarlink: {
    display: "flex",
    color: theme.palette.secondary.main,
    padding: "8px 16px 8px 16px",
    textDecoration: "none",
    listStyle: "none",
    height: "45px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: pSBC(0.6, theme.palette.primary.main),
      textDecoration: "none",
      color: "#fff",
    },
  },
  blueSideBar: {
    backgroundColor: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
    display: "flex",
    padding: "8px 16px 8px 16px",
    listStyle: "none",
    height: "45px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "8px",
  },
  subNavSelected: {
    backgroundColor: pSBC(0.7, theme.palette.primary.main),
    textDecoration: "none",
    color: "#fff",
    display: "flex",
    padding: "8px 16px 8px 16px",
    listStyle: "none",
    height: "45px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "8px",
  },
  dropdownlink: {
    display: "flex",
    color: "#060b26",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    color: theme.palette.secondary.main,
    alignItems: "center",
    padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: pSBC(0.6, theme.palette.primary.main),
      textDecoration: "none",
      color: "#fff",
    },
  },
  blueLink: {
    display: "flex",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    alignItems: "center",
    padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
  },
}));

const SubMenu = ({ items, key }) => {
  const [subnav, setSubnav] = useState(false);

  const allData = useSelector(mainDetailsFn);
  const entityInfo = useSelector(entityInfoSelectorFn);
  const COLLEGE_PREMIUM = allData?.college_details?.is_premium;

  const classes = useStyles();
  const location = useLocation();
  const [item, setItem] = useState({});

  const showSubnav = () => {
    setSubnav(!subnav);
  };

  // const hideSubnav = () => {
  //   setSubnav(null);
  // };

  // useEffect(() => {
  //   if (
  //     items.subNav &&
  //     items.subNav.length &&
  //     items.subNav.some((i) => i.path === location.pathname)
  //   )
  //     setSubnav(true);
  //   else setSubnav(false);
  // }, [items]);

  useEffect(() => {
    if (location.pathname === items.path)
      setItem({
        ...items,
        selected: true,
      });
    else
      setItem({
        ...items,

        selected: false,
      });

    if (items.subNav && items.subNav.length) {
      items.subNav.forEach((i) => {
        if (i.path === location.pathname) i["selected"] = true;
        else i["selected"] = false;
      });
    }
  }, [items, location.pathname]);

  const getIcon = (item) => {
    if (COLLEGE_PREMIUM) {
      return <>{IconSelector(item.type)}</>;
    } else {
      if (item.is_premium) return <>{IconSelector(item.type)}</>;
      else
        return (
          <>
            <LockIcon />
          </>
        );
    }
  };

  const getPath = (item) => {
    if (COLLEGE_PREMIUM) return item?.path;
    else {
      if (item.is_premium) return item?.path;
      else return location.pathname;
    }
  };

  const getSubNav = (item) => {
    if (COLLEGE_PREMIUM) return true;
    else {
      return item.is_premium;
    }
  };

  return (
    <div
      onMouseEnter={() => {
        if (item.subNav) showSubnav();
      }}
      onMouseLeave={() => {
        if (item.subNav) showSubnav();
      }}
    >
      <Link
        className={
          item.selected
            ? classes.blueSideBar
            : item.subNav &&
              item.subNav.length &&
              item.subNav.some((i) => i.path === location.pathname)
            ? classes.subNavSelected
            : classes.sidebarlink
        }
        to={getPath(item)}
        // onClick={() => {
        //   if (item.is_premium) showSubnav();
        // }}
      >
        <div style={{ display: "contents" }}>
          {/* {IconSelector(item.type)} */}
          {getIcon(item)}
          <Typography variant="body2" color="inherit" className={classes.text}>
            {item?.title?.replace("College", entityInfo?.college)}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          {item.subNav && subnav
            ? IconSelector("dropdown_open")
            : item.subNav
            ? IconSelector("dropdown_closed")
            : null}
        </div>
      </Link>

      {getSubNav(item) &&
        subnav &&
        item.subNav.map((item, index) => {
          return (
            <Link
              className={
                item.selected ? classes.blueLink : classes.dropdownlink
              }
              to={item.path}
              key={index}
            >
              {item.icon}
              <Typography variant="body2" color="inherit">
              {item?.title?.replace("College", entityInfo?.college)}
              </Typography>
            </Link>
          );
        })}
    </div>
  );
};

export default SubMenu;
