import {
  OVERVIEW_DATA,
  CAMPUS_PLACEMENT_DATA,
  COMPANY_APPLICANT,
  COMPANY_APPLICANT2,
  COMPANY_APPLICANT3,
  COMPANY_APPLICANT4,
  COMPANY_APPLICANT5,
  COMPANY_APPLICANT6,
  COMPANY_APPLICANT7,
  COMPANY_APPLICANT8,
} from "./action";

export const report = (state = {}, action) => {
  switch (action.type) {
    case OVERVIEW_DATA:
      return { ...state, overviewdata: action.payload };
    case CAMPUS_PLACEMENT_DATA:
      return { ...state, placementdata: action.payload };
    case COMPANY_APPLICANT:
      return { ...state, industry1: action.payload };
    case COMPANY_APPLICANT2:
      return { ...state, industry2: action.payload };
    case COMPANY_APPLICANT3:
      return { ...state, industry3: action.payload };
    case COMPANY_APPLICANT4:
      return { ...state, industry4: action.payload };
    case COMPANY_APPLICANT5:
      return { ...state, industry5: action.payload };
    case COMPANY_APPLICANT6:
      return { ...state, industry6: action.payload };
    case COMPANY_APPLICANT7:
      return { ...state, industry7: action.payload };
    case COMPANY_APPLICANT8:
      return { ...state, industry8: action.payload };

    default:
      return state;
  }
};
