import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Box, Button, Paper, Hidden } from "@mui/material";
import playstore from "../../../../niyantran/src/assets/img/Group.svg";
import appstore from "../../../../niyantran/src/assets/img/App Store.svg";
import code from "../../../../niyantran/src/assets/img/QR_Code.svg";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    border: "1px solid #C5C5C5",
    padding: theme.spacing(1),
    marginBottom: "10px",
    backgroundColor: "#fff",
  },
  text: {},
  grid_1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.only("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  grid_2: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
  },
  typography: {
    color: "black",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  store: {
    marginTop: "1rem",
  },
  image: {
    cursor: "pointer",
  },
}));

const MobileAppDownloadCard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
          <Grid item md={6} className={classes.grid_1}>
            <img src={code} width="100%" height="100%" />
          </Grid>

          <Grid item xs={12} md={6} className={classes.grid_2}>
            <Typography variant="h5" className={classes.typography}>
              {" "}
              Download GetWork OneApp & get real time job updates!{" "}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={10} className={classes.store}>
                <img
                  src={playstore}
                  className={classes.image}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.getwork_app"
                    );
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6} md={10} className={classes.store}>
                <img
                  src={appstore}
                  className={classes.image}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/in/app/getwork/id1579830388"
                    );
                  }}
                  width="100%"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MobileAppDownloadCard;
