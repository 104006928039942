import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import {useSelector} from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CollegeImage from "../../assets/img/company.jpg";
import CheckRegister from "../../components/Input/CheckRegister";
import LeftSideComponent from "../../components/Public/LeftSideComponent";
import RightSideComponent from "../../components/Public/RightSideComponent";
import {userLoggedInSelectorFn} from "../../api/Domain/selector";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // marginTop: "50px",
  },
  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

function PasswordResetLink() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = useSelector(userLoggedInSelectorFn);
  useEffect(() => {
    if (isLoggedIn){
      history.push("/overview");
      return;
    }
    if (location?.state !== 1022) {
      history.push("/");
    }
  }, [location]);
  return (
    <>
      {/* <Navbar /> */}
      <Grid container component="main" className={classes.root}>
      <LeftSideComponent />

      <RightSideComponent ChildComponent={CheckRegister} />
      </Grid>
    </>
  );
}

export default PasswordResetLink;
