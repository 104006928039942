import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  DesignationTeamGetAPI,
  PublicUserRoleAPI,
  TeamsPostAPI,
  TeamsPostPublicAPI,
} from "../../../api/TeamManage/action";
import { emailRegex } from "../../../utility/regex";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((_) => ({
  formControl: {
    marginTop: "12px",
    marginBottom: "5px",
    width: "100%",
  },

  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingTop: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  btn2: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px",
    marginRight: "10px",
    height: "48px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  submit: {
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    //justifyContent: "flex-start",
  },
}));

export default function InviteMemberForm({ collegeId, step, setStep }) {
  const classes = useStyles();
  const [degree, setDegree] = React.useState("");
  
  const handleChange = (event) => {
    setDegree(event.target.value);
  };

  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [teamDetails, setTeamDetails] = useState({
    name: null,
    email: null,
    phone: null,
    designation: null,
    batch: null,
    degree: null,
    course: null,
    user_role: null,
  });

  const [teamDetailsError, setTeamDetailsError] = useState({
    name: null,
    email: null,
    phone: null,
    designation: null,
    batch: null,
    degree: null,
    course: null,

    user_role: null,
  });

  const dispatch = useDispatch();
  const validateData = () => {
    var is_valid = true;
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    var batch_error = null;
    var user_role_error = null;
    var designation_error = null;
    if (!teamDetails.user_role) {
      user_role_error = "Please Select one User Role";
      is_valid = false;
    }
    if (!teamDetails.name) {
      name_error = "Name field cannot be left blank";
      is_valid = false;
    }

    if (!teamDetails.email) {
      email_error = "Email field cannot be left blank";
      is_valid = false;
    }
    if (!emailRegex.test(teamDetails.email)) {
      email_error = "Please enter a valid email";
      is_valid = false;
    }
    if (!teamDetails.phone) {
      phone_error = "Phone Number field cannot be left blank";
      is_valid = false;
    }
    if (teamDetails.phone && teamDetails?.phone?.length !== 10) {
      phone_error = "Enter a valid 10 digit phone number";
      is_valid = false;
    }

    // if (!teamDetails?.batch) {
    //   batch_error = "Please enter student's Batch";
    //   is_valid = false;
    // }
    if (!teamDetails?.designation) {
      designation_error = "Please enter student's Designation";
      is_valid = false;
    }
    setTeamDetailsError({
      ...teamDetailsError,
      name: name_error,
      email: email_error,
      phone: phone_error,
      user_role: user_role_error,
      batch: batch_error,
      designation: designation_error,
    });
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      var data = {};

      data = {
        ...teamDetails,
        college_id: [collegeId],
      };

      data.designation = teamDetails?.designation;
      data.user_role_id = teamDetails?.user_role?.id;
      data.invitation_status = true;
      dispatch(
        TeamsPostPublicAPI(
          data,
          setLoading,
          alert,
          history,
          location,
          collegeId,
          setStep
        )
      );
    }
  };

  useEffect(() => {
    dispatch(PublicUserRoleAPI(alert, history, location, window.location.href));
  }, []);
  const userRoleList = useSelector((state) => state?.TeamData?.userRole);

  return (
    <>
      <div>
        <Autocomplete
          id="country-select-demo"
          options={userRoleList}
          getOptionLabel={(option) => String(option.name)}
          value={teamDetails?.user_role}
          className={classes.formControl}
          onChange={(e, newValue) => {
            setTeamDetails({ ...teamDetails, user_role: newValue });
            setTeamDetailsError({ ...teamDetailsError, user_role: null });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="User Roles*"
              inputProps={{
                ...params.inputProps,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={teamDetailsError?.user_role ? true : false}
              helperText={<span>{teamDetailsError?.user_role}</span>}
            />
          )}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          inputProps={{ style: { padding: "16px" } }}
          label="Designation *"
          value={teamDetails?.designation}
          onChange={(e) => {
            setTeamDetails({ ...teamDetails, designation: e.target.value });
            setTeamDetailsError({ ...teamDetailsError, designation: null });
          }}
          error={teamDetailsError?.designation ? true : false}
          helperText={<span>{teamDetailsError?.designation}</span>}
        />
        <TextField
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          inputProps={{ style: { padding: "16px" } }}
          label="Name"
          name="name"
          value={teamDetails.name}
          onChange={(e) => {
            setTeamDetails({ ...teamDetails, name: e.target.value });
            setTeamDetailsError({ ...teamDetailsError, name: null });
          }}
          error={teamDetailsError.name ? true : false}
          helperText={<span>{teamDetailsError.name}</span>}
        />

        <TextField
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          fullWidth
          required
          inputProps={{ style: { padding: "16px" } }}
          label="Personal Email"
          name="Personal Email"
          autoComplete="email"
          value={teamDetails.email}
          onChange={(e) => {
            setTeamDetails({ ...teamDetails, email: e.target.value });
            setTeamDetailsError({ ...teamDetailsError, email: null });
          }}
          error={teamDetailsError.email ? true : false}
          helperText={<span>{teamDetailsError.email}</span>}
        />

        <TextField
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          required
          fullWidth
          inputProps={{ style: { padding: "16px" } }}
          label="Phone Number"
          value={teamDetails.phone}
          onChange={(e) => {
            setTeamDetails({ ...teamDetails, phone: e.target.value });
            setTeamDetailsError({ ...teamDetailsError, phone: null });
          }}
          error={teamDetailsError.phone ? true : false}
          helperText={<span>{teamDetailsError.phone}</span>}
        />
      </div>
      <div className={classes.submit}>
        {/* <Button variant="outlined" color="primary" className={classes.btn2}>
          Cancel
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          className={classes.btn2}
          onClick={() => {
            if (!loading) handleSubmit();
          }}
        >
          {loading ? <CustomButtonCircularProgress /> : "Submit"}
        </Button>
      </div>
    </>
  );
}
