import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { collegeDetailSelectorFn } from "../../../api/SignIn/selector";
import { Typography } from "@mui/material";
import { groupViewCheck } from "../../../utility/groupViewCheck";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "8px",
    display: "flex",
    marginRight: "80px",
  },
})((props) => (
  <Menu
    MenuListProps={{
      style: {
        padding: "0px",
      },
    }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function View({
  id,
  isEducationGroup = false,
  isCollegeGroup = false,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const collegeDetails = useSelector(collegeDetailSelectorFn);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const res = window.location.href.lastIndexOf("/");
  const linkToCopy =
    window.location.href.substring(0, res + 1) +
    "public-job-details/" +
    id +
    "_" +
    collegeDetails?.college_id;
  return (
    <div className={classes.root}>
      <Button
        color="primary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        View
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <Link
            to={`/${
              isEducationGroup || isCollegeGroup
                ? "track"
                : "track"
            }/?job_id=${id}`}
            target={`${
              isEducationGroup || isCollegeGroup ? "_self" : "_blank"
            }`}
            style={{ textDecoration: "none" }}
          >
            <ListItemText primary="Track Job" />
          </Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link
            target={`${
              isEducationGroup || isCollegeGroup ? "_self" : "_blank"
            }`}
            to={{
              pathname: `/${
                isEducationGroup
                  ? "educational-group/assignment-details"
                  : "assignment-details"
              }/`,
              search: `?job_id=${id}`,
            }}
            style={{ textDecoration: "none" }}
            // target="_blank"
          >
            <ListItemText primary="View Assignments" />
          </Link>
        </StyledMenuItem>

        <StyledMenuItem>
          <Link
            to={{
              pathname: `/${
                isEducationGroup || isCollegeGroup
                  ? "educational-group/interview"
                  : "interview"
              }/`,
              search: `?job_id=${id}`,
            }}
            target={`${
              isEducationGroup || isCollegeGroup ? "_self" : "_blank"
            }`}
            style={{ textDecoration: "none" }}
            // target="_blank"
          >
            <ListItemText primary="View Interviews" />
          </Link>
        </StyledMenuItem>

        {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeDetails?.college_id) && 
        <StyledMenuItem>
          <CopyToClipboard
            text={linkToCopy}
            onCopy={() => {
              alert.success("Link Copied!");
              handleClose();
            }}
          >
            <Typography variant="primary"> Copy Link</Typography>
          </CopyToClipboard>
        </StyledMenuItem>
        }
      </StyledMenu>
    </div>
  );
}
