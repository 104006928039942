import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Card } from "@material-ui/core";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    border: ".5px solid #e2e6ea",
    borderRadius: "8px",
    marginBottom: "20px",
    boxShadow: "none",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
    paddingBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textfield: {
    padding: "16px",
  },
  btn: {
    color: "#b0b6ba",
    borderRadius: "8px",
    width: "100%",
    boxShadow: "none",
    height: "48px",
    textAlign: "left",
  },
}));

export default function SearchFeed({ handleClickOpen }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div>
        <Button
          variant="outlined"
          className={classes.btn}
          onClick={handleClickOpen}
        >
          Post a new feed
        </Button>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "10px",
          }}
        >
          <Button
            onClick={() => handleClickOpen()}
            style={{ display: "flex" }}
            color="primary"
            startIcon={<CropOriginalIcon />}
          >
            Upload Image
          </Button>

          <Button
            onClick={() => handleClickOpen()}
            startIcon={<AttachFileIcon />}
            color="primary"
            style={{ display: "flex" }}
          >
            Attach File
          </Button>
        </div>
      </div>
    </Card>
  );
}
