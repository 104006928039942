import { Box, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StarsIcon from "@mui/icons-material/Stars";
import React from "react";

const useStyles = makeStyles((theme) => ({
  statusDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E9AC00",
    padding: "10px",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    // position: "absolute",
    top: "0px",
    right: "20px",
  },
}));

const PremiumJob = ({ full = true }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.statusDiv}>
        <StarsIcon style={{ fontSize: "18px", color: "#fff" }} />
        {full && (
          <Hidden only={["xs"]}>
            <Typography
              style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
            >
              Premium
            </Typography>
          </Hidden>
        )}
      </Box>
    </>
  );
};

export default PremiumJob;
