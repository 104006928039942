import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  collegeGroupListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  entityInfoSelectorFn,
} from "../../../api/SignIn/selector";
import { EDGCollegesUrlAPI } from "../../../api/University/APIs/action";
import UplacementCycle from "../../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../../components/CircularProgressBar";
import GroupListDropdown from "../../../components/Input/DropDownButton/GroupListDropdown";
import { BackendBaseApi } from "../../../constants/constants";
import { generateCsvForParams } from "../../../utility/commaSeparatedStrings";
import useCollegeGroupList from "../../../Hooks/useCollegeGroupList";
import useNavBarCollegeGroupList from "../../../Hooks/useNavBarCollegeGroupList";
function UniversityInterview() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const entityInfo = useSelector(entityInfoSelectorFn);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  // const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);

  const {
    newCollegeGroupList,
    setNewCollegeGroupList,
    selectedGroup,
    setSelectedGroup,
  } = useCollegeGroupList(collegeGroupList, isCollegeGroup);

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else
        return `&group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  // useEffect(() => {
  //   if (collegeGroupList && collegeGroupList.length)
  //     setSelectedGroup(collegeGroupList[0]);
  // }, [collegeGroupList]);

  useEffect(() => {
    if (selectedGroup) {
      var url = `${
        BackendBaseApi.SANCHALAN
      }api/university/university_count/?query=college${checkSelectedGroup()}`;
      dispatch(EDGCollegesUrlAPI(setLoading, alert, history, location, url));
    }
  }, [selectedGroup]);

  // const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  // useEffect(() => {
  //   if (collegeGroupList?.length > 0 && isCollegeGroup) {
  //     setNewCollegeGroupList(
  //       [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
  //     );
  //   } else setNewCollegeGroupList(collegeGroupList);
  // }, [collegeGroupList?.length]);
  const collegeList = useSelector((state) => state?.EDGCommon?.edgCollegeList);

  useNavBarCollegeGroupList(selectedGroup, setLoading);

  return (
    <>
      {/* <UniversityLayout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          {collegeGroupList?.length > 0 && (
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
              <GroupListDropdown
                title="Group"
                state={selectedGroup}
                setState={setSelectedGroup}
                groupList={newCollegeGroupList}
              />
            </div>
          )}
          <br />
          <Typography variant="h2">All {entityInfo?.college}s</Typography>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={5}>
              {collegeList?.length > 0 &&
                collegeList?.map((item) => (
                  <Grid item xs={4}>
                    <UplacementCycle
                      item={item}
                      countTitle={`${entityInfo?.college} Admin`}
                      count={item?.tpo_admin}
                      link={`/college-profile`}
                      linkTitle={`View ${entityInfo?.college} Profile Info`}
                      from={"AllCollege"}
                    />{" "}
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityInterview;
