import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SortIcon from "@material-ui/icons/Sort";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  btn: {
    // borderColor: "#e2e6ea",
    border: "2px solid #e2e6ea",
    color: "#6c757d",
    display: "flex",
    height: "45px",
    display: "flex",
    fontWeight: "normal",
  },
  expand: {
    transform: "rotate(0deg)",
    color: "#6c757d",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
    color: "#6c757d",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function SortMenu({ sort, setSort }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Button
        className={classes.btn}
        variant="outlined"
        onClick={handleClick}
        //onClick={handleExpandClick}
        startIcon={<SortIcon style={{ color: "#6c757d" }} />}
        style={{ backgroundColor: "#fff" }}
        endIcon={
          <ExpandMoreIcon
            style={{ marginLeft: "20px" }}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
          />
        }
      >
        Sort
      </Button>
      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem
          selected={sort.name}
          onClick={() => {
            setSort({ ...sort, name: true, date: false });
          }}
        >
          <ListItemText primary="By Name" />
        </StyledMenuItem>
        <StyledMenuItem
          selected={sort.date}
          onClick={() => {
            setSort({ ...sort, date: true, name: false });
          }}
        >
          <ListItemText primary="By Date" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
