import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: "#343a40",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boxShadow: "none",

    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

function createData(name, enrollment, status) {
  return { name, enrollment, status };
}

const rows = [
  createData("John Smith", "3121123", "Rejected"),
  createData("John Smith", "3121123", "Shortlist"),
  createData("John Smith", "3121123", "Rejected"),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ApplicantsStatus({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Name</StyledTableCell>
            <StyledTableCell align="center">Enrollment ID</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell
                component="th"
                style={{ border: ".5px solid #ced4da" }}
                scope="row"
                align="center"
              >
                {row?.name}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ border: ".5px solid #ced4da" }}
              >
                {row?.enrollment_id}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ border: ".5px solid #ced4da" }}
              >
                {row?.status}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
