import { Report } from "@material-ui/icons";
import BusinessIcon from "@material-ui/icons/Business";
import ChatIcon from "@material-ui/icons/Chat";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ForumIcon from "@material-ui/icons/Forum";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SettingsIcon from "@material-ui/icons/Settings";
import React from "react";
import calendar from "../assets/icons/calendar.png";
import graduated from "../assets/icons/graduated.png";
import hand from "../assets/icons/hand.png";
import office from "../assets/icons/office-building.png";
import s1 from "../assets/icons/suitcase.png";
import home from "../assets/svg/home.svg";
import AdminAcces from "../assets/svg/help/1.js";
import ManageAssignmentInterviews from "../assets/svg/help/10.js";
import Settingshelp from "../assets/svg/help/11.js";
import PlacementCycle from "../assets/svg/help/3.js";
import ManageTeam from "../assets/svg/help/4.js";
import ManageCompanies from "../assets/svg/help/5.js";
import ManageStudents from "../assets/svg/help/6.js";
import PostJobs from "../assets/svg/help/7.js";
import ManageJobs from "../assets/svg/help/8.js";
import ManageEjd from "../assets/svg/help/9.js";

export default function IconSelectorNew(icon_key, domainTheme) {
  switch (icon_key) {
    case "overview":
      return <DashboardIcon />;
    case "placement_cycle":
      return <PlacementCycle primaryColor={domainTheme} />;
    case "jobs":
      return <ManageJobs primaryColor={domainTheme} />;
    case "companies":
      return <ManageCompanies primaryColor={domainTheme} />;
    case "manage_team":
      return <ManageTeam primaryColor={domainTheme} />;
    case "manage_students":
      return <ManageStudents primaryColor={domainTheme} />;
    case "manage_ejd":
      return <ManageEjd primaryColor={domainTheme} />;
    case "chat_room":
      return <ForumIcon />;
    case "report":
      return <Report />;
    case "manage_forms":
      return <ManageEjd primaryColor={domainTheme} />;
    case "assignments":
      return <ManageAssignmentInterviews primaryColor={domainTheme} />;
    case "interviews":
      return <ManageAssignmentInterviews primaryColor={domainTheme} />;
    case "settings":
      return <SettingsIcon />;
    case "dropdown_closed":
      return <KeyboardArrowDownIcon />;
    case "dropdown_open":
      return <KeyboardArrowUpIcon />;
    case "Manage Jobs":
      return <ManageJobs primaryColor={domainTheme} />;
    case "Manage Students":
      return <ManageStudents primaryColor={domainTheme} />;
    case "Manage Team":
      return <ManageTeam primaryColor={domainTheme} />;
    case "Manage Forms & e-JD":
      return <ManageEjd primaryColor={domainTheme} />;
    case "Manage Assignment & Interviews":
      return <ManageAssignmentInterviews primaryColor={domainTheme} />;
    case "Reports":
      return <BusinessIcon color="primary" style={{ fontSize: "80px" }} />;
    case "Manage Admin account":
      return <AdminAcces primaryColor={domainTheme} />;
    case "Post Job & Placement Cycle & Overview":
      return <PostJobs primaryColor={domainTheme} />;
    case "Settings":
      return <Settingshelp primaryColor={domainTheme} />;
    case "Manage e-JNF":
      return <ManageEjd primaryColor={domainTheme} />;
    case "Companies":
      return <ManageCompanies primaryColor={domainTheme} />;
    case "Chat Room":
      return <ChatIcon color="primary" style={{ fontSize: "80px" }} />;
    case "Jobs":
      return <ManageJobs primaryColor={domainTheme} />;
    case "connected_company":
    case "total_colleges":
      return <img src={office} height="41px" width="45px" alt="" />;
    case "total_jobs":
      return <img src={s1} height="41px" width="45px" alt="" />;
    case "registered_student":
      return <img src={graduated} height="41px" width="45px" alt="" />;
    case "average_offered_ctc":
      return <img src={hand} height="41px" width="45px" alt="" />;
    case "registered_students":
      return <img src={graduated} height="41px" width="45px" alt="" />;
    case "home":
      return <img src={home} height="41px" width="45px" alt="" />;
    case "placement":
      return <img src={calendar} height="41px" width="45px" alt="" />;
    default:
      return <KeyboardArrowUpIcon />;
  }
}
