import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import StudentTab from "../StudentTab";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
}));

export default function JobOffer({ data }) {
  const classes = useStyles();

  return (
    <>
      <br />
      <div
        className={classes.paper}
        style={{ maxHeight: 500, minHeight: 300, overflowY: "scroll" }}
      >
        <Typography variant="h6">Job Offer</Typography>
        <br />
        <div className={classes.root}>
          <StudentTab data={data} />
        </div>
      </div>
    </>
  );
}
