import { Button, Divider } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ApartmentIcon from "@material-ui/icons/Apartment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import GroupIcon from "@material-ui/icons/Group";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import moment from "moment";
import React from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { entityInfoSelectorFn } from "../../../../api/SignIn/selector";
import collegelogo from "../../../../assets/png/default_college.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
    boxShadow: "rgb(0 0 0 / 5%) 0px 3px 24px",
    borderRadius: "8px",
  },
  paperchild: {
    padding: theme.spacing(2),
    display: "flex",
  },
  image: {
    width: 100,
    height: 100,
  },
  img: {
    margin: "auto",
    display: "block",

    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: 20,
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function AllGroups({
  item,
  countTitle,
  count,
  link,
  linkTitle,
  from,
  secondaryCount,
  secondaryCountTitle,
}) {
  const classes = useStyles();
  const history = useHistory();
  const entityInfo = useSelector(entityInfoSelectorFn);

  const Data = [
    {
      icon: <GroupIcon color="secondary" />,
      info: item.group_name,
      show: true,
    },
    {
      icon: <ApartmentIcon color="secondary" />,
      info: item.college_count + " " + entityInfo?.college,
      show: true,
    },

    {
      icon: <SupervisorAccountIcon color="secondary" />,
      info: `${item.admin_count} Admins`,
      show: true,
    },
    {
      icon: <DateRangeIcon color="secondary" />,
      info: `Last Updated on ${moment(item?.last_updated).format(
        "DD/MM/YYYY"
      )}`,
      show: true,
    },
  ];

  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.paperchild}>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase className={classes.image}>
                <img
                  className={classes.img}
                  alt="complex"
                  src={item?.logo ? item?.logo : collegelogo}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid container spacing={2}>
                {Data.map(
                  (item) =>
                    item?.show && (
                      <Grid item xs={12}>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item className={classes.grid}>
                            {item.icon}
                          </Grid>
                          <Grid item xs className={classes.grid}>
                            <Typography
                              variant="body2"
                              color="secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {item.info}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className={classes.paperchild}>
          <Button
            color="primary"
            style={{ display: "flex" }}
            //   endIcon={<ChevronRightIcon />}
            onClick={() => {
              history.push(
                `/educational-group/sub-groups/group-details/${item?.id}`
              );
            }}
          >
            View Group Info
          </Button>
        </div>
      </Paper>
    </div>
  );
}
