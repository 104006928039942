import { Button, Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import usePublicJobPost from "../../api/PublicJobPostLink/usePublicJobPost";
import JobDetailsCard from "../../components/Cards/JobDetailsCard";
import OtherJob from "../../components/Cards/OtherJob";
import CustomCircularProgress from "../../components/CircularProgressBar";
import PublicJobNavbar from "../../components/Common/Navbar/PublicJobNavbar";
import ApplyDialog from "../../components/DialogSection/Apply/ApplyDialog";
import MobileAppDownloadCard from "../../components/MobileAppDownload/MobileAppDownloadCard";
import { BackendBaseApi } from "../../constants/constants";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  fixedPosition: {
    position: "fixed",
    left: "69%",
    maxHeight: 630,
    overflowY: "scroll",
    width: "30%",
    [theme.breakpoints.only("xs")]: {
      position: "relative",
      left: "0%",
      paddingBottom: "60px !important",
      padding: 8,
    },
  },
  maingrid: {
    position: "relative",
    width: "100%",
    margin: 0,
    [theme.breakpoints.only("sm")]: {
      paddingBottom: "70px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0px !important",
      backgroundColor: "#fff",
    },
  },

  subgrid: {
    [theme.breakpoints.only("xs")]: {
      padding: "0px !important",
    },
  },

  maindiv: {
    position: "relative",
    marginTop: "30px",
    [theme.breakpoints.only("xs")]: {
      marginTop: "7px",
    },
  },
}));

export default function PublicJobDetails() {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [loadingSideData, setLoadingSideData] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);

  const id = params?.college_job_id?.split("_");
  const job_id = id[0];
  const [jobId, setJobId] = useState(job_id);
  const [userDetails, setUserDetails] = useState({});

  const college_id = id[1];

  const alert = useAlert();
  const [loginSucessful, setLoginSucessful] = useState(
    window.localStorage.getItem("loginApplyJob") ?? false
  );
  const [open, setOpen] = React.useState(false);

  const obj = { job_id: jobId };
  const [OTHER_JOB_DATA, JOB_DETAILS, applied, setApplied] = usePublicJobPost(
    jobId,
    college_id,
    setLoading,
    setLoadingSideData,
    loginSucessful
  );

  const disabled = JOB_DETAILS?.job_stage !== "REGISTRATION OPEN";
  const disbaled_Text = "REGISTRATION CLOSED";

  const handleApply = async () => {
    setLoadingApply(true);
    const response = await axios.post(
      BackendBaseApi.SANCHALAN + "public/apply-public-job/",
      obj,
      {
        withCredentials: true,
      }
    );

    setLoadingApply(false);
    return response;
  };

  const handleClickOpen = () => {
    if (!loginSucessful) setOpen(true);
    else {
      handleApply()
        .then((response) => {
          if (response?.data?.success) {
            alert.success(response?.data?.data?.message);
            setApplied(response?.data?.success);
          } else alert.error(response?.data?.data?.message);
        })
        .catch((error) => {
          alert.error("Something went Wrong");
        });
    }
  };

  const jobStatus = {
    logo: JOB_DETAILS?.logo,
    company_website: JOB_DETAILS?.company_website,
  };



  return (
    <>
      <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
        <PublicJobNavbar
          userDetails={userDetails}
          loginSucessful={loginSucessful}
          setLoginSucessful={setLoginSucessful}
          setUserDetails={setUserDetails}
          jobDetails={JOB_DETAILS}
          jobId={jobId}
          applied={applied}
          setApplied={setApplied}
          handleApplyClick={handleClickOpen}
          college_id={college_id}
          disabled={disabled}
          loadingApply={loadingApply}
        >
          <div className={classes.maindiv}>
            <Grid
              container
              // spacing={3}
              className={classes.maingrid}
            >
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomCircularProgress />
                </div>
              ) : (
                <Grid item xs={12} sm={8} className={classes.subgrid}>
                  <>
                    <JobDetailsCard
                      data={JOB_DETAILS?.college_job_data}
                      fromJobDetail={true}
                      jobStatus={jobStatus}
                      loginSucessful={loginSucessful}
                      applied={applied}
                      handleClickOpen={handleClickOpen}
                      OTHER_JOB_DATA={OTHER_JOB_DATA}
                      setJobId={setJobId}
                      disabled={disabled}
                      loadingApply={loadingApply}

                    />
                    <Hidden only={["sm", "xs"]}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        {" "}
                        <Button
                          color="primary"
                          disabled={disabled}
                          variant="contained"
                          onClick={handleClickOpen}
                          style={{
                            borderRadius: "8px",
                            boxShadow: "none",
                            paddingLeft: "50px",
                            height: "40px",
                            display: "flex",
                            marginTop: "20px",
                            paddingRight: "50px",
                          }}
                        >
                          {disabled
                            ? disbaled_Text
                            : loginSucessful
                              ? loadingApply ? <CustomButtonCircularProgress /> : applied
                                ? "APPLIED"
                                : "APPLY"
                              : "LOGIN TO APPLY"}
                        </Button>
                      </div>

                      <ApplyDialog
                        open={open}
                        setOpen={setOpen}
                        handleClickOpen={handleClickOpen}
                        alert={alert}
                        job_id={jobId}
                        loginSuccessful={loginSucessful}
                        jobDetails={JOB_DETAILS}
                        setLoginSucessful={setLoginSucessful}
                        setUserDetails={setUserDetails}
                        college_id={college_id}
                      />
                    </Hidden>
                  </>
                </Grid>
              )}
              <Grid item xs={12} sm={4} className={classes.fixedPosition}>
                {!loading && <MobileAppDownloadCard />}
                {!loading && (
                  <Hidden only={["sm", "xs"]}>
                    <OtherJob data={OTHER_JOB_DATA} setJobId={setJobId} />
                  </Hidden>
                )}
              </Grid>
            </Grid>
          </div>
        </PublicJobNavbar>
      </div>
    </>
  );
}
