import { Container, Grid, Typography } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import LanguageIcon from "@material-ui/icons/Language";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CollegeGet } from "../../api/CollegeProfile/action";
import { collegeDetailsSelectorFn } from "../../api/Domain/selector";
import CollegeImage from "../../assets/img/company.jpg";
import FormFooter from "../../components/Common/FormFooter";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginTop: "20px",
  },

  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    height: "270px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  submit: {
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  imagedata: {
    display: "flex",
    width: 150,
    backgroundColor: "#fff",
    height: 80,
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  imgdata: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",

    //  borderRadius: "50%",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  ranking: {
    borderRadius: "8px",
    padding: "20px",
    display: "flex",
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "#fff",
  },
  course: {
    borderRadius: "8px",
    border: ".5px solid #b0b6ba",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "80%",
    marginBottom: "20px",
  },
  social: {
    // margin: theme.spacing(3.5),
    // marginTop: "70px",
    display: "flex",

    alignItems: "center",
  },
  icons: {
    marginRight: "10px",
  },
  infoicon: {
    color: "#5a6268",
  },
}));

function CollegeInfo() {
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const data = useSelector((state) => state?.collegeProfile?.collegeData);
  const collegeDetail = useSelector(collegeDetailsSelectorFn);
  const [link, setLink] = useState({
    facebook: "",
    insta: "",
    linkedin: "",
  });

  useEffect(() => {
    setLink({
      ...link,
      facebook:
        data?.social_links?.length > 1
          ? data?.social_links[1]?.value || ""
          : "",
      insta:
        data?.social_links?.length > 2
          ? data?.social_links[2]?.value || ""
          : "",
      linkedin:
        data?.social_links?.length > 0
          ? data?.social_links[0]?.value || ""
          : "",
    });
  }, [data]);

  useEffect(() => {
    if (collegeDetail?.college_id) {
      dispatch(
        CollegeGet(
          // setLoading,
          alert,
          history,
          location,
          collegeDetail?.college_id
        )
      );
    }
  }, [collegeDetail?.college_id]);

  return (
    <>
      <div style={{ backgroundColor: "#fff" }}>
        <Container maxWidth="lg">
          <div style={{ paddingTop: "30px" }}>
            {/* <Typography variant="h6">Ranking</Typography> */}
            {/* <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper className={classes.ranking}>
                  <BarChartIcon color="primary" />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h6">
                      2nd Rank
                      <br /> <span style={{ color: "#6c757d" }}> In 2019 </span>
                    </Typography>

                    <Typography variant="h6">Nirfindia</Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid> */}
            {/* <Typography variant="h6"> Cutoff</Typography>
            <br />
            <CutOff /> */}
            {/* <div className={classes.ranking}>
            <BarChartIcon color="primary" />
            <div style={{ marginLeft: "20px" }}>
              <Typography variant="h6" style={{ color: "#5a6268" }}>
                No 1, 2018
              </Typography>
              <br />
              <Typography variant="h6" style={{ color: "#5a6268" }}>
                nirfindia
              </Typography>
            </div>
          </div> */}

            {/* <br />
            <Typography variant="h6">Course</Typography>
            <br /> */}
            <Grid container spacing={3}>
              {/* <Grid item xs={12} sm={6}>
                <div className={classes.course}>
                  <div style={{ display: "flex" }}>
                    <SchoolIcon
                      color="primary"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography variant="h6">Diploma ( 3 Year )</Typography>
                  </div>
                  {tiers.map((tier) => (
                    <div style={{ marginLeft: "15px", marginTop: "7px" }}>
                      <ul style={{ display: "contents" }}>
                        {tier.description.map((line) => (
                          <Typography
                            component="li"
                            variant="body2"
                            key={line}
                            style={{
                              fontSize: "14px",
                              color: "#6c757d",
                              marginBottom: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <br />
                <div className={classes.course}>
                  <div style={{ display: "flex" }}>
                    <SchoolIcon
                      color="primary"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography variant="h6">
                      Masters in Technology ( 2 Year )
                    </Typography>
                  </div>
                  {tiers.map((tier) => (
                    <div style={{ marginLeft: "15px", marginTop: "7px" }}>
                      <ul style={{ display: "contents" }}>
                        {tier.description.map((line) => (
                          <Typography
                            component="li"
                            variant="body2"
                            key={line}
                            style={{
                              color: "#6c757d",
                              fontSize: "14px",
                              marginBottom: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <div className={classes.course}>
                  <div style={{ display: "flex" }}>
                    <SchoolIcon
                      color="primary"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography variant="h6">
                      Bachelor of Engineering ( 4 Year )
                    </Typography>
                  </div>
                  {tiers.map((tier) => (
                    <div style={{ marginLeft: "15px", marginTop: "7px" }}>
                      <ul style={{ display: "contents" }}>
                        {tier.description.map((line) => (
                          <Typography
                            component="li"
                            variant="body2"
                            key={line}
                            style={{
                              fontSize: "14px",
                              color: "#6c757d",
                              marginBottom: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </Grid> */}

              <Grid item xs={12} sm={4}>
                <div>
                  <Typography variant="h3" style={{ fontWeight: 600 }}>
                    College Address
                  </Typography>
                  <br />
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    University Campus
                  </Typography>
                  <br />
                  <Typography variant="body2">
                    {data?.college_address} {data?.city} {data?.state}
                  </Typography>
                  <br />
                  {/* <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Contact No. 0987654321
                  </Typography> */}
                  <br />
                  {/* <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    University Campus 2
                  </Typography>
                  <br />
                  <Typography variant="body2">
                    NH8, Pune , Mumbai Highway, HR Layout Pune -123405
                  </Typography>
                  <br />
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Contact No. 0987654321
                  </Typography> */}
                  <br />
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingBottom: "100px",
                }}
              >
                <iframe
                  src={`https://maps.google.com/maps?q=${data?.latitude},${data?.longitude}&output=embed`} // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.954778817422!2d78.9094!3d28.89523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19c738367b87%3A0xd1a083f3edbfcaf8!2sGetWork!5e0!3m2!1sen!2sin!4v1632218850143!5m2!1sen!2sin"
                  width="700"
                  height="350"
                  style={{ borderRadius: "8px", border: "none" }}
                  //  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Grid>
            </Grid>
          </div>
        </Container>
        <FormFooter />
      </div>
    </>
  );
}

export default CollegeInfo;

const Data = [
  {
    icon: <LanguageIcon color="secondary" />,
    info: "www.microsoft.com",
  },
  {
    icon: <ApartmentIcon color="secondary" />,
    info: "Gurgaon",
  },
  {
    icon: <GolfCourseIcon color="secondary" />,
    info: "Private",
  },
  {
    icon: <PeopleAltOutlinedIcon color="secondary" />,
    info: "1000+ Employees",
  },

  {
    icon: <FlagOutlinedIcon color="secondary" />,
    info: "Founded in 1990",
  },
];

const Ranking = [
  {
    icon: <LanguageIcon color="secondary" />,
    info: "www.microsoft.com",
  },
  {
    icon: <ApartmentIcon color="secondary" />,
    info: "Gurgaon",
  },
  {
    icon: <PeopleAltOutlinedIcon color="secondary" />,
    info: "1000+ Employees",
  },

  {
    icon: <FlagOutlinedIcon color="secondary" />,
    info: "Founded in 1990",
  },
];

const tiers = [
  {
    description: [
      "Electronics and communication Engineering",
      "Civil Engineering",
      "Information Technology",
      "Mechanical Engineering",
    ],
  },
];
