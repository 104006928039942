import axios from "axios";
import { LOGGED_IN } from "./api/SignIn/action";
import { BackendBaseApi } from "./constants/constants";

export const GET_THEME_DETAILS = "GET_THEME_DETAILS";
export const UPDATE_MAIN_THEME = "UPDATE_MAIN_THEME";

export const getThemeDetails = () => {
  return async (dispatch) => {
    const res = await axios
      .get(BackendBaseApi.PRAVESH + "api/saas/domain_detail/", {
        withCredentials: true,
      })
      .catch((error) => {
        throw error;
      });
    var data = {};

    // if (res.data.success) {
    data = {
      main: res.data.primary_color,
      hover: "#0069D9",
      disable: "#58AAFF",
      contrast_text: "#fff",
      secondary: res.data.secondary_color,
      college_name: res.data.college_name,
      side_image: res.data.side_image,
      logo: res.data.logo,
    };

    dispatch({ type: GET_THEME_DETAILS, payload: data });
    dispatch({ type: LOGGED_IN, payload: true});
  };
};

export const changeState = (dispatcher, data) => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
  };
};
