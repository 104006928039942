import axios from "axios";
import { BackendBaseApi } from "../../constants/constants";

export const BILLING_DETAILS = "BILLING_DETAILS";

export const BillingDetails = (
  alert,
  history,
  location,
  collegeId,
  setLoader
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .get(
        `${BackendBaseApi.PRAVESH}api/saas/billing/?college_id=${collegeId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: BILLING_DETAILS,
        payload: res.data.data.data,
      });
    } else {
    }
    setLoader(false);
  };
};
