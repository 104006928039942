import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import pSBC from "shade-blend-color";
import { collegeDetailSelectorFn } from "../../api/SignIn/selector";
import StudentFormNavbar from "../../components/Common/StudentFormNavbar";
import TrackPasscode from "../../components/DialogSection/TrackPasscode";
import TrackHeader from "../../components/Header/TrackHeader";
import TrackJobTab from "../../components/Header/TrackJobTab";
import { BackendBaseApi, getColumnString } from "../../constants/constants";
import { sanchalanAxiosGetReq } from "../../api/BaseApi/apiBase";
import { checkForToken, sendExportLink } from "../../constants/ApiCall";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  sharedBox: {
    padding: 20,
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    width: "25%",
    borderRadius: "8px",
  },
}));

export default function PublicTrack() {

  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState({
    job_id: null,
    current_status: null,
    search: null,
    search_check: false,
    enrollment_search: null,
    enrollment_search_check: false,
    course: null,
    degree: null,
    batch: null,
    applied_on: null,
    college_id: null,
    navbar_object: { tooltip: "Applied" },
  });
  const [moveToNext, setMoveToNext] = useState({
    round_id: null,
    feedback: null,
    feedback_typed: null,
    round_name: null,
  });
  const [navbarData, setNavbarData] = useState();
  const [breadcrumbData, setBreadCrumbData] = useState();
  const [trackTableData, setTrackTableData] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstApiCall, setFirstApiCall] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const userId = collegeDetails?.user_id;
  const alert = useAlert();
  const [placementSession, setPlacementSession] = useState(null);
  const [trackPasscode, setTrackPasscode] = useState({
    track_passcode: null,
    passcode_error: null,
  });
  const [openPasscode, setOpenPasscode] = React.useState(true);
  const [validatorData, setValidatorData] = useState();
  // useEffect(() => {
  //   if (location?.search) {
  //     var search = location.search;
  //     var params = new URLSearchParams(search);
  //     var job_id = params.get("job_id");
  //     setFilters({ ...filters, job_id: job_id });
  //   }
  // }, [location]);

  // useEffect(() => {
  //   if (filters?.job_id && filters?.college_id) dispatch(PlacementAccordingToJobPublic(alert, history, location, filters?.job_id, filters?.college_id));
  // }, [filters?.college_id, filters?.job_id]);

  // const placementSessionList = useSelector((state) => state?.JobPlacement?.placementList);

  const checkCurrentTab = () => {
    if (filters?.current_status) return `&round_id=${filters?.current_status}`;
    else return ``;
  };

  const checkSearch = () => {
    if (filters?.search) return `&search=${filters?.search}`;
    else return ``;
  };

  const checkEnrollmentSearch = () => {
    if (filters?.enrollment_search)
      return `&enrollment_no=${filters?.enrollment_search}`;
    else return ``;
  };

  const checkDegree = () => {
    if (filters?.degree) return `&degree=${filters?.degree?.id}`;
    else return ``;
  };
  const checkCourse = () => {
    if (filters?.course) return `&course=${filters?.course?.id}`;
    else return ``;
  };

  const checkBatch = () => {
    if (filters?.batch) return `&batch=${filters?.batch.id}`;
    else return ``;
  };

  const checkPlacement = () => {
    if (placementSession && placementSession.id !== "All")
      return `&placement_session=${placementSession.id}`;
    else return ``;
  };
  const checkAppliedDate = () => {
    if (filters?.applied_on)
      return `&applied_on=${new Date(filters.applied_on)
        .toISOString()
        .substring(0, 10)}`;
    else return ``;
  };
  useEffect(() => {
    if (!openPasscode) trackHeader(true);
  }, [openPasscode]);

  useEffect(() => {
    if (filters?.current_status && !openPasscode) {
      trackTable();
    }
  }, [filters?.current_status, openPasscode]);

  useEffect(() => {
    if (filters?.search_check === true) {
      trackTable();
      trackHeader();
    }
  }, [filters?.search_check]);
  useEffect(() => {
    if (filters?.enrollment_search_check === true) {
      trackTable();
      trackHeader();
    }
  }, [filters?.enrollment_search_check]);

  useEffect(() => {
    if (firstApiCall) {
      trackTable();
      trackHeader();
    }
  }, [filters?.degree, filters?.course, filters?.batch, filters?.applied_on]);

  const trackHeader = (call) => {
    axios
      .get(
        `${BackendBaseApi.SANCHALAN}public/track_sharer_header/?action=${
          window.location.href
        }${checkEnrollmentSearch()}${checkSearch()}${checkDegree()}${checkCourse()}${checkBatch()}${checkAppliedDate()}${checkPlacement()}`,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          setNavbarData(res.data.data.navbar_data);
          setBreadCrumbData(res.data.data.breadcrumb_data);
          if (call)
            setFilters({
              ...filters,
              current_status: res.data.data.navbar_data["applied"].round_id,
            });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const trackTable = () => {
    setLoading(true);
    axios
      .get(
        `${BackendBaseApi.SANCHALAN}public/track_sharer_main/?action=${
          window.location.href
        }${checkCurrentTab()}${checkEnrollmentSearch()}${checkSearch()}${checkDegree()}${checkCourse()}${checkBatch()}${checkAppliedDate()}${checkPlacement()}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          res.data.data.applicant_data.forEach((item) => {
            item["id"] = item?.student_id;
          });
          setFilterList(res.data.data.filter_data);
          setTrackTableData(res.data.data.applicant_data);
          setLoading(false);
          setFilters({
            ...filters,
            search_check: false,
            enrollment_search_check: false,
          });
          setFirstApiCall(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        throw err;
      });
  };

  const [moveNextArray, setMoveNextArray] = useState([]);
  useEffect(() => {
    if (navbarData) {
      var arr = Object.keys(navbarData);
      var new_arr = [];
      var index;
      Object.keys(navbarData).map((item) => {
        if (navbarData[item].round_id === filters?.current_status) {
          if (navbarData[item].round_id !== "618f96f84ded0fcb55962c9f")
            index = Object.keys(navbarData).indexOf(item);
          else index = -1;
        }
      });
      if (index !== -1) arr.splice(0, index + 1);
      else arr.splice(1, 1);

      arr.map((item) => {
        if (item !== "rejected") {
          new_arr.push({
            name: navbarData[item].heading,
            round_id: navbarData[item].round_id,
          });
        }
      });
      setMoveNextArray(new_arr);
    }
  }, [navbarData, filters?.current_status]);

  const MoveToNextStep = (setLoadingInt, fromDialog, student_id, roundData) => {
    setLoadingInt(true);
    var arr = [];
    if (fromDialog) arr.push(student_id);
    else selectedRowsData?.map((item) => arr.push(item.id));
    if (!fromDialog) {
      var data = {
        applicant_feedback: {
          user_id: userId,
          round_id: moveToNext?.round_id,
          feedback_id: null,
          feedback_name:
            moveToNext?.feedback &&
            moveToNext?.feedback?.name.toLowerCase() === "other"
              ? moveToNext?.feedback_typed
              : moveToNext?.feedback?.name,
          round_name: moveToNext?.round_name,
          user_name:
            collegeDetails?.first_name + " " + collegeDetails?.last_name,
          posted_on: new Date().toISOString().slice(0, 10),
        },
        student_data: arr,
        job_id: filters?.job_id,
      };
    } else {
      var data = {
        applicant_feedback: {
          user_id: userId,
          round_id: roundData?.round_id,
          feedback_id: null,
          feedback_name:
            moveToNext?.feedback &&
            moveToNext?.feedback?.name.toLowerCase() === "other"
              ? moveToNext?.feedback_typed
              : moveToNext?.feedback?.name,
          round_name: roundData?.round_name,
          user_name:
            collegeDetails?.first_name + " " + collegeDetails?.last_name,
          posted_on: new Date().toISOString().slice(0, 10),
        },
        student_data: arr,
        job_id: filters?.job_id,
      };
    }
    axios
      .post(
        `${BackendBaseApi.SANCHALAN}public/track_applicant_move/?action=${window.location.href}`,
        data,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setLoadingInt(false);
          var myArray = [];
          let temp = trackTableData.filter((data) =>
            arr.includes(data.student_id)
          );
          myArray = trackTableData.filter((item) => !temp.includes(item));
          setTrackTableData(myArray);
          setMoveToNext({
            ...moveToNext,
            round_id: "",
            feedback: "",
            feedback_typed: "",
            round_name: "",
          });
          trackHeader();
        } else {
          alert.error("Some error Occurred");
          setLoadingInt(false);
        }
      })
      .catch((err) => {
        setLoadingInt(false);

        throw err;
      });
  };

  // useEffect(() => {
  //   if (placementSessionList?.length > 0) {
  //     if (location.search.includes("placement_session")) {
  //       var search = location.search;
  //       var params = new URLSearchParams(search);
  //       var placement_id = params.get("placement_session");
  //       var placement_name = checkPlacementName(placement_id);
  //       setPlacementSession({
  //         id: placement_id,
  //         name: placement_name,
  //       });
  //     } else {
  //       setPlacementSession({ id: "All", name: "All Placement Cycles" });
  //     }
  //   }
  // }, [location, placementSessionList?.length]);

  // const checkPlacementName = (id) => {
  //   var name = "";
  //   placementSessionList.map((item) => {
  //     if (item.id === id) name = item.name;
  //   });
  //   return name;
  // };

  // useEffect(() => {
  //   if (firstApiCall) {
  //     trackTable();
  //     trackHeader();
  //   }
  // }, [placementSession]);

  const [tempExportList, setTempExportList] = useState([]);
  useEffect(() => {
    if (trackTableData?.length > 0) {
      var arr = [...trackTableData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        new_arr.push({
          enrollment_id: item?.enrollment_id,
          name: item?.name,
          degree: item?.degree_name,
          course: item?.course_name,
          batch: item?.batch,
          applied_on: item?.applied_on,
        });
      });
      setTempExportList(new_arr);
    }
  }, [trackTableData?.length]);

  useEffect(() => {
    if (selectedRowsData?.length > 0) {
      let arr = [...selectedRowsData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        new_arr.push({
          enrollment_id: item?.enrollment_id,
          name: item?.name,
          degree: item?.degree_name,
          course: item?.course_name,
          batch: item?.batch,
          applied_on: item?.applied_on,
        });
      });
      setTempExportList(new_arr);
    } else {
      if (trackTableData?.length > 0) {
        var arr = [...trackTableData];
        let new_arr = [];
        var obj = {};
        arr.forEach((item) => {
          new_arr.push({
            enrollment_id: item?.enrollment_id,
            name: item?.name,
            degree: item?.degree_name,
            course: item?.course_name,
            batch: item?.batch,
            applied_on: item?.applied_on,
          });
        });
        setTempExportList(new_arr);
      }
    }
  }, [selectedRowsData?.length]);

  const handleOpenPasscode = () => {
    setOpenPasscode(true);
  };

  const handleClosePasscode = () => {
    setOpenPasscode(false);
  };

  const checkPasscode = (setLoadingPasscode) => {
    setLoadingPasscode(true);
    var data = {
      link: window.location.href,
      passcode: trackPasscode?.track_passcode,
    };
    axios
      .post(
        `${BackendBaseApi.SANCHALAN}public/track_sharer_link/validator/`,
        data,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          alert.success("Passcode successfully Verified!!");
          setLoadingPasscode(false);
          setValidatorData({
            ...res.data.data,
            college_id: res.data.data.college_id__in,
          });
          setFilters({
            ...filters,
            job_id: res.data.data?.job_id,
            college_id: res.data.data.college_id__in,
          });
          handleClosePasscode();
        } else {
          setTrackPasscode({
            ...trackPasscode,
            passcode_error: "Please check your Passcode",
          });
          setLoadingPasscode(false);
        }
      })
      .catch((err) => {
        setLoadingPasscode(false);
        if (err.response.status !== 401)
          setTrackPasscode({
            ...trackPasscode,
            passcode_error: "Please check your Passcode",
          });
        throw err;
      });
  };

  const checkIfCookieSet = () => {
    axios
      .get(
        `${BackendBaseApi.SANCHALAN}public/track_sharer_link/check_cookies/?code=${window.location.href}`,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          handleClosePasscode();
          setValidatorData(res.data.data.data);
          setFilters({
            ...filters,
            job_id: res.data.data.data?.job_id,
            college_id: res.data.data.data?.college_id,
          });
        } else {
          handleOpenPasscode();
        }
      })
      .catch((err) => {
        if (err.response.status !== 401) handleOpenPasscode();
        throw err;
      });
  };

  useEffect(() => {
    checkIfCookieSet();
  }, []);

  useEffect(() => {
    if (validatorData)
      setFilters({
        ...filters,
        job_id: validatorData?.job_id,
        college_id: validatorData?.college_id,
      });
  }, [validatorData]);

  const [selectedColumnFields, setSelectedColumnFields] = useState([]);
  const [exportLoading, setExportLoading] = useState({
    loading: false,
    success: false,
  });

  const handleExport = (email = "") => {
    if (selectedColumnFields?.length > 0) {
     
      let columnStrings = getColumnString(selectedColumnFields);
      columnStrings =  columnStrings.replace('batch',"current_batch");
         
      const URL = `public/export-public-manage-job-track-sharer/?college_id=${
        validatorData?.college_id
      }&column_name=${columnStrings}&job_id=${
        validatorData?.job_id
      }${checkCurrentTab()}${checkEnrollmentSearch()}${checkSearch()}${checkDegree()}${checkCourse()}${checkBatch()}${checkAppliedDate()}${checkPlacement()}&action=${
        window.location.href
      }&email=${email}`;

      sendExportLink(URL, setExportLoading, alert, history, location, true);
    } else {
      alert.error("Select Columns to Export");
    }
  };

  return (
    <div>
      <StudentFormNavbar />
      <div style={{ margin: 50 }}>
        <div className={classes.sharedBox}>
          Shared By: {validatorData?.user_name}
          {validatorData?.college_name && <>, {validatorData?.college_name}</>}
        </div>
        <>
          {/* {placementSessionList?.length > 0 && (
            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              <DropDownButton
                title="Placement Session 2021"
                placementSession={placementSession}
                setPlacementSession={setPlacementSession}
                placementSessionList={placementSessionList}
              />
            </div>
          )} */}
          <TrackHeader
            data={breadcrumbData}
            tempExportList={tempExportList}
            filters={filters}
            fromPublicTrack={true}
            validatorData={validatorData}
            handleExport={handleExport}
            exportLoading={exportLoading}
            setExportLoading={setExportLoading}
          />
          <TrackJobTab
            navbarData={navbarData}
            filters={filters}
            setFilters={setFilters}
            filterList={filterList}
            trackTableData={trackTableData}
            selectedRowsData={selectedRowsData}
            setSelectedRowsData={setSelectedRowsData}
            loading={loading}
            moveNextArray={moveNextArray}
            moveToNext={moveToNext}
            setMoveToNext={setMoveToNext}
            MoveToNextStep={MoveToNextStep}
            fromPublicTrack={true}
            validatorData={validatorData}
            selectedColumnFields={selectedColumnFields}
            setSelectedColumnFields={setSelectedColumnFields}
          />
          <TrackPasscode
            trackPasscode={trackPasscode}
            setTrackPasscode={setTrackPasscode}
            checkPasscode={checkPasscode}
            open={openPasscode}
            handleClickOpen={handleOpenPasscode}
            handleClose={handleClosePasscode}
          />
          {/* <TrackSuccessfully />
        <InterviewScheduling /> */}
          {/* <Interview /> */}
        </>
      </div>
    </div>
  );
}
