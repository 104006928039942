import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableFooter,
  TableHead,
  IconButton,
  TableContainer,
  Paper,
  TablePagination,
  useTheme,
  styled,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D2E3FF",
    color: theme.palette.common.black,
    border: "1px solid #CBD5E1",
    padding: 5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #CBD5E1",
    color: "#6C757D",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: "1px solid #CBD5E1",
    // border: 0,
  },
}));

function createData(
  transaction_id,
  transaction_type,
  credits_free,
  expires_on,
  credits_paid,
  balance,
  action_type,
  action_by,
  date
) {
  return {
    transaction_id,
    transaction_type,
    credits_free,
    expires_on,
    credits_paid,
    balance,
    action_type,
    action_by,
    date,
  };
}

const rows = [
  createData(
    1726483,
    "Credit",
    45,
    "Expires on 12-05-2024",
    75,
    "₹ 1,000",
    "Add",
    "GetWork",
    "08-05-2024 at12:32 PM"
  ),
  createData(
    1726483,
    "Credit",
    45,
    "Expires on 12-05-2024",
    75,
    "₹ 1,000",
    "Add",
    "GetWork",
    "08-05-2024 at12:32 PM"
  ),
  createData(
    1726483,
    "Credit",
    45,
    "",
    75,
    "₹ 1,000",
    "Add",
    "GetWork",
    "08-05-2024 at12:32 PM"
  ),
];

function formatDate(timestamp, dateAndTime = false) {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return dateAndTime
    ? `${day}-${month}-${year} at ${hours}:${minutes} ${ampm}`
    : `${day}-${month}-${year}`;
}

export default function AllHistory({
  transactionData,
  totalCount,
  page,
  setPage,
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    // const handleFirstPageButtonClick = (event) => {
    //   onPageChange(event, 0);
    // };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    // const handleLastPageButtonClick = (event) => {
    //   onPageChange(event, Math.max(0, Math.ceil(totalCount / rowsPerPage) - 1));
    // };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        {/* <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton> */}
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        {/* <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton> */}
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: "100%",
          boxShadow: "none",
        }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell rowSpan={2}>Transaction ID</StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Transaction Type
            </StyledTableCell>
            <StyledTableCell colSpan={2} align="center">
              Credits
            </StyledTableCell>

            <StyledTableCell rowSpan={2} align="center">
              Available Credits
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Action Type
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Action By
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Date & Time
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" style={{ color: "#029E73" }}>
              Free
            </StyledTableCell>
            <StyledTableCell align="center" style={{ color: "#0B57D0" }}>
              Paid
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionData?.length === 0 ? (
            <StyledTableRow style={{ height: "250px" }}>
              <StyledTableCell colSpan={8} align="center">
                No data present
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            transactionData
              .slice(
                rowsPerPage == -1 ? 0 : page * rowsPerPage,
                rowsPerPage == -1
                  ? transactionData?.length
                  : page * rowsPerPage + rowsPerPage
              )
              .map((row) => (
                <StyledTableRow key={row?.name}>
                  <StyledTableCell component="th" scope="row">
                    {row?.transaction_id}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color:
                        row?.transaction_type == "CREDIT"
                          ? "#029E73"
                          : "#EE2C3C",
                    }}
                  >
                    {row?.transaction_type}
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: "150px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        {row?.transaction_type == "CREDIT" ? (
                          <span style={{ color: "#029E73" }}>
                            {" "}
                            +{row?.free_credit}{" "}
                          </span>
                        ) : (
                          <span style={{ color: "#EE2C3C" }}>
                            {" "}
                            -{row?.free_credit}{" "}
                          </span>
                        )}
                      </div>
                      <div>
                        <span
                          style={{
                            fontSize: "10px",
                            fontStyle: "italic",
                            color: "#6C757D",
                          }}
                        >
                          {row?.expiry_date &&
                            `Expires on ${formatDate(row?.expiry_date)}`}
                        </span>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: "150px" }}>
                    {row?.transaction_type == "CREDIT" ? (
                      <span style={{ color: "#029E73" }}>
                        {" "}
                        +{row.paid_credit}{" "}
                      </span>
                    ) : (
                      <span style={{ color: "#EE2C3C" }}>
                        {" "}
                        -{row.paid_credit}{" "}
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "#0455D6" }}>
                    {row?.available_credits}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.action_type}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.action_by?.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.date_and_time}
                  </StyledTableCell>
                </StyledTableRow>
              ))
          )}
        </TableBody>
        <TableFooter style={{ position: "relative", width: "100%" }}>
          <TableRow style={{}}>
            <TablePagination
              rowsPerPageOptions={[5]}
              // colSpan={3}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              style={{
                paddingRight: "34%",
              }}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
