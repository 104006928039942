import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ProfileApproveDialog from "../../EditStudent/ProfileApproveDialog/ProfileApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
}));

const Certifictions = ({
  data,
  userId,
  ApproveAcceptList,
  StudentDetailsAPI,
  fromProfileUpdate,
}) => {
  const classes = useStyles();
  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <br />
      {data?.length > 0 ||
      (fromProfileUpdate &&
        ApproveAcceptList &&
        Object.keys(ApproveAcceptList)?.includes("CERT")) ? (
        <div className={classes.paper}>
          <Typography variant="h6">Courses and Certifications</Typography>
          {data?.length > 0 &&
            data?.map((certification) => (
              <div style={{ margin: "20px 0 30px 0" }}>
                <Typography
                  variant="body1"
                  style={{
                    // fontWeight: "bold",
                    marginBottom: "4px",
                  }}
                >
                  {certification?.name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    // fontWeight: "bold",
                    marginBottom: "4px",
                  }}
                >
                  {certification?.institute}
                </Typography>

                <Typography variant="body2">
                  {certification?.start_date &&
                    certification?.start_date !== null && (
                      <>
                        {moment(certification?.start_date).format("MMM, YYYY")}{" "}
                        {certification.end_date &&
                          certification.end_date !== null && (
                            <>
                              {" - "}
                              {moment(certification?.end_date).format(
                                "MMM, YYYY"
                              )}{" "}
                            </>
                          )}
                      </>
                    )}
                </Typography>

                <Typography
                  variant="body2"
                  style={{
                    // fontWeight: "bold",
                    marginBottom: "4px",
                  }}
                >
                  Valid Upto:{" "}
                  {certification?.valid_date &&
                  certification?.valid_date !== null
                    ? moment(certification?.valid_date).format("MMM, YYYY")
                    : "Doesn't Expire"}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginBottom: "4px",
                  }}
                >
                  Description:{" "}
                  {certification?.description && certification?.description}
                </Typography>
              </div>
            ))}
          {fromProfileUpdate &&
          ApproveAcceptList &&
          Object.keys(ApproveAcceptList)?.includes("CERT") ? (
            <div
              style={{
                backgroundColor: "green ",
                padding: "10px",
                width: "160px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                right: "10px",
                bottom: "10px",
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
              >
                {`Pending Request (${ApproveAcceptList?.CERT})`}
              </Typography>
            </div>
          ) : (
            <></>
          )}
          <ProfileApproveDialog
            title="Courses and Certifications Request"
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            formType="approveCertification"
            data={data}
            userId={userId}
            type={"CERT"}
            StudentDetailsAPI={StudentDetailsAPI}
          />
        </div>
      ) : null}
    </>
  );
};

export default Certifictions;
