import {
  Checkbox,
  Grid,
  IconButton,
  InputBase,
  Paper,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import AcceptTeam from "../../DialogSection/AcceptTeam";
import ChangePlacement from "../../DialogSection/AddJobPlacementCycle/ChnagePlacement";
import BlockCompany from "../../DialogSection/BlockCompany";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "90%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "45px",
    //paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    //paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".3px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
    width: "90%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

function ManageAction({
  filters,
  setFilters,
  setClickSearchButton,
  selectedRowsData,
  clickSearchButton,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  designationList,
  teamList,
  collegeId,
  userRolesPermission,
  setCallNavbar,
}) {
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const [checked, setChecked] = React.useState(true);

  const handleCheckChange = (event) => {
    setFilters({ ...filters, status_active: event.target.checked });
  };
  const handleCheckChange2 = (event) => {
    setFilters({ ...filters, status_blocked: event.target.checked });
  };
  const courseList = [
    {
      id: 1,
      name: "Computer Science and Engineering",
    },
  ];
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          width: "90%",
          marginTop: "10px",
          marginBottom: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={2}>
            <Typography variant="h6">Add Team Members</Typography>
          </Grid>
          <Grid item xs={3}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search by Name..."
                inputProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
                // onChange={(e) => {
                //   setFilters({
                //     ...filters,
                //     search: e.target.value,
                //   });
                // }}
              />
              {/* {filters?.search && ( */}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                //   onClick={() => {
                //     setFilters({
                //       ...filters,
                //       search: "",
                //     });
                //     setClickSearchButton(true);
                //   }}
              >
                <ClearIcon />
              </IconButton>
              {/* )} */}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.floatingLabelFocusStyle}>
                Role
              </InputLabel>
              <Select
                native
                value={state.age}
                onChange={handleChange}
                label="Role"
                inputProps={{
                  style: {
                    padding: "16px",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  },
                }}
              >
                <option aria-label="None" value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2} style={{ display: "grid" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Add Member
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ManageAction;
