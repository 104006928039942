import { Chip, Grid, Typography, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import pSBC from "shade-blend-color";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },

  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    marginRight: "10px",
    boxShadow: "none",
    padding: "10px",
    height: "40px",
    display: "flex",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
    
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "10px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
}));

export default function AddStudentPlacementCycle({
  disableBtn,
  addStudentToPlacementCycle,
  dataLoading,
  placement_cycle_name,
  addToPlacementCycleError,
  setAddToPlacementCycleError,
  apiResponse,
  selectedRowsData,
  setSelectedRowsData,
  setSelectedID,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAddToPlacementCycleError(false);
    setOpen(false);
  };

  const handleSubmit = () => {
    addStudentToPlacementCycle();
    if (apiResponse) {
      handleClose();
    }
  };
  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  const [showMore, setShowMore] = useState(false);
  return (
    <div>
      <Button
        color="primary"
        size="small"
        className={classes.button}
        variant="contained"
        onClick={handleClickOpen}
        style={{ height: "40px" }}
        disabled={disableBtn}
      >
        Add Student to Placement Cycle
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {addToPlacementCycleError ? (
          <div style={{ padding: "20px", width: "350px" }}>
            <div
              style={{
                padding: "10px",
                paddingLeft: "10px",
                textAlign: "center",
              }}
            >
              <Typography variant="h6">Something went wrong!</Typography>
              <Typography variant="h6">Please Try again</Typography>
            </div>

            <Grid
              container
              spacing={2}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  {dataLoading ? <CustomButtonCircularProgress /> : "Try Again"}
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ padding: "20px", width: "570px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "10px",
                paddingLeft: "10px",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                Are you sure you want to add selected students in <br></br>
                {placement_cycle_name} ?
              </Typography>
              {/*    <Tooltip title={placement_cycle_name} placement="right">
                <Typography variant="h6" noWrap style={{ maxWidth: 200 }}>
                  {placement_cycle_name} ?
                </Typography>
              </Tooltip> */}
            </div>

            <div component="ul" className={classes.root}>
              {!showMore
                ? selectedRowsData.slice(0, 5).map((data, index) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : selectedRowsData.map((data, index) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
            </div>

            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}

            <Grid
              container
              spacing={2}
              style={{ marginTop: "20px", marginBottom: "10px" }}
            >
              <Grid item md={6} style={{ display: "grid" }}>
                <Button
                  className={classes.button}
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item md={6} style={{ display: "grid" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  {dataLoading ? (
                    <CustomButtonCircularProgress />
                  ) : (
                    "Add to Placement Cycle"
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </Dialog>
    </div>
  );
}
