import React from "react";
import "./feed.css";

import "quill/dist/quill.snow.css";

import { useQuill } from "react-quilljs";
//import "quill-paste-smart";

const placeholder = "Type Something...";

export default function FeedEditor(props) {
  const modules = {
    toolbar: ["bold", "italic", "underline", "link", "clean"],
  };
  const { state, setState, desc } = props;

  const { quill, quillRef } = useQuill({ modules, placeholder });

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta) => {
        let content = quill.root.innerHTML;

        const cleanedContent = content
          .replace(/<p>\s*<\/p>/g, "")
          .replace(/<p><br><\/p>/g, "")
          .trim();

        setState(cleanedContent === "" ? "" : content);
      });
    }
  }, [quill]);

  React.useEffect(() => {
    if (quill && desc) {
      quill.clipboard.dangerouslyPasteHTML(desc ? desc.replace(/,/g, "") : "");
    }
  }, [quill, desc]);
  return (
    <div className="App">
      <div
        // className="container"
        style={{
          backgroundColor: "#fff",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 150,
            marginBottom: 50,
            borderRadius: 8,
          }}
        >
          <div
            ref={quillRef}
            style={{
              borderRadius: "0px 0px 8px 8px",
              backgroundColor: "white",
              paddingBottom: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
}
