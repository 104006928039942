import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import AddNewMember from "../DialogItem/AddNewMember";
import AddNewMemberGroup from "../DialogItem/AddNewMember/groupView";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../constants/constants";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function AddMemberGroup({
  teamList,
  userRoleList,
  designationList,
  placementSession,
  userRoleId,
  setApiCalled,
  collegeId,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const alert = useAlert();

  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const handleClickOpen = () => {
    if (licenceData && licenceData?.display) {
      alert.error(licenceData?.display_message);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [teamDetails, setTeamDetails] = useState({
    name: null,
    email: null,
    phone: null,
    designation: null,
    is_super_admin: null,
  });

  const [teamDetailsError, setTeamDetailsError] = useState({
    is_super_admin: null,
    name: null,
    email: null,
    phone: null,
    designation: null,
    batch: null,
    degree: null,
    course: null,
    group: null,
    user_role: null,
    college_list: null,
  });

  const [groupArray, setGroupArray] = useState([]);
  const [groupArrayInitialState, setGroupArrayInitialState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getGroupCollege = async () => {
      const res = await axios
        .get(
          `${BackendBaseApi.SANCHALAN}api/college_group/group_college_ids/`,
          {
            withCredentials: true,
          }
        )
        .catch((error) => {
          setLoading(false);
          throw error;
        });

      if (res.data.success) {
        setGroupArray(res?.data?.data);
        setGroupArrayInitialState(res?.data?.data);
      }
      setLoading(false);
    };
    getGroupCollege();
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Add New
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <Grid container spacing={3} style={{ padding: "40px" }}>
            <Grid item xs={12}>
              <AddNewMemberGroup
                handleClose={handleClose}
                teamList={teamList}
                userRoleList={userRoleList}
                teamDetails={teamDetails}
                setTeamDetails={setTeamDetails}
                teamDetailsError={teamDetailsError}
                setTeamDetailsError={setTeamDetailsError}
                designationList={designationList}
                placementSession={placementSession}
                userRoleId={userRoleId}
                setApiCalled={setApiCalled}
                collegeId={collegeId}
                groupArray={groupArray}
                setGroupArray={setGroupArray}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
