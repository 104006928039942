import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Dialog, Button, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
    padding: "20px",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "20px",
  },
}));

export default function Block({ BlockStudent, blockStatus }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={handleClickOpen}
        style={{ marginRight: "20px" }}
      >
        {!blockStatus ? "Block" : "Unblock"}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ width: "400px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "20px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure. <br />
              Want to block this student
            </Typography>
          </div>
        </div>

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          multiline
          rows="2"
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label={`Why you want to ${
            !blockStatus ? "block" : "Active"
          } this students?`}
          value={deleteReason}
          onChange={(e) => {
            setDeleteReason(e.target.value);
            setDeleteReasonError("");
          }}
          style={{ marginBottom: 10 }}
          error={deleteReasonError ? true : false}
          helperText={<span>{deleteReasonError}</span>}
        />
        <br />

        <div className={classes.btngrid}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            autoFocus
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              if (deleteReason)
                BlockStudent(
                  !blockStatus ? "INACTIVE" : "ACTIVE",
                  deleteReason,
                  handleClose
                );
            }}
          >
            {!blockStatus ? "Block" : "Unblock"}
          </Button>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
