import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { StudentJobApplyAPI } from "../../../api/ManageStudents/action";
import { userRolesActionsFn } from "../../../api/SignIn/selector";
import FilterScore from "../FilterScore";
const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    padding: "5px",
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
    // color: "#6c757d",
    "& ::-webkit-input-placeholder": {
      color: "#000 !important",
    },
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  apply: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "48px",
  },
}));

export default function EligibleActionBar({
  filters,
  setFilters,
  setClickSearchButton,
  selectedRowsData,
  clickSearchButton,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  filterList,
  studentList,
  setApplyClickedScore,
  collegeId,
  setRedirectionAfterApplyToJob,
}) {
  const [checked, setChecked] = React.useState(true);

  const handleCheckChange = (event) => {
    setFilters({ ...filters, status_active: event.target.checked });
  };
  const handleCheckChange2 = (event) => {
    setFilters({ ...filters, status_blocked: event.target.checked });
  };

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleClose = () => {};
  const handleSubmit = () => {
    var arr = [];
    selectedRowsData?.map((item) => arr.push(item.id));

    var data = {
      college_id: [collegeId],
      job_id: params?.job?.split("-")?.pop(),
      student_id: arr,
    };
    dispatch(
      StudentJobApplyAPI(
        data,
        alert,
        history,
        location,
        studentList,
        handleClose,
        setLoading,
        true,
        setSelectedRowsData
      )
    );
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search By Name"
                value={filters.search}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  });
                }}
              />
              {filters?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      search: "",
                    });
                    setClickSearchButton(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {filterList &&
            Object.keys(filterList)?.length > 0 &&
            Object.keys(filterList).map(
              (item) =>
                filterList[item].type === "autocomplete" && (
                  <Grid item sm={2} style={{padding:"1rem"}}>
                    <Autocomplete
                      id="country-select-demo"
                      options={filterList[item].data}
                      getOptionLabel={(option) => String(option.name)}
                      renderOption={(option) => (
                        <Typography style={{ fontSize: "14px" }}>
                          {option.name}
                        </Typography>
                      )}
                      value={filters[filterList[item].key]}
                      onChange={(e, newValue) => {
                        setFilters({
                          ...filters,
                          [filterList[item].key]: newValue,
                        });
                      }}
                      style={{ background: "#fff", borderRadius:8 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={filterList[item].heading}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                )
            )}

          <Grid item xs={2}>
            <FilterScore
              scoreData={filters}
              setScoreData={setFilters}
              setApplyClickedScore={setApplyClickedScore}
            />
          </Grid>
          <Grid item xs={2} className={classes.grid}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={filters.status_active}
                size="small"
                color="primary"
                onChange={handleCheckChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />

              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "8px",
                  backgroundColor: "#51c03e",
                }}
              />
              <Typography
                variant="body2"
                style={{ marginLeft: "7px", fontWeight: "bold" }}
              >
                Active
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={filters.status_blocked}
                size="small"
                color="primary"
                onChange={handleCheckChange2}
                inputProps={{ "aria-label": "primary checkbox" }}
              />

              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "8px",
                  backgroundColor: "#f64a3f",
                }}
              />
              <Typography
                variant="body2"
                style={{ marginLeft: "7px", fontWeight: "bold" }}
              >
                Block
              </Typography>
            </div>
          </Grid>
          {selectedRowsData?.length > 0 &&
            userRolesPermission?.some(
              (item) => item.access_control_key === "manage_students"
            ) && (
              <Grid item xs={2}>
                <Button
                  color="primary"
                  style={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    height: "40px",
                    display: "flex",
                  }}
                  // size="small"
                  //className={classes.button}
                  variant="contained"
                  onClick={() => {
                    if (!loading) handleSubmit();
                  }}
                >
                  Apply to Job Profile
                </Button>{" "}
              </Grid>
            )}
        </Grid>
      </div>
    </>
  );
}
