import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  collegeFormCountSelectorFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { checkForBreadcrumb } from "../../../utility/checkBreadcrumbs";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import BackendDrivenFilterDrawer from "../../DialogSection/FilterDrawer/BackendDrivenFilterDrawer";
import OfferLetter from "../../DialogSection/OfferLetter/OfferLetter";
import PlacementCyclesDialog from "../../DialogSection/UniManageJob";
import CompanyTableNew from "../../Table/CompanyTableNew";
import View from "../../Table/JobTable/View";
import { openJobsField } from "./openJobColumn";
import InfoToolTip from "../../Common/ToolTips/InfoToolTip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  primebtn: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  formControl: {
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root1: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
    "& ::-webkit-input-placeholder": {
      color: "#000 !important",
    },
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    marginRight: "12px",
    boxShadow: "none",
    borderRadius: 8,
    height: "48px",
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "8px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
}));

export default function JobTab({
  loading,
  setSelectedRowsData,
  selectedRowsData,
  filters,
  setFilters,
  companyList,
  setCompanyList,
  jobTitleSearch,
  companyNameSearch,
  setJobTitleSearch,
  setCompanyNameSearch,
  setApplyClickedCTC,
  applyClickedCTC,
  jobNavbar,
  placementSession,
  teamMemberList,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  selectedColumnFields,
  setSelectedColumnFields,
  setJobTypeClick,
  jobTypeClick,
  backendDrivenFilter,
  setBackendDrivenFilter,
  filterData,
  filterLoading,
  applicationStatusLoading,
  setApplicationStatusLoading,
  companyListTable,
  setCompanyListTable,
  companyListTotalCount,
  pageData,
  setPageData,
  toggleViewCollege,
  handleToggle,
  setToggleViewCollege,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const jobList = useSelector((state) => state?.JobData?.details);
  const [selectedID, setSelectedID] = React.useState([]);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const [openOfferTable, setOpenOfferTable] = useState(false);
  const initialOfferLetterTableMeta = {
    jobId: "",
    data: [],
  };

  const [offerTableData, setOfferTableData] = useState(
    initialOfferLetterTableMeta
  );

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    if (!loading) setFilters({ ...filters, job_status: newValue });
  };

  const totalJobs = useSelector((store) => store?.JobData?.details?.length);
  const collegeFormCount = useSelector(collegeFormCountSelectorFn);

  // useEffect(() => {
  //   setSelectedRowsData([]);
  //   // setFilters({ ...filters, job_title: "", company_name: "" });
  // }, [filters?.job_status]);

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);
  const alert = useAlert();

  // const [applicationStatusData, setApplicationStatusData] = React.useState({});
  // const [applicationStatusLoading, setApplicationStatusLoading] =
  //   React.useState(true);

  // const getApplicationStatusData = async (id) => {
  //   const url = `api/manage_job/job_applicant_count/?job_id=${id}`;
  //   setApplicationStatusLoading(true);
  //   try {
  //     const res = await sanchalanAxiosGetReq(url);
  //     if (res.data.success) {
  //       const statusResponse = res?.data?.data;
  //       setApplicationStatusData(statusResponse);
  //     } else {
  //       console.log("error ocuured");
  //     }
  //     setApplicationStatusLoading(false);
  //   } catch (error) {
  //     setApplicationStatusLoading(false);
  //     checkForToken(error, history, location, alert);
  //     if (error?.response?.status !== 401) {
  //       alert.error(error?.response?.data?.error);
  //     }
  //     throw error;
  //   }
  // };
  // const [companyListTable, setCompanyListTable] = useState([]);

  // useEffect(() => {
  //   if (companyList && companyList?.length > 0) {
  //     const arr = [...companyList];
  //     arr
  //       ?.slice(
  //         (pageData?.currentPage + 1) * pageData?.pageSize - pageData?.pageSize,
  //         (pageData?.currentPage + 1) * pageData?.pageSize
  //       )
  //       ?.forEach((item) => {
  //         applicationStatusData[item?.id]?.map((statusObj) => {
  //           switch (statusObj?.name) {
  //             case "Eligible":
  //               item.eligible_count = statusObj?.value;
  //               return;
  //             case "Applied":
  //               item.applied_count = statusObj?.value;
  //               return;
  //             case "Present":
  //               item.present_count = statusObj?.value;
  //               return;
  //             case "Hired":
  //               item.hired_count = statusObj?.value;
  //               return;
  //           }
  //         });
  //       });
  //     setCompanyListTable(arr);
  //   }
  // }, [companyList, applicationStatusData]);

  // useEffect(() => {
  //   if (companyList?.length && pageData && filters?.job_status === "OPEN") {
  //     let idString = ``;

  //     setCompanyListTable([]);
  //     const arr = [...companyList];
  //     arr
  //       ?.slice(
  //         (pageData?.currentPage + 1) * pageData?.pageSize - pageData?.pageSize,
  //         (pageData?.currentPage + 1) * pageData?.pageSize
  //       )
  //       ?.map((item) => {
  //         idString += `${item?.id},`;
  //       });
  //     getApplicationStatusData(idString.replace(/,\s*$/, ""));
  //   }
  // }, [pageData, companyList]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [collegeToPlacementCycle, setCollegeToPlacementCycle] = useState([]);

  const handleClickView = (data) => {
    setCollegeToPlacementCycle(data);
    handleClickOpen();
  };

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const checkColumns = (param) => {
    switch (param) {
      case "OPEN":
        return [
          //   {
          //     field: "id",
          //     headerName: "Job ID",
          //     // type: "text",
          //     width: 170,
          //     renderHeader: (params) => (
          //       <strong
          //         style={{
          //           color: "#5a6268",
          //           marginLeft: "45px",
          //         }}
          //       >
          //         Job ID
          //       </strong>
          //     ),
          //     renderCell: (params) => (
          //       <>
          //         <Grid
          //           container
          //           wrap="nowrap"
          //           style={{
          //             textAlign: "left",
          //             display: "flex",
          //             fontSize: "14px",
          //           }}
          //         >
          //           {" "}
          //           <Grid item xs zeroMinWidth>
          //             <Typography noWrap style={{ fontSize: "14px" }}>
          //               {params.row.id}
          //             </Typography>
          //           </Grid>
          //         </Grid>
          //       </>
          //     ),
          //   },
          {
            field: "job_title",
            headerName: "Job Title",

            width: 170,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "45px",
                }}
              >
                Job Title
              </strong>
            ),
            renderCell: (params) => (
              <>
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    textAlign: "left",
                    display: "flex",
                    //    margin: "auto",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  <Grid item xs zeroMinWidth>
                    <Link
                      to={`/${
                        isEducationGroup || isCollegeGroup ? "job" : "job"
                      }/${params?.row?.job_title
                        ?.toLowerCase()
                        .replace(" ", "-")}-${params.row.id}`}
                      target={`${
                        isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                      }`}
                      style={{ textDecoration: "none", display: "contents" }}
                    >
                      <Tooltip title={params.row.job_title} placement="right">
                        <Typography
                          color="primary"
                          noWrap
                          style={{ fontSize: "14px" }}
                        >
                          {params.row.job_title}
                        </Typography>
                      </Tooltip>
                    </Link>
                  </Grid>
                </Grid>
              </>
            ),
          },
          {
            field: "company_name",
            headerName: "Company Name",
            // type: "text",
            width: 170,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "20px" }}>
                Company Name
              </strong>
            ),
            renderCell: (params) => (
              <>
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    textAlign: "left",
                    display: "flex",
                    //    margin: "auto",
                    fontSize: "14px",
                  }}
                >
                  <Grid item xs zeroMinWidth>
                    <Link
                      to={`/${
                        isEducationGroup || isCollegeGroup
                          ? "company-profile"
                          : "company-profile"
                      }/${params.row.id}`}
                      target={`${
                        isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                      }`}
                      style={{ textDecoration: "none", display: "contents" }}
                    >
                      {" "}
                      <Tooltip
                        title={params.row.company_name}
                        placement="right"
                      >
                        <Typography
                          color="primary"
                          noWrap
                          style={{ fontSize: "14px" }}
                        >
                          {params.row.company_name}
                        </Typography>
                      </Tooltip>
                    </Link>
                  </Grid>
                </Grid>
              </>
            ),
          },
          {
            field: "college_posting",
            headerName: "College Postings",
            // type: "text",
            width: 170,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "5px" }}>
                {`${entityInfo?.college} Count`}
              </strong>
            ),
            renderCell: (params) => (
              <>
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    margin: "auto",
                    fontSize: "14px",
                  }}
                >
                  <Grid item xs zeroMinWidth>
                    <Typography
                      color="primary"
                      noWrap
                      style={{ fontSize: "14px", cursor: "pointer" }}
                      onClick={() =>
                        handleClickView(params.row.college_to_cycle)
                      }
                    >
                      {`View (${params.row.college_posting})`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ),
          },
          {
            field: "job_type",
            // type: "text",
            headerName: "Job Type",
            sortable: false,
            width: 110,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
                Job Type
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.job_type}
              </span>
            ),
          },

          {
            field: "ctc",
            // type: "text",
            headerName: "CTC(Min-Max)",
            sortable: false,
            width: 150,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
                CTC(Min-Max)
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.ctc}
              </span>
            ),
          },

          {
            field: "stipend",
            // type: "text",
            headerName: "Stipend(Min-Max)",
            sortable: false,
            width: 180,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "18px" }}>
                Stipend(Min-Max)
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.stipend}
              </span>
            ),
          },

          {
            field: "vacancies",
            headerName: "Vacancies",
            // type: "text",
            width: 120,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
                Vacancies
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.vacancy ? params.row.vacancy : "NA"}
              </span>
            ),
          },
          {
            field: "source",
            // type: "text",
            headerName: "Source",
            sortable: false,
            width: 140,
            renderHeader: (params) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <strong style={{ color: "#5a6268", marginLeft: 10 }}>
                  Source
                </strong>
              </div>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.source}
              </span>
            ),
          },
          {
            field: "posted_by",
            headerName: "Posted By",

            width: 140,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "2px" }}>Posted By</div>
              </strong>
            ),

            renderCell: (params) => (
              <span
                color="primary"
                style={{ fontSize: "14px", textAlign: "center", width: "100%" }}
              >
                {params?.row.posted_by_type &&
                params?.row.posted_by_type != "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ marginRight: "5px" }}>
                        {params?.row.posted_by_type}
                      </p>
                      <InfoToolTip
                        size={2}
                        rejectedDate={""}
                        label={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `Name: ${params?.row.posted_by?.user_name} <br /> Email Id: ${params?.row.posted_by?.user_email} <br /> Designation: ${params?.row.posted_by?.designation} <br /> ${entityInfo?.college} Name: ${params?.row.posted_by?.college_name}`,
                            }}
                          />
                        }
                      />
                    </div>
                  </>
                ) : (
                  "..."
                )}
              </span>
            ),
          },
          {
            field: "applicant_count",
            // type: "number",
            headerName: "Applicants",
            width: 120,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "8px" }}>
                Applicants
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                }}
              >
                {" "}
                <Link
                  to={`/educational-group/track/?job_id=${params?.row?.id}`}
                  target={`${
                    isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                  }`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography color="primary" style={{ fontSize: "14px" }}>
                    {params.row.applicant_count}
                  </Typography>
                </Link>
              </span>
            ),
          },
          {
            field: "created",
            headerName: "Action Date",
            type: "date",
            width: 230,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "60px" }}>
                Action Date
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.created}
              </span>
            ),
          },
          {
            field: "button",
            headerName: "Actions",
            type: "button",
            width: 100,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "6px" }}>
                Actions
              </strong>
            ),
            renderCell: (cellValues) => {
              return (
                <View
                  id={cellValues?.row?.id}
                  title={cellValues?.row?.job_title}
                  isEducationGroup={isEducationGroup}
                  isCollegeGroup={isCollegeGroup}
                />
              );
            },
          },
        ];
    }
  };

  const checkFields = (param) => {
    switch (param) {
      case "OPEN":
        return openJobsField;
    }
  };

  const [columns, setColumns] = useState(checkColumns("OPEN"));

  return (
    <div className={classes.root}>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          // justifyContent: "space-between",
        }}
      >
        {/* {!loading ? ( */}
        <Grid container spacing={3} style={{ display: "contents" }}>
          <Grid item xs={2} className={classes.grid} style={{ paddingLeft: 0 }}>
            <Paper component="form" className={classes.root1}>
              <InputBase
                className={classes.input}
                placeholder="Job by title"
                value={
                  backendDrivenFilter?.outerFilters?.job_search
                    ? backendDrivenFilter?.outerFilters?.job_search
                    : ""
                }
                onChange={(e) => {
                  setBackendDrivenFilter((prev) => ({
                    ...prev,
                    outerFilters: {
                      ...prev?.outerFilters,
                      job_search: e.target.value,
                    },
                  }));
                }}
                disabled={loading}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setJobTitleSearch(true);
                  }
                }}
              />
              {backendDrivenFilter?.outerFilters?.job_search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setBackendDrivenFilter((prev) => ({
                      ...prev,
                      outerFilters: {
                        ...prev?.outerFilters,
                        job_search: "",
                      },
                    }));
                    setJobTitleSearch(true);
                  }}
                  disabled={loading}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                disabled={loading}
                onClick={() => {
                  setJobTitleSearch(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid item xs={2} className={classes.grid}>
            <Paper component="form" className={classes.root1}>
              <InputBase
                className={classes.input}
                placeholder="Company Name"
                value={
                  backendDrivenFilter?.outerFilters?.company_search
                    ? backendDrivenFilter?.outerFilters?.company_search
                    : ""
                }
                onChange={(e) => {
                  setBackendDrivenFilter((prev) => ({
                    ...prev,
                    outerFilters: {
                      ...prev?.outerFilters,
                      company_search: e.target.value,
                    },
                  }));
                }}
                disabled={loading}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setCompanyNameSearch(true);
                  }
                }}
              />
              {backendDrivenFilter?.outerFilters?.company_search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  disabled={loading}
                  onClick={() => {
                    setBackendDrivenFilter((prev) => ({
                      ...prev,
                      outerFilters: {
                        ...prev?.outerFilters,
                        company_search: "",
                      },
                    }));
                    setCompanyNameSearch(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                disabled={loading}
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setCompanyNameSearch(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid item md={2}>
            <BackendDrivenFilterDrawer
              backendDrivenFilter={backendDrivenFilter}
              setBackendDrivenFilter={setBackendDrivenFilter}
              filterData={filterData}
              loading={loading}
              filterLoading={filterLoading}
            />
          </Grid>
          {/* <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 0,
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                color={toggleViewCollege ? "primary" : "#6C757D"}
                style={{ fontSize: 16, marginRight: 5 }}
              >
                Show Colleges
              </Typography>
              <AntSwitch
                checked={toggleViewCollege}
                onChange={handleToggle}
                name="checkedC"
              />
            </Box>
          </Grid> */}
        </Grid>
      </div>

      <>
        {selectedRowsData?.length > 0 && (
          <div
            style={{
              display: "flex",
              marginTop: "15px",
              marginBottom: "20px",
            }}
          >
            {selectedRowsData?.length === 1 && (
              <Button
                color="primary"
                variant="contained"
                style={{
                  borderRadius: "8px",
                  marginRight: "10px",
                  boxShadow: "none",
                  padding: "7px",
                  height: "40px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  display: "flex",
                }}
                onClick={() => {
                  if (selectedRowsData[0]?.niyukti_job_id) {
                    history.push(
                      groupViewCheck(
                        isEducationGroup,
                        isCollegeGroup,
                        collegeId
                      )
                        ? `${checkForBreadcrumb(
                            isEducationGroup,
                            isCollegeGroup,
                            collegeId,
                            `post-job/edit-${selectedRowsData[0]?.id}`
                          )}?is_niyukti_job=true`
                        : `/post-job/edit-${selectedRowsData[0]?.id}?is_niyukti_job=true`
                    );
                  } else {
                    history.push(
                      groupViewCheck(
                        isEducationGroup,
                        isCollegeGroup,
                        collegeId
                      )
                        ? checkForBreadcrumb(
                            isEducationGroup,
                            isCollegeGroup,
                            collegeId,
                            `post-job/edit-${selectedRowsData[0]?.id}`
                          )
                        : `/post-job/edit-${selectedRowsData[0]?.id}`
                    );
                  }
                }}
              >
                Edit
              </Button>
            )}
          </div>
        )}

        <CompanyTableNew
          loading={loading}
          list={companyList?.length > 0 ? companyList : []}
          columns={columns}
          setSelectedRowsData={setSelectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          checkBoxSelection={true}
          pageData={pageData}
          setPageData={setPageData}
          companyListTotalCount={companyListTotalCount}
          setCompanyList={setCompanyList}
        />
      </>

      <PlacementCyclesDialog
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        collegeToPlacementCycle={collegeToPlacementCycle}
      />
      {openOfferTable && (
        <OfferLetter
          open={openOfferTable}
          jobId={offerTableData?.jobId}
          handleClose={() => {
            setOpenOfferTable(false);
          }}
        />
      )}
    </div>
  );
}
