import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const columns = [
  // { field: "id", headerName: "No", width: 100 },

  {
    field: "id",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "65px",
        }}
      >
        College Code
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.id}
      </span>
    ),
  },

  {
    field: "college",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "65px",
        }}
      >
        College Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Link to="/university" style={{ textDecoration: "none" }}>
          <Typography color="primary" style={{ fontSize: "14px" }}>
            {params.row.college}
          </Typography>
        </Link>
      </span>
    ),
  },

  {
    field: "date",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "40px",
        }}
      >
        Created Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.date}
      </span>
    ),
  },
  {
    field: "link",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "50px",
        }}
      >
        e-Registration Link
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        <Link to="/university" style={{ textDecoration: "none" }}>
          <Button color="primary">Copy</Button>
        </Link>
      </span>
    ),
  },
  {
    field: "placed",
    type: "text",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "65px",
        }}
      >
        Action
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        <Link to="/university" style={{ textDecoration: "none" }}>
          <Button color="primary">View</Button>
        </Link>
      </span>
    ),
  },
];

const rows = [
  {
    id: 101,
    college: "Maharaja Surajmal Institute",
    ejdtitle: "Amazon",
    date: "02-08-2021",
  },
  {
    id: 102,
    college: "Maharaja Surajmal Institute",
    ejdtitle: "Amazon",
    date: "02-08-2021",
  },
  {
    id: 103,
    college: "Maharaja Surajmal Institute",
    ejdtitle: "Amazon",
    date: "02-08-2021",
  },
  {
    id: 104,
    college: "Maharaja Surajmal Institute",
    ejdtitle: "Amazon",
    date: "02-08-2021",
  },
];

export default function UniversityCompanyLinkTable() {
  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
        }}
        rows={rows}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
      />
    </div>
  );
}
