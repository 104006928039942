import React from 'react';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    FormControl,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: "auto",
    },
    appbar: {
        backgroundColor: "#fff",
        boxShadow: "none",
        borderBottom: "1px solid #6C757D",
        color: "#000",
        width: "300px",
    },
    checkboxroot: {
        paddingLeft: 25,
        marginTop: 80,
    },
});


const ShowMore = ({ heading, data, handleChange, isCheckboxSelected, paramName }) => {

    const classes = useStyles();
    const [state, setState] = React.useState({
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [menuName, setMenuName] = React.useState(null);

    const list = (anchor, dataList) => {
        const clickListener = (text) => {
            if (!menuName) {
                return setMenuName(text);
            } else {
                return alert(`${text} clicked`);
            }
        };
        // console.log("flag", data);
        return (
            <div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === "top" || anchor === "bottom",
                })}
                role="presentation"
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <>
                    <AppBar position="fixed" className={classes.appbar}>
                        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h6" style={{ marginLeft: "2px" }}>
                                {heading}
                            </Typography>
                            <IconButton
                                onKeyDown={toggleDrawer(anchor, false)}
                                onClick={toggleDrawer(anchor, false)}
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.checkboxroot}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                {data.map((item) => {
                                    return (
                                        <FormControlLabel
                                            key={item?.id}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    size="small"
                                                    checked={isCheckboxSelected?.some((obj) => obj?.id === item?.id)}
                                                    onChange={(e) => {
                                                        handleChange(paramName, item, "multiple");
                                                    }}
                                                />
                                            }
                                            label={item?.name}
                                        />
                                    );
                                })}
                            </FormGroup>
                        </FormControl>
                    </div>
                </>
            </div>
        );

    }
    return (
        <div>
            {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)} color="primary" variant="text">
                        Show All {`(${data?.length})`}
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {menuName && (
                            <ListItem button onClick={() => setMenuName(null)}>
                                <ListItemText primary="Back to main menu" />
                                <ChevronLeftIcon />
                            </ListItem>
                        )}
                        {list(anchor, data)}
                    </Drawer>
                </React.Fragment>
            ))}

        </div>
    )
}

export default ShowMore