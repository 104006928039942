import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { isGradeSystemFn } from "../../../../api/Domain/selector";
import DegreeSpecializationCustom from "../../../DegreeSpecializationCustom";
import Editor from "../../../Editor";
import Calendar from "../../../Input/Calendar";
import { AddCircleOutlineTwoTone } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import UploadDoc from "../../../Input/UploadDoc/UploadDoc";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "auto",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    padding: "15px",
    backgroundColor: "#fff",
  },
  leftpaper: {
    height: "auto",
    boxShadow: "none",
    padding: "20px",

    paddingLeft: "35px",
    paddingRight: "35px",
  },
  rightpaper: {
    height: "auto",
    boxShadow: "none",
    // padding: "15px",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  helper: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //padding: theme.spacing(1, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  include: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textfield: {
    padding: "16px",
  },
  addbtn: {
    height: "48px",
    width: "100%",
    borderRadius: "8px",
    boxShadow: "none",
  },
  customize: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  upload: {
    "@media only screen and (max-width: 2000px)": {
      marginBottom: "50px",
      marginTop: "20px",
    },
    "@media only screen and (max-width: 1700px)": {
      marginBottom: "50px",
      marginTop: "20px",
    },
    "@media only screen and (max-width: 1300px)": {
      marginBottom: "70px",
      marginTop: "20px",
    },
  },
  locupload: {
    "@media only screen and (max-width: 2000px)": {
      /// marginBottom: "50px",
      // marginTop: "20px",
    },
    "@media only screen and (max-width: 1700px)": {
      //marginBottom: "50px",
      // marginTop: "20px",
    },
    "@media only screen and (max-width: 1300px)": {
      marginBottom: "70px",
      marginTop: "20px",
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default function CompanyInfo({
  title,
  dataArray,
  localFieldList,
  setLocalFieldList,
  userRolesPermission,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const [editor, setEditor] = React.useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const [groupFieldInnerList, setGroupFieldInnerList] = useState();
  const [dataSelected, setDataSelected] = useState([]);
  const [specializationInDegreeId, setSpecializationInDgegreeId] = useState([]);
  const [pushArray, setPushArray] = useState([]);
  const isGradeSystem = useSelector(isGradeSystemFn);

  const coursesArray = [
    {
      education_id: 1,
      education_name: "Graduation",
      degrees: [
        {
          degree_id: 1,
          degree_name: "B.Tech",
          select_all: false,
          specialization: [
            {
              degree_id: 1,
              specialization_id: 1,
              specialization_name: "Computer Science",
              selected: false,
            },
            {
              degree_id: 1,
              specialization_id: 2,
              specialization_name: "Electronics and Communication Engineering",
              selected: false,
            },
          ],
        },
        {
          degree_id: 2,
          degree_name: "B.Com",
          select_all: false,
          specialization: [
            {
              degree_id: 2,
              specialization_id: 3,
              specialization_name: "Economics",
              selected: false,
            },
            {
              degree_id: 2,
              specialization_id: 4,
              specialization_name: "Marketing",
              selected: false,
            },
          ],
        },
      ],
    },
    {
      education_id: 2,
      education_name: "Post Graduation",
      degrees: [
        {
          degree_id: 3,
          degree_name: "M.Tech",
          select_all: false,
          specialization: [
            {
              degree_id: 3,
              specialization_id: 5,
              specialization_name: "Computer Science",
              selected: false,
            },
            {
              degree_id: 3,
              specialization_id: 6,
              specialization_name: "Electronics and Communication Engineering",
              selected: false,
            },
          ],
        },
        {
          degree_id: 4,
          degree_name: "M.Com",
          select_all: false,
          specialization: [
            {
              degree_id: 4,
              specialization_id: 7,
              specialization_name: "Economics",
              selected: false,
            },
            {
              degree_id: 4,
              specialization_id: 8,
              specialization_name: "Marketing",
              selected: false,
            },
          ],
        },
      ],
    },
  ];

  const handleSelectionChange = (e, item) => {
    var arr = [...localFieldList];
    var index = arr.findIndex((rank) => rank.id === item.id);
    if (index !== -1) {
      item.is_selected = e.target.checked;
      arr[index] = item;
      setLocalFieldList(arr);
    }
  };

  useEffect(() => {
    if (dataArray?.length > 0) {
      var result = dataArray.reduce(function (r, a) {
        if (a.section) {
          r[a.section] = r[a.section] || [];
          r[a.section].push(a);
        } else {
          r["top"] = r[["top"]] || [];

          r["top"].push(a);
        }

        return r;
      }, Object.create(null));
      setGroupFieldInnerList(result);
    }
  }, [dataArray]);

  const checkChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  function getFieldContent(step, item, index) {
    switch (item.field_type) {
      case "job_type":
        return (
          <>
            <Grid item xs={9}>
              <div
                className={clsx(classes.column, classes.helper)}
                style={{ marginBottom: "-20px" }}
              >
                <Paper className={classes.rightpaper}>
                  <div>
                    <FormControl
                      component="fieldset"
                      disabled={item.is_selected}
                    >
                      {/* <FormLabel component="legend">Gender</FormLabel> */}

                      <RadioGroup
                        defaultValue="female"
                        aria-label="gender"
                        name="customized-radios"
                        className={classes.customize}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginRight: "10px", fontWeight: "bold" }}
                        >
                          Job Type
                        </Typography>
                        <FormControlLabel
                          value="Full-Time"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2"> Full-Time</Typography>
                          }
                        />
                        <FormControlLabel
                          value="Internship"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2"> Internship</Typography>
                          }
                        />
                        {/* <FormControlLabel value="Part-Time" control={<StyledRadio />} label={<Typography variant="body2"> Part-Time</Typography>} /> */}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "autocomplete":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    disabled={item.is_selected}
                  >
                    <InputLabel
                      required
                      className={classes.floatingLabelFocusStyle}
                    >
                      {item.field_name}
                    </InputLabel>
                    <Select
                      // value={state.job}
                      // onChange={handleChange}
                      label={item.field_name}
                      disabled
                      inputProps={{
                        style: {
                          padding: "16px",
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                        },
                      }}
                    >
                      <MenuItem value={10}>Demo 1</MenuItem>
                      <MenuItem value={20}>Demo 2</MenuItem>
                      <MenuItem value={30}>Demo 3</MenuItem>
                    </Select>
                  </FormControl>
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "textfield":
        return (
          <>
            <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    disabled={item.is_selected}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    inputProps={{ className: classes.textfield }}
                    id="title"
                    label={item.field_name}
                    name="Title"
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "application_date":
        return (
          <>
            <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid container spacing={1} style={{ marginTop: "10px" }}>
                    <Grid item xs>
                      <Calendar title="Application Start Date" disabled />
                    </Grid>
                    <Grid item xs>
                      <Calendar title="Application End Date" disabled />
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "date_single":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Calendar title="Joining Date" disabled />
                </Paper>
              </div>
            </Grid>
          </>
        );
       case "campus_drive_date":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Calendar title="Campus Drive Date" disabled/>
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "date":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Calendar title={item.field_name} disabled />
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "spoc":
        return (
          <>
            <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Typography variant="h6" style={{ paddingTop: "25px" }}>
                    SPOC Details (1/3)
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        fullWidth
                        inputProps={{
                          className: classes.textfield,
                        }}
                        id="full name"
                        label="Full Name"
                        name="full name"
                        autoComplete="title"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        inputProps={{
                          className: classes.textfield,
                        }}
                        id="email"
                        label="Email ID"
                        name="email"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        required
                        fullWidth
                        inputProps={{
                          className: classes.textfield,
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        label="Phone Number"
                        name="phone number"
                      />
                    </Grid>
                    <Button
                      style={{
                        display: "flex",
                        marginTop: "-10px",
                        //marginBottom: "20px",
                      }}
                      startIcon={<AddCircleOutlineIcon />}
                      color="primary"
                    >
                      Add Another SPOC
                    </Button>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "job-desc":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Typography variant="h6" style={{ paddingTop: "25px" }}>
                    Job Description*
                  </Typography>
                  {/* <MyEditor /> */}
                  <div className={classes.upload}>
                    <Editor state={editor} setState={setEditor} />
                  </div>
                </Paper>
              </div>
            </Grid>
            <br />
          </>
        );

      case "job-desc-docs":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <UploadDoc disabled={true}/>
                </Paper>
              </div>
            </Grid>
            <br />
          </>
        );

      case "ctc_range":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Typography variant="h6" style={{ paddingTop: "25px" }}>
                    CTC Range
                  </Typography>

                  <div style={{ display: "flex" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      disabled={item.is_selected}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{ className: classes.textfield }}
                      label="Minimum"
                      name="Minimum"
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      disabled={item.is_selected}
                      style={{ marginLeft: "20px" }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{ className: classes.textfield }}
                      label="Maximum"
                      name="Maximum"
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={checkChange}
                        name="checkedB"
                        size="small"
                        color="primary"
                        disabled={item.is_selected}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        {" "}
                        Including Bonuses
                      </Typography>
                    }
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "editor":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div
                className={clsx(classes.column, classes.helper)}
                style={{ marginBottom: 30 }}
              >
                <Paper className={classes.rightpaper}>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    {item.field_name}
                  </Typography>
                  {/* <MyEditor /> */}
                  <div className={classes.locupload}>
                    <Editor state={editor} setState={setEditor} />
                  </div>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "location":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                      paddingTop: "15px",
                    }}
                  >
                    <Grid item xs={9}>
                      {" "}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Location
                        </InputLabel>
                        <Select
                          value={state.job}
                          onChange={handleChange}
                          label=" Location"
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <MenuItem value={10}>New Delhi</MenuItem>
                          <MenuItem value={20}>Mumbai</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{ display: "grid" }}>
                      <Button
                        variant="contained"
                        className={classes.addbtn}
                        color="primary"
                        disabled={item.is_selected}
                      >
                        Add Location
                      </Button>
                    </Grid>
                  </Grid>
                  <FormControl component="fieldset" disabled={item.is_selected}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="Pan"
                        control={<Radio color="primary" />}
                        // label="Pan India"
                        label={
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            PAN India
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={checkChange}
                        size="small"
                        name="checkedB"
                        color="primary"
                        disabled={item.is_selected}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        Work From Home Available
                      </Typography>
                    }
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "vacancy":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    disabled={item.is_selected}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ className: classes.textfield }}
                    label="Number of Vacancies"
                    name="Number of Vacancies"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        size="small"
                        onChange={checkChange}
                        name="checkedB"
                        color="primary"
                        disabled={item.is_selected}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        Include Service Agreement/ Bond
                      </Typography>
                    }
                  />
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Conditions Text
                  </Typography>
                  {/* <MyEditor /> */}
                  <div className={classes.upload}>
                    <Editor state={editor} setState={setEditor} />
                  </div>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "duration":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    disabled={item.is_selected}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ className: classes.textfield }}
                    label="Duration(in months)"
                    name="Duration(in months)"
                    // disabled
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "ppo":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={checkChange}
                        size="small"
                        name="checkedB"
                        color="primary"
                        disabled={item.is_selected}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        PPO After Internship
                      </Typography>
                    }
                  />
                  <Typography variant="h6" style={{ marginTop: "10px" }}>
                    CTC Range
                  </Typography>

                  <div style={{ display: "flex" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      disabled={item.is_selected}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{ className: classes.textfield }}
                      label="Minimum"
                      name="Minimum"
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ marginLeft: "20px" }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      disabled={item.is_selected}
                      inputProps={{ className: classes.textfield }}
                      label="Maximum"
                      name="Maximum"
                    />
                  </div>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Salary Breakup
                  </Typography>
                  {/* <MyEditor /> */}
                  <Editor state={editor} setState={setEditor} />
                  <br />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "courses":
        return (
          <>
            <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={9}>
                      <DegreeSpecializationCustom
                        data={dataSelected}
                        setData={setDataSelected}
                        specialization={specializationInDegreeId}
                        setSpecialization={setSpecializationInDgegreeId}
                        coursesArray={coursesArray}
                        pushArray={pushArray}
                        setPushArray={setPushArray}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {/* <Button variant="contained" style={{ borderRadius: "30px", boxShadow: "none" }} color="primary">
                      Add Course
                    </Button> */}
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "graduation_grade":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "20px" }}
                >
                  {/* <div>
                    {" "}
                    <FormControl disabled={item.is_selected}>

                      <RadioGroup
                        defaultValue="female"
                        aria-label="gender"
                        name="customized-radios"
                        className={classes.customize}
                        disabled={item.is_selected}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginRight: "10px", fontWeight: "bold" }}
                        >
                          Scoring System Graduation*
                        </Typography>
                        <FormControlLabel
                          value="CGPA"
                          control={<StyledRadio />}
                          label={<Typography variant="body2">CGPA</Typography>}
                        />
                        <FormControlLabel
                          value="Percentage"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">Percentage</Typography>
                          }
                        />
                        <FormControlLabel
                          value="GPA"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">
                              GPA out of 4
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                   */}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    style={{ width: "50%" }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    disabled
                    inputProps={{ className: classes.textfield }}
                    label={`Minimum ${
                      isGradeSystem ? "CGPA" : "Percentage"
                    } Required (Current Education)`}
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "school_grade":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  {/* <div style={{ paddingTop: "20px" }}>
                    {" "}
                    <FormControl disabled={item.is_selected}>
                      

                      <RadioGroup
                        defaultValue="female"
                        aria-label="gender"
                        name="customized-radios"
                        className={classes.customize}
                        disabled={item.is_selected}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginRight: "10px", fontWeight: "bold" }}
                        >
                          Scoring System 12th Grade
                        </Typography>
                        <FormControlLabel
                          value="CGPA"
                          control={<StyledRadio />}
                          label={<Typography variant="body2">CGPA</Typography>}
                        />
                        <FormControlLabel
                          value="Percentage"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">Percentage</Typography>
                          }
                        />
                        <FormControlLabel
                          value="GPA"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">
                              GPA out of 4
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                   */}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    disabled
                    style={{ width: "50%" }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ className: classes.textfield }}
                    label="Minimum Score Required in 12th Grade(Percentage)"
                  />
                </Paper>
                <Paper className={classes.rightpaper}>
                  {/* <div>
                    {" "}
                    <FormControl disabled={item.is_selected}>
                      

                      <RadioGroup
                        defaultValue="female"
                        aria-label="gender"
                        name="customized-radios"
                        className={classes.customize}
                        disabled={item.is_selected}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginRight: "10px", fontWeight: "bold" }}
                        >
                          Scoring System 10th Grade
                        </Typography>
                        <FormControlLabel
                          value="CGPA"
                          control={<StyledRadio />}
                          label={<Typography variant="body2">CGPA</Typography>}
                        />
                        <FormControlLabel
                          value="Percentage"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">Percentage</Typography>
                          }
                        />
                        <FormControlLabel
                          value="GPA"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">
                              GPA out of 4
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                   */}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    disabled
                    style={{ width: "50%" }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ className: classes.textfield }}
                    label="Minimum Score Required in 10th Grade(Percentage)"
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "batch":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={9}>
                      {" "}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Eligible Batches
                        </InputLabel>
                        <Select
                          // value={state.job}
                          // onChange={handleChange}
                          label="Eligible Batches"
                          disbaled
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          {/* <MenuItem value={10}>2018</MenuItem>

                          <MenuItem value={10}>2019</MenuItem>
                          <MenuItem value={20}>2020</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{ display: "grid" }}>
                      <Button
                        variant="contained"
                        className={classes.addbtn}
                        color="primary"
                        disabled
                      >
                        Add Batch
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "selection":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={9}
                      className={classes.dropdowngrid}
                      style={{ marginTop: "10px" }}
                    >
                      {" "}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Job Round Name
                        </InputLabel>
                        <Select
                          // value={state.job}
                          // onChange={handleChange}
                          disabled
                          label="Job Round Name"
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          {/* <MenuItem value={10}>Manager Round</MenuItem>
                          <MenuItem value={20}>Aptitude</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        className={classes.addbtn}
                        color="primary"
                        disabled
                      >
                        Add Process
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  <form
                    className={classes.container}
                    style={{ paddingTop: "10px" }}
                    noValidate
                  >
                    <TextField
                      id="time"
                      variant="outlined"
                      label="Duration of the Round"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      disabled={item.is_selected}
                      type="time"
                      inputProps={{ className: classes.textfield }}
                      defaultValue="12:00"
                      className={classes.textField}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      style={{ width: "40%" }}
                      // inputProps={{
                      //   step: 300, // 5 min
                      // }}
                    />
                  </form>
                  <div style={{ marginTop: "15px" }}>
                    {" "}
                    <FormControl component="fieldset">
                      <RadioGroup
                        defaultValue="female"
                        aria-label="gender"
                        name="customized-radios"
                        className={classes.customize}
                        disabled={item.is_selected}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            marginRight: "10px",

                            fontWeight: "bold",
                          }}
                        >
                          Venue Type:
                        </Typography>
                        <FormControlLabel
                          value="Online"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2"> Online</Typography>
                          }
                        />
                        <FormControlLabel
                          value="Offline"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">Offline</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <TextField
                    className={classes.formControl}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    disabled
                    inputProps={{ className: classes.textfield }}
                    label="Link for the Meeting"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={state.checkedB}
                        onChange={checkChange}
                        name="checkedB"
                        color="primary"
                        disabled
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        Documents Required
                      </Typography>
                    }
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "skills":
        return (
          <>
            <Grid
              item
              xs={9}
              className={classes.dropdowngrid}
              //style={{ marginTop: "20px" }}
            >
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={9}>
                      {" "}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Skills
                        </InputLabel>
                        <Select
                          value={state.job}
                          onChange={handleChange}
                          label="Skills Required"
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <MenuItem value={10}>C</MenuItem>
                          <MenuItem value={20}>C++</MenuItem>
                          <MenuItem value={20}>Management</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );

      default:
        return <></>;
    }
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography
          variant="h6"
          style={{ marginLeft: "70px", marginTop: "20px" }}
        >
          {title}
        </Typography>
        <Grid container spacing={0}>
          {groupFieldInnerList &&
            Object.keys(groupFieldInnerList)?.length > 0 &&
            Object.keys(groupFieldInnerList).map((item) => (
              <>
                {item !== "company" && (
                  <>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                      <div className={clsx(classes.column, classes.helper)}>
                        <Typography
                          variant="h6"
                          style={{ marginTop: "10px", marginLeft: 40 }}
                        >
                          {item}
                        </Typography>
                      </div>
                    </Grid>
                  </>
                )}
                {groupFieldInnerList[item]?.length > 0 &&
                  groupFieldInnerList[item]?.map((item2, index2) => (
                    <>
                      <Grid item xs={3}>
                        <Paper className={classes.leftpaper}>
                          {" "}
                          <FormControlLabel
                            className={classes.include}
                            value="end"
                            control={
                              <Checkbox
                                size="small"
                                color="primary"
                                checked={item2?.is_selected}
                                onChange={(e) => {
                                  if (!item2?.pre_selected)
                                    handleSelectionChange(e, item2, index2);
                                }}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                style={{ fontWeight: "bold" }}
                              >
                                {" "}
                                Include in e-JNF
                              </Typography>
                            }
                            labelPlacement="end"
                            disabled={item2?.pre_selected}
                            // disabled={
                            //   userRolesPermission?.some(
                            //     (item) =>
                            //       item.access_control_key ===
                            //       "manage_ejnf_forms"
                            //   ) === true
                            //     ? false
                            //     : true
                            // }
                          />
                        </Paper>
                      </Grid>
                      {getFieldContent(item2.field_type, item2, index2)}
                    </>
                  ))}

                {/* <Grid item xs={3}>
                <Paper className={classes.leftpaper}>
                  {" "}
                  <FormControlLabel
                    className={classes.include}
                    value="end"
                    control={<Checkbox size="small" color="primary" />}
                    label={
                      <Typography variant="body2" style={{ fontWeight: "bold" }}>
                        {" "}
                        Include in e-JD
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Paper>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className={clsx(classes.column, classes.helper)}>
                  <Paper className={classes.rightpaper}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel className={classes.floatingLabelFocusStyle} htmlFor="outlined-age-native-simple" InputProps={{ classes: { root: classes.inputRoot } }}>
                        Select Industry*
                      </InputLabel>
                      <Select
                        native
                        value={state.industry}
                        onChange={handleChange}
                        label="Select Industry"
                        inputProps={{
                          style: {
                            padding: "16px",
                            backgroundColor: "#fff",
                            borderRadius: "30px",
                          },
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value={10}>Ten</option>
                        <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option>
                      </Select>
                    </FormControl>
                  </Paper>
                </div>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.leftpaper}>
                  {" "}
                  <FormControlLabel
                    value="end"
                    className={classes.include}
                    control={<Checkbox size="small" color="primary" />}
                    label={
                      <Typography variant="body2" style={{ fontWeight: "bold" }}>
                        {" "}
                        Include in e-JD
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Paper>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className={clsx(classes.column, classes.helper)}>
                  <Paper className={classes.rightpaper}>
                    <Calendar title="Tentative Date" />
                  </Paper>
                </div>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.leftpaper}>
                  {" "}
                  <FormControlLabel
                    value="end"
                    className={classes.include}
                    control={<Checkbox size="small" color="primary" />}
                    label={
                      <Typography variant="body2" style={{ fontWeight: "bold" }}>
                        {" "}
                        Include in e-JD
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Paper>
              </Grid>
              <Grid
                item
                xs={9}
                // style={{
                //   display: "flex",
                //   flexDirection: "column",
                //   justifyContent: "center",
                // }}
              >
                <div className={clsx(classes.column, classes.helper)}>
                  <Paper className={classes.rightpaper}>
                    <Typography variant="h6" style={{ marginTop: "10px" }}>
                      SPOC Details (1/3)
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                          fullWidth
                          inputProps={{
                            className: classes.textfield,
                          }}
                          id="full name"
                          label="Full Name"
                          name="full name"
                          autoComplete="title"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                          inputProps={{
                            className: classes.textfield,
                          }}
                          id="email"
                          label="Email ID"
                          name="email"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                          required
                          fullWidth
                          inputProps={{
                            className: classes.textfield,
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          label="Phone Number"
                          name="phone number"
                        />
                      </Grid>
                      <Button
                        style={{
                          display: "flex",
                          marginTop: "-10px",
                          marginBottom: "20px",
                        }}
                        startIcon={<AddCircleOutlineIcon />}
                        color="primary"
                      >
                        Add Another SPOC
                      </Button>
                    </Grid>
                  </Paper>
                </div>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.leftpaper}>
                  {" "}
                  <FormControlLabel
                    className={classes.include}
                    value="end"
                    control={<Checkbox size="small" color="primary" />}
                    label={
                      <Typography variant="body2" style={{ fontWeight: "bold" }}>
                        {" "}
                        Include in e-JD
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Paper>
              </Grid>
              <Grid item xs={9}>
                <div className={clsx(classes.column, classes.helper)}>
                  <Paper className={classes.rightpaper}>
                    <Typography variant="h6" style={{ marginBottom: "10px" }}>
                      About Company*
                    </Typography>
                    <Editor state={editor} setState={setEditor} />
                  </Paper>
                </div>
              </Grid> */}
              </>
            ))}
        </Grid>
      </Paper>
    </>
  );
}
