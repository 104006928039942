import {
  Button, CircularProgress, Grid, Link, TextField, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ForgotPasswordAPI } from "../../../api/ForgotPassword/action";
import { emailRegex } from "../../../utility/regex";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    width: "60%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginTop: "25px",
  },
  submit: {
    borderRadius: "8px",
    margin: theme.spacing(3, 0, 2),
    boxShadow: "none",
  },
  paper2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  text: {
    textAlign: "center",
    width: "90%",
    display: "contents",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    password: "",

    showPassword: false,
  });
  const validateData = () => {
    var email_error = null;
    var is_valid = true;
    if (!state) {
      email_error = "Email field cannot be left blank";
      is_valid = false;
    }
    if (state && !emailRegex.test(state)) {
      email_error = "Please enter a valid email";
      is_valid = false;
    }

    setErrors(email_error);
    return is_valid;
  };

  const submitData = () => {
    if (validateData()) {
      dispatch(ForgotPasswordAPI(state, setLoading, setErrors, alert, history));
    }
  };

  return (
    <>
      <div className={classes.paper2}>
        <div className={classes.paper}>
          <div className={classes.text}>
            <Typography
              component="h1"
              variant="body2"
              style={{ color: "#6c757d" }}
            >
              If there's a password needed at the gates of heaven, only Latin
              will unlock it, he thinks We’ll help you reset it and get back on
              track.
            </Typography>
            <br />
            <Typography component="h1" variant="h6" color="inherit">
              We’ll help you reset it and get back on track.
            </Typography>
          </div>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              value={state}
              onChange={e => {
                setState(e.target.value);
                setErrors(null);
              }}
              id="email"
              label="Your Email"
              name="email"
              autoComplete="email"
              error={errors ? true : false}
              helperText={<span>{errors}</span>}
              onKeyPress={data => {
                if (data.charCode === 13) {
                  submitData();
                }
              }}
            />
            {/* <Link href="/resetlink" style={{ textDecoration: "none" }}> */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submitData}
            >
              {loading ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : (
                <>Send Reset link</>
              )}
            </Button>
            {/* </Link> */}
            <Grid container>
              <Grid item xs>
                <Link href="/sign-in" style={{ textDecoration: "none" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ArrowBackIosIcon style={{ fontSize: "14px" }} />
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: 600, marginLeft: "4px" }}
                    >
                      Back to Sign In
                    </Typography>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
}
