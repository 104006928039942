import { COMPANY_UPLOADER_FIELDS, SELECTED_COMPANY_UPLOADER_FIELDS } from "./action";

export const CompanyUploader = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_UPLOADER_FIELDS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const CompanyFields = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_COMPANY_UPLOADER_FIELDS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};
