//Imports
import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
import { TEAM_MEMBER_DETAILS } from "../SignIn/action";
//Exports
export const ACCESS_CONTROLS = "ACCESS_CONTROLS";
export const USER_ROLES_ALL = "USER_ROLES_ALL";

export const AccountSettingUpdateAPI = (data, setLoading, alert, userId, location, history, file) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .patch(BackendBaseApi.SANCHALAN + "api/settings/account/" + userId + "/", data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      // alert.success(res.data.data.message);
      // var arr = [...list];
      // arr.push(res.data.data.data);
      // dispatch({
      //   type: USER_ROLES_ALL,
      //   payload: arr,
      // });
      dispatch({
        type: TEAM_MEMBER_DETAILS,
        payload: res.data.data.data,
      });
      alert.success(res.data.data.message);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};
