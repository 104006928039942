import { Button, Divider } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AssignmentOutlinedIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOn";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBook";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SchoolOutlinedIcon from "@material-ui/icons/School";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CallCollegeAPI } from "../../../../api/University/APIs/action";
import collegelogo from "../../../../assets/png/default_college.png";
import { GroupViewCheck } from "../../../../api/Common/action";
import { ReactComponent as Location } from "../../../../assets/svg/postjob2.svg";
import { ReactComponent as Doc } from "../../../../assets/svg/postjob3.svg";
import { ReactComponent as Building } from "../../../../assets/svg/postjob1.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
    boxShadow: "rgb(0 0 0 / 5%) 0px 3px 24px",
    borderRadius: "8px",
  },
  paperchild: {
    padding: theme.spacing(2),
  },
  image: {
    width: 100,
    height: 100,
  },
  img: {
    margin: "auto",
    display: "block",

    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: 8,
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  colorChange: {
    color: theme.palette.secondary.main,
  },
}));

export default function UplacementCycle({
  item,
  countTitle,
  count,
  link,
  linkTitle,
  from,
  secondaryCount,
  secondaryCountTitle,
  thirdCount,
  thirdCountTitle,
  fourthCount,
  fourthCountTitle,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  const checkIcon = () => {
    switch (from) {
      case "Placement":
        return <DateRangeOutlinedIcon color="secondary" />;
      case "Company":
        return <BusinessIcon color="secondary" />;
      case "Jobs":
        return <PeopleIcon color="secondary" />;
      case "EJD":
        return <DescriptionIcon color="secondary" />;
      case "Interviews":
        return <SubjectIcon color="secondary" />;
      case "Assignments":
        return <AssignmentOutlinedIcon color="secondary" />;
      case "UserRoles":
        return <PersonIcon color="secondary" />;
      case "PostJob":
        return <DescriptionIcon color="secondary" />;
      case "Teams":
        return <PeopleIcon color="secondary" />;
      case "Courses":
        return <SchoolOutlinedIcon color="secondary" />;
      case "PublicPage":
        return <BusinessIcon color="secondary" />;
      case "Billing":
        return <ReceiptIcon color="secondary" />;
      default:
        return <BusinessIcon color="secondary" />;
    }
  };

  const checkSecondaryIcon = () => {
    switch (from) {
      case "Jobs":
        return <DescriptionIcon color="secondary" />;
      case "Students":
        return <DescriptionIcon color="secondary" />;
      case "Courses":
        return <MenuBookOutlinedIcon color="secondary" />;
      case "Billing":
        return <LocalAtmIcon color="secondary" />;

      default:
        return <BusinessIcon color="secondary" />;
    }
  };

  const Data = [
    {
      icon: (
        <Building
          height="23px"
          width="23px"
          fill={theme.palette.secondary.main}
        />
      ),
      info: item.name,
      show: true,
    },
    {
      icon: (
        <Location
          height="23px"
          width="23px"
          fill={theme.palette.secondary.main}
        />
      ),
      info:
        item?.city && item?.state
          ? item?.city + ", " + item.state
          : item?.city
          ? item?.city
          : "NA",
      show: true,
    },

    {
      icon: checkIcon(),
      info: `${countTitle}: ${count}`,
      show: from.includes("ERF") ? false : true,
    },
    {
      icon: checkSecondaryIcon(),
      info: `${secondaryCountTitle} : ${secondaryCount}`,
      show:
        from === "Jobs" ||
        from === "Students" ||
        from === "Courses" ||
        from === "Billing" ||
        from === "Company"
          ? true
          : false,
    },
    {
      icon: checkSecondaryIcon(),
      info: `${thirdCountTitle} : ${thirdCount}`,
      show: from === "Company" || from === "Students" ? true : false,
    },
    {
      icon: checkSecondaryIcon(),
      info: `${fourthCountTitle} : ${fourthCount}`,
      show: from === "Students" ? true : false,
    },
  ];

  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  const handleClick = () => {
    dispatch(
      CallCollegeAPI(
        item?.id,
        alert,
        history,
        location,
        user_details,
        false,
        false,
        setLoader,
        setApiSuccess
      )
    );
  };

  useEffect(() => {
    if (apiSuccess) {
      dispatch(GroupViewCheck(true));
      history.push(link);
    }
  }, [apiSuccess]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.paperchild}>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase className={classes.image}>
                <img
                  className={classes.img}
                  alt="complex"
                  src={item?.logo ? item?.logo : collegelogo}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid container spacing={2}>
                {Data.map(
                  (item) =>
                    item?.show && (
                      <Grid item xs={12}>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item className={classes.grid}>
                            {item.icon}
                          </Grid>
                          <Grid item xs className={classes.grid}>
                            <Typography
                              variant="body2"
                              color="secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {item.info}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className={classes.paperchild}>
          {from !== "PublicProfile" ? (
            <Button
              color="primary"
              style={{ display: "flex" }}
              endIcon={<ChevronRightIcon />}
              onClick={() => {
                handleClick();
              }}
            >
              {linkTitle}
            </Button>
          ) : (
            <Link to={link} target="_blank">
              <Button
                color="primary"
                style={{ display: "flex" }}
                endIcon={<ChevronRightIcon />}
              >
                {linkTitle}
              </Button>
            </Link>
          )}
        </div>
      </Paper>
    </div>
  );
}
