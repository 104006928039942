//Imports
import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
//Exports
export const SELECTED_STUDENT_UPLOADER_FIELDS = "SELECTED_STUDENT_UPLOADER_FIELDS";
export const SELECTED_STUDENT_UPLOADER_FIELDS_PUBLIC = "SELECTED_STUDENT_UPLOADER_FIELDS_PUBLIC";

export const STUDENT_UPLOADER_FIELDS = "STUDENT_UPLOADER_FIELDS";

//Access Control Api Call Action
export const StudentUploaderFieldsAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "api/uploader_form/student/", {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: STUDENT_UPLOADER_FIELDS,
      payload: res.data.data,
    });
  };
};

//Student Form Selected Fields
export const StudentSelectedFieldsAPI = (setLoading, alert, history, location, collegeId) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "api/uploader_form/student/selected_fields/?college_id=" + collegeId, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_STUDENT_UPLOADER_FIELDS,
      payload: res.data.data,
    });
  };
};

// Students Field Post Api
export const StudentFieldsPostAPI = (data, setLoading, alert, history, location, collegeId, setInviteCall) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "api/uploader_form/student/selected_fields/?college_id=" + collegeId, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("Updated Successfully");
      setInviteCall(true);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const StudentFormPostAPI = (data, setLoading, alert, history, location, link) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}public/company_student_form/?link=${link}`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error("Some Error Occurred!!");
        }
        throw error;
      });

    if (res.data.success) {
      alert.success("Student Created Successfully!");
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const StudentSelectedFieldsPublicAPI = (setLoading, alert, history, location, link) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}public/company_student_form/?link=${link}`, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (!res.data.success) {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_STUDENT_UPLOADER_FIELDS_PUBLIC,
      payload: res.data.data,
    });
  };
};
