import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../../api/Domain/selector";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";

const useStyles = makeStyles((theme) => ({
  lay: {
    width: "100%",
    "@media only screen and (min-width: 2000px)": {
      height: "600px",
    },
    "@media only screen and (max-width: 2000px)": {
      height: "400px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "310px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "230px",
    },
  },
}));

export default function GroupTeamTable({
  loading,
  list,
  columns,
  setSelectedRowsData,
  selectedRowData,
  selectedID,
  setSelectedID,
  filterDataText,
}) {
  const theme_change = useSelector(themeSelectorFn);
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState(20);

  return (
    <div className={classes.lay}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
        }}
        rows={list?.length ? list : []}
        columns={columns}
        selectionModel={selectedID}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);

          const selectedRowData = list.filter((row) => selectedIDs.has(row.id));
          setSelectedRowsData(selectedRowData);
          setSelectedID(ids);
        }}
        disableColumnSelector
        checkboxSelection
        disableSelectionOnClick
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        pagination
        rowCount={list?.length}
        loading={loading}
        componentsProps={{
          pagination: {
            style: {
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            },
          },
        }}
        components={{
          NoRowsOverlay: () => (
            <>
              <br /> <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  paddingTop: 30,
                }}
              >
                <NoData
                  height="180px"
                  width="180px"
                  alt="404 Error"
                  fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                />

                <span align="center" style={{ fontSize: 14, color: "#6c757d" }}>
                  <b>
                    {" "}
                    {filterDataText ? filterDataText : "No Data Available"}
                  </b>
                </span>
              </div>
            </>
          ),
        }}
      />
    </div>
  );
}
