import { Button, Link } from "@material-ui/core";
import React from "react";
import Title from "../../../pages/Dashboard/Title";
import { LinkedInEmbed } from "react-social-media-embed";

const Linkedinvactor = (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5673_278)">
      <path
        d="M29.9062 0.773557H3.09375C2.48539 0.767365 1.89943 1.00276 1.46442 1.4281C1.02942 1.85343 0.780917 2.43396 0.773438 3.04231V29.9631C0.78227 30.5705 1.03137 31.1497 1.46622 31.574C1.90108 31.9982 2.48627 32.2329 3.09375 32.2267H29.9062C30.5146 32.2315 31.1002 31.9953 31.535 31.5697C31.9698 31.1441 32.2184 30.5637 32.2266 29.9554V3.03457C32.2157 2.42801 31.9659 1.85025 31.5314 1.42687C31.0969 1.00349 30.5129 0.768704 29.9062 0.773557Z"
        fill="#0076B2"
      />
      <path
        d="M5.42984 12.5625H10.0988V27.5852H5.42984V12.5625ZM7.76563 5.08594C8.30114 5.08594 8.82461 5.24477 9.26984 5.54233C9.71506 5.8399 10.062 6.26283 10.2668 6.75763C10.4717 7.25242 10.5251 7.79685 10.4205 8.32203C10.3158 8.84722 10.0577 9.32956 9.67887 9.70804C9.30003 10.0865 8.81744 10.3441 8.29216 10.4483C7.76688 10.5525 7.2225 10.4985 6.7279 10.2932C6.2333 10.0879 5.8107 9.74055 5.51356 9.29504C5.21642 8.84953 5.05808 8.3259 5.05859 7.79039C5.05928 7.07289 5.34478 6.38501 5.85238 5.8779C6.35997 5.37079 7.04812 5.08594 7.76563 5.08594ZM13.0276 12.5625H17.5032V14.625H17.5651C18.189 13.4442 19.7101 12.199 21.9814 12.199C26.7097 12.1887 27.5862 15.3005 27.5862 19.3352V27.5852H22.9173V20.2762C22.9173 18.536 22.8863 16.2956 20.4913 16.2956C18.0962 16.2956 17.6888 18.1931 17.6888 20.1628V27.5852H13.0276V12.5625Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5673_278">
        <rect width="33" height="33" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

function LinkedinSection() {
  return (
    <div
      style={{
        border: "1px solid #c3c7ca",
        padding: 15,
        borderRadius: 8,
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {" "}
          <div style={{ marginBottom: 5 }}>{Linkedinvactor}</div>{" "}
          <Title>@getworknow</Title>
        </div>
        <Link
          href="https://www.linkedin.com/company/getworknow/"
          target="_blank"
        >
          <Button
            color="primary"
            variant="contained"
            style={{ boxShadow: "none" }}
          >
            Follow
          </Button>
        </Link>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <LinkedInEmbed
          url="https://www.linkedin.com/embed/feed/update/urn:li:share:7172830919945388033"
          // url="https://in.linkedin.com/company/getworknow"
          //url="https://www.linkedin.com/embed/feed/update/urn:li:share:6898694772484112384"
          // postUrl="https://www.linkedin.com/posts/peterdiamandis_5-discoveries-the-james-webb-telescope-will-activity-6898694773406875648-z-D7"
          width="100%"
        />
      </div>
    </div>
  );
}

export default LinkedinSection;
