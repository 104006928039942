import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  InviteStudentLinkAPI,
  PlacementListAPI,
} from "../../api/Common/action";
import { isGradeSystemFn } from "../../api/Domain/selector";
import {
  ExcelStudentPostAPI,
  StudentNavbarAPI,
  StudentsGetAPI,
} from "../../api/ManageStudents/action";
import { collegeIdFn, userRolesActionsFn } from "../../api/SignIn/selector";
import sampleExcel from "../../assets/excel/Sample_Student.xlsx";
import CustomCircularProgress from "../../components/CircularProgressBar";
import ShortlistEligible from "../../components/DialogSection/AddCompanyPlacement/AddStudentPlacement copy";
import AddNewStudent from "../../components/DialogSection/AddNewCompany/AddNewStudent";
import ImportData from "../../components/DialogSection/ImportData";
import UploadExcelFile from "../../components/DialogSection/UploadExcelFile";
import StudentTab from "../../components/Header/StudentTab";
import DropDownButton from "../../components/Input/DropDownButton";
import NoDataFuncNew from "../../components/NoDataSVG/index2";
import {
  capitalizeFirstLetter,
  generateCsv,
} from "../../utility/commaSeparatedStrings";
import { dataRow, headerSampleFile } from "./sampleFIle";
import { STUDENT_DATA } from "../../api/ManageStudents/action";
import ShareStudentDetails from "../../components/DialogSection/ShareApplicant/shareStudent";
import TrackShareSuccessfully from "../../components/DialogSection/TrackShareSuccessfull";
import axios from "axios";
import ProfileUpdateTab from "../../components/Header/ProfileUpdateTab";
import { praveshAxiosGetReq } from "../../api/BaseApi/apiBase";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    //  alignItems: "center",
    flexDirection: "row",
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function ProfileUpdateRequest() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState("all");
  const [applyClickedScore, setApplyClickedScore] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);
  const collegeId = useSelector(collegeIdFn);
  const [callNavbar, setCallNavbar] = useState(false);
  const [filters, setFilters] = useState({
    search: null,
    course: null,
    degree: null,
    batch: null,
    cgpa_min: null,
    cgpa_max: null,
    status_active: true,
    status_blocked: true,
    status: "PENDING",
    current_tab: { id: 8, heading: "Pending Update", params: "PENDING" },
    placement_status: null,
  });
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const isGradeSystem = useSelector(isGradeSystemFn);
  const [open, setOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const checkValue = () => {
    if (
      filters?.status === "" ||
      filters?.status === "ALL" ||
      filters?.status === "all"
    )
      return ``;
    else if (checkForPendingRejected())
      return `&key=${filters?.current_tab?.params}`;
    else return `&placement_status=${filters?.status}`;
  };
  const checkForKey = () => {
    if (
      filters?.status === "" ||
      filters?.status === "ALL" ||
      filters?.status === "all"
    )
      return ``;
    else if (checkForPendingRejected())
      return `&key=${filters?.current_tab?.params}`;
    else return `&placement_status=${filters?.status}`;
  };

  const searchFilter = () => {
    if (filters.search) return `&search=${filters.search}`;
    else return ``;
  };

  const studentList = useSelector((state) => state?.StudentData?.details);
  const filterList = useSelector((state) => state?.StudentData?.filterData);

  // const navbarData = useSelector((state) => state?.StudentData?.navbar);

  const navbarData = [
    {
      id: 8,
      heading: "Pending Updates",
      params: "PENDING",
      /*      count: 7, */
    },
    {
      id: 7,
      heading: "Approved Updates",
      params: "APPROVED",
      /*  count: 6, */
    },
    {
      id: 2,
      heading: "Rejected Updates",
      params: "REJECTED",
      /*   count: 1, */
    },
  ];

  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );
  const [loadingPlacement, setLoadingPlacement] = useState(false);
  const userRolesPermission = useSelector(userRolesActionsFn);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  useEffect(() => {
    dispatch(InviteStudentLinkAPI(alert, history, location));
  }, [collegeId]);

  const [filterDataText, setFilterDataText] = useState(null);
  useEffect(() => {
    if (
      (filters.status_blocked ||
        filters.status_active ||
        filters.batch ||
        filters.course ||
        filters.degree ||
        applyClickedScore ||
        clickSearchButton) &&
      studentList?.length === 0
    ) {
      setFilterDataText("No Results Found");
    }
  }, [
    filters.status_blocked,
    filters.status_active,
    filters.batch,
    filters.course,
    filters.degree,
    applyClickedScore,
    clickSearchButton,
  ]);

  useEffect(() => {
    if (checkForPendingRejected()) {
      setPlacementSession({ id: "All", name: "All Placement Cycles" });
      // setPlacementSession({});
    }
  }, [filters?.current_tab]);

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.params === "pending_students" ||
      filters?.current_tab?.params === "rejected_students"
    );
  };

  const [studentListMain, setStudentListMain] = useState([]);
  const [studentListLoading, setStudentListLoading] = useState(false);
  const [studentListError, setStudentListError] = useState(false);
  const getSearchString = () => {
    if (filters?.search) return `&search=${filters?.search}`;
    return ``;
  };
  const getChangeType = () => {
    if (filters?.changeType) return `&=change_type${filters?.key}`;
    return ``;
  };
  const getSectionName = (type) => {
    switch (type) {
      case "BASIC":
        return `Basic`;
      case "SKILL":
        return `Skills`;
      case "PROJ":
        return `Projects`;
      case "PREF":
        return `Preferences`;
      case "EDU":
        return `Education`;
      case "CERT":
        return `Certifications`;
      case "WORK":
        return `Work Experience`;
      case "RESUME":
        return `Resume`;
      case "LINKS":
        return `Links`;
      case "ACHIEVE":
        return `Achievements`;
      case "LANG":
        return `Languages`;
      case "HOB":
        return `Hobbies`;
      case "EXTRA_CURR":
        return `Extra Curricular`;
      default:
        return `Basic`;
    }
  };
  // const capitalizeFirstLetter = (str) => {
  //   let string = str?.toLowerCase();
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };
  const getStudentList = async () => {
    const url = `api/saas/profile/get/request_list/?change_status=${
      filters?.status
    }${getSearchString()}${getChangeType()}`;

    setStudentListLoading(true);
    try {
      const res = await praveshAxiosGetReq(url);
      const list = [];
      if (res?.data?.success) {
        res?.data?.data?.map((item) => {
          const obj = {
            id: item?.id,
            user_id: item?.user_id,
            name: item?.user_name,
            email: item?.user_email,
            change_type: capitalizeFirstLetter(item?.change_type),
            status: capitalizeFirstLetter(item?.change_status),
            change_by: `${item?.change_by_name}`, //`${item?.change_by_name} (${item?.change_info?.change_by_entity} USER)`
            response_by: item?.response_by_name
              ? `${item?.response_by_name}` //`${item?.response_by_name} (${item?.change_info?.response_by_entity} USER)`
              : "NA",

            change_in: item?.change_in,
            change_in_text: item?.change_text,
            section_name: getSectionName(item?.change_in),
            request_time: item?.request_time,
            response_time: item?.response_time,
          };
          list.push(obj);
        });

        setStudentListMain(list);
      }
    } catch (error) {
      console.log(error);
      setStudentListError(true);
    } finally {
      setStudentListLoading(false);
      setClickSearchButton(false);
    }
  };

  useEffect(() => {
    getStudentList();
  }, [filters?.current_tab, clickSearchButton]);

  return (
    <>
      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : (
        <>
          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Profile Update Request</Typography>
              <Typography variant="body2" color="secondary">
                Manage your all Students Profile in one place
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: "-15px" }}>
            <ProfileUpdateTab
              loading={loading}
              filters={filters}
              setFilters={setFilters}
              setClickSearchButton={setClickSearchButton}
              setSelectedRowsData={setSelectedRowsData}
              selectedRowsData={selectedRowsData}
              clickSearchButton={clickSearchButton}
              value={value}
              setValue={setValue}
              navbarData={navbarData}
              filterList={filterList}
              setApplyClickedScore={setApplyClickedScore}
              filterDataText={filterDataText}
              setFilterDataText={setFilterDataText}
              collegeId={collegeId}
              userRolesPermission={userRolesPermission}
              setCallNavbar={setCallNavbar}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
              studentListMain={studentListMain}
              setStudentListMain={setStudentListMain}
              studentListLoading={studentListLoading}
              studentListError={studentListError}
            />
          </div>
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
