import { Laptop, VerifiedUserSharp } from "@material-ui/icons";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import ChatIcon from "@material-ui/icons/Chat";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  default as DateRange,
  default as DateRangeIcon,
} from "@material-ui/icons/DateRange";
import {
  default as Description,
  default as DescriptionIcon,
} from "@material-ui/icons/Description";
import ForumIcon from "@material-ui/icons/Forum";
import GroupIcon from "@material-ui/icons/Group";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import PieChartIcon from "@material-ui/icons/PieChart";
import PublicIcon from "@material-ui/icons/Public";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import WorkIcon from "@material-ui/icons/Work";
import { FeaturedPlayList } from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import home from "../assets/svg/home.svg";
import BoltIcon from "@mui/icons-material/Bolt";
import SensorsIcon from "@mui/icons-material/Sensors";
import React from "react";

export const IconSelector = (icon_key) => {
  switch (icon_key) {
    case "overview":
      return <DashboardIcon />;
    case "placement_cycle":
      return <DateRangeIcon />;
    case "jobs":
      return <WorkIcon />;
    case "companies":
      return <BusinessIcon />;
    case "manage_team":
      return <ManageAccountsIcon />;
    case "manage_offcampus":
      return <TrackChangesIcon />;
    case "manage_students":
      return <GroupIcon />;
    case "manage_ejd":
      return <DescriptionIcon />;
    case "manage_links":
      return <DescriptionIcon />;
    case "chat_room":
      return <ForumIcon />;
    case "report":
      return <PieChartIcon />;
    case "manage_forms":
      return <PlaylistAddIcon />;
    case "assignments":
      return <AssignmentOutlinedIcon />;
    case "interviews":
      return <RecordVoiceOverIcon />;
    case "settings":
      return <SettingsIcon />;
    case "home":
      return <HomeIcon />;
    case "dropdown_closed":
      return <KeyboardArrowDownIcon />;
    case "dropdown_open":
      return <KeyboardArrowUpIcon />;
    case "Manage Jobs":
      return <Laptop color="primary" style={{ fontSize: "80px" }} />;
    case "Manage Students":
      return <BusinessIcon color="primary" style={{ fontSize: "80px" }} />;
    case "Manage Team":
      return (
        <PermDataSettingIcon color="primary" style={{ fontSize: "80px" }} />
      );
    case "Manage Forms & e-JD":
      return <Description color="primary" style={{ fontSize: "80px" }} />;
    case "Manage Assignment & Interviews":
      return <DateRange color="primary" style={{ fontSize: "80px" }} />;
    case "Reports":
      return <PieChartIcon color="primary" style={{ fontSize: "80px" }} />;
    case "Manage Admin account":
      return <VerifiedUserSharp color="primary" style={{ fontSize: "80px" }} />;
    case "Post Job & Placement Cycle & Overview":
      return <WorkIcon color="primary" style={{ fontSize: "80px" }} />;
    case "Settings":
      return <VerifiedUserSharp color="primary" style={{ fontSize: "80px" }} />;
    case "Companies":
      return <BusinessIcon color="primary" style={{ fontSize: "80px" }} />;
    case "Chat Room":
      return <ChatIcon color="primary" style={{ fontSize: "80px" }} />;
    case "Jobs":
      return <WorkIcon color="primary" style={{ fontSize: "80px" }} />;
    case "CampusFeed":
      return <PublicIcon />;
    case "recommendation-jobs":
      return <FeaturedPlayList />;
    case "live_jobs":
      return <SensorsIcon />;
    case "brand_jobs":
      return <BoltIcon />;
    case "Off Campus Data":
      return <TrackChangesIcon /*  primaryColor={domainTheme} */ />;
    default:
      return <KeyboardArrowUpIcon />;
  }
};
