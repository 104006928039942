import { Chip, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { StudentPlacementCycleChangeAPI } from "../../../api/ManageStudents/action";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "10px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "40px",
    paddingRight: "40px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  primebtn: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function AddStudentPlacement({ selectedRowsData, setSelectedRowsData, selectedID, setSelectedID, jobList, collegeId }) {
  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  const handleDeletePlacement = (item, index) => {
    if (item.is_selected) {
      var arr = [...placementCycle];
      if (index !== -1) {
        arr[index].new_selected = false;
        setPlacementCycle(arr);
      }
    }
  };
  const classes = useStyles();
  const [showMore, setShowMore] = React.useState(false);
  const [placementCycle, setPlacementCycle] = useState(null);
  const [currentPlacement, setCurrentPlacement] = useState(null);
  const [placementError, setPlacementError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();

  const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: 14 }} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkedIcon} />;

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentPlacement(null);
  };
  const [loader, setLoader] = useState(false);

  // const placementSessionList = useSelector((state) => state.commonData.placementListEnded);

  useEffect(() => {
    if (selectedRowsData?.length > 0) {
      setPlacementCycle(selectedRowsData[0]?.placement_cycle_list ? selectedRowsData[0]?.placement_cycle_list : []);
    }
  }, [selectedRowsData]);

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Software Engineer | GetWork" },
    { key: 1, label: "Software Engineer | GetWork" },
    { key: 2, label: "Software Engineer | GetWork" },
    { key: 3, label: "Software Engineer | GetWork" },
  ]);

  const handleSubmit = () => {
    if (validateData()) {
      var arr = [];
      // var new_arr = [];
      // new_arr = placementCycle.filter((i) => i.new_selected);
      // new_arr?.forEach((item) => {
      //   arr.push(item.id);
      // });
      var student_arr = [];
      selectedRowsData?.map((item) => {
        student_arr.push(item?.id);
      });
      var data = {
        college_id: collegeId,
        placement_id: [currentPlacement?.id],
        object_id: student_arr,
      };
      dispatch(StudentPlacementCycleChangeAPI(data, alert, history, location, jobList, handleClose, setLoader, collegeId, setSelectedRowsData ));
    }
  };

  const validateData = () => {
    var is_valid = true;
    var placement_error = null;
    if (!currentPlacement) {
      is_valid = false;
      placement_error = "Please Enter Active Placement Cycle";
    }
    setPlacementError(placement_error);
    return is_valid;
  };

  return (
    <div>
      <Button color="primary" size="small" variant="contained" className={classes.primebtn} onClick={handleClickOpen}>
        Add Students to Placement Cycle
      </Button>
      <Dialog fullScreen={fullScreen} maxWidth="md" PaperProps={{ classes: { root: classes.root1 } }} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">Add Students to Placement Cycle </Typography>
          </div>
          {/* <Typography
            variant="body2"
            style={{ color: "#6c757d", marginLeft: "10px" }}
          >
            Selected Jobs
          </Typography> */}
          <div component="ul" className={classes.root}>
            {!showMore
              ? selectedRowsData.slice(0, 5).map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : selectedRowsData.map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
          </div>
          <div>
            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
          </div>{" "}
          {placementCycle?.length > 0 && placementCycle.some((e) => e.new_selected) && (
            <div
              style={{
                padding: "10px",
                paddingLeft: "10px",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <Typography variant="h6">Selected Placement Cycles </Typography>
            </div>
          )}
          {placementCycle?.length > 0 &&
            placementCycle?.map((item, index) => (
              <>
                {item.new_selected && (
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    onDelete={() => handleDeletePlacement(item, index)}
                    // deleteIcon={item.is_selected && !item.new_selected ? <></> : <CloseIcon />}
                    deleteIcon={<CloseIcon />}
                    label={item.name}
                    className={classes.chip}
                  />
                )}
              </>
            ))}
          <br />
          {/* <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            disableCloseOnSelect
            options={placementCycle}
            getOptionLabel={(option) => option.name}
            // value={placementCycle}

            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => <></>);
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={option.new_selected}
                        // disabled={option.is_selected && !option.new_selected}
                        onChange={(e) => {
                          setPlacementError(null);
                          var arr = [...placementCycle];
                          var obj = { ...option, new_selected: true };
                          obj["is_selected"] = e.target.checked;
                          var index = arr.findIndex((i) => i.id === option.id);
                          arr[index] = obj;
                          setPlacementCycle(arr);
                        }}
                        // onChange={(e) => {
                        //   handleSelectSingleSpecializations(e, i, item);
                        // }}
                        name="gilad"
                      />
                    }
                    label={<span style={{ fontSize: 13 }}>{option.name}</span>}
                  />
                </FormGroup>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={"Active Placement Cycle"}
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={placementError ? true : false}
                helperText={<span>{placementError}</span>}
              />
            )}
          /> */}
          <Autocomplete
            id="country-select-demo"
            options={placementCycle}
            getOptionLabel={(option) => option.name}
            value={currentPlacement}
            onChange={(e, newValue) => {
              setCurrentPlacement(newValue);
              setPlacementError(null);
            }}
            style={{ background: "#fff", borderRadius: 8 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={"Active Placement Cycles"}
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={placementError ? true : false}
                helperText={<span>{placementError}</span>}
              />
            )}
          />
          <Link to="/placement-cycles" style={{ textDecoration: "none" }}>
            <Button color="primary" style={{ display: "flex" }} startIcon={<AddCircleIcon />}>
              Add New Placement Cycle
            </Button>
          </Link>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Button autoFocus variant="outlined" className={classes.button} onClick={handleClose} color="primary">
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "140px" }}
              className={classes.button}
              onClick={() => {
                if (!loader) handleSubmit();
              }}
            >
              {loader ? <CustomButtonCircularProgress /> : "Submit"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
