import React from "react";
import Charst from "react-apexcharts";

function LastYearCampusBarGraph({ chartDataForGraph = {} }) {
  const lastYears = chartDataForGraph?.lastYears;

  const chartData = {
    series: lastYears
      ? [
          {
            name: lastYears && lastYears[0],
            data: lastYears && chartDataForGraph[lastYears[0]],
          },
          {
            name: lastYears && lastYears[1],
            data: lastYears && chartDataForGraph[lastYears[1]],
          },
          {
            name: lastYears && lastYears[2],
            data: lastYears && chartDataForGraph[lastYears[2]],
          },
        ]
      : [],
    options: {
      chart: { type: "bar" },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      chart: {
        id: chartDataForGraph?.heading
          ?.split(" ")
          ?.join("_")
          ?.replaceAll("(%)", "_Percent"),
        toolbar: {
          export: {
            csv: {
              headerCategory: chartDataForGraph?.xAxis,
            },
          },
        },
      },
      fill: { colors: ["#0B57D0", "#E0A800", "#029E73"] },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      title: {
        text: chartDataForGraph?.heading,
        style: {
          fontSize: "18px",
          fontFamily: "Nunito",
          fontWeight: 1000,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      xaxis: {
        categories: chartDataForGraph?.name ?? [],
        title: {
          text: chartDataForGraph?.xAxis,
          style: {
            fontSize: "14px",
            fontFamily: "Nunito",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
        labels: {
          // rotateAlways: true,
          // hideOverlappingLabels: true,
          rotate: -45,
          trim: true,
        },
      },
      yaxis: {
        title: {
          text: chartDataForGraph?.yAxis,
          style: {
            fontSize: "14px",
            fontFamily: "Nunito",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      stroke: { width: 0 },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 8,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
    },
  };
  return (
    <div>
      <Charst
        options={chartData.options} // series={[
        //   {
        //     name: "Inflation",
        //     data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2],
        //   },
        // }]
        series={chartData.series}
        type="bar"
        height={320}
        //height="100%"
      />
    </div>
  );
}

export default LastYearCampusBarGraph;
