import { Skeleton } from '@material-ui/lab'
import React from 'react'

const SkeletonCourses = () => {

  return (
    <ul style={{
        padding: 0,
        display: "flex",
        listStyleType: "none",
        flexWrap: "wrap",
        textAlign: "left",
      }}> 
      <li>
      <Skeleton variant="rectangular" width={300} height={20} />      
    </li>
    </ul>
  )
}

export default SkeletonCourses
