import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { mergeClasses } from "@material-ui/styles";
import { PlayDisabled } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { collegeIdFn } from "../../../api/SignIn/selector";
import {
  CallCollegeAPI,
  CallCollegeInternalAPI,
} from "../../../api/University/APIs/action";
import { allCollegeSelectedSelectorFn } from "../../../api/University/APIs/selector";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drop: {
    padding: "5px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "8px",
    width: "150px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function UniversityDropDown({ collegeList, disabled = false }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const allCollegeSelected = useSelector(allCollegeSelectedSelectorFn);
  const collegeId = useSelector(collegeIdFn);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkName = () => {
    var name = "";
    collegeList?.map((item) => {
      if (item?.id === collegeId) name = item?.name;
    });
    return name;
  };

  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  const handleClickNew = (id) => {
    dispatch(
      CallCollegeAPI(
        id,
        alert,
        history,
        location,
        user_details,
        location.pathname
      )
    );
  };
  const handleSubmit = (id) => {
    dispatch(
      CallCollegeInternalAPI(
        id,
        alert,
        history,
        location,
        user_details,
        location.pathname
      )
    );
  };

  // useEffect(() => {
  //   if (collegeList) {
  //     handleSubmit(collegeList[0]?.id);
  //   }
  // }, []);

  // const educationalGroup = () => {
  //   dispatch(
  //     CallCollegeAPI(
  //       null,
  //       alert,
  //       history,
  //       location,
  //       user_details,
  //       location.pathname,
  //       true
  //     )
  //   );
  // };

  // useEffect(() => {
  //   if (allCollegeSelected) {
  //     if (location.pathname.includes("overview")) history.push("/dashboard");
  //     else {
  //       if (location.pathname.includes("college-profile")) {
  //         history.push("/educational-group/all-colleges");
  //       } else if (location.pathname.includes("interview")) {
  //         history.push("/educational-group/interview-calendar");
  //       } else if (!location.pathname.includes("/educational-group/")) {
  //         history.push("/educational-group/" + location.pathname.split("/")[1]);
  //       }
  //     }
  //   }
  // }, [allCollegeSelected]);

  return (
    <div>
      <Button
        className={mergeClasses.title}
        aria-controls="customized-menu"
        style={{
          display: "flex",
          borderRadius: "8px",
          backgroundColor: "#e2e6ea",
          color: "#7a7e83",
          boxShadow: "none",
          maxWidth: "200px",
        }}
        aria-haspopup="true"
        variant="contained"
        size="small"
        onClick={handleClick}
        disabled={disabled}
        endIcon={<ArrowDropDownIcon />}
      >
        <Typography
          variant="overline"
          color="inherit"
          noWrap
          style={{ marginRight: "20px", fontWeight: "bold" }}
        >
          {collegeId &&
          !location.pathname.includes("educational-group") &&
          !location.pathname.includes("dashboard")
            ? checkName()
            : "Select a College"}
        </Typography>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* {!location.pathname.includes("educational-group") &&
          !location.pathname.includes("dashboard") && (
            <StyledMenuItem
              onClick={() => {
                educationalGroup();
                handleClose();
              }}
            >
              <Typography
                variant="overline"
                color="inherit"
                style={{ marginRight: "50px" }}
              >
                Group View
              </Typography>
            </StyledMenuItem>
          )} */}
        {collegeList?.map((item, index) => {
          return (
            <StyledMenuItem
              selected={item?.id === collegeId ? true : false}
              onClick={() => {
                // setPlacementSession(item);
                // handleClose();
                // if (show) setFilterDataText("No Data Found");

                if (
                  !location.pathname.includes("educational-group") &&
                  !location.pathname.includes("dashboard")
                ) {
                  handleClickNew(item?.id);
                } else {
                  handleSubmit(item?.id);
                }
                handleClose();
              }}
            >
              <Typography variant="overline" color="inherit" noWrap>
                {item.name}
              </Typography>
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
