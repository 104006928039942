import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import doc from "../../../assets/img/about-us-longer.jpg";
//import doc from "../../../assets/img/mslogo.jpg";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import ArticleIcon from "@material-ui/icons/Description";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import pdf from "../../../assets/png/pdf.jpg";
import doc_img from "../../../assets/png/doc.png";
import { truncate, truncateWithRange } from "../../../utility/ellipsify";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    borderRadius: "8px",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      border: `3.5px solid ${theme.palette.primary.main}`,
    },
  },
  rootSelected: {
    maxWidth: 300,
    borderRadius: "8px",
    boxShadow: "none",
    cursor: "pointer",

    border: `3.5px solid ${theme.palette.primary.main}`,
  },
  media: {
    height: 0,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    border: "1px solid #b0b7c3 ",
    // border: "1px solid",
    paddingTop: "56.25%", // 16:9
    "&:hover": {
      borderBottom: "1px solid #b0b7c3 ",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
    },
  },
  mediaSelected: {
    height: 0,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    // border: "1px solid",
    paddingTop: "56.25%", // 16:9
    borderBottom: "1px solid #b0b7c3 ",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default function ImageCard({ handleClickOpen, item, index, data, setData, selectedVault, setSelectedVault }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const checkForDownImage = (item) => {
    switch (item.file_ext) {
      case "pdf":
        return <PictureAsPdfIcon style={{ color: "#6c757d" }} />;

      case "docx":
        return <ArticleIcon style={{ color: "#6c757d" }} />;

      default:
        return <ImageIcon style={{ color: "#6c757d" }} />;
    }
  };

  const checkForImage = () => {
    switch (item.file_ext) {
      case "pdf":
        return pdf;
      case "docx":
        return doc_img;
      default:
        return item?.file;
    }
  };

  return (
    <Card className={item?.select ? classes.rootSelected : classes.root}>
      <CardMedia
        onClick={() => handleClickOpen(item)}
        className={item?.select ? classes.mediaSelected : classes.media}
        image={item?.thumbnail && (item?.file_ext?.includes("pdf") || item?.file_ext?.includes("doc")) ? item?.thumbnail : checkForImage(item)}
        title={item?.file_name}
      />
      <CardContent
        style={
          item?.select
            ? { padding: "2px 10px", borderTop: "0px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px" }
            : {
                padding: "2px 10px",
                border: "1px solid #e2e6ea",
                borderTop: "0px",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
                "&:hover": {
                  border: "none",
                },
              }
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // gap: 30,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>{checkForDownImage(item)}</div>
          <div style={{ width: "100%" }}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              component="p"
              style={{
                marginLeft: "12px",
                //color: "#6c757d",
                color:"#000",
                maxWidth: "100px",
                fontSize: 10,
              }}
            >
              {item?.file_name}
            </Typography>
          </div>
          <div style={{ position: "relative", left: 26 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={item?.select}
                  onChange={(e) => {
                    var arr = [...data];
                    arr[index].select = e.target.checked;
                    setData(arr);
                    if (e.target.checked) {
                      var select_id = [...selectedVault];
                      if (!selectedVault?.includes(arr[index]?.id)) select_id.push(arr[index]?.id);
                      setSelectedVault(select_id);
                    } else {
                      var select_id = [...selectedVault];
                      if (selectedVault?.includes(arr[index]?.id)) var index_ = select_id.findIndex((item) => item === arr[index]?.id);
                      select_id.splice(index_, 1);
                      setSelectedVault(select_id);
                    }
                  }}
                />
              }
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
