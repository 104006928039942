import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Slider, Typography, withStyles } from "@material-ui/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ScrollSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 6,
    padding: "15px 0",
  },
  thumb: {
    height: 10,
    width: 120,
    backgroundColor: "#6C757D",
    borderRadius: 20,
    marginTop: -2,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow: "none",
      //   boxShadow:
      //     "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: "none",
        // boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 6,
    backgroundColor: "#fff",
  },
  rail: {
    height: 6,
    opacity: 0.5,
    backgroundColor: "#fff",
  },
  mark: {
    backgroundColor: "#fff",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "#fff",
  },
})(Slider);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },

  elements: {
    point: {
      radius: 1,
    },
  },

  // legend: {
  //   labels: {
  //     filter: function (label) {
  //       if (label.text == "Mt") return true;
  //     },
  //   },
  // },

  scales: {
    x: {
      min: 0,
      max: 12,
      //display: false, // show/ hide x-axis
      grid: {
        display: false, // show/hide grid line in x-axis
      },
    },
    y: {
      //display: false, // same as x-axis
      grid: {
        //display: false,
      },
    },
  },
};

export default function ScrollBarGraph({ chartData, yAxis, xAxis }) {
  const labels = chartData?.labels ?? [];

  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: chartData?.data ?? [],
        barThickness: 40,
        borderRadius: 10,
        backgroundColor: chartData?.color ?? "#CD393E",
        borderColor: chartData?.color ?? "#CD393E",
        fill: false,
      },
    ],
  };

  const [opt, setOpt] = useState(options);
  const [value, setValue] = useState(0);

  const clickBar = (att) => {};
  const noOfColumnToDisplay = 10;
  const noOfColumnToScroll = 2;
  const labelsLength = labels?.length;

  const noOfMaxForSlider = Math.ceil(
    (labelsLength - noOfColumnToDisplay) / noOfColumnToScroll
  );
  const clickScroll = (event, newValue) => {
    const newOpt = JSON.parse(JSON.stringify(opt));
    let minVal = opt.scales.x.min;
    let maxVal = opt.scales.x.max;
    if (value !== newValue) {
      if (labelsLength - minVal >= noOfColumnToDisplay) {
        let newMin = noOfColumnToScroll * newValue;
        newOpt.scales.x.min = newMin;
        newOpt.scales.x.max = newMin + (noOfColumnToDisplay - 1);
        setOpt(newOpt);
        setValue(newValue);
      } else if (maxVal === labelsLength) {
        let newMin = noOfColumnToScroll * newValue;
        newOpt.scales.x.min = newMin;
        newOpt.scales.x.max = newMin + (noOfColumnToDisplay - 1);
        setOpt(newOpt);
        setValue(newValue);
      }
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Typography
          style={{
            transform: "rotate(270deg)",
            fontSize: "14px",
            position: "absolute",
            top: " 50%",
            left: "-60px",
            bottom: "50%",
            color: "#000",
            fontWeight: 600,
          }}
        >
          {yAxis}
        </Typography>
        <div style={{ paddingLeft: 20 }}>
          <Bar options={opt} data={data} width={"500px"} height={"150px"} />
        </div>
        <Typography
          style={{
            fontSize: "14px",
            color: "#000",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          {xAxis}
        </Typography>
      </div>
      <div style={{ paddingLeft: 80, paddingRight: 120 }}>
        <ScrollSlider
          value={value}
          onChange={clickScroll}
          step={1}
          // marks
          min={0}
          max={noOfMaxForSlider}
        />
      </div>
    </>
  );
}
