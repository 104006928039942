import React from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import reactCSS from "reactcss";
import { changeState, GET_THEME_DETAILS } from "../../../action";
import { isEducationGroupFn, themeSelectorFn } from "../../../api/Domain/selector";


function ColorPickerSecondary({ state, setState, fromCollege }) {

  const theme_change = useSelector(themeSelectorFn);

  // const [state, setState] = useState({
  //   displayColorPicker: false,
  //   color: theme_change?.secondary,
  // });

  const dispatch = useDispatch();

  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker });
  };

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };

  const isEducationGroup = useSelector(isEducationGroupFn);

  const handleChange = (color) => {
    setState({ ...state, color: color.hex });
    // handleFilterChange(UPDATE_MAIN_THEME, color.hex);
    var data = {
      main: theme_change?.main,
      hover: "#0069D9",
      disable: "#58AAFF",
      contrast_text: "#fff",
      secondary: color.hex,
    };
    if (!isEducationGroup && fromCollege) handleFilterChange(GET_THEME_DETAILS, data);
    if (isEducationGroup) handleFilterChange(GET_THEME_DETAILS, data);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "56px",
        height: "24px",
        borderRadius: "8px",
        background: state.color,
      },
      swatch: {
        borderRadius: "8px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "relative",
        zIndex: "2",
      },
      cover: {
        // position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {state.displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={state.color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}

export default ColorPickerSecondary;
