import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { sanchalanAxiosGetReq } from "../../../api/BaseApi/apiBase";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import {
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { checkForToken } from "../../../constants/ApiCall";
import { truncate } from "../../../utility/ellipsify";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import UpdateProfileActionBar from "../../Input/ProfileUpdateActionBar";
import CompanyTable from "../../Table/CompanyTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    color: "red",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    color: "red",
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function ProfileUpdateTab({
  loading,
  filters,
  setFilters,
  setClickSearchButton,
  setSelectedRowsData,
  selectedRowsData,
  clickSearchButton,
  value,
  setValue,
  navbarData,
  filterList,
  setApplyClickedScore,
  filterDataText,
  setFilterDataText,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  checkSelectedGroup = () => {},
  clearData = () => {},
  setSelectedID,
  selectedID,
  studentListMain,
  setStudentListMain,
  studentListLoading,
  studentListError,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isGradeSystem = useSelector(isGradeSystemFn);
  const studentList = useSelector((state) => state?.StudentData?.details);
  const [pageData, setPageData] = useState({ currentPage: 0, pageSize: 20 });
  const collegeListSelector = useSelector(collegeListSelectorFn);

  const [tempStudentList, setTempStudentList] = useState([]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const alert = useAlert();

  const allColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "60px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.user_id ? (
              <Grid item xs zeroMinWidth>
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      // history.push({
                      //   pathname: `/student-profile-request/${params?.row?.name.replace(
                      //     / /g,
                      //     "_"
                      //   )}-${params?.row?.user_id}`,
                      // });
                      window.open(
                        `/student-profile-request/${params?.row?.name.replace(
                          / /g,
                          "_"
                        )}-${params?.row?.user_id}`,
                        "_blank"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xs zeroMinWidth>
                <Tooltip title={params?.row?.name} placement="right">
                  <Typography
                    color="primary"
                    noWrap
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => {
                      alert.error(
                        "Something wrong with this data, kindly connect with GetWork"
                      );
                    }}
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",

              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "change_type",
      headerName: "Change Type",
      // type: "text",
      width: 170,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          Change Type
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.change_type}
        </span>
      ),
    },
    {
      field: "section_name",
      type: "text",
      headerName: "Section Name",
      width: 170,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Section Name
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.section_name}
        </span>
      ),
    },
    {
      field: "change_status",
      type: "text",
      headerName: "Degree",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Status
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.status}
        </span>
      ),
    },
    {
      field: "change_by",
      type: "text",
      headerName: "Change By",
      width: 190,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Change By
        </strong>
      ),
      renderCell: (params) => (
        <Tooltip title={params?.row?.change_by} placement="right">
          <Typography
            noWrap
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.change_by}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "response_by",
      type: "text",
      headerName: "Response By",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Response By{" "}
          <span
            style={{ fontSize: 14, color: "#5a6268" }}
          >{`(Team Member)`}</span>
        </strong>
      ),
      renderCell: (params) => (
        <Tooltip title={params?.row?.response_by} placement="right">
          <Typography
            noWrap
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.response_by}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "request_time",
      type: "text",
      headerName: "Request Time",
      width: 190,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Request Time
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.request_time ?? "NA"}
        </span>
      ),
    },
  ];

  const [columns, setColumns] = useState(allColumns);
  useEffect(() => {
    if (filters?.status === "APPROVED" || filters?.status === "REJECTED") {
      const newCols = [...allColumns];
      newCols?.push({
        field: "response_time",
        type: "text",
        headerName: "Response Time",
        width: 190,
        renderHeader: (params) => (
          <strong
            style={{
              color: "#5a6268",
              marginLeft: "30px",
            }}
          >
            Response Time
          </strong>
        ),
        renderCell: (params) => (
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            {params?.row?.response_time ?? "NA"}
          </span>
        ),
      });
      setColumns(newCols);
    } else {
      setColumns(allColumns);
    }
  }, [filters?.status]);

  const checkForAll = () => {
    return filters?.current_tab?.params === "ALL" || !filters?.current_tab;
  };

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.params === "pending_students" ||
      filters?.current_tab?.params === "rejected_students"
    );
  };

  const checkForRejected = () => {
    return filters?.current_tab?.params === "rejected_students";
  };

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    setFilters({ ...filters, status: newValue });
    setFilterDataText("No Data Found");
  };

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={filters?.status}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="ant example"
        >
          {navbarData?.length > 0 &&
            navbarData?.map((item) => (
              <NewTab
                value={String(item.params)}
                label={`${item.heading}`} //${item.heading}(${item.count})
                onClick={() => {
                  setFilters({
                    ...filters,
                    current_tab: item,
                    status: String(item.params),
                  });
                  clearData();
                  setFilterDataText("No Data Found");
                }}
                wrapped
                {...a11yProps(String(item.params))}
              />
            ))}
        </NewTabs>
      </AppBar>

      <UpdateProfileActionBar
        filters={filters}
        setFilters={setFilters}
        setClickSearchButton={setClickSearchButton}
        selectedRowsData={selectedRowsData}
        clickSearchButton={clickSearchButton}
        setSelectedRowsData={setSelectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        filterList={filterList}
        studentList={studentList}
        setApplyClickedScore={setApplyClickedScore}
        collegeId={collegeId}
        userRolesPermission={userRolesPermission}
        setCallNavbar={setCallNavbar}
        checkSelectedGroup={checkSelectedGroup}
        checkForPendingRejected={checkForPendingRejected}
      />

      <br />
      {navbarData?.length > 0 &&
        navbarData?.map((item, index) => {
          return (
            <TabPanel
              value={String(filters?.status)}
              index={String(item.params)}
            >
              <CompanyTable
                loading={studentListLoading}
                list={studentListMain?.length ? studentListMain : []}
                columns={columns}
                checkBoxSelection={false}
                setSelectedRowsData={setSelectedRowsData}
                selectedRowData={selectedRowsData}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                filterDataText={filterDataText}
                pageData={pageData}
                setPageData={setPageData}
                fromProfileUpdate={true}
              />{" "}
            </TabPanel>
          );
        })}
    </div>
  );
}
