import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import pSBC from "shade-blend-color";
import { collegeDetailSelectorFn } from "../../api/SignIn/selector";
import StudentFormNavbar from "../../components/Common/StudentFormNavbar";
import TrackPasscode from "../../components/DialogSection/TrackPasscode";
import TrackHeader from "../../components/Header/TrackHeader";
import TrackJobTab from "../../components/Header/TrackJobTab";
import { BackendBaseApi, getColumnString } from "../../constants/constants";
import { Button, Typography, Tooltip, Grid, Chip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { truncate } from "../../utility/ellipsify";
import StudentActionBar from "../../components/Input/StudentActionBar";
import CompanyTable from "../../components/Table/CompanyTable/index";
import {
  sanchalanAxiosGetReq,
  sanchalanAxiosPostReq,
} from "../../api/BaseApi/apiBase";
import { isGradeSystemFn } from "../../api/Domain/selector";
import { getAllColumnsStudentField } from "../../utility/allColumns";
import StudentTab from "../../components/Header/StudentTab";
import { checkForToken, sendExportLink } from "../../constants/ApiCall";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import GetEmailAndExportForSharer from "../../components/DialogSection/ShareApplicant/GetEmailAndExportForSharer";

const useStyles = makeStyles((theme) => ({
  sharedBox: {
    padding: 20,
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    width: "50%",
    borderRadius: "8px",
  },
  chip: {
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    borderRadius: "8px",
  },
}));

export default function StudentDetailsTrack() {
  const classes = useStyles();
  const location = useLocation();
  const [filters, setFilters] = useState({
    search: null,
    search_check: false,
    course: null,
    degree: null,
    batch: null,
    cgpa_min: null,
    cgpa_max: null,
    status_active: true,
    status_blocked: true,
    status: null,
    student_status: null,
  });

  const [clickSearchButton, setClickSearchButton] = useState(false);
  const [value, setValue] = React.useState("all");
  const [applyClickedScore, setApplyClickedScore] = useState(false);
  const [filterDataText, setFilterDataText] = useState(null);
  const [selectedID, setSelectedID] = React.useState([]);
  const [studentList, setStudentList] = useState([]);
  const [dataForTableRows, setDataForTableRows] = useState([]);
  const [authority, setAuthority] = useState({});

  const [trackTableData, setTrackTableData] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstApiCall, setFirstApiCall] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  //const isGradeSystem = useSelector(isGradeSystemFn);
  const userId = collegeDetails?.user_id;
  const alert = useAlert();
  const [trackPasscode, setTrackPasscode] = useState({
    track_passcode: null,
    passcode_error: null,
  });
  const [openPasscode, setOpenPasscode] = React.useState(false);
  const [validatorData, setValidatorData] = useState({
    user_name: null,
    college_name: null,
  });
  const userRolesPermission = [{}];
  let collegeId1 = 0;
  const [collegeId, setCollegeId] = useState();
  const history = useHistory();

  const [filtersSelectedbyUser, setFiltersSelectedbyUser] = useState([]);
  const [columnsNew, setColumnsNew] = useState([]);
  const [appliedFiltersNew, setAppliedFiltersNew] = useState([]);

  useEffect(() => {
    if (
      (filters.status_blocked ||
        filters.status_active ||
        filters.batch ||
        filters.course ||
        filters.degree ||
        applyClickedScore ||
        clickSearchButton) &&
      studentList?.length === 0
    ) {
      setFilterDataText("No Results Found");
    }
  }, [
    filters.status_blocked,
    filters.status_active,
    filters.batch,
    filters.course,
    filters.degree,
    applyClickedScore,
    clickSearchButton,
  ]);

  const checkSearch = () => {
    if (filters?.search) return `&name=${filters?.search}`;
    else return ``;
  };

  const checkDegree = () => {
    if (filters?.degree) return `&degree=${filters?.degree?.id}`;
    else return ``;
  };
  const checkCourse = () => {
    if (filters?.course) return `&course=${filters?.course?.id}`;
    else return ``;
  };

  const checkBatch = () => {
    if (filters?.batch) return `&batch=${filters?.batch.id}`;
    else return ``;
  };

  const checkCgpa_min = () => {
    if (filters?.cgpa_min) return `&min_cgpa=${filters?.cgpa_min}`;
    else return ``;
  };

  const checkCgpa_max = () => {
    if (filters?.cgpa_max) return `&max_cgpa=${filters?.cgpa_max}`;
    else return ``;
  };
  const checkStudent_status = () => {
    if (filters?.student_status)
      return `&status=${filters?.student_status?.name}`;
    else return ``;
  };

  useEffect(() => {
    if (filters?.search_check === true) {
      getQueryData();
    }
  }, [filters?.search_check]);

  useEffect(() => {
    if (firstApiCall) {
      getQueryData();
    }
  }, [filters?.degree, filters?.course, filters?.batch]);

  useEffect(() => {
    if (firstApiCall) {
      getQueryData();
      setClickSearchButton(false);
    }
  }, [clickSearchButton]);

  useEffect(() => {
    if (firstApiCall) {
      getQueryData();
    }
  }, [filters?.cgpa_min, filters?.cgpa_max, filters?.student_status]);

  const getQueryData = async () => {
    const url = `public/student_sharer_filter/?link=${
      window.location.href
    }&college_id=${collegeId}${checkSearch()}${checkDegree()}${checkCourse()}${checkBatch()}${checkCgpa_min()}${checkCgpa_max()}${checkStudent_status()}`;

    try {
      const res = await sanchalanAxiosGetReq(url);

      if (res?.data?.success) {
        setStudentList(res.data.data.data);
      } else {
        alert.success("Some Error Occurred");
      }
    } catch (error) {
      throw error;
    }
  };

  const isGradeSystem = true;

  const columns = [
    {
      field: "enrollment_no",
      headerName: "Enrollment Id",
      width: 150,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.enrollment_no}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "45px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.name} placement="right">
                <Typography
                  // color="primary"
                  noWrap
                  style={{ fontSize: "14px" }}
                >
                  {params?.row?.name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.email} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.email}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "phone",
      headerName: "Phone Number",

      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.phone}
        </span>
      ),
    },

    {
      field: "batch",
      headerName: "Batch",
      // type: "text",
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.batch}
        </span>
      ),
    },
    {
      field: "degree",
      type: "text",
      headerName: "Degree",
      width: 100,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "7px",
          }}
        >
          Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.branch}
        </span>
      ),
    },

    {
      field: "degree",
      type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.degree} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.degree)}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "course",
      type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Course
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            //  margin: "auto",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.course} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {truncate(params?.row?.course)}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      // type: "text",
      width: 110,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "20px",
          }}
        >
          Status
        </strong>
      ),

      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "8px",
              marginRight: "10px",
              width: "8px",
              backgroundColor:
                params.row.status === "ACTIVE"
                  ? "#51c03e"
                  : params.row.status === "INACTIVE"
                  ? "#f64a3f"
                  : "",
              borderRadius: "8px",
            }}
          />

          {params.row.status === "ACTIVE"
            ? "Active"
            : params.row.status === "INACTIVE"
            ? "Blocked"
            : ""}
        </span>
      ),
    },
    {
      field: "applied_job",
      headerName: "Applied Jobs",
      type: "text",
      width: 120,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "0px",
          }}
        >
          Applied Jobs
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.applied_job}
        </span>
      ),
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      width: 120,
      type: "text",

      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          {isGradeSystem ? "CGPA" : "Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {isGradeSystem ? params?.row?.cgpa : params?.row?.percentage}
        </span>
      ),
    },
  ];

  const [tempExportList, setTempExportList] = useState([]);

  useEffect(() => {
    if (trackTableData?.length > 0) {
      var arr = [...trackTableData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        new_arr.push({
          enrollment_id: item?.enrollment_id,
          name: item?.name,
          degree: item?.degree_name,
          course: item?.course_name,
          batch: item?.batch,
          applied_on: item?.applied_on,
        });
      });
      setTempExportList(new_arr);
    }
  }, [trackTableData?.length]);

  useEffect(() => {
    if (selectedRowsData?.length > 0) {
      let arr = [...selectedRowsData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        new_arr.push({
          enrollment_id: item?.enrollment_id,
          name: item?.name,
          degree: item?.degree_name,
          course: item?.course_name,
          batch: item?.batch,
          applied_on: item?.applied_on,
        });
      });
      setTempExportList(new_arr);
    } else {
      if (trackTableData?.length > 0) {
        var arr = [...trackTableData];
        let new_arr = [];
        var obj = {};
        arr.forEach((item) => {
          new_arr.push({
            enrollment_id: item?.enrollment_id,
            name: item?.name,
            degree: item?.degree_name,
            course: item?.course_name,
            batch: item?.batch,
            applied_on: item?.applied_on,
          });
        });
        setTempExportList(new_arr);
      }
    }
  }, [selectedRowsData?.length]);

  const handleOpenPasscode = () => {
    setOpenPasscode(true);
  };

  const handleClosePasscode = () => {
    setOpenPasscode(false);
  };

  useEffect(() => {
    checkLink();
  }, []);

  const checkLink = async () => {
    const url = `public/student_link_check/?code=${window.location.href}`;

    try {
      const res = await sanchalanAxiosGetReq(url);

      if (res?.data?.success) {
        if (res?.data?.data?.link_matched) {
          collegeId1 = res?.data?.data?.data?.college_id[0];

          setCollegeId(collegeId1);

          // alert?.success(res.data.data.message);
          res?.data?.data?.cookie ? getStudentData() : handleOpenPasscode();

          setValidatorData({
            college_name: res?.data?.data?.data?.college_name,
            user_name: res?.data?.data?.data?.user_name,
          });
        } else {
          alert.error(res.data.data.message);

          history.push(`/public/link-not-valid`);
          // history.push(`/not-found`);
        }
      } else {
        alert.success("Some Error Occurred");
        history.push(`/public/link-not-valid`);

        //  history.push(`/not-found`);
      }
    } catch (error) {
      handleOpenPasscode();
      throw error;
    }
  };

  const checkPasscode = async () => {
    const url = `public/student_passcode_validation/`;

    let data = {
      link: window.location.href,
      passcode: trackPasscode?.track_passcode,
    };

    try {
      const res = await sanchalanAxiosPostReq(url, data);

      if (res?.data?.success) {
        alert.success("Passcode successfully Verified!!");

        collegeId1 = res.data.data[0]?.college_id[0];
        // setCollegeId(collegeId);
        setCollegeId(res.data.data[0]?.college_id[0]);
        setValidatorData({
          college_name: res.data.data[0]?.college_name,
          user_name: res.data.data[0]?.student_sharer_name,
          college_id: res.data.data[0]?.college_id,
        });

        handleClosePasscode();

        getStudentData();
      } else {
        setTrackPasscode({
          ...trackPasscode,
          passcode_error: "Please check your Passcode",
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const getStudentData = async () => {
    const url = `public/student_sharer_filter/?link=${window.location.href}&college_id=${collegeId1}`;

    try {
      const res = await sanchalanAxiosGetReq(url);

      if (res?.data?.success) {
        setFiltersSelectedbyUser(res.data.data.disable_filter);

        setAuthority({
          filtering:
            res?.data?.data?.authority?.authority?.data[0]?.name?.track,
          download:
            res?.data?.data?.authority?.authority?.data[0]?.name?.download,
          action: res?.data?.data?.authority?.action,
        });

        setFilterList(res.data.data.filter_dict);

        setStudentList(res?.data?.data?.data);
        setFirstApiCall(true);
      } else {
        alert.success("Please Re-Enter the passcode!");
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const appliedFilters = [];

    Object?.keys(filtersSelectedbyUser)?.map((item) => {
      if (filtersSelectedbyUser[item]?.heading === "Batch") {
        if (filtersSelectedbyUser[item]?.data[0]?.name?.id)
          appliedFilters.push(
            `Batch ${filtersSelectedbyUser[item]?.data[0]?.name?.id}`
          );
      } else if (
        filtersSelectedbyUser[item]?.heading === "Course" ||
        filtersSelectedbyUser[item]?.heading === "Degree"
      ) {
        if (filtersSelectedbyUser[item]?.data[0]?.name)
          appliedFilters.push(filtersSelectedbyUser[item]?.data[0]?.name);
      } else if (filtersSelectedbyUser[item]?.heading === "CGPA") {
        if (filtersSelectedbyUser[item]?.data[0]?.cgpa_min)
          appliedFilters.push(
            `CGPA Min ${filtersSelectedbyUser[item]?.data[0]?.cgpa_min}`
          );

        if (filtersSelectedbyUser[item]?.data[1]?.cgpa_max)
          appliedFilters.push(
            `CGPA Max ${filtersSelectedbyUser[item]?.data[1]?.cgpa_max}`
          );
      }
    });

    setAppliedFiltersNew(appliedFilters);
  }, [firstApiCall]);

  useEffect(() => {
    let newColumns = [];

    const sampleData = studentList[0];

    if (sampleData) {
      Object?.keys(sampleData)?.map((item2) => {
        columns?.map((item) => {
          if (item?.field === item2) {
            // console.log("columns matching for sample data", item2);
            newColumns.push(item);
          }
        });
      });
    }

    setColumnsNew(newColumns);
  }, [firstApiCall]);

  const [downloadStudentList, setDownloadStudentList] = useState([]);
  useEffect(() => {
    if (studentList?.length > 0) {
      const newList = [];
      studentList?.map((item) => {
        const newObj = { ...item };
        if (newObj?.id) delete newObj.id; //removing id from export table
        newList?.push(newObj);
      });
      setDownloadStudentList(newList);
    }
  }, [studentList]);

  const [selectedColumnFields, setSelectedColumnFields] = useState([]);
  const [exportLoading, setExportLoading] = useState({
    loading: false,
    success: false,
  });

  const handleExport = (email = "") => {
    if (selectedColumnFields?.length > 0) {
      let columnStrings = getColumnString(selectedColumnFields);

      columnStrings = columnStrings.replace("batch", "current_batch");
      columnStrings = columnStrings.replace(
        "_current_percentage",
        "current_percentage"
      );
      columnStrings = columnStrings.replace(
        /specialization_/g,
        "specialization"
      );

      const URL = `public/export-public-student-track-sharer/?college_id=${collegeId}&column_name=${columnStrings}&job_id=${
        validatorData?.job_id
      }${checkSearch()}${checkDegree()}${checkCourse()}${checkBatch()}${checkCgpa_min()}${checkCgpa_max()}${checkStudent_status()}&action=${
        window.location.href
      }&email=${email}`;
      sendExportLink(URL, setExportLoading, alert, history, location, true);
    } else {
      alert.error("Select Columns to Export");
    }
  };

  const [openExportSharer, setOpenExportSharer] = React.useState(false);
  const handleClickOpenExportSharer = () => {
    setOpenExportSharer(true);
  };

  const handleCloseExportSharer = () => {
    setOpenExportSharer(false);
  };

  return (
    <div>
      <StudentFormNavbar />
      <div style={{ margin: 50 }}>
        <div className={classes.sharedBox}>
          Shared By: {validatorData?.user_name}
          {validatorData?.college_name && <>, {validatorData?.college_name}</>}
        </div>
        <>
          <div>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Students Data
            </Typography>

            {!authority?.download ? null : (
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                {/* <CSVLink
                  // data={tempStudentList}
                  data={
                    authority?.download
                      ? downloadStudentList
                      : []
                  }
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  filename={"Students.csv"}
                  // disabled= {!authority?.download}
                >
                  <Button
                    variant="contained"
                    // size="small"
                    color="primary"
                    style={{
                      marginRight: "0px",
                      display: "flex",
                      height: "48px",
                      borderRadius: "8px",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                    }}
                    className={classes.button}
                    startIcon={<GetAppIcon />}
                    // disabled= {!authority?.download}
                  >
                    Export
                  </Button>
                </CSVLink> */}
                {/* <Button
                   variant="contained"
                   size="small"
                   style={{ marginRight: "0px" }}
                   className={classes.export}
                   startIcon={<GetAppIcon />}
                   onClick={
                     fromPublicTrack ? handleClickOpenExportSharer : handleExport
                   }
                 >
                   {!fromPublicTrack && exportLoading ? (
                     <CircularProgress size={18} style={{ color: "#fff" }} />
                   ) : (
                     "Export"
                   )}
                 </Button> */}

                <GetEmailAndExportForSharer
                  open={openExportSharer}
                  handleClose={handleCloseExportSharer}
                  handleExport={handleExport}
                  exportLoading={exportLoading}
                />
                <Button
                  variant="contained"
                  // size="small"
                  color="primary"
                  style={{
                    marginRight: "0px",
                    display: "flex",
                    height: "48px",
                    borderRadius: "8px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}
                  onClick={handleClickOpenExportSharer}
                  className={classes.button}
                  startIcon={<GetAppIcon />}
                  // disabled= {!authority?.download}
                >
                  Export
                </Button>
              </div>
            )}
          </div>
          {!authority?.filtering ? null : (
            <StudentActionBar
              filters={filters}
              setFilters={setFilters}
              clickSearchButton={clickSearchButton}
              setClickSearchButton={setClickSearchButton}
              selectedRowsData={selectedRowsData}
              setSelectedRowsData={setSelectedRowsData}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
              filterList={filterList}
              studentList={studentList}
              setApplyClickedScore={setApplyClickedScore}
              collegeId={collegeId}
              userRolesPermission={userRolesPermission}
              // setCallNavbar={setCallNavbar}
              checkSelectedGroup={() => {}}
              checkForPendingRejected={() => {
                return false;
              }}
              fromStudentDetials={true}
              filteringDisabled={!authority?.filtering}
              filtersSelectedbyUser={filtersSelectedbyUser}
              fromPublic={true}
            />
          )}
          {/* 
          <div>
            {appliedFiltersNew?.map((item) => (
              <Chip
                variant="outlined"
                size="small"
                label={item}
                //  className={classes.sharedBox}
                className={classes.chip}
                color="primary"
                style={{ marginRight: "1rem", borderRadius: "8px" }}
              />
            ))}
          </div> */}

          {/* <CompanyTable
            loading={loading}
            list={studentList}
            columns={columnsNew}
            checkBoxSelection={true}
            setSelectedRowsData={setSelectedRowsData}
            selectedRowData={selectedRowsData}
            selectedID={selectedID}
            setSelectedID={setSelectedID}
            filterDataText={filterDataText}

            fromStudentDetials={true}
          /> */}

          <StudentTab
            loading={loading}
            filters={filters}
            setFilters={setFilters}
            setClickSearchButton={setClickSearchButton}
            setSelectedRowsData={setSelectedRowsData}
            selectedRowsData={selectedRowsData}
            clickSearchButton={clickSearchButton}
            value={value}
            setValue={setValue}
            //navbarData={navbarData}
            filterList={filterList}
            applyClickedScore={applyClickedScore}
            setApplyClickedScore={setApplyClickedScore}
            filterDataText={filterDataText}
            setFilterDataText={setFilterDataText}
            collegeId={collegeId}
            userRolesPermission={userRolesPermission}
            //setCallNavbar={setCallNavbar}
            //clearData={clearStudentData}
            selectedID={selectedID}
            setSelectedID={setSelectedID}
            selectedColumnFields={selectedColumnFields}
            setSelectedColumnFields={setSelectedColumnFields}
            exportLoading={exportLoading}
            handleExport={handleExport}
            fromStudentSharer={true}
            authority={authority}
            studentListFromSharer={studentList}
          />

          <TrackPasscode
            trackPasscode={trackPasscode}
            setTrackPasscode={setTrackPasscode}
            checkPasscode={checkPasscode}
            open={openPasscode}
            handleClickOpen={handleOpenPasscode}
            handleClose={handleClosePasscode}
            fromStudentDetials={true}
          />
          {/* <TrackSuccessfully /> */}
          {/* <InterviewScheduling /> */}
          {/* <Interview /> */}
        </>
      </div>
    </div>
  );
}
