import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import OnGoing from "../../OnGoing";
import NoDataFunc from "../../../NoDataSVG/index1";
import OffCampusDetails from "./OffCampusDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function StudentTab({ data }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkName = (name) => {
    switch (name) {
      case "ONGOING":
        return "On Going";
      case "ONCAMPUS":
        return "On Campus";
      case "OFFCAMPUS":
        return "Off Campus";
      case "REJECTED":
        return "Rejected";
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{ backgroundColor: "#fff", boxShadow: "none" }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {Object.keys(data)?.map((item, index) => (
            <Tab
              label={`${checkName(item)} (${data[item]?.length})`}
              {...a11yProps(index)}
            />
          ))}
          {/* <Tab label="Placed (2)" {...a11yProps(1)} />
          <Tab label="Rejected (4)" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      {Object?.keys(data).map((item, index) => (
        <TabPanel value={value} index={index}>
          {data[item]?.length > 0 && item === "OFFCAMPUS" ? (
            data[item]?.map((item2) => (
              <OffCampusDetails OffCampusDetailsData={item2} />
            ))
          ) : data[item]?.length > 0 ? (
            data[item]?.map((item2) => (
              <OnGoing onCampusOnGoingRejectedData={item2} />
            ))
          ) : (
            <NoDataFunc height={"150px"} width={"150px"} />
          )}
        </TabPanel>
      ))}
      {/* <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}

      {/* New Off Campus Card Info Section */}
      {/* <OffCampusDetails /> */}
    </div>
  );
}
