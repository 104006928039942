import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  Button,
  Chip,
  Grid,
  TextField,
} from "@material-ui/core";
import pSBC from "shade-blend-color";
import { Autocomplete } from "@material-ui/lab";
import defaultCompany from "../../../assets/png/default_college.png";
import CloseIcon from "@material-ui/icons/Close";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import { BackendBaseApi } from "../../../constants/constants";
import axios from "axios";
import { useAlert } from "react-alert";
import { getWebsiteLogo } from "../../../utility/getWebsiteLogo";
const useStyles = makeStyles((theme) => ({
  divider: {
    height: 28,
    margin: 4,
  },
  rootchip: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    paddingLeft: "0px",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#B0B6BA",
    fontSize: "14px",
  },
  placementBox: {
    border: "1px solid #C4C4C4",
    borderRadius: "8px",
    padding: 5,
    position: "relative",
    minHeight: "55px",
    "&:hover": {
      borderColor: "#000",
    },
  },
}));
export default function AddMultipleCompany({
  open,
  handleClose,
  collegesList = [],
  jobDetails,
  setListedCompany,
  jobDetailsError,
  setJobDetailsError,
  setCollegesWithoutCompany,
  setCompanyCheckLoader,
  setJobDetails,
  setCompanyAdded,
}) {
  const classes = useStyles();
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const alert = useAlert();

  const [showMore, setShowMore] = React.useState(false);
  const [loader, setLoader] = useState(false);

  const handleAddCompany = async () => {
    try {
      if (jobDetails?.company_id && collegesList.length) {
        setLoader(true);
        const collegeIds = collegesList.map((college) => college.id).join(",");
        console.log("collegeIds123", collegeIds, collegesList);
        const url = `${BackendBaseApi.SANCHALAN}api/manage_job/add-college/?college_ids=${collegeIds}&company_id=${jobDetails?.company_id}`;

        const res = await axios.get(url, { withCredentials: true });
        if (res?.data?.success) {
          setJobDetailsError({ ...jobDetailsError, company: null });
          try {
            setCompanyCheckLoader(true);
            handleClose();
            setListedCompany(false);
            setCompanyAdded(false);
            setJobDetailsError({
              ...jobDetailsError,
              company: "no_validation",
            });
            setCollegesWithoutCompany([]);

            const url = `${BackendBaseApi.SANCHALAN}api/manage_job/company-check/?college_ids=${collegeIds}&company_id=${jobDetails?.company_id}`;

            const res = await axios.get(url, { withCredentials: true });
            if (res?.data?.success) {
              setListedCompany(res?.data?.data?.status);
              if (!res?.data?.data?.status) {
                setJobDetailsError({
                  ...jobDetailsError,
                  company: "no_validation",
                });
                setCollegesWithoutCompany(res?.data?.data?.data);
              } else {
                setCompanyAdded(true);
              }
            } else {
              setJobDetails({
                ...jobDetails,
                company: null,
                company_id: null,
                company_name: null,
                company_website: null,
                company_logo: null,
                company_address: null,
              });
              alert.error("Something went wrong, Try again.");
            }
            setCompanyCheckLoader(false);
          } catch (error) {
            setCompanyCheckLoader(false);
            alert.error("Something went wrong, Try again.");
            throw error;
          }
        } else {
          alert.error("Company not added, Try again.");
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      alert.error("Company not added, Try again.");

      throw error;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <Box style={{ padding: 5, fontSize: "14px" }}>
          <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
            <Grid item xs={12}>
              <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                Add Company
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img
                src={
                  getWebsiteLogo(
                    jobDetails?.company_logo,
                    jobDetails?.company_website,
                    defaultCompany
                  )
                  // jobDetails?.company_logo || defaultCompany
                }
                style={{
                  height: 120,
                  width: 120,
                  borderRadius: "50%",
                  border: "1px solid #D9D9D9",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Company Name "
                variant="outlined"
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                disabled
                fullWidth
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={jobDetails?.company_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Company Website "
                variant="outlined"
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                fullWidth
                disabled
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={jobDetails?.company_website}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                label="Location"
                variant="outlined"
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                fullWidth
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={jobDetails?.company_address}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Box
                className={classes.placementBox}
                style={{
                  border: "1px solid #C4C4C4",
                  borderColor: "#B0B6BA",
                }}
              >
                <Box
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    top: "-12px",
                    left: 10,
                  }}
                >
                  <span
                    style={{
                      color: "#B0B6BA",
                      fontSize: "10px",
                      padding: "0 5px",
                    }}
                  >
                    Missing Company Colleges
                  </span>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  <div className={classes.rootchip}>
                    {!showMore
                      ? collegesList

                          ?.slice(0, 5)
                          ?.map((data, index) => (
                            <Chip
                              className={classes.chip}
                              color="primary"
                              variant="outlined"
                              size="small"
                              label={data?.name}
                            />
                          ))
                      : collegesList?.map((data, index) => (
                          <Chip
                            className={classes.chip}
                            color="primary"
                            variant="outlined"
                            size="small"
                            label={data?.name}
                          />
                        ))}
                    {collegesList?.length > 5 && (
                      <Button
                        color="primary"
                        onClick={() => {
                          setShowMore(!showMore);
                        }}
                      >
                        {" "}
                        {!showMore
                          ? `+${collegesList?.length - 5} more`
                          : "Hide"}
                      </Button>
                    )}
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                style={{ height: "45px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ boxShadow: "none", height: "45px" }}
                onClick={handleAddCompany}
              >
                {loader ? <CustomButtonCircularProgress /> : "Add Company"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
}
