import { Typography, Grid, Button, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CampusPlacementReportsCard from "../../components/Cards/CampusPlacementReports/CampusPlacementReportsCard";
import CampusReportActionbar from "../../components/Input/CampusReportActionBar/CampusReportActionbar";
import CourseStudent from "../../components/Cards/CampusPlacementReports/CourseStudent";
import LastYearCard from "../../components/Cards/CampusPlacementReports/LastYearCard";
import StartPerformerSection from "../../components/Cards/CampusPlacementReports/StartPerformerSection";
import { useSelector } from "react-redux";
import { collegeDetailsSelectorFn } from "../../api/Domain/selector";
import StarPerformer from "../../components/Cards/CampusPlacementReports/StarPerformer";
import axios from "axios";
import { BackendBaseApi } from "../../constants/constants";
import { useAlert } from "react-alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@material-ui/styles";
import { collegeIdFn } from "../../api/SignIn/selector";
const useStyles = makeStyles((theme) => ({
  label: {
    padding: "10px",
    color: "white",
    borderRadius: "2em 0 0 2em",
    padding: "0.5em 1.5em",
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    right: "-20px",
  },
}));
function CampusPlacementReports() {
  const classes = useStyles();
  const collegeDetail = useSelector(collegeDetailsSelectorFn);
  // const collegeId = collegeDetail?.college_id;
  const collegeId = useSelector(collegeIdFn);

  const [selectedPlacement, setSelectedPlacement] = useState(null);
  const [starPerformer, setStarPerformer] = useState(false);
  const [cardData, setCardData] = useState(null);
  const alert = useAlert();
  const [loadingReportCard, setLoadingReportCard] = useState(false);

  const handleClickStar = () => {
    setStarPerformer((prev) => !prev);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        <div>
          <Typography variant="h2">Campus Placement Reports</Typography>
          <Typography variant="body2" color="secondary">
            Visualize all Placement Data with Bar Graphs & Pie Charts
          </Typography>
        </div>
        <div className={classes.label}>
          <Typography style={{ color: "white" }}>BETA VERSION</Typography>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        <CampusReportActionbar
          selectedPlacement={selectedPlacement}
          setSelectedPlacement={setSelectedPlacement}
          collegeId={collegeId}
          loadingReportCard={loadingReportCard}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <CampusPlacementReportsCard
          selectedPlacement={selectedPlacement}
          collegeId={collegeId}
          setStarPerformer={setStarPerformer}
          starPerformer={starPerformer}
          setLoadingReportCard={setLoadingReportCard}
        />
      </div>
      {/* <div style={{ marginTop: "20px" }}>
        <StartPerformerSection />
      </div> */}
      {!starPerformer ? (
        <>
          <div>
            <CourseStudent
              selectedPlacement={selectedPlacement}
              collegeId={collegeId}
            />
          </div>
          <div>
            <LastYearCard
              selectedPlacement={selectedPlacement}
              collegeId={collegeId}
            />
          </div>
        </>
      ) : (
        <div style={{ marginTop: "15px", marginBottom: 15 }}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              display: "flex",
              marginBottom: "20px",
              alignItems: "center",
              height: 40,
            }}
            onClick={handleClickStar}
          >
            <ArrowBackIcon /> Back to Reports
          </Button>
          <StartPerformerSection
            collegeId={collegeId}
            selectedPlacement={selectedPlacement}
            starPerformer={starPerformer}
          />
        </div>
      )}
    </div>
  );
}

export default CampusPlacementReports;
