import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button } from "@material-ui/core";
import { set } from "date-fns";
import { useSelector } from "react-redux";
import { collegeDetailSelectorFn } from "../../../api/SignIn/selector";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "none",
    // boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
  text: {
    marginBottom: "7px",
  },
}));

export default function OtherJob({ data, setJobId }) {
  const classes = useStyles();
  function changeJobIdInLink(job_id) {
    setJobId(job_id);
    const res = window.location.href.lastIndexOf("/");
    const nums = window.location.href.slice(res + 1).split("_");
    window.location.href =
      window.location.href.slice(0, res + 1) + job_id + "_" + nums[1];
  }

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Other Open Job {`(${data.length})`}
          </Typography>
          {data?.map((item) => {
            return (
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                }}
              >
                <Typography
                  variant="body2"
                  noWrap
                  style={{ color: "#6C757D", cursor: "pointer" }}
                  onClick={() => {
                    changeJobIdInLink(item?.id);
                  }}
                >
                  {item?.job_title}
                </Typography>{" "}
                <ArrowForwardIosRoundedIcon
                  style={{ color: "#6C757D", fontSize: "14px" }}
                />
              </div>
            );
          })}
        </Paper>
      </div>
    </>
  );
}
