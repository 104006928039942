import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./offer.css";
import NextOfferArrow from "./NextOfferArrow";
import PreOfferArrow from "./PreOfferArrow";
import ModalVideo from "react-modal-video";
import "../../../style/reactVideo.css";
import { Skeleton } from "@material-ui/lab";

function Offer({ squareBannerData, loading }) {
  var settings = {
    // className: "",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextOfferArrow />,
    prevArrow: <PreOfferArrow />,

    // arrows: false,
  };

  const [isOpen, setOpen] = useState(false);
  const [isvideo, setvideo] = useState();

  const handleClickOpen = (id) => {
    setOpen(true);
    setvideo(id);
  };

  const temp = squareBannerData?.filter((data) => data.banner_id === isvideo);

  const handleClose = () => {
    setOpen(false);
  };

  const VID_REGEX =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  return (
    <>
      <div className="slider-container" style={{ height: "100%" }}>
        {" "}
        {loading ? (
          <>
            <Slider {...settings}>
              {squareBannerData?.map((value, index) => (
                <div
                  style={{ height: "100%", width: "100%", cursor: "pointer" }}
                  key={index}
                  onClick={() => {
                    window.open(value?.banner_url, "_blank");
                  }}
                >
                  <div
                    style={{
                      height: "480px",
                      // width: "200px",
                      backgroundImage: `url(${value?.banner_path})`,
                      borderRadius: 8,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </>
        ) : (
          <Skeleton
            animation="wave"
            variant="rect"
            style={{
              height: "480px",
              borderRadius: 10,
            }}
          />
        )}
      </div>
      <div>
        {/* {temp?.map((item) => (
          <ModalVideo
            channel="youtube"
            videoId={item?.redirection_url?.match(VID_REGEX)[1]}
            youtube={{ mute: 0, autoplay: 0 }}
            isOpen={isOpen}
            onClose={handleClose}
          />
        ))} */}
      </div>
    </>
  );
}

export default Offer;
