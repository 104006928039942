import React, { useEffect, useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";

const whatsApp = (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.140625"
      width="54.8592"
      height="54.8592"
      rx="27.4296"
      fill="#075E54"
    />
    <path
      d="M13.0885 27.3562C13.0877 29.9171 13.7621 32.4177 15.0445 34.6218L12.9658 42.1524L20.7327 40.1317C22.8809 41.2921 25.2878 41.9001 27.7337 41.9003H27.7401C35.8145 41.9003 42.3873 35.3808 42.3907 27.3676C42.3923 23.4847 40.8697 19.8334 38.1033 17.0863C35.3374 14.3395 31.6589 12.826 27.7395 12.8242C19.6642 12.8242 13.0919 19.3433 13.0886 27.3562"
      fill="url(#paint0_linear_5595_3122)"
    />
    <path
      d="M12.5649 27.3501C12.5641 30.0032 13.2626 32.5932 14.5907 34.8762L12.4375 42.6768L20.4828 40.5836C22.6996 41.7829 25.1954 42.4152 27.7351 42.4161H27.7416C36.1057 42.4161 42.9146 35.6622 42.9182 27.3621C42.9196 23.3396 41.3422 19.5571 38.477 16.7116C35.6115 13.8665 31.8014 12.2985 27.7416 12.2969C19.3761 12.2969 12.5682 19.0499 12.5649 27.3501ZM17.3562 34.483L17.0558 34.0099C15.793 32.0175 15.1265 29.7152 15.1274 27.351C15.13 20.4521 20.7885 14.8393 27.7464 14.8393C31.1159 14.8407 34.2826 16.1441 36.6644 18.5088C39.046 20.8738 40.3566 24.0175 40.3558 27.3612C40.3527 34.2601 34.6941 39.8736 27.7416 39.8736H27.7366C25.4729 39.8724 23.2527 39.2692 21.3164 38.1292L20.8556 37.8581L16.0814 39.1001L17.3562 34.483Z"
      fill="url(#paint1_linear_5595_3122)"
    />
    <path
      d="M23.9477 21.0573C23.6636 20.4308 23.3647 20.4182 23.0945 20.4072C22.8733 20.3977 22.6204 20.3984 22.3677 20.3984C22.1148 20.3984 21.7039 20.4928 21.3566 20.8691C21.009 21.2458 20.0293 22.1559 20.0293 24.0071C20.0293 25.8584 21.3882 27.6474 21.5776 27.8987C21.7673 28.1495 24.201 32.0699 28.0554 33.5781C31.2587 34.8315 31.9106 34.5822 32.6058 34.5194C33.3011 34.4568 34.8494 33.6095 35.1653 32.7308C35.4814 31.8523 35.4814 31.0993 35.3867 30.9419C35.2919 30.7851 35.039 30.691 34.6598 30.5029C34.2804 30.3147 32.4161 29.4044 32.0686 29.2788C31.7209 29.1534 31.4681 29.0908 31.2152 29.4675C30.9623 29.8437 30.2361 30.691 30.0148 30.9419C29.7937 31.1934 29.5724 31.2248 29.1933 31.0366C28.8138 30.8478 27.5925 30.4509 26.1436 29.1692C25.0163 28.1718 24.2553 26.9402 24.0341 26.5635C23.8128 26.1873 24.0104 25.9834 24.2005 25.7959C24.3709 25.6273 24.5798 25.3565 24.7696 25.1369C24.9587 24.9171 25.0218 24.7604 25.1483 24.5094C25.2748 24.2583 25.2115 24.0385 25.1168 23.8503C25.0218 23.6621 24.2849 21.8013 23.9477 21.0573Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5595_3122"
        x1="1484.21"
        y1="2945.64"
        x2="1484.21"
        y2="12.8242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1FAF38" />
        <stop offset="1" stop-color="#60D669" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5595_3122"
        x1="1536.47"
        y1="3050.29"
        x2="1536.47"
        y2="12.2969"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F9F9F9" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignContent: "space-between",
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 1000, // Ensures the button stays above other content
  },
  community: {
    backgroundColor: "#075E54",
    borderRadius: "50px",
    fontColor: "white",
    height: "32px",
    width: "215px",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    marginRight: "5px",
  },
}));

const FloatingWhatsAppButton = () => {
  const classes = useStyles();
  const [isVisible, setVisible] = useState(true);

  setTimeout(() => {
    setVisible(false);
  }, 3000);

  return (
    <div className={classes.root}>
      {/* {isVisible && ( */}
      <div className={classes.community}>
        <Typography
          style={{ alignSelf: "center", color: "#fff", fontSize: "14px" }}
        >
          Join our WhatsApp Community
        </Typography>
      </div>
      {/* )} */}

      <div>
        <a
          href="https://chat.whatsapp.com/JeiySqQuv5D9pG0CfaY9rY"
          target="_blank"
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          {whatsApp}
        </a>
      </div>
    </div>
  );
};

export default FloatingWhatsAppButton;
