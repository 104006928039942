import {
  AppBar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { sanchalanAxiosGetReq } from "../../../api/BaseApi/apiBase";
import {
  collegeFormCountSelectorFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { checkForToken } from "../../../constants/ApiCall";
import InfoToolTip from "../../Common/ToolTips/InfoToolTip";
import AddJobPlacement from "../../DialogSection/AddCompanyPlacement/AddJobPlacement";
import AddTeamMember from "../../DialogSection/AddTeamMember";
import ChangeJobStatus from "../../DialogSection/ChangeJobStatus";
import DeleteJobDialog from "../../DialogSection/DeleteJobDialog";
import OfferLetter from "../../DialogSection/OfferLetter/OfferLetter";
import RejectJobDialog from "../../DialogSection/RejectJobDialog";
import JobActionBar from "../../Input/JobActionBar";
import CompanyTableNew from "../../Table/CompanyTableNew";
import View from "../../Table/JobTable/View";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import {
  closedJobsField,
  columnsClosedJobs,
  columnsEJD,
  columnsEJDRejected,
  columnsJobInvitations,
  columnsUpcomingJobs,
  draftedJobsColumn,
  draftedJobsField,
  EJDField,
  EJDRejectedField,
  jobInvitationsField,
  openJobsField,
  upcomingJobsField,
} from "./openJobColumn";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
  primebtn: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function JobTab({
  loading,
  setSelectedRowsData,
  selectedRowsData,
  filters,
  setFilters,
  companyList,
  setCompanyList,
  jobTitleSearch,
  companyNameSearch,
  setJobTitleSearch,
  setCompanyNameSearch,
  setApplyClickedCTC,
  applyClickedCTC,
  jobNavbar,
  placementSession,
  teamMemberList,
  collegeId,
  userRolesPermission,
  setCallNavbar,
  selectedColumnFields,
  setSelectedColumnFields,
  setJobTypeClick,
  jobTypeClick,
  backendDrivenFilter,
  setBackendDrivenFilter,
  filterData,
  filterLoading,
  applicationStatusLoading,
  setApplicationStatusLoading,
  companyListTable,
  setCompanyListTable,
  companyListTotalCount,
  pageData,
  setPageData,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const jobList = useSelector((state) => state?.JobData?.details);
  const [selectedID, setSelectedID] = React.useState([]);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const [openOfferTable, setOpenOfferTable] = useState(false);
  const initialOfferLetterTableMeta = {
    jobId: "",
    data: [],
  };

  const [offerTableData, setOfferTableData] = useState(
    initialOfferLetterTableMeta
  );

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    if (!loading) setFilters({ ...filters, job_status: newValue });
  };

  const totalJobs = useSelector((store) => store?.JobData?.details?.length);
  const collegeFormCount = useSelector(collegeFormCountSelectorFn);

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  // useEffect(() => {
  //   setSelectedRowsData([]);
  //   // setFilters({ ...filters, job_title: "", company_name: "" });
  // }, [filters?.job_status]);

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);
  const alert = useAlert();

  // const [applicationStatusData, setApplicationStatusData] = React.useState({});
  // const [applicationStatusLoading, setApplicationStatusLoading] =
  //   React.useState(true);

  // const getApplicationStatusData = async (id) => {
  //   const url = `api/manage_job/job_applicant_count/?job_id=${id}`;
  //   setApplicationStatusLoading(true);
  //   try {
  //     const res = await sanchalanAxiosGetReq(url);
  //     if (res.data.success) {
  //       const statusResponse = res?.data?.data;
  //       setApplicationStatusData(statusResponse);
  //     } else {
  //       console.log("error ocuured");
  //     }
  //     setApplicationStatusLoading(false);
  //   } catch (error) {
  //     setApplicationStatusLoading(false);
  //     checkForToken(error, history, location, alert);
  //     if (error?.response?.status !== 401) {
  //       alert.error(error?.response?.data?.error);
  //     }
  //     throw error;
  //   }
  // };
  // const [companyListTable, setCompanyListTable] = useState([]);

  // useEffect(() => {
  //   if (companyList && companyList?.length > 0) {
  //     const arr = [...companyList];
  //     arr
  //       ?.slice(
  //         (pageData?.currentPage + 1) * pageData?.pageSize - pageData?.pageSize,
  //         (pageData?.currentPage + 1) * pageData?.pageSize
  //       )
  //       ?.forEach((item) => {
  //         applicationStatusData[item?.id]?.map((statusObj) => {
  //           switch (statusObj?.name) {
  //             case "Eligible":
  //               item.eligible_count = statusObj?.value;
  //               return;
  //             case "Applied":
  //               item.applied_count = statusObj?.value;
  //               return;
  //             case "Present":
  //               item.present_count = statusObj?.value;
  //               return;
  //             case "Hired":
  //               item.hired_count = statusObj?.value;
  //               return;
  //           }
  //         });
  //       });
  //     setCompanyListTable(arr);
  //   }
  // }, [companyList, applicationStatusData]);

  // useEffect(() => {
  //   if (companyList?.length && pageData && filters?.job_status === "OPEN") {
  //     let idString = ``;

  //     setCompanyListTable([]);
  //     const arr = [...companyList];
  //     arr
  //       ?.slice(
  //         (pageData?.currentPage + 1) * pageData?.pageSize - pageData?.pageSize,
  //         (pageData?.currentPage + 1) * pageData?.pageSize
  //       )
  //       ?.map((item) => {
  //         idString += `${item?.id},`;
  //       });
  //     getApplicationStatusData(idString.replace(/,\s*$/, ""));
  //   }
  // }, [pageData, companyList]);

  const checkFullfiledInbound = () => {
    let flag = true;

    if (selectedRowsData[0].job_source === "GETWORK_INBOUND") {
      flag = false;
    }
    if (selectedRowsData[0].job_source === "GETWORK_FULFILLED") {
      flag = false;
    }
    return flag;
  };

  const checkColumns = (param) => {
    switch (param) {
      case "OPEN":
        return [
          {
            field: "job_title",
            headerName: "Job Title",
            // type: "text",
            width: 170,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "45px",
                }}
              >
                Job Title
              </strong>
            ),
            renderCell: (params) => (
              <>
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    textAlign: "left",
                    display: "flex",
                    //    margin: "auto",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  <Grid item xs zeroMinWidth>
                    <Link
                      to={`/${
                        isEducationGroup || isCollegeGroup ? "job" : "job"
                      }/${params?.row?.job_title
                        ?.toLowerCase()
                        .replace(" ", "-")}-${params.row.id}`}
                      target={`${
                        isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                      }`}
                      style={{ textDecoration: "none", display: "contents" }}
                    >
                      {" "}
                      <Tooltip title={params.row.job_title} placement="right">
                        <Typography
                          color="primary"
                          noWrap
                          style={{ fontSize: "14px" }}
                        >
                          {params.row.job_title}
                        </Typography>
                      </Tooltip>
                    </Link>
                  </Grid>
                </Grid>
              </>

              // {/* <span
              //   style={{
              //     textAlign: "left",
              //     display: "flex",
              //     //   margin: "auto",
              //   }}
              // >
              //   <Link
              //     to={`/job/${params.row.job_title.toLowerCase().replace(" ", "-")}-${
              //       params.row.id
              //     }`}
              //     style={{ textDecoration: "none" }}
              //   >
              //     <Tooltip title={params.row.job_title} placement="right">
              //       <Typography color="primary" style={{ fontSize: "14px" }}>
              //         {params.row.job_title}
              //       </Typography>
              //     </Tooltip>
              //   </Link>
              // </span> */}
            ),
          },
          {
            field: "company",
            headerName: "Company",
            // type: "text",
            width: 170,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
                Company
              </strong>
            ),
            renderCell: (params) => (
              <>
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    textAlign: "left",
                    display: "flex",
                    //    margin: "auto",
                    fontSize: "14px",
                  }}
                >
                  <Grid item xs zeroMinWidth>
                    <Link
                      to={`/${
                        isEducationGroup || isCollegeGroup
                          ? "company-profile"
                          : "company-profile"
                      }/${params.row.company_id}`}
                      target={`${
                        isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                      }`}
                      style={{ textDecoration: "none", display: "contents" }}
                    >
                      {" "}
                      <Tooltip title={params.row.company} placement="right">
                        <Typography
                          color="primary"
                          noWrap
                          style={{ fontSize: "14px" }}
                        >
                          {params.row.company}
                        </Typography>
                      </Tooltip>
                    </Link>
                  </Grid>
                </Grid>
              </>

              // <span
              //   style={{
              //     textAlign: "left",
              //     display: "flex",
              //     //  margin: "auto",
              //     fontSize: "14px",
              //   }}
              // >
              //   {" "}
              //   <Link to="/company-details" style={{ textDecoration: "none" }}>
              //     <Tooltip title={params.row.company} placement="right">
              //       <Typography color="primary" style={{ fontSize: "14px" }}>
              //         {params.row.company}
              //       </Typography>
              //     </Tooltip>
              //   </Link>
              // </span>
            ),
          },
          {
            field: "job_type",
            // type: "text",
            headerName: "Job Type",
            sortable: false,
            width: 110,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
                Job Type
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.job_type}
              </span>
            ),
          },
          {
            field: "job_source",
            // type: "text",
            headerName: "Source",
            sortable: false,
            width: 140,
            renderHeader: (params) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <strong style={{ color: "#5a6268" }}>Source</strong>
              </div>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.job_source}
              </span>
            ),
          },
          {
            field: "posted_by",
            headerName: "Posted By",

            width: 140,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "2px" }}>Posted By</div>
              </strong>
            ),

            renderCell: (params) => (
              <span
                color="primary"
                style={{ fontSize: "14px", textAlign: "center", width: "100%" }}
              >
                {params?.row.posted_by_type &&
                params?.row.posted_by_type != "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ marginRight: "5px" }}>
                        {params?.row.posted_by_type}
                      </p>
                      <InfoToolTip
                        size={2}
                        rejectedDate={""}
                        label={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `Name: ${params?.row.posted_by?.user_name} <br /> Email Id: ${params?.row.posted_by?.user_email} <br /> Designation: ${params?.row.posted_by?.designation} <br /> ${entityInfo?.college} Name: ${params?.row.posted_by?.college_name}`,
                            }}
                          />
                        }
                      />
                    </div>
                  </>
                ) : (
                  "..."
                )}
              </span>
            ),
          },

          {
            field: "eligible_count",
            headerName: "Eligible Count",
            type: "number",
            width: 120,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "2px" }}>Eligible</div>
                <InfoToolTip
                  rejectedDate={""}
                  label={
                    "Eligibility is based on course, specialization, percentage, batch  of students."
                  }
                />
              </strong>
            ),

            renderCell: (params) => (
              <>
                <Link
                  to={`/${
                    isEducationGroup || isCollegeGroup
                      ? "eligible-students"
                      : "eligible-students"
                  }/${params?.row?.company}-${params?.row?.job_id}`}
                  target={`${
                    isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                  }`}
                  style={{
                    textDecoration: "none",
                    textAlign: "center",
                    display: "flex",
                    margin: "auto",
                    fontSize: "14px",
                  }}
                >
                  <Typography color="primary" style={{ fontSize: "14px" }}>
                    {params?.row?.eligible_count ?? "..."}
                  </Typography>
                </Link>
              </>
            ),
          },
          {
            field: "applied_count",
            headerName: "Applied Count",
            type: "number",
            width: 120,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "15px",
                }}
              >
                Applied
              </strong>
            ),
            renderCell: (params) => (
              <>
                <Link
                  to={`/${
                    isEducationGroup || isCollegeGroup
                      ? "eligible-students"
                      : "eligible-students"
                  }/${params?.row?.company}-${params?.row?.job_id}?applied=1`}
                  target={`${
                    isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                  }`}
                  style={{
                    textDecoration: "none",
                    textAlign: "center",
                    display: "flex",
                    color: "primary",
                    margin: "auto",
                    fontSize: "14px",
                  }}
                >
                  <Typography color="primary" style={{ fontSize: "14px" }}>
                    {params?.row?.applied_count ?? "..."}
                  </Typography>
                </Link>
              </>
            ),
          },
          {
            field: "present_count",
            headerName: "Present Count",
            type: "number",
            width: 120,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "15px",
                }}
              >
                Present
              </strong>
            ),
            renderCell: (params) => (
              <>
                <span
                  style={{
                    textAlign: "center",
                    display: "flex",
                    margin: "auto",
                  }}
                >
                  {" "}
                  <Link
                    to={`/${
                      isEducationGroup || isCollegeGroup ? "track" : "track"
                    }/?job_id=${params?.row?.id}`}
                    target={`${
                      isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                    }`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography color="primary" style={{ fontSize: "14px" }}>
                      {params?.row?.present_count ?? "..."}
                    </Typography>
                  </Link>
                </span>
              </>
            ),
          },
          {
            field: "hired_count",
            headerName: "Hired Count",
            type: "number",
            width: 120,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "25px",
                }}
              >
                Hired
              </strong>
            ),
            renderCell: (params) => (
              <>
                <span
                  style={{
                    textAlign: "center",
                    display: "flex",
                    margin: "auto",
                  }}
                >
                  {" "}
                  <Link
                    to={`/${
                      isEducationGroup || isCollegeGroup ? "track" : "track"
                    }/?job_id=${params?.row?.id}`}
                    target={`${
                      isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                    }`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography color="primary" style={{ fontSize: "14px" }}>
                      {params?.row?.hired_count ?? "..."}
                    </Typography>
                  </Link>
                </span>
              </>
            ),
          },
          {
            field: "students_offered",
            headerName: "Offered",
            type: "number",
            width: 120,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "25px",
                }}
              >
                Offered
              </strong>
            ),
            renderCell: (params) => (
              <>
                <span
                  style={{
                    textAlign: "center",
                    display: "flex",
                    margin: "auto",
                  }}
                >
                  {" "}
                  <Link
                    to={`/${
                      isEducationGroup || isCollegeGroup ? "track" : "track"
                    }/?job_id=${params?.row?.id}`}
                    target={`${
                      isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                    }`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography color="primary" style={{ fontSize: "14px" }}>
                      {params?.row?.student_offered ?? "..."}
                    </Typography>
                  </Link>
                </span>
              </>
            ),
          },
          {
            field: "students_joined",
            headerName: "Joined",
            type: "number",
            width: 120,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "25px",
                }}
              >
                Joined
              </strong>
            ),
            renderCell: (params) => (
              <>
                <span
                  style={{
                    textAlign: "center",
                    display: "flex",
                    margin: "auto",
                  }}
                >
                  {" "}
                  <Link
                    to={`/${
                      isEducationGroup || isCollegeGroup ? "track" : "track"
                    }/?job_id=${params?.row?.id}`}
                    target={`${
                      isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                    }`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography color="primary" style={{ fontSize: "14px" }}>
                      {params?.row?.student_joined ?? "..."}
                    </Typography>
                  </Link>
                </span>
              </>
            ),
          },

          {
            field: "ctc",
            // type: "text",
            headerName: "CTC(Min-Max)",
            sortable: false,
            width: 150,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
                CTC(Min-Max)
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.ctc}
              </span>
            ),
          },
          {
            field: "stipend",
            // type: "text",
            headerName: "Stipend(Min-Max)",
            sortable: false,
            width: 180,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
                Stipend(Min-Max)
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.stipend}
              </span>
            ),
          },
          {
            field: "vacancies",
            headerName: "Vacancies",
            // type: "text",
            width: 120,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
                Vacancies
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.vacancies ? params.row.vacancies : "NA"}
              </span>
            ),
          },
          {
            field: "applicants",
            type: "number",
            headerName: "Applicants",
            width: 120,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "5px" }}>
                Applicants
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                }}
              >
                {" "}
                <Link
                  to={`/track/?job_id=${params?.row?.id}`}
                  target={`${
                    isEducationGroup || isCollegeGroup ? "_self" : "_blank"
                  }`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography color="primary" style={{ fontSize: "14px" }}>
                    {params.row.applicants}
                  </Typography>
                </Link>
              </span>
            ),
          },
          {
            field: "job_stage",
            // type: "text",
            headerName: "Job Stage",
            sortable: false,
            width: 160,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
                Job Stage
              </strong>
            ),
            renderCell: (params) => (
              <Tooltip title={params.row.job_stage} placement="right">
                <Typography noWrap style={{ fontSize: "14px" }}>
                  {params.row.job_stage}
                </Typography>
              </Tooltip>
              // <span
              //   style={{
              //     textAlign: "center",
              //     display: "flex",
              //     margin: "auto",
              //     fontSize: "14px",
              //   }}
              // >
              //   {params.row.job_stage}
              // </span>
            ),
          },
          {
            field: "campus_drive_date",
            // type: "text",
            headerName: "Drive On",
            width: 145,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
                Drive On
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params?.row?.campus_drive_date?.slice(0, 10) || "NA"}
              </span>
            ),
          },
          {
            field: "end_date",
            // type: "text",
            headerName: "Apply By",
            width: 145,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
                Apply By
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params?.row?.end_date?.slice(0, 10)}
              </span>
            ),
          },
          {
            field: "created",
            headerName: "Action Date",
            type: "date",
            width: 150,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "20px" }}>
                Action Date
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.created}
              </span>
            ),
          },
          {
            field: "offer_letter_count",
            headerName: "Offer Letter Count",
            type: "number",
            width: 180,
            sortable: false,
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "20px",
                  display: "flex",
                  width: "100%",
                }}
              >
                Offer Letter Count
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                {params.row.offer_letter_count}
              </span>
            ),
          },
          {
            field: "Offer Letter",
            headerName: "Offer Letter",
            width: 160,
            type: "text",
            renderHeader: (params) => (
              <strong
                style={{
                  color: "#5a6268",
                  marginLeft: "25px",
                  // width:'10rem'
                }}
              >
                {"Offer Letter"}
              </strong>
            ),
            renderCell: (params) => (
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                <Button
                  className={classes?.btn_view}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ width: "5rem" }}
                  onClick={() => {
                    setOpenOfferTable(true);
                    setOfferTableData({
                      jobId: params?.row?.job_source.includes("GETWORK")
                        ? params?.row?.niyukti_job_id
                        : params.row.job_id,
                    });
                  }}
                >
                  {"View"}
                </Button>
              </span>
            ),
          },
          {
            field: "button",
            headerName: "Actions",
            type: "button",
            width: 100,
            renderHeader: (params) => (
              <strong style={{ color: "#5a6268", marginLeft: "6px" }}>
                Actions
              </strong>
            ),
            renderCell: (cellValues) => {
              return (
                <View
                  id={cellValues?.row?.id}
                  title={cellValues?.row?.job_title}
                  isEducationGroup={isEducationGroup}
                  isCollegeGroup={isCollegeGroup}
                />
              );
            },
          },
        ];
      case "CLOSED":
        return columnsClosedJobs(isEducationGroup, isCollegeGroup, entityInfo);
      case "UPCOMING":
        return columnsUpcomingJobs(entityInfo);
      case "DRAFTED":
        return draftedJobsColumn(isEducationGroup, isCollegeGroup, entityInfo);
      case "E-JNF":
        return columnsEJD(isEducationGroup, isCollegeGroup, entityInfo);
      case "REJECTED":
        return columnsEJDRejected(isEducationGroup, isCollegeGroup, entityInfo);
      case "JOBINVITATIONS":
        return columnsJobInvitations(entityInfo);
    }
  };

  const checkFields = (param) => {
    switch (param) {
      case "OPEN":
        return openJobsField;
      case "CLOSED":
        return closedJobsField; //upcomingJobsField
      case "UPCOMING":
        return upcomingJobsField;
      case "DRAFTED":
        return draftedJobsField;
      case "E-JNF":
        return EJDField;
      case "REJECTED":
        return EJDRejectedField;
      case "JOBINVITATIONS":
        return jobInvitationsField;
    }
  };

  const [columns, setColumns] = useState(checkColumns(filters?.job_status));

  const [shareColumns, setShareColumns] = useState(null);

  useEffect(() => {
    if (shareColumns && columns) {
      let temp = [...columns];
      let fieldsToShare = [];
      shareColumns?.map((item, index) => {
        temp[index].hide = item?.hide;
        if (!item?.hide) {
          if (
            !(
              item?.headerName === "Offer Letter" ||
              item?.headerName === "Actions"
            )
          )
            fieldsToShare?.push(item?.headerName);
        }
      });
      setSelectedColumnFields(fieldsToShare);
      setColumns(temp);
      localStorage?.setItem(
        `${filters?.job_status}_column_fields`,
        JSON.stringify(shareColumns)
      );
    }
  }, [shareColumns]);

  useEffect(() => {
    if (filters?.job_status) {
      setColumns(checkColumns(filters?.job_status));
      setCompanyListTable([]);
      setShareColumns(
        localStorage.getItem(`${filters?.job_status}_column_fields`) !== null
          ? JSON.parse(
              localStorage.getItem(`${filters?.job_status}_column_fields`)
            )
          : checkFields(filters?.job_status)
      );
    }
  }, [filters?.job_status]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
          //  borderBottom: ".5px solid #dddddd",
        }}
      >
        {" "}
        <NewTabs
          value={filters.job_status}
          disabled={loading}
          onChange={handleChange}
          aria-label="ant example"
        >
          {jobNavbar?.length > 0 &&
            jobNavbar?.map((item) => (
              <NewTab
                value={item.param}
                label={`${item.heading}(${item.count})`}
                wrapped
                {...a11yProps(item.param)}
              />
            ))}
        </NewTabs>
      </AppBar>

      <JobActionBar
        filters={filters}
        setFilters={setFilters}
        selectedRowsData={selectedRowsData}
        jobTitleSearch={jobTitleSearch}
        companyNameSearch={companyNameSearch}
        setJobTitleSearch={setJobTitleSearch}
        setCompanyNameSearch={setCompanyNameSearch}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        setApplyClickedCTC={setApplyClickedCTC}
        collegeId={collegeId}
        applyClickedCTC={applyClickedCTC}
        setSelectedRowsData={setSelectedRowsData}
        tableColumns={shareColumns}
        setTableColumns={setShareColumns}
        setJobTypeClick={setJobTypeClick}
        jobTypeClick={jobTypeClick}
        loading={
          // filters?.job_status !== "OPEN" ? loading : applicationStatusLoading
          loading
        }
        backendDrivenFilter={backendDrivenFilter}
        setBackendDrivenFilter={setBackendDrivenFilter}
        filterData={filterData}
        filterLoading={filterLoading}
      />

      {jobNavbar?.length > 0 &&
        jobNavbar?.map((item) => (
          <TabPanel value={filters.job_status} index={item.param}>
            {selectedRowsData?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  marginTop: "15px",
                  marginBottom: "20px",
                }}
              >
                {selectedRowsData?.length === 1 &&
                  // && parseInt(selectedRowsData[0].applicants) === 0
                  item.features.includes("edit") &&
                  //  && checkFullfiledInbound()
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "manage_job"
                  ) && (
                    <Button
                      color="primary"
                      variant="contained"
                      style={{
                        borderRadius: "8px",
                        marginRight: "10px",
                        boxShadow: "none",
                        padding: "7px",
                        height: "40px",
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        display: "flex",
                      }}
                      onClick={() => {
                        if (selectedRowsData[0]?.niyukti_job_id) {
                          history.push(
                            groupViewCheck(
                              isEducationGroup,
                              isCollegeGroup,
                              collegeId
                            )
                              ? `/post-job/edit-${selectedRowsData[0]?.id}?is_niyukti_job=true`
                              : `/post-job/edit-${selectedRowsData[0]?.id}?is_niyukti_job=true`
                          );
                        } else {
                          history.push(
                            groupViewCheck(
                              isEducationGroup,
                              isCollegeGroup,
                              collegeId
                            )
                              ? `/educational-group/post-job/edit-${selectedRowsData[0]?.id}`
                              : `/post-job/edit-${selectedRowsData[0]?.id}`
                          );
                        }
                      }}
                    >
                      Edit
                    </Button>
                  )}
                {/* {item.features.includes("delete") &&
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "manage_job"
                  ) &&
                  //  &&  checkFullfiledInbound()
                  selectedRowsData.every(
                    (item) => parseInt(item?.applicants) === 0
                  ) && (
                    <DeleteJobDialog
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      selectedID={selectedID}
                      setSelectedID={setSelectedID}
                      jobList={jobList}
                      fromEJD={item?.param === "E-JD" ? true : false}
                      collegeId={collegeId}
                      setCallNavbar={setCallNavbar}
                    />
                  )} */}
                {item.features.includes("add_team") &&
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "manage_job"
                  ) && (
                    <AddTeamMember
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      selectedID={selectedID}
                      setSelectedID={setSelectedID}
                      jobList={jobList}
                      teamMemberList={teamMemberList}
                      collegeId={collegeId}
                    />
                  )}
                {item.features.includes("change_placement_cycle") &&
                  selectedRowsData?.length > 0 &&
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "manage_job"
                  ) && (
                    <AddJobPlacement
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      selectedID={selectedID}
                      setSelectedID={setSelectedID}
                      jobList={jobList}
                      collegeId={collegeId}
                    />
                  )}
                {item.features.includes("change_job_status") &&
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "manage_job"
                  ) && (
                    <>
                      <ChangeJobStatus
                        selectedRowsData={selectedRowsData}
                        setSelectedRowsData={setSelectedRowsData}
                        selectedID={selectedID}
                        setSelectedID={setSelectedID}
                        jobList={jobList}
                        filters={filters}
                        collegeId={collegeId}
                        setCallNavbar={setCallNavbar}
                      />
                    </>
                  )}
                {item.features.includes("publish") &&
                  selectedRowsData?.length === 1 &&
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "manage_job"
                  ) && (
                    // <Publish selectedRowsData={selectedRowsData} setSelectedRowsData={setSelectedRowsData} selectedID={selectedID} setSelectedID={setSelectedID} jobList={jobList} />
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      className={classes.primebtn}
                      onClick={() => {
                        history.push(
                          `/post-job/draft-${selectedRowsData[0].job_id}`
                        );
                      }}
                    >
                      Publish
                    </Button>
                  )}

                {item.features.includes("accept") &&
                  selectedRowsData?.length === 1 &&
                  selectedRowsData[0].ejd_status === "PENDING" &&
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "accept_ejnf"
                  ) && (
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      className={classes.primebtn}
                      onClick={() => {
                        if (totalJobs < 1) {
                          history.push("/job-posting-form/full-time-job");
                        }
                        if (
                          selectedRowsData[0].ejd_comp_verification ===
                          "Verified"
                        )
                          history.push(
                            `/post-job/ejd-${selectedRowsData[0].job_id}`
                          );
                        else {
                          alert.error(
                            "Please Verify your Company First from Pending Companies Section in Manage Compaines"
                          );
                          history.push("/manage-companies");
                        }
                      }}
                    >
                      {collegeFormCount?.job_form_count > 0 ||
                      collegeFormCount?.internship_form_count > 0
                        ? "Accept & Post"
                        : "Please Create Your Form"}
                    </Button>
                  )}

                {item.features.includes("reject") &&
                  selectedRowsData?.length > 0 &&
                  selectedRowsData.every(
                    (item) => item.ejd_status === "PENDING"
                  ) &&
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "accept_ejnf"
                  ) && (
                    <RejectJobDialog
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      selectedID={selectedID}
                      setSelectedID={setSelectedID}
                      jobList={jobList}
                      collegeId={collegeId}
                    />
                  )}
              </div>
            )}

            <CompanyTableNew
              loading={
                // filters?.job_status !== "OPEN"
                //   ?
                loading
                // : applicationStatusLoading
              }
              // list={jobList}
              list={
                // /* companyList */ companyListTable?.length > 0
                //   ? companyListTable
                //   : []
                companyList?.length > 0 ? companyList : []
              }
              //columns={checkColumns(item.param)}
              columns={columns}
              setSelectedRowsData={setSelectedRowsData}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
              checkBoxSelection={item.param === "REJECTED" ? false : true}
              pageData={pageData}
              setPageData={setPageData}
              companyListTotalCount={companyListTotalCount}
              setCompanyList={setCompanyList}
            />
          </TabPanel>
        ))}
      {openOfferTable && (
        <OfferLetter
          open={openOfferTable}
          jobId={offerTableData?.jobId}
          handleClose={() => {
            setOpenOfferTable(false);
          }}
        />
      )}
    </div>
  );
}
