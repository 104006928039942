import { Breadcrumbs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { interviewList } from "../../api/Assignment/action";
import { collegeDetailSelectorFn, userRolesActionsFn } from "../../api/SignIn/selector";
import OnGoing from "../../components/Cards/Interview/OnGoing";
import CustomCircularProgress from "../../components/CircularProgressBar";
const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    height: "50px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
  },
  grid: {
    marginTop: "20px",
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function Interview() {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.assignment.interviewList);
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  var params = new URLSearchParams(location.search);
  var job_id = params.get("job_id");
  var date = params.get("date");

 
  useEffect(() => {
    if (location?.search) {
      var params = new URLSearchParams(location.search);
      var job_id = params.get("job_id");
      var date = params.get("date");

      if(job_id || date){
        dispatch(interviewList(setLoading, alert, history, location, null, job_id, date));
      }
    }
  }, [ location?.search]);

  return (
    <>
      {/* <Layout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/interview-calendar" style={{ textDecoration: "none" }}>
              <Typography color="primary" variant="body2">
                Interviews
              </Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} color="inherit" href="/getting-started/installation/" onClick={handleClick}>
              <Typography color="secondary" variant="body2">
                {date ? date : data[0]?.job_title}
              </Typography>
            </Link>
          </Breadcrumbs>
          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Interview</Typography>
              <Typography variant="body2" color="secondary">
                Managing all your interviews now in one place
              </Typography>
            </div>
          </div>

          <div style={{ marginTop: "40px", marginBottom: "40px" }}></div>
          <OnGoing data={data} />
        </div>
      )}
      {/* </Layout> */}
    </>
  );
}
