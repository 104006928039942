import {
  Button,
  //FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  //InputLabel,
  //MenuItem,
  Paper,
  Radio,
  RadioGroup,
  // Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { DropzoneAreaBase } from "material-ui-dropzone";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { changeState, GET_THEME_DETAILS } from "../../action";
import {
  affliationgGet,
  cityGet,
  CollegeGet,
  CollegePatch,
  stateGet,
} from "../../api/CollegeProfile/action";
import { isEducationGroupFn, themeSelectorFn } from "../../api/Domain/selector";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  userRolesActionsFn,
  entityInfoSelectorFn
} from "../../api/SignIn/selector";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import Editor from "../../components/Editor";
import ColorPicker from "../../components/Form/ColorPicker";
import ColorPickerSecondary from "../../components/Form/ColorPicker/secondary";
import Calendar from "../../components/Input/Calendar/index4";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import pSBC from "shade-blend-color";
import CustomCircularProgress from "../../components/CircularProgressBar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { alpha, styled } from "@mui/material/styles";
import MuiCustomTheme from "../../components/NewMUITheme/MuiCustomTheme";
import { praveshAxiosGetReq } from "../../api/BaseApi/apiBase";

const NewFormControl = styled(FormControl)(({ theme }) => ({
  "& label.Mui-focused": {
    //  color: "#C4C4C4",
  },
  "& label": {
    color: "#7e7e7e",
  },
  "& .MuiInput-underline:after": {
    //borderBottomColor: "#C4C4C4",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&:hover fieldset": {
      //borderColor: "#C4C4C4",
    },
    "&.Mui-focused fieldset": {
      //borderColor: "#C4C4C4",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#fff",
    display: "flex",
    // justifyContent: "center",
    borderRadius: "8px",
    padding: "30px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  button: {
    borderRadius: "8px",
    paddingLeft: "40px",
    display: "flex",
    height: "48px",
    marginRight: "12px",
    paddingRight: "40px",
    boxShadow: "none",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textbutton: {
    padding: "0px",
  },
  text: {
    fontWeight: "bold",
    marginTop: "5px",
  },
  text1: {
    fontWeight: "bold",
    marginTop: "20px",
  },
  btn: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "flex-start",
  },
  formControl: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  customize: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  DropzoneArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function CollegeProfile() {
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingGet, setLoadingGet] = useState(false);

  const [editor, setEditor] = useState();
  const [mediaLinks, setMediaLinks] = useState([
    { id: 1, Name: "LinkedIn", value: "" },
    { id: 2, Name: "Facebook", value: "" },
    { id: 3, Name: "Instagram", value: "" },
  ]);
  const theme_change = useSelector(themeSelectorFn);
  const entityInfo = useSelector(entityInfoSelectorFn);

  const [state, setState] = useState({
    displayColorPicker: false,
    color: theme_change?.main,
  });
  const [state1, setState1] = useState({
    displayColorPicker: false,
    color: theme_change?.secondary,
  });
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const data = useSelector((state) => state?.collegeProfile?.collegeData);
  const affiliateddata = useSelector(
    (state) => state?.collegeProfile?.affiliatedData
  );
  const city = useSelector((state) => state?.collegeProfile?.cityData);
  const stateList = useSelector((state) => state?.collegeProfile?.stateData);
  const userRolesPermission = useSelector(userRolesActionsFn);

  const [collegeProfile, setCollegeProfile] = React.useState({
    about_us: "",
    banner: "",
    city: "",
    city_id: "",
    college_address: "",
    college_id: "",
    college_name: "",
    college_type: "",
    country: "",
    country_id: 1,
    founded_in: "",
    latitude: "",
    logo: "",
    longitude: "",
    no_of_students: "",
    primary_color: "",
    secondary_color: "",
    sidebar: "",
    social_links: [],
    state: "",
    state_id: 12,
    university_id: 1,
    university_name: "",
    website: "",
    logoData: "",
    bannerData: "",
    date: "",
    college_passcode: "",
  });
  const isEducationGroup = useSelector(isEducationGroupFn);

  const [passcode, setPasscode] = useState("");
  const [errorForPasscode, setErrorForPasscode] = useState("");

  useEffect(() => {
    if (
      passcode &&
      passcode !== collegeProfile.college_passcode &&
      passcode !== data?.passcode
    ) {
      const timeOutId = setTimeout(() => {
        handlePasscode(passcode);
      }, 1000);

      return () => clearTimeout(timeOutId);
    }
  }, [passcode]);

  const handlePasscode = async (value) => {
    if (value.length < 3 || value.length > 8) return;

    const endPoint = `/api/college/check-passcode/?passcode=${value}${
      collegeId ? "&college_id=" + collegeId : ""
    }`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      const data = res?.data?.data;

      if (res?.data?.success) {
        if (data?.flag) {
          alert.success(data?.message);
          setCollegeProfile((prevData) => ({
            ...prevData,
            college_passcode: value.toUpperCase(),
          }));
          setErrorForPasscode("");
        } else {
          setCollegeProfile((prevData) => ({
            ...prevData,
            college_passcode: "",
          }));
          setErrorForPasscode(data?.message);
        }
      }
    } catch (error) {
      console.error("Passcode failed", error);
    }
  };

  useEffect(() => {
    mediaLinks[0].value = data?.social_links[0]?.value;
    mediaLinks[1].value = data?.social_links[1]?.value;
    mediaLinks[2].value = data?.social_links[2]?.value;
  }, [data]);

  useEffect(() => {
    setCollegeProfile({
      ...collegeProfile,
      about_us: data?.about_us,
      bannerData: data?.banner,
      city: data?.city,
      city_id: data?.city_id,
      college_address: data?.college_address,
      college_name: data?.college_name,
      college_id: data?.college_id,
      college_type: data?.college_type,
      country: data?.country,
      country_id: data?.country_id,
      founded_in: data?.founded_in,
      latitude: data?.latitude,
      logoData: data?.logo,
      longitude: data?.longitude,
      no_of_students: data?.no_of_students,
      primary_color: data?.primary_color,
      secondary_color: data?.secondary_color,
      sidebar: data?.sidebar,
      social_links: data?.social_links,
      state: data?.state,
      state_id: data?.state_id,
      university_id: data?.university_id,
      university_name: data?.university_name,
      website: data?.website,
      date: moment(data?.founded_in),
      college_passcode: data?.passcode,
    });
    if (isEducationGroup) {
      setState({ ...state, color: data?.primary_color });
      setState1({ ...state1, color: data?.secondary_color });
    }
    setPasscode(data?.passcode);
  }, [data]);

  useEffect(() => {
    if (collegeProfile?.state_id) {
      dispatch(cityGet(alert, history, location, collegeProfile?.state_id));
    }
  }, [collegeProfile.state_id]);

  useEffect(() => {
    setCollegeProfile({ ...collegeProfile, about_us: editor });
  }, [editor]);

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };

  useEffect(() => {
    if (collegeId) {
      dispatch(
        CollegeGet(
          // setLoading,
          alert,
          history,
          location,
          collegeId
        )
      );
      dispatch(affliationgGet(alert, history, location));
      dispatch(stateGet(alert, history, location));
    }
  }, [collegeId]);

  const save = () => {
    if (!collegeProfile?.college_passcode || !passcode) {
      alert.error("Please Enter Passcode");
      return;
    }
    if (
      collegeProfile?.college_passcode &&
      (collegeProfile?.college_passcode?.length > 8 ||
        collegeProfile?.college_passcode < 3)
    ) {
      alert.error(
        "Please Enter valid Passcode. Length of Passcode must be between 3 to 8 characters."
      );
    } else {
      const formData = new FormData();

      formData.append("name", collegeProfile.college_name);
      formData.append("description", collegeProfile.about_us);
      formData.append("address", collegeProfile.college_address);
      formData.append("organization_type", collegeProfile.college_type);
      formData.append("university_id", collegeProfile.university_id);
      formData.append("university_name", collegeProfile.university_name);
      formData.append("size", collegeProfile.no_of_students);
      formData.append(
        "year_founded",
        moment(collegeProfile.date).format("yyyy-MM-DD")
      );
      formData.append("website", collegeProfile.website);
      if (collegeProfile.logo) formData.append("logo", collegeProfile.logo);
      if (collegeProfile.banner)
        formData.append("banner", collegeProfile.banner);
      formData.append(
        "latitude",
        collegeProfile.latitude ? collegeProfile.latitude : ""
      );
      formData.append(
        "longitude",
        collegeProfile.longitude ? collegeProfile.longitude : ""
      );
      formData.append("primary_color", state.color);
      formData.append("secondary_color", state1.color);
      formData.append("city", collegeProfile.city_id);
      formData.append("state", collegeProfile.state_id);
      formData.append("social_links", JSON.stringify(mediaLinks));
      formData.append("passcode", collegeProfile?.college_passcode);

      setLoading(true);
      dispatch(
        CollegePatch(setLoading, alert, history, location, formData, collegeId)
      );
    }
  };

  return (
    <>
      {loadingGet ? (
        <CustomCircularProgress />
      ) : (
        <>
          {/* <Layout> */}
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div>
                <Typography variant="h2">{entityInfo?.college} Profile</Typography>
                <Typography variant="body2" color="secondary">
                  Manage your {entityInfo?.college} Profile Settings
                </Typography>
              </div>
              <div>
                <Link
                  to={`/public/college/profile/${collegeId}`}
                  target="_blank"
                  // rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    View {entityInfo?.college} Profile
                  </Button>
                </Link>
              </div>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div
                  style={{
                    display: "flex",
                    width: "70%",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ marginBottom: "10px" }}
                  >
                    {entityInfo?.college} Passcode
                  </Typography>
                  <div className={classes.link}>
                    <InputBase
                      className={classes.input}
                      placeholder={passcode}
                      inputProps={{
                        className: classes.labellink,
                        "aria-label": "https://getwork-ejd-mba.in",
                      }}
                      value={passcode}
                      onChange={(e) => {
                        let passcodeValue = e.target.value.toUpperCase();
                        if (passcodeValue.length <= 8) {
                          setPasscode(passcodeValue);
                        }
                      }}
                    />
                    <CopyToClipboard
                      text={collegeProfile?.college_passcode}
                      onCopy={() => alert.success(`${entityInfo?.college} Passcode Copied!`)}
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-label="copy"
                      >
                        <FileCopyOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                  <FormHelperText
                    style={{
                      marginLeft: !0,
                      marginBottom: "4px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Your Passcode must have more than 3 characters, and less
                    than 8
                    <span style={{ color: "red" }}>
                      {" "}
                      {errorForPasscode ? `${errorForPasscode}` : ""}
                    </span>
                  </FormHelperText>

                  <Typography variant="body2" className={classes.text}>
                  {entityInfo?.college} Name
                  </Typography>
                  <TextField
                    floatingLabelStyle={{ color: "" }}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    required
                    label={`${entityInfo?.college} Name`}
                    name="College Name"
                    value={collegeProfile?.college_name}
                    onChange={(e) =>
                      setCollegeProfile({
                        ...collegeProfile,
                        college_name: e.target.value,
                      })
                    }
                  />
                  {/* <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={state.checkedB}
                      onChange={handleChange}
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ marginRight: "10px" }}>
                      University
                    </Typography>
                  }
                /> */}
                  <FormControl
                    component="fieldset"
                    style={{ marginTop: "10px" }}
                  >
                    {/* <FormLabel component="legend">Gender</FormLabel> */}

                    <RadioGroup
                      defaultValue="Goverment"
                      aria-label="gender"
                      name="customized-radios"
                      className={classes.customize}
                      value={collegeProfile?.college_type}
                      onChange={(e) =>
                        setCollegeProfile({
                          ...collegeProfile,
                          college_type: e.target.value,
                        })
                      }
                    >
                      <Typography
                        variant="body2"
                        style={{ marginRight: "10px" }}
                      >
                        {entityInfo?.college} Type
                      </Typography>
                      <FormControlLabel
                        value="Government"
                        control={<StyledRadio />}
                        label={
                          <Typography variant="body2">Government</Typography>
                        }
                      />
                      <FormControlLabel
                        value="SemiPrivate"
                        control={<StyledRadio />}
                        label={
                          <Typography variant="body2">Semi-Private</Typography>
                        }
                      />
                      <FormControlLabel
                        value="Private"
                        control={<StyledRadio />}
                        label={<Typography variant="body2">Private</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ marginBottom: "10px" }}
                  >
                    Affiliated From University{" "}
                  </Typography>
                  <MuiCustomTheme>
                    <NewFormControl
                      variant="outlined"
                      /// className={classes.formControl}
                      style={{ borderRadius: "8px" }}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        required
                        // className={classes.floatingLabelFocusStyle}
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                      >
                        Affiliated From University
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={collegeProfile?.university_name}
                        label="Affiliated From University"
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: 300, borderRadius: "8px" },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <Typography variant="body2" noWrap>
                            None
                          </Typography>
                        </MenuItem>
                        {affiliateddata?.map((item) => {
                          return (
                            <MenuItem
                              onClick={() =>
                                setCollegeProfile({
                                  ...collegeProfile,
                                  university_id: item.university_id,
                                  university_name: item.university_name,
                                })
                              }
                              value={item.university_name}
                              name={item.university_id}
                            >
                              <Typography variant="body2" noWrap>
                                {item.university_name}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </NewFormControl>
                  </MuiCustomTheme>
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ marginTop: "10px" }}
                  >
                    {entityInfo?.college} Website
                  </Typography>
                  <TextField
                    floatingLabelStyle={{ color: "" }}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    label={`${entityInfo?.college} Website link`}
                    name="College Website link"
                    value={collegeProfile?.website}
                    onChange={(e) =>
                      setCollegeProfile({
                        ...collegeProfile,
                        website: e.target.value,
                      })
                    }
                  />
                  <div style={{ marginTop: "20px" }}>
                    <Calendar
                      selectedDate={collegeProfile?.founded_in}
                      title="Established In"
                      onDateChange={(date) =>
                        setCollegeProfile({
                          ...collegeProfile,
                          founded_in: moment(date).format("YYYY"),
                          date: moment(date).format("YYYY-MM-DD"),
                        })
                      }
                    />
                  </div>

                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ marginBottom: "15px" }}
                  >
                    {entityInfo?.college} Address
                  </Typography>
                  <TextField
                    floatingLabelStyle={{ color: "" }}
                    variant="outlined"
                    //  margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    label={`${entityInfo?.college} Address`}
                    name="College Address"
                    value={collegeProfile?.college_address}
                    onChange={(e) =>
                      setCollegeProfile({
                        ...collegeProfile,
                        college_address: e.target.value,
                      })
                    }
                  />
                  <br />
                  <MuiCustomTheme>
                    <NewFormControl
                      variant="outlined"
                      //  className={classes.formControl}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        //className={classes.floatingLabelFocusStyle}
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                      >
                        State
                      </InputLabel>
                      <Select
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={collegeProfile?.state}
                        label="State"
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: 300, borderRadius: "8px" },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <Typography variant="body2" noWrap>
                            None
                          </Typography>
                        </MenuItem>
                        {stateList?.map((item) => {
                          return (
                            <MenuItem
                              onClick={() =>
                                setCollegeProfile({
                                  ...collegeProfile,
                                  state_id: item.state_id,
                                  state: item.state,
                                })
                              }
                              value={item.state}
                              name={item.state_id}
                            >
                              <Typography variant="body2" noWrap>
                                {item.state}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </NewFormControl>
                  </MuiCustomTheme>
                  <br />
                  <MuiCustomTheme>
                    <NewFormControl
                      variant="outlined"
                      //className={classes.formControl}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        //className={classes.floatingLabelFocusStyle}
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                      >
                        City
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={collegeProfile?.city}
                        label="City"
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: 300, borderRadius: "8px" },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <Typography variant="body2" noWrap>
                            None
                          </Typography>
                        </MenuItem>
                        {city?.map((item) => {
                          return (
                            <MenuItem
                              onClick={() =>
                                setCollegeProfile({
                                  ...collegeProfile,
                                  city_id: item.city_id,
                                  city: item.city,
                                })
                              }
                              value={item.city}
                              name={item.city_id}
                            >
                              <Typography variant="body2" noWrap>
                                {" "}
                                {item.city}{" "}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </NewFormControl>
                  </MuiCustomTheme>
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  >
                    Total Students
                  </Typography>
                  <MuiCustomTheme>
                    <NewFormControl
                      variant="outlined"
                      // className={classes.formControl}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        //className={classes.floatingLabelFocusStyle}
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                      >
                        Total Student in {entityInfo?.college}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={collegeProfile?.no_of_students}
                        onChange={(e) =>
                          setCollegeProfile({
                            ...collegeProfile,
                            no_of_students: e.target.value,
                          })
                        }
                        label={`Total Student in ${entityInfo?.college}`}
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: 300, borderRadius: "8px" },
                          },
                        }}
                      >
                        <MenuItem value={"1-100"}>
                          {" "}
                          <Typography variant="body2" noWrap>
                            1-100
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"101-500"}>
                          {" "}
                          <Typography variant="body2" noWrap>
                            101-500
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"501-1000"}>
                          {" "}
                          <Typography variant="body2" noWrap>
                            501-1000
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"1000+"}>
                          {" "}
                          <Typography variant="body2" noWrap>
                            1000+
                          </Typography>
                        </MenuItem>
                      </Select>
                    </NewFormControl>
                  </MuiCustomTheme>
                  <div style={{ display: "flex" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          className={classes.text}
                          style={{ marginTop: "20px" }}
                        >
                          Map Location
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        {" "}
                        <TextField
                          floatingLabelStyle={{ color: "" }}
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          fullWidth
                          label="Enter Latitude"
                          name="Enter Latitude"
                          value={collegeProfile?.latitude}
                          onChange={(e) =>
                            setCollegeProfile({
                              ...collegeProfile,
                              latitude: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          floatingLabelStyle={{ color: "" }}
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          fullWidth
                          label="Enter Longitude"
                          name="Enter Longitude"
                          value={collegeProfile?.longitude}
                          onChange={(e) =>
                            setCollegeProfile({
                              ...collegeProfile,
                              longitude: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Typography variant="body2" className={classes.text}>
                  {entityInfo?.college} Description
                    <span style={{ color: "#181818", fontWeight: "normal" }}>
                      {" "}
                      (Max 512 Characters){" "}
                    </span>
                  </Typography>
                  <br />
                  <Editor
                    state={editor}
                    setState={setEditor}
                    desc={data?.about_us}
                  />
                  <br />
                  <Typography variant="body2" className={classes.text1}>
                    Social Media
                  </Typography>
                  <TextField
                    floatingLabelStyle={{ color: "" }}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    label="LinkedIn URL"
                    name="LinkedIn URL"
                    value={mediaLinks[0]?.value}
                    onChange={(e) => {
                      var arr = [...mediaLinks];
                      arr[0].value = e.target.value;
                      setMediaLinks(arr);
                    }}
                  />
                  <TextField
                    floatingLabelStyle={{ color: "" }}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    label="Facebook URL"
                    name="Facebook URL"
                    value={mediaLinks[1]?.value}
                    onChange={(e) => {
                      var arr = [...mediaLinks];
                      arr[1].value = e.target.value;
                      setMediaLinks(arr);
                    }}
                  />
                  <TextField
                    floatingLabelStyle={{ color: "" }}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    label="Instagram URL"
                    name="Instagram URL"
                    value={mediaLinks[2]?.value}
                    onChange={(e) => {
                      var arr = [...mediaLinks];
                      arr[2].value = e.target.value;
                      setMediaLinks(arr);
                    }}
                  />

                  {/* <Typography variant="body2" className={classes.text}>
                  Ranking
                </Typography>
                <TextField
                  floatingLabelStyle={{ color: "" }}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label="Ranking Agency Name"
                  name="Ranking Agency Name"
                />
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    className={classes.floatingLabelFocusStyle}
                  >
                    Rank Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={degree}
                    onChange={handleChange}
                    label="Rank Year"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Btech</MenuItem>
                    <MenuItem value={20}>BCA</MenuItem>
                    <MenuItem value={30}>MCA</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  floatingLabelStyle={{ color: "" }}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label="College Rank"
                  name="College Rank"
                />
                <Button
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  Add New Ranking
                </Button>

                <Typography variant="body2" className={classes.text}>
                  College Cutoff
                </Typography>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    required
                    className={classes.floatingLabelFocusStyle}
                  >
                    Entrance Exam Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={degree}
                    onChange={handleChange}
                    label="Entrance Exam Name"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Btech</MenuItem>
                    <MenuItem value={20}>BCA</MenuItem>
                    <MenuItem value={30}>MCA</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    required
                    className={classes.floatingLabelFocusStyle}
                  >
                    Exam Cutoff Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={degree}
                    onChange={handleChange}
                    label="Exam Cutoff Year"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Btech</MenuItem>
                    <MenuItem value={20}>BCA</MenuItem>
                    <MenuItem value={30}>MCA</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    required
                    className={classes.floatingLabelFocusStyle}
                  >
                    Exam Cutoff Course/Branch
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={degree}
                    onChange={handleChange}
                    label="Exam Cutoff Course/Branch"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Btech</MenuItem>
                    <MenuItem value={20}>BCA</MenuItem>
                    <MenuItem value={30}>MCA</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    required
                    className={classes.floatingLabelFocusStyle}
                  >
                    Cutoff Rank/Marks
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={degree}
                    onChange={handleChange}
                    label="Cutoff Rank/Marks"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Btech</MenuItem>
                    <MenuItem value={20}>BCA</MenuItem>
                    <MenuItem value={30}>MCA</MenuItem>
                  </Select>
                </FormControl> */}
                  <Typography
                    variant="h6"
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    Upload Logo
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                      <DropzoneAreaBase
                        dropzoneClass={classes.DropzoneArea}
                        acceptedFiles={["image/*"]}
                        dropzoneText={"Drag and drop an Image here"}
                        showPreviewsInDropzone={true}
                        showPreviews={true}
                        // dropzoneText={"Drag and drop an Image here"}
                        onAdd={(files) => {
                          setCollegeProfile({
                            ...collegeProfile,
                            logo: files[0].file,
                            logoData: files[0].data,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {collegeProfile?.logoData ? (
                        <div style={{ width: "300px", marginTop: "10px" }}>
                          <img
                            src={collegeProfile?.logoData}
                            width="200px"
                            height="200px"
                          />
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  {/* <div style={{ width: "300px", marginTop: "10px" }}>
                  <DropzoneAreaBase
                    dropzoneClass={classes.DropzoneArea}
                    acceptedFiles={["image/*"]}
                    dropzoneText={"Drag and drop an Image here"}
                    showPreviewsInDropzone={true}
                    showPreviews={true}
                    dropzoneText={"Drag and drop an Image here"}
                    onAdd={files => {
                      setCollegeProfile({
                        ...collegeProfile,
                        logo: files[0].file,
                        logoData: files[0].data,
                      });
                    }}
                  />
                  <br />
                  {collegeProfile?.logoData ? (
                    <div style={{ width: "300px", marginTop: "10px" }}>
                      <img
                        src={collegeProfile?.logoData}
                        width="200px"
                        height="200px"
                      />
                    </div>
                  ) : null}
                </div> */}
                  <br />
                  <Typography
                    variant="h6"
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    Upload Banner Image
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <DropzoneAreaBase
                        dropzoneClass={classes.DropzoneArea}
                        acceptedFiles={["image/*"]}
                        showPreviewsInDropzone={true}
                        showPreviews={true}
                        dropzoneText={"Drag and drop an Image here"}
                        onAdd={(files) =>
                          setCollegeProfile({
                            ...collegeProfile,
                            banner: files[0].file,
                            bannerData: files[0].data,
                          })
                        }
                        // onAlert={(message, variant) =>
                        //   console.log(`${variant}: ${message}`)
                        // }
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      {collegeProfile?.bannerData ? (
                        <div style={{ width: "500px", marginTop: "10px" }}>
                          <img
                            src={collegeProfile?.bannerData}
                            width="400px"
                            height="200px"
                          />
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <br />
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ marginBottom: 10 }}
                  >
                    Choose {entityInfo?.college} Theme
                  </Typography>
                  {/* <SketchPicker /> */}
                  <div style={{ display: "flex" }}>
                    <span style={{ marginRight: 20 }}>
                      Primary{" "}
                      <ColorPicker
                        data={state}
                        handleChange={(color) => {
                          setState({ ...state, color: color.hex });
                          let data = {
                            main: color.hex,
                            hover: "#0069D9",
                            disable: "#58AAFF",
                            contrast_text: "#fff",
                            secondary: theme_change?.secondary,
                          };
                          if (!isEducationGroup)
                            handleFilterChange(GET_THEME_DETAILS, data);
                        }}
                        handleClose={() =>
                          setState({ ...state, displayColorPicker: false })
                        }
                        handleClick={() =>
                          setState({
                            ...state,
                            displayColorPicker: !state.displayColorPicker,
                          })
                        }
                      />
                    </span>
                    <span>
                      Secondary{" "}
                      <ColorPickerSecondary
                        state={state1}
                        setState={setState1}
                        fromCollege={true}
                      />
                    </span>
                  </div>
                  {/* <Typography variant="body2" className={classes.text}>
                  College/ University Type
                </Typography>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    required
                    className={classes.floatingLabelFocusStyle}
                  >
                    College Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={degree}
                    onChange={handleChange}
                    label="College Type"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Btech</MenuItem>
                    <MenuItem value={20}>BCA</MenuItem>
                    <MenuItem value={30}>MCA</MenuItem>
                  </Select>
                </FormControl> */}
                </div>
              </Paper>
              {userRolesPermission?.some(
                (item) => item.access_control_key === "create_college_profile"
              ) && (
                <div className={classes.btn}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ minWidth: "120px" }}
                    className={classes.button}
                    onClick={() => save()}
                  >
                    {loading ? <CustomButtonCircularProgress /> : <> Save</>}{" "}
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
