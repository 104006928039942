import React from "react"

export default function ManageJobs({ primaryColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="99" height="101" viewBox="0 0 99 101">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_5064" data-name="Rectangle 5064" width="99" height="101" transform="translate(380 270)" fill="#fff" stroke="#707070" strokeWidth="1" />
        </clipPath>
      </defs>
      <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-380 -270)" clipPath="url(#clip-path)">
        <path id="job-portfolio" d="M30.486,26.716H63.632a1.358,1.358,0,1,0,0-2.717H30.486a1.358,1.358,0,0,0,0,2.717ZM97.593,46.7V6.792A6.8,6.8,0,0,0,90.8,0H52.221a6.8,6.8,0,0,0-6.792,6.792V9.466H38.637a6.8,6.8,0,0,0-6.792,6.792v1.358H27.769a6.8,6.8,0,0,0-6.792,6.792V35.551A6.8,6.8,0,0,0,15,42.293V85.944a6.8,6.8,0,0,0,6.792,6.792H95.51a6.8,6.8,0,0,0,6.792-6.792V53.16A6.8,6.8,0,0,0,97.593,46.7ZM48.146,6.792a4.079,4.079,0,0,1,4.075-4.075H90.8a4.079,4.079,0,0,1,4.075,4.075V46.368H84.009V16.258a6.8,6.8,0,0,0-6.792-6.792H48.146ZM34.562,16.258a4.079,4.079,0,0,1,4.075-4.075h38.58a4.079,4.079,0,0,1,4.075,4.075v30.11H73.141V24.408a6.8,6.8,0,0,0-6.792-6.792H34.562ZM23.694,24.408a4.079,4.079,0,0,1,4.075-4.075h38.58a4.079,4.079,0,0,1,4.075,4.075v21.96H64.21a4.1,4.1,0,0,1-2.918-1.23l-7.4-7.589A6.832,6.832,0,0,0,49.026,35.5H23.694ZM99.586,85.944a4.079,4.079,0,0,1-4.075,4.075H21.792a4.079,4.079,0,0,1-4.075-4.075V42.293a4.079,4.079,0,0,1,4.075-4.075H49.026a4.1,4.1,0,0,1,2.916,1.23l7.406,7.589a6.827,6.827,0,0,0,4.861,2.049h31.3a4.079,4.079,0,0,1,4.075,4.075Zm-28.98-25.79H67.293v-1.4a3.668,3.668,0,0,0-3.664-3.664H53.667A3.668,3.668,0,0,0,50,58.754v1.4H46.7A3.441,3.441,0,0,0,43.256,63.6V80.566A3.441,3.441,0,0,0,46.7,84.008H70.606a3.441,3.441,0,0,0,3.441-3.441V63.6A3.441,3.441,0,0,0,70.606,60.154Zm-17.877-1.4a.947.947,0,0,1,.947-.947h9.962a.947.947,0,0,1,.947.947v1.4H52.727ZM71.33,80.57a.725.725,0,0,1-.725.725H46.7a.725.725,0,0,1-.725-.725V70.052l8.482,4.233v1.746a2.63,2.63,0,0,0,2.626,2.626h3.144a2.63,2.63,0,0,0,2.626-2.626V74.285l8.478-4.233Zm-14.162-4.63V72.979h2.963v2.961ZM71.33,67.016l-8.908,4.445a2.625,2.625,0,0,0-2.2-1.2H57.079a2.625,2.625,0,0,0-2.2,1.2l-8.906-4.445V63.6a.725.725,0,0,1,.725-.725H70.606a.725.725,0,0,1,.725.725ZM64.991,30.791a1.358,1.358,0,0,0-1.358-1.358H30.486a1.358,1.358,0,0,0,0,2.717H63.632a1.358,1.358,0,0,0,1.358-1.358Z" transform="translate(370.849 273.264)" fill={primaryColor} />
      </g>
    </svg>
  )
}