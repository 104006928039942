import { Divider, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import hand from "../../assets/icons/calendar-university.png";
import graduated from "../../assets/icons/graduated.png";
import office from "../../assets/icons/office-building.png";
import s1 from "../../assets/icons/suitcase.png";
import NoDataFunc from "../../components/NoDataSVG/index1";
import Title from "./Title";
import { useSelector } from "react-redux";
import {
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    display: "flex",
    height: "130px",
    borderRadius: "8px",
    boxShadow: "none",
    // padding: theme.spacing(2),

    "&:hover": {
      transform: "scale(1.09)",
      transition: "transform 0.2s",
    },
    //textAlign: "center",
    // backgroundColor: "#c8facd",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100px",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
  },
  text: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "left",
  },
}));

export default function UniversityInfo({ data }) {
  const classes = useStyles();
  const IconSelector = (icon_key) => {
    switch (icon_key) {
      case "connected_companies":
        return <img src={office} height="41px" width="45px" />;
      case "open_jobs":
        return <img src={s1} height="41px" width="45px" />;
      case "registered_students":
        return <img src={graduated} height="41px" width="45px" />;
      case "placement_cycles":
        return <img src={hand} height="41px" width="45px" />;
    }
  };

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };
  return (
    <>
      <Paper
        style={{ borderRadius: "8px", padding: "20px", boxShadow: "none" }}
      >
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title>Overview</Title>
          {data?.total_count && (
            <Typography variant="h6">
              {`Total ${entityInfo?.college} :
              ${data?.total_count}`}
            </Typography>
          )}
        </div>
        <Divider style={{ marginBottom: "30px" }} />

        <Grid container spacing={3}>
          {data?.overview?.length > 0 ? (
            data?.overview?.map((item) => (
              <Grid item xs={12} md={3}>
                <Grid item xs={12}>
                  <Link to={item?.link} style={{ textDecoration: "none" }}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: item?.colour1 }}
                    >
                      <div
                        className={classes.item}
                        style={{
                          backgroundColor: item?.colour2,
                        }}
                      >
                        {IconSelector(item?.icon)}
                      </div>
                      <div className={classes.text}>
                        <Typography variant="h6" style={{ color: "#000" }}>
                          {item?.title}
                        </Typography>
                        <br />
                        <Typography
                          variant="h2"
                          style={{ fontWeight: "bold" }}
                          color="inherit"
                        >
                          {item?.count}
                        </Typography>
                      </div>
                    </Paper>
                  </Link>
                </Grid>
              </Grid>
            ))
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <NoDataFunc width={"300Px"} height={"200px"} />
                <Typography style={{ fontSize: "16px" }}>
                  {" "}
                  No Data Available
                </Typography>{" "}
              </div>
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
}
