import { Grid, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";

export const columns = [
  // { field: "id", headerName: "No", width: 100 },
  {
    field: "name",
    headerName: "name",
    width: 230,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "75px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid container wrap="nowrap">
          <Grid
            item
            xs
            zeroMinWidth
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "8px",
                marginRight: "10px",
                width: "8px",
                backgroundColor:
                  params.row.current_status === "ACTIVE"
                    ? "#51c03e"
                    : "#f64a3f",
                borderRadius: "50%",
              }}
            />
            <Link
              to={`/team-profile/${params?.row?.id}`}
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params.row.name} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.name}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //  margin: "auto",
      //     fontSize: "14px",
      //     alignItems: "center",
      //   }}
      // >
      //   <div
      //     style={{
      //       height: "8px",
      //       marginRight: "10px",
      //       width: "8px",
      //       backgroundColor:
      //         params.row.current_status === "ACTIVE" ? "#51c03e" : "#f64a3f",
      //       borderRadius: "50%",
      //     }}
      //   />
      //   <Link
      //     to={`/team-profile/${params?.row?.id}`}
      //     style={{ textDecoration: "none" }}
      //   >
      //     <Typography color="primary" style={{ fontSize: "14px" }}>
      //       {params.row.name}
      //     </Typography>
      //   </Link>
      // </span>
    ),
  },

  {
    field: "email",
    headerName: "Email",
    type: "Email",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "70px",
        }}
      >
        Email
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Tooltip title={params.row.email} placement="right">
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params.row.email}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     // margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   {params.row.email}
      // </span>
    ),
  },
  {
    field: "phone",
    headerName: "Phone Number",

    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "40px",
        }}
      >
        Phone Number
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.phone}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },

  {
    field: "designation",
    headerName: "Phone Number",

    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "40px",
        }}
      >
        Designation
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.designation}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },
  {
    field: "assign_job",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "50px",
        }}
      >
        Assign Job
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.assign_job}
      </span>
    ),
  },
  {
    field: "current_status",
    width: 200,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Status
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.current_status === "ACTIVE" ? "Active" : "Blocked"}
      </span>
    ),
  },
  // {
  //   field: "button",
  //   headerName: "",
  //   type: "button",
  //   width: 60,
  //   renderHeader: (params) => <strong style={{ color: "#5a6268" }}></strong>,
  //   renderCell: (cellValues) => {
  //     return <Action />;
  //   },
  // },
  // {
  //   field: "date",
  //   headerName: "Created on",
  //   type: "date",
  //   width: 160,
  //   editable: true,
  // },
];

export const columns2 = [
  {
    field: "name",
    headerName: "name",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "85px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid container wrap="nowrap">
          <Grid
            item
            xs
            zeroMinWidth
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
              alignItems: "center",
            }}
          >
            {/* <div
              style={{
                height: "8px",
                marginRight: "10px",
                width: "8px",
                backgroundColor: params.row.current_status === "ACTIVE" ? "#51c03e" : "#f64a3f",
                borderRadius: "50%",
              }}
            /> */}

            <Tooltip title={params.row.name} placement="right">
              <span> {params.row.name}</span>
            </Tooltip>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //  margin: "auto",
      //     fontSize: "14px",
      //     alignItems: "center",
      //   }}
      // >
      //   <div
      //     style={{
      //       height: "8px",
      //       marginRight: "10px",
      //       width: "8px",
      //       backgroundColor:
      //         params.row.current_status === "ACTIVE" ? "#51c03e" : "#f64a3f",
      //       borderRadius: "50%",
      //     }}
      //   />
      //   <Link
      //     to={`/team-profile/${params?.row?.id}`}
      //     style={{ textDecoration: "none" }}
      //   >
      //     <Typography color="primary" style={{ fontSize: "14px" }}>
      //       {params.row.name}
      //     </Typography>
      //   </Link>
      // </span>
    ),
  },

  {
    field: "email",
    headerName: "Email",
    type: "Email",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "90px",
        }}
      >
        Email
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Tooltip title={params.row.email} placement="right">
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params.row.email}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     // margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   {params.row.email}
      // </span>
    ),
  },
  {
    field: "phone",
    headerName: "Phone Number",

    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Phone Number
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.phone}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },

  {
    field: "designation",
    headerName: "Phone Number",

    width: 230,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Designation
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.designation}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },
  {
    field: "user_role_name",
    width: 220,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        User Role
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.user_role_name}
      </span>
    ),
  },
  // {
  //   field: "current_status",
  //   width: 200,
  //   renderHeader: params => (
  //     <strong
  //       style={{
  //         color: "#5a6268",
  //         marginLeft: "60px",
  //       }}
  //     >
  //       Status
  //     </strong>
  //   ),
  //   renderCell: params => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params.row.current_status === "ACTIVE" ? "Active" : "Blocked"}
  //     </span>
  //   ),
  // },
  // {
  //   field: "button",
  //   headerName: "",
  //   type: "button",
  //   width: 60,
  //   renderHeader: (params) => <strong style={{ color: "#5a6268" }}></strong>,
  //   renderCell: (cellValues) => {
  //     return <Action />;
  //   },
  // },
  // {
  //   field: "date",
  //   headerName: "Created on",
  //   type: "date",
  //   width: 160,
  //   editable: true,
  // },
];

export const columnsTeamRejected = [
  {
    field: "name",
    headerName: "name",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "85px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid container wrap="nowrap">
          <Grid
            item
            xs
            zeroMinWidth
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
              alignItems: "center",
            }}
          >
            {/* <div
              style={{
                height: "8px",
                marginRight: "10px",
                width: "8px",
                backgroundColor: params.row.current_status === "ACTIVE" ? "#51c03e" : "#f64a3f",
                borderRadius: "50%",
              }}
            /> */}

            <Tooltip title={params.row.name} placement="right">
              <span> {params.row.name}</span>
            </Tooltip>
          </Grid>
        </Grid>
      </>
    ),
  },

  {
    field: "email",
    headerName: "Email",
    type: "Email",
    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "90px",
        }}
      >
        Email
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Tooltip title={params.row.email} placement="right">
              <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                {params.row.email}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </>
    ),
  },
  {
    field: "phone",
    headerName: "Phone Number",

    width: 250,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Phone Number
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.phone}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },
  {
    field: "designation",
    // headerName: "Phone Number",

    width: 230,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Designation
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.designation}
      </span>
    ),
    // valueGetter: params =>
    //   `${params.getValue(params.id, "firstName") || ""} ${
    //     params.getValue(params.id, "lastName") || ""
    //   }`,
  },
  {
    field: "user_role_name",
    width: 220,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        User Role
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.user_role_name}
      </span>
    ),
  },
  {
    field: "last_updated_by",
    width: 220,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Rejected By
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.last_updated_by}
      </span>
    ),
  },
  {
    field: "reason",
    width: 220,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Rejection Reason
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.reason}
      </span>
    ),
  },
  {
    field: "update_time",
    width: 220,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "60px",
        }}
      >
        Rejected At
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.update_time
          ? moment(params?.row?.update_time).format("YYYY-MM-DD")
          : null}
      </span>
    ),
  },
];
