import {
  Box,
  Button,






  Checkbox,



  FormControlLabel, Grid,
  IconButton,
  InputBase,




  Menu,
  MenuItem, Paper,
  TextField,
  Typography,



  withStyles
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import PhoneOutlined from "@material-ui/icons/PhoneOutlined";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { niyuktiAxiosGetReq } from "../../api/BaseApi/apiBase";
import { collegeIdFn, isEducationGroupFn } from "../../api/SignIn/selector";
import JobCard from "../../components/Cards/LiveJobCard/JobCard";
import JobCardSkelton from "../../components/Cards/LiveJobCard/JobCardSkelton";
import TabWithApply from "../../components/Cards/LiveJobCard/TabWithApply";
import NewFilterDrawer from "../../components/DialogSection/FilterDrawer/NewFilterDrawer";
import NoDataFunc from "../../components/NoDataSVG/index1";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    // justifyContent: "space-between",
    // alignItems: "center",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  filterSection: {
    display: "flex",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  actionBar: {
    marginBottom: "1.25rem",
    marginTop: "20px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  iconButton: {
    padding: 10,
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
    height: "50px",
  },
  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "12rem",
    boxShadow: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  buttonClear: {
    borderRadius: 8,
    width: 108,
    height: 50,
    color: "#6C757D",
    backgroundColor: "white",
    border: ".5px solid #6C757D",
    cursor: "pointer",
  },
  filtersCount: {
    // color: theme.palette.primary.main,
    color: "white",
    // backgroundColor: pSBC(0.8, theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    position: "fixed",
    height: "9%",
    width: "auto",
    top: "41%",
    // left: '80%',
    right: "58%",
  },
  pageLoader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    // alignItems: 'center',
  },
  noDataAvailableMessage: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  floatingLabelFocusStyle: {
    color: "#b8b8b8",
    alignSelf: "centre",
    fontSize: 14,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function LiveJob() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  const loaderRef = useRef(null);
  const collegeId = useSelector(collegeIdFn);
  const isEducationGroup = useSelector(isEducationGroupFn);

  const [allJobs, setAllJobs] = useState([]);
  const [pageloading, setPageloading] = useState(true);
  const initialJobsCount = {
    totalCounts: "",
    filteredJobCounts: "",
  };
  const [totalJobsCount, setTotalJobsCount] = useState(initialJobsCount);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [filterData, setFilterData] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterDataError, setFilterDataError] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [clickFilterApplyButton, setClickFilterApplyButton] = useState(false);
  const [showSkeletonCards, setShowSkeletonCards] = useState(false);
  const [filterPayLoad, setFilterPayLoad] = useState("");
  const [searchFilter, setSearchFilter] = useState(null);
  const [hrDetails, setHrDetails] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [currentJobDetailIndex, setCurrentJobDetailIndex] = useState(null);
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const url = window.location.toString().includes("brand-jobs");
  const [unLockHRContactCheck, setUnLockHRContactCheck] = useState(0);
  const [isLiveJobs, setIsLiveJobs] = useState(-1);
  const [bottomReached, setBottomReached] = useState(false);
  const initialFilters = {
    jobType: null,
    sortBy: null,
    searchByJobTiltle: null,
  };
  const [filterValues, setFilterValues] = useState(initialFilters);

  const [filterObject, setFilterObject] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClearFilters = () => {
    setSelectedFilters(null);
    setFilterPayLoad("");
    setFilterValues({ ...initialFilters });
    setUnLockHRContactCheck(0);
    setClearFilter(true);
    setSearchFilter("");
  };

  const handleUpdate = () => {
    setAllJobs([]);
    setCompanyId(null);
    setJobId(null);
    setTotalJobsCount(initialJobsCount);
    setClickSearchButton(false);
    setCurrentJobDetailIndex(null);
    setJobDetails(null);
    setHrDetails(false);
    setNextPageUrl(null);
    setPageNo(1);
    setFilterPayLoad("");
    setSelectedFilters(null);
    setSearchFilter("");
    setUnLockHRContactCheck(0);
    setFilterValues(initialFilters);
    setUnLockHRContactCheck(0);
    setClearFilter(true);
    setSelectedFilters(null);
    setFilterPayLoad("");
  };

  useEffect(() => {
    // Reset counter or perform any other necessary actions when route changes
    if (location.pathname === "/live-jobs") setIsLiveJobs(1);
    else setIsLiveJobs(0);
    handleUpdate();
  }, [location.pathname]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onPressingEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setFilterPayLoad(
        (prevFilterString) => prevFilterString + `&search=${searchFilter}`
      );
      setClickSearchButton(true);
    }
  };

  const handleJobDetails = (data, jobIndex) => {
    setJobDetails(data);
    setCurrentJobDetailIndex(jobIndex);
  };

  const handleNotInterestedCard = {
    undo: (jobIndex) => {
      let tempArr = allJobs[jobIndex];
      tempArr = { ...allJobs[jobIndex], removed: false };

      let tempAllJobs = [...allJobs];
      tempAllJobs.splice(jobIndex, 1, tempArr);
      setAllJobs([...tempAllJobs]);
    },
    notInterested: (jobIndex) => {
      let tempArr = allJobs[jobIndex];
      tempArr = { ...allJobs[jobIndex], removed: true };
      let tempAllJobs = [...allJobs];
      tempAllJobs.splice(jobIndex, 1, tempArr);
      setAllJobs([...tempAllJobs]);
    },
  };

  const handleforwardJobDetail = () => {
    if (currentJobDetailIndex < allJobs?.length - 1) {
      setJobDetails(allJobs[currentJobDetailIndex + 1]?.["job"]);
      setCurrentJobDetailIndex((jobIndex) => jobIndex + 1);
    }
  };
  const handleBackwardJobDetail = () => {
    // disabled for first job
    if (!(currentJobDetailIndex === 0)) {
      setJobDetails(allJobs[currentJobDetailIndex - 1]?.["job"]);
      setCurrentJobDetailIndex((jobIndex) => jobIndex - 1);
    }
  };

  useEffect(() => {
    if (selectedFilters && Object.keys(selectedFilters).length > 0) {
      let payloadString = "";

      Object.keys(selectedFilters).map((item) => {
        if (selectedFilters?.[item].length > 0) {
          const ids = selectedFilters?.[item]?.map(
            (filteredField) => filteredField?.id
          );
          payloadString = payloadString + `&${item}=${ids.join(",")}`;
        }
      });

      setFilterPayLoad(payloadString);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (isLiveJobs >= 0) getFilteredData();
  }, [filterPayLoad, unLockHRContactCheck, isLiveJobs]);

  const getFilteredData = async () => {
    const endPoint = `job/college_saas/brand_and_live_jobs/?filtered_response=true${filterPayLoad}&is_unlocked_hr_details=${unLockHRContactCheck}&is_live_jobs=${isLiveJobs}`;
    setPageloading(true);
    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      if (res?.data?.success) {
        setNextPageUrl(res?.data?.data?.["next"]);

        if (totalJobsCount?.totalCounts) {
          setTotalJobsCount((prevData) => ({
            ...prevData,
            filteredJobCounts: res?.data?.data?.["count"],
          }));
        } else {
          setTotalJobsCount((prevData) => ({
            ...prevData,
            totalCounts: res?.data?.data?.["count"],
          }));
        }

        setAllJobs([...res?.data?.data?.["results"]]);
        if (res?.data?.data?.["results"]?.length) {
          setJobId(res?.data?.data?.["results"][0]?.job_id);
          setCompanyId(res?.data?.data?.["results"][0]?.company?.company_id);
        } else {
          setJobId(null);
          setCompanyId(null);
        }
      }
      setPageloading(false);
    } catch (error) {
      setPageloading(false);
      throw error;
    }
  };

  const addNextPageJobs = async () => {
    setShowSkeletonCards(true);
    try {
      const res = await axios.get(nextPageUrl, { withCredentials: true });
      if (res?.data?.success) {
        setNextPageUrl(res?.data?.data?.["next"]);
        setAllJobs([...allJobs, ...res?.data?.data?.["results"]]);
      }
      setShowSkeletonCards(false);
    } catch (error) {
      setShowSkeletonCards(false);
      throw error;
    }
  };

  useEffect(() => {
    if (nextPageUrl) {
      addNextPageJobs();
    }
  }, [pageNo]);

  const getFiltersForRecommendedJobs = async () => {
    const endPoint = `job/filter/job_lake/`;

    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      const data = res?.data?.data;
      if (res?.data?.success) {
        let tempArr = { ...data };

        Object.keys(tempArr).forEach((header, index) => {
          let singlefilterArray = tempArr[header]?.data?.map((item) => {
            const modifiedItem = {
              ...item,
              is_selected: false,
            };
            return modifiedItem;
          });

          tempArr[header]["data"] = singlefilterArray;
        });
        setFilterObject(tempArr);
        setFilterData(tempArr);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // if (isLiveJobs >= 0) getFilteredData();
    getFiltersForRecommendedJobs();
  }, [isLiveJobs]);

  const getCount = () => {
    if (totalJobsCount?.totalCounts) {
      if (totalJobsCount?.filteredJobCounts === "")
        return `We have ${totalJobsCount?.totalCounts} jobs based on your preference.`;
      else
        return `We have ${totalJobsCount?.filteredJobCounts} jobs based on your preference.`;
    } else {
      return `We have 0 jobs based on your preference. `;
    }
  };

  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      setPageNo(pageNo + 1);
    }
  }, [inView]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div>
              <Typography variant="h2">
                {!isLiveJobs
                  ? "GetWork Brand Jobs (18K+)"
                  : "GetWork Live Jobs (18K+)"}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                style={{
                  fontWeight: "bold",
                }}
              >
                {getCount()}
              </Typography>
            </div>
            {/* {!isEducationGroup ? ( */}
            <div>
              <Link
                to={`/Contact`}
                target={`${isEducationGroup ? "_self" : "_blank"}`}
                style={{ textDecoration: "none", display: "contents" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PhoneOutlined />}
                  style={{
                    boxShadow: "none",
                    borderRadius: "8px",
                    height: "48px",
                    display: "flex",
                  }}
                  // onClick={() => {
                  //   const url = `${window.location.origin}/Contact`;
                  //   window.open(url, "_blank").focus();
                  //   // history.push(`/Contact`)
                  // }}
                >
                  Contact Support
                </Button>
              </Link>
            </div>
            {/* ) : null} */}
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Search by Job Title"
              inputProps={{
                style: { fontSize: "14px" },
              }}
              onKeyPress={onPressingEnter}
              value={searchFilter}
              onChange={(e) => {
                setSearchFilter(e.target.value);
                setClickSearchButton(false);
                if (e.target.value === "") {
                  if (filterPayLoad) {
                    setFilterPayLoad((prevFilterString) => {
                      let newfilterString = prevFilterString?.split("&search=");
                      setFilterPayLoad(newfilterString[0]);
                    });
                    setSearchFilter("");
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  if (searchFilter) {
                    setFilterPayLoad(
                      (prevFilterString) =>
                        prevFilterString + `&search=${searchFilter}`
                    );
                    setClickSearchButton(true);
                  }
                }
              }}
            />

            {searchFilter && clickSearchButton && (
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setFilterPayLoad((prevFilterString) => {
                    let newfilterString = prevFilterString?.split("&search=");
                    setFilterPayLoad(newfilterString[0]);
                  });
                  setClickSearchButton(false);
                  setSearchFilter("");
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
            {!clickSearchButton ? (
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  if (searchFilter) {
                    setFilterPayLoad(
                      (prevFilterString) =>
                        prevFilterString + `&search=${searchFilter}`
                    );
                    setClickSearchButton(true);
                  }
                }}
              >
                <SearchIcon />
              </IconButton>
            ) : null}
          </Paper>
        </Grid>
        <Grid item md={2}>
          <Autocomplete
            options={filterData ? filterData?.job_type?.data : []}
            value={filterValues?.jobType?.name ? filterValues?.jobType : null}
            getOptionLabel={(option) => option?.name}
            renderOption={(option) => (
              <Typography style={{ fontSize: "14px" }}>
                {option?.name}
              </Typography>
            )}
            style={{ background: "#fff", borderRadius: 8 }}
            onChange={(e, newValue) => {
              if (newValue) {
                setFilterValues((prevData) => ({
                  ...prevData,
                  jobType: newValue,
                }));
                setFilterPayLoad(
                  (prevFilterString) =>
                    prevFilterString + `&job_type_id=${newValue?.id}`
                );
              } else {
                setFilterValues((prevData) => ({
                  ...prevData,
                  jobType: null,
                }));
                setFilterPayLoad((prevFilterString) => {
                  let newfilterString =
                    prevFilterString?.split("&job_type_id=");
                  setFilterPayLoad(newfilterString[0]);
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // style={{ color: 'red' }}
                label="Job Type"
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
            )}
          />
        </Grid>

        <Grid item md={4}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <NewFilterDrawer
              filterData={filterData}
              filterLoading={filterLoading}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
              setClickFilterApplyButton={setClickFilterApplyButton}
              filterDataError={filterDataError}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />

            <div style={{ marginLeft: 15 }}>
              {filterPayLoad?.length > 0 ? (
                <Button
                  variant="text"
                  color="primary"
                  style={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    height: "48px",
                  }}
                  onClick={handleClearFilters}
                >
                  Clear Filter
                </Button>
              ) : null}
            </div>
          </div>
        </Grid>

        <Grid
          item
          md={3}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!url && (
              <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label={
                  <span style={{ fontSize: 14 }}>Unlocked HR Contacts</span>
                }
                value={unLockHRContactCheck}
                onChange={(e) =>
                  setUnLockHRContactCheck(e.target.checked ? 1 : 0)
                }
              />
            )}
          </div>
        </Grid>
        {allJobs?.length === 0 && !pageloading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <NoDataFunc width={"300px"} height={"200px"} />
            <Typography style={{ fontSize: "18px", fontWeight: 600 }}>
              {" "}
              No Data Available
            </Typography>{" "}
          </div>
        ) : (
          <>
            <Grid item md={4}>
              <Box
                style={{
                  height: "70dvh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="leftlivejobcardscroll"
              >
                {pageloading ? (
                  <>
                    <JobCardSkelton />
                    <JobCardSkelton />
                    <JobCardSkelton />
                    <JobCardSkelton />
                  </>
                ) : (
                  allJobs?.map((jobData) => (
                    <>
                      <JobCard
                        nextPageUrl={nextPageUrl}
                        jobId={jobId}
                        collegeId={collegeId}
                        setPageNo={setPageNo}
                        setJobId={setJobId}
                        data={jobData}
                        setCompanyId={setCompanyId}
                        setHrDetails={setHrDetails}
                        hrDetails={hrDetails}
                      />
                    </>
                  ))
                )}
                {showSkeletonCards ? (
                  <>
                    <JobCardSkelton />
                    <JobCardSkelton />
                    <JobCardSkelton />
                  </>
                ) : null}
                <div
                  ref={ref}
                  style={{
                    height: 10,
                  }}
                />
              </Box>
            </Grid>
            <Grid item md={8} style={{ paddingLeft: 2 }}>
              <TabWithApply
                jobId={jobId}
                allJobs={allJobs}
                setAllJobs={setAllJobs}
                collegeId={collegeId}
                companyId={companyId}
                setHrDetails={setHrDetails}
                hrDetails={hrDetails}
                isLiveJobs={isLiveJobs}
                pageloading={pageloading}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default LiveJob;
