import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const OVERVIEW_DATA = "OVERVIEW_DATA";
export const PLACEMENT_DATA = "PLACEMENT_DATA";
export const SKILL_DATA = "SKILL_DATA";

export const overviewGet = (
  alert,
  history,
  location,
  college,
  placement,
  setLoader = () => {}
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/dashboard/overview/?college_id=${college}&placement_id=${placement}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
        setLoader(false);
      });
    if (res.data.success) {
      dispatch({
        type: OVERVIEW_DATA,
        payload: res.data.data,
      });
    } else {
    }
    setLoader(false);
  };
};

export const placementGet = (
  alert,
  history,
  location,
  college,
  placement,
  setLoader
) => {
  return async (dispatch) => {
    setLoader(true);

    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/dashboard/overview_placement/?college_id=${college}&placement_id=${placement}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
        setLoader(false);
      });
    if (res.data.success) {
      dispatch({
        type: PLACEMENT_DATA,
        payload: res.data.data,
      });
    } else {
    }
    setLoader(false);
  };
};

export const skillGet = (
  alert,
  history,
  location,
  college,
  placement,
  setLoader
) => {
  return async (dispatch) => {
    setLoader(true);

    const res = await axios
      .get(
        `${BackendBaseApi.PRAVESH}api/saas/skill_dashboard/?college_id=${college}&placement_id=${placement}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
        setLoader(false);
      });
    if (res.data.success) {
      dispatch({
        type: SKILL_DATA,
        payload: res.data.data,
      });
    } else {
    }
    setLoader(false);
  };
};
