import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const columns = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "eventname",
    width: 170,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
        }}
      >
        Event Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          display: "flex",
          marginLeft: "5px",
        }}
      >
        <Typography color="primary" variant="body2">
          {params.row.eventname}{" "}
        </Typography>
      </span>
    ),
  },
  {
    field: "eventtype",
    width: 170,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "22px",
        }}
      >
        Event Type
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.eventtype}
      </span>
    ),
  },
  {
    field: "payment",
    width: 170,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "10px",
        }}
      >
        Payment Required
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.payment}
      </span>
    ),
  },
  {
    field: "start",
    width: 170,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "10px",
        }}
      >
        Start Date & Time
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.start}
      </span>
    ),
  },

  {
    field: "end",
    width: 170,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "10px",
        }}
      >
        End Date & Time
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.end}
      </span>
    ),
  },

  {
    field: "attendees",
    // type: "number",
    width: 120,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "7px",
        }}
      >
        Attendees
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Link to="/total-member" style={{ textDecoration: "none" }}>
          <Typography color="primary" variant="body2">
            {" "}
            {params.row.attendees}{" "}
          </Typography>
        </Link>
      </span>
    ),
  },

  {
    field: "guest",
    // type: "number",
    width: 100,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Guest
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Link to="/all-guest" style={{ textDecoration: "none" }}>
          <Typography color="primary" variant="body2">
            {" "}
            {params.row.guest}{" "}
          </Typography>
        </Link>
      </span>
    ),
  },
  {
    field: "action",
    sortable: false,
    width: 100,

    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "15px",
        }}
      >
        Action
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Button color="primary" variant="text">
          Share
        </Button>
      </span>
    ),
  },
];

const rows = [
  {
    id: 1,
    eventname: "Social Science Day",
    eventtype: "Public",
    payment: "Free",
    start: "12 Mar 2022  |  08:00",
    end: "12 Mar 2022  |  08:00",
    attendees: "200",
    guest: "04",
  },
  {
    id: 2,
    eventname: "Social Science Day",
    eventtype: "Public",
    payment: "Free",
    start: "12 Mar 2022  |  08:00",
    end: "12 Mar 2022  |  08:00",
    attendees: "200",
    guest: "04",
  },
];

export default function AllEvent() {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
          boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        }}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}
