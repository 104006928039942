import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import pSBC from "shade-blend-color";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Tab,
  Typography,
  Box,
  AppBar,
  Tabs,
  useTheme,
  withStyles,
  Paper,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  TableHead,
  Grid,
  CircularProgress,
  DialogContent,
  DialogContentText,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import HtmlParser from "react-html-parser";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    color: "#000",
    borderTop: "none",
    border: "1px solid #B0B6BA",
  },
  body: {
    fontSize: 14,
    border: "1px solid #B0B6BA",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  //   root: {
  //     "&:nth-of-type(odd)": {
  //       backgroundColor: theme.palette.action.hover,
  //     },
  //   },
}))(TableRow);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    width: "100%",
  },
}));

export default function ServiceDialog({
  open,
  handleClose,
  handleClickOpen,
  currentRow,
  updateRow,
  statusUpdate,
  setStatusUpdate,
  curentStatus,
  loading,
}) {
  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [scroll, setScroll] = React.useState("paper");
  const descriptionElementRef = React.useRef(null);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        scroll={scroll}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: "600px",
          },
        }}
      >
        <DialogTitle
          id="max-width-dialog-title"
          style={{ textAlign: "center" }}
        >
          Service Request Details
        </DialogTitle>

        <Box>
          <div className={classes.root}>
            <AppBar
              position="static"
              color="default"
              style={{
                boxShadow: "none",
                backgroundColor: "#fff",
                borderBottom: "1px solid #C3C3C3",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Request Description" {...a11yProps(0)} />
                <Tab label="Action History" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
          </div>
        </Box>
        <DialogContent dividers={scroll === "paper"} style={{ padding: 0 }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {" "}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box>
                <AppBar
                  position="static"
                  style={{
                    backgroundColor: pSBC(0.8, theme.palette.primary.main),
                    height: 45,
                    boxShadow: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // borderBottom: "1px solid #C3C3C3",
                  }}
                >
                  <Typography style={{ textAlign: "center" }}>
                    Request
                  </Typography>
                </AppBar>
                <Box style={{ padding: 25 }}>
                  <Typography
                    variant="body2"
                    style={{ textAlign: "left", marginBottom: 7 }}
                  >
                    Module: {currentRow?.module_name}
                  </Typography>

                  <Typography variant="body2">
                    {HtmlParser(currentRow?.description)}
                  </Typography>
                </Box>
                {currentRow?.images?.length ? (
                  <>
                    <AppBar
                      position="static"
                      style={{
                        backgroundColor: pSBC(0.8, theme.palette.primary.main),
                        height: 45,
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #C3C3C3",
                      }}
                    >
                      <Typography style={{ textAlign: "center" }}>
                        Images
                      </Typography>
                    </AppBar>
                    <Box
                      style={{
                        height: "160px",
                        borderBottom: "1px solid #C3C3C3",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      {currentRow?.images?.map((item) => (
                        <img
                          src={item}
                          alt="Img Error"
                          style={{
                            height: "100px",
                            objectFit: "contain",
                            aspectRatio: "3/2",
                            margin: "20px",
                          }}
                        />
                      ))}
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box>
                <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          Service Request Update
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Date Update
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Comment
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRow?.action_history?.map((row) => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell
                            style={{ width: 280 }}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {row?.action}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.updated_time
                              ? moment(row?.updated_time).format("DD/MM/YYYY")
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.comment ? row?.comment : "NA"}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </TabPanel>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          {/* {value === 0 ? (
            <Box style={{ padding: 8 }}>
              <Button
                variant="contained"
                onClick={handleClose}
                style={{ width: 200, boxShadow: "none", height: 45 }}
                color="primary"
              >
                Close
              </Button>
            </Box>
          ) : ( */}
          <Box style={{ width: "100%" }}>
            <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
              {currentRow?.status !== "CREATED" ? (
                ""
              ) : (
                <Grid item md={6}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      updateRow(currentRow?.id);
                    }}
                    fullWidth
                    style={{
                      boxShadow: "none",
                      height: 45,
                      color: "red",
                      border: "1px solid red",
                    }}
                    color="red"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : (
                      "Close Request"
                    )}
                  </Button>
                </Grid>
              )}
              <Grid
                item
                md={currentRow?.status !== "CREATED" ? 12 : 6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  onClick={handleClose}
                  fullWidth
                  style={{
                    boxShadow: "none",
                    height: 45,
                    width: currentRow?.status !== "CREATED" ? "200px" : "",
                  }}
                  color="primary"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
          {/* )} */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
