import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import pSBC from "shade-blend-color";
import { truncate } from "../../../utility/ellipsify";
import "./arrow.css";

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  closeIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
    cursor: "pointer",
  },
  arrow: {
    width: "170px",
    marginRight: 30,
    height: "120px",
    background: "#fff",
    borderRadius: "8px",
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    padding: "10px",
    position: "relative",
    borderColor: theme.palette.primary.main,
    border: "2.5px solid",
    top: "0px",
    "&:after": {
      content: '" "',
      position: "absolute",
      right: "-6.7px",
      top: "35px",
      borderTop: "7px solid transparent",
      borderRight: "none",
      //  backgroundColor: pSBC(0.9, theme.palette.primary.main),
      borderLeftColor: pSBC(0.9, theme.palette.primary.main),
      //  borderColor: theme.palette.primary.main,
      //   borderLeftColor: "white",
      borderLeft: "7px solid",
      borderBottom: "7px solid transparent",
    },
    // "&:before": {
    //   content: '" "',
    //   position: "absolute",
    //   right: "-15px",
    //   borderColor: theme.palette.primary.main,
    //   top: "20px",
    //   borderTop: "15px solid transparent",
    //   borderRight: "0px  solid",
    //   borderLeft: "15px solid ",
    //   borderBottom: "15px solid transparent",
    // },
    "&:before": {
      content: '" "',
      position: "absolute",
      right: "-10px",
      top: "34px",
      borderTop: "8px solid transparent",
      borderRight: "none",

      //borderColor: theme.palette.primary.main,
      borderLeftColor: theme.palette.primary.main,
      borderLeft: "8px solid",
      borderBottom: "8px solid transparent",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: "0px",
      height: "65px",
      "&:after": {
        content: '" "',
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "none",
        //borderTop: "7px solid transparent",
        borderRight: "none",
        //  backgroundColor: pSBC(0.9, theme.palette.primary.main),
        //borderLeftColor: pSBC(0.9, theme.palette.primary.main),
        //  borderColor: theme.palette.primary.main,
        //   borderLeftColor: "white",
        // borderLeft: "7px solid",
        //borderBottom: "7px solid transparent",
      },
      "&:before": {
        content: '" "',
        position: "absolute",
        right: "0px",
        top: "0px",
        //borderTop: "8px solid transparent",
        borderRight: "none",
        display: "none",

        //borderColor: theme.palette.primary.main,
        // borderLeftColor: theme.palette.primary.main,
        // borderLeft: "8px solid",
        //borderBottom: "8px solid transparent",
      },
    },
  },
}));

function ArrowCards({ data, fromPostJob, handleDeleteRounds, disableDelete }) {
  const classes = useStyles();
  const [newList, setNewList] = useState();

  useEffect(() => {
    if (data?.length > 0) {
      var perChunk = 4; // items per chunk

      var inputArray = [...data];

      var result = inputArray.reduce((r, a, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!r[chunkIndex]) {
          r[chunkIndex] = []; // start a new chunk
        }

        r[chunkIndex].push(a);
        return r;
      }, Object.create(null));
      setNewList(result);
    }
  }, [data?.length]);

  return (
    <>
      {newList &&
        Object.keys(newList)?.length > 0 &&
        Object.keys(newList)?.map((item, index) => (
          <>
            <div className={classes.item} style={{ marginBottom: 10 }}>
              {newList[item]?.map((item2) => (
                <>
                  <div
                    className={classes.arrow}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={11}>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {item2.round_name}
                        </Typography>
                        <div>
                          <Typography variant="body2" color="secondary">
                            Time-
                            {item2?.duration
                              ? item2?.duration + " mins"
                              : "NA"}{" "}
                            <br />
                            {item2?.type === "ONLINE" ? (
                              <>
                                {" "}
                                Link -
                                <a
                                  href={item2?.venue}
                                  target="blank"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  {item2?.venue ? truncate(item2?.venue) : "NA"}{" "}
                                </a>{" "}
                              </>
                            ) : (
                              <>
                                Venue-
                                {item2?.venue ? truncate(item2?.venue) : "NA"}
                              </>
                            )}
                          </Typography>
                        </div>
                      </Grid>
                      {fromPostJob && (
                        <Grid item xs={1}>
                          <CloseIcon
                            className={classes.closeIcon}
                            onClick={() => {
                              if (disableDelete) {
                                return;
                              }
                              handleDeleteRounds(item2);
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  <br />
                </>
              ))}
            </div>
          </>
        ))}

      {/* <DoubleArrowIcon color="primary" />
        <div className={classes.arrow}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            2. Technical Round
          </Typography>

          <Typography variant="body2">
            Time-6:00 - 7:00 PM <br /> Venue-GetWork
          </Typography>
        </div>
        <DoubleArrowIcon color="primary" />
        <div className={classes.arrow}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            3. Group Discussion
          </Typography>

          <Typography variant="body2">
            Time-6:00 - 7:00 PM <br /> Venue-GetWork
          </Typography>
        </div>
        <DoubleArrowIcon color="primary" />
        <div className={classes.arrow}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            4. HR Round
          </Typography>

          <Typography variant="body2">
            Time-6:00 - 7:00 PM <br /> Venue-GetWork
          </Typography>
        </div> */}
    </>
  );
}

export default ArrowCards;
