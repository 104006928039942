import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
import { BlockSingleTeamAPI, BlockTeamAPI } from "../../../../api/TeamManage/action";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import { isCollegeGroupFn, isEducationGroupFn } from "../../../../api/SignIn/selector";
import { groupViewCheck } from "../../../../utility/groupViewCheck";
import BlockSingleTeam from '../../../DialogSection/BlockSingleTeam/index';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    width: "80%",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
}));

export default function Contact({ data, params, collegeId }) {
  const classes = useStyles();
  const Data = [
    {
      icon: <PersonIcon color="primary" />,
      info: data?.name,
      show: data?.name ? true : false,
    },
    {
      icon: <PhoneIcon color="primary" />,
      info: data?.phone,
      show: data?.phone ? true : false,
    },
    {
      icon: <MailIcon color="primary" />,
      info: data?.email,
      show: data?.email ? true : false,
    },
    {
      icon: <WorkIcon color="primary" />,
      info: data?.designation,
      show: data?.designation ? true : false,
    },
    {
      icon: <LanguageIcon color="primary" />,
      info: data?.website,
      show: data?.website ? true : false,
    },
  ];
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const blockMember = (status, reason) => {
    var data_ = {
      college_id: [collegeId],
      object_id: [params?.team_member_id],
      status: status,
      reason: reason,

    };
    dispatch(BlockSingleTeamAPI(data_, alert, history, location, data));
  };
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Contact and Portfolio</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              {data?.status === "ACTIVE" ? (
                <>
                  <div
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#28a745",
                      borderRadius: "8px",
                    }}
                  />
                  <Typography variant="body2" style={{ fontWeight: "bold", marginLeft: "10px" }}>
                    Active
                  </Typography>
                </>
              ) : (
                <>
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      backgroundColor: "#f64a3f",
                      borderRadius: "8px",
                    }}
                  />
                  <Typography variant="body2" style={{ fontWeight: "bold", marginLeft: "10px" }}>
                    Blocked
                  </Typography>
                </>
              )}
            </div>
          </div>
          <br />
          <div>
            {Data.map(
              (item) =>
                item.show && (
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>{item.icon}</Grid>
                    <Grid item xs>
                      <Typography variant="body2" color="secondary">
                        {item.info}
                      </Typography>
                    </Grid>
                  </Grid>
                )
            )}
          </div>
          <br />
          {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
          <>
         
            <BlockSingleTeam data={data} blockMember={blockMember}/>
</>
          )}
        </Paper>
      </div>
    </>
  );
}
