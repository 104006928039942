import { Hidden } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
  showing: {
    color: theme.palette.primary.main,
    fontSize: 14,
    cursor: "pointer",
    paddingTop: 2,
  },
}));
export default function ChipCourses({
  data,
  setData,
  specialization,
  setSpecialization,
  coursesArray,
  pushArray,
  qualifications,
  setQualifications,
  isRecommended,
}) {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);
  const [specLength, setSpecLength] = useState(0);

  const checkLength = () => {
    var len = coursesArray?.map(
      (item) => Object.values(item.degrees).flat().length
    );
    var count = 0;
    for (var i = 0; i < coursesArray?.length; i++) {
      count = count + coursesArray[i].degrees.length;
    }
    return count;
  };
  useEffect(() => {
    var arr = [...data];
    if (
      specialization &&
      Object.keys(specialization) &&
      Object.keys(specialization)?.length
    ) {
      Object.keys(specialization).map((item) => {
        if (specialization[item].length > 0) {
          var spec_arr = [];
          coursesArray.map((edu) =>
            edu.degrees.map((x) => {
              if (Number(x.degree_id) === Number(item)) {
                spec_arr = x.specialization;
              }
            })
          );

          if (specialization[item].length === spec_arr.length) {
            var degree_obj = arr.find(
              (x) => Number(x.degree_id) === Number(item)
            );
            degree_obj["this_degree_selected"] = true;
            var degree_arr = [degree_obj];
            arr.map(
              (obj) =>
                degree_arr.find((o) => o.degree_id === obj.degree_id) || obj
            );
          } else {
            var degree_obj = arr.find(
              (x) => Number(x.degree_id) === Number(item)
            );

            degree_obj["this_degree_selected"] = false;
            var degree_arr = [degree_obj];
            arr.map(
              (obj) =>
                degree_arr.find((o) => o.degree_id === obj.degree_id) || obj
            );
          }
        }
      });
      var check = arr.every((item) => item.this_degree_selected === true);
      if (arr.length === checkLength() && check) {
        arr.forEach((element) => {
          element["all_degree_selected"] = true;
        });
      } else {
        arr.forEach((element) => {
          element["all_degree_selected"] = false;
        });
      }
      var object = {};

      if (arr.length > 0) {
        if (arr[0].all_degree_selected) object["Open to All"] = {};
        else {
          arr.map((item) => {
            object[item?.degree_name] = checkValue(item);
          });
        }
      }

      setData(arr);
      setQualifications(object);
    }
  }, [pushArray.length]);

  useEffect(() => {
    if (data?.length > 0) {
      var count = 0;
      data.map((item) =>
        item?.specialization?.map((item2) => {
          count = count + 1;
        })
      );
      setSpecLength(count);
    }
  }, [data]);

  const checkValue = (item) => {
    var boole = item.this_degree_selected;
    var obj;
    if (boole) {
      obj = [{ name: "All Courses" }];
    } else {
      obj = item?.specialization;
    }
    return obj;
  };

  return (
    <>
      <div className={classes.chipsection}>
        <>
          {Object.keys(qualifications)?.map((item, index) => (
            <>
              <>
                {item === "Open to All" && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={"Open to All Courses"}
                    className={classes.chip}
                  />
                )}
              </>
            </>
          ))}
        </>
        <>
          <>
            {!showMore && (
              <>
                {specLength > 5 ? (
                  <>
                    {Object.keys(qualifications)
                      ?.slice(0, 5)
                      .map((item, index) => (
                        <>
                          {item !== "Open to All" &&
                            !showMore &&
                            qualifications[item]
                              .slice(0, 5)
                              .map((item2) => (
                                <>
                                  {
                                    <Chip
                                      variant="outlined"
                                      size="small"
                                      label={
                                        item2.name
                                          ? item + " / " + item2.name
                                          : item +
                                            " / " +
                                            item2.specialization_name
                                      }
                                      className={classes.chip}
                                    />
                                  }
                                </>
                              ))}
                          {/* <span onClick={() => setShowMore(true)} style={{ color: "#007bff", fontSize: 14, cursor: "pointer", paddingTop: 2 }}>
                            Show More...
                          </span> */}
                        </>
                      ))}
                    {Object.keys(qualifications)?.length > 5 && (
                      <span
                        onClick={() => setShowMore(true)}
                        className={classes.showing}
                      >
                        Show More...
                      </span>
                    )}
                  </>
                ) : (
                  Object.keys(qualifications)?.map((item, index) => (
                    <>
                      {item !== "Open to All" &&
                        qualifications[item].map((item2) => (
                          <>
                            <Hidden smUp>
                              <Chip
                                variant="outlined"
                                size="small"
                                label={
                                  item2.name
                                    ? item + " / " + item2.name
                                    : item + " / " + item2.specialization_name
                                }
                                className={classes.chip}
                                style={{ maxWidth: "100%" }}
                              />
                            </Hidden>
                            <Hidden smDown>
                              <Chip
                                variant="outlined"
                                size="small"
                                label={
                                  item2.name
                                    ? item + " / " + item2.name
                                    : item + " / " + item2.specialization_name
                                }
                                className={classes.chip}
                              />
                            </Hidden>
                          </>
                        ))}
                    </>
                  ))
                )}
              </>
            )}
          </>

          <>
            {showMore &&
              Object.keys(qualifications)?.map((item, index) => (
                <>
                  {item !== "Open to All" &&
                    showMore &&
                    qualifications[item].map((item2) => (
                      <>
                        {/* <Hidden smUp>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                            className={classes.chip}
                            style={{ maxWidth: "100%" }}
                          />
                        </Hidden> */}
                        <Hidden smDown>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={
                              item2.name
                                ? item + " / " + item2.name
                                : item + " / " + item2.specialization_name
                            }
                            className={classes.chip}
                          />
                        </Hidden>
                      </>
                    ))}
                </>
              ))}
            {showMore && (
              <span
                onClick={() => setShowMore(false)}
                className={classes.showing}
              >
                Show less...
              </span>
            )}
          </>
        </>
      </div>
    </>
  );
}
