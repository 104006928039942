import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme, makeStyles } from "@material-ui/styles";
import {
  Paper,
  IconButton,
  InputBase,
  Box,
  Typography,
  Button,
  DialogActions,
} from "@material-ui/core";
import AllCourseGrid from "./AllCourseGrid";
import DegreeSpecializationSingleCollege from "../../DegreeSpecializationCustom/DegreeSpecializationSingleCollege";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { entityInfoSelectorFn } from "../../../api/SignIn/selector";
import ChipCustom from "../../Form/ChipCourses/index2";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid #6C757D",
    width: "300px ",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function DegreeSpecializationDialog({
  open,
  coursesArray,
  handleClose,
  allInOneSelected = "All Courses",
  allSelected = "Open to All Courses",
  primaryItems,
  primaryName,
  secondaryName,
  secondaryItems,
  onlyGroup = false,
}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const alert = useAlert();

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography style={{ color: "#5B5B5B", fontSize: "16px" }}>
            Selected Degree/Specializations Details
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <Box sx={{ py: 3 }}>
            <ChipCustom
              courseArray={coursesArray}
              allInOneSelected={allInOneSelected}
              allSelected={allSelected}
              primaryItems={primaryItems}
              primaryName={primaryName}
              secondaryName={secondaryName}
              secondaryItems={secondaryItems}
              onlyGroup={onlyGroup}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 15, paddingRight: 25 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="inherit"
            style={{ height: "40px", boxShadow: "none", width: "200px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
