import { HELP_DATA } from "./action";
import { CARD_DATA } from "./action";
import { QUESTION_DATA } from "./action";

export const Helps = (state = {}, action) => {
  switch (action.type) {
    case HELP_DATA:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const Cards = (state = {}, action) => {
  switch (action.type) {
    case CARD_DATA:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const Questions = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_DATA:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};
