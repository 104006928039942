import { Button, Container, Grid, Hidden, Typography } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ApartmentIcon from "@material-ui/icons/Apartment";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CompanyPublicPostAPI,
  CompanySelectedFieldsPublicAPI,
} from "../../api/Company/action";
import { collegeDetailsSelectorFn } from "../../api/Domain/selector";
import { collegeIdFn } from "../../api/SignIn/selector";
import CollegeImage from "../../assets/img/company.jpg";
import defaultCompany from "../../assets/img/default.jpg";
import CustomCircularProgress from "../../components/CircularProgressBar";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import FormFooter from "../../components/Common/FormFooter";
import StudentFormNavbar from "../../components/Common/StudentFormNavbar";
import CompanyData from "../../components/Form/CompanyData";
import { BackendBaseApi, NEXT_APP_ENDPOINT } from "../../constants/constants";
import { url } from "../../utility/regex";
import ThankYou from "../ThankYou";
import Facebook from "../../assets/png/facebook.png";
import Insta from "../../assets/png/insta.png";
import Linkedin from "../../assets/png/linkedin.png";
import { checkForUrl } from "../../utility/checkForUrl";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginTop: "30px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "20px",
    },
  },

  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    height: "270px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  submit: {
    paddingBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingTop: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingBottom: "10px",
  },

  imagedata: {
    display: "flex",
    width: 150,
    backgroundColor: "#fff",
    height: 80,
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  imgdata: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",

    //  borderRadius: "50%",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  icons: {
    marginRight: "10px",
    cursor: "pointer",
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      // width: "100px",
    },
  },
}));

function CompanyForm() {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [loadingDegree, setLoadingDegree] = useState(false);
  const [stepNo, setStepNo] = useState(0);

  React.useEffect(() => {
    dispatch(
      CompanySelectedFieldsPublicAPI(
        setLoadingForm,
        alert,
        history,
        location,
        window.location.href
      )
    );
  }, []);
  const [fileNames, setFileNames] = useState();

  const selectedFields = useSelector(
    (state) => state?.CompanyData?.selectedPublicData?.form_fields
  );
  const collegeData = useSelector(
    (state) => state?.CompanyData?.selectedPublicData
  );
  const collegeDetails = useSelector(collegeDetailsSelectorFn);
  const [pocList, setPocList] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({
    company_name: null,
    company_website: null,

    indsutry: null,
    industry_type_id: null,
    industry_type_name: null,
    about: "",
    company_location: null,
    linkedin: null,
    glassdoor: null,
    hiring_for_degree: null,
  });

  const [companyDetailsError, setCompanyDetailsError] = useState({
    company_name: null,
    company_website: null,
    contact_person_name: null,
    email: null,
    phone: null,
    indsutry: null,
    industry_type: null,
    designation: null,
    company_location: null,
  });

  const handleSubmit = () => {
    if (validateData()) {
      if (pocList?.length <= 0) {
        alert.error("Please enter atleast one POC Detail");
      } else {
        var company_details = {
          company_name: companyDetails.company_name,
          company_website: companyDetails.company_website,
          industry_id: companyDetails.industry.id,
          industry_name: companyDetails.industry.name,
          industry_type_id: companyDetails.industry_type_id,
          industry_type_name:
            companyDetails.industry_type_id === 1
              ? "Private"
              : companyDetails.industry_type_id === 2
              ? "Government"
              : "Semi-Government",
          linkedin: companyDetails?.linkedin,
          glassdoor: companyDetails?.glassdoor,
          about: companyDetails?.about,
          company_location: companyDetails?.company_location?.city_id
            ? {
                id: companyDetails?.company_location?.city_id,
                name: companyDetails?.company_location?.city,
              }
            : null,
          hiring_for_degree: companyDetails?.hiring_for_degree,
        };
        var data = {
          college_id: [collegeDetails?.college_id],
          company_form_data: company_details,
          poc_list: pocList,
          invitation_source: "Company_E_RF",
        };
        dispatch(
          CompanyPublicPostAPI(
            data,
            setLoadingPost,
            alert,
            history,
            location,
            collegeDetails?.college_id,
            setStepNo,
            fileNames,
            updateLogo
          )
        );
      }
    }
  };

  const updateLogo = (comp_id) => {
    var fd = new FormData();
    fd.append("college_id", collegeDetails?.college_id);
    fd.append("logo", fileNames[0]);

    axios
      .patch(`${BackendBaseApi.SANCHALAN}public/add_company/${comp_id}/`, fd, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
        } else {
          alert.error("Some Error Occurred");
        }
      })
      .catch((err) => {
        if (err.response.status !== 401)
          alert.error("Some Error Occurred while uploading");

        throw err;
      });
  };

  const validateData = () => {
    var is_valid = true;
    var company_name_error = null;
    var comapny_website_error = null;
    // var contact_person_error = null;
    // var email_error = null;
    // var phone_error = null;
    var industry_error = null;
    var industry_type_error = null;
    // var designation_error = null;
    var company_location = null;
    selectedFields?.length > 0 &&
      selectedFields?.map((item) => {
        if (
          item.field_type.toLowerCase() === "textfield" &&
          item?.key === "company_name"
        ) {
          if (!companyDetails.company_name) {
            company_name_error = "Company Name field cannot be left blank";
            is_valid = false;
          }
        }
        if (
          item.field_type.toLowerCase() === "textfield" &&
          item?.key === "company_website"
        ) {
          if (!companyDetails.company_website) {
            comapny_website_error =
              "Company Website field cannot be left blank";
            is_valid = false;
          }
          if (!url.test(companyDetails.company_website)) {
            comapny_website_error = "Enter Correct Web Address";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "industry") {
          if (!companyDetails.industry) {
            industry_error = "Industry Field cannot be left blank";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "industry_type") {
          if (!companyDetails.industry_type_id) {
            industry_type_error = "Industry Type field cannot be left blank";
            is_valid = false;
          }
        }
        if (item.field_type.toLowerCase() === "company_location") {
          if (!companyDetails.company_location) {
            company_location = "Company Location field cannot be left blank";
            is_valid = false;
          }
        }
        // if (item?.key === "spoc") {
        //     company_name_error = "Company Name field cannot be left blank";
        //     is_valid = false;

        // }
      });

    setCompanyDetailsError({
      ...companyDetailsError,
      company_name: company_name_error,
      company_website: comapny_website_error,
      industry: industry_error,
      industry_type: industry_type_error,
      company_location: company_location,
    });
    return is_valid;
  };

  const Data = [
    {
      icon: <LanguageIcon color="secondary" />,
      info: checkForUrl(collegeDetails?.website),
      website: true,
      show: collegeDetails?.website ? true : false,
    },
    {
      icon: <ApartmentIcon color="secondary" />,
      info: collegeDetails?.city,
      show: collegeDetails?.city ? true : false,
    },
    {
      icon: <GolfCourseIcon color="secondary" />,
      info: collegeDetails?.college_type,
      show: collegeDetails?.college_type ? true : false,
    },
    {
      icon: <PeopleAltOutlinedIcon color="secondary" />,
      info: collegeDetails?.size + " Students",
      show: collegeDetails?.size ? true : false,
    },
    {
      icon: <FlagOutlinedIcon color="secondary" />,
      info: collegeDetails?.founded_year,
      show: collegeDetails?.founded_year ? true : false,
    },
    {
      icon: <LocationCityIcon color="secondary" />,
      info: collegeDetails?.affiliated,
      show: collegeDetails?.affiliated ? true : false,
    },
  ];

  const someTruthy = Object.values(Data).some((val) => val.show === true);

  return (
    <>
      {/* <StudentFormNavbar /> */}
      {loadingForm ? (
        <CustomCircularProgress />
      ) : stepNo === 0 ? (
        <>
          <div style={{ backgroundColor: "#fafcfc", paddingBottom: "20px" }}>
            <div
              style={{
                backgroundImage: `url(${
                  collegeDetails?.banner ? collegeDetails?.banner : CollegeImage
                })`,
                backgroundRepeat: "no-repeat",
                height: "270px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />{" "}
            <Container maxWidth="lg">
              <Grid container spacing={2} style={{ marginTop: "-30px" }}>
                <Grid item>
                  <div className={classes.imagedata}>
                    <img
                      className={classes.imgdata}
                      alt="complex"
                      src={
                        collegeDetails?.logo
                          ? collegeDetails?.logo
                          : defaultCompany
                      }
                    />
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm container>
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    spacing={2}
                    style={{ display: "contents" }}
                  >
                    <div className={classes.title}>
                      <Typography variant="h3" style={{ fontWeight: "bold" }}>
                        <Typography variant="h3">
                          {collegeDetails?.college_name}
                        </Typography>
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  sm
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    spacing={2}
                    style={{ display: "contents" }}
                  >
                    <div className={classes.title}>
                      <Typography
                        variant="h3"
                        style={{ fontWeight: 600, paddingBottom: "10px" }}
                      >
                        {collegeDetails?.college_name}
                      </Typography>
                    </div>
                    <div className={classes.social}>
                      {collegeDetails?.social_links?.length > 1 &&
                        collegeDetails?.social_links[1].value && (
                          <a
                            href={collegeDetails?.social_links[1].value}
                            target="blank"
                          >
                            <img
                              src={Facebook}
                              height="40px"
                              className={classes.icons}
                              width="40px"
                            />
                          </a>
                        )}
                      {collegeDetails?.social_links?.length > 0 &&
                        collegeDetails?.social_links[0].value && (
                          <a
                            href={collegeDetails?.social_links[0].value}
                            target="blank"
                          >
                            <img
                              src={Linkedin}
                              className={classes.icons}
                              height="40px"
                              width="40px"
                            />
                          </a>
                        )}
                      {collegeDetails?.social_links?.length > 2 &&
                        collegeDetails?.social_links[2].value && (
                          <a
                            href={collegeDetails?.social_links[2].value}
                            target="blank"
                          >
                            <img
                              src={Insta}
                              className={classes.icons}
                              height="40px"
                              width="40px"
                            />
                          </a>
                        )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <div>
                {someTruthy && <Typography variant="h6">Overview</Typography>}
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  {Data.map(
                    (item) =>
                      item.show && (
                        <Grid item xs={12} sm={4}>
                          <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>{item.icon}</Grid>
                            <Grid
                              item
                              xs
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                variant="body2"
                                color="secondary"
                                noWrap
                                className={classes.text}
                              >
                                {item.website ? (
                                  <a href={item.info} target="_blank">
                                    {item.info}
                                  </a>
                                ) : (
                                  item.info
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                  )}
                </Grid>

                {collegeDetails?.about_us && (
                  <Grid item xs={12}>
                    <Typography variant="h6">About us</Typography>

                    <Typography variant="body2">
                      {ReactHtmlParser(collegeDetails?.about_us)}
                    </Typography>
                  </Grid>
                )}
              </div>
            </Container>
          </div>
          <Container maxWidth="lg">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div className={classes.form}>
                <Hidden smDown>
                  <Typography
                    variant="h3"
                    style={{ textAlign: "center", marginBottom: "20px" }}
                  >
                    Provide your Information to Register your Company for Campus
                    Placement
                  </Typography>
                </Hidden>
                <Hidden smUp>
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    Provide your Information to Register your Company for Campus
                    Placement
                  </Typography>
                </Hidden>
                <CompanyData
                  selectedFields={selectedFields}
                  companyDetails={companyDetails}
                  setCompanyDetails={setCompanyDetails}
                  companyDetailsError={companyDetailsError}
                  setCompanyDetailsError={setCompanyDetailsError}
                  pocList={pocList}
                  setPocList={setPocList}
                  fileNames={fileNames}
                  setFileNames={setFileNames}
                  collegeId={collegeDetails?.college_id}
                />
              </div>
              <div className={classes.submit}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={() => {
                    if (!loadingPost) handleSubmit();
                  }}
                >
                  {loadingPost ? <CustomButtonCircularProgress /> : "Submit"}
                </Button>
              </div>
            </div>
          </Container>
          <div style={{ backgroundColor: "#fafcfc", paddingTop: "20px" }}>
            <Container maxWidth="lg">
              <Grid item xs={12} style={{ marginBottom: "30px" }}>
                <Typography variant="h5">General Instruction</Typography>
                <br />
                {instructions.map((instructions) => (
                  <div style={{ marginTop: "7px" }}>
                    <ul style={{ marginLeft: "-22px", color: "#707070" }}>
                      {instructions.line.map((line) => (
                        <li>
                          <Typography
                            //  component="li"
                            variant="body2"
                            // align="center"
                            key={line}
                            style={{ marginBottom: "10px", color: "#707070" }}
                          >
                            {line}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}

                {/* <Typography variant="body2" color="secondary">
                  {instructions.map(item => (
                    <li style={{ marginBottom: "8px" }}> {item.line} </li>
                  ))}
                </Typography> */}
              </Grid>
            </Container>
          </div>
          <FormFooter />
        </>
      ) : (
        <ThankYou
          text={"You have been successfully registered!!"}
          link={`${NEXT_APP_ENDPOINT}/employer`}
        />
      )}
    </>
  );
}

const instructions = [
  {
    line: [
      "Companies wishing for campus placements should fill the Company e-Registration Form or e-JD Form, we entertain entries received only through e-JD form.",
      "The Training and Placement Team will send you an invitation mail within 24 hrs seeking essential information about the Job profile and other Relevant details after receiving e- JD / e - RF Response.",
      "The details of Campus visit of a Company will be negotiated with the Training and Placements Team and a Team member (Singe Point of Contact - SPoC) will be allotted to the respective Company.",
      "Your entire schedule will be managed by the Team member allotted to you. You can organize your placement session as per your convenience using the facilities available at our campus.",
      "Failure to do so will result in penalty in terms of TPO credits which may lead to your debarment from future placement activities.",
      "Pay attention to the company-specific instructions sent to you on the mail by TPO team. Adhere to the specific format of attachments as asked for.",
      "After the recruitment process, the company will be required to declare the results of the final recruitment on the Portal either on-campus or from Office.",
      "The Company can then send the offer letter within a reasonable span of time.",
      "The Training and Placement Team also coordinates the signing of offer letters by students, who have been selected, to ensure that they reach the company/ organization as early as possible.",
    ],
  },
  // {
  //   line: "Companies wishing for campus placements should fill the Company e-Registration Form or e-JD Form, we entertain entries received only through e-JD form.",
  // },
  // {
  //   line: "The Training and Placement Team will send you an invitation mail within 24 hrs seeking essential information about the Job profile and other Relevant details after receiving e- JD / e - RF Response.",
  // },
  // {
  //   line: "The details of Campus visit of a Company will be negotiated with the Training and Placements Team and a Team member (Singe Point of Contact - SPoC) will be allotted to the respective Company.",
  // },
  // {
  //   line: "Your entire schedule will be managed by the Team member allotted to you. You can organize your placement session as per your convenience using the facilities available at our campus.",
  // },
  // {
  //   line: "Failure to do so will result in penalty in terms of TPO credits which may lead to your debarment from future placement activities.",
  // },
  // {
  //   line: "Pay attention to the company-specific instructions sent to you on the mail by TPO team. Adhere to the specific format of attachments as asked for.",
  // },
  // {
  //   line: "After the recruitment process, the company will be required to declare the results of the final recruitment on the Portal either on-campus or from Office.",
  // },
  // {
  //   line: "The Company can then send the offer letter within a reasonable span of time.",
  // },
  // {
  //   line: "The Training and Placement Team also coordinates the signing of offer letters by students, who have been selected, to ensure that they reach the company/ organization as early as possible.",
  // },
];

export default CompanyForm;
