import React from "react";
import Charst from "react-apexcharts";

function StudentPlacedGraph({ yAxis, xAxis, DATA }) {
  return (
    <div>
      <Charst
        options={{
          xaxis: {
            title: {
              text: xAxis,
              style: {
                fontSize: "14px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            categories: DATA?.labels ?? [],
          },
          chart: {
            id: DATA?.heading
              ?.split(" ")
              ?.join("_")
              ?.replaceAll("(%)", "_Percent"),
            toolbar: {
              export: {
                csv: {
                  headerCategory: xAxis,
                },
              },
            },
          },

          // animations: {
          //   enabled: true,
          //   easing: "easeinout",
          //   speed: 800,
          //   animateGradually: {
          //     enabled: true,
          //     delay: 150,
          //   },
          //   dynamicAnimation: {
          //     enabled: true,
          //     speed: 350,
          //   },
          // },

          colors: ["#EEF2FF"],
          markers: {
            size: 5,
            colors: ["#007BFF"],
            hover: {
              size: 9,
            },
          },

          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: "10px",
              fontFamily: "Nunito",
              colors: ["#304758"],
            },
          },

          yaxis: {
            title: {
              text: yAxis,
              style: {
                fontSize: "14px",
                fontFamily: "Nunito",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            // labels: {
            //   formatter: function (value) {
            //     return Math.round(value); // Rounds the value to an integer
            //   }
            // }
          },
          plotOptions: {
            area: {
              horizontal: false,
              borderRadius: 8,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          title: {
            text: DATA?.heading,
            style: {
              fontSize: "18px",
              fontFamily: "Nunito",
              fontWeight: 1000,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        }}
        series={[
          {
            name: yAxis,
            data: DATA?.data ?? [],
          },
        ]}
        type="area"
        height={290}
        //height="100%"
      />
    </div>
  );
}

export default StudentPlacedGraph;
