import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { JobListDropdownAPI } from "../../../api/TeamManage/action";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "40px",
    paddingRight: "40px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "8px",

  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  primebtn: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function ShortlistEligible({ collegeId }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [currentProfileError, setCurrentProfileError] = useState(null);
  const history = useHistory();
  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Rohan Singh | A103193131" },
    { key: 1, label: "Rohan Singh | A103193131" },
    { key: 2, label: "Rohan Singh | A103193131" },
    { key: 3, label: "Rohan Singh | A103193131" },
  ]);

  useEffect(() => {
    if (open) {
      dispatch(JobListDropdownAPI(collegeId));
    }
  }, [open]);
  const jobsList = useSelector((state) =>
    state?.TeamData?.listJobs ? state?.TeamData?.listJobs : []
  );
  const [currentJobProfile, setCurrentJobProfile] = useState(false);
  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="contained"
        style={{ marginRight: "0px" }}
        className={classes.primebtn}
        onClick={handleClickOpen}
      >
        Shortlist Eligible Students
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              Shortlist Eligible Students
            </Typography>

            <Typography variant="body2" color="secondary">
              Select Job title from the dropdown, Get filtered eligible students
              based on Job criteria & apply to the Job.
            </Typography>
          </div>

          <div className={classes.root}>
            <Autocomplete
              id="country-select-demo"
              options={jobsList}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.name}
                </Typography>
              )}
              value={currentJobProfile}
              onChange={(e, newValue) => {
                setCurrentJobProfile(newValue);
                setCurrentProfileError(null);
              }}
              style={{ background: "#fff", borderRadius: 8, width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Job Profiles"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={currentProfileError ? true : false}
                  helperText={currentProfileError}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Button
              autoFocus
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                if (currentJobProfile)
                  history.push({
                    pathname: `/eligible-students/${currentJobProfile?.name}-${currentJobProfile?.id}`,
                    state: { from: "/manage-students" },
                  });
                else setCurrentProfileError("Please Select a Job profile");
              }}
            >
              Shortlist
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
