import React from "react"

export default function AdminAcces({ primaryColor }){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="99" height="101" viewBox="0 0 99 101">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_5064" data-name="Rectangle 5064" width="99" height="101" transform="translate(380 270)" fill="#fff" stroke="#707070" strokeWidth="1" />
        </clipPath>
      </defs>
      <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-380 -270)" clipPath="url(#clip-path)">
        <path id="_56_Account_Admin_Browser_User" data-name="56 Account, Admin, Browser, User" d="M86.875,4H12.125A8.125,8.125,0,0,0,4,12.125V78.75a8.125,8.125,0,0,0,8.125,8.125h13v-3.25h-13A4.875,4.875,0,0,1,7.25,78.75V20.25h84.5v58.5a4.875,4.875,0,0,1-4.875,4.875H74.184v3.25H86.875A8.125,8.125,0,0,0,95,78.75V12.125A8.125,8.125,0,0,0,86.875,4ZM7.25,17V12.125A4.875,4.875,0,0,1,12.125,7.25h74.75a4.875,4.875,0,0,1,4.875,4.875V17Zm6.5-6.5h6.5v3.25h-6.5Zm9.75,0H30v3.25H23.5Zm9.75,0h6.5v3.25h-6.5Zm30.875,0H85.25v3.25H64.125Zm22.75,69.875H74.086v-3.25H85.25V26.75H13.75V77.125H25.125v3.25h-13A1.625,1.625,0,0,1,10.5,78.75V25.125A1.625,1.625,0,0,1,12.125,23.5h74.75A1.625,1.625,0,0,1,88.5,25.125V78.75A1.625,1.625,0,0,1,86.875,80.375ZM49.5,52.75A21.125,21.125,0,1,0,70.625,73.875,21.125,21.125,0,0,0,49.5,52.75ZM39.246,88.5a11.375,11.375,0,0,1,20.491,0A17.777,17.777,0,0,1,39.246,88.5ZM62.3,86.322a14.625,14.625,0,0,0-25.61,0,17.875,17.875,0,1,1,25.61,0ZM49.5,59.25a8.125,8.125,0,1,0,8.125,8.125A8.125,8.125,0,0,0,49.5,59.25Zm0,13a4.875,4.875,0,1,1,4.875-4.875A4.875,4.875,0,0,1,49.5,72.25ZM33.25,44.625v-9.75a1.625,1.625,0,0,0-1.625-1.625h-9.75a1.625,1.625,0,0,0-1.625,1.625v9.75a1.625,1.625,0,0,0,1.625,1.625h9.75A1.625,1.625,0,0,0,33.25,44.625ZM30,43H23.5V36.5H30Zm48.75-6.5h-39V33.25h39Zm-39,6.5h39v3.25h-39Z" transform="translate(380 271)" fill={primaryColor} />
      </g>
    </svg>
  )
}