import { COMPANY_DATA, COMPANY_INVITE_LINK, SELECTED_COMPANY_UPLOADER_FIELDS_PUBLIC, SINGLE_COMPANY_DATA, COMPANY_JOB_DETAILS, PUBLIC_COMPANY_LIST } from "./action";

export const CompanyData = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_DATA:
      return { ...state, details: action.payload };
    case SINGLE_COMPANY_DATA:
      return { ...state, singeCompany: action.payload };
    case COMPANY_JOB_DETAILS:
      return { ...state, jobDetails: action.payload };
    case SELECTED_COMPANY_UPLOADER_FIELDS_PUBLIC:
      return { ...state, selectedPublicData: action.payload };

    default:
      return state;
  }
};

export const CompanyJobData = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_JOB_DETAILS:
      return { ...state, jobDetails: action.payload };

    default:
      return state;
  }
};

export const CompanyInviteLink = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_INVITE_LINK:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export const PublicCompany = (state = {}, action) => {
  switch (action.type) {
    case PUBLIC_COMPANY_LIST:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};
