import React, { useEffect,useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import pSBC from "shade-blend-color";


import {
  Radio,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Grid,Chip,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "@material-ui/lab";
import { useAlert } from "react-alert";


const useStyles = makeStyles((theme) => ({
  overview: {
    paddingBottom: "15px",
    paddingTop: "5px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",

  },
  chipDisplay:{
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
  },

  input: {
    height: 48,
  },
  textfiledrow: {
    height: 120,
  },

  inputbase: {
    padding: "10px 14px",
    fontSize: "14px",
  },
  resize: {
    color: "#7e7e7e",
    padding: "6px",
    fontSize: "14px",
    paddingLeft: "10px",
  },

  MuiInput: {
    height: 48,
  },

  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  margin: {
    width: "100%",
  },
  cal: {
    marginBottom: "20px",
  },

  check: {
    display: "flex",
  },

  textField: {
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio className={classes.root} disableRipple color="primary" {...props} />
  );
}

export default function WorkExperienceForm({
  addedWorkEx,
  setAddedWorkEx,
  companiesList,
  setIsCompanyNotListed,
  isCompanyNotListed,skillsList,
}) {

  const [tempUserSkills, setTempUserSkills] = useState(addedWorkEx?.skills?.length > 0 ? addedWorkEx?.skills : []);
  const alert = useAlert();

  const classes = useStyles();
  const jobData = [
    { job_type_id: "1", job_type_name: "Internship" },
    { job_type_id: "7", job_type_name: "Full-time Job" },
    { job_type_id: "4", job_type_name: "Part Time Job" },
  ];

  const jobTypeMap = {
    1: "Internship",
    7: "Full-time Job",
    4: "Part Time Job",
  };


  useEffect(() => {
   
      setAddedWorkEx({...addedWorkEx, skills: tempUserSkills});  
                  
  },[tempUserSkills?.length])

 const handleDelete = (index) => {
    setTempUserSkills((tempUserSkills) =>
      tempUserSkills.filter((_, skillIndex) => skillIndex !== index)
    );
  };

  const addSkill = (skill) => {
    if (skill) {
      const skillAlreadyAdded = tempUserSkills.some(
        (skl) => skl.skill_id === skill.skill_id
      );
      if (skillAlreadyAdded) alert.error("Already Added!");
      else {
        setTempUserSkills([
          ...tempUserSkills,
          {
            skill_id: skill.skill_id,
            skill_name: skill.skill_name,
            type: skill.type,
            skill_rating_value: 3,
            skill_rating: "Intermediate",
          },
        ]);
        
      }
    }
  }; 


  useEffect(() => {
    if (Object.keys(addedWorkEx).length === 0) {
      setIsCompanyNotListed(false);
      return;
    }
    if (addedWorkEx && !addedWorkEx?.company_id) setIsCompanyNotListed(true);
  }, [addedWorkEx?.company_id]);

  const CHARACTER_LIMIT = 150;

  return (
    <>
      <div className={classes.overview}>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label="Job Title"
                //   size="small"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                variant="outlined"
                id="custom-css-outlined-input"
                value={addedWorkEx?.job_designation}
                onChange={(event) => {
                  setAddedWorkEx({
                    ...addedWorkEx,
                    job_designation: event.target.value,
                  });
                }}
              />
            </Grid>
            {isCompanyNotListed ? (
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  label="Enter Company Name"
                  //size="small"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  variant="outlined"
                  id="job-title"
                  value={addedWorkEx?.company_name}
                  onChange={(event) => {
                    setAddedWorkEx({
                      ...addedWorkEx,
                      company_name: event.target.value,
                      company_id: null,
                    });
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                >
                  <Autocomplete
                    id="select-company"
                    // value={addedWorkEx.company_name}
                    value={{
                      company: addedWorkEx?.company_name,
                      id: addedWorkEx?.company_id,
                    }}
                    renderOption={(option) => {
                      return (
                        <span
                          style={{ fontSize: 13 }}
                        >{`${option.company}`}</span>
                      );
                    }}
                    options={companiesList}
                    getOptionLabel={(item) => item?.company}
                    onChange={(e, item) => {
                      if (item) {
                        setAddedWorkEx({
                          ...addedWorkEx,
                          company_id: item?.id,
                          company_name: item?.company,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            
            <Grid item xs={12}>
              <div>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup className={classes.check}>
                    <FormControlLabel
                      value="read"
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={isCompanyNotListed ? true : false}
                        />
                      }
                      onClick={() => {
                        if (isCompanyNotListed)
                          setAddedWorkEx({
                            ...addedWorkEx,
                            company_website: null,
                            company_name: null,
                          });
                        else
                          setAddedWorkEx({ ...addedWorkEx, company_id: null });
                        setIsCompanyNotListed(!isCompanyNotListed);
                      }}
                      label={
                        <Typography
                          variant="body2"
                          className={classes.secondfont}
                        >
                          My Company not listed
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Grid>

            {isCompanyNotListed && (
              <Grid item xs={12}>
                <TextField
                  style={{ marginTop: "-10px" }}
                  className={classes.textField}
                  label="Company Website"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  // size="small"
                  variant="outlined"
                  id="custom-css-outlined-input"
                  value={addedWorkEx?.company_website}
                  onChange={(e) => {
                    setAddedWorkEx({
                      ...addedWorkEx,
                      company_website: e?.target?.value,
                    });
                  }}
                />
              </Grid>
            )}
             <Grid item xs={12}>
                      <Autocomplete
                        // multiple
                        style={{ width: "100%" }}
                        id="combo-box-demo"
                        onChange={(_, newValue) => {
                          if (newValue) {
                            addSkill(newValue);
                          }
                        }}
                        options={skillsList}
                        getOptionLabel={(option) => `${option.skill_name}`}
                        renderOption={(option) => {
                          return (
                            <span style={{ fontSize: 13 }}>{`${option.skill_name}`}</span>
                          );
                        }}
                        className={classes.fieldStyling1}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Select Skill"
                              variant="outlined"
                              InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                              }}
                            />
                          );
                        }}
                  />
           </Grid>
            <div className={classes.chipDisplay}>
                {tempUserSkills &&
                  tempUserSkills.map((data, index) => {
                    return (
                      <li key={data.skill_id}>
                        <Chip
                          color="primary"
                          deleteIcon={<CloseIcon color="primary" />}
                          variant="outlined"
                          size="small"
                          label={data.skill_name}
                          onDelete={() => {
                            handleDelete(index);
                          }}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
              </div>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Job Type
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  className={classes.check}
                  aria-label="gender"
                  value={JSON.stringify(addedWorkEx?.job_type_id)}
                  onChange={(event) => {
                    setAddedWorkEx({
                      ...addedWorkEx,
                      job_type_id: parseInt(event.target.value),
                      job_type_name: jobTypeMap[event.target.value],
                    });
                  }}
                >
                  {jobData.map((data) => {
                    return (
                      <FormControlLabel
                        value={data?.job_type_id}
                        control={<StyledRadio />}
                        label={
                          <Typography
                            variant="body2"
                            className={classes.secondfont}
                          >
                            {data.job_type_name}
                          </Typography>
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <div>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <Typography
                    variant="body2"
                    className={classes.secondfont}
                    style={{ fontWeight: "bold" }}
                  >
                    Time Period
                  </Typography>
                  <FormGroup className={classes.check}>
                    <FormControlLabel
                      value="read"
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={
                            addedWorkEx?.is_current_working ? true : false
                          }
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          className={classes.secondfont}
                        >
                          Currently working here
                        </Typography>
                      }
                      onChange={(event) => {
                        setAddedWorkEx({
                          ...addedWorkEx,
                          is_current_working: event.target.checked,
                          end_date: null,
                        });
                      }}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={6} style={{ marginTop: "-7px" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  format="MM/yyyy"
                  openTo="year"
                  // margin="normal"
                  views={["year", "month"]}
                  // format="MM/yyyy"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="Start Date"
                  value={
                    addedWorkEx?.start_date ? addedWorkEx?.start_date : null
                  }
                  onChange={(date, value) => {
                    setAddedWorkEx({ ...addedWorkEx, start_date: date });
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {!addedWorkEx?.is_current_working && (
              <>
                <Grid item xs={6} style={{ marginTop: "-7px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="outlined"
                      format="MM/yyyy"
                      openTo="year"
                      //margin="normal"
                      views={["year", "month"]}
                      // format="MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      defaultValue="MM/YYYY"
                      label="End Date"
                      value={
                        addedWorkEx?.end_date ? addedWorkEx?.end_date : null
                      }
                      onChange={(date, value) => {
                        setAddedWorkEx({ ...addedWorkEx, end_date: date });
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography
                variant="body2"
                className={classes.font}
                style={{ fontWeight: "bold" }}
              >
                Description
              </Typography>
              <Typography
                style={{ color: "#b0b6ba", marginTop: "10px" }}
                variant="body2"
              >
                Describe your professional responsibilities and accomplishments.
                (Eg: Maintained inventory of office supplies and ordered as and
                when needed)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                margin="normal"
                multiline
                maxRows={3}
                minRows={3}
                inputProps={{
                  className: classes.textfiledrow,
                  maxlength: CHARACTER_LIMIT,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={addedWorkEx?.job_description}
                onChange={(e) => {
                  setAddedWorkEx({
                    ...addedWorkEx,
                    job_description: e.target.value,
                  });
                }}
                helperText={`${
                  addedWorkEx?.job_description?.length
                    ? addedWorkEx?.job_description?.length
                    : 0
                }/${CHARACTER_LIMIT}`}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
