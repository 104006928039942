import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, IconButton, Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    marginBottom: "10px",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    padding: "7px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginLeft: "7px",
    marginTop: "7px",
  },
}));

export default function AdminAccess({
  item,
  handleClickOpen,
  open,
  collegeId,
  userRolesPermission,
}) {
  const classes = useStyles();
  const [showList, setShowList] = useState(false);
  const [accessControlList, setAccessControlList] = useState([]);
  useEffect(() => {
    if (!showList)
      setAccessControlList(item?.access_controls_list?.slice(0, 4));
    else setAccessControlList(item?.access_controls_list);
  }, [showList, open]);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex" }}>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h6">{item?.user_role_name}</Typography>
              </Grid>
              <Grid item xs={1}>
                {item?.is_editable &&
                  userRolesPermission?.some(
                    (item) => item.access_control_key === "manage_user_role"
                  ) && (
                    <IconButton
                      onClick={() => handleClickOpen(item)}
                      variant="contained"
                      style={{
                        marginLeft: "10px",
                        padding: "0px",
                      }}
                    >
                      <EditIcon style={{ color: "#181818" }} />
                    </IconButton>
                  )}
              </Grid>
            </Grid>
          </div>
          <div>
            <Typography variant="h6">
              Access Control ({item?.access_controls_list?.length})
            </Typography>
            <div style={{ marginTop: "7px" }}>
              <ul style={{ display: "contents" }}>
                {accessControlList?.map((line) => (
                  <Typography
                    variant="body2"
                    color="secondary"
                    // align="center"
                    key={line}
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    {line?.access_control_name}
                  </Typography>
                ))}
              </ul>
            </div>
          </div>
          {item?.access_controls_list?.length > 4 && (
            <>
              <Button
                color="primary"
                style={{ padding: "0px" }}
                onClick={() => {
                  setShowList(!showList);
                }}
              >
                {showList ? "Show Less" : "Show Complete List"}
              </Button>
            </>
          )}
        </Paper>
      </div>
    </>
  );
}

const round = [
  {
    description: [
      "Can Track and Change Applicants Status",
      "Can add/remove custom Fields on e-JD/e-JNF",
      "Can download/Upload student Data",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
      "Can Manage all the controls of other TnP Members",
    ],
  },
];
