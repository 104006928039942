import {
  Dialog,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import React from "react";
import { useAlert } from "react-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { collegeDetailSelectorFn } from "../../../api/SignIn/selector";
import JobSharerButton from "../../../utility/JobSharer/JobSharerButton";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  root: {
    padding: "20px",
    width: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    width: "40%",
    marginBottom: "10px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  circle: {
    width: "100px",
    height: "100px",
    backgroundColor: "#28a745",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
  },
}));

export default function TrackShareSuccessfully({
  data,
  handleClickOpen,
  handleClose,
  open,
  jobTitle,
  source,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const alert = useAlert();
  const collegeDetails = useSelector(collegeDetailSelectorFn);


  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.root}>
          <div
            style={{
              padding: "10px",
              textAlign: "center",
            }}
          >
            <div className={classes.circle}>
              <CheckCircleIcon style={{ color: "#fff", fontSize: "70px" }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <CheckCircleIcon
                  style={{
                    color: "#28a745",
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                />
              </div>
              <div>
                <Typography variant="body2 ">
                  {source === "EJNF"
                    ? `E-JNF Link has been generated Successfully. Use below link to share with company`
                    : `Track Sharer Link has been generated Successfully. Use the below Pascode and Link`}
                </Typography>
              </div>
            </div>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid
                item
                className={classes.grid}
                xs={source !== "EJNF" ? 9 : 12}
              >
                <div className={classes.paper}>
                  <Paper component="form" className={classes.link}>
                    <InputBase
                      className={classes.input}
                      placeholder={data?.link}
                      inputProps={{
                        className: classes.labellink,
                        "aria-label": "https://getwork-ejd-mba.in",
                      }}
                    />
                    <CopyToClipboard
                      text={data?.link}
                      onCopy={() => alert.success("Link Copied!")}
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-label="copy"
                      >
                        <FileCopyOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  </Paper>
                </div>
              </Grid>

              {source !== "EJNF" && (
                <Grid item xs={3} className={classes.grid}>
                  <TextField
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    label="Passcode"
                    inputProps={{
                      style: {
                        padding: "16px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                      },
                    }}
                    style={{ width: "120%" }}
                    disabled
                    value={data?.passcode}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </div>
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <JobSharerButton
            detail={{
              url: data?.link,
              user: collegeDetails?.college_name,
              jobProfile: jobTitle,
            }}
            page={source === "EJNF" ? "SHARE_EJNF" : "SHARE_TRACK"}
          />
        </div>
        <br />
        <br />
        {/* <div className={classes.btngrid}>
          <Button autoFocus className={classes.button} variant="outlined" onClick={handleClose} color="primary">
            Back
          </Button>
        </div> */}
      </Dialog>
    </div>
  );
}
