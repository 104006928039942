import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 4%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    //   marginBottom: "10px",
    padding: "30px",
    // display: "flex",
    margin: "auto",
    justifyContent: "center",
    height: 230,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // flexDirection: "column",
  },
  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    height: "34px",
    display: "flex",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function JobTypeCards({
  heading,
  subTitle,
  buttonName,
  action,
  actionKey,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" style={{ textAlign: "center" }}>
              {" "}
              {heading}
            </Typography>
          </div>

          <Typography
            variant="body2"
            color="secondary"
            style={{
              marginTop: "10px",
              marginBottom: "20px",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            {subTitle}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              //  marginBottom: 20,
            }}
          >
            <Button
              size="small"
              color="primary"
              variant="contained"
              className={classes.del}
              onClick={() => {
                action(actionKey);
              }}
            >
              {buttonName}
            </Button>
          </div>
          {/* <br /> */}
          {/* <br /> */}

          {/* <Grid container spacing={3}>
            <Grid item xs={6} className={classes.btngrid}>
              <Button
                color="primary"
                size="small"
                variant="contained"
                className={classes.del}
                onClick={() => {
                  window.open(`/job/${item.job_title.toLowerCase().replace(" ", "-")}-${item?.id}`);
                }}
              >
                View
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.btngrid}>
              {" "}
              <Button
                size="small"
                color="primary"
                variant="contained"
                className={classes.del}
                onClick={() => {
                  setDataSelected([]);
                  history.push(`/post-job/clone-${item.id}`);
                }}
                disabled={userRolesPermission?.some((item) => item.access_control_key === "manage_job") === true ? false : true}
              >
                Clone
              </Button>
            </Grid>
          </Grid> */}
        </Paper>
      </div>
    </>
  );
}
