import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import User from "../../../../assets/img/user.png";
import { Box, Button, Tooltip, Zoom } from "@material-ui/core";
import Block from "../../../DialogSection/Block";
import { useLocation } from "react-router-dom";
import { themeSelectorFn } from "../../../../api/Domain/selector";
import { useSelector } from "react-redux";
import { ReactComponent as Default } from "../../../../assets/svg/student_placeholer.svg";
import tick from "../../../../assets/png/verified-user.png";
import { Link, useHistory } from "react-router-dom";
import Drive from "../../../../pages/Drive";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import { praveshAxiosGetReq } from "../../../../api/BaseApi/apiBase";
import RejectDialog from "../../../DialogSection/Block/RejectDialog";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import ChangeRequestDialog from "../../../DialogSection/ChangeRequest/ChangeRequestDialog";
import { capitalizeFirstLetter } from "../../../../utility/commaSeparatedStrings";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    position: "relative",
    //maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  linkColor: {
    //  color: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
  },
  resume: {
    height: "35px",
    boxShadow: "none",
    display: "flex",
    borderRadius: "8px",
    marginTop: "20px",
    fontWeight: "normal",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    //paddingLeft: "20px",
    //paddingRight: "20px",
    marginRight: "20px",
  },
  resumebtn: {
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    display: "flex",
    marginRight: "10px",
  },
  editbutton: {
    backgroundColor: theme.palette.primary.secondary,
    borderRadius: "8px",
    height: "40px",
    boxShadow: "none",
    display: "flex",
  },
  chip: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 8,
    // borderRadius: "8px",
  },
}));

export default function BasicDetails({
  data,
  setStudentData,
  fromTrack,
  fromPublicTrack,
  validatorData,
  BlockStudent,
  blockStatus,
  fromProfileUpdate = false,
  fromProfileStatus,
  item,
  collegeId,
  invitationStatusData,
  getInvitationStatus,
  StudentDetailsAPI,
}) {
  const classes = useStyles();
  const [filterDetails, setFilterDetails] = useState();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  useEffect(() => {
    var filters = data?.student_user_details?.education?.filter(
      (item) => item?.is_current === true
    );
    setFilterDetails(filters);
  }, [data]);
  const theme_change = useSelector(themeSelectorFn);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openRequest, setOpenRequest] = React.useState(false);

  const handleClickOpenRequest = () => {
    setOpenRequest(true);
  };

  const handleCloseRequest = () => {
    setOpenRequest(false);
  };

  const [openApprove, setOpenApprove] = React.useState(false);

  const handleClickOpenApprove = () => {
    setOpenApprove(true);
  };

  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  return (
    <>
      {
        // filterDetails && Object?.keys(filterDetails)?.length > 0 &&
        <div className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase className={classes.image}>
                {data?.profile_picture ? (
                  <img
                    className={classes.img}
                    alt="complex"
                    src={data?.profile_picture}
                  />
                ) : (
                  <>
                    <Default
                      width="128"
                      height="128"
                      alt="img"
                      className={classes.img}
                      fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                    />
                  </>
                )}
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid
                item
                xs
                container
                direction="column"
                spacing={2}
                style={{ display: "contents" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Typography variant="h6">
                      {" "}
                      {data?.first_name + " " + data?.last_name}
                    </Typography>
                    &nbsp;{" "}
                    {/*    <img src={tick} style={{ height: 20, marginTop: 2 }} /> */}
                  </div>
                  {/* <div style={{ display: "flex", marginTop: "10px" }}>
                    <Typography variant="body2">
                      {" "}
                      Profession: {data?.student_user_details?.profession}
                    </Typography>
                    &nbsp;
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      marginBottom: "10px",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">
                      Status :{" "}
                      {!location.pathname.includes("pending")
                        ? !blockStatus
                          ? "Active"
                          : "Blocked"
                        : "Pending"}
                    </Typography>{" "}
                    <div
                      style={{
                        width: "10px",
                        marginLeft: "10px",
                        height: "10px",
                        backgroundColor: !blockStatus ? "#51c03e" : "red",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                  {filterDetails &&
                    filterDetails?.length > 0 &&
                    filterDetails[0]?.degree && (
                      <Typography variant="body2" color="textSecondary">
                        {filterDetails[0]?.degree}{" "}
                        {filterDetails[0]?.specialization && (
                          <> in {filterDetails[0]?.specialization}</>
                        )}{" "}
                      </Typography>
                    )}
                  {filterDetails && filterDetails[0]?.enrollment_id && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      Enrollment No: {filterDetails[0]?.enrollment_id}
                    </Typography>
                  )}
                  {filterDetails && filterDetails[0]?.backlog && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      Active backlogs: {filterDetails[0]?.backlog}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {data?.student_user_details?.saas_verification_status &&
            !fromProfileUpdate && (
              <div
                style={{
                  display: "flex",
                  // marginLeft: "10px",
                  marginTop: "10px",
                  justifyContent: "flex-start",
                }}
              >
                {data?.student_user_details?.college_resume &&
                  (fromTrack || fromPublicTrack) && (
                    <a
                      href={data?.student_user_details?.college_resume}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        style={{ width: "9rem" }}
                      >
                        View Resume
                      </Button>
                    </a>
                  )}

                {data?.cover_letter && (fromTrack || fromPublicTrack) && (
                  <a
                    href={data?.cover_letter}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      style={{ width: "8rem" }}
                    >
                      Cover Letter
                    </Button>
                  </a>
                )}

                {!fromProfileUpdate &&
                  !fromTrack &&
                  !location.pathname.includes("pending") && (
                    <Button
                      variant="contained"
                      color="primary"
                      /*  disabled={
                !data?.student_user_details?.has_document ? true : false
              } */
                      className={classes.button}
                      onClick={() => {
                        history.push(
                          `/student-drive/${data?.first_name?.replace(
                            / /g,
                            "_"
                          )}_${data?.last_name?.replace(/ /g, "_")}-${data?.id}`
                        );
                      }}
                    >
                      Documents
                    </Button>
                  )}

                {!fromProfileUpdate &&
                  !fromTrack &&
                  !location.pathname.includes("pending") && (
                    <Block
                      BlockStudent={BlockStudent}
                      blockStatus={blockStatus}
                    />
                  )}

                {fromPublicTrack ? (
                  <></>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.editbutton}
                      onClick={() => {
                        history.push(
                          `/edit-student/${data?.first_name?.replace(
                            / /g,
                            "_"
                          )}_${data?.last_name?.replace(/ /g, "_")}-${data?.id}`
                        );
                      }}
                    >
                      Edit Info
                    </Button>
                  </>
                )}
              </div>
            )}
          {!fromTrack && location.pathname.includes("pending") && <></>}

          {!data?.student_user_details?.saas_verification_status && (
            <>
              {invitationStatusData &&
                invitationStatusData?.status &&
                !(
                  invitationStatusData?.status === "APPROVED" ||
                  invitationStatusData?.status === "REJECTED"
                ) && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      style={{
                        width: "120px",
                      }}
                      onClick={handleClickOpenApprove}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        borderColor: "#ff0000",
                        color: "#ff0000",
                        width: "120px",
                      }}
                      className={classes.button}
                      onClick={handleClickOpen}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#6f6f6f",
                        color: "#fff",
                        width: "160px",
                      }}
                      className={classes.button}
                      onClick={handleClickOpenRequest}
                    >
                      <span>Change Request</span>
                    </Button>
                    <RejectDialog
                      open={openApprove}
                      handleClickOpen={handleClickOpenApprove}
                      handleClose={handleCloseApprove}
                      reject={false}
                      userId={data?.id}
                      collegeId={collegeId}
                      getInvitationStatus={getInvitationStatus}
                      StudentDetailsAPI={StudentDetailsAPI}
                    />
                    <RejectDialog
                      open={open}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      reject={true}
                      userId={data?.id}
                      collegeId={collegeId}
                      getInvitationStatus={getInvitationStatus}
                      StudentDetailsAPI={StudentDetailsAPI}
                    />
                    <ChangeRequestDialog
                      userId={data?.id}
                      collegeId={collegeId}
                      open={openRequest}
                      handleClickOpen={handleClickOpenRequest}
                      handleClose={handleCloseRequest}
                      getInvitationStatus={getInvitationStatus}
                    />
                  </div>
                )}

              <Box
                style={{
                  backgroundColor:
                    item?.change_type === "ADD"
                      ? theme.palette.primary.main
                      : item?.change_type === "UPDATE"
                      ? "#ff0000"
                      : "#6f6f6f",
                }}
                className={classes.chip}
              >
                <Typography style={{ color: "#fff", fontSize: "14px" }}>
                  {invitationStatusData?.status
                    ? capitalizeFirstLetter(invitationStatusData?.status)
                        ?.split("_")
                        ?.join(" ")
                    : ""}
                </Typography>
              </Box>
            </>
          )}
        </div>
      }
    </>
  );
}
