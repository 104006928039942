import { Button, Tooltip, Typography, Chip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { truncateLarge } from "../../../utility/ellipsify";
import { generateCsvNew } from "../../../utility/commaSeparatedStrings";
import TrackShareSuccessfully from "../../DialogSection/TrackShareSuccessfull";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import pSBC from "shade-blend-color";
import { Item } from "devextreme-react/box";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "https://app.getwork.org/public/trac...",
    "List Item",
    "Track, Download",
    "Email, Resume",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "https://app.getwork.org/public/trac...",
    "List Item",
    "Track, Download",
    "Email, Resume",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "https://app.getwork.org/public/trac...",
    "List Item",
    "Track, Download",
    "Email, Resume",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "https://app.getwork.org/public/trac...",
    "List Item",
    "Track, Download",
    "Email, Resume",
    "prerit.chaudhary@getwork.org"
  ),
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    borderRadius: "8px",
  },
}));

export default function History({ data, jobTitle, fromStudent }) {
  const classes = useStyles();

  const checkActions = (obj) => {
    var arr = [];
    var str = "";

    if (fromStudent) {
      if (obj?.track === true) arr.push("Filtering");
    } else {
      if (obj?.track === true) arr.push("Track");
    }

    if (obj?.download === true) arr.push("Download");
    var str = generateCsvNew(arr);

    return str;
  };

  const alert = useAlert();
  const [showMore, setShowMore] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [shareSuccessData, setShareSuccessData] = useState({
    link: null,
    passcode: null,
  });

  const capitalizeFirstLetter = (newArr) => {
    var new_arr = [];
    for (let day of newArr) {
      day = day.charAt(0).toUpperCase() + day.substr(1);
      new_arr.push(day);
    }
    return new_arr;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell>
                {fromStudent ? "Student Link" : "Job Link"}
              </StyledTableCell>
              <StyledTableCell align="left">Passcode</StyledTableCell> */}
              <StyledTableCell align="center">
                Selected Table Column
              </StyledTableCell>
              <StyledTableCell align="center">Invite Email </StyledTableCell>

              <StyledTableCell align="center"> User Action</StyledTableCell>
              <StyledTableCell align="center">Share Again</StyledTableCell>
              {fromStudent ? (
                <>
                  <StyledTableCell align="center">Passcode</StyledTableCell>
                  <StyledTableCell align="center"> Copy</StyledTableCell>
                </>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow
                key={fromStudent ? row.previous_links : row.job_link}
              >
                {/* <Tooltip
                  title={fromStudent ? row.previous_links : row.job_link}
                  placement="right"
                >
                  <StyledTableCell
                    component="th"
                    style={{ borderRight: "1px solid #D3D3D3" }}
                    scope="row"
                  >
                    {truncateLarge(
                      fromStudent ? row.previous_links : row.job_link
                    )}
                  </StyledTableCell>
                </Tooltip>
                <StyledTableCell
                  align="center"
                  style={{ borderRight: "1px solid #D3D3D3" }}
                >
                  {fromStudent ? row?.previous_passcode : row?.passcode}
                </StyledTableCell> */}
                {/* <Tooltip
                  title={
                    fromStudent
                      ? generateCsvNew(row?.previous_action)
                      : generateCsvNew(row?.action_performed?.action)
                  }
                  placement="right"
                >
                  <StyledTableCell align="center">
                    {fromStudent
                      ? generateCsvNew(row?.previous_action)
                      : generateCsvNew(row?.action_performed?.action)}
                  </StyledTableCell>
                </Tooltip> */}
                <StyledTableCell
                  align="center"
                  style={{ borderRight: "1px solid #D3D3D3", width: "380px" }}
                >
                  <>
                    {!showMore
                      ? row?.action_performed?.action
                          ?.slice(0, 8)
                          .map((item) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              size="small"
                              label={item}
                              className={classes.chip}
                              style={{
                                borderRadius: 5,
                              }}
                            />
                          ))
                      : row?.action_performed?.action?.map((item) => (
                          <Chip
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={item}
                            className={classes.chip}
                            style={{
                              borderRadius: 5,
                            }}
                          />
                        ))}

                    {row?.action_performed?.action?.length > 8 && (
                      <Button
                        color="primary"
                        onClick={() => {
                          setShowMore(!showMore);
                        }}
                      >
                        {" "}
                        {!showMore
                          ? `+${row?.action_performed?.action?.length - 8} more`
                          : "Hide"}
                      </Button>
                    )}
                  </>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ borderRight: "1px solid #D3D3D3", width: "200px" }}
                >
                  {/* {fromStudent
                    ? generateCsvNew(row?.previous_email)
                    : generateCsvNew(row?.action_performed?.email)} */}

                  <>
                    {!showMore
                      ? row?.action_performed?.email
                          ?.slice(0, 4)
                          .map((item) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              size="small"
                              label={item}
                              className={classes.chip}
                              style={{
                                border: "1px solid #F2F1F1",
                                backgroundColor: "#FAFAFA",
                                borderRadius: 5,
                                color: "#181818",
                              }}
                            />
                          ))
                      : row?.row?.action_performed?.email?.map((item) => (
                          <Chip
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={item}
                            className={classes.chip}
                            style={{
                              border: "1px solid #F2F1F1",
                              backgroundColor: "#FAFAFA",
                              borderRadius: 5,
                              color: "#181818",
                            }}
                          />
                        ))}

                    {row?.action_performed?.email?.length > 4 && (
                      <Button
                        color="primary"
                        onClick={() => {
                          setShowMore(!showMore);
                        }}
                      >
                        {" "}
                        {!showMore
                          ? `+${row?.action_performed?.email?.length - 4} more`
                          : "Hide"}
                      </Button>
                    )}
                  </>
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ borderRight: "1px solid #D3D3D3" }}
                >
                  {checkActions(
                    fromStudent
                      ? row?.previous_authority
                      : row?.action_performed?.authority
                  )}
                </StyledTableCell>

                {/* <StyledTableCell
                  align="center"
                  style={{ borderRight: "1px solid #D3D3D3" }}
                >
                  {generateCsvNew(
                    capitalizeFirstLetter(
                      fromStudent
                        ? row?.previous_action
                        : row?.action_performed?.action
                    )
                  )}
                </StyledTableCell> */}
                {fromStudent ? null : (
                  <StyledTableCell
                    align="center"
                    style={{ borderRight: "1px solid #D3D3D3", width: 120 }}
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        setShareSuccessData({
                          ...shareSuccessData,
                          link: row.job_link,
                          passcode: row.passcode,
                        });
                        handleClickOpen();
                      }}
                    >
                      Share
                    </Button>
                  </StyledTableCell>
                )}

                {fromStudent ? (
                  <>
                    <StyledTableCell
                      align="center"
                      style={{ borderRight: "1px solid #D3D3D3" }}
                    >
                      {row?.previous_passcode}
                    </StyledTableCell>
                    <CopyToClipboard
                      text={row.previous_links}
                      onCopy={() => alert.success("Link Copied!")}
                    >
                      <StyledTableCell align="center">
                        {/* Copy */}
                        <Typography
                          // variant="h6"
                          color="primary"
                          style={{
                            marginLeft: "-10px",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          Copy
                        </Typography>
                      </StyledTableCell>
                    </CopyToClipboard>
                  </>
                ) : null}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TrackShareSuccessfully
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        data={shareSuccessData}
        jobTitle={jobTitle}
      />
    </>
  );
}
