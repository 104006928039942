import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { themeSelectorFn } from "../../api/Domain/selector";
import { ReactComponent as NoData } from "../../assets/svg/New entries-rafiki.svg";

const useStyles = makeStyles(theme => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  img: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    alignItems: "center",
    margin: "auto",
  },
}));

export default function NotAuthorized({
  title,
  subtitle,
  buttonText,
  height,
  width,
  link,
}) {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);
  const history = useHistory();
  return (
    <>
      <div className={classes.img}>
        <NoData
          height={height ? height : "400px"}
          width={width ? width : "400px"}
          alt="404 Error"
          fill={theme_change?.main ? theme_change?.main : "#007BFF"}
        />

        <Typography
          style={{ fontSize: "24px" }}
          // style={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            color: "#6c757d",
            marginTop: "10px",
            fontSize: "18px",
            width: "300px",
            textAlign: "center",
          }}
        >
          {subtitle}
          <br />
          {/* You may also refresh the page or try again later */}
        </Typography>
        <br />
        {buttonText ? (
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(link);
            }}
          >
            {buttonText}{" "}
          </Button>
        ) : null}
      </div>
    </>
  );
}
