import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import graduated from "../../../assets/icons/graduated.png";
import { typography } from "@material-ui/system";
import Dashboard from "../../../pages/Dashboard";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    //  width: "20%",
    borderRadius: "8px",
    boxShadow: "none",
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#c8facd",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function HeaderCard() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div className={classes.item}>
                <div
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "#E3FCE6",
                    padding: "12px",
                  }}
                >
                  <img src={graduated} height="31px" width="35px" />
                </div>
                <Typography variant="h6"> Registered Students</Typography>
              </div>
              <Typography variant="h4" color="inherit">
                224
              </Typography>
              <br />
              <Typography variant="h6">Placed Students: 124</Typography>
              <br />
              <Typography variant="h6">Eligible Students: 100</Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
