import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ProfileApproveDialog from "../../EditStudent/ProfileApproveDialog/ProfileApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "20px auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
}));

export default function HigherEducation({
  data,
  // userId,
  // StudentDetailsAPI,
  // fromProfileUpdate,
}) {
  const classes = useStyles();
  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
        {data && Object.keys(data)?.length !== 0 && (<div className={classes.paper}>
          <Typography variant="h6">Higher Education</Typography>
                    <div>
                      <Typography variant="body1" style={{ marginTop: "10px" }}>
                         {data?.higher_studies_degree} 
                      </Typography>
                      <Typography
                        variant="body1" /* style={{ marginTop: "10px" }} */
                      >
                        {data?.college_name}{" "}
                      </Typography>
                      <Typography
                        variant="body1" /* style={{ marginTop: "10px" }} */
                      >
                        {data?.qualify_exam}{" "}
                      </Typography>
                      <Typography
                        variant="body1" /* style={{ marginTop: "10px" }} */
                      >
                        {data?.specialization}{" "}
                      </Typography>
                      <Typography
                        variant="body1" /* style={{ marginTop: "10px" }} */
                      >
                        {data?.state}-{data?.country}{" "}
                      </Typography>
                      {(data?.course_start_date || data?.course_end_date) && (<Typography
                        variant="body1" /* style={{ marginTop: "10px" }} */
                      >
                        {data?.course_start_date}-{data?.course_end_date}{" "}
                      </Typography>)}
                      </div>
                     </div>)}
                
    </>
  );
}
