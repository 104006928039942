import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ALL_ASSIGNMENT, getAllassignment } from "../../api/Assignment/action";
import {
  isEducationGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import AssignmentActionBar from "../../components/Input/AssignmentActionBar";
import AssignmentTrackHistory from "../../components/Table/AssignmentTable/AssignmentTrack";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    height: "50px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
  },
  grid: {
    // marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  new: {
    height: "40px",
    display: "flex",
    boxShadow: "none",
    borderRadius: "8px",
  },
}));

export default function AssignmentTrack() {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const companyList = useSelector(
    (state) => state?.assignment?.assignmentfilter?.filter_data.company_list
  );
  const jobprofileList = useSelector(
    (state) => state?.assignment?.assignmentfilter?.filter_data.job_list
  );
  const assignMentdata = useSelector(
    (state) => state?.assignment?.allassignment
  );
  const assignMentdata1 = useSelector(
    (state) => state?.assignment?.allassignment1
  );

  useEffect(() => {
    dispatch(getAllassignment(setLoading, alert, history, location));
  }, []);

  const filterCompany = (e) => {
    if (e.target.value !== "") {
      const filterData = assignMentdata1.filter(
        (item) => item.company_id === e.target.value
      );
      dispatch({
        type: ALL_ASSIGNMENT,
        payload: filterData,
      });
    } else {
      dispatch({
        type: ALL_ASSIGNMENT,
        payload: assignMentdata1,
      });
    }
  };

  const filterJob = (e) => {
    if (e.target.value !== "") {
      const filterData = assignMentdata1.filter(
        (item) => item.job_id === e.target.value
      );
      dispatch({
        type: ALL_ASSIGNMENT,
        payload: filterData,
      });
    } else {
      dispatch({
        type: ALL_ASSIGNMENT,
        payload: assignMentdata1,
      });
    }
  };
  const userRolesPermission = useSelector(userRolesActionsFn);
  const isEducationGroup = useSelector(isEducationGroupFn);

  return (
    <>
      {/* <Layout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <div>
          {/* <Breadcrumbs aria-label="breadcrumb">
              <Link
                href="/"
                onClick={handleClick}
                style={{ textDecoration: "none" }}
              >
                <Typography color="secondary" variant="body2">
                 
                  Assignment
                </Typography>
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                color="inherit"
                href="/getting-started/installation/"
                onClick={handleClick}
              >
                <Typography color="primary" variant="body2">
              
                </Typography>
              </Link>
            </Breadcrumbs> */}
          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Assignment</Typography>
              <Typography
                variant="body2"
                color="secondary"
                // style={{ marginTop: "10px" }}
              >
                Manage all your Assignment on single page
              </Typography>
            </div>
            {userRolesPermission?.some(
              (item) => item.access_control_key === "manage_assignment"
            ) && (
              <>
                <Link
                  to="/create-assignment"
                  target={`${isEducationGroup ? "_self" : "_blank"}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.new}
                  >
                    Create New Assignment
                  </Button>
                </Link>
              </>
            )}
          </div>
          <AssignmentActionBar
            compantData={companyList}
            jobData={jobprofileList}
            handleChange={filterCompany}
            handleJobprofile={filterJob}
          />
          <br />
          {assignMentdata !== undefined ? (
            <AssignmentTrackHistory data={assignMentdata} />
          ) : null}
        </div>
      )}
      {/* </Layout> */}
    </>
  );
}
