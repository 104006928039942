import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Typography } from "@material-ui/core";

const columns = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 270,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          // marginLeft: "100px",
        }}
      >
        Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          marginLeft: "5px",
          // margin: "auto",
        }}
      >
        <Typography color="primary" variant="body2">
          {params.row.name}{" "}
        </Typography>
      </span>
    ),
  },
  {
    field: "email",
    headerName: "Email ID",
    width: 270,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "100px",
        }}
      >
        Email
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.email}
      </span>
    ),
  },
  {
    field: "phone",
    // type: "number",
    width: 160,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "20px",
        }}
      >
        Phone Number
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.phone}
      </span>
    ),
  },
  {
    field: "role",
    headerName: "Role",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, "firstName") || ""} ${
        params.getValue(params.id, "lastName") || ""
      }`,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "50px",
        }}
      >
        Role
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.role}
      </span>
    ),
  },
  {
    field: "action",
    headerName: "Action",

    width: 150,
    editable: true,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "35px",
        }}
      >
        Action
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Button color="primary" variant="text">
          Remove
        </Button>
      </span>
    ),
  },
];

const rows = [
  {
    id: 1,
    name: "Rohan Singh",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    role: "Placement Head",
  },
  {
    id: 2,
    name: "Rohan Singh",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    role: "Placement Head",
  },
  {
    id: 3,
    name: "Rohan Singh",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    role: "Placement Head",
  },
  {
    id: 4,
    name: "Rohan Singh",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    role: "Placement Head",
  },
  {
    id: 5,
    name: "Rohan Singh",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    role: "Placement Head",
  },
  {
    id: 6,
    name: "Rohan Singh",
    email: "rohan.singh@gamil.coom",
    phone: 1234567890,
    role: "Placement Head",
  },
];

export default function EventTable() {
  return (
    <div style={{ height: 400, width: "90%" }}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
          boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        }}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}
