import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CompanyGetAPI } from "../../api/Company/action";
import {
  collegeGroupListSelectorFn,
  collegeIdFn,
  isCollegeGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import AddNewCompany from "../../components/DialogSection/AddNewCompany";
import CompanyTab from "../../components/Header/CompanyTab";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";
import checkSelectedGroup from "../../utility/getGroupIdParam";
import { isEducationGroupFn } from "../../api/Domain/selector";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function CompanyTrackGroup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);

  const [clickSearchButton, setClickSearchButton] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [applyClickedCTC, setApplyClickedCTC] = useState(false);

  const collegeId = useSelector(collegeIdFn);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);

  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [filtersCompany, setFiltersCompany] = useState({
    search: null,
    sector: null,
    industry: null,
    ctc: null,
    status: null,
    created: null,
    industryObj: null,
    min_ctc: null,
    max_ctc: null,
    current_tab: "All",
  });

  const companyList1 = useSelector((state) => state?.CompanyData?.details);
  const [companyList, setCompanyList] = useState(companyList1);

  // This will sync up the table data with current values.
  useEffect(() => {
    if (totalCount === 0) {
      if (filtersCompany?.current_tab === "All")
        var url = `api/company/view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;

      dispatch(
        CompanyGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setApplyClickedCTC,
          setTotalCount,
          setPendingCount
        )
      );
    }
  }, [totalCount]);

  const searchFilter = () => {
    if (filtersCompany.search) return `search=${filtersCompany.search}`;
    else return ``;
  };

  const sectorFilter = () => {
    if (filtersCompany.sector && filtersCompany.sector !== "All")
      return `&sector=${filtersCompany.sector}`;
    else return ``;
  };
  const industryFilter = () => {
    if (filtersCompany.industryObj)
      return `&industry_id=${filtersCompany.industryObj?.id}`;
    else return ``;
  };
  const ctcFilter = () => {
    if (filtersCompany.ctc_min && filtersCompany?.ctc_max)
      return `&ctc_min=${Number(filtersCompany.ctc_min) * 100000}&ctc_max=${
        Number(filtersCompany.ctc_max) * 100000
      }`;
    else if (filtersCompany.ctc_min)
      return `&ctc_min=${Number(filtersCompany.ctc_min) * 100000}`;
    else if (filtersCompany.ctc_max)
      return `&ctc_max=${Number(filtersCompany.ctc_max) * 100000}`;
    else return ``;
  };
  const statusFilter = () => {
    if (filtersCompany.status && filtersCompany?.status !== "ALL")
      return `&status=${filtersCompany.status}`;
    else return ``;
  };
  const createdFilter = () => {
    if (filtersCompany.created)
      return `&created=${new Date(filtersCompany.created)
        .toISOString()
        .substring(0, 10)}`;
    else return ``;
  };

  const [callNavbar, setCallNavbar] = useState(false);

  useEffect(() => {
    if (
      filtersCompany.sector ||
      filtersCompany.industryObj ||
      filtersCompany.created ||
      filtersCompany.ctc
    ) {
      if (filtersCompany?.current_tab === "All")
        var url = `api/company/view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
      else
        var url = `api/company/invite_view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
      dispatch(
        CompanyGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setApplyClickedCTC,
          setTotalCount,
          setPendingCount
        )
      );
    }
  }, [
    filtersCompany.sector,
    filtersCompany.industryObj,
    filtersCompany.status,
    filtersCompany.created,
    filtersCompany.ctc,
  ]);

  useEffect(() => {
    if (clickSearchButton) {
      if (filtersCompany?.current_tab === "All")
        var url = `api/company/view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
      else
        var url = `api/company/invite_view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
      dispatch(
        CompanyGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setApplyClickedCTC,
          setTotalCount,
          setPendingCount,
          setRejectedCount,
          setCompanyList
        )
      );
    }
  }, [clickSearchButton]);

  useEffect(() => {
    if (applyClickedCTC) {
      if (filtersCompany?.current_tab === "All")
        var url = `api/company/view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
      else
        var url = `api/company/invite_view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
      dispatch(
        CompanyGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setApplyClickedCTC,
          setTotalCount,
          setPendingCount,
          setRejectedCount,
          setCompanyList
        )
      );
    }
  }, [applyClickedCTC]);

  useEffect(() => {
    if (selectedGroup) {
      if (filtersCompany?.current_tab === "All")
        var url = `api/company/view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;
      else
        var url = `api/company/invite_view/?${searchFilter()}${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}${sectorFilter()}${industryFilter()}${ctcFilter()}${statusFilter()}${createdFilter()}`;

      dispatch(
        CompanyGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setApplyClickedCTC,
          setTotalCount,
          setPendingCount,
          setRejectedCount,
          setCompanyList
        )
      );
    }
  }, [selectedGroup]);

  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  useEffect(() => {
    if (collegeGroupList?.length > 1 && isCollegeGroup) {
      setNewCollegeGroupList(
        [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
      );
    } else setNewCollegeGroupList(collegeGroupList);
  }, [collegeGroupList?.length]);

  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <>
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      <div className={classes.grid}>
        <div>
          <Typography variant="h2">Companies</Typography>
          <Typography
            variant="body2"
            color="secondary"
            // style={{ marginTop: "10px" }}
          >
            Manage your all Connected Companies in one place
          </Typography>
        </div>

        {userRolesPermission?.some(
          (item) => item.access_control_key === "manage_company"
        ) && (
          <div style={{ display: "flex" }}>
            {filtersCompany?.current_tab === "All" && (
              <>
                <AddNewCompany collegeId={collegeId} />
              </>
            )}

            <CSVLink
              data={
                selectedRowsData?.length > 0
                  ? selectedRowsData
                  : companyList?.length
                  ? companyList
                  : []
              }
              style={{
                cursor: "pointer",
                textDecoration: "none",
              }}
              filename={"Companies.csv"}
            >
              <Button
                variant="contained"
                startIcon={<GetAppIcon />}
                size="small"
                className={classes.button}
                style={{ textDecoration: "none", marginRight: "0px" }}
              >
                Export
              </Button>{" "}
            </CSVLink>
          </div>
        )}
      </div>
      <CompanyTab
        page={page}
        setPage={setPage}
        totalCount={totalCount}
        pendingCount={pendingCount}
        loading={loading}
        filtersCompany={filtersCompany}
        setFiltersCompany={setFiltersCompany}
        setClickSearchButton={setClickSearchButton}
        setSelectedRowsData={setSelectedRowsData}
        selectedRowsData={selectedRowsData}
        clickSearchButton={clickSearchButton}
        setApplyClickedCTC={setApplyClickedCTC}
        collegeId={collegeId}
        userRolesPermission={userRolesPermission}
        setCallNavbar={setCallNavbar}
        fromGroup={true}
        companyList={companyList}
        rejectedCount={rejectedCount}
      />
    </>
  );
}
