import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import EventTab from "../../components/Header/EventTab";
import EventIcon from "@material-ui/icons/Event";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function AllEvents() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.grid}>
        <div>
          <Typography variant="h2">Events</Typography>
          <Typography variant="body2" color="secondary">
            Manage your all Events in one place
          </Typography>
        </div>
        {/* {userRolesPermission?.some(
          (item) => item.access_control_key === "manage_company"
        ) && ( */}
        <div style={{ display: "flex" }}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            className={classes.primebtn}
            startIcon={<EventIcon />}
          >
            Calendar
          </Button>
          <Link to="/create-event" style={{ textDecoration: "none" }}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.primebtn}
              startIcon={<AddIcon />}
            >
              Add New Event
            </Button>
          </Link>
        </div>
      </div>
      <EventTab />
    </>
  );
}
