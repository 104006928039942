import { Typography, useTheme } from "@material-ui/core";
import React, { useState, useMemo, useEffect } from "react";
import CampusBarGraph from "./CampusBarGraph";
import ReportHeading from "./ReportHeading";
import ReportHeadingSecond from "./ReportHeadingSecond";
import StudentPlacedGraph from "./StudentPlacedGraph";
import GraphSkeleton from "./GraphSkeleton";
import TableTabSection from "./TableTabSection";
import ScrollBarGraph from "./ScrollBarGraph";
import BarTableTabSection from "./BarTableTabSection";
import ThirdGraphSection from "./ThirdGraphSection";
import { sanchalanAxiosGetReq } from "../../../api/BaseApi/apiBase";
import ThirdGraphSectionTable from "./ThirdGraphSectionTable";

function CourseStudent({selectedPlacement, collegeId}) {
  const theme = useTheme();
  const [value, setValue] = useState(1);
  // const [chartData,setChartData] = useState(null);
  function handleChange(id) {
    setValue(id);
  }

   const data = [
    {
      id: 1,
      title: "Student Data",
      tab: (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Number of Student" xAxis="Course" heading="Course vs Student" />
            <br />
            <TableTabSection selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Course vs Gender" from="studentData" />
            <br />
            <BarTableTabSection selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Marks vs Student" yAxis="Number of Student" xAxis="Marks" />
            <br />
            {/* <ReportHeadingSecond />
            <br />
            <GraphSkeleton /> */}
          </div>
        </>
      ),
    },
    {
      id: 2,
      title: "Job Data",
      tab:  (
        <div style={{ display: "flex", flexDirection: "column" }}>
         <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Number of Jobs" xAxis="Course" heading="Course vs Job" />
        <br/>
        <BarTableTabSection selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Job Salary Scale" yAxis="Number of Jobs" xAxis="Job Salary Scale" from="job_data" />
         <br/>
         <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Highest CTC (LPA)" xAxis="Course" heading="Course vs Highest CTC" />
         <br/> 
         <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Average CTC (LPA)" xAxis="Course" heading="Course vs Average CTC" />
         <br/> 
         <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Number of Jobs" xAxis="Industry" heading="Industry vs Jobs"  />
         <br/> 
        <ThirdGraphSectionTable selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Month vs Jobs" yAxis="Number of Placed Jobs" xAxis="Month" from="job_data" />
        </div>
      )

    },
    {
      id: 3,
      title: "Student Placed Data",
      tab: (
        <div style={{ display: "flex", flexDirection: "column" }}>
        <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Student Placed(%)" xAxis="Course" heading="Course vs Student Placed(%)" />
        <br/>
        <TableTabSection selectedPlacement={selectedPlacement} collegeId={collegeId} from="studentPlaced" />
        <br/>
        <BarTableTabSection selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Mark vs Student applicant Placed(%)" yAxis="Student applicants Placed(%)" xAxis="Marks" />
         <br/>
         <ThirdGraphSectionTable selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Month vs Student Placed" yAxis="Student Placed" xAxis="Month"/>
        </div>
      ) 
    },
    {
      id: 4,
      title: "Job Placed Data",
      tab: (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Job Selection (%)" xAxis="Course" heading="Course vs Job Selection(%)" />
        <br/>
        <BarTableTabSection selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Number of Jobs" xAxis="Job Salary Scale" heading="Job Salary Scale" from="job_placed_data" />
        <br/>
        <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Highest CTC (LPA)" xAxis="Course" heading="Course vs Highest CTC Placed" />
        <br/>
        <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Average CTC (LPA)" xAxis="Course" heading="Course vs Average CTC Placed" />
        <br/>
        <ReportHeading selectedPlacement={selectedPlacement} collegeId={collegeId} yAxis="Number of Jobs" xAxis="Industry" heading="Industry vs Placed Jobs" />
        <br/>
        <BarTableTabSection selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Month vs Placed Jobs" yAxis="Number of Jobs" xAxis="Month" from="job_placed_data" />
        <br/>
        <BarTableTabSection selectedPlacement={selectedPlacement} collegeId={collegeId} heading="Job Placed vs Number of Students" yAxis="Number of Jobs Placed" xAxis="Number of Students" from="job_placed_data"   />

        </div>
      ) 
    },
    // {
    //   id: 5,
    //   title: "Star Performer",
    //   tab: <ReportHeading />,
    // },
  ];
  


  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {data.map((item, id) => (
            <ul
              style={{
                padding: 0,
                display: "flex",
                listStyleType: "none",
                flexWrap: "wrap",
                textAlign: "center",
                width: "25%",
              }}
              id="myTab"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              <li
                role="presentation"
                onClick={() => handleChange(item.id)}
                style={{ width: "100%" }}
              >
                <div
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    borderRadius: "8px",

                    color: value === item.id ? "#fff" : "#000",
                    backgroundColor:
                      value === item.id ? theme.palette.primary.main : "#fff",
                  }}
                >
                  {item.title}
                </div>
              </li>
            </ul>
          ))}
        </div>

        {value && data.map(item => {
          
       return  value===item.id ?  (<div id="myTabContent">
            <div
              style={{
                display: value === item.id ? "block " : "none",
                marginTop: "20px",
              }}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {item.tab}
            </div>
          </div>) :(<></>)
        })}
      </div>
    </>
  );
}

export default CourseStudent;

