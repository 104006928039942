import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Tooltip,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  inboundJobColumns,
  inboundPostedColumns,
  inboundRejectedColumns,
} from "./inboundJobsColumns";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import CompanyTable from "../../Table/CompanyTable";
import { Link } from "react-router-dom";
import { truncate } from "../../../utility/ellipsify";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import {
  collegeFormCountSelectorFn,
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import JobActionBar from "../../Input/JobActionBar";
import RejectJobDialog from "../../DialogSection/RejectJobDialog";
import { TablePagination } from "@mui/material";
import ToolTipWithQuestionMark from "../../Common/ToolTips/ToolTipWithQuestionMark";
import InfoToolTip from "../../Common/ToolTips/InfoToolTip";
import JobActionBar2 from "../../Input/JobActionBar/index2";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
  primebtn: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  pagination_sec: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    color: "red",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    color: "red",
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const InboundTab = ({
  navbarData,
  filters,
  setFilters,
  setSelectedRowsData,
  selectedRowsData,
  jobTitleSearch,
  companyNameSearch,
  setJobTitleSearch,
  setCompanyNameSearch,
  setApplyClickedCTC,
  applyClickedCTC,
  collegeId,
  jobList,
  loading,
  handleRejectJob = () => {},
  paginationData,
  pageNo,
  setPageNo = () => {},
  updateJobsList,
  apiCallFlag,
  setJobsList,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const [selectedID, setSelectedID] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const totalJobs = useSelector((store) => store?.JobData?.details?.length);
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const collegeFormCount = useSelector(collegeFormCountSelectorFn);

  const handleChange = (event, newValue) => {
    setFilters({ ...filters, job_status: newValue });
  };

  useEffect(() => {
    setSelectedRowsData([]);
    setFilters({ ...filters, job_title: "", company_name: "" });
  }, [filters?.job_status]);

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);

  const handleChangePage = (event, newPage) => {
    apiCallFlag.current = true;
    // setJobsList([]);
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
  };

  const selectedColumns = {
    PENDING: inboundJobColumns,
    REJECTED: inboundRejectedColumns,
    POSTED: inboundPostedColumns,
  };

  const [shareColumns, setShareColumns] = useState(
    selectedColumns[filters?.job_status].map((col) => ({
      ...col,
      hide: false,
    })) ??
      inboundJobColumns.map((col) => ({
        ...col,
        hide: false,
      }))
  );

  useEffect(() => {
    localStorage.setItem(
      `${filters?.job_status}_column_fields`,
      JSON.stringify(shareColumns)
    );
  }, [shareColumns]);

  useEffect(() => {
    setShareColumns(
      selectedColumns[filters?.job_status].map((col) => ({
        ...col,
        hide: false,
      })) ??
        inboundJobColumns.map((col) => ({
          ...col,
          hide: false,
        }))
    );
  }, [filters.job_status]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
          //  borderBottom: ".5px solid #dddddd",
        }}
      >
        {" "}
        <NewTabs
          value={filters.job_status}
          onChange={handleChange}
          aria-label="ant example"
        >
          {navbarData?.length > 0 &&
            navbarData?.map((item) => (
              <NewTab
                value={item?.param}
                label={`${item?.heading}(${item?.count})`}
                wrapped
                {...a11yProps(item?.param)}
              />
            ))}
        </NewTabs>
      </AppBar>
      <JobActionBar2
        filters={filters}
        setFilters={setFilters}
        selectedRowsData={selectedRowsData}
        setSelectedRowsData={setSelectedRowsData}
        jobTitleSearch={jobTitleSearch}
        setJobTitleSearch={setJobTitleSearch}
        companyNameSearch={companyNameSearch}
        setCompanyNameSearch={setCompanyNameSearch}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        applyClickedCTC={applyClickedCTC}
        setApplyClickedCTC={setApplyClickedCTC}
        collegeId={collegeId}
        tableColumns={shareColumns}
        setTableColumns={setShareColumns}
      />
      {selectedRowsData?.length === 1 && (
        <div
          style={{
            display: "flex",
            marginTop: "15px",
            marginBottom: "20px",
          }}
        >
          {filters?.job_status === "PENDING" && (
            <>
              <Button
                color="primary"
                size="small"
                variant="contained"
                className={classes.primebtn}
                onClick={() => {
                  if (licenceData && licenceData?.display) {
                    alert.error(licenceData?.display_message);
                    return;
                  }

                  if (
                    collegeFormCount?.job_form_count > 0 ||
                    collegeFormCount?.internship_form_count > 0
                  ) {
                    if (selectedRowsData?.[0]?.job_id) {
                      history.push(
                        `/post-recommended-job/recommended-${selectedRowsData?.[0]?.job_id}?inbound_job=true`
                      );
                    }
                  } else {
                    history.push("/job-posting-form/full-time-job");
                  }
                }}
              >
                {collegeFormCount?.job_form_count > 0 ||
                collegeFormCount?.internship_form_count > 0
                  ? "Accept & Post"
                  : "Please Create Your Form"}
              </Button>

              <RejectJobDialog
                selectedRowsData={selectedRowsData}
                setSelectedRowsData={setSelectedRowsData}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                jobList={jobList}
                collegeId={collegeId}
                from={"INBOUND_JOBS"}
                handleRejectJob={handleRejectJob}
              />
            </>
          )}
          {filters?.job_status === "REJECTED" && (
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.primebtn}
              onClick={() => {
                if (licenceData && licenceData?.display) {
                  alert.error(licenceData?.display_message);
                  return;
                }

                handleRejectJob("ADD");
              }}
            >
              Add to Pending
            </Button>
          )}
        </div>
      )}

      {
        <CompanyTable
          loading={loading}
          list={jobList}
          columns={shareColumns}
          setSelectedRowsData={setSelectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          isPaginated={false}
        />
      }
      {
        <section className={classes.pagination_sec}>
          <TablePagination
            component="div"
            count={paginationData?.totalDataCount ?? 0}
            page={pageNo}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </section>
      }
    </div>
  );
};

export default InboundTab;
