import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Paper, InputBase } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Face from "../../../assets/icons/facebook.png";
import What from "../../../assets/icons/whatsapp.png";
import Insta from "../../../assets/png/insta.png";
import Gmail from "../../../assets/icons/gmail.png";

const useStyles = makeStyles((theme) => ({
  link: {
    width: "50%",
    boxShadow: "none",
    // border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
    // backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  share: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  social: {
    marginRight: "10px",
    marginLeft: "10px",
    cursor: "pointer",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function EventShare() {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [generatedLink, setGeneratedLink] = useState();
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></DialogTitle>
        <DialogContent style={{ textAlign: "center", padding: "30px" }}>
          <CheckCircleIcon color="primary" style={{ fontSize: "80px" }} />
          <Typography variant="h2" style={{ fontWeight: "300" }}>
            Your event has been created.
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Paper component="form" className={classes.link}>
              <InputBase
                className={classes.input}
                placeholder="https://6577672.web.app"
                inputProps={{
                  className: classes.labellink,
                  "aria-label": "https://getwork-ejd-mba.in",
                }}
              />
              <CopyToClipboard
                text={generatedLink}
                onCopy={() => alert.success("Link Copied!")}
              >
                <IconButton className={classes.iconButton} aria-label="copy">
                  <FileCopyOutlinedIcon color="primary" />
                </IconButton>
              </CopyToClipboard>
            </Paper>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Typography variant="body2" style={{ color: "#6c757d" }}>
              Share through:
            </Typography>
            <img src={What} height="32px" className={classes.social} />
            <img src={Face} height="32px" className={classes.social} />
            <img src={Insta} height="32px" className={classes.social} />
            <img src={Gmail} height="32px" className={classes.social} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
