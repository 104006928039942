import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../../api/Domain/selector";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";

const useStyles = makeStyles((theme) => ({
  lay: {
    width: "100%",
    "@media only screen and (min-width: 2000px)": {
      height: "600px",
    },
    "@media only screen and (max-width: 2000px)": {
      height: "400px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "310px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "230px",
    },
  },
}));

export default function CollegeGroupDetailTable({
  loading,
  list,
  columns,
  filterDataText,
}) {
  const theme_change = useSelector(themeSelectorFn);
  const classes = useStyles();
  return (
    <div className={classes.lay}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
        }}
        rows={list?.length ? list : []}
        columns={columns}
        disableColumnSelector
        pageSize={5}
        // checkboxSelection
        disableSelectionOnClick
        rowsPerPageOptions={[5]}
        rowCount={list?.length}
        loading={loading}
        components={{
          NoRowsOverlay: () => (
            <>
              <br /> <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  paddingTop: 30,
                }}
              >
                <NoData
                  height="180px"
                  width="180px"
                  alt="404 Error"
                  fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                />

                <span align="center" style={{ fontSize: 14, color: "#6c757d" }}>
                  <b>
                    {" "}
                    {filterDataText ? filterDataText : "No Data Available"}
                  </b>
                </span>
              </div>
            </>
          ),
        }}
      />
    </div>
  );
}
