import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PersonIcon from "@material-ui/icons/Person";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import pSBC from "shade-blend-color";
import { AddJobToTeamMemberAPI } from "../../../api/JobPost/action";
import { userRolesActionsFn } from "../../../api/SignIn/selector";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function AssignNewMember({
  teamMemberList,
  jobId,
  collegeId,
  item,
  allData,
  setTeamMemberListing,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [teamMember, setTeamMember] = useState(null);
  const [teamMemberError, setTeamMemberError] = useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Rohan Singh | A103193131" },
    { key: 1, label: "Rohan Singh | A103193131" },
    { key: 2, label: "Rohan Singh | A103193131" },
    { key: 3, label: "Rohan Singh | A103193131" },
  ]);

  const validateData = () => {
    var is_valid = true;
    var team_error = null;
    if (!teamMember) {
      is_valid = false;
      team_error = "Please Select Team Member";
    }
    setTeamMemberError(team_error);
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      var data = {
        college_id: [collegeId],
        object_id: teamMember?.id,
        job_id_list: [jobId],
      };
      dispatch(
        AddJobToTeamMemberAPI(
          data,
          alert,
          history,
          location,
          setLoading,
          collegeId,
          true,
          item,
          teamMember,
          allData,
          setTeamMemberListing
        )
      );
      handleClose();
    }
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  return (
    <div>
      {userRolesPermission?.some(
        (item) => item.access_control_key === "manage_team"
      ) && (
        <Button
          color="primary"
          size="small"
          startIcon={<PersonIcon />}
          style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
          onClick={handleClickOpen}
        >
          Assign New Team Member
        </Button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">Add Team Member</Typography>
          </div>

          <div component="ul" className={classes.root}>
            {/* {chipData.map(data => {
              let icon;

              if (data.label === "React") {
                icon = <TagFacesIcon />;
              }

              return (
                <li key={data.key}>
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    onDelete={handleDelete}
                    deleteIcon={<CloseIcon />}
                    label={data.label}
                    className={classes.chip}
                  />
                </li>
              );
            })} */}
            <Autocomplete
              id="country-select-demo"
              options={teamMemberList}
              getOptionLabel={(option) => option.name}
              value={teamMember}
              onChange={(e, newValue) => {
                setTeamMember(newValue);
                setTeamMemberError(null);
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Team Member"}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={teamMemberError ? true : false}
                  helperText={<span>{teamMemberError}</span>}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Button
              autoFocus
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? <CustomButtonCircularProgress /> : "Add"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
