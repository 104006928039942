import {
  CREDIT_DATA,
  TRANSACTION_HISTORY_DATA,
  RECHARGE_HISTORY_DATA,
} from "./action";

export const creditData = (state = {}, action) => {
  switch (action.type) {
    case CREDIT_DATA:
      return { ...state, creditData: action.payload };
    case TRANSACTION_HISTORY_DATA:
      return { ...state, transactionHistoryData: action.payload };
    case RECHARGE_HISTORY_DATA:
      return { ...state, rechargeHistoryData: action.payload };
    default:
      return state;
  }
};
