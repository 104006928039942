import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import EligibleActionBar from "../../Input/StudentActionBar/EligibleActionBar";
import CompanyTable from "../../Table/CompanyTable";
import { columns } from "./column";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function EligibleTab({
  loading,
  filters,
  setFilters,
  setClickSearchButton,
  setSelectedRowsData,
  selectedRowsData,
  clickSearchButton,

  filterList,
  setApplyClickedScore,
  filterDataText,
  setFilterDataText,
  collegeId,
  allData,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState("one");
  const [selectedID, setSelectedID] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);

  const studentList = useSelector((state) => state?.StudentData?.eligible);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <NewTab
            value="one"
            label={`Eligible Students (${
              allData?.eligible_student_count ?? 0
            }/${allData?.total_student_count ?? 0})`}
            wrapped
            {...a11yProps("one")}
          />
        </NewTabs>
      </AppBar>

      <EligibleActionBar
        filters={filters}
        setFilters={setFilters}
        setClickSearchButton={setClickSearchButton}
        selectedRowsData={selectedRowsData}
        clickSearchButton={clickSearchButton}
        setSelectedRowsData={setSelectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        filterList={filterList}
        studentList={studentList}
        setApplyClickedScore={setApplyClickedScore}
        collegeId={collegeId}
      />
      <br />
      <TabPanel value={value} index="one">
        <CompanyTable
          loading={loading}
          list={studentList}
          columns={columns}
          setSelectedRowsData={setSelectedRowsData}
          selectedRowData={selectedRowsData}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          filterDataText={filterDataText}
        />{" "}
      </TabPanel>
    </div>
  );
}
