//Imports
import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
//Exports
export const SELECTED_COMPANY_UPLOADER_FIELDS = "SELECTED_COMPANY_UPLOADER_FIELDS";
export const COMPANY_UPLOADER_FIELDS = "COMPANY_UPLOADER_FIELDS";

//Access Control Api Call Action
export const CompanyUploaderFieldsAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "api/uploader_form/company/", {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: COMPANY_UPLOADER_FIELDS,
      payload: res.data.data,
    });
  };
};

//company Form Selected Fields
export const CompanySelectedFieldsAPI = (setLoading, alert, history, location, collegeId) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "api/uploader_form/company/selected_fields/?college_id=" + collegeId, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_COMPANY_UPLOADER_FIELDS,
      payload: res.data.data,
    });
  };
};

// company Field Post Api
export const CompanyFieldsPostAPI = (data, setLoading, alert, history, location, collegeId) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "api/uploader_form/company/selected_fields/?college_id=" + collegeId, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("Updated Successfully");
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};
