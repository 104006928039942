import { Button, Chip, Dialog, TextField, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { BlockCompanyAPI, DeleteCompanyAPI } from "../../../api/Company/action";
import {
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { BlockTeamAPI, DeleteTeamAPI } from "../../../api/TeamManage/action";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import OnlyCollegeList from "../../GroupCollegeList/onlyCollegeList";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  button: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
}));

export default function BlockCompany({
  deleteUser,
  selectedRowsData,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  fromTeams = false,
  collegeId,
  setCallNavbar,
  fromGroup,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [buttonName, setButtonName] = useState("");
  const [buttonNameLower, setButtonNameLower] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [trigger, setTrigger] = useState(false);
  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);
  const [loader, setLoader] = useState(false);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const [companyDetails, setCompanyDetails] = useState({
    college_list: [],
  });

  const [companyDetailsError, setCompanyDetailsError] = useState({
    college_list: null,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseNew = () => {
    setOpen(false);
    setSelectedRowsData([]);
  };

  useEffect(() => {
    if (deleteUser) {
      setButtonName("Delete");
      setButtonNameLower("delete");
      setStatus("DELETE");
    } else {
      if (!fromGroup) {
        if (
          selectedRowsData?.length === 1 &&
          selectedRowsData[0].current_status === "INACTIVE"
        ) {
          setButtonName("Unblock");
          setButtonNameLower("unblock");
          setStatus("ACTIVE");
        } else {
          if (
            selectedRowsData?.length > 0 &&
            selectedRowsData.every((item) => item.current_status === "INACTIVE")
          ) {
            setButtonName("Unblock");
            setButtonNameLower("unblock");
            setStatus("ACTIVE");
          } else {
            setButtonName("Block");
            setButtonNameLower("block");
            setStatus("INACTIVE");
          }
        }
      } else {
        if (companyDetails?.college_list?.some((item) => item?.is_blocked)) {
          setButtonName("Unblock");
          setButtonNameLower("unblock");
          setStatus("ACTIVE");
        } else {
          setButtonName("Block");
          setButtonNameLower("block");
          setStatus("INACTIVE");
        }
      }
    }
  }, [selectedRowsData?.length, open, companyDetails?.college_list]);

  const companyList = useSelector((state) => state?.CompanyData?.details);
  const teamList = useSelector((state) => state?.TeamData?.details);
  const collegeListSelector = useSelector(collegeListSelectorFn);
  const [mainCollegeListSelector, setMainCollegeListSelector] = useState([]);
  useEffect(() => {
    if (
      collegeListSelector?.length > 0 &&
      groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
    ) {
      var arr = [...collegeListSelector];
      var res = arr.filter((item) =>
        selectedRowsData[0].college_id.includes(item?.id)
      );
      res.forEach((item) => {
        item.is_blocked = checkBlockedStatus(item.id);
      });
      setMainCollegeListSelector(res);
    }
  }, [collegeListSelector]);

  const checkBlockedStatus = (college_id) => {
    var found = false;
    found = selectedRowsData[0].blocked_colleges?.find(
      (item) => item?.id === college_id
    );
    return found?.is_blocked;
  };

  const handleSubmit = (status) => {
    var arr = [];
    if (fromTeams && fromGroup) {
      selectedRowsData?.map((item) => arr.push(item.team_id));
    } else selectedRowsData?.map((item) => arr.push(item.id));

    if (fromTeams) {
      setCallNavbar(false);
      if (status === "DELETE") {
        var arr2 = [];
        selectedRowsData?.map((item) => {
          item.college_id.map((item2) => {
            arr2.push(item2);
          });
        });
        var uniq = [...new Set(arr2)];
        var data = {
          college_id: groupViewCheck(
            isEducationGroup,
            isCollegeGroup,
            collegeId
          )
            ? uniq
            : [collegeId],
          object_id: arr,
          reason: deleteReason,
        };
        dispatch(
          DeleteTeamAPI(
            data,
            alert,
            history,
            location,
            teamList,
            handleCloseNew,
            setCallNavbar
          )
        );
      } else {
        if (
          groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) &&
          companyDetails?.college_list?.length === 0
        ) {
          setCompanyDetailsError({
            ...companyDetailsError,
            college_list: "Please Select atleast one College",
          });
          return;
        }
        if (!deleteReason) {
          setDeleteReasonError("Please Enter Reason");
          return;
        }

        var data = {
          college_id: groupViewCheck(
            isEducationGroup,
            isCollegeGroup,
            collegeId
          )
            ? companyDetails?.college_list?.map((item) => item.id)
            : [collegeId],
          object_id: arr,
          status: status,
          reason: deleteReason,
        };
        dispatch(
          BlockTeamAPI(
            data,
            alert,
            history,
            location,
            teamList,
            handleCloseNew,
            setSelectedRowsData
          )
        );
      }
    } else {
      if (!deleteReason) {
        setDeleteReasonError("Please Enter Reason");
        return;
      }
      if (status === "DELETE") {
        var arr2 = [];
        setCallNavbar(false);
        selectedRowsData?.map((item) => {
          item.college_id.map((item2) => {
            arr2.push(item2);
          });
        });
        var uniq = [...new Set(arr2)];
        var data = {
          college_id: groupViewCheck(
            isEducationGroup,
            isCollegeGroup,
            collegeId
          )
            ? uniq
            : [collegeId],
          object_id: arr,
          reason: deleteReason,
        };
        dispatch(
          DeleteCompanyAPI(
            data,
            alert,
            history,
            location,
            companyList,
            handleCloseNew,
            setCallNavbar,
            setLoader
          )
        );
      } else {
        if (
          groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) &&
          companyDetails?.college_list?.length === 0
        ) {
          setCompanyDetailsError({
            ...companyDetailsError,
            college_list: "Please Select atleast one College",
          });
          return;
        }

        var data = {
          college_id: groupViewCheck(
            isEducationGroup,
            isCollegeGroup,
            collegeId
          )
            ? companyDetails?.college_list?.map((item) => item.id)
            : [collegeId],
          object_id: arr,
          status: status,
          reason: deleteReason,
        };
        dispatch(
          BlockCompanyAPI(
            data,
            alert,
            history,
            location,
            companyList,
            handleCloseNew,
            setSelectedRowsData,
            setLoader
          )
        );
      }
    }
    setTrigger(!trigger);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  const [showMore, setShowMore] = React.useState(false);

  const dialogMessage = fromTeams
    ? `Team Member${selectedRowsData?.length > 1 ? "s" : ""}`
    : `compan${selectedRowsData?.length > 1 ? "ies" : "y"}?`;

  const labelPlaceholderText = fromTeams
    ? `Why you want to ${buttonNameLower} ${
        selectedRowsData?.length > 1 ? "these" : "this"
      } Team Member${selectedRowsData?.length > 1 ? "s" : ""}?`
    : `Why you want to ${buttonNameLower} ${
        selectedRowsData?.length > 1 ? "these" : "this"
      }  compan${selectedRowsData?.length > 1 ? "ies" : "y"}?`;

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        onClick={handleClickOpen}
      >
        {buttonName}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "400px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure, <br />
              you want to {buttonNameLower + " "}
              the selected {dialogMessage}
            </Typography>

            <div component="ul" className={classes.root}>
              {!showMore
                ? selectedRowsData.slice(0, 5).map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : selectedRowsData.map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
            </div>
            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
          </div>

          {!deleteUser &&
            groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
              <div style={{ marginTop: 20 }}>
                <OnlyCollegeList
                  groupData={companyDetails}
                  setGroupData={setCompanyDetails}
                  groupDataError={companyDetailsError}
                  setGroupDataError={setCompanyDetailsError}
                  mainCollegeList={mainCollegeListSelector}
                />
              </div>
            )}

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            multiline
            rows="2"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label={labelPlaceholderText}
            value={deleteReason}
            onChange={(e) => {
              setDeleteReason(e.target.value);
              setDeleteReasonError("");
            }}
            style={{ marginBottom: 10 }}
            error={deleteReasonError ? true : false}
            helperText={<span>{deleteReasonError}</span>}
          />
        </div>

        <div className={classes.btngrid}>
          <Button
            variant="outlined"
            style={{ minWidth: "160px" }}
            className={classes.button}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            autoFocus
            className={classes.button}
            style={{ minWidth: "160px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit(status);
            }}
          >
            {loader ? <CustomButtonCircularProgress /> : buttonName}
          </Button>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
