//Imports
import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
//Exports
export const ACCESS_CONTROLS = "ACCESS_CONTROLS";
export const USER_ROLES_ALL = "USER_ROLES_ALL";

//Access Control Api Call Action
export const AccessControlsAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "api/user_roles/access_controls/", {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (!res.data.success) {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: ACCESS_CONTROLS,
      payload: res.data.data,
    });
  };
};

export const UserRolesGetAPI = (
  setLoading,
  alert,
  history,
  location,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    let url = `${BackendBaseApi.SANCHALAN}api/user_roles/all/`;
    if (collegeId) url += `?college_id=${collegeId}`;
    const res = await axios
      .get(url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (!res.data.success) {
      alert.error("Some Error Occurred!!!");
    } else {
      res.data.data.forEach((item, index) => {
        item["tab_id"] = index;
      });

      dispatch({
        type: USER_ROLES_ALL,
        payload: res.data.data,
      });
    }
    setLoading(false);
  };
};

export const UserRolesPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  list,
  collegeId,
  handleClose
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "api/user_roles/all/?college_id=" +
          collegeId,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...list];
      arr.push(res.data.data.data);
      dispatch({
        type: USER_ROLES_ALL,
        payload: arr,
      });
      handleClose();
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const UserRolesPatchAPI = (
  data,
  objId,
  setLoading,
  alert,
  history,
  location,
  userRoleList,
  handleClose,
  access_controls
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .patch(`${BackendBaseApi.SANCHALAN}api/user_roles/all/${objId}/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("User Role Updated Successfully");
      var arr = [...userRoleList];
      arr.forEach((i) => {
        if (i.id === objId) {
          i["user_role_name"] = data.user_role_name;
          i["access_controls_list"] = access_controls;
        }
      });
      handleClose();
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: USER_ROLES_ALL,
      payload: arr,
    });
  };
};

export const changeState = (dispatcher, data) => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
  };
};
