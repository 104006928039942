import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CopyToClipboard from "react-copy-to-clipboard";
import RechargeDialog from "../../DialogSection/Recharge/RechargeDialog";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    height: 160,
    position: "relative",
  },
  root: {
    width: "100%",
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
  blur: {
    background: "rgba(255, 255, 255, 0.5)",
    backdropFilter: "blur(3px)",
    borderRadius: "8px",
    position: "absolute",
    top: "50%",
    width: "100%",
    height: "100%",
    left: "50%",
    textAlign: "center",
    transform: "translate(-50%,-50%)",
  },
}));

export default function HrContactDetails({
  details,
  setHrDetails,
  collegeId,
  companyId,
  unlock,
  setUnlock,
  allJobs,
  setAllJobs,
}) {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const handleUpdateJobList = () => {
    const nums = [...allJobs];
    nums.forEach((item, index) => {
      if (item.company.company_id == companyId) {
        item.is_amount_paid = true;
      }
    });
    setAllJobs([...nums]);
  };
  // const unLOckHrDetails = () => {
  //   if (collegeId && companyId) {
  //     setLoading(true);
  //     axios
  //       .post(
  //         `${BackendBaseApi.PRAVESH}api/saas/college/unlok_company_hr_details/`,
  //         { company: companyId, college: collegeId },
  //         { withCredentials: true }
  //       )
  //       .then((res) => {
  //         setLoading(false);
  //         if (res?.data?.success) {
  //           alert.success(res?.data?.data?.message);
  //           setUnlock(true);
  //           handleUpdateJobList();
  //         } else {
  //           alert.error(res?.data?.error);
  //         }
  //       })
  //       .catch((err) => {
  //         alert.error("something went wrong");
  //         setLoading(false);
  //       });
  //   }
  // };

  const jobRoleData = [
    {
      id: 1,
      icons: <PersonIcon style={{ color: "#fff", fontSize: 16 }} />,
      title: details?.hr_contact_details?.name
        ? `${details?.hr_contact_details?.name}`
        : "NA",
    },
    {
      id: 2,
      icons: <PhoneIcon style={{ color: "#fff", fontSize: 16 }} />,
      title: details?.hr_contact_details?.phone
        ? `${details?.hr_contact_details?.phone}`
        : "NA",
    },
    {
      id: 3,
      icons: <MailIcon style={{ color: "#fff", fontSize: 16 }} />,
      title: details?.hr_contact_details?.email
        ? `${details?.hr_contact_details?.email}`
        : "NA",
    },
  ];

  const copyText = () => {
    // Copy text to clipboard
    navigator.clipboard
      .writeText(details?.hr_contact_details?.name)
      .then(() => {
        // Alert the user that the text has been copied
        alert("Copied the text: " + details?.hr_contact_details?.nam);
      })
      .catch((error) => {
        console.error("Error copying text:", error);
      });
  };
  return (
    <Box className={classes.box}>
      <div className={classes.root}>
        <Typography
          variant="h4"
          style={{
            fontWeight: "600",
            color: "#fff",
            textAlign: "center",
            paddingBottom: 5,
            verticalAlign: "center",
            padding: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginRight: 5,
            }}
          >
            <PermContactCalendarIcon style={{ fontSize: 18, color: "#fff" }} />{" "}
          </span>{" "}
          HR Contact details
        </Typography>
        <Divider />
        <div style={{ padding: 10, paddingTop: 0 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "10px",
            }}
          >
            {jobRoleData.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 10,
                }}
                key={index}
              >
                {item.icons}{" "}
                <Typography
                  noWrap
                  style={{ fontSize: 14, marginLeft: 5, color: "#fff" }}
                >
                  {item.title}
                </Typography>
                {item.id == 1 ? (
                  ""
                ) : (
                  <IconButton size="small">
                    <CopyToClipboard
                      text={item.title}
                      onCopy={() => {
                        alert.success("Copied!");
                      }}
                    >
                      <FileCopyIcon style={{ fontSize: 14, color: "#fff" }} />
                    </CopyToClipboard>
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {details?.is_hr_details_locked ? (
        <div className={classes.blur}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <IconButton style={{ backgroundColor: "#fff", marginBottom: 10 }}>
              <LockIcon color="primary" />
            </IconButton>
            {/* <Button
              color="primary"
              variant="text"
              style={{ backgroundColor: "#fff", height: 45 }}
              onClick={() => unLOckHrDetails()}
            > */}
            <RechargeDialog
              collegeId={collegeId}
              companyId={companyId}
              setUnlock={setUnlock}
              allJobs={allJobs}
              setAllJobs={setAllJobs}
              setHrDetails={setHrDetails}
            />
            {/* {loading ? <CircularProgress size={14} /> : "Unlock HR Contact"} */}
            {/* // </Button> */}
          </div>
        </div>
      ) : (
        ""
      )}
    </Box>
  );
}
