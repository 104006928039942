import axios from 'axios';
import React, { useState, useEffect, } from 'react'
import { useAlert } from 'react-alert';

const useAxiosApi = ({ url,
    method = 'get',
    body = null,
    formData = null,
    reqConfigs = JSON.stringify({
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true
    }),
    callBackFn = () => { },
    errorCallback = () => { },
    setResponse = () => { },
    source = null,
    loginFlow = false, mountingCallFlag = true }) => {

    const alert = useAlert();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState(null);


    const apiCall = async () => {

        if (!mountingCallFlag) {
            return
        }

        setLoading(true)
        try {

            const res = await axios?.[method](url,
                formData ? formData :
                    body ? JSON?.parse(body) :
                        source ? { ...JSON?.parse(reqConfigs), cancelToken: source?.token } :
                            JSON?.parse(reqConfigs),
                formData
                    ? source
                        ? { ...JSON?.parse(reqConfigs), cancelToken: source?.token }
                        : JSON?.parse(reqConfigs)
                    : body
                        ? source
                            ? { ...JSON?.parse(reqConfigs), cancelToken: source?.token }
                            : JSON?.parse(reqConfigs)
                        : null
            );

            if (method === "get") {
                if (res?.data?.success) {

                    const data = res?.data?.data;

                    if (Object.keys(res?.data?.data).includes("next")) {

                        setPagination({
                            end: data?.next ? false : true,
                            next: data?.next ? true : false,
                            totalDataCount: data?.count,
                        });
                        setError(null);
                        setData(data?.results);
                        callBackFn(data?.results);

                    } else {

                        setError(null);
                        setData(data);
                        callBackFn(data);
                    }

                } else {
                    setError(res?.data?.error);
                    setData(null);
                }
            } else {
                if (res?.data?.success) {
                    if (alert) alert.success(res?.data?.data?.message);
                    setResponse(res?.data);
                    callBackFn(res?.data);

                } else {
                    if (!loginFlow && res?.data?.data?.code === 1) {

                        errorCallback();

                    } else {
                        errorCallback(res?.data);
                        res?.data?.data?.rd_code !== 71 && alert.error(res?.data?.error);
                        setError(res?.data?.error);
                    }
                }
            }
        } catch (error) {

            console.log(error);
            // alert?.error("Something went wrong. Please try again!");

        } finally {

            setLoading(false)
        }
    }



    useEffect(() => {

        const getData = setTimeout(() => {

            if (url) {
                apiCall()
            }
        }, 500);

        return () => clearTimeout(getData)
    }, [url])

    return { data, loading, error, apiCall, pagination, setData, setLoading }

}

export default useAxiosApi