import {
  Button,
  Dialog,
  Grid,
  IconButton,
  InputBase,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

import FilterScore from "../FilterScore";

import OffCampusStudentDialogForm from "../../DialogSection/DialogItem/OffCampusStudentDialogForm";

import OffCampusDataDelete from "../../DialogSection/DeleteOffcampusDialog/OffCampusDataDelete";
import ApproveOffCampusData from "../../DialogSection/ApproveOffCampusDialog/ApproveOffCampusData";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  button2: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    padding: "5px",
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
    // color: "#6c757d",
    "& ::-webkit-input-placeholder": {
      color: "#000 !important",
    },
  },

  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function OffCampusActionBar({
  approveRejectOffCampus,
  filters,
  setFilters,
  setClickSearchButton,
  selectedRowsData,
  clickSearchButton,
  setSelectedRowsData,
  setSelectedID,
  filterList,
  studentList,
  setApplyClickedScore,
  collegeId,
  userRolesPermission,
  studentListOffCampus,
  fetchStudentList,
  dataLoading,
  refreshData,
  setRefreshData,
  deleteOffCampusStudentData,
  setDeleteReason,
  deleteReason,
  setDeleteReasonError,
  deleteReasonError,
  deleteResponse,
}) {
  const alert = useAlert();
  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const [searchQuery, setSearchQuery] = useState("");

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search By Name"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setFilters({
                      ...filters,
                      search: searchQuery,
                    });
                    setClickSearchButton(true);
                  }
                }}
              />
              {filters?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      search: "",
                    });
                    setSearchQuery("");
                    setClickSearchButton(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setFilters({
                    ...filters,
                    search: searchQuery,
                  });
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {filterList &&
            Object.keys(filterList)?.length > 0 &&
            Object.keys(filterList).map(
              (item) =>
                filterList[item].type === "autocomplete" && (
                  <Grid item xs={2}>
                    <Autocomplete
                      id="country-select-demo"
                      options={filterList[item].data}
                      getOptionLabel={(option) => String(option.name)}
                      value={filters[filterList[item].key]}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setFilters({
                          ...filters,
                          [filterList[item].key]: newValue,
                        });
                      }}
                      style={{ background: "#fff", borderRadius: 8 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={filterList[item].heading}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                )
            )}
          {/*    */}

          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_students"
          ) &&
            selectedRowsData?.length > 0 &&
            filters?.current_tab?.params !== "Pending" && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {selectedRowsData?.length <= 1 && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ borderRadius: "8px", marginRight: "10px" }}
                      onClick={() => {
                        if (!licenceData?.display) {
                          fetchStudentList();
                          handleClickOpen();
                        } else
                          alert.error(
                            "Student license reaches its limit so you cannot add Students. Kindly connect with your Key Account Manager"
                          );
                      }}
                    >
                      Edit
                    </Button>

                    <Dialog
                      PaperProps={{ classes: { root: classes.root1 } }}
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      open={open}
                      onClose={handleClickClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <div style={{ padding: "40px" }}>
                        <OffCampusStudentDialogForm
                          handleClose={handleClickClose}
                          studentList={studentList}
                          collegeId={collegeId}
                          studentListOffCampus={studentListOffCampus}
                          dataLoading={dataLoading}
                          refreshData={refreshData}
                          setRefreshData={setRefreshData}
                          editStudentOffCampus="editStudent"
                          selectedRowsData={selectedRowsData}
                          setSelectedRowsData={setSelectedRowsData}
                        />
                      </div>
                    </Dialog>
                  </>
                )}

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem>
                    <ListItemText primary="Active" />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <ListItemText primary="Block" />
                  </StyledMenuItem>
                </StyledMenu>

                {/* delete button component */}
                <OffCampusDataDelete
                  deleteUser={true}
                  selectedRowsData={selectedRowsData}
                  setSelectedRowsData={setSelectedRowsData}
                  setSelectedID={setSelectedID}
                  deleteOffCampusStudentData={deleteOffCampusStudentData}
                  setDeleteReason={setDeleteReason}
                  deleteReason={deleteReason}
                  setDeleteReasonError={setDeleteReasonError}
                  deleteReasonError={deleteReasonError}
                  deleteResponse={deleteResponse}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  dataLoading={dataLoading}
                />
              </Grid>
            )}

          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_students"
          ) &&
            selectedRowsData?.length > 0 &&
            filters?.current_tab?.params === "Pending" && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {!licenceData?.display ? (
                  /* approve */
                  <ApproveOffCampusData
                    deleteUser={true}
                    selectedRowsData={selectedRowsData}
                    setSelectedRowsData={setSelectedRowsData}
                    setSelectedID={setSelectedID}
                    collegeId={collegeId}
                    approveRejectOffCampus={approveRejectOffCampus}
                    dataLoading={dataLoading}
                    deleteResponse={deleteResponse}
                  />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button2}
                    onClick={() => {
                      alert.error(
                        "Student license reaches its limit so you cannot accept these Students. Kindly connect with your Key Account Manager"
                      );
                    }}
                  >
                    Accept Students
                  </Button>
                )}

                {/* reject button  */}
                <ApproveOffCampusData
                  deleteUser={false}
                  selectedRowsData={selectedRowsData}
                  setSelectedRowsData={setSelectedRowsData}
                  setSelectedID={setSelectedID}
                  collegeId={collegeId}
                  approveRejectOffCampus={approveRejectOffCampus}
                  dataLoading={dataLoading}
                  deleteResponse={deleteResponse}
                />
              </Grid>
            )}
        </Grid>
      </div>
    </>
  );
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
