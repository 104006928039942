import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F6F6F6",
    color: "#000000",
  },
  body: {
    fontSize: 14,
    border: "none",
    borderRight: "1px solid #6C757D",
    // "&:last-of-type": {
    //   borderRight: "none",
    // },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);



const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ReportTableSecond({graphData,from}) {
  const classes = useStyles();
    const data = graphData?.graph_data;
 
  return (
    <TableContainer
      style={{ border: "1px solid #6C757D", borderRadius: "4px" }}
    >
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "1px solid #6C757D",
                padding: "0px",
                fontWeight: "600",
              }}
              align="center"
              rowSpan={2}
            >
              S.No
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderRight: "1px solid #6C757D",
                padding: "0px",
                fontWeight: "600",
              }}
              rowSpan={2}
            >
              Total Applicant
            </StyledTableCell>

            <StyledTableCell
              align="center"
              colSpan={2}
              style={{ padding: "0px", fontWeight: "600" }}
            >
              {from === "studentPlaced" ? "Gender(%)" : "Gender"}
            </StyledTableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: "#D8D9DB" }}>
            <StyledTableCell
              style={{
                backgroundColor: "#D8D9DB",
                padding: "0px",
                borderRight: "1px solid #6C757D",
                fontWeight: "600",
              }}
              align="center"
            >
              Male
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: "#D8D9DB",
                padding: "0px",
                fontWeight: "600",
              }}
              align="center"
            >
              Female
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
           { data?.length && (<StyledTableRow >
              <StyledTableCell
                component="th"
                scope="row"
                align="center"
                style={{ width: "100px" }}
              >
                1
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="center">
              {data[0]?.value + data[1]?.value}
              </StyledTableCell>

              <StyledTableCell
                align="center"
                style={{
                  width: "200px",
                  borderRight: "none",
                  borderRight: "1px solid #6C757D",
                }}
              >
                {data[0]?.value}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ borderRight: "unset", width: "200px" }}
              >
                {data[1]?.value}
              </StyledTableCell>
            </StyledTableRow>)
}
      
        </TableBody>
      </Table>
    </TableContainer>
  );
}
