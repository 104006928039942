import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SaveLanguages from "./SaveLanguages";
import AddLanguageDialog from "./AddLanguageDialog";

import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  newBtnStyle: {
    maxWidth: "200px",
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));

export default function Languages({
  languageData,
  postLanguages,
  langDataLoading,
  approvalRequestCounts,
  redirectionData,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [tempLangData, setTempLangData] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (languageData) setTempLangData(languageData);
  }, [languageData]);

  const handleDelete = (index) => {
    setTempLangData((tempLangData) =>
      tempLangData.filter((_, langIndex) => langIndex !== index)
    );
  };

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Languages</Typography>
        <br />
        <Grid
          item
          xs={12}
          style={
            approvalRequestCounts?.LANG
              ? { pointerEvents: "none", opacity: 0.4 }
              : {}
          }
        >
          {tempLangData &&
            tempLangData?.map((langDetail, index) => {
              return (
                <SaveLanguages
                  langDetail={langDetail}
                  langIndex={index}
                  handleDelete={handleDelete}
                />
              );
            })}
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
            color="primary"
            disabled={approvalRequestCounts?.LANG && true}
            onClick={handleClickOpen}
            style={{ display: "flex", borderRadius: "50px" }}
          >
            Add Languages
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.newBtnStyle}
            onClick={
              approvalRequestCounts?.LANG
                ? () => {
                    history.push({
                      pathname: `/student-profile-request/${redirectionData?.name.replace(
                        / /g,
                        "_"
                      )}-${redirectionData?.userId}`,
                    });
                  }
                : () => {
                    postLanguages(tempLangData);
                  }
            }
          >
            {langDataLoading ? (
              <CustomButtonCircularProgress />
            ) : approvalRequestCounts?.LANG ? (
              `Approve Request (${approvalRequestCounts?.LANG})`
            ) : (
              "Save"
            )}
          </Button>
        </div>

        <AddLanguageDialog
          handleClose={handleClose}
          setTempLangData={setTempLangData}
          tempLangData={tempLangData}
          open={open}
        />
      </div>
    </>
  );
}
