import { Box, Hidden, Typography } from "@material-ui/core";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  statusDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
    padding: "10px",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    // position: "absolute",
    top: "0px",
    right: "20px",
  },
}));

const UrgentJob = ({ full = true }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.statusDiv}>
        <FlashOnIcon color="primary" style={{ fontSize: "18px" }} />
        {full && (
          <Hidden only={["xs"]}>
            <Typography
              color="primary"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              Urgent
            </Typography>
          </Hidden>
        )}
      </Box>
    </>
  );
};

export default UrgentJob;
