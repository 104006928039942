import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState,useRef } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector, } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { niyuktiAxiosGetReq } from "../../api/BaseApi/apiBase";

import {
  collegeDetailSelectorFn,
  collegeIdFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";

import JobApplicationsInfo from "../../components/Cards/JobApplicationsInfo";
import JobDetailsCard from "../../components/Cards/JobDetailsCard";
import AssignedPlacementCycle from "../../components/Cards/JobDetailsCard/AssignedPlacementCycle";
import AssignedTeamMember from "../../components/Cards/TeamMember/AssignedTeamMember";
import CustomCircularProgress from "../../components/CircularProgressBar";
import RejectJobDialog from "../../components/DialogSection/RejectJobDialog";

const useStyles = makeStyles((_) => ({
  fixedPosition: {
    position: "fixed",
    left: "71%",
    maxHeight: 630,
    overflowY: "scroll",
    width: "28%",
  },
  
   buttonSection: {
        width: "40%",
        display: 'flex',
        justifyContent: "flex-start",
        marginLeft:"1rem",
        // marginRight: '26px',

    },
  "@media only screen and (min-width: 1700px)": {
    fixedPosition: {
      position: "fixed",
      left: "71%",
      maxHeight: 900,
      overflowY: "scroll",
      width: "28%",
    },
  },
  // "@media only screen and (min-width: 1500px)": {
  //   fixedPosition: {
  //     position: "fixed",
  //     left: "71%",
  //     maxHeight: 750,
  //     overflowY: "scroll",
  //     width: "28%",
  //   },
  // },
}));

export default function GetworkJob() {


  const params = useParams();
  const alert = useAlert();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [jobId, setJobId] = useState(null);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const userId = collegeDetails?.user_id;
  const [jobData, setJobData] = useState();
  const [undo,setUndo] = useState(false); 
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  
  const isInbound = useRef(false);

  useEffect(() => {

    if (location.search) {

      let params = new URLSearchParams(location.search);
      isInbound.current = params.get("inbound_job");
    }
  }, [location]);


  const initialJobStatus = {

      company_website:'',

  };
  const [jobStatus,setJobStatus] = useState(initialJobStatus);

  useEffect(() => {
   
    if (params.job_id) {    
      setJobId(params.job_id);
    }
  }, [params]);

  const getNiyuktiJobDetails = async() => {

     const prepareJobDetailData = (data) => {

        let modifiedData = {...data};

          modifiedData ={
            ...data,
            company_name:data?.company?.company_name,
            application_end_date:data?.apply_end_date,
            job_type_name:data?.employment_type_name,
            joining_date:data?.job_duration_start,
            job_type_id:data?.job_type,
            skills:data?.eligibility_criteria?.skills,
            grad_min_score:data?.eligibility_criteria?.percentage,
            grad_scoring:"Percentage",
            eligible_batches:data?.eligibility_criteria?.graduation_years,
            rounds:data?.job_rounds,
          }

          return modifiedData;
      }
      setLoading(true);

      // degree spec new sends data as required for the page to represent.
      const endPoint =  `job/college_jobs/job_details/?college_id=${collegeId}&job_id=${jobId}`;
      
      try {
        const res = await niyuktiAxiosGetReq(endPoint);
        if(res?.data?.success){

          const data = res?.data?.data;
          // job rejection status is set here as per changes done on job recommendation page.

          setUndo(data?.is_rejected);  
          setJobStatus((prevData) => ({...prevData,company_website:data?.company?.company_website}));
          const modifiedData = prepareJobDetailData(data);

          setJobData(modifiedData);

        }
        setLoading(false);

      } catch (error) {

        setLoading(false);
        console.log(error);
      }
  };

  useEffect(() => {

    if(jobId && collegeId){
      getNiyuktiJobDetails();
    }
  },[jobId]);


  const handleJobRejection = async(action,deleteReason) => {

    let tempUndo ;
    if(!undo){
      tempUndo=''
    }else{
      tempUndo=`&undo=1`;
    }

    let endPoint =  `job/college_jobs/reject_recommended/?college_id=${collegeId}&job_id=${jobId}${tempUndo}`;

    if(isInbound?.current){

      endPoint = `job/college_saas/inbound_jobs/reject_job/?job_id=${jobId}${undo ? '&undo=1' : ''}${deleteReason ? "&reason=" + deleteReason : ''}`

    }

    setUndo(!undo);

    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      // console.log("handle job rejection",res);
      const data = res?.data?.data;
    
      if(res?.data?.success){       
        if(undo){
          alert?.success(data?.message);
        }else{
          alert?.error(data?.message);
        }
      }else{
        alert?.error(res?.data?.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
 

  const getworkjobText = "show";

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={isInbound.current ? "/inbound-jobs" :"/jobs-recommended" } style={{ textDecoration: "none" }}>
          <Typography color="primary"         
          >GetWork {isInbound.current ? "Inbound" : "Fullfilled"} Job</Typography>
        </Link>

        <Link style={{ textDecoration: "none" }}>
          <Typography color="secondary" variant="body2">
            {jobData?.job_title}
          </Typography>
        </Link>
      </Breadcrumbs>
      <br />
      <Grid container spacing={3}>

        {loading ? (

            <Grid md={12}>
              <CustomCircularProgress />

            </Grid>
          ) : (
            <>
        <Grid item xs={12} sm={12}>
          
            <>
              <JobDetailsCard
                data={jobData}
                fromJobDetail={true}
                jobStatus={jobStatus}
                getworkjobText={getworkjobText}
                fromRecommendedJobs={true}
                from={isInbound.current === "true" ? "Inbound_Jobs" : " "}
              />
              
            </>
       
        </Grid>
        <Grid className={classes.buttonSection} >
          
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={undo || jobData?.is_accepted}
                                style={{
                                    borderRadius: "8px",
                                    boxShadow: "none",
                                    height: "40px",
                                    width:'40%',
                                }}
                                onClick={() => {

                                if (licenceData && licenceData?.display) {

                                      alert.error(licenceData?.display_message);
                                      return
                                  }

                                if(jobData?.id && isInbound.current) {
                                   history.push(`/post-recommended-job/recommended-${jobData?.id}?inbound_job=true`)

                                 }else if(jobData?.id){

                                   history.push(`/post-recommended-job/recommended-${jobData?.id}`)
                                 }
                            }}
                            >
                              {jobData?.is_accepted ? "Already Accepted" :"Accept & Post Job"}  
                            </Button>
                            {/* <button className={classes.buttonAccept}
                                onClick={() => {
                                    history.push(`/post-recommended-job/recommended-${jobDetails?.job_id}`)
                                }}
                            > Accept & Post</button> */}
                            { 
                            isInbound.current && !undo ? 
                              <section style={{marginLeft:"1rem"}}>

                                <RejectJobDialog
                                selectedRowsData={[]}
                                // setSelectedRowsData={setSelectedRowsData}
                                selectedID={jobId}
                                // setSelectedID={setSelectedID}
                                jobList={[]}
                                collegeId={collegeId}
                                from={"INBOUND_JOBS"}
                                handleRejectJob={handleJobRejection}
                              /> 
                              </section>
                              :

                               <Button
                                variant={"outlined"}
                                color="primary"
                                onClick={() => { 

                                 if (licenceData && licenceData?.display) {

                                      alert.error(licenceData?.display_message);
                                      return
                                   }                     
                                    handleJobRejection();
                                }}
                                style={
                                    {
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        height: "40px",
                                        // display: "flex",
                                        marginRight: 20,
                                        marginLeft:"1rem",
                                        minWidth: 140
                                    }
                                }
                            >
                             { undo ? "Rejected (Add again)" :"Ignore"}   
                            </Button>  
                            }                                 
            </Grid>
                    </>

           )}
      </Grid>     
    </>
  );
}
