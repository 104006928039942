import {
  COMPANY_DATA,
  JOBPROFILE_DATA,
  JOBROUND_DATA,
  ALL_ASSIGNMENT,
  ASSIGNMENT_DETAILS,
  DELETE_ASSIGNMENT,
  INTERVIEW_STUDENTS,
  INTERVIEW_ALL,
  INTERVIEW_LIST,
  ASSIGNMENT_FILTER,
  ALL_ASSIGNMENT1,
} from "./action";

const initialState= {
  interviewList: []
}
export const assignment = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_DATA:
      return { ...state, companyData: action.payload };
    case JOBPROFILE_DATA:
      return { ...state, jobProfileData: action.payload };
    case JOBROUND_DATA:
      return { ...state, jobRound: action.payload };
    case ALL_ASSIGNMENT:
      return { ...state, allassignment: action.payload };
    case ALL_ASSIGNMENT1:
      return { ...state, allassignment1: action.payload };
    case ASSIGNMENT_FILTER:
      return { ...state, assignmentfilter: action.payload };
    case ASSIGNMENT_DETAILS:
      return { ...state, assignmentdetails: action.payload };
    case DELETE_ASSIGNMENT:
      return { ...state, deleteassignment: action.payload };
    case INTERVIEW_STUDENTS:
      return { ...state, allstudents: action.payload };
    case INTERVIEW_ALL:
      return { ...state, allinterview: action.payload };
    case INTERVIEW_LIST:
      return { ...state, interviewList: action.payload };
    default:
      return state;
  }
};
