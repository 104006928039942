import { LinearProgress, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import pSBC from "shade-blend-color";
import AssignmentDownloadTable from "../../Table/AssignmentTable/AssignmentDownloadTable";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "48px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  move: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "48px",
    marginLeft: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
}));

export default function AssignmentTable({ data }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const [chipData, setChipData] = React.useState([
  //   { key: 0, label: "Rohan Singh | A103193131" },
  //   { key: 1, label: "Rohan Singh | A103193131" },
  //   { key: 2, label: "Rohan Singh | A103193131" },
  //   { key: 3, label: "Rohan Singh | A103193131" },
  // ]);
  const [selectedAssignments, setSelectedAssignments] = useState([]);

  return (
    <div>
      <Button color="primary" size="small" onClick={handleClickOpen}>
        View
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "47vw" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">
              {data?.name} Submitted Assignment
            </Typography>
          </div>
          <AssignmentDownloadTable
            data={data?.assignment_list}
            setSelectedAssignments={setSelectedAssignments}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-20px",
            }}
          >
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Back
            </Button>

            {/* <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => {
                if (selectedAssignments?.length === 0) {
                  data?.assignment_list?.forEach((element) => {
                    window.open(element.file);
                  });
                } else {
                  selectedAssignments?.forEach((element) => {
                    window.open(element.file);
                  });
                }
              }}
            >
              Download ({selectedAssignments?.length === 0 ? data?.assignment_list?.length : selectedAssignments?.length})
            </Button> */}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
