export const headerSampleFile = [
  { label: "name", key: "name" },
  { label: "enrollment_no", key: "enrollment_no" },

  { label: "email", key: "email" },

  { label: "phone", key: "phone" },

  { label: "degree", key: "degree" },

  { label: "course", key: "course" },

  { label: "start_date", key: "start_date" },
  { label: "end_date", key: "end_date" },
  { label: "cgpa", key: "cgpa" },
];

export const dataRow = [
  {
    name: "",
    enrollment_no: "",
    email: "",
    phone: "",
    batch: "",
    degree: "",
    course: "",
    start_date: "",
    end_date: "",
    cgpa: "",
  },
];
