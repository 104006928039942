import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CampusplacementGet,
  CompanyApplicant,
  CompanyApplicant2,
  CompanyApplicant3,
  CompanyApplicant4,
  CompanyApplicant5,
  CompanyApplicant6,
  CompanyApplicant7,
  CompanyApplicant8,
  overviewGet,
} from "../../../api/Reports/action";
import { collegeDetailSelectorFn } from "../../../api/SignIn/selector";
import ReportChart from "../../Cards/Report/ReportChart";
import Cal from "./Cal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function ReportTab({ placementSession }) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const overview = useSelector((state) => state.report.overviewdata);
  const placementdata = useSelector((state) => state.report.placementdata);
  const industry1 = useSelector((state) => state.report.industry1);
  const industry2 = useSelector((state) => state.report.industry2);
  const industry3 = useSelector((state) => state.report.industry3);
  const industry4 = useSelector((state) => state.report.industry4);
  const industry5 = useSelector((state) => state.report.industry5);
  const industry6 = useSelector((state) => state.report.industry6);
  const industry7 = useSelector((state) => state.report.industry7);
  const industry8 = useSelector((state) => state.report.industry8);
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkPlacementSesion = () => {
    if (placementSession && placementSession.id !== "All")
      return `&placement_id=${placementSession.id}`;
    else return ``;
  };

  useEffect(() => {
    if (collegeDetail?.college_id) {
      dispatch(
        overviewGet(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CampusplacementGet(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CompanyApplicant(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CompanyApplicant2(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CompanyApplicant3(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CompanyApplicant4(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CompanyApplicant5(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CompanyApplicant6(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CompanyApplicant7(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
          // placementSession?.id === "All" ? null : placementSession?.id
        )
      );
      dispatch(
        CompanyApplicant8(
          alert,
          history,
          location,
          collegeDetail?.college_id,
          date.startDate,
          date.endDate,
          checkPlacementSesion
        )
      );
    }
  }, [collegeDetail, date.startDate, date.endDate, placementSession]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",

          //  borderBottom: ".5px solid #dddddd",
        }}
      >
        {" "}
        <NewTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          variant="fullWidth"
        >
          {/* <div
            style={{
              padding: "10px",
              //   marginLeft: "200px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div> */}
          <NewTab
            value="one"
            // label="Last 7 days"
            label=""
            wrapped
            {...a11yProps("one")}
          />
          {/* <NewTab value="two" wrapped label="Monthly" {...a11yProps("two")} /> */}
          {/* <NewTab
            value="three"
            wrapped
            label="Yearly"
            {...a11yProps("three")}
          /> */}
          {/* </div>
          </div> */}
          {/* <CustomDateRange /> */}

          {/* <div
            style={{
              marginLeft: "450px",
              display: "flex",
            }}
          >
            <ReportCalendar />
            <ReportCalendar />
          </div> */}
          <Cal
            startDate={(event) =>
              setDate({
                ...date,
                startDate: moment(event.target.value).format(),
              })
            }
            endDate={(event) =>
              setDate({
                ...date,
                endDate: moment(event.target.value).format(),
              })
            }
            initialDate={date?.startDate}
            lastDate={date?.endDate}
          />
        </NewTabs>
      </AppBar>

      <br />
      <TabPanel value={value} index="one">
        <ReportChart
          overview={overview}
          placement={placementdata}
          industry1={industry1}
          industry2={industry2}
          industry3={industry3}
          industry4={industry4}
          industry5={industry5}
          industry6={industry6}
          industry7={industry7}
          industry8={industry8}
        />
      </TabPanel>
      {/* <TabPanel value={value} index="two">
        {" "}
        <ReportChart />
      </TabPanel>
      <TabPanel value={value} index="three">
        <YearlyReportChart />
      </TabPanel> */}
    </div>
  );
}
