import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    ///  padding: "2px 4px",
    display: "flex",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "8px",
    alignItems: "center",

    boxShadow: "none",
    border: ".2px solid #b0b7c3",
    alignItems: "center",

    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Cal(props) {
  const classes = useStyles();
  var date = new Date();

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        id="date"
        type="date"
        inputProps={{
          style: { fontSize: "14px" },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        value={
          props?.initialDate
          // ? props?.initialDate
          // : moment(new Date().setDate(new Date().getDate() - 7)).format(
          //     "yyyy-MM-DD"
          //   )
        }
        onChange={props.startDate}
      />

      <Divider className={classes.divider} orientation="vertical" />
      <InputBase
        id="date"
        type="date"
        inputProps={{
          style: { fontSize: "14px" },
        }}
        onChange={props.endDate}
        value={
          props?.lastDate //? props?.lastDate : new Date().toJSON().slice(0, 10)
        }
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Divider className={classes.divider} orientation="vertical" />

      <IconButton
        onClick={props?.handleDateFilter}
        color="primary"
        style={{ padding: "0", marginLeft: "10px" }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

// import { InputBase, Divider, Paper, useTheme, IconButton } from "@mui/material";
// import moment from "moment";
// import React, { useEffect } from "react";
// import SearchIcon from "@mui/icons-material/Search";

// export default function Cal(props) {
//   const theme = useTheme();

//   const root = {
//     display: "flex",
//     paddingLeft: "20px",
//     paddingRight: "20px",
//     borderRadius: "8px",
//     alignItems: "center",
//     boxShadow: "none",
//     border: ".2px solid #b0b7c3",
//     alignItems: "center",
//     height: "45px",
//   };
//   const input = {
//     marginLeft: theme.spacing(1),
//     flex: 1,
//   };
//   const iconButton = {
//     padding: 10,
//   };
//   const divider = {
//     height: 28,
//     margin: 4,
//   };
//   var date = new Date();

//   useEffect(() => {
//     console.log(props.lastDate);
//   }, [props.lastDate]);
//   return (
//     <Paper component="form" sx={root}>
//       <InputBase
//         id="date"
//         type="date"
//         inputProps={{
//           style: { fontSize: "14px" },
//         }}
//         InputLabelProps={{
//           shrink: true,
//         }}
//         value={
//           props?.initialDate
//           // ? props?.initialDate
//           // : moment(new Date().setDate(new Date().getDate() - 7)).format(
//           //     "yyyy-MM-DD"
//           //   )
//         }
//         onChange={props.startDate}
//       />

//       <Divider sx={divider} orientation="vertical" />
//       <InputBase
//         id="date"
//         type="date"
//         inputProps={{
//           style: { fontSize: "14px" },
//         }}
//         onChange={props.endDate}
//         value={
//           props?.lastDate //? props?.lastDate : new Date().toJSON().slice(0, 10)
//         }
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//       <Divider sx={divider} orientation="vertical" />
//       <IconButton onClick={props?.handleDateFilter} color="primary">
//         <SearchIcon />
//       </IconButton>
//     </Paper>
//   );
// }
