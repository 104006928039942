import { Paper, Grid, Typography } from "@material-ui/core";
import React from "react";
import BarChart from "../BarChart";
import Company from "../BarChart/Company";
import DegreeBarChart from "../BarChart/DegreeBarChart";
import HiredStudents from "../BarChart/HiredStudents";
import Industry from "../BarChart/Industry";
import IndustryType from "../BarChart/IndustryType";
import JobOpenings from "../BarChart/JobOpenings";
import SecondBar from "../BarChart/SecondBar";
import SingalIndustry from "../BarChart/SingalIndustry";
import VisitCompanyBar from "../BarChart/VisitCompanyBar";
import PieChart from "../PieChart";
import HiredPie from "../PieChart/HiredPie";
import { ResizableBox } from "react-resizable";
import ReportCards from "../ReportCards";
import Content from "./Content";
import SecondContent from "./SecondContent";
import FourContent from "./FourContent ";

function ReportChart({
  overview,
  placement,
  industry1,
  industry2,
  industry3,
  industry4,
  industry6,
  industry7,
  industry5,
  industry8,
}) {
  return (
    <>
      <Paper
        style={{ padding: "30px", borderRadius: "8px", boxShadow: "none" }}
      >
        <ReportCards data={overview} />
        {/* <BarChart /> */}
        <Grid container spacing={4} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Content placement={placement} industry8={industry8} />
          </Grid>
          {/* <Grid item xs={7}>
            {" "}
            <DegreeBarChart placement={placement} />
          </Grid>
          <Grid item xs={5}>
            <PieChart placement={industry8} />
          </Grid> */}

          {/* <Grid item xs={7}>
            {" "}
            <SecondBar />
          </Grid>
          <Grid item xs={5}>
            <HiredPie />
          </Grid> */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ marginTop: "10px", marginBottom: "30px" }}
            >
              Campus Placement based on CTC Criteria
            </Typography>
            <SecondContent
              industry5={industry5}
              industry6={industry6}
              industry7={industry7}
            />
            {/* <Grid container spacing={4}>
              <Grid item xs={4}>
                <VisitCompanyBar placement={industry5} />
              </Grid>
              <Grid item xs={4}>
                <JobOpenings placement={industry6} />
              </Grid>
              <Grid item xs={4}>
                <HiredStudents placement={industry7} />
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ marginTop: "10px", marginBottom: "30px" }}
            >
              Campus Placement based on Industries
            </Typography>
            <FourContent
              industry1={industry1}
              industry2={industry2}
              industry4={industry4}
              industry3={industry3}
            />
            {/* <Grid container spacing={4}>
              <Grid item xs={6}>
                
              <Company placement={industry1 />
              </Grid>
              <Grid item xs={6}>
                <Industry placement={industry2} />
              </Grid>
              <Grid item xs={6}>
                <SingalIndustry placement={industry4} />
              </Grid>
              <Grid item xs={6}>
                <IndustryType placement={industry3} />
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default ReportChart;
