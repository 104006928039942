import { sanchalanAxiosGetReq } from "../api/BaseApi/apiBase";

export const checkForToken = (error, history, location, alert) => {
  if (error?.response?.status === 401) {
    history.push({ pathname: "/", search: `?source=${location.pathname}` });
    // alert.error("Session Expired");
    throw error;
  }
  if (error?.response?.status === 403) {
    alert.error(error.response.data.error);
    throw error;
  }
};

export const sendExportLink = async (
  URL,
  setExportLoading = () => {},
  alert = null,
  history = null,
  location = null,
  fromSharer = false
) => {
  const url = URL;
  fromSharer
    ? setExportLoading({ loading: true, success: false })
    : setExportLoading(true);
  try {
    const res = await sanchalanAxiosGetReq(url);

    if (res.data.success) {
      const successMessage = res?.data?.data?.message;
      alert?.success(successMessage);
      fromSharer
        ? setExportLoading({ loading: false, success: true })
        : setExportLoading(false);
    } else {
      console.log("error ocuured");
    }
    fromSharer
      ? setExportLoading({ loading: false, success: false })
      : setExportLoading(false);
  } catch (error) {
    fromSharer
      ? setExportLoading({ loading: false, success: false })
      : setExportLoading(false);
    checkForToken(error, history, location, alert);
    if (error?.response?.status !== 401) {
      alert.error(error?.response?.data?.error);
    }
    throw error;
  }
};
