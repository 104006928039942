import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
  },
  newBtnStyle: {
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function AddCertificationDialog(props) {
  const classes = useStyles();
  const alert = useAlert();
  const {
    open,
    editCertifications,
    setEditCertifications,
    handleClose,
    saveCertifications,
    certiDataLoading,
    isEdit,
  } = props;

  const validateForm = () => {
    if (
      !(
        editCertifications?.name &&
        editCertifications?.institute &&
        editCertifications?.start_date &&
        editCertifications?.end_date
      )
    ) {
      alert.error("Please fill all required fields!");
      return false;
    }
    if (!editCertifications?.expiration && !editCertifications?.valid_date) {
      alert.error("Please fill all required fields!");
      return false;
    }
    return true;
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
      >
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>{isEdit ? "Edit" : "Add"} Course or Certification</b>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                margin="normal"
                label="Course/Certification Name*"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editCertifications?.name}
                onChange={(e) => {
                  setEditCertifications({
                    ...editCertifications,
                    name: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                style={{ width: "100%" }}
                multiline
                maxRows={6}
                minRows={6}
                inputProps={{
                  className: classes.textfiledrow,
                }}
                id="outlined-basic"
                label="Description"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                name="about"
                value={editCertifications?.description}
                onChange={(e) => {
                  setEditCertifications({
                    ...editCertifications,
                    description: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                label="Institute Name* "
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editCertifications?.institute}
                onChange={(e) => {
                  setEditCertifications({
                    ...editCertifications,
                    institute: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" className={classes.font}>
                Start Date
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  openTo="year"
                  margin="normal"
                  views={["year", "month"]}
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="Start Date*"
                  value={
                    editCertifications?.start_date
                      ? editCertifications?.start_date
                      : null
                  }
                  onChange={(date, value) => {
                    setEditCertifications({
                      ...editCertifications,
                      start_date: date,
                    });
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" className={classes.font}>
                End Date
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  openTo="year"
                  margin="normal"
                  views={["year", "month"]}
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="End Date*"
                  value={
                    editCertifications?.end_date
                      ? editCertifications?.end_date
                      : null
                  }
                  onChange={(date, value) => {
                    setEditCertifications({
                      ...editCertifications,
                      end_date: date,
                    });
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            {!editCertifications?.expiration && (
              <>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2" className={classes.font}>
                    Valid Upto
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="outlined"
                      openTo="year"
                      margin="normal"
                      views={["year", "month"]}
                      inputVariant="outlined"
                      id="date-picker-inline"
                      defaultValue="MM/YYYY"
                      label="Valid Upto"
                      value={
                        editCertifications?.valid_date
                          ? editCertifications?.valid_date
                          : null
                      }
                      onChange={(date, value) => {
                        setEditCertifications({
                          ...editCertifications,
                          valid_date: date,
                        });
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editCertifications?.expiration ? true : false}
                    onChange={() => {
                      setEditCertifications({
                        ...editCertifications,
                        expiration: !editCertifications?.expiration,
                      });
                    }}
                    name="checkedG"
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    This Certification/Course doesn't Expire
                  </Typography>
                }
              />
            </Grid>

            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                className={classes.newBtnStyle}
                onClick={() => {
                  handleClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.newBtnStyle}
                onClick={() => {
                  if (validateForm()) {
                    saveCertifications();
                  }
                }}
              >
                {certiDataLoading ? <CustomButtonCircularProgress /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
