import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const COMPANY_DATA = "COMPANY_DATA";
export const COMPANY_INVITE_LINK = "COMPANY_INVITE_LINK";
export const SINGLE_COMPANY_DATA = "SINGLE_COMPANY_DATA";
export const SELECTED_COMPANY_UPLOADER_FIELDS_PUBLIC =
  "SELECTED_COMPANY_UPLOADER_FIELDS_PUBLIC";
export const COMPANY_JOB_DETAILS = "COMPANY_JOB_DETAILS";
export const PUBLIC_COMPANY_LIST = "PUBLIC_COMPANY_LIST";

export const CompanyGetAPI = (
  setLoading,
  alert,
  history,
  location,
  url,
  setClickSearchButton,
  setApplyClickedCTC,
  setTotalCount,
  setPendingCount,
  setRejectedCount = () => {},
  setCompanyList = () => {},
  source
) => {
  return async (dispatch) => {
    setCompanyList([]);
    setLoading(true);

    let cancelTokenObj = {};

    if (source && source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        if (!error?.message.startsWith("ERR_CANCELED")) setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: COMPANY_DATA,
        payload: res?.data?.data?.data,
      });
      setTotalCount(res?.data?.data?.total_count);
      setPendingCount(res?.data?.data?.pending_count);
      setRejectedCount(res?.data?.data?.rejected_count);
      setCompanyList(res?.data?.data?.data?.results);
    } else {
      alert.error("Some Error Occurred!!!");
    }

    setLoading(false);
    setClickSearchButton(false);
    setApplyClickedCTC(false);
  };
};

export const CompanyGetAPI2 = (
  setLoading,
  alert,
  history,
  location,
  url,
  setClickSearchButton,
  setApplyClickedCTC,
  setTotalCount,
  setPendingCount,
  setRejectedCount,
  setCompanyList,
  source,
  setCompanyListTotalCount
) => {
  return async (dispatch) => {
    // setCompanyList([]);
    setLoading(true);

    let cancelTokenObj = {};

    if (source && source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        console.log("error: ", error);
        if (
          !error?.message.startsWith("ERR_CANCELED") &&
          error?.message !== "Cancelled due to stale request"
        )
          setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      setLoading(false);
      dispatch({
        type: COMPANY_DATA,
        payload: res?.data?.data?.data?.results,
      });
      setTotalCount(res?.data?.data?.total_count);
      setPendingCount(res?.data?.data?.pending_count);
      setRejectedCount(res?.data?.data?.rejected_count);
      setCompanyListTotalCount(res?.data?.data?.data?.count);
      // setCompanyList(res?.data?.data?.data?.results);
    } else {
      alert.error("Some Error Occurred!!!");
      setLoading(false);
    }

    setClickSearchButton(false);
    setApplyClickedCTC(false);
  };
};

export const SingleCompanyGetAPI = (
  setLoading,
  alert,
  history,
  location,
  object_id,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/company/view/?company_id=${object_id}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);

    dispatch({
      type: SINGLE_COMPANY_DATA,
      payload: res.data.data.data,
    });
  };
};

export const SinglePendingCompanyGetAPI = (
  setLoading,
  alert,
  history,
  location,
  object_id,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/company/invite_view/?college_id=${collegeId}&company_id=${object_id}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);

    dispatch({
      type: SINGLE_COMPANY_DATA,
      payload: res.data.data.data,
    });
  };
};

export const CompanyPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  companyList,
  handleClose,
  fromPostJob,
  setMakeCompanyCall,
  collegeId,
  setJobDetails = () => {}
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "api/company/view/", data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error("Unable to Create your company. Some Error Occurred");
        }
        throw error;
      });

    if (res.data.success) {
      alert.success("Company Added Successfully!!");

      const dataRecived = res?.data?.data;

      let company = {
        college_id: dataRecived?.college_id,
        company_website: data?.company_form_data?.company_website,
        id: dataRecived?.id,
        name: dataRecived?.name,
      };

      setJobDetails((prevData) => ({ ...prevData, company: company }));

      if (!fromPostJob) {
        var arr = [...companyList];
        // var dat = {
        //   id: res.data.data.id,
        //   college_id: res.data.data.college_id,
        //   name: res.data.data.company_form_data.company_name,
        //   industry_type: res.data.data.industry_type,
        //   vacancies_date: null,
        //   vacancies: 0,
        //   ctc: 0,
        //   hired: 0,
        //   last_job_post: null,
        //   current_status: "ACTIVE",
        // };
        // console.log(dat);
        // arr.push(res.data.data);
        arr.unshift(res.data.data);
      } else {
        setMakeCompanyCall(true);
      }
      handleClose();
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
    dispatch({
      type: COMPANY_DATA,
      payload: arr,
    });
  };
};

export const CompanyPublicPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  college_id,
  setStepNo,
  fileNames,
  updateLogo
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "public/add_company/", data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error("Unable to Create your company. Some Error Occurred");
        }
        throw error;
      });

    if (res.data.success) {
      setStepNo(1);
      if (fileNames) updateLogo(res?.data?.data?.id);
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const ExcelPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  handleClose,
  setProgress,
  setDataLoaded,
  setTotalLoad,
  setresData
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "api/company/upload_excel/", data, {
        withCredentials: true,
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setDataLoaded(Math.round(data.loaded / 1000000));
          setTotalLoad(Math.round(data.total / 1000000));

          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .catch((error) => {
        setLoading(false);
        alert.error("Upload Failed. Enter Correct File!!!");
        handleClose();

        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      // alert.success(res.data.data.message);
      const resdata = res?.data?.data;
      setresData({
        total_entries: resdata?.total_entries,
        user_created_count: resdata?.user_created_count,
        email_already_exist: resdata?.email_already_exist,
        failed_entries: resdata?.error_list_count,
        flag: res?.data?.success,
        error_list: [
          ...res?.data?.data?.error_list,
          ...res?.data?.data?.already_exist_data,
        ],
        // already_exist_list: res?.data?.data?.already_exist_data,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();

    setLoading(false);
  };
};

export const InviteLinkGetAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/company/invite_link/`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      // alert.error("Some Error Occurred!!!");
    }

    dispatch({
      type: COMPANY_INVITE_LINK,
      payload: res.data.data,
    });
  };
};

export const BlockCompanyAPI = (
  data,
  alert,
  history,
  location,
  companyList,
  handleClose,
  setSelectedRowsData,
  setLoader
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/company/block_company/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        setLoader(false);
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...companyList];
      data.object_id.forEach((item) => {
        arr.forEach((item2) => {
          if (item2.id === item) item2["current_status"] = data.status;
        });
      });
      setSelectedRowsData([]);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();
    setLoader(false);
    dispatch({
      type: COMPANY_DATA,
      payload: arr,
    });
  };
};

export const BlockSingleCompanyAPI = (
  data,
  alert,
  setLoading,
  handleResponse = () => {}
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/company/block_company/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      handleResponse();
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const DeleteCompanyAPI = (
  data,
  alert,
  history,
  location,
  companyList,
  handleClose,
  setCallNavbar,
  setLoader
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/company/delete_company/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        setLoader(false);
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...companyList];
      data.object_id.map((item) => {
        var index = arr.findIndex((item2) => item2.id === item);
        arr.splice(index, 1);
      });
      setCallNavbar(true);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();
    setLoader(false);
    dispatch({
      type: COMPANY_DATA,
      payload: arr,
    });
  };
};

export const ApproveCompanyAPI = (
  data,
  alert,
  history,
  location,
  companyList,
  handleClose,
  setCallNavbar,
  setLoader,
  companyDetail,
  partPath
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/company/company_verify/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        setLoader(false);
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      if (!companyDetail) {
        var arr = [...companyList];
        data.company_id.map((item) => {
          var index = arr.findIndex((item2) => item2.id === item);
          arr.splice(index, 1);
        });
        setCallNavbar(true);
        dispatch({
          type: COMPANY_DATA,
          payload: arr,
        });
      }

      if (companyDetail) history.push(`${partPath}`);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();
    setLoader(false);
  };
};

export const ApproveSingleCompanyAPI = (
  data,
  alert,
  history,
  location,
  setLoading
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/company/company_verify/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      setLoading(true);
      history.push("/manage-companies");
    } else {
      alert.error("Some Error Occurred!!!");
      setLoading(true);
    }
    setLoading(true);
  };
};
export const CompanyProfilePatch = (
  data,
  setLoading,
  alert,
  history,
  location,
  setPocList,
  companyId,
  handleClose
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .patch(`${BackendBaseApi.SANCHALAN}api/company/view/${companyId}`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      alert.success("POC Successfully updated!!");
      setPocList(data?.poc_list);
      handleClose();
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const CompanySelectedFieldsPublicAPI = (
  setLoading,
  alert,
  history,
  location,
  link
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}public/company_student_form/?link=${link}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (!res.data.success) {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_COMPANY_UPLOADER_FIELDS_PUBLIC,
      payload: res.data.data,
    });
  };
};

export const CompanyJobApi = (
  setLoading,
  alert,
  history,
  location,
  companyId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/company/company_job_details/?company_id=${companyId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
      });

    if (!res?.data.success) {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: COMPANY_JOB_DETAILS,
      payload: res.data.data,
    });
  };
};

export const AllCompanyPublicAPI = (alert, history, location, collegeId) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}public/company_list/?college_id=${collegeId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
      });

    if (!res?.data.success) {
      alert.error("Some Error Occurred!!!");
    } else {
      dispatch({
        type: PUBLIC_COMPANY_LIST,
        payload: res.data.data,
      });
    }
  };
};
