import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { AllLocationsAPI } from "../../../api/JobPost/action";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import Calendar from "../../Input/Calendar/index3";


const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  root1: {
    borderRadius: "8px",
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },

  btncancel: {
    height: "45px",
    width: "100%",
    boxShadow: "none",
  },

  formControl: {
    minWidth: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
  },
}));

export default function HigherStudentForm({ handleHigherClose, selectedRowsData, setSelectedRowsData, selectedID,setSelectedID, 
  callNavbarHigherStudies,
  setCallNavbarHigherStudies,
  // callViewHigherStudies,
  // setCallViewHigherStudies, 
}) {

  const classes = useStyles();
  const [loadingLocations, setLoadingLocations] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loader,setLoader] = useState(false);
  const [startDate,setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // useEffect(() => {
  //   // dispatch(AllJobRolesAPI(setLoadingJobRoles, alert, history, location));
  //   dispatch(AllLocationsAPI(setLoadingLocations, alert, history, location));
  // }, []);

  // const cityList = useSelector((state) =>
  //   state?.AllLocations?.details ? state?.AllLocations?.details : []
  // );
  const [studentDetails, setStudentDetails] = useState({
    name: null,
    email: null,
    phone: null,
    enrollment_no: null,
    degree: null,
    course: null,
    batch: null,
    start_date: "11/11/2023",
    end_date: "11/11/2023",
    cgpa: null,
    backlog_check: false,
    backlog: null,
    city: null,
    country: null,
    university: null,
    address: null,
    qualifyExam: null,
    specialization: null,

  });

  const [higherStudiesError, setHigherStudiesError] = useState({
    collegeName: null,
    universityName: null,
    country: null,
    state: null,
    address: null,
    degree: null,
    specialization: null,
    qualifyExam: null,
    start_Date: null,
    end_Date: null
  })


  const handleChange = (props, value) => {
    setStudentDetails({
      ...studentDetails,
      [props]: value
    })
  }

  const validateData = () => {
    let isError = false;
    let collegeName = null;
    let universityName = null;
    let country = null;
    let state = null;
    let address = null;
    let degree = null;
    let specialization = null;
    let qualifyExam = null;
    let start_Date = null;
    let end_Date = null;

    if (!studentDetails?.name) {
      collegeName = "College Name cannot be left blank"
      isError = true
    }
    if (!studentDetails?.university) {
      universityName = "University Name cannot be left blank"
      isError = true
    }
    if (!studentDetails?.address) {
      address = "College Address cannot be left blank"
      isError = true
    }
    if (!studentDetails?.city) {
      state = "College state cannot be left blank"
      isError = true
    }
    if (!studentDetails?.country) {
      country = "College country cannot be left blank"
      isError = true
    }
    if (!studentDetails?.specialization) {
      specialization = "Specialization cannot be left blank"
      isError = true
    }
    if (!studentDetails?.qualifyExam) {
      qualifyExam = "Qualifying Exam cannot be left blank"
      isError = true
    }
    if (!studentDetails?.degree) {
      degree = "Degree cannot be left blank"
      isError = true
    }
    if(startDate && endDate && !(startDate<endDate)){
       alert.error("Course end Date can't be before Start Date ");
      isError = true
    }
    
    setHigherStudiesError({
      ...higherStudiesError,
      collegeName,
      universityName,
      country,
      state,
      address,
      degree,
      specialization,
      qualifyExam,
      start_Date,
      end_Date,
    })
    return !isError
  }
 
  const formatDate = (date) => {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const day = String(date?.getDate())?.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  const handleSubmit = () => {
    if(validateData()){
    const payload = {
      student_id: selectedRowsData[0]?.id,
      college_name: studentDetails?.name,
      university_name: studentDetails?.university,
      college_address: studentDetails?.address,
      degree: studentDetails?.degree,
      specialization: studentDetails?.specialization,
      qualify_exam: studentDetails?.qualifyExam,
      course_start_date: startDate ? formatDate(startDate) : null,
      course_end_date: endDate ? formatDate(endDate) : null,
      state: studentDetails?.city,
      country: studentDetails?.country
    }
    setLoader(true);
    const url = `${BackendBaseApi.SANCHALAN}api/manage_student/add_student_higher_studies/`
    axios.post(url, payload, { withCredentials: true })
      .then(res => {
        if (res?.data?.success){
          alert.success(res?.data?.data?.message);
           setSelectedRowsData([]);
          // setSelectedID([]);
           setCallNavbarHigherStudies(!callNavbarHigherStudies)
        } else alert.error(res?.data?.error)
        
        setLoader(false);
        handleHigherClose();
      })
      .catch(err => {
        alert.error("Something went wrong");
        setLoader(false);

      })

    }

  }

  return (
    <>
      <div className={classes.paper}>
        <Typography
          variant="h2"
          style={{ marginBottom: "20px", marginTop: "-10px", fontSize:"24px" , fontWeight:"bold" }}
        >
          Add Student in higher Studies
        </Typography>
        <Typography
          variant="h6"
          style={{ marginBottom: "20px", marginTop: "-10px",color:"grey",fontWeight:"normal" }}
        >
      When student added to Higher Students, students can't apply for jobs via the mobile app, but the college can apply for them.
     </Typography>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <TextField
              variant="outlined"
              required
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="College Name"
              value={studentDetails.name}
              onChange={(e) => handleChange("name", e.target.value)}
              error={higherStudiesError?.collegeName ? true : false}
              helperText={<span>{higherStudiesError?.collegeName}</span>}

            />
          </Grid>
          <Grid item md={12}>
            <TextField
              variant="outlined"
              required
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              label="University name (if any)"
              value={studentDetails.university}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              onChange={(e) => handleChange("university", e.target.value)}
              error={higherStudiesError?.universityName ? true : false}
              helperText={<span>{higherStudiesError?.universityName}</span>}

            />
          </Grid>{" "}

          <Grid item md={6}>

            <TextField
            style={{width:"100%"}}
              // {...params}
              label="Country"
              variant="outlined"
              // inputProps={{
              //   ...params.inputProps,
              // }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={studentDetails?.country}
              onChange={(e) => handleChange("country", e.target.value)}
              error={higherStudiesError?.country ? true : false}
              helperText={<span>{higherStudiesError?.country}</span>}

            />

          </Grid>


          <Grid item md={6}>
            <TextField
              style={{width:"100%"}}
              // {...params}
              label="State"
              variant="outlined"
              value={studentDetails.city}
              // inputProps={{
              //   ...params.inputProps,
              // }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              onChange={(e) => handleChange("city", e.target.value)}
              error={higherStudiesError?.state ? true : false}
              helperText={<span>{higherStudiesError?.state}</span>}
            />

          </Grid>
          <Grid item md={12}>
            <TextField
              variant="outlined"
              required
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="College Address"
              value={studentDetails.address}
              onChange={(e) => handleChange("address", e.target.value)}
              error={higherStudiesError?.address ? true : false}
              helperText={<span>{higherStudiesError?.address}</span>}

            />
          </Grid>{" "}
          <Grid item md={12}>

            <TextField
              variant="outlined"
              required
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="Degree"
              value={studentDetails?.degree}
              onChange={(e) => handleChange("degree", e.target.value)}
              error={higherStudiesError?.degree ? true : false}
              helperText={<span>{higherStudiesError?.degree}</span>}
            />
          </Grid>
          <Grid item md={12}>

            <TextField
              variant="outlined"
              required
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="Specialization"
              value={studentDetails?.specialization}
              onChange={(e) => handleChange("specialization", e.target.value)}
              error={higherStudiesError?.specialization ? true : false}
              helperText={<span>{higherStudiesError?.specialization}</span>}
            />


          </Grid>
          <Grid item md={12}>
            <TextField
              variant="outlined"
              required
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="Qualifying Exam (if any)"
              value={studentDetails.qualifyExam}
              onChange={(e) => handleChange("qualifyExam", e.target.value)}
              error={higherStudiesError?.qualifyExam ? true : false}
              helperText={<span>{higherStudiesError?.qualifyExam}</span>}


            />
          </Grid>{" "}
          <Grid item md={6}>
            {/* <Calendar title="Course Start Date" 
            selectedDate={studentDetails.start_date}
            setSelectedDate={(date) => {
              setStudentDetails({
                ...studentDetails,
                start_date: moment(date).format("YYYY-MM-DD"),
              });
            }}
            minDate={
              new Date(studentDetails.end_date).toISOString().substring(0, 10)
            }
            readOnly={false}
            // errorMessage={offCampusFormErrorData.joining_date_error}
            />{" "}  */}


          <Calendar
          title="Course Start Date" 
          selectedDate={startDate}
          setSelectedDate={setStartDate}
        errorMessage={higherStudiesError.start_Date}

          />
          </Grid>{" "}
          <Grid item md={6}>
            <Calendar title="Course End Date" 
            selectedDate={endDate}
            setSelectedDate={setEndDate}
            errorMessage={higherStudiesError.end_Date}
            />{" "} 
          </Grid>{" "} 
          <Grid item md={6}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.btncancel}
              onClick={handleHigherClose}
            >
              Cancel
            </Button>
          </Grid>{" "}
          <Grid item md={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btncancel}
              onClick={handleSubmit}
            >
              {loader ? <CustomButtonCircularProgress /> : "Mark Student"}
            </Button>
          </Grid>{" "}
        </Grid>
      </div>
    </>
  );
}

const data = [
  { title: "The Shawshank Redemption" },
  { title: "The Godfather" },
  { title: "The Godfather: Part II" },
];
