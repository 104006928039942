import React from "react";
import { Grid, Typography } from "@material-ui/core";
import UniversityLayout from "../../../components/Layout/UniversityLayout";
import UniversityCompanyLinkTable from "../../../components/Table/UniversityTable/UniversityCompanyLinkTable";

export default function UniversityTeamLink() {
  return (
    <>
      {/* <UniversityLayout> */}
      <Typography variant="h2">Invite Team Member Links</Typography>
      <div style={{ marginTop: "20px" }}>
        <UniversityCompanyLinkTable />
      </div>
      {/* </UniversityLayout> */}
    </>
  );
}
