import { Box, Grid, Typography, withStyles, Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  collegeGroupListSelectorFn,
  collegeIdFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import { EDGPlacementCyclesAPI } from "../../api/University/APIs/action";
import UplacementCycle from "../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../components/CircularProgressBar";
// import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { BackendBaseApi } from "../../constants/constants";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";
import ActionBar from "../../components/Cards/PlacementCycle/ActionBar";
import CompanyTable from "../../components/Table/CompanyTableNew";
import AddCycle from "../../components/DialogSection/AddCycle";
import PlacementTable from "../../components/Table/PlacementTable";
import PlacementCyclesDialog from "../../components/DialogSection/UniManageJob";
import Cycle from "../../components/DialogSection/Cycle";
import moment from "moment";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 20.5,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function UniversityPlacementCycle() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else
        return `&group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  // useEffect(() => {
  //   var url = `${
  //     BackendBaseApi.SANCHALAN
  //   }api/university/university_count/?query=placement_cycle${checkSelectedGroup()}`;
  //   dispatch(EDGPlacementCyclesAPI(setLoading, alert, history, location, url));
  // }, []);

  useEffect(() => {
    if (selectedGroup) {
      var url = `${
        BackendBaseApi.SANCHALAN
      }api/university/university_count/?query=placement_cycle${checkSelectedGroup()}`;
      dispatch(
        EDGPlacementCyclesAPI(setLoading, alert, history, location, url)
      );
    }
  }, [selectedGroup?.id]);

  const collegePlacementList = useSelector(
    (state) => state?.EDGCommon?.edgPlacementList
  );
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  // useEffect(() => {
  //   if (collegeGroupList?.length > 0 && isCollegeGroup) {
  //     setNewCollegeGroupList(
  //       [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
  //     );
  //   } else setNewCollegeGroupList(collegeGroupList);
  // }, [collegeGroupList?.length]);

  useEffect(() => {
    if (collegeGroupList?.length > 0) {
      if (isCollegeGroup) {
        setNewCollegeGroupList(
          [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
        );
        setSelectedGroup({ id: "All", name: "All Groups" });
      } else {
        setNewCollegeGroupList(collegeGroupList);
        setSelectedGroup(collegeGroupList[0]);
      }
    }
  }, [collegeGroupList]);

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const [toggleViewCollege, setToggleViewCollege] = useState(false);
  const handleToggle = () => {
    setToggleViewCollege((prev) => !prev);
  };

  const [open1, setOpen1] = useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const [collegeToPlacementCycle, setCollegeToPlacementCycle] = useState([]);

  const handleClickView = (data) => {
    setCollegeToPlacementCycle(data);
    handleClickOpen1();
  };

  const columns = [
    {
      field: "name",
      width: 205,
      resizable: true,
      renderHeader: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <strong style={{ color: "#5a6268" }}>Placement Cycle Name</strong>
        </div>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link style={{ textDecoration: "none", display: "contents" }}>
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.name}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "date",
      width: 140,
      resizable: true,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "18px" }}>
          Create Date
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography noWrap style={{ fontSize: "14px" }}>
                {moment(params.row.create_date).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "created_by",
      width: 140,
      resizable: true,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "18px" }}>
          Created By
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography noWrap style={{ fontSize: "14px" }}>
                {params.row.created_by}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "start_date",
      width: 140,
      resizable: true,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "18px" }}>
          Start Date
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography noWrap style={{ fontSize: "14px" }}>
                {moment(params.row.start_date).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "end_date",
      width: 140,
      resizable: true,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "18px" }}>
          End Date
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography noWrap style={{ fontSize: "14px" }}>
                {params.row.end_date
                  ? moment(params.row.end_date).format("DD-MM-YYYY")
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "add_colleges",
      width: 160,
      renderHeader: (params) => (
        <div>
          {" "}
          <strong style={{ color: "#5a6268", marginLeft: 15 }}>
            {`Added ${entityInfo?.college}`}
          </strong>
        </div>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Typography
                color="primary"
                noWrap
                style={{ fontSize: "14px", cursor: "pointer" }}
                onClick={() => handleClickView(params.row?.college_details)}
              >
                {`View (${params?.row?.college_details?.length})`}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "open_jobs",
      // type: "text",
      headerName: "Source",
      sortable: false,
      width: 180,
      renderHeader: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginLeft: ".5rem",
          }}
        >
          <strong style={{ color: "#5a6268" }}>Open Jobs</strong>
        </div>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.job_count !== 0 && params.row.job_count
            ? params.row.job_count
            : "--"}
        </span>
      ),
    },

    {
      field: "registered_students",
      width: 200,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
          Registered Students
        </strong>
      ),
      renderCell: (params) => (
        <Typography
          color="primary"
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.student_count !== 0 && params.row.student_count
            ? params.row.student_count
            : "--"}
        </Typography>
      ),
    },
    {
      field: "last_update",
      width: 140,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "18px" }}>
          Last Update
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {moment(params.row.last_update_date).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      field: "last_update_by",
      width: 180,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "22px" }}>
          Last Update By
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.last_update_by}
        </span>
      ),
    },
    {
      field: "status",
      // type: "text",
      headerName: "Status",
      sortable: false,
      width: 180,
      renderHeader: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginLeft: ".5rem",
          }}
        >
          <strong style={{ color: "#5a6268" }}>Status</strong>
        </div>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {!params.row.is_ended ? "Active" : "Inactive"}
        </span>
      ),
    },
  ];

  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    if (licenceData && licenceData?.display) {
      alert.error(licenceData?.display_message);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setEdit(false);
    setOpen(false);
  };

  const userRolesPermission = useSelector(userRolesActionsFn);
  const collegeId = useSelector(collegeIdFn);

  const [selectedID, setSelectedID] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [edit, setEdit] = useState(false);

  const [searchFilter, setSearchFilter] = useState("");

  const [clickSearchButton, setClickSearchButton] = useState(false);

  const checkSelectedFilter = () => {
    if (searchFilter) {
      return `&search=${searchFilter}`;
    }
    return "";
  };

  const [open2, setOpen2] = useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <>
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      <br />
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2">Placement Cycles</Typography>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <AddCycle
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                collegeId={collegeId}
                userRolesPermission={userRolesPermission}
                multiCollege={true}
                edit={edit}
                editData={selectedRowsData[0]}
                searchFilter={searchFilter}
                selectedRowsData={selectedRowsData}
                setSelectedID={setSelectedID}
                setSelectedRowsData={setSelectedRowsData}
              />
              <Typography
                color={toggleViewCollege ? "primary" : "#6C757D"}
                style={{ fontSize: 16, marginRight: 5, marginLeft: 10 }}
              >
                Show {entityInfo?.college}
              </Typography>
              <AntSwitch
                checked={toggleViewCollege}
                onChange={handleToggle}
                name="checkedC"
              />
            </Box>
          </div>
          <div style={{ marginTop: "20px" }}>
            {!toggleViewCollege ? (
              <>
                <Grid item md={12}>
                  <Box>
                    <ActionBar
                      loading={loading}
                      handleToggle={handleToggle}
                      entityInfo={entityInfo}
                      toggleViewCollege={toggleViewCollege}
                      setToggleViewCollege={setToggleViewCollege}
                      handleClickOpen={handleClickOpen}
                      setEdit={setEdit}
                      selectedRowsData={selectedRowsData}
                      searchFilter={searchFilter}
                      setSearchFilter={setSearchFilter}
                      setClickSearchButton={setClickSearchButton}
                      openEndCycle={handleClickOpen2}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box style={{ marginTop: 15 }}>
                    <PlacementTable
                      columns={columns}
                      checkSelectedGroup={checkSelectedGroup}
                      selectedID={selectedID}
                      setSelectedID={setSelectedID}
                      setSelectedRowsData={setSelectedRowsData}
                      setEdit={setEdit}
                      clickSearchButton={clickSearchButton}
                      checkSelectedFilter={checkSelectedFilter}
                      setClickSearchButton={setClickSearchButton}
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <Grid container spacing={5}>
                {collegePlacementList?.length > 0 &&
                  collegePlacementList?.map((item) => (
                    <Grid item xs={4}>
                      <UplacementCycle
                        item={item}
                        countTitle={"Active Placement Cycles"}
                        count={item?.count}
                        link={`/placement-cycles`}
                        linkTitle={"View All Active Placement Cycles"}
                        from={"Placement"}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </div>
        </>
      )}
      {/* </UniversityLayout> */}

      <PlacementCyclesDialog
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
        handleClickOpen={handleClickOpen1}
        collegeToPlacementCycle={collegeToPlacementCycle}
      />

      <Cycle
        handleClickOpen={handleClickOpen2}
        open={open2}
        handleClose={handleClose2}
        item={selectedRowsData[0]}
        collegeId={selectedRowsData.map((item) => item.id)}
        multiCycle={true}
        setSelectedID={setSelectedID}
        setSelectedRowsData={setSelectedRowsData}
      />
    </>
  );
}

export default UniversityPlacementCycle;
