import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";
import ButtonBase from "@material-ui/core/ButtonBase";
import Sumit from "../../../../assets/img/sumit.jpg";
import PhoneIcon from "@material-ui/icons/Phone";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ProfileApproveDialog from "../../EditStudent/ProfileApproveDialog/ProfileApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    //maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
}));

export default function Publications({
  data,
  from,
  userId,
  ApproveAcceptList,
  StudentDetailsAPI,
  fromProfileUpdate,
}) {
  const classes = useStyles();
  const router = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <br />
      {/* {(from && data?.length > 0) ||
      (fromProfileUpdate &&
        ApproveAcceptList &&
        (Object.keys(ApproveAcceptList)?.includes("ACHIEVE") ||
          (from && Object.keys(ApproveAcceptList)?.includes("EXTRA_CURR")))) ? (
        <div className={classes.paper}>
          <Typography variant="h6">{`${from || "Achievements"}`}</Typography>
          {data?.length > 0 &&
            data?.map((item) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      marginRight: "10px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      height: "10px",
                      backgroundColor: "#000",
                      borderRadius: "8px",
                    }}
                  />

                  <Typography variant="body2">{item}</Typography>
                </div>
              </div>
            ))}
          {fromProfileUpdate &&
          ((from &&
            ApproveAcceptList &&
            Object.keys(ApproveAcceptList)?.includes("EXTRA_CURR")) ||
            Object.keys(ApproveAcceptList)?.includes("ACHIEVE")) ? (
            <div
              style={{
                backgroundColor: "green ",
                padding: "10px",
                width: "160px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                right: "10px",
                bottom: "10px",
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
              >
                {`Approve Request (${
                  from
                    ? ApproveAcceptList?.EXTRA_CURR ?? 0
                    : ApproveAcceptList?.ACHIEVE ?? 0
                })`}
              </Typography>
            </div>
          ) : (
            <></>
          )}
          <ProfileApproveDialog
            title={`${from || "Achievements"}`}
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            formType={from ? "approveExtraCurricular" : "approveAchievements"}
            data={data}
            userId={userId}
            type={from ? "EXTRA_CURR" : "ACHIEVE"}
            StudentDetailsAPI={StudentDetailsAPI}
          />
        </div>
      ) : null} */}

      {from ? (
        data?.length > 0 ||
        (fromProfileUpdate &&
          ApproveAcceptList &&
          Object.keys(ApproveAcceptList)?.includes("EXTRA_CURR")) ? (
          <div className={classes.paper}>
            <Typography variant="h6">{`${from}`}</Typography>
            {data?.map((item) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      marginRight: "10px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      height: "10px",
                      backgroundColor: "#000",
                      borderRadius: "8px",
                    }}
                  />

                  <Typography variant="body2">{item}</Typography>
                </div>
              </div>
            ))}
            {fromProfileUpdate &&
            ApproveAcceptList &&
            Object.keys(ApproveAcceptList)?.includes("EXTRA_CURR") ? (
              <div
                style={{
                  backgroundColor: "green ",
                  padding: "10px",
                  width: "160px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                  cursor: "pointer",
                }}
                onClick={handleClickOpen}
              >
                <Typography
                  style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
                >
                  {`Approve Request (${ApproveAcceptList?.EXTRA_CURR ?? 0})`}
                </Typography>
              </div>
            ) : (
              <></>
            )}
            <ProfileApproveDialog
              title={`${from || "Achievements"}`}
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              formType={from ? "approveExtraCurricular" : "approveAchievements"}
              data={data}
              userId={userId}
              type={from ? "EXTRA_CURR" : "ACHIEVE"}
              StudentDetailsAPI={StudentDetailsAPI}
            />
          </div>
        ) : null
      ) : data?.length > 0 ||
        (fromProfileUpdate &&
          ApproveAcceptList &&
          Object.keys(ApproveAcceptList)?.includes("ACHIEVE")) ? (
        <div className={classes.paper}>
          <Typography variant="h6">{`${"Achievements"}`}</Typography>
          {data?.map((item) => (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    marginRight: "10px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    height: "10px",
                    backgroundColor: "#000",
                    borderRadius: "8px",
                  }}
                />

                <Typography variant="body2">{item}</Typography>
              </div>
            </div>
          ))}
          {fromProfileUpdate &&
          ApproveAcceptList &&
          Object.keys(ApproveAcceptList)?.includes("ACHIEVE") ? (
            <div
              style={{
                backgroundColor: "green ",
                padding: "10px",
                width: "160px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                right: "10px",
                bottom: "10px",
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
              >
                {`Pending Request (${ApproveAcceptList?.ACHIEVE ?? 0})`}
              </Typography>
            </div>
          ) : (
            <></>
          )}
          <ProfileApproveDialog
            title={`${from || "Achievements"} Request`}
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            formType={from ? "approveExtraCurricular" : "approveAchievements"}
            data={data}
            userId={userId}
            type={from ? "EXTRA_CURR" : "ACHIEVE"}
            StudentDetailsAPI={StudentDetailsAPI}
          />
        </div>
      ) : null}
    </>
  );
}
