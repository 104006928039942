import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
export const FEED_POST = "FEED_POST";

export const createPost = (
  setPublishing,
  data,
  alert,
  history,
  location,
  setLoadAgain,
  setPosting,
  dialogClose
) => {
  return async (dispatch) => {
    setPublishing(true);
    setPosting(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/campus_feed/feed/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        alert.error("Some Error Occurred!!!");
        setPublishing(false);
        checkForToken(error, history, location, alert);
        setPosting(false);
        throw error;
      });
    if (res.data.success) {
      setPublishing(false);
      setPosting(false);
      dialogClose();
      alert.success("Feed Published Successfully");
      setLoadAgain(true);
    } else {
      setPublishing(false);
      setPosting(false);
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const likePost = (
  payload,
  alert,
  history,
  location,
  data,
  index,
  item
) => {
  return async (dispatch) => {
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/campus_feed/feed/like/`, payload, {
        withCredentials: true,
      })
      .catch((error) => {
        alert.error("Some Error Occurred!!!");
        checkForToken(error, history, location, alert);

        throw error;
      });
    if (res.data.success) {
      // alert.success(res?.data?.data?.message);
      const newArray = [...data];
      newArray[index].is_liked = item.is_liked === true ? false : true;
      newArray[index].like_count =
        item.is_liked === true ? item.like_count + 1 : item.like_count - 1;
      dispatch({
        type: FEED_POST,
        payload: newArray,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const getPost = (
  setLoader,
  alert,
  history,
  location,
  setLoadAgain,
  setNext,
  setCount
) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/campus_feed/feed/`, {
        withCredentials: true,
      })
      .catch((error) => {
        alert.error("Some Error Occurred!!!");
        setLoader(false);
        checkForToken(error, history, location, alert);

        throw error;
      });
    if (res.data.success) {
      setNext(res.data.data.next);
      setCount(res.data.data.count);
      dispatch({
        type: FEED_POST,
        payload: res?.data?.data?.results?res.data.data.results:[],
      });
      setLoader(false);
      setLoadAgain(false);
    } else {
      setLoader(false);
    }
  };
};

export const getmoreData = (
  url,
  alert,
  history,
  location,
  setNext,
  setCount,
  data,
  dispatch, setLoadmore
) => {
  setLoadmore(true);
  axios
    .get(`${url}`, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data.success) {
        setNext(res.data.data.next);
        setCount(res.data.data.count);
        dispatch({
          type: FEED_POST,
          payload: [...data, ...res.data.data.results],
        });
        setLoadmore(false);
      }
    })
    .catch((err) => {alert.error("Some Error Occurred!!!");
                     setLoadmore(false);     });
};

export const refreshData = (
  setLoader,
  alert,
  history,
  location,
  setLoadAgain,
  setNext,
  setCount,
  dispatch,
  url='api/campus_feed/feed/'
) => {
  setLoader(true);
  axios
    .get(`${BackendBaseApi.SANCHALAN}${url}`, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data.success) {
        setNext(res.data.data.next);
        setCount(res.data.data.count);
        dispatch({
          type: FEED_POST,
          payload: res.data.data.results,
        });
        setLoadAgain(false);
        setLoader(false);
      }
    })
    .catch((err) => {alert.error("Some Error Occurred!!!")
    setLoader(false);
  });
};

export const deletePost = (feedid, alert, history, location, data) => {
  return async (dispatch) => {
    const res = await axios
      .delete(`${BackendBaseApi.SANCHALAN}api/campus_feed/feed/${feedid}`, {
        withCredentials: true,
      })
      .catch((error) => {
        alert.error("Some Error Occurred!!!");
        checkForToken(error, history, location, alert);

        throw error;
      });
    if (res.data.success) {
      alert.success(res?.data?.data?.message);

      const arr = data.filter((item) => item.feed_id !== feedid);

      dispatch({
        type: FEED_POST,
        payload: arr,
      });
    } else {
    }
  };
};
