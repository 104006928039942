import {
  Button,
  Checkbox,
  Chip,
  Paper,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardArrowDownSharp,
  KeyboardArrowUpSharp,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import pSBC from "shade-blend-color";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import { SKillsAPI } from "../../../api/JobPost/action";
import { generateRandomId } from "../../../utility/generateRandomId";
import {
  uniqueArrayWithId,
  uniqueArrayWithoutId,
} from "../../../utility/uniqueArray";
import { years_list } from "../../../utility/yearList";
import ArrowCards from "../../Cards/ArrowCards";
import DegreeSpecializationCustom from "../../DegreeSpecializationCustom";
import JobDescripition from "../../DialogSection/JobDescription";
import Editor from "../../Editor";
import Calendar from "../../Input/Calendar/index2.js";
import ChipCourses from "../ChipCourses";
import { AddCircleOutlineTwoTone } from "@material-ui/icons";
import {
  sanchalanAxiosGetReq,
  sanchalanAxiosPostReq,
} from "../../../api/BaseApi/apiBase";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../constants/constants";
import DescriptionIcon from "@material-ui/icons/Description";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import { DeleteForeverSharp } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "10px",
    marginBottom: "10px",
    // margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },

  customize: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  jdTemp: {
    paddingLeft: "10px",
    paddingRight: "10px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  cal: {
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  join: {
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
      width: "100%",
      paddingLeft: "4px",
      paddingRight: "4px",
      //padding: "4px",
    },
  },
  numberof: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  texteditor: {
    // marginBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      //marginBottom: "115px",
    },
  },
  mini: {
    width: "50%",
    marginBottom: "8px",
    marginTop: "8px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  duration: {
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  plz: {
    color: "#DC3545",
    fontSize: 14,
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      marginTop: 100,
      marginBottom: "-40px",
    },
  },
  salaryBreakup: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 80,
    },
  },
  ctc: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 45,
    },
  },
  eligibleCriteria: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 17,
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      thousandsGroupStyle="lakh"
      prefix="₹"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default function JobProfile({
  data,
  jobDetails,
  setJobDetails,
  jobDetailsError,
  setJobDetailsError,
  loadingCourses,
  jobDesc,
  setJobDesc,
  salaryBreakup,
  setSalaryBreakup,
  dataSelectedNewFormat,
  setDataSelectedNewFormat,
  qualifications,
  setQualifications,
  coursesArray,
  setCoursesArray,
  specializationInDegreeId,
  setSpecializationInDgegreeId,
  pushArray,
  setPushArray,
  dataSelected,
  setDataSelected,
  ppoSalaryBreakup,
  setPpoSalaryBreakup,
  setApplicationDates,
  applicationDates,
  currentJobLocation,
  setCurrentJobLocation,
  currentbatch,
  setCurrentBatch,
  preSelectedJD,
  setPreSelectedJD,
  setJdPath = () => {},
  jdPath,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    job: "",
    name: "hai",
  });
  const [editor, setEditor] = React.useState("");
  const alert = useAlert();

  const [currentRound, setCurrentRound] = useState(null);
  const [currentDuration, setCurrentDuration] = useState(null);
  const [venueType, setVenueType] = useState("ONLINE");
  const [venue, setVenue] = useState(null);
  const [typedRoundName, setTypedRoundName] = useState("");
  const isGradeSystem = useSelector(isGradeSystemFn);

  const jobSegmentList = useSelector((state) =>
    state?.AllJobSegments?.details ? state?.AllJobSegments?.details : []
  );
  const jobRolesList = useSelector((state) =>
    state?.AllJobRoles?.details ? state?.AllJobRoles?.details : []
  );
  const cityList = useSelector((state) => state?.AllLocations?.details);
  const jobRoundList = useSelector((state) => state?.AllJobRounds?.details);
  const selectedCourses = useSelector(
    (state) => state?.commonData?.selectedCourseData?.data
  );
  const college_id = useSelector(
    (store) => store?.domainData?.collegeDetails?.college_id
  );
  const [uploadLoading, setuploadLoading] = useState(false);
  const timer = useRef();
  const [showRoundList, setShowRoundList] = useState(true);
  const [roundList, setRoundList] = useState([]);
  const documents = [
    "Resume",
    "Cover Letter",
    "Transcript",
    "ID Proof (e.g. Aadhar Card, PAN Card, etc.)",
  ];
  const [uploadedDocuments, setUploadedDocuments] = useState(null);
  // const [jdPath, setJdPath] = useState("");
  const [currentSkills, setCurrentSkills] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SKillsAPI());
  }, []);

  const skillList = useSelector((state) =>
    state?.AllSkills?.skills ? state?.AllSkills?.skills : []
  );

  const getDocumentLink = async (file) => {
    const endPoint = "api/file/upload/";

    const formData = new FormData();
    formData.append("file", file);
    formData.append("college_id", college_id);

    setuploadLoading(true);

    try {
      const res = await sanchalanAxiosPostReq(endPoint, formData);

      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setJdPath(res?.data?.data?.link);
        setJobDetails({
          ...jobDetails,
          document_for_jd: res?.data?.data?.link,
          file_name: res?.data?.data?.file_name,
        });
        setJobDetailsError({
          ...jobDetailsError,
          document_for_jd: null,
          file_name: null,
        });
      }
      setuploadLoading(false);
    } catch (error) {
      alert.error("Some error occurred!");
      setuploadLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const checkChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeText = (e) => {
    var name = e.target.name;
    setJobDetails({ ...jobDetails, [name]: e.target.value });
    setJobDetailsError({ ...jobDetailsError, [name]: null });
  };

  useEffect(() => {
    if (applicationDates?.applicationStartDate) {
      setJobDetails({
        ...jobDetails,
        application_start_date: applicationDates?.applicationStartDate,
      });
      setJobDetailsError({ ...jobDetailsError, application_start_date: null });
    }
  }, [applicationDates?.applicationStartDate]);

  useEffect(() => {
    if (applicationDates?.applicationEndDate) {
      setJobDetails({
        ...jobDetails,
        application_end_date: applicationDates?.applicationEndDate,
      });
      setJobDetailsError({ ...jobDetailsError, application_end_date: null });
    }
  }, [applicationDates?.applicationEndDate]);

  useEffect(() => {
    if (applicationDates?.joiningDate) {
      setJobDetails({
        ...jobDetails,
        joining_date: applicationDates?.joiningDate,
      });
      setJobDetailsError({ ...jobDetailsError, joining_date: null });
    }
  }, [applicationDates?.joiningDate]);

  useEffect(() => {
    if (applicationDates?.campusDriveDate) {
      setJobDetails({
        ...jobDetails,
        campus_drive_date: applicationDates?.campusDriveDate,
      });
      setJobDetailsError({ ...jobDetailsError, campus_drive_date: null });
    }
  }, [applicationDates?.campusDriveDate]);

  const handleDeleteFunc = (index, array, name) => {
    if (name) {
      var arr = [...array];

      arr.splice(index, 1);
      setJobDetails({ ...jobDetails, [name]: arr });
    }
  };

  const checkForList = (key) => {
    switch (key) {
      case "job_segment":
        return jobSegmentList;
      case "job_role":
        return jobRolesList;
    }
  };

  useEffect(() => {
    if (selectedCourses?.length > 0) {
      var arr = [...selectedCourses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2.select_all = false;
          item2.specialization.forEach((item3) => {
            item3.selected = false;
          });
        });
      });
      setCoursesArray(arr);
    }
  }, [selectedCourses?.length]);

  const addRounds = () => {
    if (validateRounds()) {
      var arr = [...jobDetails.rounds];
      if (currentRound?.name?.toLowerCase()?.includes("others")) {
        arr.push({
          round_id: currentRound?.id,
          round_code: currentRound?.code,
          round_name: typedRoundName,
          duration: currentDuration,
          type: venueType,
          venue: venue,
          random_round_id: generateRandomId(8),
        });
      } else {
        arr.push({
          round_id: currentRound?.id,
          round_code: currentRound?.code,
          round_name:
            currentRound?.name +
            " " +
            getOccurrence(jobDetails?.rounds, currentRound?.id),
          duration: currentDuration,
          type: venueType,
          venue: venue,
          random_round_id: generateRandomId(8),
        });
      }
      setCurrentRound(null);
      setCurrentDuration("");
      setVenueType("ONLINE");
      setVenue("");
      setJobDetails({ ...jobDetails, rounds: arr });
    }
  };

  function getOccurrence(array, value) {
    var count = 0;
    array.forEach((v) => v.round_id === value && count++);
    return count + 1;
  }

  const UpdateDocuments = (event) => {
    const document = event.target.name;
    if (jobDetails?.documents?.includes(document)) {
      let temp = jobDetails?.documents?.filter((doc) => doc !== document);
      setJobDetails({
        ...jobDetails,

        documents: temp,
      });
    } else {
      setJobDetails({
        ...jobDetails,

        documents: [...jobDetails.documents, document],
      });
    }
  };

  const validateRounds = () => {
    var is_valid = true;
    var round_error = null;
    if (!currentRound) {
      round_error = "Please enter round";
      is_valid = false;
    }
    if (
      currentRound &&
      currentRound?.name?.toLowerCase()?.includes("others") &&
      !typedRoundName
    ) {
      round_error = "Please Enter Round Name";
      is_valid = false;
    }

    setJobDetailsError({
      ...jobDetailsError,
      rounds: round_error,
      // round_duration: duration_error,
      // venue: venue_error,
    });
    return is_valid;
  };

  const handleDeleteRounds = (item) => {
    var arr = [...jobDetails?.rounds];
    var index = arr.findIndex(
      (x) => x.random_round_id === item.random_round_id
    );
    if (index !== -1) {
      arr.splice(index, 1);
      setJobDetails({ ...jobDetails, rounds: arr });
    }
  };

  useEffect(() => {
    if (jobDesc) {
      setJobDetails({ ...jobDetails, job_description: jobDesc });
      setJobDetailsError({ ...jobDetailsError, job_description: null });
    }
  }, [jobDesc]);

  useEffect(() => {
    if (salaryBreakup) {
      setJobDetails({ ...jobDetails, salary_breakup: salaryBreakup });
    }
  }, [salaryBreakup]);

  useEffect(() => {
    if (ppoSalaryBreakup) {
      setJobDetails({ ...jobDetails, salary_breakup_ppo: ppoSalaryBreakup });
    }
  }, [ppoSalaryBreakup]);

  useEffect(() => {
    setJobDetails({
      ...jobDetails,
      degree_spec_selected: dataSelectedNewFormat,
    });
    setJobDetailsError({ ...jobDetailsError, degree_spec_selected: null });
  }, [dataSelectedNewFormat, dataSelectedNewFormat?.length]);

  useEffect(() => {
    if (!showRoundList) setRoundList(jobDetails?.rounds.slice(0, 4));
    else setRoundList(jobDetails?.rounds);
  }, [showRoundList, jobDetails?.rounds?.length]);

  const [jdSelectButton, setJdSelectButton] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getFieldContent(step, item, index) {
    switch (item.field_type) {
      case "company":
        return (
          <>
            {/* <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "7px",
              }}
            >
              <Grid item xs={9}>
                {" "}
                <Autocomplete
                  id="country-select-demo"
                  options={companyList}
                  getOptionLabel={(option) => option.name}
                  value={jobDetails?.company ? jobDetails?.company : ""}
                  onChange={(e, newValue) => {
                    setJobDetails({ ...jobDetails, company: newValue, company_id: newValue?.id, company_name: newValue?.name });

                    setJobDetailsError({ ...jobDetailsError, company: null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Company *"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={jobDetailsError.company ? true : false}
                      helperText={<span>{jobDetailsError.company}</span>}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} className={classes.btngrid}>
                <PostJobComapny />
              </Grid>
            </Grid> */}
          </>
        );
      case "job_type":
        return (
          <>
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Gender</FormLabel> */}

              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={Number(jobDetails?.job_type_id)}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    job_type_name: e.target.name,
                    job_type_id: Number(e.target.value),
                  });
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Job Type
                </Typography>
                <FormControlLabel
                  value={7}
                  name="Full Time"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Full-Time</Typography>}
                />
                <FormControlLabel
                  value={1}
                  name="Internship"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Internship</Typography>}
                />
                {/* <FormControlLabel value={3} name="Part Time" control={<StyledRadio />} label={<Typography variant="body2">Part-Time</Typography>} /> */}
              </RadioGroup>
            </FormControl>
          </>
        );

      case "textfield":
        return (
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={jobDetails[item.key] ? jobDetails[item.key] : ""}
              onChange={handleChangeText}
              fullWidth
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              id="title"
              style={{ marginBottom: "1px" }}
              label={item?.field_name}
              name={item.key}
              error={jobDetailsError[item.key] ? true : false}
              helperText={<span>{jobDetailsError[item.key]}</span>}
            />
          </>
        );
      case "autocomplete":
        return (
          <div
            className={classes.formControl}
            style={{ marginTop: "16px", marginBottom: "16px" }}
          >
            <Autocomplete
              id="country-select-demo"
              options={checkForList(item.key)}
              getOptionLabel={(option) => option.name}
              value={jobDetails[item.key] ? jobDetails[item.key] : ""}
              onChange={(e, newValue) => {
                setJobDetails({ ...jobDetails, [item.key]: newValue });
                setJobDetailsError({ ...jobDetailsError, [item.key]: null });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={item?.field_name}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={jobDetailsError[item.key] ? true : false}
                  helperText={<span>{jobDetailsError[item.key]}</span>}
                />
              )}
            />
          </div>
        );
      case "application_date":
        return (
          <>
            <Grid container spacing={1} style={{ marginTop: "10px" }}>
              <Grid item xs={12} className={classes.cal} sm>
                <Calendar
                  title="Application Start Date"
                  selectedDate={applicationDates?.applicationStartDate}
                  setSelectedDate={(date) => {
                    setApplicationDates({
                      ...applicationDates,
                      applicationStartDate: date,
                    });
                  }}
                  errorMessage={jobDetailsError?.application_start_date}
                  minDate={new Date()}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} sm style={{ display: "contents" }}>
                <div className={classes.join}>
                  {jobDetails?.application_start_date && (
                    <Calendar
                      title="Application End Date"
                      // selectedDate={applicationEndDate}
                      // setSelectedDate={setApplicationEndDate}
                      selectedDate={applicationDates?.applicationEndDate}
                      setSelectedDate={(date) => {
                        setApplicationDates({
                          ...applicationDates,
                          applicationEndDate: date,
                        });
                      }}
                      errorMessage={jobDetailsError?.application_end_date}
                      minDate={
                        jobDetails?.application_start_date &&
                        new Date(jobDetails?.application_start_date)
                          .toISOString()
                          .substring(0, 10)
                      }
                      readOnly={true}
                    />
                  )}
                </div>
              </Grid>
            </Grid>

            {/* <br /> */}
          </>
        );
      case "date_single":
        return (
          <>
            <div className={classes.join} style={{ marginTop: "5px" }}>
              <Calendar
                title="Joining Date"
                // selectedDate={joiningDate}
                // setSelectedDate={setJoiningDate}
                selectedDate={applicationDates?.joiningDate}
                setSelectedDate={(date) => {
                  setApplicationDates({
                    ...applicationDates,
                    joiningDate: date,
                  });
                }}
                errorMessage={jobDetailsError?.joining_date}
                minDate={
                  jobDetails?.application_end_date &&
                  new Date(jobDetails?.application_end_date)
                    .toISOString()
                    .substring(0, 10)
                }
                readOnly={true}
              />
            </div>
          </>
        );
      case "campus_drive_date":
        return (
          <>
            <div className={classes.join} style={{ marginTop: "10px" }}>
              <Calendar
                title="Campus Drive Date"
                selectedDate={applicationDates?.campusDriveDate}
                setSelectedDate={(date) => {
                  setApplicationDates({
                    ...applicationDates,
                    campusDriveDate: date,
                  });
                }}
                errorMessage={jobDetailsError?.campus_drive_date}
                minDate={
                  jobDetails?.application_end_date &&
                  new Date(jobDetails?.application_end_date)
                    .toISOString()
                    .substring(0, 10)
                }
                readOnly={true}
              />
            </div>
          </>
        );
      case "job-desc":
        return (
          <>
            <JobDescripition
              jobRolesList={jobRolesList}
              setJobDesc={setJobDesc}
              setPreSelectedJD={setPreSelectedJD}
              setJdSelectButton={setJdSelectButton}
              handleClose={handleClose}
              open={open}
            />
            <Typography
              variant="body2"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <b>Job Description*</b>
              {/* <span onClick={handleClickOpen} className={classes.jdTemp}>
                Click Here
              </span>{" "}
              to choose from Job Description Template */}
            </Typography>
            <div className={classes.texteditor}>
              <Editor
                state={jobDesc}
                setState={setJobDesc}
                desc={preSelectedJD}
                scrollUp={true}
              />
            </div>

            {jobDetailsError?.job_description && (
              <Typography variant="h6" className={classes.plz}>
                Please enter Job Description
              </Typography>
            )}
            <br />
          </>
        );
      case "job-desc-docs":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ boxShadow: "none" }}
                >
                  <Typography variant="h6" style={{ paddingTop: "25px" }}>
                    Upload Document
                  </Typography>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {jdPath ? (
                        <>
                          <div>
                            <p style={{ fontSize: "14px" }}>
                              Uploaded Document:{" "}
                              {uploadedDocuments?.name
                                ? uploadedDocuments?.name
                                : ""}
                            </p>
                          </div>

                          <Tooltip title=" Click here to see Uploaded Document!">
                            <IconButton
                              style={{ border: "1px solid " }}
                              color="primary"
                              onClick={() => {
                                if (jdPath) {
                                  let endPoint = new URL(jdPath);
                                  let code = endPoint.pathname.split("/");
                                  let endUrl = `${BackendBaseApi.SANCHALAN}api/file/download/${code[2]}?download=true`;
                                  window.open(endUrl, "_blank").focus();
                                } else {
                                  alert.error("Please upload file");
                                }
                              }}
                            >
                              <DescriptionIcon />
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            style={{ border: "1px solid " }}
                            color="secondary"
                            onClick={() => {
                              setUploadedDocuments(null);
                              setJdPath("");
                              setJobDetails(() => ({
                                ...jobDetails,
                                document_for_jd: null,
                              }));
                            }}
                          >
                            <DeleteForeverSharp />
                          </IconButton>
                        </>
                      ) : null}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        component="label"
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          height: 40,
                          width: "180px",
                          boxShadow: "none",
                        }}
                      >
                        {uploadLoading ? (
                          <CustomButtonCircularProgress />
                        ) : (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <AddCircleOutlineTwoTone
                                color="white"
                                style={{ marginRight: "10px" }}
                              />{" "}
                              Add Document
                            </div>
                          </>
                        )}
                        <input
                          type="file"
                          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                          hidden
                          onChange={(e) => {
                            setUploadedDocuments(e.target.files[0]);
                            getDocumentLink(e.target.files[0]);
                          }}
                        />
                      </Button>
                    </div>
                  </Grid>
                </Paper>
              </div>
              {jobDetailsError?.document_for_jd && (
                <Typography variant="h6" className={classes.plz}>
                  Please upload document
                </Typography>
              )}
            </Grid>
            <br />
          </>
        );

      case "ctc_range":
        return (
          <>
            <Typography
              variant="h6"
              className={classes.ctc}
              // style={
              //     {
              //       marginTop : '4rem' ,
              //      '@media (max-width: 270px)': {
              //        marginTop : '5rem'
              //      },

              //     }
              //     }
            >
              {jobDetails?.job_type_id === 7
                ? "CTC Range (Per Annum)"
                : "Stipend Range (Monthly)"}
            </Typography>

            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Gender</FormLabel> */}

              <RadioGroup
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={jobDetails?.salary_type}
                onChange={(e) => {
                  setJobDetails({ ...jobDetails, salary_type: e.target.value });
                }}
              >
                <FormControlLabel
                  value="PAID"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Paid</Typography>}
                />
                <FormControlLabel
                  value="UNPAID"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Unpaid</Typography>}
                />
                <FormControlLabel
                  value="NOT DISCLOSED"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Not Disclosed</Typography>}
                />
              </RadioGroup>
            </FormControl>

            {jobDetails?.salary_type === "PAID" && (
              <>
                <div style={{ display: "flex" }}>
                  <TextField
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: { min: 0 },
                    }}
                    // InputLabelProps={{  }}
                    label="Minimum"
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    name="Minimum"
                    value={jobDetails?.ctc_min}
                    onChange={(e) => {
                      setJobDetails({
                        ...jobDetails,
                        ctc_min: Number(e.target.value),
                      });
                      setJobDetailsError({ ...jobDetailsError, ctc_min: null });
                    }}
                    error={jobDetailsError.ctc_min ? true : false}
                    helperText={<span>{jobDetailsError.ctc_min}</span>}
                  />

                  <TextField
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: { min: 0 },
                    }}
                    style={{ marginLeft: "20px" }}
                    // InputLabelProps={{  }}
                    label="Maximum"
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    name="Minimum"
                    value={jobDetails?.ctc_max}
                    onChange={(e) => {
                      setJobDetails({
                        ...jobDetails,
                        ctc_max: Number(e.target.value),
                      });
                      setJobDetailsError({ ...jobDetailsError, ctc_max: null });
                    }}
                    error={jobDetailsError.ctc_max ? true : false}
                    helperText={<span>{jobDetailsError.ctc_max}</span>}
                  />
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jobDetails?.bonus}
                      onChange={(e) =>
                        setJobDetails({
                          ...jobDetails,
                          bonus: e.target.checked,
                        })
                      }
                      name="checkedB"
                      size="small"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Including Bonuses
                    </Typography>
                  }
                />
              </>
            )}
          </>
        );

      case "editor":
        return (
          <>
            {item.key === "about_company" && (
              <>
                <Typography
                  variant="h6"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  {item?.field_name}
                </Typography>
                <div className={classes.salaryBreakup}>
                  <Editor
                    state={salaryBreakup}
                    setState={setSalaryBreakup}
                    desc={jobDetails?.salary_breakup}
                  />
                </div>
              </>
            )}
          </>
        );
      case "location":
        return (
          <>
            <br />
            {jobDetails?.pan_india ? null : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={jobDetails?.wfh}
                    onChange={(e) => {
                      setJobDetails({ ...jobDetails, wfh: e.target.checked });
                    }}
                    name="checkedB"
                    size="small"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Work From Home Available
                  </Typography>
                }
              />
            )}
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={jobDetails?.pan_india}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      pan_india: e.target.checked,
                      wfh: false,
                    });
                  }}
                  name="checkedB"
                  size="small"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  PAN India
                </Typography>
              }
            />
            {/* <FormControl component="fieldset" style={{ marginTop: "10px" }}>
              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel
                  value="Pan"
                  control={
                    <Radio
                      color="primary"
                      size="small"
                      checked={jobDetails?.pan_india}
                      onChange={(e) => {
                        setJobDetails({ ...jobDetails, pan_india: e.target.checked });
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      PAN India
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl> */}
            <br />

            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "7px",
              }}
            >
              <Grid item xs={12}>
                {" "}
                <Autocomplete
                  id="country-select-demo"
                  options={cityList}
                  getOptionLabel={(option) => option.city}
                  disabled={jobDetails?.pan_india}
                  value={currentJobLocation}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      var arr = [...jobDetails?.job_location];
                      arr.push(newValue);
                      setJobDetails({
                        ...jobDetails,
                        job_location: uniqueArrayWithoutId(arr, "city_id"),
                      });
                      setCurrentJobLocation(newValue);
                      setJobDetailsError({
                        ...jobDetailsError,
                        job_location: null,
                      });
                    } else {
                      setJobDetails({ ...jobDetails });
                      setCurrentJobLocation(null);

                      setJobDetailsError({
                        ...jobDetailsError,
                        job_location: null,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Job Location"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={jobDetailsError.job_location ? true : false}
                      helperText={<span>{jobDetailsError.job_location}</span>}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: "10px" }}>
              {jobDetails?.job_location?.length > 0 &&
                jobDetails?.job_location?.map((item, index) => (
                  <>
                    {" "}
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="small"
                      onDelete={() =>
                        handleDeleteFunc(
                          index,
                          jobDetails?.job_location,
                          "job_location"
                        )
                      }
                      deleteIcon={<CloseIcon />}
                      label={item.city}
                      className={classes.chip}
                    />
                  </>
                ))}
            </div>
          </>
        );
      case "vacancy":
        return (
          <>
            <TextField
              variant="outlined"
              required
              margin="normal"
              type="number"
              onWheel={() => document.activeElement.blur()}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 1 },
                style: {
                  padding: "16px",
                },
              }}
              className={classes.numberof}
              label="Number of Vacancies"
              name="Number of Vacancies"
              value={jobDetails?.vacancy}
              onChange={(e) => {
                if (parseInt(e.target.value) !== 0) {
                  setJobDetails({
                    ...jobDetails,
                    vacancy: parseInt(e.target.value),
                  });
                  setJobDetailsError({ ...jobDetailsError, vacancy: null });
                }
              }}
              error={jobDetailsError.vacancy ? true : false}
              helperText={<span>{jobDetailsError.vacancy}</span>}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={jobDetails?.service_bond}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      service_bond: e.target.checked,
                    });
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Include Service Agreement/ Bond
                </Typography>
              }
            />
            {jobDetails?.service_bond && (
              <>
                <Typography
                  variant="h6"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Conditions Text
                </Typography>
                <Editor state={editor} setState={setEditor} />
              </>
            )}
          </>
        );

      case "duration":
        return (
          <>
            {jobDetails?.job_type_id === 1 && (
              <TextField
                variant="outlined"
                required
                margin="normal"
                type="number"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1 },
                  style: {
                    padding: "16px",
                  },
                }}
                className={classes.numberof}
                label="Internship Duration(in months)"
                name="Duration(in months)"
                value={jobDetails?.internship_duration}
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    internship_duration: parseInt(e.target.value),
                  });
                  setJobDetailsError({
                    ...jobDetailsError,
                    internship_duration: null,
                  });
                }}
                error={jobDetailsError.internship_duration ? true : false}
                helperText={<span>{jobDetailsError.vacancy}</span>}
              />
            )}
          </>
        );

      case "ppo":
        return (
          <>
            <br />
            {jobDetails?.job_type_id === 1 && (
              <>
                <FormControlLabel
                  style={{ marginTop: "10px" }}
                  control={
                    <Checkbox
                      checked={jobDetails?.ppo}
                      onChange={(e) => {
                        setJobDetails({ ...jobDetails, ppo: e.target.checked });
                      }}
                      name="checkedB"
                      size="small"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      PPO After Internship
                    </Typography>
                  }
                />
                {jobDetails?.ppo && (
                  <>
                    <Typography variant="h6" style={{ marginTop: "10px" }}>
                      CTC Range (Per Annum)
                    </Typography>

                    <FormControl component="fieldset">
                      {/* <FormLabel component="legend">Gender</FormLabel> */}

                      <RadioGroup
                        aria-label="gender"
                        name="customized-radios"
                        className={classes.customize}
                        value={jobDetails?.ppo_salary_type}
                        onChange={(e) => {
                          setJobDetails({
                            ...jobDetails,
                            ppo_salary_type: e.target.value,
                          });
                        }}
                      >
                        {/* <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System Graduation*
                </Typography> */}
                        <FormControlLabel
                          value="PAID"
                          control={<StyledRadio />}
                          label={<Typography variant="body2">Paid</Typography>}
                        />
                        <FormControlLabel
                          value="NOT DISCLOSED"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">
                              Not Disclosed
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    {jobDetails?.ppo_salary_type === "PAID" && (
                      <>
                        <div style={{ display: "flex" }}>
                          <TextField
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                              shrink: true,
                            }}
                            variant="outlined"
                            margin="normal"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              inputProps: { min: 0 },
                            }}
                            // InputLabelProps={{  }}
                            label="Minimum"
                            inputProps={{
                              style: {
                                padding: "16px",
                              },
                            }}
                            name="Minimum"
                            value={jobDetails?.ppo_ctc_min}
                            onChange={(e) => {
                              setJobDetails({
                                ...jobDetails,
                                ppo_ctc_min: Number(e.target.value),
                              });
                              setJobDetailsError({
                                ...jobDetailsError,
                                ppo_ctc_min: null,
                              });
                            }}
                            error={jobDetailsError.ppo_ctc_min ? true : false}
                            helperText={
                              <span>{jobDetailsError.ppo_ctc_min}</span>
                            }
                          />

                          <TextField
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                              shrink: true,
                            }}
                            variant="outlined"
                            margin="normal"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              inputProps: { min: 0 },
                            }}
                            // InputLabelProps={{  }}
                            label="Maximum"
                            inputProps={{
                              style: {
                                padding: "16px",
                              },
                            }}
                            style={{ marginLeft: 20 }}
                            name="Maximum"
                            value={jobDetails?.ppo_ctc_max}
                            onChange={(e) => {
                              setJobDetails({
                                ...jobDetails,
                                ppo_ctc_max: Number(e.target.value),
                              });
                              setJobDetailsError({
                                ...jobDetailsError,
                                ppo_ctc_max: null,
                              });
                            }}
                            error={jobDetailsError.ppo_ctc_max ? true : false}
                            helperText={
                              <span>{jobDetailsError.ppo_ctc_max}</span>
                            }
                          />
                        </div>
                        <Typography
                          variant="h6"
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          Salary Breakup after PPO
                        </Typography>
                        <Editor
                          state={ppoSalaryBreakup}
                          setState={setPpoSalaryBreakup}
                          desc={jobDetails?.salary_breakup_ppo}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {/* <br /> */}
          </>
        );
      case "courses":
        return (
          <>
            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.eligibleCriteria}>
                  Eligibility Criteria for the Job
                </Typography>
                {coursesArray?.length && (
                  <>
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <DegreeSpecializationCustom
                        data={dataSelected}
                        setData={setDataSelected}
                        specialization={specializationInDegreeId}
                        setSpecialization={setSpecializationInDgegreeId}
                        coursesArray={coursesArray}
                        pushArray={pushArray}
                        setPushArray={setPushArray}
                        errorMessage={null}
                        loading={loadingCourses}
                        dataSelectedNewFormat={dataSelectedNewFormat}
                        setDataSelectedNewFormat={setDataSelectedNewFormat}
                      />
                    </div>
                    <ChipCourses
                      data={dataSelected}
                      setData={setDataSelected}
                      specialization={specializationInDegreeId}
                      setSpecialization={setSpecializationInDgegreeId}
                      coursesArray={coursesArray}
                      setCoursesArray={setCoursesArray}
                      pushArray={pushArray}
                      qualifications={qualifications}
                      setQualifications={setQualifications}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </>
        );

      case "skills":
        return (
          <>
            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "0px",
              }}
            >
              <Grid item xs={12}>
                {" "}
                <Autocomplete
                  id="country-select-demo"
                  options={skillList}
                  getOptionLabel={(option) => option.name}
                  value={currentSkills}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      var arr = [...jobDetails?.skills];
                      arr.push(newValue);
                      setJobDetails({
                        ...jobDetails,
                        skills: uniqueArrayWithId(arr, "id"),
                      });
                      setCurrentSkills(newValue);
                      setJobDetailsError({
                        ...jobDetailsError,
                        skills: null,
                      });
                    } else {
                      setCurrentSkills(null);
                    }
                    setCurrentSkills(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Skills Required"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={jobDetailsError.skills ? true : false}
                      helperText={<span>{jobDetailsError.skills}</span>}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={3} className={classes.btngrid}>
                    <Button variant="contained" className={classes.addbtn} color="primary">
                      Add Location
                    </Button>
                  </Grid> */}
            </Grid>
            <div style={{ marginTop: "10px" }}>
              {jobDetails?.skills?.length > 0 &&
                jobDetails?.skills?.map((item, index) => (
                  <>
                    {" "}
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="small"
                      onDelete={() =>
                        handleDeleteFunc(index, jobDetails?.skills, "skills")
                      }
                      deleteIcon={<CloseIcon />}
                      label={item.name}
                      className={classes.chip}
                    />
                  </>
                ))}
            </div>
          </>
        );
      case "graduation_grade":
        return (
          <>
            {/* <br /> */}
            <Typography
              variant="h6"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Grades Criteria
            </Typography>

            {/* <FormControl component="fieldset">

              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={jobDetails?.grad_scoring}
                onChange={(e) => {
                  setJobDetails({ ...jobDetails, grad_scoring: e.target.value });
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System Graduation*
                </Typography>
                <FormControlLabel value="CGPA" control={<StyledRadio />} label={<Typography variant="body2">CGPA</Typography>} />
                <FormControlLabel value="Percentage" control={<StyledRadio />} label={<Typography variant="body2">Percentage</Typography>} />
                <FormControlLabel value="GPA" control={<StyledRadio />} label={<Typography variant="body2">GPA out of 4</Typography>} />
              </RadioGroup>
            </FormControl>
            <br /> */}
            <TextField
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              type="number"
              className={classes.mini}
              onWheel={() => document.activeElement.blur()}
              //  style={{ width: "50%", marginBottom: "8px", marginTop: "8px" }}
              InputProps={{
                inputProps: { min: 0, max: 100 },
                style: {
                  padding: "16px",
                },
              }}
              label={`Minimum ${
                isGradeSystem ? "CGPA" : "Percentage"
              } Required (Current Education)`}
              value={jobDetails?.grad_min_score}
              onChange={(e) => {
                if (parseInt(e.target.value) !== 0) {
                  setJobDetails({
                    ...jobDetails,
                    grad_min_score: parseFloat(e.target.value),
                  });
                  setJobDetailsError({
                    ...jobDetailsError,
                    grad_min_score: null,
                  });
                }
              }}
              error={jobDetailsError.grad_min_score ? true : false}
              helperText={<span>{jobDetailsError.grad_min_score}</span>}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={jobDetails?.backlog}
                  onChange={(e) => {
                    setJobDetails({ ...jobDetails, backlog: e.target.checked });
                  }}
                  name="checkedB"
                  size="small"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Back Papers Allowed*
                </Typography>
              }
            />
          </>
        );
      case "school_grade":
        return (
          <>
            {/* <br />
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={jobDetails?.senior_sec_scoring}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    senior_sec_scoring: e.target.value,
                  });
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System 12th Grade*
                </Typography>
                <FormControlLabel
                  value="CGPA"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">CGPA</Typography>}
                />
                <FormControlLabel
                  value="Percentage"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Percentage</Typography>}
                />
                </RadioGroup>
            </FormControl>
            <br /> */}
            <br />
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              InputProps={{
                inputProps: { min: 0, max: 100 },
                style: {
                  padding: "16px",
                },
              }}
              variant="outlined"
              margin="normal"
              className={classes.mini}
              onWheel={() => document.activeElement.blur()}
              // style={{ width: "50%" }}
              type="number"
              label="Minimum Score Required in 12th Grade(Percentage)"
              value={jobDetails?.senior_sec_min_score}
              onChange={(e) => {
                if (parseInt(e.target.value) !== 0) {
                  setJobDetails({
                    ...jobDetails,
                    senior_sec_min_score: parseFloat(e.target.value),
                  });
                  setJobDetailsError({
                    ...jobDetailsError,
                    senior_sec_min_score: null,
                  });
                }
              }}
              error={jobDetailsError.senior_sec_min_score ? true : false}
              helperText={<span>{jobDetailsError.senior_sec_min_score}</span>}
            />
            <br />
            {/* <FormControl component="fieldset" style={{ marginTop: "10px" }}>
              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={jobDetails?.sec_scoring}
                onChange={(e) => {
                  setJobDetails({ ...jobDetails, sec_scoring: e.target.value });
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System 10th Grade*
                </Typography>
                <FormControlLabel
                  value="CGPA"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">CGPA</Typography>}
                />
                <FormControlLabel
                  value="Percentage"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Percentage</Typography>}
                />
                </RadioGroup>
            </FormControl>
             */}
            <br />

            <TextField
              variant="outlined"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              margin="normal"
              // style={{ width: "50%" }}
              className={classes.mini}
              type="number"
              onWheel={() => document.activeElement.blur()}
              InputProps={{
                inputProps: { min: 0, max: 4 },
                style: {
                  padding: "16px",
                },
              }}
              label="Minimum Score Required in 10th Grade(Percentage)"
              value={jobDetails?.sec_min_score}
              onChange={(e) => {
                if (parseInt(e.target.value) !== 0) {
                  setJobDetails({
                    ...jobDetails,
                    sec_min_score: parseFloat(e.target.value),
                  });
                  setJobDetailsError({
                    ...jobDetailsError,
                    sec_min_score: null,
                  });
                }
              }}
              error={jobDetailsError.sec_min_score ? true : false}
              helperText={<span>{jobDetailsError.sec_min_score}</span>}
            />
          </>
        );

      case "batch":
        return (
          <>
            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  id="country-select-demo"
                  options={years_list}
                  getOptionLabel={(option) => String(option.name)}
                  value={currentbatch}
                  onChange={(e, newValue) => {
                    setCurrentBatch(newValue);
                    var arr = [...jobDetails.eligible_batches];
                    arr.push(newValue.value);
                    setJobDetails({
                      ...jobDetails,
                      eligible_batches: uniqueArrayWithoutId(arr),
                    });

                    setJobDetailsError({
                      ...jobDetailsError,
                      eligible_batches: null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Eligible Batches"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={jobDetailsError.eligible_batches ? true : false}
                      helperText={
                        <span>{jobDetailsError.eligible_batches}</span>
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div style={{ padding: "1px" }}>
              {jobDetails?.eligible_batches?.length > 0 &&
                jobDetails?.eligible_batches?.map((item, index) => (
                  <>
                    {" "}
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="small"
                      onDelete={() =>
                        handleDeleteFunc(
                          index,
                          jobDetails?.eligible_batches,
                          "eligible_batches"
                        )
                      }
                      deleteIcon={<CloseIcon />}
                      label={item}
                      className={classes.chip}
                    />
                  </>
                ))}
            </div>
          </>
        );
      case "selection":
        return (
          <>
            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "5px",
              }}
            >
              <Grid item xs={12}>
                {" "}
                {currentRound?.name?.toLowerCase()?.includes("others") ? (
                  <>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      margin="normal"
                      style={{ width: "100%" }}
                      type="text"
                      InputProps={{
                        // inputProps: jobDetails?.sec_scoring === "Percentage" ? { min: 0, max: 100 } : jobDetails?.sec_scoring === "CGPA" ? { min: 0, max: 10 } : { min: 0, max: 4 },
                        style: {
                          padding: "16px",
                        },
                      }}
                      label="Please enter your Round Name"
                      value={typedRoundName}
                      onChange={(e) => {
                        setTypedRoundName(e.target.value);
                        setJobDetailsError({
                          ...jobDetailsError,
                          rounds: null,
                        });
                      }}
                      error={jobDetailsError.rounds ? true : false}
                      helperText={<span>{jobDetailsError.rounds}</span>}
                    />

                    <Button
                      color="primary"
                      style={{ display: "flex", marginTop: -5, marginLeft: 10 }}
                      onClick={() => {
                        setCurrentRound(null);
                      }}
                    >
                      Click here to select Round from Dropdown
                    </Button>
                  </>
                ) : (
                  <Autocomplete
                    id="country-select-demo"
                    options={jobRoundList}
                    getOptionLabel={(option) => option.name}
                    value={currentRound}
                    onChange={(e, newValue) => {
                      setCurrentRound(newValue);

                      setJobDetailsError({ ...jobDetailsError, rounds: null });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Job Round"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        error={jobDetailsError.rounds ? true : false}
                        helperText={<span>{jobDetailsError.rounds}</span>}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>

            <TextField
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
                shrink: true,
              }}
              // InputProps={{
              //   inputComponent: NumberFormatCustom,
              // }}
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              label="Duration of the Round "
              name="Duration of the Round"
              value={currentDuration}
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => {
                setCurrentDuration(e.target.value);
                setJobDetailsError({
                  ...jobDetailsError,
                  round_duration: null,
                });
              }}
              className={classes.duration}
              // style={{ width: "25%" }}
              type="number"
              error={jobDetailsError.round_duration ? true : false}
              helperText={<span>{jobDetailsError.round_duration}</span>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">mins</InputAdornment>
                ),
              }}
              // InputLabelProps={{  }}
            />
            <br />
            <FormControl component="fieldset" style={{ marginTop: "10px" }}>
              {/* <FormLabel component="legend">Gender</FormLabel> */}

              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={venueType}
                onChange={(e) => {
                  setVenueType(e.target.value);
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Venue Type:
                </Typography>
                <FormControlLabel
                  value="ONLINE"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Online</Typography>}
                />
                <FormControlLabel
                  value="OFFLINE"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Offline</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <br />
            <TextField
              variant="outlined"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              margin="normal"
              fullWidth
              InputProps={{ classes: { root: classes.inputRoot } }}
              label={
                venueType === "ONLINE"
                  ? "Link for the Meeting"
                  : "Address for the Meeting"
              }
              value={venue}
              onChange={(e) => {
                setVenue(e.target.value);
                setJobDetailsError({ ...jobDetailsError, venue: null });
              }}
              error={jobDetailsError.venue ? true : false}
              helperText={<span>{jobDetailsError.venue}</span>}
            />
            <br />
            <Grid item xs={6} sm={6} md={3} className={classes.btngrid}>
              <Button
                variant="contained"
                className={classes.addbtn}
                color="primary"
                onClick={() => {
                  addRounds();
                }}
                style={{
                  borderRadius: 8,
                  marginTop: "10px",
                  boxShadow: "none",
                }}
              >
                Add Round
              </Button>
            </Grid>
            <br />

            {jobDetails?.rounds?.length > 0 && (
              <>
                <ArrowCards
                  data={roundList}
                  fromPostJob={true}
                  handleDeleteRounds={handleDeleteRounds}
                />
                {jobDetails?.rounds?.length > 4 && (
                  <div style={{ marginTop: "20px" }}>
                    <Button
                      color="primary"
                      style={{ display: "flex" }}
                      endIcon={
                        !showRoundList ? (
                          <KeyboardArrowDownSharp />
                        ) : (
                          <KeyboardArrowUpSharp />
                        )
                      }
                      onClick={() => {
                        setShowRoundList(!showRoundList);
                      }}
                    >
                      {showRoundList ? "Hide" : "View All Job Rounds"}
                    </Button>
                  </div>
                )}

                <br />
              </>
            )}

            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Documents Required
            </Typography>

            <div
              style={{
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingRight: "10px",
              }}
            >
              {documents?.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={jobDetails?.documents?.includes(item)}
                      onChange={UpdateDocuments}
                      name={item}
                      color="primary"
                    />
                  }
                  label={<Typography variant="body2">{item}</Typography>}
                />
              ))}
            </div>
          </>
        );
    }
  }

  return (
    <>
      {data.map((item2, index2) => (
        <>{getFieldContent(item2.field_type, item2, index2)}</>
      ))}
    </>
  );
}
