import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  JobFieldsPostAPI,
  JobSelectedFieldsAPI,
  JobSelectedLocalStateFieldsAPI,
  JobUploaderFieldsAPI,
} from "../../api/JobManagementForm/action";
import { collegeIdFn, userRolesActionsFn } from "../../api/SignIn/selector";
import MaangeJob from "../../components/Cards/ManageJob/index2.js";
import CustomCircularProgress from "../../components/CircularProgressBar";
import JobPosting from "./JobPosting";

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    marginTop: "30px",
    height: "48px",
    marginBottom: "30px",
  },
  btnSelected: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    marginTop: "30px",
    height: "48px",
    marginBottom: "30px",
    color: "#6c757d",
    borderColor: "#6c757d",
    // backgroundColor: theme.palette.primary.hover,
    "&:hover": {
      //backgroundColor: theme.palette.primary.hover,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  btnSelectedprimary: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    marginTop: "30px",
    marginBottom: "30px",
    height: "48px",
    "&:hover": {
      //backgroundColor: theme.palette.primary.hover,
    },
  },
  sliderbutton: {
    border: "none",
    backgroundColor: "transparent",
    padding: "0",
  },
  arrow: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "40px",
    cursor: "pointer",
    height: "40px",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function ManageJobPosting() {
  const classes = useStyles();
  const [localFieldList, setLocalFieldList] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSelected, setLoadingSelected] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const params = useParams();
  const job_type = params?.job_type;
  const [step, setStep] = useState(job_type ? 1 : 0);
  const [fullTimeJobs, setFullTimeJobs] = useState([]);
  const [internships, setInternships] = useState([]);
  // const [jobTypeSelected, setJobTypeSelected] = useState(null);
  React.useEffect(() => {
    if (collegeId) {
      dispatch(
        JobUploaderFieldsAPI(
          setLoadingData,
          alert,
          history,
          location,
          collegeId,
          job_type
        )
      );
      dispatch(
        JobSelectedFieldsAPI(
          setLoadingSelected,
          alert,
          history,
          location,
          collegeId,
          job_type
        )
      );
      dispatch(
        JobSelectedLocalStateFieldsAPI(
          alert,
          history,
          location,
          collegeId,
          0,
          setFullTimeJobs,
          setInternships
        )
      );
      dispatch(
        JobSelectedLocalStateFieldsAPI(
          alert,
          history,
          location,
          collegeId,
          1,
          setFullTimeJobs,
          setInternships
        )
      );
    }
  }, [collegeId]);

  React.useEffect(() => {
    if (collegeId && job_type) {
      dispatch(
        JobUploaderFieldsAPI(
          setLoadingData,
          alert,
          history,
          location,
          collegeId,
          job_type
        )
      );
      dispatch(
        JobSelectedFieldsAPI(
          setLoadingSelected,
          alert,
          history,
          location,
          collegeId,
          job_type
        )
      );
    }
  }, [job_type]);

  const fieldList = useSelector((state) => state?.JobUploader?.details);

  const handleSubmit = () => {
    var arr = [];
    localFieldList.map((item) => {
      if (item.is_selected) arr.push(item.id);
    });
    var data = {
      college_id: [collegeId],
      is_internship: job_type && job_type === "internship" ? 1 : 0,
      job_form_fields: arr,
    };
    if (arr.length > 0)
      dispatch(
        JobFieldsPostAPI(data, setLoading, alert, history, location, collegeId)
      );
    else alert.error("Please Select at least one Checkbox");
  };

  const handleFunction = (key) => {
    if (key === "full-time") {
      setStep(1);
      history.push("/job-posting-form/full-time-job");
    } else {
      setStep(1);
      history.push("/job-posting-form/internship");
    }
  };
  return (
    <>
      {/* <Layout> */}
      {loadingData ? (
        <CustomCircularProgress />
      ) : (
        <>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Typography variant="h2">
              Manage {job_type?.includes("full-time") ? "Job" : "Internship"}{" "}
              Posting Form
            </Typography>
            <Typography
              variant="body2"
              color="secondary"
              style={{
                // marginTop: "7px",
                marginBottom: "7px",
              }}
            >
              Create Custom Data Set for{" "}
              {job_type?.includes("full-time") ? "Job" : "Internship"} Posting
            </Typography>
          </div>

          {step === 1 ? (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  variant={
                    !job_type || job_type?.includes("full-time")
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  style={{ minWidth: "270px", marginRight: 30 }}
                  className={
                    !job_type || job_type?.includes("full-time")
                      ? classes.btn
                      : classes.btnSelected
                  }
                  onClick={() =>
                    history.push("/job-posting-form/full-time-job")
                  }
                >
                  {"Full Time Job"}
                </Button>

                <Button
                  variant={
                    job_type && job_type?.includes("internship")
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  style={{ minWidth: "270px" }}
                  className={
                    job_type && job_type?.includes("internship")
                      ? classes.btn
                      : classes.btnSelected
                  }
                  onClick={() => history.push("/job-posting-form/internship")}
                >
                  {"Internship"}
                </Button>
              </div>
              <MaangeJob
                localFieldList={localFieldList}
                setLocalFieldList={setLocalFieldList}
                userRolesPermission={userRolesPermission}
                loadingData={loadingData}
                fieldList={fieldList}
                job_type={job_type}
              />
              {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_forms"
              ) && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "270px" }}
                  className={classes.btn}
                  onClick={() => handleSubmit()}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.circularProgress}
                      size={14}
                    />
                  ) : (
                    `Create ${
                      job_type?.includes("full-time") ? "Job" : "Internship"
                    } Posting Form`
                  )}
                </Button>
              )}
            </>
          ) : (
            <>
              <JobPosting
                action={handleFunction}
                fullTimeJobs={fullTimeJobs}
                internships={internships}
              />
            </>
          )}
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
