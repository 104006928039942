import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlacementListAPI } from "../../api/Common/action";
import { collegeIdFn, userRolesActionsFn } from "../../api/SignIn/selector";
import {
  DESIGNATION_TEAM_LIST,
  DesignationTeamGetAPI,
  InviteLinkGetAPI,
  NavbarTeamGetAPI,
  TEAMS_DATA,
  TeamsGetAPI1,
} from "../../api/TeamManage/action";
import CustomCircularProgress from "../../components/CircularProgressBar";
import ManageJobTab from "../../components/Header/ManageJobTab";
import ManageTeamHeader from "../../components/Header/ManageTeamHeader";
import DropDownButton from "../../components/Input/DropDownButton";
import NoDataFuncNew from "../../components/NoDataSVG/index2";
import { generateCsv } from "../../utility/commaSeparatedStrings";
import axios from "axios";

function ManageTeam() {
  const [filters, setFilters] = useState({
    search: null,
    course: null,
    batch: null,
    status_active: true,
    status_blocked: true,
    designation: null,
    current_tab: null,
  });
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [firstApiCall, setFirstApiCall] = useState(false);
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);
  const [loadingUserRoles, setLoadingUserRoles] = useState(false);
  const [userRoleId, setUserRoleId] = useState();
  const [apiCalled, setApiCalled] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const [callNavbar, setCallNavbar] = useState(false);
  const teamList1 = useSelector((state) => state?.TeamData?.details);
  const [teamList, setTeamList] = useState(teamList1);

  const checkValue = () => {
    if (!userRoleId) return ``;
    else if (checkForPendingRejected())
      return `&key=${filters?.current_tab?.key}`;
    else return `&user_role_id=${userRoleId}`;
  };

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.key === "pending_team" ||
      filters?.current_tab?.key === "rejected_team"
    );
  };
  const searchFilter = () => {
    if (filters.search) return `&search=${filters.search}`;
    else return ``;
  };
  const departmentFilter = () => {
    if (filters.designation)
      return `&designation=${filters.designation.name.toUpperCase()}`;
    else return ``;
  };
  const batchFilter = () => {
    if (filters.batch) return `&batch=${filters.batch.value}`;
    else return ``;
  };
  const statusFilter = () => {
    if (filters.status_active && filters.status_blocked) return ``;
    else if (filters.status_active) return `&status=ACTIVE`;
    else if (filters.status_blocked) return `&status=INACTIVE`;
    else return ``;
  };

  // const checkPlacementSesion = () => {
  //   if (placementSession) return `&placement_session=${placementSession.id}`;
  //   else return ``;
  // };

  const checkPlacementSesion = () => {
    if (placementSession && placementSession.id !== "All")
      return `&placement_session=${placementSession.id}`;
    else return ``;
  };

  const callbackfn = (res) => {
    res?.data?.data?.designation_list?.forEach((item, index) => {
      item["id"] = index + 1;
    });
    setTeamList(res?.data?.data?.data);
    dispatch({
      type: TEAMS_DATA,
      payload: res?.data?.data?.data,
    });
    dispatch({
      type: DESIGNATION_TEAM_LIST,
      payload: res?.data?.data?.designation_list,
    });
  };

  useEffect(() => {
    dispatch(InviteLinkGetAPI(alert, history, location));
  }, [collegeId]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    if (callNavbar) {
      var url_filter = `api/manage_team/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
      dispatch(
        NavbarTeamGetAPI(
          setLoadingUserRoles,
          alert,
          history,
          location,
          url_filter,
          source
        )
      );
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [callNavbar]);

  const lastRequestRef = useRef(0);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    const requestTime = Date.now();
    lastRequestRef.current = requestTime;

    if (userRoleId) {
      const url = `api/manage_team/view/?${checkValue()}${checkPlacementSesion()}`;
      dispatch(
        TeamsGetAPI1(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setTeamList,
          source
        )
      ).then((response) => {
        if (lastRequestRef.current === requestTime) {
          // setTeamList(response?.data?.data?.data);
          callbackfn(response);
        }
      });

      const url_filter = `api/manage_team/navbar/?${checkValue()}${checkPlacementSesion()}`;
      dispatch(
        NavbarTeamGetAPI(
          setLoadingUserRoles,
          alert,
          history,
          location,
          url_filter,
          source
        )
      );
    }

    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [userRoleId]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    const requestTime = Date.now();
    lastRequestRef.current = requestTime;

    if (placementSession) {
      const url = `api/manage_team/view/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
      dispatch(
        TeamsGetAPI1(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setTeamList,
          source
        )
      ).then((response) => {
        if (lastRequestRef.current === requestTime) {
          // setTeamList(response?.data?.data?.data);
          callbackfn(response);
        }
      });

      const url_filter = `api/manage_team/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
      dispatch(
        NavbarTeamGetAPI(
          setLoadingUserRoles,
          alert,
          history,
          location,
          url_filter,
          source
        )
      );
    }

    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [placementSession]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();

  //   if (userRoleId) {
  //     console.log("triggg 1");
  //     var url = `api/manage_team/view/?${checkValue()}${checkPlacementSesion()}`;
  //     dispatch(
  //       TeamsGetAPI1(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setClickSearchButton,
  //         setFirstApiCall,
  //         setTeamList,
  //         source
  //       )
  //     );
  //     var url_filter = `api/manage_team/navbar/?${checkValue()}${checkPlacementSesion()}`;
  //     dispatch(
  //       NavbarTeamGetAPI(
  //         setLoadingUserRoles,
  //         alert,
  //         history,
  //         location,
  //         url_filter,
  //         source
  //       )
  //     );
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //   };
  // }, [userRoleId]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    const requestTime = Date.now();
    lastRequestRef.current = requestTime;

    if (
      filters.status_blocked ||
      filters.status_active ||
      filters.designation
    ) {
      var url = `api/manage_team/view/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
      dispatch(
        TeamsGetAPI1(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setTeamList,
          source
        )
      ).then((response) => {
        if (lastRequestRef.current === requestTime) {
          // setTeamList(response?.data?.data?.data);
          callbackfn(response);
        }
      });
      var url_filter = `api/manage_team/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
      dispatch(
        NavbarTeamGetAPI(
          setLoadingUserRoles,
          alert,
          history,
          location,
          url_filter,
          source
        )
      );
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [filters.status_blocked, filters.status_active, filters.designation]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    const requestTime = Date.now();
    lastRequestRef.current = requestTime;

    if (clickSearchButton) {
      var url = `api/manage_team/view/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
      dispatch(
        TeamsGetAPI1(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setTeamList,
          source
        )
      ).then((response) => {
        if (lastRequestRef.current === requestTime) {
          callbackfn(response);
        }
      });
      var url_filter = `api/manage_team/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
      dispatch(
        NavbarTeamGetAPI(
          setLoadingUserRoles,
          alert,
          history,
          location,
          url_filter,
          source
        )
      );
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [clickSearchButton]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();

  //   if (placementSession) {
  //     console.log("triggg 2", placementSession);

  //     var url = `api/manage_team/view/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
  //     dispatch(
  //       TeamsGetAPI1(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setClickSearchButton,
  //         setFirstApiCall,
  //         setTeamList,
  //         source
  //       )
  //     );
  //     var url_filter = `api/manage_team/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
  //     dispatch(
  //       NavbarTeamGetAPI(
  //         setLoadingUserRoles,
  //         alert,
  //         history,
  //         location,
  //         url_filter,
  //         source
  //       )
  //     );
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //   };
  // }, [placementSession]);

  const [loadingPlacement, setLoadingPlacement] = useState(false);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if (collegeId) {
      dispatch(
        PlacementListAPI(
          alert,
          history,
          location,
          setLoadingPlacement,
          collegeId,
          source
        )
      );
      dispatch(DesignationTeamGetAPI(alert, history, location, source));
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [collegeId]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    const requestTime = Date.now();
    lastRequestRef.current = requestTime;

    if (apiCalled) {
      var url = `api/manage_team/view/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;
      dispatch(
        TeamsGetAPI1(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setTeamList,
          source
        )
      ).then((response) => {
        if (lastRequestRef.current === requestTime) {
          // setTeamList(response?.data?.data?.data);
          callbackfn(response);
        }
      });
      var url_filter = `api/manage_team/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${checkPlacementSesion()}`;

      dispatch(
        NavbarTeamGetAPI(
          setLoadingUserRoles,
          alert,
          history,
          location,
          url_filter,
          source
        )
      );
      setApiCalled(false);
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [apiCalled]);

  useEffect(() => {}, []);
  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );
  const userRoleList = useSelector((state) => state?.TeamData?.navbar);
  const designationList = useSelector(
    (state) => state?.TeamData?.designationTeamList
  );
  const designationNewList = useSelector(
    (state) => state?.TeamData?.designationList
  );
  const inviteLink = useSelector((state) => state?.TeamData?.link);

  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_cycle")) {
        var placement_name = checkPlacementName(
          location?.search.replace("?placement_cycle=", "")
        );
        setPlacementSession({
          id: location?.search.replace("?placement_cycle=", ""),
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  const [tempTeamList, setTempTeamList] = useState([]);
  useEffect(() => {
    if (teamList?.length > 0) {
      var arr = [...teamList];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        var a = [];
        if (item?.placement_cycle_list?.length > 0)
          a = [...item?.placement_cycle_list];
        var n = a.filter((i) => i.is_selected);
        new_arr.push({
          name: item?.name,
          email: item?.email,
          phone: item?.phone,
          designation: item?.designation,
          current_status: item?.current_status,
          placement_cycles: generateCsv(n, "name"),
        });
      });
      setTempTeamList(new_arr);
    }
  }, [teamList?.length]);

  useEffect(() => {
    if (selectedRowsData?.length > 0) {
      let arr = [...selectedRowsData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        var a = [...item?.placement_cycle_list];
        var n = a.filter((i) => i?.is_selected);
        new_arr.push({
          name: item?.name,
          email: item?.email,
          phone: item?.phone,
          designation: item?.designation,
          current_status: item?.current_status,
          placement_cycles: generateCsv(n, "name"),
        });
      });
      setTempTeamList(new_arr);
    } else {
      if (teamList?.length > 0) {
        var arr = [...teamList];
        let new_arr = [];
        var obj = {};
        arr.forEach((item) => {
          var a = item?.placement_cycle_list
            ? [...item?.placement_cycle_list]
            : [];
          var n = a.filter((i) => i.is_selected);
          new_arr.push({
            name: item?.name,
            email: item?.email,
            phone: item?.phone,
            designation: item?.designation,
            current_status: item?.current_status,
            placement_cycles: item?.placement_cycle_list
              ? generateCsv(n, "name")
              : "",
          });
        });
        setTempTeamList(new_arr);
      }
    }
  }, [selectedRowsData?.length]);

  useEffect(() => {
    if (checkForPendingRejected()) {
      setPlacementSession({ id: "All", name: "All Placement Cycles" });
    }
  }, [filters?.current_tab]);

  return (
    <>
      {/* <Layout> */}
      {placementSessionList?.length > 0 && !checkForPendingRejected() && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <DropDownButton
            title="Placement Session 2021"
            placementSession={placementSession}
            setPlacementSession={setPlacementSession}
          />
        </div>
      )}
      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : placementSessionList?.length > 0 ? (
        <>
          <ManageTeamHeader
            teamList={teamList}
            userRoleList={userRoleList}
            designationList={designationNewList}
            selectedRowsData={selectedRowsData}
            placementSession={placementSession}
            userRoleId={userRoleId}
            setApiCalled={setApiCalled}
            tempTeamList={tempTeamList}
            collegeId={collegeId}
            userRolesPermission={userRolesPermission}
            inviteLink={inviteLink}
            filters={filters}
          />
          <ManageJobTab
            loading={loading}
            filters={filters}
            setFilters={setFilters}
            setClickSearchButton={setClickSearchButton}
            setSelectedRowsData={setSelectedRowsData}
            selectedRowsData={selectedRowsData}
            clickSearchButton={clickSearchButton}
            value={value}
            setValue={setValue}
            setUserRoleId={setUserRoleId}
            designationList={designationList}
            collegeId={collegeId}
            userRolesPermission={userRolesPermission}
            setCallNavbar={setCallNavbar}
            teamList={teamList}
          />
        </>
      ) : (
        <>
          <NoDataFuncNew
            title={"Please Add Placement Cycle First"}
            subtitle={"Click the below Button to add placement Cycle"}
            buttonText={"Add Placement Cycle"}
            height={"320px"}
            width={"320px"}
            link={"/placement-cycles"}
          />
        </>
      )}
      {/* <Oops /> */}
      {/* </Layout> */}
    </>
  );
}

export default ManageTeam;
