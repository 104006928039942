import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Paper,
  InputBase,
  IconButton,
  Button,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { userRolesActionsFn } from "../../../api/SignIn/selector";
import Tooltip from "@mui/material/Tooltip";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 4%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    //   marginBottom: "10px",
    padding: "30px",
  },
  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    height: "34px",
    display: "flex",
    boxShadow: "none",
  },
  btn_disabledClone: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    height: "34px",
    display: "flex",
    boxShadow: "none",
    width: "8rem",
  },

  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function PreviousJob({
  item,
  setDataSelected,
  placementCycleId,
}) {
  const classes = useStyles();
  const history = useHistory();
  const userRolesPermission = useSelector(userRolesActionsFn);

  const JobSource = {
    GETWORK_FULFILLED: "GetWork Fulfilled",
    INTERNAL: "Internal",
    GETWORK_INBOUND: "GetWork Inbound",
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <BusinessIcon color="secondary" style={{ marginRight: "10px" }} />{" "}
            <Typography variant="h6"> {item?.company}</Typography>
          </div>
          <Typography variant="body2" color="secondary">
            Created Date : {moment(item?.created).format("DD MMMM' YYYY")}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            style={{ marginTop: "4px" }}
          >
            Job Source : {JobSource[item?.job_source] ?? "Internal"}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            style={{
              marginTop: "4px",
              marginBottom: "10px",
              "line-height": "1.5em",
              height: "3em",
              overflow: "hidden",
            }}
          >
            Job Title: {item?.job_title}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} className={classes.btngrid}>
              {/* <Link to={`/job/${item.job_title.toLowerCase().replace(" ", "-")}-${item?.job_id}`} target="_blank" className={classes.del} style={{ textDecoration: "none" }}> */}
              <Button
                color="primary"
                size="small"
                variant="outlined"
                className={classes.del}
                onClick={() => {
                  window.open(
                    `/job/${item.job_title.toLowerCase().replace(" ", "-")}-${
                      item?.id
                    }`
                  );
                }}
              >
                View
              </Button>
              {/* </Link> */}
            </Grid>
            <Grid item xs={6} className={classes.btngrid}>
              {" "}
              {item?.niyukti_job_id ? (
                <Tooltip title="Cloning Not allowed on GetWork Fullfilled and Inbound jobs.">
                  <span>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      className={classes.btn_disabledClone}
                      disabled={true}
                    >
                      Clone
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.del}
                  onClick={() => {
                    setDataSelected([]);
                    history.push(
                      `/post-job/clone-${item.id}/?placement_cycle_id=${placementCycleId}`
                    );
                  }}
                  disabled={
                    (userRolesPermission?.some(
                      (item) => item.access_control_key === "manage_job"
                    ) === true
                      ? false
                      : true) || item?.niyukti_job_id
                  }
                >
                  Clone
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}
