import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Menu,
  Paper,
  Switch,
  withStyles,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 20.5,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    fontSize: "14px",
    color: "#c5c4ba",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    display: "flex",
    height: "40px",
    paddingLeft: "30px",
    marginRight: "10px",
    paddingRight: "30px",
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "8px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
  clearIndicator: {
    backgroundColor: "gray",
    "& span": {
      "& svg": {
        backgroundColor: "red",
      },
    },
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')",
        },
      },
    },
  },
}));

export default function ActionBar({
  loading,
  setRefreshData,
  toggleViewCollege,
  handleToggle,
  setToggleViewCollege,
  entityInfo,
  handleClickOpen,
  setEdit,
  selectedRowsData,
  searchFilter,
  setSearchFilter,
  setClickSearchButton,
  openEndCycle,
}) {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* {!loading ? ( */}
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Paper component="form" className={classes.root}>
              <InputBase
                value={searchFilter}
                disabled={loading}
                className={classes.input}
                placeholder="Search by Name"
                inputProps={{
                  style: { fontSize: "14px" },
                }}
                onChange={(e) => setSearchFilter(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setClickSearchButton(true);
                  }
                }}
              />

              <IconButton
                disabled={loading}
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setSearchFilter("");
                  setClickSearchButton(true);

                  // setBackendDrivenFilter((prev) => ({
                  //   ...prev,
                  //   outerFilters: {
                  //     ...prev?.outerFilters,
                  //     search: "",
                  //   },
                  // }));
                }}
              >
                <ClearIcon />
              </IconButton>

              <IconButton
                disabled={loading}
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            {/* {filterData.length ? ( */}
            {/* <BackendDrivenFilterDrawer
              backendDrivenFilter={backendDrivenFilter}
              setBackendDrivenFilter={setBackendDrivenFilter}
              filterData={filterData}
              filterLoading={filterLoading}
              loading={loading}
            /> */}
          </Grid>

          {/* <Grid item xs={7}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                color={toggleViewCollege ? "primary" : "#6C757D"}
                style={{ fontSize: 16, marginRight: 5 }}
              >
                Show {entityInfo?.college}
              </Typography>

              <AntSwitch
                checked={toggleViewCollege}
                onChange={handleToggle}
                name="checkedC"
              />
            </Box>
          </Grid> */}

          {selectedRowsData?.length ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                {selectedRowsData?.length === 1 ? (
                  <>
                    {" "}
                    {/* <Link style={{ textDecoration: "none" }}> */}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      disabled
                    >
                      Post Job
                    </Button>
                    {/* </Link> */}
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.btn}
                      onClick={() => {
                        setEdit(true);
                        handleClickOpen();
                      }}
                    >
                      Edit
                    </Button>
                  </>
                ) : null}

                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.btn}
                  onClick={openEndCycle}
                >
                  End cycle
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </>
  );
}
