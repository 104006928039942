import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import pSBC from "shade-blend-color";
import DescriptionIcon from "@material-ui/icons/Description";
import { IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    overflow: "hidden",
    // padding: theme.spacing(0, 1),
  },
  paper: {
    maxWidth: 200,
    borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  icon: {
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    borderRadius: "8px",
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: "14px",
    color: "#fff",
    fontWeight: 100,
  },
}));

export default function Attachfile({ removeAttachmnet, message }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={removeAttachmnet}
            style={{ padding: "0px", marginTop: "-10px", marginRight: "-10px" }}
          >
            <CancelIcon style={{ color: "#fff", fontSize: "16px" }} />
          </IconButton>
        </div>

        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <div className={classes.icon}>
              <DescriptionIcon color="primary" />
            </div>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography noWrap className={classes.text}>
              {message}
            </Typography>
            {/* <Typography variant="overline" style={{ color: "#fff" }}>
              50 KB
            </Typography> */}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
