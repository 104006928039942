import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../api/Domain/selector";
import { ReactComponent as NoData } from "../../assets/svg/New entries-rafiki.svg";

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  img: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    alignItems: "center",
    margin: "auto",
  },
}));

export default function NoDataFunc({
  handleClickOpen,
  title,
  subtitle,
  buttonText,
  height,
  width,
}) {
  const classes = useStyles();
  const theme_change = useSelector(themeSelectorFn);

  return (
    <>
      <div className={classes.img}>
        <NoData
          height={height ? height : "400px"}
          width={width ? width : "400px"}
          alt="404 Error"
          fill={theme_change?.main ? theme_change?.main : "#007BFF"}
        />

        <Typography
          variant="h1"
          // style={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          style={{ color: "#6c757d", marginTop: "20px" }}
        >
          {subtitle}
          <br />
          {/* You may also refresh the page or try again later */}
        </Typography>
        <br />
        {buttonText ? (
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={() => {
              handleClickOpen();
            }}
          >
            {buttonText}{" "}
          </Button>
        ) : null}
      </div>
    </>
  );
}
