import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  InputBase,
  IconButton,
  Button,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  withStyles,
  TableRow,
  TableHead,
  Box,
  CircularProgress,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { useTheme } from "@mui/material";
import pSBC from "shade-blend-color";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ServiceDialog from "../../components/DialogSection/ServiceDialog/ServiceDialog";
import {
  sanchalanAxiosGetReq,
  sanchalanAxiosPatchReq,
} from "../../api/BaseApi/apiBase";
import { useAlert } from "react-alert";
import moment from "moment";
import { useHistory } from "react-router-dom";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    color: "#000",
    border: ".5px solid #B0B6BA",
  },
  body: {
    fontSize: 14,
    border: ".5px solid #B0B6BA",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  //   root: {
  //     "&:nth-of-type(odd)": {
  //       backgroundColor: theme.palette.action.hover,
  //     },
  //   },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
    // color: "#6c757d",
    "& ::-webkit-input-placeholder": {
      color: "#000 !important",
    },
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    marginRight: "12px",
    boxShadow: "none",
    borderRadius: 8,
    height: "48px",
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "8px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
}));

function ToUpper(str) {
  return str
    .toLowerCase()
    .split("_")
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1);
    })
    .join(" ");
}

function ProgressIcon({ text, color }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FiberManualRecordIcon
          style={{
            color: color,
          }}
        />
        <Typography
          style={{
            color: color,
          }}
        >
          {ToUpper(text)}
        </Typography>
      </div>
    </>
  );
}

function ServiceDesk() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [service, setService] = React.useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [curentStatus, setCurrentStatus] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [edit, setEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const alert = useAlert();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getServiceList = () => {
    setLoader(true);
    setPageLoading(true);
    sanchalanAxiosGetReq(
      `api/settings/service_history/?page_size=${rowsPerPage}&page=${page + 1}`
    )
      .then((res) => {
        if (res?.data?.success) {
          setService(res?.data?.data?.results);
          setTotalCount(res?.data?.data?.count);
          setShowFilter(false);
        } else {
          setService([]);
        }
        setPageLoading(false);
        setLoader(false);
      })
      .catch((err) => {
        setPageLoading(false);
        setLoader(false);
      });
  };

  // const [statusUpdate, setStatusUpdate] = useState({
  //   comment: null,
  //   new_status: null,
  // });

  // useEffect(() => {
  //   if (edit && currentRow) {
  //     setStatusUpdate({
  //       ...statusUpdate,
  //     });
  //   }
  // }, [edit]);

  const [updateLoader, setUpdateLoader] = useState(false);

  const updateRow = (id) => {
    setUpdateLoader(true);
    const requestOptionsPatch = {
      comment: "",
      new_status: "closed",
    };
    sanchalanAxiosPatchReq(
      `api/settings/service_history/${id}/`,
      requestOptionsPatch
    )
      .then((res) => {
        if (res?.data?.success) {
          alert?.success(res?.data?.data?.message);
          // setStatusUpdate(null);
        } else {
          alert?.error(res?.data?.data?.message);
        }
        setUpdateLoader(false);
        handleClose();
      })
      .catch((err) => {
        alert?.error("Something went wrong");
        setUpdateLoader(false);
        handleClose();
      });
  };

  useEffect(() => {
    getServiceList();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    // const handleFirstPageButtonClick = (event) => {
    //   onPageChange(event, 0);
    // };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    // const handleLastPageButtonClick = (event) => {
    //   onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    // };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  return (
    <>
      <Box style={{ marginTop: 20 }}>
        <Grid container spacing={5}>
          <Grid item xs={5} style={{ paddingBottom: "0px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h2" style={{ fontWeight: 600 }}>
                Service Request
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search Module"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
              {filter && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilter("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton className={classes.iconButton} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                history.push("/college-group/contact-us");
              }}
              style={{ boxShadow: "none", height: 50, padding: 0 }}
            >
              Create New Request
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <TableContainer
                component={Paper}
                style={{ boxShadow: "none", position: "relative" }}
              >
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{ fontWeight: "600" }}
                      >
                        Module
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ fontWeight: "600" }}
                      >
                        Service Request Ticket ID
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ fontWeight: "600" }}
                      >
                        Date
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ fontWeight: "600" }}
                      >
                        Current Status
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ fontWeight: "600" }}
                      >
                        Center Name
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ fontWeight: "600" }}
                      >
                        Raised By
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ fontWeight: "600" }}
                      >
                        Service Request Details
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageLoading ? (
                      <TableRow style={{ height: "50vh" }}>
                        <StyledTableCell colSpan={7} align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100px",
                            }}
                          >
                            <CircularProgress color="primary" size={60} />
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      service
                        ?.filter((row) => {
                          return filter?.toLowerCase() === ""
                            ? row
                            : row?.module_name?.toLowerCase()?.includes(filter);
                        })
                        ?.map((row) => (
                          <StyledTableRow key={row?.id}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row?.module_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row?.ticket_id
                                ? `Ticket ID: ${row?.ticket_id}`
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row?.create_time
                                ? moment(row?.create_time).format("DD/MM/YYYY")
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <ProgressIcon
                                color={row?.status_color}
                                text={row?.status}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row?.submitted_by?.college_name}
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row?.submitted_by?.user_name}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ boxShadow: "none", display: "flex" }}
                                startIcon={<VisibilityOutlinedIcon />}
                                onClick={() => {
                                  handleClickOpen();
                                  setCurrentRow(row);
                                  setEdit(true);
                                  setCurrentStatus(row?.status);
                                }}
                              >
                                View
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    )}
                  </TableBody>
                  <TableFooter style={{ position: "relative", width: "100%" }}>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                        style={{
                          paddingRight: "40%",
                        }}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <ServiceDialog
                open={open}
                handleClose={handleClose}
                handleClickOpen={handleClickOpen}
                updateRow={updateRow}
                currentRow={currentRow}
                setCurrentRow={setCurrentRow}
                data={service}
                // statusUpdate={statusUpdate}
                // setStatusUpdate={setStatusUpdate}
                curentStatus={curentStatus}
                loading={updateLoader}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ServiceDesk;
