import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { UserRolesGetAPI } from "../../api/AccessControls/action";
import { collegeDetailSelectorFn, collegeIdFn, userRolesActionsFn } from "../../api/SignIn/selector";
import AdminAccess from "../../components/Cards/ManageRole/AdminAccess";
import CustomCircularProgress from "../../components/CircularProgressBar";
import CreateCustomRole from "../../components/DialogSection/CreateCustomRole";
import CreateCustomRoleEdit from "../../components/DialogSection/CreateCustomRole/edit";
import NoDataUserRolesFunc from "../../components/NoDataSVG/userRoles";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    //  marginTop: "15px",
    height: "40px",
    paddingLeft: "30px",
    marginRight: "20px",
    paddingRight: "30px",
    display: "flex",
  },
}));
export default function Setting() {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [currentEditRole, setCurrentEditRole] = useState({});
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const userRolesPermission = useSelector(userRolesActionsFn);

  const collegeId = useSelector(collegeIdFn);
  useEffect(() => {
    if (collegeId) dispatch(UserRolesGetAPI(setLoading, alert, history, location, collegeId));
  }, [collegeId]);

  const userRoleList = useSelector((state) => state.AllUserRoles.details);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEdit = (item) => {
    setOpenEdit(true);
    setCurrentEditRole(item);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography variant="h2">Manage Role's Access</Typography>
            {/* <br /> */}
            {/* <Typography variant="body2" color="secondary">
              Create Custom Role Access
            </Typography> */}
            {userRoleList?.length > 0 && userRolesPermission?.some((item) => item.access_control_key === "manage_user_role") && (
              <Button color="primary" variant="contained" size="small" className={classes.button} onClick={handleClickOpen}>
                Create Custom Role
              </Button>
            )}
          </div>
        </Grid>
        {loading ? (
          <>
            <Grid item xs={12}>
              <CustomCircularProgress />
            </Grid>
          </>
        ) : (
          <>
            <br />
            {userRoleList?.length > 0 ? (
              userRoleList?.map((item) => (
                <Grid item xs={12} sm={6}>
                  <AdminAccess item={item} handleClickOpen={handleClickOpenEdit} open={openEdit} collegeId={collegeId} userRolesPermission={userRolesPermission} />
                </Grid>
              ))
            ) : (
              <>
                <NoDataUserRolesFunc handleClickOpen={handleClickOpen} />
              </>
            )}

            <CreateCustomRole open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} userRoleList={userRoleList} collegeId={collegeId} />

            <CreateCustomRoleEdit
              open={openEdit}
              handleClickOpen={handleClickOpenEdit}
              handleClose={handleCloseEdit}
              currentEditRole={currentEditRole}
              userRoleList={userRoleList}
              collegeId={collegeId}
            />
          </>
        )}
      </Grid>
      {/* </Layout> */}
    </>
  );
}
