import { Breadcrumbs, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  ApproveSingleCompanyAPI,
  CompanyJobApi,
  SingleCompanyGetAPI,
  SinglePendingCompanyGetAPI,
} from "../../api/Company/action";
import {
  collegeIdFn,
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import defaultCompany from "../../assets/img/default.jpg";
import CurrentOpening from "../../components/Cards/CompanyDetailInfo/CurrentOpening";
import Overview from "../../components/Cards/CompanyDetailInfo/Overview";
import CustomCircularProgress from "../../components/CircularProgressBar";
import AcceptCompany from "../../components/DialogSection/AcceptCompany";
import CustomSwitches from "../../components/Input/CustomSwitches";
import BlockedStatusTable from "../../components/Table/BlockedStatusTable";
import CompanyDetailsTable from "../../components/Table/CompanyDetailsTable";
import NoteListTable from "../../components/Table/EditHistoryTable/NoteListTable";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";
import { groupViewCheck } from "../../utility/groupViewCheck";
import { getWebsiteLogo } from "../../utility/getWebsiteLogo";

const useStyles = makeStyles((_) => ({
  root: {
    flexGrow: 1,
  },
  open: {
    marginTop: "30px",
    marginBottom: "20px",
  },

  image: {
    display: "flex",
    width: 150,
    height: 80,
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  img: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    //  borderRadius: "50%",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
}));

function CompanyDetails() {
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [pocList, setPocList] = useState([]);
  const [showMore, setShowMore] = React.useState(false);
  const collegeListSelector = useSelector(collegeListSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [disableActions, setDisableActions] = useState(false);

  // >>>>>>>>>>>>>>>>>>
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedID, setSelectedID] = React.useState([]);
  const [callNavbar, setCallNavbar] = useState(false);
  // <<<<<<<<<<<<<<<<<

  useEffect(() => {
    if (
      (collegeId ||
        groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)) &&
      params?.company_id
    ) {
      if (location.pathname.includes("rejected")) setDisableActions(true);
      if (
        location.pathname.includes("pending") ||
        location.pathname.includes("rejected")
      ) {
        dispatch(
          SinglePendingCompanyGetAPI(
            setLoading,
            alert,
            history,
            location,
            params?.company_id,
            collegeId
          )
        );
      } else {
        dispatch(
          SingleCompanyGetAPI(
            setLoading,
            alert,
            history,
            location,
            params?.company_id,
            collegeId
          )
        );

        dispatch(
          CompanyJobApi(
            setLoading,
            alert,
            history,
            location,
            params?.company_id
          )
        );
      }
    }
  }, [params?.company_id, collegeId]);

  const companyDataFromApi = useSelector(
    (state) => state?.CompanyData?.singeCompany
  );
  const [companyData, setCompanyData] = useState({});

  useEffect(() => {
    if (companyDataFromApi) {
      setCompanyData(companyDataFromApi);
    }
  }, [companyDataFromApi]);

  const companyJobDetails = useSelector((state) =>
    state?.CompanyJobData?.jobDetails ? state?.CompanyJobData?.jobDetails : []
  );

  useEffect(() => {
    if (companyData) {
      setPocList(companyData?.poc_list);
    }
  }, [companyData]);

  const handleAccept = (status, setLoader) => {
    var data = {
      college_id: companyData?.college_id,
      company_id: [params?.company_id],
      status: status,
    };
    dispatch(
      ApproveSingleCompanyAPI(data, alert, history, location, setLoader)
    );
  };

  const [blockedCollegeList, setBlockedCollegeList] = useState([]);

  useEffect(() => {
    if (
      companyData?.blocked_colleges?.length &&
      groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
    ) {
      var arr = [];
      companyData?.blocked_colleges?.forEach((item) => {
        arr.push({ ...item, name: checkCollegeName(item?.id) });
      });
      setBlockedCollegeList(arr);
    } else setBlockedCollegeList([]);
  }, [companyData?.blocked_colleges?.length]);

  const checkCollegeName = (id) => {
    var name = "";
    collegeListSelector.map((item) => {
      if (item?.id === id) name = item?.name;
    });
    return name;
  };

  return (
    <>
      {/* <Layout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to={checkForBreadcrumb(
                isEducationGroup,
                isCollegeGroup,
                collegeId,
                "manage-companies"
              )}
              style={{ textDecoration: "none" }}
            >
              <Typography color="primary" variant="body2">
                Company
              </Typography>
            </Link>
            {/* <Link style={{ textDecoration: "none" }} color="inherit"  onClick={handleClick}> */}
            <Typography color="secondary" variant="body2">
              {companyData?.name}
            </Typography>
            {/* </Link> */}
          </Breadcrumbs>
          <br />
          <Grid container spacing={2}>
            <Grid item>
              <div className={classes.image}>
                <img
                  className={classes.img}
                  alt="complex"
                  src={
                    getWebsiteLogo(
                      companyData?.logo,
                      companyData?.company_website,
                      defaultCompany
                    )
                    // companyData?.logo
                    //   ? companyData?.logo
                    //   : companyData?.company_website
                    //   ? `//logo.clearbit.com/${companyData?.company_website}`
                    //   : defaultCompany
                  }
                  onError={(e) => {
                    e.target.onerror = () => null;
                    e.target.src = defaultCompany;
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid
                item
                xs
                container
                direction="column"
                spacing={2}
                style={{ display: "contents" }}
              >
                <div className={classes.title}>
                  <Typography variant="h2" style={{ fontWeight: 600 }}>
                    {companyData?.name}
                  </Typography>
                  {companyData?.industry_name && (
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        marginBottom: "10px",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h4"
                        style={{ fontWeight: "normal" }}
                        color="secondary"
                      >
                        {companyData?.industry_name} Company
                      </Typography>{" "}
                    </div>
                  )}
                </div>
              </Grid>
              {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_company"
              ) && (
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  spacing={2}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {!groupViewCheck(
                    isEducationGroup,
                    isCollegeGroup,
                    collegeId
                  ) && (
                    <CustomSwitches
                      companyData={companyData}
                      collegeId={collegeId}
                      setCompanyData={setCompanyData}
                    />
                  )}
                  {location.pathname.includes("pending") ? (
                    <>
                      <AcceptCompany
                        deleteUser={true}
                        selectedRowsData={selectedRowsData}
                        setSelectedRowsData={setSelectedRowsData}
                        selectedID={selectedID}
                        setSelectedID={setSelectedID}
                        collegeId={collegeId}
                        setCallNavbar={setCallNavbar}
                        companyDetail={true}
                        companyId={params?.company_id}
                      />
                      <AcceptCompany
                        deleteUser={false}
                        selectedRowsData={selectedRowsData}
                        setSelectedRowsData={setSelectedRowsData}
                        selectedID={selectedID}
                        setSelectedID={setSelectedID}
                        collegeId={collegeId}
                        setCallNavbar={setCallNavbar}
                        companyDetail={true}
                        companyId={params?.company_id}
                      />
                    </>
                  ) : (
                    !disableActions && (
                      <Link to={`/edit-company-profile/${companyData?.id}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                        >
                          Edit
                        </Button>
                      </Link>
                    )
                  )}
                  <div className={classes.title}></div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Overview companyData={companyData} />
          <CompanyDetailsTable
            companyData={companyData}
            pocList={pocList}
            setPocList={setPocList}
            enableEdit={
              location.pathname.includes("pending") ||
              location.pathname.includes("rejected")
                ? false
                : true
            }
          />

          {blockedCollegeList?.length > 0 &&
            groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
              <>
                <Typography variant="h6" className={classes.open}>
                  {" "}
                  Blocked Status
                </Typography>
                <BlockedStatusTable data={blockedCollegeList} />
              </>
            )}

          {companyData?.notes_list?.length > 0 && (
            <>
              <Typography variant="h6" className={classes.open}>
                {" "}
                Action History
              </Typography>
              <NoteListTable data={companyData?.notes_list} />
            </>
          )}

          {/* <Grid item md={5} xs={12} style={{ marginTop: "20px" }}>
          <Contact />
        </Grid> */}
          {companyJobDetails?.length > 0 && (
            <>
              <Typography variant="h6" className={classes.open}>
                {" "}
                Current Opening ( {companyJobDetails?.length} )
              </Typography>
              {!showMore
                ? companyJobDetails.slice(0, 2).map((item, index) => (
                    <>
                      <CurrentOpening companyJobDetails={item} />
                      <br />
                      {/* <br /> */}
                    </>
                  ))
                : companyJobDetails.map((item, index) => (
                    <>
                      <CurrentOpening companyJobDetails={item} />
                      <br />
                      {/* <br /> */}
                    </>
                  ))}
              {companyJobDetails?.length > 2 && (
                <Button
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                  color="primary"
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {showMore ? (
                    <span style={{ display: "flex" }}>
                      Hide Jobs <ArrowDropUpIcon />
                    </span>
                  ) : (
                    <span style={{ display: "flex" }}>
                      See all Jobs <ArrowDropDownIcon />
                    </span>
                  )}
                </Button>
              )}
            </>
          )}
        </>
      )}
      {/* </Layout> */}
    </>
  );
}

export default CompanyDetails;
