import { Button, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  InviteStudentLinkAPI,
  PlacementListAPI,
} from "../../api/Common/action";
import { isGradeSystemFn } from "../../api/Domain/selector";

import { collegeIdFn, userRolesActionsFn } from "../../api/SignIn/selector";

import CustomCircularProgress from "../../components/CircularProgressBar";

import NoDataFuncNew from "../../components/NoDataSVG/index2";

import { STUDENT_DATA } from "../../api/ManageStudents/action";
import AddStudentPlacementCycle from "../../components/DialogSection/AddJobPlacementCycle/AddStudentPlacementCycle";

import AllStudentTab from "../../components/Header/StudentTab/AllStudentTab";
import {
  sanchalanAxiosGetReq,
  sanchalanAxiosPostReq,
} from "../../api/BaseApi/apiBase";
import { checkForToken } from "../../constants/ApiCall";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  newBtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",

    backgroundColor: theme.palette.primary.secondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
}));

export default function AddStudent() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [value, setValue] = React.useState("all");
  const [firstApiCall, setFirstApiCall] = useState(false);

  const [openImport, setOpenImport] = useState(false);

  const [applyClickedScore, setApplyClickedScore] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);
  const collegeId = useSelector(collegeIdFn);
  const [callNavbar, setCallNavbar] = useState(false);
  const [filters, setFilters] = useState({
    search: null,
    course: null,
    degree: null,
    batch: null,
    cgpa_min: null,
    cgpa_max: null,
    /*  status_active: true,
    status_blocked: true, */
    status: "ALL",
    student_status: null,
    current_tab: null,
    placement_status: null,
  });
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const isGradeSystem = useSelector(isGradeSystemFn);

  //const { placement_cycle_id } = useParams();

  const search = useLocation().search;
  const placement_cycle_id = new URLSearchParams(search).get(
    "placement_cycle_id"
  );
  const placement_cycle_name = new URLSearchParams(search).get(
    "placement_cycle_name"
  );

  const [resData, setresData] = useState({
    total_entries: null,
    user_created_count: null,
    email_already_exist: null,
    failed_entries: null,
    flag: null,
    error_list: [],
    already_exist_list: [],
    flagCloseImport: false,
  });

  const checkValue = () => {
    if (
      filters?.status === "" ||
      filters?.status === "ALL" ||
      filters?.status === "all"
    )
      return ``;
    else if (checkForPendingRejected())
      return `&key=${filters?.current_tab?.params}`;
    else return `&placement_status=${filters?.status}`;
  };
  const checkForKey = () => {
    if (
      filters?.status === "" ||
      filters?.status === "ALL" ||
      filters?.status === "all"
    )
      return ``;
    else if (checkForPendingRejected())
      return `&key=${filters?.current_tab?.params}`;
    else return `&placement_status=${filters?.status}`;
  };

  const searchFilter = () => {
    if (filters.search) return `&search=${filters.search}`;
    else return ``;
  };
  const departmentFilter = () => {
    if (filters.course) return `&course=${filters.course.id}`;
    else return ``;
  };
  const degreeFilter = () => {
    if (filters.degree) return `&degree=${filters.degree.id}`;
    else return ``;
  };
  const batchFilter = () => {
    if (filters.batch) return `&batch=${filters.batch.id}`;
    else return ``;
  };
  const statusFilter = () => {
    if (filters.status_active && filters.status_blocked) return ``;
    else if (filters.status_active) return `&status=ACTIVE`;
    else if (filters.status_blocked) return `&status=INACTIVE`;
    else return ``;
  };

  const checkPlacementSesion = () => {
    if (placementSession && placementSession.id !== "All")
      return `&placement_session=${placementSession.id}`;
    else return ``;
  };

  const scoreFilter = () => {
    if (isGradeSystem) {
      if (filters.cgpa_min && filters?.cgpa_max)
        return `&min_cgpa=${Number(filters.cgpa_min)}&max_cgpa=${Number(
          filters.cgpa_max
        )}`;
      else if (filters.cgpa_min) return `&min_cgpa=${Number(filters.cgpa_min)}`;
      else if (filters.cgpa_max) return `&max_cgpa=${Number(filters.cgpa_max)}`;
      else return ``;
    } else {
      if (filters.cgpa_min && filters?.cgpa_max)
        return `&min_percentage=${Number(
          filters.cgpa_min
        )}&max_percentage=${Number(filters.cgpa_max)}`;
      else if (filters.cgpa_min)
        return `&min_percentage=${Number(filters.cgpa_min)}`;
      else if (filters.cgpa_max)
        return `&max_percentage=${Number(filters.cgpa_max)}`;
      else return ``;
    }
  };

  useEffect(() => {
    if (firstApiCall) {
      var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
      /*  dispatch(
        StudentsGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore
        )
      ); */

      var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

      //dispatch(StudentNavbarAPI(url_filter));
    }
  }, [filters?.status]);

  useEffect(() => {
    if (placementSession) {
      var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
      /* dispatch(
        StudentsGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore
        )
      ); */

      var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

      //dispatch(StudentNavbarAPI(url_filter));
    }
  }, [placementSession]);

  useEffect(() => {
    if (firstApiCall) {
      var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
      /*   dispatch(
        StudentsGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore
        )
      ); */

      var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

      //dispatch(StudentNavbarAPI(url_filter));
    }
  }, [
    filters.status_blocked,
    filters.status_active,
    filters.batch,
    filters.course,
    filters.degree,
  ]);

  useEffect(() => {
    if (clickSearchButton) {
      var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
      /*    dispatch(
        StudentsGetAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore
        )
      ); */
      var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

      //dispatch(StudentNavbarAPI(url_filter));
    }
  }, [clickSearchButton]);

  const studentList = useSelector((state) => state?.StudentData?.details);

  const clearStudentData = () => {
    dispatch({
      type: STUDENT_DATA,
      payload: [],
    });
  };

  useEffect(() => {
    if (resData?.flagCloseImport) {
      setOpenImport(false);
    }
  }, [resData?.flagCloseImport, openImport]);

  /*   const handleCloseImport = () => {
    setOpenImport(true);
    setFileNames([]);
  }; */

  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );
  const [loadingPlacement, setLoadingPlacement] = useState(false);
  const userRolesPermission = useSelector(userRolesActionsFn);

  useEffect(() => {
    if (collegeId)
      dispatch(
        PlacementListAPI(
          alert,
          history,
          location,
          setLoadingPlacement,
          collegeId
        )
      );
  }, [collegeId]);

  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_cycle")) {
        var placement_name = checkPlacementName(
          location?.search.replace("?placement_cycle=", "")
        );
        setPlacementSession({
          id: location?.search.replace("?placement_cycle=", ""),
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  const inviteLink = useSelector((state) => state.commonData?.studentLink);

  useEffect(() => {
    dispatch(InviteStudentLinkAPI(alert, history, location));
  }, [collegeId]);

  const [filterDataText, setFilterDataText] = useState(null);
  useEffect(() => {
    if (
      (filters.status_blocked ||
        filters.status_active ||
        filters.batch ||
        filters.course ||
        filters.degree ||
        applyClickedScore ||
        clickSearchButton) &&
      studentList?.length === 0
    ) {
      setFilterDataText("No Results Found");
    }
  }, [
    filters.status_blocked,
    filters.status_active,
    filters.batch,
    filters.course,
    filters.degree,
    applyClickedScore,
    clickSearchButton,
  ]);

  useEffect(() => {
    if (checkForPendingRejected()) {
      setPlacementSession({ id: "All", name: "All Placement Cycles" });
      // setPlacementSession({});
    }
  }, [filters?.current_tab]);

  useEffect(() => {
    if (callNavbar) {
      var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
      //dispatch(StudentNavbarAPI(url_filter));
    }
  }, [callNavbar]);
  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.params === "pending_students" ||
      filters?.current_tab?.params === "rejected_students"
    );
  };

  /* ###################################################################################################### */
  const [navbarDataPlacementCycle, setNavbarDataPlacementCycle] = useState([]);
  const [placementCycleData, setPlacementCycleData] = useState([]);
  const [filterListPlacementCycle, setFilterListPlacementCycle] = useState([]);
  const [firstCall, setFirstCall] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [addToPlacementCycleError, setAddToPlacementCycleError] =
    useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [selectedID, setSelectedID] = React.useState([]);

  const getFiltersParam = () => {
    var filterString = "?";
    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        if (key === "status") {
          if (value === "ALLREADY") {
            filterString += `placement_id=${placement_cycle_id}&`;
          } else {
            filterString += `key=${value}&`;
          }
        }
      }
      if (value) {
        if (key === "degree") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      if (value) {
        if (key === "student_status") {
          filterString += `status=${value?.name}&`;
        }
      }
      if (value) {
        if (key === "course") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      if (value) {
        if (key === "batch") {
          filterString += `${key}=${value?.id}&`;
        }
      }
      if (value) {
        if (key === "company") {
          filterString += `${key}_name=${value?.name}&`;
        }
      }
      if (value) {
        if (key === "search") {
          filterString += `${key}=${value}&`;
        }
      }
      /*  if (value) {
      if (key === "status") {
        filterString += `${key}=${value?.name}&`;
      }
    } */
      if (value) {
        if (key === "cgpa_min") {
          filterString += `min_cgpa=${Number(value)}&`;
        }
      }
      if (value) {
        if (key === "cgpa_max") {
          filterString += `max_cgpa=${Number(value)}&`;
        }
      }
      if (value) {
        if (key === "job_type") {
          filterString += `job_type=${value?.id}&`;
        }
      }
    }

    return filterString;
  };

  const [loadingNavbar, setLoadingNavbar] = useState(true);

  const getNavbarData = async () => {
    const url = `api/placement_cycles/placementnavbar/data/${getFiltersParam()}placement_id=${placement_cycle_id}`;
    setLoadingNavbar(true);
    try {
      // setNavbarDataPlacementCycle([]);
      const res = await sanchalanAxiosGetReq(url);

      if (res.data.success) {
        setLoadingNavbar(false);
        setNavbarDataPlacementCycle(res.data.data);
      } else {
        setLoadingNavbar(false);
        alert.error(res.data.error);
      }
    } catch (error) {
      setLoadingNavbar(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const getPlacementCycleDataFirstTime = async () => {
    const url = `api/placement_cycles/placement/data/${getFiltersParam()}`;
    setLoading(true);
    try {
      setPlacementCycleData([]);
      const res = await sanchalanAxiosGetReq(url);

      if (res.data.success) {
        setLoading(false);
        setPlacementCycleData(res?.data?.data?.data);
        setFilterListPlacementCycle(res?.data?.data?.filter_data);
        setFirstCall(false);
      } else {
        setLoading(false);
        alert.error(res.data.error);
      }
    } catch (error) {
      setLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  useEffect(() => {
    const url = `api/placement_cycles/placement/data/${getFiltersParam()}`;
    const getPlacementCycleData = async () => {
      setLoading(true);
      try {
        setPlacementCycleData([]);

        const res = await sanchalanAxiosGetReq(url);

        if (res.data.success) {
          setLoading(false);
          setPlacementCycleData(res?.data?.data?.data);
        } else {
          setLoading(false);
          alert.error(res.data.error);
        }
      } catch (error) {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      }
    };
    if (firstCall) {
      getPlacementCycleDataFirstTime();
      getNavbarData();
    } else {
      getPlacementCycleData();
      getNavbarData();
    }
  }, [filters, refreshData]);

  const addStudentToPlacementCycle = async () => {
    const url = `api/manage_student/add_student_placement/?college_id=${collegeId}`;

    var arr = [];
    selectedRowsData?.map((item) => {
      return arr.push(item.id);
    });
    const data = {
      placement_id: [placement_cycle_id],
      object_id: arr,
      college_id: collegeId,
    };

    try {
      setDataLoading(true);
      setAddToPlacementCycleError(false);

      const res = await sanchalanAxiosPostReq(url, data);

      if (res.data.success) {
        alert.success(res.data.data.message);
        setDataLoading(false);
        setApiResponse(res.data.success);
        setSelectedRowsData([]);
        setRefreshData(!refreshData);
      } else {
        setDataLoading(false);
        setAddToPlacementCycleError(true);
      }
    } catch (error) {
      setDataLoading(false);
      setAddToPlacementCycleError(true);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
      }
      throw error;
    }
  };

  /* ######################################################################################################## */

  return (
    <>
      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : placementSessionList?.length > 0 ? (
        <>
          <div className={classes.grid}>
            <div
              style={{
                display: "flex",
                //flexDirection: "column",
                justifyContent: "center",
                //padding: "10px",
                //paddingLeft: "10px",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h2">Add Students to </Typography>
              <Tooltip title={placement_cycle_name} placement="bottom">
                <Typography
                  variant="h2"
                  noWrap
                  style={{ maxWidth: 400, marginLeft: "5px" }}
                >
                  {placement_cycle_name}
                </Typography>
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {selectedRowsData?.length > 0 ? (
                <AddStudentPlacementCycle
                  //disableBtn={selectedRowsData.length > 0 ? false : true}
                  addStudentToPlacementCycle={addStudentToPlacementCycle}
                  dataLoading={dataLoading}
                  placement_cycle_name={placement_cycle_name}
                  setAddToPlacementCycleError={setAddToPlacementCycleError}
                  addToPlacementCycleError={addToPlacementCycleError}
                  apiResponse={apiResponse}
                  selectedRowsData={selectedRowsData}
                  setSelectedRowsData={setSelectedRowsData}
                  setSelectedID={setSelectedID}
                />
              ) : (
                <></>
              )}
              <Link
                to={{
                  pathname: `/placement-cycles`,
                }}
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  className={classes.newBtn}
                >
                  {/*   Clear */}
                  Close
                </Button>
              </Link>
            </div>
          </div>
          <div>
            <AllStudentTab
              loading={loading}
              filters={filters}
              setFilters={setFilters}
              setClickSearchButton={setClickSearchButton}
              setSelectedRowsData={setSelectedRowsData}
              selectedRowsData={selectedRowsData}
              clickSearchButton={clickSearchButton}
              value={value}
              setValue={setValue}
              //navbarData={navbarData}
              //filterList={filterList}
              setApplyClickedScore={setApplyClickedScore}
              filterDataText={filterDataText}
              setFilterDataText={setFilterDataText}
              collegeId={collegeId}
              userRolesPermission={userRolesPermission}
              setCallNavbar={setCallNavbar}
              clearData={clearStudentData}
              navbarDataPlacementCycle={navbarDataPlacementCycle}
              filterListPlacementCycle={filterListPlacementCycle}
              placementCycleData={placementCycleData}
              setSelectedID={setSelectedID}
              selectedID={selectedID}
            />
          </div>
        </>
      ) : (
        <>
          <NoDataFuncNew
            title={"Please Add Placement Cycle First"}
            subtitle={"Click the below Button to add placement Cycle"}
            buttonText={"Add Placement Cycle"}
            height={"320px"}
            width={"320px"}
            link={"/placement-cycles"}
          />
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
