import React from "react";
import {
  Typography,
  Chip,
  IconButton,
  Tooltip,
  Divider,
  Button,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import pSBC from "shade-blend-color";
import { Check } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import HistoryIcon from "@material-ui/icons/History";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    position: "relative",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  chip: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 8,
    // borderRadius: "8px",
  },
  chipDisplay: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  boxShadowClass: {
    position: "relative",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.11)",
    padding: 10,
    margin: "10px 0",
  },
  alignPill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
function SavedEducation({ data, approveReject }) {
  const classes = useStyles();

  return (
    <div>
      {data?.map((item) => (
        <>
          {item?.updatedData?.education_id > 2 ? (
            <>
              <div className={classes?.boxShadowClass}>
                <div className={classes?.alignPill}>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    {item?.updatedData?.highest_qualification}
                  </Typography>
                  {/* <Chip
                    color="primary"
                    deleteIcon={<CloseIcon color="primary" />}
                    variant="outlined"
                    style={{
                      backgroundColor:
                        item?.change_type === "ADD"
                          ? "red"
                          : item?.change_type === "UPDATE"
                          ? "yellow"
                          : "maroon",
                    }}
                    label={
                      item?.change_type === "ADD"
                        ? "Added"
                        : item?.change_type === "UPDATE"
                        ? "Updated"
                        : "Deleted"
                    }
                    className={classes.chip}
                    size="small"
                  /> */}
                  <Tooltip
                    placement="bottom"
                    title={
                      item?.change_type === "ADD"
                        ? "New education added"
                        : item?.change_type === "UPDATE"
                        ? "Update existing education"
                        : "Delete existing education"
                    }
                  >
                    <div
                      style={{
                        backgroundColor:
                          item?.change_type === "ADD"
                            ? "#00203F"
                            : item?.change_type === "UPDATE"
                            ? "#2AF598"
                            : "#FF0000",
                      }}
                      className={classes.chip}
                    >
                      <Typography style={{ color: "#fff", fontSize: "14px" }}>
                        {item?.change_type === "ADD"
                          ? "Added"
                          : item?.change_type === "UPDATE"
                          ? "Updated"
                          : "Deleted"}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>

                <Typography variant="body1" /* style={{ marginTop: "10px" }} */>
                  {item?.updatedData?.college_name}{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ marginTop: "5px", marginBottom: "7px" }}
                >
                  {item?.updatedData?.degree && (
                    <>{item?.updatedData?.degree} </>
                  )}
                  {item?.updatedData?.specialization && (
                    <> in {item?.updatedData?.specialization}</>
                  )}
                  {item?.updatedData?.degree && <>,</>}{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ marginTop: "5px", marginBottom: "7px" }}
                >
                  {" "}
                  {item?.updatedData?.is_grade_system
                    ? item?.updatedData?.cgpa &&
                      "CGPA: " + item?.updatedData?.cgpa
                    : item?.updatedData?.percentage &&
                      "Percentage: " + item?.updatedData?.percentage}
                </Typography>
                <div className={classes?.alignPill}>
                  <Typography variant="body2">
                    {item?.updatedData?.start_date &&
                      item?.updatedData?.start_date !== null && (
                        <>
                          {moment(item?.updatedData?.start_date).format(
                            "MMM, YYYY"
                          )}{" "}
                          {item?.updatedData?.end_date &&
                            item?.updatedData?.end_date !== null && (
                              <>
                                {" - "}
                                {moment(item?.updatedData?.end_date).format(
                                  "MMM, YYYY"
                                )}{" "}
                              </>
                            )}
                        </>
                      )}
                  </Typography>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // position: "absolute",
                      // right: 0,
                      // bottom: 5,
                    }}
                  >
                    <Tooltip title="Approve" placement="left">
                      <IconButton
                        style={{
                          backgroundColor: "#51c03e",
                          marginRight: "10px",
                        }}
                        size="small"
                        onClick={() => {
                          approveReject([item?.id], "APPROVED", "EDU");
                        }}
                      >
                        <Check style={{ color: "#fff" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reject" placement="right">
                      <IconButton
                        style={{ backgroundColor: "#f64a3f" }}
                        size="small"
                        onClick={() => {
                          approveReject([item?.id], "REJECTED", "EDU");
                        }}
                      >
                        <CloseIcon style={{ color: "#fff" }} />
                      </IconButton>
                    </Tooltip>
                  </div> */}
                </div>
                <Divider
                  variant="middle"
                  style={{ marginLeft: 0, marginRight: 0, marginTop: "10px" }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HistoryIcon style={{ color: "#010101" }} />{" "}
                    <Typography
                      variant="body2"
                      style={{ color: "#010101", paddingLeft: "5px" }}
                    >
                      {moment(item?.create_time)?.format(
                        "MMMM Do YYYY, h:mm a"
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        marginRight: "10px",
                        height: "35px",
                        boxShadow: "none",
                      }}
                      onClick={() => {
                        approveReject([item?.id], "APPROVED");
                      }}
                    >
                      <Typography
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        Accept
                      </Typography>
                    </Button>

                    <Button
                      variant="outlined"
                      style={{
                        borderColor: "#FF0000",
                        height: "35px",
                        boxShadow: "none",
                      }}
                      size="small"
                      onClick={() => {
                        approveReject([item?.id], "REJECTED");
                      }}
                    >
                      <Typography
                        style={{
                          color: "#FF0000",
                          fontSize: "14px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        Decline
                      </Typography>
                    </Button>
                  </div>
                </Box>
              </div>
            </>
          ) : (
            <>
              <div className={classes?.boxShadowClass}>
                <div className={classes?.alignPill}>
                  {" "}
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    {item?.updatedData?.highest_qualification
                      ? item?.updatedData?.highest_qualification + " Education"
                      : ""}{" "}
                  </Typography>
                  {/* <Chip
                    color="primary"
                    deleteIcon={<CloseIcon color="primary" />}
                    variant="outlined"
                    style={{
                      backgroundColor:
                        item?.change_type === "ADD"
                          ? "red"
                          : item?.change_type === "UPDATE"
                          ? "yellow"
                          : "maroon",
                    }}
                    label={
                      item?.change_type === "ADD"
                        ? "Added"
                        : item?.change_type === "UPDATE"
                        ? "Updated"
                        : "Deleted"
                    }
                    className={classes.chip}
                    size="small"
                  /> */}
                  <Tooltip
                    placement="bottom"
                    title={
                      item?.change_type === "ADD"
                        ? "New education added"
                        : item?.change_type === "UPDATE"
                        ? "Update existing education"
                        : "Delete existing education"
                    }
                  >
                    <div
                      color="primary"
                      style={{
                        backgroundColor:
                          item?.change_type === "ADD"
                            ? "#00203F"
                            : item?.change_type === "UPDATE"
                            ? "#2AF598"
                            : "#FF0000",
                      }}
                      className={classes.chip}
                    >
                      <Typography style={{ color: "#fff", fontSize: "14px" }}>
                        {item?.change_type === "ADD"
                          ? "Added"
                          : item?.change_type === "UPDATE"
                          ? "Updated"
                          : "Deleted"}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>

                <Typography variant="body1" /* style={{ marginTop: "10px" }} */>
                  {item?.updatedData?.college_name}{" "}
                </Typography>

                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ marginTop: "5px", marginBottom: "7px" }}
                >
                  {item?.updatedData?.board_name && (
                    <>{item?.updatedData?.board_name},</>
                  )}{" "}
                  {item?.updatedData?.is_grade_system
                    ? "CGPA:" + item?.cupdatedData?.gpa
                    : "Percentage: " + item?.updatedData?.percentage}
                </Typography>
                <div className={classes?.alignPill}>
                  <Typography variant="body2">
                    {item?.updatedData?.start_date &&
                      item?.updatedData?.start_date !== null && (
                        <>
                          {moment(item?.updatedData?.start_date).format(
                            "MMM, YYYY"
                          )}{" "}
                          {item?.updatedData?.end_date &&
                            item?.updatedData?.end_date !== null && (
                              <>
                                {" - "}
                                {moment(item?.updatedData?.end_date).format(
                                  "MMM, YYYY"
                                )}{" "}
                              </>
                            )}
                        </>
                      )}
                  </Typography>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // position: "absolute",
                      // right: 0,
                      // bottom: 5,
                    }}
                  >
                    <Tooltip title="Approve" placement="left">
                      <IconButton
                        style={{
                          backgroundColor: "#51c03e",
                          marginRight: "10px",
                        }}
                        size="small"
                        onClick={() => {
                          approveReject([item?.id], "APPROVED", "EDU");
                        }}
                      >
                        <Check style={{ color: "#fff" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reject" placement="right">
                      <IconButton
                        style={{ backgroundColor: "#f64a3f" }}
                        size="small"
                        onClick={() => {
                          approveReject([item?.id], "REJECTED", "EDU");
                        }}
                      >
                        <CloseIcon style={{ color: "#fff" }} />
                      </IconButton>
                    </Tooltip>
                  </div> */}
                </div>
                <Divider
                  variant="middle"
                  style={{ marginLeft: 0, marginRight: 0 }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HistoryIcon style={{ color: "#010101" }} />{" "}
                    <Typography
                      variant="body2"
                      style={{ color: "#010101", paddingLeft: "5px" }}
                    >
                      {moment(item?.create_time)?.format(
                        "MMMM Do YYYY, h:mm a"
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        marginRight: "10px",
                        height: "35px",
                        boxShadow: "none",
                      }}
                      onClick={() => {
                        approveReject([item?.id], "APPROVED");
                      }}
                    >
                      <Typography
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        Accept
                      </Typography>
                    </Button>

                    <Button
                      variant="outlined"
                      style={{
                        borderColor: "#FF0000",
                        height: "35px",
                        boxShadow: "none",
                      }}
                      size="small"
                      onClick={() => {
                        approveReject([item?.id], "REJECTED");
                      }}
                    >
                      <Typography
                        style={{
                          color: "#FF0000",
                          fontSize: "14px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        Decline
                      </Typography>
                    </Button>
                  </div>
                </Box>
              </div>
            </>
          )}
        </>
      ))}
    </div>
  );
}

export default SavedEducation;
