import {
  COURSE_DATA,
  DEGREE_DATA,
  INDUSTRY_DATA,
  LICENCE_CHECK,
  NAVBAR_EJD_LIST,
  NAVBAR_LIST_DATA,
  PLACEMENT_LIST,
  PLACEMENT_LIST_ENDED,
  SELECTED_COURSE_DATA,
  SPEC_DATA,
  STUDENT_LINK,
  TEAM_MEMBER_LIST_API,
  GROUP_VIEW
} from "./action";

export const commonData = (state = {}, action) => {
  switch (action.type) {
    case INDUSTRY_DATA:
      return { ...state, industryData: action.payload };
    case COURSE_DATA:
      return { ...state, allCourseData: action.payload };
    case SELECTED_COURSE_DATA:
      return { ...state, selectedCourseData: action.payload };
    case STUDENT_LINK:
      return { ...state, studentLink: action.payload };
    case PLACEMENT_LIST:
      return { ...state, placementList: action.payload };
    case PLACEMENT_LIST_ENDED:
      return { ...state, placementListEnded: action.payload };
    case TEAM_MEMBER_LIST_API:
      return { ...state, teamMember: action.payload };
    case DEGREE_DATA:
      return { ...state, degrees: action.payload };
    case SPEC_DATA:
      return { ...state, specialization: action.payload };
    case NAVBAR_LIST_DATA:
      return { ...state, searchList: action.payload };
    case NAVBAR_EJD_LIST:
      return { ...state, ejdList: action.payload };
    case LICENCE_CHECK:
      return { ...state, licenceData: action.payload };
    case GROUP_VIEW:
      return { ...state, groupView: action.payload };
    default:
      return state;
  }
};
