import { LOGIN_API, USER_DETAILS, TEAM_MEMBER_DETAILS } from "./action";

const initialState = {
  isLoggedIn:false
}

export const LoginApi = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_API:
      return { ...state, details: action.payload};
    default:
      return state;
  }
};

export const UserDetails = (state=initialState, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {...state, user_details: action.payload};
    case TEAM_MEMBER_DETAILS:
      return {...state, user_details : {...state.user_details, user_detail: action.payload} }
    // case LOGGED_IN:
    //   return { ...state, isLoggedin: action.payload};
    default:
      return state;
  }
};