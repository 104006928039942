import { Typography } from "@material-ui/core";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import NoDataFunc from "../../NoDataSVG/index1";

const DATA_COUNT = 7;
const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };

function DoughnutChart({ data, data1 }) {
  return (
    <>
      {data?.length >= 1 && data1?.length >= 1 ? (
        <Doughnut
          data={{
            labels: data,
            datasets: data1,
          }}
          height={400}
          width={600}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
              },
            },
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <NoDataFunc width={"300Px"} height={"200px"} />
          <Typography style={{ fontSize: "16px" }}>
            No Students Registered
          </Typography>{" "}
        </div>
      )}
    </>
  );
}

export default DoughnutChart;
