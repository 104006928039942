import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  collegeGroupListSelectorFn,
  isCollegeGroupFn,
} from "../api/SignIn/selector";
import { BackendBaseApi } from "../constants/constants";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import { generateCsvForParams } from "../utility/commaSeparatedStrings";
import { EDGAllAPIUrl } from "../api/University/APIs/action";

const useNavBarCollegeGroupList = (selectedGroup, setLoading, query = null) => {
  console.log("hererre");
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();

  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const collegeGroupList = useSelector(collegeGroupListSelectorFn);

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `?&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `?&group_id=${selectedGroup.id}`;
      else
        return `?&group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `?&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      var url = `${
        BackendBaseApi.SANCHALAN
      }api/university/university_count/${checkSelectedGroup()}`;
      if (query) url += `&query=${query}`;
      dispatch(EDGAllAPIUrl(setLoading, alert, history, location, url));
    }
  }, [selectedGroup]);

  return;
};

export default useNavBarCollegeGroupList;
