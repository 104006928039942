import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { isEducationGroupFn } from "../../api/Domain/selector";
import { CardData } from "../../api/Help/action";
import { truncateQuestion } from "../../utility/ellipsify";
import SearchFaqs from "./Search";

const useStyles = makeStyles((_) => ({
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  paper: {
    padding: "30px",
    borderRadius: "8px",
    boxShadow: "none",
    // height: "270px",
  },
  email: {
    padding: "30px",
    boxShadow: "none",
    borderRadius: "8px",
  },
  img: {
    borderRadius: "8px",
    marginLeft: "35px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  heading: {
    fontWeight: "bold",
  },
  // btn: {
  //   display: "flex",
  //   justifyContent: "flex-start",
  //   padding: "0px",
  //   marginTop: "10px",
  // },
  question: {
    padding: "10px",
    textDecoration: "none",
    marginBottom: "3px",
    width: "100%",
  },
  loader: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translateX(-50%)",
  },
}));

export default function Info() {
  const params = useParams();
  const classes = useStyles();

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isEducationGroup = useSelector(isEducationGroupFn);

  var split_id = params.card_id.split("-");
  var card_id = split_id[split_id?.length - 1];

  useEffect(() => {
    dispatch(CardData(alert, history, location, card_id, isEducationGroup));
  }, [card_id]);

  const cardData = useSelector((state) => state?.Cards?.details);

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to={isEducationGroup ? "/educational-group/help" : "/help"}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" variant="body2">
            Help & Support
          </Typography>
        </Link>
        {/* <Link style={{ textDecoration: "none" }} color="inherit" to={`/group_FAQs/${cardData?.id}`}> */}
        <Typography color="secondary" variant="body2">
          {cardData?.Group_Name}
        </Typography>
        {/* </Link> */}
      </Breadcrumbs>
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ marginTop: "17px", marginBottom: "15px" }}>
          <Typography variant="h2">{cardData?.Group_Name}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            marginTop: "17px",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          <SearchFaqs />
        </Grid>
        <br />

        <Grid item xs={8}>
          <div>
            <div>
              {cardData ? (
                cardData.how_to_use_saas.map((item, index) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography className={classes.heading} variant="body2">
                        {index + 1}
                        {".   "}
                        {item.Question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          marginTop: "-20px",
                        }}
                      >
                        <Typography
                          style={{ color: "#6c757d", fontSize: "14px" }}
                        >
                          {truncateQuestion(item?.Content_QuestionList)}
                        </Typography>
                        <Link
                          to={
                            isEducationGroup
                              ? `/educational-group/questions/${item?.Question?.toLowerCase()?.replace(
                                  / /g,
                                  "-"
                                )}-${item?.id}`
                              : `/questions/${item?.Question?.toLowerCase()?.replace(
                                  / /g,
                                  "-"
                                )}-${item?.id}`
                          }
                          key={index}
                        >
                          <Button
                            variant="text"
                            color="primary"
                            className={classes.btn}
                          >
                            View in Details
                          </Button>
                        </Link>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <div className={classes.loader}>
                  <CircularProgress size={60} />
                </div>
              )}
            </div>

            {/* <Accordion>
                 <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading} variant="body2">
                    How to Add an Individual Company?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography style={{ color: "#6c757d", fontSize: "14px" }}>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    </Typography>
                    <Button
                      variant="text"
                      color="primary"
                      className={classes.btn}
                    >
                      View in Details
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion> */}
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">Not finding the help you need?</Typography>
          <br />
          <Link
            to={isEducationGroup ? `/educational-group/contact-us` : `/contact`}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{
                boxShadow: "none",
                borderRadius: "8px",
                height: "40px",
                display: "flex",
              }}
            >
              Contact us
            </Button>
          </Link>
        </Grid>
      </Grid>
      {/* </Layout> */}
    </>
  );
}
