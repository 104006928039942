import { Typography, Button, makeStyles, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Charst from "react-apexcharts";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { sanchalanAxiosGetReq } from "../../../api/BaseApi/apiBase";
import { useAlert } from "react-alert";
import StarPerformer from "./StarPerformer";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Skeleton } from "@material-ui/lab";

const series = [
  {
    data: [25, 66, 41, 59, 25],
  },
];

const spark1 = {
  chart: {
    sparkline: {
      enabled: true,
    },
    dropShadow: {
      enabled: true,
      top: 1,
      left: 1,
      blur: 2,
      opacity: 0.2,
    },
    // toolbar: {
    //   show: false,
    // },
  },

  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
  },
  grid: {
    show: false,
    padding: {
      top: 0,
      bottom: 0,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  colors: ["#079B28"],
  plotOptions: {
    line: {
      horizontal: false,
      columnWidth: "80%",
      barHeight: "100%",
    },
  },
  dataLabels: {
    enabled: false,
  },

  tooltip: {
    enabled: false,
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      show: false,
    },
  },
  yaxis: {
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      show: false,
    },
  },
  tooltip: {
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function formatter(val) {
          return "";
        },
      },
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #E2E2E2",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "stretch",
    borderRadius: 8,
    width: "100%",
  },
  borderCard: {
    borderRight: "1px solid #E2E2E2",
    display: "flex",
    alignItems: "center",
    width: "22%",
    justifyContent: "center",
    [theme.breakpoints.only("md")]: {
      width: "22%",
    },
  },
  alignCard: {
    width: "100%",
    margin: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "-webkit-fill-available",
    [theme.breakpoints.only("md")]: {
      margin: "15px",
    },
  },
  midCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: "#606060",
    fontWeight: 400,
  },
  number: {
    color: "#606060",
    marginTop: "15px",
  },
  info: {
    marginTop: "15px",
    fontSize: 14,
  },
  lastCard: {
    margin: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "-webkit-fill-available",
    [theme.breakpoints.only("md")]: {
      margin: "15px",
    },
  },
  borderFirstCard: {
    borderRight: "1px solid #E2E2E2",
    display: "flex",
    alignItems: "center",
    width: "15%",
    [theme.breakpoints.only("md")]: {
      width: "13%",
    },
  },
  firstCard: {
    margin: "15px",
    width: "100%",
    height: "-webkit-fill-available",
    [theme.breakpoints.only("md")]: {
      margin: "15px",
    },
  },
}));

function CampusPlacementReportsCard({
  selectedPlacement,
  collegeId,
  setStarPerformer,
  starPerformer,
  setLoadingReportCard = () => {},
}) {
  const classes = useStyles();
  const alert = useAlert();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedPlacement) {
      setLoading(true);
      setLoadingReportCard(true);
      sanchalanAxiosGetReq(
        `api/placement_cycles/get_line_chart_data/?college_id=${collegeId}&placement_id=${selectedPlacement?.id}`
      )
        .then((res) => {
          if (res?.data?.success) setDetails(res?.data?.data);
          setLoading(false);
          setLoadingReportCard(false);
        })
        .catch((err) => {
          alert.error(err?.response?.data?.error);
          setLoading(false);
          setLoadingReportCard(false);
        });
    }
  }, [selectedPlacement]);

  const getSeries = (index) => {
    if (details?.length)
      return [
        {
          data: details[index]?.graph_data,
        },
      ];
    else
      return [
        {
          data: [0, 0, 0],
        },
      ];
  };
  const handleClickStar = () => {
    setStarPerformer((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      <div className={classes.borderFirstCard}>
        <div className={classes.firstCard}>
          <Typography variant="h6" className={classes.title}>
            {loading ? (
              <Skeleton variant="rectangular" width={110} height={12} />
            ) : (
              details?.length && details[0]?.name
            )}
          </Typography>
          <Typography
            variant="h6"
            className={classes.number}
            style={{ marginBottom: 28 }}
          >
            {loading ? (
              <Skeleton variant="rectangular" width={50} height={12} />
            ) : (
              details?.length && details[0]?.value
            )}
          </Typography>
        </div>
      </div>
      <div className={classes.borderCard}>
        <div className={classes.alignCard}>
          <div
            style={{
              display: "flex",
              alignItems: loading ? "flex-start" : " center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="h6" className={classes.title}>
                {loading ? (
                  <Skeleton variant="rectangular" width={110} height={12} />
                ) : (
                  details?.length && details[1]?.name
                )}
              </Typography>
              <Typography variant="h6" className={classes.number}>
                {loading ? (
                  <Skeleton variant="rectangular" width={50} height={12} />
                ) : (
                  details?.length && details[1]?.value
                )}
              </Typography>
            </div>
            <div>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={80}
                  style={{ borderRadius: 8 }}
                />
              ) : (
                <Charst
                  options={spark1}
                  series={getSeries(1)}
                  type="line"
                  height="60px"
                  width="100px"
                />
              )}
            </div>
          </div>
          <Typography variant="body2" className={classes.info}>
            {loading ? (
              <Skeleton variant="rectangular" width={180} height={12} />
            ) : (
              details?.length && details[1]?.percent
            )}
          </Typography>
        </div>
      </div>
      <div className={classes.borderCard}>
        <div className={classes.alignCard}>
          <div
            className={classes.midCard}
            style={{ alignItems: loading ? "flex-start" : " center" }}
          >
            <div>
              <Typography variant="h6" className={classes.title}>
                {loading ? (
                  <Skeleton variant="rectangular" width={110} height={12} />
                ) : (
                  details?.length && details[2]?.name
                )}
              </Typography>
              <Typography variant="h6" className={classes.number}>
                {loading ? (
                  <Skeleton variant="rectangular" width={50} height={12} />
                ) : (
                  details?.length && details[2]?.value
                )}
              </Typography>
            </div>
            <div>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={80}
                  style={{ borderRadius: 8 }}
                />
              ) : (
                <Charst
                  options={spark1}
                  series={getSeries(2)}
                  type="line"
                  height="60px"
                  width="100px"
                />
              )}
            </div>
          </div>
          <Typography variant="body2" className={classes.info}>
            {loading ? (
              <Skeleton variant="rectangular" width={180} height={12} />
            ) : (
              details?.length && details[2]?.percent
            )}
          </Typography>
        </div>
      </div>

      <div className={classes.borderCard}>
        <div className={classes.alignCard}>
          <div
            className={classes.midCard}
            style={{ alignItems: loading ? "flex-start" : " center" }}
          >
            <div>
              <Typography variant="h6" className={classes.title}>
                {loading ? (
                  <Skeleton variant="rectangular" width={110} height={12} />
                ) : (
                  details?.length && details[3]?.name
                )}
              </Typography>
              <Typography variant="h6" className={classes.number}>
                {loading ? (
                  <Skeleton variant="rectangular" width={50} height={12} />
                ) : (
                  details?.length && details[3]?.value
                )}
              </Typography>
            </div>
            <div>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={80}
                  style={{ borderRadius: 8 }}
                />
              ) : (
                <Charst
                  options={spark1}
                  series={getSeries(3)}
                  type="line"
                  height="60px"
                  width="100px"
                />
              )}
            </div>
          </div>
          <Typography variant="body2" className={classes.info}>
            {loading ? (
              <Skeleton variant="rectangular" width={180} height={12} />
            ) : (
              details?.length && details[3]?.percent
            )}
          </Typography>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", width: "20%" }}>
        <div className={classes.lastCard}>
          <div>
            <Typography variant="h6" className={classes.title}>
              {loading ? (
                <Skeleton variant="rectangular" width={110} height={12} />
              ) : (
                details?.length && details[4]?.name
              )}
            </Typography>
            <Typography
              variant="h6"
              className={classes.number}
              //style={{ marginBottom: 7 }}
            >
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={50}
                  height={12}
                  style={{ marginTop: 6, marginBottom: 10 }}
                />
              ) : (
                details?.length && details[4]?.value
              )}
            </Typography>
          </div>
          <div>
            <Button
              variant="text"
              color="primary"
              endIcon={<ArrowRightAltIcon />}
              disabled={starPerformer}
              style={{
                padding: 0,
                fontWeight: 600,
                fontSize: 14,
                cursor: "pointer",
                marginTop: 10,
                marginBottom: "-2px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleClickStar}
              className={classes.info}
            >
              Check Star Performer
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusPlacementReportsCard;
