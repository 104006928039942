import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";



const studentColumns = [
  { id: "student_name", label: "Student Name", minWidth: 130 },
  {
    id: "email",
    label: "Email ID",
    minWidth: 140,
    align: "center",
  },

  {
    id: "error",
    label: "Error Message",
    minWidth: 150,
    align: "center",
  },
];

const teamColumns = [
  { id: "team_name", label: "Team Name", minWidth: 130 },
  {
    id: "email",
    label: "Email ID",
    minWidth: 140,
    align: "center",
  },

  {
    id: "error",
    label: "Error Message",
    minWidth: 150,
    align: "center",
  },
];



const useStyles = makeStyles({
  root: {
    width: "100%",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    marginBottom: "10px",
  },
  container: {
    maxHeight: 340,
    borderRadius: "8px",
  },
});

export default function UploadTable({rowEntries}) {

  
  let columns = [
  { id: "company_name", label: "Company Name", minWidth: 130 },
  {
    id: "poc_email",
    label: "Email ID",
    minWidth: 140,
    align: "center",
  },

  {
    id: "error",
    label: "Error Message",
    minWidth: 150,
    align: "center",
  },
];
 
  if (rowEntries?.length > 0)
  {
    const firstColumn = (Object?.keys(rowEntries[0])?.[0]);
    if(firstColumn ==='team_name')
    columns = teamColumns;
    if(firstColumn ==='student_name')
    columns = studentColumns;
       
  };


  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);

  //   const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  //   };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography style={{ fontWeight: "400" }} variant="body2">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowEntries?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
