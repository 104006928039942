import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import CollegeImage from "../../assets/img/company.jpg";
import SignUpForm from "../Input/SignUpForm";
import LeftSideComponent from "../Public/LeftSideComponent";
import RightSideComponent from "../Public/RightSideComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    //  marginTop: "50px",
  },
  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default function Form({ state, setState, errors, setErrors }) {
  const classes = useStyles();

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <LeftSideComponent />
        <RightSideComponent 
            ChildComponent={SignUpForm}
            state={state}
            setState={setState}
            errors={errors}
            setErrors={setErrors}>
        </RightSideComponent>
        
        {/* <Grid
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          elevation={6}
          square
        >
          <SignUpForm
            state={state}
            setState={setState}
            errors={errors}
            setErrors={setErrors}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="overline">Powered by</Typography>
            <img src={logo} height="20px" width="80px" />
          </div>
        </Grid> */}
      </Grid>
    </>
  );
}
