import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { Typography } from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { InfoOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  toolTipIcon: { color: theme.palette.primary.main },
}));

const InfoToolTip = ({ rejectedDate, label }) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="bottom-start"
      title={
        <>
          {rejectedDate && (
            <Typography style={{ fontSize: "14px", color: "#fff" }}>
              {`Rejected On: ${rejectedDate?.slice(0, 10)}`}
            </Typography>
          )}

          <Typography style={{ fontSize: "14px", color: "#fff" }}>
            {label ?? "NA"}
          </Typography>
        </>
      }
    >
      {label && (
        <InfoOutlined
          className={classes?.toolTipIcon}
          style={{ width: "17px", height: "17px" }}
        />
      )}
    </Tooltip>
  );
};

export default InfoToolTip;
