import { Button, Chip, Dialog, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import pSBC from "shade-blend-color";
import { collegeUserDetailSelectorFn } from "../../../api/SignIn/selector";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  button: {
    borderRadius: "8px",
    //  margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

  },
}));
const ApproveOffCampusData = ({
  deleteResponse,
  deleteUser,
  selectedRowsData,
  setSelectedRowsData,
  setSelectedID,
  collegeId,
  approveRejectOffCampus,
  dataLoading,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [buttonName, setButtonName] = useState("");
  const [buttonNameLower, setButtonNameLower] = useState("");
  const [status, setStatus] = useState("");

  const [trigger, setTrigger] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (deleteUser) {
      setButtonName("Approve");
      setButtonNameLower("approve");
      setStatus("APPROVE");
    } else {
      setButtonName("Reject");
      setButtonNameLower("reject");
      setStatus("REJECT");
    }
  }, [selectedRowsData?.length, open]);

  const userDetails = useSelector(collegeUserDetailSelectorFn);

  const handleSubmit = (status) => {
    var arr = [];
    selectedRowsData?.map((item) => arr.push(item.id));

    if (status === "APPROVE") {
      var data = {
        college_id: [collegeId],
        offcampus_id: arr,
        note: [
          {
            user_id: userDetails.user_id,
            user_name: userDetails.name,
            designation: userDetails.designation,
            action_type: `Placement data ${buttonName}`,
          },
        ],
        is_approved: `${buttonName}d`,
      };
      approveRejectOffCampus(data);
    } else {
      var data = {
        college_id: [collegeId],
        offcampus_id: arr,
        note: [
          {
            user_id: userDetails.user_id,
            user_name: userDetails.name,
            designation: userDetails.designation,
            action_type: `Placement data ${buttonName}`,
          },
        ],
        is_approved: `${buttonName}ed`,
      };
      approveRejectOffCampus(data);
    }
    if (deleteResponse) handleClose();

    setTrigger(!trigger);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };
  const [showMore, setShowMore] = React.useState(false);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        onClick={handleClickOpen}
      >
        {buttonName}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "400px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Are you sure. <br />
              You want to {buttonNameLower + " "}
              the selected Pending Student
              {selectedRowsData.length > 1 ? "s" : ""}
            </Typography>

            <div component="ul" className={classes.root}>
              {!showMore
                ? selectedRowsData.slice(0, 5).map((data, index) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : selectedRowsData.map((data, index) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDelete(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
            </div>
            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
          </div>
        </div>

        <div className={classes.btngrid}>
          <Button
            variant="outlined"
            style={{ minWidth: "160px" }}
            className={classes.button}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            autoFocus
            className={classes.button}
            style={{ minWidth: "160px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit(status);
            }}
          >
            {dataLoading ? <CustomButtonCircularProgress /> : buttonName}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ApproveOffCampusData;
