import { Chip, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { EJDJobDeleteAPI, JobDeleteAPI } from "../../../api/JobPost/action";
// import CircularProgressBar from "../../CircularProgressBar";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },

  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    marginRight: "10px",
    boxShadow: "none",
    padding: "10px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function DeleteJobDialog({
  selectedRowsData,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  jobList,
  fromEJD,
  collegeId,
  setCallNavbar,
}) {
  const classes = useStyles();

  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseNew = () => {
    setSelectedRowsData([]);
    setOpen(false);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };
  const [showMore, setShowMore] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();

  const handleSubmit = () => {
    if (!deleteReason) {
      setDeleteReasonError("Please Enter Reason");
      return;
    }
    setCallNavbar(false);
    var arr = [];
    selectedRowsData?.map((item) => arr.push(item.id));
    var data = {
      college_id: collegeId,
      object_id: arr,
      reason: deleteReason,
    };
    if (!fromEJD)
      dispatch(
        JobDeleteAPI(
          data,
          alert,
          history,
          location,
          jobList,
          handleCloseNew,
          collegeId,
          setCallNavbar,
          setLoading
        )
      );
    else
      dispatch(
        EJDJobDeleteAPI(
          data,
          alert,
          history,
          location,
          jobList,
          handleCloseNew,
          collegeId,
          setCallNavbar,
          setLoading
        )
      );
  };

  return (
    <div>
      <Button
        color="primary"
        size="small"
        className={classes.button}
        variant="contained"
        onClick={handleClickOpen}
      >
        Delete
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">
              Are you sure, want to Delete these Jobs?
            </Typography>
          </div>

          <div component="ul" className={classes.root}>
            {!showMore
              ? selectedRowsData.slice(0, 5).map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.job_title}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : selectedRowsData.map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.job_title}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}{" "}
          </div>

          {selectedRowsData?.length > 5 && (
            <Button
              className={classes.button}
              onClick={() => {
                setShowMore(!showMore);
              }}
              style={{
                padding: "5px",
                marginLeft: "7px",
                display: "flex",
                justifyContent: "flex-start",
              }}
              endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              color="primary"
            >
              {showMore ? "Hide" : "View All"}
            </Button>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            multiline
            rows="2"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label={"Why you want to delete these jobs?"}
            value={deleteReason}
            onChange={(e) => {
              setDeleteReason(e.target.value);
              setDeleteReasonError("");
            }}
            error={deleteReasonError ? true : false}
            helperText={<span>{deleteReasonError}</span>}
          />
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              className={classes.button}
              onClick={handleClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: "12px" }}
              className={classes.button}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading ? <CustomButtonCircularProgress /> : "Delete"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
