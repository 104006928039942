import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "8px",
  },
  paper: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  newBtnStyle: {
    maxWidth: "200px",
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
}));

export default function Achievements({
  achievements,
  postAchievements,
  achivementDataLoading,
  from,
  approvalRequestCounts,
  redirectionData,
}) {
  const [tempAchievements, setTempAchievements] = useState([]);
  const [addedAchievement, setAddedAchievement] = useState("");
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const handleDelete = (index) => {
    setTempAchievements((tempAchievements) =>
      tempAchievements.filter(
        (_, achievementIndex) => achievementIndex !== index
      )
    );
  };

  useEffect(() => {
    setTempAchievements(achievements ? achievements : []);
  }, [achievements]);

  const addAchievement = () => {
    setTempAchievements([...tempAchievements, addedAchievement]);
    setAddedAchievement("");
  };

  const validateData = () => {
    if (tempAchievements.length === 0) {
      alert?.error(`Please add atleast 1 ${from || "achievement"}`);
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className={classes.paper}>
      <Typography variant="h6">{`${from || "Achievements"}`}</Typography>
      {from ? (
        <Grid
          item
          xs={12}
          style={
            from && approvalRequestCounts?.EXTRA_CURR
              ? { pointerEvents: "none", opacity: 0.4 }
              : {}
          }
        >
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={10} style={{ display: "grid" }}>
                <TextField
                  id="outlined-basic"
                  label={from || "Add Achievement"}
                  // size="small"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  onChange={(e) => {
                    setAddedAchievement(e.target.value);
                  }}
                  value={addedAchievement}
                />
              </Grid>
              <Grid item xs={2} style={{ display: "grid" }}>
                <Button
                  style={{ display: "flex", borderRadius: "8px" }}
                  startIcon={<AddCircleOutlineIcon />}
                  variant="text"
                  color="primary"
                  disabled={addedAchievement ? false : true}
                  onClick={() => {
                    addAchievement();
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className={classes.root}>
            <div style={{ marginTop: "7px" }}>
              <ul style={{ marginLeft: "-22px", color: "#000" }}>
                {tempAchievements &&
                  tempAchievements.map((data, index) => {
                    return (
                      <li>
                        <Grid container spacing={2} className={classes.div}>
                          <Grid item xs={10}>
                            <Typography
                              key={index}
                              color="primary"
                              variant="outlined"
                              margin="normal"
                              InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                              }}
                            >
                              {data}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                handleDelete(index);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          style={
            approvalRequestCounts?.ACHIEVE
              ? { pointerEvents: "none", opacity: 0.4 }
              : {}
          }
        >
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={10} style={{ display: "grid" }}>
                <TextField
                  id="outlined-basic"
                  label={from || "Add Achievement"}
                  // size="small"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  onChange={(e) => {
                    setAddedAchievement(e.target.value);
                  }}
                  value={addedAchievement}
                />
              </Grid>
              <Grid item xs={2} style={{ display: "grid" }}>
                <Button
                  style={{ display: "flex", borderRadius: "8px" }}
                  startIcon={<AddCircleOutlineIcon />}
                  variant="text"
                  color="primary"
                  disabled={addedAchievement ? false : true}
                  onClick={() => {
                    addAchievement();
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className={classes.root}>
            <div style={{ marginTop: "7px" }}>
              <ul style={{ marginLeft: "-22px", color: "#000" }}>
                {tempAchievements &&
                  tempAchievements.map((data, index) => {
                    return (
                      <li>
                        <Grid container spacing={2} className={classes.div}>
                          <Grid item xs={10}>
                            <Typography
                              key={index}
                              color="primary"
                              variant="outlined"
                              margin="normal"
                              InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                              }}
                            >
                              {data}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                handleDelete(index);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Grid>
      )}

      {from ? (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.newBtnStyle}
            /* disabled={
          tempAchievements && tempAchievements.length > 0 ? false : true
        } */
            onClick={
              approvalRequestCounts?.EXTRA_CURR
                ? () => {
                    history.push({
                      pathname: `/student-profile-request/${redirectionData?.name.replace(
                        / /g,
                        "_"
                      )}-${redirectionData?.userId}`,
                    });
                  }
                : () => {
                    if (validateData()) {
                      postAchievements(tempAchievements);
                    }
                  }
            }
          >
            {achivementDataLoading ? (
              <CustomButtonCircularProgress />
            ) : approvalRequestCounts?.EXTRA_CURR ? (
              `Approve Request (${approvalRequestCounts?.EXTRA_CURR ?? 0})`
            ) : (
              "Save"
            )}
          </Button>
        </div>
      ) : (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.newBtnStyle}
            /* disabled={
            tempAchievements && tempAchievements.length > 0 ? false : true
          } */
            onClick={
              approvalRequestCounts?.ACHIEVE
                ? () => {
                    history.push({
                      pathname: `/student-profile-request/${redirectionData?.name.replace(
                        / /g,
                        "_"
                      )}-${redirectionData?.userId}`,
                    });
                  }
                : () => {
                    if (validateData()) {
                      postAchievements(tempAchievements);
                    }
                  }
            }
          >
            {achivementDataLoading ? (
              <CustomButtonCircularProgress />
            ) : approvalRequestCounts?.ACHIEVE ? (
              `Approve Request (${approvalRequestCounts?.ACHIEVE ?? 0})`
            ) : (
              "Save"
            )}
          </Button>
        </div>
      )}
    </div>
  );
}
