import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import pSBC from "shade-blend-color";
import {
  sanchalanAxiosGetReq,
  sanchalanAxiosPostReq,
} from "../../../api/BaseApi/apiBase";
import { collegeDetailSelectorFn } from "../../../api/SignIn/selector";
import Share from "../../../assets/png/share.png";
import { emailRegex } from "../../../utility/regex";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import History from "../../Table/History";
import { getAllColumnsStudentField } from "../../../utility/allColumns";
import { isGradeSystemFn } from "../../../api/Domain/selector";

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  button: {
    borderRadius: "8px",
    boxShadow: "none",
    width: "100%",
    height: "100%",
    display: "flex",
  },

  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  share: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  floatingLabelFocusStyle: {
    //color: "#7E7E7E",
    fontSize: "14px",
    color: "#C5C4BA",
  },
}));

export default function ShareApplicant({
  data,
  filters,
  handleOpenShareSuccess,
  shareSuccessData,
  setShareSuccessData,
  selectedID,
  setValidationData,
  validationData,
}) {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(true);

  const dataSelectedFields = [
    {
      dataID: 1,
      key: "enrollment_id",
      dataName: "Enrollment Id*",
      selected: true,
      disabled: true,
    },
    {
      dataID: 2,
      key: "name",
      dataName: "Name*",
      selected: true,
      disabled: true,
    },
    {
      dataID: 3,
      key: "email",
      dataName: "Email",
      selected: true,
      disabled: false,
    },
    {
      dataID: 4,
      key: "phone",
      dataName: "Phone Number",
      selected: true,
      disabled: false,
    },
    {
      dataID: 5,
      key: "batch",
      dataName: "Current Batch",
      selected: true,
      disabled: false,
    },
    {
      dataID: 6,
      key: "degree_name",
      dataName: "Current Degree",
      selected: true,
      disabled: false,
    },
    {
      dataID: 7,
      key: "course_name",
      dataName: "Current Course",
      selected: true,
      disabled: false,
    },

    {
      dataID: 8,
      key: "ugCollege",
      dataName: "College Name(UG)",
      selected: true,
      disabled: false,
    },

    {
      dataID: 9,
      key: "ugdegree",
      dataName: "Degree(UG)",
      selected: true,
      disabled: false,
    },
    {
      dataID: 10,
      key: "ugSpecialization",
      dataName: "Specialization(UG)",
      selected: true,
      disabled: false,
    },
    {
      dataID: 11,
      key: "ugPercentage",
      dataName: "Percentage(UG)",
      selected: true,
      disabled: false,
    },

    {
      dataID: 12,
      key: "pgCollege",
      dataName: "College Name(PG)",
      selected: true,
      disabled: false,
    },

    {
      dataID: 13,
      key: "pgdegree",
      dataName: "Degree(PG)",
      selected: true,
      disabled: false,
    },
    {
      dataID: 14,
      key: "pgSpecialization",
      dataName: "Specialization(PG)",
      selected: true,
      disabled: false,
    },
    {
      dataID: 15,
      key: "pgPercentage",
      dataName: "Percentage(PG)",
      selected: true,
      disabled: false,
    },

    {
      dataID: 16,
      key: "phdCollege",
      dataName: "College Name(Phd)",
      selected: true,
      disabled: false,
    },

    {
      dataID: 17,
      key: "phdDegree",
      dataName: "Degree(Phd)",
      selected: true,
      disabled: false,
    },
    {
      dataID: 18,
      key: "phdSpecialization",
      dataName: "Specialization(Phd)",
      selected: true,
      disabled: false,
    },
    {
      dataID: 19,
      key: "phdPercentage",
      dataName: "Percentage(Phd)",
      selected: true,
      disabled: false,
    },

    {
      dataID: 20,
      key: "secondaryBoard",
      dataName: "Secondary Board",
      selected: true,
      disabled: false,
    },
    {
      dataID: 21,
      key: "secondaryPercentage",
      dataName: "Secondary Percentage",
      selected: true,
      disabled: false,
    },

    {
      dataID: 22,
      key: "seniorSecondaryBoard",
      dataName: "Senior Secondary Board",
      selected: true,
      disabled: false,
    },
    {
      dataID: 23,
      key: "seniorSecondaryPercentage",
      dataName: "Senior Secondary Percentage",
      selected: true,
      disabled: false,
    },
    {
      dataID: 24,
      key: "gender",
      dataName: "Gender",
      selected: true,
      disabled: false,
    },
    {
      dataID: 25,
      key: "applied_on",
      dataName: "Applied On",
      selected: true,
      disabled: false,
    },
    {
      dataID: 26,
      key: "assignment",
      dataName: "Assignment",
      selected: true,
      disabled: false,
    },
  ];

  const [selectedFields, setSelectedFields] =
    React.useState(dataSelectedFields);
  const [columnsShown, setColumnsShown] = useState([]);

  const handleChange = (event) => {
    let updatedFields = [];

    const togglevalue = (id) => {
      updatedFields = selectedFields?.map((item) => {
        if (item.dataID === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      setSelectedFields(updatedFields);
    };

    const eventLength = event?.target?.value?.length - 1;
    // Name and enrolment Id have been made to be selected by default.

    if (
      !(
        event?.target?.value[eventLength] === 1 ||
        event?.target?.value[eventLength] === 2
      )
    ) {
      togglevalue(event?.target?.value[eventLength]);
    }

    let newlist = updatedFields?.map(function (i) {
      if (i?.selected) return i?.dataName;
    });
    const newlist2 = newlist?.filter((n) => n);

    setColumnsShown(newlist2);
  };

  let actions = selectedFields?.filter((item) => {
    if (item?.selected === true) {
      return item?.dataName;
    }
  });

  let actionsList = [];
  actions?.forEach(function (obj) {
    actionsList?.push(obj.key);
  });
  actionsList?.unshift("id");
  const isGradeSystem = useSelector(isGradeSystemFn);
  const alert = useAlert();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const [loading, setLoading] = useState(false);

  const initalSharerObject = {
    passcode: null,
    email: [],
    action: [],
    authority: { track: false, download: false },
    current_email: null,
    selectedTableColumns: [],
  };
  const [shareObject, setShareObject] = useState(initalSharerObject);

  const initialShareObjectError = {
    passcode: null,
    current_email: null,
    selectedTableColumns: null,
  };
  const [shareObjectError, setShareObjectError] = useState(
    initialShareObjectError
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShareObject(initalSharerObject);
    setShareObjectError(initialShareObjectError);
    setOpen(false);
  };

  const [generatedLink, setGeneratedLink] = useState();
  const [shareHistory, setShareHistory] = useState([]);

  const [finalFilters, setFinalFilters] = useState({
    ...filters,
    batch: filters?.batch ? filters?.batch : {},
    course: filters?.course ? filters?.course : {},
    degree: filters?.degree ? filters?.degree : {},
    status: null,
  });

  useEffect(() => {
    setFinalFilters({
      ...filters,
      batch: filters?.batch ? filters?.batch : {},
      course: filters?.course ? filters?.course : {},
      degree: filters?.degree ? filters?.degree : {},
      status:
        filters.status_active && filters.status_blocked
          ? null
          : filters.status_active
          ? "ACTIVE"
          : null,
    });
  }, [filters]);

  useEffect(() => {
    getSharerHistory();
  }, []);

  useEffect(() => {
    getSharerHistory();
  }, [open]);

  const collegeId = parseInt(collegeDetails?.college_id);

  const getSharerHistory = async () => {
    const url = `api/manage_student/student_sharer/history/?college_id=${collegeId}`;

    try {
      const res = await sanchalanAxiosGetReq(url);

      if (res?.data?.success) {
        setShareHistory(res?.data?.data);
      } else {
        alert.success("Some Error Occurred");
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = () => {
    if (validateData()) {
      setLoading(true);
      getSharerLink();
    }
  };

  const getSharerLink = async () => {
    const url = `api/manage_student/student_sharer/`;

    const columnsToShare = [];
    shareObject?.selectedTableColumns?.map((item) => {
      if (item?.field === "degree_name") {
        columnsToShare.push(item?.field);
        columnsToShare.push("degree_id");
      } else if (item?.field === "course_name") {
        columnsToShare.push(item?.field);
        columnsToShare.push("course_id");
      } else if (item?.field === "job_title") {
        columnsToShare.push(item?.field);
        columnsToShare.push("job_id");
      } else return columnsToShare.push(item?.field);
    });

    let data = {
      college_id: [parseInt(collegeDetails?.college_id)],
      user_id: collegeDetails?.user_id,
      sharer_user_name:
        collegeDetails?.first_name + " " + collegeDetails?.last_name,
      applied_filter: finalFilters,
      student_id: selectedID ? selectedID : [],
      passcode: shareObject?.passcode,
      action_performed: {
        // email: shareObject?.email,
        // action: actionsList,
        // authority: shareObject?.authority,
        email: shareObject?.email,
        action: columnsToShare,
        authority: shareObject?.authority,
      },
    };

    try {
      const res = await sanchalanAxiosPostReq(url, data);

      if (res?.data?.success) {
        setLoading(false);
        setShareObject({
          ...shareObject,
          passcode: "",
          email: [],
          action: [],
          authority: { track: false, download: false },
          current_email: null,
        });
        handleOpenShareSuccess();
        setGeneratedLink(res?.data?.data?.link);
        setValidationData({
          link: res?.data?.data?.link,
          passcode: shareObject?.passcode,
        });
        handleClose();
        setSelectedFields(dataSelectedFields);
        setColumnsShown([]);
      } else {
        setLoading(false);
        alert.success("Some Error Occurred");
      }
    } catch (error) {
      throw error;
    }
  };

  const handleDelete = (index) => {
    if (index !== -1) {
      var arr = [...shareObject?.email];
      arr.splice(index, 1);
      setShareObject({ ...shareObject, email: arr });
    }
  };

  const validateData = () => {
    var passcode_error = null;
    var email_error = null;
    var is_valid = true;
    if (!shareObject?.passcode) {
      passcode_error = "Please enter Passcode";
      is_valid = false;
    }
    setShareObjectError({ shareObjectError, passcode: passcode_error });
    return is_valid;
  };

  const handleChangeCheckTableColumn = (event) => {
    // const {
    //   target: { value },
    // } = event;
    const value = event.target.value;
    //console.log("event", value);
    const preventDuplicate = value.filter((v, i, a) => {
      //console.log("v, i, a", v, i, a);
      // return a.findIndex((t) => t.id === v.id) === i;
      if (!shareObject?.selectedTableColumns?.includes(v.field)) return value;
    });

    //console.log("preventDuplicate", preventDuplicate);
    setShareObject({
      ...shareObject,
      selectedTableColumns:
        typeof preventDuplicate === "string"
          ? preventDuplicate.split(",")
          : preventDuplicate,
    });
    setShareObjectError({ ...shareObjectError, selectedTableColumns: null });
  };
  const [tableColumns, setTableColumns] = useState([]);
  useEffect(() => {
    if (open) {
      setTableColumns(getAllColumnsStudentField(isGradeSystem, true));
    }
  }, [open]);

  useEffect(() => {
    if (open && tableColumns?.length > 0) {
      const columnsToShow = [];
      tableColumns?.map((item) => {
        if (
          !(
            item?.field === "eligible_count" ||
            item?.field === "applied_count" ||
            item?.field === "present_count" ||
            item?.field === "hired_count"
          )
        ) {
          columnsToShow?.push(item);
        }
      });
      setShareObject({
        ...shareObject,
        selectedTableColumns: columnsToShow,
      });
    }
  }, [open, tableColumns]);

  return (
    <div>
      <Button
        variant="contained"
        className={classes.share}
        color="primary"
        onClick={handleClickOpen}
      >
        <img
          src={Share}
          height="12px"
          width="12px"
          style={{ marginRight: "5px" }}
        />
        Share
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <div style={{ padding: "40px" }}>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Share Students Info
            </Typography>

            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item className={classes.grid} xs={10}>
                <div className={classes.paper}>
                  <Typography style={{ color: "gray", fontStyle: "italic" }}>
                    *An unique students details share link will be auto
                    generated once you fill all the below details.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Invite People (Optional)</Typography>
              </Grid>

              <Grid item xs={10} className={classes.grid}>
                <div className={classes.paper}>
                  <TextField
                    variant="outlined"
                    // required
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "16px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                      },
                    }}
                    id="email"
                    label="Email Id"
                    name="email"
                    autoComplete="email"
                    value={shareObject?.current_email}
                    onChange={(e) => {
                      setShareObject({
                        ...shareObject,
                        current_email: e.target.value,
                      });
                      setShareObjectError({
                        ...shareObjectError,
                        current_email: null,
                      });
                    }}
                    error={shareObjectError?.current_email ? true : false}
                    helperText={shareObjectError?.current_email}
                    //autoFocus
                  />
                  {/* <br /> */}
                </div>
              </Grid>

              <Grid
                item
                xs={2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    if (emailRegex.test(shareObject.current_email)) {
                      var arr = [...shareObject.email];
                      arr.push(shareObject.current_email);
                      setShareObject({
                        ...shareObject,
                        email: arr,
                        current_email: "",
                      });
                    } else {
                      alert.error("Invalid Email");
                    }
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginTop: "15px", marginBottom: "10px" }}
            >
              <div>
                {shareObject?.email?.length > 0 &&
                  shareObject?.email?.map((item, index) => (
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="small"
                      onDelete={() => handleDelete(index)}
                      deleteIcon={<CloseIcon />}
                      label={item}
                      className={classes.chip}
                    />
                  ))}
              </div>
            </Grid>
            <div
              style={{
                display: "flex",
                //marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6">
                Student Details to be visible
              </Typography>

              <FormGroup row>
                <>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginRight: "30px",
                    }}
                  >
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                      style={{
                        width: "300px",
                        marginRight: "1 rem",
                        // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                        // borderColor: "none",
                      }}
                    >
                      <InputLabel
                        className={classes.floatingLabelFocusStyle}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Table Columns
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        inputProps={{
                          style: {
                            padding: "16px",
                            borderRadius: "8px",
                          },
                        }}
                        variant="outlined"
                        /* value={columnsShown} */
                        value={shareObject?.selectedTableColumns}
                        label="Select Table Columns"
                        /* onChange={handleChange} */
                        onChange={handleChangeCheckTableColumn}
                        // renderValue={(selected) =>
                        // selected?.map((x) => x?.dataName)?.join(", ")
                        // }
                        renderValue={(selected) =>
                          selected.map((x) => x.headerName).join(", ")
                        }
                        // renderValue={(selected) =>
                        //   selected
                        //     ?.map(function (i) {
                        //       return i;
                        //     })
                        //     ?.join(",")
                        // }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                              maxWidth: "200px",
                              borderRadius: "8px",
                            },
                          },
                        }}
                      >
                        {Array.isArray(tableColumns)
                          ? tableColumns?.map((variant) =>
                              variant?.show ? (
                                <MenuItem
                                  key={variant?.id}
                                  value={variant}
                                  style={{ backgroundColor: "#fff" }}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      shareObject?.selectedTableColumns?.indexOf(
                                        variant
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={variant.headerName} />
                                </MenuItem>
                              ) : null
                            )
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                </>

                <Grid
                  item
                  xs={3}
                  className={classes.grid}
                  style={{ marginLeft: "8 rem" }}
                >
                  <TextField
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    label="Passcode"
                    InputProps={{
                      style: {
                        padding: "16px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                      },
                      inputProps: {
                        maxlength: "6",
                      },
                    }}
                    value={shareObject?.passcode}
                    onChange={(e) => {
                      setShareObject({
                        ...shareObject,
                        passcode: e.target.value,
                      });
                      setShareObjectError({
                        ...shareObjectError,
                        passcode: null,
                      });
                    }}
                    error={shareObjectError?.passcode ? true : false}
                    helperText={shareObjectError?.passcode}
                  />
                </Grid>
              </FormGroup>
            </div>
            <div
              style={{
                display: "flex",
                // marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Choose User Actions
              </Typography>

              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={shareObject?.authority?.download}
                      onChange={(e) => {
                        setShareObject({
                          ...shareObject,
                          authority: {
                            ...shareObject.authority,
                            download: e.target.checked,
                          },
                        });
                      }}
                      name="checkedA"
                    />
                  }
                  label={<Typography variant="body2">Download</Typography>}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={shareObject?.authority?.track}
                      onChange={(e) => {
                        setShareObject({
                          ...shareObject,
                          authority: {
                            ...shareObject.authority,
                            track: e.target.checked,
                          },
                        });
                      }}
                      name="checkedA"
                    />
                  }
                  label={<Typography variant="body2">Filtering</Typography>}
                />
              </FormGroup>
            </div>
            <Accordion
              style={{
                boxShadow: "none",
                marginTop: "-2px",
              }}
              classes={{
                root: classes.MuiAccordionroot,
              }}
              // classes={{
              //   root: classes.test,
              // }}
            >
              <AccordionSummary
                style={{ width: "19%" }}
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ marginLeft: "-10px" }}
                >
                  View History
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px" }}>
                <History
                  data={shareHistory ? shareHistory : []}
                  fromStudent={true}
                />
              </AccordionDetails>
            </Accordion>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{
                  height: "48px",
                  borderRadius: "8px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  marginRight: "10px",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "48px",
                  borderRadius: "8px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
                onClick={() => {
                  if (!loading) {
                    handleSubmit();
                  }
                }}
              >
                {loading ? <CustomButtonCircularProgress /> : "Share"}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
