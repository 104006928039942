import { createSelector } from "reselect";

const userDetailsSelector = (state) => state.UserDetails?.user_details;
export const sidebarSelectorFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.sidebar_data
);
export const collegeDetailSelectorFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.college_details
);
export const collegeUserDetailSelectorFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.user_detail
);
export const userLoggedInSelectorFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.isLoggedin
);
export const userRolesActionsFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.user_actions
);
export const collegeIdFn = createSelector(userDetailsSelector, (user_details) =>
  user_details?.college_details?.college_id
    ? user_details?.college_details?.college_id
    : null
);
export const isEducationGroupFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.is_education_group_admin
);
export const isCollegeGroupFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.is_college_group_admin
);
export const entityInfoSelectorFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.college_details?.entity_info
);
export const collegeListSelectorFn = createSelector(
  userDetailsSelector,
  (user_details) =>
    user_details?.college_details?.college_list ||
    user_details?.college_details?.selected_college
);
export const collegeGroupListSelectorFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.college_group_data
);
export const collegeFormCountSelectorFn = createSelector(
  userDetailsSelector,
  (user_details) => user_details?.college_details?.college_forms
);
export const educationGroupIdFn = createSelector(
  userDetailsSelector,
  (user_details) =>
    user_details?.college_details?.education_group_id
      ? user_details?.college_details?.education_group_id
      : null
);
