import { Grid, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  sanchalanAxiosOffCampusPatchReq,
  sanchalanAxiosOffCampusPostReq,
  sanchalanAxiosPatchReq,
  sanchalanAxiosPostReq,
} from "../../../../api/BaseApi/apiBase";

import { collegeIdFn } from "../../../../api/SignIn/selector";
import { checkForToken } from "../../../../constants/ApiCall";
import CustomCircularProgress from "../../../CircularProgressBar";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import Calendar from "../../../Input/Calendar/blockedDates";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },

  btncancel: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  formControl: {
    minWidth: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
  },
  mstudents: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      thousandsGroupStyle="lakh"
      prefix="₹"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function OffCampusStudentDialogForm({
  setSelectedRowsData,
  selectedRowsData,
  editStudentOffCampus,
  studentListOffCampus,
  refreshData,
  setRefreshData,
  dataLoading,
  handleClose,
  fromGroup,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const [apiCallLoading, setApiCallLoading] = useState(false);

  /* ############################################################################################### */

  const jobTypeOptions = [
    { jobType: "Full Time", id: 7 },
    { jobType: "Internship", id: 1 },
  ];

  //default values for student details state
  let initial_state =
    //selectedRowsData contains the data for all the checked row from data table
    {
      //enrollment_no: null,
      name:
        selectedRowsData?.length === 1
          ? {
              enrollment_no: selectedRowsData[0]?.enrollment_no,
              user_name: selectedRowsData[0]?.name,
              user_id: selectedRowsData[0]?.user_id,
              id: selectedRowsData[0]?.id,
              status: selectedRowsData[0]?.status,
            }
          : null,
      company_name:
        selectedRowsData?.length === 1
          ? selectedRowsData[0].company_name
          : null,
      job_title:
        selectedRowsData?.length === 1 ? selectedRowsData[0].job_title : null,
      job_type_id:
        selectedRowsData?.length === 1
          ? {
              id: selectedRowsData[0]?.job_type_id,
              jobType:
                selectedRowsData[0]?.job_type_id === 1
                  ? "Internship"
                  : selectedRowsData[0]?.job_type_id === 3
                  ? "Trainer"
                  : "Full Time",
            }
          : null,

      ctc: selectedRowsData?.length === 1 ? selectedRowsData[0]?.ctc : null,
      placed_date:
        selectedRowsData?.length === 1
          ? selectedRowsData[0]?.placed_date
          : null,
      is_approved: "Approved",
      is_deleted: false,
    };

  const [studentDetails, setStudentDetails] = useState(initial_state);

  const [studentDetailsError, setStudentDetailsError] = useState({
    //enrollment_no: null,
    name: null,
    company_name: null,
    job_title: null,
    job_type_id: null,
    ctc: null,
    placed_date: null,
    college_id: null,
  });

  const postOffCampusStudentData = async (data) => {
    const url = "api/manage_student/off_campus_jobs/";

    try {
      setApiCallLoading(true);
      const res = await sanchalanAxiosPostReq(url, data);
      console.log(res);
      if (res.data.success) {
        alert.success(res.data.data.message);
        setApiCallLoading(false);
        setRefreshData(!refreshData);
        handleClose();
      }
    } catch (error) {
      setApiCallLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const patchOffCampusStudentData = async (data) => {
    const url = `api/manage_student/off_campus_jobs/${studentDetails.name.id}/`;
    try {
      setApiCallLoading(true);
      const res = await sanchalanAxiosPatchReq(url, data);
      console.log(res);
      if (res.data.success) {
        alert.success(res.data.error);
        setApiCallLoading(false);
        setRefreshData(!refreshData);
        const arr = [...selectedRowsData];
        arr.pop();
        setSelectedRowsData(arr);
        handleCloseDialog();
      }
    } catch (error) {
      setApiCallLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const handleSubmitOffCampus = () => {
    if (validateData()) {
      const college_id = [collegeId ? collegeId : null];

      const data = {
        ...studentDetails,
        college_id: college_id,
        name: studentDetails?.name?.user_name,
        job_type_id: studentDetails?.job_type_id?.id,
        job_type_name: studentDetails.job_type_id?.jobType,
        user_id: studentDetails?.name?.user_id,
      };

      if (selectedRowsData?.length === 1) {
        patchOffCampusStudentData(data);
      } else {
        postOffCampusStudentData(data);
      }
    }
  };

  /* #################################################################################################### */

  const handleCloseDialog = () => {
    handleClose();
  };
  const validateData = () => {
    var is_valid = true;
    var name_error = null;
    var company_error = null;
    var enrollment_no_error = null;
    var job_title_error = null;
    var job_type_error = null;
    var ctc_error = null;
    var placed_date_error = null;

    if (!studentDetails.name) {
      name_error = "Name field cannot be left blank";
      is_valid = false;
    }

    if (!studentDetails.company_name) {
      company_error = "Company Name field cannot be left blank";
      is_valid = false;
    }
    if (!studentDetails?.job_title) {
      job_title_error = "Please enter job title";
      is_valid = false;
    }
    if (!studentDetails?.job_type_id) {
      job_type_error = "Please enter job type";
      is_valid = false;
    }

    if (!studentDetails?.placed_date) {
      placed_date_error = "Please enter student's placed Date";
      is_valid = false;
    }

    if (!studentDetails?.ctc) {
      ctc_error = "Please enter student's ctc";
      is_valid = false;
    }

    setStudentDetailsError({
      ...studentDetailsError,
      name: name_error,
      company_name: company_error,
      enrollment_no: enrollment_no_error,
      job_title: job_title_error,
      job_type_id: job_type_error,
      placed_date: placed_date_error,
      ctc: ctc_error,
    });
    return is_valid;
  };

  return (
    <>
      {dataLoading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <div className={classes.paper}>
            <Typography variant="h5">
              {editStudentOffCampus === "editStudent"
                ? "Edit Off Campus Placement Details"
                : "Add Off Campus Placement Details"}
            </Typography>
            <br />
            <Autocomplete
              id="country-select-demo"
              options={studentListOffCampus}
              getOptionLabel={(student) => student?.user_name}
              value={studentDetails?.name}
              onChange={(e, newValue) => {
                setStudentDetails({
                  ...studentDetails,
                  name: newValue,
                });
                setStudentDetailsError({ ...studentDetailsError, name: null });
              }}
              className={classes.formControl}
              disabled={
                !fromGroup ? false : studentDetails?.college?.id ? false : true
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Student"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={studentDetailsError.name ? true : false}
                  helperText={<span>{studentDetailsError.name}</span>}
                />
              )}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label="Company Name"
              value={studentDetails.company_name}
              onChange={(e) => {
                setStudentDetails({
                  ...studentDetails,
                  company_name: e.target.value,
                });
                setStudentDetailsError({
                  ...studentDetailsError,
                  company_name: null,
                });
              }}
              error={studentDetailsError.company_name ? true : false}
              helperText={<span>{studentDetailsError.company_name}</span>}
            />
            <TextField
              variant="outlined"
              required
              margin="normal"
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="Job Title"
              value={studentDetails.job_title}
              onChange={(e) => {
                setStudentDetails({
                  ...studentDetails,
                  job_title: e.target.value,
                });
                setStudentDetailsError({
                  ...studentDetailsError,
                  job_title: null,
                });
              }}
              error={studentDetailsError.job_title ? true : false}
              helperText={<span>{studentDetailsError.job_title}</span>}
            />
            <Autocomplete
              id="country-select-demo"
              options={jobTypeOptions}
              getOptionLabel={(option) => option.jobType}
              value={studentDetails.job_type_id}
              onChange={(e, newValue) => {
                setStudentDetails({
                  ...studentDetails,
                  job_type_id: newValue,
                });
                setStudentDetailsError({
                  ...studentDetailsError,
                  job_title: null,
                  job_type_id: null,
                });
              }}
              className={classes.formControl}
              disabled={
                !fromGroup ? false : studentDetails?.college?.id ? false : true
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Job Type"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={studentDetailsError.job_type_id ? true : false}
                  helperText={<span>{studentDetailsError.job_type_id}</span>}
                />
              )}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              name="ctc"
              label={
                studentDetails?.job_type_id?.id === 1
                  ? "Stipend (Monthly)"
                  : "CTC (Annually)"
              }
              value={studentDetails?.ctc}
              onChange={(e) => {
                setStudentDetails({
                  ...studentDetails,
                  ctc: Number(e.target.value),
                });
                setStudentDetailsError({ ...studentDetailsError, ctc: null });
              }}
              error={studentDetailsError.ctc ? true : false}
              helperText={<span>{studentDetailsError.ctc}</span>}
            />
            <div style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Calendar
                style={{ display: "grid" }}
                selectedDate={studentDetails?.placed_date}
                title="Placed Date"
                onDateChange={(date) => {
                  setStudentDetails({
                    ...studentDetails,
                    placed_date: moment(date).format("YYYY-MM-DD"),
                  });
                  setStudentDetailsError({
                    ...studentDetailsError,
                    placed_date: null,
                  });
                }}
                errorMessage={studentDetailsError?.placed_date}
                minDate={"1990-11-17"}
              />{" "}
            </div>
            <>
              <div>
                <Typography variant="body2">
                  You can add new student from
                  <a href="/manage-students" style={{ textDecoration: "none" }}>
                    <span className={classes.mstudents}>Manage Students</span>
                  </a>
                  Section
                </Typography>
              </div>
            </>
          </div>
          <br />
          <Grid container spacing={3} style={{ display: "flex" }}>
            <Grid item sm={6} style={{ display: "grid" }}>
              <Button
                size="small"
                className={classes.btncancel}
                variant="outlined"
                style={{
                  borderRadius: "8px",
                  boxShadow: "none",
                  height: "48px",
                  padding: "10px",
                }}
                onClick={() => {
                  handleCloseDialog();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item sm={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                size="small"
                style={{
                  borderRadius: "8px",
                  boxShadow: "none",
                  padding: "10px",
                  height: "48px",
                }}
                color="primary"
                onClick={() => {
                  if (!loading) handleSubmitOffCampus();
                }}
              >
                {apiCallLoading ? (
                  <CustomButtonCircularProgress />
                ) : (
                  <>
                    {editStudentOffCampus === "editStudent" ? "Edit" : "Add"}{" "}
                  </>
                )}{" "}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
