import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme, makeStyles } from "@material-ui/styles";
import {
  Paper,
  IconButton,
  InputBase,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  DialogActions,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AllCollegesGrid from "./AllCollegesGrid";
import { useAlert } from "react-alert";
import { entityInfoSelectorFn } from "../../../api/SignIn/selector";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid #6C757D",
    width: "300px ",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CollegesPostedJobsDialog({
  open,
  handleClose,
  collegesList,
  setCollegesList,
  searchState = false,
  setSearchState = () => {},
  selectedCollegeIds,
  setSelectedCollegeIds,
  setSelectedCollegeChangeTrigger,
  nestedColleges,
  groups,
}) {
  console.log("abcd selectedCollegeIds", selectedCollegeIds);
  const classes = useStyles();
  const alert = useAlert();

  const entityInfo = useSelector(entityInfoSelectorFn);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("lg");
  const [selectAllCollege, setSelectAllCollege] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [temporaryCollegesList, setTemporaryCollegesList] = useState([]);
  const [tempSelectedCollegeIds, setTempSelectedCollegeIds] =
    useState(selectedCollegeIds);
  useEffect(
    () => console.log("abcd tempSelectedCollegeIds", tempSelectedCollegeIds),
    [tempSelectedCollegeIds]
  );

  const groupCollegeList =
    useSelector((state) => state?.GroupCollegeList?.collegeList) || [];

  useEffect(() => {
    if (open) {
      setTemporaryCollegesList(collegesList);
    }
  }, [open, collegesList]);

  const handleSearch = (event) => {
    const query = event?.target?.value || "";
    setSearchQuery(query);

    if (query.trim() === "") {
      setTemporaryCollegesList(searchState);
    } else {
      const filteredList = searchState?.filter((college) =>
        college?.details?.name.toLowerCase().includes(query.toLowerCase())
      );
      setTemporaryCollegesList(filteredList);
    }
  };

  const handleSelectAll = () => {
    const newSelectAllState = !selectAllCollege;
    setSelectAllCollege(newSelectAllState);

    const updatedCollegesList = temporaryCollegesList.map((college) => {
      if (college.details?.is_job_applied) {
        return {
          ...college,
          checked: true,
          placementCycles: college.placementCycles.map((cycle) => ({
            ...cycle,
            checked: true,
          })),
        };
      }

      return {
        ...college,
        checked: newSelectAllState,
        placementCycles: college.placementCycles.map((cycle) => ({
          ...cycle,
          checked: newSelectAllState,
        })),
      };
    });

    if (newSelectAllState) {
      const selectedCollegeIds = temporaryCollegesList.map(
        (college) => college.details.id
      );
      setTempSelectedCollegeIds(selectedCollegeIds);
    } else {
      setTempSelectedCollegeIds([]);
    }

    setTemporaryCollegesList(updatedCollegesList);
    setSearchState(updatedCollegesList);
  };

  useEffect(() => {
    if (
      temporaryCollegesList &&
      temporaryCollegesList.length == groupCollegeList.length
    ) {
      const allSelected = temporaryCollegesList.every(
        (college) => college.checked
      );
      setSelectAllCollege(allSelected);
    }
  }, [temporaryCollegesList]);

  const [prevStateCollege, setPrevStateCollege] = useState(collegesList);

  useEffect(
    () => console.log("prevStateCollege", prevStateCollege),
    [prevStateCollege]
  );
  const handleSaveAndUpdate = () => {
    let isValid1 = true;
    let isAnyCollegeSelected = false;

    setSearchQuery("");

    const tempState = searchState.map((college) => {
      if (college.checked) {
        isAnyCollegeSelected = true;
        const isAnyCycleSelected = college.placementCycles.some(
          (cycle) => cycle.checked
        );
        if (!isAnyCycleSelected) {
          isValid1 = false;

          return { ...college, error: true };
        }
      }
      return college;
    });

    setCollegesList(tempState);
    if (
      isAnyCollegeSelected &&
      !(
        selectedCollegeIds.length === tempSelectedCollegeIds.length &&
        selectedCollegeIds.every((id) => tempSelectedCollegeIds.includes(id))
      )
    )
      setSelectedCollegeChangeTrigger(true);

    if (!isAnyCollegeSelected) {
      isValid1 = false;

      alert.error("Select at least one college to proceed");
    }

    if (isValid1) {
      handleCloseNew(isValid1);
    }
  };

  const handleCloseNew = (isValid = false) => {
    console.log("isValid", isValid);
    if (!isValid) {
      setCollegesList(prevStateCollege);
      setSelectedCollegeIds(selectedCollegeIds);
    } else {
      setCollegesList((prev) => {
        return prev.map((college) => {
          const isAnyCycleChecked = college.placementCycles.some(
            (cycle) => cycle.checked
          );

          if (college.checked && !isAnyCycleChecked) {
            return {
              ...college,
              checked: false,
              error: false,
              placementCycles: college.placementCycles.map((cycle) => ({
                ...cycle,
                checked: false,
              })),
            };
          }

          return {
            ...college,
            error: false,
          };
        });
      });
      // if (selectAllCollege) {
      //   const selectedCollegeIds = temporaryCollegesList.map(
      //     (college) => college.details.id
      //   );
      //   setSelectedCollegeIds(selectedCollegeIds);
      // } else {
      //   setSelectedCollegeIds([]);
      // }
      setSelectedCollegeIds(tempSelectedCollegeIds);
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleCloseNew(false)}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 6,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllCollege}
                  onChange={handleSelectAll}
                  color="primary"
                  disabled={searchQuery != ""}
                />
              }
              label={
                <Typography style={{ color: "#5B5B5B", fontSize: "16px" }}>
                  {` Select all ${entityInfo?.college} and placement cycle`}
                </Typography>
              }
            />
            <Paper component="form" className={classes.root}>
              <IconButton className={classes.iconButton} aria-label="menu">
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder={`Search ${entityInfo?.college}`}
                inputProps={{ "aria-label": "Search Colleges" }}
                value={searchQuery}
                onChange={handleSearch}
              />
            </Paper>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <AllCollegesGrid
              collegesList={temporaryCollegesList}
              setCollegesList={setTemporaryCollegesList}
              setSearchState={setSearchState}
              setSelectedCollegeIds={setTempSelectedCollegeIds}
              nestedColleges={nestedColleges}
              groups={groups}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 15, paddingRight: 25 }}>
          <Button
            onClick={() => handleCloseNew(false)}
            variant="outlined"
            color="inherit"
            style={{ height: "40px", boxShadow: "none", width: "200px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveAndUpdate}
            variant="contained"
            color="primary"
            style={{ height: "40px", boxShadow: "none", width: "200px" }}
          >
            Save and Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
