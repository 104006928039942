import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Collegeimg from "../../../assets/png/default_college.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import GroupIcon from "@mui/icons-material/Group";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "20px",
    cursor: "pointer",
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
    border: "1px solid #E3E6EA",
    borderRadius: "8px",
    margin: "auto",
    // maxWidth: 500,
    "&:hover": {
      backgroundColor: pSBC(0.9, theme.palette.primary.main),
    },
  },
  image: {
    width: 102,
    height: 102,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    //borderColor: theme.palette.primary.main,
    //border: "1px solid",
    // borderRadius: "50%",
  },
}));

export default function CollegeCardList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase className={classes.image}>
              <img
                className={classes.img}
                alt="complex"
                src={props.logo}
                // src={Collegeimg}
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="body2">
                  {props.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <LocationOnOutlinedIcon
                    style={{ color: "#6C757D", marginRight: "10px" }}
                  />
                  {props.city}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <GroupIcon
                    style={{ color: "#6C757D", marginRight: "10px" }}
                  />
                  {props.count}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
